import React, { useEffect, useRef, useState } from 'react'

import GoogleMap from 'google-map-react'
import { List, Popup, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { IPort, Ports, IWarehouse, Warehouses } from 'context/shipment/utils'
import * as supplierOrderUtils from 'context/supplier-order/utils'
import { SupplierOrder } from 'types/supplier-order'

interface MapProps {
  onSupplierOrderHighlighted: (so: SupplierOrder | null) => void
  onSupplierOrderSelected: (so: SupplierOrder) => void
  supplierOrderHighlighted?: SupplierOrder
  supplierOrderSelected: SupplierOrder
  supplierOrders: SupplierOrder[]
  show?: 'shipments' | 'routes'
  showPorts?: boolean
  showWarehouses?: boolean
}

const Map = ({
  onSupplierOrderHighlighted,
  onSupplierOrderSelected,
  show,
  showPorts,
  showWarehouses,
  supplierOrderHighlighted,
  supplierOrderSelected,
  supplierOrders,
}: MapProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [highlightedPort, highlightedWarehouse] = supplierOrderHighlighted
    ? supplierOrderUtils.getSupplierOrderWarehousePort(supplierOrderHighlighted)
    : [null, null]
  const [loaded, setLoaded] = useState(false)

  function usePrevious(value: Partial<MapProps>) {
    const ref = useRef<Partial<MapProps>>()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevProps = usePrevious({ supplierOrders })

  useEffect(() => {
    if (
      map !== null &&
      (!loaded || prevProps?.supplierOrders?.length !== supplierOrders.length)
    ) {
      const bounds = new window.google.maps.LatLngBounds()
      let count = 0

      supplierOrders.forEach((so) => {
        const [lat, lng] = supplierOrderUtils.getSupplierOrderLatLng(so)
        if (lat && lng) {
          bounds.extend(new google.maps.LatLng(lat, lng))
          count += 1
        }
      })

      if (count) {
        map.fitBounds(bounds)

        let zoom = map.getZoom()
        zoom = zoom > 10 ? 8 : zoom - 1
        map.setZoom(zoom)
      } else {
        map.setCenter({ lat: 39.4471265, lng: -95.3905955 })
        map.setZoom(4)
      }
      setLoaded(true)
    }
  }, [map, supplierOrders])

  return (
    <div className="map" style={{ height: '65vh' }}>
      <GoogleMap
        bootstrapURLKeys={{
          key: process.env.GATSBY_GOOGLE_API_KEY as string,
        }}
        defaultCenter={{
          lat: 39.4471265,
          lng: -95.3905955,
        }}
        defaultZoom={6}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          setMap(map)
        }}
      >
        {showPorts
          ? Ports.map((p) => (
              <PortMarker
                key={p.addressLocality}
                highlighted={
                  highlightedPort === `${p.addressLocality}, ${p.addressRegion}`
                }
                lat={p.lat}
                lng={p.lng}
                port={p}
              />
            ))
          : null}

        {showWarehouses
          ? Warehouses.map((w) => (
              <WarehouseMarker
                key={w.addressLocality}
                highlighted={
                  highlightedWarehouse ===
                  `${w.addressLocality}, ${w.addressRegion}`
                }
                lat={w.lat}
                lng={w.lng}
                warehouse={w}
              />
            ))
          : null}

        {supplierOrders.map((so) => {
          const [lat, lng] = supplierOrderUtils.getSupplierOrderLatLng(so)
          const color = show === 'routes' ? so.route?.color : so.shipment?.color
          if (lat && lng)
            return (
              <SOMarker
                key={so.id ?? ''}
                lat={lat}
                lng={lng}
                onSupplierOrderHighlighted={onSupplierOrderHighlighted}
                onSupplierOrderSelected={onSupplierOrderSelected}
                supplierOrder={so}
                supplierOrderHighlighted={supplierOrderHighlighted}
                supplierOrderSelected={supplierOrderSelected}
                color={color ? color : '#BEBEBE'}
              />
            )
        })}
      </GoogleMap>
    </div>
  )
}

const MapPoint = styled.div<{ color?: string; $highlighted?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50% 50% 50% 0;
  border: 2px solid rgba(0, 0, 0, 0.65);
  background: ${(props) => props.color};
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 3;

  ${({ $highlighted }) =>
    $highlighted &&
    `
    width: 30px;
    height: 30px;
    border: 2px solid rgba(255, 255, 255, 0.65);
    z-index: 99;
  `}

  &:hover {
    width: 30px;
    height: 30px;
    border: 2px solid rgba(255, 255, 255, 0.65);
    z-index: 99;
  }
`

interface IMarker {
  key: string
  color?: string
  highlighted?: boolean
  lat: number
  lng: number
}

interface ISOMarker extends IMarker {
  onSupplierOrderHighlighted: (so: SupplierOrder | null) => void
  onSupplierOrderSelected: (so: SupplierOrder) => void
  supplierOrder: SupplierOrder
  supplierOrderHighlighted?: SupplierOrder
  supplierOrderSelected: SupplierOrder
}

interface IPortMarker extends IMarker {
  port: IPort
}

interface IWarehouseMarker extends IMarker {
  warehouse: IWarehouse
}

const SOMarker = ({
  color,
  onSupplierOrderHighlighted,
  onSupplierOrderSelected,
  supplierOrder,
  supplierOrderHighlighted,
}: ISOMarker) => {
  const so = supplierOrder
  const receivingWarehouse = so.shipment?.data?.receivingWarehouse
  const shippingAt =
    so.shipment?.data?.shippingAtActual ||
    so.shipment?.data?.shippingAtEstimated
  const receivingAt =
    so.shipment?.data?.receivingAtActual ||
    so.shipment?.data?.receivingAtEstimated
  const orderName = so.nobiliaOrderRef || so.data?.nobiliaOrderReference || ''
  const ackNumber =
    so.nobiliaAckNumber || so.data?.nobiliaFactoryAckNumber || ''
  const volume = so.data?.volumeCubicMeters || so.data?.nobiliaVolumeCubicMeters
  return (
    <Popup
      open={supplierOrderHighlighted?.id === so.id}
      trigger={
        <MapPoint
          color={color}
          $highlighted={supplierOrderHighlighted?.id === so.id}
          onClick={() => onSupplierOrderSelected(so)}
          onMouseEnter={() => onSupplierOrderHighlighted(so)}
          onMouseLeave={() => onSupplierOrderHighlighted(null)}
        />
      }
      content={
        <List>
          <List.Item>
            <b>Ref: </b>
            {orderName}
          </List.Item>
          <List.Item>
            <b>ACK: </b>
            {ackNumber}
          </List.Item>
          <List.Item>
            <b>Volume: </b>
            {volume} m3
          </List.Item>
          {receivingWarehouse ? (
            <List.Item>
              <b>WH: </b>
              {receivingWarehouse}
            </List.Item>
          ) : null}
          {shippingAt ? (
            <List.Item>
              <b>Shipping: </b>
              {shippingAt}
            </List.Item>
          ) : null}
          {receivingAt ? (
            <List.Item>
              <b>Receiving: </b>
              {receivingAt}
            </List.Item>
          ) : null}
          <List.Item></List.Item>
        </List>
      }
      size="mini"
    />
  )
}

const PortMarker = ({ port, highlighted }: IPortMarker) => {
  return (
    <Popup
      trigger={
        <Icon
          circular
          color="blue"
          inverted
          name="ship"
          size={highlighted ? 'small' : 'tiny'}
        />
      }
      content={
        <List>
          <List.Item>{port.addressLocality}</List.Item>
          <List.Item>{port.addressRegion}</List.Item>
        </List>
      }
      size="mini"
    />
  )
}

const WarehouseMarker = ({ warehouse, highlighted }: IWarehouseMarker) => {
  return (
    <Popup
      trigger={
        <Icon
          circular
          color="grey"
          inverted
          name="warehouse"
          size={highlighted ? 'small' : 'tiny'}
        />
      }
      content={
        <List>
          <List.Item>{warehouse.addressLocality}</List.Item>
          <List.Item>{warehouse.addressRegion}</List.Item>
          <List.Item>{warehouse.company}</List.Item>
        </List>
      }
      size="mini"
    />
  )
}

export default Map
