import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useProspect from 'context/prospect/use'
import { ScrollBarStyle } from 'styles/app/system'

const MenuHomeowner = () => {
  const { pathname } = useLocation()
  const { prospect, prospectUploads } = useProspect()

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="overview"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/overview`,
        )}
        onClick={() => navigate(`/admin/prospects/${prospect?.id}/overview`)}
      />
      <Menu.Item
        name="quiz"
        active={pathname.startsWith(`/admin/prospects/${prospect?.id}/quiz`)}
        onClick={() => navigate(`/admin/prospects/${prospect?.id}/quiz`)}
      >
        Quiz{' '}
        {prospect?.data?.quizAnswers?.length ? (
          <Label color="green">YES</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="estimate"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/estimate`,
        )}
        onClick={() => navigate(`/admin/prospects/${prospect?.id}/estimate`)}
      >
        Estimate{' '}
        {prospect?.data?.estimate ? <Label color="green">YES</Label> : null}
      </Menu.Item>
      <Menu.Item
        name="floor-plan"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/floor-plan`,
        )}
        onClick={() => navigate(`/admin/prospects/${prospect?.id}/floor-plan`)}
      >
        Show Us Your Space{' '}
        {prospectUploads?.length ? <Label color="green">YES</Label> : null}
      </Menu.Item>
      <Menu.Item
        name="call-outcome"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/call-outcome`,
        )}
        onClick={() =>
          navigate(`/admin/prospects/${prospect?.id}/call-outcome`)
        }
      >
        Prospect Call Outcome
      </Menu.Item>
      <Menu.Item
        name="message-templates"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/message-templates`,
        )}
        onClick={() =>
          navigate(`/admin/prospects/${prospect?.id}/message-templates`)
        }
      >
        Message Templates
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuHomeowner

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
