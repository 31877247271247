import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { parseNobiliaOptions } from 'context/design-option-utils'
import {
  FIND_MANY_NOBILIA_OPTION,
  FindManyNobiliaOptionPayload,
  FindManyNobiliaOptionVariables,
} from 'queries/nobilia-option'

const useNobiliaOptionManyNoContext = (opts?: {
  variables?: FindManyNobiliaOptionVariables
  skip?: boolean
}) => {
  const { data: { nobiliaOptions } = { nobiliaOptions: [] } } = useQuery<
    FindManyNobiliaOptionPayload,
    FindManyNobiliaOptionVariables
  >(FIND_MANY_NOBILIA_OPTION, {
    skip: opts?.skip,
    variables: opts?.variables,
  })

  const { fronts, handles } = useMemo(
    () => parseNobiliaOptions(nobiliaOptions),
    [nobiliaOptions],
  )

  return {
    fronts,
    handles,
    nobiliaOptions,
  }
}

export default useNobiliaOptionManyNoContext
