import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Feed, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { parseOwners } from 'context/project/utils'
import { prospectMeetingType } from 'context/prospect/utils'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { Colors } from 'styles/app/system'
import { Meeting, MeetingTypeProspect } from 'types/meeting'
import * as viewUtils from 'views/utils'

import UrgentBadge from './urgent-badge'

const UpcomingMeetings = ({ meetings }: { meetings: Meeting[] }) => {
  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <h6>Upcoming Meetings</h6>
          {!meetings.length ? <p>No upcoming meetings</p> : null}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={5} stretched style={{ rowGap: '25px' }}>
        {meetings.slice(0, 5).map((m) => (
          <Grid.Column key={m.id} width={3}>
            <MeetingCard>
              <div className="title">
                <p className="caption flex">
                  <CircleIcon>
                    <FontAwesomeIcon icon={['fal', 'video']} />
                  </CircleIcon>
                  {m.metadata.type === MeetingTypeProspect.FREE
                    ? prospectMeetingType(m)
                    : viewUtils.capsSnakeCaseToTitleCase(m.metadata.type)}
                  {m.metadata.calendar === 'urgent' ? (
                    <div>
                      <UrgentBadge>Urgent</UrgentBadge>
                    </div>
                  ) : null}
                </p>
                <Button
                  kind="ghost"
                  icon={
                    <FontAwesomeIcon
                      icon={['fal', 'arrow-up-right-from-square']}
                    />
                  }
                  onClick={() =>
                    window.open(
                      m.prospect
                        ? `/admin/prospects/${m.prospectId}`
                        : `/admin/projects/${m.project?.id}/overview`,
                    )
                  }
                />
              </div>
              <div className="divider" />
              <div className="content">
                <CustomerFeed>
                  <Feed.Event>
                    {m.project ? (
                      <Feed.Label>
                        <Image
                          avatar
                          circular
                          src={`${parseOwners(m.project).primaryOwner
                            ?.picture}`}
                        />
                      </Feed.Label>
                    ) : null}
                    <Feed.Content>
                      {m.prospect ? (
                        <p>
                          {m.prospect.firstName
                            ? `${m.prospect.firstName} ${
                                m.prospect.lastName || ''
                              }`
                            : m.prospect.email}
                        </p>
                      ) : (
                        <p>
                          {viewUtils.responsiveText(
                            parseOwners(m.project).primaryOwnersNames,
                            30,
                          )}
                        </p>
                      )}
                    </Feed.Content>
                  </Feed.Event>
                </CustomerFeed>
                <p style={{ marginTop: '8px' }}>
                  {moment(m.startTime).format('MM/DD/YYYY h:mm A')}{' '}
                  {moment(m.startTime).fromNow()}
                </p>
              </div>
            </MeetingCard>
          </Grid.Column>
        ))}
        {meetings.length ? (
          <Grid.Column width={1} style={{ padding: 0 }}>
            <AllMeetingsModal meetings={meetings} />
          </Grid.Column>
        ) : null}
      </Grid.Row>
    </>
  )
}

const AllMeetingsModal = ({ meetings }: { meetings: Meeting[] }) => {
  const [showModal, toggleModal] = useState<boolean>(false)

  return (
    <Modal
      open={showModal}
      title="All Meetings"
      onClose={() => toggleModal(false)}
      onOpen={() => toggleModal(true)}
      size="large"
      customActions={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            kind="solid"
            text="Ok"
            onClick={() => toggleModal(false)}
            style={{ paddingInline: 25 }}
          />
        </div>
      }
      trigger={
        <Button
          text="View All"
          kind="text"
          icon={<FontAwesomeIcon icon={['fal', 'plus']} />}
        />
      }
    >
      <Grid
        style={{
          display: 'flex',
          gap: '20px',
          padding: '30px',
          background: Colors.gray100,
        }}
      >
        {meetings.map((m) => (
          <MeetingRow key={m.id} verticalAlign="middle">
            <Grid.Column width={6}>
              <CustomerFeed>
                <Feed.Event>
                  {m.project ? (
                    <Feed.Label>
                      <Image
                        avatar
                        circular
                        src={`${parseOwners(m.project).primaryOwner?.picture}`}
                      />
                    </Feed.Label>
                  ) : null}
                  <Feed.Content>
                    {m.prospect ? (
                      <p>
                        {m.prospect.firstName
                          ? `${m.prospect.firstName} ${
                              m.prospect.lastName || ''
                            }`
                          : m.prospect.email}
                      </p>
                    ) : (
                      <p>
                        {viewUtils.responsiveText(
                          parseOwners(m.project).primaryOwnersNames,
                          30,
                        )}
                      </p>
                    )}
                  </Feed.Content>
                </Feed.Event>
              </CustomerFeed>
            </Grid.Column>
            <Grid.Column width={4}>
              <p className="caption flex no-margin">
                <CircleIcon>
                  <FontAwesomeIcon icon={['fal', 'video']} />
                </CircleIcon>
                {viewUtils.capsSnakeCaseToTitleCase(m.metadata.type)}
                {m.metadata.calendar === 'urgent' ? (
                  <div>
                    <UrgentBadge>Urgent</UrgentBadge>
                  </div>
                ) : null}
              </p>
            </Grid.Column>
            <Grid.Column width={5}>
              <span>
                <p className="no-margin">
                  {moment(m.startTime).format('MM/DD/YYYY HH:mm')}
                </p>
                <p className="gray">{moment(m.startTime).fromNow()}</p>
              </span>
            </Grid.Column>
            <Grid.Column width={1}>
              <Button
                kind="ghost"
                iconShape="circle"
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'arrow-up-right-from-square']}
                  />
                }
                onClick={() =>
                  window.open(
                    m.prospect
                      ? `/admin/prospects/${m.prospectId}`
                      : `/admin/projects/${m.project?.id}/overview`,
                  )
                }
              />
            </Grid.Column>
          </MeetingRow>
        ))}
      </Grid>
    </Modal>
  )
}

export default UpcomingMeetings

const MeetingCard = styled.div`
  &&&& {
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 14px 18px;
    transition: all 0.2s ease-in-out;

    p,
    button {
      font-size: 14px;
      margin: 0;
      padding: 0;

      svg {
        font-size: 16px;
      }
    }

    div.title {
      align-items: center;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 16px;
      }
    }

    div.divider {
      border-bottom: 1px solid ${Colors.gray200};
      margin: 10px 0 12px;
    }

    :hover {
      box-shadow: 0px 17px 25px rgba(0, 0, 0, 0.15);
      transform: translateY(-10px);
    }
  }
`

const MeetingRow = styled(Grid.Row)`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  padding: 15px 25px !important;
`

const CustomerFeed = styled(Feed)`
  &&&&&& {
    margin-bottom: 0;

    .event {
      .label,
      img {
        height: 30px;
        width: 30px;
      }
      .content {
        align-items: center;
        display: flex;
      }
    }
  }
`

const CircleIcon = styled.span`
  align-items: center;
  background: rgba(157, 165, 110, 0.15);
  border-radius: 100px;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 10px;
  padding: 5ps;
  width: 24px;

  svg {
    color: ${Colors.greenBright};
    font-size: 12px !important;
  }
`
