import { gql } from '@apollo/client'

import { Route, RouteData } from 'types/route'

export const CREATE_ROUTE = gql`
  mutation createRoute($data: RouteCreateInput!) {
    createOneRoute(data: $data) {
      id
    }
  }
`

export interface CreateRoutePayload {
  createOneRoute: Route
}

export interface CreateRouteVariables {
  data: {
    color?: string
    confirmedAt?: Date
    data: RouteData
    reference?: string
    supplierOrders?: {
      connect: { id: string }[]
    }
  }
}

export const DELETE_ROUTE = gql`
  mutation deleteRoute($where: RouteWhereUniqueInput!) {
    deleteOneRoute(where: $where) {
      id
    }
  }
`

export interface DeleteRoutePayload {
  deleteOneRoute: Route
}

export interface DeleteRouteVariables {
  where: { id: string }
}

export const FIND_UNIQUE_ROUTE = gql`
  query findUniqueRoute($where: RouteWhereUniqueInput!) {
    route: findUniqueRoute(where: $where) {
      id
      archivedAt
      color
      confirmedAt
      data
      reference
      supplierOrders {
        id
        actions {
          id
          data
        }
        data
        deliveryNote {
          id
          key
          name
          numPages
          type
        }
        design {
          id
          order {
            id
            targetDate
          }
          room {
            id
            data
            type
          }
        }
        nobiliaAckNumber
        nobiliaOrderRef
        project {
          id
          data
          owners {
            isSubscribed
            isPrimary
            user {
              id
              email
              firstName
              lastName
              type
            }
            userRole
          }
          projectAddress
        }
        route {
          id
          archivedAt
          color
          confirmedAt
          reference
        }
      }
    }
  }
`

export interface FindUniqueRoutePayload {
  route: Route
}

export interface FindUniqueRouteVariables {
  where: {
    id?: string
  }
}

export const FIND_MANY_ROUTE = gql`
  query findManyRoute(
    $take: Int
    $orderBy: [RouteOrderByWithRelationInput!]
    $skip: Int
    $where: RouteWhereInput
  ) {
    count: findManyRouteCount(where: $where)
    routes: findManyRoute(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      archivedAt
      color
      confirmedAt
      data
      reference
      supplierOrders {
        id
        actions {
          id
          data
        }
        data
        design {
          id
          name
          room {
            id
            data
            type
          }
        }
        nobiliaAckNumber
        nobiliaOrderRef
        project {
          id
          data
          owners {
            isSubscribed
            isPrimary
            user {
              id
              email
              firstName
              lastName
              picture
              type
            }
            userRole
          }
          projectAddress
        }
        route {
          id
          archivedAt
          color
          confirmedAt
          reference
        }
      }
    }
  }
`

export const FIND_MANY_ROUTE_SHALLOW = gql`
  query {
    routes: findManyRoute {
      id
      derivedStatus
    }
  }
`

export const FIND_MANY_ROUTE_COUNT = gql`
  query findManyRouteCount($where: RouteWhereInput) {
    count: findManyRouteCount(where: $where)
  }
`

export interface FindManyRouteCountPayload {
  count: number
}

export interface FindManyRoutePayload {
  routes: Route[]
  count?: number
}

export interface FindManyRouteVariables {
  orderBy?: {
    derivedStatus?: string
    reference?: string
  }
  skip?: number
  take?: number
  where?: {
    archivedAt?: { not?: string | null } | null
    confirmedAt?: { not?: string | null } | null
    data?: {
      path?: string[]
      equals?: string
    }
    derivedSearch?: {
      contains: string
      mode?: 'insensitive'
    }
    derivedStatus?: {
      gte?: string
      lte?: string
      gt?: string
      lt?: string
    }
    OR?: {
      derivedStatus?: {
        startsWith?: string
      }
    }[]
  }
}

export const UPDATE_ROUTE = gql`
  mutation updateRoute(
    $data: RouteUpdateInput!
    $where: RouteWhereUniqueInput!
  ) {
    updateOneRoute(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateRoutePayload {
  updateOneRoute: { id: string }
}

export interface UpdateRouteVariables {
  where: { id: string }
  data: {
    archivedAt?: Date
    color?: string
    confirmedAt?: Date
    data?: RouteData
    reference?: string
    supplierOrders?: {
      disconnect?: { id: string }[]
      connect?: { id: string }[]
    }
  }
}
