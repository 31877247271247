import { useContext, useMemo } from 'react'

import useUnresolvedIssuesCount from 'context/issue/use-unresolved-issues-count'
import useAddOnRemedialCounts from 'context/supplier-order/use-addon-remedial-counts'
import { parseTasks } from 'context/task/utils-many'
import useFORMEmployees from 'context/user/use-form-employees'

import { CustomerSupportContext } from './provider'

const useCustomerSupport = () => {
  const {
    meetings,
    messages,
    projects,
    refetchMessages,
    refetchTasks,
    refetchProjects,
    tasks,
    userId,
  } = useContext(CustomerSupportContext)

  const { FORMEmployeeIDToName } = useFORMEmployees()
  const userName = FORMEmployeeIDToName[userId]

  const {
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
  } = useAddOnRemedialCounts(userId)

  const { issuesUnresolvedCount } = useUnresolvedIssuesCount(userId)

  const parsedTasks = useMemo(() => parseTasks(tasks), [tasks])

  return {
    issuesUnresolvedCount,
    meetings,
    messages,
    projects,
    refetchMessages,
    refetchProjects,
    refetchTasks,
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
    userId,
    userName,
    ...parsedTasks,
  }
}

export default useCustomerSupport
