import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from 'semantic-ui-react'

import GenericOptionsSelection from 'components/shared/catalog/generic-option/selection'
import GenericOptionManyProvider from 'context/generic-option/provider-many'
import { QueryFilters } from 'context/generic-option/utils-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { GenericOption } from 'types/generic-option'

import { Labels } from '../utils'

const GenericOptionsModal = ({
  defaultFilters,
  onSave,
  selectedGenericOptions,
  title,
}: {
  defaultFilters: QueryFilters
  selectedGenericOptions: Partial<GenericOption>[]
  onSave: (genericOptions: Partial<GenericOption>[]) => void
  title?: string
}) => {
  const [genericOptions, setGenericOptions] = useState<
    Partial<GenericOption>[]
  >(selectedGenericOptions)
  const [showModal, toggleModal] = useState<boolean>(false)
  const [defaultFilters_] = useState<QueryFilters>(defaultFilters)

  useEffect(
    () => setGenericOptions(selectedGenericOptions),
    [selectedGenericOptions],
  )

  const onClose = () => {
    setGenericOptions(selectedGenericOptions)
    toggleModal(false)
  }

  return (
    <Modal
      onClose={onClose}
      open={showModal}
      size="large"
      title={`Choose ${title || 'options'}`}
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={13}>
              {genericOptions.length ? (
                <Labels>
                  {genericOptions.map((o) => (
                    <div key={o.id} className="selection">
                      <p>{o.description}</p>
                      <FontAwesomeIcon
                        icon={['fal', 'times']}
                        onClick={() => {
                          if (genericOptions.find((s) => s.id === o.id))
                            setGenericOptions(
                              genericOptions.filter((s) => s.id !== o.id),
                            )
                          else setGenericOptions([...genericOptions, o])
                        }}
                      />
                    </div>
                  ))}
                </Labels>
              ) : null}
            </Grid.Column>
            <Grid.Column width={3}>
              <div className="flex-center" style={{ flexWrap: 'unset' }}>
                <Button
                  kind="outlined"
                  text="Close"
                  color="gray"
                  onClick={onClose}
                  style={{ marginRight: '20px' }}
                />
                <Button
                  kind="solid"
                  text="Save"
                  onClick={() => {
                    onSave(genericOptions)
                    toggleModal(false)
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      trigger={
        <Button
          kind="ghost"
          fontAwesomeIcon={genericOptions.length ? 'pencil' : 'plus'}
          text={genericOptions.length ? 'Change' : 'Add'}
          onClick={() => toggleModal(true)}
        />
      }
    >
      <GenericOptionManyProvider defaultFilters={defaultFilters_} skipLoader>
        <GenericOptionsSelection
          selectedGenericOptions={genericOptions}
          setSelectedGenericOptions={setGenericOptions}
        />
      </GenericOptionManyProvider>
    </Modal>
  )
}

export default GenericOptionsModal
