import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  DropdownProps,
  Form,
  Image,
  InputOnChangeData,
} from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import CurrencyInput from 'components/shared/currency-input'
import useAppliancePack from 'context/appliance-pack/use'
import useAppliancePackGenericProductMutate from 'context/appliance-pack/use-generic-product-mutate'
import { formatDollars } from 'context/dollar-utils'
import useGenericProductClassMany from 'context/generic-product-class/use-many'
import { getReferenceImage } from 'context/media/utils-many'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import {
  GenericProductData,
  GenericProductGraphQL,
} from 'types/generic-product'
import { GenericProductClass } from 'types/generic-product-class'

const emptyAppliance: Partial<GenericProductGraphQL> = {
  productClass: undefined,
  data: {
    imageUrl: '',
    productDetails: '',
    linkToProduct: '',
    linkToProductSpec: '',
    size: '',
  },
}

const CreateUpdateApplianceModal = ({
  appliance,
  open,
  onClose,
}: {
  appliance?: Partial<GenericProductGraphQL>
  open: boolean
  onClose: () => void
}) => {
  const { genericProductClasses } = useGenericProductClassMany()
  const { appliancePack } = useAppliancePack()
  const {
    createGenericProductAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateGenericProductAndRefetch,
  } = useAppliancePackGenericProductMutate()
  const [state, setState] = useState<Partial<GenericProductGraphQL>>(
    appliance || emptyAppliance,
  )

  useEffect(() => {
    setState(appliance || emptyAppliance)
  }, [open, appliance])

  const sizes = state.productClass?.metadata?.defaultSizes

  useEffect(() => {
    if (sizes?.length === 1) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          size: sizes[0],
        },
      }))
    }
  }, [sizes])

  const genericProductClassOptions = useMemo(
    () =>
      genericProductClasses.map((ce: GenericProductClass) => {
        return {
          key: ce.identifier,
          text: (
            <div
              style={{
                alignItems: 'center',
                display: 'inline-flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Image
                style={{
                  margin: '0 12px 0 0',
                  width: '55px',
                  height: '55px',
                }}
                src={ce.media ? getReferenceImage(ce.media) : ''}
              />
              {ce.metadata?.name}
            </div>
          ),
          value: ce.identifier,
        }
      }),
    [genericProductClasses],
  )

  const onSave = async () => {
    if (appliance) {
      await updateGenericProductAndRefetch({
        variables: {
          data: {
            productClass: {
              connect: {
                id: state.productClass?.id || '',
              },
            },
            data: state.data,
          },
          where: {
            id: appliance.id ?? '',
          },
        },
      })
    } else {
      await createGenericProductAndRefetch({
        variables: {
          data: {
            data: state.data as GenericProductData,
            productClass: {
              connect: {
                id: state.productClass?.id || '',
              },
            },
            quantity: 1,
            appliancePack: {
              connect: {
                id: appliancePack.id ?? '',
              },
            },
          },
        },
      })
    }
    onClose()
  }

  const onChangeProductClass: DropdownProps['onChange'] = (_, { value }) => {
    setState({
      ...state,
      productClass: value
        ? genericProductClasses.find((a) => a.identifier === value)
        : undefined,
      data: {
        ...state.data,
        size: '',
      },
    })
  }

  const onChangeInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { name, value }: InputOnChangeData,
  ) =>
    setState({
      ...state,
      data: {
        ...state.data,
        [`${name}`]: value,
      },
    })

  const onChangePrice = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      data: {
        ...state.data,
        price: Math.round(Number(value.replace(/[, $]+/g, '')) * 100),
      },
    })
  }

  return (
    <Modal
      title={`${appliance ? 'Update' : 'Create'} appliance`}
      onClose={onClose}
      open={open}
      size="small"
      saveButton={{
        disabled:
          !state.productClass ||
          !state.data?.size ||
          !state.data?.productDetails ||
          !state.data?.linkToProduct,
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
    >
      <StyledForm
        onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) =>
          e.preventDefault()
        }
      >
        <Form.Dropdown
          label="Appliance"
          onChange={onChangeProductClass}
          options={genericProductClassOptions}
          placeholder="Select an appliance"
          required
          selection
          value={state.productClass?.identifier}
        />
        {sizes && sizes.length > 1 ? (
          <Form.Field required style={{ margin: '0' }}>
            <label>Size</label>
            <Form.Group style={{ flexWrap: 'wrap', width: '100%' }} widths={6}>
              {sizes?.map((s: string, idx) => (
                <Form.Field key={idx}>
                  <StyledSizeButton
                    content={s}
                    value={s}
                    name="size"
                    onClick={onChangeInput}
                    styled={{
                      selected: state.data?.size === s,
                    }}
                    type="button"
                  />
                </Form.Field>
              ))}
            </Form.Group>
          </Form.Field>
        ) : null}
        <Form.Input
          label="Make / Model"
          name="productDetails"
          onChange={onChangeInput}
          required
          value={state.data?.productDetails}
        />
        <Form.Input
          label="Link"
          name="linkToProduct"
          onChange={onChangeInput}
          required
          value={state.data?.linkToProduct}
        />
        <Form.Input
          label="Link to Spec"
          name="linkToProductSpec"
          onChange={onChangeInput}
          value={state.data?.linkToProductSpec}
        />
        <Form.Field>
          <span>Price</span>
          <CurrencyInput
            defaultValue={formatDollars(state.data?.price ?? 0)}
            inputMode="numeric"
            maskOptions={{}}
            onChange={onChangePrice}
            value={formatDollars(state.data?.price ?? 0)}
          />
        </Form.Field>
        <Form.Input
          label="Image URL"
          name="imageUrl"
          onChange={onChangeInput}
          value={state.data?.imageUrl}
        />
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateApplianceModal

const StyledSizeButton = styled(Button)<{
  styled: {
    selected: boolean
  }
}>`
  &&&&& {
    align-items: center;
    background: transparent;
    border: 1px solid ${Colors.gray200} !important;
    border-radius: 4px;
    height: 50px;
    justify-content: center;
    width: 100px;
    margin-bottom: 10px;

    /* text style */
    color: ${Colors.black};
    font-family: 'GT America' !important;
    font-size: 16px !important;
    font-weight: 300;

    ${({ styled }) =>
      styled?.selected &&
      css`
        border-color: black !important;
      `}
  }
`
