import { useMutation } from '@apollo/client'

import {
  UPDATE_MANY_SUPPLIER_ORDER,
  UpdateManySupplierOrderPayload,
  UpdateManySupplierOrderVariables,
} from 'queries/supplier-order'

const useSupplierOrderMutateMany = () => {
  const [updateManySupplierOrder, { loading: loadingUpdateMany }] = useMutation<
    UpdateManySupplierOrderPayload,
    UpdateManySupplierOrderVariables
  >(UPDATE_MANY_SUPPLIER_ORDER)

  return {
    updateManySupplierOrder,
    loadingUpdateMany,
  }
}

export default useSupplierOrderMutateMany
