import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import TextareaAutosize from 'react-textarea-autosize'
import { Form } from 'semantic-ui-react'

import { notifySuccess } from 'context/notifications/trigger'
import { Modal } from 'design-system/components/modal'
import {
  CREATE_MESSAGE_TEMPLATE_BOOK,
  CreateTemplateBookPayload,
  CreateTemplateBookVariables,
  UPDATE_MESSAGE_TEMPLATE_BOOK,
  UpdateTemplateBookPayload,
  UpdateTemplateBookVariables,
} from 'queries/message-template-book'
import { StyledForm } from 'styles/admin/main'
import { MessageTemplateBook } from 'types/message-template'
import * as viewUtils from 'views/utils'

interface TemplateBookModalProps {
  onCloseModal: () => void
  openModal: boolean
  template?: MessageTemplateBook
  refetch: () => void
}

const TemplateBookModal = ({
  onCloseModal,
  openModal,
  template,
  refetch,
}: TemplateBookModalProps) => {
  const initialState = {
    description: template?.data.description || '',
    identifier: template?.identifier || viewUtils.humanReadableUid(),
    index: template?.index || '',
    name: template?.data.name || '',
  }
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (openModal) setState(initialState)
  }, [openModal])

  const [createTemplateBook] = useMutation<
    CreateTemplateBookPayload,
    CreateTemplateBookVariables
  >(CREATE_MESSAGE_TEMPLATE_BOOK, {
    onCompleted: () => {
      notifySuccess('Message Template Book Created')
      refetch()
    },
  })

  const [updateTemplateBook] = useMutation<
    UpdateTemplateBookPayload,
    UpdateTemplateBookVariables
  >(UPDATE_MESSAGE_TEMPLATE_BOOK, {
    onCompleted: () => {
      notifySuccess('Message Template Book Updated')
      refetch()
    },
  })

  const onClose = () => {
    setState(initialState)
    onCloseModal()
  }

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: value })

  const handleSubmit = async () => {
    if (template?.id) {
      await updateTemplateBook({
        variables: {
          data: {
            data: {
              name: state.name,
            },
          },
          where: {
            id: template.id,
          },
        },
      })
    } else {
      await createTemplateBook({
        variables: {
          data: {
            data: {
              description: state.description,
              name: state.name,
            },
            identifier: state.identifier,
            index: state.index,
          },
        },
      })
    }

    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={openModal}
      size="large"
      title={
        template
          ? 'Edit Message Template Book'
          : 'Create New Message Template Book'
      }
      saveButton={{
        disabled: !state.index || !state.name,
        onClick: handleSubmit,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Group widths="equal">
          {!template ? (
            <Form.Input
              label="Index"
              name="index"
              onChange={onChangeInput}
              required
              value={state.index}
            />
          ) : null}
          <Form.Input
            label="Name"
            name="name"
            onChange={onChangeInput}
            required
            value={state.name}
          />
        </Form.Group>
        {!template ? (
          <Form.Field
            control={TextareaAutosize}
            label="Description"
            placeholder="Description"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setState({ ...state, description: e.target.value })
            }
            value={state.description}
          />
        ) : null}
      </StyledForm>
    </Modal>
  )
}

export default TemplateBookModal
