import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import Tasks from 'components/admin/project/task/tasks'
import ActivityFeed from 'components/admin/project-event'
import useTechDesigner from 'context/dashboard/tech-designer/use'
import ProjectEventsManyProvider from 'context/project-event/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import TechDesignerLayout from './layout'

const TechDesignerOverview = (_: RouteComponentProps) => {
  const { tasksToDo, tasksUrgent, refetchTasks, userId } = useTechDesigner()

  return (
    <TechDesignerLayout>
      <Grid stackable doubling padded>
        <Grid.Row>
          <Grid.Column width={9}>
            <ProjectEventsManyProvider
              defaultFilters={{
                assignedTo: {
                  id: userId,
                },
                assignedToRole: ProjectStaffRole.TECH_DESIGNER,
                newFor: {
                  id: userId,
                },
                showStaff: true,
                take: 40,
                sortBy: 'createdAt',
                sortDirection: 'descending',
              }}
              formUserId={userId}
            >
              <ActivityFeed formUserId={userId} />
            </ProjectEventsManyProvider>
          </Grid.Column>
          <Grid.Column width={7}>
            <Tasks
              tasks={tasksUrgent}
              title="Tasks Urgent"
              refetch={refetchTasks}
            />
            <Tasks
              tasks={tasksToDo}
              title="Tasks To Do"
              refetch={refetchTasks}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </TechDesignerLayout>
  )
}

export default TechDesignerOverview
