import React from 'react'

import { RouteComponentProps } from '@reach/router'

import OrderProvider from 'context/order/provider'

interface OrderProps
  extends RouteComponentProps<{
    project_id: string
    order_id: string
  }> {
  children?: React.ReactNode
}

export default (props: OrderProps) => (
  <OrderProvider order_id={props.order_id}>{props.children}</OrderProvider>
)
