import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import useProject from 'context/project/use'
import useProjectStaffMutate from 'context/project/use-project-staff-mutate'
import useFORMEmployees from 'context/user/use-form-employees'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import { ProjectStaff, ProjectStaffRole } from 'types/project-staff'
import { StaffMemberAssignmentType } from 'types/utils'
import * as viewUtils from 'views/utils'

interface ProjectStaffModalProps {
  open: boolean
  onClose: () => void
  projectStaff: ProjectStaff
  disablePrimary?: boolean
}

const ProjectStaffCreateUpdateModal = ({
  open,
  onClose,
  projectStaff,
  disablePrimary,
}: ProjectStaffModalProps) => {
  const { project } = useProject()
  const {
    createProjectStaffAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateProjectStaffAndRefetch,
  } = useProjectStaffMutate()
  const { userOptions } = useFORMEmployees()
  const [state, setState] = useState<ProjectStaff>(
    projectStaff || {
      data: {
        staffMemberAssignmentType: StaffMemberAssignmentType.MANUAL,
      },
      isPrimary: false,
      isSubscribed: true,
      staffMemberRole: ProjectStaffRole.CUSTOMER_SUPPORT,
      staffMemberId: '',
      projectId: project.id,
    },
  )

  useEffect(() => {
    setState(
      projectStaff || {
        data: {
          staffMemberAssignmentType: StaffMemberAssignmentType.MANUAL,
        },
        isPrimary: false,
        isSubscribed: true,
        staffMemberRole: ProjectStaffRole.CUSTOMER_SUPPORT,
        staffMemberId: '',
        projectId: project.id,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStaff])

  const onSave = async () => {
    if (!state.staffMemberId) return
    if (projectStaff?.staffMemberId) {
      await updateProjectStaffAndRefetch({
        variables: {
          data: {
            data: state.data || {},
            staffMember: {
              connect: {
                id: state.staffMemberId,
              },
            },
            staffMemberRole: state.staffMemberRole,
            isPrimary: state.isPrimary,
            isSubscribed: state.isSubscribed,
          },
          where: {
            projectId_staffMemberId: {
              projectId: project.id,
              staffMemberId: projectStaff.staffMemberId,
            },
          },
        },
      })
    } else {
      await createProjectStaffAndRefetch({
        variables: {
          data: {
            data: state.data || {},
            project: {
              connect: {
                id: project.id,
              },
            },
            staffMember: {
              connect: {
                id: state.staffMemberId,
              },
            },
            staffMemberRole: state.staffMemberRole,
            isPrimary: state.isPrimary,
            isSubscribed: state.isSubscribed,
          },
        },
      })
    }
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title={projectStaff ? 'Edit Staff Relationship' : 'Attach Staff'}
      contentStyle={{ overflow: 'initial' }}
      saveButton={{
        disabled: !state.staffMemberId || !state.staffMemberRole,
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
    >
      <StyledForm>
        <Form.Dropdown
          fluid
          label="Staff Member"
          options={userOptions}
          onChange={(_, { value }) =>
            setState({
              ...state,
              staffMemberId: value as string,
            })
          }
          placeholder="Select Staff Member"
          search
          selection
          value={state.staffMemberId}
        />
        <Form.Dropdown
          fluid
          label="Role"
          options={[
            ProjectStaffRole.ACCOUNT_EXECUTIVE,
            ProjectStaffRole.SALES_DESIGNER,
            ProjectStaffRole.TECH_DESIGNER,
            ProjectStaffRole.TECH_DESIGNER_SUPPORT,
            ProjectStaffRole.CUSTOMER_SUPPORT,
            ProjectStaffRole.TRADE_DESIGNER,
          ].map((r) => ({
            key: r,
            text: viewUtils.capsSnakeCaseToTitleCase(r),
            value: r,
          }))}
          onChange={(_, { value }) =>
            setState({
              ...state,
              staffMemberRole: value as ProjectStaffRole,
            })
          }
          placeholder="Role"
          selection
          value={state.staffMemberRole}
        />
        <Form.Dropdown
          fluid
          label="Assignment Type"
          options={[
            StaffMemberAssignmentType.MANUAL,
            StaffMemberAssignmentType.REFERRED,
            StaffMemberAssignmentType.ROUND_ROBIN,
            StaffMemberAssignmentType.ROUND_ROBIN_EXEMPT,
            StaffMemberAssignmentType.ROUND_ROBIN_REFILL,
          ].map((r) => ({
            key: r,
            text: viewUtils.capsSnakeCaseToTitleCase(r),
            value: r,
          }))}
          onChange={(_, { value }) =>
            setState({
              ...state,
              data: {
                ...state.data,
                staffMemberAssignmentType: value as StaffMemberAssignmentType,
              },
            })
          }
          placeholder="Assignment Type"
          selection
          value={state.data?.staffMemberAssignmentType || ''}
        />
        <FormCheckbox
          disabled={disablePrimary}
          checked={state.isPrimary}
          label={'Primary'}
          onChange={() =>
            setState({
              ...state,
              isPrimary: !state.isPrimary,
            })
          }
        />
        <FormCheckbox
          disabled={disablePrimary}
          checked={state.isSubscribed}
          label={'Subscribed'}
          onChange={() =>
            setState({
              ...state,
              isSubscribed: !state.isSubscribed,
            })
          }
        />
      </StyledForm>
    </Modal>
  )
}

export default ProjectStaffCreateUpdateModal
