import { useMutation } from '@apollo/client'
import { JSONContent } from '@tiptap/react'

import {
  CREATE_MESSAGE,
  CreateMessagePayload,
  CreateMessageVariables,
  UPDATE_MESSAGE,
  UpdateMessagePayload,
  UpdateMessageVariables,
} from 'queries/message'
import { Message, MessageSource } from 'types/message'
import { Project } from 'types/project'
import { User } from 'types/user'
import { mapOrEmptyArray } from 'views/utils'

const useMessageMutate = (userId: string) => {
  const [createMessage, { loading: loadingCreate }] = useMutation<
    CreateMessagePayload,
    CreateMessageVariables
  >(CREATE_MESSAGE)

  const [updateMessage, { loading: loadingUpdate }] = useMutation<
    UpdateMessagePayload,
    UpdateMessageVariables
  >(UPDATE_MESSAGE)

  const acknowledgeMessage = async (
    message: Message,
    onCompleted?: () => void,
  ) => {
    await updateMessage({
      variables: {
        data: {
          newFor: {
            disconnect: [
              {
                id: userId,
              },
            ],
          },
        },
        where: {
          id: message.id ?? '',
        },
      },
      onCompleted: onCompleted,
    })
  }

  const sendMessage = async ({
    body,
    bodyHtml,
    from,
    onCompleted,
    project,
  }: {
    body: JSONContent
    bodyHtml: string
    from: User
    onCompleted: () => void
    project?: Project
  }) => {
    await createMessage({
      variables: {
        data: {
          body,
          bodyHtml,
          from: {
            connect: { id: userId },
          },
          project: {
            connect: { id: project?.id ?? '' },
          },
          newFor: {
            connect: mapOrEmptyArray([from], (u) => ({
              id: u.id,
            })),
          },
          to: {
            connect: mapOrEmptyArray([from], (u) => ({
              id: u.id,
            })),
          },
          data: {
            source: MessageSource.APP,
          },
        },
      },
      onCompleted,
    })
  }

  return {
    acknowledgeMessage,
    loadingCreate,
    loadingUpdate,
    sendMessage,
  }
}

export default useMessageMutate
