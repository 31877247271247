import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Designs from 'components/admin/design/index-many'
import DesignsReviewsTable from 'components/admin/design/table-reviews'
import { FiltersVersion } from 'components/admin/design/use-filters-search'
import useTechDesigner from 'context/dashboard/tech-designer/use'
import DesignManyProvider from 'context/design/provider-many'

import TechDesignerLayout from './layout'

const TechDesignerDesignsAwaitingFeedback = (_: RouteComponentProps) => {
  const { userId, userName } = useTechDesigner()

  return (
    <TechDesignerLayout>
      <DesignManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          reviewStatus: 'awaitingFeedback',
          take: 40,
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
        }}
        skipLoader
      >
        <Designs
          filtersType={FiltersVersion.REVIEW}
          table={<DesignsReviewsTable />}
        />
      </DesignManyProvider>
    </TechDesignerLayout>
  )
}

export default TechDesignerDesignsAwaitingFeedback
