import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NotesDashboard from 'components/admin/project-notes/notes-dashboard'
import useAuth from 'context/auth/use'
import CommentNoteManyProvider from 'context/project/project-notes/provider-many'

import Layout from './layout'

const SelectionCenterManagerNotes = (_: RouteComponentProps) => {
  const { formUserId } = useAuth()

  return (
    <CommentNoteManyProvider
      formUserId={formUserId}
      defaultFilters={{
        newFor: {
          id: formUserId,
        },
      }}
    >
      <Layout>
        <NotesDashboard />
      </Layout>
    </CommentNoteManyProvider>
  )
}

export default SelectionCenterManagerNotes
