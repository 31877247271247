import React from 'react'

import { RouteComponentProps } from '@reach/router'

import TasksDashboard from 'components/admin/task/dashboard'
import useTechDesignerSupport from 'context/dashboard/tech-designer-support/use'

import TechDesignerSupportLayout from './layout'

const TechDesignerSupportTasks = (_: RouteComponentProps) => {
  const { tasksToDo, tasksUrgent, tasksCompleted, refetchTasks } =
    useTechDesignerSupport()
  return (
    <TechDesignerSupportLayout>
      <TasksDashboard
        refetch={refetchTasks}
        tasksToDo={tasksToDo}
        tasksUrgent={tasksUrgent}
        tasksCompleted={tasksCompleted}
      />
    </TechDesignerSupportLayout>
  )
}

export default TechDesignerSupportTasks
