import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import Media from 'components/admin/catalog/media/index-many'
import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import MediaManyProvider from 'context/media/provider-many'

const Module = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="Media" />
        <Grid.Row>
          <Grid.Column>
            <MediaManyProvider
              defaultFilters={{
                sortBy: 'createdAt',
                sortDirection: 'descending',
                take: 50,
              }}
              skipLoader
            >
              <Media />
            </MediaManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
