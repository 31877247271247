import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Grid, Label } from 'semantic-ui-react'

import CatalogFeatureSelectionCard from 'components/shared/catalog/catalog-feature/card'
import {
  SelectionCardVariation,
  SelectionCardDeleteProps,
} from 'design-system/components/selection-card'
import { SelectionAccordion } from 'styles/admin/main'
import { CatalogFeature } from 'types/catalog-feature'

import CatalogFeatureSelectionModal from './selection-modal'

interface CatalogFeatureAccordionProps {
  catalogFeatures: Partial<CatalogFeature>[]
  onSave: (nobiliaOptions: Partial<CatalogFeature>[]) => void
  title: string
}

const CatalogFeatureAccordion = ({
  catalogFeatures,
  onSave,
  title,
}: CatalogFeatureAccordionProps) => {
  const [showCards, toggleCards] = useState<boolean>(true)

  return (
    <SelectionAccordion fluid>
      <Accordion.Title className="accordion-title">
        <span className="name" onClick={() => toggleCards(!showCards)}>
          <p className="subtitle no-margin">{title}</p>
          <FontAwesomeIcon
            icon={['fal', showCards ? 'chevron-up' : 'chevron-down']}
          />
        </span>
        <CatalogFeatureSelectionModal
          onSave={onSave}
          selectedCatalogFeatures={catalogFeatures}
          title={title}
        />
      </Accordion.Title>
      <Accordion.Content active className="accordion-content">
        {showCards ? (
          <Grid>
            <Grid.Row columns={6}>
              {catalogFeatures?.map((cf) => (
                <Grid.Column key={cf.id}>
                  <CatalogFeatureSelectionCard
                    catalogFeature={cf}
                    selectionCardProps={
                      {
                        imageRatio: '1/1',
                        expandOnly: true,
                        onDelete: () =>
                          onSave(catalogFeatures.filter((c) => c.id !== cf.id)),
                      } as Partial<SelectionCardDeleteProps>
                    }
                    selectionCardVariation={SelectionCardVariation.DELETE}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        ) : (
          <div className="labels">
            {catalogFeatures?.map((cf) => (
              <Label key={cf.id}>
                {cf.data?.displayName || cf.description}
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  onClick={() =>
                    onSave(catalogFeatures.filter((c) => c.id !== cf.id))
                  }
                />
              </Label>
            ))}
          </div>
        )}
      </Accordion.Content>
    </SelectionAccordion>
  )
}

export default CatalogFeatureAccordion
