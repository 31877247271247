import { DESIGN_TYPES } from 'types/design'

export interface NameParts {
  type: string
  number?: string
  other?: string | null
  additionalDescription?: string | null
}

export const getNameParts = (name: string, roomDesigns: number): NameParts => {
  const type =
    Object.values(DESIGN_TYPES).find((d) => name.startsWith(d)) ||
    DESIGN_TYPES.OTHER

  const secondPartName =
    type === DESIGN_TYPES.REVISION && name.includes('-')
      ? name.split('-')[1]
      : roomDesigns.toString()

  const additionalDescription =
    type === DESIGN_TYPES.ADD_ON_DESIGN
      ? name.split(`${DESIGN_TYPES.ADD_ON_DESIGN}-`)[1]
      : null

  return {
    type,
    number: type === DESIGN_TYPES.REVISION ? secondPartName : '',
    other: type === DESIGN_TYPES.OTHER ? name : null,
    additionalDescription,
  }
}
