import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import { Media, MediaCategory } from 'types/media'

import MediaCard from './card'

const Assets = ({
  media,
  refetch,
}: {
  media: Media[]
  refetch: () => Promise<unknown>
}) => {
  return (
    <Grid>
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <h3>Assets and Textures</h3>
        </Grid.Column>
      </Grid.Row>
      {media.filter(
        (m) =>
          m.category === MediaCategory.asset_3D ||
          m.category === MediaCategory.asset_2D_plan ||
          m.category === MediaCategory.texture_high ||
          m.category === MediaCategory.texture_low ||
          m.category === MediaCategory.texture_mid,
      ).length == 0 && (
        <Grid.Row>
          <Grid.Column>No assets found</Grid.Column>
        </Grid.Row>
      )}
      {media.filter(
        (m) =>
          m.category === MediaCategory.asset_3D ||
          m.category === MediaCategory.asset_2D_plan ||
          m.category === MediaCategory.texture_high ||
          m.category === MediaCategory.texture_low ||
          m.category === MediaCategory.texture_mid,
      ).length > 0 && (
        <Grid.Row columns={3}>
          {media
            .filter(
              (m) =>
                m.category === MediaCategory.asset_3D ||
                m.category === MediaCategory.asset_2D_plan ||
                m.category === MediaCategory.texture_high ||
                m.category === MediaCategory.texture_low ||
                m.category === MediaCategory.texture_mid,
            )
            .map((m) => (
              <Grid.Column
                key={m.id}
                style={{
                  maxHeight: '300px',
                  marginBottom: '70px',
                }}
              >
                <MediaCard enableEdit={true} media={m} refetch={refetch} />
                {m.category !== MediaCategory.asset_3D ? (
                  <p style={{ marginTop: '12px' }}>{m.name}</p>
                ) : null}
              </Grid.Column>
            ))}
        </Grid.Row>
      )}
    </Grid>
  )
}

export default Assets
