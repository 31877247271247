import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Container } from 'semantic-ui-react'
import styled from 'styled-components'

import HeaderBasic from 'components/app/project/header/header-basic'
import HelpMessage from 'components/shared/help-message'
import Tracking from 'components/shared/tracking'
import { getAddressString } from 'context/address/utils'
import useProject from 'context/project/use'
import { Colors } from 'styles/app/system'
import { responsiveText } from 'views/utils'

import Content from './layout/content'
import AdminMessageLayoutProvider from './layout/context'
import LayoutTabs from './layout/tabs'

const Messages = (_: RouteComponentProps) => {
  const { project, primaryOwnersNames } = useProject()
  const [fixedHeaderRef, setFixedHeaderRef] = useState<HTMLDivElement | null>(
    null,
  )

  const fixedContentHeightOffset = fixedHeaderRef?.offsetHeight

  const wrapperPadding = `${fixedHeaderRef?.offsetHeight}px 1rem 0px 1rem`

  return (
    <AdminMessageLayoutProvider>
      <HeaderWrapper ref={setFixedHeaderRef}>
        <HeaderBasic
          noBorder
          title={`${responsiveText(primaryOwnersNames, 100)} - ${
            getAddressString(project.projectAddress) || 'No Location Yet'
          }`}
        />

        <LayoutTabs />
        <HelpMessage
          icon="triangle-exclamation"
          title="Notice: We've Moved Messaging to Email"
          message={`All project communications have moved to email. Utilize your email inbox with the HubSpot extension enabled for project communication.`}
        />
      </HeaderWrapper>

      <Tracking />
      <Wrapper style={{ padding: wrapperPadding }}>
        <Container>
          <ContentWrapper
            style={{
              height: `calc(100vh - ${fixedContentHeightOffset}px)`,
            }}
          >
            <div style={{ flexGrow: 1, overflow: 'hidden' }}>
              <Content />
            </div>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </AdminMessageLayoutProvider>
  )
}

export default Messages

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  padding-bottom: 20px;
  gap: 20px;
`

const HeaderWrapper = styled.div`
  box-shadow: 0px 10px 15px rgba(120, 113, 108, 0.03);
  background-color: ${Colors.white};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  #headerForm {
    position: relative;
  }
`
