import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import useProspect from 'context/prospect/use'

const ProspectRedirect = (_: RouteComponentProps) => {
  const { prospect } = useProspect()

  // Redirect all prospects to overview
  useEffect(() => {
    navigate(`/admin/prospects/${prospect?.id}/overview`, {
      replace: true,
    })
  }, [])

  return <></>
}

export default ProspectRedirect
