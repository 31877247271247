import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Grid, Label } from 'semantic-ui-react'

import Button from 'design-system/components/button'
import {
  SelectionCardDeleteProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { SelectionAccordion } from 'styles/admin/main'
import { RenderProp } from 'types/render-prop'

import RenderPropSelectionCard from './card'
import RenderPropSelectionModal from './selection-modal'

interface RenderPropAccordionProps {
  renderProps: RenderProp[]
  onSave: (renderProps: RenderProp[]) => void
}

const RenderPropAccordion = ({
  renderProps,
  onSave,
}: RenderPropAccordionProps) => {
  const [showCards, toggleCards] = useState<boolean>(true)
  const [showModal, toggleModal] = useState<boolean>(false)

  return (
    <SelectionAccordion fluid>
      <Accordion.Title className="accordion-title">
        <span className="name" onClick={() => toggleCards(!showCards)}>
          <p className="subtitle no-margin">Render props</p>
          <FontAwesomeIcon
            icon={['fal', showCards ? 'chevron-up' : 'chevron-down']}
          />
        </span>
        <Button
          kind="ghost"
          fontAwesomeIcon={renderProps.length ? 'pencil' : 'plus'}
          text={renderProps.length ? 'Change' : 'Add'}
          onClick={() => toggleModal(true)}
        />
        <RenderPropSelectionModal
          onClose={() => toggleModal(false)}
          onSave={onSave}
          openModal={showModal}
          selectedRenderProps={renderProps}
        />
      </Accordion.Title>
      <Accordion.Content active className="accordion-content">
        {showCards ? (
          <Grid>
            <Grid.Row columns={6}>
              {renderProps?.map((rp) => (
                <Grid.Column key={rp.id}>
                  <RenderPropSelectionCard
                    renderProp={rp}
                    selectionCardProps={
                      {
                        imageRatio: '1/1',
                        onDelete: () =>
                          onSave(renderProps.filter((n) => n.id !== rp.id)),
                      } as Partial<SelectionCardDeleteProps>
                    }
                    selectionCardVariation={SelectionCardVariation.DELETE}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        ) : (
          <div className="labels">
            {renderProps?.map((rp) => (
              <Label key={rp.id}>
                {rp.data.formCategory || rp.data.category},{' '}
                {rp.data.formBrand || rp.data.brand}
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  onClick={() =>
                    onSave(renderProps.filter((n) => n.id !== rp.id))
                  }
                />
              </Label>
            ))}
          </div>
        )}
      </Accordion.Content>
    </SelectionAccordion>
  )
}

export default RenderPropAccordion
