import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled from 'styled-components'

import { getSupplierOrderStatusSummary } from 'context/supplier-order/utils'
import Button from 'design-system/components/button'
import { Delivery } from 'types/delivery'
import { Shipment } from 'types/shipment'
import { SupplierOrder } from 'types/supplier-order'

const CellContentsSupplierOrders = ({
  delivery,
  setShipmentToEdit,
  setSupplierOrderToEdit,
}: {
  delivery: Delivery
  setShipmentToEdit: (s?: Shipment) => void
  setSupplierOrderToEdit: (so?: SupplierOrder) => void
}) => {
  return (
    <div style={{ display: 'grid', rowGap: '16px' }}>
      {delivery.supplierOrders.map((so) => {
        const soStatus = getSupplierOrderStatusSummary(so)
        return (
          <SupplierOrdersDiv key={so.id}>
            <div>
              <FontAwesomeIcon icon={['fal', 'dolly']} />
            </div>
            <div>
              <p className="no-margin">{so.nobiliaOrderRef}</p>
              <Button
                color="gray"
                fontAwesomeIcon="tag"
                iconPosition="left"
                kind="outlined"
                onClick={() => setSupplierOrderToEdit(so)}
                style={{ margin: '4px 0' }}
                text={`ACK ${so.nobiliaAckNumber ?? ''}`}
              />
              {so.shipment ? (
                <Button
                  color="gray"
                  fontAwesomeIcon="truck"
                  iconPosition="left"
                  kind="outlined"
                  onClick={() => setShipmentToEdit(so.shipment)}
                  style={{ marginBottom: '4px' }}
                  text="View shipment"
                />
              ) : null}
              <p className="light-gray no-margin x-small">
                {soStatus.currentStage}
              </p>
              <p className="light-gray no-margin x-small">
                {soStatus.currentStageDate
                  ? moment(soStatus.currentStageDate).format('MMMM DD, YYYY')
                  : null}
              </p>
            </div>
          </SupplierOrdersDiv>
        )
      })}
    </div>
  )
}

export default CellContentsSupplierOrders

const SupplierOrdersDiv = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  p.linkTo {
    cursor: pointer;
  }
`
