import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Grid, Label } from 'semantic-ui-react'

import CustomOptionCreateUpdateModal from 'components/admin/catalog/custom-option/create-update-modal'
import DisconnectedOptionSelectionCard from 'components/shared/catalog/disconnected-option/card'
import {
  SelectionCardDeleteProps,
  SelectionCardProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import {
  FIND_UNIQUE_DISCONNECTED_OPTION,
  FindUniqueDisconnectedOptionPayload,
  FindUniqueDisconnectedOptionVariables,
} from 'queries/disconnected-option'
import { SelectionAccordion } from 'styles/admin/main'
import { DisconnectedOptionDesign } from 'types/design'
import { DisconnectedOption } from 'types/disconnected-option'
import { ProjectFile } from 'types/project-file'
import { DisconnectedOptionRoom } from 'types/room'

import DisconnectedOptionSelectionModal from './selection-modal'

interface DisconnectedOptionAccordionProps {
  conceptImages?: ProjectFile[]
  customIdentifier?: string
  featureIdentifier?: string
  disconnectedOptions: Partial<DisconnectedOption>[]
  onDelete?: (d: Partial<DisconnectedOption>) => void
  onSave: (d: Partial<DisconnectedOption>[]) => void
  onSaveCustom?: (
    d: Partial<DisconnectedOptionRoom> | Partial<DisconnectedOptionDesign>,
  ) => void
  selectionCardProps?: SelectionCardProps
  title: string
}

const DisconnectedOptionAccordion = ({
  conceptImages,
  customIdentifier,
  featureIdentifier,
  disconnectedOptions,
  onDelete: onDeleteDisconnectedOption,
  onSave,
  onSaveCustom,
  selectionCardProps,
  title,
}: DisconnectedOptionAccordionProps) => {
  const [showCards, toggleCards] = useState<boolean>(true)

  const { data: { disconnectedOption } = { disconnectedOption: null } } =
    useQuery<
      FindUniqueDisconnectedOptionPayload,
      FindUniqueDisconnectedOptionVariables
    >(FIND_UNIQUE_DISCONNECTED_OPTION, {
      skip: !customIdentifier,
      variables: {
        where: { identifier: customIdentifier },
      },
    })

  const formOptions = disconnectedOptions.filter(
    (o) => o.optionIdentifier !== 'custom',
  )

  return (
    <SelectionAccordion fluid>
      <Accordion.Title className="accordion-title">
        <span className="name" onClick={() => toggleCards(!showCards)}>
          <p className="subtitle no-margin">{title}</p>
          <FontAwesomeIcon
            icon={['fal', showCards ? 'chevron-up' : 'chevron-down']}
          />
        </span>
        <div style={{ display: 'flex', gap: '14px' }}>
          {conceptImages?.length ? (
            <CustomOptionCreateUpdateModal
              conceptImages={conceptImages}
              elementType={title}
              onSave={(n, f) => {
                onSaveCustom?.({
                  disconnectedOption: {
                    ...(disconnectedOption as DisconnectedOption),
                    data: {
                      ...disconnectedOption?.data,
                      customName: n,
                      customImageUrl: f.key,
                      fileId: f.id,
                    },
                  },
                  data: {
                    name: n,
                  },
                  projectFile: f,
                })
              }}
            />
          ) : null}
          <DisconnectedOptionSelectionModal
            {...(featureIdentifier &&
              ({
                defaultFilters: {
                  feature: {
                    identifier: featureIdentifier,
                  },
                },
              } as any))}
            onSave={onSave}
            selectedDisconnectedOptions={formOptions}
            title={title}
          />
        </div>
      </Accordion.Title>
      <Accordion.Content active className="accordion-content">
        {showCards ? (
          <Grid>
            <Grid.Row columns={3}>
              {disconnectedOptions?.map((option) => (
                <Grid.Column key={option.id}>
                  <DisconnectedOptionSelectionCard
                    disconnectedOption={option}
                    selectionCardProps={
                      {
                        ...selectionCardProps,
                        onDelete: () => onDeleteDisconnectedOption?.(option),
                      } as Partial<SelectionCardDeleteProps>
                    }
                    selectionCardVariation={SelectionCardVariation.DELETE}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        ) : (
          <div className="labels">
            {disconnectedOptions?.map((option) => (
              <Label key={option.id}>
                {option.description}
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  onClick={() => onDeleteDisconnectedOption?.(option)}
                />
              </Label>
            ))}
          </div>
        )}
      </Accordion.Content>
    </SelectionAccordion>
  )
}

export default DisconnectedOptionAccordion
