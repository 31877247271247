import React from 'react'

import useAuth from 'context/auth/use'
import { parseLatestPlan } from 'context/design/utils'
import { ButtonTooltip } from 'design-system/components/button'
import { TooltipProps } from 'design-system/components/tooltip'
import { Colors } from 'styles/app/system'
import { Design } from 'types/design'

const CellContentActions = ({
  design,
  setDesignToSend,
}: {
  design: Design
  setDesignToSend?: React.Dispatch<React.SetStateAction<Design | undefined>>
}) => {
  const { isArtist, isFORMStaff } = useAuth()
  const { latestPlan } = parseLatestPlan(design)
  const COMMON_TOOLTIP_PROPS: Omit<TooltipProps, 'children' | 'label'> = {
    color: Colors.blue700,
  }
  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        rowGap: '8px',
      }}
    >
      {isFORMStaff && !!latestPlan?.approvedAt && setDesignToSend && (
        <ButtonTooltip
          color="dark"
          fontAwesomeIcon="send"
          kind="solid"
          onClick={() => setDesignToSend(design)}
          style={{ marginBottom: '8px' }}
          tooltipProps={{
            label: 'Send S.O.',
            ...COMMON_TOOLTIP_PROPS,
          }}
        />
      )}
      {isFORMStaff ? (
        <>
          <ButtonTooltip
            color="blue"
            fontAwesomeIcon="arrow-up-right-from-square"
            kind="solid"
            onClick={() =>
              window.open(
                `/admin/projects/${design.room.project.id}/rooms/${design.room.id}/designs/${design.id}`,
              )
            }
            tooltipProps={{
              label: 'Open in project',
              ...COMMON_TOOLTIP_PROPS,
            }}
          />
          <ButtonTooltip
            color="blue"
            fontAwesomeIcon="eye"
            kind="solid"
            onClick={() => window.open(`/admin/files/${latestPlan?.id}/`)}
            tooltipProps={{
              label: 'See plan',
              ...COMMON_TOOLTIP_PROPS,
            }}
          />
        </>
      ) : null}
      {isArtist || isFORMStaff ? (
        <ButtonTooltip
          color="blue"
          fontAwesomeIcon="file-image"
          kind="solid"
          onClick={() => window.open(`/admin/designs/${design.id}`)}
          tooltipProps={{
            label: 'See render page',
            ...COMMON_TOOLTIP_PROPS,
          }}
        />
      ) : null}
    </div>
  )
}

export default CellContentActions
