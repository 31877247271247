import React, { useState } from 'react'

import { Card, Divider, Feed, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import CreateUpdateRoomModal from 'components/shared/project/room/create-update-modal'
import useProject from 'context/project/use'
import { getRoomFormattedType } from 'context/room/utils'
import Button from 'design-system/components/button'
import { StyledLabel as StyledLabel_ } from 'styles/admin/main'
import { RoomGraphQL } from 'types/room'

const RoomsCard = () => {
  const { isTradeProject, project, roomsAll } = useProject()

  const [enableCreateRoom, setEnableCreateRoom] = useState<boolean>(false)
  const [roomToEdit, setRoomToEdit] = useState<RoomGraphQL>()

  return (
    <Card fluid>
      <Card.Content>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h6 className="no-margin">Rooms</h6>
          <Button
            kind="outlined"
            color="gray"
            fontAwesomeIcon="plus"
            text="Add Room"
            onClick={() => setEnableCreateRoom(true)}
          />
        </div>
        <Divider hidden />
        <Feed>
          {roomsAll.map((r) => (
            <Feed.Event key={r.id}>
              <Feed.Label>
                <StyledLabel
                  background={r.data?.tagColor}
                  circular
                  size="mini"
                />
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary>
                  <p>
                    {getRoomFormattedType(r)}
                    {r.data?.archivedAt ? (
                      <StyledLabel_
                        style={{ marginLeft: '10px' }}
                        styled={{ gray: true }}
                      >
                        Archived
                      </StyledLabel_>
                    ) : null}
                  </p>
                </Feed.Summary>
                <Feed.Extra text>{r.data?.descriptionAdditional}</Feed.Extra>
                <Feed.Extra>
                  <Button
                    kind="outlined"
                    color="gray"
                    text="Update"
                    onClick={() => setRoomToEdit(r)}
                  />
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          ))}
        </Feed>
        <CreateUpdateRoomModal
          isTradeProject={isTradeProject}
          onClose={() => {
            setRoomToEdit(undefined)
            setEnableCreateRoom(false)
          }}
          open={enableCreateRoom || !!roomToEdit}
          projectId={project.id}
          room={roomToEdit}
          rooms={roomsAll}
        />
      </Card.Content>
    </Card>
  )
}

export default RoomsCard

const StyledLabel = styled(Label)<{ background: string }>`
  &&& {
    background: ${(props) => props.background} !important;
  }
`
