import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Modal } from 'design-system/components/modal'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { ConversationMessages } from 'views/admin/project/messages/layout/conversation-messages'

type ConversationMessagesModalProps = {
  conversationId: string
  handleClose: () => void
}

export const ConversationMessagesModal = ({
  conversationId,
  handleClose,
}: ConversationMessagesModalProps) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading || !conversationId) return
    setTimeout(() => {
      const messagesModal = document.getElementById(
        'messages-modal',
      ) as HTMLElement
      messagesModal.scrollTop = messagesModal.scrollHeight
    }, 300)
  }, [loading, conversationId])

  return (
    <Modal
      open={!!conversationId}
      onClose={handleClose}
      unmountOnExit
      disableBackdropClick={false}
      size="large"
      contentStyle={{
        backgroundColor: Colors.gray50,
        overflow: 'hidden',
        display: 'flex',
        paddingRight: 0,
      }}
      title="Conversation"
    >
      <MessagesContainer id="messages-modal">
        <ConversationMessages
          conversationId={conversationId}
          setLoading={setLoading}
        />
      </MessagesContainer>
    </Modal>
  )
}

const MessagesContainer = styled.div`
  ${ScrollBarStyle}

  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 8px;
  scroll-behavior: smooth;
`
