import React from 'react'

import { calculateGrossProfit } from 'context/order/utils'
import StatusLabel from 'design-system/components/status-label/status-label'
import { Order } from 'types/order'
import { StatusMode } from 'types/utils'

const CellContentsCommission = ({ order }: { order: Order }) => {
  const { gpFormatted, commissionApproved, commissionApprovedFormatted } =
    calculateGrossProfit(order)
  return (
    <StatusLabel
      type={
        commissionApproved ? StatusMode.Confirmed : StatusMode.OpenForRevision
      }
      text={commissionApprovedFormatted || gpFormatted}
    />
  )
}

export default CellContentsCommission
