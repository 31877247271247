import { useContext } from 'react'

import { RoomElementClassContext } from './provider'

const useRoomElementClass = () => {
  const { catalog, roomElementClass, refetch } = useContext(
    RoomElementClassContext,
  )

  return {
    catalog,
    roomElementClass,
    refetch,
  }
}

export default useRoomElementClass
