import React from 'react'

import styled from 'styled-components'

type ConversatonModalConfirmationProps = {
  isCreatingUser: boolean
  hasUserSelected: boolean
  showConfirmation: boolean
}

export const ConversatonModalConfirmation = ({
  isCreatingUser,
  hasUserSelected,
  showConfirmation,
}: ConversatonModalConfirmationProps) => {
  if (!showConfirmation) return null

  let title = 'Save user assignment'
  let subtitle =
    'Are you sure you want to detach the user from this conversation?'
  let description =
    'Doing so will show the messages held in this conversation as an anonymous sender.'

  if (isCreatingUser) {
    title = 'Create user and assign'
    subtitle = 'Are you sure you want to create a new user?'
    description =
      'The user will be added to the project and attached to this conversation.'
  } else if (hasUserSelected) {
    subtitle = 'Are you sure you want to attach the user to this conversation?'
    description =
      'Doing so will show the messages held in this conversation as sent by this user.'
  }

  return (
    <StyledDiv>
      <h4>{title}</h4>
      <h6>{subtitle}</h6>
      <p className="gray">{description}</p>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(250, 250, 250, 0.5);
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: -webkit-fill-available;
  z-index: 2;

  animation: appear 0.3s ease-in-out;

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
