import React, { useEffect, useState } from 'react'

import { Dropdown, Form } from 'semantic-ui-react'

import useProjectMutateNoContext from 'context/project/use-mutate-no-context'
import useFORMEmployees from 'context/user/use-form-employees'
import { userTypeToProjectUserRole } from 'context/user/utils'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { FormCheckbox } from 'styles/app/components/checkbox'
import { ProjectType } from 'types/project'
import { ProjectStaffRole } from 'types/project-staff'
import { User } from 'types/user'
import { StaffMemberAssignmentType } from 'types/utils'

import * as viewUtils from '../utils'

interface CreateProjectModalProps {
  user: User
  open: boolean
  onClose: () => void
}

const CreateProjectModal = ({
  user,
  open,
  onClose,
}: CreateProjectModalProps) => {
  const [primaryDesignerId, setPrimaryDesignerId] = useState<string>('')
  const [projectType, setProjectType] = useState<ProjectType>(
    ProjectType.HOMEOWNER,
  )
  const [projectLabel, setProjectLabel] = useState('')
  const [checked, setChecked] = useState<boolean>(false)

  const { createProject } = useProjectMutateNoContext()
  const { userOptions } = useFORMEmployees()

  useEffect(() => {
    setPrimaryDesignerId('')
    setProjectType(ProjectType.HOMEOWNER)
    setProjectLabel('')
    setChecked(false)
  }, [user])

  const onSubmit = async () => {
    await createProject({
      variables: {
        data: {
          data: {
            projectLabel: projectLabel,
            projectType: projectType,
          },
          owners: {
            create: [
              {
                isSubscribed: true,
                isPrimary: true,
                user: {
                  create: {
                    email: user.email,
                    type: user.type,
                  },
                },
                userRole: (() => {
                  return userTypeToProjectUserRole(user.type)
                })(),
              },
            ],
          },
          staff: {
            create: [
              {
                data: {
                  staffMemberAssignmentType: StaffMemberAssignmentType.MANUAL,
                },
                isSubscribed: true,
                isPrimary: true,
                staffMember: { connect: { id: primaryDesignerId } },
                staffMemberRole: ProjectStaffRole.SALES_DESIGNER,
              },
            ],
          },
        },
      },
    })
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title="Create New Project"
      customActions={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <FormCheckbox
            checked={checked}
            label="I'm sure I want to create this project"
            onChange={() => setChecked(!checked)}
          />
          <div>
            <Button
              color="blue"
              kind="solid"
              onClick={onClose}
              style={{ marginRight: '14px' }}
              text="Close"
            />
            <Button
              kind="solid"
              text="Create"
              disabled={!checked}
              onClick={async () => {
                await onSubmit()
                onClose()
              }}
            />
          </div>
        </div>
      }
    >
      {user && (
        <>
          <p>
            To create a new project for <b>{viewUtils.getNameForUser(user)}</b>{' '}
            please choose a designer
          </p>
          <StyledForm styled={{ grayInputs: true }}>
            <Form.Field>
              <label>Designer to assign</label>
              <Dropdown
                fluid
                onChange={(_, { value }) =>
                  setPrimaryDesignerId(value as string)
                }
                options={userOptions}
                placeholder="Designer"
                search
                selection
                value={primaryDesignerId}
              />
            </Form.Field>
            <Form.Dropdown
              fluid
              label="Project Type"
              onChange={(_, { value }) => setProjectType(value as ProjectType)}
              options={Object.values(ProjectType).map((v) => ({
                text: viewUtils.capsSnakeCaseToTitleCase(v),
                value: v as ProjectType,
              }))}
              placeholder="Select a project type"
              selection
              value={projectType}
            />
            <Form.Input
              disabled={projectType !== ProjectType.TRADE}
              fluid
              label="Project Label (Trade Only)"
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                setProjectLabel(value)
              }}
              value={projectLabel}
            />
          </StyledForm>
        </>
      )}
    </Modal>
  )
}

export default CreateProjectModal
