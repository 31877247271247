// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BABYLON from 'babylonjs'
import { Button, Form, Modal } from 'semantic-ui-react'

import useNobiliaOptions from 'context/planner/nobilia-options-use'
import { ModalIcon } from 'design-system/components/modal'
import { NodeBase } from 'planner/objects/node'
import * as volumeUtils from 'planner/utils/volume-utils'
import { StyledModal } from 'styles/planner/main'
import {
  TemplateCabinetHeight,
  TemplateLayout,
  TemplateParameters,
  TemplateStyle,
  TemplateUpgrades,
  TemplateUpperStorage,
} from 'types/volume'

interface EstimateModalProps {
  catalog: string
  onCloseModal: () => void
  onPopulate: (nodes: NodeBase[]) => void
  open: boolean
  position: BABYLON.Vector3
  rotation: BABYLON.Vector3
}

const EstimateModal = ({
  catalog,
  onCloseModal,
  onPopulate,
  open,
  position,
  rotation,
}: EstimateModalProps) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<TemplateParameters>({
    catalog,
    cabinetHeight: TemplateCabinetHeight.StandardBase,
    integratedLighting: false,
    layout: TemplateLayout.Galley,
    linearMillimeters: 0,
    style: TemplateStyle.FlatPanelWithHandle,
    upgrades: TemplateUpgrades.$,
    upperStorage: TemplateUpperStorage.Uppers,
  })
  const { nobiliaFeatures } = useNobiliaOptions()
  return (
    <StyledModal onClose={onCloseModal} open={open} size="large">
      <ModalIcon onClick={onCloseModal}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </ModalIcon>
      <Modal.Header>
        <p className="label-black">Generate An Estimate</p>
      </Modal.Header>
      <Modal.Content scrolling>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Layout</label>
              <Form.Dropdown
                options={Object.values(TemplateLayout).map((l) => ({
                  key: l,
                  text: l,
                  value: l,
                }))}
                onChange={(
                  _: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: { value: string },
                ) => {
                  setState({
                    ...state,
                    layout: value as TemplateLayout,
                  })
                }}
                search
                selection
                value={state.layout}
              />
            </Form.Field>
            <Form.Field>
              <label>Total Linear Millimeters</label>
              <Form.Input
                onChange={(
                  _: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: { value: string | number },
                ) => {
                  setState({
                    ...state,
                    linearMillimeters:
                      typeof value === 'number'
                        ? (value as number)
                        : parseInt(value as string) || 0,
                  })
                }}
                type="number"
                value={state.linearMillimeters || ''}
              />
            </Form.Field>
            <Form.Field>
              <label>Style</label>
              <Form.Dropdown
                options={Object.values(TemplateStyle).map((l) => ({
                  key: l,
                  text: l,
                  value: l,
                }))}
                onChange={(
                  _: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: { value: string },
                ) => {
                  setState({
                    ...state,
                    style: value as TemplateStyle,
                  })
                }}
                search
                selection
                value={state.style}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Upgrades</label>
              <Form.Dropdown
                options={Object.values(TemplateUpgrades).map((l) => ({
                  key: l,
                  text: l,
                  value: l,
                }))}
                onChange={(
                  _: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: { value: string },
                ) => {
                  setState({
                    ...state,
                    upgrades: value as TemplateUpgrades,
                  })
                }}
                search
                selection
                value={state.upgrades}
              />
            </Form.Field>
            <Form.Field>
              <label>Upper Storage</label>
              <Form.Dropdown
                options={Object.values(TemplateUpperStorage).map((l) => ({
                  key: l,
                  text: l,
                  value: l,
                }))}
                onChange={(
                  _: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: { value: string },
                ) => {
                  setState({
                    ...state,
                    upperStorage: value as TemplateUpperStorage,
                  })
                }}
                search
                selection
                value={state.upperStorage}
              />
            </Form.Field>
            <Form.Field>
              <label>Cabinet Height</label>
              <Form.Dropdown
                options={Object.values(TemplateCabinetHeight).map((l) => ({
                  key: l,
                  text: l,
                  value: l,
                }))}
                onChange={(
                  _: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: { value: string },
                ) => {
                  setState({
                    ...state,
                    cabinetHeight: value as TemplateCabinetHeight,
                  })
                }}
                search
                selection
                value={state.cabinetHeight}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Integrated Lighting</label>
              <Form.Checkbox
                checked={state.integratedLighting}
                onChange={() => {
                  setState({
                    ...state,
                    integratedLighting: !state.integratedLighting,
                  })
                }}
                toggle
              />
            </Form.Field>
            <Form.Field />
            <Form.Field />
          </Form.Group>
          <Button
            onClick={() => {
              const { volumes } = volumeUtils.templateParametersToVolumes(
                state,
                null,
                null,
                position,
                rotation,
              )
              onPopulate(volumes)
              onCloseModal()
            }}
          >
            Generate Volumes
          </Button>
          <Button
            loading={loading}
            onClick={async () => {
              const { volumes } = volumeUtils.templateParametersToVolumes(
                state,
                null,
                null,
                position,
                rotation,
              )
              setLoading(true)
              const products = await volumeUtils.generateProductsFromVolumes(
                volumes,
                nobiliaFeatures,
              )
              setLoading(false)
              onPopulate(products)
              onCloseModal()
            }}
          >
            Generate Products
          </Button>
        </Form>
      </Modal.Content>
    </StyledModal>
  )
}

export default EstimateModal
