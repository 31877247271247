import { useContext } from 'react'

import useNobiliaOrderProcessorCounts from 'context/supplier-order/use-nobilia-order-processor-counts'

import { NobiliaOrderProcessorContext } from './provider'

const useNobiliaOrderProcessor = () => {
  const { userId } = useContext(NobiliaOrderProcessorContext)

  const { lateCount, onTrackCount } = useNobiliaOrderProcessorCounts()

  return {
    lateCount,
    onTrackCount,
    userId,
  }
}

export default useNobiliaOrderProcessor
