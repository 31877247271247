import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  IFormCheckboxValue,
} from 'components/admin/filters-search'
import useRoomElementClassMany from 'context/room-element-class/use-many'

export type RoomElementClassFilterValues = {
  productTypes: IFormCheckboxValue
}

const useRoomElementClassFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useRoomElementClassMany()

  const filters: DynamicFilterGroup<RoomElementClassFilterValues>[] =
    useMemo(() => {
      return []
    }, [])

  const defaultFilters = useMemo(() => {
    const roomElementClassFilters: Partial<RoomElementClassFilterValues> = {}
    return roomElementClassFilters
  }, [queryFilters])

  const handleApplyFilters = (_: RoomElementClassFilterValues) => {
    setQueryFilters({
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useRoomElementClassFiltersSearch
