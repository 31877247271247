import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from 'semantic-ui-react'

import CatalogFeatureAccordion from 'components/admin/catalog/catalog-feature/accordion'
import DisconnectedOptionAccordion from 'components/admin/catalog/disconnected-option/accordion'
import NobiliaOptionAccordion from 'components/admin/catalog/nobilia-option/accordion'
import useDesign from 'context/design/use'
import useDesignOptionsMutate from 'context/design/use-design-options-mutate'
import StyledAccordion from 'design-system/components/accordion'
import * as NO from 'planner/objects/products/nobilia/options/definitions'

const Finishes = ({ initialCatalog = '' }: { initialCatalog?: string }) => {
  const {
    carcaseColorsExterior,
    carcaseColorsInterior,
    catalogFeatures,
    design,
    fronts,
    glassColors,
    glassDesigns,
    glassFrameColors,
    glassFrameDesigns,
    handles,
    nobiliaCarcaseExteriorsDiscontinued,
    nobiliaFrontsDiscontinued,
    nobiliaHandlesDiscontinued,
    nobiliaPlinthColorsDiscontinued,
    plinthColors,
  } = useDesign()

  const {
    onDeleteDisconnectedOption,
    onSaveCatalogFeatures,
    onSaveDisconnectedOptions,
    onSaveNobiliaOptions,
  } = useDesignOptionsMutate()

  const [showGlassOptions, toggleGlassOptions] = useState<boolean>(
    !!glassColors?.length ||
      !!glassDesigns?.length ||
      !!glassFrameColors?.length ||
      !!glassFrameDesigns?.length,
  )

  return (
    <StyledAccordion titleText="Finishes">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={design?.metadata?.catalog || initialCatalog}
              featureNo={NO.FRONT_COMBO}
              nobiliaOptions={fronts ?? []}
              onSave={(no) => onSaveNobiliaOptions(no, fronts ?? [])}
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Fronts"
            />
          </Grid.Column>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={design?.metadata?.catalog || initialCatalog}
              featureNo={NO.HANDLE_COMBINATION}
              nobiliaOptions={handles ?? []}
              onSave={(no) => onSaveNobiliaOptions(no, handles ?? [])}
              selectionCardProps={{ imageRatio: '3/2' }}
              title="Handles"
            />
          </Grid.Column>
        </Grid.Row>
        {nobiliaFrontsDiscontinued?.length ||
        nobiliaHandlesDiscontinued?.length ? (
          <Grid.Row columns={2}>
            <Grid.Column>
              <DisconnectedOptionAccordion
                disconnectedOptions={nobiliaFrontsDiscontinued ?? []}
                featureIdentifier="nobiliaFrontDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) => {
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaFrontsDiscontinued ?? [],
                  )
                }}
                selectionCardProps={{ imageRatio: '2/3' }}
                title="Fronts (discontinued)"
              />
            </Grid.Column>
            <Grid.Column>
              <DisconnectedOptionAccordion
                disconnectedOptions={nobiliaHandlesDiscontinued ?? []}
                featureIdentifier="nobiliaHandleDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) => {
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaHandlesDiscontinued ?? [],
                  )
                }}
                selectionCardProps={{ imageRatio: '3/2' }}
                title="Handles (discontinued)"
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row columns={2}>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={design?.metadata?.catalog ?? ''}
              featureNo={NO.CARCASE_COLOR_EXTERIOR}
              nobiliaOptions={carcaseColorsExterior ?? []}
              onSave={(no) =>
                onSaveNobiliaOptions(no, carcaseColorsExterior ?? [])
              }
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Carcase Exterior"
            />
          </Grid.Column>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={design?.metadata?.catalog ?? ''}
              featureNo={NO.CARCASE_COLOR_INTERIOR}
              nobiliaOptions={carcaseColorsInterior ?? []}
              onSave={(no) =>
                onSaveNobiliaOptions(no, carcaseColorsInterior ?? [])
              }
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Carcase Interior"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={design?.metadata?.catalog ?? ''}
              featureNo={NO.PLINTH_COLOR}
              nobiliaOptions={plinthColors ?? []}
              onSave={(no) => onSaveNobiliaOptions(no, plinthColors ?? [])}
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Toe kicks"
            />
          </Grid.Column>
        </Grid.Row>
        {nobiliaCarcaseExteriorsDiscontinued?.length ||
        nobiliaPlinthColorsDiscontinued?.length ? (
          <Grid.Row columns={2}>
            <Grid.Column>
              <DisconnectedOptionAccordion
                disconnectedOptions={nobiliaCarcaseExteriorsDiscontinued ?? []}
                featureIdentifier="nobiliaCarcaseExteriorDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) =>
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaCarcaseExteriorsDiscontinued ?? [],
                  )
                }
                selectionCardProps={{ imageRatio: '2/3' }}
                title="Carcase exterior (discontinued)"
              />
              <DisconnectedOptionAccordion
                disconnectedOptions={nobiliaPlinthColorsDiscontinued ?? []}
                featureIdentifier="nobiliaPlinthColorDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) =>
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaPlinthColorsDiscontinued ?? [],
                  )
                }
                selectionCardProps={{ imageRatio: '2/3' }}
                title="Toe kick (discontinued)"
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            <CatalogFeatureAccordion
              catalogFeatures={catalogFeatures ?? []}
              onSave={(cf) => onSaveCatalogFeatures(cf, catalogFeatures ?? [])}
              title="Features"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h6
              style={{ cursor: 'pointer' }}
              onClick={() => toggleGlassOptions(!showGlassOptions)}
            >
              {showGlassOptions ? 'Hide' : 'Show'} glass door options{' '}
              <FontAwesomeIcon
                icon={['fal', showGlassOptions ? 'chevron-up' : 'chevron-down']}
                style={{ marginLeft: '10px' }}
              />
            </h6>
          </Grid.Column>
        </Grid.Row>
        {showGlassOptions ? (
          <>
            <Grid.Row columns={3}>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={design?.metadata?.catalog ?? ''}
                  featureNo={NO.GLASS_DOOR_FRAME_DESIGN}
                  gridColumns={2}
                  nobiliaOptions={glassFrameDesigns ?? []}
                  onSave={(no) =>
                    onSaveNobiliaOptions(no, glassFrameDesigns ?? [])
                  }
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Frame design"
                />
              </Grid.Column>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={design?.metadata?.catalog ?? ''}
                  featureNo={NO.GLASS_DOOR_FRAME_COLOR}
                  gridColumns={2}
                  nobiliaOptions={glassFrameColors ?? []}
                  onSave={(no) =>
                    onSaveNobiliaOptions(no, glassFrameColors ?? [])
                  }
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Frame color"
                />
              </Grid.Column>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={design?.metadata?.catalog ?? ''}
                  featureNo={NO.GLASS_COLOR}
                  gridColumns={2}
                  nobiliaOptions={glassColors ?? []}
                  onSave={(no) => onSaveNobiliaOptions(no, glassColors ?? [])}
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Glass color"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={design?.metadata?.catalog ?? ''}
                  featureNo={NO.GLASS_DESIGN}
                  gridColumns={2}
                  nobiliaOptions={glassDesigns ?? []}
                  onSave={(no) => onSaveNobiliaOptions(no, glassDesigns ?? [])}
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Glass design"
                />
              </Grid.Column>
            </Grid.Row>
          </>
        ) : null}
      </Grid>
    </StyledAccordion>
  )
}

export default Finishes
