import React, { useState } from 'react'

import { Header, Image, Table } from 'semantic-ui-react'

import RenderPropSelectionModal from 'components/admin/catalog/render-prop/selection-modal'
import { getReferenceImage } from 'context/media/utils-many'
import useRoom from 'context/room/use'
import useRoomGenericProductMutate from 'context/room/use-generic-product-mutate'
import Button from 'design-system/components/button'
import { GenericProductGraphQL } from 'types/generic-product'
import { responsiveText } from 'views/utils'

const AppliancesTable = ({
  changeRenderProp,
}: {
  changeRenderProp?: boolean
}) => {
  const [editProduct, setEditProduct] = useState<GenericProductGraphQL | null>(
    null,
  )
  const { appliancesUnarchived } = useRoom()
  const { updateGenericProductAndRefetch } = useRoomGenericProductMutate()

  const AppliancesGroups = [
    'Ovens',
    'Refrigerators',
    'Appliance sets',
    'Kitchen appliances',
    'Appliances',
    'Kitchen hoods',
    'Sinks & Faucets',
    'Cooktops & Ranges',
    'Dishwashers',
    'Freezers',
    'Microwaves',
  ]

  return (
    <Table className="small" celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>
            <p className="caption">Appliance type</p>
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>
            <p className="caption">Model / Maker</p>
          </Table.HeaderCell>
          <Table.HeaderCell width={5}>
            <p className="caption">Link to Appliance</p>
          </Table.HeaderCell>
          <Table.HeaderCell width={4}>
            <p className="caption">Render Model</p>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {appliancesUnarchived.map((product) => (
          <Table.Row key={product.id}>
            <Table.Cell>
              <Header as="h4" image>
                <Image
                  src={getReferenceImage(product.productClass.media)}
                  rounded
                  size="mini"
                  style={{ marginBottom: 0 }}
                />
                <Header.Content>
                  <p>
                    <b>{product.productClass.metadata?.name}</b>
                    {product.data?.size?.endsWith('"')
                      ? `, ${product.data?.size}`
                      : ''}
                  </p>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <p>{product.data?.productDetails}</p>
            </Table.Cell>
            <Table.Cell>
              <a href={product.data?.linkToProduct ?? ''} target="_blank">
                {responsiveText(product.data?.linkToProduct ?? '', 40)}
              </a>
            </Table.Cell>
            <Table.Cell>
              {product.renderProp ? (
                <Header as="h4" image>
                  <Image src={product.renderProp.imageUrl} size="mini" />
                  <Header.Content style={{ padding: '0 10px' }}>
                    <p className="no-margin">
                      {product.renderProp.data.formCategory ||
                        product.renderProp.data.category}
                    </p>
                    <Header.Subheader>
                      {product.renderProp.data.formBrand ||
                        product.renderProp.data.brand}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              ) : null}
              {changeRenderProp ? (
                product.renderProp ? (
                  <Button
                    kind="solid"
                    fontAwesomeIcon="exchange"
                    text="Change"
                    onClick={() => setEditProduct(product)}
                  />
                ) : (
                  <Button
                    kind="solid"
                    color="blue"
                    text="Add render model"
                    fontAwesomeIcon="plus"
                    onClick={() => setEditProduct(product)}
                  />
                )
              ) : null}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <RenderPropSelectionModal
        defaultFilters={{
          groupIdentifiers: AppliancesGroups,
          sortBy: 'groupIdentifier',
          sortDirection: 'ascending',
          take: 40,
        }}
        openModal={!!editProduct}
        selectedRenderProps={
          editProduct?.renderProp ? [editProduct.renderProp] : []
        }
        oneSelection
        onClose={() => setEditProduct(null)}
        onSave={async (rp) => {
          const [renderProp] = rp
          await updateGenericProductAndRefetch({
            variables: {
              where: {
                id: editProduct?.id ?? '',
              },
              data: {
                renderProp: {
                  ...(!renderProp
                    ? { disconnect: true }
                    : {
                        connect: { id: renderProp.id },
                      }),
                },
              },
            },
          })
        }}
      />
    </Table>
  )
}

export default AppliancesTable
