import { useContext } from 'react'

import { DeliveryManyContext } from './provider-many'

const useDeliveryMany = () => {
  const {
    deliveries,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(DeliveryManyContext)

  return {
    deliveries,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useDeliveryMany
