import React from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import useAuth from 'context/auth/use'
import useDesignMany from 'context/design/use-many'

import DesignsTable from './table'
import useDesignFiltersSearch, { FiltersVersion } from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const Designs = ({
  filtersType,
  table,
}: {
  filtersType?: FiltersVersion
  table?: React.ReactNode
}) => {
  const { isArtist } = useAuth()
  const { count, designs, fetchMore, loading, loadingMore } = useDesignMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useDesignFiltersSearch(filtersType)

  return (
    <Grid>
      {!isArtist ? (
        <>
          <FiltersAndSearch
            defaultAppliedFilters={defaultFilters}
            filters={filters}
            onApply={handleApplyFilters}
            handleSearchChange={handleSearch}
            overrideWithDefaultFilters
          />
        </>
      ) : null}
      <Grid.Row style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${designs?.length} of ${count}`} Designs
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table ?? <DesignsTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && designs?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default Designs
