import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_SUPPLIER_ORDER,
  CreateSupplierOrderPayload,
  CreateSupplierOrderVariables,
  DELETE_SUPPLIER_ORDER,
  DeleteSupplierOrderPayload,
  DeleteSupplierOrderVariables,
  UPDATE_SUPPLIER_ORDER,
  UpdateSupplierOrderPayload,
  UpdateSupplierOrderVariables,
} from 'queries/supplier-order'

import { ProjectSupplierOrderManyContext } from './provider-many'

const useProjectSupplierOrderManyMutate = () => {
  const { refetch } = useContext(ProjectSupplierOrderManyContext)

  const [createSupplierOrderAndRefetch, { loading: loadingCreate }] =
    useMutation<CreateSupplierOrderPayload, CreateSupplierOrderVariables>(
      CREATE_SUPPLIER_ORDER,
      {
        onCompleted: refetch,
      },
    )

  const [deleteSupplierOrderAndRefetch, { loading: loadingDelete }] =
    useMutation<DeleteSupplierOrderPayload, DeleteSupplierOrderVariables>(
      DELETE_SUPPLIER_ORDER,
      {
        onCompleted: refetch,
      },
    )

  const [updateSupplierOrderAndRefetch, { loading: loadingUpdate }] =
    useMutation<UpdateSupplierOrderPayload, UpdateSupplierOrderVariables>(
      UPDATE_SUPPLIER_ORDER,
      {
        onCompleted: refetch,
      },
    )

  return {
    createSupplierOrderAndRefetch,
    deleteSupplierOrderAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateSupplierOrderAndRefetch,
  }
}

export default useProjectSupplierOrderManyMutate
