import React from 'react'

import RouteProvider from 'context/route/provider'
import { Modal } from 'design-system/components/modal'
import { SupplierOrder } from 'types/supplier-order'

import RouteTab from './tab'

interface RouteModalProps {
  onClose?: () => void
  open?: boolean
  onComplete: () => void
  supplierOrdersInitial?: SupplierOrder[]
}

const RouteModal = ({
  open,
  onClose,
  onComplete,
  supplierOrdersInitial,
}: RouteModalProps) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      size="fullscreen"
      title="Route"
      customActions={null}
    >
      <RouteTab
        onComplete={async () => {
          onComplete?.()
          onClose?.()
        }}
        supplierOrdersInitial={supplierOrdersInitial ?? []}
      />
    </Modal>
  )
}

interface RouteModalIdProps {
  routeId: string
  onClose?: () => void
  open?: boolean
  refetch?: () => void
  supplierOrdersInitial?: SupplierOrder[]
}

const RouteModalId = ({
  open,
  onClose,
  refetch,
  routeId,
  supplierOrdersInitial,
}: RouteModalIdProps) => {
  return (
    <RouteProvider route_id={routeId} noLoader>
      <RouteModal
        onClose={onClose}
        open={open}
        onComplete={() => refetch?.()}
        supplierOrdersInitial={supplierOrdersInitial}
      />
    </RouteProvider>
  )
}

export default RouteModalId
