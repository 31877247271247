import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import SupplierOrders from 'components/admin/supplier-order/index-many'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import {
  FORMStatus,
  SupplierOrderType,
} from 'context/supplier-order/utils-many'

const Module = (
  props: RouteComponentProps<{ location: { search: string } }>,
) => {
  const defaultFilter = new URLSearchParams(props.location?.search)?.get(
    'defaultFilter',
  )

  const type = new URLSearchParams(props.location?.search)?.get('type')

  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title={'Supplier Orders'} />
        <Grid.Row>
          <Grid.Column>
            <SupplierOrderManyProvider
              defaultFilters={{
                ...(() => {
                  if (defaultFilter === 'EDINotSent')
                    return {
                      statusesFORM: [FORMStatus.EDINotSent],
                    }
                  if (defaultFilter === 'awaitingNobilia')
                    return {
                      statusesFORM: [FORMStatus.AwaitingNobilia],
                    }
                  if (defaultFilter === 'awaitingForm')
                    return {
                      statusesFORM: [FORMStatus.AwaitingFORM],
                    }
                  return null
                })(),
                sortBy: 'derivedStatus',
                sortDirection: 'ascending',
                take: 50,
                type:
                  type === 'issues'
                    ? [SupplierOrderType.Remedial, SupplierOrderType.AddOn]
                    : [SupplierOrderType.Regular],
              }}
              skipLoader
            >
              <SupplierOrders />
            </SupplierOrderManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
