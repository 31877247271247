import { gql } from '@apollo/client'

import { RenderProp } from 'types/render-prop'

export const GET_CURRENT_ARCHIVIZER_PROJECT = gql`
  query getCurrentArchivizerProject {
    currentProject: getCurrentArchivizerProject {
      id
      created_at
      name
      description
      estimate_cost
      created_date
      estimate_date
      status
      user_id
      member_ids
      file_attachment_ids
    }
  }
`

export interface GetCurrentArchivizerProjectPayload {
  currentProject: {
    id: number
    created_at: string
    name: string
    description: string
    estimate_cost: number
    created_date: string
    estimate_date: string
    status: string
    user_id: number
    member_ids: [number]
    file_attachment_ids: [number]
  }
}

export const GET_ARCHIVIZER_PRODUCTS = gql`
  query getArchivizerProducts($data: SearchArchivizerProductsInput) {
    renderProps: getArchivizerProducts(data: $data) {
      id
      identifier
      data
      description
      groupIdentifier
      imageUrl
    }
  }
`

export interface GetArchivizerProductsPayload {
  renderProps: RenderProp[]
}

export interface GetArchivizerProductsVariables {
  data: {
    name?: string
    brand?: string
    category?: string
  }
}
