import React, { useState } from 'react'

import { Table } from 'semantic-ui-react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import useAppliancePackMany from 'context/appliance-pack/use-many'
import useAppliancePackManyMutate from 'context/appliance-pack/use-many-mutate'
import useAuth from 'context/auth/use'
import Button from 'design-system/components/button/button'
import { AppliancePack } from 'types/appliance-pack'

import AppliancePackCreateUpdateModal from './create-update-modal'

const AppliancePacksTable = () => {
  const { isAdmin } = useAuth()
  const { appliancePacks, catalog } = useAppliancePackMany()
  const { deleteAppliancePackAndRefetch } = useAppliancePackManyMutate()
  const [appliancePackToDelete, setAppliancePackToDelete] =
    useState<AppliancePack>()
  const [appliancePackToEdit, setAppliancePackToEdit] =
    useState<AppliancePack>()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Identifier</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {appliancePacks.map((ap) => {
          return (
            <Table.Row key={ap.id}>
              <Table.Cell width={7}>{ap.data?.name}</Table.Cell>
              <Table.Cell width={7}>{ap.identifier}</Table.Cell>
              <Table.Cell textAlign="center" width={2}>
                <Button
                  kind="solid"
                  color="blue"
                  fontAwesomeIcon="arrow-up-right-from-square"
                  onClick={() =>
                    window.open(
                      `/admin/catalog/${catalog}/appliance-packs/${ap.id}`,
                    )
                  }
                  style={{ marginRight: 4 }}
                />
                <Button
                  kind="solid"
                  disabled={!isAdmin}
                  fontAwesomeIcon="pencil"
                  onClick={() => setAppliancePackToEdit(ap)}
                  style={{ marginRight: 4 }}
                />
                <Button
                  kind="solid"
                  color="gray"
                  disabled={!isAdmin}
                  fontAwesomeIcon="trash-alt"
                  onClick={() => setAppliancePackToDelete(ap)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <AppliancePackCreateUpdateModal
        appliancePack={appliancePackToEdit}
        open={!!appliancePackToEdit}
        onClose={() => setAppliancePackToEdit(undefined)}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to delete this pack?"
        onCancel={() => setAppliancePackToDelete(undefined)}
        onConfirm={async () => {
          await deleteAppliancePackAndRefetch({
            variables: {
              where: {
                id: appliancePackToDelete?.id ?? '',
              },
            },
          })
          setAppliancePackToDelete(undefined)
        }}
        open={!!appliancePackToDelete}
      />
    </Table>
  )
}

export default AppliancePacksTable
