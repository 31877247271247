import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_SHIPMENT,
  FindUniqueShipmentPayload,
  FindUniqueShipmentVariables,
} from 'queries/shipment'
import { Shipment } from 'types/shipment'

interface IShipmentContext {
  shipment?: Shipment
  refetch: () => Promise<unknown>
}

interface ShipmentProps
  extends RouteComponentProps<{
    shipment_id: string
    noLoader?: boolean
  }> {
  children: React.ReactNode
}

export const ShipmentContext = createContext<IShipmentContext>(
  {} as IShipmentContext,
)

const ShipmentProvider = (props: ShipmentProps) => {
  const { loading, data, refetch } = useQuery<
    FindUniqueShipmentPayload,
    FindUniqueShipmentVariables
  >(FIND_UNIQUE_SHIPMENT, {
    skip: !props.shipment_id,
    variables: {
      where: { id: props.shipment_id },
    },
  })

  if (loading) return props.noLoader ? null : <Loader />

  return (
    <ShipmentContext.Provider
      value={{
        shipment: data?.shipment,
        refetch,
      }}
    >
      {props.children}
    </ShipmentContext.Provider>
  )
}

export default ShipmentProvider
