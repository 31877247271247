import React from 'react'

import { RouteComponentProps } from '@reach/router'

import OrdersSamples from 'components/admin/order-samples/index-many'
import useTradeDesigner from 'context/dashboard/trade-designer/use'
import OrderSamplesManyProvider from 'context/order/provider-many-samples'
import { OrderType } from 'types/order'
import { ProjectStaffRole } from 'types/project-staff'

import TradeDesignerLayout from './layout'

const TradeDesignerSamplesOrders = (_: RouteComponentProps) => {
  const { userId, userName } = useTradeDesigner()

  return (
    <TradeDesignerLayout>
      <OrderSamplesManyProvider
        defaultFilters={{
          assignedTo: {
            id: userId,
            name: userName,
          },
          assignedToRole: ProjectStaffRole.TRADE_DESIGNER,
          includePending: true,
          sortBy: 'placedAt',
          sortDirection: 'descending',
          take: 30,
          type: OrderType.SAMPLES,
        }}
        skipLoader
      >
        <OrdersSamples />
      </OrderSamplesManyProvider>
    </TradeDesignerLayout>
  )
}

export default TradeDesignerSamplesOrders
