import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Card, Divider, Form, Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import { isAddressComplete } from 'components/shared/address/form/parser'
import { notifySuccess } from 'context/notifications/trigger'
import useProject from 'context/project/use'
import {
  UPDATE_PROJECT,
  UpdateProjectPayload,
  UpdateProjectVariables,
} from 'queries/project'
import { Address } from 'types/utils'

const Info = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project, refetch } = useProject()
  const [projectAddressState, setProjectAddressState] = useState<Address>(
    project.projectAddress || {
      addressCountry: '',
      addressLocality: '',
      addressRegion: '',
      apartmentNumber: '',
      postalCode: '',
      postOfficeBoxNumber: '',
      streetAddress: '',
    },
  )
  const [shippingAddressState, setShippingAddressState] = useState<Address>(
    project.shippingAddress || {
      addressCountry: '',
      addressLocality: '',
      addressRegion: '',
      apartmentNumber: '',
      postalCode: '',
      postOfficeBoxNumber: '',
      streetAddress: '',
      recipient: '',
    },
  )

  const [updateProject, { loading }] = useMutation<
    UpdateProjectPayload,
    UpdateProjectVariables
  >(UPDATE_PROJECT, {
    onCompleted: () => {
      refetch()
      notifySuccess('Address saved')
    },
  })

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Addresses" />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h5>Addresses</h5>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} centered>
          <Grid.Column>
            <p className="subtitle">Project Address - Where to ship kitchen</p>
            <Card fluid>
              <Card.Content>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Form.Input
                    label="Street Address"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setProjectAddressState({
                        ...projectAddressState,
                        streetAddress: e.target.value,
                      })
                    }}
                    placeholder="Street Address"
                    required
                    value={projectAddressState.streetAddress}
                  />
                  <Form.Input
                    label="Apt / Floor Number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setProjectAddressState({
                        ...projectAddressState,
                        apartmentNumber: e.target.value,
                      })
                    }}
                    placeholder="Apt / Floor Number"
                    value={projectAddressState.apartmentNumber}
                  />
                  <Form.Input
                    label="City"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setProjectAddressState({
                        ...projectAddressState,
                        addressLocality: e.target.value,
                      })
                    }}
                    placeholder="City"
                    required
                    value={projectAddressState.addressLocality}
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      label="State"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setProjectAddressState({
                          ...projectAddressState,
                          addressRegion: e.target.value,
                        })
                      }}
                      placeholder="State"
                      required
                      value={projectAddressState.addressRegion}
                    />
                    <Form.Input
                      label="Zip Code"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setProjectAddressState({
                          ...projectAddressState,
                          postalCode: e.target.value,
                        })
                      }}
                      placeholder="Zip Code"
                      required
                      value={projectAddressState.postalCode}
                    />
                  </Form.Group>
                </Form>
              </Card.Content>
              <Card.Content>
                <Button
                  content="Save"
                  disabled={!isAddressComplete(projectAddressState)}
                  floated="right"
                  loading={loading}
                  onClick={() => {
                    updateProject({
                      variables: {
                        data: {
                          projectAddress: projectAddressState,
                        },
                        where: {
                          id: project.id,
                        },
                      },
                    })
                  }}
                  primary
                />
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <p className="subtitle">Shipping Address - Where to ship samples</p>
            <Card fluid>
              <Card.Content>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Form.Input
                    label="Name of Recipient"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShippingAddressState({
                        ...shippingAddressState,
                        recipient: e.target.value,
                      })
                    }}
                    placeholder="Name of Recipient"
                    value={shippingAddressState.recipient}
                  />
                  <Form.Input
                    label="Street Address"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShippingAddressState({
                        ...shippingAddressState,
                        streetAddress: e.target.value,
                      })
                    }}
                    placeholder="Street Address"
                    required
                    value={shippingAddressState.streetAddress}
                  />
                  <Form.Input
                    label="Apt / Floor Number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShippingAddressState({
                        ...shippingAddressState,
                        apartmentNumber: e.target.value,
                      })
                    }}
                    placeholder="Apt / Floor Number"
                    value={shippingAddressState.apartmentNumber}
                  />
                  <Form.Input
                    label="City"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShippingAddressState({
                        ...shippingAddressState,
                        addressLocality: e.target.value,
                      })
                    }}
                    placeholder="City"
                    required
                    value={shippingAddressState.addressLocality}
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      label="State"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressState({
                          ...shippingAddressState,
                          addressRegion: e.target.value,
                        })
                      }}
                      placeholder="State"
                      required
                      value={shippingAddressState.addressRegion}
                    />
                    <Form.Input
                      label="Zip Code"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setShippingAddressState({
                          ...shippingAddressState,
                          postalCode: e.target.value,
                        })
                      }}
                      placeholder="Zip Code"
                      required
                      value={shippingAddressState.postalCode}
                    />
                  </Form.Group>
                </Form>
              </Card.Content>
              <Card.Content>
                <Button
                  content="Save"
                  floated="right"
                  disabled={!isAddressComplete(shippingAddressState)}
                  loading={loading}
                  onClick={() => {
                    updateProject({
                      variables: {
                        data: {
                          shippingAddress: shippingAddressState,
                        },
                        where: {
                          id: project.id,
                        },
                      },
                    })
                  }}
                  primary
                />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProjectLayout>
  )
}

export default Info
