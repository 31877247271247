import React, { useCallback, useEffect, useState } from 'react'

import { debounce } from 'lodash'
import { Form } from 'semantic-ui-react'

import useProject from 'context/project/use'
import useProjectUserMutate from 'context/project/use-project-user-mutate'
import useUserUnique from 'context/user/use-unique'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import {
  ProjectUser,
  ProjectUserRole,
  ProjectUserRoleHomeowner,
  ProjectUserRoleTrade,
} from 'types/project-user'
import * as viewUtils from 'views/utils'

interface ProjectUserModalProps {
  open: boolean
  onClose: () => void
  projectUser?: ProjectUser
}

const ProjectUserCreateUpdateModal = ({
  open,
  onClose,
  projectUser,
}: ProjectUserModalProps) => {
  const { project, allOwners, isTradeProject } = useProject()
  const {
    createProjectUserAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateProjectUserAndRefetch,
  } = useProjectUserMutate()
  const [search, setSearch] = useState('')
  const { userOptions } = useUserUnique({ search, otherUsers: allOwners })
  const initialState = projectUser || {
    isPrimary: false,
    isSubscribed: true,
    userRole: ProjectUserRoleHomeowner.HOMEOWNER as ProjectUserRole,
    userId: '',
    projectId: project.id,
  }
  const [state, setState] = useState<ProjectUser>(initialState)

  useEffect(() => {
    setState(initialState)
  }, [projectUser])

  const onSearchChange = (_: unknown, data: { searchQuery: string }) =>
    setSearch(data.searchQuery)
  const debouncedOnSearchChange = useCallback(debounce(onSearchChange, 300), [])

  const onSave = async () => {
    if (projectUser) {
      await updateProjectUserAndRefetch({
        variables: {
          data: {
            user: {
              connect: {
                id: state.userId ?? '',
              },
            },
            isPrimary: state.isPrimary,
            isSubscribed: state.isSubscribed,
            userRole: state.userRole,
          },
          where: {
            projectId_userId: {
              projectId: project.id,
              userId: projectUser.userId ?? '',
            },
          },
        },
      })
    } else {
      await createProjectUserAndRefetch({
        variables: {
          data: {
            project: {
              connect: {
                id: project.id ?? '',
              },
            },
            user: {
              connect: {
                id: state.userId ?? '',
              },
            },
            isPrimary: state.isPrimary,
            isSubscribed: state.isSubscribed,
            userRole: state.userRole,
          },
        },
      })
    }
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      onExited={() => setState(initialState)}
      open={open}
      size="small"
      title={projectUser ? 'Edit Owner Relationship' : 'Attach Owner'}
      saveButton={{
        disabled: !state.userId || !state.userRole,
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
    >
      <StyledForm>
        <Form.Dropdown
          fluid
          label="User (please enter full email)"
          options={userOptions}
          onChange={(_, { value }) =>
            setState({
              ...state,
              userId: value as string,
            })
          }
          onSearchChange={debouncedOnSearchChange}
          placeholder="Enter full user email here"
          search
          selection
          value={state.userId}
        />
        <Form.Dropdown
          fluid
          label="Role"
          options={viewUtils.getDropdownOptionsFromEnum(
            isTradeProject ? ProjectUserRoleTrade : ProjectUserRoleHomeowner,
          )}
          onChange={(_, { value }) =>
            setState({
              ...state,
              userRole: value as ProjectUserRole,
            })
          }
          placeholder="Role"
          selection
          value={state.userRole}
        />
        <FormCheckbox
          checked={state.isPrimary}
          label="Primary"
          onChange={() =>
            setState({
              ...state,
              isPrimary: !state.isPrimary,
            })
          }
        />
        <FormCheckbox
          checked={state.isSubscribed}
          label={'Subscribed'}
          onChange={() =>
            setState({
              ...state,
              isSubscribed: !state.isSubscribed,
            })
          }
        />
      </StyledForm>
    </Modal>
  )
}

export default ProjectUserCreateUpdateModal
