import React, { useMemo } from 'react'

import moment from 'moment'
import { Label } from 'semantic-ui-react'
import styled from 'styled-components'

import { isMeetingCanceledOrNoShow } from 'context/meeting/utils'
import useProject from 'context/project/use'
import { getNameForProspect } from 'context/prospect/utils'
import { getRoomTypeWithDescription } from 'context/room/utils'
import { getNameForUser } from 'views/utils'

import { getIssueText, getMeetingsText, getPaymentText } from './utils'

type UseFormattedProps = {
  selectedRoom: string | null | undefined
}

export const useFormattedDropdowns = ({ selectedRoom }: UseFormattedProps) => {
  const {
    allOwners,
    deliveries,
    designs,
    issues,
    meetingsAll,
    paymentsAll,
    prospects,
    roomsAll,
    supplierOrders,
  } = useProject()

  const formattedOwners = useMemo(
    () =>
      allOwners?.map((owner) => ({
        text: getNameForUser(owner),
        value: owner.id,
      })) ?? [],
    [allOwners],
  )

  const formattedSupplierOrders = useMemo(
    () =>
      supplierOrders
        ?.map((supplierOrder) => ({
          text: `${supplierOrder.nobiliaOrderRef} - ${
            supplierOrder.nobiliaAckNumber ?? 'No Ack Number'
          }`,
          value: supplierOrder.id,
        }))
        .reverse() ?? [],
    [supplierOrders],
  )

  const formattedDeliveries = useMemo(
    () =>
      deliveries
        ?.map((delivery) => ({
          text: `${delivery.data?.name ?? 'Name Unknown'} - ${moment(
            delivery.date,
          ).format('MM/DD/YYYY')}`,
          value: delivery.id,
        }))
        .reverse() ?? [],
    [deliveries],
  )

  const formattedProspects = useMemo(
    () =>
      prospects?.map((prospect) => ({
        text: getNameForProspect(prospect),
        value: prospect?.id,
      })) ?? [],
    [prospects],
  )

  const formattedPayments = useMemo(
    () =>
      paymentsAll
        ?.map((payment) => ({
          text: getPaymentText(payment),
          value: payment.id,
        }))
        .reverse() ?? [],
    [paymentsAll],
  )

  const formattedIssues = useMemo(
    () =>
      issues?.map((issue) => ({
        text: getIssueText(issue),
        value: issue.id,
      })) ?? [],
    [issues],
  )

  const formattedMeetings = useMemo(
    () =>
      meetingsAll
        ?.filter((meeting) => !isMeetingCanceledOrNoShow(meeting))
        .map((meeting) => ({
          text: getMeetingsText(meeting),
          value: meeting.id,
        })) ?? [],
    [meetingsAll],
  )

  const formattedRooms = useMemo(
    () =>
      roomsAll?.map((room) => ({
        text: getRoomTypeWithDescription(room),
        value: room.id,
        label: room.data?.tagColor && (
          <StyledLabel background={room.data?.tagColor} empty circular />
        ),
      })) ?? [],
    [roomsAll],
  )

  const formattedDesigns = useMemo(() => {
    if (!selectedRoom) return null
    return (
      designs
        ?.filter((design) => design.room.id === selectedRoom)
        .map((design) => {
          return {
            text: design.name,
            value: design.id,
          }
        })
        .reverse() ?? []
    )
  }, [designs, selectedRoom])

  return {
    formattedDeliveries,
    formattedDesigns,
    formattedIssues,
    formattedMeetings,
    formattedOwners,
    formattedPayments,
    formattedProspects,
    formattedRooms,
    formattedSupplierOrders,
  }
}

const StyledLabel = styled(Label)<{ background: string }>`
  &&& {
    background: ${(props) => props.background} !important;
  }
`
