import React, { useCallback, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { FileRejection, useDropzone } from 'react-dropzone'
import sanitize from 'sanitize-filename'
import styled, { css } from 'styled-components'

import { CardLoader } from 'components/shared/loader'
import { apolloClient } from 'context/authorized-apollo/provider'
import { convertHeicToJpeg } from 'context/project-file/utils'
import Button from 'design-system/components/button'
import {
  apiResultToAWSFormData,
  GET_TESTIMONIAL_FILE_UPLOAD_URL,
  GetTestimonialFileUploadPayload,
  GetTestimonialFileUploadVariables,
} from 'queries/aws'
import {
  CREATE_PROJECT_FILE,
  CreateProjectFilePayload,
  CreateProjectFileVariables,
} from 'queries/project-file'
import { Colors } from 'styles/app/system'
import { ProjectFile, ProjectFileType } from 'types/project-file'

interface UploadTestimonialFileCardProps {
  userId?: string
  disabled?: boolean
  error?: boolean
  fileType: ProjectFileType
  onFilesUploaded: (uploadedFiles: Partial<ProjectFile>[]) => void
}

export const UploadTestimonialFileCard = ({
  userId,
  disabled,
  error,
  fileType,
  onFilesUploaded,
}: UploadTestimonialFileCardProps) => {
  return (
    <UploadFileCard
      disabled={disabled}
      error={error}
      onFilesUploaded={onFilesUploaded}
      uploadFile={async (file) =>
        await uploadImage({
          userId,
          fileType,
          imgFile: file,
        })
      }
    />
  )
}

export const UploadFileCard = ({
  acceptFiles = '.jpg, .jpeg, .png, .heic, .tiff, .tif, .pdf, .webp, .ifc, .drw, .prw',
  disabled,
  error,
  onFilesUploaded,
  uploadFile,
}: {
  acceptFiles?: string
  disabled?: boolean
  error?: boolean
  onFilesUploaded?: (uploadedFiles: Partial<ProjectFile>[]) => void
  uploadFile: (file: File) => Promise<unknown>
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [fileRejection, setFileRejection] = useState<string>('')

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setFileRejection('')
      setLoading(true)

      if (fileRejections.length) {
        setFileRejection('Please only upload PDFs or images')
      }

      const uploadImagePromises = []
      for (const file of acceptedFiles) {
        if (file.size > 0) uploadImagePromises.push(uploadFile(file))
        else setFileRejection('Invalid file size. Please try another file')
      }
      const results = (await Promise.all(uploadImagePromises)) as ProjectFile[]
      setLoading(false)
      onFilesUploaded?.(results)
    },
    [],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFiles,
    disabled: disabled || loading,
  })

  return (
    <>
      <ProjectFileCard
        {...getRootProps()}
        className={error ? 'error' : ''}
        styled={{
          disabled: disabled || loading,
          isDragActive: isDragActive,
        }}
      >
        {loading && (
          <CardLoader active={loading}>
            <p>Uploading</p>
          </CardLoader>
        )}
        <div className="content">
          <FontAwesomeIcon icon={['fal', 'image-polaroid']} />
          <div className="only-computer">
            <p>Drag a file here or</p>
          </div>
          <div className="down-computer">
            <p>Add file here</p>
          </div>
          <Button
            disabled={loading || disabled || isDragActive}
            color="blue"
            kind="solid"
            size="fluid"
            text="Select files"
          />
        </div>
        <input
          disabled={loading}
          hidden
          multiple
          type="file"
          {...getInputProps()}
        />
      </ProjectFileCard>
      {fileRejection && (
        <div style={{ marginTop: 15 }}>
          <p>
            <i className="red">{fileRejection}</i>
          </p>
        </div>
      )}
    </>
  )
}

export const uploadImage = async ({
  userId,
  fileType,
  imgFile,
}: {
  userId?: string
  fileType: ProjectFileType
  imgFile: File
}): Promise<ProjectFile> => {
  let file = imgFile
  if (
    file.type === 'image/heic' ||
    file.name.toLocaleLowerCase().endsWith('heic')
  )
    file = await convertHeicToJpeg(imgFile)
  const fileRemovedSpecialCharacters = file.name.replace(
    /[^a-zA-Z0-9.\-_ ]/g,
    '',
  )
  const fileNameSanitized = sanitize(fileRemovedSpecialCharacters)

  let url = ''
  let dataFields: Record<string, unknown> = {}

  const res = await apolloClient?.mutate<
    GetTestimonialFileUploadPayload,
    GetTestimonialFileUploadVariables
  >({
    mutation: GET_TESTIMONIAL_FILE_UPLOAD_URL,
    variables: { data: { fname: fileNameSanitized, userId: userId ?? '' } },
  })
  url = res?.data?.uploadUrlTestimonialFile.url ?? ''
  dataFields = apiResultToAWSFormData(res?.data?.uploadUrlTestimonialFile ?? {})

  const data = new FormData()
  Object.keys(dataFields).forEach((k) => {
    data.append(k, dataFields[k] as string | Blob)
  })
  data.append('acl', 'public-read')
  data.append('Content-Type', file.type)
  data.append('file', file)

  const uploadRes = await axios.post(url, data)

  if (uploadRes.status === 204) {
    const res = await apolloClient?.mutate<
      CreateProjectFilePayload,
      CreateProjectFileVariables
    >({
      mutation: CREATE_PROJECT_FILE,
      variables: {
        data: {
          user: userId ? { connect: { id: userId } } : undefined,
          key: dataFields.key as string,
          name: fileNameSanitized,
          type: fileType,
          url,
        },
      },
    })
    return res?.data?.createOneProjectFile as ProjectFile
  }
  throw new Error(
    `[${uploadRes.status}] Upload image failed: ${uploadRes.statusText}`,
  )
}

export const ProjectFileCard = styled.div<{
  styled?: {
    disabled?: boolean
    isDragActive?: boolean
  }
}>`
  border: 1px dashed ${Colors.gray300};
  border-radius: 1px;
  padding: 36px 18px 18px;

  ${({ styled }) =>
    styled?.disabled &&
    css`
      cursor: default;
    `}

  ${({ styled }) =>
    styled?.isDragActive &&
    css`
      border: 1px dashed ${Colors.gray500};
    `}

  div.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-around;
    opacity: ${({ styled }) => (styled?.disabled ? 0.3 : 1)};
    text-align: center;
  }

  svg {
    font-size: 19px;
    color: ${Colors.gray600};
  }

  .error {
    border-color: ${Colors.red700};
  }

  :hover {
    button {
      background-color: ${Colors.blue300};
      transition-duration: 0.3s;
    }
  }
`
