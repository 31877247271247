// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import styled from 'styled-components'

import { StyledButton, StyledPopup } from 'styles/planner/main'

export interface ControlSetProps {
  buttonsGroup: {
    active: boolean
    disabled?: boolean
    onClick: () => void
    icon: any
    key: string
  }[]
  buttonsGroupTwo: {
    active: boolean
    onClick: () => void
    icon: React.ReactElement
    key: string
  }[]
}

const ControlSet = (props: ControlSetProps) => {
  return (
    <Container>
      <ButtonGroup>
        {props.buttonsGroup.map((b) => (
          <StyledPopup
            key={b.key}
            basic
            content={<p>{b.key}</p>}
            on="hover"
            position="bottom center"
            trigger={
              <span style={{ height: 32, width: 32 }}>
                <StyledButton
                  active={b.active}
                  disabled={!!b.disabled}
                  onClick={b.onClick}
                  style={{ margin: 0 }}
                  icon={b.icon}
                />
              </span>
            }
          />
        ))}
      </ButtonGroup>

      <ButtonGroup>
        {props.buttonsGroupTwo.map((b) => (
          <StyledPopup
            key={b.key}
            basic
            content={<p>{b.key}</p>}
            on="hover"
            position="bottom center"
            trigger={
              <span style={{ height: 32, width: 32 }}>
                <StyledButton
                  active={b.active}
                  onClick={b.onClick}
                  icon={b.icon}
                  orange="true"
                  style={{ margin: 0 }}
                />
              </span>
            }
          />
        ))}
      </ButtonGroup>
    </Container>
  )
}

export default ControlSet

const Container = styled.div`
  align-items: center;
  display: flex;
`

const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 50%;
`
