import React from 'react'

import { RouteComponentProps } from '@reach/router'

import TasksDashboard from 'components/admin/task/dashboard'
import useTradeDesigner from 'context/dashboard/trade-designer/use'

import TradeDesignerLayout from './layout'

const TradeDesignerTasks = (_: RouteComponentProps) => {
  const { tasksToDo, tasksUrgent, tasksCompleted, refetchTasks } =
    useTradeDesigner()
  return (
    <TradeDesignerLayout>
      <TasksDashboard
        refetch={refetchTasks}
        tasksToDo={tasksToDo}
        tasksUrgent={tasksUrgent}
        tasksCompleted={tasksCompleted}
      />
    </TradeDesignerLayout>
  )
}

export default TradeDesignerTasks
