import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'
import { RoomElementOption } from 'types/room-element-option'

import RoomElementOptionModal from './metadata-modal'

const RoomElementOptionMetadata = ({
  option,
  refetch,
}: {
  option: RoomElementOption
  refetch?: () => Promise<unknown>
}) => {
  return (
    <Metadata>
      <Grid.Row>
        <Grid.Column width={8}>
          <h6 className="no-margin">Metadata</h6>
        </Grid.Column>
        <Grid.Column width={8} textAlign="right">
          <RoomElementOptionModal
            roomElementOption={option}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column className="title" floated="left" width={7}>
          Status
        </Grid.Column>
        <Grid.Column width={9}>
          {option.data?.isDiscontinued ? 'Discontinued' : 'Available'}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column floated="left" style={{ color: Colors.gray600 }} width={7}>
          Display Name
        </Grid.Column>
        <Grid.Column width={9}>
          {option.data?.displayName || option.description}
        </Grid.Column>
      </Grid.Row>
    </Metadata>
  )
}

export default RoomElementOptionMetadata

const Metadata = styled(Grid)`
  background-color: ${Colors.gray50};
  padding: 10px !important;
  div.divider {
    margin: 3px 14px 14px !important;
    padding: 0px !important;
  }
  div.row {
    padding: 10px 0 !important;
  }

  div.column.title {
    color: ${Colors.gray600};
  }
`
