import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEqual } from 'lodash'
import moment from 'moment'
import { Button as ButtonSemantic, Form, Label, Table } from 'semantic-ui-react'

import { BottomSection } from 'components/shared/project/project-file/utils'
import useRoom from 'context/room/use'
import useRoomDimensionMutate from 'context/room/use-dimension-mutate'
import { sortAndReindexDimensions as sortAndReindex } from 'context/room/utils'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import { Dimension, DimensionType } from 'types/dimension'

import ConfirmDelete from './confirm-delete'

interface SidebarMeasureProps {
  sidebarToggle: () => void
}

const SidebarMeasure = ({ sidebarToggle }: SidebarMeasureProps) => {
  const { measurements = [] } = useRoom()
  const { deleteDimension, loadingDimensionsSave, saveDimensions } =
    useRoomDimensionMutate()
  const [state, setState] =
    useState<NullableRecord<Dimension, 'data'>[]>(measurements)

  useEffect(() => {
    if (!isEqual(state, measurements)) setState(measurements)
  }, [measurements])

  const handleAdd = () => {
    setState([
      ...state,
      {
        data: {
          confirmedAt: null,
          index: state.length,
          lastAcknowledgedAt: null,
          lastUpdatedByCustomerAt: null,
          lengthInInches: 0,
          name: '',
          type: DimensionType.MEASUREMENT,
        },
      },
    ])
    setTimeout(() => {
      document.getElementById('lastInput') &&
        document.getElementById('lastInput')?.focus()
    }, 400)
  }

  return (
    <div style={{ minWidth: '50vw', paddingBottom: '70px' }}>
      <span
        className="down-computer"
        onClick={sidebarToggle}
        style={{
          fontSize: '18px',
          padding: '16px',
          position: 'absolute',
          right: '16px',
          top: '8px',
          zIndex: 1,
        }}
      >
        <FontAwesomeIcon icon={['fal', 'times']} />
      </span>
      <h4>{'Measurements'}</h4>
      <Label color="red">Changed by Customer</Label>
      <Label color="green">Confirmed by Customer</Label>
      <br />
      <br />
      <p>
        If a change was made by the customer, you must mark the{' '}
        <i>Ack(nowledge) change</i> checkbox, in order to enable the customer’s
        confirmation.
      </p>
      {state && (
        <Table celled className="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Reorder</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Length in inches</Table.HeaderCell>
              <Table.HeaderCell>Change requested</Table.HeaderCell>
              <Table.HeaderCell>Ack change</Table.HeaderCell>
              <Table.HeaderCell>Customer confirm.</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.map((item, key: number) => {
              const { data } = item
              return (
                <Table.Row
                  key={key}
                  style={{
                    background:
                      (data?.lastAcknowledgedAt &&
                        moment(data.lastUpdatedByCustomerAt).isAfter(
                          moment(data.lastAcknowledgedAt),
                        )) ||
                      (!data?.lastAcknowledgedAt &&
                        data?.lastUpdatedByCustomerAt)
                        ? '#FFD8D8'
                        : data.confirmedAt
                        ? '#E4FDD5'
                        : undefined,
                  }}
                >
                  <Table.Cell collapsing textAlign="center">
                    <ButtonSemantic.Group vertical>
                      <Button
                        kind="solid"
                        color="gray"
                        disabled={key === 0}
                        fontAwesomeIcon="angle-up"
                        onClick={() => {
                          setState(
                            sortAndReindex(
                              state.map((d, index) => {
                                if (index === key) {
                                  return {
                                    ...d,
                                    data: {
                                      ...d.data,
                                      index: isNaN(Number(d.data.index) - 1)
                                        ? index
                                        : Number(d.data?.index) - 1,
                                    },
                                  }
                                }
                                if (index === key - 1) {
                                  return {
                                    ...d,
                                    data: {
                                      ...d.data,
                                      index: isNaN(Number(d.data.index) + 1)
                                        ? index - 1
                                        : Number(d.data.index) + 1,
                                    },
                                  }
                                }
                                return d
                              }) as Dimension[],
                            ),
                          )
                        }}
                      />
                      <Button
                        kind="solid"
                        color="gray"
                        disabled={key === state.length - 1}
                        fontAwesomeIcon="angle-down"
                        onClick={() => {
                          setState(
                            sortAndReindex(
                              state.map((d, index) => {
                                if (index === key) {
                                  return {
                                    ...d,
                                    data: {
                                      ...d.data,
                                      index: isNaN(Number(d.data.index) + 1)
                                        ? index
                                        : Number(d.data.index) + 1,
                                    },
                                  }
                                }
                                if (index === key + 1) {
                                  return {
                                    ...d,
                                    data: {
                                      ...d.data,
                                      index: isNaN(Number(d.data.index) - 1)
                                        ? index + 1
                                        : Number(d.data.index) - 1,
                                    },
                                  }
                                }
                                return d
                              }) as Dimension[],
                            ),
                          )
                        }}
                      />
                    </ButtonSemantic.Group>
                  </Table.Cell>
                  <Table.Cell width={7}>
                    <Form.Input
                      {...(key === state.length - 1 && { id: 'lastInput' })}
                      fluid
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setState(
                          state.map((d, index) => {
                            if (key === index)
                              return {
                                ...d,
                                data: {
                                  ...d.data,
                                  confirmedAt: null,
                                  lastAcknowledgedAt: null,
                                  lastUpdatedByCustomerAt: null,
                                  name: e.target.value,
                                },
                              }
                            return d
                          }),
                        )
                      }}
                      placeholder="Name"
                      value={data.name}
                    />
                  </Table.Cell>
                  <Table.Cell width={4} textAlign="center">
                    <Form.Input
                      fluid
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value
                          ? parseFloat(e.target.value)
                          : null
                        setState(
                          state.map((d, index) => {
                            if (key === index) {
                              return {
                                ...d,
                                data: {
                                  ...d.data,
                                  confirmedAt: null,
                                  lastAcknowledgedAt: null,
                                  lastUpdatedByCustomerAt: null,
                                  lengthInInches: value,
                                },
                              }
                            }
                            return d
                          }),
                        )
                      }}
                      placeholder={`${data.name} Length In Inches`}
                      type="number"
                      value={data.lengthInInches || ''}
                    />
                    {data.lengthInInches ? (
                      <p className="gray small">
                        {(data.lengthInInches * 25.4).toFixed(2)} mm
                      </p>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Form.Checkbox
                      checked={!!data.lastUpdatedByCustomerAt}
                      onChange={() => {
                        setState(
                          state.map((d, index) => {
                            if (key === index)
                              return {
                                ...d,
                                data: {
                                  ...d.data,
                                  lastUpdatedByCustomerAt:
                                    !data.lastUpdatedByCustomerAt
                                      ? new Date()
                                      : null,
                                },
                              }
                            return d
                          }),
                        )
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Form.Checkbox
                      checked={!!data.lastAcknowledgedAt}
                      onChange={() => {
                        setState(
                          state.map((d, index) => {
                            if (key === index)
                              return {
                                ...d,
                                data: {
                                  ...d.data,
                                  lastAcknowledgedAt: !data.lastAcknowledgedAt
                                    ? new Date()
                                    : null,
                                },
                              }
                            return d
                          }),
                        )
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center" width={1}>
                    <Form.Checkbox
                      checked={!!data.confirmedAt}
                      onChange={() => {
                        setState(
                          state.map((d, index) => {
                            if (key === index)
                              return {
                                ...d,
                                data: {
                                  ...d.data,
                                  confirmedAt: !data.confirmedAt
                                    ? new Date()
                                    : null,
                                },
                              }
                            return d
                          }),
                        )
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <ConfirmDelete
                      onDelete={() => {
                        setState(
                          sortAndReindex(
                            state.filter(
                              (_, idx) => idx !== key,
                            ) as Dimension[],
                          ),
                        )
                        if (item.id) deleteDimension(item as Dimension)
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      )}
      <BottomSection>
        <div
          style={{
            background: Colors.gray50,
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '16px',
          }}
        >
          <Button
            kind="solid"
            color="blue"
            text="Add Dimension"
            fontAwesomeIcon="plus"
            onClick={handleAdd}
            style={{ marginRight: '20px' }}
          />
          <Button
            kind="solid"
            text="Save"
            disabled={
              state.filter((m) => !m.data.lengthInInches).length > 0 ||
              !state.length ||
              loadingDimensionsSave ||
              JSON.stringify(measurements) === JSON.stringify(state)
            }
            loading={loadingDimensionsSave}
            onClick={() => saveDimensions(state as Dimension[])}
          />
        </div>
      </BottomSection>
    </div>
  )
}

export default SidebarMeasure
