import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Divider, Grid, Table } from 'semantic-ui-react'

import CreateRemedialOrderModal from 'components/admin/delivery/create-remedial-order-modal'
import DeliveryModal from 'components/admin/delivery/create-update-modal'
import SendOrderModal from 'components/admin/design/supplier-order/send-order-modal'
import AttachSupplierOrderModal from 'components/admin/issue/attach-supplier-order'
import IssueStatusCell from 'components/admin/issue/issue-status-cell'
import {
  IssueDescriptionCell,
  IssueRelatedImgsCell,
  IssueResolutionCell,
  IssueTypeSubtypeCell,
} from 'components/admin/issue/utils-table'
import { ProjectPageTitle } from 'components/admin/page-title'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useProjectIssueMany from 'context/project/issue/use-many'
import useProjectIssueManyMutate from 'context/project/issue/use-many-mutate'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import { ButtonTooltip } from 'design-system/components/button/button-tooltip'
import { TooltipProps } from 'design-system/components/tooltip/tooltip'
import { Colors } from 'styles/app/system'
import { Delivery } from 'types/delivery'
import { Issue, ResolutionType } from 'types/issue'

const Issues = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project } = useProject()
  const { issues, refetch, queryFilters, setQueryFilters } =
    useProjectIssueMany()
  const { deleteIssueAndRefetch } = useProjectIssueManyMutate()

  const [deliveryToEdit, setDeliveryToEdit] = useState<Delivery>()
  const [issueToDelete, setIssueToDelete] = useState<Issue>()
  const [issueToSendOrder, setIssueToSendOrder] = useState<Issue>()
  const [issueToSendRemedial, setIssueToSendRemedial] = useState<Issue>()
  const [issueToAttach, setIssueToAttach] = useState<Issue>()

  const COMMON_TOOLTIP_PROPS: Omit<TooltipProps, 'children' | 'label'> = {
    color: Colors.blue700,
  }

  return (
    <>
      <ProjectPageTitle viewName="Issues" />
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <h5>Issues</h5>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              kind="solid"
              text="Create New Issue"
              fontAwesomeIcon="plus"
              onClick={() =>
                navigate(`/admin/projects/${project.id}/issues/create`)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <Table celled sortable fixed className="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Type / Sub-type</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Resolution</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Description</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Related image</Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    onClick={() =>
                      setQueryFilters({
                        ...queryFilters,
                        sortDirection:
                          queryFilters.sortDirection === 'ascending'
                            ? 'descending'
                            : 'ascending',
                      })
                    }
                    sorted={queryFilters.sortDirection}
                  >
                    Status / Order
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {issues.map((i) => (
                  <Table.Row key={i.id}>
                    <IssueTypeSubtypeCell issue={i} />
                    <IssueResolutionCell issue={i} />
                    <IssueDescriptionCell issue={i} />
                    <IssueRelatedImgsCell issue={i} />
                    <IssueStatusCell
                      issue={i}
                      toggleOrderModal={() =>
                        i.resolutionType === ResolutionType.REMEDIAL
                          ? setIssueToSendRemedial(i)
                          : setIssueToSendOrder(i)
                      }
                      onAttachOrder={() => setIssueToAttach(i)}
                    />
                    <Table.Cell width={1}>
                      <div
                        style={{
                          display: 'grid',
                          rowGap: '12px',
                          justifyContent: 'center',
                        }}
                      >
                        <ButtonTooltip
                          kind="solid"
                          fontAwesomeIcon="edit"
                          onClick={() =>
                            navigate(
                              `/admin/projects/${project.id}/issues/${i.id}`,
                            )
                          }
                          tooltipProps={{
                            label: 'Edit issue',
                            ...COMMON_TOOLTIP_PROPS,
                          }}
                        />
                        {!!i.delivery?.id && (
                          <ButtonTooltip
                            color="blue"
                            fontAwesomeIcon="truck-ramp-box"
                            kind="solid"
                            onClick={() => setDeliveryToEdit(i.delivery)}
                            tooltipProps={{
                              label: 'Go to delivery',
                              ...COMMON_TOOLTIP_PROPS,
                            }}
                          />
                        )}
                        <ButtonTooltip
                          color="blue"
                          kind="solid"
                          fontAwesomeIcon="trash-alt"
                          onClick={() => setIssueToDelete(i)}
                          tooltipProps={{
                            label: 'Delete issue',
                            ...COMMON_TOOLTIP_PROPS,
                          }}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <DeliveryModal
              deliveryId={deliveryToEdit?.id ?? ''}
              open={!!deliveryToEdit}
              refetch={refetch}
              onClose={() => setDeliveryToEdit(undefined)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmationModal
        firstTitle="Are you sure you want to delete the issue?"
        onCancel={() => setIssueToDelete(undefined)}
        onConfirm={async () => {
          await deleteIssueAndRefetch({
            variables: {
              where: {
                id: issueToDelete?.id ?? '',
              },
            },
          })
          refetch()
          setIssueToDelete(undefined)
        }}
        open={!!issueToDelete}
      />
      {issueToSendOrder?.designResolving ? (
        <SendOrderModal
          design={issueToSendOrder?.designResolving}
          onClose={() => setIssueToSendOrder(undefined)}
          open={!!issueToSendOrder}
          project={issueToSendOrder?.project}
          refetch={refetch}
        />
      ) : null}
      {issueToSendRemedial?.delivery && issueToSendRemedial ? (
        <CreateRemedialOrderModal
          delivery={issueToSendRemedial?.delivery}
          issueSelected={issueToSendRemedial}
          open={!!issueToSendRemedial}
          onClose={() => setIssueToSendRemedial(undefined)}
          refetch={refetch}
        />
      ) : null}
      {issueToAttach?.id ? (
        <AttachSupplierOrderModal
          project={project}
          issueId={issueToAttach?.id}
          open={!!issueToAttach}
          onClose={() => setIssueToAttach(undefined)}
          refetch={refetch}
        />
      ) : null}
    </>
  )
}

export default Issues
