import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { getQueryVariables } from 'context/room/utils-many'
import {
  FIND_MANY_ROOM_COUNT,
  FindManyRoomCountPayload,
  FindManyRoomVariables,
} from 'queries/room'
import { ProjectStaffRole } from 'types/project-staff'

const useRoomsAwaitingPackCount = (
  userId?: string,
  projectStaffRole?: ProjectStaffRole,
) => {
  const variables = useMemo(
    () =>
      getQueryVariables({
        assignedTo: { id: userId ?? '' },
        assignedToRole: projectStaffRole,
        packStatus: 'awaitingPack',
        take: undefined,
      }),
    [userId, projectStaffRole],
  )

  const { data: { count: roomsAwaitingPackCount } = { count: 0 }, loading } =
    useQuery<FindManyRoomCountPayload, FindManyRoomVariables>(
      FIND_MANY_ROOM_COUNT,
      {
        variables,
      },
    )

  return {
    roomsAwaitingPackCount,
    loading,
  }
}

export default useRoomsAwaitingPackCount
