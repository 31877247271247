import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import { Media, MediaCategory } from 'types/media'

import MediaCard from './card'

const Photography = ({
  media,
  refetch,
}: {
  media: Media[]
  refetch: () => Promise<unknown>
}) => {
  return (
    <>
      <Divider style={{ width: '100%' }} />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h3>Product Photography</h3>
          </Grid.Column>
        </Grid.Row>
        {media.filter(
          (m) =>
            m.category !== MediaCategory.asset_3D &&
            m.category !== MediaCategory.asset_2D_plan &&
            m.category !== MediaCategory.texture_high &&
            m.category !== MediaCategory.texture_low &&
            m.category !== MediaCategory.texture_mid &&
            m.category !== MediaCategory.reference &&
            m.category !== MediaCategory.installation,
        ).length == 0 && (
          <Grid.Row>
            <Grid.Column>No photography found</Grid.Column>
          </Grid.Row>
        )}
        {media.filter(
          (m) =>
            m.category !== MediaCategory.asset_3D &&
            m.category !== MediaCategory.asset_2D_plan &&
            m.category !== MediaCategory.texture_high &&
            m.category !== MediaCategory.texture_low &&
            m.category !== MediaCategory.texture_mid &&
            m.category !== MediaCategory.reference &&
            m.category !== MediaCategory.installation,
        ).length > 0 && (
          <Grid.Row columns={3}>
            {media
              .filter(
                (m) =>
                  m.category !== MediaCategory.asset_3D &&
                  m.category !== MediaCategory.asset_2D_plan &&
                  m.category !== MediaCategory.texture_high &&
                  m.category !== MediaCategory.texture_low &&
                  m.category !== MediaCategory.texture_mid &&
                  m.category !== MediaCategory.reference &&
                  m.category !== MediaCategory.installation,
              )
              .map((m) => (
                <Grid.Column key={m.id} style={{ marginBottom: '70px' }}>
                  <MediaCard enableEdit={true} media={m} refetch={refetch} />
                  <p style={{ marginTop: '12px' }}>{m.name}</p>
                </Grid.Column>
              ))}
          </Grid.Row>
        )}
      </Grid>
    </>
  )
}

export default Photography
