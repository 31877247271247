import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'

const DashboardRedirect = (_: RouteComponentProps) => {
  const { formUserId, isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) return

    navigate(`/admin/dashboard/${formUserId}`, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <Loader />
}

export default DashboardRedirect
