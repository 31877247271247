import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { ApolloQueryResult } from '@apollo/client'

import useInfiniteQuery from 'hooks/use-infinite-query'
import {
  FIND_MANY_COMMENT_NOTE,
  FindManyCommentNotePayload,
  FindManyCommentNoteVariables,
} from 'queries/comment-notes'
import { CommentNote } from 'types/comment-note'
import { groupItemsByDate } from 'utils'

import useCommentNoteCount from './use-count'
import {
  QueryFilters,
  getQueryVariables,
  getCommentNotesWithTopic,
} from './utils-many'

type FetchMore = () => Promise<ApolloQueryResult<FindManyCommentNotePayload>>

interface ICommentNoteManyContext {
  commentNotes: CommentNote[]
  commentNotesGroupedByDate: [string, CommentNote[]][]
  count: number
  fetchMore: FetchMore
  fetchedCount: number
  newForMeCount: number
  loading: boolean
  loadingMore: boolean
  loadingFirst: boolean
  refetch: () => Promise<ApolloQueryResult<FindManyCommentNotePayload>>
  refetchNewForMeCount: () => Promise<
    ApolloQueryResult<FindManyCommentNotePayload>
  >
  refetchAll: () => Promise<
    [
      ApolloQueryResult<FindManyCommentNotePayload>,
      ApolloQueryResult<FindManyCommentNotePayload>,
    ]
  >
  queryFilters: QueryFilters
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

export type CommentNotesManyProviderProps = {
  defaultFilters: QueryFilters
  formUserId: string
}

export const CommentNoteManyContext = createContext<ICommentNoteManyContext>(
  {} as ICommentNoteManyContext,
)

const CommentNoteManyProvider = ({
  children,
  defaultFilters,
  formUserId,
}: React.PropsWithChildren<CommentNotesManyProviderProps>) => {
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(defaultFilters)
  const [queryVariables, setQueryVariables] =
    useState<FindManyCommentNoteVariables>(getQueryVariables(queryFilters))
  const [loadingFirst, setLoadingFirst] = useState(true)

  const { data, loading, loadingMore, fetchedCount, fetchMore, refetch } =
    useInfiniteQuery<FindManyCommentNotePayload, FindManyCommentNoteVariables>(
      FIND_MANY_COMMENT_NOTE,
      {
        cacheFieldName: 'findManyComment',
        fetchedPropertyName: 'commentNotes',
        refetchBehavior: 'keepSkip',
        queryOptions: {
          variables: queryVariables,
        },
      },
    )

  const { count: newForMeCount, refetch: refetchNewForMeCount } =
    useCommentNoteCount({
      projectId: defaultFilters.projectId,
      newFor: {
        id: formUserId,
      },
    })

  const commentsWithTopic = useMemo(
    () => getCommentNotesWithTopic(data?.commentNotes ?? []),
    [data?.commentNotes],
  )

  const commentNotesGroupedByDate = useMemo(
    () => groupItemsByDate(commentsWithTopic, { groupOnlyByCreatedAt: true }),
    [commentsWithTopic],
  )

  useEffect(() => {
    setQueryFilters(defaultFilters)
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  useEffect(() => {
    loadingFirst && !loading && setLoadingFirst(false)
  }, [loading])

  const refetchAll = async () =>
    Promise.all([refetchNewForMeCount(), refetch()])

  return (
    <CommentNoteManyContext.Provider
      value={{
        commentNotes: commentsWithTopic,
        commentNotesGroupedByDate,
        count: data?.count || 0,
        fetchedCount,
        fetchMore: fetchMore as FetchMore,
        loading,
        loadingMore,
        loadingFirst,
        newForMeCount,
        queryFilters,
        refetch,
        refetchNewForMeCount,
        refetchAll,
        setQueryFilters,
      }}
    >
      {children}
    </CommentNoteManyContext.Provider>
  )
}

export const useCommentNoteMany = () => useContext(CommentNoteManyContext)

export default CommentNoteManyProvider
