import React, { useState } from 'react'

import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import SelectFileCard from 'components/admin/project-file/select-file-card'
import useProjectFileMutateNoContext from 'context/project-file/use-mutate-no-context'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { ProjectFile, ProjectFileType } from 'types/project-file'
import { capsSnakeCaseToTitleCase } from 'views/utils'

type Props = {
  files: ProjectFile[]
  onClose: () => void
  open: boolean
  refetch: () => Promise<unknown>
  title: string
  typeFrom: ProjectFileType
  typeTo: ProjectFileType
}

const CategorizationModalMultiple = ({
  files,
  onClose,
  open,
  refetch,
  title,
  typeFrom,
  typeTo,
}: Props) => {
  const [selected, setSelected] = useState<string[]>([])
  const { updateProjectFile, loadingUpdate } = useProjectFileMutateNoContext()

  const handleClose = () => {
    onClose()
  }

  const handleSave = async () => {
    await Promise.all(
      selected.map((s) =>
        updateProjectFile({
          variables: {
            data: {
              type: typeTo,
            },
            where: {
              id: s,
            },
          },
        }),
      ),
    )
    refetch()
    onClose()
  }

  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClose}
      disableEscape
      size="large"
      unmountOnExit
      customActions={
        <ActionsContainer>
          <Button
            kind="solid"
            color="blue"
            text="Cancel"
            onClick={handleClose}
          />
          <Button
            kind="solid"
            text="Recategorize"
            onClick={handleSave}
            disabled={!selected.length}
            loading={loadingUpdate}
          />
        </ActionsContainer>
      }
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <p>
              Select files to recategorize from{' '}
              {capsSnakeCaseToTitleCase(typeFrom)} to{' '}
              {capsSnakeCaseToTitleCase(typeTo)}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          {files.map((f) => (
            <Grid.Column key={f.id}>
              <SelectFileCard
                file={f}
                onSelect={(id: string) => {
                  if (selected.includes(id)) {
                    setSelected((prev) => prev.filter((p) => p !== id))
                  } else {
                    setSelected((prev) => [...prev, id])
                  }
                }}
                selected={selected.includes(f.id)}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default CategorizationModalMultiple

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`
