import { useQuery } from '@apollo/client'

import {
  FIND_UNIQUE_RENDER_PROP,
  FindUniqueRenderPropPayload,
  FindUniqueRenderPropVariables,
} from 'queries/render-prop'

const useRenderPropNoContext = (opts?: {
  variables: FindUniqueRenderPropVariables
  skip: boolean
}) => {
  const { data: { renderProp } = { renderProp: null }, loading } = useQuery<
    FindUniqueRenderPropPayload,
    FindUniqueRenderPropVariables
  >(FIND_UNIQUE_RENDER_PROP, {
    skip: opts?.skip,
    variables: opts?.variables,
  })

  return {
    loading,
    renderProp,
  }
}

export default useRenderPropNoContext
