import React from 'react'

import { Divider, Image, List } from 'semantic-ui-react'

import AddressFormat from 'components/shared/address-format'
import { Badge } from 'components/shared/chip'
import { parseOwners } from 'context/project/utils'
import Button from 'design-system/components/button'
import { TableUserListItem } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { Project, ProjectType } from 'types/project'
import { User } from 'types/user'
import { capsSnakeCaseToTitleCase, getNameForUser } from 'views/utils'

export const ProjectOwnersCellContents = ({
  includeAddress,
  linkToProject,
  multipleOwners = false,
  project,
}: {
  includeAddress?: boolean
  linkToProject?: boolean
  multipleOwners?: boolean
  project?: Project | null
}) => {
  const { primaryOwner, primaryOwners } = parseOwners(project)
  return (
    <>
      {project?.data?.projectType === ProjectType.TRADE && (
        <div style={{ marginBottom: '16px' }}>
          <Badge
            color="#FFF0D9"
            name={capsSnakeCaseToTitleCase(project?.data?.projectType)}
          />
        </div>
      )}
      <div style={{ display: 'grid', rowGap: '8px' }}>
        {multipleOwners ? (
          primaryOwners.map((o) => {
            const { id, picture } = o.user as User
            return (
              <TableUserListItem key={id} style={{ padding: '0' }}>
                <Image src={picture} avatar circular />
                <List.Content>
                  <List.Header style={{ lineBreak: 'anywhere' }}>
                    {getNameForUser(o.user)}
                  </List.Header>
                </List.Content>
              </TableUserListItem>
            )
          })
        ) : (
          <TableUserListItem key={primaryOwner?.id}>
            <Image src={primaryOwner?.picture} avatar circular />
            <List.Content>
              <List.Header style={{ lineBreak: 'anywhere' }}>
                {getNameForUser(primaryOwner)}
              </List.Header>
            </List.Content>
          </TableUserListItem>
        )}
        {project?.data?.projectLabel ? (
          <p
            className="no-margin x-small"
            style={{ color: Colors.orange700, padding: '0' }}
          >
            {project?.data?.projectLabel}
          </p>
        ) : null}
      </div>
      {includeAddress ? (
        <>
          <Divider style={{ margin: '16px 0 8px 0' }} />
          <div className="light-gray x-small" style={{ padding: '0' }}>
            <AddressFormat address={project?.projectAddress} />
          </div>
        </>
      ) : null}
      {linkToProject ? (
        <>
          <Divider style={{ margin: '8px 0 0 0' }} />
          <Button
            color="dark"
            fontAwesomeIcon="arrow-up-right-from-square"
            kind="text"
            text="Go to project"
            onClick={() =>
              window.open(`/admin/projects/${project?.id}/overview`)
            }
            style={{ padding: '0' }}
          />
        </>
      ) : null}
    </>
  )
}
