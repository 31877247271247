import { CommentType } from './comment'
import { Diff } from './diff'
import { MeetingType } from './meeting'
import { MessageSource } from './message'
import { OrderType } from './order'
import { PaymentType } from './payment'
import { Project } from './project'
import { ProjectFileType } from './project-file'
import { User } from './user'
import { Address } from './utils'

export type ProjectEvent = ProjectEventBase & ProjectEventUnion

type ProjectEventBase = {
  id: string
  createdAt: string
  newFor?: User[]
  project: Project
  publisher: Pick<
    User,
    'id' | 'email' | 'firstName' | 'lastName' | 'picture' | 'type'
  >
  showStaff?: boolean
  updatedAt: string
  type: EventType
  data: ProjectEventData
}
interface ProjectEventDataBase {
  count: number
  language: string
  lastUpdatedAt: Date
  urlAdmin: string
}

export type ProjectEventData<T = Record<string, never>> = ProjectEventDataBase &
  T

type ProjectEventUnion =
  | CommentCreated
  | CommentCreatedInternal
  | DeliveryDamagesReported
  | IssueAdded
  | DeliveryUpdated
  | DesignCreated
  | DesignPlanApproved
  | DesignPlanApprovedInternally
  | DesignPlanInternalReviewRequested
  | DesignPlanSubmitted
  | DesignPlanSubmittedInternally
  | DesignReadyForRender
  | DesignRendersUploaded
  | DesignUpdated
  | MeetingCanceled
  | MeetingScheduled
  | MessageCreated
  | PaymentFailed
  | PaymentInvoiceCreated
  | PaymentPending
  | PaymentSucceeded
  | OrderPlaced
  | OrderUpdated
  | ProjectAccessed
  | ProjectAddressUpdated
  | ProjectArchived
  | ProjectCreated
  | ProjectOwnersAdded
  | ProjectUnarchived
  | ProjectUpdated
  | ProjectFileDeleted
  | ProjectFileUploaded
  | RoomAppliancesConfirmed
  | RoomApplianceChangesSubmitted
  | RoomAppliancesUpdated
  | RoomDesignBriefApproved
  | RoomMeasurementsConfirmed
  | RoomMeasurementChangesSubmitted
  | RoomMeasurementsUpdated
  | RoomPackDisabled
  | RoomUpdated
  | SupplierOrderApproved
  | SupplierOrderCreated
  | UserCreated
  | UserUpdated

export enum EventSubtype {
  COMMENT = 'COMMENT',
  DELIVERY = 'DELIVERY',
  DESIGN = 'DESIGN',
  ISSUE = 'ISSUE',
  MEETING = 'MEETING',
  MESSAGE = 'MESSAGE',
  ORDER = 'ORDER',
  PAYMENT = 'PAYMENT',
  PROJECT = 'PROJECT',
  PROJECT_FILE = 'PROJECT_FILE',
  RENDER = 'RENDER',
  ROOM = 'ROOM',
  SIGN_IN = 'SIGN_IN',
  SUPPLIER_ORDER = 'SUPPLIER_ORDER',
  USER = 'USER',
}

export enum EventType {
  COMMENT_CREATED = 'comment.created',
  COMMENT_CREATED_INTERNAL = 'comment.created_internal',
  DELIVERY_DAMAGE_REPORTED = 'delivery.damage_reported',
  DELIVERY_UPDATED = 'delivery.updated',
  DESIGN_CREATED = 'design.created',
  DESIGN_PLAN_APPROVED = 'design.plan_approved',
  DESIGN_PLAN_APPROVED_INTERNALLY = 'design.plan_approved_internally',
  DESIGN_PLAN_INTERNAL_REVIEW_REQUESTED = 'design.plan_internal_review_requested',
  DESIGN_PLAN_SUBMITTED = 'design.plan_submitted',
  DESIGN_PLAN_SUBMITTED_INTERNALLY = 'design.plan_submitted_internally',
  DESIGN_READY_FOR_RENDER = 'design.ready_for_render',
  DESIGN_RENDERS_UPLOADED = 'design.renders_uploaded',
  DESIGN_UPDATED = 'design.updated',
  ISSUE_ADDED = 'issue.added',
  MEETING_CANCELED = 'meeting.canceled',
  MEETING_CREATED = 'meeting.created',
  MEETING_SCHEDULED = 'meeting.scheduled',
  MESSAGE_CREATED = 'message.created',
  ORDER_PLACED = 'order.placed',
  ORDER_UPDATED = 'order.updated',
  PAYMENT_FAILED = 'payment.failed',
  PAYMENT_INVOICE_CREATED = 'payment.invoice_created',
  PAYMENT_PENDING = 'payment.pending',
  PAYMENT_SUCCEEDED = 'payment.succeeded',
  PROJECT_ACCESSED = 'project.accessed',
  PROJECT_ADDRESS_UPDATED = 'project.address_updated',
  PROJECT_ARCHIVED = 'project.archived',
  PROJECT_CREATED = 'project.created',
  PROJECT_OWNERS_ADDED = 'project.owners_added',
  PROJECT_UNARCHIVED = 'project.unarchived',
  PROJECT_UPDATED = 'project.updated',
  PROJECT_FILE_DELETED = 'project_file.deleted',
  PROJECT_FILE_UPLOADED = 'project_file.uploaded',
  ROOM_APPLIANCES_CONFIRMED = 'room.appliances_confirmed',
  ROOM_APPLIANCE_CHANGES_SUBMITTED = 'room.appliance_changes_submitted',
  ROOM_APPLIANCES_UPDATED = 'room.appliances_updated',
  ROOM_DESIGN_BRIEF_APPROVED = 'room.design_brief_approved',
  ROOM_MEASUREMENTS_CONFIRMED = 'room.measurements_confirmed',
  ROOM_MEASUREMENT_CHANGES_SUBMITTED = 'room.measurement_changes_submitted',
  ROOM_MEASUREMENTS_UPDATED = 'room.measurements_updated',
  ROOM_PACK_DISABLED = 'room.pack_disabled',
  ROOM_UPDATED = 'room.updated',
  SUPPLIER_ORDER_APPROVED = 'supplier_order.approved',
  SUPPLIER_ORDER_CREATED = 'supplier_order.created',
  USER_CREATED = 'user.created',
  USER_UPDATED = 'user.updated',
}

interface CommentCreated {
  type: EventType.COMMENT_CREATED
  data: ProjectEventData<{
    commentType: CommentType
    designName?: string
    fileId?: string
    roomId?: string
    roomName?: string
  }>
}

interface CommentCreatedInternal {
  type: EventType.COMMENT_CREATED_INTERNAL
  data: ProjectEventData<{
    designName?: string
    designId?: string
    roomId?: string
    roomName?: string
  }>
}

interface DeliveryDamagesReported {
  type: EventType.DELIVERY_DAMAGE_REPORTED
  data: ProjectEventData<{
    deliveryId: string
  }>
}

interface IssueAdded {
  type: EventType.ISSUE_ADDED
  data: {
    deliveryId?: string
    publisher: User
    projectId: string
  }
}

interface DeliveryUpdated {
  type: EventType.DELIVERY_UPDATED
  data: ProjectEventData<{
    deliveryId: string
  }>
}

interface DesignCreated {
  type: EventType.DESIGN_CREATED
  data: ProjectEventData<{
    designName?: string
    designId: string
    roomId: string
    roomName: string
  }>
}

interface DesignPlanApproved {
  type: EventType.DESIGN_PLAN_APPROVED
  data: ProjectEventData<{
    designName?: string
    designId: string
    roomId: string
    roomName: string
  }>
}

interface DesignPlanApprovedInternally {
  type: EventType.DESIGN_PLAN_APPROVED_INTERNALLY
  data: ProjectEventData<{
    designName?: string
    designId: string
    roomId: string
    roomName: string
  }>
}

interface DesignPlanInternalReviewRequested {
  type: EventType.DESIGN_PLAN_INTERNAL_REVIEW_REQUESTED
  data: ProjectEventData<{
    designName?: string
    designId: string
    roomId: string
    roomName: string
  }>
}

interface DesignPlanSubmitted {
  type: EventType.DESIGN_PLAN_SUBMITTED
  data: ProjectEventData<{
    designName?: string
    designId: string
    roomId: string
    roomName: string
  }>
}

interface DesignPlanSubmittedInternally {
  type: EventType.DESIGN_PLAN_SUBMITTED_INTERNALLY
  data: ProjectEventData<{
    designName?: string
    designId: string
    roomId: string
    roomName: string
  }>
}

interface DesignReadyForRender {
  type: EventType.DESIGN_READY_FOR_RENDER
  data: ProjectEventData<{
    designName?: string
    designId: string
    roomId: string
    roomName: string
  }>
}

interface DesignRendersUploaded {
  type: EventType.DESIGN_RENDERS_UPLOADED
  data: ProjectEventData<{
    designId: string
    designName: string
    roomId: string
    roomName: string
  }>
}

interface DesignUpdated {
  type: EventType.DESIGN_UPDATED
  data: ProjectEventData<{
    designName?: string
    designId?: string
    roomId?: string
    roomName?: string
  }>
}

interface MeetingCanceled {
  type: EventType.MEETING_CANCELED
  data: ProjectEventData<{
    meetingId?: string
    meetingType: MeetingType
    tracking: {
      utmMedium: string
      utmSource: string
      utmCampaign: string
    }
  }>
}

interface MeetingScheduled {
  type: EventType.MEETING_SCHEDULED
  data: ProjectEventData<{
    isReschedule?: boolean
    meetingId?: string
    meetingType: MeetingType
    prospectId?: string
    tracking: {
      utmMedium: string
      utmSource: string
      utmCampaign: string
    }
  }>
}

interface MessageCreated {
  type: EventType.MESSAGE_CREATED
  data: ProjectEventData<{
    attachments?: {
      name: string
      url: string
    }[]
    body: string
    messageId: string
    subject?: string
    to: string[]
    source?: MessageSource
    sourceFrom?: string
  }>
}

interface OrderPlaced {
  type: EventType.ORDER_PLACED
  data: ProjectEventData<{
    orderId: string
    orderType: OrderType
    shippingAddress: Address
  }>
}

interface OrderUpdated {
  type: EventType.ORDER_UPDATED
  data: ProjectEventData<{
    orderId: string
    orderType: OrderType
  }>
}

interface PaymentFailed {
  type: EventType.PAYMENT_FAILED
  data: ProjectEventData<{
    message: string
    paymentId?: string
    paymentType?: PaymentType
    stripeEmail: string
  }>
}

interface PaymentInvoiceCreated {
  type: EventType.PAYMENT_INVOICE_CREATED
  data: ProjectEventData<{
    paymentId: string
    paymentType: PaymentType
    stripeInvoiceId: string
  }>
}

interface PaymentPending {
  type: EventType.PAYMENT_PENDING
  data: ProjectEventData<{
    paymentId: string
    paymentType: PaymentType
  }>
}

interface PaymentSucceeded {
  type: EventType.PAYMENT_SUCCEEDED
  data: ProjectEventData<{
    paymentId: string
    paymentType: PaymentType
    paymentName?: string
    paymentAmount?: number
  }>
}

interface ProjectAccessed {
  type: EventType.PROJECT_ACCESSED
  data: ProjectEventData
}

interface ProjectAddressUpdated {
  type: EventType.PROJECT_ADDRESS_UPDATED
  data: ProjectEventData
}

interface ProjectArchived {
  type: EventType.PROJECT_ARCHIVED
  data: ProjectEventData
}

interface ProjectCreated {
  type: EventType.PROJECT_CREATED
  data: ProjectEventData<{
    paymentId?: string
    salesDesignerEmail?: string
  }>
}

interface ProjectOwnersAdded {
  type: EventType.PROJECT_OWNERS_ADDED
  data: ProjectEventData
}

interface ProjectUnarchived {
  type: EventType.PROJECT_UNARCHIVED
  data: ProjectEventData
}

interface ProjectUpdated {
  type: EventType.PROJECT_UPDATED
  data: ProjectEventData
}

interface ProjectFileDeleted {
  type: EventType.PROJECT_FILE_DELETED
  data: ProjectEventData<{
    projectFileId: string
    projectFileType: ProjectFileType
  }>
}

interface ProjectFileUploaded {
  type: EventType.PROJECT_FILE_UPLOADED
  data: ProjectEventData<{
    projectFileId: string
    projectFileType: ProjectFileType
  }>
}

interface RoomAppliancesConfirmed {
  type: EventType.ROOM_APPLIANCES_CONFIRMED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface RoomApplianceChangesSubmitted {
  type: EventType.ROOM_APPLIANCE_CHANGES_SUBMITTED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface RoomAppliancesUpdated {
  type: EventType.ROOM_APPLIANCES_UPDATED
  data: ProjectEventData<{
    roomId: string
    roomName: string
    diffs?: Diff[]
  }>
}

interface RoomDesignBriefApproved {
  type: EventType.ROOM_DESIGN_BRIEF_APPROVED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface RoomMeasurementsConfirmed {
  type: EventType.ROOM_MEASUREMENTS_CONFIRMED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface RoomMeasurementChangesSubmitted {
  type: EventType.ROOM_MEASUREMENT_CHANGES_SUBMITTED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface RoomMeasurementsUpdated {
  type: EventType.ROOM_MEASUREMENTS_UPDATED
  data: ProjectEventData<{
    roomId: string
    roomName: string
    diffs?: Diff[]
  }>
}

interface RoomPackDisabled {
  type: EventType.ROOM_PACK_DISABLED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface RoomUpdated {
  type: EventType.ROOM_UPDATED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface RoomUpdated {
  type: EventType.ROOM_UPDATED
  data: ProjectEventData<{
    roomId: string
    roomName: string
  }>
}

interface SupplierOrderApproved {
  type: EventType.SUPPLIER_ORDER_APPROVED
  data: ProjectEventData<{
    supplierOrderId?: string
    supplierOrderName?: string
  }>
}

interface SupplierOrderCreated {
  type: EventType.SUPPLIER_ORDER_CREATED
  data: ProjectEventData<{
    roomId?: string
    roomName?: string
    supplierOrderId?: string
    supplierOrderType: 'Regular Order' | 'Remedial' | 'Add-On'
  }>
}

interface UserCreated {
  type: EventType.USER_CREATED
  data: ProjectEventData<{
    userEmail: string
    userId: string
    userType: string
  }>
}

interface UserUpdated {
  type: EventType.USER_UPDATED
  data: ProjectEventData<{
    userId: string
  }>
}
