import React, { useState } from 'react'

import { Card, List } from 'semantic-ui-react'

import PersonListItem from 'components/admin/person-list-item'
import ProjectProspectCreateUpdateModal from 'components/admin/project/project-prospect/create-update-modal'
import ProjectStaffCreateUpdateModal from 'components/admin/project/project-staff/create-update-modal'
import ProjectNewUserModal from 'components/admin/project/project-user/create-new-modal'
import ProjectUserCreateUpdateModal from 'components/admin/project/project-user/create-update-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useProject from 'context/project/use'
import useProjectProspectMutate from 'context/project/use-project-prospect-mutate'
import useProjectStaffMutate from 'context/project/use-project-staff-mutate'
import useProjectUserMutate from 'context/project/use-project-user-mutate'
import Button from 'design-system/components/button'
import { ProjectProspect } from 'types/project-prospect'
import { ProjectStaff } from 'types/project-staff'
import { ProjectUser } from 'types/project-user'
import { User } from 'types/user'
import * as viewUtils from 'views/utils'

const PeopleCard = () => {
  const {
    allProjectStaff,
    allProjectUsers,
    primaryOwner,
    projectProspects,
    project,
  } = useProject()
  const [createNewUser, setCreateNewUser] = useState(false)
  const [projectUserToRemove, setProjectUserToRemove] = useState<ProjectUser>()
  const [projectUserToEdit, setProjectUserToEdit] = useState<ProjectUser>()
  const [projectUserEnableCreate, setProjectUserEnableCreate] = useState(false)

  const [projectStaffToRemove, setProjectStaffToRemove] =
    useState<ProjectStaff>()
  const [projectStaffToEdit, setProjectStaffToEdit] = useState<ProjectStaff>()
  const [projectStaffEnableCreate, setProjectStaffEnableCreate] =
    useState(false)

  const [projectProspectToRemove, setProjectProspectToRemove] =
    useState<ProjectProspect>()
  const [projectProspectToEdit, setProjectProspectToEdit] =
    useState<ProjectProspect>()
  const [projectProspectEnableCreate, setProjectProspectEnableCreate] =
    useState(false)

  const { deleteProjectProspectAndRefetch } = useProjectProspectMutate()
  const { deleteProjectStaffAndRefetch } = useProjectStaffMutate()
  const { deleteProjectUserAndRefetch } = useProjectUserMutate()

  return (
    <Card fluid>
      <Card.Content>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h6 className="no-margin">Owners</h6>
          <div
            style={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              kind="outlined"
              color="gray"
              fontAwesomeIcon="plus"
              text="Attach"
              onClick={() => setProjectUserEnableCreate(true)}
            />
            <Button
              kind="outlined"
              color="gray"
              fontAwesomeIcon="plus"
              text="Create"
              onClick={() => setCreateNewUser(true)}
            />
            <ProjectNewUserModal
              onClose={() => {
                setCreateNewUser(false)
              }}
              open={createNewUser}
            />
          </div>
        </div>
        <List relaxed>
          {allProjectUsers.map((o) => (
            <PersonListItem
              isPrimary={o.user?.id == primaryOwner.id}
              key={o.user?.id}
              onRemove={() => setProjectUserToRemove(o)}
              onUpdate={() => setProjectUserToEdit(o)}
              removeDisabled={allProjectUsers.length <= 1}
              type={viewUtils.capsSnakeCaseToTitleCase(o.userRole)}
              user={o.user as User}
            />
          ))}
          <ProjectUserCreateUpdateModal
            open={projectUserEnableCreate || !!projectUserToEdit}
            onClose={() => {
              setProjectUserToEdit(undefined)
              setProjectUserEnableCreate(false)
            }}
            projectUser={projectUserToEdit}
          />
          <ConfirmationModal
            firstTitle="Are you sure you want to remove this owner?"
            onCancel={() => setProjectUserToRemove(undefined)}
            onConfirm={async () => {
              await deleteProjectUserAndRefetch({
                variables: {
                  where: {
                    projectId_userId: {
                      projectId: project.id,
                      userId: projectUserToRemove?.userId ?? '',
                    },
                  },
                },
              })
              setProjectUserToRemove(undefined)
            }}
            open={!!projectUserToRemove}
          />
        </List>
      </Card.Content>
      <Card.Content>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h6 className="no-margin">Staff</h6>
          <Button
            kind="outlined"
            color="gray"
            fontAwesomeIcon="plus"
            text="Attach"
            onClick={() => setProjectStaffEnableCreate(true)}
          />
        </div>
        <List relaxed>
          {allProjectStaff.map((s) => (
            <PersonListItem
              isPrimary={s.isPrimary}
              key={s.staffMemberId}
              onRemove={() => setProjectStaffToRemove(s)}
              onUpdate={() => setProjectStaffToEdit(s)}
              removeDisabled={
                s.isPrimary &&
                allProjectStaff.filter((p) => p.isPrimary).length <= 1
              }
              type={viewUtils.capsSnakeCaseToTitleCase(s.staffMemberRole)}
              user={s.staffMember as User}
            />
          ))}
          <ProjectStaffCreateUpdateModal
            open={projectStaffEnableCreate || !!projectStaffToEdit}
            onClose={() => {
              setProjectStaffToEdit(undefined)
              setProjectStaffEnableCreate(false)
            }}
            projectStaff={projectStaffToEdit as ProjectStaff}
            disablePrimary={
              projectStaffToEdit?.isPrimary &&
              allProjectStaff.filter((p) => p.isPrimary).length <= 1
            }
          />
          <ConfirmationModal
            firstTitle="Are you sure you want to remove this staff member?"
            onCancel={() => setProjectStaffToRemove(undefined)}
            onConfirm={async () => {
              await deleteProjectStaffAndRefetch({
                variables: {
                  where: {
                    projectId_staffMemberId: {
                      projectId: project.id,
                      staffMemberId: projectStaffToRemove?.staffMemberId ?? '',
                    },
                  },
                },
              })
              setProjectStaffToRemove(undefined)
            }}
            open={!!projectStaffToRemove}
          />
        </List>
      </Card.Content>
      <Card.Content>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h6 className="no-margin">Prospects</h6>
          <Button
            kind="outlined"
            color="gray"
            fontAwesomeIcon="plus"
            text="Attach"
            onClick={() => setProjectProspectEnableCreate(true)}
          />
        </div>
        <List relaxed>
          {projectProspects.map((p) => (
            <PersonListItem
              isPrimary={p.isPrimary}
              key={p.prospect?.id}
              onUpdate={() => setProjectProspectToEdit(p)}
              onRemove={() => setProjectProspectToRemove(p)}
              user={{
                email: p.prospect?.email,
                phone: p.prospect?.phone,
                firstName: p.prospect?.firstName,
                lastName: p.prospect?.lastName,
              }}
            />
          ))}
          <ProjectProspectCreateUpdateModal
            open={projectProspectEnableCreate || !!projectProspectToEdit}
            onClose={() => {
              setProjectProspectToEdit(undefined)
              setProjectProspectEnableCreate(false)
            }}
            projectProspect={projectProspectToEdit as ProjectProspect}
          />
          <ConfirmationModal
            firstTitle="Are you sure you want to remove this prospect?"
            onCancel={() => setProjectProspectToRemove(undefined)}
            onConfirm={async () => {
              await deleteProjectProspectAndRefetch({
                variables: {
                  where: {
                    projectId_prospectId: {
                      projectId: project.id,
                      prospectId: projectProspectToRemove?.prospectId ?? '',
                    },
                  },
                },
              })
              setProjectProspectToRemove(undefined)
            }}
            open={!!projectProspectToRemove}
          />
        </List>
      </Card.Content>
    </Card>
  )
}

export default PeopleCard
