import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomElementClassManyProvider from 'context/room-element-class/provider-many'

interface RoomElementClassIndexManyProps
  extends RouteComponentProps<{ catalog_id: string }> {
  children: React.ReactNode
}

const RoomElementClassIndexMany = (props: RoomElementClassIndexManyProps) => {
  return (
    <RoomElementClassManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        sortBy: 'identifier',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </RoomElementClassManyProvider>
  )
}

export default RoomElementClassIndexMany
