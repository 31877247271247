import { useContext } from 'react'

import { CatalogFeatureManyContext } from './provider-many'

const useCatalogFeatureMany = () => {
  const {
    catalog,
    catalogFeatures,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    queryFilters,
    refetch,
    setQueryFilters,
  } = useContext(CatalogFeatureManyContext)

  return {
    catalog,
    catalogFeatures,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    queryFilters,
    refetch,
    setQueryFilters,
  }
}

export default useCatalogFeatureMany
