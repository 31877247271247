import React from 'react'

import { Image, Table } from 'semantic-ui-react'

import useRenderPropMany from 'context/render-prop/use-many'
import { RenderProp } from 'types/render-prop'
import { camelCaseToTitleCase } from 'views/utils'

const RenderPropTable = () => {
  const { renderProps, queryFilters, setQueryFilters } = useRenderPropMany()
  const { sortBy, sortDirection } = queryFilters
  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'groupIdentifier' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'groupIdentifier',
                sortDirection:
                  sortBy === 'groupIdentifier' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Group
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'identifier' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'identifier',
                sortDirection:
                  sortBy === 'identifier' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Identifier
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {renderProps.map((n: RenderProp) => {
          return (
            <Table.Row key={n.id}>
              <Table.Cell width={4}>
                <Image centered size="small" src={n.imageUrl} />
              </Table.Cell>
              <Table.Cell width={4}>{n.description}</Table.Cell>
              <Table.Cell width={4}>
                {camelCaseToTitleCase(n.groupIdentifier)}
              </Table.Cell>
              <Table.Cell width={4}>{n.identifier}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default RenderPropTable
