import {
  Accordion,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
  Menu,
  Modal,
  Popup,
  Search,
  Segment,
  Table,
} from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { Colors, StyledSystem } from './system'

export const PlannerColumn = styled.div<{ show?: boolean }>`
  display: ${(props) => (props?.show ? 'block' : 'none')};
  height: 100%;
  margin-bottom: -8px;
`

export const LoaderContainer = styled.div<{
  styled?: {
    opacity?: boolean
    show?: boolean
  }
}>`
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 1);
  display: ${(props) => (props.styled?.show ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  opacity: ${(props) => (props.styled?.opacity ? '1' : '0')};
  transition: all 0.3s ease-in-out;
  width: 100%;

  svg {
    height: 70px !important;
    width: 70px !important;
  }
`

const ScrollBar = css`
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray600};
    border-radius: 100px;
    :hover {
      background-color: ${Colors.gray600};
    }
  }
`

const Paragraph = css`
  color: ${Colors.gray900};
  font-family: 'GT America';
  font-size: 14px;
  font-weight: 100;
`

export const DeleteButton = styled(Button)`
  &&&& {
    height: 26px;
    margin: 0;
    padding: 0 !important;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 26px;
    z-index: 1;
  }
`

export const FormSelect = styled(Form.Select)`
  &&&& {
    opacity: 1 !important;

    label {
      opacity: 1 !important;
      color: ${Colors.gray500};
      font-family: 'GT America';
      font-size: 13px;
      font-weight: 100;
      line-height: 200%;
      margin: 0;
    }

    .disabled div {
      opacity: 0.6 !important;
    }

    div.dropdown {
      border: 1px solid ${Colors.gray200};
      border-radius: 1px !important;
      min-height: 36px !important;
      padding: 9px 30px 9px 11px !important;

      .text {
        ${Paragraph}
        color: ${Colors.gray600};

        &.divider {
          color: ${Colors.black};
        }
      }

      .menu {
        border: none;
        border-radius: 0 !important;
        box-shadow:
          0px 2px 4px rgb(0 0 0 / 12%),
          0px 7px 20px rgb(0 0 0 / 14%);
        top: 103%;

        .item {
          border: none !important;
          padding: 13px 11px !important;
        }

        .item:hover,
        .item.active {
          background: ${Colors.gray100};
          .text {
            color: ${Colors.black};
          }
        }

        ${ScrollBar}
      }

      i {
        align-items: center;
        background: ${Colors.gray200};
        display: flex;
        height: 100%;
        justify-content: center;
        min-width: 30px;
        position: absolute;
        right: 0;
        top: 0;

        svg {
          font-size: 10px;
        }
      }

      &.active {
        border: 1px solid #a7b3cd !important;
        i {
          background: ${Colors.blue200};

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`

export const HeaderDropdown = styled(Dropdown)`
  &&&& {
    align-items: center;
    color: ${Colors.white};
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 10px 20px;

    > span,
    svg,
    :hover {
      color: ${Colors.white} !important;
    }

    svg {
      font-size: 9px;
      margin-left: 10px;
    }

    .avatar {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }

    &.user,
    :hover {
      background-color: ${Colors.blue800} !important;
    }

    &.active {
      background-color: ${Colors.blue800} !important;
      svg {
        transform: rotate(180deg);
      }
    }

    div.menu {
      background: ${Colors.white};
      border-radius: 2px;
      border: 1px solid ${Colors.gray200} !important;
      top: 100%;
      min-width: 100%;

      div.item {
        padding: 8px 16px !important;

        span.text {
          ${Paragraph}
          color: ${Colors.gray500} !important;
        }

        :hover {
          background: ${Colors.gray50} !important;
          span.text {
            color: ${Colors.black} !important;
          }
        }
      }
    }
  }
`

export const PointingMenu = styled(Menu)`
  &&& {
    border-bottom: 1px solid ${Colors.gray300};
    margin-bottom: 20px;
    width: fit-content;

    .item {
      padding: 8px 16px !important;
      p {
        color: ${Colors.gray500};
      }

      &.active {
        border-bottom: 2px solid ${Colors.orange500} !important;

        p {
          color: ${Colors.black};
        }
      }
    }
  }
`

export const StyledAccordion = styled(Accordion)<{
  open?: boolean
  transparent?: boolean
}>`
  &&&&&&& {
    background: ${(props) =>
      props?.transparent ? 'none' : 'white'} !important;
    border: ${(props) =>
      props?.transparent ? 'none' : `1px solid ${Colors.gray200}`};
    border-radius: 1px;
    box-shadow: none !important;
    transition: all 0.3s ease-in-out;

    .title {
      align-items: center;
      display: flex !important;

      p {
        margin: 0;
        color: ${Colors.gray900};
      }

      svg {
        color: ${Colors.gray900};
        font-size: 11px;
        margin-right: 9px;
      }

      ${(props) =>
        props?.open &&
        css`
          background: #44403c !important;
          border-top-left-radius: 1px;
          border-top-right-radius: 1px;
          color: white !important;

          p,
          svg {
            color: white;
          }
        `}
    }

    .content {
      padding: 14px 0;

      div.category {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        p {
          margin: 0;
        }
      }
    }

    .content.catalog-accordion {
      padding: 16px 16px 0;

      p.subtitle {
        color: ${Colors.gray500} !important;
        font-weight: 400;
      }

      div.divider {
        border: 0.5px dashed ${Colors.gray400};
        margin: 16px -16px;
      }

      div {
        align-items: center;
        display: flex;
        margin-bottom: 24px;
        justify-content: space-between;

        p {
          color: ${Colors.black};
          margin: 0;
          width: 50%;
          :last-child {
            text-align: end;
          }
        }
      }

      div.cabinetry-price p {
        font-weight: 400;
      }

      div.price {
        background: ${Colors.green50};
        margin: 0 -16px;
        padding: 12px 16px;
      }
    }
  }
`

export const StyledBreadcrumb = styled(Breadcrumb)`
  padding-bottom: 18px;

  svg {
    color: ${Colors.gray700};
    font-size: 9px;
    margin: 0 8px;
  }

  .section {
    color: ${Colors.gray700} !important;
    font-weight: 300 !important;
  }
`

export const StyledButton = styled(Button)<{
  active?: boolean
  black?: boolean
  blackOutline?: boolean
  orange?: string
  transparent?: boolean
}>`
  &&&& {
    background: ${Colors.gray100};
    border: 0.5px solid ${Colors.gray200};
    height: 32px;
    margin: 0 5px;
    padding: 0;
    width: 32px;

    ${(props) =>
      props.active &&
      css`
        background: ${props.orange ? Colors.red700 : Colors.gray700};
        :hover {
          background: ${props.orange
            ? Colors.red700
            : Colors.gray700} !important;
        }
      `}

    ${({ styled }) =>
      styled?.black &&
      css`
        background-color: ${Colors.black};
        box-shadow: none;
        color: ${Colors.white};
        transition-duration: 0.3s;

        &:hover,
        :active,
        :focus {
          background-color: ${Colors.gray600} !important;
          color: ${Colors.white} !important;
          transition-duration: 0.3s;
        }
      `}
    
    ${({ styled }) =>
      styled?.blackOutline &&
      css`
        background: transparent;
        border: 1px solid ${Colors.black} !important;
        box-shadow: none;
        color: ${Colors.gray500};
        position: relative;

        &:hover,
        :active,
        :focus {
          background-color: ${Colors.blue300};
          border: 1px solid ${Colors.blue300} !important;
          color: ${Colors.blue900};
          transition-duration: 0.3s;
        }

        &.loading:after {
          animation: none;
        }

        &.disabled {
          background: transparent;
          border: 1px solid ${Colors.gray500};
          color: ${Colors.gray500};
        }
      `}

    ${({ styled }) =>
      styled?.transparent &&
      css`
        padding: 8px 0 !important;
        background: none;
        color: ${styled.color ? styled.color : Colors.black};

        &:hover,
        :active,
        :focus {
          box-shadow: none;
          border: none;
          background: ${styled.backgroundColor
            ? styled.backgroundColor
            : 'none'};
          color: ${styled.color ? styled.color : Colors.black} !important;
        }
      `}

    :hover {
      background: ${Colors.gray200};
    }
  }
`

export const StyledCard = styled(Card)`
  &&& {
    border: 1px solid ${Colors.gray300};
    border-radius: 1px;
    box-shadow: none;
    margin-bottom: 18px !important;
    transition: all 0.2s ease-in-out;

    :hover {
      border: 1px solid ${Colors.gray500};
      border-radius: 1px;
      box-shadow: none;
    }

    .content {
      background: ${Colors.gray};
      border-radius: 1px !important;
      padding: 6px 6px;
      text-align: center;
    }

    .content.product-image {
      background: white;
      padding: 0;

      img {
        margin-top: 10px;
        width: 90%;
      }
    }
  }
`

export const StyledForm = styled(Form)`
  .field {
    margin: 0 0 18px !important;
  }

  .field:last-of-type {
    margin: 0 !important;
  }

  input {
    max-height: 36px;
    border-radius: 0 !important;
  }
`

export const StyledHeader = styled(Menu)`
  &&&&& {
    background: ${Colors.blue700};
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;

    > .item {
      color: white;
      font-size: 15px;
      font-weight: 300;
      padding: 0;
    }
  }
`

export const StyledInput = styled(Form.Input)`
  &&&& {
    margin-right: 12px;

    div.arrows {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      position: absolute;
      right: 0;
      width: 19px;

      div.arrow {
        align-items: center;
        background: ${Colors.gray200};
        border: 1px solid ${Colors.gray300};
        cursor: pointer;
        display: flex;
        height: 50%;
        justify-content: center;
        width: 100%;

        svg {
          font-size: 11px;
          color: ${Colors.gray800};
        }
      }

      .disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
      }
    }

    div.input {
      min-width: 80px;
    }

    input {
      border: 1px solid #d6d3d1;
      border-radius: 0;
      font-size: 14px;
      font-family: 'GT America';
      max-height: 34px;
      min-width: 80px;

      label {
        font-size: 13px;
      }
    }

    input[type='number']::-webkit-inner-spin-button {
      opacity: 0;
      display: none;
    }
  }
`

export const StyledMenu = styled(Menu)`
  &&&& {
    align-items: center;
    border-radius: 0;
    display: flex;
    width: calc(100% + 2px) !important;

    .item {
      &.active {
        background: ${Colors.blue200};
        border-radius: 2px !important;
      }

      :before {
        background: none !important;
      }
    }
  }
`

export const StyledModal = styled(Modal)`
  &&& {
    ${StyledSystem}
    margin: 0;
    padding: 0;

    div.header {
      border-bottom: 1px solid ${Colors.gray200};
      padding: 10px 20px;
    }

    div.content {
      ${ScrollBar}
    }
  }
`

export const StyledPortal = styled(Segment)<{ styled?: { light?: boolean } }>`
  &&& {
    background: ${({ styled }) =>
      styled?.light ? Colors.white : Colors.gray50};
    border-radius: 0;
    border: none;
    box-shadow: 0px 10px 37px rgba(0, 0, 0, 0.15);
    left: 50%;
    margin: 0;
    max-height: 85vh;
    padding: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;

    div.header {
      align-items: center;
      background: ${({ styled }) =>
        styled?.light ? Colors.gray50 : Colors.gray900};
      display: flex;
      justify-content: space-between;
      padding: 10px 18px;

      p,
      svg {
        color: ${({ styled }) =>
          styled?.light ? Colors.gray700 : Colors.white} !important;
        margin: 0;
      }
      span {
        cursor: pointer;
      }
    }

    div.delete-content {
      text-align: center;
      padding: 30px 50px;

      p {
        font-size: 15px;
        margin-top: 18px;
      }
    }

    > div.content {
      padding: 20px 30px;
      height: calc(100% - 96px);
      overflow-y: scroll;

      ${ScrollBar}
    }

    div.actions {
      background: ${({ styled }) =>
        styled?.light ? Colors.white : Colors.gray50};
      border-top: 0.5px solid ${Colors.gray300};
      display: flex;
      justify-content: flex-end;
      padding: 11px 14px;
    }
  }
`

export const StyledPopup = styled(Popup)<{
  styled?: { position: 'bottom' | 'right' }
}>`
  &&& {
    background: rgba(246, 245, 244);
    border: 0.5px solid ${Colors.gray300};
    box-shadow: none;
    padding: 0 6px 3px;
    margin-top: 5px !important;
    z-index: 100000;
    position: relative;

    p {
      color: ${Colors.gray900};
      font-family: 'GT America';
      font-style: normal;
      text-transform: capitalize;
      font-weight: 100;
    }

    :before {
      background: rgba(246, 245, 244) !important;
      border-left: 0.5px solid ${Colors.gray300};
      box-shadow: none !important;
      border-color: ${Colors.gray300} !important;

      ${({ styled }) =>
        styled?.position === 'right'
          ? css`
              left: -5px !important;
              border-bottom: 0.5px solid ${Colors.gray300};
            `
          : css`
              top: -5.5px !important;
              border-top: 0.5px solid ${Colors.gray300};
            `}
    }
  }
`

export const StyledSearch = styled(Search)`
  &&& {
    div.input {
      width: 100%;

      input {
        border: 1px solid ${Colors.gray300};
        border-radius: 2px;
        ${Paragraph}

        :focus {
          outline: none;
        }
      }
    }

    div.results {
      background: ${Colors.white};
      box-shadow:
        0px 2px 4px rgb(0 0 0 / 12%),
        0px 7px 20px rgb(0 0 0 / 14%);
      border-radius: 2px;
      border: none;
      max-height: 400px;
      overflow-y: scroll;
      width: 100%;

      div.result {
        .title {
          ${Paragraph}
        }
        :hover {
          background: ${Colors.gray100};
        }
      }

      div.header {
        background: none;
        ${Paragraph}
        padding: 0;
      }

      ${ScrollBar}
    }
  }
`

export const StyledSegment = styled.div`
  background: ${Colors.gray100};
  border: 1px solid ${Colors.gray300};
  border-radius: 0;
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 14px 14px 30px;

  ${ScrollBar}
`

export const StyledSlider = styled.div<{ width: number }>`
  width: 100%;
  div.semantic_ui_range_inner {
    div:nth-of-type(1) {
      background: ${Colors.white} !important;
      border: 0.3px solid ${Colors.gray200} !important;
      border-radius: 100px;
    }

    div:nth-of-type(2) {
      background: ${Colors.orange} !important;
      width: ${(props) =>
        `${((props.width * 100) / 360).toFixed(2)}%`} !important;
    }

    div:nth-of-type(3) {
      background: ${Colors.white} !important;
      border: 0.1px solid ${Colors.gray200} !important;
      box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.03),
        0px 1px 2px rgba(0, 0, 0, 0.06) !important;
      height: 15px !important;
      left: ${(props) =>
        `calc(${((props.width * 100) / 360).toFixed(2)}% - 10px)`} !important;
      top: 3px !important;
      width: 15px !important;
    }
  }
`

export const StyledTable = styled(Table)`
  &&& {
    border: 1px solid ${Colors.gray200};
    border-radius: 3px;

    thead tr th {
      background: ${Colors.gray100};
      padding: 7px;
    }
  }
`
