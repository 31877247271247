import React from 'react'

import DeliveryTab from 'components/admin/project/delivery/tab'
import DeliveryProvider from 'context/delivery/provider'
import useDeliveryLookup from 'context/delivery/use-lookup'
import ProjectProvider from 'context/project/provider'
import { Modal } from 'design-system/components/modal'
import { FIND_UNIQUE_PROJECT_DELIVERY } from 'queries/project'

interface DeliveryModalProps {
  onClose?: () => void
  open?: boolean
  onComplete: () => void
}

const DeliveryModal = ({ onClose, open, onComplete }: DeliveryModalProps) => {
  return (
    <Modal onClose={onClose} open={open} size="fullscreen" title="Delivery">
      <DeliveryTab
        onComplete={async () => {
          onComplete?.()
          onClose?.()
        }}
      />
    </Modal>
  )
}

interface DeliveryModalIdProps {
  deliveryId: string
  onClose?: () => void
  open?: boolean
  refetch?: () => void
}

const DeliveryModalId = ({
  open,
  deliveryId,
  onClose,
  refetch,
}: DeliveryModalIdProps) => {
  const { loading, delivery } = useDeliveryLookup(deliveryId)
  if (!delivery || loading) return null

  return (
    <ProjectProvider
      project_id={delivery.project?.id}
      query={FIND_UNIQUE_PROJECT_DELIVERY}
      noLoader
    >
      <DeliveryProvider delivery_id={delivery.id} noLoader>
        <DeliveryModal
          onClose={onClose}
          open={open}
          onComplete={() => refetch?.()}
        />
      </DeliveryProvider>
    </ProjectProvider>
  )
}

export default DeliveryModalId
