import React from 'react'

import { RouteComponentProps } from '@reach/router'

import TasksDashboard from 'components/admin/task/dashboard'
import useSalesDesigner from 'context/dashboard/sales-designer/use'

import SalesDesignerLayout from './layout'

const SalesDesignerTasks = (_: RouteComponentProps) => {
  const { tasksToDo, tasksUrgent, tasksCompleted, refetchTasks } =
    useSalesDesigner()
  return (
    <SalesDesignerLayout>
      <TasksDashboard
        refetch={refetchTasks}
        tasksToDo={tasksToDo}
        tasksUrgent={tasksUrgent}
        tasksCompleted={tasksCompleted}
      />
    </SalesDesignerLayout>
  )
}

export default SalesDesignerTasks
