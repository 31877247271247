import React from 'react'

import { RouteComponentProps } from '@reach/router'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import NotesDashboard from 'components/admin/project-notes'
import useProjectDeepLinks from 'context/project/use-deep-links'

const Notes = (_: RouteComponentProps<{ project_id: string }>) => {
  const { deepLinks } = useProjectDeepLinks()

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Notes" />
      <h5>Project Notes</h5>
      <NotesDashboard deepLinks={deepLinks} isProjectNote />
    </ProjectLayout>
  )
}

export default Notes
