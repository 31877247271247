import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Editor } from '@tiptap/react'
import { Form } from 'semantic-ui-react'

import { notifySuccess } from 'context/notifications/trigger'
import { Modal } from 'design-system/components/modal'
import RichText from 'design-system/components/rich-text'
import {
  CREATE_MESSAGE_TEMPLATE,
  CreateMessageTemplatePayload,
  CreateMessageTemplateVariables,
  UPDATE_MESSAGE_TEMPLATE,
  UpdateMessageTemplatePayload,
  UpdateMessageTemplateVariables,
} from 'queries/message-template'
import { StyledForm } from 'styles/admin/main'
import { MessageTemplate } from 'types/message-template'
import { humanReadableUid } from 'views/utils'

interface TemplateModalProps {
  onCloseModal: () => void
  openModal: boolean
  refetch: () => void
  template?: MessageTemplate
  templateBookId?: string
}

const CreateUpdateTemplateModal = ({
  onCloseModal,
  openModal,
  refetch,
  template,
  templateBookId,
}: TemplateModalProps) => {
  const initialState = {
    body: template?.body || '',
    identifier: template?.identifier || humanReadableUid(),
    index: template?.index || '',
    title: template?.title || '',
  }
  const [state, setState] = useState(initialState)
  const [richTextApi, setRichTextApi] = useState<Editor | null>(null)
  const [isEmptyMsg, setIsEmptyMsg] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (openModal) setState(initialState)
  }, [openModal])

  const [createMessageTemplate] = useMutation<
    CreateMessageTemplatePayload,
    CreateMessageTemplateVariables
  >(CREATE_MESSAGE_TEMPLATE, {
    onCompleted: () => {
      notifySuccess('Message Template Created')
      refetch()
    },
  })

  const [updateMessageTemplate] = useMutation<
    UpdateMessageTemplatePayload,
    UpdateMessageTemplateVariables
  >(UPDATE_MESSAGE_TEMPLATE, {
    onCompleted: () => {
      notifySuccess('Message Template Updated')
      refetch()
    },
  })

  const onClose = () => {
    if (!template)
      setState({
        identifier: humanReadableUid(),
        index: '',
        body: '',
        title: '',
      })

    onCloseModal()
  }

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: value })

  const handleSubmit = async () => {
    setLoading(true)
    const body = richTextApi?.getJSON() ?? {}
    const bodyHtml = richTextApi?.getHTML() ?? ''

    if (template?.id) {
      await updateMessageTemplate({
        variables: {
          data: {
            title: state.title,
            body,
            bodyHtml,
            identifier: state.identifier,
            index: state.index,
          },
          where: {
            id: template.id,
          },
        },
      })
    } else {
      await createMessageTemplate({
        variables: {
          data: {
            body,
            bodyHtml,
            title: state.title,
            identifier: state.identifier,
            index: state.index,
            messageTemplateBook: {
              connect: {
                id: templateBookId ?? '',
              },
            },
          },
        },
      })
    }

    setLoading(false)
    onClose()
  }

  useEffect(() => {
    if (richTextApi) {
      richTextApi?.on('update', ({ editor }) => {
        setIsEmptyMsg(editor.isEmpty)
      })
    }
  }, [richTextApi])

  return (
    <Modal
      onClose={onClose}
      open={openModal}
      size="medium"
      title={template ? 'Update Message Template' : 'Create Message Template'}
      saveButton={{
        disabled: isEmptyMsg || !state.title,
        onClick: handleSubmit,
        loading,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Input
          label="Identifier"
          name="identifier"
          required
          value={state.identifier}
          disabled
        />
        <Form.Input
          label="Index"
          name="index"
          onChange={onChangeInput}
          required
          value={state.index}
        />
        <Form.Input
          label="Title"
          name="title"
          onChange={onChangeInput}
          required
          value={state.title}
        />
        <Form.Field>
          <label>Body</label>
          <RichText
            editable
            content={state.body}
            getRichTextApi={setRichTextApi}
          />
        </Form.Field>
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateTemplateModal
