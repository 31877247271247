import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { List, Image, Table } from 'semantic-ui-react'

import useUserMany from 'context/user/use-many'
import { isCustomer, isFORMStaff } from 'context/user/utils'
import { ButtonTooltip } from 'design-system/components/button'
import { TableUserListItem, StyledLabel } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { User } from 'types/user'
import CreateProjectModal from 'views/admin/create-user-project-modal'
import * as viewUtils from 'views/utils'

const UsersTable = () => {
  const [userToCreateProject, setUserToCreateProject] = useState<User | null>(
    null,
  )
  const { users } = useUserMany()

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Phone</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Projects</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {users.map((user) => (
          <Table.Row key={user.id}>
            <Table.Cell width={4}>
              <div>
                <TableUserListItem>
                  <Image
                    avatar
                    circular
                    src={user.picture}
                    style={{
                      minHeight: '35px',
                      minWidth: '35px',
                      marginRight: '10px',
                    }}
                  />
                  <List.Content>
                    <List.Header>
                      <span style={{ fontSize: '16px', textTransform: 'none' }}>
                        {viewUtils.getNameForUser(user)}
                      </span>
                      {isFORMStaff(user.type) ? (
                        <StyledLabel
                          style={{ marginLeft: '5px' }}
                          styled={{ gray: true }}
                        >
                          Staff
                        </StyledLabel>
                      ) : null}
                    </List.Header>
                    <List.Description>
                      {viewUtils.capsSnakeCaseToTitleCase(user.type)}
                    </List.Description>
                  </List.Content>
                </TableUserListItem>
              </div>
            </Table.Cell>
            <Table.Cell width={3}>{user.email}</Table.Cell>
            <Table.Cell width={3}>{user.phone}</Table.Cell>
            <Table.Cell width={3}>
              {user.type && viewUtils.capsSnakeCaseToTitleCase(user.type)}
            </Table.Cell>
            <Table.Cell width={2}>{user.projects?.length}</Table.Cell>
            <Table.Cell width={2}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  rowGap: '8px',
                }}
              >
                <ButtonTooltip
                  kind="solid"
                  fontAwesomeIcon="edit"
                  onClick={() => navigate(`/admin/users/${user.id}`)}
                  tooltipProps={{
                    label: 'Edit User',
                    color: Colors.blue700,
                  }}
                />
                {isCustomer(user.type) && (
                  <ButtonTooltip
                    kind="solid"
                    color="gray"
                    fontAwesomeIcon="folder-plus"
                    onClick={() => setUserToCreateProject(user)}
                    tooltipProps={{
                      label: 'Create project',
                      color: Colors.blue700,
                    }}
                  />
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      {userToCreateProject ? (
        <CreateProjectModal
          user={userToCreateProject}
          open={!!userToCreateProject}
          onClose={() => setUserToCreateProject(null)}
        />
      ) : null}
    </Table>
  )
}

export default UsersTable
