import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { isEqual } from 'lodash'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import CreateUpdateIssueForm from 'components/admin/project/issue/form'
import useIssue from 'context/issue/use'
import useIssueMutate from 'context/issue/use-mutate'
import useProjectIssueMany from 'context/project/issue/use-many'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import SaveToast from 'design-system/components/save-toast'
import { Issue, ResolutionType } from 'types/issue'
import { mapOrEmptyArray } from 'views/utils'

const IssueCreateUpdate = (
  _: RouteComponentProps<{ project_id: string; issue_id?: string }>,
) => {
  const { issue } = useIssue()
  const { project, damagePhotos, refetch: refetchProject } = useProject()
  const { refetch } = useProjectIssueMany()
  const {
    createIssueAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateIssueAndRefetch,
  } = useIssueMutate()

  const initialState = {
    type: issue?.type || null,
    resolutionType: issue?.resolutionType || ResolutionType.PENDING,
    data: issue?.data || {
      orderNumber: '',
      positionNumber: '',
    },
    delivery: issue?.delivery || null,
    files: issue?.files || [],
  }
  const [state, setState] = useState<NullableRecord<Issue>>(initialState)

  useEffect(() => {
    setState(initialState)
  }, [issue])

  const onSave = async () => {
    let createdId = ''
    if (issue) {
      await updateIssueAndRefetch({
        variables: {
          data: {
            data: state.data,
            type: state.type,
            resolutionType: state.resolutionType,
            files: {
              set: state.files?.map((d) => ({ id: d.id })) ?? [],
            },
            ...(state.deliveryId && {
              delivery: {
                connect: {
                  id: state.deliveryId,
                },
              },
            }),
          },
          where: {
            id: issue.id ?? '',
          },
        },
      })
    } else {
      const res = await createIssueAndRefetch({
        variables: {
          data: {
            data: state.data ?? {},
            type: state.type ?? undefined,
            resolutionType: state.resolutionType ?? undefined,
            ...(state.deliveryId && {
              delivery: {
                connect: {
                  id: state.deliveryId,
                },
              },
            }),
            files: {
              connect:
                mapOrEmptyArray(state.files, (d) => ({
                  id: d.id,
                })) ?? [],
            },
            project: {
              connect: {
                id: project.id,
              },
            },
          },
        },
      })
      createdId = res.data?.createOneIssue.id ?? ''
    }

    refetch()
    refetchProject()

    if (createdId) navigate(`/admin/projects/${project.id}/issues/${createdId}`)
  }

  return (
    <Grid>
      <ProjectPageTitle viewName="Issue" />
      <Grid.Row>
        <Grid.Column>
          <h5>{issue ? 'Edit Issue' : 'Create Issue'}</h5>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={16} widescreen={12}>
          <CreateUpdateIssueForm
            damagePhotos={damagePhotos}
            issue={state as Issue}
            onChange={setState}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
      <div style={{ margin: '20px 0' }}>
        <SaveToast
          extraOptions={
            <>
              or
              <Button
                color="dark"
                kind="outlined"
                text="Discard"
                onClick={() => navigate(`/admin/projects/${project.id}/issues`)}
              />
            </>
          }
          loading={loadingCreate || loadingUpdate}
          mainButtonText="Save"
          message="You have unsaved changes"
          onClick={onSave}
          show={!isEqual(initialState, state)}
        />
      </div>
    </Grid>
  )
}

export default IssueCreateUpdate
