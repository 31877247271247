import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import useAuth from 'context/auth/use'
import CommentNoteManyProvider from 'context/project/project-notes/provider-many'
import Button from 'design-system/components/button'

import Layout from './layout'

const SelectionCenterManagerLinks = (_: RouteComponentProps) => {
  const { formUserId } = useAuth()

  return (
    <CommentNoteManyProvider
      formUserId={formUserId}
      defaultFilters={{
        forMe: {
          id: formUserId,
        },
      }}
    >
      <Layout>
        <MainContainer>
          <Button
            kind="solid"
            text="Go to selection center app"
            fontAwesomeIcon="link"
            onClick={() => navigate('/selection-centers/admin')}
          />
        </MainContainer>
      </Layout>
    </CommentNoteManyProvider>
  )
}

export default SelectionCenterManagerLinks

const MainContainer = styled.div`
  & > div.filters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
  }
`
