import React from 'react'

import OrderTab from 'components/admin/project/order-form/tab'
import OrderProvider from 'context/order/provider'
import useOrderLookup from 'context/order/use-lookup'
import ProjectProvider from 'context/project/provider'
import { Modal } from 'design-system/components/modal'
import { FIND_UNIQUE_PROJECT_ORDER_CABINETRY } from 'queries/project'

interface OrderModalProps {
  onClose?: () => void
  openModal?: boolean
  onComplete: () => void
}

const OrderModal = ({ onClose, openModal, onComplete }: OrderModalProps) => {
  return (
    <Modal
      onClose={onClose}
      open={openModal}
      size="fullscreen"
      title="Order"
      customActions={null}
    >
      <OrderTab
        onComplete={() => {
          onComplete?.()
          onClose?.()
        }}
      />
    </Modal>
  )
}

interface OrderModalIdProps {
  open?: boolean
  onClose?: () => void
  orderId: string
  refetch?: () => void
}

const OrderModalId = ({
  open,
  onClose,
  orderId,
  refetch,
}: OrderModalIdProps) => {
  const { loading, order } = useOrderLookup(orderId)
  if (!order || loading) return null

  return (
    <ProjectProvider
      project_id={order.project?.id}
      query={FIND_UNIQUE_PROJECT_ORDER_CABINETRY}
      noLoader
    >
      <OrderProvider order_id={order.id} noLoader>
        <OrderModal
          onClose={onClose}
          openModal={open}
          onComplete={() => refetch?.()}
        />
      </OrderProvider>
    </ProjectProvider>
  )
}

export default OrderModalId
