// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion } from 'semantic-ui-react'

import { formatDollars } from 'context/dollar-utils'
import { StyledAccordion } from 'styles/planner/main'

import { ProductBase } from '../../../objects/product'
import {
  formatDimensionMillimetersWithSuffix,
  formatDimensionInches,
} from '../../../utils/measurement-utils'

interface ProductSummaryProps {
  product: ProductBase
}

const ProductSummaryMenu = ({ product }: ProductSummaryProps) => {
  const [active, setActive] = useState(false)

  return (
    <StyledAccordion open={active} fluid styled>
      <Accordion.Title
        active={active}
        onClick={() => setActive(!active)}
        style={{ justifyContent: 'space-between' }}
      >
        <p>Summary</p>
        <FontAwesomeIcon icon={['fal', active ? 'minus' : 'plus']} />
      </Accordion.Title>
      <Accordion.Content active={active} style={{ padding: '0 16px 16px' }}>
        <div className="category">
          <p>Price</p>
          <p>{formatDollars(product.getPrice())}</p>
        </div>
        <div className="category">
          <p>Width</p>
          <p>
            {formatDimensionMillimetersWithSuffix(product.getWidth())} /{' '}
            {formatDimensionInches(product.getWidth())}
          </p>
        </div>
        <div className="category">
          <p>Height</p>
          <p>
            {formatDimensionMillimetersWithSuffix(product.getHeight())} /{' '}
            {formatDimensionInches(product.getHeight())}
          </p>
        </div>
        <div className="category">
          <p>Depth</p>
          <p>
            {formatDimensionMillimetersWithSuffix(product.getDepth())} /{' '}
            {formatDimensionInches(product.getDepth())}
          </p>
        </div>
      </Accordion.Content>
    </StyledAccordion>
  )
}

export default ProductSummaryMenu
