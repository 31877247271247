import { gql } from '@apollo/client'

import { User } from 'types/user'

export const ARE_SALES_DESIGNERS_REQUESTING_NEW_PROJECTS = gql`
  query areSalesDesignersRequestingNewProjects(
    $data: AreSalesDesignersRequestingNewProjectsInput!
  ) {
    areSalesDesignersRequestingNewProjects(data: $data)
  }
`

export interface AreSalesDesignersRequestingNewProjectsPayload {
  areSalesDesignersRequestingNewProjects: boolean
}

export interface AreSalesDesignersRequestingNewProjectsVariables {
  data: {
    currentDesignerId: string
  }
}

export const ASSIGN_NEXT_SALES_DESIGNER_IN_QUEUE = gql`
  mutation assignNextSalesDesignerInQueue(
    $data: AssignNextSalesDesignerInQueueInput!
  ) {
    assignNextSalesDesignerInQueue(data: $data)
  }
`

export interface AssignNextSalesDesignerInQueuePayload {
  assignNextSalesDesignerInQueue: boolean
}

export interface AssignNextSalesDesignerInQueueVariables {
  data: {
    prospectId: string
    isForced?: boolean
  }
}

export const GET_NEXT_TRADE_DESIGNER_IN_QUEUE = gql`
  query getNextTradeDesignerInQueue {
    proposedTradeDesigner: getNextTradeDesignerInQueue {
      calendlyId
      calendlyUrlSlugProjectConsultation
    }
  }
`

export interface GetNextTradeDesignerInQueuePayload {
  proposedTradeDesigner: {
    calendlyId: string
    calendlyUrlSlugProjectConsultation: string
  }
}

export const GET_SALES_DESIGNER_NEW_PROSPECT_QUEUE = gql`
  query getSalesDesignerNewProspectQueue {
    designers: getSalesDesignerNewProspectQueue {
      id
      auth0Id
      data
      email
      firstName
      lastName
      phone
      picture
      type
    }
  }
`

export const GET_SALES_DESIGNER_NEW_PROJECT_QUEUE = gql`
  query getSalesDesignerNewProjectQueue {
    designers: getSalesDesignerNewProjectQueue {
      id
      auth0Id
      data
      email
      firstName
      lastName
      phone
      picture
      type
    }
  }
`

export const GET_TRADE_DESIGNER_NEW_PROSPECT_QUEUE = gql`
  query getTradeDesignerNewProspectQueue {
    designers: getTradeDesignerNewProspectQueue {
      id
      auth0Id
      data
      email
      firstName
      lastName
      phone
      picture
      type
    }
  }
`
export const GET_TRADE_DESIGNER_NEW_PROJECT_QUEUE = gql`
  query getTradeDesignerNewProjectQueue {
    designers: getTradeDesignerNewProjectQueue {
      id
      auth0Id
      data
      email
      firstName
      lastName
      phone
      picture
      type
    }
  }
`

export const GET_TECH_DESIGNER_NEW_PROJECT_QUEUE = gql`
  query getTechDesignerNewProjectQueue {
    designers: getTechDesignerNewProjectQueue {
      id
      auth0Id
      data
      email
      firstName
      lastName
      phone
      picture
      type
    }
  }
`

export const GET_TECH_DESIGNER_SUPPORT_NEW_PROJECT_QUEUE = gql`
  query getTechDesignerSupportNewProjectQueue {
    designers: getTechDesignerSupportNewProjectQueue {
      id
      auth0Id
      data
      email
      firstName
      lastName
      phone
      picture
      type
    }
  }
`

export interface UserQueuePayload {
  designers: User[]
}
