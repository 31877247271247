import React from 'react'

import { CatalogLabel } from 'styles/admin/main'
import { Media } from 'types/media'
import { camelCaseToTitleCase } from 'views/utils'

const AssociationLabels = ({ media }: { media: Partial<Media> }) => {
  const CardLabel = ({ content, icon }: { content: string; icon: string }) => (
    <CatalogLabel
      content={<p>{content}</p>}
      icon={<img src={icon} />}
      styled={{ color: 'green' }}
    />
  )

  return (
    <>
      {media.catalogFeatures?.length ? (
        <CardLabel
          content={media.catalogFeatures
            .map((f) => `${f.description}`)
            .join(', ')}
          icon={`${process.env.GATSBY_CDN_ROOT}/images/catalog/front.png`}
        />
      ) : null}
      {media.genericOptions?.length ? (
        <CardLabel
          content={media.genericOptions
            .map(
              (f) =>
                `${camelCaseToTitleCase(f.featureIdentifier)} - ${
                  f.description
                }`,
            )
            .join(', ')}
          icon={`${process.env.GATSBY_CDN_ROOT}/images/catalog/front.png`}
        />
      ) : null}
      {media.nobiliaOptions?.length ? (
        <CardLabel
          content={media.nobiliaOptions
            .map((f) => `${f.optionKey} - ${f.description}`)
            .join(', ')}
          icon={`${process.env.GATSBY_CDN_ROOT}/images/catalog/front.png`}
        />
      ) : null}
      {media.nobiliaProductClasses?.length ? (
        <CardLabel
          content={media.nobiliaProductClasses
            .map((npc) => npc.orderId)
            .join(', ')}
          icon={`${process.env.GATSBY_CDN_ROOT}/images/catalog/product-class.png`}
        />
      ) : null}
      {media.roomElementOptions?.length ? (
        <CardLabel
          content={media.roomElementOptions
            .map(
              (f) =>
                `${camelCaseToTitleCase(f.featureIdentifier)} - ${
                  f.description
                }`,
            )
            .join(', ')}
          icon={`${process.env.GATSBY_CDN_ROOT}/images/catalog/front.png`}
        />
      ) : null}
    </>
  )
}

export default AssociationLabels
