import React, { useEffect, useState } from 'react'

import { Divider } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import { UnderlineSubtitle } from 'components/shared/utils'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox } from 'styles/admin/main'
import { PaymentType } from 'types/payment'
import { Project, ProjectData } from 'types/project'

interface TradeSettingsModalProps {
  onClose: () => void
  onSave: (data: Partial<ProjectData>) => void
  open: boolean
  project: Project
}

export const TradeSettingsModal = ({
  onClose,
  onSave,
  open,
  project,
}: TradeSettingsModalProps) => {
  const [checked, setChecked] = useState<boolean>(false)
  const hasTradeDeposit = project.payments.find(
    (p) => p.metadata.type === PaymentType.PROJECT_DEPOSIT,
  )
  const { noBlockOrderSamples, skipDesignDeposit, skipProjectConsultation } =
    project.data

  const initialState = {
    noBlockOrderSamples,
    skipDesignDeposit,
    skipProjectConsultation,
  }

  const [state, setState] = useState(initialState)

  useEffect(() => {
    setState({
      noBlockOrderSamples,
      skipDesignDeposit,
      skipProjectConsultation,
    })
  }, [project.data])

  const handleClose = () => {
    onClose()
    setChecked(false)
  }

  return (
    <Modal
      onClose={() => {
        handleClose()
        setState(initialState)
      }}
      open={open}
      size="small"
      title="Trade Settings"
      customActions={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <FormCheckbox
            checked={checked}
            label="I’m sure about these changes"
            onChange={() => setChecked(!checked)}
          />
          <Button
            disabled={!checked}
            kind="solid"
            onClick={() => {
              onSave(state)
              handleClose()
            }}
            text="Save"
          />
        </div>
      }
    >
      <div style={{ padding: '8px 16px' }}>
        <h6>Please choose the settings of this project</h6>
        <Divider hidden />
        <UnderlineSubtitle>Video consultation</UnderlineSubtitle>
        <FormCheckbox
          checked={state.skipProjectConsultation}
          label="Skip Project Consultation"
          onChange={() =>
            setState({
              ...state,
              skipProjectConsultation: !state.skipProjectConsultation,
            })
          }
        />
        <Divider hidden />
        <UnderlineSubtitle>Samples</UnderlineSubtitle>
        <FormCheckbox
          checked={state.noBlockOrderSamples}
          label="Don’t block samples on Design Deposit"
          onChange={() =>
            setState({
              ...state,
              noBlockOrderSamples: !state.noBlockOrderSamples,
            })
          }
        />
        <Divider hidden />
        <UnderlineSubtitle>Payment</UnderlineSubtitle>
        {!hasTradeDeposit && (
          <>
            <HelpMessage
              title="Create a Trade Deposit"
              message="Create a Trade Deposit to skip Design Deposit"
            />
            <Divider hidden />
          </>
        )}
        <FormCheckbox
          disabled={!hasTradeDeposit && !state.skipDesignDeposit}
          checked={state.skipDesignDeposit}
          label="Skip Design Deposit - Straight to Trade Deposit"
          onChange={() =>
            setState({
              ...state,
              skipDesignDeposit: !state.skipDesignDeposit,
            })
          }
        />
        <Divider hidden />
      </div>
    </Modal>
  )
}

export default TradeSettingsModal
