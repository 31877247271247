import React from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectLayout from 'components/admin/project/layout'
import ProjectIssueManyProvider from 'context/project/issue/provider-many'

interface IssueIndexManyProps
  extends RouteComponentProps<{
    project_id: string
  }> {
  children?: React.ReactNode
}

export default (props: IssueIndexManyProps) => (
  <ProjectLayout>
    <ProjectIssueManyProvider>{props.children}</ProjectIssueManyProvider>
  </ProjectLayout>
)
