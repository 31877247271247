import React from 'react'

import styled from 'styled-components'

import Button from 'design-system/components/button/button'

type ConversationUserModalActionsProps = {
  showCreateNewUser: boolean
  setShowCreateNewUser: (showCreateNewUser: boolean) => void
  showConfirmation: boolean
  setShowConfirmation: (showConfirmation: boolean) => void
  onCancel: () => void
  onSave: () => void
  disableSave: boolean
  loading: boolean
  disableCreateNewUser?: boolean
}

export const ConversationUserModalActions = ({
  showCreateNewUser,
  setShowCreateNewUser,
  onCancel,
  onSave,
  disableSave,
  loading,
  disableCreateNewUser,
  showConfirmation,
  setShowConfirmation,
}: ConversationUserModalActionsProps) => {
  const saveBtnText = showCreateNewUser ? 'Create User and Connect' : 'Save'

  return (
    <ActionDiv>
      <Button
        kind="solid"
        color="dark"
        text={showCreateNewUser ? 'Back to User Select' : 'Create New User'}
        fontAwesomeIcon={showCreateNewUser ? 'arrow-left' : 'plus'}
        iconPosition={showCreateNewUser ? 'left' : 'right'}
        onClick={() => setShowCreateNewUser(!showCreateNewUser)}
        disabled={disableCreateNewUser}
      />
      <Button
        kind="outlined"
        text="Cancel"
        color="gray"
        onClick={showConfirmation ? () => setShowConfirmation(false) : onCancel}
      />
      <Button
        kind="solid"
        text={showConfirmation ? 'Confirm' : saveBtnText}
        disabled={disableSave}
        loading={loading}
        onClick={showConfirmation ? onSave : () => setShowConfirmation(true)}
      />
    </ActionDiv>
  )
}

const ActionDiv = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto max-content max-content;
  width: 100%;
`
