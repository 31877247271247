import React from 'react'

import SupplierOrderTab from 'components/admin/project/supplier-order/tab'
import { getAddressString } from 'context/address/utils'
import ProjectProvider from 'context/project/provider'
import useProject from 'context/project/use'
import SupplierOrderProvider from 'context/supplier-order/provider'
import useSupplierOrder from 'context/supplier-order/use'
import useSupplierOrderLookup from 'context/supplier-order/use-lookup'
import { getSupplierOrderAddress } from 'context/supplier-order/utils'
import { Modal } from 'design-system/components/modal'
import { FIND_UNIQUE_PROJECT_SUPPLIER_ORDER } from 'queries/project'

interface SupplierOrderModalProps {
  onClose?: () => void
  open?: boolean
  onComplete: () => void
  refetchMany?: () => Promise<unknown>
}

const SupplierOrderModal = ({
  onClose,
  open,
  onComplete,
  refetchMany,
}: SupplierOrderModalProps) => {
  const { project } = useProject()
  const { supplierOrder } = useSupplierOrder()

  const address = getSupplierOrderAddress({
    ...supplierOrder,
    project: project,
  })

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="fullscreen"
      title={`${
        supplierOrder.nobiliaOrderRef ||
        supplierOrder.data?.nobiliaOrderReference ||
        ''
      }, ACK ${
        supplierOrder.nobiliaAckNumber ||
        supplierOrder.data?.nobiliaFactoryAckNumber ||
        ''
      } - ${getAddressString(address)}`}
    >
      <SupplierOrderTab
        onComplete={async () => {
          onComplete?.()
          onClose?.()
        }}
        refetchMany={refetchMany}
      />
    </Modal>
  )
}

interface SupplierOrderModalIdProps {
  onClose?: () => void
  open?: boolean
  refetch?: () => Promise<unknown>
  supplierOrderId: string
}

const SupplierOrderModalId = ({
  open,
  onClose,
  refetch,
  supplierOrderId,
}: SupplierOrderModalIdProps) => {
  const { loading, supplierOrder } = useSupplierOrderLookup(supplierOrderId)
  if (!supplierOrder || loading) return null

  return (
    <ProjectProvider
      project_id={supplierOrder.project?.id}
      query={FIND_UNIQUE_PROJECT_SUPPLIER_ORDER}
      noLoader
    >
      <SupplierOrderProvider supplier_order_id={supplierOrder.id} noLoader>
        <SupplierOrderModal
          onClose={onClose}
          onComplete={() => refetch?.()}
          open={open}
          refetchMany={refetch}
        />
      </SupplierOrderProvider>
    </ProjectProvider>
  )
}

export default SupplierOrderModalId
