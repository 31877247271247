import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import moment from 'moment'
import { Grid, Image, Search, SearchProps } from 'semantic-ui-react'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'

import { Labels } from '../utils'

const SamplesModal = ({
  samples,
  selectedSamples,
  setSamples,
}: {
  samples: NobiliaSampleClass[]
  selectedSamples: NobiliaSampleClass[]
  setSamples: (samplesId: string[]) => void
}) => {
  const [modalSamples, setModalSamples] =
    useState<NobiliaSampleClass[]>(selectedSamples)
  const [search, setSearch] = useState<{
    results: { key: string; title: string }[] | null
    value: string
  }>({
    results: null,
    value: '',
  })
  const [showModal, toggleModal] = useState<boolean>(false)

  useEffect(() => {
    setModalSamples(selectedSamples)
  }, [selectedSamples])

  const onClose = () => {
    setModalSamples(selectedSamples)
    toggleModal(false)
  }

  const searchElements = samples.map((s) => ({
    key: s.id,
    title: s.description,
  }))

  const handleResultSelect = (
    _e: React.MouseEvent,
    { result }: { result: { key: string; title: string } },
  ) => setSearch({ results: [result], value: result.title })

  const handleSearchChange = (_e: React.MouseEvent, { value }: SearchProps) =>
    setSearch({
      results: searchElements.filter((e) =>
        e.title.toLowerCase().includes((value as string).toLowerCase()),
      ),
      value: value as string,
    })

  const unParseFeatures = (results: { key: string; title: string }[]) =>
    samples.filter((f) => results.map((r) => r.key).includes(f.id))

  const renderSamples = (samples: NobiliaSampleClass[]) => {
    return samples.map((sc, key) => {
      const active = modalSamples.find((sf) => sf.id === sc.id)
      return (
        <Grid.Column
          key={key}
          style={{ cursor: 'pointer', marginBottom: '20px' }}
          {...(!active && {
            onClick: () => setModalSamples(modalSamples.concat(sc)),
          })}
        >
          {sc.meta.outOfStock && (
            <OutOfStock>
              <div style={{ textAlign: 'center' }}>
                <b>
                  Out of Stock
                  <br />
                  {`Expected back: ${moment(sc.meta.expectedRestockDate).format(
                    'LL',
                  )}`}
                </b>
              </div>
            </OutOfStock>
          )}
          {active && (
            <Hover>
              <Button
                color="gray"
                kind="outlined"
                text="Remove"
                onClick={() =>
                  setModalSamples(modalSamples.filter((sf) => sf.id !== sc.id))
                }
              />
            </Hover>
          )}

          <Image
            style={{ marginBottom: '8px' }}
            src={`${process.env.GATSBY_CDN_ROOT}/${sc.imageUrl}`}
          />
          <p>
            <b>{sc.description}</b>
          </p>
        </Grid.Column>
      )
    })
  }

  return (
    <Modal
      onClose={onClose}
      open={showModal}
      size="large"
      title="Samples"
      trigger={
        <Button
          kind="outlined"
          text={
            selectedSamples.length > 0 ? 'Change samples' : 'Choose samples'
          }
          onClick={() => toggleModal(true)}
        />
      }
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={13}>
              <Labels>
                {modalSamples.map((f) => (
                  <div className="selection" key={f.id}>
                    <p>{f.description}</p>
                  </div>
                ))}
              </Labels>
            </Grid.Column>
            <Grid.Column width={3}>
              <div className="flex-center" style={{ flexWrap: 'unset' }}>
                <Button
                  kind="outlined"
                  text="Close"
                  color="gray"
                  onClick={onClose}
                  style={{ marginRight: '20px' }}
                />
                <Button
                  kind="solid"
                  text="Save"
                  onClick={() => {
                    setSamples(_.uniq(modalSamples.map((s) => s.id)))
                    toggleModal(false)
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={5} floated="right">
            <Search
              onResultSelect={handleResultSelect}
              onSearchChange={handleSearchChange}
              placeholder="Search feature by name"
              results={search.results ?? undefined}
              value={search.value}
            />
          </Grid.Column>
        </Grid.Row>
        {search.results && !!search.value ? (
          <Grid.Row columns={4}>
            {renderSamples(unParseFeatures(search.results))}
          </Grid.Row>
        ) : (
          <Grid.Row columns={4}>{renderSamples(samples)}</Grid.Row>
        )}
      </Grid>
    </Modal>
  )
}

export default SamplesModal

const Hover = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 90%;
  z-index: 1;
`

const OutOfStock = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  height: 25%;
  justify-content: center;
  position: absolute;
  width: 90%;
  z-index: 1;
`
