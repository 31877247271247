// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import { useQuery } from '@apollo/client'
import { Card, Grid } from 'semantic-ui-react'

import {
  FIND_MANY_ROOM_ELEMENT_CLASS,
  FindManyRoomElementClassPayload,
  FindManyRoomElementClassVariables,
} from 'queries/room-element-class'
import { StyledCard } from 'styles/planner/main'
import { RoomElementClass } from 'types/room-element-class'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import { RoomElementTypesToCreate } from '../utils'

interface RoomElementsProps {
  onCreate: (roomElementClass: RoomElementClass) => void
}

const RoomElementsMenu = (props: RoomElementsProps) => {
  const { data, loading } = useQuery<
    FindManyRoomElementClassPayload,
    FindManyRoomElementClassVariables
  >(FIND_MANY_ROOM_ELEMENT_CLASS)

  if (loading) return null

  return (
    <Grid>
      <Grid.Row columns={3}>
        {data.roomElementClasses
          .filter((rec) =>
            RoomElementTypesToCreate.includes(rec.roomElementType),
          )
          .map((rec) => (
            <Grid.Column key={rec.identifier}>
              <StyledCard
                onClick={() => {
                  return props.onCreate(rec)
                }}
              >
                <Card.Content>
                  <p>{capsSnakeCaseToTitleCase(rec.roomElementType)}</p>
                </Card.Content>
              </StyledCard>
            </Grid.Column>
          ))}
      </Grid.Row>
    </Grid>
  )
}

export default RoomElementsMenu
