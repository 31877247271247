import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_ROUTE,
  CreateRoutePayload,
  CreateRouteVariables,
  UPDATE_ROUTE,
  UpdateRoutePayload,
  UpdateRouteVariables,
} from 'queries/route'

import { RouteContext } from './provider'

const useRouteMutate = () => {
  const { refetch } = useContext(RouteContext)

  const [createRouteAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateRoutePayload,
    CreateRouteVariables
  >(CREATE_ROUTE, {
    onCompleted: refetch,
  })
  const [updateRouteAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateRoutePayload,
    UpdateRouteVariables
  >(UPDATE_ROUTE, {
    onCompleted: refetch,
  })

  return {
    createRouteAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateRouteAndRefetch,
  }
}

export default useRouteMutate
