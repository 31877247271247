import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NobiliaSampleClassManyProvider from 'context/nobilia-sample-class/provider-many'

type SampleOrdersIndexProps = RouteComponentProps<{ catalog_id: string }> & {
  children: React.ReactNode
}

const SampleOrdersIndex = ({
  catalog_id,
  children,
}: SampleOrdersIndexProps) => {
  return (
    <NobiliaSampleClassManyProvider
      catalog={catalog_id}
      defaultFilters={{
        sortBy: 'identifier',
        sortDirection: 'ascending',
        stock: 'inStock',
        take: 50,
      }}
    >
      {children}
    </NobiliaSampleClassManyProvider>
  )
}

export default SampleOrdersIndex
