import React from 'react'

import moment from 'moment'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { parseOwners } from 'context/project/utils'
import Button from 'design-system/components/button'
import { FormCheckbox } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { Task } from 'types/task'
import * as viewUtils from 'views/utils'

const TaskCard = ({
  noActions,
  onCheck,
  onDelete,
  onEdit,
  projectView,
  task,
}: {
  noActions?: boolean
  onCheck: () => void
  onDelete?: () => void
  onEdit?: () => void
  projectView?: boolean
  task: Task
}) => {
  const { primaryOwner } = parseOwners(task.project)

  return (
    <StyledCard
      styled={{
        green: !!task.data.completedAt,
        red:
          !task.data.completedAt &&
          task.data.dueAt &&
          moment(task.data.dueAt) < moment(),
      }}
    >
      <div style={{ flex: '0 1 40px' }}>
        <FormCheckbox checked={!!task.data.completedAt} onChange={onCheck} />
      </div>
      <div className="avatar-text" style={{ flex: '1 0 18%' }}>
        {projectView ? (
          <>
            <Image avatar src={task.owner.picture} />
            <p>{viewUtils.getNameForUser(task.owner)}</p>
          </>
        ) : (
          <>
            <Image avatar src={primaryOwner?.picture} />
            <a
              onClick={(e) => {
                e.preventDefault()
                window.open(`/admin/projects/${task.project?.id}/overview`)
              }}
              style={{ cursor: 'pointer' }}
            >
              <p style={{ wordBreak: 'break-all' }}>
                {viewUtils.getShortNameForUser(
                  parseOwners(task.project)?.primaryOwner,
                )}
              </p>
            </a>
          </>
        )}
      </div>
      <div className="avatar-text" style={{ flex: '1 0 40%' }}>
        <p style={{ wordBreak: 'break-word' }}>{task.data.description}</p>
      </div>
      <div style={{ flex: '0 1 200px' }}>
        {task.data.dueAt && <p>Due {moment(task.data.dueAt).fromNow()}</p>}
      </div>
      {!noActions && (
        <div className="actions">
          {onEdit && (
            <Button
              fontAwesomeIcon="pen-to-square"
              kind="ghost"
              onClick={onEdit}
            />
          )}
          {onDelete && (
            <Button fontAwesomeIcon="trash" kind="ghost" onClick={onDelete} />
          )}
        </div>
      )}
    </StyledCard>
  )
}

export default TaskCard

const StyledCard = styled.div<{
  styled?: {
    green?: boolean
    red?: boolean
  }
}>`
  align-items: center;
  background: ${({ styled }) => (styled?.red ? Colors.red50 : Colors.white)};
  display: flex;
  border-radius: 4px;
  border: ${({ styled }) =>
    styled?.green
      ? `1px solid ${Colors.green300}`
      : styled?.red
      ? `1px solid ${Colors.red200}`
      : 'none'};
  gap: 16px;
  justify-content: space-between;
  padding: 16px 14px 16px 22px;

  div.actions {
    display: flex;
    flex-direction: row;
  }

  div.avatar-text {
    align-items: center;
    display: flex;
    gap: 6px;

    p {
      margin: 0;
    }
  }

  p {
    margin: 0;
  }
`
