import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { Form, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import useMediaMutate from 'context/media/use-mutate'
import { getMediaDisplay } from 'context/media/utils-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import * as NO from 'planner/objects/products/nobilia/options/definitions'
import {
  FIND_MANY_CATALOG_FEATURE,
  FindManyCatalogFeaturePayload,
  FindManyCatalogFeatureVariables,
} from 'queries/catalog-feature'
import {
  FIND_MANY_GENERIC_OPTION,
  FindManyGenericOptionPayload,
  FindManyGenericOptionVariables,
} from 'queries/generic-option'
import {
  FIND_MANY_GENERIC_PRODUCT_CLASS,
  FindManyGenericProductClassPayload,
  FindManyGenericProductClassVariables,
} from 'queries/generic-product-class'
import {
  FIND_MANY_NOBILIA_OPTION,
  FindManyNobiliaOptionPayload,
  FindManyNobiliaOptionVariables,
} from 'queries/nobilia-option'
import {
  FIND_MANY_NOBILIA_PRODUCT_CLASS_PARTIAL,
  FindManyNobiliaProductClassPayload,
  FindManyNobiliaProductClassVariables,
} from 'queries/nobilia-product-class'
import {
  FIND_MANY_ROOM_ELEMENT_CLASS,
  FindManyRoomElementClassPayload,
  FindManyRoomElementClassVariables,
} from 'queries/room-element-class'
import {
  FIND_MANY_ROOM_ELEMENT_OPTION,
  FindManyRoomElementOptionPayload,
  FindManyRoomElementOptionVariables,
} from 'queries/room-element-option'
import { StyledForm, StyledRadio, VideoCard } from 'styles/admin/main'
import { CatalogFeature } from 'types/catalog-feature'
import { GenericOption } from 'types/generic-option'
import { GenericProductClass } from 'types/generic-product-class'
import { Media, MediaCategory, MediaCategoryIcons } from 'types/media'
import { NobiliaOption } from 'types/nobilia-option'
import { NobiliaProductClass } from 'types/nobilia-product-class'
import { RoomElementClass } from 'types/room-element-class'
import { RoomElementOption } from 'types/room-element-option'
import * as viewUtils from 'views/utils'

import UploadMediaCard from './upload-card'

interface MediaModalProps {
  catalogFeatures?: Partial<CatalogFeature>[]
  genericOptions?: Partial<GenericOption>[]
  genericProductClasses?: Partial<GenericProductClass>[]
  media?: Partial<Media>
  nobiliaOptions?: Partial<NobiliaOption>[]
  nobiliaProductClasses?: Partial<NobiliaProductClass>[]
  onClose?: () => void
  refetch?: () => Promise<unknown>
  roomElementClasses?: Partial<RoomElementClass>[]
  roomElementOptions?: Partial<RoomElementOption>[]
  trigger: JSX.Element
}

const MediaModal = ({
  catalogFeatures: catalogFeatures_,
  genericOptions: genericOptions_,
  genericProductClasses: genericProductClasses_,
  media,
  nobiliaOptions: nobiliaOptions_,
  nobiliaProductClasses: nobiliaProductClasses_,
  onClose,
  refetch,
  roomElementClasses: roomElementClasses_,
  roomElementOptions: roomElementOptions_,
  trigger,
}: MediaModalProps) => {
  const initialState: Media = {
    id: media?.id,
    catalogFeatures: media?.catalogFeatures || catalogFeatures_ || [],
    category: media?.category || ('' as MediaCategory),
    createdAt: media?.createdAt || '',
    data: media?.data || {},
    genericOptions: media?.genericOptions || genericOptions_ || [],
    genericProductClasses:
      media?.genericProductClasses || genericProductClasses_ || [],
    key: media?.key || '',
    name: media?.name || '',
    nobiliaOptions: media?.nobiliaOptions || nobiliaOptions_ || [],
    nobiliaProductClasses:
      media?.nobiliaProductClasses || nobiliaProductClasses_ || [],
    roomElementClasses: media?.roomElementClasses || roomElementClasses_ || [],
    roomElementOptions: media?.roomElementOptions || roomElementOptions_ || [],
    updatedAt: media?.createdAt || '',
  }
  const [showModal, toggleModal] = useState<boolean>(false)
  const [state, setState] = useState<Media>(initialState)

  const [catalogFeatureSearch, setCatalogFeatureSearch] = useState<string>('')
  const [genericOptionSearch, setGenericOptionSearch] = useState<string>('')
  const [genericProductClassSearch, setGenericProductClassSearch] =
    useState<string>('')
  const [nobiliaOptionSearch, setNobiliaOptionSearch] = useState<string>('')
  const [nobiliaProductClassSearch, setNobiliaProductClassSearch] =
    useState<string>('')
  const [roomElementClassSearch, setRoomElementClassSearch] =
    useState<string>('')
  const [roomElementOptionSearch, setRoomElementOptionSearch] =
    useState<string>('')

  useEffect(() => {
    setState(initialState)
  }, [media])

  const { data: { catalogFeatures } = { catalogFeatures: [] } } = useQuery<
    FindManyCatalogFeaturePayload,
    FindManyCatalogFeatureVariables
  >(FIND_MANY_CATALOG_FEATURE, {
    skip: !showModal || !catalogFeatureSearch,
    variables: {
      where: {
        description: {
          contains: catalogFeatureSearch,
        },
      },
      orderBy: {
        identifier: 'asc',
      },
    },
  })

  const { data: { genericOptions } = { genericOptions: [] } } = useQuery<
    FindManyGenericOptionPayload,
    FindManyGenericOptionVariables
  >(FIND_MANY_GENERIC_OPTION, {
    skip: !showModal || !genericOptionSearch,
    variables: {
      where: {
        description: {
          contains: genericOptionSearch,
        },
      },
      orderBy: {
        identifier: 'asc',
      },
    },
  })

  const { data: { genericProductClasses } = { genericProductClasses: [] } } =
    useQuery<
      FindManyGenericProductClassPayload,
      FindManyGenericProductClassVariables
    >(FIND_MANY_GENERIC_PRODUCT_CLASS, {
      skip: !showModal || !genericProductClassSearch,
      variables: {
        where: {
          identifier: {
            contains: genericProductClassSearch,
          },
        },
        orderBy: {
          identifier: 'asc',
        },
      },
    })

  const { data: { nobiliaOptions } = { nobiliaOptions: [] } } = useQuery<
    FindManyNobiliaOptionPayload,
    FindManyNobiliaOptionVariables
  >(FIND_MANY_NOBILIA_OPTION, {
    skip: !showModal || !nobiliaOptionSearch,
    variables: {
      orderBy: {
        optionKey: 'asc',
      },
      where: {
        featureNo: {
          in: [
            NO.FRONT_COMBO,
            NO.HANDLE_COMBINATION,
            NO.GLASS_DESIGN,
            NO.GLASS_DOOR_FRAME_DESIGN,
            NO.CARCASE_COLOR_EXTERIOR,
            NO.CARCASE_COLOR_INTERIOR,
            NO.PLINTH_COLOR,
            NO.CEILING_PANEL_COLOR,
            NO.UPRIGHT_BAR_COLOR,
          ],
        },
        description: {
          contains: nobiliaOptionSearch,
        },
      },
    },
  })

  const { data: { nobiliaProductClasses } = { nobiliaProductClasses: [] } } =
    useQuery<
      FindManyNobiliaProductClassPayload,
      FindManyNobiliaProductClassVariables
    >(FIND_MANY_NOBILIA_PRODUCT_CLASS_PARTIAL, {
      skip: !showModal || !nobiliaProductClassSearch,
      variables: {
        orderBy: {
          derivedSort: 'asc',
        },
        where: {
          orderId: {
            contains: nobiliaProductClassSearch,
          },
        },
      },
    })

  const { data: { roomElementClasses } = { roomElementClasses: [] } } =
    useQuery<
      FindManyRoomElementClassPayload,
      FindManyRoomElementClassVariables
    >(FIND_MANY_ROOM_ELEMENT_CLASS, {
      skip: !showModal || !roomElementClassSearch,
      variables: {
        orderBy: {
          identifier: 'asc',
        },
        where: {
          identifier: {
            contains: roomElementClassSearch,
          },
        },
      },
    })

  const { data: { roomElementOptions } = { roomElementOptions: [] } } =
    useQuery<
      FindManyRoomElementOptionPayload,
      FindManyRoomElementOptionVariables
    >(FIND_MANY_ROOM_ELEMENT_OPTION, {
      skip: !showModal || !roomElementOptionSearch,
      variables: {
        orderBy: {
          identifier: 'asc',
        },
        where: {
          description: {
            contains: roomElementOptionSearch,
          },
        },
      },
    })

  const { createMedia, loadingCreate, loadingUpdate, updateMedia } =
    useMediaMutate()

  const onSave = async () => {
    if (state.id) {
      await updateMedia({
        variables: {
          data: {
            catalogFeatures: {
              set:
                viewUtils.mapOrEmptyArray(state.catalogFeatures, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            category: state.category,
            data: state.data,
            genericOptions: {
              set:
                viewUtils.mapOrEmptyArray(state.genericOptions, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            genericProductClasses: {
              set:
                viewUtils.mapOrEmptyArray(state.genericProductClasses, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            key: state.key,
            name: state.name,
            nobiliaOptions: {
              set:
                viewUtils.mapOrEmptyArray(state.nobiliaOptions, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            nobiliaProductClasses: {
              set:
                viewUtils.mapOrEmptyArray(state.nobiliaProductClasses, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            roomElementClasses: {
              set:
                viewUtils.mapOrEmptyArray(state.roomElementClasses, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            roomElementOptions: {
              set:
                viewUtils.mapOrEmptyArray(state.roomElementOptions, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
          },
          where: { id: state.id },
        },
        onCompleted: refetch,
      })
    } else {
      await createMedia({
        variables: {
          data: {
            catalogFeatures: {
              connect:
                viewUtils.mapOrEmptyArray(state.catalogFeatures, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            category: state.category,
            data: state.data,
            genericOptions: {
              connect:
                viewUtils.mapOrEmptyArray(state.genericOptions, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            genericProductClasses: {
              connect:
                viewUtils.mapOrEmptyArray(state.genericProductClasses, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            key: state.key,
            name: state.name,
            nobiliaOptions: {
              connect:
                viewUtils.mapOrEmptyArray(state.nobiliaOptions, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            nobiliaProductClasses: {
              connect:
                viewUtils.mapOrEmptyArray(state.nobiliaProductClasses, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            roomElementClasses: {
              connect:
                viewUtils.mapOrEmptyArray(state.roomElementClasses, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
            roomElementOptions: {
              connect:
                viewUtils.mapOrEmptyArray(state.roomElementOptions, (p) => ({
                  id: p.id as string,
                })) ?? [],
            },
          },
        },
        onCompleted: refetch,
      })
    }
    onCloseModal()
  }

  const onCloseModal = () => {
    setState(initialState)
    setCatalogFeatureSearch('')
    setGenericProductClassSearch('')
    setGenericOptionSearch('')
    setNobiliaOptionSearch('')
    setNobiliaProductClassSearch('')
    setRoomElementClassSearch('')
    setRoomElementOptionSearch('')
    toggleModal(false)
    if (onClose) onClose()
  }

  const renderMedia = () => {
    const { display, isVideo } = getMediaDisplay(state.key, true)
    return isVideo ? (
      <VideoCard>
        <video controls playsInline src={display} />
      </VideoCard>
    ) : (
      <Image
        centered
        src={display}
        style={{ maxHeight: '500px', width: 'auto' }}
      />
    )
  }

  const RadioField = ({ category }: { category: string }) => (
    <Form.Field>
      <StyledRadio
        label={
          <RadioLabel>
            <img src={MediaCategoryIcons[category]} />
            {viewUtils.capsSnakeCaseToTitleCase(category)}
          </RadioLabel>
        }
        value={category}
        checked={state.category === category}
        onChange={(_: React.ChangeEvent<HTMLInputElement>) => {
          setState({
            ...state,
            category: category as MediaCategory,
            data: {
              ...state.data,
              ...(category !== 'special'
                ? {
                    specialTag: '',
                  }
                : null),
            },
          })
        }}
      />
    </Form.Field>
  )

  return (
    <Modal
      title="Media"
      onClose={() => onCloseModal()}
      onOpen={() => toggleModal(true)}
      open={showModal}
      trigger={trigger}
      size="fullscreen"
      saveButton={{
        disabled: !state.key || !state.category,
        loading: loadingUpdate || loadingCreate,
        onClick: onSave,
        text: 'Upload',
      }}
    >
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="center" width={7}>
            <StyledForm>
              <Form.Input
                className="name"
                name="name"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    name: e.target.value,
                  })
                }}
                placeholder="Name"
                value={state.name}
              />
              {state.key && (
                <p className="caption" style={{ textAlign: 'left' }}>
                  {state.key.replace('media/', '')}
                </p>
              )}
            </StyledForm>
            {state.key ? (
              renderMedia()
            ) : (
              <UploadMediaCard
                onFileUploaded={(data: { key: string; name: string }) => {
                  setState({
                    ...state,
                    key: data.key,
                    name: data.name,
                  })
                }}
              />
            )}
            <Button
              color="gray"
              kind="outlined"
              text="Replace File"
              disabled={!state.key}
              fontAwesomeIcon="exchange"
              onClick={() => {
                setState({
                  ...state,
                  key: '',
                })
              }}
              style={{ marginTop: '16px' }}
            />
          </Grid.Column>
          <Grid.Column width={9}>
            <StyledForm
              onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) =>
                e.preventDefault()
              }
            >
              <p className="caption">Select file type</p>
              <Form.Group style={{ margin: '0' }} widths={3}>
                {[
                  MediaCategory.reference,
                  MediaCategory.video_360,
                  MediaCategory.installation,
                ].map((c) => (
                  <RadioField key={c} category={c} />
                ))}
              </Form.Group>
              <Form.Group style={{ margin: '0' }} widths={3}>
                {[
                  MediaCategory.image_close,
                  MediaCategory.image_medium,
                  MediaCategory.image_wide,
                ].map((c) => (
                  <RadioField key={c} category={c} />
                ))}
              </Form.Group>
              <Form.Group style={{ margin: '0' }} widths={3}>
                {[
                  MediaCategory.texture_high,
                  MediaCategory.texture_mid,
                  MediaCategory.texture_low,
                ].map((c) => (
                  <RadioField key={c} category={c} />
                ))}
              </Form.Group>
              <Form.Group style={{ margin: '0' }} widths={3}>
                {[
                  MediaCategory.asset_3D,
                  MediaCategory.asset_2D_plan,
                  MediaCategory.special,
                ].map((c) => (
                  <RadioField key={c} category={c} />
                ))}
              </Form.Group>
              <Form.Group style={{ margin: '0' }} widths={3}>
                <Form.Input
                  disabled={state.category !== 'special'}
                  name="specialTag"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        specialTag: e.target.value,
                      },
                    })
                  }}
                  placeholder="Special Tag"
                  value={state.data?.specialTag || ''}
                />
              </Form.Group>
              <p className="caption">Associations</p>
              <Form.Field>
                <label>Catalog Features</label>
                <Form.Dropdown
                  fluid
                  multiple
                  options={_.uniqBy(
                    [...state.catalogFeatures, ...catalogFeatures],
                    (cf) => cf.identifier,
                  ).map((cf) => ({
                    key: cf.identifier,
                    text: cf.description,
                    value: cf.id,
                  }))}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      catalogFeatures: (value as string[]).map((v) => {
                        {
                          const found =
                            catalogFeatures.find((cf) => cf.id === v) ||
                            state.catalogFeatures.find((cf) => cf.id === v)
                          return {
                            id: v,
                            identifier: found?.identifier,
                            description: found?.description,
                          }
                        }
                      }),
                    })
                  }}
                  onSearchChange={(_, data) =>
                    setCatalogFeatureSearch(data.searchQuery)
                  }
                  placeholder="Catalog Features"
                  search
                  selection
                  value={state.catalogFeatures.map((d) => d.id) as string[]}
                />
              </Form.Field>
              <Form.Field>
                <label>Generic Options</label>
                <Form.Dropdown
                  fluid
                  multiple
                  options={_.uniqBy(
                    [...state.genericOptions, ...genericOptions],
                    (go) => go.identifier,
                  ).map((go) => ({
                    key: go.id,
                    text: `${go.featureIdentifier} - ${go.description}`,
                    value: go.id,
                  }))}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      genericOptions: (value as string[]).map((v) => {
                        {
                          const found =
                            genericOptions.find((go) => go.id === v) ||
                            state.genericOptions.find((go) => go.id === v)
                          return {
                            id: v,
                            identifier: found?.identifier,
                            description: found?.description,
                            featureIdentifier: found?.featureIdentifier,
                          }
                        }
                      }),
                    })
                  }}
                  onSearchChange={(_, data) =>
                    setGenericOptionSearch(data.searchQuery)
                  }
                  placeholder="Generic Options"
                  search
                  selection
                  value={state.genericOptions.map((d) => d.id) as string[]}
                />
              </Form.Field>
              <Form.Field>
                <label>Generic Product Classes</label>
                <Form.Dropdown
                  fluid
                  multiple
                  options={_.uniqBy(
                    [...state.genericProductClasses, ...genericProductClasses],
                    (gpc) => gpc.identifier,
                  ).map((gpc) => ({
                    key: gpc.id,
                    text: gpc.identifier,
                    value: gpc.id,
                  }))}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      genericProductClasses: (value as string[]).map((v) => {
                        {
                          const found =
                            genericProductClasses.find((gpc) => gpc.id === v) ||
                            state.genericProductClasses.find(
                              (gpc) => gpc.id === v,
                            )
                          return {
                            id: v,
                            identifier: found?.identifier,
                          }
                        }
                      }),
                    })
                  }}
                  onSearchChange={(_, data) =>
                    setGenericProductClassSearch(data.searchQuery)
                  }
                  placeholder="Generic Product Classes"
                  search
                  selection
                  value={
                    state.genericProductClasses.map((d) => d.id) as string[]
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Nobilia Options</label>
                <Form.Dropdown
                  fluid
                  multiple
                  options={_.uniqBy(
                    [...state.nobiliaOptions, ...nobiliaOptions],
                    (no) => no.id,
                  ).map((no) => ({
                    key: no.id,
                    text: `${no.featureNo} - ${no.description} - ${no.catalog}`,
                    value: no.id,
                  }))}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      nobiliaOptions: (value as string[]).map((v) => {
                        {
                          const found =
                            nobiliaOptions.find((no) => no.id === v) ||
                            state.nobiliaOptions.find((no) => no.id === v)
                          return {
                            id: v,
                            catalog: found?.catalog,
                            identifier: found?.identifier,
                            description: found?.description,
                            featureNo: found?.featureNo,
                          }
                        }
                      }),
                    })
                  }}
                  onSearchChange={(_, data) =>
                    setNobiliaOptionSearch(data.searchQuery)
                  }
                  placeholder="Nobilia Options"
                  search
                  selection
                  value={state.nobiliaOptions.map((d) => d.id) as string[]}
                />
              </Form.Field>
              <Form.Field>
                <label>Nobilia Product Classes</label>
                <Form.Dropdown
                  fluid
                  multiple
                  options={_.uniqBy(
                    [...state.nobiliaProductClasses, ...nobiliaProductClasses],
                    (npc) => npc.id,
                  ).map((npc) => ({
                    key: `${npc.orderId} - ${npc.catalog}`,
                    text: `${npc.orderId} - ${npc.catalog}`,
                    value: npc.id,
                  }))}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      nobiliaProductClasses: (value as string[]).map((v) => {
                        const found =
                          nobiliaProductClasses.find((npc) => npc.id === v) ||
                          state.nobiliaProductClasses.find(
                            (npc) => npc.id === v,
                          )
                        return {
                          id: v,
                          catalog: found?.catalog,
                          orderId: found?.orderId,
                        }
                      }),
                    })
                  }}
                  onSearchChange={(_, data) =>
                    setNobiliaProductClassSearch(data.searchQuery.toUpperCase())
                  }
                  placeholder="Nobilia Product Classes"
                  search
                  selection
                  value={
                    state.nobiliaProductClasses.map((d) => d.id) as string[]
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Room Element Classes</label>
                <Form.Dropdown
                  fluid
                  multiple
                  options={_.uniqBy(
                    [...state.roomElementClasses, ...roomElementClasses],
                    (rec) => rec.identifier,
                  ).map((rec) => ({
                    key: rec.id,
                    text: rec.identifier,
                    value: rec.id,
                  }))}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      roomElementClasses: (value as string[]).map((v) => {
                        {
                          const found =
                            roomElementClasses.find((rec) => rec.id === v) ||
                            state.roomElementClasses.find((rec) => rec.id === v)
                          return {
                            id: v,
                            identifier: found?.identifier,
                          }
                        }
                      }),
                    })
                  }}
                  onSearchChange={(_, data) =>
                    setRoomElementClassSearch(data.searchQuery)
                  }
                  placeholder="Room Element Classes"
                  search
                  selection
                  value={state.roomElementClasses.map((d) => d.id) as string[]}
                />
              </Form.Field>
              <Form.Field>
                <label>Room Element Options</label>
                <Form.Dropdown
                  fluid
                  multiple
                  options={_.uniqBy(
                    [...state.roomElementOptions, ...roomElementOptions],
                    (reo) => reo.identifier,
                  ).map((reo) => ({
                    key: reo.id,
                    text: `${reo.featureIdentifier} - ${reo.description}`,
                    value: reo.id,
                  }))}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      roomElementOptions: (value as string[]).map((v) => {
                        {
                          const found =
                            roomElementOptions.find((reo) => reo.id === v) ||
                            state.roomElementOptions.find((reo) => reo.id === v)
                          return {
                            id: v,
                            identifier: found?.identifier,
                            description: found?.description,
                            featureIdentifier: found?.featureIdentifier,
                          }
                        }
                      }),
                    })
                  }}
                  onSearchChange={(_, data) =>
                    setRoomElementOptionSearch(data.searchQuery)
                  }
                  placeholder="Room Element Options"
                  search
                  selection
                  value={state.roomElementOptions.map((d) => d.id) as string[]}
                />
              </Form.Field>
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default MediaModal

const RadioLabel = styled.label`
  align-items: center;
  display: flex !important;

  img {
    height: 24px;
    width: 24px;
  }
`
