import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { uniqBy, sortBy } from 'lodash'

import {
  FIND_UNIQUE_USER,
  FindUniqueUserPayload,
  FindUniqueUserVariables,
} from 'queries/user'
import { User } from 'types/user'

const useUserUnique = ({
  search,
  otherUsers = [],
  id,
}: {
  search?: string
  otherUsers?: User[]
  id?: string
}) => {
  const { data: { user } = { user: null }, loading } = useQuery<
    FindUniqueUserPayload,
    FindUniqueUserVariables
  >(FIND_UNIQUE_USER, {
    skip: !id && !search,
    variables: {
      where: {
        ...(id && { id }),
        ...(!id && search && { email: search }),
      },
    },
  })

  const userOptions = useMemo(() => {
    return sortBy(
      uniqBy([...(user ? [user] : []), ...otherUsers], (p) => p.email).map(
        (p) => {
          return {
            key: p.id,
            text: p.email,
            value: p.id,
          }
        },
      ),
      'text',
    )
  }, [user, otherUsers])

  return {
    user,
    userOptions,
    loading,
  }
}

export default useUserUnique
