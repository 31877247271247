import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import OrdersSamples from 'components/admin/order-samples/index-many'
import SectionTitle from 'components/admin/section-title'
import OrderSamplesManyProvider from 'context/order/provider-many-samples'
import { OrderType } from 'types/order'

const Module = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="Sample Orders" />
        <Grid.Row>
          <Grid.Column>
            <OrderSamplesManyProvider
              defaultFilters={{
                sortBy: 'placedAt',
                sortDirection: 'descending',
                take: 30,
                type: OrderType.SAMPLES,
              }}
              skipLoader
            >
              <OrdersSamples />
            </OrderSamplesManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
