import React from 'react'

import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import HelpMessage from 'components/shared/help-message'
import { SecondaryCard } from 'design-system/components/cards'
import { User } from 'types/user'
import { formatPhoneNumber, getNameForUser } from 'views/utils'

import { SearchUserBar } from './search-user-bar'

type ConversationSelectUserProps = {
  selectedUser: User | null
  setSelectedUser: (user: User | null) => void
}

export const ConversationSelectUser = ({
  selectedUser,
  setSelectedUser,
}: ConversationSelectUserProps) => {
  return (
    <>
      {selectedUser && (
        <HelpMessage
          title="PRO TIP"
          message="To link the conversation to another user, you need to detach it first by deleting the user below"
        />
      )}
      <p className="no-margin">
        Select the user you want to attach the conversation to (FORM staff
        cannot be selected)
      </p>
      <SearchUserBar
        disabled={!!selectedUser}
        handleSelectUser={setSelectedUser}
      />
      {selectedUser ? (
        <SecondaryCard
          image={selectedUser.picture}
          title={getNameForUser(selectedUser)}
          subtitle={formatPhoneNumber(selectedUser.phone)}
          actionIcon="times"
          onClick={() => setSelectedUser(null)}
        />
      ) : (
        <ImageDiv>
          <p className="light-gray no-margin bold">No User Linked</p>
          <Image
            src={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/user-missing.svg`}
            alt="No user"
            size="medium"
            centered
          />
        </ImageDiv>
      )}
    </>
  )
}

const ImageDiv = styled.div`
  margin-top: 8px;
  position: relative;
  & > p {
    position: absolute;
    text-align: center;
    top: -8px;
    width: 100%;
    z-index: 1;
  }
`
