import React, { useCallback, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { debounce } from 'lodash'
import TextareaAutosize from 'react-textarea-autosize'
import { Divider, Form, Grid } from 'semantic-ui-react'

import GenericOptionAccordion from 'components/admin/catalog/generic-option/accordion'
import RenderPropAccordion from 'components/admin/catalog/render-prop/accordion'
import RoomElementOptionAccordion from 'components/admin/catalog/room-element-option/accordion'
import AppliancesTableRendering from 'components/admin/project/room/generic-product/table-rendering'
import FileCard from 'components/shared/project/project-file/card'
import { UploadProjectFileCard } from 'components/shared/project/project-file/upload-card'
import useDesign from 'context/design/use'
import useDesignOptionsMutate from 'context/design/use-design-options-mutate'
import useDesignProjectFileMutate from 'context/design/use-project-file-mutate'
import useProject from 'context/project/use'
import { isPDF } from 'context/project-file/utils'
import useRoom from 'context/room/use'
import StyledAccordion from 'design-system/components/accordion'
import Button from 'design-system/components/button'
import CheckHighlighted from 'design-system/components/check-highlighted'
import { Design } from 'types/design'
import { ProjectFile, ProjectFileType } from 'types/project-file'

const Rendering = ({
  setState,
  state,
}: {
  setState: (d: Partial<Design>) => void
  state: Partial<Design>
}) => {
  const [renderNotes, setRenderNotes] = useState<string | null>(
    state.metadata?.initialRenderNotes ?? null,
  )
  const { conceptImages, project } = useProject()
  const { room } = useRoom()
  const {
    backsplashMaterials,
    countertopMaterials,
    design,
    floorMaterials,
    refetch,
    renderFeedbackFiles,
    renderProps,
    latestPlan,
  } = useDesign()
  const { deleteProjectFileAndRefetch } = useDesignProjectFileMutate()
  const {
    onDeleteGenericOption,
    onDeleteRoomElementOption,
    onSaveCustomGenericOption,
    onSaveCustomRoomElementOption,
    onSaveGenericOptions,
    onSaveRoomElementOptions,
    onSaveRenderProps,
  } = useDesignOptionsMutate()

  useEffect(() => {
    debounceRenderNotes()
  }, [renderNotes])

  const debounceRenderNotes = useCallback(
    debounce(
      () =>
        setState({
          metadata: {
            ...state.metadata,
            initialRenderNotes: renderNotes ?? '',
          },
        }),
      100,
    ),
    [renderNotes],
  )

  const onFilesUploaded = () => {
    refetch()
  }

  const renderRenderFeedback = (f: ProjectFile) => {
    return (
      <Grid.Column key={f.id} style={{ marginBottom: '25px' }}>
        <FileCard
          file={f}
          onFileRemoved={async (f: Partial<ProjectFile>) => {
            deleteProjectFileAndRefetch({
              variables: {
                where: {
                  id: f.id as string,
                },
              },
            })
          }}
          navigateTo={`/admin/files/${f.id}/`}
          showActions
        />
      </Grid.Column>
    )
  }

  const numUnspecifiedAppliances = room?.genericProducts?.filter(
    (a) => !a.data?.archivedAt && !a.renderProp,
  ).length
  const sendingToRender = !design?.sentToRenderingAt && state.sentToRenderingAt
  const hasPreviousRender = room?.designs.some((d) => !!d.sentToArchAt)

  // Various error states for render submission
  const isRevertingAlreadySent =
    !!design?.sentToArchAt && !state.sentToRenderingAt
  const isRevertingInProgressSend =
    !!design?.sentToRenderingAt && !state.sentToRenderingAt
  const isNamedIncorrectly =
    sendingToRender &&
    !state.name?.toLowerCase().includes('initial design') &&
    !state.name?.toLowerCase().includes('final design')
  const isMissingRenderProps =
    sendingToRender && !hasPreviousRender && !renderProps?.length
  const hasMissingApplianceRenderModels =
    sendingToRender && !!numUnspecifiedAppliances
  const isMissingNotes = sendingToRender && !state.metadata?.initialRenderNotes
  const isMissingVisualNotes =
    sendingToRender &&
    !renderFeedbackFiles.filter(
      (f) => !f.name.toLocaleLowerCase().includes('.ifc'),
    )?.length
  const isMissingIFCFile =
    sendingToRender &&
    !renderFeedbackFiles.filter(
      (f) => !!f.name.toLocaleLowerCase().includes('.ifc'),
    )?.length

  return (
    <StyledAccordion titleText="Rendering Elements">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <p className="caption">Finishes for rendering</p>
            <Divider fitted />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              kind="solid"
              fontAwesomeIcon="up-right-from-square"
              onClick={() =>
                navigate(`/admin/projects/${project.id}/custom-images`)
              }
              text="Upload custom image"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <GenericOptionAccordion
              conceptImages={conceptImages}
              customIdentifier="countertopMaterialcustom"
              featureIdentifier="countertopMaterial"
              genericOptions={countertopMaterials ?? []}
              gridColumns={2}
              onDelete={onDeleteGenericOption}
              onSave={(go) =>
                onSaveGenericOptions(go, countertopMaterials ?? [])
              }
              onSaveCustom={onSaveCustomGenericOption}
              title="Countertop"
            />
          </Grid.Column>
          <Grid.Column>
            <RoomElementOptionAccordion
              conceptImages={conceptImages}
              customIdentifier="floorMaterialcustom"
              featureIdentifier="floorMaterial"
              gridColumns={2}
              onDelete={onDeleteRoomElementOption}
              onSave={(re) =>
                onSaveRoomElementOptions(re, floorMaterials ?? [])
              }
              onSaveCustom={onSaveCustomRoomElementOption}
              roomElementOptions={floorMaterials ?? []}
              title="Floor"
            />
          </Grid.Column>
          <Grid.Column>
            <GenericOptionAccordion
              conceptImages={conceptImages}
              customIdentifier="backsplashMaterialcustom"
              featureIdentifier="backsplashMaterial"
              genericOptions={backsplashMaterials ?? []}
              gridColumns={2}
              onDelete={onDeleteGenericOption}
              onSave={(go) =>
                onSaveGenericOptions(go, backsplashMaterials ?? [])
              }
              onSaveCustom={onSaveCustomGenericOption}
              title="Backsplashes"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p className="caption">Rendering props</p>
            <Divider fitted />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <RenderPropAccordion
              renderProps={renderProps ?? []}
              onSave={(rp) => onSaveRenderProps(rp)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            {hasPreviousRender ? (
              <p>
                <i>
                  Note! Only include <b>new</b> rendering props for subsequent
                  designs.
                </i>
              </p>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <p className="caption">Appliances</p>
          </Grid.Column>
          {room?.genericProducts?.length ? (
            <Grid.Column textAlign="right">
              <Button
                color="gray"
                kind="outlined"
                onClick={() =>
                  window.open(
                    `/admin/projects/${project.id}/rooms/${room?.id}/appliances`,
                  )
                }
                text="Go to Appliances"
              />
            </Grid.Column>
          ) : null}
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <AppliancesTableRendering changeRenderProp />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p className="caption">Rendering notes</p>
            <Divider fitted />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p>Render notes</p>
            <Form>
              <Form.Field
                control={TextareaAutosize}
                placeholder="Render notes. Heads up! These get sent directly to Archi CGI contractors, so include any necessary context."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setRenderNotes(e.target.value)
                }
                value={renderNotes}
                error={isMissingNotes}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p>Visual notes / Propping locations / Camera angles</p>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column>
                  <UploadProjectFileCard
                    fileType={ProjectFileType.RENDER_FEEDBACK}
                    onFilesUploaded={onFilesUploaded}
                    projectId={project.id}
                    roomId={room?.id}
                    designRenderFeedbackFileId={design?.id}
                    error={!!isMissingVisualNotes || !!isMissingIFCFile}
                  />
                </Grid.Column>
                {renderFeedbackFiles.map(renderRenderFeedback)}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column textAlign="center" width={6}>
            <CheckHighlighted
              checked={!!state.sentToRenderingAt}
              disabled={
                !latestPlan ||
                (isPDF(latestPlan) && latestPlan.numPages === null)
              }
              color="red"
              text="Design is ready for render review"
              onChange={() =>
                setState({
                  sentToRenderingAt: state.sentToRenderingAt
                    ? null
                    : new Date(),
                })
              }
            />
            {sendingToRender ||
            isRevertingAlreadySent ||
            isRevertingInProgressSend ? (
              <div style={{ textAlign: 'left', marginTop: '16px' }}>
                {isRevertingAlreadySent ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    This design has already been sent to Archi CGI. Unchecking
                    this will not do anything.
                  </p>
                ) : null}
                {isRevertingInProgressSend ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    Check with the team to make sure they haven't already
                    started the submission process.
                  </p>
                ) : null}
                {isNamedIncorrectly ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    We were expecting the design to be named "Initial Design" or
                    "Final Design" if you are submitting for rendering.
                  </p>
                ) : null}
                {hasMissingApplianceRenderModels ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    You haven't specified a render model for{' '}
                    {numUnspecifiedAppliances} appliance
                    {numUnspecifiedAppliances > 1 ? 's' : ''}. If you can't find
                    what you're looking for, make sure the team is aware and
                    they can help get one for you.
                  </p>
                ) : null}
                {isMissingRenderProps ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    You haven't specified any render props for the scene.
                  </p>
                ) : null}
                {isMissingNotes ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    No notes detected.
                  </p>
                ) : null}
                {isMissingVisualNotes ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    No visual notes detected.
                  </p>
                ) : null}
                {isMissingIFCFile ? (
                  <p>
                    <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />{' '}
                    No IFC file detected.
                  </p>
                ) : null}
              </div>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledAccordion>
  )
}

export default Rendering
