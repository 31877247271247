import { gql } from '@apollo/client'

import { ProjectEvent, ProjectEventData } from 'types/project-event'
import { UserType } from 'types/user'

export const FIND_MANY_PROJECT_EVENT = gql`
  query findManyProjectEvent(
    $skip: Int
    $take: Int
    $orderBy: [ProjectEventOrderByWithRelationInput!]
    $where: ProjectEventWhereInput
  ) {
    count: findManyProjectEventCount(where: $where)
    projectEvents: findManyProjectEvent(
      skip: $skip
      take: $take
      orderBy: $orderBy
      where: $where
    ) {
      id
      createdAt
      data
      newFor {
        id
      }
      type
      project {
        id
        data
        owners {
          user {
            id
            email
            firstName
            lastName
            picture
            type
          }
          userRole
        }
        projectAddress
      }
      publisher {
        id
        email
        firstName
        lastName
        picture
        type
      }
      updatedAt
    }
  }
`

export interface FindManyProjectEventVariables {
  take?: number
  skip?: number
  orderBy?: any
  where?: {
    project?: {
      id?: { equals: string }
      staff?: {
        some: {
          staffMemberId?: {
            equals: string
          }
          staffMemberRole?: {
            equals: string
          }
          isSubscribed?: {
            equals: boolean
          }
        }
      }
    }
    createdAt?: { gt: Date }
    publisher?: {
      id?: string
      type?: {
        notIn: UserType[]
      }
    }
    showStaff?: {
      equals: boolean
    }
    newFor?: {
      some: {
        id: {
          equals: string
        }
      }
    }
  }
}

export type FindManyProjectEventPayload = {
  projectEvents: ProjectEvent[]
  count: number
}

export const FIND_MANY_PROJECT_EVENT_COUNT = gql`
  query findManyProjectEventCount($where: ProjectEventWhereInput!) {
    count: findManyProjectEventCount(where: $where)
  }
`

export type FindManyProjectEventCountPayload = {
  count: number
}

export const UPDATE_PROJECT_EVENT = gql`
  mutation updateProjectEvent(
    $data: ProjectEventUpdateInput!
    $where: ProjectEventWhereUniqueInput!
  ) {
    updateOneProjectEvent(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateProjectEventPayload {
  updateOneProjectEvent: {
    id: string
  }
}

export interface UpdateProjectEventVariables {
  data: {
    data?: Partial<ProjectEventData>
    newFor?:
      | {
          disconnect: { id: string } | { id: string }[]
        }
      | {
          connect: { id: string } | { id: string }[]
        }
  }
  where: {
    id: string
  }
}

export const UPDATE_MANY_PROJECT_EVENTS = gql`
  mutation updateManyProjectEvents(
    $data: ProjectEventUpdateInput!
    $where: ProjectEventWhereInput!
  ) {
    updateManyProjectEvent(data: $data, where: $where) {
      count
    }
  }
`

export interface UpdateManyProjectEventsPayload {
  updateManyProjectEvent: {
    count: number
  }
}

export interface UpdateManyProjectEventsVariables {
  data: {
    data?: Partial<ProjectEventData>
    newFor?:
      | {
          disconnect: { id: string } | { id: string }[]
        }
      | {
          connect: { id: string } | { id: string }[]
        }
  }
  where: {
    id: {
      in: string[]
    }
  }
}
