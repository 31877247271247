import React, { useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'
import { Divider, Form } from 'semantic-ui-react'

import { useDesignLayout } from 'content-queries/cx/design-layout'
import { useHandleType } from 'content-queries/cx/handle-type'
import useDesign from 'context/design/use'
import { notifySuccess } from 'context/notifications/trigger'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import * as NO from 'planner/objects/products/nobilia/options/definitions'
import {
  UPDATE_DESIGN,
  UpdateDesignPayload,
  UpdateDesignVariables,
} from 'queries/design'
import {
  FIND_MANY_NOBILIA_OPTION,
  FindManyNobiliaOptionPayload,
  FindManyNobiliaOptionVariables,
} from 'queries/nobilia-option'
import { StyledForm } from 'styles/admin/main'
import { Design, DesignMetadataGallery } from 'types/design'

interface DesignModalProps {
  location?: string
  design?: Design
  designsGalleryCount?: number
  onClose?: () => void
}

const DesignModal = ({
  location,
  design: design,
  designsGalleryCount,
  onClose,
}: DesignModalProps) => {
  const { fronts, handles } = useDesign()
  const frontsData =
    fronts?.map((f) => {
      return {
        id: f.id as string,
        identifier: f.identifier as string,
        optionKey: f.optionKey as string,
        featureNo: f.featureNo as string,
        description: f.description as string,
      }
    }) ?? []
  const handlesData = handles?.map((h) => {
    return {
      id: h.id as string,
      identifier: h.identifier as string,
      optionKey: h.optionKey as string,
      featureNo: h.featureNo as string,
      description: h.description as string,
    }
  })

  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, toggleModal] = useState<boolean>(false)
  const [designSearch, setDesignSearch] = useState<string>('')
  const [state, setState] = useState<NullableRecord<DesignMetadataGallery>>({
    location: location || '',
    name: design?.metadataGallery?.name || `#${designsGalleryCount}` || '',
    fronts: frontsData ?? [],
    handles: handlesData || [],
    handleTypes: design?.metadataGallery?.handleTypes || null,
    layout: design?.metadataGallery?.layout || '',
    size: design?.metadataGallery?.size || '',
    priceRange: design?.metadataGallery?.priceRange || '',
    featuresLevel: design?.metadataGallery?.featuresLevel || '',
    showOnWebsiteGallery:
      design?.metadataGallery?.showOnWebsiteGallery || false,
  })

  const { data: { nobiliaOptions } = { nobiliaOptions: [] } } = useQuery<
    FindManyNobiliaOptionPayload,
    FindManyNobiliaOptionVariables
  >(FIND_MANY_NOBILIA_OPTION, {
    variables: {
      orderBy: {
        optionKey: 'asc',
      },
      where: {
        catalog: { equals: process.env.GATSBY_DEFAULT_CATALOG as string },
        featureNo: {
          in: [NO.FRONT_COMBO, NO.HANDLE_COMBINATION],
        },
        description: {
          contains: designSearch,
        },
      },
    },
  })

  const onCloseModal = () => {
    toggleModal(false)
    if (onClose) onClose()
  }

  const [updateDesign] = useMutation<
    UpdateDesignPayload,
    UpdateDesignVariables
  >(UPDATE_DESIGN, {
    onCompleted: () => {
      notifySuccess('Data Updated')
    },
  })

  const onSave = async () => {
    setLoading(true)
    await updateDesign({
      variables: {
        data: {
          metadataGallery: {
            ...design?.metadataGallery,
            ...(state as DesignMetadataGallery),
          },
        },
        where: { id: design?.id as string },
      },
    })
    setLoading(false)
    onCloseModal()
  }

  const sizes = ['Small', 'Medium', 'Large']
  const { data: handleTypes } = useHandleType()
  const { data: designLayouts } = useDesignLayout()
  const priceRanges = [
    '$ 15-20k',
    '$ 20-25k',
    '$ 25-30k',
    '$ 30-35k',
    '$ 35-40k',
    '$ 40+k',
  ]
  const featuresLevel = ['$ Essentials', '$$ Premium', '$$$ Luxury']

  return (
    <Modal
      title="Tags"
      onClose={() => onCloseModal()}
      onOpen={() => toggleModal(true)}
      open={showModal}
      trigger={
        <Button
          kind="ghost"
          color="gray"
          fontAwesomeIcon="pencil"
          text="Edit"
        />
      }
      saveButton={{
        text: 'Save',
        disabled:
          !state.name ||
          !state.fronts?.length ||
          !state.handles?.length ||
          !state.handleTypes?.length ||
          !state.layout ||
          !state.size ||
          !state.priceRange ||
          !state.featuresLevel,
        loading: loading,
        onClick: onSave,
      }}
      size="large"
    >
      <Form>
        <Form.Group widths={2} inline>
          <Form.Field>
            <h3>Metadata</h3>
          </Form.Field>
          <Form.Checkbox
            checked={state.showOnWebsiteGallery ?? undefined}
            label="Show on website gallery"
            onChange={() =>
              setState({
                ...state,
                showOnWebsiteGallery: !state.showOnWebsiteGallery,
              })
            }
            toggle
          />
        </Form.Group>
      </Form>
      <Divider hidden />
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Group widths={2}>
          <Form.Input
            disabled
            fluid
            label="Name"
            placeholder="Name"
            required
            selection
            value={state.name}
          />
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Dropdown
            disabled
            fluid
            label="Fronts"
            multiple
            options={_.uniqBy(
              [
                ...(state?.fronts ?? []),
                ...nobiliaOptions.filter(
                  (no) => no.featureNo === NO.FRONT_COMBO,
                ),
              ],
              (no) => no.identifier,
            ).map((no) => ({
              key: no.id,
              text: `${no.optionKey} - ${no.description}`,
              value: no.id,
            }))}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                fronts: (value as string[]).map((v) => {
                  {
                    const found =
                      nobiliaOptions
                        .filter((no) => no.featureNo === NO.FRONT_COMBO)
                        .find((no) => no.id === v) ||
                      state.fronts?.find((no) => no.id === v)
                    return {
                      id: v,
                      identifier: found?.identifier as string,
                      description: found?.description as string,
                      featureNo: found?.featureNo as string,
                      optionKey: found?.optionKey as string,
                    }
                  }
                }),
              })
            }}
            onSearchChange={(
              _: unknown,
              data: { searchQuery: React.SetStateAction<string> },
            ) => setDesignSearch(data.searchQuery)}
            placeholder="Fronts"
            required
            search
            selection
            value={state.fronts?.map((d) => d.id)}
          />
          <Form.Dropdown
            disabled
            fluid
            label="Handles"
            multiple
            options={_.uniqBy(
              [
                ...(state?.handles ?? []),
                ...nobiliaOptions.filter(
                  (no) => no.featureNo === NO.HANDLE_COMBINATION,
                ),
              ],
              (no) => no.identifier,
            ).map((no) => ({
              key: no.id,
              text: `${no.optionKey} - ${no.description}`,
              value: no.id,
            }))}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                handles: (value as string[]).map((v) => {
                  {
                    const found =
                      nobiliaOptions
                        .filter((no) => no.featureNo === NO.HANDLE_COMBINATION)
                        .find((no) => no.id === v) ||
                      state.handles?.find((no) => no.id === v)

                    return {
                      id: v,
                      identifier: found?.identifier as string,
                      description: found?.description as string,
                      featureNo: found?.featureNo as string,
                      optionKey: found?.optionKey as string,
                    }
                  }
                }),
              })
            }}
            onSearchChange={(
              _: unknown,
              data: { searchQuery: React.SetStateAction<string> },
            ) => setDesignSearch(data.searchQuery)}
            placeholder="Handles"
            required
            search
            selection
            value={state.handles?.map((d) => d.id)}
          />
        </Form.Group>
        <p className="gray">For Gallery Filters</p>
        <Form.Group widths={2}>
          <Form.Dropdown
            fluid
            label="Handle Type"
            multiple
            options={handleTypes.map(
              (ht: { title: string; contentful_id: string }) => ({
                key: ht.title,
                text: ht.title,
                value: ht.contentful_id,
              }),
            )}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                handleTypes: value as string[],
              })
            }}
            placeholder="Handle Type"
            required
            selection
            value={state.handleTypes ?? undefined}
          />
          <Form.Dropdown
            fluid
            label="Layout"
            options={designLayouts.map(
              (l: { title: string; contentful_id: string }) => ({
                key: l.title,
                text: l.title,
                value: l.contentful_id,
              }),
            )}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                layout: value as string,
              })
            }}
            placeholder="Layout"
            required
            selection
            value={state.layout ?? undefined}
          />
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Dropdown
            fluid
            label="Size"
            options={sizes.map((s) => ({
              key: s,
              text: s,
              value: s,
            }))}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                size: value as string,
              })
            }}
            placeholder="Size"
            required
            selection
            value={state.size ?? undefined}
          />
          <Form.Dropdown
            fluid
            label="Price Range"
            options={priceRanges.map((pr) => ({
              key: pr,
              text: pr,
              value: pr,
            }))}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                priceRange: value as string,
              })
            }}
            placeholder="Price Range"
            required
            selection
            value={state.priceRange ?? undefined}
          />
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Dropdown
            fluid
            label="Features Level"
            options={featuresLevel.map((fl) => ({
              key: fl,
              text: fl,
              value: fl,
            }))}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                featuresLevel: value as string,
              })
            }}
            placeholder="Features Level"
            required
            selection
            value={state.featuresLevel ?? undefined}
          />
        </Form.Group>
      </StyledForm>
    </Modal>
  )
}

export default DesignModal
