import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { uniqBy, sortBy } from 'lodash'

import {
  FIND_UNIQUE_PROSPECT,
  FindUniqueProspectPayload,
  FindUniqueProspectVariables,
} from 'queries/prospect'
import { Prospect } from 'types/prospect'

const useProspectUnique = (search: string, otherProspects: Prospect[]) => {
  const { data: { prospect } = { prospect: null }, loading } = useQuery<
    FindUniqueProspectPayload,
    FindUniqueProspectVariables
  >(FIND_UNIQUE_PROSPECT, {
    skip: !search,
    variables: {
      where: {
        email: search,
      },
    },
  })

  const prospectOptions = useMemo(() => {
    return sortBy(
      uniqBy(
        [...(prospect ? [prospect] : []), ...otherProspects],
        (p) => p.email,
      ).map((p) => {
        return {
          key: p.id,
          text: p.email,
          value: p.id,
        }
      }),
      'text',
    )
  }, [prospect, otherProspects])

  return {
    prospect,
    prospectOptions,
    loading,
  }
}

export default useProspectUnique
