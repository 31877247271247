import { useContext, useMemo } from 'react'

import useUnresolvedIssuesCount from 'context/issue/use-unresolved-issues-count'
import useAddOnRemedialCounts from 'context/supplier-order/use-addon-remedial-counts'
import { parseTasks } from 'context/task/utils-many'
import useFORMEmployees from 'context/user/use-form-employees'

import { TechDesignerSupportContext } from './provider'

const useTechDesignerSupport = () => {
  const { projects, refetchTasks, refetchProjects, tasks, userId } = useContext(
    TechDesignerSupportContext,
  )

  const {
    refetchAllCounts: refetchCounts,
    sosAddOnEDINotSentCount,
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
  } = useAddOnRemedialCounts(userId)

  const { FORMEmployeeIDToName } = useFORMEmployees()
  const userName = FORMEmployeeIDToName[userId]

  const parsedTasks = useMemo(() => parseTasks(tasks), [tasks])

  const { issuesUnresolvedCount } = useUnresolvedIssuesCount(userId)

  return {
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
    issuesUnresolvedCount,
    projects,
    refetchProjects,
    refetchTasks,
    refetchCounts,
    sosAddOnEDINotSentCount,
    userId,
    userName,
    ...parsedTasks,
  }
}

export default useTechDesignerSupport
