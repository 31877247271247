import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import DeliveryTab from 'components/admin/project/delivery/tab'
import useDelivery from 'context/delivery/use'
import useProjectDeliveryMany from 'context/project/delivery/use-many'
import useProject from 'context/project/use'

const DeliveryCreateUpdate = (
  _: RouteComponentProps<{ project_id: string; delivery_id?: string }>,
) => {
  const { project, refetch: refetchProject } = useProject()
  const { delivery } = useDelivery()
  const { refetch } = useProjectDeliveryMany()

  return (
    <Grid>
      <ProjectPageTitle viewName="Delivery" />
      <Grid.Row columns={2}>
        <Grid.Column>
          <h5>{delivery ? 'Edit Delivery' : 'Create Delivery'}</h5>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DeliveryTab
            onComplete={(createdId?: string) => {
              refetch()
              refetchProject()
              if (createdId)
                navigate(
                  `/admin/projects/${project.id}/deliveries/${createdId}`,
                )
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default DeliveryCreateUpdate
