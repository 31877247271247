import React from 'react'

import { Grid } from 'semantic-ui-react'

import MediaCard from 'components/admin/catalog/media/card'
import * as mUtils from 'context/media/utils-many'
import { NodeBase } from 'planner/objects/node'
import { GenericProductBase } from 'planner/objects/products/generic/base'
import { GenericProductClass } from 'types/generic-product-class'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import PlannerView from './planner-view'

interface SummaryProps {
  genericProduct: GenericProductBase | null
  genericProductClass: GenericProductClass
  refetch: () => Promise<unknown>
  sceneNodes: NodeBase[]
}

const GenericProductClassSummary = ({
  genericProduct,
  genericProductClass,
  sceneNodes,
  refetch,
}: SummaryProps) => {
  const referenceImage = mUtils.parseMedia(genericProductClass.media ?? [])
    .reference[0]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <h2>{genericProductClass.identifier}</h2>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={2}>
          <p className="caption">Configuration price</p>
          <p>None</p>
        </Grid.Column>
        <Grid.Column width={2}>
          <p className="caption">Type</p>
          <p>{capsSnakeCaseToTitleCase(genericProductClass.productType)}</p>
        </Grid.Column>
        <Grid.Column width={5} textAlign="center" stretched>
          <MediaCard
            enableEdit={!!referenceImage}
            media={referenceImage}
            refetch={refetch}
          />
          <p className="caption" style={{ marginTop: '14px' }}>
            Reference Image
          </p>
        </Grid.Column>
        {genericProduct ? (
          <Grid.Column width={5} textAlign="center">
            <PlannerView product={genericProduct} sceneNodes={sceneNodes} />
            <p className="caption" style={{ marginTop: '14px' }}>
              Planner View
            </p>
          </Grid.Column>
        ) : null}
      </Grid.Row>
    </Grid>
  )
}

export default GenericProductClassSummary
