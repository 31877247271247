import NobiliaProductBase from 'planner/objects/products/nobilia/base'
import { NobiliaOption } from 'types/nobilia-option'
import { NobiliaPriceFeatureGroup } from 'types/nobilia-price-feature-group'
import { NobiliaPrices, NobiliaProductClass } from 'types/nobilia-product-class'

const getPriceFromFeatureGroup = (
  options: NobiliaOption[],
  price: NobiliaPrices,
  priceGroup: NobiliaPriceFeatureGroup,
): number => {
  const featuresOpts = priceGroup.features?.map((feat) => {
    const idx = options
      .map((option) => option.featureNo)
      .indexOf(feat.featureNo)
    return idx !== -1 ? options[idx].optionKey : ''
  })

  const finish = priceGroup.finishes?.find(
    (finish) =>
      finish.options.map((opt) => opt.optionKey).toString() ===
      featuresOpts.toString(),
  )

  if (finish) {
    const priceIdx = price.priceFields.findIndex(
      (pf) => pf === finish.priceField,
    )
    return priceIdx > -1 ? price.prices[priceIdx] : 0
  }
  return 0
}

const getAdditionalPrices = (
  options: NobiliaOption[],
  productClass: NobiliaProductClass,
): number => {
  const prices = productClass.additionalPrices
  const priceGroups = productClass.additionalPriceFeatureGroups

  let total = 0
  for (let i = 0; i < prices.length; i++) {
    const price = prices[i]
    const priceGroup = priceGroups[i]
    total += getPriceFromFeatureGroup(options, price, priceGroup)
  }
  return total
}

export const getAdditionalItemPrice = (
  productClass: NobiliaProductClass,
): number => {
  const mainPrices = productClass.prices
  return mainPrices.prices.length === 1 ? mainPrices.prices[0] : 0
}

export const getPriceWithAdditionalItems = (
  additionalItems: NobiliaProductBase[],
  options: NobiliaOption[],
  productClass: NobiliaProductClass,
): number => {
  const additionalItemsPrice = additionalItems.reduce(
    (acc, a) => acc + getAdditionalItemPrice(a.productClass) * a.quantity,
    0,
  )

  return (
    getPriceWithoutAdditionalItems(options, productClass) + additionalItemsPrice
  )
}

export const getPriceWithoutAdditionalItems = (
  options: NobiliaOption[],
  productClass: NobiliaProductClass,
): number => {
  const mainPrices = productClass.prices
  const mainPriceFeatureGroup = productClass.priceFeatureGroup

  return mainPrices.prices.length === 1
    ? mainPrices.prices[0]
    : getPriceFromFeatureGroup(options, mainPrices, mainPriceFeatureGroup) +
        getAdditionalPrices(options, productClass)
}
