import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'

import {
  BULK_IMPORT_ORDER_LOGS,
  GET_ORDER_LOGS_FOR_BULK_IMPORT,
  BulkImportOrderLogsVariables,
  GetOrderLogsForbulkImportPayload,
  RawOrderLog,
  NobiliaOrderLog,
} from 'queries/zoho-bulk-import'

const rawDateFormat = 'MM/DD/YYYY'

const formatDate = (date: string): string => {
  return moment(date, rawDateFormat)
    .add(12, 'hours') // UTC mini hack
    .format('YYYY-MM-DD')
}

const useZohoBulkImport = () => {
  const [
    fetchOrderLogs,
    { data, loading: fetchingOrderLogs, called: dataHasBeenFetched },
  ] = useLazyQuery<GetOrderLogsForbulkImportPayload>(
    GET_ORDER_LOGS_FOR_BULK_IMPORT,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const [bulkImportOrderLogs, { loading: importingOrderLogs }] = useMutation<
    { bulkImportOrderLogs: boolean },
    BulkImportOrderLogsVariables
  >(BULK_IMPORT_ORDER_LOGS)

  const parseRawOrderLogToZohoApiStructure = (
    orderLog: RawOrderLog,
  ): Omit<NobiliaOrderLog, 'Ack_No'> => {
    const {
      dateAgreedForDelivery,
      dateFinalMileContactCommittedToDelivery,
      finalMileDeliverdToFinalDestination,
      firstAttemptCallOutcome,
      firstAttemptCallToEndPoint,
      monthsInStorage,
      schedulingNotes,
      storageStartDate,
      zohoId,
    } = orderLog
    const id = zohoId.split('_')[1]

    return {
      id,
      st_Attempt_for_Delivery_Appointment: firstAttemptCallToEndPoint
        ? formatDate(firstAttemptCallToEndPoint)
        : undefined,
      st_Attempt_Outcome: firstAttemptCallOutcome || undefined,
      Final_Mile_Delivery_Date_Scheduled:
        dateFinalMileContactCommittedToDelivery
          ? formatDate(dateFinalMileContactCommittedToDelivery)
          : undefined,
      Scheduled_Delivery_Date: dateAgreedForDelivery
        ? formatDate(dateAgreedForDelivery)
        : undefined,
      Storage_Start_Date: storageStartDate
        ? formatDate(storageStartDate)
        : undefined,
      Months_in_Storage: !monthsInStorage ? undefined : monthsInStorage, // Allows usage for '0'
      Scheduling_Notes: schedulingNotes || undefined,
      Final_Delivery_Date_Actual: finalMileDeliverdToFinalDestination
        ? formatDate(finalMileDeliverdToFinalDestination)
        : undefined,
    }
  }

  const rawOrderLogs = data?.orderLogs ?? []

  return {
    bulkImportOrderLogs,
    dataHasBeenFetched,
    fetchingOrderLogs,
    fetchOrderLogs,
    importingOrderLogs,
    parseRawOrderLogToZohoApiStructure,
    rawOrderLogs,
  }
}

export default useZohoBulkImport
