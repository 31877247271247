import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { DateInput } from 'semantic-ui-calendar-react'
import { Form, Grid } from 'semantic-ui-react'

import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useProject from 'context/project/use'
import useSupplierOrder from 'context/supplier-order/use'
import useSupplierOrderMutateNoContext from 'context/supplier-order/use-mutate-no-context'
import useSupplierOrderActionMutateNoContext from 'context/supplier-order-action/use-mutate-no-context'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { ProjectFile, ProjectFileType } from 'types/project-file'
import {
  SupplierOrderAction,
  SupplierOrderActionData,
  SupplierOrderActionType,
} from 'types/supplier-order-action'

const SupplierOrderActionModal = ({
  supplierOrderAction,
  open,
  onClose,
  refetchMany,
}: {
  supplierOrderAction?: SupplierOrderAction
  open: boolean
  onClose: () => void
  refetchMany?: () => Promise<unknown>
}) => {
  const { project } = useProject()
  const {
    latestAckFile,
    latestAckNumber,
    ediAction,
    orderSentAction,
    supplierOrder,
    supplierOrderActions,
    refetch,
  } = useSupplierOrder()
  const { updateSupplierOrder } = useSupplierOrderMutateNoContext()
  const { createSupplierOrderAction, updateSupplierOrderAction } =
    useSupplierOrderActionMutateNoContext()
  const [state, setState] = useState<NullableRecord<SupplierOrderAction>>(
    (supplierOrderAction as SupplierOrderAction) || {
      ackFile: null,
      data: {
        doneAt: moment().format(),
        nobiliaAckNumber: '',
        type: null,
      },
    },
  )

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setState(
      (supplierOrderAction as SupplierOrderAction) || {
        ackFile: null,
        data: {
          doneAt: moment().format(),
          nobiliaAckNumber: '',
          type: null,
        },
      },
    )
  }, [supplierOrderAction, open])

  const onProjectFilesUploaded = (f: Partial<ProjectFile>[]) => {
    setState((currentState) => ({
      ...currentState,
      ackFile: f[0] as ProjectFile,
    }))
  }

  const onSave = async () => {
    setLoading(true)
    if (supplierOrderAction) {
      await updateSupplierOrderAction({
        variables: {
          data: {
            ...(state.ackFile
              ? {
                  ackFile: {
                    connect: { id: state.ackFile.id },
                  },
                }
              : null),
            ...(!state.ackFile && supplierOrderAction.ackFile
              ? {
                  ackFile: {
                    disconnect: true,
                  },
                }
              : null),
            data: state.data as SupplierOrderActionData,
          },
          where: { id: supplierOrderAction.id ?? '' },
        },
      })

      if (state.data?.nobiliaAckNumber)
        await updateSupplierOrder({
          variables: {
            data: {
              nobiliaAckNumber: state.data.nobiliaAckNumber,
            },
            where: { id: supplierOrder.id ?? '' },
          },
        })
    } else {
      await createSupplierOrderAction({
        variables: {
          data: {
            ...(state.ackFile
              ? {
                  ackFile: {
                    connect: { id: state.ackFile.id },
                  },
                }
              : null),
            data: state.data as SupplierOrderActionData,
            supplierOrder: {
              connect: { id: supplierOrder.id ?? '' },
            },
          },
        },
      })
      if (state.data?.nobiliaAckNumber)
        await updateSupplierOrder({
          variables: {
            data: {
              nobiliaAckNumber: state.data.nobiliaAckNumber,
            },
            where: { id: supplierOrder.id ?? '' },
          },
        })
    }
    await refetch()
    refetchMany?.()
    setLoading(false)
    onClose()
  }

  const actionTypeOptions = useMemo(() => {
    const nullAction = {
      text: '',
      key: 'NONE',
      value: '',
    }
    if (!supplierOrderActions?.length) {
      return [
        nullAction,
        {
          text: SupplierOrderActionType.FORMOrderSent,
          key: SupplierOrderActionType.FORMOrderSent,
          value: SupplierOrderActionType.FORMOrderSent,
        },
      ]
    }
    if (!ediAction) {
      return [
        nullAction,
        {
          text: SupplierOrderActionType.FORMEDISent,
          key: SupplierOrderActionType.FORMEDISent,
          value: SupplierOrderActionType.FORMEDISent,
        },
        {
          text: SupplierOrderActionType.FORMEDISkipped,
          key: SupplierOrderActionType.FORMEDISkipped,
          value: SupplierOrderActionType.FORMEDISkipped,
        },
      ]
    }
    return [
      nullAction,
      {
        text: SupplierOrderActionType.NobiliaCommentsReceived,
        key: SupplierOrderActionType.NobiliaCommentsReceived,
        value: SupplierOrderActionType.NobiliaCommentsReceived,
      },
      {
        text: SupplierOrderActionType.NobiliaAckReceived,
        key: SupplierOrderActionType.NobiliaAckReceived,
        value: SupplierOrderActionType.NobiliaAckReceived,
      },
      {
        text: SupplierOrderActionType.FORMCommentsSent,
        key: SupplierOrderActionType.FORMCommentsSent,
        value: SupplierOrderActionType.FORMCommentsSent,
      },
      ...(latestAckNumber
        ? [
            {
              text: SupplierOrderActionType.FORMAckApproved,
              key: SupplierOrderActionType.FORMAckApproved,
              value: SupplierOrderActionType.FORMAckApproved,
            },
          ]
        : []),
    ]
  }, [supplierOrderActions, orderSentAction, ediAction, latestAckNumber])

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title="Supplier Order Action"
      contentStyle={{
        overflow: 'initial',
      }}
      saveButton={{
        loading: loading,
        onClick: async () => {
          await onSave()
        },
      }}
    >
      <StyledForm>
        {supplierOrderAction ? (
          <Form.Field>
            <label>Action Type</label>
            <p>{supplierOrderAction.data.type}</p>
          </Form.Field>
        ) : (
          <Form.Dropdown
            label="Action Type"
            name="design"
            onChange={(_, { value }) => {
              setState({
                ...state,
                ...(value === SupplierOrderActionType.FORMAckApproved
                  ? {
                      ackFile: latestAckFile,
                    }
                  : null),
                data: {
                  ...state.data,
                  nobiliaAckNumber: [
                    SupplierOrderActionType.NobiliaAckReceived,
                    SupplierOrderActionType.FORMAckApproved,
                  ].includes(value as SupplierOrderActionType)
                    ? (latestAckNumber as string)
                    : '',
                  type: value as SupplierOrderActionType,
                },
              })
            }}
            options={actionTypeOptions}
            placeholder="Select Action Type"
            search
            selection
            value={state.data?.type || ''}
          />
        )}
        <Form.Field>
          <label>Done At</label>
          <DateInput
            closable
            dateFormat={'MM/DD/YYYY'}
            timeFormat={'ampm'}
            duration={0}
            initialDate={moment().format('MM/DD/YYYY hh:mm a')}
            name="dueOn"
            onChange={(
              _: React.SyntheticEvent<HTMLElement, Event>,
              { value }: { value: string },
            ) => {
              setState({
                ...state,
                data: {
                  ...state.data,
                  doneAt: moment(value).format(),
                },
              })
            }}
            value={
              state.data?.doneAt
                ? moment(state.data?.doneAt).format('MM/DD/YYYY hh:mm a')
                : ''
            }
          />
        </Form.Field>
        {[
          SupplierOrderActionType.NobiliaAckReceived,
          SupplierOrderActionType.FORMAckApproved,
        ].includes(state.data?.type as SupplierOrderActionType) ? (
          <Form.Field>
            <label>Nobilia Ack Number</label>
            <Form.Input
              disabled={
                state.data?.type === SupplierOrderActionType.FORMAckApproved
              }
              name="nobiliaAckNumber"
              onChange={(
                _: React.SyntheticEvent<HTMLElement, Event>,
                { value }: { value: string },
              ) => {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    nobiliaAckNumber: value,
                  },
                })
              }}
              value={state.data?.nobiliaAckNumber || ''}
            />
          </Form.Field>
        ) : null}
      </StyledForm>
      {[
        SupplierOrderActionType.NobiliaAckReceived,
        SupplierOrderActionType.FORMAckApproved,
      ].includes(state.data?.type as SupplierOrderActionType) && (
        <Grid>
          <ProjectFileUploads
            columnsProps={{
              computer: 16,
              widescreen: 16,
            }}
            onlyOneFile
            onProjectFileRemoved={(_: Partial<ProjectFile>) => {
              setState((currentState) => ({
                ...currentState,
                ackFile: null,
              }))
            }}
            onProjectFilesUploaded={onProjectFilesUploaded}
            projectFiles={state.ackFile ? [state.ackFile] : []}
            projectFileType={ProjectFileType.ACK_FILE}
            projectId={project.id}
            refetch={refetch}
          />
        </Grid>
      )}
    </Modal>
  )
}

export default SupplierOrderActionModal
