import React, { useEffect } from 'react'

import { navigate } from 'gatsby'
import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import useCompanyMany from 'context/company/use-many'
import Button from 'design-system/components/button'

import CompaniesTable from './table'
import useCompanyFiltersSearch from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const CompanyIndexMany = () => {
  const { count, fetchMore, loading, loadingMore, companies, refetch } =
    useCompanyMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useCompanyFiltersSearch()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${companies?.length} of ${count}`}{' '}
            Companies
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            color="gray"
            kind="outlined"
            onClick={() => navigate(`/admin/companies/new`)}
            text="Create New"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            <CompaniesTable />
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && companies?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default CompanyIndexMany
