import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uniqBy } from 'lodash'
import { Button, Checkbox, Table } from 'semantic-ui-react'

import MultiMessageModal from 'components/admin/project/message/create-update-message-many-modal'
import SupplierOrderModal from 'components/admin/supplier-order/create-update-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useAuth from 'context/auth/use'
import useRouteMany from 'context/route/use-many'
import useRouteMutateNoContext from 'context/route/use-mutate-no-context'
import * as routeUtils from 'context/route/utils'
import * as supplierOrderUtils from 'context/supplier-order/utils'
import { StyledIcon, StyledLabelAnyColor } from 'styles/admin/main'
import { Route } from 'types/route'
import { SupplierOrder } from 'types/supplier-order'

import RouteModal from './create-update-modal'
import { SupplierOrdersCellContents } from '../supplier-order/table-cell-content-many'

const RoutesTable = () => {
  const { isNobiliaOrderProcessor } = useAuth()
  const { routes, refetch, queryFilters, setQueryFilters } = useRouteMany()
  const { sortBy, sortDirection } = queryFilters
  const { deleteRoute, loadingDelete, loadingUpdate, updateRoute } =
    useRouteMutateNoContext()

  const [routeToArchive, setRouteToArchive] = useState<Route>()
  const [routeToDelete, setRouteToDelete] = useState<Route>()
  const [routeToEdit, setRouteToEdit] = useState<Route>()
  const [routeToMessage, setRouteToMessage] = useState<Route>()
  const [supplierOrderToEdit, setSupplierOrderToEdit] =
    useState<SupplierOrder>()
  const [supplierOrdersShow, setSupplierOrdersShow] = useState(true)

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortBy === 'reference' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'reference',
                sortDirection:
                  sortBy === 'reference' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Reference
          </Table.HeaderCell>
          <Table.HeaderCell>Receiving Warehouse</Table.HeaderCell>
          <Table.HeaderCell>Volume / Weight</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>
            Orders
            <Checkbox
              checked={supplierOrdersShow}
              onChange={() => setSupplierOrdersShow(!supplierOrdersShow)}
              style={{ float: 'right' }}
              toggle
            />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedStatus' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedStatus',
                sortDirection:
                  sortBy === 'derivedStatus' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Status
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {routes.map((r: Route) => {
          const routeStatusSummary = routeUtils.getRouteStatusSummary(r)
          return (
            <Table.Row key={r.id} warning={!r.confirmedAt}>
              <Table.Cell width={2}>
                <div>
                  <div>
                    <StyledLabelAnyColor background={r.color} />
                    <span>{r.reference || ''}</span>
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell width={2}>{r.data?.receivingWarehouse}</Table.Cell>
              <Table.Cell width={2}>
                {supplierOrderUtils.sumSupplierOrderVolumes(r.supplierOrders)}{' '}
                m3 /{' '}
                {supplierOrderUtils.sumSupplierOrderWeights(r.supplierOrders)}{' '}
                kg
              </Table.Cell>
              <Table.Cell width={2}>{r.data?.type}</Table.Cell>
              <Table.Cell width={3}>
                {supplierOrdersShow ? (
                  <SupplierOrdersCellContents
                    onSelectSupplierOrder={(id: string) =>
                      setSupplierOrderToEdit(
                        r.supplierOrders.find((so) => so.id === id),
                      )
                    }
                    supplierOrders={r.supplierOrders}
                  />
                ) : (
                  `${r.supplierOrders.length} Total Order(s), ${
                    r.supplierOrders.filter((so) => so.project).length
                  } FORM Order(s)`
                )}
              </Table.Cell>
              <Table.Cell width={3}>
                <div>{routeStatusSummary.currentStage}</div>
                <div style={{ marginBottom: '5px' }}>
                  {routeStatusSummary.currentStageDate}
                  <StyledIcon
                    style={{ marginLeft: '5px', height: '10px', width: '10px' }}
                    icon={[
                      'fal',
                      routeStatusSummary.currentStageConfirmedAt
                        ? 'check'
                        : 'question',
                    ]}
                    styled={{
                      inactive: !routeStatusSummary.currentStageConfirmedAt,
                    }}
                  />
                </div>
              </Table.Cell>
              <Table.Cell width={2}>
                <Button
                  floated="right"
                  icon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
                  onClick={() => setRouteToDelete(r)}
                />
                {!r.archivedAt ? (
                  <Button
                    floated="right"
                    icon={<FontAwesomeIcon icon={['fal', 'archive']} />}
                    onClick={() => setRouteToArchive(r)}
                    loading={loadingUpdate}
                  />
                ) : null}

                {r.supplierOrders.length > 0 && !isNobiliaOrderProcessor && (
                  <Button
                    floated="right"
                    icon={<FontAwesomeIcon icon={['fal', 'envelope']} />}
                    onClick={() => setRouteToMessage(r)}
                  />
                )}
                <Button
                  floated="right"
                  icon={<FontAwesomeIcon icon={['fal', 'eye']} />}
                  onClick={() => setRouteToEdit(r)}
                  primary
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
        {isNobiliaOrderProcessor ? null : (
          <MultiMessageModal
            open={!!routeToMessage}
            onClose={() => setRouteToMessage(undefined)}
            projects={uniqBy(
              routeToMessage?.supplierOrders
                .filter((so) => so.project)
                .map((so) => so.project) || [],
              'id',
            )}
            title={`Route ${routeToMessage?.reference}`}
          />
        )}

        <RouteModal
          open={!!routeToEdit}
          onClose={() => setRouteToEdit(undefined)}
          refetch={refetch}
          routeId={routeToEdit?.id ?? ''}
        />
        <SupplierOrderModal
          onClose={() => setSupplierOrderToEdit(undefined)}
          open={!!supplierOrderToEdit}
          supplierOrderId={supplierOrderToEdit?.id ?? ''}
        />
        <ConfirmationModal
          firstTitle="Are you sure you want to delete the route?"
          loading={loadingDelete}
          onCancel={() => setRouteToDelete(undefined)}
          onConfirm={async () => {
            await deleteRoute({
              variables: {
                where: {
                  id: routeToDelete?.id ?? '',
                },
              },
            })
            setRouteToDelete(undefined)
          }}
          open={!!routeToDelete}
        />
        <ConfirmationModal
          firstTitle="Are you sure you want to archive the route *and* mark all orders as delivered?"
          loading={loadingUpdate}
          onCancel={() => setRouteToArchive(undefined)}
          onConfirm={async () => {
            if (routeToArchive) {
              await updateRoute({
                variables: {
                  data: {
                    archivedAt: new Date(),
                  },
                  where: {
                    id: routeToArchive.id ?? '',
                  },
                },
              })
            }
            setRouteToArchive(undefined)
          }}
          open={!!routeToArchive}
        />
      </Table.Body>
    </Table>
  )
}

export default RoutesTable
