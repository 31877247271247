import React from 'react'

import path from 'path'
import { Divider, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import MediaCard from 'components/admin/catalog/media/card'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import { Media } from 'types/media'

const Installation = ({
  installationMedia,
  refetch,
}: {
  installationMedia: Partial<Media>[]
  refetch: () => Promise<unknown>
}) => {
  const installationVideo = installationMedia.find((im) =>
    ['.MOV', '.MP4', '.WMV'].includes(path.extname(im.key ?? '').toUpperCase()),
  )
  const installationPdf = installationMedia.find((im) =>
    ['.PDF'].includes(path.extname(im.key ?? '').toUpperCase()),
  )
  return (
    <>
      <Divider style={{ width: '100%' }} />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h3>Installation Materials</h3>
          </Grid.Column>
        </Grid.Row>
        {installationMedia.length == 0 && (
          <Grid.Row>
            <Grid.Column>
              <p>No installation materials found</p>
            </Grid.Column>
          </Grid.Row>
        )}
        {installationVideo && (
          <Grid.Row stretched>
            <Grid.Column width={7}>
              <MediaCard media={installationVideo} refetch={refetch} />
              <p style={{ padding: '5px 0 45px 0' }}>
                {installationVideo.name}
              </p>
            </Grid.Column>
            {installationPdf && (
              <Grid.Column width={4}>
                <InstallationCard>
                  <div>
                    <p>Installation PDF manual</p>
                    <Button
                      kind="solid"
                      color="blue"
                      text="Open"
                      size="fluid"
                      onClick={() =>
                        window.open(
                          `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                            installationPdf.key ?? '',
                          )}`,
                        )
                      }
                    />
                  </div>
                  <Image
                    src={`${process.env.GATSBY_CDN_ROOT}/images/app/mini-pdf-preview.png`}
                  />
                </InstallationCard>
              </Grid.Column>
            )}
          </Grid.Row>
        )}
      </Grid>
    </>
  )
}

export default Installation

const InstallationCard = styled.div`
  background: ${Colors.gray100};
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  max-height: 120px;

  div {
    height: fit-content;
    padding: 20px 17px;
  }

  img {
    max-height: 120px;
  }
`
