import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_ONE_PROSPECT_STAFF,
  CreateOneProspectStaffPayload,
  CreateOneProspectStaffVariables,
  DELETE_ONE_PROSPECT_STAFF,
  DeleteOneProspectStaffPayload,
  DeleteOneProspectStaffVariables,
  UPDATE_ONE_PROSPECT_STAFF,
  UpdateOneProspectStaffPayload,
  UpdateOneProspectStaffVariables,
} from 'queries/prospect-staff'

import { ProspectContext } from './provider'

const useProspectStaffMutate = () => {
  const { refetch } = useContext(ProspectContext)

  const [createProspectStaffAndRefetch, { loading: loadingCreate }] =
    useMutation<CreateOneProspectStaffPayload, CreateOneProspectStaffVariables>(
      CREATE_ONE_PROSPECT_STAFF,
      {
        onCompleted: () => {
          refetch()
        },
      },
    )

  const [deleteProspectStaffAndRefetch, { loading: loadingDelete }] =
    useMutation<DeleteOneProspectStaffPayload, DeleteOneProspectStaffVariables>(
      DELETE_ONE_PROSPECT_STAFF,
      {
        onCompleted: () => {
          refetch()
        },
      },
    )

  const [updateProspectStaffAndRefetch, { loading: loadingUpdate }] =
    useMutation<UpdateOneProspectStaffPayload, UpdateOneProspectStaffVariables>(
      UPDATE_ONE_PROSPECT_STAFF,
      {
        onCompleted: () => {
          refetch()
        },
      },
    )

  return {
    createProspectStaffAndRefetch,
    deleteProspectStaffAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateProspectStaffAndRefetch,
  }
}

export default useProspectStaffMutate
