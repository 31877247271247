// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import * as BABYLON from 'babylonjs'
import lodash from 'lodash'
import Measure from 'react-measure'
import styled from 'styled-components'

import { RenderMode } from 'context/planner/types'

import { NodeRelationshipGraph } from '../compose'
import { SectionViewBase } from '../objects/annotations/section-view'
import { CameraBase, CameraScene } from '../objects/camera'
import { ArcRotateCameraScene } from '../objects/cameras/arcrotate'
import { TargetCameraScene } from '../objects/cameras/target'
import { UniversalCameraScene } from '../objects/cameras/universal'
import { MeshScene } from '../objects/mesh'
import { NodeBase, NodeScene, NodeType } from '../objects/node'
import * as NodeFactory from '../objects/node-factory'
import diff from '../utils/immutable-diff/diff'
import * as nodeUtils from '../utils/node-utils'

const StyledCanvas = styled.canvas`
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
`

const SCREENSHOT_RESOLUTION = 3840

interface PlannerSceneProps {
  activeCamera: CameraBase
  activeSectionView?: SectionViewBase
  cameraPosition?: BABYLON.Vector3
  cameraTarget?: BABYLON.Vector3
  onPointerDown?: (scene: BABYLON.Scene, canvas?: HTMLCanvasElement) => void
  onPointerMove?: (
    scene: BABYLON.Scene,
    canvas?: HTMLCanvasElement,
    dimensions?: {
      width: number
      height: number
    },
  ) => void
  onPointerUp?: (scene: BABYLON.Scene, canvas?: HTMLCanvasElement) => void
  recenterTriggeredAt?: Date
  renderMode: RenderMode
  sceneActive: boolean
  sceneCache: NodeScene[]
  sceneRelationshipGraph: NodeRelationshipGraph
  sceneState: NodeBase[]
  screenshotTriggeredAt?: Date
  selectedNode?: NodeBase
}

interface PlannerSceneState {
  dimensions: {
    width: number
    height: number
  }
}

class PlannerScene extends React.Component<
  PlannerSceneProps,
  PlannerSceneState
> {
  public canvas: HTMLCanvasElement
  public engine: BABYLON.Engine
  public scene: BABYLON.Scene

  constructor(props: PlannerSceneProps) {
    super(props)
    this.state = {
      dimensions: {
        // arbitrary initial values (resized by measure component)
        width: 1200,
        height: 800,
      },
    }
  }

  private addSceneEventListeners() {
    this.scene.onPointerDown = (_) =>
      this.props.onPointerDown?.(this.scene, this.canvas)
    this.scene.onPointerMove = (_) =>
      this.props.onPointerMove?.(this.scene, this.canvas, this.state.dimensions)
    this.scene.onPointerUp = (_) =>
      this.props.onPointerUp?.(this.scene, this.canvas)

    window.addEventListener('resize', this.onResizeWindow)
  }

  clearScene(except: NodeBase[]) {
    const names = except.map((n) => n.getNodeName())
    const toRemove: number[] = []
    for (const ns of this.props.sceneCache) {
      if (!names.includes(ns.nodeBase.getNodeName())) {
        ns.dispose()
        const toRemoveIdx = this.props.sceneCache.findIndex(
          (n) => n.nodeBase.getNodeName() === ns.nodeBase.getNodeName(),
        )
        toRemove.push(toRemoveIdx)
      }
    }
    const sorted = lodash.sortBy(toRemove).reverse()
    for (const i of sorted) {
      this.props.sceneCache.splice(i, 1)
    }
  }

  async componentDidMount() {
    // create engine
    this.engine = new BABYLON.Engine(
      this.canvas,
      true, // antialias
      {
        preserveDrawingBuffer: true,
      }, // BABYLON.EngineOptions
      false, // adaptToDeviceRatio
    )

    // create scene
    this.scene = new BABYLON.Scene(this.engine)
    this.scene.clearColor = new BABYLON.Color4(1, 1, 1, 1)

    this.addSceneEventListeners()

    // seed scene
    await this.updateScene([], this.props.sceneState)

    const activeCamera = this.getActiveCameraScene()
    this.scene.activeCamera = activeCamera.cameraBabylon
    activeCamera.attachControlIfAllowed(this.canvas)
    this.setupRenderingPipeline()
    this.setActiveCameraParameters({ skipIfInitialized: true })

    if (this.props.renderMode === RenderMode.MATERIALS) this.renderMaterials()
    else this.renderLines()

    // Run render loop
    this.engine.runRenderLoop(() => {
      this.scene.render()
    })
  }

  async componentDidUpdate(prevProps: PlannerSceneProps) {
    if (this.props.sceneActive && !prevProps.sceneActive) {
      const nonRoomElementsOrProductsOrVolumes = this.props.sceneState.filter(
        (n) => !n.isRoomElement() && !n.isProduct() && !n.isVolume(),
      )
      this.clearScene(nonRoomElementsOrProductsOrVolumes)
      await this.updateScene(
        nonRoomElementsOrProductsOrVolumes,
        this.props.sceneState,
      )
      const activeCamera = this.getActiveCameraScene()
      this.scene.activeCamera = activeCamera.cameraBabylon
      activeCamera.attachControlIfAllowed(this.canvas)
      this.setActiveCameraParameters({ skipIfInitialized: true })

      if (this.props.renderMode === RenderMode.MATERIALS) this.renderMaterials()
      else this.renderLines()
    } else if (this.props.sceneActive) {
      await this.updateScene(prevProps.sceneState, this.props.sceneState)
      if (prevProps.activeCamera !== this.props.activeCamera) {
        const prevCamera = nodeUtils.findNodeSceneByName(
          this.props.sceneCache,
          prevProps.activeCamera.getNodeName(),
        ) as CameraScene
        prevCamera.detachControl(this.canvas)

        const activeCamera = this.getActiveCameraScene()
        this.scene.activeCamera = activeCamera.cameraBabylon
        activeCamera.attachControlIfAllowed(this.canvas)
        this.setActiveCameraParameters({ skipIfInitialized: true })
      }

      if (
        !(prevProps.renderMode === RenderMode.MATERIALS) &&
        this.props.renderMode === RenderMode.MATERIALS
      ) {
        this.renderMaterials()
      } else if (this.props.renderMode === RenderMode.LINES) {
        this.renderLines()
      }

      if (
        this.props.screenshotTriggeredAt !== prevProps.screenshotTriggeredAt
      ) {
        BABYLON.Tools.CreateScreenshotUsingRenderTarget(
          this.engine,
          this.scene.activeCamera,
          SCREENSHOT_RESOLUTION,
        )
      }

      if (this.props.recenterTriggeredAt !== prevProps.recenterTriggeredAt) {
        this.setActiveCameraParameters()
      }

      if (
        this.props.activeSectionView &&
        this.state.dimensions.width / this.state.dimensions.height
      )
        this.setActiveCameraParametersForSectionView()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow)
  }

  createCanvasRef(c: HTMLCanvasElement) {
    if (c) this.canvas = c
  }

  getActiveCameraScene() {
    return nodeUtils.findNodeSceneByName(
      this.props.sceneCache,
      this.props.activeCamera.getNodeName(),
    ) as CameraScene
  }

  onResizeWindow = () => {
    if (this.engine) this.engine.resize()
  }

  renderLines = () => {
    this.scene.lightsEnabled = false
    this.props.sceneCache.forEach((m) => {
      if (![NodeType.CAMERA, NodeType.LIGHT].includes(m.nodeBase.getType()))
        (m as MeshScene).setLines(this)
    })
  }

  renderMaterials = () => {
    this.scene.lightsEnabled = true
    this.props.sceneCache.forEach((m) => {
      if (![NodeType.CAMERA, NodeType.LIGHT].includes(m.nodeBase.getType()))
        (m as MeshScene).setMaterials(this)
    })
  }

  setupRenderingPipeline = () => {
    const pipeline = new BABYLON.DefaultRenderingPipeline(
      'defaultPipeline',
      false,
      this.scene,
      this.scene.cameras,
    )
    pipeline.sharpenEnabled = true
    pipeline.sharpen.edgeAmount = -0.1
    pipeline.samples = 16
  }

  setActiveCameraParameters(params?: { skipIfInitialized: boolean }) {
    if (!this.props.cameraTarget) return
    if (this.props.activeCamera.isArcRotateCamera()) {
      const cameraScene = this.getActiveCameraScene() as ArcRotateCameraScene
      cameraScene.setParameters({
        skipIfInitialized: params?.skipIfInitialized,
        target: this.props.cameraTarget,
      })
    }
    if (this.props.activeCamera.isTargetCamera()) {
      const cameraScene = this.getActiveCameraScene() as TargetCameraScene
      cameraScene.setParameters({
        skipIfInitialized: params?.skipIfInitialized,
        position: this.props.cameraPosition,
        target: this.props.cameraTarget,
      })
    }
    if (this.props.activeCamera.isUniversalCamera()) {
      const cameraScene = this.getActiveCameraScene() as UniversalCameraScene
      cameraScene.setParameters({
        skipIfInitialized: params?.skipIfInitialized,
        position: this.props.cameraPosition,
        target: this.props.cameraTarget,
      })
    }
  }

  setActiveCameraParametersForSectionView() {
    if (this.props.activeCamera.isTargetCamera()) {
      const cameraScene = this.getActiveCameraScene() as TargetCameraScene
      cameraScene.setOrthoParamsForSectionView(
        this.props.activeSectionView,
        this.state.dimensions.width / this.state.dimensions.height,
      )
    }
    if (this.props.activeCamera.isUniversalCamera()) {
      const cameraScene = this.getActiveCameraScene() as UniversalCameraScene
      // TODO: write ortho for estimator
      if (this.props.activeCamera.isPerspective())
        cameraScene.setPerspectiveParamsForSectionView(
          this.props.activeSectionView,
        )
    }
  }

  async updateScene(prevNodes: NodeBase[], currentNodes: NodeBase[]) {
    const [prevChildren, prevParents] = nodeUtils.separateChildren(prevNodes)
    const [currChildren, currParents] = nodeUtils.separateChildren(currentNodes)
    await this.updateNodes(prevChildren, currChildren)
    await this.updateNodes(prevParents, currParents)
  }

  async updateNodes(prevNodes: NodeBase[], currentNodes: NodeBase[]) {
    const prevToDiff = nodeUtils.nodesToDiff(prevNodes)
    const currToDiff = nodeUtils.nodesToDiff(currentNodes)
    const changes = diff(prevToDiff, currToDiff).toJS()

    for (const c of changes) {
      switch (c.op) {
        case 'add': {
          const addIdx = c.path.split('/')[1]
          const toAdd = currentNodes.find((o) => o.getNodeName() === addIdx)
          const toAddScene = NodeFactory.createNodeScene(toAdd)
          if (toAddScene) {
            await toAddScene.create(this)
            this.props.sceneCache.push(toAddScene)
          }
          break
        }
        case 'remove': {
          const removeIdx = c.path.split('/')[1]
          const toRemove = prevNodes.find((o) => o.getNodeName() === removeIdx)
          const toRemoveScene = this.props.sceneCache.find(
            (n) => n.nodeBase.getNodeName() === toRemove.getNodeName(),
          )
          toRemoveScene?.dispose()
          this.props.sceneCache.filter(
            (n) => n.nodeBase.getNodeName() !== toRemove.getNodeName(),
          )
          break
        }
        default:
          console.log(c)
      }
    }
  }

  render() {
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          if (this.state.dimensions.width !== contentRect.bounds.width) {
            this.setState({
              dimensions: {
                width: contentRect.bounds.width,
                // TODO: make height dynamic
                // document.body.scrollHeight - offsetTop
                height: contentRect.bounds.height,
              },
            })
          }
        }}
      >
        {({ measureRef }) => {
          return (
            <div style={{ height: '100%' }} ref={measureRef}>
              <StyledCanvas
                width={this.state.dimensions.width}
                height={this.state.dimensions.height}
                ref={this.createCanvasRef.bind(this)}
              />
            </div>
          )
        }}
      </Measure>
    )
  }
}

export default PlannerScene
