import React, { useState } from 'react'

import { isEqual } from 'lodash'
import styled from 'styled-components'

import SaveModal from 'components/admin/save-modal'
import useSelectionCenterMutate from 'context/selection-centers/use-mutate'
import Tabs, { TabContent } from 'design-system/components/tabs'
import { ScrollBarStyle } from 'styles/app/system'
import { SelectionCenter } from 'types/selection-center'
import { SelectionCenterStaffRole } from 'types/selection-center-staff'

import InfoTab from './info'
import StaffTab from './staff'

export type State = Omit<SelectionCenter, 'id' | 'createdAt' | 'updatedAt'>

type Tab = 'info' | 'staff'

const tabs: { value: Tab; label: string }[] = [
  { value: 'info', label: 'Information' },
  { value: 'staff', label: 'Staff' },
]

const tabMap: Record<Tab, number> = {
  info: 0,
  staff: 1,
}

type SelectionCenterTabProps = {
  selectionCenter: SelectionCenter | null
  onComplete?: () => void
}

const SelectionCenterTab = ({
  selectionCenter,
  onComplete,
}: SelectionCenterTabProps) => {
  const [currentTab, setCurrentTab] = useState<Tab>('info')
  const {
    createSelectionCenter,
    loadingCreate,
    updateSelectionCenter,
    loadingUpdate,
  } = useSelectionCenterMutate()
  const initialState: NullableRecord<State> = {
    identifier: selectionCenter?.identifier ?? null,
    name: selectionCenter?.name ?? null,
    staff: selectionCenter?.staff ?? [],
    data: selectionCenter?.data ?? null,
  }
  const [state, setState] = useState(initialState)

  const onUpdate = async ({ identifier, name, staff, data }: State) => {
    if (!selectionCenter) return

    const newStaffIds = staff.map((s) => s.staffMember?.id)
    const oldStaffIds = selectionCenter.staff.map((s) => s.staffMember?.id)

    const staffToRemove = selectionCenter.staff.filter(
      (s) => !newStaffIds.includes(s.staffMember?.id),
    )
    const staffToAdd = staff.filter(
      (s) => !oldStaffIds.includes(s.staffMember?.id),
    )

    const udpateCandidates = staff.filter((s) =>
      oldStaffIds.includes(s.staffMember?.id),
    )

    const staffToUpdate = udpateCandidates.filter((s) => {
      const prev = selectionCenter.staff.find(
        (ps) => ps.staffMemberId === s.staffMemberId,
      )

      return (
        !!prev &&
        !isEqual(
          {
            id: prev.staffMemberId,
            active: prev.isActive,
          },
          {
            id: s.staffMemberId,
            active: s.isActive,
          },
        )
      )
    })

    await updateSelectionCenter({
      variables: {
        data: {
          identifier,
          name,
          data: {
            ...selectionCenter.data,
            ...data,
          },
          staff: {
            update: staffToUpdate.map((s) => ({
              data: {
                isActive: s.isActive,
              },
              where: {
                selectionCenterId_staffMemberId: {
                  selectionCenterId: selectionCenter.id,
                  staffMemberId: s.staffMember?.id ?? '',
                },
              },
            })),
            create: staffToAdd.map((s) => ({
              isActive: s.isActive ?? null,
              staffMemberRole: s.staffMemberRole as SelectionCenterStaffRole,
              staffMember: {
                connect: {
                  id: s.staffMember?.id ?? '',
                },
              },
            })),
            delete: staffToRemove.map((s) => ({
              selectionCenterId_staffMemberId: {
                selectionCenterId: selectionCenter.id,
                staffMemberId: s.staffMember?.id ?? '',
              },
            })),
          },
        },
        where: {
          id: selectionCenter.id,
        },
      },
    })
    onComplete?.()
  }

  const onCreate = async ({ identifier, name, staff, data }: State) => {
    await createSelectionCenter({
      variables: {
        data: {
          identifier,
          name,
          data,
          staff: staff?.length
            ? {
                create: staff.map((s) => ({
                  staffMember: {
                    connect: {
                      id: s.staffMember?.id ?? '',
                    },
                  },
                  isActive: s.isActive ?? null,
                  staffMemberRole:
                    s.staffMemberRole as SelectionCenterStaffRole,
                })),
              }
            : undefined,
        },
      },
    })
    onComplete?.()
  }

  const handleTabClick = (_: unknown, { value }: { value: Tab }) => {
    setCurrentTab(value)
  }

  const onSave = async () => {
    if (selectionCenter) {
      await onUpdate(state as State)
      return
    }

    await onCreate(state as State)
  }

  const isValid =
    !!state.staff?.length &&
    !!state.identifier &&
    !!state.name &&
    !!state.data?.address &&
    !!state.data?.calendlyUrl &&
    !!state.data?.illustration &&
    !!state.data?.timezone

  return (
    <div>
      <Tabs
        tabs={tabs}
        currentTab={tabMap[currentTab]}
        handleClick={handleTabClick}
        variant="plain"
      />
      <TabsWrapper>
        <TabContent active={currentTab === 'info'} style={{ height: '100%' }}>
          <InfoTab
            setState={setState}
            loading={loadingCreate || loadingUpdate}
            state={state}
          />
        </TabContent>
        <TabContent active={currentTab === 'staff'} style={{ height: '100%' }}>
          <StaffTab
            setState={setState}
            loading={loadingCreate || loadingUpdate}
            state={state}
          />
        </TabContent>
      </TabsWrapper>

      <SaveModal
        hideSegments
        currentState={state}
        initialState={initialState}
        disableSave={!isValid}
        loadingSave={loadingCreate || loadingUpdate}
        onDiscard={() => {
          setState(initialState)
        }}
        onSave={onSave}
      />
    </div>
  )
}

export default SelectionCenterTab

const TabsWrapper = styled.div`
  ${ScrollBarStyle}

  height: 600px;
  overflow-y: auto;
  padding-inline: 2rem;
  padding-block: 8px;
`
