import React from 'react'

import { RouteComponentProps } from '@reach/router'

import DeliveryProvider from 'context/delivery/provider'

interface DeliveryProps
  extends RouteComponentProps<{
    project_id: string
    delivery_id: string
  }> {
  children?: React.ReactNode
}

export default (props: DeliveryProps) => (
  <DeliveryProvider delivery_id={props.delivery_id}>
    {props.children}
  </DeliveryProvider>
)
