import React, { useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'

import CatalogFeaturesSelection from 'components/shared/catalog/catalog-feature/selection'
import CatalogFeatureManyProvider from 'context/catalog-feature/provider-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { CatalogFeature } from 'types/catalog-feature'

const CatalogFeaturesModal = ({
  onSave,
  selectedCatalogFeatures,
  title,
}: {
  onSave: (catalogFeatures: Partial<CatalogFeature>[]) => void
  selectedCatalogFeatures: Partial<CatalogFeature>[]
  title?: string
}) => {
  const [catalogFeatures, setCatalogFeatures] = useState<
    Partial<CatalogFeature>[]
  >(selectedCatalogFeatures)
  const [showModal, toggleModal] = useState<boolean>(false)
  const [defaultFilters] = useState({
    sortBy: 'groupIdentifier' as const,
    sortDirection: 'ascending' as const,
    take: 40,
  })

  useEffect(
    () => setCatalogFeatures(selectedCatalogFeatures),
    [selectedCatalogFeatures],
  )

  const onClose = () => {
    setCatalogFeatures(selectedCatalogFeatures)
    toggleModal(false)
  }

  return (
    <Modal
      onClose={onClose}
      open={showModal}
      size="large"
      title={`Choose ${title || 'options'}`}
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row>
            <Grid.Column width={13}>
              {catalogFeatures.length ? (
                <p>{catalogFeatures.length} features selected</p>
              ) : null}
            </Grid.Column>
            <Grid.Column width={3}>
              <div className="flex-center" style={{ flexWrap: 'unset' }}>
                <Button
                  kind="outlined"
                  text="Close"
                  color="gray"
                  onClick={onClose}
                  style={{ marginRight: '20px' }}
                />
                <Button
                  kind="solid"
                  text="Save"
                  onClick={() => {
                    onSave(catalogFeatures)
                    toggleModal(false)
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      trigger={
        <Button
          kind="ghost"
          fontAwesomeIcon={catalogFeatures.length ? 'pencil' : 'plus'}
          text={catalogFeatures.length ? 'Change' : 'Add'}
          onClick={() => toggleModal(true)}
        />
      }
    >
      <CatalogFeatureManyProvider
        catalog={process.env.GATSBY_DEFAULT_CATALOG}
        defaultFilters={defaultFilters}
        skipLoader
      >
        <CatalogFeaturesSelection
          selectedCatalogFeatures={catalogFeatures}
          setSelectedCatalogFeatures={setCatalogFeatures}
        />
      </CatalogFeatureManyProvider>
    </Modal>
  )
}

export default CatalogFeaturesModal
