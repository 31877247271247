import React, { useState } from 'react'

import { Divider, Form, Grid, Loader as Loading } from 'semantic-ui-react'

import SupplierOrdersMap from 'components/admin/supplier-order/map'
import { LoadButton } from 'components/shared/load-buttons'
import useSupplierOrderMany from 'context/supplier-order/use-many'
import CheckHighlighted from 'design-system/components/check-highlighted'
import { SupplierOrder } from 'types/supplier-order'

import LogisticsJobButtons from './logistics-job-buttons'
import SupplierOrdersTablePlanning from './table-planning'
import useSupplierOrderFiltersSearch from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const SupplierOrdersPlanning = ({
  includeFilters,
  includeJobButtons,
}: {
  includeFilters?: boolean
  includeJobButtons?: boolean
}) => {
  const { count, supplierOrders, fetchMoreOnDemand, loading, loadingMore } =
    useSupplierOrderMany()

  const [supplierOrderHighlighted, setSupplierOrderHighlighted] =
    useState<SupplierOrder | null>(null)
  const [supplierOrderSelected, setSupplierOrderSelected] =
    useState<SupplierOrder | null>(null)
  const [show, setShow] = useState<'shipments' | 'routes'>('shipments')
  const [showPorts, setShowPorts] = useState<boolean>(false)
  const [showWarehouses, setShowWarehouses] = useState<boolean>(false)
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useSupplierOrderFiltersSearch()

  return (
    <Grid>
      {includeFilters && (
        <FiltersAndSearch
          defaultAppliedFilters={defaultFilters}
          filters={filters}
          onApply={handleApplyFilters}
          handleSearchChange={handleSearch}
        />
      )}
      <Grid.Row style={{ padding: '10px 0 0 0' }}>
        <Grid.Column width={4}>
          <p className="gray small">
            {loading ? `???` : `Showing ${supplierOrders?.length} of ${count}`}{' '}
            Supplier Orders
          </p>
        </Grid.Column>
        <Grid.Column width={12} textAlign="right">
          {includeJobButtons && <LogisticsJobButtons />}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: '0' }}>
        <Grid.Column>
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <SupplierOrdersTablePlanning
            onSupplierOrderHighlighted={(so) => {
              setSupplierOrderHighlighted(so)
            }}
            supplierOrderHighlighted={supplierOrderHighlighted ?? undefined}
            supplierOrderSelected={supplierOrderSelected ?? undefined}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={7}>
                <Form>
                  <Form.Dropdown
                    onChange={(_, { value }) => {
                      setShow(value as 'shipments' | 'routes')
                    }}
                    options={[
                      {
                        key: 'routes',
                        text: 'Showing Routes',
                        value: 'routes',
                      },
                      {
                        key: 'shipments',
                        text: 'Showing Shipments',
                        value: 'shipments',
                      },
                    ]}
                    selection
                    value={show}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width={4}>
                <CheckHighlighted
                  checked={showPorts}
                  color="gray"
                  text="Ports"
                  onChange={() => setShowPorts(!showPorts)}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <CheckHighlighted
                  checked={showWarehouses}
                  color="gray"
                  text="Warehouses"
                  onChange={() => setShowWarehouses(!showWarehouses)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <SupplierOrdersMap
                  onSupplierOrderHighlighted={(so) =>
                    setSupplierOrderHighlighted(so)
                  }
                  onSupplierOrderSelected={(so) => setSupplierOrderSelected(so)}
                  supplierOrderHighlighted={
                    supplierOrderHighlighted ?? undefined
                  }
                  supplierOrderSelected={
                    supplierOrderHighlighted as SupplierOrder
                  }
                  supplierOrders={supplierOrders}
                  show={show}
                  showPorts={showPorts}
                  showWarehouses={showWarehouses}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading &&
        supplierOrders?.length > 0 &&
        supplierOrders.length !== count && (
          <LoadButton onClick={fetchMoreOnDemand} />
        )}
    </Grid>
  )
}

export default SupplierOrdersPlanning
