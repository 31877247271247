import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import MeetingsUpcoming from 'components/admin/meeting/dashboard-upcoming'
import MessagesInbox from 'components/admin/message/dashboard-inbox'
import ActivityFeed from 'components/admin/project-event'
import useCustomerSupport from 'context/dashboard/customer-support/use'
import ProjectEventsManyProvider from 'context/project-event/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import CustomerSupportLayout from './layout'

const CustomerSupportOverview = (_: RouteComponentProps) => {
  const { meetings, messages, refetchMessages, userId } = useCustomerSupport()
  return (
    <CustomerSupportLayout>
      <Grid stackable doubling padded>
        <MeetingsUpcoming meetings={meetings} />
        <Grid.Row>
          <Grid.Column width={9}>
            <ProjectEventsManyProvider
              defaultFilters={{
                assignedTo: {
                  id: userId,
                },
                assignedToRole: ProjectStaffRole.CUSTOMER_SUPPORT,
                newFor: {
                  id: userId,
                },
                showStaff: true,
                take: 40,
                sortBy: 'createdAt',
                sortDirection: 'descending',
              }}
              formUserId={userId}
            >
              <ActivityFeed formUserId={userId} />
            </ProjectEventsManyProvider>
          </Grid.Column>
          <Grid.Column width={7}>
            <MessagesInbox messages={messages} refetch={refetchMessages} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CustomerSupportLayout>
  )
}

export default CustomerSupportOverview
