import React from 'react'

import { sortBy } from 'lodash'
import { Divider, Grid } from 'semantic-ui-react'

import AppliancesTableRendering from 'components/admin/project/room/generic-product/table-rendering'
import CatalogFeatureSelectionCard from 'components/shared/catalog/catalog-feature/card'
import DisconnectedOptionsSelectionCard from 'components/shared/catalog/disconnected-option/card'
import GenericOptionSelectionCard from 'components/shared/catalog/generic-option/card'
import NobiliaOptionSelectionCard from 'components/shared/catalog/nobilia-option/card'
import RoomElementOptionSelectionCard from 'components/shared/catalog/room-element-option/card'
import FileCard from 'components/shared/project/project-file/card'
import useAuth from 'context/auth/use'
import useDesign from 'context/design/use'
import StyledAccordion from 'design-system/components/accordion'
import {
  SelectionCardExpandSelectProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { GenericOption } from 'types/generic-option'
import { NobiliaOption } from 'types/nobilia-option'

const DesignAssets = () => {
  const { isAdmin } = useAuth()
  const {
    backsplashMaterials,
    catalogFeatures,
    countertopMaterials,
    design,
    floorMaterials,
    fronts,
    handles,
    inspirationElements,
    inspirationGenerals,
  } = useDesign()

  const renderGenericOptions = (
    title: string,
    elements: Partial<GenericOption>[],
  ) =>
    elements.length > 0 && (
      <>
        {elements.map((element, idx) => (
          <Grid.Column key={idx} computer={4} tablet={5} mobile={16}>
            <p className="caption" style={{ marginTop: '10px' }}>
              {title}
            </p>
            <GenericOptionSelectionCard
              genericOption={element}
              selectionCardProps={
                {
                  imageRatio: '1/1',
                  expandOnly: true,
                } as Partial<SelectionCardExpandSelectProps>
              }
              selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
            />
          </Grid.Column>
        ))}
      </>
    )

  const renderNobiliaOptions = (
    title: string,
    elements: Partial<NobiliaOption>[],
  ) =>
    elements.length > 0 && (
      <>
        <p className="caption" style={{ marginTop: '10px' }}>
          {title}
        </p>
        <Grid.Row>
          {elements.map((element, idx) => (
            <Grid.Column key={idx} computer={4} tablet={5} mobile={16}>
              <NobiliaOptionSelectionCard
                nobiliaOption={element}
                selectionCardProps={
                  {
                    expandOnly: true,
                  } as Partial<SelectionCardExpandSelectProps>
                }
                selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </>
    )

  return (
    <StyledAccordion backgroundWhite titleText="Assets">
      <Grid>
        {renderNobiliaOptions('Fronts', fronts ?? [])}
        {renderNobiliaOptions('Handles', handles ?? [])}
        <Grid.Row>
          {renderGenericOptions('Countertops', countertopMaterials ?? [])}
          {floorMaterials?.map((fm, idx) => (
            <Grid.Column key={idx} computer={4} tablet={5} mobile={16}>
              <p className="caption" style={{ marginTop: '10px' }}>
                Flooring
              </p>
              <RoomElementOptionSelectionCard
                roomElementOption={fm}
                selectionCardProps={
                  {
                    expandOnly: true,
                  } as Partial<SelectionCardExpandSelectProps>
                }
                selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
              />
            </Grid.Column>
          ))}
          {renderGenericOptions('Backsplash', backsplashMaterials ?? [])}
        </Grid.Row>
        {!catalogFeatures?.length ? (
          <>
            <Grid.Row>
              <Grid.Column>
                <p className="caption" style={{ marginTop: '10px' }}>
                  Accessories
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {catalogFeatures?.map((cf, idx) => (
                <Grid.Column key={idx} computer={4} tablet={5} mobile={16}>
                  <CatalogFeatureSelectionCard
                    catalogFeature={cf}
                    selectionCardProps={
                      {
                        imageRatio: '1/1',
                        expandOnly: true,
                      } as Partial<SelectionCardExpandSelectProps>
                    }
                    selectionCardVariation={
                      SelectionCardVariation.EXPAND_SELECT
                    }
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </>
        ) : null}
        {inspirationElements?.length || inspirationGenerals?.length ? (
          <>
            <Grid.Row>
              <Grid.Column>
                <p className="caption">Inspiration</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {inspirationElements?.map((ie, idx) => (
                <Grid.Column key={idx} computer={4} tablet={5} mobile={16}>
                  <DisconnectedOptionsSelectionCard
                    disconnectedOption={ie}
                    selectionCardProps={
                      {
                        imageRatio: '1/1',
                        expandOnly: true,
                      } as Partial<SelectionCardExpandSelectProps>
                    }
                    selectionCardVariation={
                      SelectionCardVariation.EXPAND_SELECT
                    }
                  />
                </Grid.Column>
              ))}
              {inspirationGenerals?.map((im, idx) => (
                <Grid.Column key={idx} computer={4} tablet={5} mobile={16}>
                  <DisconnectedOptionsSelectionCard
                    disconnectedOption={im}
                    selectionCardProps={
                      {
                        imageRatio: '3/2',
                        expandOnly: true,
                      } as Partial<SelectionCardExpandSelectProps>
                    }
                    selectionCardVariation={
                      SelectionCardVariation.EXPAND_SELECT
                    }
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </>
        ) : null}
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <p className="caption">Appliances</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <AppliancesTableRendering />
          </Grid.Column>
        </Grid.Row>
        {isAdmin && (
          <>
            <Divider />
            <Grid.Row columns={2}>
              <Grid.Column width={8}>
                <p className="caption">Render notes</p>
                <pre
                  style={{
                    fontFamily: 'GT America',
                    whiteSpace: 'normal',
                  }}
                >
                  {design?.metadata?.initialRenderNotes || 'No notes'}
                </pre>
              </Grid.Column>
              <Grid.Column width={8}>
                <p className="caption">
                  Visual notes / Propping locations / Camera angles
                </p>
                <Grid>
                  <Grid.Row columns={2}>
                    {sortBy(design?.renderFeedbackFiles, 'name').map((f) => (
                      <Grid.Column key={f.id} style={{ marginBottom: '25px' }}>
                        <FileCard
                          file={f}
                          navigateTo={`/admin/files/${f.id}/`}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </StyledAccordion>
  )
}

export default DesignAssets
