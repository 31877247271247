import { useMemo } from 'react'

import useUserMany from 'context/user/use-many'
import {
  UserType,
  UserTypeHomeowner,
  UserTypePartner,
  UserTypeStaff,
  UserTypeTrade,
} from 'types/user'
import {
  capsSnakeCaseToTitleCase,
  getDropdownOptionsFromEnum,
} from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormCheckboxValue,
} from '../filters-search'

export type UserFilterValues = {
  userTypes: IFormCheckboxValue
}

const useUserFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useUserMany()
  const filters: DynamicFilterGroup<UserFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'userTypes',
            type: DynamicFilterTypes.CHECKBOX,
            label: 'User Types',
            options: getDropdownOptionsFromEnum({
              ...UserTypeStaff,
              ...UserTypePartner,
              ...UserTypeHomeowner,
              ...UserTypeTrade,
            }),
          },
        ],
      },
    ]
  }, [])

  const defaultFilters = useMemo(() => {
    const userFilters: Partial<UserFilterValues> = {}

    if (queryFilters.userTypes?.length)
      userFilters.userTypes = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.userTypes.map((s) => {
          return {
            value: s,
            text: capsSnakeCaseToTitleCase(s),
          }
        }),
      }
    return userFilters
  }, [queryFilters])

  const handleApplyFilters = (data: UserFilterValues) => {
    setQueryFilters({
      userTypes: data.userTypes?.selection.map((f) => {
        return f.value as UserType
      }),
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useUserFiltersSearch
