// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Slider } from 'react-semantic-ui-range'
import { Accordion, Form } from 'semantic-ui-react'
import styled from 'styled-components'

import { StyledAccordion, StyledForm } from 'styles/planner/main'
import { camelCaseToTitleCase } from 'views/utils'

import { NodeParameter } from '../../../objects/node'

interface NodeParametersProps {
  ps: NodeParameter[]
  onParameterChange: (changedNodeParameter: NodeParameter) => void
}

const NodeParameters = ({ ps, onParameterChange }: NodeParametersProps) => {
  const [paramsActive, setParamsActive] = useState(false)

  return (
    <StyledAccordion fluid transparent="true">
      <Accordion.Title
        active={paramsActive}
        onClick={() => setParamsActive(!paramsActive)}
      >
        <FontAwesomeIcon
          icon={['fal', paramsActive ? 'chevron-down' : 'chevron-right']}
        />
        <p>Parameters</p>
      </Accordion.Title>
      <Accordion.Content active={paramsActive}>
        {paramsActive ? (
          <StyledForm>
            {ps
              .filter((p) => !p.hidden)
              .map((p) => (
                <NodeParameterForm
                  key={p.key}
                  p={p}
                  onSubmit={(value: number) =>
                    onParameterChange({
                      ...p,
                      value,
                    })
                  }
                />
              ))}
          </StyledForm>
        ) : null}
      </Accordion.Content>
    </StyledAccordion>
  )
}

const NodeParameterForm = ({
  p,
  onSubmit,
}: {
  p: NodeParameter
  onSubmit: (val: number) => void
}) => {
  const [parameter, setParameter] = useState<number>(p.value)
  useEffect(() => {
    setParameter(p.value)
  }, [p])

  return (
    <>
      <label>
        <p className="gray">{camelCaseToTitleCase(p.key)}</p>
      </label>
      <Form.Group widths="equal" style={{ alignItems: 'center' }}>
        {p.range ? (
          <FormField>
            <Slider
              disabled={parameter < p.range.min || parameter > p.range.max}
              settings={{
                start: p.value,
                min: p.range.min,
                max: p.range.max,
                onChange: (value: number) => {
                  setParameter(value)
                },
                step: p.range.step,
              }}
              style={{
                marginTop: '14px',
                ...((parameter < p.range.min || parameter > p.range.max) && {
                  thumb: {
                    visibility: 'hidden',
                  },
                }),
              }}
              value={parameter}
            />
            <p className="small gray no-margin" style={{ float: 'left' }}>
              {p.range.min}
            </p>
            <p className="small gray no-margin" style={{ float: 'right' }}>
              {p.range.max}
            </p>
          </FormField>
        ) : null}
        <Form.Input
          type="number"
          placeholder={p.key}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            const val = parseFloat(value)
            setParameter(val)
          }}
          value={parameter}
          width={p.range ? '7' : '16'}
        />
      </Form.Group>
      {parameter !== p.value ? (
        <Form.Button
          content="Apply Changes"
          onClick={() => {
            onSubmit(parameter)
          }}
        />
      ) : null}
    </>
  )
}

export default NodeParameters

const FormField = styled(Form.Field)`
  &&& {
    margin: 0 !important;
  }
`
