import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import FiltersAndSearch from 'components/admin/filters-search'
import { BackToTop } from 'components/shared/load-buttons'
import useAuth from 'context/auth/use'
import useGenericOptionMany from 'context/generic-option/use-many'
import Button from 'design-system/components/button'

import GenericOptionCreateUpdateModal from './create-update-modal'
import GenericOptionsTable from './table'
import useGenericOptionFiltersSearch from './use-filters-search'

const GenericOptions = ({ table }: { table?: React.ReactNode }) => {
  const [showModal, setShowModal] = useState(false)
  const { isSuperAdmin } = useAuth()
  const {
    count,
    catalog,
    genericOptions,
    fetchMore,
    loading,
    loadingMore,
    refetch,
    queryFilters,
  } = useGenericOptionMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useGenericOptionFiltersSearch()
  const feature = genericOptions.find(
    (go) => go.feature?.id === queryFilters.feature?.id,
  )?.feature

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${genericOptions?.length} of ${count}`}{' '}
            Generic Options
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <GenericOptionCreateUpdateModal
            featureIdentifier={feature?.identifier ?? ''}
            onClose={() => setShowModal(false)}
            openModal={showModal}
            onSave={(id: string, featureId: string) => {
              setShowModal(false)
              refetch()
              navigate(
                `/admin/catalog/${catalog}/generic-features/${featureId}/generic-options/${id}`,
              )
            }}
            trigger={
              <Button
                color="gray"
                disabled={!isSuperAdmin}
                kind="outlined"
                onClick={() => setShowModal(true)}
                text="Create New"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table || <GenericOptionsTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && genericOptions?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default GenericOptions
