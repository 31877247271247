import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  UPDATE_SETTINGS,
  UpdateSettingsPayload,
  UpdateSettingsVariables,
} from 'queries/settings'

import { SettingsContext } from './provider'

const useSettingsMutate = () => {
  const { refetch } = useContext(SettingsContext)

  const [updateSettings, { loading: loadingUpdate }] = useMutation<
    UpdateSettingsPayload,
    UpdateSettingsVariables
  >(UPDATE_SETTINGS)

  const updateSettingsAndRefetch = async ({
    variables,
    onCompleted,
  }: {
    variables: UpdateSettingsVariables
    onCompleted?: () => void
  }) => {
    const res = await updateSettings({ variables, onCompleted })
    await refetch()
    return res
  }

  return {
    loadingUpdate,
    updateSettings,
    updateSettingsAndRefetch,
  }
}

export default useSettingsMutate
