import React, { useEffect, useState } from 'react'

import TextareaAutosize from 'react-textarea-autosize'
import {
  Divider,
  Form,
  FormInputProps,
  Grid,
  InputProps,
} from 'semantic-ui-react'
import styled from 'styled-components'

import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import { UnderlineSubtitle } from 'components/shared/utils'
import useProject from 'context/project/use'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import SelectTextCard from 'design-system/components/cards/selection/select-text-card'
import { Drawer } from 'design-system/components/drawer'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import {
  Issue,
  IssueSubtype,
  IssueType,
  IssueTypeSubtype,
  ResolutionType,
} from 'types/issue'
import { ProjectFile, ProjectFileType } from 'types/project-file'
import { SupplierOrder } from 'types/supplier-order'
import { responsiveText } from 'views/utils'

const CreateUpdateIssueForm = ({
  damagePhotos,
  grayInputs,
  issue,
  onChange,
  refetch,
}: {
  damagePhotos: ProjectFile[]
  grayInputs?: boolean
  issue?: Issue
  onChange: React.Dispatch<React.SetStateAction<NullableRecord<Issue>>>
  refetch?: () => Promise<unknown>
}) => {
  const { project, deliveries, supplierOrders: projectSO } = useProject()
  const [subtypeOptions, setSubtypeOptions] = useState<IssueSubtype[]>([])
  const [supplierOrders, setSupplierOrders] = useState<SupplierOrder[]>(
    issue?.delivery?.supplierOrders || projectSO || [],
  )

  const orderNumbers = (
    issue?.delivery ? issue.delivery.supplierOrders : supplierOrders
  ).map((so) => so.nobiliaAckNumber || 'Missing ACK Number')

  const onProjectFilesUploaded = async (f: Partial<ProjectFile>[]) => {
    onChange((prev) => ({
      ...prev,
      files: [...(f as ProjectFile[]), ...(prev.files ?? [])],
    }))
    await refetch?.()
  }

  useEffect(() => {
    setSubtypeOptions(issue?.type ? IssueTypeSubtype[issue.type] : [])
  }, [issue])

  useEffect(() => {
    if (issue?.deliveryId) {
      const delivery = deliveries.find((d) => d.id === issue?.deliveryId)
      setSupplierOrders(delivery?.supplierOrders ?? [])
    } else setSupplierOrders(projectSO || [])
  }, [issue?.deliveryId])

  const onChangeInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { name, value }: { name: string; value: string },
  ) =>
    onChange({
      ...issue,
      data: {
        ...issue?.data,
        [name]: value,
      },
    })

  const onChangeTextArea = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...issue,
      data: {
        ...issue?.data,
        [e.target.name]: e.target.value,
      },
    })

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <StyledForm styled={{ grayInputs: grayInputs }}>
            <UnderlineSubtitle>Related Delivery</UnderlineSubtitle>
            {!issue?.delivery?.id ? (
              <Form.Dropdown
                clearable
                label="Delivery"
                options={deliveries?.map((d) => ({
                  key: d.id,
                  text: d.data?.name,
                  value: d.id,
                }))}
                onChange={(_, { value }) =>
                  onChange({ ...issue, deliveryId: value as string })
                }
                placeholder="Choose a delivery"
                search
                selection
                value={issue?.deliveryId}
                width={4}
              />
            ) : (
              <Form.Field>
                <label>Delivery</label>
                <p>{issue?.delivery?.data?.name}</p>
              </Form.Field>
            )}
            <Divider hidden />
            <UnderlineSubtitle>Issue Details</UnderlineSubtitle>
            <StyledFormField
              control={TextareaAutosize}
              label="Client's Description"
              name="customerDescription"
              onChange={onChangeTextArea}
              value={issue?.data?.customerDescription}
            />
            <StyledFormField
              control={TextareaAutosize}
              label="Internal description (Include ACKs and POS(s) affected)"
              name="internalDescription"
              onChange={onChangeTextArea}
              value={issue?.data?.internalDescription}
            />
            <Form.Field
              style={{ border: '1px solid #E7E5E4', borderRadius: '2px' }}
            >
              <Drawer
                content={
                  <div style={{ margin: '0 12px 12px' }}>
                    {supplierOrders.map((so, idx) => (
                      <p key={so.id} className="light-gray no-margin">
                        {idx + 1}. {so.nobiliaOrderRef} ACK:{' '}
                        {so.nobiliaAckNumber ?? 'No Ack Number'}
                      </p>
                    ))}
                  </div>
                }
                defaultOpen
                title="ACK Cheatsheet"
              />
            </Form.Field>
            <Divider hidden />
            <UnderlineSubtitle>Choose an issue type</UnderlineSubtitle>
            <Form.Group style={{ flexWrap: 'wrap', gap: 0 }} widths={4}>
              {Object.values(IssueType).map((s, idx) => (
                <Form.Field key={idx}>
                  <SelectTextCard
                    text={s}
                    onClick={() => {
                      onChange((prev) => {
                        if (prev.type === s) return prev

                        return {
                          ...issue,
                          type: s,
                          data: {
                            ...issue?.data,
                            subtype: [],
                            subtypeOther: null,
                            typeOther: null,
                          },
                        }
                      })

                      setSubtypeOptions(s ? IssueTypeSubtype[s] : [])
                    }}
                    selected={issue?.type === s}
                  />
                </Form.Field>
              ))}
              {issue?.type === 'Other' && (
                <Form.Input
                  label="Other"
                  name="typeOther"
                  onChange={onChangeInput as InputProps['onChange']}
                  value={issue?.data?.typeOther}
                />
              )}
            </Form.Group>
            <Divider hidden />
            <UnderlineSubtitle>Choose a sub-issue type</UnderlineSubtitle>
            {!subtypeOptions ? (
              !issue?.type ? (
                <p className="red">
                  Choose an issue type to display its sub-issues
                </p>
              ) : (
                <p className="gray">
                  There's no sub-issues to display for “Other”
                </p>
              )
            ) : (
              <Form.Field width={8}>
                <StyledForm styled={{ grayInputs: grayInputs }}>
                  <Form.Group
                    style={{
                      flexWrap: 'wrap',
                      gap: '16px 0',
                      margin: '22px 0',
                    }}
                    widths={2}
                  >
                    {subtypeOptions?.map((value) => (
                      <Form.Field
                        key={value}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                        }}
                      >
                        <FormCheckbox
                          checked={issue?.data?.subtype?.includes(
                            value as IssueSubtype,
                          )}
                          label={value}
                          onChange={() =>
                            onChange((prev) => {
                              const subtype = prev.data?.subtype || []

                              return {
                                ...issue,
                                data: {
                                  ...issue?.data,
                                  subtype: subtype?.includes(
                                    value as IssueSubtype,
                                  )
                                    ? subtype?.filter((s) => s !== value)
                                    : ([...subtype, value] as IssueSubtype[]),
                                },
                              }
                            })
                          }
                        />
                        {value === 'Other' && (
                          <Form.Input
                            disabled={
                              !issue?.data?.subtype?.includes(
                                'Other' as IssueSubtype,
                              )
                            }
                            name="subtypeOther"
                            onChange={onChangeInput as InputProps['onChange']}
                            label="Other"
                            value={issue?.data?.subtypeOther}
                          />
                        )}
                      </Form.Field>
                    ))}
                  </Form.Group>
                </StyledForm>
              </Form.Field>
            )}
            <Divider hidden />
            <UnderlineSubtitle>Resolution type</UnderlineSubtitle>
            <Form.Group style={{ flexWrap: 'wrap', gap: 0 }} widths={4}>
              {Object.values(ResolutionType).map((type: string, idx) => (
                <Form.Field key={idx}>
                  <SelectTextCard
                    text={type}
                    onClick={() =>
                      onChange({
                        ...issue,
                        resolutionType: type as ResolutionType,
                        data: {
                          ...issue?.data,
                          isAddOnComplementary: false,
                        },
                      })
                    }
                    selected={issue?.resolutionType === type}
                  />
                </Form.Field>
              ))}
              {issue?.resolutionType === ResolutionType.ADD_ON && (
                <FormCheckbox
                  checked={issue?.data?.isAddOnComplementary}
                  label="Make it complementary"
                  onChange={() =>
                    onChange({
                      ...issue,
                      data: {
                        ...issue?.data,
                        isAddOnComplementary:
                          !issue?.data?.isAddOnComplementary,
                      },
                    })
                  }
                />
              )}
            </Form.Group>
            {issue?.resolutionType === ResolutionType.REMEDIAL && (
              <Form.Group widths={4}>
                <Form.Dropdown
                  label="ACK Number"
                  onChange={(_, { value }) =>
                    onChange({
                      ...issue,
                      data: {
                        ...issue?.data,
                        orderNumber: value as string,
                      },
                    })
                  }
                  options={orderNumbers?.map((ack, idx) => ({
                    id: idx,
                    text: ack,
                    value: ack,
                  }))}
                  selection
                  value={issue?.data?.orderNumber ?? undefined}
                />
                <Form.Input
                  id="positionNumber"
                  label="Position Number (POS)"
                  name="positionNumber"
                  onChange={onChangeInput as FormInputProps['onChange']}
                  value={issue?.data?.positionNumber}
                />
              </Form.Group>
            )}
          </StyledForm>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <UnderlineSubtitle>Related Images</UnderlineSubtitle>
      <ProjectFileUploads
        deliveryId={issue?.delivery?.id || issue?.deliveryId}
        projectFiles={[]}
        projectFileType={ProjectFileType.DAMAGE_PHOTO}
        projectId={project.id}
        onProjectFilesUploaded={onProjectFilesUploaded}
      />
      <Grid.Row columns={4} className="row-gap">
        {damagePhotos.map((file) => (
          <SelectImageColumn key={file.id}>
            <SelectImageCard
              key={file.id}
              onClick={() =>
                onChange((prev) => {
                  return {
                    ...issue,
                    files: prev?.files?.find((f) => f.id === file.id)
                      ? prev.files.filter((f) => f.id !== file.id)
                      : [file, ...(prev.files ?? [])],
                  }
                })
              }
              image={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                file.key,
              )}`}
              selected={issue?.files?.some((f) => f.id === file.id)}
              text={responsiveText(file.name, 20)}
            />
          </SelectImageColumn>
        ))}
      </Grid.Row>
    </Grid>
  )
}

export default CreateUpdateIssueForm

const SelectImageColumn = styled(Grid.Column)`
  .select-image-card img.main-image {
    max-height: 160px;
    object-fit: cover;
    height: 160px;
  }
`

const StyledFormField = styled(Form.Field)`
  textarea {
    min-height: 50px !important;
  }
`
