import moment from 'moment'

import useProject from 'context/project/use'
import { parseFiles } from 'context/project-file/utils-many'
import { OverviewAlert, OverviewChecklist, StatusMode } from 'types/utils'

import * as utils from './utils'

const useConstructDesignCall = (): {
  checklists: OverviewChecklist[]
  alerts: OverviewAlert[]
} => {
  const {
    isTradeProject,
    meetingDesignOccurred,
    meetingDesignScheduled,
    orderSamplesCreated,
    orderSamplesPlaced,
    paymentDesignDepositPaid,
    paymentProjectDepositPaid,
    primaryProspect,
    project,
    prospectMeetingsAdvisory,
    prospectMeetingsFreeConsultation,
    prospectMeetingsVisitNY,
    prospectMeetingsVisitSF,
    roomPrimary: room,
  } = useProject()
  const { floorPlans, roomPhotos, inspirationImages } = parseFiles(room?.files)

  const {
    appliancesAreAcknowledged,
    appliancesUnarchived,
    appliancesConfirmed,
    appliancesDirty,
  } = utils.parseAppliances(room)

  const appliancesMode = utils.parseAppliancesMode({
    isTradeProject,
    room,
    appliancesAreAcknowledged,
    paymentProjectDepositPaid: !!paymentProjectDepositPaid,
  })

  const appliancesModeText = utils.generateAppliancesModeText({
    room,
    appliancesMode,
    appliancesConfirmed,
    appliancesDirty,
    appliancesUnarchived,
  })

  return {
    checklists: [
      {
        checklistItems: [
          {
            key: 'prospectCall',
            url: `/admin/prospects/${primaryProspect?.id}/overview`,
            name: 'Prospect Call',
            statusMode: (() => {
              if (
                prospectMeetingsAdvisory?.length ||
                prospectMeetingsFreeConsultation?.length ||
                prospectMeetingsVisitSF?.length ||
                prospectMeetingsVisitNY?.length
              )
                return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              const totalProspectMeetings = [
                ...(prospectMeetingsAdvisory || []),
                ...(prospectMeetingsVisitSF || []),
                ...(prospectMeetingsVisitNY || []),
                ...(prospectMeetingsFreeConsultation || []),
              ].length
              const moreProspectMeetingsText =
                totalProspectMeetings > 1
                  ? ` ( and ${totalProspectMeetings - 1} more)`
                  : ''
              if (prospectMeetingsAdvisory?.length)
                return `Advisory Call ${moment(
                  prospectMeetingsAdvisory[0].startTime,
                ).format('LL')}${moreProspectMeetingsText}`
              if (prospectMeetingsVisitSF?.length)
                return `SF Studio Visit ${moment(
                  prospectMeetingsVisitSF[0].startTime,
                ).format('LL')}${moreProspectMeetingsText}`
              if (prospectMeetingsVisitNY?.length)
                return `NY Studio Visit ${moment(
                  prospectMeetingsVisitNY[0].startTime,
                ).format('LL')}${moreProspectMeetingsText}`
              if (prospectMeetingsFreeConsultation?.length)
                return `Free Consultation ${moment(
                  prospectMeetingsFreeConsultation[0].startTime,
                ).format('LL')}${moreProspectMeetingsText}`
              return 'None'
            })(),
          },
          {
            key: 'projectAddress',
            url: `/admin/projects/${project.id}/addresses`,
            name: 'Address',
            statusMode: (() => {
              if (project.projectAddress) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (project.projectAddress) return 'Completed'
              return 'Pending'
            })(),
          },
          {
            key: 'floorPlans',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/plans`,
            name: 'Floor Plans',
            statusMode: (() => {
              if (room?.data?.floorPlansCompletedAt) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (floorPlans.length) return `${floorPlans.length} Uploaded`
              return 'Pending'
            })(),
          },
          {
            key: 'roomPhotos',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/photos`,
            name: 'Room Photos',
            statusMode: (() => {
              if (room?.data?.roomPhotosCompletedAt) {
                return StatusMode.Confirmed
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (roomPhotos.length) return `${roomPhotos.length} Uploaded`
              if (room?.data?.roomPhotosCompletedAt) return 'Skipped'
              return 'Pending'
            })(),
          },
          {
            key: 'inspirationImages',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/inspiration-images`,
            name: 'Inspiration Images',
            statusMode: (() => {
              if (room?.data?.inspirationImagesCompletedAt) {
                return StatusMode.Confirmed
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (inspirationImages.length)
                return `${inspirationImages.length} Uploaded`
              if (room?.data?.inspirationImagesCompletedAt) return 'Skipped'
              return 'Pending'
            })(),
          },
          {
            key: 'appliances',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/appliances`,
            name: 'Appliances',
            statusMode: appliancesMode,
            statusText: appliancesModeText.text,
          },
          {
            key: 'designCall',
            url: `/admin/projects/${project.id}/meetings`,
            name: 'Initial Call',
            statusMode: (() => {
              if (meetingDesignOccurred) {
                return StatusMode.Confirmed
              }
              if (meetingDesignScheduled) {
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (meetingDesignOccurred) {
                return `Completed ${moment(
                  meetingDesignOccurred.startTime,
                ).format('LL')}`
              }
              if (meetingDesignScheduled) {
                return `Scheduled ${moment(
                  meetingDesignScheduled.startTime,
                ).format('LL')}`
              }
              return 'Pending'
            })(),
          },
          {
            key: 'designBrief',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/design-brief`,
            name: 'Design Brief',
            statusMode: room?.data?.designBriefApprovedAt
              ? StatusMode.Confirmed
              : StatusMode.OpenForEdit,
            statusText: room?.data?.designBriefApprovedAt
              ? `Approved ${moment(room?.data.designBriefApprovedAt).format(
                  'LL',
                )}`
              : 'Pending',
          },
          {
            key: 'designDeposit',
            url: `/admin/projects/${project.id}/payments`,
            name: 'Design Deposit Paid',
            statusMode: paymentDesignDepositPaid
              ? StatusMode.Confirmed
              : StatusMode.OpenForEdit,
            statusText: paymentDesignDepositPaid ? 'Paid' : 'Pending',
          },
          {
            key: 'samplesOrder',
            url: `/admin/projects/${project.id}/sample-orders`,
            name: 'Samples Order',
            statusMode: (() => {
              if (orderSamplesPlaced) {
                return StatusMode.Confirmed
              }
              if (orderSamplesCreated) {
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (orderSamplesPlaced) {
                return `Placed ${moment(orderSamplesPlaced.placedAt).format(
                  'LL',
                )}`
              }
              if (orderSamplesCreated) {
                return `Order Created`
              }
              return 'Pending'
            })(),
          },
        ],
      },
    ],
    alerts: [],
  }
}

export default useConstructDesignCall
