import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_SUPPLIER_ORDER_ACTION,
  CreateSupplierOrderActionPayload,
  CreateSupplierOrderActionVariables,
  DELETE_SUPPLIER_ORDER_ACTION,
  DeleteSupplierOrderActionPayload,
  DeleteSupplierOrderActionVariables,
  UPDATE_SUPPLIER_ORDER_ACTION,
  UpdateSupplierOrderActionPayload,
  UpdateSupplierOrderActionVariables,
} from 'queries/supplier-order-action'

import { SupplierOrderContext } from './provider'

const useSupplierOrderActionMutate = () => {
  const { refetch } = useContext(SupplierOrderContext)

  const [createSupplierOrderActionAndRefetch, { loading: loadingCreate }] =
    useMutation<
      CreateSupplierOrderActionPayload,
      CreateSupplierOrderActionVariables
    >(CREATE_SUPPLIER_ORDER_ACTION, {
      onCompleted: refetch,
    })

  const [deleteSupplierOrderActionAndRefetch, { loading: loadingDelete }] =
    useMutation<
      DeleteSupplierOrderActionPayload,
      DeleteSupplierOrderActionVariables
    >(DELETE_SUPPLIER_ORDER_ACTION, {
      onCompleted: refetch,
    })

  const [updateSupplierOrderActionAndRefetch, { loading: loadingUpdate }] =
    useMutation<
      UpdateSupplierOrderActionPayload,
      UpdateSupplierOrderActionVariables
    >(UPDATE_SUPPLIER_ORDER_ACTION, {
      onCompleted: refetch,
    })

  return {
    createSupplierOrderActionAndRefetch,
    deleteSupplierOrderActionAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateSupplierOrderActionAndRefetch,
  }
}

export default useSupplierOrderActionMutate
