import { gql } from '@apollo/client'

import { Shipment, ShipmentData } from 'types/shipment'

export const CREATE_SHIPMENT = gql`
  mutation createShipment($data: ShipmentCreateInput!) {
    createOneShipment(data: $data) {
      id
    }
  }
`

export interface CreateShipmentPayload {
  createOneShipment: Shipment
}

export interface CreateShipmentVariables {
  data: {
    color?: string | null
    confirmedAt?: Date | null
    data: ShipmentData
    reference?: string | null
    supplierOrders?: {
      connect: { id: string }[]
    } | null
  }
}

export const DELETE_SHIPMENT = gql`
  mutation deleteShipment($where: ShipmentWhereUniqueInput!) {
    deleteOneShipment(where: $where) {
      id
    }
  }
`

export interface DeleteShipmentPayload {
  deleteOneShipment: Shipment
}

export interface DeleteShipmentVariables {
  where: { id: string }
}

export const FIND_UNIQUE_SHIPMENT = gql`
  query findUniqueShipment($where: ShipmentWhereUniqueInput!) {
    shipment: findUniqueShipment(where: $where) {
      id
      archivedAt
      color
      confirmedAt
      data
      reference
      supplierOrders {
        id
        actions {
          id
          data
        }
        data
        delivery {
          id
          data
          date
        }
        deliveryNote {
          id
          key
          name
          numPages
          type
        }
        design {
          id
          order {
            id
            targetDate
          }
          room {
            id
            data
            type
          }
        }
        nobiliaAckNumber
        nobiliaOrderRef
        project {
          id
          data
          meetings {
            id
            canceledAt
            metadata
            startTime
          }
          owners {
            isSubscribed
            isPrimary
            user {
              id
              email
              firstName
              lastName
              type
            }
            userRole
          }
          projectAddress
        }
        route {
          id
          archivedAt
          color
          confirmedAt
          data
          reference
        }
        shipment {
          id
          archivedAt
          color
          confirmedAt
          data
          reference
        }
      }
    }
  }
`

export interface FindUniqueShipmentPayload {
  shipment: Shipment
}

export interface FindUniqueShipmentVariables {
  where: {
    id?: string
  }
}

export const FIND_MANY_SHIPMENT = gql`
  query findManyShipment(
    $take: Int
    $orderBy: [ShipmentOrderByWithRelationInput!]
    $skip: Int
    $where: ShipmentWhereInput
  ) {
    count: findManyShipmentCount(where: $where)
    shipments: findManyShipment(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      archivedAt
      color
      confirmedAt
      data
      reference
      supplierOrders {
        id
        actions {
          id
          data
        }
        data
        delivery {
          id
          data
          date
        }
        design {
          id
          name
          room {
            id
            data
            type
          }
        }
        nobiliaAckNumber
        nobiliaOrderRef
        project {
          id
          data
          owners {
            isSubscribed
            isPrimary
            user {
              id
              email
              firstName
              lastName
              picture
              type
            }
            userRole
          }
          projectAddress
        }
        route {
          id
          archivedAt
          color
          confirmedAt
          data
          reference
        }
        shipment {
          id
          archivedAt
          color
          confirmedAt
          reference
        }
      }
    }
  }
`

export const FIND_MANY_SHIPMENT_SHALLOW = gql`
  query findManyShipment(
    $take: Int
    $orderBy: [ShipmentOrderByWithRelationInput!]
    $skip: Int
    $where: ShipmentWhereInput
  ) {
    shipments: findManyShipment(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      derivedStatus
      reference
    }
  }
`

export const FIND_MANY_SHIPMENT_COUNT = gql`
  query findManyShipmentCount($where: ShipmentWhereInput) {
    count: findManyShipmentCount(where: $where)
  }
`

export interface FindManyShipmentCountPayload {
  count: number
}

export interface FindManyShipmentPayload {
  shipments: Shipment[]
  count?: number
}

export interface FindManyShipmentVariables {
  orderBy?: {
    derivedStatus?: string
    reference?: string
  }
  skip?: number
  take?: number
  where?: {
    archivedAt?: { not?: string | null } | null
    confirmedAt?: { not?: string | null } | null
    data?: {
      path?: string[]
      equals?: string
    }
    derivedSearch?: {
      contains: string
      mode?: 'insensitive'
    }
    derivedStatus?: {
      gte?: string
      lte?: string
      gt?: string
      lt?: string
      startsWith?: string
    }
    OR?: {
      derivedStatus?: {
        startsWith?: string
      }
    }[]
  }
}

export const UPDATE_SHIPMENT = gql`
  mutation updateShipment(
    $data: ShipmentUpdateInput!
    $where: ShipmentWhereUniqueInput!
  ) {
    updateOneShipment(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateShipmentPayload {
  updateOneShipment: { id: string }
}

export interface UpdateShipmentVariables {
  where: { id: string }
  data: {
    archivedAt?: Date | null
    color?: string | null
    confirmedAt?: Date | null
    orderLogsAssignedAt?: string | Date | null
    data?: ShipmentData | null
    reference?: string | null
    supplierOrders?: {
      disconnect?: { id: string }[]
      connect?: { id: string }[]
    } | null
  }
}
