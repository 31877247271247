import React from 'react'

import { RouteComponentProps } from '@reach/router'

import AppliancePackManyProvider from 'context/appliance-pack/provider-many'

interface AppliancePackIndexManyProps
  extends RouteComponentProps<{ catalog_id: string }> {
  children: React.ReactNode
}

const AppliancePacksIndexMany = (props: AppliancePackIndexManyProps) => {
  return (
    <AppliancePackManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        sortBy: 'identifier',
        sortDirection: 'ascending',
        take: undefined,
      }}
      skipLoader
    >
      {props.children}
    </AppliancePackManyProvider>
  )
}

export default AppliancePacksIndexMany
