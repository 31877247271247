import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_ONE_PROJECT_STAFF,
  CreateOneProjectStaffPayload,
  CreateOneProjectStaffVariables,
  DELETE_ONE_PROJECT_STAFF,
  DeleteOneProjectStaffPayload,
  DeleteOneProjectStaffVariables,
  UPDATE_ONE_PROJECT_STAFF,
  UpdateOneProjectStaffPayload,
  UpdateOneProjectStaffVariables,
} from 'queries/project-staff'

import { ProjectContext } from './provider'

const useProjectStaffMutate = () => {
  const { refetch } = useContext(ProjectContext)

  const [createProjectStaffAndRefetch, { loading: loadingCreate }] =
    useMutation<CreateOneProjectStaffPayload, CreateOneProjectStaffVariables>(
      CREATE_ONE_PROJECT_STAFF,
      {
        onCompleted: () => {
          refetch()
        },
      },
    )

  const [deleteProjectStaffAndRefetch, { loading: loadingDelete }] =
    useMutation<DeleteOneProjectStaffPayload, DeleteOneProjectStaffVariables>(
      DELETE_ONE_PROJECT_STAFF,
      {
        onCompleted: () => {
          refetch()
        },
      },
    )

  const [updateProjectStaffAndRefetch, { loading: loadingUpdate }] =
    useMutation<UpdateOneProjectStaffPayload, UpdateOneProjectStaffVariables>(
      UPDATE_ONE_PROJECT_STAFF,
      {
        onCompleted: () => {
          refetch()
        },
      },
    )

  return {
    createProjectStaffAndRefetch,
    deleteProjectStaffAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateProjectStaffAndRefetch,
  }
}

export default useProjectStaffMutate
