import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_ONE_PROJECT_PROSPECT,
  CreateOneProjectProspectPayload,
  CreateOneProjectProspectVariables,
  DELETE_ONE_PROJECT_PROSPECT,
  DeleteOneProjectProspectPayload,
  DeleteOneProjectProspectVariables,
  UPDATE_ONE_PROJECT_PROSPECT,
  UpdateOneProjectProspectPayload,
  UpdateOneProjectProspectVariables,
} from 'queries/project-prospect'

import { ProjectContext } from './provider'

const useProjectProspectMutate = () => {
  const { refetch } = useContext(ProjectContext)

  const [createProjectProspectAndRefetch, { loading: loadingCreate }] =
    useMutation<
      CreateOneProjectProspectPayload,
      CreateOneProjectProspectVariables
    >(CREATE_ONE_PROJECT_PROSPECT, {
      onCompleted: () => {
        refetch()
      },
    })

  const [deleteProjectProspectAndRefetch, { loading: loadingDelete }] =
    useMutation<
      DeleteOneProjectProspectPayload,
      DeleteOneProjectProspectVariables
    >(DELETE_ONE_PROJECT_PROSPECT, {
      onCompleted: () => {
        refetch()
      },
    })

  const [updateProjectProspectAndRefetch, { loading: loadingUpdate }] =
    useMutation<
      UpdateOneProjectProspectPayload,
      UpdateOneProjectProspectVariables
    >(UPDATE_ONE_PROJECT_PROSPECT, {
      onCompleted: () => {
        refetch()
      },
    })

  return {
    createProjectProspectAndRefetch,
    deleteProjectProspectAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateProjectProspectAndRefetch,
  }
}

export default useProjectProspectMutate
