import React from 'react'

import { Form, Grid } from 'semantic-ui-react'

import SelectFileCard from 'components/admin/project-file/select-file-card'
import { UnderlineSubtitle } from 'components/shared/utils'
import { parseFiles } from 'context/project-file/utils-many'
import Button from 'design-system/components/button'
import { StyledForm } from 'styles/admin/main'
import { RoomGraphQL } from 'types/room'

import { SupplierOrderObject } from './send-order-modal'

interface SupplierOrderFormProps {
  applianceSpecSelected: string[]
  index: number
  onChange: (i: number, n: string, v: string | string[]) => void
  onRemove?: (i: number) => void
  room: RoomGraphQL
  supplierOrder: SupplierOrderObject
  supplierOrderFileSelected: string[]
}

const SupplierOrderForm = ({
  applianceSpecSelected,
  index,
  onChange,
  onRemove,
  room,
  supplierOrder,
  supplierOrderFileSelected,
}: SupplierOrderFormProps) => {
  const files = parseFiles(room?.files || [])

  const applianceSpecs = files.applianceSpecs.filter((f) => !f.supplierOrder)
  const supplierOrderFiles = files.supplierOrderFiles.filter(
    (f) => !f.supplierOrder,
  )

  return (
    <>
      <Grid.Row columns="equal">
        <Grid.Column>
          <UnderlineSubtitle>Supplier Order #{index + 1}</UnderlineSubtitle>
        </Grid.Column>
        {index > 0 && (
          <Grid.Column floated="right" width={1}>
            <Button
              kind="solid"
              color="blue"
              fontAwesomeIcon="trash-alt"
              onClick={() => onRemove?.(index)}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <StyledForm styled={{ grayInputs: true }}>
            <Form.Group widths="equal">
              <Form.Input
                label="Nobilia Order Reference"
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(index, 'nobiliaOrderRef', value)
                }
                required
                value={supplierOrder.nobiliaOrderRef || ''}
              />
              <Form.TextArea
                label="Notes For Nobilia"
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onChange(index, 'notesForNobilia', value)
                }
                placeholder="Write notes about related ACKs, EDI, etc here..."
                value={supplierOrder.notesForNobilia || ''}
              />
            </Form.Group>
          </StyledForm>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <UnderlineSubtitle>Supplier Order Files</UnderlineSubtitle>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <p className="caption">Choose supplier order files</p>
          {!supplierOrderFiles.length && (
            <p className="gray">
              Upload the Supplier Order files in the Production Files section of
              the room.
            </p>
          )}
          {supplierOrderFiles.map((f) => (
            <SelectFileCard
              key={f.id}
              disabled={
                !supplierOrder.supplierOrderFiles?.includes(f.id) &&
                supplierOrderFileSelected.includes(f.id)
              }
              file={f}
              onSelect={(id: string) =>
                onChange(
                  index,
                  'supplierOrderFiles',
                  supplierOrder.supplierOrderFiles?.find((i) => i === id)
                    ? supplierOrder.supplierOrderFiles.filter((i) => i !== id)
                    : [...(supplierOrder.supplierOrderFiles ?? []), id],
                )
              }
              selected={supplierOrder.supplierOrderFiles?.includes(f.id)}
            />
          ))}
        </Grid.Column>
        <Grid.Column>
          <p className="caption">Choose an appliance spec</p>
          {room?.data?.appliancesSpecsNotIncluded ? (
            <p>This room has no appliances</p>
          ) : (
            !applianceSpecs.length && (
              <p className="gray">
                Upload the Appliance Specs in the Production Files section of
                the room.
              </p>
            )
          )}
          {applianceSpecs.map((f) => (
            <SelectFileCard
              key={f.id}
              disabled={
                !supplierOrder.applianceSpecs?.includes(f.id) &&
                applianceSpecSelected.includes(f.id)
              }
              file={f}
              onSelect={(id: string) =>
                onChange(
                  index,
                  'applianceSpecs',
                  supplierOrder.applianceSpecs?.find((i) => i === id)
                    ? supplierOrder.applianceSpecs.filter((i) => i !== id)
                    : [...(supplierOrder.applianceSpecs ?? []), id],
                )
              }
              selected={supplierOrder.applianceSpecs?.includes(f.id)}
            />
          ))}
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default SupplierOrderForm
