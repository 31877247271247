import { useMemo } from 'react'

import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { Editor, Extensions } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { createMarkdownEditor } from 'tiptap-markdown'

import { Link } from 'design-system/components/rich-text/plugins'
import { MentionPlugin } from 'design-system/components/rich-text/plugins'
import DeepLinksPlugin from 'design-system/components/rich-text/plugins/deep-links'

const urlRegex = new RegExp(
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
)

const useMdEditor = () => {
  const extensions = useMemo<Extensions>(() => {
    return [
      StarterKit.configure({
        heading: false,
        blockquote: false,
        code: false,
        codeBlock: false,
        horizontalRule: false,
        dropcursor: false,
      }),
      Underline,
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
      }),
      Link.configure({
        validate: (href) => urlRegex.test(href),
        openOnClick: false,
      }),
      MentionPlugin,
      DeepLinksPlugin,
    ]
  }, [])

  const MdEditor = createMarkdownEditor(Editor)
  const editor = new MdEditor({
    extensions,
  })

  return editor
}

export default useMdEditor
