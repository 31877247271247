import styled from 'styled-components'

import { Colors } from 'styles/app/system'

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  div.selection {
    align-items: center;
    background: ${Colors.white};
    border-radius: 2px;
    color: ${Colors.gray800};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    gap: 10px;
    padding: 8px 12px;

    p {
      font-size: 14px;
      margin: 0;
    }

    svg {
      cursor: pointer;
    }
  }
`
