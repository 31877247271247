import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'semantic-ui-react'
import styled from 'styled-components'

import { ProductBase } from 'planner/objects/product'
import { DeleteButton, StyledInput } from 'styles/planner/main'
import { Colors } from 'styles/planner/system'
import { NobiliaProductClass } from 'types/nobilia-product-class'

const AdditionalItemCard = ({
  additionalItemProductClass,
  deleteButton,
  maxQuantity,
  minValue = 0,
  onAdditionalItemChange,
  quantity,
}: {
  additionalItemProductClass: NobiliaProductClass
  deleteButton?: boolean
  maxQuantity: number
  minValue?: number
  onAdditionalItemChange: (ai: Partial<ProductBase>) => void
  quantity: number
}) => {
  return (
    <StyledCard fluid>
      <Card.Content>
        <p>{additionalItemProductClass.orderId}</p>
        <p className="gray">{additionalItemProductClass.shortText}</p>
        <div className="quantity-input">
          <StyledInput
            max={maxQuantity}
            min={minValue}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              const quantity = parseFloat(value)
              if (quantity >= minValue || quantity <= maxQuantity)
                onAdditionalItemChange({
                  productClass: additionalItemProductClass,
                  quantity: quantity,
                })
            }}
            style={{ maxWidth: '70px' }}
            type="number"
            value={quantity}
          >
            <input />
            <div className="arrows">
              <div
                className={`arrow ${quantity === maxQuantity && 'disabled'}`}
                onClick={() =>
                  onAdditionalItemChange({
                    productClass: additionalItemProductClass,
                    quantity: quantity + 1,
                  })
                }
              >
                <FontAwesomeIcon icon={['fal', 'chevron-up']} />
              </div>
              <div
                className={`arrow ${quantity === minValue && 'disabled'}`}
                onClick={() =>
                  onAdditionalItemChange({
                    productClass: additionalItemProductClass,
                    quantity: quantity - 1,
                  })
                }
              >
                <FontAwesomeIcon icon={['fal', 'chevron-down']} />
              </div>
            </div>
          </StyledInput>
          <p className="gray">(Max {maxQuantity})</p>
        </div>
      </Card.Content>
      {deleteButton ? (
        <DeleteButton
          className="orange-hover small"
          icon={<FontAwesomeIcon icon={['fal', 'times']} />}
          onClick={() =>
            onAdditionalItemChange({
              productClass: additionalItemProductClass,
              quantity: 0,
            })
          }
        />
      ) : null}
      <ImageCard image="https://via.placeholder.com/140" />
    </StyledCard>
  )
}

export default AdditionalItemCard

const StyledCard = styled(Card)`
  &&&& {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    border: 1px solid ${Colors.gray200};
    border-radius: 2px;
    box-shadow: none;

    img {
      border-radius: 0;
    }

    .content {
      padding: 14px 14px 50px !important;
      width: 65%;
    }

    .quantity-input {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 16px;
    }
  }
`

const ImageCard = styled.div<{ image: string }>`
  &&&& {
    background: url('${(props) => props.image}') no-repeat center;
    border-radius: 0 !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    margin: 0;
    width: 35% !important;
  }
`
