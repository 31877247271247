import React from 'react'

import moment from 'moment'
import { Divider, Segment } from 'semantic-ui-react'

import useDesign from 'context/design/use'
import useProjectFile from 'context/project-file/use'
import useProjectFileMutate from 'context/project-file/use-mutate'
import * as StyleSystem from 'styles/app/system'
import { StatusMode } from 'types/utils'

import FeedbackModal from './modal-feedback-internal'
import SignOffModal from './modal-sign-off-internal'

const SubmissionInternal = ({ isReviewer }: { isReviewer: boolean }) => {
  const { internalReviewMode, projectFile } = useProjectFile()
  const { loadingUpdate, updateProjectFileAndRefetch } = useProjectFileMutate()
  const { comments, internalReviewRequestedCount } = useDesign()
  return (
    <div>
      {(() => {
        if (internalReviewMode === StatusMode.Confirmed) {
          return (
            <Segment
              basic
              textAlign="left"
              style={{ margin: '0', paddingLeft: '0' }}
            >
              <h4 style={{ marginBottom: '5px' }}>{`Signed off on ${moment(
                projectFile.metadata.approvedInternallyAt,
              ).format('MM/DD/YY')}`}</h4>
              <p style={{ textAlign: 'left' }}>
                This design has been approved by ops.
              </p>
            </Segment>
          )
        }
        if (internalReviewMode === StatusMode.Submitted) {
          return (
            <Segment
              basic
              style={{
                margin: '0',
                paddingBottom: '25px',
                paddingLeft: '0',
              }}
              textAlign="center"
            >
              <p
                className="caption"
                style={{
                  color: StyleSystem.Colors.red700,
                  textAlign: 'left',
                }}
              >
                {`Feedback submitted on ${moment(
                  projectFile.metadata?.commentedInternallyAt,
                ).format('MM/DD/YY')}`}
              </p>
              <p style={{ textAlign: 'left' }}>
                {isReviewer
                  ? 'Awaiting Sales Designer to upload a new plan with revisions. Please resubmit feedback if you have resolved more comments.'
                  : 'Please upload new plan with revisions.'}
              </p>
            </Segment>
          )
        }
        return (
          <Segment
            basic
            style={{
              margin: '0',
              paddingBottom: '15px',
              paddingLeft: '0',
              paddingTop: '0',
            }}
            textAlign="center"
          >
            <p
              style={{
                color: StyleSystem.Colors.red700,
                textAlign: 'left',
              }}
            >
              {`Awaiting review since ${moment(
                projectFile.metadata?.internalReviewRequestedAt,
              ).format('MM/DD/YY')}`}
            </p>
            <p style={{ textAlign: 'left' }}>
              {isReviewer
                ? 'Please review this design and submit any feedback.'
                : 'This design is ready and I take full responsibility of any un-resolved comments.'}
            </p>
          </Segment>
        )
      })()}
      {isReviewer ? (
        <FeedbackModal
          disabled={
            internalReviewMode === StatusMode.Submitted ||
            internalReviewMode === StatusMode.Confirmed
          }
          isReadyToSignOff={
            internalReviewRequestedCount > 1 &&
            !!comments?.every((c) => c.data?.doneBy)
          }
          loading={loadingUpdate}
          onSave={() =>
            updateProjectFileAndRefetch({
              variables: {
                data: {
                  metadata: {
                    ...projectFile.metadata,
                    commentedInternallyAt: new Date(),
                  },
                },
                where: { id: projectFile.id },
              },
            })
          }
        />
      ) : (
        <SignOffModal
          disabled={
            internalReviewMode === StatusMode.Confirmed ||
            internalReviewMode !== StatusMode.Submitted ||
            !comments?.every(
              (c) => c.data?.doneBy || c.data?.internalSpecialExceptionBy,
            )
          }
          loading={loadingUpdate}
          onSave={() =>
            updateProjectFileAndRefetch({
              variables: {
                data: {
                  metadata: {
                    ...projectFile.metadata,
                    approvedInternallyAt: projectFile.metadata
                      ?.approvedInternallyAt
                      ? null
                      : new Date(),
                  },
                },
                where: { id: projectFile.id },
              },
            })
          }
        />
      )}
      <Divider
        clearing
        style={{
          borderTop: `1px solid ${StyleSystem.Colors.gray200}`,
          marginBottom: '20px',
          marginTop: '20px',
        }}
      />
    </div>
  )
}

export default SubmissionInternal
