import React from 'react'

import { Segment } from 'semantic-ui-react'

import { InitialContainer } from 'components/shared/project/project-file/utils'

const SidebarApproved = () => {
  return (
    <InitialContainer>
      <Segment basic textAlign="center" style={{ margin: '30px' }}>
        <h3>Design Approved</h3>
        <br />
      </Segment>
    </InitialContainer>
  )
}

export default SidebarApproved
