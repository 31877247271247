import React, { createContext, useContext, useEffect, useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'

import useInfiniteQuery from 'hooks/use-infinite-query'
import {
  FIND_MANY_CONVERSATION,
  FindManyConversationPayload,
  FindManyConversationVariables,
} from 'queries/conversation'
import { Conversation } from 'types/conversation'

import { QueryFilters, getQueryVariables } from './utils-many'

interface IConversationManyContext {
  conversations: Conversation[]
  count: number
  fetchMore: () => Promise<ApolloQueryResult<FindManyConversationPayload>>
  fetchedCount: number
  loading: boolean
  loadingMore: boolean
  loadingFirst: boolean
  refetch: () => Promise<ApolloQueryResult<FindManyConversationPayload>>
  queryFilters: QueryFilters
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

export type ConversationsManyProviderProps = {
  defaultFilters: QueryFilters
}

export const ConversationManyContext = createContext<IConversationManyContext>(
  {} as IConversationManyContext,
)

const ConversationManyProvider = ({
  children,
  defaultFilters,
}: React.PropsWithChildren<ConversationsManyProviderProps>) => {
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(defaultFilters)
  const [queryVariables, setQueryVariables] =
    useState<FindManyConversationVariables>(getQueryVariables(queryFilters))
  const [loadingFirst, setLoadingFirst] = useState(true)

  const { data, loading, loadingMore, fetchedCount, fetchMore, refetch } =
    useInfiniteQuery<
      FindManyConversationPayload,
      FindManyConversationVariables
    >(FIND_MANY_CONVERSATION, {
      cacheFieldName: 'findManyConversation',
      fetchedPropertyName: 'conversations',
      refetchBehavior: 'keepSkip',
      queryOptions: {
        variables: queryVariables,
      },
    })

  useEffect(() => {
    setQueryFilters(defaultFilters)
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  useEffect(() => {
    loadingFirst && !loading && setLoadingFirst(false)
  }, [loading])

  return (
    <ConversationManyContext.Provider
      value={{
        conversations: data?.conversations || [],
        count: data?.count || 0,
        fetchedCount,
        fetchMore: fetchMore as () => Promise<
          ApolloQueryResult<FindManyConversationPayload>
        >,
        loading,
        loadingMore,
        loadingFirst,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {children}
    </ConversationManyContext.Provider>
  )
}

export const useConversationMany = () => useContext(ConversationManyContext)

export default ConversationManyProvider
