import React from 'react'

import { Table } from 'semantic-ui-react'

import * as shipmentUtils from 'context/shipment/utils'
import { Shipment } from 'types/shipment'
import * as viewUtils from 'views/utils'
import { TableRowWithCheckProps } from 'views/utils'

const ShipmentSummaryTable = ({ shipment }: { shipment: Shipment }) => {
  const shipmentStatus = shipmentUtils.getShipmentStatus(shipment)

  return (
    <Table definition celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Context</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Confirmed</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <viewUtils.TableRowWithCheck
          {...(shipmentStatus.find(
            (s) => s.id === 'productionWeek',
          ) as TableRowWithCheckProps)}
        />
        <viewUtils.TableRowWithCheck
          {...(shipmentStatus.find(
            (s) => s.id === 'shipment',
          ) as TableRowWithCheckProps)}
        />
        <viewUtils.TableRowWithCheck
          {...(shipmentStatus.find(
            (s) => s.id === 'arrival',
          ) as TableRowWithCheckProps)}
        />
        <viewUtils.TableRowWithCheck
          {...(shipmentStatus.find(
            (s) => s.id === 'receivingWarehouse',
          ) as TableRowWithCheckProps)}
        />
      </Table.Body>
    </Table>
  )
}

export default ShipmentSummaryTable
