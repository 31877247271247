import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Button as SUIButton, Grid } from 'semantic-ui-react'

import { RoomPageTitle } from 'components/admin/page-title'
import GenericProductCreateUpdateModal from 'components/admin/project/room/generic-product/create-update-modal'
import GenericProductsTable from 'components/admin/project/room/generic-product/table'
import RoomLayout from 'components/admin/project/room/layout'
import HelpMessage from 'components/shared/help-message'
import GenericProductClassManyProvider from 'context/generic-product-class/provider-many'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import CheckHighlighted from 'design-system/components/check-highlighted'
import StatusCard from 'design-system/components/status-card'
import { StatusMode } from 'types/utils'

import AppliancesPDFDownloadButton from './pdf'

const Appliances = (
  _: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  const { refetch: refetchProject } = useProject()
  const {
    appliancesAll,
    appliancesAreConfirmed,
    appliancesMode,
    appliancesModeText,
    room,
  } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()
  const [enableCreateAppliance, setEnableCreateAppliance] = useState(false)

  return (
    <>
      <RoomPageTitle room={room} viewName="Appliances" />
      <Grid>
        {appliancesMode === StatusMode.Confirmed && !appliancesAreConfirmed && (
          <Grid.Row>
            <Grid.Column>
              <HelpMessage
                message="Appliances are confirmed at the top level, but not all individual appliances are confirmed. Please unconfirm at the top level to allow customer to confirm on their side, or confirm individual appliances on the customer's behalf to get the system in sync."
                title="Confirmation is out of sync"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={6}>
            <StatusCard
              statusStep={appliancesModeText.summary}
              statusText={appliancesModeText.text}
              statusType={appliancesMode}
            />
            {appliancesMode &&
            (appliancesMode === StatusMode.OpenForRevision ||
              appliancesMode === StatusMode.Submitted) ? (
              <Button
                color="gray"
                fontAwesomeIcon={
                  appliancesMode === StatusMode.Submitted ? 'lock' : 'lock-open'
                }
                kind="text"
                text={`${
                  appliancesMode === StatusMode.Submitted
                    ? 'Unsubmit'
                    : 'Submit'
                } changes`}
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  width: '100%',
                }}
                onClick={async () => {
                  await updateRoomAndRefetch({
                    variables: {
                      data: {
                        data: {
                          applianceChangesSubmittedAt: room?.data
                            .applianceChangesSubmittedAt
                            ? null
                            : new Date(),
                        },
                      },
                      where: { id: room?.id ?? '' },
                    },
                  })
                  refetchProject()
                }}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center" width={6}>
            {appliancesMode &&
            (appliancesMode === StatusMode.Confirmation ||
              appliancesMode === StatusMode.Confirmed) ? (
              <Button
                color="blue"
                fontAwesomeIcon={
                  appliancesMode === StatusMode.Confirmed ? 'lock' : 'lock-open'
                }
                kind="solid"
                text={`${
                  appliancesMode === StatusMode.Confirmed
                    ? 'Unconfirm'
                    : 'Confirm'
                } Appliances`}
                onClick={async () => {
                  await updateRoomAndRefetch({
                    variables: {
                      data: {
                        data: {
                          appliancesConfirmedAt: room?.data
                            .appliancesConfirmedAt
                            ? null
                            : new Date(),
                        },
                      },
                      where: { id: room?.id ?? '' },
                    },
                  })
                  refetchProject()
                }}
              />
            ) : null}
          </Grid.Column>
          <Grid.Column
            width={10}
            style={{ display: 'flex', justifyContent: 'right' }}
          >
            <CheckHighlighted
              checked={!!room?.data?.appliancesCompletedAt}
              color="red"
              text="Initial Flow Completed"
              onChange={async () => {
                await updateRoomAndRefetch({
                  variables: {
                    data: {
                      data: {
                        appliancesCompletedAt: !room?.data.appliancesCompletedAt
                          ? new Date()
                          : null,
                      },
                    },
                    where: {
                      id: room?.id ?? '',
                    },
                  },
                })
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ alignItems: 'center', paddingTop: '0' }}>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={8}>
            <SUIButton
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'plus']}
                  style={{ marginRight: '10px' }}
                />
              }
              content="Add Appliance"
              floated="right"
              onClick={() => setEnableCreateAppliance(true)}
              primary
              style={{ marginLeft: '15px' }}
            />
            <AppliancesPDFDownloadButton />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0' }}>
          <Grid.Column>
            {!!appliancesAll.length && <GenericProductsTable />}
          </Grid.Column>
        </Grid.Row>
        <GenericProductCreateUpdateModal
          open={enableCreateAppliance}
          onClose={() => setEnableCreateAppliance(false)}
        />
      </Grid>
    </>
  )
}

export default (
  props: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  return (
    <RoomLayout>
      <GenericProductClassManyProvider
        defaultFilters={{
          identifierNotIn: ['backsplash', 'countertop', 'plinth'],
          sortBy: 'identifier',
          sortDirection: 'ascending',
          take: undefined,
        }}
      >
        <Appliances {...props} />
      </GenericProductClassManyProvider>
    </RoomLayout>
  )
}
