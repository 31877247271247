import React, { createContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { VisibilityCalculations } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import { apolloClient } from 'context/authorized-apollo/provider'
import {
  FindManyNobiliaProductClassPayload,
  FindManyNobiliaProductClassVariables,
  FIND_MANY_NOBILIA_PRODUCT_CLASS,
} from 'queries/nobilia-product-class'
import { NobiliaProductClass } from 'types/nobilia-product-class'

import { QueryFilters, getQueryVariables } from './utils-many'

interface INobiliaProductClassManyContext {
  catalog: string
  nobiliaProductClasses: NobiliaProductClass[]
  count: number
  loading: boolean
  loadingMore: boolean
  fetchMore: (v: VisibilityCalculations) => Promise<void>
  fetchMoreOnDemand: () => Promise<void>
  queryFilters: QueryFilters
  refetch: () => Promise<unknown>
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

type NobiliaProductClassManyProviderProps = React.PropsWithChildren<{
  catalog?: string
  defaultFilters: QueryFilters
  skipLoader?: boolean
}>

export const NobiliaProductClassManyContext =
  createContext<INobiliaProductClassManyContext>(
    {} as INobiliaProductClassManyContext,
  )

const NobiliaProductClassManyProvider = ({
  catalog,
  children,
  defaultFilters,
  skipLoader,
}: NobiliaProductClassManyProviderProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(defaultFilters)
  const [queryVariables, setQueryVariables] =
    useState<FindManyNobiliaProductClassVariables>(
      getQueryVariables(queryFilters),
    )
  const {
    data: { nobiliaProductClasses, count } = {
      nobiliaProductClasses: [],
      count: 0,
    },
    loading,
    fetchMore,
  } = useQuery<
    FindManyNobiliaProductClassPayload,
    FindManyNobiliaProductClassVariables
  >(FIND_MANY_NOBILIA_PRODUCT_CLASS, {
    variables: queryVariables,
  })

  useEffect(() => {
    // this effect triggers on refetch, something to do with the cache eviction
    // triggering a higher level re-render.
    // To avoid losing filters on refetch we add the refetching check here
    if (!refetching) {
      setQueryFilters(defaultFilters)
    }
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  const fetchMore_ = async ({ bottomVisible }: VisibilityCalculations) => {
    if (
      loading ||
      loadingMore ||
      !bottomVisible ||
      nobiliaProductClasses.length === count
    )
      return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: nobiliaProductClasses.length,
      },
    })
    setLoadingMore(false)
  }

  const fetchMoreOnDemand = async () => {
    if (loading || loadingMore || nobiliaProductClasses.length === count) return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: nobiliaProductClasses.length,
      },
    })
    setLoadingMore(false)
  }

  const refetch = async () => {
    setRefetching(true)
    // evict and refetch first batch
    apolloClient?.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'findManyNobiliaProductClass',
    })
  }

  if (loading && !skipLoader) return <Loader />

  return (
    <NobiliaProductClassManyContext.Provider
      value={{
        catalog: catalog ?? '',
        nobiliaProductClasses,
        count: count ?? 0,
        loading,
        loadingMore,
        fetchMore: fetchMore_,
        fetchMoreOnDemand,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {children}
    </NobiliaProductClassManyContext.Provider>
  )
}

export default NobiliaProductClassManyProvider
