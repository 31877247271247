import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import moment from 'moment'
import { Divider, Dropdown, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import PublishModal from 'components/admin/catalog/catalog-version/publish-modal'
import PageTitle from 'components/admin/page-title'
import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'
import { useCatalog } from 'context/catalog/provider'
import useCatalogSnapshot from 'context/catalog/use-snapshot'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import * as viewUtils from 'views/utils'

import { CATALOGS } from './catalog.config'

const CatalogView = (_: RouteComponentProps<{ catalog_id: string }>) => {
  const catalogOptions = viewUtils.filterUnsupportedCatalogOptions(
    viewUtils.DESIGN_CATALOG_OPTIONS,
    ['2021'],
  )
  const { isAdmin, isSuperAdmin } = useAuth()

  const {
    catalog,
    disconnectedFeatures,
    genericFeatures,
    roomElementFeatures,
    parsedNobiliaFeatures,
    loading,
  } = useCatalog()

  const {
    diffCatalogData,
    diffCatalogRestoreData,
    loadingDiffCatalog,
    loadingDiffCatalogRestore,
    loadingRestore,
    loadingSave,
    getDiffs,
    restoreCatalog,
    saveCatalog,
    totalRestoreUpdated,
    totalUpdated,
  } = useCatalogSnapshot()

  if (loading) return <Loader />

  return (
    <>
      <PageTitle title="Catalog" />
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <h3>
              Catalog {catalog}
              <Dropdown
                className="yearcatalog"
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'angle-down']}
                    style={{
                      fontSize: '25px',
                      verticalAlign: 'middle',
                      padding: '0 10px',
                    }}
                  />
                }
                inline
                onChange={(_, { value }) => {
                  if (value !== catalog) {
                    window.location.href = `/admin/catalog/${value}`
                  }
                }}
                options={catalogOptions}
                text={' '}
              />
            </h3>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              color="blue"
              disabled={!isAdmin}
              fontAwesomeIcon="external-link-square"
              kind="solid"
              onClick={() => navigate(`/admin/media`)}
              text="Go to media manager"
            />
          </Grid.Column>
        </Grid.Row>
        {isSuperAdmin && (
          <Grid.Row>
            <Grid.Column verticalAlign="middle" textAlign="right">
              {!!diffCatalogData._metadata?.author && (
                <p>
                  {`LAST MODIFIED 
                ${moment(diffCatalogData?._metadata?.timestamp)
                  .format('MMM Do YYYY')
                  .toUpperCase()} 
                BY ${diffCatalogData?._metadata?.author?.toUpperCase()}`}
                </p>
              )}
              <Button
                color="gray"
                fontAwesomeIcon="clock-rotate-left"
                kind="ghost"
                loading={loadingDiffCatalog || loadingDiffCatalogRestore}
                onClick={() => getDiffs()}
                style={{ marginRight: '16px' }}
                text="Check Latest Snapshot"
              />
              {!!diffCatalogData._metadata?.timestamp && (
                <PublishModal
                  catalogDiff={diffCatalogData}
                  onCommit={async () => {
                    await saveCatalog()
                    getDiffs()
                  }}
                  onCommitLoading={loadingSave || loadingDiffCatalog}
                  icon="upload"
                  title="Publish Changes"
                  trigger={
                    <Button
                      disabled={!totalUpdated}
                      style={{ marginRight: '16px' }}
                      kind="outlined"
                      color="blue"
                      fontAwesomeIcon="upload"
                      text={`Publish changes (${totalUpdated})`}
                    />
                  }
                />
              )}
              {!!diffCatalogRestoreData._metadata?.timestamp && (
                <PublishModal
                  catalogDiff={diffCatalogRestoreData}
                  onCommit={async () => {
                    await restoreCatalog()
                    getDiffs()
                  }}
                  onCommitLoading={loadingRestore || loadingDiffCatalogRestore}
                  icon="download"
                  title="Restore From Snapshot"
                  trigger={
                    <Button
                      disabled={!totalRestoreUpdated}
                      kind="solid"
                      fontAwesomeIcon="download"
                      text={`Restore from snapshot (${totalRestoreUpdated})`}
                    />
                  }
                />
              )}
            </Grid.Column>
          </Grid.Row>
        )}

        {CATALOGS.map(({ subtitle, categories }) => (
          <React.Fragment key={subtitle}>
            <StyledSubtitleRow>
              <Grid.Column>
                <SubTitle>{subtitle}</SubTitle>
                <Divider />
              </Grid.Column>
            </StyledSubtitleRow>
            <Grid.Row columns={4}>
              {categories.map(({ navigateUrl, imageName, name }) => (
                <CategoryColumn
                  key={`${subtitle}-${name}`}
                  onClick={() =>
                    navigate(
                      typeof navigateUrl === 'function'
                        ? navigateUrl({
                            catalog,
                            genericFeatures,
                            disconnectedFeatures,
                            roomElementFeatures,
                            ...parsedNobiliaFeatures,
                          })
                        : navigateUrl,
                    )
                  }
                >
                  <Image
                    className="ui centered medium image"
                    src={`${process.env.GATSBY_CDN_ROOT}/images/app/${imageName}`}
                  />
                  <p>{name}</p>
                </CategoryColumn>
              ))}
            </Grid.Row>
          </React.Fragment>
        ))}
      </Grid>
    </>
  )
}

export default CatalogView

const SubTitle = styled.div`
  &&& {
    color: ${Colors.gray500};
    text-transform: capitalize;
  }
`

const StyledSubtitleRow = styled(Grid.Row)`
  &&& {
    padding: 30px 0 0 0;
  }
`

const CategoryColumn = styled(Grid.Column)`
  padding: 40px 50px !important;

  :hover {
    background: ${Colors.gray100};
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
  }

  p {
    color: ${Colors.black};
    padding-top: 10px;
    text-align: center;
  }
`
