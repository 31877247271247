import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import useProjectsStaff from 'context/project/use-projects-staff'
import useTechDesignerNewProjectQueue from 'context/round-robin/use-tech-designer-new-project-queue'
import { ProjectStaff, ProjectStaffRole } from 'types/project-staff'
import { User } from 'types/user'

import ProjectsStaffTable from './projects-staff-table'
import UsersTable from './users-table'

const TechDesignersQueue = ({
  designers,
  projectsStaff,
  refetch,
}: {
  designers: User[]
  projectsStaff: ProjectStaff[]
  refetch: () => Promise<unknown>
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={7}>
          <p>Queue</p>
          <UsersTable
            designers={designers}
            refetch={refetch}
            role={ProjectStaffRole.TECH_DESIGNER}
          />
        </Grid.Column>
        <Grid.Column width={9}>
          <p>Recent Project Assignments</p>
          <ProjectsStaffTable projectsStaff={projectsStaff} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default (_: RouteComponentProps) => {
  const { designers, loading, refetch } = useTechDesignerNewProjectQueue()
  const { projectsStaff, loadingProjectsStaff } = useProjectsStaff(
    ProjectStaffRole.TECH_DESIGNER,
  )

  if (loading || loadingProjectsStaff) return <Loader />

  return (
    <TechDesignersQueue
      designers={designers}
      projectsStaff={projectsStaff}
      refetch={refetch}
    />
  )
}
