import React from 'react'

import { Grid } from 'semantic-ui-react'

import MediaCard from 'components/admin/catalog/media/card'
import * as mUtils from 'context/media/utils-many'
import { RoomElementClass } from 'types/room-element-class'

const RoomElementClassSummary = ({
  refetch,
  roomElementClass,
}: {
  refetch: () => Promise<unknown>
  roomElementClass: RoomElementClass
}) => {
  const referenceImage = mUtils.parseMedia(roomElementClass.media).reference[0]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          width={5}
          textAlign="center"
          style={{ display: 'inline-table' }}
        >
          <MediaCard
            enableEdit={!!referenceImage}
            media={referenceImage}
            refetch={refetch}
          />
          <p className="caption" style={{ marginTop: '14px' }}>
            Reference Image
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default RoomElementClassSummary
