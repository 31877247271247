import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import NobiliaSampleClasses from 'components/admin/catalog/nobilia-sample-class/index-many'
import { useNobiliaSampleClassMany } from 'context/nobilia-sample-class/provider-many'
import { StyledBreadcrumb } from 'styles/planner/main'

type SampleOrdersViewProps = RouteComponentProps<{ catalog_id: string }>

const SampleOrdersView = (_: SampleOrdersViewProps) => {
  const { catalog } = useNobiliaSampleClassMany()
  const sections = [
    {
      key: 'Catalog',
      content: `Catalog`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Nobilia Samples',
      content: 'Nobilia Samples',
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <h3 className="catalog">Nobilia Samples</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <NobiliaSampleClasses />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default SampleOrdersView
