import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import CreateUpdateTaskModal from 'components/admin/project/task/create-update-modal'
import { Chip } from 'components/shared/chip'
import useProject from 'context/project/use'
import { parseStaff } from 'context/project/utils'
import { getRoomTypeWithDescription } from 'context/room/utils'
import StyledAccordion from 'design-system/components/accordion'
import Button from 'design-system/components/button'
import { Drawer } from 'design-system/components/drawer/drawer'
import GuideCard from 'design-system/components/guide-card/guide-card'
import StatusLabel from 'design-system/components/status-label/status-label'
import { Colors } from 'styles/app/system'
import { OverviewStage } from 'types/utils'

import HelpMessage from '../../shared/help-message'
import { InfoPopup } from '../../shared/project/project-file/utils'

export default ({ overviewStage }: { overviewStage: OverviewStage }) => {
  const { project, refetch } = useProject()
  const [showTaskModal, toggleTaskModal] = useState<boolean>(false)

  return (
    <StyledAccordion
      titleExtras={
        <ExtrasDiv>
          {overviewStage.rooms.map((r) => (
            <Chip
              key={r.id}
              name={getRoomTypeWithDescription(r)}
              color={r.data.tagColor}
              opacity={0.3}
            />
          ))}
        </ExtrasDiv>
      }
      titleText={overviewStage.name}
    >
      <ContentDiv>
        <div className="left">
          {overviewStage.checklists.map((c, idx) => (
            <Grid key={idx}>
              <Grid.Row>
                <Grid.Column>
                  {c.room ? (
                    <Chip
                      key={c.room.id}
                      name={getRoomTypeWithDescription(c.room)}
                      color={c.room.data.tagColor}
                      opacity={0.3}
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={7}>
                  <p className="overline light-gray">Checklist</p>
                </Grid.Column>
                <Grid.Column width={8}>
                  <p className="overline light-gray">Status</p>
                </Grid.Column>
              </Grid.Row>
              {c.checklistItems.map((ci, idx) => (
                <Grid.Row key={idx}>
                  <Grid.Column>
                    <ItemGrid>
                      <Grid.Row>
                        <Grid.Column width={7}>
                          <Button
                            kind="ghost"
                            text=""
                            icon={
                              <FontAwesomeIcon
                                icon={['fal', 'external-link']}
                              />
                            }
                            onClick={() => window.open(ci.url, '_blank')}
                            style={{ marginRight: '8px' }}
                          />
                          <span>
                            {ci.room ? (
                              <Chip
                                noMargin
                                name={ci.name}
                                color={ci.room.data?.tagColor}
                              />
                            ) : (
                              ci.name
                            )}
                            {ci.subtitle && (
                              <p className="small gray">{ci.subtitle}</p>
                            )}
                          </span>
                        </Grid.Column>
                        <Grid.Column width={7}>
                          <StatusLabel
                            type={ci.statusMode}
                            text={ci.statusText}
                          />
                        </Grid.Column>
                        <Grid.Column width={1}>
                          {ci.urlHelp ? (
                            <InfoPopup
                              content={
                                <p>
                                  Click on the{' '}
                                  <FontAwesomeIcon
                                    icon={['fal', 'question-circle']}
                                    style={{
                                      fontSize: '14px',
                                      pointerEvents: 'none',
                                    }}
                                  />{' '}
                                  to learn more about this step.
                                </p>
                              }
                              position="top center"
                              trigger={
                                <a target="_blank" href={ci.urlHelp}>
                                  <FontAwesomeIcon
                                    icon={['fal', 'question-circle']}
                                    style={{
                                      color: Colors.blue800,
                                      cursor: 'pointer',
                                      fontSize: '14px',
                                      pointerEvents: 'none',
                                    }}
                                  />
                                </a>
                              }
                            />
                          ) : null}
                        </Grid.Column>
                        <Grid.Column width={1}>
                          <Button
                            icon={
                              <FontAwesomeIcon icon={['fal', 'calendar-pen']} />
                            }
                            onClick={() => toggleTaskModal(true)}
                            kind="ghost"
                            text=""
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </ItemGrid>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          ))}
        </div>
        <div className="right">
          {overviewStage.alerts.length > 0 && (
            <Drawer
              content={() => (
                <Grid>
                  {overviewStage.alerts.map((a, idx) => (
                    <Grid.Row key={idx}>
                      <Grid.Column>
                        <HelpMessage
                          icon="bell-exclamation"
                          title="Important"
                          message={a.message}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                </Grid>
              )}
              defaultOpen
              title="Alerts"
              withLine
            />
          )}
          {overviewStage.guides.length > 0 && (
            <Drawer
              content={() => (
                <Grid>
                  {overviewStage.guides.map((g, idx) => (
                    <Grid.Row key={idx}>
                      <Grid.Column>
                        <GuideCard type={g.type} orderId={g.orderId} />
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                </Grid>
              )}
              defaultOpen
              title="Interactive Guides"
              withLine
            />
          )}
        </div>
        <CreateUpdateTaskModal
          open={showTaskModal}
          onClose={() => toggleTaskModal(false)}
          projectId={project.id}
          projectStaff={parseStaff(project).allStaff}
          refetch={refetch}
        />
      </ContentDiv>
    </StyledAccordion>
  )
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;

  div.left {
    width: 60%;
    .grid {
      padding-top: 10px;
      margin-bottom: 40px;
    }
    .grid:last-of-type {
      margin-bottom: 10px;
    }
    .row {
      padding: 6px 0 !important;
    }
  }

  div.right {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 40%;
  }
`

const ExtrasDiv = styled.div`
  align-items: center;
  display: flex;
  gap: 16px !important;
  .room-tag {
    margin: 0;
  }
`
const ItemGrid = styled(Grid)<{ checked?: boolean; small?: boolean }>`
  &&& {
    align-items: center;
    background: ${Colors.white};
    border-radius: 6px;
    box-shadow: 0px 1px 5px rgba(120, 113, 108, 0.1);
    display: flex;
    justify-content: space-between;
    margin: 0 !important;
    padding: 12px 20px;
    .row {
      padding: 0;
    }
    .column {
      align-items: center;
      display: flex !important;
      padding: 0 !important;
    }
    label {
      font-size: 15px !important;
    }
  }
`
