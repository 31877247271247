import React from 'react'

import { RouteComponentProps } from '@reach/router'

import DisconnectedOptionManyProvider from 'context/disconnected-option/provider-many'

interface DisconnectedOptionIndexManyProps
  extends RouteComponentProps<{
    catalog_id: string
    disconnected_feature_id: string
  }> {
  children: React.ReactNode
}

const DisconnectedOptionIndexMany = (
  props: DisconnectedOptionIndexManyProps,
) => {
  return (
    <DisconnectedOptionManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        feature: {
          id: props.disconnected_feature_id,
        },
        sortBy: 'description',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </DisconnectedOptionManyProvider>
  )
}

export default DisconnectedOptionIndexMany
