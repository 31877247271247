import React from 'react'

import {
  SelectionCardDelete,
  SelectionCardDeleteProps,
  SelectionCardExpandSelect,
  SelectionCardExpandSelectProps,
  SelectionCardProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { RenderProp } from 'types/render-prop'
import { responsiveText } from 'views/utils'

export interface RenderPropsSelectionCardProps {
  renderProp: RenderProp
  selectionCardProps: SelectionCardProps
  selectionCardVariation: SelectionCardVariation
}

const RenderPropSelectionCard = ({
  renderProp,
  selectionCardProps,
  selectionCardVariation,
}: RenderPropsSelectionCardProps) => {
  switch (selectionCardVariation) {
    case SelectionCardVariation.EXPAND_SELECT:
      return (
        <SelectionCardExpandSelect
          description={renderProp.groupIdentifier}
          imageUrl={renderProp.imageUrl}
          title={responsiveText(renderProp.description, 50)}
          {...(selectionCardProps as Omit<
            SelectionCardExpandSelectProps,
            'imageUrl'
          >)}
        />
      )
    case SelectionCardVariation.DELETE:
      return (
        <SelectionCardDelete
          description={renderProp.groupIdentifier}
          imageUrl={renderProp.imageUrl}
          title={responsiveText(renderProp.description, 50)}
          {...(selectionCardProps as Omit<
            SelectionCardDeleteProps,
            'imageUrl'
          >)}
        />
      )
    default:
      return null
  }
}

export default RenderPropSelectionCard
