import React, { useState } from 'react'

import { CompactPicker } from 'react-color'
import styled from 'styled-components'

interface ColorPickerProps {
  color?: string
  onChangeColor: (hex: string) => void
}

const ColorPicker = (props: ColorPickerProps) => {
  const [state, setState] = useState({
    displayClrPkr: false,
    color: props.color ? props.color : '#BEBEBE',
  })

  const onClick = () => {
    setState({
      ...state,
      displayClrPkr: !state.displayClrPkr,
    })
  }

  const stateClose = () => {
    setState({
      ...state,
      displayClrPkr: false,
    })
  }

  const onChange = (color: { hex: string }) => {
    setState({
      ...state,
      color: color.hex,
    })
    props.onChangeColor(color.hex)
  }

  return (
    <>
      <div>
        <SwatchDiv onClick={onClick}>
          <ColorDiv color={state.color} />
        </SwatchDiv>
        {state.displayClrPkr ? (
          <PopoverDiv>
            <CoverDiv onClick={stateClose} />
            <CompactPicker color={state.color} onChange={onChange} />
          </PopoverDiv>
        ) : null}
      </div>
    </>
  )
}

const CoverDiv = styled.div`
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: fixed;
`

const PopoverDiv = styled.div`
  position: absolute;
  z-index: 2;
`

const SwatchDiv = styled.div`
  padding: 6px,
  background: #fffff6;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 0 0 2px rgba(0,0,0,.5);
`

const ColorDiv = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: ${(props) => props.color};
`
export default ColorPicker
