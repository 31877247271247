import React from 'react'

import { isEqual } from 'lodash'
import { Grid } from 'semantic-ui-react'

import { useCatalogFrontColor } from 'content-queries/cx/catalog-front-color'
import { useDrillPatterns } from 'content-queries/cx/drill-patterns'
import { useFrontMaterial } from 'content-queries/cx/front-material'
import { useHandleType } from 'content-queries/cx/handle-type'
import {
  NobiliaOptionDiff,
  NobiliaOptionUpdate,
} from 'types/nobilia-option-diff'

import MediaDiffComponent from './media-diff'
import {
  getReferenceImage,
  ImageCard,
  ReferenceDiv,
  StrikeThrough,
  StyledLabelDiffs,
  SubGrid,
} from './utils'

const useParseDataFields = (nobiliaOption: NobiliaOptionDiff) => {
  const { data: allFrontColors } = useCatalogFrontColor()
  const { data: allDrillPatterns } = useDrillPatterns()
  const { data: allHandleTypes } = useHandleType()
  const { data: allFrontMaterials } = useFrontMaterial()

  const colorIdToContentfulTitle = (colorId: string) => {
    return (
      allFrontColors.find(
        (fc: { contentful_id: string; title: string }) =>
          colorId === fc.contentful_id,
      )?.title || colorId
    )
  }

  const drillPatternIdToContentfulTitle = (drillPatternId: string) => {
    return (
      allDrillPatterns.find(
        (fc: { contentful_id: string; title: string }) =>
          drillPatternId === fc.contentful_id,
      )?.title || drillPatternId
    )
  }

  const frontMaterialIdToContentfulTitle = (frontMaterialId: string) => {
    return (
      allFrontMaterials.find(
        (fc: { contentful_id: string; title: string }) =>
          frontMaterialId === fc.contentful_id,
      )?.title || frontMaterialId
    )
  }

  const handleTypeIdToContentfulTitle = (handleTypeId: string) => {
    return (
      allHandleTypes.find(
        (fc: { contentful_id: string; title: string }) =>
          handleTypeId === fc.contentful_id,
      )?.title || handleTypeId
    )
  }

  const isUpdate = nobiliaOption.type === 'update'
  const snapshotFrom = isUpdate
    ? (nobiliaOption as NobiliaOptionUpdate).data.from
    : nobiliaOption.snapshot
  const snapshotTo = isUpdate
    ? (nobiliaOption as NobiliaOptionUpdate).data.to
    : nobiliaOption.snapshot
  const snapshotFromData = snapshotFrom.data
  const snapshotToData = snapshotTo.data
  const out = [
    {
      fieldName: 'Category',
      fieldValue: snapshotToData?.category,
      fieldValueChanged: snapshotFromData?.category,
    },
    {
      fieldName: 'Color',
      fieldValue: colorIdToContentfulTitle(snapshotToData?.color ?? ''),
      fieldValueChanged: colorIdToContentfulTitle(
        snapshotFromData?.color ?? '',
      ),
    },
    {
      fieldName: 'Description',
      fieldValue: snapshotTo?.description,
      fieldValueChanged: snapshotFrom?.description,
    },
    {
      fieldName: 'Discontinued',
      fieldValue: snapshotToData?.isDiscontinued,
      fieldValueChanged: snapshotFromData?.isDiscontinued,
    },
    {
      fieldName: 'Display Name',
      fieldValue: snapshotToData?.displayName,
      fieldValueChanged: snapshotFromData?.displayName,
    },
    {
      fieldName: 'Drill Distance',
      fieldValue: snapshotToData?.drillDistance,
      fieldValueChanged: snapshotToData?.drillDistance,
    },
    {
      fieldName: 'Drill Pattern',
      fieldValue: snapshotToData?.drillPatterns
        ?.map(drillPatternIdToContentfulTitle)
        .sort()
        .join(', '),
      fieldValueChanged: snapshotFromData?.drillPatterns
        ?.map(drillPatternIdToContentfulTitle)
        .sort()
        .join(', '),
    },
    {
      fieldName: 'Edging',
      fieldValue: snapshotToData?.edging,
      fieldValueChanged: snapshotFromData?.edging,
    },
    {
      fieldName: 'Finish',
      fieldValue: snapshotToData?.finish,
      fieldValueChanged: snapshotFromData?.finish,
    },
    {
      fieldName: 'Group Identifier',
      fieldValue: snapshotToData?.groupIdentifier,
      fieldValueChanged: snapshotFromData?.groupIdentifier,
    },
    {
      fieldName: 'Handle Types',
      fieldValue: snapshotToData?.handleTypes
        ?.map(handleTypeIdToContentfulTitle)
        .sort()
        .join(', '),
      fieldValueChanged: snapshotFromData?.handleTypes
        ?.map(handleTypeIdToContentfulTitle)
        .sort()
        .join(', '),
    },
    {
      fieldName: 'Height',
      fieldValue: snapshotToData?.height,
      fieldValueChanged: snapshotFromData?.height,
    },
    {
      fieldName: 'Material',
      fieldValue: frontMaterialIdToContentfulTitle(
        snapshotToData?.material ?? '',
      ),
      fieldValueChanged: frontMaterialIdToContentfulTitle(
        snapshotFromData?.material ?? '',
      ),
    },
    {
      fieldName: 'Price Range',
      fieldValue: snapshotToData?.priceRange,
      fieldValueChanged: snapshotFromData?.priceRange,
    },
    {
      fieldName: 'Related Fronts',
      fieldValue: snapshotToData?.relatedFronts
        ?.map((rf) => rf.description)
        .sort()
        .join(', '),
      fieldValueChanged: snapshotFromData?.relatedFronts
        ?.map((rf) => rf.description)
        .sort()
        .join(', '),
    },
    {
      fieldName: 'Show On Website Catalog',
      fieldValue: snapshotToData?.showOnWebsiteCatalog,
      fieldValueChanged: snapshotFromData?.showOnWebsiteCatalog,
    },
    {
      fieldName: 'Texture',
      fieldValue: snapshotToData?.texture,
      fieldValueChanged: snapshotFromData?.texture,
    },
  ]
  return out.filter((o) => {
    if (!isUpdate) return true
    return !isEqual(o.fieldValue, o.fieldValueChanged)
  })
}

const MediaDiffs = ({
  nobiliaOptionDiff,
}: {
  nobiliaOptionDiff: NobiliaOptionDiff
}) => {
  const isAdd = nobiliaOptionDiff.type === 'add'
  const isDelete = nobiliaOptionDiff.type === 'delete'
  const showMedia =
    !isAdd && !isDelete
      ? !!nobiliaOptionDiff.media?.length
      : !!nobiliaOptionDiff.snapshot.media.length
  if (!showMedia) return <></>
  if (isAdd)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {nobiliaOptionDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'add',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  if (isDelete)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {nobiliaOptionDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'delete',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  return (
    <>
      <p className="caption no-margin">Media</p>
      {nobiliaOptionDiff.media?.map((mdiff) => (
        <MediaDiffComponent key={mdiff.snapshot.key} mediaDiff={mdiff} />
      ))}
    </>
  )
}

const DataDiffs = ({
  nobiliaOptionDiff,
}: {
  nobiliaOptionDiff: NobiliaOptionDiff
}) => {
  const fields = useParseDataFields(nobiliaOptionDiff)
  const isUpdate = nobiliaOptionDiff.type === 'update'

  if (!fields.length) return null

  return (
    <>
      <p className="caption no-margin">Data</p>
      {fields.map((f) => (
        <Grid.Row key={f.fieldName}>
          <Grid.Column width={4}>
            <p className="caption">{f.fieldName}</p>
          </Grid.Column>
          <Grid.Column width={12}>
            {isUpdate ? (
              <StrikeThrough>{`${f.fieldValueChanged}`}</StrikeThrough>
            ) : null}
            <p>{`${f.fieldValue}`}</p>
          </Grid.Column>
        </Grid.Row>
      ))}
    </>
  )
}

const NobiliaOptionDiffComponent = ({
  nobiliaOptionDiff,
}: {
  nobiliaOptionDiff: NobiliaOptionDiff
}) => {
  const reference = getReferenceImage(nobiliaOptionDiff.snapshot.media)
  return (
    <>
      <ReferenceDiv>
        <ImageCard image={reference} />
        <div>
          <p className="caption">{nobiliaOptionDiff.snapshot?.catalog}</p>
          <p>
            {nobiliaOptionDiff.snapshot.data?.displayName ||
              nobiliaOptionDiff.snapshot.description}
          </p>
        </div>
        <StyledLabelDiffs type={nobiliaOptionDiff.type} />
      </ReferenceDiv>
      <SubGrid>
        <MediaDiffs nobiliaOptionDiff={nobiliaOptionDiff} />
        <DataDiffs nobiliaOptionDiff={nobiliaOptionDiff} />
      </SubGrid>
    </>
  )
}

export default NobiliaOptionDiffComponent
