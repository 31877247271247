import React from 'react'

import { RoomElementClassDiff } from 'types/room-element-class-diff'

import MediaDiffComponent from './media-diff'
import {
  getReferenceImage,
  ImageCard,
  ReferenceDiv,
  StyledLabelDiffs,
  SubGrid,
} from './utils'

const MediaDiffs = ({
  roomElementClassDiff,
}: {
  roomElementClassDiff: RoomElementClassDiff
}) => {
  const isAdd = roomElementClassDiff.type === 'add'
  const isDelete = roomElementClassDiff.type === 'delete'
  const showMedia =
    !isAdd && !isDelete
      ? !!roomElementClassDiff.media?.length
      : roomElementClassDiff.snapshot.media.length > 0
  if (!showMedia) return <></>
  if (isAdd)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {roomElementClassDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'add',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  if (isDelete)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {roomElementClassDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'delete',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  return (
    <>
      <p className="caption no-margin">Media</p>
      {roomElementClassDiff.media?.map((mdiff) => (
        <MediaDiffComponent key={mdiff.snapshot.key} mediaDiff={mdiff} />
      ))}
    </>
  )
}

const RoomElementClassDiffComponent = ({
  roomElementClassDiff,
}: {
  roomElementClassDiff: RoomElementClassDiff
}) => {
  const reference = getReferenceImage(roomElementClassDiff.snapshot.media)

  return (
    <>
      <ReferenceDiv>
        <ImageCard image={reference} />
        <div>
          <p className="caption">{roomElementClassDiff.snapshot.identifier}</p>
        </div>
        <StyledLabelDiffs type={roomElementClassDiff.type} />
      </ReferenceDiv>
      <SubGrid>
        <MediaDiffs roomElementClassDiff={roomElementClassDiff} />
      </SubGrid>
    </>
  )
}

export default RoomElementClassDiffComponent
