import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_COMPANY,
  CreateCompanyPayload,
  CreateCompanyVariables,
  DELETE_COMPANY,
  DeleteCompanyPayload,
  DeleteCompanyVariables,
  UPDATE_COMPANY,
  UpdateCompanyPayload,
  UpdateCompanyVariables,
} from 'queries/company'

import { CompanyContext } from './provider'

const useCompanyMutate = () => {
  const { refetch } = useContext(CompanyContext)

  const [createCompanyAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateCompanyPayload,
    CreateCompanyVariables
  >(CREATE_COMPANY, {
    onCompleted: refetch,
  })

  const [updateCompanyAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateCompanyPayload,
    UpdateCompanyVariables
  >(UPDATE_COMPANY, {
    onCompleted: refetch,
  })

  const [deleteCompanyAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteCompanyPayload,
    DeleteCompanyVariables
  >(DELETE_COMPANY, {
    onCompleted: refetch,
  })

  return {
    createCompanyAndRefetch,
    deleteCompanyAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateCompanyAndRefetch,
  }
}

export default useCompanyMutate
