import React from 'react'

import SamplesOrderTab from 'components/admin/project/order-samples/tab'
import OrderProvider from 'context/order/provider'
import useOrder from 'context/order/use'
import useOrderLookup from 'context/order/use-lookup'
import { Modal } from 'design-system/components/modal'
import { FIND_UNIQUE_ORDER_SAMPLES_WITH_PROJECT_AND_PROSPECT } from 'queries/order'

interface SamplesModalProps {
  onClose?: () => void
  openModal?: boolean
  onComplete: () => void
  trigger?: React.ReactElement
}

const SamplesModal = ({
  onClose,
  openModal,
  onComplete,
  trigger,
}: SamplesModalProps) => {
  const { order } = useOrder()
  return (
    <Modal
      onClose={onClose}
      open={openModal}
      size="fullscreen"
      title="Samples Order"
      trigger={trigger}
    >
      {order && (
        <SamplesOrderTab
          project={order.project}
          prospect={!order.project ? order.prospect : undefined}
          onComplete={() => {
            onComplete?.()
            onClose?.()
          }}
        />
      )}
    </Modal>
  )
}

interface SamplesModalIdProps {
  open?: boolean
  onClose?: () => void
  orderId: string
  refetch?: () => void
  trigger?: React.ReactElement
}

export default ({
  open,
  onClose,
  orderId,
  refetch,
  trigger,
}: SamplesModalIdProps) => {
  const { loading, order } = useOrderLookup(orderId)
  if (!order || loading) return null

  return (
    <OrderProvider
      query={FIND_UNIQUE_ORDER_SAMPLES_WITH_PROJECT_AND_PROSPECT}
      order_id={order.id}
      noLoader
    >
      <SamplesModal
        onClose={onClose}
        openModal={open}
        onComplete={() => refetch?.()}
        trigger={trigger}
      />
    </OrderProvider>
  )
}
