import React from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'
import 'moment-timezone'
import { Router } from '@reach/router'

import ErrorBoundary from 'components/shared/error-boundary'
import SemanticUIFull from 'components/shared/semantic-ui-full'
import SEO from 'components/shared/seo'
import { StyledContent } from 'styles/admin/system'
import CatalogIndex from 'views/admin/catalog'
import AppliancePackIndex from 'views/admin/catalog/appliance-pack/index'
import AppliancePackIndexMany from 'views/admin/catalog/appliance-pack/index-many'
import AppliancePackView from 'views/admin/catalog/appliance-pack/view'
import AppliancePackViewMany from 'views/admin/catalog/appliance-pack/view-many'
import Catalog from 'views/admin/catalog/catalog'
import CatalogFeatureIndex from 'views/admin/catalog/catalog-feature/index'
import CatalogFeatureIndexMany from 'views/admin/catalog/catalog-feature/index-many'
import CatalogFeatureView from 'views/admin/catalog/catalog-feature/view'
import CatalogFeatureViewMany from 'views/admin/catalog/catalog-feature/view-many'
import DisconnectedOptionIndexMany from 'views/admin/catalog/disconnected-option/index-many'
import DisconnectedOptionViewMany from 'views/admin/catalog/disconnected-option/view-many'
import GenericOptionIndex from 'views/admin/catalog/generic-option/index'
import GenericOptionIndexMany from 'views/admin/catalog/generic-option/index-many'
import GenericOptionView from 'views/admin/catalog/generic-option/view'
import GenericOptionViewMany from 'views/admin/catalog/generic-option/view-many'
import GenericProductClassIndex from 'views/admin/catalog/generic-product-class/index'
import GenericProductClassIndexMany from 'views/admin/catalog/generic-product-class/index-many'
import GenericProductClassView from 'views/admin/catalog/generic-product-class/view'
import GenericProductClassViewMany from 'views/admin/catalog/generic-product-class/view-many'
import Media from 'views/admin/catalog/media'
import NobiliaFeatureBrowser from 'views/admin/catalog/nobilia-feature-browser/browse'
import NobiliaOptionIndex from 'views/admin/catalog/nobilia-option/index'
import NobiliaOptionIndexMany from 'views/admin/catalog/nobilia-option/index-many'
import NobiliaOptionView from 'views/admin/catalog/nobilia-option/view'
import NobiliaOptionViewMany from 'views/admin/catalog/nobilia-option/view-many'
import NobiliaProductClassIndex from 'views/admin/catalog/nobilia-product-class/index'
import NobiliaProductClassIndexMany from 'views/admin/catalog/nobilia-product-class/index-many'
import NobiliaProductClassView from 'views/admin/catalog/nobilia-product-class/view'
import NobiliaProductClassViewMany from 'views/admin/catalog/nobilia-product-class/view-many'
import NobiliaSampleClassIndexMany from 'views/admin/catalog/nobilia-sample-class/index-many'
import NobiliaSampleClassViewMany from 'views/admin/catalog/nobilia-sample-class/view-many'
import RenderPropIndexMany from 'views/admin/catalog/render-prop/index-many'
import RenderPropViewMany from 'views/admin/catalog/render-prop/view-many'
import RoomElementClassIndex from 'views/admin/catalog/room-element-class/index'
import RoomElementClassIndexMany from 'views/admin/catalog/room-element-class/index-many'
import RoomElementClassView from 'views/admin/catalog/room-element-class/view'
import RoomElementClassViewMany from 'views/admin/catalog/room-element-class/view-many'
import RoomElementOptionIndex from 'views/admin/catalog/room-element-option/index'
import RoomElementOptionIndexMany from 'views/admin/catalog/room-element-option/index-many'
import RoomElementOptionView from 'views/admin/catalog/room-element-option/view'
import RoomElementOptionViewMany from 'views/admin/catalog/room-element-option/view-many'
import Companies from 'views/admin/companies'
import Company from 'views/admin/company'
import AccountExecutive from 'views/admin/dashboard/account-executive'
import AccountExecutiveNotes from 'views/admin/dashboard/account-executive/notes'
import AccountExecutiveOverview from 'views/admin/dashboard/account-executive/overview'
import AccountExecutiveProspects from 'views/admin/dashboard/account-executive/prospects'
import CustomerSupport from 'views/admin/dashboard/customer-support'
import CustomerSupportConversations from 'views/admin/dashboard/customer-support/conversations'
import CustomerSupportDeliveries from 'views/admin/dashboard/customer-support/deliveries'
import CustomerSupportIssues from 'views/admin/dashboard/customer-support/issues'
import CustomerSupportNotes from 'views/admin/dashboard/customer-support/notes'
import CustomerSupportOverview from 'views/admin/dashboard/customer-support/overview'
import CustomerSupportSupplierOrders from 'views/admin/dashboard/customer-support/supplier-orders'
import CustomerSupportSupplierOrdersAwaitingFORM from 'views/admin/dashboard/customer-support/supplier-orders-awaiting-form'
import CustomerSupportSupplierOrdersAwaitingNobilia from 'views/admin/dashboard/customer-support/supplier-orders-awaiting-nobilia'
import CustomerSupportTasks from 'views/admin/dashboard/customer-support/tasks'
import DashboardIndex from 'views/admin/dashboard/index'
import NobiliaOrderProcessor from 'views/admin/dashboard/nobilia-order-processor'
import NobiliaOrderProcessorOrders from 'views/admin/dashboard/nobilia-order-processor/orders'
import NobiliaOrderProcessorOrdersAckApproved from 'views/admin/dashboard/nobilia-order-processor/orders-ack-approved'
import NobiliaOrderProcessorOrdersAckReceived from 'views/admin/dashboard/nobilia-order-processor/orders-ack-received'
import NobiliaOrderProcessorOrdersLate from 'views/admin/dashboard/nobilia-order-processor/orders-late'
import NobiliaOrderProcessorOrdersNoAckYet from 'views/admin/dashboard/nobilia-order-processor/orders-no-ack-yet'
import NobiliaOrderProcessorOrdersOnTrack from 'views/admin/dashboard/nobilia-order-processor/orders-ontrack'
import NobiliaZohoBulkImport from 'views/admin/dashboard/nobilia-order-processor/zoho-bulk-import'
import DashboardRedirect from 'views/admin/dashboard/redirect'
import RedirectUser from 'views/admin/dashboard/redirect-user'
import SalesDesigner from 'views/admin/dashboard/sales-designer'
import SalesDesignerDesignsAwaitingSignoff from 'views/admin/dashboard/sales-designer/designs-awaiting-signoff'
import SalesDesignerDesignsRendering from 'views/admin/dashboard/sales-designer/designs-rendering'
import SalesDesignerNotes from 'views/admin/dashboard/sales-designer/notes'
import SalesDesignerOrders from 'views/admin/dashboard/sales-designer/orders'
import SalesDesignerOverview from 'views/admin/dashboard/sales-designer/overview'
import SalesDesignerProjects from 'views/admin/dashboard/sales-designer/projects'
import SalesDesignerProspects from 'views/admin/dashboard/sales-designer/prospects'
import SalesDesignerTasks from 'views/admin/dashboard/sales-designer/tasks'
import SelectionCenterManagerIndex from 'views/admin/dashboard/selection-center-manager'
import SelectionCenterManagerLinks from 'views/admin/dashboard/selection-center-manager/links'
import SelectionCenterManagerNotes from 'views/admin/dashboard/selection-center-manager/notes'
import TechDesigner from 'views/admin/dashboard/tech-designer'
import TechDesignerDesignsApprovedUnsent from 'views/admin/dashboard/tech-designer/designs-approved-unsent'
import TechDesignerDesignsAwaitingFeedback from 'views/admin/dashboard/tech-designer/designs-awaiting-feedback'
import TechDesignerNotes from 'views/admin/dashboard/tech-designer/notes'
import TechDesignerOverview from 'views/admin/dashboard/tech-designer/overview'
import TechDesignerProjects from 'views/admin/dashboard/tech-designer/projects'
import TechDesignerRoomsAwaitingPack from 'views/admin/dashboard/tech-designer/rooms-awaiting-pack'
import TechDesignerSupplierOrdersAwaitingFORM from 'views/admin/dashboard/tech-designer/supplier-orders-awaiting-form'
import TechDesignerSupplierOrdersAwaitingNobilia from 'views/admin/dashboard/tech-designer/supplier-orders-awaiting-nobilia'
import TechDesignerSupplierOrdersEDINotSent from 'views/admin/dashboard/tech-designer/supplier-orders-edi-not-sent'
import TechDesignerTasks from 'views/admin/dashboard/tech-designer/tasks'
import TechDesignerSupport from 'views/admin/dashboard/tech-designer-support'
import TechDesignerSupportDeliveries from 'views/admin/dashboard/tech-designer-support/deliveries'
import TechDesignerSupportIssues from 'views/admin/dashboard/tech-designer-support/issues'
import TechDesignerSupportNotes from 'views/admin/dashboard/tech-designer-support/notes'
import TechDesignerSupportOverview from 'views/admin/dashboard/tech-designer-support/overview'
import TechDesignerSupportProjects from 'views/admin/dashboard/tech-designer-support/projects'
import TechDesignerSupportSupplierOrders from 'views/admin/dashboard/tech-designer-support/supplier-orders'
import TechDesignerSupportSupplierOrdersAwaitingFORM from 'views/admin/dashboard/tech-designer-support/supplier-orders-awaiting-form'
import TechDesignerSupportSupplierOrdersAwaitingNobilia from 'views/admin/dashboard/tech-designer-support/supplier-orders-awaiting-nobilia'
import TechDesignerSupportSupplierOrdersEDINotSent from 'views/admin/dashboard/tech-designer-support/supplier-orders-edi-not-sent'
import TechDesignerSupportTasks from 'views/admin/dashboard/tech-designer-support/tasks'
import TradeDesigner from 'views/admin/dashboard/trade-designer'
import TradeDesignerDesignsAwaitingSignoff from 'views/admin/dashboard/trade-designer/designs-awaiting-signoff'
import TradeDesignerNotes from 'views/admin/dashboard/trade-designer/notes'
import TradeDesignerSampleOrders from 'views/admin/dashboard/trade-designer/orders-samples'
import TradeDesignerOverview from 'views/admin/dashboard/trade-designer/overview'
import TradeDesignerProjects from 'views/admin/dashboard/trade-designer/projects'
import TradeDesignerProspects from 'views/admin/dashboard/trade-designer/prospects'
import TradeDesignerTasks from 'views/admin/dashboard/trade-designer/tasks'
import DeliveriesGlobal from 'views/admin/deliveries'
import DesignGlobal from 'views/admin/design'
import DesignAdvisoryCodes from 'views/admin/design-advisory-code-groups'
import DesignsGlobal from 'views/admin/designs'
import Index from 'views/admin/index'
import LeadTimes from 'views/admin/lead-times'
import MessageTemplateBooks from 'views/admin/message-template-books'
import OrdersCabinetryGlobal from 'views/admin/orders-cabinetry'
import OrdersSamplesGlobal from 'views/admin/orders-samples'
import PlannerDesign from 'views/admin/planner-design'
import PlannerRoom from 'views/admin/planner-room'
import Profile from 'views/admin/profile'
import Project from 'views/admin/project'
import Activity from 'views/admin/project/activity'
import Addresses from 'views/admin/project/addresses'
import BuildZoom from 'views/admin/project/buildzoom'
import CustomImages from 'views/admin/project/custom-images'
import CustomerSettings from 'views/admin/project/customer-settings'
import DeliveryCreateUpdate from 'views/admin/project/delivery/create-update'
import DeliveryIndex from 'views/admin/project/delivery/index'
import DeliveriesIndex from 'views/admin/project/delivery/index-many'
import DeliveriesViewMany from 'views/admin/project/delivery/view-many'
import Guides from 'views/admin/project/guides'
import InitialCosting from 'views/admin/project/initial-costing'
import InstallationPhotos from 'views/admin/project/installation-photos'
import IssueCreateUpdate from 'views/admin/project/issue/create-update'
import IssueIndex from 'views/admin/project/issue/index'
import IssuesIndex from 'views/admin/project/issue/index-many'
import IssuesViewMany from 'views/admin/project/issue/view-many'
import MeetingsIndex from 'views/admin/project/meeting/index-many'
import MeetingsViewMany from 'views/admin/project/meeting/view-many'
import Messages from 'views/admin/project/messages'
import Notes from 'views/admin/project/notes'
import OrderCreateUpdate from 'views/admin/project/order-form/create-update'
import OrderIndex from 'views/admin/project/order-form/index'
import OrdersIndex from 'views/admin/project/order-form/index-many'
import OrdersViewMany from 'views/admin/project/order-form/view-many'
import OrderSamplesCreateUpdate from 'views/admin/project/order-samples/create-update'
import OrderSamplesIndex from 'views/admin/project/order-samples/index'
import OrdersSamplesIndex from 'views/admin/project/order-samples/index-many'
import OrdersSamplesViewMany from 'views/admin/project/order-samples/view-many'
import Overview from 'views/admin/project/overview/index'
import PaymentsIndex from 'views/admin/project/payment/index-many'
import PaymentsViewMany from 'views/admin/project/payment/view-many'
import Payouts from 'views/admin/project/payout/index'
import ProjectFile from 'views/admin/project/project-file'
import ProjectProposal from 'views/admin/project/project-proposal'
import Rooms from 'views/admin/project/room'
import Appliances from 'views/admin/project/room/appliances'
import DesignIndex from 'views/admin/project/room/design'
import DesignCreateUpdate from 'views/admin/project/room/design/create-update'
import Designs from 'views/admin/project/room/design/index-many'
import DesignPlan from 'views/admin/project/room/design/plan'
import DesignPlanExternal from 'views/admin/project/room/design/plan/index-external'
import DesignPlanInternal from 'views/admin/project/room/design/plan/index-internal'
import DesignBrief from 'views/admin/project/room/design-brief'
import InspirationPhotos from 'views/admin/project/room/inspiration-images'
import Measurements from 'views/admin/project/room/measurements'
import MeasurementsFile from 'views/admin/project/room/measurements/file'
import Packs from 'views/admin/project/room/packs'
import Photos from 'views/admin/project/room/photos'
import Plans from 'views/admin/project/room/plans'
import ProductionFiles from 'views/admin/project/room/production-files'
import SupplierOrderCreateUpdate from 'views/admin/project/supplier-order/create-update'
import SupplierOrderIndex from 'views/admin/project/supplier-order/index'
import SupplierOrderIndexMany from 'views/admin/project/supplier-order/index-many'
import SupplierOrderViewMany from 'views/admin/project/supplier-order/view-many'
import Tasks from 'views/admin/project/tasks'
import ProjectBuildZoom from 'views/admin/project-buildzoom'
import File from 'views/admin/project-file'
import Projects from 'views/admin/projects'
import ProjectsBuildZoom from 'views/admin/projects-buildzoom'
import ProspectCallOutcome from 'views/admin/prospect/call-outcome'
import ProspectCompany from 'views/admin/prospect/company'
import ProspectFloorPlan from 'views/admin/prospect/floor-plan'
import Prospect from 'views/admin/prospect/index'
import ProspectMessageTemplates from 'views/admin/prospect/message-templates'
import ProspectOverview from 'views/admin/prospect/overview'
import ProspectRedirect from 'views/admin/prospect/redirect'
import ProspectResultsEstimate from 'views/admin/prospect/results-estimate'
import ProspectResultsQuiz from 'views/admin/prospect/results-quiz'
import Prospects from 'views/admin/prospects'
import RoomsPacksGlobal from 'views/admin/rooms-packs'
import RoomsPlannerTestGlobal from 'views/admin/rooms-planner-test'
import Routes from 'views/admin/routes'
import SelectionCenters from 'views/admin/selection-centers'
import Shipments from 'views/admin/shipments'
import StaffQueue from 'views/admin/staff-queue'
import SalesDesignerProjectsQueue from 'views/admin/staff-queue/sales-designer-projects'
import TechDesignerProjectsQueue from 'views/admin/staff-queue/tech-designer-projects'
import TechDesignerSupportProjectsQueue from 'views/admin/staff-queue/tech-designer-support-projects'
import TradeDesignerProjectsQueue from 'views/admin/staff-queue/trade-designer-projects'
import SupplierOrdersGlobal from 'views/admin/supplier-orders'
import SupplierOrdersPlanning from 'views/admin/supplier-orders-planning'
import User from 'views/admin/user'
import Users from 'views/admin/users'

const Admin = () => {
  return (
    <StyledContent id="adminRoot">
      <SemanticUIFull />
      <ErrorBoundary>
        <Router>
          <Index path="/admin">
            <CatalogIndex path="catalog/:catalog_id">
              <Catalog path="/" />
              <AppliancePackIndexMany path="appliance-packs">
                <AppliancePackViewMany default />
                <AppliancePackIndex path=":appliance_pack_id">
                  <AppliancePackView default />
                </AppliancePackIndex>
              </AppliancePackIndexMany>
              <CatalogFeatureIndexMany path="catalog-features">
                <CatalogFeatureViewMany default />
                <CatalogFeatureIndex path=":catalog_feature_id">
                  <CatalogFeatureView default />
                </CatalogFeatureIndex>
              </CatalogFeatureIndexMany>
              <DisconnectedOptionIndexMany path="disconnected-features/:disconnected_feature_id">
                <DisconnectedOptionViewMany default />
              </DisconnectedOptionIndexMany>
              <GenericOptionIndexMany path="generic-features/:generic_feature_id">
                <GenericOptionViewMany default />
                <GenericOptionIndex path="generic-options/:generic_option_id">
                  <GenericOptionView default />
                </GenericOptionIndex>
              </GenericOptionIndexMany>
              <GenericProductClassIndexMany path="generic-product-classes">
                <GenericProductClassViewMany default />
                <GenericProductClassIndex path=":generic_product_class_id">
                  <GenericProductClassView default />
                </GenericProductClassIndex>
              </GenericProductClassIndexMany>
              <NobiliaFeatureBrowser path="nobilia-features/:feature_no/browse" />
              <NobiliaOptionIndexMany path="nobilia-features/:nobilia_feature_id">
                <NobiliaOptionViewMany default />
                <NobiliaOptionIndex path="nobilia-options/:nobilia_option_id">
                  <NobiliaOptionView default />
                </NobiliaOptionIndex>
              </NobiliaOptionIndexMany>
              <NobiliaProductClassIndexMany path="nobilia-product-classes">
                <NobiliaProductClassViewMany default />
                <NobiliaProductClassIndex path=":nobilia_product_class_id">
                  <NobiliaProductClassView default />
                </NobiliaProductClassIndex>
              </NobiliaProductClassIndexMany>
              <RenderPropIndexMany path="render-props">
                <RenderPropViewMany default />
              </RenderPropIndexMany>
              <RoomElementOptionIndexMany path="room-element-features/:room_element_feature_id">
                <RoomElementOptionViewMany default />
                <RoomElementOptionIndex path="room-element-options/:room_element_option_id">
                  <RoomElementOptionView default />
                </RoomElementOptionIndex>
              </RoomElementOptionIndexMany>
              <RoomElementClassIndexMany path="room-element-classes">
                <RoomElementClassViewMany default />
                <RoomElementClassIndex path=":room_element_class_id">
                  <RoomElementClassView default />
                </RoomElementClassIndex>
              </RoomElementClassIndexMany>
              <NobiliaSampleClassIndexMany path="nobilia-sample-classes">
                <NobiliaSampleClassViewMany default />
              </NobiliaSampleClassIndexMany>
            </CatalogIndex>
            <Companies path="companies" />
            <Company path="companies/:company_id" />
            <DashboardIndex path="dashboard/:user_id">
              <AccountExecutive path="account-executive">
                <AccountExecutiveOverview default />
                <AccountExecutiveNotes path="notes" />
                <AccountExecutiveProspects path="prospects" />
              </AccountExecutive>
              <CustomerSupport path="customer-support">
                <CustomerSupportOverview default />
                <CustomerSupportNotes path="notes" />
                <CustomerSupportTasks path="tasks" />
                <CustomerSupportSupplierOrders path="supplier-orders" />
                <CustomerSupportSupplierOrdersAwaitingNobilia path="supplier-orders-awaiting-nobilia" />
                <CustomerSupportSupplierOrdersAwaitingFORM path="supplier-orders-awaiting-form" />
                <CustomerSupportDeliveries path="deliveries" />
                <CustomerSupportIssues path="issues" />
                <CustomerSupportConversations path="conversations" />
              </CustomerSupport>
              <NobiliaOrderProcessor path="nobilia-order-processor">
                <NobiliaOrderProcessorOrders default />
                <NobiliaOrderProcessorOrdersLate path="orders-late" />
                <NobiliaOrderProcessorOrdersOnTrack path="orders-ontrack" />
                <NobiliaOrderProcessorOrdersAckApproved path="orders-ack-approved" />
                <NobiliaOrderProcessorOrdersAckReceived path="orders-ack-received" />
                <NobiliaOrderProcessorOrdersNoAckYet path="orders-no-ack-yet" />
                <NobiliaZohoBulkImport path="bulk-import" />
              </NobiliaOrderProcessor>
              <SalesDesigner path="sales-designer">
                <SalesDesignerOverview default />
                <SalesDesignerNotes path="notes" />
                <SalesDesignerTasks path="tasks" />
                <SalesDesignerDesignsAwaitingSignoff path="designs-awaiting-signoff" />
                <SalesDesignerDesignsRendering path="designs-rendering" />
                <SalesDesignerOrders path="orders" />
                <SalesDesignerProjects path="projects" />
                <SalesDesignerProspects path="prospects" />
              </SalesDesigner>
              <SelectionCenterManagerIndex path="selection-center-manager">
                <SelectionCenterManagerNotes default />
                <SelectionCenterManagerLinks path="links" />
              </SelectionCenterManagerIndex>
              <TechDesigner path="tech-designer">
                <TechDesignerOverview default />
                <TechDesignerNotes path="notes" />
                <TechDesignerTasks path="tasks" />
                <TechDesignerDesignsAwaitingFeedback path="designs-awaiting-feedback" />
                <TechDesignerDesignsApprovedUnsent path="designs-approved-unsent" />
                <TechDesignerProjects path="projects" />
                <TechDesignerRoomsAwaitingPack path="rooms-awaiting-pack" />
                <TechDesignerSupplierOrdersEDINotSent path="supplier-orders-edi-not-sent" />
                <TechDesignerSupplierOrdersAwaitingNobilia path="supplier-orders-awaiting-nobilia" />
                <TechDesignerSupplierOrdersAwaitingFORM path="supplier-orders-awaiting-form" />
              </TechDesigner>
              <TechDesignerSupport path="tech-designer-support">
                <TechDesignerSupportOverview default />
                <TechDesignerSupportProjects path="projects" />
                <TechDesignerSupportNotes path="notes" />
                <TechDesignerSupportTasks path="tasks" />
                <TechDesignerSupportIssues path="issues" />
                <TechDesignerSupportSupplierOrders path="supplier-orders" />
                <TechDesignerSupportSupplierOrdersEDINotSent path="supplier-orders-edi-not-sent" />
                <TechDesignerSupportSupplierOrdersAwaitingNobilia path="supplier-orders-awaiting-nobilia" />
                <TechDesignerSupportSupplierOrdersAwaitingFORM path="supplier-orders-awaiting-form" />
                <TechDesignerSupportDeliveries path="deliveries" />
              </TechDesignerSupport>
              <TradeDesigner path="trade-designer">
                <TradeDesignerOverview default />
                <TradeDesignerNotes path="notes" />
                <TradeDesignerTasks path="tasks" />
                <TradeDesignerDesignsAwaitingSignoff path="designs-awaiting-signoff" />
                <TradeDesignerSampleOrders path="sample-orders" />
                <TradeDesignerProjects path="projects" />
                <TradeDesignerProspects path="prospects" />
              </TradeDesigner>
              <RedirectUser default />
            </DashboardIndex>
            <DashboardRedirect path="dashboard" />
            <DeliveriesGlobal path="deliveries" />
            <DesignAdvisoryCodes path="design-advisory-codes" />
            <StaffQueue path="staff-queue">
              <SalesDesignerProjectsQueue path="sales-designer-projects" />
              <TechDesignerProjectsQueue path="tech-designer-projects" />
              <TechDesignerSupportProjectsQueue path="tech-designer-support-projects" />
              <TradeDesignerProjectsQueue path="trade-designer-projects" />
            </StaffQueue>
            <DesignGlobal path="designs/:design_id" />
            <DesignsGlobal path="designs" />
            <File path="files/:file_id" />
            <LeadTimes path="lead-times" />
            <Media path="media" />
            <MessageTemplateBooks path="message-template-books" />
            <OrdersCabinetryGlobal path="orders-cabinetry" />
            <OrdersSamplesGlobal path="orders-samples" />
            <RoomsPacksGlobal path="packs" />
            <PlannerDesign path="rooms/:room_id/designs/:design_id" />
            <PlannerRoom path="rooms/:room_id" />
            <Profile path="profile" />
            <Project path="projects/:project_id">
              <Addresses path="addresses" />
              <Activity path="activity" />
              <BuildZoom path="buildzoom" />
              <CustomImages path="custom-images" />
              <CustomerSettings path="customer-settings" />
              <DeliveriesIndex path="deliveries">
                <DeliveriesViewMany default />
                <DeliveryIndex path=":delivery_id">
                  <DeliveryCreateUpdate default />
                </DeliveryIndex>
              </DeliveriesIndex>
              <Guides path="guides" />
              <InitialCosting path="initial-costing" />
              <InstallationPhotos path="installation-photos" />
              <IssuesIndex path="issues">
                <IssuesViewMany default />
                <IssueIndex path=":issue_id">
                  <IssueCreateUpdate default />
                </IssueIndex>
              </IssuesIndex>
              <Notes path="notes" />
              <MeetingsIndex path="meetings">
                <MeetingsViewMany default />
              </MeetingsIndex>
              <Messages path="messages" />
              <OrdersIndex path="orders">
                <OrdersViewMany default />
                <OrderIndex path=":order_id">
                  <OrderCreateUpdate default />
                </OrderIndex>
              </OrdersIndex>
              <Overview path="overview" />
              <PaymentsIndex path="payments">
                <PaymentsViewMany default />
              </PaymentsIndex>
              <Payouts path="payouts" />
              <ProjectFile path="files/:file_id" />
              <ProjectProposal path="project-proposal" />
              <Rooms path="rooms/:room_id">
                <Appliances path="appliances" />
                <DesignBrief path="design-brief" />
                <DesignIndex path="designs/:design_id">
                  <DesignPlan path="plan/:file_id" />
                  <DesignPlanExternal path="plan-external/:file_id" />
                  <DesignPlanInternal path="plan-internal/:file_id" />
                  <DesignCreateUpdate default />
                </DesignIndex>
                <Designs path="designs" />
                <InspirationPhotos path="inspiration-images" />
                <MeasurementsFile path="measurements/:file_id" />
                <Measurements path="measurements" />
                <Packs path="packs" />
                <Photos path="photos" />
                <Plans path="plans" />
                <ProductionFiles path="production-files" />
              </Rooms>
              <OrdersSamplesIndex path="sample-orders">
                <OrdersSamplesViewMany default />
                <OrderSamplesIndex path=":order_id">
                  <OrderSamplesCreateUpdate default />
                </OrderSamplesIndex>
              </OrdersSamplesIndex>
              <SupplierOrderIndexMany path="supplier-orders">
                <SupplierOrderViewMany default />
                <SupplierOrderIndex path=":supplier_order_id">
                  <SupplierOrderCreateUpdate default />
                </SupplierOrderIndex>
              </SupplierOrderIndexMany>
              <Tasks path="tasks" />
            </Project>
            <ProjectBuildZoom path="projects-buildzoom/:project_id" />
            <ProjectsBuildZoom path="projects-buildzoom" />
            <Projects path="projects" />
            <Prospect path="prospects/:prospect_id">
              <ProspectOverview path="overview" />
              <ProspectCompany path="company" />
              <ProspectResultsQuiz path="quiz" />
              <ProspectResultsEstimate path="estimate" />
              <ProspectFloorPlan path="floor-plan" />
              <ProspectCallOutcome path="call-outcome" />
              <ProspectMessageTemplates path="message-templates" />
              <ProspectRedirect default />
            </Prospect>
            <Prospects path="prospects" />
            <RoomsPlannerTestGlobal path="rooms-planner-test" />
            <Routes path="routes" />
            <SelectionCenters path="selection-centers" />
            <Shipments path="shipments" />
            <SupplierOrdersPlanning path="supplier-orders-planning" />
            <SupplierOrdersGlobal path="supplier-orders" />
            <Users path="users" />
            <User path="users/:user_id" />
          </Index>
        </Router>
      </ErrorBoundary>
    </StyledContent>
  )
}

export default withAuthenticationRequired(Admin)

export const Head = () => (
  <>
    <meta name="viewport" content="width=1200"></meta>
    <SEO title="Admin" description="Form admin application" />
  </>
)
