import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  Text,
  View,
  Link,
} from '@react-pdf/renderer'
import { Button } from 'semantic-ui-react'

import { getAddressStringAbbrState } from 'context/address/utils'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import { GenericProductGraphQL } from 'types/generic-product'
import { getNameForUser } from 'views/utils'

import { appliancesStyles } from './styles'

const AppliancesPDFDownloadButton = () => {
  const [ready, setReady] = useState(false)
  const { project, primaryOwner } = useProject()
  const { appliancesUnarchived } = useRoom()
  const ownerName = getNameForUser(primaryOwner)
  const fileName = `${ownerName} Appliances Specs.pdf`
  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 2000)
  }, [appliancesUnarchived])

  return (
    <Button disabled={!ready || !appliancesUnarchived.length} floated="right">
      <PDFDownloadLink
        style={{ color: 'black' }}
        document={
          <AppliancesPDF
            addressFormatted={getAddressStringAbbrState(project.projectAddress)}
            appliances={appliancesUnarchived}
            primaryOwnerName={getNameForUser(primaryOwner)}
          />
        }
        fileName={`${fileName}`}
      >
        <FontAwesomeIcon
          icon={['fal', 'file-arrow-down']}
          style={{ marginRight: '10px' }}
        />
        Download PDF
      </PDFDownloadLink>
    </Button>
  )
}

export default AppliancesPDFDownloadButton

const AppliancesPDF = ({
  addressFormatted,
  appliances,
  primaryOwnerName,
}: {
  addressFormatted: string
  appliances: GenericProductGraphQL[]
  primaryOwnerName: string
}) => {
  return (
    <Document>
      <Page style={[appliancesStyles.body]} size="A4" wrap>
        <View style={[appliancesStyles.container]}>
          <Image
            style={{ width: 100, height: 20, marginBottom: 20 }}
            src={`${process.env.GATSBY_CDN_ROOT}/images/admin/form-logo.png`}
          />
          <Text
            style={[
              appliancesStyles.headerText,
              appliancesStyles.title,
              { marginBottom: 10 },
            ]}
          >
            Appliances specs
          </Text>
          <Text style={[appliancesStyles.headerText, { marginBottom: 5 }]}>
            Project Owner: {primaryOwnerName}
          </Text>
          <Text
            style={[
              appliancesStyles.headerText,
              { marginBottom: 20, lineHeight: 0.8 },
            ]}
          >
            Project Address:{''}
            {addressFormatted}
          </Text>
        </View>
        <View style={[appliancesStyles.border]}>
          <View style={[appliancesStyles.header, appliancesStyles.row]}>
            <Text
              style={[
                appliancesStyles.header,
                appliancesStyles.table,
                { width: 100, textAlign: 'left' },
              ]}
            >
              Appliance
            </Text>
            <Text
              style={[
                appliancesStyles.header,
                appliancesStyles.table,
                { width: 220, textAlign: 'left' },
              ]}
            >
              Make / Model{' '}
            </Text>
            <Text
              style={[
                appliancesStyles.link,
                appliancesStyles.header,
                appliancesStyles.table,
                { width: 20, textAlign: 'left' },
              ]}
            >
              Link{' '}
            </Text>
            <Text
              style={[
                appliancesStyles.link,
                appliancesStyles.header,
                appliancesStyles.table,
                { width: 60, textAlign: 'left' },
              ]}
            >
              Link to Spec{' '}
            </Text>
          </View>
          {appliances.map((a, key) => (
            <AppliancePDFItem item={a} key={key} />
          ))}
        </View>
      </Page>
    </Document>
  )
}

const AppliancePDFItem = ({ item }: { item: GenericProductGraphQL }) => {
  const description = item.productClass.metadata?.name || ''
  const makeModel = item.data?.productDetails || ''
  const link = item.data?.linkToProduct || ''
  const linkSpec = item.data?.linkToProductSpec || ''
  return (
    <View
      style={[
        appliancesStyles.rowTable,
        appliancesStyles.borderTop,
        appliancesStyles.view,
      ]}
    >
      <Text
        style={[
          appliancesStyles.headerText,
          appliancesStyles.table,
          { width: '100', textAlign: 'left' },
        ]}
      >
        {description}
      </Text>
      <Text
        style={[
          appliancesStyles.headerText,
          appliancesStyles.table,
          { width: '220', textAlign: 'left' },
        ]}
      >
        {makeModel}
      </Text>
      <Text
        style={[
          appliancesStyles.link,
          appliancesStyles.headerText,
          appliancesStyles.table,
          { color: 'blue', width: '20', textAlign: 'left' },
        ]}
      >
        <Link src={link}>Link</Link>
      </Text>
      <Text
        style={[
          appliancesStyles.link,
          appliancesStyles.headerText,
          appliancesStyles.table,
          { color: 'blue', width: '60', textAlign: 'left' },
        ]}
      >
        <Link src={linkSpec}>Link</Link>
      </Text>
    </View>
  )
}
