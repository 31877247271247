import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_APPLIANCE_PACK,
  FindUniqueAppliancePackPayload,
  FindUniqueAppliancePackVariables,
} from 'queries/appliance-pack'
import { AppliancePack } from 'types/appliance-pack'

interface IAppliancePackContext {
  appliancePack: AppliancePack
  refetch: () => Promise<unknown>
}

interface AppliancePackProps
  extends RouteComponentProps<{ appliance_pack_id: string }> {
  children: React.ReactNode
}

export const AppliancePackContext = createContext<IAppliancePackContext>(
  {} as IAppliancePackContext,
)

const AppliancePackProvider = (props: AppliancePackProps) => {
  const { data, loading, refetch } = useQuery<
    FindUniqueAppliancePackPayload,
    FindUniqueAppliancePackVariables
  >(FIND_UNIQUE_APPLIANCE_PACK, {
    variables: {
      where: { id: props.appliance_pack_id as string },
    },
  })

  if (loading || !data) return <Loader />

  return (
    <AppliancePackContext.Provider
      value={{
        appliancePack: data.appliancePack,
        refetch,
      }}
    >
      {props.children}
    </AppliancePackContext.Provider>
  )
}

export default AppliancePackProvider
