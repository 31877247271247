import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useCustomerSupport from 'context/dashboard/customer-support/use'
import useConversationCount from 'context/project/conversation/use-count'
import useCommentNoteCount from 'context/project/project-notes/use-count'
import { ScrollBarStyle } from 'styles/app/system'

const MenuCustomerSupport = () => {
  const { pathname } = useLocation()
  const {
    issuesUnresolvedCount,
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
    tasksToDo,
    userId,
  } = useCustomerSupport()
  const { count: newForNotesCount } = useCommentNoteCount({
    newFor: { id: userId },
  })

  const { count: conversationCount } = useConversationCount({
    clientUser: 'notAssigned',
  })

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="overview"
        active={pathname === `/admin/dashboard/${userId}/customer-support/`}
        onClick={() => navigate(`/admin/dashboard/${userId}/customer-support`)}
      />
      <Menu.Item
        name="tasks"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/tasks`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/customer-support/tasks`)
        }
      >
        Tasks
        {tasksToDo.length ? (
          <Label className="count">{tasksToDo.length}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="notes"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/notes`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/customer-support/notes`)
        }
      >
        Notes
        {!!newForNotesCount && (
          <Label className="count">{newForNotesCount}</Label>
        )}
      </Menu.Item>
      <Menu.Item
        name="issues"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/issues`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/customer-support/issues`)
        }
      >
        Issues
        {issuesUnresolvedCount ? (
          <Label className="count">{issuesUnresolvedCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="supplier-orders"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/supplier-orders`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/customer-support/supplier-orders`,
          )
        }
      >
        Supplier Orders
      </Menu.Item>
      <Menu.Item
        name="remedialsAwaitingNobilia"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/supplier-orders-awaiting-nobilia`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/customer-support/supplier-orders-awaiting-nobilia`,
          )
        }
      >
        S.O. Awaiting Nobilia
        {addOnRemedialsAwaitingNobiliaCount ? (
          <Label className="count">{addOnRemedialsAwaitingNobiliaCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="remedialsAwaitingFORM"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/supplier-orders-awaiting-form`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/customer-support/supplier-orders-awaiting-form`,
          )
        }
      >
        S.O. Awaiting FORM
        {addOnRemedialsAwaitingFORMCount ? (
          <Label className="count">{addOnRemedialsAwaitingFORMCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="deliveries"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/delivieries`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/customer-support/deliveries`)
        }
      >
        Deliveries
      </Menu.Item>
      <Menu.Item
        name="conversations"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/customer-support/conversations`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/customer-support/conversations`)
        }
      >
        Conversations
        {!!conversationCount && (
          <Label className="count">{conversationCount}</Label>
        )}
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuCustomerSupport

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
