import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import OrderTab from 'components/admin/project/order-form/tab'
import useOrder from 'context/order/use'
import useProjectOrderFORMMany from 'context/project/order-form/use-many'
import useProject from 'context/project/use'

const OrderCreateUpdate = (
  _: RouteComponentProps<{ project_id: string; order_id?: string }>,
) => {
  const { project, refetch: refetchProject } = useProject()
  const { order } = useOrder()
  const { refetch } = useProjectOrderFORMMany()

  return (
    <>
      <ProjectPageTitle viewName="Order" />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h5>{order ? 'Edit Order' : 'Create Order'}</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <OrderTab
              onComplete={(createdId?: string) => {
                // refetch table
                refetch()
                // refetch project
                refetchProject()
                if (createdId)
                  navigate(`/admin/projects/${project.id}/orders/${createdId}`)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default OrderCreateUpdate
