import { useContext } from 'react'

import { ProjectOrderFORMManyContext } from './provider-many'

const useProjectOrderFORMMany = () => {
  const { orders, refetch } = useContext(ProjectOrderFORMManyContext)

  return {
    orders,
    refetch,
  }
}

export default useProjectOrderFORMMany
