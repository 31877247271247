import React from 'react'

import { RouteComponentProps } from '@reach/router'

import GenericProductClassProvider from 'context/generic-product-class/provider'

interface GenericProductClassIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    generic_product_class_id: string
  }> {
  children: React.ReactNode
}

const GenericProductClassIndex = (props: GenericProductClassIndexProps) => {
  return (
    <GenericProductClassProvider
      catalog={props.catalog_id}
      generic_product_class_id={props.generic_product_class_id}
    >
      {props.children}
    </GenericProductClassProvider>
  )
}

export default GenericProductClassIndex
