import { Font, StyleSheet } from '@react-pdf/renderer'

import { Colors } from 'styles/app/system'

Font.register({
  family: 'GT America',
  fontWeight: 300,
  src: `${process.env.GATSBY_CDN_ROOT}/assets/GT-America-Standard-Light.woff2`,
})

Font.register({
  family: 'GT Super',
  fontWeight: 300,
  src: `${process.env.GATSBY_CDN_ROOT}/assets/GT-Super-Display-Light.woff2`,
})

export const appliancesStyles = StyleSheet.create({
  body: {
    color: Colors.black,
    fontFamily: 'GT America',
    fontSize: '11px',
    display: 'flex',
    paddingBottom: 65,
    paddingHorizontal: 35,
    paddingTop: 35,
  },
  border: {
    borderColor: '#CACBCD',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  borderTop: {
    borderTopColor: '#CACBCD',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
  },
  container: {
    display: 'flex',
    fontSize: 10,
    flexDirection: 'column',
    textAlign: 'left',
    width: 750,
  },
  link: {
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-start',

    textAlign: 'left',
  },
  header: {
    color: 'black',
    backgroundColor: '#eee',
    fontSize: 12,
    fontWeight: 200,
    textAlign: 'center',
  },
  headerText: {
    color: 'black',
    fontWeight: 200,
  },
  page: {
    flexDirection: 'column',
    padding: 25,
  },
  row: {
    alignContent: 'stretch',
    alignItems: 'stretch',
    display: 'flex',
    flexBasis: 35,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
  },
  rowTable: {
    alignContent: 'stretch',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 50,
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
  },
  table: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontSize: 10,
    flexDirection: 'column',
    justifyContent: 'center',

    flexWrap: 'nowrap',
    margin: 15,
    textAlign: 'left',
  },
  title: {
    fontSize: 24,
    fontFamily: 'GT Super',
    textAlign: 'left',
  },
  view: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
})
