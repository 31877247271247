import { DocumentNode, useQuery } from '@apollo/client'

import {
  FIND_MANY_PROJECT_FILE,
  FindManyProjectFilePayload,
  FindManyProjectFileVariables,
} from 'queries/project-file'

const useProjectFileManyNoContext = (opts?: {
  variables?: FindManyProjectFileVariables
  query?: DocumentNode
  skip?: boolean
}) => {
  const {
    data: { projectFiles } = { projectFiles: [] },
    loading,
    refetch,
  } = useQuery<FindManyProjectFilePayload, FindManyProjectFileVariables>(
    opts?.query || FIND_MANY_PROJECT_FILE,
    {
      skip: opts?.skip,
      variables: opts?.variables,
    },
  )

  return {
    loading,
    refetch,
    projectFiles,
  }
}

export default useProjectFileManyNoContext
