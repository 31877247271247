import React from 'react'

import { RouteComponentProps } from '@reach/router'

import GenericProductClassManyProvider from 'context/generic-product-class/provider-many'

interface GenericProductClassIndexManyProps
  extends RouteComponentProps<{ catalog_id: string }> {
  children: React.ReactNode
}

const GenericProductClassIndexMany = (
  props: GenericProductClassIndexManyProps,
) => {
  return (
    <GenericProductClassManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        sortBy: 'identifier',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </GenericProductClassManyProvider>
  )
}

export default GenericProductClassIndexMany
