import React, { useEffect, useRef } from 'react'

import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { CSSTransitionProps } from 'react-transition-group/CSSTransition'

import { Overlay, Panel, PanelPosition, PanelSize } from './styles'
import Button from '../button'

type OverlayPanelProps = {
  children: React.ReactNode
  closeOnEscape?: boolean
  closeOnOverlayClick?: boolean
  handleClose: () => void
  open: boolean
  panelRef?: (el: HTMLDivElement) => void
  position?: PanelPosition
  size?: PanelSize
} & Pick<
  CSSTransitionProps<HTMLDivElement>,
  | 'onEntering'
  | 'onEntered'
  | 'onExit'
  | 'onExiting'
  | 'onExited'
  | 'unmountOnExit'
>

const OverlayPanel = ({
  children,
  closeOnEscape = true,
  closeOnOverlayClick = true,
  handleClose,
  open,
  panelRef,
  position = 'right',
  size = 'sm',
  unmountOnExit = true,
  ...transitionProps
}: OverlayPanelProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const closeBtnRef = useRef<HTMLButtonElement>(null)

  const handleCloseOnEscape: React.KeyboardEventHandler = (e) => {
    if (e?.key?.toLowerCase() === 'escape') {
      handleClose()
    }
  }

  useEffect(() => {}, [closeBtnRef])

  return createPortal(
    <CSSTransition
      in={open}
      timeout={{ enter: 0, exit: 300 }}
      unmountOnExit={unmountOnExit}
      nodeRef={overlayRef}
      onEnter={() => {
        if (closeOnEscape) {
          // Focus overlay, so event listeners are active
          setTimeout(() => overlayRef.current?.focus(), 300)
        }
      }}
      {...transitionProps}
    >
      <Overlay
        onClick={closeOnOverlayClick ? handleClose : undefined}
        role="presentation"
        open={open}
        ref={overlayRef}
        position={position}
        tabIndex={0}
        onKeyDown={closeOnEscape ? handleCloseOnEscape : undefined}
      >
        <Panel
          ref={panelRef}
          position={position}
          size={size}
          open={open}
          onClick={(e) => e.stopPropagation()}
          className="panel"
        >
          <Button
            className="close-btn"
            kind="ghost"
            fontAwesomeIcon="close"
            color="blue"
            onClick={handleClose}
            ref={closeBtnRef}
          />
          {children}
        </Panel>
      </Overlay>
    </CSSTransition>,
    document.body,
  )
}

export default OverlayPanel
