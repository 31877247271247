import React from 'react'

import { RouteComponentProps } from '@reach/router'

import AccountExecutiveProvider from 'context/dashboard/account-executive/provider'

const AccountExecutiveIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  return (
    <AccountExecutiveProvider user_id={props.user_id ?? ''}>
      {props.children}
    </AccountExecutiveProvider>
  )
}

export default AccountExecutiveIndex
