import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'semantic-ui-react'

import ConfirmationModal from 'components/shared/confirmation-modal'

const DeleteItemModal = ({ onDelete }: { onDelete: () => void }) => {
  const [showModal, toggleModal] = useState(false)

  return (
    <>
      <Button
        icon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
        onClick={() => toggleModal(true)}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to delete it?"
        onCancel={() => toggleModal(false)}
        onConfirm={() => {
          onDelete()
          toggleModal(false)
        }}
        open={showModal}
      />
    </>
  )
}

export default DeleteItemModal
