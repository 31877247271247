// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const slashRe = new RegExp('/', 'g')
const escapedSlashRe = new RegExp('~1', 'g')
const tildeRe = /~/g
const escapedTildeRe = /~0/g

export function escape_(str: any) {
  if (typeof str === 'number') {
    return str.toString()
  }
  if (typeof str !== 'string') {
    throw 'param str (' + str + ') is not a string'
  }

  return str.replace(tildeRe, '~0').replace(slashRe, '~1')
}

export function unescape_(str: any) {
  if (typeof str == 'string') {
    return str.replace(escapedSlashRe, '/').replace(escapedTildeRe, '~')
  } else {
    return str
  }
}

export function concat(path: any, key: any) {
  return path + '/' + key
}
