import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import SelectionCentersIndexMany from 'components/admin/selection-centers/index-many'
import SelectionCentersProvider from 'context/selection-centers/provider'

const SelectionCenters = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="Selection Centers" />
        <Grid.Row>
          <Grid.Column>
            <SelectionCentersProvider
              defaultFilters={{
                take: 50,
              }}
            >
              <SelectionCentersIndexMany />
            </SelectionCentersProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default SelectionCenters
