import React from 'react'

import { Image, List } from 'semantic-ui-react'

import { parseStaff as parseProspectStaff } from 'context/prospect/utils'
import { TableUserListItem } from 'styles/admin/main'
import { Prospect } from 'types/prospect'
import { capsSnakeCaseToTitleCase, getShortNameForUser } from 'views/utils'

export const ProspectStaffCellContents = ({
  prospect,
}: {
  prospect?: Prospect | null
}) => {
  return (
    <List relaxed>
      {parseProspectStaff(prospect).allProspectStaff.map((u) => (
        <TableUserListItem key={u.staffMember?.id}>
          <Image src={u.staffMember?.picture} avatar circular />
          <List.Content>
            <p className="no-margin small">
              {getShortNameForUser(u.staffMember)}
            </p>
            <p className="no-margin x-small light-gray">
              {capsSnakeCaseToTitleCase(u.staffMemberRole)}
            </p>
          </List.Content>
        </TableUserListItem>
      ))}
    </List>
  )
}
