import React from 'react'

import AddCommentForm from 'components/shared/project/project-file/comment/form'
import CommentList from 'components/shared/project/project-file/comment/list'
import { BottomSection } from 'components/shared/project/project-file/utils'
import useProjectFile from 'context/project-file/use'
import { StatusMode } from 'types/utils'

interface SidebarCommentsExternalProps {
  sidebarToggle: () => void
}

const SidebarCommentsExternal = ({
  sidebarToggle,
}: SidebarCommentsExternalProps) => {
  const { creationMode, refetch, externalReviewMode } = useProjectFile()

  return (
    <>
      <h4>Comments</h4>
      <CommentList
        disabled={externalReviewMode === StatusMode.Submitted}
        isAdmin
        onClose={sidebarToggle}
      />
      {creationMode && <div style={{ padding: '85px 0' }} />}
      <BottomSection>
        <div className="content">
          <AddCommentForm onCommentCreated={() => refetch()} />
        </div>
      </BottomSection>
    </>
  )
}

export default SidebarCommentsExternal
