import { FindManyUserVariables } from 'queries/user'
import { UserType } from 'types/user'

export interface QueryFilters {
  userTypes?: UserType[]
  search?: string

  // sort values
  sortBy?: 'email'
  sortDirection?: 'ascending' | 'descending'

  // how many to take
  take: number
}

export const getQueryVariables = ({
  userTypes,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyUserVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'email': {
        return {
          email: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyUserVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (userTypes?.length) {
    return {
      ...variables,
      where: {
        ...variables.where,
        type: {
          in: userTypes,
        },
      },
    }
  }
  return variables
}
