import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  DELETE_ISSUE,
  DeleteIssuePayload,
  DeleteIssueVariables,
  UPDATE_ISSUE,
  UpdateIssuePayload,
  UpdateIssueVariables,
} from 'queries/issue'

import { ProjectIssueManyContext } from './provider-many'

const useProjectIssueManyMutate = () => {
  const { refetch } = useContext(ProjectIssueManyContext)
  const [updateIssueAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateIssuePayload,
    UpdateIssueVariables
  >(UPDATE_ISSUE, {
    onCompleted: refetch,
  })

  const [deleteIssueAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteIssuePayload,
    DeleteIssueVariables
  >(DELETE_ISSUE, {
    onCompleted: refetch,
  })

  return {
    deleteIssueAndRefetch,
    loadingDelete,
    loadingUpdate,
    updateIssueAndRefetch,
  }
}

export default useProjectIssueManyMutate
