import React from 'react'

import { isEqual } from 'lodash'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { getMediaDisplay } from 'context/media/utils-many'
import { MediaDiff, MediaUpdate } from 'types/media-diff'

import { ImageCard, StrikeThrough, StyledLabelDiffs } from './utils'

const parseDataFields = (mediaDiff: MediaDiff) => {
  const isUpdate = mediaDiff.type === 'update'
  const snapshotFrom = isUpdate
    ? (mediaDiff as MediaUpdate).data.from
    : mediaDiff.snapshot
  const snapshotTo = isUpdate
    ? (mediaDiff as MediaUpdate).data.to
    : mediaDiff.snapshot
  const out = [
    {
      fieldName: 'Name',
      fieldValue: snapshotTo.name,
      fieldValueChanged: snapshotFrom.name,
    },
    {
      fieldName: 'Category',
      fieldValue: snapshotTo.category,
      fieldValueChanged: snapshotFrom.category,
    },
    {
      fieldName: 'Key',
      fieldValue: snapshotTo.key,
      fieldValueChanged: snapshotFrom.key,
    },
    {
      fieldName: 'Special Tag',
      fieldValue: snapshotTo.data?.specialTag,
      fieldValueChanged: snapshotFrom.data?.specialTag,
    },
  ]
  return out.filter((o) => {
    if (!isUpdate) return true
    return !isEqual(o.fieldValue, o.fieldValueChanged)
  })
}

const MediaDiffComponent = ({ mediaDiff }: { mediaDiff: MediaDiff }) => {
  const missing = 'images/app/catalog_missing_file.png'
  const { display, isVideo, isOBJ, isGLB } = getMediaDisplay(
    mediaDiff.snapshot.key || missing,
  )
  let imageUrl = display
  if (isVideo || isOBJ || isGLB) imageUrl = getMediaDisplay(missing).display // need better image for this
  const fields = parseDataFields(mediaDiff)
  const isUpdate = mediaDiff.type === 'update'
  return (
    <>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={3}>
          <ImageCard image={imageUrl} />
        </Grid.Column>
        <Grid.Column width={11}>
          <StyledFields>
            {fields.map((f) => (
              <Grid.Row key={f.fieldName}>
                <Grid.Column width={4}>
                  <p className="caption">{f.fieldName} </p>
                </Grid.Column>
                <Grid.Column width={12}>
                  {isUpdate ? (
                    <StrikeThrough>{`${f.fieldValueChanged}`}</StrikeThrough>
                  ) : null}
                  <p>{`${f.fieldValue}`}</p>
                </Grid.Column>
              </Grid.Row>
            ))}
          </StyledFields>
        </Grid.Column>
        <Grid.Column width={2}>
          <StyledLabelDiffs type={mediaDiff.type} />
        </Grid.Column>
      </Grid.Row>
      <Divider />
    </>
  )
}

export default MediaDiffComponent

export const StyledFields = styled(Grid)`
  &&&&&& {
    div.row {
      padding: 0;
    }
  }
`
