import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from 'semantic-ui-react'

import RenderPropManyProvider from 'context/render-prop/provider-many'
import { QueryFilters } from 'context/render-prop/utils-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { RenderProp } from 'types/render-prop'

import RenderPropSelection from './selection'
import { Labels } from '../utils'

const RenderPropModal = ({
  defaultFilters,
  selectedRenderProps,
  openModal,
  onClose,
  oneSelection,
  onSave,
}: {
  defaultFilters?: QueryFilters
  selectedRenderProps?: RenderProp[]
  openModal?: boolean
  onClose?: () => void
  oneSelection?: boolean
  onSave: (rp: RenderProp[]) => void
}) => {
  const [renderProps, setRenderProps] = useState<RenderProp[]>(
    selectedRenderProps ?? [],
  )
  const [defaultFilters_] = useState<QueryFilters>(
    defaultFilters || {
      sortBy: 'groupIdentifier',
      sortDirection: 'ascending',
      take: 50,
    },
  )

  useEffect(
    () => setRenderProps(selectedRenderProps ?? []),
    [selectedRenderProps],
  )

  return (
    <Modal
      onClose={onClose}
      open={openModal}
      size="large"
      title="Choose render props"
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row verticalAlign="middle" style={{ maxHeight: '300px' }}>
            <Grid.Column
              textAlign="left"
              width={13}
              style={{ height: '100%', overflow: 'auto' }}
            >
              {renderProps.length ? (
                <Labels>
                  {renderProps.map((option) => (
                    <div key={option.id} className="selection">
                      <p>
                        {option.data.formCategory || option.data.category},{' '}
                        {option.data.formBrand || option.data.brand}
                      </p>
                      <FontAwesomeIcon
                        icon={['fal', 'times']}
                        onClick={() => {
                          if (renderProps.find((s) => s.id === option.id))
                            setRenderProps(
                              oneSelection
                                ? []
                                : renderProps.filter((s) => s.id !== option.id),
                            )
                          else
                            setRenderProps(
                              oneSelection
                                ? [option]
                                : [...renderProps, option],
                            )
                        }}
                      />
                    </div>
                  ))}
                </Labels>
              ) : null}
            </Grid.Column>
            <Grid.Column width={3}>
              <div className="flex-center" style={{ flexWrap: 'unset' }}>
                <Button
                  kind="outlined"
                  text="Close"
                  color="gray"
                  onClick={onClose}
                  style={{ marginRight: '20px' }}
                />
                <Button
                  kind="solid"
                  text="Save"
                  onClick={() => {
                    onSave(renderProps)
                    onClose?.()
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    >
      <RenderPropManyProvider defaultFilters={defaultFilters_} skipLoader>
        <RenderPropSelection
          oneSelection={oneSelection}
          selectedRenderProp={renderProps}
          setSelectedRenderProp={(rp) => setRenderProps(rp ?? [])}
        />
      </RenderPropManyProvider>
    </Modal>
  )
}

export default RenderPropModal
