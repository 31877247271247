import { isCustomer } from 'context/user/utils'
import { FindManyProjectEventVariables } from 'queries/project-event'
import { ProjectEvent } from 'types/project-event'
import { ProjectStaffRole } from 'types/project-staff'

export interface QueryFilters {
  // staff assigned
  assignedTo?: {
    id: string
  }
  assignedToRole?: ProjectStaffRole
  // new for
  newFor?: {
    id: string
    name?: string
  }
  projectId?: string
  showStaff?: boolean

  // how many to take
  take: number

  sortBy?: 'createdAt'
  sortDirection?: 'descending'
}

export const getQueryVariables = ({
  assignedTo,
  assignedToRole,
  newFor,
  projectId,
  showStaff,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyProjectEventVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'createdAt': {
        return {
          createdAt: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyProjectEventVariables = {
    orderBy,
    take,
  }
  if (assignedTo?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        project: {
          staff: {
            some: {
              staffMemberId: {
                equals: assignedTo.id,
              },
              isSubscribed: {
                equals: true,
              },
              ...(assignedToRole && {
                staffMemberRole: {
                  equals: assignedToRole,
                },
              }),
            },
          },
        },
      },
    }
  }
  if (newFor?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        newFor: {
          some: {
            id: {
              equals: newFor.id,
            },
          },
        },
      },
    }
  }
  if (projectId) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        project: {
          id: {
            equals: projectId,
          },
        },
      },
    }
  }
  if (showStaff) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        showStaff: {
          equals: showStaff,
        },
      },
    }
  }
  return variables
}

export const parseEvents = (events: ProjectEvent[]) => {
  return events.reduce<{
    customer: ProjectEvent[]
    internal: ProjectEvent[]
  }>(
    (acc, e) => {
      if (isCustomer(e.publisher?.type))
        return {
          ...acc,
          customer: [...acc.customer, e],
        }
      else
        return {
          ...acc,
          internal: [...acc.internal, e],
        }
    },
    {
      customer: [],
      internal: [],
    },
  )
}
