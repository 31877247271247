// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  max-width: 75%;
  overflow: hidden;
`

export const PlannerContainer = styled.div`
  flex-grow: 1;
  position: relative;
`

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  max-width: 25%;
  min-width: 350px;
  padding: 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  width: 100%;
`
