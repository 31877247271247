import React from 'react'

import { Divider } from 'semantic-ui-react'
import styled from 'styled-components'

import { Chip } from 'components/shared/chip'
import { formatDollars } from 'context/dollar-utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import { parsePaymentsByStatus } from 'context/payment/utils'
import { getRoomTypeWithDescription } from 'context/room/utils'
import { Colors } from 'styles/app/system'
import { Design } from 'types/design'
import { NobiliaSampleGraphQL } from 'types/nobilia-sample'
import { Order } from 'types/order'
import { Payment } from 'types/payment'
import { RoomGraphQL } from 'types/room'
import { PaymentItem } from 'types/utils'

interface OrderCardProps {
  order: Order | NullableRecord<Order> | null
}

const renderPaymentItem = ({
  className,
  item,
  key,
  room,
}: {
  className?: string
  item: PaymentItem | null
  key?: number
  room?: RoomGraphQL
}) => {
  return (
    <div key={key} className={`flex-container ${className}`}>
      <div className="flex" style={{ gap: 16 }}>
        <p>{item?.name}</p>
        {room ? (
          <Chip
            color={room.data.tagColor}
            name={getRoomTypeWithDescription(room)}
            noMargin
            opacity={0.3}
          />
        ) : null}
      </div>
      <p className="price">
        {item ? formatDollars(item.quantity * item.price) : null}
      </p>
    </div>
  )
}

const renderDesign = (design: Design, key: number) => {
  return renderPaymentItem({
    item: checkoutUtils.designToPaymentItem(design),
    key: key,
    className: 'bold',
  })
}

const renderNobiliaSample = (product: NobiliaSampleGraphQL, key: number) => {
  return renderPaymentItem({
    item: checkoutUtils.nobiliaSampleToPaymentItem(product),
    key: key,
    className: 'bold',
  })
}

const renderPaymentMade = (payment: Payment, key: number) => {
  return renderPaymentItem({
    item: checkoutUtils.madePaymentToPaymentItem(payment),
    key: key,
    className: 'disabled',
    room: payment.room,
  })
}

const renderDisabled = (name: string, price: number) => {
  return renderPaymentItem({
    item: {
      name,
      price: price,
      quantity: 1,
    },
    className: 'disabled',
  })
}

const renderActive = (name: string, price: number, className?: string) => {
  return renderPaymentItem({
    item: {
      name,
      price: price,
      quantity: 1,
    },
    className: className || 'box',
  })
}

const OrderCard = ({ order }: OrderCardProps) => {
  const designs = checkoutUtils.sortOrderDesignsByPrice(order)
  const nobiliaSamples = order?.nobiliaSamples || []
  const discounts = order?.discounts || []
  const payments = order?.payments || []
  const totalWithSucceededPayments =
    checkoutUtils.calculateAmountDueWithSucceededPayments(order)
  const total = checkoutUtils.calculateAmountDue(order)
  const taxes = checkoutUtils.calculateTaxes(order)

  const { pendingPayments, succeededPayments } = parsePaymentsByStatus(payments)

  return (
    <Card>
      <p>Form Order</p>
      <Divider />
      {designs.map(renderDesign)}
      {nobiliaSamples.map(renderNobiliaSample)}
      {discounts.map((d, i) =>
        renderPaymentItem({ item: d, key: i, className: 'discount' }),
      )}
      <Divider />
      {renderActive(
        'Total Before Tax',
        checkoutUtils.calculateTotalBeforeTaxes(order),
      )}
      {taxes ? renderDisabled('Estimated Tax', taxes) : null}
      {succeededPayments.map(renderPaymentMade)}
      {renderActive('Amount Due', totalWithSucceededPayments, 'green-box')}
      {pendingPayments.map(renderPaymentMade)}
      {pendingPayments.length
        ? renderActive('Amount After Pending', total, 'yellow-box')
        : null}
    </Card>
  )
}

export default OrderCard

const Card = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 16px;

  .flex-container {
    align-items: center;
    display: flex;
    margin: 16px 0;
    justify-content: space-between;

    p {
      margin: 0;
    }

    &.bold p {
      font-weight: 300;
    }

    &.discount {
      p {
        color: ${Colors.gray500};
      }
      p.price {
        color: #838d72;
      }
    }

    &.box {
      background: ${Colors.gray100};
      padding: 16px;
    }

    &.yellow-box {
      background: ${Colors.yellow50};
      padding: 16px;

      p {
        color: ${Colors.yellow900};
        font-weight: 300;
        font-size: 18px;
      }
    }

    &.green-box {
      background: ${Colors.green100};
      padding: 16px;

      p {
        color: #3c4233;
        font-weight: 300;
        font-size: 18px;
      }
    }

    &.disabled p {
      color: ${Colors.gray500};
    }
  }
`
