import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Card, Form, Grid, List } from 'semantic-ui-react'

import PersonListItem from 'components/admin/person-list-item'
import ConsultationCard from 'components/admin/prospect/consultation-card'
import ConsultationModal from 'components/admin/prospect/create-update-consultation'
import ProspectLayout from 'components/admin/prospect/layout'
import ProspectStaffCreateUpdateModal from 'components/admin/prospect/prospect-staff/create-update-modal'
import { ProspectStatusTable } from 'components/admin/prospect/utils'
import SaveModal from 'components/admin/save-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { notifySuccess } from 'context/notifications/trigger'
import useProspect from 'context/prospect/use'
import useProspectMutateNoContext from 'context/prospect/use-mutate-no-context'
import useProspectStaffMutate from 'context/prospect/use-prospect-staff-mutate'
import Button from 'design-system/components/button'
import { FormCheckbox } from 'styles/admin/main'
import { Prospect, ProspectData, ProspectType } from 'types/prospect'
import { ProspectStaff } from 'types/prospect-staff'
import { User } from 'types/user'
import * as viewUtils from 'views/utils'

import ProspectInfo from './info'

const Overview = (_: RouteComponentProps) => {
  const { meetingsNotCanceled, prospect, allProspectStaff, refetch } =
    useProspect()
  const { loadingUpdate, updateProspect } = useProspectMutateNoContext()
  const { deleteProspectStaffAndRefetch } = useProspectStaffMutate()

  const { data } = prospect ? prospect : { data: null }
  const initialState: NullableRecord<ProspectData> = {
    closedAt: data?.closedAt || null,
    prospectType: data?.prospectType || ('' as ProspectType),
  }
  const [state, setState] = useState(initialState)

  const [prospectStaffToRemove, setProspectStaffToRemove] =
    useState<ProspectStaff>()
  const [prospectStaffToEdit, setProspectStaffToEdit] =
    useState<ProspectStaff>()
  const [prospectStaffEnableCreate, setProspectStaffEnableCreate] =
    useState(false)

  return (
    <ProspectLayout>
      <Grid padded>
        <Grid.Row columns="equal">
          <Grid.Column width={4}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h6>Staff</h6>
              <Button
                kind="outlined"
                color="gray"
                onClick={() => setProspectStaffEnableCreate(true)}
                text="Add Staff"
                fontAwesomeIcon="plus"
              />
            </div>
            <List relaxed>
              {allProspectStaff.map((s) => (
                <PersonListItem
                  linkText={
                    s.data?.designCallOneOffUrl
                      ? `One Off Initial Call Expiring ${moment(
                          s.data?.designCallOneOffExpiration,
                        ).format('LL')}`
                      : undefined
                  }
                  linkUrl={s.data?.designCallOneOffUrl ?? undefined}
                  key={s.staffMemberId}
                  type={viewUtils.capsSnakeCaseToTitleCase(s.staffMemberRole)}
                  onUpdate={() => setProspectStaffToEdit(s)}
                  onRemove={() => setProspectStaffToRemove(s)}
                  user={s.staffMember as User}
                />
              ))}
              <ProspectStaffCreateUpdateModal
                open={prospectStaffEnableCreate || !!prospectStaffToEdit}
                onClose={() => {
                  setProspectStaffToEdit(undefined)
                  setProspectStaffEnableCreate(false)
                }}
                prospectStaff={prospectStaffToEdit as ProspectStaff}
              />
              <ConfirmationModal
                firstTitle="Are you sure you want to remove this staff member?"
                onCancel={() => setProspectStaffToRemove(undefined)}
                onConfirm={async () => {
                  await deleteProspectStaffAndRefetch({
                    variables: {
                      where: {
                        prospectId_staffMemberId: {
                          prospectId: prospect?.id ?? '',
                          staffMemberId:
                            prospectStaffToRemove?.staffMemberId ?? '',
                        },
                      },
                    },
                  })
                  setProspectStaffToRemove(undefined)
                }}
                open={!!prospectStaffToRemove}
              />
            </List>
          </Grid.Column>
          <Grid.Column>
            <ProspectStatusTable prospect={prospect as Prospect} />
            <ProspectInfo prospect={prospect as Prospect} />
            <Card fluid>
              <Card.Content>
                <p>Prospect Type</p>
              </Card.Content>
              <Card.Content>
                <Form.Dropdown
                  clearable
                  fluid
                  selection
                  onChange={(_, { value }) =>
                    setState({
                      ...state,
                      prospectType: value as ProspectType,
                    })
                  }
                  options={[
                    {
                      key: 'null',
                      text: '',
                      value: '',
                    },
                    ...Object.values(ProspectType).map((v) => ({
                      key: v,
                      text: viewUtils.capsSnakeCaseToTitleCase(v),
                      value: v,
                    })),
                  ]}
                  value={state.prospectType || ''}
                />
              </Card.Content>
            </Card>
            <FormCheckbox
              label={'Close Prospect'}
              checked={!!state.closedAt}
              onChange={() =>
                setState({
                  ...state,
                  closedAt: state.closedAt ? null : new Date(),
                })
              }
            />
          </Grid.Column>
          <Grid.Column>
            <ConsultationModal
              onCompleted={() => {
                refetch()
                notifySuccess('Consultation created')
              }}
              prospect={prospect as Prospect}
              trigger={
                <Button
                  kind="solid"
                  color="blue"
                  text="Create New Consultation"
                />
              }
            />
            {meetingsNotCanceled.map((m) => (
              <ConsultationCard
                key={m.id}
                meeting={m}
                prospect={prospect as Prospect}
                refetch={refetch}
              />
            ))}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SaveModal
              currentState={state}
              initialState={initialState}
              loadingSave={loadingUpdate}
              onDiscard={() => setState(initialState)}
              onSave={async () => {
                await updateProspect({
                  variables: {
                    data: {
                      data: {
                        ...data,
                        closedAt: state.closedAt,
                        prospectType: state.prospectType,
                      },
                    },
                    where: {
                      id: prospect?.id ?? '',
                    },
                  },
                })
                refetch()
                notifySuccess('Prospect updated')
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProspectLayout>
  )
}

export default Overview
