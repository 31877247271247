import React, { useEffect, useMemo, useState } from 'react'

import { uniqBy } from 'lodash'
import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import { DateInput } from 'semantic-ui-calendar-react'
import {
  Divider,
  DropdownProps,
  Form,
  Grid,
  Input,
  Label,
  Tab,
  Table,
} from 'semantic-ui-react'

import SaveModal from 'components/admin/save-modal'
import SupplierOrderCellContentFulfillmentStatus from 'components/admin/supplier-order/table-cell-content-fulfillment-status'
import CurrencyInput from 'components/shared/currency-input'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import { isAddOnDesign } from 'context/design/utils'
import { formatDollars } from 'context/dollar-utils'
import useProject from 'context/project/use'
import { getRoomFormattedType } from 'context/room/utils'
import useSupplierOrder from 'context/supplier-order/use'
import useSupplierOrderMutate from 'context/supplier-order/use-mutate'
import { isRemedialSupplierOrder } from 'context/supplier-order/utils'
import { StyledForm } from 'styles/admin/main'
import { ProjectFileType } from 'types/project-file'
import { SupplierOrder } from 'types/supplier-order'

import SupplierOrderSummaryTable from './summary'
import SupplierOrderActionTable from './supplier-order-action/table'
import IssuesTable from '../issue/table'

const onHoldReasonOptions = [
  { key: '0', text: '', value: '' },
  {
    key: '1',
    text: 'FORM Delay Production',
    value: 'FORM Delay Production',
  },
  {
    key: '2',
    text: 'FORM Ack Awaiting Sales Confirmation',
    value: 'FORM Ack Awaiting Sales Confirmation',
  },
  {
    key: '3',
    text: 'Other',
    value: 'Other',
  },
]

const SupplierOrderTab = ({
  onComplete,
  refetchMany,
}: {
  onComplete: (createdId?: string) => void
  // for refetch on supplier order action change
  refetchMany?: () => Promise<unknown>
}) => {
  const { designs, designsApproved, primaryOwnerName, project } = useProject()
  const {
    ackApprovedAt,
    applianceSpecs,
    refetch: refetchSupplierOrder,
    supplierOrder,
    supplierOrderFiles,
  } = useSupplierOrder()
  const {
    createSupplierOrderAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateSupplierOrderAndRefetch,
  } = useSupplierOrderMutate()
  const initialState: Partial<NullableRecord<SupplierOrder>> = {
    designId: supplierOrder?.design?.id || null,
    data: supplierOrder?.data || {
      nobiliaFurnitureDiscount: 51.3,
      nobiliaAccessoriesDiscount: 19.8,
    },
    nobiliaAckNumber: supplierOrder?.nobiliaAckNumber || null,
    nobiliaOrderRef: supplierOrder?.nobiliaOrderRef || null,
  }
  const [state, setState] = useState(initialState)

  const { design } = useMemo(() => {
    const design = designs?.find((d) => d.id === state.designId)
    const room = design?.room

    return {
      design: design
        ? {
            ...design,
            room,
          }
        : undefined,
    }
  }, [state.designId, designs])

  useEffect(() => {
    if (supplierOrder?.nobiliaAckNumber) {
      setState({
        ...state,
        nobiliaAckNumber: supplierOrder.nobiliaAckNumber,
      })
    }
  }, [supplierOrder?.nobiliaAckNumber])

  useEffect(() => {
    if (supplierOrder?.data.onHoldReason !== state.data?.onHoldReason) {
      setState({
        ...state,
        data: {
          ...state.data,
          onHoldReason: '',
        },
      })
    }
  }, [supplierOrder?.data])

  const onSave = async () => {
    let createdId = ''
    if (supplierOrder) {
      await updateSupplierOrderAndRefetch({
        variables: {
          data: {
            ...(state.designId
              ? {
                  design: {
                    connect: { id: state.designId },
                  },
                }
              : null),
            data: state.data,
            nobiliaAckNumber: state.nobiliaAckNumber,
            nobiliaOrderRef: state.nobiliaOrderRef,
          },
          where: { id: supplierOrder?.id as string },
        },
      })
    } else {
      const res = await createSupplierOrderAndRefetch({
        variables: {
          data: {
            ...(state.designId
              ? {
                  design: {
                    connect: { id: state.designId },
                  },
                }
              : null),
            data: state.data,
            nobiliaAckNumber: state.nobiliaAckNumber,
            nobiliaOrderRef: state.nobiliaOrderRef,
            project: {
              connect: { id: project.id },
            },
          },
        },
      })
      createdId = res.data?.createOneSupplierOrder?.id ?? ''
    }
    onComplete(createdId)
  }

  // if third party just render nobilia information
  if (!project) {
    return (
      <>
        <Tab
          panes={[
            {
              menuItem: 'Nobilia Order Information',
              render: () => (
                <NobiliaOrderInformationTab
                  supplierOrder={supplierOrder}
                  state={state as SupplierOrder}
                  setState={setState}
                />
              ),
            },
          ]}
        />
        <SaveModal
          currentState={state}
          initialState={initialState}
          loadingSave={loadingUpdate}
          onDiscard={onComplete}
          onSave={onSave}
        />
      </>
    )
  }

  const onChangeDropdown = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    const design = designs.find((d) => d.id === value)

    setState({
      ...state,
      designId: value as string,
      ...(design && {
        nobiliaOrderRef: `${primaryOwnerName} | ${getRoomFormattedType(
          design.room,
        )} 1`,
      }),
    })
  }

  return (
    <>
      <Tab
        panes={[
          {
            menuItem: 'FORM Order Information',
            render: () => (
              <Tab.Pane>
                {isRemedialSupplierOrder(supplierOrder) ? (
                  <Label color="red" content="Remedial" ribbon />
                ) : null}
                {supplierOrder?.design &&
                isAddOnDesign(supplierOrder?.design) ? (
                  <Label color="yellow" content="Add-On" ribbon />
                ) : null}
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <p className="subtitle">Order Information</p>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <p className="subtitle">Fulfillment Status</p>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <p className="subtitle">Delivery Note</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <StyledForm>
                        <Form.Field>
                          FORM Design
                          <Form.Dropdown
                            name="design"
                            onChange={onChangeDropdown}
                            options={uniqBy(
                              [...(design ? [design] : []), ...designsApproved],
                              'id',
                            ).map((d) => ({
                              key: d.id,
                              text: `${
                                d.room ? getRoomFormattedType(d.room) : ''
                              } | ${d.name}`,
                              value: d.id,
                            }))}
                            placeholder="Design"
                            search
                            selection
                            value={state.designId ?? undefined}
                          />
                        </Form.Field>
                        <Form.Field>
                          Nobilia Order Reference
                          <Form.Input
                            name="nobiliaOrderRef"
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) => {
                              setState({
                                ...state,
                                nobiliaOrderRef: value,
                              })
                            }}
                            value={state.nobiliaOrderRef || ''}
                          />
                        </Form.Field>
                        <Form.Field>
                          Nobilia ACK Number
                          <Form.Input
                            name="nobiliaAckNumber"
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) => {
                              setState({
                                ...state,
                                nobiliaAckNumber: value,
                              })
                            }}
                            value={state.nobiliaAckNumber || ''}
                            error={
                              state.nobiliaAckNumber &&
                              state.nobiliaAckNumber?.length > 8
                                ? {
                                    content:
                                      'Please enter only the 8 digit ACK number',
                                    pointing: 'below',
                                  }
                                : null
                            }
                          />
                        </Form.Field>
                      </StyledForm>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <StyledForm style={{ margin: '0' }}>
                        <Form.Field>
                          Guaranteed On / Before
                          <DateInput
                            closable
                            dateFormat={'MM/DD/YYYY'}
                            duration={0}
                            initialDate={moment().format('MM/DD/YYYY')}
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  guaranteedOnOrBefore: value,
                                },
                              })
                            }
                            value={state.data?.guaranteedOnOrBefore || ''}
                          />
                        </Form.Field>
                        <Form.Field>
                          # of Palletes
                          <Form.Input
                            type="number"
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  numPalletes: parseFloat(value),
                                },
                              })
                            }
                            value={state.data?.numPalletes || ''}
                          />
                        </Form.Field>
                        <Form.Field>
                          # of Boxes
                          <Form.Input
                            type="number"
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  numPackages: parseFloat(value),
                                },
                              })
                            }
                            value={state.data?.numPackages || ''}
                          />
                        </Form.Field>
                        <Form.Field>
                          Route Index
                          <Form.Input
                            type="number"
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  routeIndex: parseFloat(value),
                                },
                              })
                            }
                            value={state.data?.routeIndex || ''}
                          />
                        </Form.Field>
                      </StyledForm>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      {supplierOrder && (
                        <SupplierOrderCellContentFulfillmentStatus
                          supplierOrder={supplierOrder}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column width={4}>
                      {supplierOrder && (
                        <Grid>
                          <ProjectFileUploads
                            columnsProps={{
                              computer: 16,
                              widescreen: 16,
                            }}
                            onlyOneFile
                            projectFiles={
                              supplierOrder?.deliveryNote
                                ? [supplierOrder.deliveryNote]
                                : []
                            }
                            projectFileType={ProjectFileType.DELIVERY_NOTE}
                            projectId={project.id}
                            refetch={refetchSupplierOrder}
                            supplierOrderDeliveryNoteId={supplierOrder?.id}
                          />
                        </Grid>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                  <Grid.Row>
                    <Grid.Column>
                      <Grid>
                        <Grid.Row columns={4}>
                          <Grid.Column>
                            <p className="subtitle">Order Files</p>
                            <Grid>
                              <ProjectFileUploads
                                columnsProps={{
                                  width: 16,
                                }}
                                projectFiles={supplierOrderFiles}
                                projectId={project.id}
                                noUpload
                              />
                            </Grid>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="subtitle">Appliance Specs</p>
                            <Grid>
                              <ProjectFileUploads
                                columnsProps={{
                                  width: 16,
                                }}
                                projectFiles={applianceSpecs}
                                projectId={project.id}
                                noUpload
                              />
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          },
          {
            menuItem: {
              content: 'FORM Confirmation',
              disabled: !supplierOrder,
            },
            render: () => (
              <Tab.Pane>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <p className="subtitle">ACK Revisions</p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <p className="subtitle">Approved ACK Information</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <SupplierOrderActionTable refetchMany={refetchMany} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <StyledForm>
                        <Form.Group widths={8}>
                          <Form.Field width={8} disabled={!ackApprovedAt}>
                            Nobilia Price
                            <CurrencyInput
                              defaultValue={
                                state.data?.nobiliaPrice &&
                                state.data.nobiliaPrice > 0
                                  ? formatDollars(state.data?.nobiliaPrice)
                                  : ''
                              }
                              inputMode="numeric"
                              maskOptions={{}}
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) => {
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    nobiliaPrice: Math.round(
                                      Number(value.replace(/[, $]+/g, '')) *
                                        100,
                                    ),
                                  },
                                })
                              }}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field disabled={!ackApprovedAt}>
                            Volume (cubic feet)
                            <Input
                              disabled={!ackApprovedAt}
                              label="ft3"
                              labelPosition="right"
                              type="number"
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    volumeCubicFeet: parseFloat(value),
                                  },
                                })
                              }
                              value={state.data?.volumeCubicFeet || ''}
                            />
                          </Form.Field>
                          <Form.Field disabled={!ackApprovedAt}>
                            Volume (cubic meters)
                            <Input
                              label="m3"
                              labelPosition="right"
                              type="number"
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    volumeCubicMeters: parseFloat(value),
                                  },
                                })
                              }
                              value={state.data?.volumeCubicMeters || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Dropdown
                            disabled={!!ackApprovedAt}
                            label="Ack On Hold"
                            name="hold"
                            onChange={(_, { value }) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  onHoldReason: value as string,
                                  ...(!state.data?.onHoldDate && {
                                    onHoldDate: new Date().toISOString(),
                                  }),
                                },
                              })
                            }}
                            options={onHoldReasonOptions}
                            placeholder="Select On Hold Reason"
                            selection
                            width={8}
                            value={
                              ackApprovedAt ? '' : state.data?.onHoldReason
                            }
                          />
                          {state.data?.onHoldDate && (
                            <Form.Field>
                              On Hold Date
                              <p>
                                {moment(state.data?.onHoldDate)
                                  .format('MM/DD/YYYY')
                                  .toString()}
                              </p>
                            </Form.Field>
                          )}
                        </Form.Group>
                      </StyledForm>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          },
          {
            menuItem: {
              content: 'Nobilia Order Information',
              disabled: !supplierOrder,
            },
            render: () => (
              <NobiliaOrderInformationTab
                supplierOrder={supplierOrder}
                state={state as SupplierOrder}
                setState={setState}
              />
            ),
          },
          {
            menuItem: {
              content: 'Last Mile',
              disabled: !supplierOrder,
            },
            render: () => (
              <Tab.Pane>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <StyledForm>
                        <Form.Field width={8}>
                          In Storage Until
                          <DateInput
                            closable
                            dateFormat={'MM/DD/YYYY'}
                            duration={0}
                            initialDate={moment().format('MM/DD/YYYY')}
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  inStorageUntil: value,
                                },
                              })
                            }
                            value={state.data?.inStorageUntil || ''}
                          />
                        </Form.Field>
                        <Form.Group widths="equal">
                          <Form.Field>
                            Ready For Dispatch Estimated
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    readyForDispatchAtEstimated: value,
                                  },
                                })
                              }
                              value={
                                state.data?.readyForDispatchAtEstimated || ''
                              }
                            />
                          </Form.Field>
                          <Form.Field>
                            Ready For Dispatch Actual
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    readyForDispatchAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.readyForDispatchAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            Customer Contact Estimated
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    customerContactAtEstimated: value,
                                  },
                                })
                              }
                              value={
                                state.data?.customerContactAtEstimated || ''
                              }
                            />
                          </Form.Field>
                          <Form.Field>
                            Customer Contact Actual
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    customerContactAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.customerContactAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            Delivery Date (From Delivery Card)
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={() => {}}
                              value={
                                state.delivery?.date
                                  ? moment(state.delivery.date).format(
                                      'MM/DD/YYYY',
                                    )
                                  : ''
                              }
                            />
                          </Form.Field>
                          <Form.Field>
                            Delivery Estimated (From Nobilia/Zoho)
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    deliveryAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.deliveryAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            Delivery Actual
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    deliveryAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.deliveryAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                      </StyledForm>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          },
          ...(supplierOrder?.data?.isRemedial ||
          isAddOnDesign(supplierOrder?.design)
            ? [
                {
                  menuItem: {
                    content: 'Issues Resolved',
                    disabled: !supplierOrder,
                  },
                  render: () => (
                    <Tab.Pane>
                      <Grid padded>
                        <Grid.Row>
                          <Grid.Column>
                            <p>
                              Issues resolved by this Remedial should be checked
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <IssuesTable
                              issues={supplierOrder.issuesResolved ?? []}
                              mode="readOnly"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
              ]
            : []),
          {
            menuItem: {
              content: 'Summary',
              disabled: !supplierOrder,
            },
            render: () => (
              <Tab.Pane>
                {supplierOrder && (
                  <Grid padded>
                    <Grid.Row centered>
                      <Grid.Column width={12}>
                        <SupplierOrderSummaryTable
                          supplierOrder={supplierOrder}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </Tab.Pane>
            ),
          },
        ]}
      />
      <SaveModal
        currentState={state}
        disableSave={!state.designId}
        initialState={initialState}
        loadingSave={loadingCreate || loadingUpdate}
        onDiscard={onComplete}
        onSave={onSave}
      />
    </>
  )
}

export default SupplierOrderTab

const NobiliaOrderInformationTab = ({
  supplierOrder,
  state,
  setState,
}: {
  supplierOrder: SupplierOrder
  state: Partial<SupplierOrder>
  setState: (s: Partial<SupplierOrder>) => void
}) => {
  const project = supplierOrder.project
  return (
    <Tab.Pane>
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <StyledForm>
              <Form.Field
                control={TextareaAutosize}
                label="Notes"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setState({
                    ...state,
                    data: {
                      ...state.data,
                      notes: e.target.value,
                    },
                  })
                }
                placeholder="Notes"
                value={state.data?.notes || ''}
              />
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table celled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={4}>Record ID</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaRecordId}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Order Log Name</Table.Cell>
                  <Table.Cell>
                    {project
                      ? supplierOrder?.data?.nobiliaOrderLogName
                      : 'REDACTED'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Status</Table.Cell>
                  <Table.Cell>{supplierOrder?.data?.nobiliaStatus}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Order Reference</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaOrderReference}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Order Reference 2</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaOrderReference2}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Order Type</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaOrderType}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Ack Number</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaFactoryAckNumber}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Ack Approved At</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data
                      ?.nobiliaFinalApprovalFromClientActual ||
                      supplierOrder?.data?.nobiliaFinalApprovalFromClient}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Delivery Area</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaDeliveryArea}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Delivery Address</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaDeliveryZip
                      ? `${supplierOrder?.data?.nobiliaDeliveryStreetAddress}, ${supplierOrder?.data?.nobiliaDeliveryCity}, ${supplierOrder?.data?.nobiliaDeliveryState} ${supplierOrder?.data?.nobiliaDeliveryZip}`
                      : null}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Last Mile Delivery</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaLastMileDelivery}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Price</Table.Cell>
                  <Table.Cell>
                    {project
                      ? formatDollars(
                          (supplierOrder?.data?.nobiliaFurniturePrice ??
                            0 +
                              (supplierOrder?.data?.nobiliaAccessoriesPrice ??
                                0)) * 100 || 0,
                        )
                      : 'REDACTED'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Furniture Price</Table.Cell>
                  <Table.Cell>
                    {project
                      ? formatDollars(
                          (supplierOrder?.data?.nobiliaFurniturePrice ?? 0) *
                            100 || 0,
                        )
                      : 'REDACTED'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Furniture Discount</Table.Cell>
                  <Table.Cell>
                    {project
                      ? supplierOrder?.data?.nobiliaFurnitureDiscount
                      : 'REDACTED'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Accessories Price</Table.Cell>
                  <Table.Cell>
                    {project
                      ? formatDollars(
                          (supplierOrder?.data?.nobiliaAccessoriesPrice ?? 0) *
                            100 || 0,
                        )
                      : 'REDACTED'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Accessories Discount</Table.Cell>
                  <Table.Cell>
                    {project
                      ? supplierOrder?.data?.nobiliaAccessoriesDiscount
                      : 'REDACTED'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Container Sequence</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaContainerSequence}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Tracking Number</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaTrackingNumber}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Container Number</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaContainerNumber}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Port</Table.Cell>
                  <Table.Cell>{supplierOrder?.data?.nobiliaPort}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Warehouse</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaWarehouse}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Production Week</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaProductionWeek}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Production Week Ending Date</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaProductionWeekEndingDate}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Estimated Shipping</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaContainerShippingAtEstimated}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Actual Shipping</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaContainerShippingAtActual}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Estimated Warehouse</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaContainerReceivingAtEstimated}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Actual Warehouse</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaContainerReceivingAtActual}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Actual Delivery At Site</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaDeliveredAtActual}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Delivery Truck</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaDeliveryTruck}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Volume m3</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaVolumeCubicMeters}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>Weigth kg</Table.Cell>
                  <Table.Cell>
                    {supplierOrder?.data?.nobiliaWeightKilograms}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  )
}
