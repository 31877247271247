import React from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectLayout from 'components/admin/project/layout'
import ProjectSupplierOrderManyProvider from 'context/project/supplier-order/provider-many'

interface SupplierOrderIndexManyProps
  extends RouteComponentProps<{
    project_id: string
  }> {
  children?: React.ReactNode
}

export default (props: SupplierOrderIndexManyProps) => (
  <ProjectLayout>
    <ProjectSupplierOrderManyProvider>
      {props.children}
    </ProjectSupplierOrderManyProvider>
  </ProjectLayout>
)
