import React from 'react'

import { RouteComponentProps } from '@reach/router'

import SupplierOrders from 'components/admin/supplier-order/index-many'
import SupplierOrdersConfirmationTable from 'components/admin/supplier-order/table-confirmation'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import { FORMStatus } from 'context/supplier-order/utils-many'

import NobiliaOrderProcessorLayout from './layout'

const NobiliaOrderProcessorProjects = (_: RouteComponentProps) => {
  return (
    <NobiliaOrderProcessorLayout>
      <SupplierOrderManyProvider
        defaultFilters={{
          statusesFORM: [
            FORMStatus.EDINotSent,
            FORMStatus.AwaitingNobilia,
            FORMStatus.AwaitingFORM,
          ],
          sortBy: 'createdAt',
          sortDirection: 'ascending',
          take: 50,
        }}
        skipLoader
      >
        <SupplierOrders table={<SupplierOrdersConfirmationTable />} />
      </SupplierOrderManyProvider>
    </NobiliaOrderProcessorLayout>
  )
}

export default NobiliaOrderProcessorProjects
