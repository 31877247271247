import React, { useState } from 'react'

import { isEqual, sortBy } from 'lodash'
import { Button, Card, Divider, Form } from 'semantic-ui-react'

import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import {
  AffiliateCodes,
  FORM_CABINETRY_DISCOUNT_OPTIONS,
} from 'context/project/utils-discounts'
import { StyledForm } from 'styles/app/components/form'
import { Colors } from 'styles/app/system'

const DiscountsCard = () => {
  const { project } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()

  const initialState = {
    affiliateCode: project.data.affiliateCode,
    affiliateIsPaid: project.data.affiliateIsPaid,
    shareASaleTrackingCode: project.data.shareASaleTrackingCode,
    shareASaleProjectDepositPaid: project.data.shareASaleProjectDepositPaid,
    baseCabinetryDiscount: project.data.baseCabinetryDiscount || 0,
    projectDiscount: project.data.projectDiscount || 0,
  }
  const [state, setState] = useState(initialState)

  const onChangeAffiliateCode = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      affiliateCode: value,
    })
  }

  const updateCabinetryDiscount = (value: string) => {
    const parsed = parseFloat(value)
    setState({
      ...state,
      baseCabinetryDiscount: parsed,
      projectDiscount: parsed ? 0 : state.projectDiscount,
    })
  }

  return (
    <Card fluid>
      <Card.Content>
        <h6>Discount / Affiliate</h6>
        <Divider hidden />
        <StyledForm>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Project Base Cabinetry Discount (%)</label>
              <Form.Dropdown
                placeholder="Project Base Cabinetry Discount"
                selection
                options={FORM_CABINETRY_DISCOUNT_OPTIONS}
                onChange={(_, { value }) => {
                  updateCabinetryDiscount(value as string)
                }}
                value={state.baseCabinetryDiscount.toString()}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <i>
              The cabinetry discount is calculated in Winner. When changing the
              project discount, make sure current designs reflect any change in
              discount amount.{' '}
              <span style={{ color: Colors.red700 }}>
                <b>
                  Setting this to a non-zero value removes the project discount,
                  if any.
                </b>
              </span>
            </i>
          </Form.Field>
          <Divider />
          <p className="subtitle">Affiliate Information</p>
          <Form.Input
            fluid
            label="Affiliate Code"
            onChange={onChangeAffiliateCode}
            value={state.affiliateCode}
          />
          <Form.Checkbox
            checked={state.affiliateIsPaid}
            label="Affiliate Paid"
            onChange={() => {
              setState({
                ...state,
                affiliateIsPaid: !state.affiliateIsPaid,
              })
            }}
          />
          <Divider />
          <p className="subtitle">Share a Sale Information</p>
          <Form.Input
            fluid
            label="Share A Sale Tracking Code"
            value={state.shareASaleTrackingCode}
          />
          <Form.Checkbox
            checked={state.shareASaleProjectDepositPaid}
            label="Share A Sale Project Deposit Paid"
          />
          <Form.Group widths="equal">
            <Form.Field disabled={!!state.baseCabinetryDiscount}>
              <label>Project Discount ($)</label>
              <Form.Dropdown
                placeholder="Project Discount"
                selection
                options={sortBy(
                  AffiliateCodes.reduce(
                    (acc, ac) => {
                      if (ac.projectDiscount === 0) return acc
                      const found = acc.find(
                        (item) => item.value === ac.projectDiscount.toString(),
                      )
                      if (!found)
                        return [
                          ...acc,
                          {
                            key: ac.projectDiscount.toString(),
                            text: `${ac.amount} - ${ac.code}`,
                            value: ac.projectDiscount.toString(),
                          },
                        ]
                      else found.text = `${found.text}, ${ac.code}`
                      return acc
                    },
                    [{ key: 'null', text: '', value: '0' }],
                  ),
                  (c) => parseFloat(c.value),
                )}
                onChange={(_, { value }) => {
                  setState({
                    ...state,
                    projectDiscount: parseFloat(value as string),
                  })
                }}
                value={state.projectDiscount.toString()}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field disabled={!!state.baseCabinetryDiscount}>
            <i>
              The project discount should <b>not</b> be calculated in Winner.
              The system should show it as a final line item.
            </i>
          </Form.Field>
          <Button
            content="Save"
            disabled={isEqual(initialState, state)}
            floated="right"
            type="submit"
            onClick={async () =>
              await updateProjectAndRefetch({
                variables: {
                  data: {
                    data: {
                      affiliateIsPaid: state.affiliateIsPaid,
                      affiliateCode: state.affiliateCode,
                      baseCabinetryDiscount: state.baseCabinetryDiscount,
                      projectDiscount: state.projectDiscount,
                    },
                  },
                  where: {
                    id: project.id,
                  },
                },
              })
            }
            primary
          />
        </StyledForm>
      </Card.Content>
    </Card>
  )
}

export default DiscountsCard
