import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import SalesDesignerProvider from 'context/dashboard/sales-designer/provider'
import { StyledToggle } from 'styles/admin/main'
import { UserType, UserTypeStaff } from 'types/user'

const SalesDesignerIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  const { formUserId, user } = useAuth()

  return (
    <SalesDesignerProvider user_id={props.user_id ?? ''}>
      <Grid>
        <Grid.Row style={{ padding: '0 0 3px' }}>
          <Grid.Column textAlign="right">
            <StyledToggle
              checked={(
                [
                  UserTypeStaff.SALES_DESIGNER,
                  UserTypeStaff.ADMIN,
                ] as UserType[]
              ).includes(user.type)}
              label="Sales Designer Dashboard"
              onChange={() =>
                navigate(`/admin/dashboard/${formUserId}/trade-designer`)
              }
              toggle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {props.children}
    </SalesDesignerProvider>
  )
}

export default SalesDesignerIndex
