import React from 'react'

import { Table } from 'semantic-ui-react'

import * as supplierOrderUtils from 'context/supplier-order/utils'
import { SupplierOrder } from 'types/supplier-order'
import { StatusStage } from 'types/utils'
import * as viewUtils from 'views/utils'

const SupplierOrderSummaryTable = ({
  supplierOrder,
}: {
  supplierOrder: SupplierOrder
}) => {
  const orderStatus = supplierOrderUtils.getSupplierOrderStatus(supplierOrder)

  return (
    <Table definition celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Context</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Confirmed</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <viewUtils.TableRowWithCheck
          {...(orderStatus.find(
            (s) => s.id === 'designSentToNobilia',
          ) as StatusStage)}
        />
        <viewUtils.TableRowWithCheck
          {...(orderStatus.find((s) => s.id === 'ackApproved') as StatusStage)}
        />
        <viewUtils.TableRowWithCheck
          {...(orderStatus.find(
            (s) => s.id === 'productionWeek',
          ) as StatusStage)}
        />
        <viewUtils.TableRowWithCheck
          {...(orderStatus.find((s) => s.id === 'shipment') as StatusStage)}
        />
        <viewUtils.TableRowWithCheck
          {...(orderStatus.find((s) => s.id === 'arrival') as StatusStage)}
        />
        <viewUtils.TableRowWithCheck
          {...(orderStatus.find(
            (s) => s.id === 'receivingWarehouse',
          ) as StatusStage)}
        />
        <viewUtils.TableRowWithCheck
          {...(orderStatus.find((s) => s.id === 'delivered') as StatusStage)}
        />
      </Table.Body>
    </Table>
  )
}

export default SupplierOrderSummaryTable
