import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import Tasks from 'components/admin/project/task/tasks'
import useProject from 'context/project/use'

const TasksM = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project, refetch, tasksCompleted, tasksToDo, tasksUrgent } =
    useProject()

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Tasks" />
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <h5>Tasks</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={15}>
            <Tasks
              projectView
              project={project}
              refetch={refetch}
              tasks={[...tasksUrgent, ...tasksToDo].map((t) => ({
                ...t,
                project: project,
              }))}
              title="To Do"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={15}>
            <Tasks
              projectView
              completedTasks
              project={project}
              refetch={refetch}
              tasks={tasksCompleted.map((t) => ({ ...t, project: project }))}
              title="Completed"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProjectLayout>
  )
}

export default TasksM
