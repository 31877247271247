// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import Lottie from 'react-lottie-player'

import useAuth from 'context/auth/use'
import lottieJson from 'icons/loader.json'
import ControlSet, { ControlSetProps } from 'planner/ui/controls/control-set'
import Header, { HeaderProps } from 'planner/ui/header'
import { LoaderContainer } from 'styles/planner/main'
import { StyledContent } from 'styles/planner/system'

import { ContentWrapper, Main, PlannerContainer, Sidebar } from './styles'

type PlannerLayoutProps = {
  controlSetProps: ControlSetProps
  headerProps: Pick<HeaderProps, 'menus' | 'buttons'>
  loader: { show: boolean; style: boolean }
  main: React.ReactNode
  sidebar: React.ReactNode
}

const PlannerLayout = ({
  controlSetProps,
  headerProps,
  loader,
  main,
  sidebar,
}: PlannerLayoutProps) => {
  const { user, logout } = useAuth()

  return (
    <StyledContent>
      <Header user={user} logout={logout} {...headerProps} />

      <ContentWrapper>
        <Main>
          <PlannerContainer
            style={{ display: !loader.show ? 'none' : undefined }}
          >
            <LoaderContainer
              styled={{
                show: loader.show,
                opacity: loader.style,
              }}
            >
              <Lottie speed={2} loop play animationData={lottieJson} />
            </LoaderContainer>
          </PlannerContainer>
          <PlannerContainer
            style={{ display: loader.show ? 'none' : undefined }}
          >
            {main}
          </PlannerContainer>
          <ControlSet {...controlSetProps} />
        </Main>

        <Sidebar>{sidebar}</Sidebar>
      </ContentWrapper>
    </StyledContent>
  )
}

export default PlannerLayout
