import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Grid } from 'semantic-ui-react'

import MediaCard from 'components/admin/catalog/media/card'
import { formatDollars } from 'context/dollar-utils'
import * as mUtils from 'context/media/utils-many'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import { NodeBase, NodeOption } from 'planner/objects/node'
import { ProductBase } from 'planner/objects/product'
import * as ProductFactory from 'planner/objects/product-factory'
import NobiliaProductBase from 'planner/objects/products/nobilia/base'
import {
  formatDimensionMillimetersWithSuffix,
  formatDimensionInches,
} from 'planner/utils/measurement-utils'
import { StyledAccordion } from 'styles/planner/main'
import { ProductType } from 'types/product-type'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import OptionsModal from './options-modal'
import PlannerView from './planner-view'
import PriceGroupTable from './price-group-table'
import * as productUtils from './utils'

interface SummaryProps {
  nobiliaProduct: NobiliaProductBase
  refetch: () => Promise<unknown>
  sceneNodes: NodeBase[]
}

const Summary = ({ nobiliaProduct, refetch, sceneNodes }: SummaryProps) => {
  const { globalRestrictionsIndex } = useNobiliaOptions()
  const [product, setProduct] = useState<NobiliaProductBase>(nobiliaProduct)
  const [showSpecs, toggleSpecs] = useState<boolean>(true)
  const [showConfiguration, toggleConfiguration] = useState<boolean>(false)

  const additionalItems = product.getNobiliaProductChildren() || []
  const options = product.getNodeOptions(globalRestrictionsIndex)
  const onChange = (
    options: NodeOption[],
    changedAdditionalItems: ProductBase[],
  ): void => {
    let newProduct = ProductFactory.copy(product, {
      changedOptions: options,
    })
    changedAdditionalItems.forEach((acc) => {
      newProduct = ProductFactory.copy(newProduct, {
        changedAdditionalItem: acc,
      })
    })
    setProduct(newProduct as NobiliaProductBase)
  }

  const productPrice = productUtils.getPriceWithAdditionalItems(
    additionalItems,
    product.options,
    product.productClass,
  )

  const media = mUtils.parseMedia(product.productClass.media ?? [])
  const referenceImage = media.reference[0]

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <h2>{product.productClass.orderId}</h2>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <p className="caption">Configuration price</p>
                <h4>{formatDollars(productPrice)}</h4>
              </Grid.Column>
              <Grid.Column>
                <p className="caption">Type</p>
                <p>
                  {capsSnakeCaseToTitleCase(product.productClass.productType)}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: '0' }}>
              <Grid.Column>
                <p className="caption">Planning advice</p>
                <p>{product.productClass.planningAdvice || ''}</p>
                <StyledAccordion open={showSpecs} styled>
                  <Accordion.Title
                    active={showSpecs}
                    onClick={() => toggleSpecs(!showSpecs)}
                    style={{ justifyContent: 'space-between' }}
                  >
                    <p className="caption">Product Specs</p>
                    <FontAwesomeIcon
                      icon={['fal', showSpecs ? 'minus' : 'plus']}
                    />
                  </Accordion.Title>
                  <Accordion.Content
                    active={showSpecs}
                    className="catalog-accordion"
                  >
                    <div>
                      <p className="gray">Width</p>
                      <p>
                        {formatDimensionMillimetersWithSuffix(
                          product.getWidth(),
                        )}{' '}
                        / {formatDimensionInches(product.getWidth())}
                      </p>
                    </div>
                    <div>
                      <p className="gray">Height</p>
                      <p>
                        {formatDimensionMillimetersWithSuffix(
                          product.getHeight(),
                        )}{' '}
                        / {formatDimensionInches(product.getHeight())}
                      </p>
                    </div>
                    <div>
                      <p className="gray">Depth</p>
                      <p>
                        {formatDimensionMillimetersWithSuffix(
                          product.getDepth(),
                        )}{' '}
                        / {formatDimensionInches(product.getDepth())}
                      </p>
                    </div>
                  </Accordion.Content>
                </StyledAccordion>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <StyledAccordion open={showConfiguration} styled>
                  <Accordion.Title
                    active={showConfiguration}
                    onClick={() => toggleConfiguration(!showConfiguration)}
                    style={{ justifyContent: 'space-between' }}
                  >
                    <p className="caption">Configuration</p>
                    <FontAwesomeIcon
                      icon={['fal', showConfiguration ? 'minus' : 'plus']}
                    />
                  </Accordion.Title>
                  <Accordion.Content
                    active={showConfiguration}
                    className="catalog-accordion"
                  >
                    <p className="subtitle">Options</p>
                    <div className="divider" />
                    {options
                      ?.filter((o) => !!o.value)
                      .map((o) => {
                        const value = o.options.find(
                          (opt) => opt.value === o.value,
                        )?.text

                        return (
                          <div key={o.id}>
                            <p className="gray">{o.description}</p>
                            <p>{value}</p>
                          </div>
                        )
                      })}
                    {additionalItems.length ? (
                      <>
                        <div className="cabinetry-price">
                          <p>Cabinetry Price</p>
                          <p>
                            {formatDollars(
                              productUtils.getPriceWithoutAdditionalItems(
                                product.options,
                                product.productClass,
                              ),
                            )}
                          </p>
                        </div>
                        <p className="subtitle" style={{ marginTop: '60px' }}>
                          Additional Items
                        </p>
                        <div className="divider" />
                        {additionalItems.map((a) => (
                          <div>
                            <p className="gray">
                              {a.productClass.orderId}{' '}
                              <span className="black">x{a.quantity}</span>
                            </p>
                            <p>
                              {formatDollars(
                                productUtils.getAdditionalItemPrice(
                                  a.productClass,
                                ),
                              )}
                            </p>
                          </div>
                        ))}
                      </>
                    ) : null}
                    <div className="price">
                      <p>Total Price</p>
                      <h4>{formatDollars(productPrice)}</h4>
                    </div>
                  </Accordion.Content>
                </StyledAccordion>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <OptionsModal
                  buttonText={
                    productPrice > 0 ? 'Edit Configuration' : 'Configure'
                  }
                  nodeAdditionalItems={additionalItems}
                  nodeOptions={options}
                  onChange={onChange}
                  product={product}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column>
          <Grid columns="equal">
            <Grid.Row stretched>
              <Grid.Column width={6} textAlign="center">
                <MediaCard
                  enableEdit={!!referenceImage}
                  media={referenceImage}
                  refetch={refetch}
                />
                <p className="caption" style={{ marginTop: '14px' }}>
                  Reference Image
                </p>
              </Grid.Column>
              {![
                ProductType.UNCATEGORIZED,
                ProductType.ACCESSORY,
                ProductType.ADDITIONAL_ITEM,
              ].includes(product.productClass.productType) ? (
                <Grid.Column width={6} textAlign="center">
                  <PlannerView product={product} sceneNodes={sceneNodes} />
                  <p className="caption" style={{ marginTop: '14px' }}>
                    Planner View
                  </p>
                </Grid.Column>
              ) : null}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <PriceGroupTable productClass={product.productClass} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Summary
