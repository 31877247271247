// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, Divider, Form, Grid } from 'semantic-ui-react'

import AdditionalItemCard from 'components/shared/catalog/nobilia-product-class/additional-item-card'
import useDesign from 'context/planner/design-use'
import {
  FIND_UNIQUE_NOBILIA_PRODUCT_CLASS,
  FindUniqueNobiliaProductClassPayload,
  FindUniqueNobiliaProductClassVariables,
} from 'queries/nobilia-product-class'
import { FormSelect, StyledAccordion } from 'styles/planner/main'

import { ProductBase } from '../../../objects/product'
import NobiliaProductBase from '../../../objects/products/nobilia/base'

const NobiliaAdditionalItemsMenu = (props: {
  onAdditionalItemChange: (changedAdditionalItem: Partial<ProductBase>) => void
  product: NobiliaProductBase
}) => {
  const apolloClient = useApolloClient()
  const { design } = useDesign()
  const [additionalItemsActive, setAdditionalItemsActive] =
    useState<boolean>(false)
  const [additionalItem, setAdditionalItem] = useState<string>()
  const additionalItems = props.product.getNobiliaProductChildren() || []

  const additionalItemOptions: [string, number][] =
    props.product.productClass.additionalItemGroups.reduce((acc, g) => {
      if (g.additionalItems)
        g.additionalItems.map((i, idx) => acc.push([i, g.quantities[idx]]))
      return acc
    }, [])

  const dropdownOptions = additionalItemOptions.filter(
    (o) => !additionalItems.map((a) => a.productClass.orderId).includes(o[0]),
  )

  return (
    <StyledAccordion fluid transparent="true">
      <Accordion.Title
        active={additionalItemsActive}
        onClick={() => setAdditionalItemsActive(!additionalItemsActive)}
      >
        <FontAwesomeIcon
          icon={[
            'fal',
            additionalItemsActive ? 'chevron-down' : 'chevron-right',
          ]}
        />
        <p>Additional Items</p>
      </Accordion.Title>
      <Accordion.Content active={additionalItemsActive}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <FormSelect
                  disabled={!dropdownOptions.length}
                  fluid
                  icon={
                    <i>
                      <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                    </i>
                  }
                  label="Additional Items"
                  onChange={(
                    _: React.ChangeEvent<HTMLInputElement>,
                    { value }: { value: string },
                  ) => setAdditionalItem(value)}
                  options={dropdownOptions.map((ao) => ({
                    key: ao[0],
                    value: ao[0],
                    text: ao[0],
                  }))}
                  value={additionalItem}
                />
              </Form>
              <Button
                className="blue"
                content="Add"
                disabled={!additionalItem}
                floated="right"
                onClick={async () => {
                  const { data } = await apolloClient.query<
                    FindUniqueNobiliaProductClassPayload,
                    FindUniqueNobiliaProductClassVariables
                  >({
                    query: FIND_UNIQUE_NOBILIA_PRODUCT_CLASS,
                    variables: {
                      where: {
                        orderId_catalog: {
                          orderId: additionalItem,
                          catalog:
                            design.metadata?.catalog ||
                            process.env.GATSBY_DEFAULT_CATALOG,
                        },
                      },
                    },
                  })
                  props.onAdditionalItemChange({
                    productClass: data.nobiliaProductClass,
                    quantity: 1,
                  })
                  setAdditionalItem('')
                }}
                style={{ marginTop: '12px' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {additionalItems?.length ? (
          <>
            <Divider />
            {additionalItems.map((acc) => {
              // todo refactor with metadata
              const maxQuantity = 10
              return (
                <AdditionalItemCard
                  key={acc.idLocal}
                  additionalItemProductClass={acc.productClass}
                  deleteButton
                  maxQuantity={maxQuantity}
                  minValue={1}
                  onAdditionalItemChange={props.onAdditionalItemChange}
                  quantity={acc.quantity}
                />
              )
            })}
          </>
        ) : null}
      </Accordion.Content>
    </StyledAccordion>
  )
}

export default NobiliaAdditionalItemsMenu
