import { useMutation } from '@apollo/client'

import {
  UPDATE_ONE_GENERIC_OPTION,
  UpdateGenericOptionPayload,
  UpdateGenericOptionVariables,
} from 'queries/generic-option'

const useGenericOptionMutate = () => {
  const [updateGenericOption, { loading: loadingUpdate }] = useMutation<
    UpdateGenericOptionPayload,
    UpdateGenericOptionVariables
  >(UPDATE_ONE_GENERIC_OPTION)

  return {
    loadingUpdate,
    updateGenericOption,
  }
}

export default useGenericOptionMutate
