import { useQuery } from '@apollo/client'

import {
  FIND_UNIQUE_SUPPLIER_ORDER_LOOKUP,
  FindUniqueSupplierOrderPayload,
  FindUniqueSupplierOrderVariables,
} from 'queries/supplier-order'

// to get ids for contexts
const useSupplierOrderLookup = (supplierOrderId: string) => {
  const { loading, data } = useQuery<
    FindUniqueSupplierOrderPayload,
    FindUniqueSupplierOrderVariables
  >(FIND_UNIQUE_SUPPLIER_ORDER_LOOKUP, {
    skip: !supplierOrderId,
    variables: {
      where: { id: supplierOrderId },
    },
  })

  return {
    loading,
    supplierOrder: data?.supplierOrder,
  }
}

export default useSupplierOrderLookup
