import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Projects from 'components/admin/project/index-many'
import useSalesDesigner from 'context/dashboard/sales-designer/use'
import ProjectManyProvider from 'context/project/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import SalesDesignerLayout from './layout'

const SalesDesignerProjects = (_: RouteComponentProps) => {
  const { userId, userName } = useSalesDesigner()

  return (
    <SalesDesignerLayout>
      <ProjectManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          assignedToRole: ProjectStaffRole.SALES_DESIGNER,
          sortBy: 'derivedActivity',
          sortDirection: 'descending',
          status: 'open',
          take: 30,
        }}
        skipLoader
      >
        <Projects />
      </ProjectManyProvider>
    </SalesDesignerLayout>
  )
}

export default SalesDesignerProjects
