import React, { createContext, useContext, useEffect, useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'

import useInfiniteQuery from 'hooks/use-infinite-query'
import {
  FIND_MANY_NOBILIA_SAMPLE_CLASS,
  FindManyNobiliaSampleClassPayload,
  FindManyNobiliaSampleClassVariables,
} from 'queries/nobilia-sample-class'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'

import { QueryFilters, getQueryVariables } from './utils-many'

interface INobiliaSampleClassManyContext {
  catalog: string
  count: number
  fetchedCount: number
  fetchMore: () => Promise<ApolloQueryResult<FindManyNobiliaSampleClassPayload>>
  loading: boolean
  loadingMore: boolean
  nobiliaSampleClasses: NobiliaSampleClass[]
  queryFilters: QueryFilters
  refetch: () => Promise<ApolloQueryResult<FindManyNobiliaSampleClassPayload>>
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

const NobiliaSampleClassManyContext =
  createContext<INobiliaSampleClassManyContext>(
    {} as INobiliaSampleClassManyContext,
  )

type NobiliaSampleClassManyProviderProps = {
  catalog?: string
  children: React.ReactNode
  defaultFilters: QueryFilters
}

const NobiliaSampleClassManyProvider = ({
  catalog,
  children,
  defaultFilters,
}: NobiliaSampleClassManyProviderProps) => {
  const [queryFilters, setQueryFilters] = useState(defaultFilters)
  const [queryVariables, setQueryVariables] =
    useState<FindManyNobiliaSampleClassVariables>(
      getQueryVariables(queryFilters),
    )

  const {
    data: { nobiliaSampleClasses, count } = {
      nobiliaSampleClasses: [] as NobiliaSampleClass[],
      count: 0,
    },
    loading,
    loadingMore,
    fetchMore,
    refetch,
    fetchedCount,
  } = useInfiniteQuery<
    FindManyNobiliaSampleClassPayload,
    FindManyNobiliaSampleClassVariables
  >(FIND_MANY_NOBILIA_SAMPLE_CLASS, {
    queryOptions: {
      variables: queryVariables,
    },
    cacheFieldName: 'findManyNobiliaSampleClass',
    fetchedPropertyName: 'nobiliaSampleClasses',
  })

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  return (
    <NobiliaSampleClassManyContext.Provider
      value={{
        catalog: catalog ?? '',
        count,
        fetchedCount,
        fetchMore: fetchMore as () => Promise<
          ApolloQueryResult<FindManyNobiliaSampleClassPayload>
        >,
        loading,
        loadingMore,
        nobiliaSampleClasses,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {children}
    </NobiliaSampleClassManyContext.Provider>
  )
}

export const useNobiliaSampleClassMany = () =>
  useContext(NobiliaSampleClassManyContext)

export default NobiliaSampleClassManyProvider
