import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import ProspectLayout from 'components/admin/prospect/layout'
import { FilePlaceholder } from 'components/shared/file-placeholder'
import UploadFileCard from 'components/welcome/prospect/project-file/upload-file-card'
import { isImageFile } from 'context/project-file/utils'
import useProspect from 'context/prospect/use'
import useProspectMutate from 'context/prospect/use-mutate'
import { ProjectFileType } from 'types/project-file'

const FloorPlans = (_: RouteComponentProps) => {
  const { prospect, prospectUploads } = useProspect()
  const { updateProspectAndRefetch } = useProspectMutate()

  return (
    <ProspectLayout>
      <Grid padded>
        <Grid.Row
          columns={4}
          stretched
          style={{ rowGap: '28px' }}
          verticalAlign="middle"
        >
          <Grid.Column>
            <UploadFileCard
              fileType={ProjectFileType.FLOOR_PLAN}
              onFilesUploaded={async () => {
                await updateProspectAndRefetch({
                  variables: {
                    data: {
                      data: {
                        ...prospect?.data,
                        floorPlanCompletedAt:
                          prospect?.data?.floorPlanCompletedAt ||
                          new Date().toISOString(),
                      },
                    },
                    where: {
                      id: prospect?.id ?? '',
                    },
                  },
                })
              }}
              prospectId={prospect?.id ?? ''}
            />
          </Grid.Column>
          {prospectUploads?.map((f, i) => (
            <Grid.Column key={i}>
              {isImageFile(f.name) ? (
                <ImageCard
                  image={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    f.key,
                  )}`}
                />
              ) : (
                <FilePlaceholder fileKey={f.key} />
              )}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </ProspectLayout>
  )
}

export default FloorPlans

const ImageCard = styled.div<{ image: string }>`
  background: url('${(props) => props.image}') no-repeat center;
  border-radius: 1px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  cursor: pointer;
  height: 100%;
  margin: 0;
  min-height: 270px;
  width: 100% !important;
`
