import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'

import CatalogLayout from 'components/admin/catalog/layout'
import { CatalogProvider } from 'context/catalog/provider'
import NobiliaOptionsProvider from 'context/planner/nobilia-options-provider'

interface CatalogProps extends RouteComponentProps<{ catalog_id: string }> {
  children: React.ReactNode
}

const Catalog = (props: CatalogProps) => {
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [props.location])

  useEffect(() => {
    return () => {
      window.history.replaceState(
        { ...history.state, keepScroll: true },
        null as any,
      )
    }
  }, [])

  return (
    <CatalogLayout>
      <NobiliaOptionsProvider catalog={props.catalog_id ?? ''}>
        <CatalogProvider catalog={props.catalog_id ?? ''}>
          {props.children}
        </CatalogProvider>
      </NobiliaOptionsProvider>
    </CatalogLayout>
  )
}

export default Catalog
