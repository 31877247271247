import React, { createContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { VisibilityCalculations } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import { apolloClient } from 'context/authorized-apollo/provider'
import {
  FindManyProjectPayload,
  FindManyProjectVariables,
  FIND_MANY_PROJECT,
} from 'queries/project'
import { Project } from 'types/project'

import { QueryFilters, getQueryVariables } from './utils-many'

interface IProjectManyContext {
  projects: Project[]
  count: number
  loading: boolean
  loadingMore: boolean
  fetchMore: (v: VisibilityCalculations) => Promise<void>
  refetch: () => Promise<unknown>
  queryFilters: QueryFilters
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

type ProjectManyProviderProps = React.PropsWithChildren<{
  defaultFilters?: QueryFilters
  skipLoader?: boolean
}>

export const ProjectManyContext = createContext<IProjectManyContext>(
  {} as IProjectManyContext,
)

const ProjectManyProvider = ({
  children,
  defaultFilters,
  skipLoader,
}: ProjectManyProviderProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [queryFilters, setQueryFilters] = useState(
    defaultFilters as QueryFilters,
  )
  const [queryVariables, setQueryVariables] =
    useState<FindManyProjectVariables>(
      getQueryVariables(queryFilters as QueryFilters),
    )

  const {
    data: { projects, count } = { projects: [], count: 0 },
    loading,
    fetchMore,
  } = useQuery<FindManyProjectPayload, FindManyProjectVariables>(
    FIND_MANY_PROJECT,
    {
      variables: queryVariables,
    },
  )

  useEffect(() => {
    // this effect triggers on refetch, something to do with the cache eviction
    // triggering a higher level re-render.
    // To avoid losing filters on refetch we add the refetching check here
    if (!refetching) {
      setQueryFilters(defaultFilters as QueryFilters)
    }
    setRefetching(false)
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  const fetchMore_ = async ({ bottomVisible }: VisibilityCalculations) => {
    if (loading || loadingMore || !bottomVisible || projects?.length === count)
      return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: projects?.length,
      },
    })
    setLoadingMore(false)
  }

  const refetch = async () => {
    setRefetching(true)
    // evict and refetch first batch
    apolloClient?.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'findManyProject',
    })
  }

  if (loading && !skipLoader) return <Loader />

  return (
    <ProjectManyContext.Provider
      value={{
        projects: projects ?? [],
        count: count ?? 0,
        loading,
        loadingMore,
        fetchMore: fetchMore_,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {children}
    </ProjectManyContext.Provider>
  )
}

export default ProjectManyProvider
