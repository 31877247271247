// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, Grid, Portal } from 'semantic-ui-react'
import styled from 'styled-components'

import useDesign from 'context/planner/design-use'
import { StyledPortal } from 'styles/planner/main'
import { Colors, StyledContent } from 'styles/planner/system'

import GlobalOptionsMenu from './create-menus/global-options'
import { Preset } from '../../utils/preset-utils'

const PresetCreatePanel = () => {
  const { design, presets, presetsCount, updateDesign } = useDesign()

  const [activeIndex, setActiveIndex] = useState<number>()
  const [unlockedPresets, setUnlockedPresets] = useState<Preset[]>([])
  const [showNewPreset, toggleNewPreset] = useState<boolean>(!presetsCount)

  return (
    <>
      {showNewPreset ? (
        <>
          {!presetsCount ? (
            <Button
              className="white"
              floated="right"
              icon={<FontAwesomeIcon icon={['fal', 'times']} />}
              onClick={() => toggleNewPreset(false)}
              style={{ padding: '6px 9px' }}
            />
          ) : null}
          <GlobalOptionsMenu
            options={[]}
            onSave={() => toggleNewPreset(false)}
            presetIdentifier={
              !presetsCount ? `default` : `${Object.keys(presets).length}`
            }
          />
        </>
      ) : (
        <Grid style={{ marginLeft: '-20px', marginRight: '-20px' }}>
          <Grid.Row>
            <Grid.Column>
              <Button
                className="small blue"
                floated="right"
                onClick={() => toggleNewPreset(true)}
              >
                New <FontAwesomeIcon icon={['fal', 'plus']} />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '0' }}>
            <Grid.Column>
              <StyledAccordion
                exclusive={false}
                fluid
                styled
                activeIndex={[activeIndex]}
                panels={presets.map((p, idx) => ({
                  key: p,
                  title: {
                    icon: null,
                    content: (
                      <>
                        <FontAwesomeIcon
                          className="drop"
                          icon={[
                            'fal',
                            activeIndex === idx
                              ? 'chevron-down'
                              : 'chevron-right',
                          ]}
                        />
                        <p>{p.name}</p>
                        <Button
                          className={
                            unlockedPresets.some(
                              (pr) => pr.identifier === p.identifier,
                            )
                              ? 'white'
                              : 'orange'
                          }
                          floated="right"
                          icon={
                            <FontAwesomeIcon
                              icon={[
                                'fal',
                                unlockedPresets.some(
                                  (pr) => pr.identifier === p.identifier,
                                )
                                  ? 'unlock'
                                  : 'lock',
                              ]}
                            />
                          }
                          onClick={(e) => {
                            e.stopPropagation()
                            setUnlockedPresets(
                              unlockedPresets.some(
                                (pr) => pr.identifier === p.identifier,
                              )
                                ? unlockedPresets.filter(
                                    (u) => u.identifier !== p.identifier,
                                  )
                                : [...unlockedPresets, p],
                            )
                          }}
                        />
                        {p.identifier !== 'default' &&
                          unlockedPresets.some(
                            (pr) => pr.identifier === p.identifier,
                          ) && (
                            <DeleteModal
                              onDelete={async () => {
                                await updateDesign({
                                  data: {
                                    nobiliaOptions: {
                                      delete: p.options.map((o) => ({
                                        nobiliaOptionId_designId_identifier: {
                                          nobiliaOptionId: o.id,
                                          designId: design.id,
                                          identifier: p.identifier,
                                        },
                                      })),
                                    },
                                  },
                                  where: {
                                    id: design.id,
                                  },
                                })
                              }}
                            />
                          )}
                      </>
                    ),
                  },
                  content: {
                    content: (
                      <GlobalOptionsMenu
                        locked={
                          !unlockedPresets.some(
                            (pr) => pr.identifier === p.identifier,
                          )
                        }
                        options={p.options}
                        presetIdentifier={p.identifier}
                      />
                    ),
                  },
                }))}
                onTitleClick={(
                  _: React.MouseEvent<HTMLDivElement>,
                  { index }: { index: number },
                ) => {
                  setActiveIndex(activeIndex === index ? null : index)
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  )
}

export default PresetCreatePanel

const DeleteModal = ({ onDelete }: { onDelete: () => Promise<void> }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, toggleModal] = useState<boolean>(false)

  return (
    <Portal
      closeOnTriggerClick
      openOnTriggerClick
      onClose={() => toggleModal(false)}
      onOpen={() => toggleModal(true)}
      trigger={
        <Button
          className="white delete"
          floated="right"
          icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
          onClick={(e) => e.stopPropagation()}
        />
      }
      open={showModal}
    >
      <StyledContent>
        <StyledPortal style={{ maxWidth: '450px' }}>
          <div className="header">
            <p>Delete Preset</p>
          </div>
          <div className="delete-content">
            <h1>Are you sure you want to delete this preset?</h1>
            <p className="gray">
              Once you delete it all the cabinets with this preset will be
              changed to default
            </p>
          </div>
          <div className="actions">
            <Button
              className="white"
              content="Cancel"
              onClick={() => toggleModal(false)}
              style={{ marginRight: '14px' }}
            />
            <Button
              className="orange"
              content="Delete"
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await onDelete()
                setLoading(false)
                toggleModal(false)
              }}
            />
          </div>
        </StyledPortal>
      </StyledContent>
    </Portal>
  )
}

export const StyledAccordion = styled(Accordion)`
  background: ${Colors.gray} !important;
  border: 1px solid ${Colors.gray300};
  border-radius: 0 !important;
  box-shadow: none !important;
  transition: all 0.3s ease-in-out;

  .title {
    align-items: center;
    display: flex;
    padding: 0 !important;

    p {
      border-left: 1px solid #d6d3d1;
      margin: 0;
      padding: 10px;
    }

    svg.drop {
      font-size: 11px;
      margin: 14px;
      min-width: 10px;
    }

    i {
      display: none !important;
    }

    button {
      border-radius: 0;
      min-width: 24px;
      padding: 5px !important;
      position: absolute;
      right: 24px;

      svg {
        font-size: 13px;
      }
    }

    button.delete {
      right: 60px;
    }

    :hover {
      background: #e6e2e0 !important;
    }
  }

  .active.title {
    background: #e6e2e0 !important;
    border-bottom: 1px solid ${Colors.gray300};
  }

  .content {
    background: ${Colors.gray100};
    padding: 14px 14px 25px !important;
  }
`
