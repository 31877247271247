import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import UserIndexMany from 'components/admin/user/index-many'
import UserManyProvider from 'context/user/provider-many'

const Users = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="Users" />
        <Grid.Row>
          <Grid.Column>
            <UserManyProvider
              defaultFilters={{
                sortBy: 'email',
                sortDirection: 'ascending',
                take: 40,
              }}
              skipLoader
            >
              <UserIndexMany />
            </UserManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Users
