import { useContext, useMemo } from 'react'

import { parseFiles } from 'context/project-file/utils-many'

import { SupplierOrderContext } from './provider'
import * as utils from './utils'

// Important: all functions must support undefined supplier order!
const useSupplierOrder = () => {
  const { supplierOrder, refetch } = useContext(SupplierOrderContext)
  const parsedActions = useMemo(
    () => utils.parseActions(supplierOrder),
    [supplierOrder?.actions],
  )

  const guaranteedOnOrBefore = useMemo(
    () => utils.getGuaranteedOnOrBefore(supplierOrder),
    [supplierOrder?.data],
  )

  const parsedFiles = useMemo(
    () => parseFiles(supplierOrder?.files || []),
    [supplierOrder?.files],
  )

  return {
    ...parsedActions,
    ...parsedFiles,
    guaranteedOnOrBefore,
    supplierOrder,
    refetch,
  }
}

export default useSupplierOrder
