import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Button, Divider, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import ProspectLayout from 'components/admin/prospect/layout'
import CarouselModal from 'components/shared/carousel/carousel-modal'
import { getReferenceImage } from 'context/media/utils-many'
import useProspect from 'context/prospect/use'
import StyledAccordion from 'design-system/components/accordion'
import { Colors } from 'styles/app/system'

const QuizResults = (_: RouteComponentProps) => {
  const [elementsIndex, setElementsIndex] = useState<number | null>(null)

  const {
    budgetAnswer,
    catalogFeatures,
    inspirationGenerals,
    inspirationMoodboards,
    motivationAnswer,
    priorityAnswer,
    progressAnswer,
    prospectPhotos,
    timelineAnswer,
  } = useProspect()

  const inspirationAnswer = inspirationGenerals.length
    ? inspirationGenerals
    : inspirationMoodboards
  const accessories = catalogFeatures
  const showPriorityAccordion = !!priorityAnswer?.every((a) => a.image)

  return (
    <ProspectLayout>
      <StyledAccordion backgroundWhite titleText="What is driving the project?">
        <Grid>
          <Grid.Row columns={3} stretched className="row-gap">
            {motivationAnswer?.map((a, key) => (
              <Grid.Column key={key}>
                <Answer className="border">
                  <p>{a.value}</p>
                </Answer>
              </Grid.Column>
            ))}
          </Grid.Row>
          <Divider />
          <Grid.Row columns={4} stretched>
            {!showPriorityAccordion ? (
              <Grid.Column>
                <Answer>
                  <p>
                    <b>What’s most important to you?</b>
                  </p>
                  {priorityAnswer?.map((a, key) => (
                    <div className="list-item" key={key}>
                      <div className="check-icon">
                        <FontAwesomeIcon icon={['fal', 'check']} />
                      </div>
                      <p>{a.value}</p>
                    </div>
                  ))}
                </Answer>
              </Grid.Column>
            ) : null}
            <Grid.Column>
              <Answer>
                <p className="subtitle">What have you done so far?</p>
                {progressAnswer?.map((a, key) => (
                  <div className="list-item" key={key}>
                    <div className="check-icon">
                      <FontAwesomeIcon icon={['fal', 'check']} />
                    </div>
                    <p>{a.value}</p>
                  </div>
                ))}
              </Answer>
            </Grid.Column>
            <Grid.Column>
              <Answer style={{ justifyContent: 'flex-start' }}>
                <p className="subtitle">Budget and timeline</p>
                <div className="list-item">
                  <div className="price-icon">
                    <FontAwesomeIcon icon={['fal', 'lightbulb-dollar']} />
                  </div>
                  <p>{budgetAnswer?.value}</p>
                </div>
                <div className="list-item">
                  <div className="time-icon">
                    <FontAwesomeIcon icon={['fal', 'calendar-day']} />
                  </div>
                  <p>{timelineAnswer?.value}</p>
                </div>
              </Answer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </StyledAccordion>
      {showPriorityAccordion ? (
        <StyledAccordion
          backgroundWhite
          titleText="What’s most important to you?"
        >
          <Grid>
            <Grid.Row columns={4} style={{ rowGap: '28px' }}>
              {priorityAnswer?.map((p, i) => (
                <Grid.Column key={i} textAlign="center">
                  <Image
                    src={`${process.env.GATSBY_CDN_ROOT}/images/onboarding/priority/${p.image}`}
                    style={{ marginBottom: '16px' }}
                  />
                  <p>{p.value}</p>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </StyledAccordion>
      ) : null}
      <StyledAccordion backgroundWhite titleText="Styles you are drawn to">
        <Grid>
          <Grid.Row columns={4} style={{ rowGap: '28px' }}>
            {inspirationAnswer?.map((e, i) => (
              <Grid.Column key={i}>
                <Image
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    String(e.data?.imageUrl),
                  )}`}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </StyledAccordion>
      {accessories.length ? (
        <StyledAccordion
          backgroundWhite
          titleText="Elements you are drawn to"
          titleExtras={
            <CarouselModal
              currentIdx={elementsIndex}
              images={accessories?.map((e) => getReferenceImage(e.media))}
              onClose={() => setElementsIndex(null)}
              openModal={elementsIndex !== null}
              trigger={
                <Button
                  className="orange"
                  disabled={!prospectPhotos.length}
                  onClick={() => setElementsIndex(0)}
                >
                  View all
                  <FontAwesomeIcon
                    icon={['fal', 'arrow-up-right-from-square']}
                    style={{ marginLeft: '10px' }}
                  />
                </Button>
              }
            />
          }
        >
          <Grid>
            <Grid.Row columns={4} style={{ rowGap: '28px' }}>
              {accessories?.map((e, i) => (
                <Grid.Column key={i}>
                  <Image
                    src={getReferenceImage(e.media)}
                    onClick={() => setElementsIndex(i)}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </StyledAccordion>
      ) : null}
    </ProspectLayout>
  )
}

export default QuizResults

const Answer = styled.div`
  &.border {
    align-items: center;
    border: 1px solid ${Colors.gray200};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 16px;
  }

  p {
    font-size: 18px;
  }

  div.list-item {
    align-items: center;
    display: flex;
    gap: 11px;
    margin-bottom: 16px;
    width: 100%;

    p {
      font-size: 16px;
      text-align: left;
    }

    .check-icon,
    .price-icon,
    .time-icon {
      align-items: center;
      background: ${Colors.greenBright};
      border-radius: 50%;
      display: flex;
      font-size: 10px;
      height: 17px !important;
      justify-content: center;
      min-width: 17px !important;

      svg {
        color: ${Colors.white};
      }
    }

    .price-icon {
      background: ${Colors.blueSelect};
    }

    .time-icon {
      background: ${Colors.orange500};
    }
  }
`
