import { useState } from 'react'

import { useMutation } from '@apollo/client'

import { notifyError } from 'context/notifications/trigger'
import {
  CREATE_PROJECT_CHECKLIST_ITEM,
  CreateProjectChecklistItemPayload,
  CreateProjectChecklistItemVariables,
  DELETE_PROJECT_CHECKLIST_ITEM,
  DeleteProjectChecklistItemPayload,
  DeleteProjectChecklistItemVariables,
} from 'queries/project-checklist-items'

import { useProjectChecklist } from './provider'
import useProject from '../use'

const useProjectChecklistItemMutate = () => {
  const { project } = useProject()
  const { refetch } = useProjectChecklist()
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const [createMutation] = useMutation<
    CreateProjectChecklistItemPayload,
    CreateProjectChecklistItemVariables
  >(CREATE_PROJECT_CHECKLIST_ITEM, {
    onError: ({ message }) => {
      notifyError(message)
    },
  })

  const [deleteMutation] = useMutation<
    DeleteProjectChecklistItemPayload,
    DeleteProjectChecklistItemVariables
  >(DELETE_PROJECT_CHECKLIST_ITEM, {
    onError: ({ message }) => {
      notifyError(message)
    },
  })

  const createChecklistItem = async (
    data: Omit<CreateProjectChecklistItemVariables['data'], 'project'>,
  ) => {
    setLoadingCreate(true)

    await createMutation({
      variables: {
        data: {
          ...data,
          project: {
            connect: { id: project.id },
          },
        },
      },
    })

    await refetch()

    setLoadingCreate(false)
  }

  const deleteChecklistItem = async (id: string) => {
    setLoadingDelete(true)

    await deleteMutation({
      variables: {
        where: {
          id,
        },
      },
    })

    await refetch()

    setLoadingDelete(false)
  }

  return {
    createChecklistItem,
    deleteChecklistItem,
    loadingCreate,
    loadingDelete,
  }
}

export default useProjectChecklistItemMutate
