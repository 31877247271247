import { useContext } from 'react'

import { MediaManyContext } from './provider-many'

const useMediaMany = () => {
  const {
    media,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(MediaManyContext)

  return {
    media,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useMediaMany
