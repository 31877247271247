import { useContext } from 'react'

import { ProjectSupplierOrderManyContext } from './provider-many'

const useProjectSupplierOrderMany = () => {
  const { supplierOrders, loading, refetch } = useContext(
    ProjectSupplierOrderManyContext,
  )

  return {
    loading,
    refetch,
    supplierOrders,
  }
}

export default useProjectSupplierOrderMany
