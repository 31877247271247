import { useContext, useMemo } from 'react'

import { Shipment } from 'types/shipment'

import { ShipmentContext } from './provider'
import * as utils from './utils'

// Important: all functions must support undefined shipment!
const useShipment = () => {
  const { shipment, refetch } = useContext(ShipmentContext)

  const parsedSupplierOrders = useMemo(
    () => utils.parseSupplierOrders(shipment as Shipment),
    [shipment?.supplierOrders],
  )

  return {
    ...parsedSupplierOrders,
    shipment,
    refetch,
  }
}

export default useShipment
