import React from 'react'

import { Grid } from 'semantic-ui-react'

import Tasks from 'components/admin/project/task/tasks'
import { Task } from 'types/task'

const DashboardTasks = ({
  refetch,
  tasksCompleted,
  tasksToDo,
  tasksUrgent,
}: {
  refetch: () => Promise<unknown>
  tasksCompleted: Task[]
  tasksToDo: Task[]
  tasksUrgent: Task[]
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={8} tablet={16}>
          <Tasks tasks={tasksUrgent} title="Urgent" refetch={refetch} />
        </Grid.Column>
        <Grid.Column computer={8} tablet={16}>
          <Tasks tasks={tasksToDo} title="Others" refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={8} tablet={16}>
          <Tasks
            completedTasks
            refetch={refetch}
            tasks={tasksCompleted}
            title="Completed"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default DashboardTasks
