import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import UserTab from 'components/admin/user/tab'
import Loader from 'components/shared/loader'
import useUserUnique from 'context/user/use-unique'
import Button from 'design-system/components/button'

const UserView = (props: RouteComponentProps<{ user_id: string }>) => {
  const { user, loading } = useUserUnique({ id: props.user_id })

  if (loading) return <Loader />

  return (
    <AdminLayout>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Button
              fontAwesomeIcon="arrow-left"
              iconPosition="left"
              color="gray"
              kind="text"
              text="Go back to Users"
              onClick={() => navigate('/admin/users')}
            />
          </Grid.Column>
        </Grid.Row>
        <SectionTitle title="User Information" />
        <Grid.Row>
          <Grid.Column>
            <UserTab user={user} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default UserView
