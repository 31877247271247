import React from 'react'

import { Button, Image, List } from 'semantic-ui-react'

import { StyledLabel, TableUserListItem } from 'styles/admin/main'
import { User } from 'types/user'
import { getNameForUser } from 'views/utils'

const PersonListItem = ({
  linkText,
  linkUrl,
  isPrimary,
  onRemove,
  onUpdate,
  removeDisabled,
  type,
  user,
}: {
  linkText?: string
  linkUrl?: string
  isPrimary?: boolean
  onRemove?: () => void
  onUpdate?: () => void
  removeDisabled?: boolean
  type?: string
  user: Partial<User>
}) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <TableUserListItem style={{ marginBottom: '10px' }}>
        <Image
          avatar
          circular
          src={
            user.picture ||
            'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon&f=y'
          }
          style={{
            minHeight: '35px',
            minWidth: '35px',
            marginRight: '10px',
          }}
        />
        <List.Content>
          <List.Header>
            {getNameForUser(user as User)}
            {isPrimary ? (
              <StyledLabel
                style={{ marginLeft: '5px' }}
                styled={{ green: true }}
              >
                Primary
              </StyledLabel>
            ) : null}
          </List.Header>
          <List.Description>{type}</List.Description>
          {!!user.company && (
            <List.Description>{user.company.name}</List.Description>
          )}
          <List.Description>{user.email}</List.Description>
          {!!linkText && (
            <List.Description>
              <a
                style={{ fontSize: '14px', textDecoration: 'underline' }}
                href={linkUrl}
                target="_blank"
              >
                {linkText}
              </a>
            </List.Description>
          )}
          {user.phone && <List.Description>{user.phone}</List.Description>}
          {user.data?.companyName && (
            <List.Description>{user.data.companyName}</List.Description>
          )}
        </List.Content>
      </TableUserListItem>
      {onUpdate && (
        <Button basic content="Update" onClick={onUpdate} size="tiny" />
      )}
      {onRemove && (
        <Button
          basic
          disabled={removeDisabled}
          content="Remove"
          onClick={onRemove}
          size="mini"
        />
      )}
    </div>
  )
}

export default PersonListItem
