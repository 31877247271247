import React from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import Prospects from 'components/admin/prospect/index-many'
import SectionTitle from 'components/admin/section-title'
import Tracking from 'components/shared/tracking'
import ProspectManyProvider from 'context/prospect/provider-many'

const ProspectsView = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Tracking />
      <Grid>
        <SectionTitle title="Prospects" />
        <Grid.Row>
          <Grid.Column>
            <ProspectManyProvider
              defaultFilters={{
                meetingTime: {
                  gte: moment().startOf('week').toISOString(),
                  lte: moment().endOf('week').toISOString(),
                },
                sortBy: 'derivedMeeting',
                sortDirection: 'ascending',
                take: 30,
              }}
              skipLoader
            >
              <Prospects />
            </ProspectManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default ProspectsView
