import React from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import useOrderSamplesMany from 'context/order/use-many-samples'

import OrderSamplesTable from './table'
import useOrdersSamplesFiltersSearch from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const OrdersSamples = () => {
  const { count, orders, fetchMore, loading, loadingMore } =
    useOrderSamplesMany()

  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useOrdersSamplesFiltersSearch()
  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${orders?.length} of ${count}`} Orders
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            <OrderSamplesTable />
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && orders?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default OrdersSamples
