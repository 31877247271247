import React from 'react'

import { Form } from 'semantic-ui-react'

import { StyledForm } from 'styles/admin/main'

import { State } from '.'
import { timezoneOptions } from '../utils'

type InfoTabProps = {
  loading: boolean
  setState: React.Dispatch<React.SetStateAction<NullableRecord<State>>>
  state: NullableRecord<State>
}

const InfoTab = ({ loading, setState, state }: InfoTabProps) => {
  const onInputChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: value })
  }

  const onDataInputChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    })
  }

  return (
    <StyledForm>
      <Form.Group widths="equal">
        <Form.Input
          label="Name"
          name="name"
          onChange={onInputChange}
          value={state.name ?? ''}
          disabled={loading}
          required
        />
        <Form.Input
          label="Identifier"
          name="identifier"
          onChange={onInputChange}
          value={state.identifier ?? ''}
          disabled={loading}
          required
        />
        <Form.Dropdown
          search
          fluid
          selection
          label="Timezone"
          onChange={(_, { value }) =>
            setState({
              ...state,
              data: {
                ...state.data,
                timezone: value as string,
              },
            })
          }
          options={timezoneOptions}
          value={state.data?.timezone}
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Calendly URL"
          name="calendlyUrl"
          onChange={onDataInputChange}
          value={state.data?.calendlyUrl ?? ''}
          disabled={loading}
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Address"
          name="address"
          onChange={onDataInputChange}
          value={state.data?.address ?? ''}
          disabled={loading}
          required
        />
        <Form.Input
          label="Location URL"
          name="locationUrl"
          onChange={onDataInputChange}
          value={state.data?.locationUrl ?? ''}
          disabled={loading}
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Illustration Path"
          name="illustration"
          placeholder="eg. images/selection-center/illustrations/sc.svg"
          onChange={onDataInputChange}
          value={state.data?.illustration ?? ''}
          disabled={loading}
          required
        />
      </Form.Group>
    </StyledForm>
  )
}

export default InfoTab
