import React, { useState, useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import sanitize from 'sanitize-filename'

import { CardLoader } from 'components/shared/loader'
import { ProjectFileCard } from 'components/shared/project/project-file/upload-card'
import { apolloClient } from 'context/authorized-apollo/provider'
import { convertHeicToJpeg } from 'context/project-file/utils'
import Button from 'design-system/components/button'
import {
  apiResultToAWSFormData,
  GET_MEDIA_FILE_UPLOAD_URL,
  GetMediaFileUploadUrlPayload,
  GetMediaFileUploadUrlVariables,
} from 'queries/aws'

interface UploadFileCardProps {
  onFileUploaded: (data: { key: string; name: string }) => void
}

const UploadFileCard = ({ onFileUploaded }: UploadFileCardProps) => {
  return (
    <UploadCard
      onFileUploaded={onFileUploaded}
      uploadFile={async (file) =>
        await uploadImage({
          imgFile: file,
        })
      }
    />
  )
}

export default UploadFileCard

const UploadCard = ({
  onFileUploaded,
  uploadFile,
}: {
  onFileUploaded?: (data: { key: string; name: string }) => void
  uploadFile: (file: File) => Promise<{ key: string; name: string }>
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setLoading(true)
    const data = await uploadFile(acceptedFiles[0])
    setLoading(false)
    onFileUploaded?.(data)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <ProjectFileCard
      {...getRootProps()}
      styled={{
        isDragActive: isDragActive,
        disabled: loading,
      }}
    >
      {loading && (
        <CardLoader active={loading}>
          <p>Uploading</p>
        </CardLoader>
      )}
      <div className="content">
        <FontAwesomeIcon icon={['fal', 'image-polaroid']} />
        <div className="only-computer">
          <p>Drag a file here or</p>
        </div>
        <div className="down-computer">
          <p>Add file here</p>
        </div>
        <Button
          disabled={loading || isDragActive}
          color="blue"
          kind="solid"
          size="fluid"
          text="Select files"
        />
      </div>
      <input disabled={loading} hidden type="file" {...getInputProps()} />
    </ProjectFileCard>
  )
}

export const uploadImage = async ({ imgFile }: { imgFile: File }) => {
  let file = imgFile
  if (
    file.type === 'image/heic' ||
    file.name.toLocaleLowerCase().endsWith('heic')
  )
    file = await convertHeicToJpeg(imgFile)
  const fileRemovedSpecialCharacters = file.name.replace(
    /[^a-zA-Z0-9.\-_]/g,
    '',
  )
  const fileNameSanitized = sanitize(fileRemovedSpecialCharacters)

  let url = ''
  let dataFields: Record<string, string | Blob> = {}

  const res = await apolloClient?.mutate<
    GetMediaFileUploadUrlPayload,
    GetMediaFileUploadUrlVariables
  >({
    mutation: GET_MEDIA_FILE_UPLOAD_URL,
    variables: {
      data: { fname: fileNameSanitized },
    },
  })

  if (res?.data?.uploadUrlMediaFile.url) {
    url = res.data?.uploadUrlMediaFile.url
  }

  dataFields = apiResultToAWSFormData(res?.data?.uploadUrlMediaFile ?? {})

  const data = new FormData()
  Object.keys(dataFields).forEach((k) => {
    data.append(k, dataFields[k])
  })
  data.append('acl', 'public-read')
  data.append('Content-Type', file.type)
  data.append('file', file)

  const uploadRes = await axios.post(url, data)

  if (uploadRes.status === 204) {
    return {
      key: res?.data?.uploadUrlMediaFile.key ?? '',
      name: file.name,
    }
  }
  throw new Error(
    `[${uploadRes.status}] Upload image failed: ${uploadRes.statusText}`,
  )
}
