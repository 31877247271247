import React, { useEffect, useState } from 'react'

import {
  ChecklistTab,
  useProjectChecklist,
} from 'context/project/checklist/provider'
import OverlayPanel from 'design-system/components/overlay-panel'
import Tabs, { TabContent } from 'design-system/components/tabs'

import { DelivaryTab, DesignTab, RefineTab } from './utils'

type ProjectChecklistPanelProps = {
  open: boolean
  onClose: () => void
}

const tabs: { value: ChecklistTab; label: string }[] = [
  { value: 'design', label: '1 Design' },
  { value: 'refine', label: '2 Refine' },
  { value: 'delivery', label: '3 Delivery' },
]

const tabMap: Record<ChecklistTab, number> = {
  design: 0,
  refine: 1,
  delivery: 2,
}

const ProjectChecklistPanel = ({
  onClose,
  open,
}: ProjectChecklistPanelProps) => {
  const { initialTab, setScrollPosition, scrollPosition } =
    useProjectChecklist()
  const [currentTab, setCurrentTab] = useState<ChecklistTab>(initialTab)
  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null)

  const handleTabClick = (_: unknown, { value }: { value: ChecklistTab }) => {
    setCurrentTab(value)
  }

  useEffect(() => {
    setCurrentTab(initialTab)
  }, [initialTab])

  useEffect(() => {
    if (panelRef) {
      if (open) {
        panelRef.scrollTo({
          top: scrollPosition,
          behavior: 'auto',
        })
      }

      if (!open) {
        setScrollPosition(panelRef.scrollTop)
      }
    }
  }, [open, panelRef])

  return (
    <OverlayPanel
      handleClose={onClose}
      open={open}
      unmountOnExit
      panelRef={setPanelRef}
    >
      <Tabs
        tabs={tabs}
        currentTab={tabMap[currentTab]}
        handleClick={handleTabClick}
        variant="plain"
      />

      <div tabIndex={-1} style={{ pointerEvents: 'none', marginBottom: 20 }} />

      <TabContent active={currentTab === 'design'}>
        <DesignTab />
      </TabContent>
      <TabContent active={currentTab === 'refine'}>
        <RefineTab />
      </TabContent>
      <TabContent active={currentTab === 'delivery'}>
        <DelivaryTab />
      </TabContent>
    </OverlayPanel>
  )
}

export default ProjectChecklistPanel
