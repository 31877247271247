import { useContext } from 'react'

import useDesignMutate from 'context/design/use-mutate'
import { CatalogFeature } from 'types/catalog-feature'
import {
  DisconnectedOptionDesign,
  GenericOptionDesign,
  RoomElementOptionDesign,
} from 'types/design'
import { DisconnectedOption } from 'types/disconnected-option'
import { GenericOption } from 'types/generic-option'
import { NobiliaOption } from 'types/nobilia-option'
import { RenderProp } from 'types/render-prop'
import { RoomElementOption } from 'types/room-element-option'

import { DesignContext } from './provider'

const useDesignOptionsMutate = () => {
  const { design } = useContext(DesignContext)
  const { updateDesignAndRefetch } = useDesignMutate()

  const onSaveNobiliaOptions = async (
    nobiliaOptions: Partial<NobiliaOption>[],
    prev: Partial<NobiliaOption>[],
    groupIdentifier?: string,
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          nobiliaOptions: {
            delete: prev.map((no) => ({
              nobiliaOptionId_designId_identifier: {
                identifier: groupIdentifier || 'default',
                nobiliaOptionId: no.id ?? '',
                designId: design?.id ?? '',
              },
            })),
            create: nobiliaOptions.map((no) => ({
              identifier: groupIdentifier || 'default',
              nobiliaOption: {
                connect: {
                  id: no.id ?? '',
                },
              },
            })),
          },
        },
      },
    })
  }

  const onSaveGenericOptions = async (
    genericOptions: Partial<GenericOption>[],
    prev: Partial<GenericOption>[],
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          genericOptions: {
            delete: prev
              .filter((o) => o.optionIdentifier !== 'custom')
              .map((go) => ({
                genericOptionId_designId_identifier: {
                  identifier: 'default',
                  genericOptionId: go.id ?? '',
                  designId: design?.id ?? '',
                },
              })),
            create: genericOptions.map((go) => ({
              identifier: 'default',
              genericOption: {
                connect: {
                  id: go.id ?? '',
                },
              },
            })),
          },
        },
      },
    })
  }

  const onSaveCustomGenericOption = async (
    gor: Partial<GenericOptionDesign>,
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          genericOptions: {
            create: [
              {
                identifier: gor.projectFile?.id ?? '',
                genericOption: {
                  connect: {
                    id: gor.genericOption?.id ?? '',
                  },
                },
                data: gor.data,
                projectFile: {
                  connect: {
                    id: gor.projectFile?.id ?? '',
                  },
                },
              },
            ],
          },
        },
      },
    })
  }

  const onDeleteGenericOption = async (
    genericOption: Partial<GenericOption>,
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          genericOptions: {
            delete: [
              {
                genericOptionId_designId_identifier: {
                  identifier: genericOption.data?.fileId || 'default',
                  genericOptionId: genericOption.id ?? '',
                  designId: design?.id ?? '',
                },
              },
            ],
          },
        },
      },
    })
  }

  const onSaveRoomElementOptions = async (
    roomElements: Partial<RoomElementOption>[],
    prev: Partial<RoomElementOption>[],
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          roomElementOptions: {
            delete: prev
              .filter((o) => o.optionIdentifier !== 'custom')
              .map((re) => ({
                roomElementOptionId_designId_identifier: {
                  identifier: 'default',
                  roomElementOptionId: re.id ?? '',
                  designId: design?.id ?? '',
                },
              })),
            create: roomElements.map((re) => ({
              identifier: 'default',
              roomElementOption: {
                connect: {
                  id: re.id ?? '',
                },
              },
            })),
          },
        },
      },
    })
  }

  const onSaveCustomRoomElementOption = async (
    reo: Partial<RoomElementOptionDesign>,
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          roomElementOptions: {
            create: [
              {
                identifier: reo.projectFile?.id ?? '',
                roomElementOption: {
                  connect: {
                    id: reo.roomElementOption?.id ?? '',
                  },
                },
                data: reo.data,
                projectFile: {
                  connect: {
                    id: reo.projectFile?.id ?? '',
                  },
                },
              },
            ],
          },
        },
      },
    })
  }

  const onDeleteRoomElementOption = async (
    roomElement: Partial<RoomElementOption>,
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          roomElementOptions: {
            delete: [
              {
                roomElementOptionId_designId_identifier: {
                  identifier: roomElement.data?.fileId || 'default',
                  roomElementOptionId: roomElement?.id ?? '',
                  designId: design?.id ?? '',
                },
              },
            ],
          },
        },
      },
    })
  }

  const onSaveCatalogFeatures = async (
    catalogFeatures: Partial<CatalogFeature>[],
    prev: Partial<CatalogFeature>[],
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          catalogFeatures: {
            delete: prev.map((cf) => ({
              catalogFeatureId_designId_identifier: {
                identifier: 'default',
                catalogFeatureId: cf.id ?? '',
                designId: design?.id ?? '',
              },
            })),
            create: catalogFeatures.map((cf) => ({
              identifier: 'default',
              catalogFeature: {
                connect: {
                  id: cf.id ?? '',
                },
              },
            })),
          },
        },
      },
    })
  }

  const onSaveDisconnectedOptions = async (
    disconnectedOptions: Partial<DisconnectedOption>[],
    prev: Partial<DisconnectedOption>[],
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          disconnectedOptions: {
            delete: prev
              .filter((o) => o.optionIdentifier !== 'custom')
              .map((d) => ({
                disconnectedOptionId_designId_identifier: {
                  identifier: 'default',
                  disconnectedOptionId: d.id ?? '',
                  designId: design?.id ?? '',
                },
              })),
            create: disconnectedOptions.map((d) => ({
              identifier: 'default',
              disconnectedOption: {
                connect: {
                  id: d.id ?? '',
                },
              },
            })),
          },
        },
      },
    })
  }

  const onSaveCustomDisconnectedOption = async (
    reo: Partial<DisconnectedOptionDesign>,
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          disconnectedOptions: {
            create: [
              {
                identifier: reo.projectFile?.id ?? '',
                disconnectedOption: {
                  connect: {
                    id: reo.disconnectedOption?.id ?? '',
                  },
                },
                data: reo.data,
                projectFile: {
                  connect: {
                    id: reo.projectFile?.id ?? '',
                  },
                },
              },
            ],
          },
        },
      },
    })
  }

  const onDeleteDisconnectedOption = async (
    disconnectedOption: Partial<DisconnectedOption>,
  ) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          disconnectedOptions: {
            delete: [
              {
                disconnectedOptionId_designId_identifier: {
                  identifier: disconnectedOption.data?.fileId || 'default',
                  disconnectedOptionId: disconnectedOption?.id ?? '',
                  designId: design?.id ?? '',
                },
              },
            ],
          },
        },
      },
    })
  }

  const onSaveRenderProps = async (renderProps: RenderProp[]) => {
    await updateDesignAndRefetch({
      variables: {
        where: {
          id: design?.id ?? '',
        },
        data: {
          renderProps: {
            set: renderProps.map((r) => ({ id: r.id })),
          },
        },
      },
    })
  }

  return {
    onDeleteDisconnectedOption,
    onDeleteGenericOption,
    onDeleteRoomElementOption,
    onSaveCustomDisconnectedOption,
    onSaveCustomGenericOption,
    onSaveCustomRoomElementOption,
    onSaveCatalogFeatures,
    onSaveDisconnectedOptions,
    onSaveGenericOptions,
    onSaveNobiliaOptions,
    onSaveRenderProps,
    onSaveRoomElementOptions,
  }
}

export default useDesignOptionsMutate
