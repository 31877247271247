import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import SupplierOrderCellContentFulfillmentStatus from 'components/admin/supplier-order/table-cell-content-fulfillment-status'
import { IssueStatusMode, parseIssueStatus } from 'context/issue/utils'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import { Issue, ResolutionType } from 'types/issue'

const IssueStatusCell = ({
  issue,
  toggleOrderModal,
  onAttachOrder,
}: {
  issue: Issue
  toggleOrderModal?: () => void
  onAttachOrder?: () => void
}) => {
  if (
    ![ResolutionType.REMEDIAL, ResolutionType.ADD_ON].includes(
      issue.resolutionType as ResolutionType,
    )
  )
    return <Table.Cell />
  const status = parseIssueStatus(issue)

  if (issue.supplierOrderResolving)
    return (
      <Table.Cell>
        <SupplierOrderCellContentFulfillmentStatus
          supplierOrder={issue.supplierOrderResolving}
        />
        <Button
          color="blue"
          kind="solid"
          fontAwesomeIcon="arrow-up-right-from-square"
          text="Open Supplier Order"
          onClick={() =>
            window.open(
              `/admin/projects/${issue.project?.id}/supplier-orders/${issue.supplierOrderResolving?.id}`,
            )
          }
        />
      </Table.Cell>
    )

  return (
    <Table.Cell>
      <div style={{ display: 'grid', rowGap: '4px' }}>
        {status.map((s, key) => {
          const icon =
            s.status === IssueStatusMode.Missing
              ? 'lock-keyhole'
              : s.status === IssueStatusMode.Pending
              ? 'clock'
              : 'check'

          return (
            <StatusLabel key={key} status={s.status}>
              <FontAwesomeIcon icon={['fal', icon]} />
              <p>{s.text}</p>
            </StatusLabel>
          )
        })}
        <Button
          disabled={
            !status.every((s) => s.status === IssueStatusMode.Completed)
          }
          kind="solid"
          fontAwesomeIcon="arrow-up-right-from-square"
          text="Create Supplier Order"
          onClick={toggleOrderModal}
        />
        {issue.resolutionType === ResolutionType.ADD_ON &&
          !issue.designResolving && (
            <Button
              fontAwesomeIcon="link-horizontal"
              kind="text"
              text="Attach to existing order"
              onClick={onAttachOrder}
            />
          )}
      </div>
    </Table.Cell>
  )
}

export default IssueStatusCell

const StatusLabel = styled.div<{ status: IssueStatusMode }>`
  align-items: center;
  color: ${Colors.gray600};
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;

  svg {
    color: ${Colors.gray600};
  }

  ${(props) =>
    props.status === IssueStatusMode.Completed &&
    css`
      color: ${Colors.greenBright};

      a {
        text-decoration: underline;
      }

      svg {
        color: ${Colors.greenBright};
      }
    `}

  ${(props) =>
    props.status === IssueStatusMode.Pending &&
    css`
      color: ${Colors.yellow700};

      a {
        text-decoration: underline;
      }

      svg {
        color: ${Colors.yellow700};
      }
    `}
`
