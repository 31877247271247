import moment from 'moment'

import { isMeetingCanceled } from 'context/meeting/utils'
import useProject from 'context/project/use'
import { parseFiles } from 'context/project-file/utils-many'
import { OverviewChecklist, OverviewAlert, StatusMode } from 'types/utils'

import * as utils from './utils'

const useConstructStartTradeProject = (): {
  checklists: OverviewChecklist[]
  alerts: OverviewAlert[]
} => {
  const {
    initialCostings,
    isTradeProject,
    meetingProjectConsultationOccurred,
    meetingProjectConsultationScheduled,
    meetingProjectProposalOccurred,
    meetingProjectProposalScheduled,
    orderSamplesCreated,
    orderSamplesPlaced,
    paymentProjectDepositPaid,
    paymentsProjectDepositNotSucceeded,
    paymentsDesignDepositSucceeded,
    project,
    projectProposals,
    roomPrimary: room,
  } = useProject()

  const { floorPlans, roomPhotos, inspirationImages, measurementAids } =
    parseFiles(room?.files)

  const {
    initialDesign,
    initialDesignPlanInternalReviewMode,
    initialDesignRendersReleased,
    initialDesignRendersUnreleased,
  } = utils.parseDesigns(room)

  const {
    appliancesAreAcknowledged,
    appliancesUnarchived,
    appliancesConfirmed,
    appliancesDirty,
  } = utils.parseAppliances(room)

  const appliancesMode = utils.parseAppliancesMode({
    isTradeProject,
    room,
    appliancesAreAcknowledged,
    paymentProjectDepositPaid: !!paymentProjectDepositPaid,
  })

  const appliancesModeText = utils.generateAppliancesModeText({
    room,
    appliancesMode,
    appliancesConfirmed,
    appliancesDirty,
    appliancesUnarchived,
  })

  const meetingsNotCanceled = project.meetings?.filter(
    (m) => !isMeetingCanceled(m),
  )

  return {
    checklists: [
      {
        checklistItems: [
          {
            key: 'projectAddress',
            url: `/admin/projects/${project.id}/addresses`,
            name: 'Address',
            statusMode: (() => {
              if (project.projectAddress) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (project.projectAddress) return 'Completed'
              return 'Pending'
            })(),
          },
          {
            key: 'floorPlans',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/plans`,
            name: 'Floor Plans',
            statusMode: (() => {
              if (room?.data?.floorPlansCompletedAt) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (floorPlans?.length) return `${floorPlans.length} Uploaded`
              if (room?.data?.floorPlansCompletedAt) return 'Skipped'
              return 'Pending'
            })(),
          },
          {
            key: 'roomPhotos',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/photos`,
            name: 'Room Photos',
            statusMode: (() => {
              if (room?.data?.roomPhotosCompletedAt) {
                return StatusMode.Confirmed
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (roomPhotos.length) return `${roomPhotos.length} Uploaded`
              if (room?.data?.roomPhotosCompletedAt) return 'Skipped'
              return 'Pending'
            })(),
          },
          {
            key: 'inspirationImages',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/inspiration-images`,
            name: 'Inspiration Images',
            statusMode: (() => {
              if (room?.data?.inspirationImagesCompletedAt) {
                return StatusMode.Confirmed
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (inspirationImages.length)
                return `${inspirationImages.length} Uploaded`
              if (room?.data?.inspirationImagesCompletedAt) return 'Skipped'
              return 'Pending'
            })(),
          },
          {
            key: 'appliances',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/appliances`,
            name: 'Appliances',
            statusMode: appliancesMode,
            statusText: appliancesModeText.text,
          },
          {
            key: 'initialCosting',
            url: `/admin/projects/${project.id}/initial-costing`,
            name: 'Initial Costing',
            statusMode: (() => {
              if (initialCostings.length) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (initialCostings.length) return `Initial Costing Uploaded`
              return 'Pending'
            })(),
          },
          {
            key: 'projectConsultation',
            url: `/admin/projects/${project.id}/meetings`,
            name: 'Project Consultation',
            statusMode: (() => {
              if (meetingProjectConsultationOccurred) {
                return StatusMode.Confirmed
              }
              if (meetingProjectConsultationScheduled) {
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (meetingProjectConsultationOccurred) {
                return `Completed ${moment(
                  meetingProjectConsultationOccurred.startTime,
                ).format('LL')}`
              }
              if (meetingProjectConsultationScheduled) {
                return `Scheduled ${moment(
                  meetingProjectConsultationScheduled.startTime,
                ).format('LL')}`
              }
              return 'Pending'
            })(),
          },
          {
            key: 'designDeposit',
            url: `/admin/projects/${project.id}/payments`,
            name: 'Design Deposit',
            statusMode: (() => {
              if (paymentsDesignDepositSucceeded.length)
                return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (paymentsDesignDepositSucceeded.length) return 'Paid'
              return 'Pending'
            })(),
          },
          {
            key: 'samplesOrder',
            url: `/admin/projects/${project.id}/sample-orders`,
            name: 'Samples Order',
            statusMode: (() => {
              if (orderSamplesPlaced) {
                return StatusMode.Confirmed
              }
              if (orderSamplesCreated) {
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (orderSamplesPlaced) {
                return `Placed ${moment(orderSamplesPlaced.placedAt).format(
                  'LL',
                )}`
              }
              if (orderSamplesCreated) {
                return `Order Created`
              }
              return 'Pending'
            })(),
          },
          {
            key: 'projecProposalCall',
            url: `/admin/projects/${project.id}/meetings`,
            name: 'Project Proposal Call',
            statusMode: (() => {
              if (meetingProjectProposalOccurred) {
                return StatusMode.Confirmed
              }
              if (meetingProjectProposalScheduled) {
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (meetingProjectProposalOccurred) {
                return `Completed ${moment(
                  meetingProjectProposalOccurred.startTime,
                ).format('LL')}`
              }
              if (meetingProjectProposalScheduled) {
                return `Scheduled ${moment(
                  meetingProjectProposalScheduled.startTime,
                ).format('LL')}`
              }
              return 'Pending'
            })(),
          },
          {
            key: 'projectProposal',
            url: `/admin/projects/${project.id}/project-proposal`,
            name: 'Project Proposal',
            statusMode: (() => {
              if (projectProposals.length) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (projectProposals.length) return `Project Proposal Uploaded`
              return 'Pending'
            })(),
          },
          {
            key: 'measurements',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/measurements`,
            name: 'Measurements',
            statusMode: measurementAids.length
              ? StatusMode.Confirmed
              : StatusMode.OpenForEdit,
            statusText: measurementAids.length
              ? 'Measurement Aid Uploaded'
              : 'Pending',
          },
          {
            key: 'initialDesign',
            url: initialDesign
              ? `/admin/projects/${project.id}/rooms/${room?.id}/designs/${initialDesign.id}`
              : `/admin/projects/${project.id}/rooms/${room?.id}/designs`,
            name: 'Initial Design',
            statusMode: (() => {
              if (initialDesign) {
                if (initialDesign.metadata?.publishedAt)
                  return StatusMode.Confirmed
                if (initialDesignRendersReleased.length)
                  return StatusMode.Confirmation
                if (initialDesignRendersUnreleased.length)
                  return StatusMode.Confirmation
                if (initialDesign.sentToArchAt)
                  return StatusMode.OpenForRevision
                if (initialDesign.sentToRenderingAt)
                  return StatusMode.OpenForRevision
                if (initialDesignPlanInternalReviewMode) {
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Confirmed
                  ) {
                    return StatusMode.OpenForRevision
                  }
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Submitted
                  ) {
                    return StatusMode.Submitted
                  }
                  if (
                    initialDesignPlanInternalReviewMode ===
                    StatusMode.OpenForRevision
                  ) {
                    return StatusMode.OpenForRevision
                  }
                }
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (initialDesign) {
                if (initialDesign.metadata?.publishedAt)
                  return `Published with ${initialDesignRendersReleased.length} renders`
                if (initialDesignRendersReleased.length)
                  return `${initialDesignRendersReleased.length} renders added, not published`
                if (initialDesignRendersUnreleased.length)
                  return `${initialDesignRendersUnreleased.length} renders received`
                if (initialDesign.sentToArchAt)
                  return `Sent to CGI ${moment(
                    initialDesign.sentToArchAt,
                  ).format('LL')}`
                if (initialDesign.sentToRenderingAt)
                  return `Sent to render ${moment(
                    initialDesign.sentToRenderingAt,
                  ).format('LL')}`
                if (initialDesignPlanInternalReviewMode) {
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Confirmed
                  ) {
                    return `Internal review signed off`
                  }
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Submitted
                  ) {
                    return `${initialDesign.comments.length} internal comments submitted`
                  }
                  if (
                    initialDesignPlanInternalReviewMode ===
                    StatusMode.OpenForRevision
                  ) {
                    return 'Awaiting internal review'
                  }
                }
                return 'Design created'
              }
              return 'Pending'
            })(),
          },
          {
            key: 'projectDeposit',
            url: `/admin/projects/${project.id}/payments`,
            name: 'Trade Deposit',
            statusMode: paymentProjectDepositPaid
              ? StatusMode.Confirmed
              : StatusMode.OpenForEdit,
            statusText: paymentProjectDepositPaid ? 'Paid' : 'Pending',
          },
        ],
      },
    ],
    alerts: [
      ...(!meetingsNotCanceled.length
        ? [
            {
              message:
                'This project has no scheduled calls. Please make sure to schedule or record a Project Consultation.',
            },
          ]
        : []),
      ...(!!paymentsProjectDepositNotSucceeded.length &&
      !(meetingProjectProposalOccurred || meetingProjectProposalScheduled)
        ? [
            {
              message:
                'This project has a Trade Deposit but no Project Proposal meeting. Please schedule or record a Project Proposal.',
            },
          ]
        : []),
    ],
  }
}

export default useConstructStartTradeProject
