import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import DisplayNameEditable from 'components/admin/catalog/edit-display-name'
import Assets from 'components/admin/catalog/media/assets'
import MediaModal from 'components/admin/catalog/media/modal'
import Photography from 'components/admin/catalog/media/photography'
import RoomElementOptionSummary from 'components/admin/catalog/room-element-option/summary'
import useAuth from 'context/auth/use'
import useRoomElementOption from 'context/room-element-option/use'
import useRoomElementOptionMutate from 'context/room-element-option/use-mutate'
import Button from 'design-system/components/button'
import { StyledBreadcrumb } from 'styles/planner/main'

export default (_: RouteComponentProps) => {
  const { isAdmin } = useAuth()
  const { catalog, roomElementOption, refetch } = useRoomElementOption()
  const { updateRoomElementOption } = useRoomElementOptionMutate()

  const onSaveDisplayName = async (displayName: string) => {
    await updateRoomElementOption({
      variables: {
        data: {
          data: {
            ...roomElementOption.data,
            displayName: displayName,
          },
        },
        where: { id: roomElementOption.id },
      },
      onCompleted: refetch,
    })
  }

  const sections = [
    {
      key: 'Catalog',
      content: `Catalog`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Room Element Feature',
      content: roomElementOption.feature?.description,
      href: `/admin/catalog/${catalog}/room-element-features/${roomElementOption.feature?.id}`,
    },
    {
      key: 'Room Element Option',
      content: roomElementOption.description,
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
        <Grid.Column>
          <MediaModal
            roomElementOptions={[roomElementOption]}
            refetch={refetch}
            trigger={
              <Button
                kind="solid"
                color="blue"
                text="Upload Media"
                disabled={!isAdmin}
                style={{ float: 'right' }}
                fontAwesomeIcon="cloud-arrow-up"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DisplayNameEditable
            name={
              roomElementOption.data?.displayName ||
              roomElementOption.description
            }
            onSave={onSaveDisplayName}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <RoomElementOptionSummary
            option={roomElementOption}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Assets media={roomElementOption.media ?? []} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Photography
            media={roomElementOption.media ?? []}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
