import React from 'react'

import { Form, Image, List, Table } from 'semantic-ui-react'

import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import Button from 'design-system/components/button/button'
import { TableUserListItem } from 'styles/admin/main'
import { ProjectStaffRole } from 'types/project-staff'
import { User } from 'types/user'
import * as viewUtils from 'views/utils'

const UsersTable = ({
  designers,
  refetch,
  role,
}: {
  designers: User[]
  refetch: () => void
  role: ProjectStaffRole
}) => {
  const { updateUser } = useUserMutateNoContext()
  const isSales = role === ProjectStaffRole.SALES_DESIGNER
  const isTrade = role === ProjectStaffRole.TRADE_DESIGNER
  const isSalesOrTrade = isSales || isTrade

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Order</Table.HeaderCell>
          <Table.HeaderCell>Designer</Table.HeaderCell>
          {isSalesOrTrade && (
            <>
              <Table.HeaderCell>Requested</Table.HeaderCell>
              <Table.HeaderCell>Calendly</Table.HeaderCell>
            </>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {designers.map((designer: User, index: number) => {
          const calendlyUrl = (() => {
            if (isSales)
              return `https://calendly.com/${designer.data?.calendlyId}/${designer.data?.calendlyUrlSlugDesignCall}`
            if (isTrade)
              return `https://calendly.com/${designer.data?.calendlyId}/${designer.data?.calendlyUrlSlugProjectConsultation}`
            return ''
          })()
          return (
            <Table.Row key={designer.id}>
              <Table.Cell textAlign="left" width={2}>
                <Form.Checkbox
                  disabled={
                    index === 0 &&
                    designers.length === 1 &&
                    !!designer.data?.availableForNewProjects
                  }
                  checked={!!designer.data?.availableForNewProjects}
                  onChange={async () => {
                    await updateUser({
                      variables: {
                        data: {
                          data: {
                            ...designer.data,
                            availableForNewProjects: !(
                              designer.data?.availableForNewProjects || false
                            ),
                          },
                        },
                        where: {
                          id: designer.id,
                        },
                      },
                    })
                    refetch()
                  }}
                  slider
                />
              </Table.Cell>
              <Table.Cell textAlign="left" width={2}>
                {designer.data?.availableForNewProjects ? index + 1 : '-'}
              </Table.Cell>
              <Table.Cell textAlign="left">
                <List relaxed verticalAlign="middle">
                  <TableUserListItem>
                    <Image
                      src={
                        designer?.picture ||
                        'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon&f=y'
                      }
                      avatar
                      circular
                    />
                    <List.Content verticalAlign="middle">
                      <List.Header>
                        {viewUtils.responsiveText(
                          viewUtils.getNameForUser(designer),
                          50,
                        )}
                      </List.Header>
                    </List.Content>
                  </TableUserListItem>
                </List>
              </Table.Cell>
              {isSalesOrTrade && (
                <>
                  <Table.Cell width={2}>{'N/A'}</Table.Cell>
                  <Table.Cell textAlign="center" width={2}>
                    <Button
                      kind="solid"
                      color="blue"
                      fontAwesomeIcon="arrow-up-right-from-square"
                      onClick={() => {
                        window.open(calendlyUrl)
                      }}
                    />
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default UsersTable
