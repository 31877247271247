import React from 'react'

import { RouteComponentProps } from '@reach/router'

import CatalogFeatureManyProvider from 'context/catalog-feature/provider-many'

interface CatalogFeatureIndexManyProps
  extends RouteComponentProps<{ catalog_id: string }> {
  children: React.ReactNode
}

const CatalogFeatureIndexMany = (props: CatalogFeatureIndexManyProps) => {
  return (
    <CatalogFeatureManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        sortBy: 'groupIdentifier',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </CatalogFeatureManyProvider>
  )
}

export default CatalogFeatureIndexMany
