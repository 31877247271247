import React from 'react'

import StatusLabel from 'design-system/components/status-label/status-label'
import { Order } from 'types/order'
import { StatusMode } from 'types/utils'

const CellContentsPublished = ({ order }: { order: Order }) => {
  return (
    <StatusLabel
      type={
        order?.metadata?.publishedAt
          ? StatusMode.Confirmed
          : StatusMode.OpenForEdit
      }
      text={order?.metadata?.publishedAt ? 'Published' : 'Unpublished'}
    />
  )
}

export default CellContentsPublished
