import React from 'react'

import moment from 'moment'

import useLogisticsJobSettings from 'context/settings/use-logistics-jobs'
import Button from 'design-system/components/button'
import { SettingType } from 'types/settings'

const LogisticsJobButtons = () => {
  const {
    loading,
    loadingUpsert,
    logisticsPlannedAtStatus,
    logisticsPlannedAtStatusTime,
    logisticsShipmentsSyncedAtStatus,
    logisticsShipmentsSyncedAtStatusTime,
    logisticsSyncedAtStatus,
    logisticsSyncedAtStatusTime,
    logisticsEligibleOrdersSyncedAtStatus,
    logisticsEligibleOrdersSyncedAtStatusTime,
    upsertSettingsAndRefetch,
  } = useLogisticsJobSettings()

  return (
    <>
      <Button
        color="gray"
        kind="ghost"
        loading={loading || loadingUpsert}
        disabled={logisticsSyncedAtStatus === 'started'}
        onClick={() => {
          const value = `started|${moment().format()}`
          upsertSettingsAndRefetch({
            variables: {
              update: { value },
              create: {
                key: 'logisticsSyncedAt',
                type: SettingType.LOGISTICS,
                value,
              },
              where: {
                type_key: {
                  key: 'logisticsSyncedAt',
                  type: SettingType.LOGISTICS,
                },
              },
            },
          })
        }}
        text={`Sync (${logisticsSyncedAtStatus || 'no history'}${
          logisticsSyncedAtStatusTime
            ? ' ' + moment(logisticsSyncedAtStatusTime).fromNow()
            : ''
        })`}
      />
      <Button
        color="gray"
        kind="ghost"
        loading={loading || loadingUpsert}
        disabled={logisticsPlannedAtStatus === 'started'}
        onClick={() => {
          const value = `started|${moment().format()}`
          upsertSettingsAndRefetch({
            variables: {
              update: { value },
              create: {
                key: 'logisticsPlannedAt',
                type: SettingType.LOGISTICS,
                value,
              },
              where: {
                type_key: {
                  key: 'logisticsPlannedAt',
                  type: SettingType.LOGISTICS,
                },
              },
            },
          })
        }}
        text={`Plan (${logisticsPlannedAtStatus || 'no history'}${
          logisticsPlannedAtStatusTime
            ? ' ' + moment(logisticsPlannedAtStatusTime).fromNow()
            : ''
        })`}
      />
      <Button
        color="gray"
        kind="ghost"
        loading={loading || loadingUpsert}
        disabled={logisticsShipmentsSyncedAtStatus === 'started'}
        onClick={() => {
          const value = `started|${moment().format()}`
          upsertSettingsAndRefetch({
            variables: {
              update: { value },
              create: {
                key: 'logisticsShipmentsSyncedAt',
                type: SettingType.LOGISTICS,
                value,
              },
              where: {
                type_key: {
                  key: 'logisticsShipmentsSyncedAt',
                  type: SettingType.LOGISTICS,
                },
              },
            },
          })
        }}
        text={`Shipments Sync (${
          logisticsShipmentsSyncedAtStatus || 'no history'
        }${
          logisticsShipmentsSyncedAtStatusTime
            ? ' ' + moment(logisticsShipmentsSyncedAtStatusTime).fromNow()
            : ''
        })`}
      />

      <Button
        color="gray"
        kind="ghost"
        loading={loading || loadingUpsert}
        disabled={logisticsEligibleOrdersSyncedAtStatus === 'started'}
        onClick={() => {
          const value = `started|${moment().format()}`
          upsertSettingsAndRefetch({
            variables: {
              update: { value },
              create: {
                key: 'logisticsEligibleOrdersSyncedAt',
                type: SettingType.LOGISTICS,
                value,
              },
              where: {
                type_key: {
                  key: 'logisticsEligibleOrdersSyncedAt',
                  type: SettingType.LOGISTICS,
                },
              },
            },
          })
        }}
        text={`Orders Sync (${
          logisticsEligibleOrdersSyncedAtStatus || 'no history'
        }${
          logisticsEligibleOrdersSyncedAtStatusTime
            ? ' ' + moment(logisticsEligibleOrdersSyncedAtStatusTime).fromNow()
            : ''
        })`}
      />
    </>
  )
}

export default LogisticsJobButtons
