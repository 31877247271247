import React from 'react'

import { Table } from 'semantic-ui-react'

import useProspectMany from 'context/prospect/use-many'
import { parseMeetings, parseQuizAnswers } from 'context/prospect/utils'
import Button from 'design-system/components/button'
import { InfoPopup } from 'styles/admin/main'
import { Colors } from 'styles/app/system'

import { ProspectStaffCellContents } from './prospect-staff/table-cell-content'
import { ProspectCellContents } from './table-cell-content'
import { MeetingCellContents } from './table-cell-meeting'
import TasksStatus from './tasks-status'

const ProspectsTable = () => {
  const { prospects, queryFilters, setQueryFilters } = useProspectMany()
  const { sortBy, sortDirection } = queryFilters

  return (
    <Table className="small" celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}>Prospect</Table.HeaderCell>
          <Table.HeaderCell width={2}>Staff</Table.HeaderCell>
          <Table.HeaderCell width={3}>Last meeting</Table.HeaderCell>
          <Table.HeaderCell
            width={3}
            sorted={sortBy === 'derivedMeeting' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedMeeting',
                sortDirection:
                  sortBy === 'derivedMeeting' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Upcoming meeting
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>Status</Table.HeaderCell>
          <Table.HeaderCell width={2}>Budget</Table.HeaderCell>
          <Table.HeaderCell width={1}>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {prospects?.map((p) => {
          const budgetAnswer = parseQuizAnswers(p.data?.quizAnswers ?? [])
            ?.budgetAnswer
          const budgetString = budgetAnswer?.value
            ? `${budgetAnswer?.value}`
            : '---'
          const budgetLow = budgetString.includes('Less')
          const {
            meetingOccurredIncludingCanceledOrNoShow,
            meetingUpcomingIncludingCanceledOrNoShow,
          } = parseMeetings(p)
          return (
            <Table.Row key={p.id}>
              <Table.Cell width={3}>
                <ProspectCellContents prospect={p} />
              </Table.Cell>
              <Table.Cell width={2}>
                <ProspectStaffCellContents prospect={p} />
              </Table.Cell>
              <Table.Cell width={3}>
                <MeetingCellContents
                  meeting={meetingOccurredIncludingCanceledOrNoShow}
                />
              </Table.Cell>
              <Table.Cell width={3}>
                <MeetingCellContents
                  meeting={meetingUpcomingIncludingCanceledOrNoShow}
                />
              </Table.Cell>
              <Table.Cell width={2}>
                <TasksStatus prospect={p} />
              </Table.Cell>
              <Table.Cell width={2}>
                {budgetLow ? (
                  <p style={{ color: Colors.orange500 }}>{budgetString}</p>
                ) : (
                  <p>{budgetString}</p>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                <div style={{ display: 'grid', rowGap: '8px' }}>
                  <InfoPopup
                    content={<p>Open prospect</p>}
                    trigger={
                      <Button
                        color="dark"
                        fontAwesomeIcon="arrow-up-right-from-square"
                        kind="solid"
                        onClick={() => window.open(`/admin/prospects/${p.id}`)}
                      />
                    }
                  />
                  {p.projects.length > 0 && (
                    <InfoPopup
                      content={<p>Open project</p>}
                      trigger={
                        <Button
                          color="blue"
                          fontAwesomeIcon="eye"
                          kind="solid"
                          onClick={() =>
                            window.open(
                              `/admin/projects/${p.projects?.[0].project?.id}/overview`,
                            )
                          }
                        />
                      }
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default ProspectsTable
