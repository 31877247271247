import React from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import FiltersAndSearch from 'components/admin/filters-search'
import { BackToTop } from 'components/shared/load-buttons'
import useNobiliaOptionMany from 'context/nobilia-option/use-many'

import NobiliaOptionTable from './table'
import useNobiliaOptionFiltersSearch from './use-filters-search'

const NobiliaOptiones = ({ table }: { table?: React.ReactNode }) => {
  const { count, nobiliaOptions, fetchMore, loading, loadingMore } =
    useNobiliaOptionMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useNobiliaOptionFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${nobiliaOptions?.length} of ${count}`}{' '}
            Nobilia Options
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table || <NobiliaOptionTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && nobiliaOptions?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default NobiliaOptiones
