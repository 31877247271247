import React, { useState } from 'react'

import styled from 'styled-components'

import ConfirmationModal from 'components/shared/confirmation-modal'
import { formatCount } from 'context/project/utils'
import { useProjectEventsMany } from 'context/project-event/provider-many'
import useProjectEventMutate from 'context/project-event/use-mutate'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'

type EventFiltersProps = {
  formUserId: string
}

export const EventFilters = ({ formUserId }: EventFiltersProps) => {
  const [openAcknowledgeAll, setOpenAcknowledgeAll] = useState(false)
  const {
    fetchedCount,
    newForMeCount,
    projectEvents,
    refetchAll,
    queryFilters,
    setQueryFilters,
  } = useProjectEventsMany()

  const { updateManyProjectEvents, loadingUpdateManyEvents } =
    useProjectEventMutate({
      refetch: refetchAll,
    })

  const handleAcknowledgeAll = async () => {
    await updateManyProjectEvents({
      variables: {
        data: {
          newFor: {
            disconnect: {
              id: formUserId,
            },
          },
        },
        where: {
          id: {
            in: projectEvents.map((e) => e.id),
          },
        },
      },
    })
  }

  return (
    <FiltersContainer>
      <div className="tags">
        <p className="no-margin">Showing</p>
        <Tag
          active={!!queryFilters.newFor}
          onClick={() =>
            setQueryFilters({
              ...queryFilters,
              newFor: {
                id: formUserId,
              },
            })
          }
        >
          New
          {!!newForMeCount && (
            <span className="badge">
              {formatCount(queryFilters.take, newForMeCount)}
            </span>
          )}
        </Tag>
        <Tag
          active={!queryFilters.newFor}
          onClick={() =>
            setQueryFilters({
              ...queryFilters,
              newFor: undefined,
            })
          }
        >
          View All
        </Tag>
      </div>
      <ConfirmationModal
        firstTitle={`Are you sure you want to acknowledge these ${fetchedCount} events?`}
        onConfirm={() => {
          handleAcknowledgeAll()
          setOpenAcknowledgeAll(false)
        }}
        open={openAcknowledgeAll}
        onOpen={() => setOpenAcknowledgeAll(true)}
        onCancel={() => setOpenAcknowledgeAll(false)}
        trigger={
          <Button
            kind="solid"
            text="Acknowledge All"
            fontAwesomeIcon="square-check"
            iconPosition="left"
            iconAnimation="scale"
            loading={loadingUpdateManyEvents}
            disabled={!queryFilters.newFor || !newForMeCount}
          />
        }
      />
    </FiltersContainer>
  )
}

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  justify-content: space-between;

  & > div.tags {
    display: flex;
    column-gap: 8px;
    align-items: center;

    & > p:first-child {
      margin-right: 12px !important;
    }
  }
`

const Tag = styled.button<{ active: boolean }>`
  align-items: center;
  background-color: ${(props) =>
    props.active ? Colors.blue100 : 'transparent'};
  border: 1px solid
    ${(props) => (props.active ? Colors.blue100 : Colors.blue200)};
  border-radius: 24px;
  color: ${Colors.black};
  display: flex;
  font-weight: 300;
  gap: 8px;
  height: 32px;
  justify-content: center;
  outline: none;
  padding: 4px 16px;
  cursor: pointer;

  & > span.badge {
    align-items: center;
    background-color: ${Colors.orange500};
    border-radius: 11px;
    color: ${Colors.white};
    display: flex;
    font-size: 10px;
    font-weight: 300;
    min-width: 22px;
    height: 100%;
    justify-content: center;
    line-height: 1.5;
    padding: 4px;
    text-align: center;
  }
`
