import React, { useState } from 'react'

import { isEqual, uniqBy } from 'lodash'
import { Divider, Grid, Image, Transition } from 'semantic-ui-react'
import styled from 'styled-components'

import FileCard from 'components/shared/project/project-file/card'
import { useCatalogFrontColor } from 'content-queries/cx/catalog-front-color'
import { useDesignLayout } from 'content-queries/cx/design-layout'
import { useHandleType } from 'content-queries/cx/handle-type'
import useAuth from 'context/auth/use'
import useDesign from 'context/design/use'
import useDesignGalleryCount from 'context/design/use-gallery-count'
import { sortAndPartitionGalleryRenders } from 'context/design/utils'
import useProject from 'context/project/use'
import useProjectFileMutateNoContext from 'context/project-file/use-mutate-no-context'
import StyledAccordion from 'design-system/components/accordion'
import Button from 'design-system/components/button'
import { StyledToggle } from 'styles/admin/main'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { ProjectFile } from 'types/project-file'

import DesignModal from './modal'

const DesignGallery = () => {
  const { isAdmin } = useAuth()
  const {
    design,
    rendersShowingOnWebsiteGallery,
    rendersNotOnWebsiteGallery,
    fronts,
    refetch,
  } = useDesign()
  const { project } = useProject()
  const { updateProjectFileReturnIdOnly } = useProjectFileMutateNoContext()
  const { designsGalleryCount, loading: loadingCount } = useDesignGalleryCount()

  const [rendersState, setRendersState] = useState<ProjectFile[]>([
    ...rendersShowingOnWebsiteGallery,
    ...rendersNotOnWebsiteGallery,
  ])
  const [loading, setLoading] = useState<boolean>(false)

  const { data: allHandleTypes } = useHandleType()
  const handleTypesTitle = design?.metadataGallery?.handleTypes?.map((ht) => {
    return allHandleTypes.find(
      (aht: { contentful_id: string }) => ht === aht.contentful_id,
    )?.title
  })

  const { data: allFrontColors } = useCatalogFrontColor()
  const frontsColor = uniqBy(fronts, (f) => f.data?.color).map((f) => {
    const frontColor = allFrontColors.find(
      (afc: { contentful_id: string }) => f.data?.color === afc.contentful_id,
    )
    return {
      color: frontColor?.image?.file.url,
      title: frontColor?.title,
    }
  })
  const { data: allDesignLayouts } = useDesignLayout()
  const designLayoutTitle = allDesignLayouts.find(
    (ach: { contentful_id: string }) =>
      design?.metadataGallery?.layout === ach.contentful_id,
  )?.title
  const designGalleryStatus = design?.metadataGallery?.showOnWebsiteGallery
    ? 'Showing on website gallery'
    : 'Not showing on website gallery'

  const onSave = async () => {
    setLoading(true)
    for (const render of rendersState) {
      await updateProjectFileReturnIdOnly({
        variables: {
          data: {
            metadata: {
              ...render.metadata,
              index: render.metadata?.index,
              showOnWebsiteGallery: render.metadata?.showOnWebsiteGallery,
            },
          },
          where: {
            id: render.id,
          },
        },
      })
    }
    refetch()
    setLoading(false)
  }

  const onToggle = (id: string) => {
    const toggledRenders = rendersState.map((rs) => {
      if (rs.id === id)
        return {
          ...rs,
          metadata: {
            ...rs.metadata,
            showOnWebsiteGallery: !rs.metadata?.showOnWebsiteGallery,
          },
        }
      return rs
    })

    return sortAndPartitionGalleryRenders(toggledRenders)
  }

  if (loadingCount) return null

  const renderRender = (f: ProjectFile, index: number) => {
    return (
      <Grid.Column key={f.id} style={{ marginBottom: '25px' }}>
        <div style={{ opacity: f.metadata?.showOnWebsiteGallery ? '1' : '.4' }}>
          <FileCard file={f} />
          <Transition animation="slide down" duration={500}>
            <div>
              <Grid style={{ marginTop: '0', justifyContent: 'space-between' }}>
                <Grid.Column width={7}>
                  {f.metadata?.showOnWebsiteGallery && (
                    <label className="caption">
                      Position
                      <span style={{ color: Colors.black }}> #{index + 1}</span>
                    </label>
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right" width={7}>
                  {index !== 0 && (
                    <Button
                      color="blue"
                      fontAwesomeIcon="arrow-left"
                      kind="solid"
                      disabled={!f.metadata?.showOnWebsiteGallery}
                      onClick={() =>
                        setRendersState(
                          sortAndPartitionGalleryRenders(
                            rendersState.map((r, idx) => {
                              if (idx === index)
                                return {
                                  ...r,
                                  metadata: {
                                    ...r.metadata,
                                    index: isNaN(Number(r.metadata.index) - 1)
                                      ? idx
                                      : Number(r.metadata.index) - 1,
                                  },
                                }
                              if (idx === index - 1)
                                return {
                                  ...r,
                                  metadata: {
                                    ...r.metadata,
                                    index: isNaN(Number(r.metadata.index) + 1)
                                      ? idx - 1
                                      : Number(r.metadata.index) + 1,
                                  },
                                }
                              return r
                            }),
                          ),
                        )
                      }
                    />
                  )}
                  {index !== rendersState.length - 1 && (
                    <Button
                      color="blue"
                      disabled={!f.metadata?.showOnWebsiteGallery}
                      fontAwesomeIcon="arrow-right"
                      onClick={() =>
                        setRendersState(
                          sortAndPartitionGalleryRenders(
                            rendersState.map((r, idx) => {
                              if (idx === index)
                                return {
                                  ...r,
                                  metadata: {
                                    ...r.metadata,
                                    index: isNaN(Number(r.metadata.index) + 1)
                                      ? idx
                                      : Number(r.metadata.index) + 1,
                                  },
                                }
                              if (idx === index + 1)
                                return {
                                  ...r,
                                  metadata: {
                                    ...r.metadata,
                                    index: isNaN(Number(r.metadata.index) - 1)
                                      ? idx + 1
                                      : Number(r.metadata.index) - 1,
                                  },
                                }
                              return r
                            }),
                          ),
                        )
                      }
                      kind="solid"
                    />
                  )}
                </Grid.Column>
              </Grid>
            </div>
          </Transition>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <StyledToggle
            checked={f.metadata?.showOnWebsiteGallery}
            label="Show on gallery"
            onChange={() => {
              setRendersState(onToggle(f.id))
            }}
            toggle
          />
        </div>
      </Grid.Column>
    )
  }

  return (
    <StyledAccordion backgroundWhite titleText="Gallery Settings">
      <Grid>
        {isAdmin && (
          <>
            <Grid.Row>
              <Grid.Column>
                <p>
                  Choose which renders to show in the gallery, please be mindful
                  of the order of the images.{' '}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>{rendersState.map(renderRender)}</Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button
                  kind="solid"
                  fontAwesomeIcon="check"
                  disabled={isEqual(rendersState, design?.renders)}
                  loading={loading}
                  onClick={onSave}
                  text="Save Changes"
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column>
                Please fill the information below by clicking the Edit button.
                The information updated will be visible in our website gallery
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={9}>
                <Metadata>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <h6 className="no-margin">Metadata</h6>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="right">
                      <DesignModal
                        design={design}
                        designsGalleryCount={designsGalleryCount}
                        onClose={refetch}
                        location={`${project.projectAddress?.addressLocality}, ${project.projectAddress?.addressRegion}`}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                  <div className="content">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Status
                        </Grid.Column>
                        <Grid.Column width={9}>
                          {designGalleryStatus}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Fronts
                        </Grid.Column>
                        <Grid.Column width={9}>
                          {fronts?.map((f, idx) => (
                            <p
                              key={idx}
                              style={{
                                backgroundColor: 'white',
                                margin: '0 28px 5px 0',
                              }}
                            >
                              {f.optionKey} {f.description}
                            </p>
                          ))}
                        </Grid.Column>
                      </Grid.Row>
                      <div className="frontsData">
                        <Grid>
                          <Grid.Row>
                            <Grid.Column
                              floated="left"
                              style={{ color: Colors.gray600 }}
                              width={7}
                            >
                              Color
                            </Grid.Column>
                            <Grid.Column width={9}>
                              {frontsColor.map((fc, idx) => (
                                <p key={idx} style={{ display: 'inline-flex' }}>
                                  <Image
                                    style={{
                                      width: '30px',
                                      marginRight: '10px',
                                    }}
                                    src={fc.color}
                                  />
                                  {fc.title}
                                </p>
                              ))}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column
                              floated="left"
                              style={{ color: Colors.gray600 }}
                              width={7}
                            >
                              Texture Reproduction
                            </Grid.Column>
                            <Grid.Column width={9}>
                              {fronts?.map((f) => f.data?.texture)}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Handles
                        </Grid.Column>
                        <Grid.Column width={9}>
                          {design?.metadataGallery?.handles
                            ?.map((h) => h.optionKey + ' - ' + h.description)
                            ?.join(', ')}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Handle Type
                        </Grid.Column>
                        <Grid.Column width={9}>
                          {handleTypesTitle?.join(', ')}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Layout
                        </Grid.Column>
                        <Grid.Column width={9}>{designLayoutTitle}</Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Size
                        </Grid.Column>
                        <Grid.Column width={9}>
                          {design?.metadataGallery?.size}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Price Range
                        </Grid.Column>
                        <Grid.Column width={9}>
                          {design?.metadataGallery?.priceRange}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column
                          floated="left"
                          style={{ color: Colors.gray600 }}
                          width={7}
                        >
                          Features Level
                        </Grid.Column>
                        <Grid.Column width={9}>
                          {design?.metadataGallery?.featuresLevel}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </Metadata>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </StyledAccordion>
  )
}

export default DesignGallery

const Metadata = styled(Grid)`
  background-color: ${Colors.gray50};
  padding: 10px !important;
  margin-top: 10px !important;

  div.divider {
    margin: 3px 14px 14px !important;
    padding: 0px !important;
  }

  div.row {
    padding: 10px 0 !important;
  }

  div.content {
    overflow-y: scroll;
    padding-left: 5px;
    position: relative;
    right: -10px;

    ${ScrollBarStyle}

    div.frontsData {
      background-color: white;
      padding: 25px;
      margin: 0 16px;
    }
  }
`
