import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RenderPropManyProvider from 'context/render-prop/provider-many'

interface RenderPropIndexManyProps
  extends RouteComponentProps<{ catalog_id: string }> {
  children: React.ReactNode
}

const RenderPropIndexMany = (props: RenderPropIndexManyProps) => {
  return (
    <RenderPropManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        sortBy: 'groupIdentifier',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </RenderPropManyProvider>
  )
}

export default RenderPropIndexMany
