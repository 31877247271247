import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  DELETE_ORDER,
  DeleteOrderPayload,
  DeleteOrderVariables,
  UPDATE_ORDER,
  UpdateOrderPayload,
  UpdateOrderVariables,
} from 'queries/order'

import { ProjectOrderFORMManyContext } from './provider-many'

const useProjectOrderFORMManyMutate = () => {
  const { refetch } = useContext(ProjectOrderFORMManyContext)
  const [updateOrderAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateOrderPayload,
    UpdateOrderVariables
  >(UPDATE_ORDER, {
    onCompleted: refetch,
  })

  const [deleteOrderAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteOrderPayload,
    DeleteOrderVariables
  >(DELETE_ORDER, {
    onCompleted: refetch,
  })

  return {
    deleteOrderAndRefetch,
    loadingDelete,
    loadingUpdate,
    updateOrderAndRefetch,
  }
}

export default useProjectOrderFORMManyMutate
