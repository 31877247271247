import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { isEqual } from 'lodash'
import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import { DateInput } from 'semantic-ui-calendar-react'
import {
  Card,
  Divider,
  Form,
  Grid,
  Image,
  List,
  Table,
} from 'semantic-ui-react'

import { NoteCard } from 'components/admin/project-notes/note-card'
import ProspectLayout from 'components/admin/prospect/layout'
import {
  prospectMeetingIcon,
  ProspectStatusTable,
} from 'components/admin/prospect/utils'
import SaveModal from 'components/admin/save-modal'
import CurrencyInput from 'components/shared/currency-input'
import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'
import { formatDollars } from 'context/dollar-utils'
import useMeetingMutateNoContext from 'context/meeting/use-mutate-no-context'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import { useCommentNoteManyNoContext } from 'context/project/project-notes/use-many-no-context'
import { getCommentNotesWithTopic } from 'context/project/project-notes/utils-many'
import { AffiliateCodes } from 'context/project/utils-discounts'
import useProspect from 'context/prospect/use'
import useProspectMutateNoContext from 'context/prospect/use-mutate-no-context'
import { prospectMeetingType } from 'context/prospect/utils'
import Button from 'design-system/components/button'
import FormLogo from 'icons/short-logo.svg'
import {
  FormCheckbox,
  StyledRating,
  TableUserListItem,
} from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { CommentType } from 'types/comment'
import { Meeting, MeetingOutcome, MeetingTypeProspect } from 'types/meeting'
import {
  BudgetType,
  ProspectData,
  ProspectOptions,
  ProspectType,
} from 'types/prospect'
import * as viewUtils from 'views/utils'

import ProspectInfo from './info'

const CallOutcome = (_: RouteComponentProps) => {
  const {
    meetingsFollowUp,
    meetingsFreeConsultation,
    meetingsNotCanceledOrFollowUp,
    meetingsVisitSF,
    meetingsVisitNY,
    meetingsWebinar,
    prospect,
    refetch,
  } = useProspect()
  const { updateProspect } = useProspectMutateNoContext()
  const { formUserId } = useAuth()

  const initialState: NullableRecord<ProspectData> = {
    affiliateCode: prospect?.data?.affiliateCode || '',
    amount: prospect?.data.amount || 0,
    amountRange: prospect?.data.amountRange || 0,
    applicableOptions: prospect?.data.applicableOptions || [],
    budgetType: prospect?.data.budgetType || ('' as BudgetType),
    customerEngagement: prospect?.data.customerEngagement || '',
    expectedOrderDate: prospect?.data.expectedOrderDate || null,
    followUpCallDate: prospect?.data.followUpCallDate || null,
    formFits: prospect?.data.formFits || '',
    messageSentAt: prospect?.data.messageSentAt || null,
    notes: prospect?.data?.notes || '',
    projectType: prospect?.data.projectType || '',
    prospectType: prospect?.data.prospectType || ('' as ProspectType),
    statedSource: prospect?.data?.statedSource || '',
  }

  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState<boolean>(false)

  const OptionCheck = ({ option }: { option: ProspectOptions }) => (
    <FormCheckbox
      checked={!!state.applicableOptions?.includes(option)}
      onChange={() => {
        const options = state.applicableOptions?.includes(option)
          ? state.applicableOptions.filter((o) => o !== option)
          : [...(state.applicableOptions ?? []), option]

        setState({
          ...state,
          applicableOptions: options,
        })
      }}
    />
  )

  return (
    <ProspectLayout>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={5}>
            <ProspectInfo prospect={prospect} />
          </Grid.Column>
          <Grid.Column width={5}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <ProspectStatusTable prospect={prospect} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Table definition compact>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={6}>
                          <div
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'video']}
                              style={{ color: Colors.red700 }}
                            />
                            <p style={{ marginLeft: '12px' }}>Free Call at:</p>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {meetingsFreeConsultation.map((m) => (
                            <p className="no-margin">
                              <Image
                                avatar
                                circular
                                src={m.staffMember?.picture}
                                style={{
                                  display: 'inline',
                                  marginRight: '10px',
                                }}
                              />
                              {moment(m?.startTime).format(
                                'MM/DD/YYYY hh:mm A',
                              )}
                            </p>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <div
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'redo']}
                              style={{ color: Colors.red700 }}
                            />
                            <p style={{ marginLeft: '12px' }}>
                              Follow Up Call at:
                            </p>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {meetingsFollowUp.map((m) => (
                            <p className="no-margin">
                              <Image
                                avatar
                                circular
                                src={m.staffMember?.picture}
                                style={{
                                  display: 'inline',
                                  marginRight: '10px',
                                }}
                              />
                              {moment(m?.startTime).format(
                                'MM/DD/YYYY hh:mm A',
                              )}
                            </p>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <div
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'house-return']}
                              style={{ color: Colors.red700 }}
                            />
                            <p style={{ marginLeft: '12px' }}>Visit SF at:</p>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {meetingsVisitSF.map((m) => (
                            <p className="no-margin">
                              <Image
                                avatar
                                circular
                                src={m.staffMember?.picture}
                                style={{
                                  display: 'inline',
                                  marginRight: '10px',
                                }}
                              />
                              {moment(m?.startTime).format(
                                'MM/DD/YYYY hh:mm A',
                              )}
                            </p>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <div
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'house-return']}
                              style={{ color: Colors.red700 }}
                            />
                            <p style={{ marginLeft: '12px' }}>Visit NY at:</p>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {meetingsVisitNY.map((m) => (
                            <p className="no-margin">
                              <Image
                                avatar
                                circular
                                src={m.staffMember?.picture}
                                style={{
                                  display: 'inline',
                                  marginRight: '10px',
                                }}
                              />
                              {moment(m?.startTime).format(
                                'MM/DD/YYYY hh:mm A',
                              )}
                            </p>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <div
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'users-class']}
                              style={{ color: Colors.red700 }}
                            />
                            <p style={{ marginLeft: '12px' }}>Webinar at:</p>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {meetingsWebinar.map((m) => (
                            <p className="no-margin">
                              {m.staffMember?.picture ? (
                                <Image
                                  src={m.staffMember?.picture}
                                  circular
                                  avatar
                                  style={{
                                    display: 'inline',
                                    marginRight: '10px',
                                  }}
                                />
                              ) : (
                                <FormLogo
                                  style={{
                                    width: '32px',
                                    marginRight: '10px',
                                    verticalAlign: 'middle',
                                  }}
                                />
                              )}

                              {moment(m?.startTime).format(
                                'MM/DD/YYYY hh:mm A',
                              )}
                            </p>
                          ))}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        {meetingsNotCanceledOrFollowUp.map((m) => (
          <MeetingComponent key={m.id} meeting={m} formUserId={formUserId} />
        ))}
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <h3 style={{ fontSize: '40px' }}>Prospect Information</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <p style={{ color: Colors.gray500 }}>Suggested Follow Up Date</p>
            <DateInput
              closable
              dateFormat={'MM/DD/YYYY'}
              duration={0}
              fluid
              initialDate={moment().format('MM/DD/YYYY')}
              minDate={moment().subtract(1, 'years').format('MM/DD/YYYY')}
              name="date"
              onChange={(
                _: React.SyntheticEvent<HTMLElement, Event>,
                { value }: { value: string },
              ) => {
                setState({
                  ...state,
                  followUpCallDate: moment(value, 'MM/DD/YYYY').isValid()
                    ? moment(value, 'MM/DD/YYYY').toDate()
                    : null,
                })
              }}
              value={
                state.followUpCallDate
                  ? moment(state.followUpCallDate).format('MM/DD/YYYY')
                  : ''
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <p>How engaged was the customer on the call?</p>
                    </Card.Content>
                    <Card.Content>
                      <Form.Dropdown
                        clearable
                        fluid
                        selection
                        onChange={(_, { value }) =>
                          setState({
                            ...state,
                            customerEngagement: value as string,
                          })
                        }
                        options={[
                          {
                            key: 'null',
                            text: '',
                            value: '',
                            content: '',
                          },
                          {
                            key: '5',
                            text: <StyledRating disabled maxRating={5} />,
                            value: '5 STARS',
                            content: <StyledRating disabled maxRating={5} />,
                          },
                          {
                            key: '4',
                            text: <StyledRating disabled maxRating={4} />,
                            value: '4 STARS',
                            content: <StyledRating disabled maxRating={4} />,
                          },
                          {
                            key: '3',
                            text: <StyledRating disabled maxRating={3} />,
                            value: '3 STARS',
                            content: <StyledRating disabled maxRating={3} />,
                          },
                          {
                            key: '2',
                            text: <StyledRating disabled maxRating={2} />,
                            value: '2 STARS',
                            content: <StyledRating disabled maxRating={2} />,
                          },
                          {
                            key: '1',
                            text: <StyledRating disabled maxRating={1} />,
                            value: '1 STAR',
                            content: <StyledRating disabled maxRating={1} />,
                          },
                        ]}
                        value={state.customerEngagement ?? undefined}
                      />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <p className="gray">Choose the Type of project</p>
                    </Card.Content>
                    <Card.Content>
                      <Form.Dropdown
                        clearable
                        fluid
                        selection
                        onChange={(_, { value }) =>
                          setState({
                            ...state,
                            projectType: value as string,
                          })
                        }
                        options={[
                          {
                            key: 'null',
                            text: '',
                            value: '',
                            content: '',
                          },
                          {
                            key: '1',
                            text: 'Kitchen remodel',
                            value: 'Kitchen remodel',
                          },
                          {
                            key: '2',
                            text: 'Kitchen remodel, part of a bigger project',
                            value: 'Kitchen remodel, part of a bigger project',
                          },
                          {
                            key: '3',
                            text: 'New construction',
                            value: 'New construction',
                          },
                          {
                            key: '4',
                            text: 'Trade project',
                            value: 'Trade project',
                          },
                          {
                            key: '5',
                            text: 'Multi unit project',
                            value: 'Multi unit project',
                          },
                        ]}
                        value={state.projectType ?? undefined}
                      />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={5}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <p>How good a fit is FORM for their project?</p>
                    </Card.Content>
                    <Card.Content>
                      <Form.Dropdown
                        clearable
                        fluid
                        selection
                        onChange={(_, { value }) =>
                          setState({
                            ...state,
                            formFits: value as string,
                          })
                        }
                        options={[
                          {
                            key: 'null',
                            text: '',
                            value: '',
                            content: '',
                          },
                          {
                            key: '5',
                            text: <StyledRating disabled maxRating={5} />,
                            value: '5 STARS',
                            content: <StyledRating disabled maxRating={5} />,
                          },
                          {
                            key: '4',
                            text: <StyledRating disabled maxRating={4} />,
                            value: '4 STARS',
                            content: <StyledRating disabled maxRating={4} />,
                          },
                          {
                            key: '3',
                            text: <StyledRating disabled maxRating={3} />,
                            value: '3 STARS',
                            content: <StyledRating disabled maxRating={3} />,
                          },
                          {
                            key: '2',
                            text: <StyledRating disabled maxRating={2} />,
                            value: '2 STARS',
                            content: <StyledRating disabled maxRating={2} />,
                          },
                          {
                            key: '1',
                            text: <StyledRating disabled maxRating={1} />,
                            value: '1 STAR',
                            content: <StyledRating disabled maxRating={1} />,
                          },
                        ]}
                        value={state.formFits ?? undefined}
                      />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <p className="gray">Budget Information</p>
                    </Card.Content>
                    <Card.Content>
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Dropdown
                          clearable
                          fluid
                          selection
                          onChange={(_, { value }) =>
                            setState({
                              ...state,
                              budgetType: value as BudgetType,
                            })
                          }
                          options={[
                            {
                              key: 'null',
                              text: '',
                              value: '',
                              content: '',
                            },
                            {
                              key: '1',
                              text: 'Range (preferred)',
                              value: BudgetType.RANGE,
                            },
                            {
                              key: '2',
                              text: 'Do not exceed',
                              value: BudgetType.NOT_EXCEED,
                            },
                            {
                              key: '3',
                              text: 'Approximately',
                              value: BudgetType.APPROXIMATELY,
                            },
                          ]}
                          value={state.budgetType ?? undefined}
                        />
                        <Form.Field>
                          <label>
                            <p className="small gray">
                              What’s the customer’s budget?
                            </p>
                          </label>
                          <Form.Group widths="equal">
                            <Form.Field disabled={!state.budgetType}>
                              <CurrencyInput
                                inputMode="numeric"
                                maskOptions={{}}
                                label="Budget"
                                name="dealValue"
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setState({
                                    ...state,
                                    amount: Math.round(
                                      Number(value.replace(/[, $]+/g, '')) *
                                        100,
                                    ),
                                  })
                                }}
                                defaultValue={formatDollars(state.amount)}
                              />
                            </Form.Field>
                            -
                            <Form.Field
                              disabled={
                                !state.budgetType ||
                                state.budgetType !== BudgetType.RANGE
                              }
                            >
                              <CurrencyInput
                                inputMode="numeric"
                                maskOptions={{}}
                                label="Budget"
                                name="dealValue"
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setState({
                                    ...state,
                                    amountRange: Math.round(
                                      Number(value.replace(/[, $]+/g, '')) *
                                        100,
                                    ),
                                  })
                                }}
                                defaultValue={formatDollars(state.amountRange)}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Form.Field>
                      </Form>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={5}>
            <Card fluid>
              <Card.Content>
                <p className="gray">Check all options that apply</p>
              </Card.Content>
              <Card.Content style={{ padding: '0' }}>
                <Table celled style={{ border: 'none' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <p>Both partners on the call</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck option={ProspectOptions.BOTH_PARTNERS} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p>Working with an architect</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck
                          option={ProspectOptions.WORKING_ARCHITECT}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p>Working with a designer</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck
                          option={ProspectOptions.WORKING_DESIGNER}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p>Has contractor already</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck option={ProspectOptions.HAS_CONTRACTOR} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p>Opening up walls</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck option={ProspectOptions.OPEN_WALLS} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p>Utilities staying the same</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck option={ProspectOptions.SAME_UTILITIES} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p>Has appliances picked already</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck option={ProspectOptions.HAS_APPLIANCES} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p>Interested in cabinetry for other rooms</p>
                      </Table.Cell>
                      <Table.Cell
                        collapsing
                        style={{ background: 'rgba(0,0,0,.03)' }}
                      >
                        <OptionCheck option={ProspectOptions.MORE_ROOMS} />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h3 style={{ fontSize: '40px' }}>Project Notes (Deprecated)</h3>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Group>
                <Form.Field
                  control={TextareaAutosize}
                  label={
                    <label>
                      <p className="small">
                        <span className="red">
                          <b>Internal</b>
                        </span>{' '}
                        Project Notes
                      </p>
                    </label>
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      notes: e.target.value,
                    })
                  }
                  placeholder="Internal Project Notes"
                  value={state.notes}
                  width={8}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Group widths="equal">
                <Form.Dropdown
                  fluid
                  selection
                  clearable
                  label={
                    <label>
                      <p className="small gray">Referral Code (Optional)</p>
                    </label>
                  }
                  name="referralCode"
                  onChange={(_, { value }) =>
                    setState({
                      ...state,
                      affiliateCode: value as string,
                    })
                  }
                  options={AffiliateCodes.filter((ac) => ac.active).map(
                    (e) => ({
                      key: e.code,
                      text: `${e.code} (${e.amount} ${e.description} )`,
                      value: e.code,
                    }),
                  )}
                  placeholder="Referral Code"
                  width="8"
                  value={state.affiliateCode ?? undefined}
                />
                <Form.Dropdown
                  fluid
                  clearable
                  selection
                  label={
                    <label>
                      <p className="small gray">
                        Where did they hear about us?
                      </p>
                    </label>
                  }
                  name="statedSource"
                  onChange={(_, { value }) =>
                    setState({
                      ...state,
                      statedSource: value as string,
                    })
                  }
                  options={[
                    {
                      key: 'null',
                      text: '',
                      value: '',
                      content: '',
                    },
                    {
                      key: 'i',
                      text: 'Instagram',
                      value: 'instagram',
                    },
                    {
                      key: 'f',
                      text: 'Facebook',
                      value: 'facebook',
                    },
                    {
                      key: 'g',
                      text: 'Google',
                      value: 'google',
                    },
                    {
                      key: 'p',
                      text: 'Pinterest',
                      value: 'pinterest',
                    },
                    {
                      key: 'r',
                      text: 'Referral',
                      value: 'referral',
                    },
                    {
                      key: 'pr',
                      text: 'Press',
                      value: 'press',
                    },
                    {
                      key: 'o',
                      text: 'Other',
                      value: 'other',
                    },
                  ]}
                  placeholder="Where did they hear about us?"
                  width="8"
                  value={state.statedSource ?? undefined}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SaveModal
              currentState={state}
              initialState={initialState}
              loadingSave={loading}
              onDiscard={() => setState(initialState)}
              onSave={async () => {
                setLoading(true)
                await updateProspect({
                  variables: {
                    data: {
                      data: {
                        ...prospect?.data,
                        affiliateCode: state.affiliateCode,
                        amount: state.amount,
                        amountRange: state.amountRange,
                        applicableOptions: state.applicableOptions,
                        budgetType: state.budgetType,
                        customerEngagement: state.customerEngagement,
                        expectedOrderDate: state.expectedOrderDate,
                        followUpCallDate: state.followUpCallDate,
                        formFits: state.formFits,
                        messageSentAt: state.messageSentAt,
                        notes: state.notes,
                        projectType: state.projectType,
                        statedSource: state.statedSource,
                      },
                    },
                    where: {
                      id: prospect?.id ?? '',
                    },
                  },
                })
                refetch()
                notifySuccess('Prospect updated')
                setLoading(false)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProspectLayout>
  )
}

const MeetingComponent = ({
  meeting,
  formUserId,
}: {
  meeting: Meeting
  formUserId: string
}) => {
  const { prospect, refetch } = useProspect()
  const { updateMeeting, loadingUpdate } = useMeetingMutateNoContext()
  const initialState = {
    outcome: meeting?.metadata.outcome || ('' as MeetingOutcome),
    notes: meeting?.metadata.notes || '',
  }
  const [state, setState] = useState(initialState)
  const {
    data: commentNoteData,
    loading: loadingCommentNote,
    refetch: refetchCommentNote,
  } = useCommentNoteManyNoContext({
    variables: {
      where: {
        meetingId: {
          equals: meeting.id ?? '',
        },
        prospectId: {
          equals: prospect?.id ?? '',
        },
        type: {
          equals: CommentType.NOTES,
        },
        replyOf: null,
      },
      orderBy: {
        createdAt: 'desc',
      },
    },
  })

  const staffMemberRole = prospect?.staff?.find(
    (s) => s.staffMemberId == meeting.staffMember?.id,
  )?.staffMemberRole
  const isWebinar = meeting.metadata.type === MeetingTypeProspect.WEBINAR

  const renderSCCommentNote = () => {
    if (loadingCommentNote) return <Loader fullScreen={false} flex />
    if (!commentNoteData?.commentNotes?.length)
      return (
        <h4>
          The Selection Center Manager hasn't left any notes regarding this
          visit
        </h4>
      )

    const commentsWithTopic = getCommentNotesWithTopic(
      commentNoteData.commentNotes ?? [],
    )

    return commentsWithTopic.map((cn) => (
      <NoteCard
        key={cn.id}
        note={cn}
        formUserId={formUserId}
        isSuperAdmin={false}
        refetch={refetchCommentNote}
        isProjectNote
      />
    ))
  }

  return (
    <>
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <h3 style={{ fontSize: '40px' }}>{prospectMeetingType(meeting)}</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <TableUserListItem style={{ marginBottom: '12px' }}>
            {meeting.staffMember?.picture ? (
              <Image
                src={meeting.staffMember?.picture}
                circular
                avatar
                style={{
                  minHeight: '50px',
                  minWidth: '50px',
                  marginRight: '20px',
                }}
              />
            ) : (
              <FormLogo
                style={{
                  width: '50px',
                  marginRight: '20px',
                }}
              />
            )}
            <List.Content>
              <List.Header>
                <p className="caption">
                  {viewUtils.getNameForUser(meeting?.staffMember)}
                </p>
              </List.Header>
              <List.Description>
                <p className="small">
                  {viewUtils.capsSnakeCaseToTitleCase(staffMemberRole)}
                </p>
              </List.Description>
            </List.Content>
          </TableUserListItem>
        </Grid.Column>
        <Grid.Column width={5}>
          <Table compact definition style={{ height: '100%' }}>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={6}>
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <FontAwesomeIcon
                      icon={['fal', prospectMeetingIcon(meeting)]}
                      style={{ color: Colors.red700 }}
                    />
                    <p style={{ marginLeft: '12px' }}>
                      {prospectMeetingType(meeting)} at:
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <p className="no-margin">
                    {moment(meeting.startTime).format('LLL')}
                  </p>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Card fluid>
            <Card.Content>
              <p>What was the meeting outcome?</p>
            </Card.Content>
            <Card.Content>
              <Form.Dropdown
                clearable
                fluid
                selection
                width={8}
                onChange={(_, { value }) =>
                  setState({
                    ...state,
                    outcome: value as MeetingOutcome,
                  })
                }
                options={isWebinar ? webinarOptions : meetingOptions}
                value={state.outcome}
              />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {meeting.metadata.type === MeetingTypeProspect.SELECTION_CENTER ? (
            renderSCCommentNote()
          ) : (
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Field
                control={TextareaAutosize}
                label={'Meeting Notes'}
                placeholder="Meeting Notes"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setState({
                    ...state,
                    notes: e.target.value,
                  })
                }
                value={state.notes}
              />

              <Button
                disabled={isEqual(initialState, state)}
                kind="solid"
                loading={loadingUpdate}
                onClick={async () => {
                  await updateMeeting({
                    variables: {
                      data: {
                        metadata: {
                          ...meeting.metadata,
                          notes: state.notes,
                          outcome: state.outcome,
                        },
                      },
                      where: {
                        id: meeting.id ?? '',
                      },
                    },
                    onCompleted: () => {
                      notifySuccess('Saved meeting outcome')
                    },
                    onError: () => {
                      notifyError("Couldn't save meeting outcome")
                    },
                  })
                  refetch()
                }}
                text="Save Meeting Info"
              />
            </Form>
          )}
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default CallOutcome

const webinarOptions = [
  {
    key: '1',
    text: <>{MeetingOutcome.TWO_STARS}</>,
    value: MeetingOutcome.TWO_STARS,
    content: <>{MeetingOutcome.TWO_STARS}</>,
  },
  {
    key: '0',
    text: <>{MeetingOutcome.NO_SHOW}</>,
    value: MeetingOutcome.NO_SHOW,
    content: <>{MeetingOutcome.NO_SHOW}</>,
  },
]
const meetingOptions = [
  {
    key: '',
    text: '',
    value: '',
  },
  {
    key: '5',
    text: (
      <>
        {MeetingOutcome.FIVE_STARS} <StyledRating disabled maxRating={5} />
      </>
    ),
    value: MeetingOutcome.FIVE_STARS,
    content: (
      <>
        {MeetingOutcome.FIVE_STARS} <StyledRating disabled maxRating={5} />
      </>
    ),
  },
  {
    key: '4',
    text: (
      <>
        {MeetingOutcome.FOUR_STARS} <StyledRating disabled maxRating={4} />
      </>
    ),
    value: MeetingOutcome.FOUR_STARS,
    content: (
      <>
        {MeetingOutcome.FOUR_STARS} <StyledRating disabled maxRating={4} />
      </>
    ),
  },
  {
    key: '3',
    text: (
      <>
        {MeetingOutcome.THREE_STARS} <StyledRating disabled maxRating={3} />
      </>
    ),
    value: MeetingOutcome.THREE_STARS,
    content: (
      <>
        {MeetingOutcome.THREE_STARS} <StyledRating disabled maxRating={3} />
      </>
    ),
  },
  {
    key: '2',
    text: (
      <>
        {MeetingOutcome.TWO_STARS} <StyledRating disabled maxRating={2} />
      </>
    ),
    value: MeetingOutcome.TWO_STARS,
    content: (
      <>
        {MeetingOutcome.TWO_STARS} <StyledRating disabled maxRating={2} />
      </>
    ),
  },
  {
    key: '1',
    text: (
      <>
        {MeetingOutcome.ONE_STAR} <StyledRating disabled maxRating={1} />
      </>
    ),
    value: MeetingOutcome.ONE_STAR,
    content: (
      <>
        {MeetingOutcome.ONE_STAR} <StyledRating disabled maxRating={1} />
      </>
    ),
  },
  {
    key: '0',
    text: (
      <>
        {MeetingOutcome.NO_SHOW} <StyledRating disabled maxRating={0} />
      </>
    ),
    value: MeetingOutcome.NO_SHOW,
    content: (
      <>
        {MeetingOutcome.NO_SHOW} <StyledRating disabled maxRating={0} />
      </>
    ),
  },
]
