import React from 'react'

import { Grid } from 'semantic-ui-react'

import MediaCard from 'components/admin/catalog/media/card'
import * as mUtils from 'context/media/utils-many'
import { RoomElementOption } from 'types/room-element-option'

import RoomElementOptionMetadata from './metadata'

const RoomElementOptionSummary = ({
  option,
  refetch,
}: {
  option: RoomElementOption
  refetch?: () => Promise<unknown>
}) => {
  const referenceImage = mUtils.parseMedia(option.media).reference[0]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column
          width={4}
          textAlign="center"
          style={{ display: 'inline-table' }}
        >
          <MediaCard
            enableEdit={!!referenceImage}
            media={referenceImage}
            refetch={refetch}
          />
          <p className="caption" style={{ marginTop: '14px' }}>
            Reference Image
          </p>
        </Grid.Column>
        <Grid.Column width={6}>
          <RoomElementOptionMetadata option={option} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default RoomElementOptionSummary
