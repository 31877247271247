// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import lodash from 'lodash'

import { NobiliaOptionDesign } from 'types/design'
import { NobiliaFeature } from 'types/nobilia-feature'
import { NobiliaOption } from 'types/nobilia-option'

export interface Preset {
  name: string
  identifier: string
  options: NobiliaOption[]
}

export const deserializePresets = (
  nobiliaOptionsDesigns: NobiliaOptionDesign[],
) => {
  return lodash.sortBy(
    nobiliaOptionsDesigns.reduce((acc, no) => {
      const found = acc.find((p) => p.identifier === no.identifier)
      if (found) {
        found.options.push(no.nobiliaOption)
        return acc
      }
      acc.push({
        name:
          no.identifier === 'default' ? 'Default' : `Preset ${no.identifier}`,
        identifier: no.identifier,
        options: [no.nobiliaOption],
      })
      return acc
    }, [] as Preset[]),
    'name',
  )
}

export const matchPreset = (
  presets: Preset[],
  nobiliaOptions: NobiliaOption[],
): Preset => {
  for (const preset of presets) {
    const presetIdentifiers = lodash.sortBy(
      preset.options.map((p) => p.featureNo + p.optionKey),
    )
    const identifiers = lodash.sortBy(
      nobiliaOptions.map((p) => p.featureNo + p.optionKey),
    )
    const intersection = lodash.intersection(identifiers, presetIdentifiers)
    if (intersection.length === identifiers.length) {
      return preset
    }
  }
  return null
}

export const getOptionIdFromOption = (
  nobiliaFeatures: NobiliaFeature[],
  o: Partial<NobiliaOption>,
) => {
  const feature = nobiliaFeatures.find((f) => f.featureNo === o.featureNo)
  const option = feature.options.find((o_) => o_.optionKey === o.optionKey)
  return option.id
}

export const getOptionsExcluded = (
  first: Partial<NobiliaOption>[],
  second: Partial<NobiliaOption>[],
) => {
  return first.filter(
    (o) =>
      !second.some(
        (o_) => o_.featureNo + o_.optionKey === o.featureNo + o.optionKey,
      ),
  )
}
