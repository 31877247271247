import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import useAppliancePackManyMutate from 'context/appliance-pack/use-many-mutate'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { AppliancePack } from 'types/appliance-pack'

const emptyAppliancePack = {
  data: {
    description: '',
    groupIdentifier: '',
    name: '',
    nameDisplay: '',
  },
  identifier: '',
}

const CreateUpdateAppliancePackModal = ({
  appliancePack,
  open,
  onClose,
}: {
  appliancePack?: AppliancePack
  open: boolean
  onClose: () => void
}) => {
  const [state, setState] = useState<
    AppliancePack | Pick<AppliancePack, 'data' | 'identifier'>
  >(appliancePack || emptyAppliancePack)
  const {
    createAppliancePackAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateAppliancePackAndRefetch,
  } = useAppliancePackManyMutate()

  useEffect(() => {
    setState(appliancePack || emptyAppliancePack)
  }, [open, appliancePack])

  const onSave = async () => {
    if (appliancePack) {
      await updateAppliancePackAndRefetch({
        variables: {
          data: {
            data: state.data,
            identifier: state.identifier,
          },
          where: {
            id: appliancePack.id ?? '',
          },
        },
      })
    } else {
      await createAppliancePackAndRefetch({
        variables: {
          data: {
            data: state.data,
            identifier: state.identifier,
          },
        },
      })
    }
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title={appliancePack ? 'Update Appliance Pack' : 'Create Appliance Pack'}
      saveButton={{
        disabled: !state.identifier || !state.data?.name,
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Input
          label="Identifier"
          name="identifier"
          onChange={(
            _: React.ChangeEvent<HTMLInputElement>,
            { value }: { value: string },
          ) =>
            setState({
              ...state,
              identifier: value,
            })
          }
          value={state.identifier}
        />
        <Form.Input
          label="Group Identifier"
          name="groupIdentifier"
          onChange={(
            _: React.ChangeEvent<HTMLInputElement>,
            { value }: { value: string },
          ) =>
            setState({
              ...state,
              data: {
                ...state.data,
                groupIdentifier: value,
              },
            })
          }
          value={state.data?.groupIdentifier || ''}
        />
        <Form.Input
          label="Name"
          name="name"
          onChange={(
            _: React.ChangeEvent<HTMLInputElement>,
            { value }: { value: string },
          ) =>
            setState({
              ...state,
              data: {
                ...state.data,
                name: value,
              },
            })
          }
          value={state.data?.name || ''}
        />
        <Form.Input
          label="Display Name"
          name="nameDisplay"
          onChange={(
            _: React.ChangeEvent<HTMLInputElement>,
            { value }: { value: string },
          ) =>
            setState({
              ...state,
              data: {
                ...state.data,
                nameDisplay: value,
              },
            })
          }
          value={state.data?.nameDisplay || ''}
        />
        <Form.Input
          label="Description"
          name="description"
          onChange={(
            _: React.ChangeEvent<HTMLInputElement>,
            { value }: { value: string },
          ) =>
            setState({
              ...state,
              data: {
                ...state.data,
                description: value,
              },
            })
          }
          value={state.data?.description || ''}
        />
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateAppliancePackModal
