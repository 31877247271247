import React, { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { DropdownProps, Dropdown } from 'semantic-ui-react'

import { UserInfo } from 'components/shared/user-info'
import {
  FIND_MANY_USER,
  FindManyUserPayload,
  FindManyUserVariables,
} from 'queries/user'
import { StyledForm } from 'styles/admin/main'
import { FORM_EMPLOYEES, User } from 'types/user'
import { getNameForUser } from 'views/utils'

type SearchUserBarProps = {
  disabled?: boolean
  handleSelectUser: (project: User) => void
}

export const SearchUserBar = ({
  disabled,
  handleSelectUser,
}: SearchUserBarProps) => {
  const [fetchUsers, { loading, data }] = useLazyQuery<
    FindManyUserPayload,
    FindManyUserVariables
  >(FIND_MANY_USER)

  const handleSearchChange = (
    _: React.SyntheticEvent,
    { searchQuery }: DropdownProps,
  ) => {
    const sanitizedQuery = searchQuery?.trim().replace(/[^a-zA-Z0-9_\s@.]/g, '')
    if (!sanitizedQuery) return

    fetchUsers({
      variables: {
        where: {
          derivedSearch: {
            contains: sanitizedQuery,
            mode: 'insensitive',
          },
          type: {
            notIn: FORM_EMPLOYEES,
          },
        },
      },
    })
  }

  const handleSearchChangeDebounced = useCallback(
    debounce(handleSearchChange, 1000),
    [],
  )

  return (
    <StyledForm>
      <Dropdown
        search
        className="selection"
        fluid
        disabled={disabled}
        placeholder="Search User"
        onSearchChange={handleSearchChangeDebounced}
        loading={loading}
        open={disabled ? false : undefined}
      >
        <Dropdown.Menu>
          {data?.count ? (
            data.users.map((user) => (
              <Dropdown.Item
                key={user.id}
                onClick={() => handleSelectUser(user)}
              >
                <UserInfo
                  name={getNameForUser(user)}
                  picture={user.picture}
                  phone={user.phone}
                />
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item>
              <p>No users found</p>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </StyledForm>
  )
}
