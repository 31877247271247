import { gql } from '@apollo/client'

import { CatalogDiff } from 'types/catalog-diff'

export const DIFF_CATALOG = gql`
  mutation diffCatalog {
    diffCatalog
  }
`

export interface DiffCatalogPayload {
  diffCatalog: CatalogDiff
}

export const DIFF_CATALOG_RESTORE = gql`
  mutation diffCatalogRestore {
    diffCatalogRestore
  }
`

export interface DiffCatalogRestorePayload {
  diffCatalogRestore: CatalogDiff
}

export interface DiffCatalogRestorePayload {
  diffCatalogRestore: CatalogDiff
}

export const RESTORE_CATALOG = gql`
  mutation restoreCatalog {
    restoreCatalog
  }
`

export interface RestoreCatalogPayload {
  restoreCatalog: boolean
}

export const SAVE_CATALOG = gql`
  mutation saveCatalog {
    saveCatalog
  }
`

export interface SaveCatalogPayload {
  saveCatalog: boolean
}
