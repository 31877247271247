import { useQuery } from '@apollo/client'

import {
  GET_SALES_DESIGNER_NEW_PROJECT_QUEUE,
  UserQueuePayload,
} from 'queries/round-robin'

const useSalesDesignerNewProjectQueue = () => {
  const {
    data: { designers } = { designers: [] },
    loading,
    refetch,
  } = useQuery<UserQueuePayload>(GET_SALES_DESIGNER_NEW_PROJECT_QUEUE)

  return { designers, loading, refetch }
}

export default useSalesDesignerNewProjectQueue
