import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import Rooms from 'components/admin/room/index-many'
import SectionTitle from 'components/admin/section-title'
import RoomManyProvider from 'context/room/provider-many'

const Module = (
  props: RouteComponentProps & { location?: { search: string } },
) => {
  const defaultFilter = new URLSearchParams(props.location?.search)?.get(
    'defaultFilter',
  )
  let packStatus = 'awaitingPack' as 'all' | 'awaitingPack' | 'releasedPack'
  if (defaultFilter === 'releasedPack') packStatus = 'releasedPack'

  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="Packs" />
        <Grid.Row>
          <Grid.Column>
            <RoomManyProvider
              defaultFilters={{
                sortBy: 'derivedStatus',
                sortDirection: 'ascending',
                packStatus,
                take: 30,
              }}
              skipLoader
            >
              <Rooms />
            </RoomManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
