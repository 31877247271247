import React from 'react'

import { RouteComponentProps } from '@reach/router'

import SupplierOrders from 'components/admin/supplier-order/index-many'
import useTechDesignerSupport from 'context/dashboard/tech-designer-support/use'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import {
  FORMStatus,
  SupplierOrderType,
} from 'context/supplier-order/utils-many'

import TechDesignerSupportLayout from './layout'

const TechDesignerSupportSupplierOrders = (_: RouteComponentProps) => {
  const { refetchCounts, userId, userName } = useTechDesignerSupport()

  return (
    <TechDesignerSupportLayout>
      <SupplierOrderManyProvider
        defaultFilters={{
          assignedTo: {
            id: userId,
            name: userName,
          },
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          statusesFORM: [FORMStatus.AwaitingNobilia],
          take: 50,
          type: [SupplierOrderType.AddOn, SupplierOrderType.Remedial],
        }}
        refetchMore={refetchCounts}
        skipLoader
      >
        <SupplierOrders />
      </SupplierOrderManyProvider>
    </TechDesignerSupportLayout>
  )
}

export default TechDesignerSupportSupplierOrders
