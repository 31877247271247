import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import { Dropdown, Form } from 'semantic-ui-react'

import useProspect from 'context/prospect/use'
import useProspectStaffMutate from 'context/prospect/use-prospect-staff-mutate'
import useFORMEmployees from 'context/user/use-form-employees'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import { ProspectStaff, ProspectStaffRole } from 'types/prospect-staff'
import { StaffMemberAssignmentType } from 'types/utils'
import * as viewUtils from 'views/utils'

const now = moment().format('MM-DD-YYYY hh:mm a')

interface ProspectStaffModalProps {
  open: boolean
  onClose: () => void
  prospectStaff: ProspectStaff
  disablePrimary?: boolean
}

const ProspectStaffCreateUpdateModal = ({
  open,
  onClose,
  prospectStaff,
  disablePrimary,
}: ProspectStaffModalProps) => {
  const { prospect } = useProspect()
  const {
    createProspectStaffAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateProspectStaffAndRefetch,
  } = useProspectStaffMutate()
  const { userOptions } = useFORMEmployees()
  const [state, setState] = useState<ProspectStaff>(
    prospectStaff || {
      data: {
        designCallOneOffUrl: '',
        staffMemberAssignmentType: StaffMemberAssignmentType.MANUAL,
      },
      isPrimary: false,
      isSubscribed: true,
      staffMemberRole: ProspectStaffRole.ACCOUNT_EXECUTIVE,
      staffMemberId: '',
      prospectId: prospect?.id ?? '',
    },
  )

  useEffect(() => {
    setState(
      prospectStaff || {
        data: {
          designCallOneOffUrl: '',
        },
        isPrimary: false,
        isSubscribed: true,
        staffMemberRole: ProspectStaffRole.ACCOUNT_EXECUTIVE,
        staffMemberId: '',
        prospectId: prospect?.id ?? '',
      },
    )
  }, [prospectStaff])

  const onChangeStartTime = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      data: {
        ...state.data,
        designCallOneOffExpiration: moment(
          value,
          'MM-DD-YYYY hh:mm a',
        ).isValid()
          ? moment(value, 'MM-DD-YYYY hh:mm a').toDate()
          : null,
      },
    })
  }

  const onSave = async () => {
    if (prospectStaff) {
      await updateProspectStaffAndRefetch({
        variables: {
          data: {
            staffMember: {
              connect: {
                id: state.staffMemberId ?? '',
              },
            },
            data: state.data || {},
            isPrimary: state.isPrimary,
            isSubscribed: state.isSubscribed,
            staffMemberRole: state.staffMemberRole,
          },
          where: {
            prospectId_staffMemberId: {
              prospectId: prospect?.id ?? '',
              staffMemberId: prospectStaff.staffMemberId ?? '',
            },
          },
        },
      })
    } else {
      await createProspectStaffAndRefetch({
        variables: {
          data: {
            prospect: {
              connect: {
                id: prospect?.id ?? '',
              },
            },
            staffMember: {
              connect: {
                id: state.staffMemberId ?? '',
              },
            },
            data: state.data || {},
            staffMemberRole: state.staffMemberRole,
            isPrimary: state.isPrimary,
            isSubscribed: state.isSubscribed,
          },
        },
      })
    }
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title={
        prospectStaff ? 'Edit Staff Relationship' : 'Create Staff Relationship'
      }
      saveButton={{
        disabled: !state.staffMemberId || !state.staffMemberRole,
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
      contentStyle={{ overflow: 'initial' }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Field>
          <label>Staff Member</label>
          <Dropdown
            fluid
            options={userOptions}
            onChange={(_, { value }) =>
              setState({
                ...state,
                staffMemberId: value as string,
              })
            }
            placeholder="Select Staff Member"
            search
            selection
            value={state.staffMemberId}
          />
        </Form.Field>
        <Form.Field>
          <label>Role</label>
          <Dropdown
            fluid
            options={[
              ProspectStaffRole.ACCOUNT_EXECUTIVE,
              ProspectStaffRole.PROPOSED_DESIGNER,
              ProspectStaffRole.TRADE_DESIGNER,
            ].map((r) => ({
              key: r,
              text: viewUtils.capsSnakeCaseToTitleCase(r),
              value: r,
            }))}
            onChange={(_, { value }) =>
              setState({
                ...state,
                staffMemberRole: value as ProspectStaffRole,
              })
            }
            placeholder="Role"
            selection
            value={state.staffMemberRole}
          />
        </Form.Field>
        <Form.Dropdown
          fluid
          label="Assignment Type"
          options={[
            StaffMemberAssignmentType.MANUAL,
            StaffMemberAssignmentType.REFERRED,
            StaffMemberAssignmentType.ROUND_ROBIN,
            StaffMemberAssignmentType.ROUND_ROBIN_EXEMPT,
            StaffMemberAssignmentType.ROUND_ROBIN_REFILL,
          ].map((r) => ({
            key: r,
            text: viewUtils.capsSnakeCaseToTitleCase(r),
            value: r,
          }))}
          onChange={(_, { value }) =>
            setState({
              ...state,
              data: {
                ...state.data,
                staffMemberAssignmentType: value as StaffMemberAssignmentType,
              },
            })
          }
          placeholder="Assignment Type"
          selection
          value={state.data?.staffMemberAssignmentType || ''}
        />
        {state.staffMemberRole === ProspectStaffRole.PROPOSED_DESIGNER && (
          <Form.Field>
            <label>One Off Initial Call URL</label>
            <Form.Input
              name="designCallOneOffUrl"
              onChange={(
                _: React.SyntheticEvent<HTMLElement, Event>,
                { value }: { value: string },
              ) => {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    designCallOneOffUrl: value,
                  },
                })
              }}
              value={state.data?.designCallOneOffUrl || ''}
            />
          </Form.Field>
        )}
        {state.staffMemberRole === ProspectStaffRole.PROPOSED_DESIGNER && (
          <Form.Field>
            <label>One Off Initial Call Expiry</label>
            <DateTimeInput
              closable
              autoComplete="off"
              className="example-calendar-input"
              dateFormat="MM-DD-YYYY hh:mm a"
              timeFormat="ampm"
              duration={0}
              hideMobileKeyboard
              iconPosition="left"
              initialDate={now}
              name="time"
              onChange={onChangeStartTime}
              placeholder="Time"
              value={
                state.data?.designCallOneOffExpiration
                  ? moment(state.data.designCallOneOffExpiration).format(
                      'MM-DD-YYYY hh:mm a',
                    )
                  : ''
              }
            />
          </Form.Field>
        )}
        <FormCheckbox
          disabled={disablePrimary}
          checked={state.isPrimary}
          label={'Primary'}
          onChange={() =>
            setState({
              ...state,
              isPrimary: !state.isPrimary,
            })
          }
        />
        <FormCheckbox
          disabled={disablePrimary}
          checked={state.isSubscribed}
          label={'Subscribed'}
          onChange={() =>
            setState({
              ...state,
              isSubscribed: !state.isSubscribed,
            })
          }
        />
      </StyledForm>
    </Modal>
  )
}

export default ProspectStaffCreateUpdateModal
