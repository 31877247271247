import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Checkbox, Grid } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import TechDesignerProvider from 'context/dashboard/tech-designer/provider'
import { UserType, UserTypeStaff } from 'types/user'

const TechDesignerIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  const { formUserId, user } = useAuth()

  return (
    <TechDesignerProvider user_id={props.user_id ?? ''}>
      <Grid>
        <Grid.Row style={{ padding: '0 0 3px' }}>
          <Grid.Column textAlign="right">
            <Checkbox
              checked={(
                [UserTypeStaff.TECH_DESIGNER, UserTypeStaff.ADMIN] as UserType[]
              ).includes(user.type)}
              label="Tech Designer Dashboard"
              onChange={() =>
                navigate(`/admin/dashboard/${formUserId}/tech-designer-support`)
              }
              toggle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {props.children}
    </TechDesignerProvider>
  )
}

export default TechDesignerIndex
