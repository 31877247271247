import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Checkbox, Grid } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import TechDesignerSupportProvider from 'context/dashboard/tech-designer-support/provider'
import { UserTypeStaff } from 'types/user'

const TechDesignerSupportIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  const { formUserId, user } = useAuth()

  return (
    <TechDesignerSupportProvider user_id={props.user_id ?? ''}>
      <Grid>
        <Grid.Row style={{ padding: '0 0 3px' }}>
          <Grid.Column textAlign="right">
            <Checkbox
              checked={user.type === UserTypeStaff.TECH_DESIGNER_SUPPORT}
              label="Tech Designer Support Dashboard"
              onChange={() =>
                navigate(`/admin/dashboard/${formUserId}/tech-designer`)
              }
              toggle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {props.children}
    </TechDesignerSupportProvider>
  )
}

export default TechDesignerSupportIndex
