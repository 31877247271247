import React from 'react'

import { isEqual } from 'lodash'
import { Grid } from 'semantic-ui-react'

import {
  CatalogFeatureDiff,
  CatalogFeatureUpdate,
} from 'types/catalog-feature-diff'

import MediaDiffComponent from './media-diff'
import {
  getReferenceImage,
  ImageCard,
  ReferenceDiv,
  StrikeThrough,
  StyledLabelDiffs,
  SubGrid,
} from './utils'

const parseDataFields = (catalogFeature: CatalogFeatureDiff) => {
  const isUpdate = catalogFeature.type === 'update'
  const snapshotFrom = isUpdate
    ? (catalogFeature as CatalogFeatureUpdate).data?.from
    : catalogFeature.snapshot
  const snapshotTo = isUpdate
    ? (catalogFeature as CatalogFeatureUpdate).data?.to
    : catalogFeature.snapshot
  const snapshotFromData = snapshotFrom?.data
  const snapshotToData = snapshotTo?.data
  const out = [
    {
      fieldName: 'Description',
      fieldValue: snapshotTo?.description,
      fieldValueChanged: snapshotFrom?.description,
    },
    {
      fieldName: 'Group Identifier',
      fieldValue: snapshotTo?.groupIdentifier,
      fieldValueChanged: snapshotFrom?.groupIdentifier,
    },
    {
      fieldName: 'Display Name',
      fieldValue: snapshotToData?.displayName,
      fieldValueChanged: snapshotFromData?.displayName,
    },
    {
      fieldName: 'Category',
      fieldValue: snapshotToData?.category,
      fieldValueChanged: snapshotFromData?.category,
    },
    {
      fieldName: 'Discontinued',
      fieldValue: snapshotToData?.isDiscontinued,
      fieldValueChanged: snapshotFromData?.isDiscontinued,
    },
    {
      fieldName: 'Price Range',
      fieldValue: snapshotToData?.priceRange,
      fieldValueChanged: snapshotFromData?.priceRange,
    },
    {
      fieldName: 'Show On Website Catalog',
      fieldValue: snapshotToData?.showOnWebsiteCatalog,
      fieldValueChanged: snapshotFromData?.showOnWebsiteCatalog,
    },
    {
      fieldName: 'Usage',
      fieldValue: snapshotToData?.usage,
      fieldValueChanged: snapshotFromData?.usage,
    },
  ]
  return out.filter((o) => {
    if (!isUpdate) return true
    return !isEqual(o.fieldValue, o.fieldValueChanged)
  })
}

const MediaDiffs = ({
  catalogFeatureDiff,
}: {
  catalogFeatureDiff: CatalogFeatureDiff
}) => {
  const isAdd = catalogFeatureDiff.type === 'add'
  const isDelete = catalogFeatureDiff.type === 'delete'
  const showMedia =
    !isAdd && !isDelete
      ? !!catalogFeatureDiff.media?.length
      : catalogFeatureDiff.snapshot.media.length > 0
  if (!showMedia) return <></>
  if (isAdd)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {catalogFeatureDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'add',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  if (isDelete)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {catalogFeatureDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'delete',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  return (
    <>
      <p className="caption no-margin">Media</p>
      {catalogFeatureDiff.media?.map((mdiff) => (
        <MediaDiffComponent key={mdiff.snapshot.key} mediaDiff={mdiff} />
      ))}
    </>
  )
}

const DataDiffs = ({
  catalogFeatureDiff,
}: {
  catalogFeatureDiff: CatalogFeatureDiff
}) => {
  const fields = parseDataFields(catalogFeatureDiff)
  const isUpdate = catalogFeatureDiff.type === 'update'
  return (
    <>
      {!!fields.length && <p className="caption no-margin">Data</p>}
      {fields.map((f) => (
        <Grid.Row key={f.fieldName}>
          <Grid.Column width={4}>
            <p className="caption">{f.fieldName}</p>
          </Grid.Column>
          <Grid.Column width={12}>
            {isUpdate ? (
              <StrikeThrough>{`${f.fieldValueChanged}`}</StrikeThrough>
            ) : null}
            <p>{`${f.fieldValue}`}</p>
          </Grid.Column>
        </Grid.Row>
      ))}
    </>
  )
}

const CatalogFeatureDiffComponent = ({
  catalogFeatureDiff,
}: {
  catalogFeatureDiff: CatalogFeatureDiff
}) => {
  const reference = getReferenceImage(catalogFeatureDiff.snapshot.media)

  return (
    <>
      <ReferenceDiv>
        <ImageCard image={reference} />
        <div>
          <p className="caption">
            {catalogFeatureDiff.snapshot.data?.displayName ||
              catalogFeatureDiff.snapshot.description}
          </p>
          <p>{catalogFeatureDiff.snapshot.description}</p>
        </div>
        <StyledLabelDiffs type={catalogFeatureDiff.type} />
      </ReferenceDiv>
      <SubGrid>
        <MediaDiffs catalogFeatureDiff={catalogFeatureDiff} />
        <DataDiffs catalogFeatureDiff={catalogFeatureDiff} />
      </SubGrid>
    </>
  )
}

export default CatalogFeatureDiffComponent
