import { useMemo } from 'react'

import { DocumentNode, useQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import {
  FIND_MANY_SHIPMENT_SHALLOW,
  FindManyShipmentVariables,
  FindManyShipmentPayload,
} from 'queries/shipment'

const useShipmentManyNoContext = (opts?: {
  variables?: FindManyShipmentVariables
  query?: DocumentNode
}) => {
  const {
    data: { shipments } = { shipments: [] },
    loading,
    refetch,
  } = useQuery<FindManyShipmentPayload, FindManyShipmentVariables>(
    opts?.query || FIND_MANY_SHIPMENT_SHALLOW,
    {
      variables: opts?.variables,
    },
  )

  const shipmentOptions = useMemo(() => {
    return sortBy<{
      key: string
      text: string
      value: string
    }>(
      shipments.map((s) => {
        return {
          key: s.id ?? '',
          text: s.reference ?? '',
          value: s.id ?? '',
        }
      }),
      'text',
    )
  }, [shipments])

  return {
    loading,
    refetch,
    shipmentOptions,
    shipments,
  }
}

export default useShipmentManyNoContext
