import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NotesDashboard from 'components/admin/project-notes/notes-dashboard'
import useAuth from 'context/auth/use'
import CommentNoteManyProvider from 'context/project/project-notes/provider-many'

import TradeDesignerLayout from './layout'

type TradeDesignerNotesProps = React.PropsWithChildren<RouteComponentProps>

const TradeDesignerNotes = (_: TradeDesignerNotesProps) => {
  const { formUserId } = useAuth()

  return (
    <CommentNoteManyProvider
      formUserId={formUserId}
      defaultFilters={{
        newFor: {
          id: formUserId,
        },
      }}
    >
      <TradeDesignerLayout>
        <NotesDashboard />
      </TradeDesignerLayout>
    </CommentNoteManyProvider>
  )
}

export default TradeDesignerNotes
