import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import * as dsUtils from 'context/design-option-utils'
import {
  CREATE_DESIGN,
  CreateDesignPayload,
  CreateDesignVariables,
  DELETE_DESIGN,
  DeleteDesignPayload,
  DeleteDesignVariables,
  UPDATE_DESIGN,
  UpdateDesignPayload,
  UpdateDesignVariables,
} from 'queries/design'
import { Design } from 'types/design'
import { RenderProp } from 'types/render-prop'
import { RoomGraphQL } from 'types/room'

import { RoomContext } from './provider'

const useRoomDesignMutate = () => {
  const { refetch } = useContext(RoomContext)

  const [createDesignAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateDesignPayload,
    CreateDesignVariables
  >(CREATE_DESIGN, {
    onCompleted: refetch,
  })

  const [deleteDesignAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteDesignPayload,
    DeleteDesignVariables
  >(DELETE_DESIGN, {
    onCompleted: refetch,
  })

  const [updateDesignAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateDesignPayload,
    UpdateDesignVariables
  >(UPDATE_DESIGN, {
    onCompleted: refetch,
  })

  return {
    createDesignAndRefetch,
    deleteDesignAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateDesignAndRefetch,
  }
}

export default useRoomDesignMutate

// gets design options from last design or room to copy
export const calculateInitialDesignOptions = (
  room?: RoomGraphQL,
  lastDesign?: Design,
) => {
  const {
    catalogFeatures,
    disconnectedOptions,
    genericOptions,
    nobiliaOptions,
    renderProps,
    roomElementOptions,
  } = lastDesign ? optionsFromDesign(lastDesign) : optionsFromRoom(room)
  return {
    catalogFeatures: {
      create: catalogFeatures.map((c) => ({
        identifier: 'default',
        catalogFeature: {
          connect: {
            id: c.id as string,
          },
        },
      })),
    },
    disconnectedOptions: {
      create: disconnectedOptions.map((c) => ({
        identifier:
          c.optionIdentifier === 'custom'
            ? (c.data?.fileId as string)
            : 'default',
        ...(c.optionIdentifier === 'custom' && {
          data: {
            name: c.data?.customName as string,
          },
          projectFile: {
            connect: {
              id: c.data?.fileId as string,
            },
          },
        }),
        disconnectedOption: {
          connect: {
            id: c.id as string,
          },
        },
      })),
    },
    genericOptions: {
      create: genericOptions.map((c) => ({
        identifier:
          c.optionIdentifier === 'custom' ? c.data?.fileId : 'default',
        ...((c.data?.customName || c.data?.linkToProductSpec) && {
          data: {
            name: c.data?.customName ? c.data?.customName : undefined,
            linkToProductSpec: c.data?.linkToProductSpec
              ? c.data?.linkToProductSpec
              : undefined,
          },
        }),
        ...(c.optionIdentifier === 'custom' && {
          projectFile: {
            connect: {
              id: c.data?.fileId as string,
            },
          },
        }),
        genericOption: {
          connect: {
            id: c.id as string,
          },
        },
      })),
    },
    nobiliaOptions: {
      create: nobiliaOptions?.map((n) => ({
        identifier: 'default',
        nobiliaOption: {
          connect: {
            id: n.id,
          },
        },
      })),
    },
    renderProps: {
      connect: renderProps?.map((r) => ({ id: r.id })),
    },
    roomElementOptions: {
      create: roomElementOptions.map((c) => ({
        identifier:
          c.optionIdentifier === 'custom' ? c.data?.fileId : 'default',
        ...((c.data?.customName || c.data?.linkToProductSpec) && {
          data: {
            ...(c.data?.customName && {
              name: c.data?.customName as string,
            }),
            ...(c.data?.linkToProductSpec && {
              linkToProductSpec: c.data?.linkToProductSpec as string,
            }),
          },
        }),
        ...(c.optionIdentifier === 'custom' && {
          projectFile: {
            connect: {
              id: c.data?.fileId as string,
            },
          },
        }),
        roomElementOption: {
          connect: {
            id: c.id as string,
          },
        },
      })),
    },
  }
}

const optionsFromDesign = (design: Design) => {
  const { catalogFeatures } = dsUtils.parseCatalogFeaturesDesigns(
    design.catalogFeatures,
  )
  const { inspirationGenerals, inspirationElements } =
    dsUtils.parseDisconnectedOptionsDesigns(design.disconnectedOptions)
  const { backsplashMaterials, countertopMaterials } =
    dsUtils.parseGenericOptionsDesigns(design.genericOptions)
  const { floorMaterials } = dsUtils.parseRoomElementOptionsDesigns(
    design.roomElementOptions,
  )
  return {
    catalogFeatures,
    disconnectedOptions: [...inspirationElements, ...inspirationGenerals],
    genericOptions: [...backsplashMaterials, ...countertopMaterials],
    nobiliaOptions: design.nobiliaOptions.map((n) => n.nobiliaOption),
    roomElementOptions: floorMaterials,
    renderProps: design.renderProps,
  }
}

const optionsFromRoom = (room?: RoomGraphQL) => {
  const { catalogFeatures } = dsUtils.parseCatalogFeaturesRooms(
    room?.catalogFeatures ?? [],
  )
  const { inspirationGenerals, inspirationElements } =
    dsUtils.parseDisconnectedOptionsRooms(room?.disconnectedOptions ?? [])
  const { backsplashMaterials, countertopMaterials } =
    dsUtils.parseGenericOptionsRooms(room?.genericOptions ?? [])
  const { floorMaterials } = dsUtils.parseRoomElementOptionsDesigns(
    room?.roomElementOptions ?? [],
  )
  return {
    catalogFeatures,
    disconnectedOptions: [...inspirationElements, ...inspirationGenerals],
    genericOptions: [...backsplashMaterials, ...countertopMaterials],
    nobiliaOptions: room?.nobiliaOptions.map((n) => n.nobiliaOption),
    roomElementOptions: floorMaterials,
    renderProps: [] as RenderProp[],
  }
}
