import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_ROOM_ELEMENT_CLASS,
  FindUniqueRoomElementClassPayload,
  FindUniqueRoomElementClassVariables,
} from 'queries/room-element-class'
import { RoomElementClass } from 'types/room-element-class'

interface IRoomElementClassContext {
  catalog: string
  roomElementClass: RoomElementClass
  refetch: () => Promise<unknown>
}

interface RoomElementClassProps
  extends RouteComponentProps<{
    catalog: string
    room_element_class_id: string
  }> {
  children: React.ReactNode
}

export const RoomElementClassContext = createContext<IRoomElementClassContext>(
  {} as IRoomElementClassContext,
)

const RoomElementClassProvider = (props: RoomElementClassProps) => {
  const { data, loading, refetch } = useQuery<
    FindUniqueRoomElementClassPayload,
    FindUniqueRoomElementClassVariables
  >(FIND_UNIQUE_ROOM_ELEMENT_CLASS, {
    variables: {
      where: { id: props.room_element_class_id },
    },
  })

  if (loading || !data) return <Loader />

  return (
    <RoomElementClassContext.Provider
      value={{
        catalog: props.catalog ?? '',
        roomElementClass: data.roomElementClass,
        refetch,
      }}
    >
      {props.children}
    </RoomElementClassContext.Provider>
  )
}

export default RoomElementClassProvider
