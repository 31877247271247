import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Form } from 'semantic-ui-react'

import MarkdownTooltip from 'components/admin/markdown-tooltip'
import { notifySuccess } from 'context/notifications/trigger'
import { Modal } from 'design-system/components/modal'
import {
  CREATE_ONE_DESIGN_ADVISORY_CODE,
  CreateOneDesignAdvisoryCodePayload,
  CreateOneDesignAdvisoryCodeVariables,
  UPDATE_ONE_DESIGN_ADVISORY_CODE,
  UpdateOneDesignAdvisoryCodePayload,
  UpdateOneDesignAdvisoryCodeVariables,
} from 'queries/design-advisory-code'
import { StyledForm } from 'styles/admin/main'
import { DesignAdvisoryCode } from 'types/design-advisory-code'

const md = require('markdown-it')({
  breaks: true,
})
interface DesignAdvisoryCodeProps {
  designAdvisoryCode?: DesignAdvisoryCode
  designAdvisoryCodeGroupId?: string
  onCloseModal: () => void
  openModal: boolean
  refetch?: () => void
}

const CreateUpdateDesignAdvisoryCodeModal = ({
  designAdvisoryCodeGroupId,
  designAdvisoryCode,
  onCloseModal,
  openModal,
  refetch,
}: DesignAdvisoryCodeProps) => {
  const initialState = {
    description: designAdvisoryCode?.description || '',
    index: designAdvisoryCode?.index || '',
    required: designAdvisoryCode?.required || false,
  }
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (openModal) setState(initialState)
  }, [openModal])

  const [createDesignAdvisoryCode] = useMutation<
    CreateOneDesignAdvisoryCodePayload,
    CreateOneDesignAdvisoryCodeVariables
  >(CREATE_ONE_DESIGN_ADVISORY_CODE, {
    onCompleted: () => {
      notifySuccess('Design Advisory Code Created')
      refetch?.()
    },
  })

  const [updateDesignAdvisoryCode] = useMutation<
    UpdateOneDesignAdvisoryCodePayload,
    UpdateOneDesignAdvisoryCodeVariables
  >(UPDATE_ONE_DESIGN_ADVISORY_CODE, {
    onCompleted: () => {
      notifySuccess('Design Advisory Code Updated')
      refetch?.()
    },
  })

  const onClose = () => {
    if (!designAdvisoryCode)
      setState({
        index: '',
        description: '',
        required: false,
      })

    onCloseModal()
  }

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: value })

  const handleSubmit = async () => {
    if (designAdvisoryCode?.id) {
      await updateDesignAdvisoryCode({
        variables: {
          data: {
            description: state.description,
            index: state.index,
            required: state.required,
          },
          where: {
            id: designAdvisoryCode.id,
          },
        },
      })
    } else if (designAdvisoryCodeGroupId) {
      await createDesignAdvisoryCode({
        variables: {
          data: {
            description: state.description,
            index: state.index,
            required: state.required,
            designAdvisoryCodeGroup: {
              connect: {
                id: designAdvisoryCodeGroupId,
              },
            },
          },
        },
      })
    }

    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={openModal}
      size="large"
      title={
        designAdvisoryCode
          ? 'Update Design Advisory Code'
          : 'Create Design Advisory Code'
      }
      saveButton={{
        disabled: !state.index || !state.description,
        onClick: handleSubmit,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Input
          label="Index"
          name="index"
          onChange={onChangeInput}
          required
          value={state.index}
        />
        <Form.Group widths="equal">
          <Form.Input
            label={
              <label>
                Description
                <span style={{ color: '#db2828', marginRight: '5px' }}>*</span>
                <MarkdownTooltip />
              </label>
            }
            name="description"
            onChange={onChangeInput}
            value={state.description}
          />
          <Form.Field>
            <label>Preview</label>
            <div
              dangerouslySetInnerHTML={{
                __html: md.render(state.description),
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Checkbox
          checked={state.required}
          label="Required"
          name="required"
          onChange={() => {
            setState({
              ...state,
              required: !state.required,
            })
          }}
          slider
        />
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateDesignAdvisoryCodeModal
