import { useContext } from 'react'

import { IssueManyContext } from './provider-many'

const useIssueMany = () => {
  const {
    issues,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(IssueManyContext)

  return {
    issues,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useIssueMany
