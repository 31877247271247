import { useMutation } from '@apollo/client'

import {
  CreateTaskPayload,
  CreateTaskVariables,
  CREATE_TASK,
  DeleteTaskPayload,
  DeleteTaskVariables,
  DELETE_TASK,
  UpdateTaskPayload,
  UpdateTaskVariables,
  UPDATE_TASK,
} from 'queries/task'

const useTaskMutate = () => {
  const [createTask, { loading: loadingCreate }] = useMutation<
    CreateTaskPayload,
    CreateTaskVariables
  >(CREATE_TASK)

  const [updateTask, { loading: loadingUpdate }] = useMutation<
    UpdateTaskPayload,
    UpdateTaskVariables
  >(UPDATE_TASK)

  const [deleteTask, { loading: loadingDelete }] = useMutation<
    DeleteTaskPayload,
    DeleteTaskVariables
  >(DELETE_TASK)

  return {
    createTask,
    deleteTask,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateTask,
  }
}

export default useTaskMutate
