import React from 'react'

import { Image, List } from 'semantic-ui-react'

import { parseStaff } from 'context/project/utils'
import { TableUserListItem } from 'styles/admin/main'
import { Project } from 'types/project'
import { ProjectStaffRole } from 'types/project-staff'
import { capsSnakeCaseToTitleCase, getShortNameForUser } from 'views/utils'

export const ProjectStaffCellContents = ({
  project,
  roleStaff,
}: {
  project?: Project | null
  roleStaff?: ProjectStaffRole
}) => {
  const parsedStaff = parseStaff(project)
  let staff = []
  if (parsedStaff.salesDesignerStaff) staff.push(parsedStaff.salesDesignerStaff)
  if (parsedStaff.tradeDesignerStaff) staff.push(parsedStaff.tradeDesignerStaff)
  if (parsedStaff.techDesignerStaff) staff.push(parsedStaff.techDesignerStaff)
  if (roleStaff)
    staff = parsedStaff.allProjectStaff.filter(
      (s) => s.staffMemberRole === roleStaff,
    )

  return (
    <List relaxed>
      {staff?.map((u) => (
        <TableUserListItem key={u.staffMember?.id}>
          <Image src={u.staffMember?.picture} avatar circular />
          <List.Content>
            <p className="no-margin small">
              {getShortNameForUser(u.staffMember)}
            </p>
            <p className="no-margin x-small light-gray">
              {capsSnakeCaseToTitleCase(u.staffMemberRole)}
            </p>
          </List.Content>
        </TableUserListItem>
      ))}
    </List>
  )
}
