import React from 'react'

import { Image, Table } from 'semantic-ui-react'

import * as mUtils from 'context/media/utils-many'
import useNobiliaProductClassMany from 'context/nobilia-product-class/use-many'
import Button from 'design-system/components/button/button'
import * as viewUtils from 'views/utils'

const NPCTable = () => {
  const { nobiliaProductClasses, queryFilters, setQueryFilters } =
    useNobiliaProductClassMany()
  const { sortBy, sortDirection } = queryFilters
  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Order Id</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedSort' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedSort',
                sortDirection:
                  sortBy === 'derivedSort' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Section
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {nobiliaProductClasses.map((npc) => {
          const display = mUtils.parseMedia(npc.media).reference.length
            ? mUtils.parseMedia(npc.media).reference[0].key
            : 'images/app/catalog_missing_file.png'
          return (
            <Table.Row key={npc.id}>
              <Table.Cell width={4}>
                <Image
                  centered
                  size="small"
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    display,
                  )}`}
                />
              </Table.Cell>
              <Table.Cell width={2}>{npc.orderId}</Table.Cell>
              <Table.Cell width={2}>{npc.meta?.catalogSection}</Table.Cell>
              <Table.Cell width={4}>
                {viewUtils.capsSnakeCaseToTitleCase(npc.productType)}
              </Table.Cell>
              <Table.Cell textAlign="center" width={2}>
                <Button
                  kind="solid"
                  color="blue"
                  fontAwesomeIcon="arrow-up-right-from-square"
                  onClick={() =>
                    window.open(
                      `/admin/catalog/${npc.catalog}/nobilia-product-classes/${npc.id}`,
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default NPCTable
