import React from 'react'

import { RouteComponentProps } from '@reach/router'

import AdminLayout from 'components/admin/layout'
import Tracking from 'components/shared/tracking'
import ProspectProvider from 'context/prospect/provider'

const ProspectView = (
  props: RouteComponentProps<{ prospect_id: string }> & {
    children?: React.ReactNode
  },
) => {
  return (
    <AdminLayout noHeader={props.location?.pathname.includes('presentation')}>
      <ProspectProvider prospect_id={props.prospect_id}>
        <Tracking />
        {props.children}
      </ProspectProvider>
    </AdminLayout>
  )
}

export default ProspectView
