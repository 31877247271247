import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectFileNotFound from 'components/shared/project/project-file/file-not-found'
import ProjectFileLayout from 'components/shared/project/project-file/layout'
import { ScrollContainer } from 'components/shared/project/project-file/utils'
import ProjectFileProvider from 'context/project-file/provider'
import useProjectFile from 'context/project-file/use'
import { StatusMode } from 'types/utils'

import SidebarApproved from './sidebar-approved'
import SidebarComments from './sidebar-comments-external'

type ProjectFileProps = RouteComponentProps<{
  project_id: string
  room_id: string
  design_id: string
  file_id: string
}>

const DesignPlanExternal = (_: ProjectFileProps) => {
  const { projectFile, externalReviewMode } = useProjectFile()
  const [sidebarShow, setSidebarShow] = useState(false)

  if (!projectFile) return <ProjectFileNotFound />

  const sidebarToggle = () => setSidebarShow(!sidebarShow)

  const mode = externalReviewMode

  return (
    <ProjectFileLayout
      commentsOnImageEnabled={mode === StatusMode.OpenForRevision}
      commentsOnImageShowing={
        mode === StatusMode.OpenForRevision || mode === StatusMode.Submitted
      }
      sidebar={(() => {
        if (mode === StatusMode.Confirmed) return <SidebarApproved />
        return (
          <ScrollContainer>
            <SidebarComments sidebarToggle={sidebarToggle} />
          </ScrollContainer>
        )
      })()}
      sidebarShow
      sidebarToggle={sidebarToggle}
      sidebarTrigger={
        mode === StatusMode.Confirmed ? undefined : <p>Comments</p>
      }
    />
  )
}

export default (props: ProjectFileProps) => (
  <ProjectFileProvider file_id={props.file_id}>
    <DesignPlanExternal {...props} />
  </ProjectFileProvider>
)
