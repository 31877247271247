import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { ApolloQueryResult } from '@apollo/client'

import useInfiniteQuery from 'hooks/use-infinite-query'
import {
  FIND_MANY_PROJECT_EVENT,
  FindManyProjectEventPayload,
  FindManyProjectEventCountPayload,
  FindManyProjectEventVariables,
} from 'queries/project-event'
import { ProjectEvent } from 'types/project-event'
import { groupItemsByDate } from 'utils'

import useProjectEventCount from './use-count'
import { QueryFilters, getQueryVariables } from './utils-many'

type FetchMore = () => Promise<ApolloQueryResult<FindManyProjectEventPayload>>

interface IProjectEventManyContext {
  projectEvents: ProjectEvent[]
  projectEventsGroupedByDate: [string, ProjectEvent[]][]
  count: number
  fetchMore: FetchMore
  fetchedCount: number
  newForMeCount: number
  loading: boolean
  loadingMore: boolean
  refetch: () => Promise<ApolloQueryResult<FindManyProjectEventPayload>>
  refetchNewForMeCount: () => Promise<
    ApolloQueryResult<FindManyProjectEventCountPayload>
  >
  refetchAll: () => Promise<
    [
      ApolloQueryResult<FindManyProjectEventCountPayload>,
      ApolloQueryResult<FindManyProjectEventPayload>,
    ]
  >
  queryFilters: QueryFilters
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

export type ProjectEventsManyProviderProps = {
  defaultFilters: QueryFilters
  formUserId: string
}

export const ProjectEventManyContext = createContext<IProjectEventManyContext>(
  {} as IProjectEventManyContext,
)

const ProjectEventsManyProvider = ({
  children,
  defaultFilters,
  formUserId,
}: React.PropsWithChildren<ProjectEventsManyProviderProps>) => {
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(defaultFilters)
  const [queryVariables, setQueryVariables] =
    useState<FindManyProjectEventVariables>(getQueryVariables(queryFilters))

  const { data, loading, loadingMore, fetchedCount, fetchMore, refetch } =
    useInfiniteQuery<
      FindManyProjectEventPayload,
      FindManyProjectEventVariables
    >(FIND_MANY_PROJECT_EVENT, {
      cacheFieldName: 'findManyProjectEvent',
      fetchedPropertyName: 'projectEvents',
      refetchBehavior: 'keepSkip',
      queryOptions: {
        variables: queryVariables,
      },
    })

  useEffect(() => {
    setQueryFilters(defaultFilters)
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  const { count: newForMeCount, refetch: refetchNewForMeCount } =
    useProjectEventCount({
      ...queryFilters,
      newFor: {
        id: formUserId,
      },
    })

  const projectEventsGroupedByDate = useMemo(
    () => groupItemsByDate(data?.projectEvents || []),
    [data?.projectEvents],
  )

  const refetchAll = async () =>
    Promise.all([refetchNewForMeCount(), refetch()])

  return (
    <ProjectEventManyContext.Provider
      value={{
        projectEvents: data?.projectEvents || [],
        projectEventsGroupedByDate,
        count: data?.count || 0,
        fetchedCount,
        fetchMore: fetchMore as FetchMore,
        loading,
        loadingMore,
        newForMeCount,
        queryFilters,
        refetch,
        refetchNewForMeCount,
        refetchAll,
        setQueryFilters,
      }}
    >
      {children}
    </ProjectEventManyContext.Provider>
  )
}

export const useProjectEventsMany = () => useContext(ProjectEventManyContext)

export default ProjectEventsManyProvider
