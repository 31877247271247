import React from 'react'

import { orderBy } from 'lodash'
import { Grid } from 'semantic-ui-react'

import useDisconnectedOptionFiltersSearch from 'components/admin/catalog/disconnected-option/use-filters-search'
import FiltersAndSearch from 'components/admin/filters-search'
import DisconnectedOptionSelectionCard from 'components/shared/catalog/disconnected-option/card'
import Loader from 'components/shared/loader'
import useDisconnectedOptionMany from 'context/disconnected-option/use-many'
import {
  SelectionCardProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { DisconnectedOption } from 'types/disconnected-option'

const DisconnectedOptionsSelection = ({
  selectedDisconnectedOptions,
  selectionCardProps,
  setSelectedDisconnectedOptions,
}: {
  selectedDisconnectedOptions: Partial<DisconnectedOption>[]
  selectionCardProps?: SelectionCardProps
  setSelectedDisconnectedOptions: (
    disconnectedOption: Partial<DisconnectedOption>[],
  ) => void
}) => {
  const { disconnectedOptions, loading } = useDisconnectedOptionMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useDisconnectedOptionFiltersSearch()
  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
        style={{ marginTop: '10px' }}
      />
      {loading ? (
        <Grid.Row style={{ minHeight: '100vh' }}>
          <Grid.Column>
            <Loader flex fullScreen={false} />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row columns={4}>
          {orderBy(
            disconnectedOptions,
            ['data.deprecated', 'description'],
            ['desc', 'asc'],
          ).map((item) => {
            return (
              <Grid.Column key={item.id} style={{ marginBottom: '20px' }}>
                <DisconnectedOptionSelectionCard
                  disconnectedOption={item}
                  selectionCardProps={{
                    ...selectionCardProps,
                    selectOnly: true,
                    onSelect: () => {
                      if (
                        selectedDisconnectedOptions.find(
                          (s) => s.id === item.id,
                        )
                      )
                        setSelectedDisconnectedOptions(
                          selectedDisconnectedOptions.filter(
                            (s) => s.id !== item.id,
                          ),
                        )
                      else
                        setSelectedDisconnectedOptions([
                          ...selectedDisconnectedOptions,
                          item,
                        ])
                    },
                    selected: selectedDisconnectedOptions.some(
                      (s) => s.id === item.id,
                    ),
                  }}
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      )}
    </Grid>
  )
}

export default DisconnectedOptionsSelection
