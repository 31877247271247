import React, { useEffect, useMemo, useState } from 'react'

import { Form } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import useCatalogFeatureMutate from 'context/catalog-feature/use-mutate'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { CatalogFeature } from 'types/catalog-feature'

import { groupOptions as catalogFeaturesGroupOptions } from './use-filters-search'

const DEFAULT_STATE: CatalogFeature = {
  data: {},
  description: '',
  groupIdentifier: '',
  id: '',
  identifier: '',
  media: [],
  nobiliaProductClasses: [],
}

type CreateUpdateCatalogFeatureModalProps = {
  catalogFeature?: CatalogFeature
  onClose: () => void
  onSave: (id: string) => void
  openModal: boolean
  trigger?: React.ReactElement
}

const CreateUpdateCatalogFeatureModal = ({
  catalogFeature,
  onClose,
  onSave,
  openModal,
  trigger,
}: CreateUpdateCatalogFeatureModalProps) => {
  const [state, setState] = useState<CatalogFeature>(
    catalogFeature ?? DEFAULT_STATE,
  )

  const {
    createCatalogFeature,
    loadingCreate,
    updateCatalogFeature,
    loadingUpdate,
  } = useCatalogFeatureMutate()

  const catalogFeaturesTypes = useMemo(
    () =>
      catalogFeaturesGroupOptions.map<{
        key: string
        text: string
        value: string
      }>((group) => ({
        key: group.groupIdentifier,
        value: group.groupIdentifier,
        text: group.label,
      })),
    [],
  )

  const handleClose = () => {
    onClose()
    setState(DEFAULT_STATE)
  }

  const handleSave = async () => {
    const identifier = generateIdentifier()

    await createCatalogFeature({
      variables: {
        data: {
          data: {},
          description: state.description,
          groupIdentifier: state.groupIdentifier,
          identifier,
        },
      },
      onCompleted: ({ createOneCatalogFeature: { id } }) => {
        notifySuccess('Catalog feature created')
        onSave(id)
        setState(DEFAULT_STATE)
      },
      onError: () => {
        notifyError('Could not create feature')
      },
    })
  }

  const handleUpdate = async () => {
    await updateCatalogFeature({
      variables: {
        data: {
          description: state.description,
          groupIdentifier: state.groupIdentifier,
        },
        where: {
          id: catalogFeature?.id ?? '',
        },
      },
      onCompleted: ({ updateOneCatalogFeature: { id } }) => {
        notifySuccess('Catalog feature updated')
        onSave(id)
        setState(DEFAULT_STATE)
      },
      onError: () => {
        notifyError('Could not update feature')
      },
    })
  }

  useEffect(() => {
    setState(catalogFeature || DEFAULT_STATE)
  }, [catalogFeature])

  const isValidForm = state.groupIdentifier && state.description.trim()

  return (
    <Modal
      onClose={handleClose}
      open={openModal}
      size="medium"
      trigger={trigger}
      title={
        catalogFeature ? 'Update Catalog Feature' : 'Create Catalog Feature'
      }
      saveButton={
        catalogFeature
          ? {
              disabled: !isValidForm,
              loading: loadingUpdate,
              onClick: handleUpdate,
            }
          : {
              disabled: !isValidForm,
              loading: loadingCreate,
              onClick: handleSave,
            }
      }
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Input
          label="Name (with Order ID for search)"
          name="Name"
          type="text"
          placeholder="Name (with Order ID for search)"
          value={state.description}
          onChange={(evt) =>
            setState((currState) => ({
              ...currState,
              description: evt.target.value,
            }))
          }
          required
        />
        <Form.Dropdown
          label="Group"
          options={catalogFeaturesTypes}
          search
          fluid
          selection
          onChange={(_, { value }) =>
            setState((currState) => ({
              ...currState,
              groupIdentifier: value as string,
            }))
          }
          required
          value={state.groupIdentifier}
        />
      </StyledForm>
    </Modal>
  )
}

const generateIdentifier = () => `catalog_feature_${uuidv4().substring(0, 8)}`

export default CreateUpdateCatalogFeatureModal
