import React, { useEffect, useState } from 'react'

import { Menu, Segment, Transition } from 'semantic-ui-react'
import styled from 'styled-components'

import Button from 'design-system/components/button'

interface SaveModalProps<T> {
  currentState: T
  disableSave?: boolean
  hideSegments?: boolean
  initialState: T
  loadingSave?: boolean
  onDiscard: () => void
  onSave: () => void
}

function SaveModal<T>({
  currentState,
  disableSave,
  hideSegments,
  initialState,
  loadingSave,
  onDiscard,
  onSave,
}: SaveModalProps<T>) {
  const [showButtons, toggleButtons] = useState<boolean>(false)

  useEffect(() => {
    if (JSON.stringify(currentState) !== JSON.stringify(initialState))
      toggleButtons(true)
    else toggleButtons(false)
  }, [initialState, currentState])

  return (
    <>
      {hideSegments ? null : (
        <>
          <Segment basic />
          <Segment basic />
        </>
      )}
      <Transition duration={300} visible={showButtons}>
        <Menu fixed="bottom" secondary style={{ zIndex: 1 }}>
          <Menu.Item>
            <StyledSegment secondary>
              <p className="no-margin">You have unsaved changes</p>
              <span className="actions">
                <Button
                  kind="solid"
                  color="blue"
                  text="Discard"
                  onClick={onDiscard}
                />
                <Button
                  kind="solid"
                  text="Save"
                  disabled={disableSave}
                  loading={loadingSave}
                  onClick={onSave}
                />
              </span>
            </StyledSegment>
          </Menu.Item>
        </Menu>
      </Transition>
    </>
  )
}

export default SaveModal

const StyledSegment = styled(Segment)`
  &&& {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    span.actions {
      align-items: center;
      display: flex;
      gap: 12px;
    }
  }
`
