import React from 'react'

import { Modal } from 'design-system/components/modal'
import { User } from 'types/user'

import UserTab from './tab'

interface UserModalProps {
  onClose: () => void
  open: boolean
  user?: User
}

const UserModal = ({ onClose, open, user }: UserModalProps) => {
  return (
    <Modal
      title={user ? 'Update User' : 'Create User'}
      onClose={onClose}
      open={open}
      size="large"
    >
      <UserTab
        user={user}
        onComplete={() => {
          onClose()
        }}
      />
    </Modal>
  )
}

export default UserModal
