import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormCheckboxValue,
} from 'components/admin/filters-search'
import useRenderPropMany from 'context/render-prop/use-many'

export type RenderPropFilterValues = {
  groupIdentifiers: IFormCheckboxValue
}

const useRenderPropFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useRenderPropMany()

  const filters: DynamicFilterGroup<RenderPropFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'groupIdentifiers',
            type: DynamicFilterTypes.CHECKBOX,
            label: 'Group',
            options: GROUPS.map((go) => {
              return {
                key: go,
                text: go,
                value: go,
              }
            }),
          },
        ],
      },
    ]
  }, [])

  const defaultFilters = useMemo(() => {
    const renderPropFilters: Partial<RenderPropFilterValues> = {}

    if (queryFilters.groupIdentifiers?.length)
      renderPropFilters.groupIdentifiers = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.groupIdentifiers.map((s) => {
          return {
            value: s,
            text: s,
          }
        }),
      }
    return renderPropFilters
  }, [queryFilters])

  const handleApplyFilters = (data: RenderPropFilterValues) => {
    setQueryFilters({
      groupIdentifiers: data.groupIdentifiers?.selection.map(
        (f) => f.value ?? '',
      ),
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useRenderPropFiltersSearch

const GROUPS = [
  'Appliances',
  'Armchairs',
  'Banquettes',
  'Bars and restaurants',
  'Bar stools',
  'Baskets and buckets',
  'Beds',
  'Benches',
  'Bookcases',
  'Books and magazines',
  'Bouquet',
  'Carpets & Skins',
  'Ceiling lamps',
  'Chairs',
  'Chandeliers',
  'Chests of drawers',
  "Children's storage furniture",
  'Clock',
  'Coffee tables',
  'Consoles',
  'Couches',
  'Decorative set',
  'Desks',
  'Different',
  'Dining tables',
  'Divano',
  'Entrance hall',
  'Exterior elements',
  'Faucets',
  'Fireplaces, radiators',
  'Floor lamps',
  'Games and sports',
  'Holiday sets',
  'Houseplants',
  'Kitchen accessories',
  'Kitchen appliances',
  'Kitchen set',
  'Mirrors',
  'Office and creativity',
  'Office chair',
  'Ottomans & Poufs',
  'Outdoor',
  'Outdoor furniture, decor',
  'Paintings',
  'Pendants',
  'Pillows & Blankets',
  'Professional Equipment',
  'Sconces',
  'Sectionals sofas',
  'Shelves and racks',
  'Sinks & Faucets',
  'Sofas',
  'Spotlights',
  'Stools',
  'Table lamps',
  'Toys, decor',
  'Track, projector',
  'TV tables',
  'Vases',
  'Vertical garden',
  'Wall decor',
  'Wall unit',
  'Work place',
]
