import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_ISSUE_CONTEXT,
  FindUniqueIssuePayload,
  FindUniqueIssueVariables,
} from 'queries/issue'
import { Issue } from 'types/issue'

interface IIssueContext {
  issue: Issue
  refetch: () => Promise<unknown>
}

interface IssueProps
  extends RouteComponentProps<{ issue_id: string; noLoader?: boolean }> {
  children: React.ReactNode
}

export const IssueContext = createContext<IIssueContext>({} as IIssueContext)

const IssueProvider = (props: IssueProps) => {
  const { loading, data, refetch } = useQuery<
    FindUniqueIssuePayload,
    FindUniqueIssueVariables
  >(FIND_UNIQUE_ISSUE_CONTEXT, {
    variables: {
      where: { id: props.issue_id ?? '' },
    },
  })

  if (loading || !data) return props.noLoader ? null : <Loader />
  const issue = data?.issue

  return (
    <IssueContext.Provider
      value={{
        issue,
        refetch,
      }}
    >
      {props.children}
    </IssueContext.Provider>
  )
}

export default IssueProvider
