import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_COMMENT,
  CreateCommentPayload,
  CreateCommentVariables,
  DELETE_COMMENT,
  DeleteCommentPayload,
  DeleteCommentVariables,
  UPDATE_COMMENT,
  UpdateCommentPayload,
  UpdateCommentVariables,
} from 'queries/comment'

import { DesignContext } from './provider'

const useDesignCommentMutate = () => {
  const { refetch } = useContext(DesignContext)

  const [deleteCommentAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteCommentPayload,
    DeleteCommentVariables
  >(DELETE_COMMENT, {
    onCompleted: refetch,
  })

  const [updateCommentAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateCommentPayload,
    UpdateCommentVariables
  >(UPDATE_COMMENT, {
    onCompleted: refetch,
  })

  const [createCommentAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateCommentPayload,
    CreateCommentVariables
  >(CREATE_COMMENT, {
    onCompleted: refetch,
  })

  return {
    createCommentAndRefetch,
    deleteCommentAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateCommentAndRefetch,
  }
}

export default useDesignCommentMutate
