import { useMutation, useQuery } from '@apollo/client'

import { notifySuccess } from 'context/notifications/trigger'
import {
  FetchSettingsPayload,
  FetchSettingsVariables,
  FETCH_SETTINGS,
  UpsertSettingsPayload,
  UpsertSettingsVariables,
  UPSERT_SETTINGS,
} from 'queries/settings'
import { SettingType } from 'types/settings'

const interpretStatus = (value: string) => {
  const [status, time] = value.split('|')
  return {
    status,
    time,
  }
}

const useLogisticsJobSettings = () => {
  const { data, loading, refetch } = useQuery<
    FetchSettingsPayload,
    FetchSettingsVariables
  >(FETCH_SETTINGS, {
    pollInterval: 30000,
    variables: {
      where: {
        type: {
          equals: SettingType.LOGISTICS,
        },
      },
    },
  })

  const [upsertSettingsAndRefetch, { loading: loadingUpsert }] = useMutation<
    UpsertSettingsPayload,
    UpsertSettingsVariables
  >(UPSERT_SETTINGS, {
    onCompleted: () => {
      refetch()
      notifySuccess('Job Started!')
    },
  })

  const {
    logisticsPlannedAt,
    logisticsPlannedAtStatus,
    logisticsPlannedAtStatusTime,
    logisticsShipmentsSyncedAt,
    logisticsShipmentsSyncedAtStatus,
    logisticsShipmentsSyncedAtStatusTime,
    logisticsSyncedAt,
    logisticsSyncedAtStatus,
    logisticsSyncedAtStatusTime,
    logisticsEligibleOrdersSyncedAt,
    logisticsEligibleOrdersSyncedAtStatus,
    logisticsEligibleOrdersSyncedAtStatusTime,
  } = (data?.settings || []).reduce(
    (prev, currSettings) => {
      if (currSettings.key === 'logisticsPlannedAt')
        return {
          ...prev,
          logisticsPlannedAt: currSettings.value,
          logisticsPlannedAtStatus: interpretStatus(currSettings.value).status,
          logisticsPlannedAtStatusTime: interpretStatus(currSettings.value)
            .time,
        }
      if (currSettings.key === 'logisticsSyncedAt')
        return {
          ...prev,
          logisticsSyncedAt: currSettings.value,
          logisticsSyncedAtStatus: interpretStatus(currSettings.value).status,
          logisticsSyncedAtStatusTime: interpretStatus(currSettings.value).time,
        }
      if (currSettings.key === 'logisticsShipmentsSyncedAt')
        return {
          ...prev,
          logisticsShipmentsSyncedAt: currSettings.value,
          logisticsShipmentsSyncedAtStatus: interpretStatus(currSettings.value)
            .status,
          logisticsShipmentsSyncedAtStatusTime: interpretStatus(
            currSettings.value,
          ).time,
        }
      if (currSettings.key === 'logisticsEligibleOrdersSyncedAt')
        return {
          ...prev,
          logisticsEligibleOrdersSyncedAt: currSettings.value,
          logisticsEligibleOrdersSyncedAtStatus: interpretStatus(
            currSettings.value,
          ).status,
          logisticsEligibleOrdersSyncedAtStatusTime: interpretStatus(
            currSettings.value,
          ).time,
        }
      return prev
    },
    {
      logisticsPlannedAt: '',
      logisticsPlannedAtStatus: '',
      logisticsPlannedAtStatusTime: '',
      logisticsShipmentsSyncedAt: '',
      logisticsShipmentsSyncedAtStatus: '',
      logisticsShipmentsSyncedAtStatusTime: '',
      logisticsSyncedAt: '',
      logisticsSyncedAtStatus: '',
      logisticsSyncedAtStatusTime: '',
      logisticsEligibleOrdersSyncedAt: '',
      logisticsEligibleOrdersSyncedAtStatus: '',
      logisticsEligibleOrdersSyncedAtStatusTime: '',
    },
  )

  return {
    loading,
    loadingUpsert,
    logisticsEligibleOrdersSyncedAt,
    logisticsEligibleOrdersSyncedAtStatus,
    logisticsEligibleOrdersSyncedAtStatusTime,
    logisticsPlannedAt,
    logisticsPlannedAtStatus,
    logisticsPlannedAtStatusTime,
    logisticsShipmentsSyncedAt,
    logisticsShipmentsSyncedAtStatus,
    logisticsShipmentsSyncedAtStatusTime,
    logisticsSyncedAt,
    logisticsSyncedAtStatus,
    logisticsSyncedAtStatusTime,
    upsertSettingsAndRefetch,
  }
}

export default useLogisticsJobSettings
