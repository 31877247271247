import React, { useState, useEffect, useRef } from 'react'

import { Dropdown, Label, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import RouteModal from 'components/admin/route/create-update-modal'
import ShipmentModal from 'components/admin/shipment/create-update-modal'
import { isAddOnDesign } from 'context/design/utils'
import useRouteManyNoContext from 'context/route/use-many-no-context'
import useShipmentManyNoContext from 'context/shipment/use-many-no-context'
import useSupplierOrderMany from 'context/supplier-order/use-many'
import useSupplierOrderMutateNoContext from 'context/supplier-order/use-mutate-no-context'
import * as supplierOrderUtils from 'context/supplier-order/utils'
import { FIND_MANY_SHIPMENT } from 'queries/shipment'
import { StyledIcon } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { SupplierOrder } from 'types/supplier-order'

import SupplierOrderModal from './create-update-modal'
import { SupplierOrderCellContentsLess } from './table-cell-content'

const SuppierOrdersPlanningTable = ({
  onSupplierOrderHighlighted,
  supplierOrderHighlighted,
  supplierOrderSelected,
}: {
  onSupplierOrderHighlighted: (so: SupplierOrder | null) => void
  supplierOrderHighlighted?: SupplierOrder
  supplierOrderSelected?: SupplierOrder
}) => {
  const { updateSupplierOrder } = useSupplierOrderMutateNoContext()
  const { supplierOrders, refetch, queryFilters, setQueryFilters } =
    useSupplierOrderMany()
  const { sortBy, sortDirection } = queryFilters

  const [editRouteId, setEditRouteId] = useState('')
  const [editShipmentId, setEditShipmentId] = useState('')
  const [editSupplierOrderId, setEditSupplierOrderId] = useState('')
  const [supplierOrderInitial, setSupplierOrderInitial] =
    useState<SupplierOrder | null>(null)
  const rowsRef = useRef<HTMLElement[]>([])

  useEffect(() => {
    rowsRef.current = rowsRef.current.slice(0, supplierOrders.length)
  }, [supplierOrders])

  useEffect(() => {
    if (supplierOrderSelected) {
      const selectedIndex = supplierOrders.findIndex(
        (so) => so.id === supplierOrderSelected.id,
      )
      const selectedRow = rowsRef.current[selectedIndex]
      selectedRow?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [supplierOrderSelected])

  const { routes, refetch: refetchRoutes } = useRouteManyNoContext({
    variables: {
      where: {
        confirmedAt: null,
      },
    },
  })

  const { shipments, refetch: refetchShipments } = useShipmentManyNoContext({
    query: FIND_MANY_SHIPMENT,
    variables: {
      where: {
        confirmedAt: null,
      },
    },
  })

  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <Table className="small" celled sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Project</Table.HeaderCell>
            <Table.HeaderCell>Volume</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'derivedStatus' ? sortDirection : undefined}
              onClick={() =>
                setQueryFilters({
                  ...queryFilters,
                  sortBy: 'derivedStatus',
                  sortDirection:
                    sortBy === 'derivedStatus' && sortDirection === 'descending'
                      ? 'ascending'
                      : 'descending',
                })
              }
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                sortBy === 'derivedStatusPlanning' ? sortDirection : undefined
              }
              onClick={() =>
                setQueryFilters({
                  ...queryFilters,
                  sortBy: 'derivedStatusPlanning',
                  sortDirection:
                    sortBy === 'derivedStatusPlanning' &&
                    sortDirection === 'descending'
                      ? 'ascending'
                      : 'descending',
                })
              }
            >
              Shipment / Route
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {supplierOrders.length > 0 &&
            supplierOrders.map((so, i) => {
              const isRemedial = supplierOrderUtils.isRemedialSupplierOrder(so)
              const isAddOn = isAddOnDesign(so.design)
              const soStatusSummary =
                supplierOrderUtils.getSupplierOrderStatusSummary(so)
              const [lat, lng, valid] =
                supplierOrderUtils.getSupplierOrderLatLng(so)
              const proposedShipment = shipments.find(
                (s) => s.id === so.shipment?.id,
              )
              const proposedShipmentVolume = proposedShipment
                ? `${supplierOrderUtils.sumSupplierOrderVolumes(
                    proposedShipment.supplierOrders,
                  )} m3`
                : ''
              const proposedRoute = routes.find((r) => r.id === so.route?.id)
              const proposedRouteVolume = proposedRoute
                ? `${supplierOrderUtils.sumSupplierOrderVolumes(
                    proposedRoute.supplierOrders,
                  )} m3`
                : ''

              return (
                <Table.Row
                  key={i}
                  warning={valid && (!lat || !lng)}
                  error={!valid}
                  onMouseEnter={() => onSupplierOrderHighlighted(so)}
                  onMouseLeave={() => onSupplierOrderHighlighted(null)}
                  positive={so.id === supplierOrderHighlighted?.id}
                >
                  <Table.Cell width={5}>
                    <div
                      ref={(el: HTMLDivElement) => (rowsRef.current[i] = el)}
                    >
                      {isRemedial ? (
                        <Label color="red" content="Remedial" ribbon />
                      ) : null}
                      {isAddOn ? (
                        <Label color="yellow" content="Add-On" ribbon />
                      ) : null}
                      <SupplierOrderCellContentsLess
                        supplierOrder={so}
                        includeAddress
                      />
                    </div>
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {so.data?.nobiliaVolumeCubicMeters ||
                      so.data?.volumeCubicMeters ||
                      '?'}{' '}
                    m3
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <LinkDiv
                      onClick={() => setEditSupplierOrderId(so.id as string)}
                    >
                      View
                    </LinkDiv>
                    <div>{soStatusSummary.currentStage}</div>
                    <div style={{ marginBottom: '5px' }}>
                      {soStatusSummary.currentStageDate}
                      <StyledIcon
                        style={{
                          marginLeft: '5px',
                          height: '10px',
                          width: '10px',
                        }}
                        icon={[
                          'fal',
                          soStatusSummary.currentStageConfirmedAt
                            ? 'check'
                            : 'question',
                        ]}
                        styled={{
                          inactive: !soStatusSummary.currentStageConfirmedAt,
                        }}
                      />
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="left" width={5}>
                    <div>
                      <StyledLabel background={so.shipment?.color} />
                      <Dropdown
                        style={{ fontSize: '14px' }}
                        text={
                          so.shipment == null
                            ? 'No Asgd'
                            : so.shipment.reference == ''
                            ? 'No Ref'
                            : so.shipment.reference
                        }
                        value={so.shipment?.id}
                      >
                        <Dropdown.Menu>
                          {[
                            <Dropdown.Item
                              key={'null'}
                              label={<StyledLabel />}
                              onClick={async () => {
                                await updateSupplierOrder({
                                  variables: {
                                    data: {
                                      shipment: { disconnect: true },
                                    },
                                    where: {
                                      id: so.id as string,
                                    },
                                  },
                                })
                                refetchShipments()
                              }}
                              text="No Asgd"
                            />,
                            ...shipments.map((s) => (
                              <Dropdown.Item
                                key={s.id}
                                label={<StyledLabel background={s.color} />}
                                onClick={async () => {
                                  await updateSupplierOrder({
                                    variables: {
                                      data: {
                                        shipment: {
                                          connect: {
                                            id: s.id as string,
                                          },
                                        },
                                      },
                                      where: {
                                        id: so.id as string,
                                      },
                                    },
                                  })
                                  refetchShipments()
                                }}
                                text={s.reference || 'No Ref'}
                              />
                            )),
                          ]}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <LinkDiv
                      onClick={() => {
                        setEditShipmentId(so.shipment?.id || 'new')
                        if (!so.shipment?.id) setSupplierOrderInitial(so)
                      }}
                    >
                      {so.shipment
                        ? `${proposedShipmentVolume || 'Edit'}`
                        : 'Create new'}
                    </LinkDiv>
                    <div>
                      <StyledLabel background={so.route?.color} />
                      <Dropdown
                        style={{ fontSize: '14px' }}
                        text={
                          so.route == null
                            ? 'No Asgd'
                            : so.route.reference == ''
                            ? 'No Ref'
                            : so.route.reference
                        }
                        value={so.route?.id}
                      >
                        <Dropdown.Menu>
                          {[
                            <Dropdown.Item
                              key={'null'}
                              label={<StyledLabel />}
                              onClick={async () => {
                                await updateSupplierOrder({
                                  variables: {
                                    data: {
                                      route: { disconnect: true },
                                    },
                                    where: {
                                      id: so.id as string,
                                    },
                                  },
                                })
                                refetchRoutes()
                              }}
                              text={'No Asgd'}
                            />,
                            ...routes.map((r) => (
                              <Dropdown.Item
                                key={r.id}
                                label={<StyledLabel background={r.color} />}
                                onClick={async () => {
                                  await updateSupplierOrder({
                                    variables: {
                                      data: {
                                        route: {
                                          connect: {
                                            id: r.id as string,
                                          },
                                        },
                                      },
                                      where: {
                                        id: so.id as string,
                                      },
                                    },
                                  })
                                  refetchRoutes()
                                }}
                                text={r.reference || 'No Ref'}
                              />
                            )),
                          ]}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <LinkDiv
                      onClick={() => {
                        setEditRouteId(so.route?.id || 'new')
                        if (!so.route?.id) setSupplierOrderInitial(so)
                      }}
                    >
                      {so.route
                        ? `${proposedRouteVolume || 'Edit'}`
                        : 'Create new'}
                    </LinkDiv>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          <RouteModal
            open={!!editRouteId}
            onClose={() => setEditRouteId('')}
            refetch={async () => {
              refetch()
              refetchRoutes()
            }}
            routeId={editRouteId}
            supplierOrdersInitial={
              supplierOrderInitial ? [supplierOrderInitial] : undefined
            }
          />
          <ShipmentModal
            open={!!editShipmentId}
            onClose={() => setEditShipmentId('')}
            refetch={async () => {
              refetch()
              refetchShipments()
            }}
            shipmentId={editShipmentId}
            supplierOrdersInitial={
              supplierOrderInitial ? [supplierOrderInitial] : undefined
            }
          />
          <SupplierOrderModal
            open={!!editSupplierOrderId}
            onClose={() => setEditSupplierOrderId('')}
            refetch={refetch}
            supplierOrderId={editSupplierOrderId}
          />
        </Table.Body>
      </Table>
    </div>
  )
}

export default SuppierOrdersPlanningTable

const StyledLabel = styled(Label)<{
  background?: string
}>`
  &&& {
    background: ${(props) => props.background || '#BEBEBE'} !important;
    margin-right: 5px !important;
    border-radius: 100% !important;
    padding: 7px !important;
    height: 5px;
    width: 5px;
  }
`

const LinkDiv = styled.div`
  color: ${Colors.gray500};
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
`
