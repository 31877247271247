import { useQuery } from '@apollo/client'

import {
  FIND_MANY_DESIGN_COUNT,
  FindManyDesignCountPayload,
  FindManyDesignVariables,
} from 'queries/design'

const useGalleryCount = () => {
  const { data: { count: designsGalleryCount } = { count: 0 }, loading } =
    useQuery<FindManyDesignCountPayload, FindManyDesignVariables>(
      FIND_MANY_DESIGN_COUNT,
      {
        variables: {
          where: {
            metadataGallery: {
              path: ['name'],
              string_starts_with: '#',
            },
          },
        },
      },
    )

  return {
    loading,
    // Add 42 because our gallery starts at #42
    designsGalleryCount: designsGalleryCount + 42,
  }
}

export default useGalleryCount
