import React, { useCallback, useState } from 'react'

import { debounce } from 'lodash'
import { Input, InputProps } from 'semantic-ui-react'

interface SearchProps extends Omit<InputProps, 'onChange'> {
  value?: string | undefined
  onChange?: (value: string) => void
}

const SearchField = ({ onChange, value, ...props }: SearchProps) => {
  const [search, setSearch] = useState(value ?? '')

  const onSearchChange = (newVal: string) => onChange?.(newVal)
  const debouncedOnSearchChange = useCallback(
    debounce(onSearchChange, 1000),
    [],
  )

  return (
    <Input
      fluid
      icon="search"
      placeholder="Search"
      onChange={(e) => {
        setSearch(e.target.value)
        debouncedOnSearchChange(e.target.value)
      }}
      value={search}
      {...props}
    />
  )
}

export default SearchField
