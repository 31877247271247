import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import GenericProductCreateUpdateModal from 'components/admin/catalog/appliance-pack/generic-product/create-update-modal'
import GenericProductsTable from 'components/admin/catalog/appliance-pack/generic-product/table'
import useAppliancePack from 'context/appliance-pack/use'
import useAuth from 'context/auth/use'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import Button from 'design-system/components/button'
import { StyledBreadcrumb } from 'styles/planner/main'

const Module = (
  _: RouteComponentProps<{
    catalog_id: string
    appliance_pack_id: string
  }>,
) => {
  const { isAdmin } = useAuth()
  const { catalog } = useNobiliaOptions()
  const { appliancePack } = useAppliancePack()
  const [enableApplianceCreate, setEnableApplianceCreate] = useState(false)

  const sections = [
    {
      key: 'Catalog',
      content: `Catalog`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Appliance Packs',
      content: 'Appliance Packs',
      href: `/admin/catalog/${catalog}/appliance-packs`,
    },
    {
      key: 'Appliance Pack',
      content: appliancePack?.data?.name,
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{appliancePack?.data?.name}</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: '0' }} columns="equal">
        <Grid.Column>
          <p className="gray small">
            {`Showing ${appliancePack?.appliances?.length} of ${appliancePack?.appliances?.length}`}{' '}
            Appliance Packs
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            color="gray"
            disabled={!isAdmin}
            kind="outlined"
            onClick={() => setEnableApplianceCreate(true)}
            text="Add Appliance"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <GenericProductsTable />
        </Grid.Column>
      </Grid.Row>
      <GenericProductCreateUpdateModal
        open={enableApplianceCreate}
        onClose={() => setEnableApplianceCreate(false)}
      />
    </Grid>
  )
}

export default Module
