import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useAuth from 'context/auth/use'
import useCommentNoteCount from 'context/project/project-notes/use-count'
import { ScrollBarStyle } from 'styles/app/system'

const MenuSelectionCenterManager = () => {
  const { pathname } = useLocation()
  const { formUserId } = useAuth()

  const { count: newForNotesCount } = useCommentNoteCount({
    newFor: { id: formUserId },
  })

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="notes"
        active={
          pathname ===
          `/admin/dashboard/${formUserId}/selection-center-manager/`
        }
        onClick={() =>
          navigate(`/admin/dashboard/${formUserId}/selection-center-manager`)
        }
      >
        Notes
        {!!newForNotesCount && (
          <Label className="count">{newForNotesCount}</Label>
        )}
      </Menu.Item>

      <Menu.Item
        name="tasks"
        active={pathname.startsWith(
          `/admin/dashboard/${formUserId}/selection-center-manager/links`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${formUserId}/selection-center-manager/links`,
          )
        }
      >
        Links
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuSelectionCenterManager

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
