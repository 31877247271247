import { useContext } from 'react'

import { GenericProductClassContext } from './provider'

const useGenericProductClass = () => {
  const { catalog, genericProductClass, refetch } = useContext(
    GenericProductClassContext,
  )

  return {
    catalog,
    genericProductClass,
    refetch,
  }
}

export default useGenericProductClass
