import { useMutation } from '@apollo/client'

import {
  CREATE_ISSUE,
  CreateIssuePayload,
  CreateIssueVariables,
  UPDATE_ISSUE,
  UpdateIssuePayload,
  UpdateIssueVariables,
} from 'queries/issue'

const useIssueMutate = () => {
  const [createIssue, { loading: loadingCreate }] = useMutation<
    CreateIssuePayload,
    CreateIssueVariables
  >(CREATE_ISSUE)

  const [updateIssue, { loading: loadingUpdate }] = useMutation<
    UpdateIssuePayload,
    UpdateIssueVariables
  >(UPDATE_ISSUE)

  return {
    createIssue,
    loadingCreate,
    loadingUpdate,
    updateIssue,
  }
}

export default useIssueMutate
