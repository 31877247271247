import React, { useState } from 'react'

import { Visibility } from 'semantic-ui-react'
import styled from 'styled-components'

import { useConversationMany } from 'context/project/conversation/provider-many'
import { Conversation } from 'types/conversation'

import { ConversationMessagesModal } from './messages-modal'
import { ConversationsTable } from './table'
import { useConversationFilters } from './use-conversation-filters'
import { ConversationUserModal } from './user-modal'
import FiltersAndSearch from '../filters-search/filters-search'

const ConversationsFeed = () => {
  const {
    filters,
    defaultFilters,
    handleApplyFilters,
    handleApplySearch,
    searchValue,
  } = useConversationFilters()
  const { fetchMore, fetchedCount, count, loading, loadingMore, refetch } =
    useConversationMany()

  const [conversationViewMessages, setConversationViewMessages] = useState('')
  const [openUserModal, setOpenUserModal] = useState(false)
  const [conversationUserModal, setConversationUserModal] =
    useState<Conversation | null>(null)

  return (
    <StyledContainer>
      <ConversationMessagesModal
        conversationId={conversationViewMessages}
        handleClose={() => setConversationViewMessages('')}
      />
      <ConversationUserModal
        conversation={conversationUserModal}
        handleClose={() => setOpenUserModal(false)}
        handleExited={() => setConversationUserModal(null)}
        openUserModal={openUserModal}
      />

      <FiltersAndSearch
        filters={filters}
        defaultAppliedFilters={defaultFilters}
        onApply={handleApplyFilters}
        handleSearchChange={handleApplySearch}
        searchValue={searchValue}
        handleRefetch={refetch}
      />
      <p className="gray small no-margin">
        {loading ? `???` : `Showing ${fetchedCount} of ${count}`} Conversations
      </p>
      <Visibility
        onUpdate={(_, { calculations }) => {
          calculations.bottomVisible &&
            fetchedCount < count &&
            !loadingMore &&
            !loading &&
            fetchMore()
        }}
      >
        <ConversationsTable
          onViewMessages={setConversationViewMessages}
          onSetConversation={(data) => {
            setConversationUserModal(data)
            setOpenUserModal(true)
          }}
        />
      </Visibility>
    </StyledContainer>
  )
}

export default ConversationsFeed

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
