import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  DELETE_DELIVERY,
  DeleteDeliveryPayload,
  DeleteDeliveryVariables,
  UPDATE_DELIVERY,
  UpdateDeliveryPayload,
  UpdateDeliveryVariables,
} from 'queries/delivery'

import { ProjectDeliveryManyContext } from './provider-many'

const useProjectDeliveryManyMutate = () => {
  const { refetch } = useContext(ProjectDeliveryManyContext)
  const [updateDeliveryAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateDeliveryPayload,
    UpdateDeliveryVariables
  >(UPDATE_DELIVERY, {
    onCompleted: refetch,
  })

  const [deleteDeliveryAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteDeliveryPayload,
    DeleteDeliveryVariables
  >(DELETE_DELIVERY, {
    onCompleted: refetch,
  })

  return {
    deleteDeliveryAndRefetch,
    loadingDelete,
    loadingUpdate,
    updateDeliveryAndRefetch,
  }
}

export default useProjectDeliveryManyMutate
