import React, { useEffect, useState } from 'react'

import CreateUpdateIssueForm from 'components/admin/project/issue/form'
import useDelivery from 'context/delivery/use'
import useDeliveryIssueMutate from 'context/delivery/use-issue-mutate'
import useProject from 'context/project/use'
import { Modal } from 'design-system/components/modal'
import { Issue, IssueData, IssueType, ResolutionType } from 'types/issue'
import { mapOrEmptyArray } from 'views/utils'

const CreateUpdateIssueModal = ({
  open,
  onClose,
  issue,
}: {
  open: boolean
  onClose: () => void
  issue?: Issue
}) => {
  const { project, refetch: refetchProject } = useProject()
  const { damagePhotos, delivery, refetch } = useDelivery()
  const {
    createIssueAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateIssueAndRefetch,
  } = useDeliveryIssueMutate()
  const initialState = {
    type: issue?.type || null,
    resolutionType: issue?.resolutionType || ResolutionType.PENDING,
    data: issue?.data || {
      orderNumber: '',
      positionNumber: '',
    },
    delivery: delivery || null,
    files: issue?.files || [],
  }
  const [state, setState] = useState<NullableRecord<Issue>>(initialState)

  useEffect(() => {
    setState(initialState)
  }, [issue, open])

  const onSave = async () => {
    if (issue) {
      await updateIssueAndRefetch({
        variables: {
          data: {
            data: state.data as IssueData,
            type: state.type as IssueType,
            resolutionType: state.resolutionType as ResolutionType,
            files: {
              set: state.files?.map((d) => ({ id: d.id })) ?? [],
            },
          },
          where: {
            id: issue.id ?? '',
          },
        },
      })
    } else
      await createIssueAndRefetch({
        variables: {
          data: {
            data: state.data ?? {},
            type: state.type ?? undefined,
            resolutionType: state.resolutionType ?? undefined,
            delivery: {
              connect: {
                id: state?.delivery?.id ?? '',
              },
            },
            files: {
              connect:
                mapOrEmptyArray(state.files, (d) => ({
                  id: d.id,
                })) ?? [],
            },
            project: {
              connect: {
                id: project.id,
              },
            },
          },
        },
      })
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="large"
      title={issue ? 'Update Issue' : 'Create Issue'}
      saveButton={{
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
      onExited={() => {
        refetch()
        onClose()
      }}
    >
      <CreateUpdateIssueForm
        damagePhotos={damagePhotos}
        grayInputs
        issue={state as Issue}
        onChange={setState}
        refetch={async () => {
          await refetchProject()
          await refetch()
        }}
      />
    </Modal>
  )
}

export default CreateUpdateIssueModal
