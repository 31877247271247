import React, { useState } from 'react'

import { isEqual } from 'lodash'
import { Card, Divider, Form } from 'semantic-ui-react'

import TradeSettingsModal from 'components/admin/project/trade-project-modal'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import Button from 'design-system/components/button'
import StatusLabel from 'design-system/components/status-label'
import { StyledForm } from 'styles/admin/main'
import { ProjectType } from 'types/project'
import { StatusMode } from 'types/utils'
import * as viewUtils from 'views/utils'

const ProjectTypeCard = () => {
  const { project, isTradeProject } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()
  const initialState = {
    projectLabel: project.data?.projectLabel || '',
    projectType: project.data?.projectType,
  }
  const [state, setState] = useState(initialState)
  const [showTradeSettings, toggleTradeSettings] = useState(false)

  const { noBlockOrderSamples, skipDesignDeposit, skipProjectConsultation } =
    project.data

  return (
    <Card fluid>
      <Card.Content>
        <h6>Project Type</h6>
        {isTradeProject && (
          <>
            <div
              className="flex"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <p className="no-margin">Current settings</p>
              <Button
                kind="outlined"
                color="gray"
                fontAwesomeIcon="edit"
                text="Edit"
                onClick={() => toggleTradeSettings(true)}
              />
              <TradeSettingsModal
                onClose={() => toggleTradeSettings(false)}
                open={showTradeSettings}
                project={project}
                onSave={async (projectData) => {
                  await updateProjectAndRefetch({
                    variables: {
                      data: {
                        data: projectData,
                      },
                      where: {
                        id: project.id,
                      },
                    },
                  })
                  toggleTradeSettings(false)
                }}
              />
            </div>
            <div className="flex" style={{ gap: '16px', marginTop: '16px' }}>
              <StatusLabel
                type={
                  skipProjectConsultation
                    ? StatusMode.Confirmed
                    : StatusMode.Submitted
                }
                text="Skip Project Consultation"
                icon={skipProjectConsultation ? 'check' : 'times'}
              />
              <StatusLabel
                type={
                  noBlockOrderSamples
                    ? StatusMode.Confirmed
                    : StatusMode.Submitted
                }
                text="Don’t block samples on Design Deposit"
                icon={noBlockOrderSamples ? 'check' : 'times'}
              />
              <StatusLabel
                type={
                  skipDesignDeposit
                    ? StatusMode.Confirmed
                    : StatusMode.Submitted
                }
                text="Skip Design Deposit - Straight to Trade Deposit"
                icon={skipDesignDeposit ? 'check' : 'times'}
              />
            </div>
            <Divider />
            <Divider hidden />
          </>
        )}
        <StyledForm>
          <Form.Dropdown
            fluid
            onChange={(_, { value }) =>
              setState({
                ...state,
                projectType: value as ProjectType,
              })
            }
            options={Object.values(ProjectType).map((v) => ({
              text: viewUtils.capsSnakeCaseToTitleCase(v),
              value: v as ProjectType,
            }))}
            placeholder="Select a project type"
            selection
            value={state.projectType}
          />
          <Form.Input
            disabled={state.projectType !== ProjectType.TRADE}
            fluid
            label="Project Label (Trade Only)"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              setState({
                ...state,
                projectLabel: value,
              })
            }}
            value={state.projectLabel}
          />
          <Button
            kind="solid"
            text="Save"
            disabled={isEqual(state, initialState)}
            onClick={async () =>
              await updateProjectAndRefetch({
                variables: {
                  data: {
                    data: {
                      projectLabel: state.projectLabel,
                      projectType: state.projectType,
                    },
                  },
                  where: {
                    id: project.id,
                  },
                },
              })
            }
            style={{ float: 'right' }}
          />
        </StyledForm>
      </Card.Content>
    </Card>
  )
}

export default ProjectTypeCard
