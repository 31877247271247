import constructOrderChecklistsAndAlerts from 'context/order/overview'
import { getOrderNameWithDescription } from 'context/order/utils'
import useProject from 'context/project/use'
import useConstructDesignCallChecklistsAndAlerts from 'context/room/overview-design-call'
import {
  constructRoomInDesignAlerts,
  constructRoomInDesignChecklist,
} from 'context/room/overview-in-design'
import useConstructInitialDesignChecklistsAndAlerts from 'context/room/overview-initial-design'
import useConstructStartTradeProjectChecklistsAndAlerts from 'context/room/overview-start-trade-project'
import { parseDesigns } from 'context/room/utils'
import {
  GuideType,
  OverviewAlert,
  OverviewChecklist,
  OverviewStage,
} from 'types/utils'

const useOverview = (): OverviewStage[] => {
  const {
    isTradeProject,
    ordersCabinetryPublished,
    ordersIssuesPlaced,
    paymentProjectDepositPaid,
    paymentDesignDepositPaid,
    project,
    roomPrimary,
    roomsInDesign,
    roomsInOrder,
    orderSamplesPlaced,
  } = useProject()

  const designCallChecklistAndAlerts =
    useConstructDesignCallChecklistsAndAlerts()

  const initialDesignChecklistAndAlerts =
    useConstructInitialDesignChecklistsAndAlerts()

  const startTradeProjectChecklistAndAlerts =
    useConstructStartTradeProjectChecklistsAndAlerts()

  if (!paymentProjectDepositPaid && !ordersCabinetryPublished?.length) {
    // trade pre-PD / order
    if (isTradeProject) {
      return [
        {
          name: 'Initial Design',
          rooms: [roomPrimary],
          guides: [
            {
              type: GuideType.ProjectConsultation,
            },
            {
              type: GuideType.ProjectProposal,
            },
          ],
          ...startTradeProjectChecklistAndAlerts,
        },
      ]
    }

    // homeowner pre-DD, samples, design brief
    if (
      !paymentDesignDepositPaid ||
      !roomPrimary?.data?.designBriefApprovedAt ||
      !orderSamplesPlaced
    ) {
      const { initialDesign } = parseDesigns(roomPrimary)
      return [
        {
          name: 'Initial Call',
          rooms: [roomPrimary],
          guides: [
            {
              type: GuideType.DesignCall,
            },
            ...(initialDesign
              ? [
                  {
                    type: GuideType.DesignPresentation,
                  },
                ]
              : []),
          ],
          ...designCallChecklistAndAlerts,
        },
      ]
    }

    // homeowner pre-PD
    const { initialDesign } = parseDesigns(roomPrimary)
    return [
      {
        name: 'Initial Design',
        rooms: [roomPrimary],
        guides: [
          {
            type: GuideType.DesignCall,
          },
          ...(initialDesign
            ? [
                {
                  type: GuideType.DesignPresentation,
                },
              ]
            : []),
        ],
        ...initialDesignChecklistAndAlerts,
      },
    ]
  }

  return [
    ...(roomsInDesign.length
      ? [
          {
            name: 'Refine',
            rooms: roomsInDesign,
            guides: [
              {
                type: GuideType.DesignReview,
              },
              {
                type: GuideType.ExtraRoom,
              },
            ],
            ...{
              checklists: roomsInDesign.map((r) => {
                return constructRoomInDesignChecklist(r, {
                  isPrimary: r.id === roomPrimary.id,
                  project,
                })
              }),
              alerts: roomsInDesign
                .map((r) => {
                  return constructRoomInDesignAlerts(r)
                })
                .flat(),
            },
          },
        ]
      : []),
    ...ordersCabinetryPublished.map((o) => {
      return {
        name: `Deliver ${getOrderNameWithDescription(o)}`,
        rooms: roomsInOrder.filter((r) =>
          r.designs.some((d) => d.order?.id === o.id),
        ),
        guides: [
          {
            type: GuideType.HandoverCall,
            orderId: o.id,
          },
        ],
        ...constructOrderChecklistsAndAlerts(o, {
          rooms: roomsInOrder.filter((r) =>
            r.designs.some((d) => d.order?.id === o.id),
          ),
          project,
        }),
      }
    }),
    ...(ordersIssuesPlaced.length
      ? [
          {
            name: 'Add-Ons & Remedials',
            rooms: [],
            guides: [],
            ...ordersIssuesPlaced.reduce<{
              checklists: OverviewChecklist[]
              alerts: OverviewAlert[]
            }>(
              (acc, o) => {
                const checklistsAndAlerts = constructOrderChecklistsAndAlerts(
                  o,
                  {
                    rooms: roomsInOrder.filter((r) =>
                      r.designs.some((d) => d.order?.id === o.id),
                    ),
                    project,
                  },
                  true,
                )

                acc.checklists.push(...checklistsAndAlerts.checklists)
                acc.alerts.push(...checklistsAndAlerts.alerts)

                return acc
              },
              { checklists: [], alerts: [] },
            ),
          },
        ]
      : []),
  ]
}

export default useOverview
