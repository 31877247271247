import React, { useState } from 'react'

import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import { Divider, Form, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import AddressFormat from 'components/shared/address-format'
import { UnderlineSubtitle } from 'components/shared/utils'
import { parseSupplierOrders } from 'context/delivery/utils'
import { parseIssuesByResolution } from 'context/issue/utils-many'
import { notifyAppError } from 'context/notifications/trigger'
import { parseOwners } from 'context/project/utils'
import useSupplierOrderMutateNoContext from 'context/supplier-order/use-mutate-no-context'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { Delivery } from 'types/delivery'
import { Issue } from 'types/issue'
import { SupplierOrderActionType } from 'types/supplier-order-action'
import { getNameForUser } from 'views/utils'

import IssuesTable from '../project/issue/table'

interface CreateRemedialOrderModalProps {
  delivery: Delivery
  issueSelected: Issue
  open: boolean
  onClose: () => void
  refetch: () => Promise<unknown>
}

const CreateRemedialOrderModal = ({
  delivery,
  issueSelected,
  onClose,
  open,
  refetch,
}: CreateRemedialOrderModalProps) => {
  const { project } = issueSelected
  const { remedialIssues } = parseIssuesByResolution(
    delivery.issues.filter((i) => !i.supplierOrderResolving),
  )
  const { supplierOrderAckNumbers: orderNumbers } =
    parseSupplierOrders(delivery)

  const { createSupplierOrder, loadingCreate } =
    useSupplierOrderMutateNoContext()

  const [name, setName] = useState<string>('')
  const [note, setNote] = useState<string>('')
  const [selected, setSelected] = useState<Issue[]>([issueSelected])
  const [orderNumber, setOrderNumber] = useState<string>(
    issueSelected.data?.orderNumber || orderNumbers?.[0],
  )

  const { primaryOwner, primaryOwnersNames } = parseOwners(project) || {}

  const handleClose = () => {
    setNote('')
    setSelected([])
    onClose()
  }

  if (!delivery) return null

  const onSave = async () => {
    const supplierOrder = delivery.supplierOrders.find(
      (so) => so.nobiliaAckNumber === orderNumber,
    )
    if (!supplierOrder?.design?.room) {
      notifyAppError(
        `The supplier order on this delivery (${supplierOrder?.nobiliaOrderRef}) is not properly connected, probably missing a design. Please fix it to proceed`,
      )
      return
    }
    await createSupplierOrder({
      variables: {
        data: {
          actions: {
            create: [
              {
                data: {
                  doneAt: moment().format(),
                  type: SupplierOrderActionType.FORMOrderSent,
                },
              },
            ],
          },
          issuesResolved: {
            connect: selected.map((s) => ({ id: s.id as string })),
          },
          data: {
            claimAgainstNote: note,
            isRemedial: true,
            nobiliaAccessoriesDiscount: 19.8,
            nobiliaFurnitureDiscount: 51.3,
          },
          nobiliaOrderRef: `${getNameForUser(primaryOwner)} ${name}`,
          project: {
            connect: { id: project?.id ?? '' },
          },
          design: {
            connect: { id: supplierOrder.design.id },
          },
        },
      },
    })

    refetch()
    handleClose()
  }

  return (
    <Modal
      onClose={handleClose}
      open={open}
      size="fullscreen"
      title="Create Remedial Order"
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Button
                kind="solid"
                text="Cancel"
                color="blue"
                onClick={handleClose}
                style={{ marginRight: '20px' }}
              />
              <Button
                kind="solid"
                text="Send to Nobilia and Create Remedial"
                disabled={!name || !selected?.length}
                loading={loadingCreate}
                onClick={onSave}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h5>Remedial Order</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <p className="caption no-margin">Delivery</p>
            <p>{delivery.data?.name}</p>
          </Grid.Column>
          <Grid.Column>
            <p className="caption no-margin">Customers</p>
            <p>{primaryOwnersNames}</p>
          </Grid.Column>
          <Grid.Column>
            <p className="caption no-margin">Project Address:</p>
            <p>
              <AddressFormat address={project?.projectAddress} />
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
      <UnderlineSubtitle>Supplier Order</UnderlineSubtitle>
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Group widths="equal">
          <Form.Input
            required
            label="Name"
            name="name"
            onChange={(
              _: React.SyntheticEvent<HTMLElement, Event>,
              { value }: { value: string },
            ) => {
              setName(value)
            }}
            placeholder="Name for Remedial Order"
            value={name}
            width={7}
          />
          <StyledFormField
            control={TextareaAutosize}
            label="Add a message or notes for Nobilia regarding these issues:"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNote(e.target.value)
            }
            value={note}
          />
        </Form.Group>
      </StyledForm>
      <Divider hidden />
      <UnderlineSubtitle>Choose Issues Resolved</UnderlineSubtitle>
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Dropdown
          label="Choose Order Number"
          onChange={(_, { value }) => {
            setOrderNumber(value as string)
            setSelected([])
          }}
          options={orderNumbers?.map((ack, idx) => ({
            id: idx,
            text: ack,
            value: ack,
          }))}
          required
          selection
          value={orderNumber}
          width={4}
        />
      </StyledForm>
      <IssuesTable
        issues={remedialIssues.filter(
          (i) => i.data?.orderNumber === orderNumber,
        )}
        mode="enableSelect"
        selected={selected}
        onSelectToggle={(i: Issue) => {
          setSelected((prev) => {
            return prev.find((d) => d.id === i.id)
              ? prev.filter((d) => d.id !== i.id)
              : [...prev, i]
          })
        }}
      />
    </Modal>
  )
}

export default CreateRemedialOrderModal

const StyledFormField = styled(Form.Field)`
  textarea {
    min-height: 50px !important;
  }
`
