import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  IFormCheckboxValue,
} from 'components/admin/filters-search'
import useAppliancePackMany from 'context/appliance-pack/use-many'

export type AppliancePackFilterValues = {
  groupIdentifiers: IFormCheckboxValue
}

const useAppliancePackFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useAppliancePackMany()

  const filters: DynamicFilterGroup<AppliancePackFilterValues>[] =
    useMemo(() => {
      return []
    }, [])

  const defaultFilters = useMemo(() => {
    const appliancePackFilters: Partial<AppliancePackFilterValues> = {}
    return appliancePackFilters
  }, [queryFilters])

  const handleApplyFilters = (_: AppliancePackFilterValues) => {
    setQueryFilters({
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters(
      (currQueryFilters) =>
        ({
          ...currQueryFilters,
          search: search,
        }) as typeof queryFilters,
    )
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useAppliancePackFiltersSearch
