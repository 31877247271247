import React from 'react'

import { Table, Image, List, Checkbox, Form } from 'semantic-ui-react'
import styled from 'styled-components'

import useFORMEmployees from 'context/user/use-form-employees'
import Button from 'design-system/components/button'
import { StyledForm, TableUserListItem } from 'styles/admin/main'
import { SelectionCenterStaffRole } from 'types/selection-center-staff'
import { UserTypeStaff } from 'types/user'
import * as viewUtils from 'views/utils'

import { State } from '.'

type StaffTabProps = {
  loading: boolean
  setState: React.Dispatch<React.SetStateAction<NullableRecord<State>>>
  state: NullableRecord<State>
}

const StaffTab = ({ state, setState }: StaffTabProps) => {
  const { userOptions, users } = useFORMEmployees()

  const staffIds = state.staff?.map((s) => s.staffMember?.id)

  const handleRemoveStaff = (id: string) => {
    setState((prev) => ({
      ...prev,
      staff: prev.staff?.filter((s) => s.staffMemberId !== id),
    }))
  }

  const handleAddStaff = (id: string) => {
    const found = users.find((u) => u.id === id)
    if (found) {
      let role: SelectionCenterStaffRole = SelectionCenterStaffRole.MANAGER

      if (found.type === UserTypeStaff.SALES_DESIGNER) {
        role = SelectionCenterStaffRole.SALES_DESIGNER
      } else if (found.type === UserTypeStaff.ADMIN) {
        role = SelectionCenterStaffRole.ADMIN
      }

      setState((prev) => ({
        ...prev,
        staff: prev.staff?.concat([
          {
            staffMember: found,
            staffMemberRole: role,
            staffMemberId: found.id,
            isActive:
              role === SelectionCenterStaffRole.ADMIN ? undefined : new Date(),
          },
        ]),
      }))
    }
  }

  const handleSetActive = (id: string) => {
    setState({
      ...state,
      staff: state.staff?.map((s) => {
        if (s.staffMemberId === id) {
          return {
            ...s,
            isActive: s.isActive ? null : new Date(),
          }
        }

        return s
      }),
    })
  }

  return (
    <Wrapper>
      <StyledForm>
        <Form.Dropdown
          fluid
          label="Add Staff Members"
          options={userOptions.filter((o) => !staffIds?.includes(o.value))}
          onChange={(_, { value }) => handleAddStaff(value as string)}
          placeholder="Select Staff Member"
          search
          selection
        />
      </StyledForm>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {state.staff?.map((s) => {
            const user = s.staffMember

            return user ? (
              <Table.Row key={s.staffMember?.id}>
                <Table.Cell width={4}>
                  <div>
                    <TableUserListItem>
                      <Image
                        avatar
                        circular
                        src={user.picture}
                        style={{
                          minHeight: '35px',
                          minWidth: '35px',
                          marginRight: '10px',
                        }}
                      />
                      <List.Content>
                        <List.Header>
                          <span
                            style={{ fontSize: '16px', textTransform: 'none' }}
                          >
                            {viewUtils.getNameForUser(user)}
                          </span>
                        </List.Header>
                      </List.Content>
                    </TableUserListItem>
                  </div>
                </Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>
                  {viewUtils.capsSnakeCaseToTitleCase(s.staffMemberRole)}
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    slider
                    defaultChecked={!!s.isActive}
                    onChange={() => handleSetActive(user.id)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ActionsWrapper>
                    <Button
                      kind="solid"
                      fontAwesomeIcon="trash-alt"
                      onClick={() => handleRemoveStaff(user.id)}
                    />
                  </ActionsWrapper>
                </Table.Cell>
              </Table.Row>
            ) : null
          })}
        </Table.Body>
      </Table>
    </Wrapper>
  )
}

export default StaffTab

const Wrapper = styled.div`
  height: 100%;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4;
`
