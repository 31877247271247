import React from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectLayout from 'components/admin/project/layout'
import ProjectDeliveryManyProvider from 'context/project/delivery/provider-many'

interface DeliveryIndexManyProps
  extends RouteComponentProps<{
    project_id: string
  }> {
  children?: React.ReactNode
}

export default (props: DeliveryIndexManyProps) => (
  <ProjectLayout>
    <ProjectDeliveryManyProvider>{props.children}</ProjectDeliveryManyProvider>
  </ProjectLayout>
)
