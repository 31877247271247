import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, List } from 'semantic-ui-react'

import { Badge } from 'components/shared/chip'
import { getNameForProspect } from 'context/prospect/utils'
import { TableUserListItem } from 'styles/admin/main'
import { Prospect, ProspectType } from 'types/prospect'
import { capsSnakeCaseToTitleCase } from 'views/utils'

export const ProspectCellContents = ({
  prospect,
}: {
  prospect: Prospect | undefined
}) => {
  return (
    <>
      {prospect?.data?.prospectType === ProspectType.TRADE && (
        <div style={{ marginBottom: '16px' }}>
          <Badge
            color="#FFF0D9"
            name={capsSnakeCaseToTitleCase(prospect.data?.prospectType)}
          />
        </div>
      )}
      <TableUserListItem key={prospect?.id}>
        <List.Content>
          <List.Header style={{ lineBreak: 'anywhere' }}>
            {getNameForProspect(prospect)}
          </List.Header>
          <p className="light-gray x-small">{prospect?.email}</p>
          {prospect?.data?.emailAlternate ? (
            <>
              <Divider style={{ margin: '16px 0 8px 0' }} />
              <p className="light-gray x-small">
                <FontAwesomeIcon
                  icon={['fal', 'folders']}
                  style={{ marginRight: '8px' }}
                />
                {prospect.data?.emailAlternate}
              </p>
            </>
          ) : null}
        </List.Content>
      </TableUserListItem>
    </>
  )
}
