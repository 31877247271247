import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import DisplayNameEditable from 'components/admin/catalog/edit-display-name'
import GenericOptionSummary from 'components/admin/catalog/generic-option/summary'
import Assets from 'components/admin/catalog/media/assets'
import MediaModal from 'components/admin/catalog/media/modal'
import Photography from 'components/admin/catalog/media/photography'
import useAuth from 'context/auth/use'
import useGenericOption from 'context/generic-option/use'
import useGenericOptionMutate from 'context/generic-option/use-mutate'
import Button from 'design-system/components/button'
import { StyledBreadcrumb } from 'styles/planner/main'

export default (_: RouteComponentProps) => {
  const { isAdmin } = useAuth()
  const { catalog, genericOption, refetch } = useGenericOption()
  const { updateGenericOption } = useGenericOptionMutate()

  const onSaveDisplayName = async (displayName: string) => {
    await updateGenericOption({
      variables: {
        data: {
          data: {
            ...genericOption.data,
            displayName: displayName,
          },
        },
        where: { id: genericOption.id },
      },
      onCompleted: refetch,
    })
  }

  const sections = [
    {
      key: 'Catalog',
      content: `Catalog`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Generic Feature',
      content: genericOption.feature?.description,
      href: `/admin/catalog/${catalog}/generic-features/${genericOption.feature?.id}`,
    },
    {
      key: 'Generic Option',
      content: genericOption.description,
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
        <Grid.Column>
          <MediaModal
            genericOptions={[genericOption]}
            refetch={refetch}
            trigger={
              <Button
                kind="solid"
                color="blue"
                text="Upload Media"
                disabled={!isAdmin}
                style={{ float: 'right' }}
                fontAwesomeIcon="cloud-arrow-up"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DisplayNameEditable
            name={genericOption.data?.displayName || genericOption.description}
            onSave={onSaveDisplayName}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <GenericOptionSummary option={genericOption} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Assets media={genericOption.media ?? []} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Photography media={genericOption.media ?? []} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
