import React from 'react'

import { Grid } from 'semantic-ui-react'

import useNobiliaOptionFiltersSearch from 'components/admin/catalog/nobilia-option/use-filters-search'
import FiltersAndSearch from 'components/admin/filters-search'
import Loader from 'components/shared/loader'
import useNobiliaOptionMany from 'context/nobilia-option/use-many'
import {
  SelectionCardVariation,
  SelectionCardExpandSelectProps,
  SelectionCardProps,
} from 'design-system/components/selection-card'
import { NobiliaOption } from 'types/nobilia-option'

import NobiliaOptionSelectionCard from './card'

const NobiliaOptionsSelection = ({
  selectedNobiliaOptions,
  selectionCardProps,
  setSelectedNobiliaOptions,
}: {
  selectedNobiliaOptions: Partial<NobiliaOption>[]
  selectionCardProps?: SelectionCardProps
  setSelectedNobiliaOptions: (nobiliaOptions: Partial<NobiliaOption>[]) => void
}) => {
  const { nobiliaOptions, loading } = useNobiliaOptionMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useNobiliaOptionFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
        style={{ marginTop: '10px' }}
      />
      {loading ? (
        <Grid.Row style={{ minHeight: '100vh' }}>
          <Grid.Column>
            <Loader flex fullScreen={false} />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row columns={4} stretched>
          {nobiliaOptions.map((item) => {
            return (
              <Grid.Column key={item.id} style={{ marginBottom: '20px' }}>
                <NobiliaOptionSelectionCard
                  nobiliaOption={item}
                  selectionCardProps={
                    {
                      ...selectionCardProps,
                      selectOnly: true,
                      onSelect: () => {
                        if (
                          selectedNobiliaOptions.find((s) => s.id === item.id)
                        )
                          setSelectedNobiliaOptions(
                            selectedNobiliaOptions.filter(
                              (s) => s.id !== item.id,
                            ),
                          )
                        else
                          setSelectedNobiliaOptions([
                            ...selectedNobiliaOptions,
                            item,
                          ])
                      },
                      selected: selectedNobiliaOptions.some(
                        (s) => s.id === item.id,
                      ),
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      )}
    </Grid>
  )
}

export default NobiliaOptionsSelection
