import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import moment from 'moment'
import { Button, Divider, Grid, List, Table } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useProjectDeliveryMany from 'context/project/delivery/use-many'
import useProjectDeliveryManyMutate from 'context/project/delivery/use-many-mutate'
import useProject from 'context/project/use'
import { StyledIcon } from 'styles/admin/main'
import { Delivery } from 'types/delivery'

const Deliveries = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project, refetch } = useProject()
  const { deliveries } = useProjectDeliveryMany()
  const { deleteDeliveryAndRefetch } = useProjectDeliveryManyMutate()
  const [deliveryToDelete, setDeliveryToDelete] = useState<Delivery>()

  return (
    <>
      <ProjectPageTitle viewName="Deliveries" />
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <h5>Deliveries</h5>
          </Grid.Column>
          <Grid.Column>
            <Button
              primary
              floated="right"
              onClick={() =>
                navigate(`/admin/projects/${project.id}/deliveries/create`)
              }
            >
              Create New Delivery
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Delivery Date</Table.HeaderCell>
                  <Table.HeaderCell>Supplier Orders</Table.HeaderCell>
                  <Table.HeaderCell>Damages</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {deliveries.map((d) => (
                  <Table.Row key={d.id}>
                    <Table.Cell width={4}>{d.data?.name}</Table.Cell>
                    <Table.Cell width={2}>
                      {d.date ? moment(d.date).format('MM/DD/YYYY') : ''}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <List>
                        {d.supplierOrders.map((so) => (
                          <List.Item key={so.id}>
                            <List.Content>
                              <List.Header>
                                {so.nobiliaOrderRef} (ACK{so.nobiliaAckNumber})
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        ))}
                      </List>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {d.issues?.length ? (
                        <StyledIcon
                          styled={{ warning: true }}
                          icon={['fal', 'exclamation-circle']}
                        />
                      ) : null}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <Button
                        disabled={!!d.issues?.length}
                        icon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
                        floated="right"
                        onClick={() => setDeliveryToDelete(d)}
                      />
                      <Button
                        floated="right"
                        icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
                        onClick={() =>
                          navigate(
                            `/admin/projects/${project.id}/deliveries/${d.id}`,
                          )
                        }
                        primary
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmationModal
        firstTitle="Are you sure you want to delete the delivery?"
        onCancel={() => setDeliveryToDelete(undefined)}
        onConfirm={async () => {
          await deleteDeliveryAndRefetch({
            variables: {
              where: {
                id: deliveryToDelete?.id ?? '',
              },
            },
          })
          refetch()
          setDeliveryToDelete(undefined)
        }}
        open={!!deliveryToDelete}
      />
    </>
  )
}

export default Deliveries
