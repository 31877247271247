import { parseFiles } from 'context/project-file/utils-many'
import { Project, ProjectType } from 'types/project'
import { RoomGraphQL } from 'types/room'
import { OverviewChecklist, OverviewAlert, StatusMode } from 'types/utils'

import * as utils from './utils'

export const constructRoomInDesignChecklist = (
  room: RoomGraphQL,
  {
    isPrimary,
    project,
  }: {
    isPrimary: boolean
    project: Project
  },
): OverviewChecklist => {
  const { floorPlans, roomPhotos, inspirationImages } = parseFiles(room.files)
  const isTradeProject = project?.data?.projectType === ProjectType.TRADE

  const {
    latestDesignPublished,
    latestDesignPublishedPlanExternalReviewMode,
    latestDesignPublishedPlanExternalReviewText,
  } = utils.parseDesigns(room)

  const {
    appliancesAreAcknowledged,
    appliancesDirty,
    appliancesUnarchived,
    appliancesConfirmed,
  } = utils.parseAppliances(room)

  const appliancesMode = utils.parseAppliancesMode({
    isTradeProject,
    room,
    appliancesAreAcknowledged,
    paymentProjectDepositPaid: true,
  })

  const appliancesModeText = utils.generateAppliancesModeText({
    room,
    appliancesMode,
    appliancesConfirmed,
    appliancesDirty,
    appliancesUnarchived,
  })

  const {
    measurementsAreAcknowledged,
    measurementsDirty,
    measurements,
    measurementsConfirmed,
  } = utils.parseMeasurements(room)

  const measurementsMode = utils.parseMeasurementsMode({
    room,
    measurementsAreAcknowledged,
  })

  const measurementsModeText = utils.generateMeasurementsModeText({
    room,
    measurementsMode,
    measurementsConfirmed,
    measurementsDirty,
    measurements,
  })

  const {
    paymentIncluded,
    floorPlansIncluded,
    roomPhotosIncluded,
    inspirationImagesIncluded,
    appliancesIncluded,
    measurementsIncluded,
    initialFlowDone,
  } = utils.parseRoomData(room, isPrimary)

  return {
    room,
    checklistItems: [
      ...(paymentIncluded && !initialFlowDone
        ? [
            {
              key: 'payment',
              url: `/admin/projects/${project.id}/payments`,
              name: 'Payment',
              statusMode: (() => {
                if (room.payments.some((p) => p.status === 'succeeded'))
                  return StatusMode.Confirmed
                return StatusMode.OpenForEdit
              })(),
              statusText: (() => {
                if (room.payments.some((p) => p.status === 'succeeded'))
                  return 'Paid'
                return 'Pending'
              })(),
            },
          ]
        : []),
      ...(floorPlansIncluded && !initialFlowDone
        ? [
            {
              key: 'floorPlans',
              url: `/admin/projects/${project.id}/rooms/${room.id}/floor-plans`,
              name: 'Floor Plans',
              statusMode: (() => {
                if (room.data?.floorPlansCompletedAt)
                  return StatusMode.Confirmed
                return StatusMode.OpenForEdit
              })(),
              statusText: (() => {
                if (room.data?.floorPlansCompletedAt)
                  return `${floorPlans.length} Uploaded`
                return 'Pending'
              })(),
            },
          ]
        : []),
      ...(roomPhotosIncluded && !initialFlowDone
        ? [
            {
              key: 'roomPhotos',
              url: `/admin/projects/${project.id}/rooms/${room.id}/photos`,
              name: 'Room Photos',
              statusMode: (() => {
                if (room.data?.roomPhotosCompletedAt) {
                  return StatusMode.Confirmed
                }
                return StatusMode.OpenForEdit
              })(),
              statusText: (() => {
                if (room.data?.roomPhotosCompletedAt) {
                  if (roomPhotos.length) return `${roomPhotos.length} Uploaded`
                  return 'Skipped'
                }
                return 'Pending'
              })(),
            },
          ]
        : []),
      ...(inspirationImagesIncluded && !initialFlowDone
        ? [
            {
              key: 'inspirationImages',
              url: `/admin/projects/${project.id}/rooms/${room.id}/inspiration-images`,
              name: 'Inspiration Images',
              statusMode: (() => {
                if (room.data?.inspirationImagesCompletedAt) {
                  return StatusMode.Confirmed
                }
                return StatusMode.OpenForEdit
              })(),
              statusText: (() => {
                if (room.data?.inspirationImagesCompletedAt) {
                  if (inspirationImages.length)
                    return `${inspirationImages.length} Uploaded`
                  return 'Skipped'
                }
                return 'Pending'
              })(),
            },
          ]
        : []),
      ...(appliancesIncluded
        ? [
            {
              key: 'appliances',
              url: `/admin/projects/${project.id}/rooms/${room.id}/appliances`,
              name: 'Appliances',
              statusMode: appliancesMode || StatusMode.OpenForEdit,
              statusText: appliancesModeText.text,
            },
          ]
        : []),
      ...(measurementsIncluded
        ? [
            {
              key: 'measurements',
              url: `/admin/projects/${project.id}/rooms/${room.id}/measurements`,
              name: 'Measurements',
              statusMode: measurementsMode || StatusMode.OpenForEdit,
              statusText: measurementsModeText.text,
            },
          ]
        : []),
      {
        key: 'designPlan',
        url: latestDesignPublished
          ? `/admin/projects/${project.id}/rooms/${room.id}/designs/${latestDesignPublished.id}`
          : `/admin/projects/${project.id}/rooms/${room.id}/designs`,
        name: latestDesignPublished?.name || 'Design Plan',
        statusMode:
          latestDesignPublishedPlanExternalReviewMode || StatusMode.Submitted,
        statusText:
          latestDesignPublishedPlanExternalReviewText?.text ||
          'No plan or not published',
      },
    ],
  }
}

export const constructRoomInDesignAlerts = (
  _: RoomGraphQL,
): OverviewAlert[] => {
  return []
}
