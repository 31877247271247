import { useContext } from 'react'

import { ProjectIssueManyContext } from './provider-many'

const useProjectIssueMany = () => {
  const { issues, loading, refetch, queryFilters, setQueryFilters } =
    useContext(ProjectIssueManyContext)

  return {
    issues,
    loading,
    queryFilters,
    refetch,
    setQueryFilters,
  }
}

export default useProjectIssueMany
