import React from 'react'

import { Grid } from 'semantic-ui-react'

import MediaCard from 'components/admin/catalog/media/card'
import * as mUtils from 'context/media/utils-many'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import * as NO from 'planner/objects/products/nobilia/options/definitions'
import { NobiliaOption } from 'types/nobilia-option'

import NobiliaOptionMetadata from './metadata'

const NobiliaOptionSummary = ({
  option,
  refetch,
}: {
  option: NobiliaOption
  refetch?: () => Promise<unknown>
}) => {
  const { frontComboPriceGroupIndex, frontComboRangeIndex } =
    useNobiliaOptions()

  const referenceImage = mUtils.parseMedia(option.media ?? []).reference[0]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={2}>
          {option.featureNo === NO.FRONT_COMBO ? (
            <>
              <p className="caption">Price group</p>
              <h4>{frontComboPriceGroupIndex[option.optionKey]}</h4>
            </>
          ) : null}
        </Grid.Column>
        <Grid.Column width={2}>
          {option.featureNo === NO.FRONT_COMBO ? (
            <>
              <p className="caption">Range</p>
              <p>{frontComboRangeIndex[option.optionKey]}</p>
            </>
          ) : null}
        </Grid.Column>
        <Grid.Column
          width={5}
          textAlign="center"
          style={{ display: 'inline-table' }}
        >
          <MediaCard
            enableEdit={!!referenceImage}
            media={referenceImage}
            refetch={refetch}
          />
          <p className="caption" style={{ marginTop: '14px' }}>
            Reference Image
          </p>
        </Grid.Column>
        <Grid.Column width={6}>
          <NobiliaOptionMetadata
            option={option}
            priceGroup={frontComboPriceGroupIndex[option.optionKey]}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default NobiliaOptionSummary
