import { DocumentNode, useQuery } from '@apollo/client'

import {
  FIND_MANY_ROOM,
  FindManyRoomPayload,
  FindManyRoomVariables,
} from 'queries/room'

const useRoomManyNoContext = (opts?: {
  variables?: FindManyRoomVariables
  query?: DocumentNode
}) => {
  const {
    data: { rooms } = { rooms: [] },
    loading,
    refetch,
  } = useQuery<FindManyRoomPayload, FindManyRoomVariables>(
    opts?.query || FIND_MANY_ROOM,
    {
      variables: opts?.variables,
    },
  )

  return {
    loading,
    refetch,
    rooms,
  }
}

export default useRoomManyNoContext
