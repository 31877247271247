import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import CatalogFeatureSummary from 'components/admin/catalog/catalog-feature/summary'
import DisplayNameEditable from 'components/admin/catalog/edit-display-name'
import Assets from 'components/admin/catalog/media/assets'
import MediaModal from 'components/admin/catalog/media/modal'
import Photography from 'components/admin/catalog/media/photography'
import useAuth from 'context/auth/use'
import useCatalogFeature from 'context/catalog-feature/use'
import useCatalogFeatureMutate from 'context/catalog-feature/use-mutate'
import Button from 'design-system/components/button'
import { StyledBreadcrumb } from 'styles/planner/main'
import {
  camelCaseToTitleCase,
  getProductFeatureCategoryFromGroupId,
} from 'views/utils'

export default (_: RouteComponentProps) => {
  const { isAdmin } = useAuth()
  const { catalog, catalogFeature, refetch } = useCatalogFeature()
  const { updateCatalogFeature } = useCatalogFeatureMutate()

  const onSaveDisplayName = async (displayName: string) => {
    await updateCatalogFeature({
      variables: {
        data: {
          data: {
            ...catalogFeature.data,
            displayName: displayName,
          },
        },
        where: { id: catalogFeature.id },
      },
      onCompleted: refetch,
    })
  }

  const sections = [
    {
      key: 'Catalog',
      content: `Catalog`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Catalog Features',
      content: 'Catalog Features',
      href: `/admin/catalog/${catalog}/catalog-features`,
    },
    {
      key: 'Catalog Feature',
      content: `${camelCaseToTitleCase(catalogFeature?.groupIdentifier)} - ${
        catalogFeature.description
      }`,
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
        <Grid.Column>
          <MediaModal
            catalogFeatures={[catalogFeature]}
            refetch={refetch}
            trigger={
              <Button
                kind="solid"
                color="blue"
                text="Upload Media"
                disabled={!isAdmin}
                style={{ float: 'right' }}
                fontAwesomeIcon="cloud-arrow-up"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DisplayNameEditable
            category={getProductFeatureCategoryFromGroupId(
              catalogFeature?.groupIdentifier,
            )}
            name={
              catalogFeature?.data?.displayName || catalogFeature?.description
            }
            description={catalogFeature?.description}
            onSave={onSaveDisplayName}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <CatalogFeatureSummary />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Assets media={catalogFeature.media} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Photography media={catalogFeature.media} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
