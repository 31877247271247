import React from 'react'

import { RouteComponentProps, useLocation } from '@reach/router'

import ProjectLayout from 'components/admin/project/layout'
import RoomProvider from 'context/room/provider'

interface RoomsProps
  extends RouteComponentProps<{ project_id: string; room_id: string }> {
  children?: React.ReactNode
}

export default (props: RoomsProps) => {
  const { pathname } = useLocation()
  // ignore project layout on plan files
  const allowedPaths = [
    '/plan/',
    '/plan-internal/',
    '/plan-external/',
    '/measurements/',
  ]

  const ignoreProjectLayout = allowedPaths.some((path) => {
    const pathIndex = pathname.indexOf(path)
    if (pathIndex !== -1) {
      const pathSegments = pathname
        .substring(pathIndex + path.length)
        .split('/')
      return pathSegments.some((segment) => segment.trim() !== '')
    }
    return false
  })

  if (ignoreProjectLayout)
    return <RoomProvider room_id={props.room_id}>{props.children}</RoomProvider>
  return (
    <ProjectLayout>
      <RoomProvider room_id={props.room_id}>{props.children}</RoomProvider>
    </ProjectLayout>
  )
}
