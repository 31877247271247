import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'

import useAuth from 'context/auth/use'

const Admin = (
  props: RouteComponentProps & {
    children: React.ReactNode
  },
) => {
  const {
    formUserId,
    isAccountExecutive,
    isAdmin,
    isArtist,
    isAuthenticated,
    isBuildZoom,
    isCustomerSupport,
    isFORMOrExternalStaff,
    isNobiliaOrderProcessor,
    isRenderManager,
    isSalesDesigner,
    isSelectionCenterManager,
    isTechDesigner,
    isTechDesignerSupport,
    isTradeDesigner,
  } = useAuth()

  useEffect(() => {
    if (typeof window === 'undefined') return

    if (!isAuthenticated) return

    if (!isFORMOrExternalStaff) {
      props.navigate?.('/')
      return
    }

    // redirect artist if they leave designs
    if (isArtist && !props.location?.pathname.includes('/admin/designs')) {
      props.navigate?.('/admin/designs', { replace: true })
      return
    }

    // redirect buildzoom if they leave designs
    if (
      isBuildZoom &&
      !props.location?.pathname.includes('/admin/projects-buildzoom')
    ) {
      props.navigate?.('/admin/projects-buildzoom', { replace: true })
      return
    }

    // redirect everyone else on root
    if (['/admin', '/admin/'].includes(props.location?.pathname as string)) {
      if (
        isAdmin ||
        isAccountExecutive ||
        isRenderManager ||
        isSalesDesigner ||
        isSelectionCenterManager ||
        isTechDesigner ||
        isTechDesignerSupport ||
        isTradeDesigner ||
        isCustomerSupport ||
        isNobiliaOrderProcessor
      ) {
        props.navigate?.(`/admin/dashboard/${formUserId}`, { replace: true })
        return
      }
    }
  }, [props.location?.pathname, isAuthenticated])

  return <>{props.children}</>
}

export default Admin
