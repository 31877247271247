import { useQuery } from '@apollo/client'

import {
  FIND_UNIQUE_DESIGN_LOOKUP,
  FindUniqueDesignPayload,
  FindUniqueDesignVariables,
} from 'queries/design'

// to get ids for contexts
const useDesignLookup = (designId?: string) => {
  const { loading, data } = useQuery<
    FindUniqueDesignPayload,
    FindUniqueDesignVariables
  >(FIND_UNIQUE_DESIGN_LOOKUP, {
    variables: {
      where: { id: designId ?? '' },
    },
  })

  return {
    loading,
    design: data?.design,
  }
}

export default useDesignLookup
