import React, { createContext } from 'react'

import Loader from 'components/shared/loader'
import useProjectMany from 'context/dashboard/use-project-many'
import useTaskMany from 'context/dashboard/use-task-many'
import { Project } from 'types/project'
import { ProjectStaffRole } from 'types/project-staff'
import { Task } from 'types/task'

interface ITechDesignerSupportContext {
  projects: Project[]
  refetchProjects: () => Promise<unknown>
  refetchTasks: () => Promise<unknown>
  tasks: Task[]
  userId: string
}

type TechDesignerSupportProps = React.PropsWithChildren<{ user_id: string }>

export const TechDesignerSupportContext =
  createContext<ITechDesignerSupportContext>({} as ITechDesignerSupportContext)

const TechDesignerSupportProvider = (props: TechDesignerSupportProps) => {
  const {
    projects,
    loading: loadingProjects,
    refetch: refetchProjects,
  } = useProjectMany(props.user_id, ProjectStaffRole.TECH_DESIGNER)

  const {
    tasks,
    loading: loadingTasks,
    refetch: refetchTasks,
  } = useTaskMany(props.user_id)

  if (loadingProjects || loadingTasks) return <Loader />

  return (
    <TechDesignerSupportContext.Provider
      value={{
        projects: projects ?? [],
        refetchProjects,
        refetchTasks,
        tasks,
        userId: props.user_id,
      }}
    >
      {props.children}
    </TechDesignerSupportContext.Provider>
  )
}

export default TechDesignerSupportProvider
