import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useNobiliaOrderProcessor from 'context/dashboard/nobilia-order-processor/use'
import { ScrollBarStyle } from 'styles/app/system'

const MenuNobiliaOrderProcessor = () => {
  const { pathname } = useLocation()
  const { lateCount, onTrackCount, userId } = useNobiliaOrderProcessor()

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        active={
          pathname === `/admin/dashboard/${userId}/nobilia-order-processor/`
        }
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/nobilia-order-processor`)
        }
      >
        All
      </Menu.Item>
      <Menu.Item
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/nobilia-order-processor/orders-late`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/nobilia-order-processor/orders-late`,
          )
        }
      >
        Late
        <Label className="count">{lateCount}</Label>
      </Menu.Item>
      <Menu.Item
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/nobilia-order-processor/orders-ontrack`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/nobilia-order-processor/orders-ontrack`,
          )
        }
      >
        On Track
        <Label className="count">{onTrackCount}</Label>
      </Menu.Item>
      <Menu.Item
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/nobilia-order-processor/orders-no-ack-yet`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/nobilia-order-processor/orders-no-ack-yet`,
          )
        }
      >
        No ACK Yet
      </Menu.Item>
      <Menu.Item
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/nobilia-order-processor/orders-ack-received`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/nobilia-order-processor/orders-ack-received`,
          )
        }
      >
        ACK Received
      </Menu.Item>
      <Menu.Item
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/nobilia-order-processor/orders-ack-approved`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/nobilia-order-processor/orders-ack-approved`,
          )
        }
      >
        ACK Approved
      </Menu.Item>
      <Menu.Item
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/nobilia-order-processor/bulk-import`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/nobilia-order-processor/bulk-import`,
          )
        }
        disabled={process.env.GATSBY_ACTIVE_ENV !== 'production'}
      >
        Zoho Bulk Import
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuNobiliaOrderProcessor

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
