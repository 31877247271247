import React from 'react'

import { RouteComponentProps } from '@reach/router'

import ConversationsFeed from 'components/admin/conversations'
import ConversationManyProvider from 'context/project/conversation/provider-many'

import CustomerSupportLayout from './layout'

const CustomerSupportConversations = (_: RouteComponentProps) => {
  return (
    <CustomerSupportLayout>
      <ConversationManyProvider
        defaultFilters={{
          clientUser: 'notAssigned',
          take: 20,
        }}
      >
        <ConversationsFeed />
      </ConversationManyProvider>
    </CustomerSupportLayout>
  )
}

export default CustomerSupportConversations
