import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import SamplesOrderTab from 'components/admin/project/order-samples/tab'
import useOrder from 'context/order/use'
import useProjectOrderSamplesMany from 'context/project/order-samples/use-many'
import useProject from 'context/project/use'

const SamplesOrderCreateUpdate = (
  _: RouteComponentProps<{ project_id: string; order_id?: string }>,
) => {
  const { project, refetch: refetchProject } = useProject()
  const { order } = useOrder()
  const { refetch } = useProjectOrderSamplesMany()

  return (
    <>
      <ProjectPageTitle viewName="Samples Order" />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h5>{order ? 'Edit Samples Order' : 'Create Samples Order'}</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SamplesOrderTab
              project={project}
              onComplete={() => {
                // refetch table
                refetch()
                // refetch project
                refetchProject()
                navigate(`/admin/projects/${project.id}/sample-orders`)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default SamplesOrderCreateUpdate
