import { useMutation } from '@apollo/client'

import {
  CREATE_ROUTE,
  CreateRoutePayload,
  CreateRouteVariables,
  DELETE_ROUTE,
  DeleteRoutePayload,
  DeleteRouteVariables,
  UPDATE_ROUTE,
  UpdateRoutePayload,
  UpdateRouteVariables,
} from 'queries/route'

const useRouteMutateNoContext = () => {
  const [createRoute, { loading: loadingCreate }] = useMutation<
    CreateRoutePayload,
    CreateRouteVariables
  >(CREATE_ROUTE)

  const [deleteRoute, { loading: loadingDelete }] = useMutation<
    DeleteRoutePayload,
    DeleteRouteVariables
  >(DELETE_ROUTE)

  const [updateRoute, { loading: loadingUpdate }] = useMutation<
    UpdateRoutePayload,
    UpdateRouteVariables
  >(UPDATE_ROUTE)

  return {
    createRoute,
    deleteRoute,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateRoute,
  }
}

export default useRouteMutateNoContext
