import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Button, Label, Table } from 'semantic-ui-react'

import AddressFormat from 'components/shared/address-format'
import { isAddOnDesign } from 'context/design/utils'
import useSupplierOrderMany from 'context/supplier-order/use-many'
import * as supplierOrderUtils from 'context/supplier-order/utils'
import { InfoPopup } from 'styles/admin/main'
import { SupplierOrder } from 'types/supplier-order'

import SupplierOrderModal from './create-update-modal'
import { SupplierOrderCellContentsLess } from './table-cell-content'
import SupplierOrderCellContentNobiliaStatus from './table-cell-content-nobilia-status'
import { ProjectStaffCellContents } from '../project/project-staff/table-cell-content'

const SupplierOrdersConfirmationTable = () => {
  const { supplierOrders, refetch, queryFilters, setQueryFilters } =
    useSupplierOrderMany()
  const { sortBy, sortDirection } = queryFilters

  const [supplierOrderToEdit, setSupplierOrderToEdit] =
    useState<SupplierOrder>()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Order</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Shipping address</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedStatus' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedStatus',
                sortDirection:
                  sortBy === 'derivedStatus' && sortDirection === 'ascending'
                    ? 'descending'
                    : 'ascending',
              })
            }
          >
            Latest action
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'createdAt' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'createdAt',
                sortDirection:
                  sortBy === 'createdAt' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Status
          </Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {supplierOrders.map((so: SupplierOrder) => {
          const soStatusSummary =
            supplierOrderUtils.getSupplierOrderStatusSummary(so)
          const isRemedial = supplierOrderUtils.isRemedialSupplierOrder(so)
          const isAddOn = isAddOnDesign(so.design)

          return (
            <Table.Row key={so.id}>
              <Table.Cell width={3}>
                {isRemedial ? (
                  <Label color="red" content="Remedial" ribbon />
                ) : null}
                {isAddOn ? (
                  <Label color="yellow" content="Add-On" ribbon />
                ) : null}
                <SupplierOrderCellContentsLess supplierOrder={so} />
              </Table.Cell>
              <Table.Cell width={2}>
                {so.project ? (
                  <ProjectStaffCellContents project={so.project} />
                ) : (
                  'Third Party Order'
                )}
              </Table.Cell>
              <Table.Cell width={2}>
                <AddressFormat
                  address={supplierOrderUtils.getSupplierOrderAddress(so)}
                />
              </Table.Cell>
              <Table.Cell width={2}>
                <div>{soStatusSummary.currentStage}</div>
                <div style={{ marginBottom: '5px' }}>
                  {soStatusSummary.currentStageDate
                    ? `${moment().diff(
                        soStatusSummary.currentStageDate,
                        'day',
                      )} days
                  ago`
                    : null}
                </div>
              </Table.Cell>
              <Table.Cell width={2}>
                <SupplierOrderCellContentNobiliaStatus supplierOrder={so} />
              </Table.Cell>
              <Table.Cell width={2}>
                <InfoPopup
                  content={<p>Edit Supplier Order</p>}
                  trigger={
                    <Button
                      primary
                      icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
                      onClick={() => setSupplierOrderToEdit(so)}
                    />
                  }
                />
                <InfoPopup
                  content={<p>Open in project</p>}
                  trigger={
                    <Button
                      onClick={() =>
                        window.open(
                          `/admin/projects/${so.project.id}/supplier-orders/${so.id}`,
                        )
                      }
                      icon={
                        <FontAwesomeIcon
                          icon={['fal', 'arrow-up-right-from-square']}
                          fixedWidth
                        />
                      }
                    />
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
        {supplierOrderToEdit?.id ? (
          <SupplierOrderModal
            open={!!supplierOrderToEdit}
            onClose={() => setSupplierOrderToEdit(undefined)}
            refetch={refetch}
            supplierOrderId={supplierOrderToEdit?.id}
          />
        ) : null}
      </Table.Body>
    </Table>
  )
}

export default SupplierOrdersConfirmationTable
