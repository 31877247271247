import React, { createContext } from 'react'

import Loader from 'components/shared/loader'
import useMeetingMany from 'context/dashboard/use-meeting-many'
import { Meeting } from 'types/meeting'

interface IAccountExecutiveContext {
  meetings: Meeting[]
  userId: string
}

type AccountExecutiveProps = React.PropsWithChildren<{ user_id: string }>

export const AccountExecutiveContext = createContext<IAccountExecutiveContext>(
  {} as IAccountExecutiveContext,
)

const AccountExecutiveProvider = (props: AccountExecutiveProps) => {
  const { loading, meetings } = useMeetingMany(props.user_id)

  if (loading) return <Loader />

  return (
    <AccountExecutiveContext.Provider
      value={{
        meetings,
        userId: props.user_id,
      }}
    >
      {props.children}
    </AccountExecutiveContext.Provider>
  )
}

export default AccountExecutiveProvider
