import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import Designs from 'components/admin/design/index-many'
import DesignsApprovedTable from 'components/admin/design/table-approved'
import DesignsRendersTable from 'components/admin/design/table-renders'
import DesignsReviewsTable from 'components/admin/design/table-reviews'
import { FiltersVersion } from 'components/admin/design/use-filters-search'
import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import useAuth from 'context/auth/use'
import DesignManyProvider from 'context/design/provider-many'
import { QueryFilters } from 'context/design/utils-many'

const Module = (
  props: RouteComponentProps & { location?: { search: string } },
) => {
  const { isTechDesignerSupport } = useAuth()

  const defaultFilter = new URLSearchParams(props.location?.search)?.get(
    'defaultFilter',
  )

  const [filters, setFilters] = useState<Partial<QueryFilters>>()
  const [filtersType, setFiltersType] = useState<FiltersVersion>()
  const [table, setTable] = useState<JSX.Element>()
  const [title, setTitle] = useState<string>('Designs')

  useEffect(() => {
    switch (defaultFilter) {
      case 'unsent': {
        setFilters({
          productionStatus: 'approvedUnsent',
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          type: isTechDesignerSupport ? 'addOn' : 'regular',
        })
        setFiltersType(FiltersVersion.APPROVED)
        setTable(<DesignsApprovedTable />)
        setTitle('Designs Approved')
        break
      }
      case 'awaitingFeedback': {
        setFilters({
          reviewStatus: 'awaitingFeedback',
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
        })
        setFiltersType(FiltersVersion.REVIEW)
        setTable(<DesignsReviewsTable />)
        setTitle('Designs Awaiting Feedback')
        break
      }
      case 'awaitingSignoff': {
        setFilters({
          reviewStatus: 'awaitingSignoff',
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
        })
        setFiltersType(FiltersVersion.REVIEW)
        setTable(<DesignsReviewsTable />)
        setTitle('Designs Awaiting Signoff')
        break
      }
      case 'signedOff': {
        setFilters({
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          reviewStatus: 'signedOff',
        })
        setFiltersType(FiltersVersion.REVIEW)
        setTable(<DesignsReviewsTable />)
        setTitle('Designs Signed Off')
        break
      }
      case 'rendering': {
        setFilters({
          renderStatus: 'all',
          sortBy: 'sentToRenderingAt',
          sortDirection: 'descending',
        })
        setFiltersType(FiltersVersion.RENDERING)
        setTable(<DesignsRendersTable />)
        setTitle('Renders')
        break
      }
      case 'gallery': {
        setFilters({
          galleryStatus: 'showingInGallery',
          sortBy: 'sentToRenderingAt',
          sortDirection: 'descending',
        })
        setFiltersType(FiltersVersion.GALLERY)
        break
      }
      default: {
        setFilters({})
        break
      }
    }
  }, [defaultFilter, isTechDesignerSupport])

  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title={title} />
        <Grid.Row>
          <Grid.Column>
            <DesignManyProvider
              defaultFilters={{
                ...filters,
                take: 30,
              }}
              skipLoader
            >
              <Designs filtersType={filtersType} table={table} />
            </DesignManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
