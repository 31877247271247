// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Image } from 'semantic-ui-react'

import { DeleteButton } from 'styles/planner/main'

import NodeOptionsMenu from './option-menus/node-options'
import NodeParametersMenu from './option-menus/node-parameters'
import { NodeBase, NodeOption, NodeParameter } from '../../objects/node'

interface NodeOptionPanelProps {
  node: NodeBase
  nodeOptions: NodeOption[]
  nodeParameters: NodeParameter[]
  onDelete: () => void
  onOptionChange: (changedNodeOption: NodeOption[]) => void
  onParameterChange: (changedNodeParameter: NodeParameter) => void
}

const NodeOptionPanel = (props: NodeOptionPanelProps) => {
  if (!props.node) return <></>

  const name = props.node.getName()
  const imageUrl = props.node.getImageURL()

  return (
    <>
      <h2>{name}</h2>
      <DeleteButton
        className="orange-hover small"
        icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
        onClick={props.onDelete}
      />
      {imageUrl && <Image src={imageUrl} size="small" />}
      {props.nodeParameters.length ? (
        <>
          <Divider className="full-width" style={{ margin: '11px 0' }} />
          <NodeParametersMenu
            ps={props.nodeParameters}
            onParameterChange={props.onParameterChange}
          />
        </>
      ) : null}
      {props.nodeOptions.length ? (
        <>
          <Divider className="full-width" style={{ margin: '11px 0' }} />
          <NodeOptionsMenu
            nodeOptions={props.nodeOptions}
            onOptionChange={props.onOptionChange}
          />
        </>
      ) : null}
    </>
  )
}

export default NodeOptionPanel
