// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createContext, Dispatch, useReducer, useState } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import * as BABYLON from 'babylonjs'

import Loader from 'components/shared/loader'
import { NodeRelationshipGraph } from 'planner/compose'
import { ArcRotateCameraBase } from 'planner/objects/cameras/arcrotate'
import { TargetCameraBase } from 'planner/objects/cameras/target'
import { UniversalCameraBase } from 'planner/objects/cameras/universal'
import { EnvironmentLightBase } from 'planner/objects/lights/environment'
import { HemisphericLightBase } from 'planner/objects/lights/hemispheric'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import {
  FIND_MANY_DESIGN_SHALLOW,
  FindManyDesignPayload,
  FindManyDesignVariables,
} from 'queries/design'
import {
  FIND_UNIQUE_ROOM_PLANNER,
  FindUniqueRoomPayload,
  FindUniqueRoomVariables,
} from 'queries/room'
import {
  FIND_MANY_ROOM_ELEMENT_CLASS,
  FindManyRoomElementClassPayload,
  FindManyRoomElementClassVariables,
} from 'queries/room-element-class'
import { Design } from 'types/design'
import { RoomGraphQL } from 'types/room'
import { RoomElementClass } from 'types/room-element-class'

import roomPlannerReducer, {
  RoomPlannerReducerState,
  RoomPlannerReducerAction,
} from './room-reducer'
import { RenderMode, ViewMode } from './types'

interface IRoomContext {
  defaultArcRotateCamera: ArcRotateCameraBase
  defaultTargetCamera: TargetCameraBase
  defaultUniversalCamera: UniversalCameraBase
  designs: Design[]
  dispatch: Dispatch<RoomPlannerReducerAction>
  floorRoomElementClass: RoomElementClass
  refetch: () => void
  refetchDesigns: () => void
  room: RoomGraphQL
  state: Partial<RoomPlannerReducerState>
  wallRoomElementClass: RoomElementClass
}

interface RoomProps extends RouteComponentProps<{ room_id: string }> {
  children: any
}

export const RoomContext = createContext<IRoomContext>({} as IRoomContext)

const RoomProvider = (props: RoomProps) => {
  const [
    {
      defaultAmbient,
      defaultEnvironment,
      defaultLight,
      defaultArcRotateCamera,
      defaultTargetCamera,
      defaultUniversalCamera,
    },
  ] = useState(() => {
    const uc = new UniversalCameraBase({})
    const arc = new ArcRotateCameraBase({
      setActiveOnSceneIfNoneActive: true,
    })
    const tc = new TargetCameraBase({
      mode: BABYLON.Camera.ORTHOGRAPHIC_CAMERA,
    })
    const al = new HemisphericLightBase({
      color: BABYLON.Color3.White(),
    })
    const dl = new HemisphericLightBase({
      parent: arc,
    })
    const de = new EnvironmentLightBase({})
    return {
      defaultAmbient: al,
      defaultEnvironment: de,
      defaultLight: dl,
      defaultArcRotateCamera: arc,
      defaultTargetCamera: tc,
      defaultUniversalCamera: uc,
    }
  })

  const {
    loading: loadingRoom,
    data: dataRoom,
    refetch,
  } = useQuery<FindUniqueRoomPayload, FindUniqueRoomVariables>(
    FIND_UNIQUE_ROOM_PLANNER,
    {
      variables: { where: { id: props.room_id ?? '' } },
    },
  )

  const {
    loading: loadingDesigns,
    data: dataDesigns,
    refetch: refetchDesigns,
  } = useQuery<FindManyDesignPayload, FindManyDesignVariables>(
    FIND_MANY_DESIGN_SHALLOW,
    {
      variables: {
        where: {
          roomId: {
            equals: props.room_id,
          },
        },
      },
    },
  )

  const { loading: loadingRoomElementClasses, data: dataRoomElementClasses } =
    useQuery<
      FindManyRoomElementClassPayload,
      FindManyRoomElementClassVariables
    >(FIND_MANY_ROOM_ELEMENT_CLASS, {
      variables: {
        where: {
          identifier: {
            in: ['wall', 'floor'],
          },
        },
      },
    })

  const [state, dispatch] = useReducer(roomPlannerReducer, {
    activeCamera: defaultUniversalCamera,
    activeHotspot: null,
    activeSectionView: null,
    cameraPosition: null,
    cameraTarget: null,
    drawMode: false,
    measurementUnit: MeasurementUnit.MillimetersWithSuffix,
    moveEnabled: false,
    roomLoaded: false,
    renderMode: RenderMode.MATERIALS,
    sceneRelationshipGraph: new NodeRelationshipGraph([]),
    sceneState: [
      defaultAmbient,
      defaultEnvironment,
      defaultLight,
      defaultArcRotateCamera,
      defaultTargetCamera,
      defaultUniversalCamera,
    ],
    sectionViewMode: false,
    selectedNode: null,
    viewMode: ViewMode.PLAN,
  })

  if (
    loadingRoom ||
    !dataRoom ||
    loadingDesigns ||
    !dataDesigns ||
    loadingRoomElementClasses ||
    !dataRoomElementClasses
  )
    return <Loader />

  const { floorRoomElementClass, wallRoomElementClass } =
    dataRoomElementClasses.roomElementClasses.reduce<{
      floorRoomElementClass: RoomElementClass
      wallRoomElementClass: RoomElementClass
    }>(
      (acc, rec) => {
        if (rec.identifier.includes('floor')) acc.floorRoomElementClass = rec
        if (rec.identifier.includes('wall')) acc.wallRoomElementClass = rec
        return acc
      },
      {
        floorRoomElementClass: undefined as unknown as RoomElementClass,
        wallRoomElementClass: undefined as unknown as RoomElementClass,
      },
    )

  return (
    <RoomContext.Provider
      value={{
        defaultArcRotateCamera,
        defaultTargetCamera,
        defaultUniversalCamera,
        designs: dataDesigns.designs,
        dispatch,
        floorRoomElementClass,
        refetch,
        refetchDesigns,
        room: dataRoom.room,
        state,
        wallRoomElementClass,
      }}
    >
      {props.children}
    </RoomContext.Provider>
  )
}

export default RoomProvider
