import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_GENERIC_PRODUCT,
  CreateGenericProductPayload,
  CreateGenericProductVariables,
  DELETE_GENERIC_PRODUCT,
  DeleteGenericProductPayload,
  DeleteGenericProductVariables,
  UPDATE_GENERIC_PRODUCT,
  UpdateGenericProductPayload,
  UpdateGenericProductVariables,
} from 'queries/generic-product'

import { AppliancePackContext } from './provider'

const useAppliancePackGenericProductMutate = () => {
  const { refetch } = useContext(AppliancePackContext)

  const [createGenericProductAndRefetch, { loading: loadingCreate }] =
    useMutation<CreateGenericProductPayload, CreateGenericProductVariables>(
      CREATE_GENERIC_PRODUCT,
      {
        onCompleted: refetch,
      },
    )

  const [updateGenericProductAndRefetch, { loading: loadingUpdate }] =
    useMutation<UpdateGenericProductPayload, UpdateGenericProductVariables>(
      UPDATE_GENERIC_PRODUCT,
      {
        onCompleted: refetch,
      },
    )

  const [deleteGenericProductAndRefetch, { loading: loadingDelete }] =
    useMutation<DeleteGenericProductPayload, DeleteGenericProductVariables>(
      DELETE_GENERIC_PRODUCT,
      {
        onCompleted: refetch,
      },
    )

  return {
    createGenericProductAndRefetch,
    deleteGenericProductAndRefetch,
    loadingUpdate,
    loadingCreate,
    loadingDelete,
    updateGenericProductAndRefetch,
  }
}

export default useAppliancePackGenericProductMutate
