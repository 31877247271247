import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_SHIPMENT,
  CreateShipmentPayload,
  CreateShipmentVariables,
  UPDATE_SHIPMENT,
  UpdateShipmentPayload,
  UpdateShipmentVariables,
} from 'queries/shipment'

import { ShipmentContext } from './provider'

const useShipmentMutate = () => {
  const { refetch } = useContext(ShipmentContext)

  const [createShipmentAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateShipmentPayload,
    CreateShipmentVariables
  >(CREATE_SHIPMENT, {
    onCompleted: refetch,
  })
  const [updateShipmentAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateShipmentPayload,
    UpdateShipmentVariables
  >(UPDATE_SHIPMENT, {
    onCompleted: refetch,
  })

  return {
    createShipmentAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateShipmentAndRefetch,
  }
}

export default useShipmentMutate
