import React, { useState } from 'react'

import moment from 'moment'
import { Image, List, Placeholder, Table } from 'semantic-ui-react'

import AddressFormat from 'components/shared/address-format'
import { formatDollars } from 'context/dollar-utils'
import useOrderSamplesMany from 'context/order/use-many-samples'
import * as orderUtils from 'context/order/utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import { ButtonTooltip } from 'design-system/components/button'
import { TooltipProps } from 'design-system/components/tooltip'
import { Colors } from 'styles/app/system'
import { Order } from 'types/order'
import { ProjectStaffRole } from 'types/project-staff'

import SamplesModal from './create-update-modal'
import OrderCellContentPlacement from '../order-form/table-cell-content-placement'
import { ProjectOwnersCellContents } from '../project/project-owners/table-cell-content'
import { ProjectStaffCellContents } from '../project/project-staff/table-cell-content'
import { ProspectStaffCellContents } from '../prospect/prospect-staff/table-cell-content'
import { ProspectCellContents } from '../prospect/table-cell-content'

const OrderSamplesTable = () => {
  const { orders, refetch, queryFilters, setQueryFilters } =
    useOrderSamplesMany()
  const { sortBy, sortDirection } = queryFilters
  const [orderToEdit, setOrderToEdit] = useState<Order>()
  const COMMON_TOOLTIP_PROPS: Omit<TooltipProps, 'children' | 'label'> = {
    color: Colors.blue700,
  }

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Prospect / Project</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Samples</Table.HeaderCell>
          <Table.HeaderCell>Shipping Address</Table.HeaderCell>
          <Table.HeaderCell>Total Before Tax</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'placedAt' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'placedAt',
                sortDirection:
                  sortBy === 'placedAt' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Order Status
          </Table.HeaderCell>
          <Table.HeaderCell>Fulfillment</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {orders
          .filter((o) => o.prospect || o.project)
          .map((o) => {
            const orderStatus = orderUtils.getSamplesOrderStatus(o)
            const shippedDate = orderStatus.find((s) => s.id === 'shipped')
              ?.date
            return (
              <Table.Row key={o.id}>
                <Table.Cell width={3} textAlign="left">
                  {o.project ? (
                    <ProjectOwnersCellContents project={o.project} />
                  ) : (
                    <ProspectCellContents prospect={o.prospect} />
                  )}
                </Table.Cell>
                <Table.Cell width={2}>
                  {o.project ? (
                    <ProjectStaffCellContents
                      project={o.project}
                      roleStaff={ProjectStaffRole.SALES_DESIGNER}
                    />
                  ) : (
                    <ProspectStaffCellContents prospect={o.prospect} />
                  )}
                </Table.Cell>
                <Table.Cell width={3} textAlign="left">
                  <List>
                    {o.nobiliaSamples === undefined ? (
                      <Placeholder>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder>
                    ) : (
                      o.nobiliaSamples?.map((p, k) => {
                        const product =
                          checkoutUtils.nobiliaSampleToPaymentItem(p)
                        return (
                          <List.Item key={k}>
                            <List.Icon>
                              <Image
                                src={product.imageUrl}
                                style={{ margin: '0', maxWidth: '20px' }}
                              />
                            </List.Icon>
                            <List.Content verticalAlign="middle">
                              <p>{product.name}</p>
                            </List.Content>
                          </List.Item>
                        )
                      })
                    )}
                  </List>
                </Table.Cell>
                <Table.Cell width={2}>
                  <AddressFormat address={o.shippingAddress} />
                </Table.Cell>
                <Table.Cell width={2}>
                  {formatDollars(checkoutUtils.calculateTotalBeforeTaxes(o))}
                </Table.Cell>
                <Table.Cell width={2}>
                  <OrderCellContentPlacement order={o} />
                </Table.Cell>
                <Table.Cell width={2}>
                  {shippedDate ? (
                    <>
                      <p className="no-margin">Shipped</p>
                      <p className="light-gray no-margin x-small">
                        {moment(shippedDate).format('MMMM DD, YYYY')}
                      </p>
                    </>
                  ) : (
                    <p>---</p>
                  )}
                </Table.Cell>
                <Table.Cell width={2}>
                  <div
                    style={{
                      display: 'grid',
                      justifyContent: 'center',
                      rowGap: '8px',
                    }}
                  >
                    {o.project ? (
                      <>
                        <ButtonTooltip
                          color="dark"
                          fontAwesomeIcon="edit"
                          kind="solid"
                          onClick={() => setOrderToEdit(o)}
                          style={{ marginBottom: '8px' }}
                          tooltipProps={{
                            label: 'Edit Sample Order',
                            ...COMMON_TOOLTIP_PROPS,
                          }}
                        />
                        <ButtonTooltip
                          color="blue"
                          fontAwesomeIcon="arrow-up-right-from-square"
                          kind="solid"
                          onClick={() =>
                            window.open(
                              `/admin/projects/${o.project?.id}/sample-orders/${o.id}`,
                            )
                          }
                          style={{ marginBottom: '8px' }}
                          tooltipProps={{
                            label: 'Open in project',
                            ...COMMON_TOOLTIP_PROPS,
                          }}
                        />
                      </>
                    ) : null}
                    {!!o.project || !o.prospect ? null : (
                      <ButtonTooltip
                        color="blue"
                        fontAwesomeIcon="arrow-up-right-from-square"
                        kind="solid"
                        onClick={() =>
                          window.open(`/admin/prospects/${o.prospect?.id}`)
                        }
                        style={{ marginBottom: '8px' }}
                        tooltipProps={{
                          label: 'Open in prospect',
                          ...COMMON_TOOLTIP_PROPS,
                        }}
                      />
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        <SamplesModal
          onClose={() => setOrderToEdit(undefined)}
          open={!!orderToEdit}
          orderId={orderToEdit?.id ?? ''}
          refetch={refetch}
        />
      </Table.Body>
    </Table>
  )
}

export default OrderSamplesTable
