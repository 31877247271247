import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_ISSUE,
  CreateIssuePayload,
  CreateIssueVariables,
  UPDATE_ISSUE,
  UpdateIssuePayload,
  UpdateIssueVariables,
} from 'queries/issue'

import { IssueContext } from './provider'

const useIssueMutate = () => {
  const { refetch } = useContext(IssueContext)

  const [createIssueAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateIssuePayload,
    CreateIssueVariables
  >(CREATE_ISSUE, {
    onCompleted: refetch,
  })

  const [updateIssueAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateIssuePayload,
    UpdateIssueVariables
  >(UPDATE_ISSUE, {
    onCompleted: refetch,
  })

  return {
    createIssueAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateIssueAndRefetch,
  }
}

export default useIssueMutate
