import React, { useState } from 'react'

import { Form, Image } from 'semantic-ui-react'

import { getFileImages } from 'context/project-file/utils'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { ProjectFile } from 'types/project-file'
import * as viewUtils from 'views/utils'

const CustomElementsModal = ({
  conceptImages,
  elementType,
  onSave,
  showLinkToSpec,
}: {
  conceptImages: ProjectFile[]
  elementType: string
  onSave: (
    customName: string,
    file: ProjectFile,
    linkToProductSpec?: string,
  ) => void
  showLinkToSpec?: boolean
}) => {
  const [showModal, toggleModal] = useState<boolean>(false)
  const [state, setState] = useState<{
    fileId: string
    linkToProductSpec?: string
    name: string
  }>({
    fileId: '',
    linkToProductSpec: '',
    name: '',
  })

  const onClose = () => {
    setState({
      name: '',
      fileId: '',
    })
    toggleModal(false)
  }

  return (
    <Modal
      onClose={onClose}
      open={showModal}
      size="small"
      trigger={
        <Button
          color="gray"
          fontAwesomeIcon="file-import"
          kind="ghost"
          onClick={() => toggleModal(true)}
          text="Add Custom"
        />
      }
      title={`Add Custom ${elementType}`}
      saveButton={{
        disabled: !state.name || !state.fileId,
        onClick: () => {
          onSave(
            state.name,
            conceptImages.find((c) => c.id === state.fileId) as ProjectFile,
            state.linkToProductSpec,
          )
          onClose()
        },
      }}
    >
      <p>Please add a name that you want the client to see!</p>
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Input
          label="Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({
              ...state,
              name: e.target.value,
            })
          }}
          required
          value={state.name}
        />
        <Form.Dropdown
          clearable
          label="Image"
          onChange={(_, { value }) => {
            setState({
              ...state,
              fileId: value as string,
            })
          }}
          options={conceptImages.map((f: ProjectFile) => ({
            key: f.id,
            content: (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <Image
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    getFileImages(f.key, f.numPages)[0],
                  )}`}
                  size="small"
                />
                <p>{viewUtils.responsiveText(f.name, 19)}</p>
              </div>
            ),
            text: f.name,
            value: f.id,
          }))}
          placeholder="Choose an image"
          required
          selection
          value={state.fileId}
        />
        {showLinkToSpec && (
          <Form.Input
            label="Link to Spec"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setState({
                ...state,
                linkToProductSpec: e.target.value,
              })
            }}
            value={state.linkToProductSpec}
          />
        )}
      </StyledForm>
    </Modal>
  )
}

export default CustomElementsModal
