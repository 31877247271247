// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { concat as concatPath, escape_ } from './path'
import { op, isMap, isIndexed } from './utils'

const Immutable = require('immutable')

const lcs = require('./lcs')

function mapDiff(a: any, b: any, p?: any) {
  let ops: any[] = []
  const path = p || ''

  if (Immutable.is(a, b) || (a == b) == null) {
    return ops
  }

  const areLists = isIndexed(a) && isIndexed(b)
  let lastKey: any = null
  let removeKey: any = null

  if (a.forEach) {
    a.forEach(function (aValue: any, aKey: any) {
      if (b.has(aKey)) {
        if (isMap(aValue) && isMap(b.get(aKey))) {
          ops = ops.concat(
            mapDiff(aValue, b.get(aKey), concatPath(path, escape_(aKey))),
          )
        } else if (isIndexed(b.get(aKey)) && isIndexed(aValue)) {
          ops = ops.concat(
            sequenceDiff(aValue, b.get(aKey), concatPath(path, escape_(aKey))),
          )
        } else {
          const bValue = b.get ? b.get(aKey) : b
          const areDifferentValues = aValue !== bValue
          if (areDifferentValues) {
            ops.push(op('replace', concatPath(path, escape_(aKey)), bValue))
          }
        }
      } else {
        if (areLists) {
          removeKey = lastKey != null && lastKey + 1 === aKey ? removeKey : aKey
          ops.push(op('remove', concatPath(path, escape_(removeKey))))
          lastKey = aKey
        } else {
          ops.push(op('remove', concatPath(path, escape_(aKey))))
        }
      }
    })
  }

  b.forEach(function (bValue: any, bKey: any) {
    if (a.has && !a.has(bKey)) {
      ops.push(op('add', concatPath(path, escape_(bKey)), bValue))
    }
  })

  return ops
}

function sequenceDiff(a: any, b: any, p?: any) {
  let ops: any[] = []
  const path = p || ''
  if (Immutable.is(a, b) || (a == b) == null) {
    return ops
  }
  if ((a.count() + 1) * (b.count() + 1) >= 10000) {
    return mapDiff(a, b, p)
  }

  const lcsDiff = lcs.diff(a, b)

  let pathIndex = 0

  lcsDiff.forEach(function (diff: any) {
    if (diff.op === '=') {
      pathIndex++
    } else if (diff.op === '!=') {
      if (isMap(diff.val) && isMap(diff.newVal)) {
        const mapDiffs = mapDiff(
          diff.val,
          diff.newVal,
          concatPath(path, pathIndex),
        )
        ops = ops.concat(mapDiffs)
      } else {
        ops.push(op('replace', concatPath(path, pathIndex), diff.newVal))
      }
      pathIndex++
    } else if (diff.op === '+') {
      ops.push(op('add', concatPath(path, pathIndex), diff.val))
      pathIndex++
    } else if (diff.op === '-') {
      ops.push(op('remove', concatPath(path, pathIndex)))
    }
  })

  return ops
}

function primitiveTypeDiff(a: any, b: any, p?: any) {
  const path = p || ''
  if (a === b) {
    return []
  } else {
    return [op('replace', concatPath(path, ''), b)]
  }
}

function diff(a: any, b: any, p?: any) {
  if (Immutable.is(a, b)) {
    return Immutable.List()
  }
  if (a != b && (a == null || b == null)) {
    return Immutable.fromJS([op('replace', '/', b)])
  }
  if (isIndexed(a) && isIndexed(b)) {
    return Immutable.fromJS(sequenceDiff(a, b))
  } else if (isMap(a) && isMap(b)) {
    return Immutable.fromJS(mapDiff(a, b))
  } else {
    return Immutable.fromJS(primitiveTypeDiff(a, b, p))
  }
}

export default diff
