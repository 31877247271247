import React from 'react'

import { Helmet } from 'react-helmet'

import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import { RoomGraphQL } from 'types/room'

interface PageTitleProps {
  subTitle?: string
  title: string
}

const PageTitle = ({ subTitle, title }: PageTitleProps) => {
  return (
    <Helmet>
      <title>
        {title}
        {subTitle ? ` - ${subTitle}` : ''}
      </title>
    </Helmet>
  )
}

interface ProjectPageTitleProps {
  viewName: string
}

export const ProjectPageTitle = ({ viewName }: ProjectPageTitleProps) => {
  const { project } = useProject()
  const primaryOwner = project?.owners?.length
    ? parseOwners(project).primaryOwner
    : null
  const ownerName =
    primaryOwner?.firstName &&
    `${primaryOwner?.firstName} ${primaryOwner?.lastName?.[0] || ''}`
  return (
    <PageTitle
      title={ownerName || primaryOwner?.email || ''}
      subTitle={viewName}
    />
  )
}

interface RoomPageTitleProps {
  room?: RoomGraphQL
  viewName: string
}

export const RoomPageTitle = ({ room, viewName }: RoomPageTitleProps) => {
  return <ProjectPageTitle viewName={`${room?.type} - ${viewName}`} />
}

export default PageTitle
