import React from 'react'

import ShipmentProvider from 'context/shipment/provider'
import { Modal } from 'design-system/components/modal'
import { SupplierOrder } from 'types/supplier-order'

import ShipmentTab from './tab'

interface ShipmentModalProps {
  onClose?: () => void
  open?: boolean
  onComplete: () => void
  supplierOrdersInitial?: SupplierOrder[]
}

const ShipmentModal = ({
  open,
  onClose,
  onComplete,
  supplierOrdersInitial,
}: ShipmentModalProps) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      size="fullscreen"
      title="Shipment"
      customActions={null}
    >
      <ShipmentTab
        onComplete={async () => {
          onComplete?.()
          onClose?.()
        }}
        supplierOrdersInitial={supplierOrdersInitial ?? []}
      />
    </Modal>
  )
}

interface ShipmentModalIdProps {
  shipmentId: string
  onClose?: () => void
  open?: boolean
  refetch?: () => void
  supplierOrdersInitial?: SupplierOrder[]
}

const ShipmentModalId = ({
  open,
  onClose,
  refetch,
  shipmentId,
  supplierOrdersInitial,
}: ShipmentModalIdProps) => {
  return (
    <ShipmentProvider shipment_id={shipmentId} noLoader>
      <ShipmentModal
        onClose={onClose}
        open={open}
        onComplete={() => refetch?.()}
        supplierOrdersInitial={supplierOrdersInitial}
      />
    </ShipmentProvider>
  )
}

export default ShipmentModalId
