import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import useProject from 'context/project/use'
import useGuideUrls from 'context/project/use-guide-urls'
import { SecondaryCard } from 'design-system/components/cards'

const Guides = (_: RouteComponentProps<{ project_id: string }>) => {
  const { ordersCabinetryPublished, isTradeProject } = useProject()

  const guideUrls = useGuideUrls(true)

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Guides" />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <h5>Interactive Guides</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched className="row-gap">
          {isTradeProject ? (
            <>
              <Grid.Column computer={5} tablet={6} mobile={16}>
                <SecondaryCard
                  disabled={!guideUrls.ProjectConsultation}
                  image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/project-consultation/trade-project-consultation.webp`}
                  onClick={() => window.open(guideUrls.ProjectConsultation)}
                  title="Project consultation"
                />
              </Grid.Column>
              <Grid.Column computer={5} tablet={6} mobile={16}>
                <SecondaryCard
                  disabled={!guideUrls.ProjectProposal}
                  image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/project-proposal/trade-project-proposal.webp`}
                  onClick={() => window.open(guideUrls.ProjectProposal)}
                  title="Project proposal"
                />
              </Grid.Column>
            </>
          ) : (
            <Grid.Column computer={5} tablet={6} mobile={16}>
              <SecondaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-call/kickoff-guide.webp`}
                onClick={() => window.open(guideUrls.DesignCall)}
                title="Initial call"
              />
            </Grid.Column>
          )}
          <Grid.Column computer={5} tablet={6} mobile={16}>
            <SecondaryCard
              disabled={!guideUrls.DesignPresentation}
              image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-presentation/design-presentation.webp`}
              onClick={() => window.open(guideUrls.DesignPresentation)}
              title="Design presentation"
            />
          </Grid.Column>
          <Grid.Column computer={5} tablet={6} mobile={16}>
            <SecondaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-review/design-review.webp`}
              onClick={() => window.open(guideUrls.DesignReview)}
              title="Design review"
            />
          </Grid.Column>
          <Grid.Column computer={5} tablet={6} mobile={16}>
            <SecondaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/extra-room/extra-room.webp`}
              onClick={() => window.open(guideUrls.ExtraRoom)}
              title="Extra room"
            />
          </Grid.Column>
          {ordersCabinetryPublished.map((o) => (
            <Grid.Column computer={5} tablet={6} mobile={16}>
              <SecondaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/handover/handover.webp`}
                onClick={() => window.open(guideUrls.getHandover(o?.id ?? ''))}
                title="Handover call"
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </ProjectLayout>
  )
}

export default Guides
