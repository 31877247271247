import React from 'react'

import { RouteComponentProps } from '@reach/router'

import SupplierOrderProvider from 'context/supplier-order/provider'

interface SupplierOrderIndexProps
  extends RouteComponentProps<{
    project_id: string
    supplier_order_id: string
  }> {
  children?: React.ReactNode
}

export default (props: SupplierOrderIndexProps) => (
  <SupplierOrderProvider supplier_order_id={props.supplier_order_id}>
    {props.children}
  </SupplierOrderProvider>
)
