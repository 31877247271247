import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Rooms from 'components/admin/room/index-many'
import useTechDesigner from 'context/dashboard/tech-designer/use'
import RoomManyProvider from 'context/room/provider-many'

import TechDesignerLayout from './layout'

const TechDesignerRoomsAwaitingPack = (_: RouteComponentProps) => {
  const { userId, userName } = useTechDesigner()

  return (
    <TechDesignerLayout>
      <RoomManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          packStatus: 'awaitingPack',
          take: 30,
        }}
        skipLoader
      >
        <Rooms />
      </RoomManyProvider>
    </TechDesignerLayout>
  )
}

export default TechDesignerRoomsAwaitingPack
