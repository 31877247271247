// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import * as BABYLON from 'babylonjs'
import { sortBy } from 'lodash'
import { Menu } from 'semantic-ui-react'

import { notifySuccess } from 'context/notifications/trigger'
import RoomProvider from 'context/planner/room-provider'
import useRoom from 'context/planner/room-use'
import { RenderMode, ViewMode } from 'context/planner/types'
import * as compose from 'planner/compose'
import { HotspotBase } from 'planner/objects/annotations/hotspot'
import { SectionViewBase } from 'planner/objects/annotations/section-view'
import {
  NodeBase,
  NodeOption,
  NodeParameter,
  NodeScene,
} from 'planner/objects/node'
import * as NodeFactory from 'planner/objects/node-factory'
import { RoomElementBase } from 'planner/objects/room-element'
import * as RoomElementFactory from 'planner/objects/room-element-factory'
import { WallBase } from 'planner/objects/room-elements/wall'
import RoomCreatePanel from 'planner/ui/controls/create-menus/room-elements'
import NodeOptionPanel from 'planner/ui/controls/node-option-panel'
import PlannerLayout from 'planner/ui/layout'
import PlannerPlan from 'planner/ui/planner-plan'
import PlannerScene from 'planner/ui/planner-scene'
import * as geometryUtils from 'planner/utils/geometry-utils'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import * as nodeUtils from 'planner/utils/node-utils'
import * as plannerUtils from 'planner/utils/planner-utils'
import {
  CREATE_DESIGN,
  CreateDesignPayload,
  CreateDesignVariables,
} from 'queries/design'
import { PlannerColumn, StyledMenu, StyledSegment } from 'styles/planner/main'
import { Icons } from 'styles/planner/system'
import { RoomElementGraphQLCreateInput } from 'types/room-element'
import { RoomElementClass } from 'types/room-element-class'

const sceneCache: NodeScene[] = []

type PlannerProps = RouteComponentProps<{ room_id: string }>

const Planner = (props: PlannerProps) => {
  const [loader, setLoader] = useState<{
    show: boolean
    style: boolean
  }>({
    show: false,
    style: false,
  })
  const {
    activeCamera,
    activeHotspot,
    activeSectionView,
    cameraPosition,
    cameraTarget,
    defaultArcRotateCamera,
    defaultTargetCamera,
    defaultUniversalCamera,
    designs,
    dispatch,
    drawMode,
    floorRoomElementClass,
    measurementUnit,
    moveEnabled,
    moveFrom,
    recenterCameraTriggeredAt,
    recenterPlanTriggeredAt,
    refetchDesigns,
    renderMode,
    roomLoaded,
    room,
    sceneRelationshipGraph,
    sceneState,
    sectionViewMode,
    selectedNode,
    updateRoom,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
    viewMode,
    wallRoomElementClass,
  } = useRoom()

  const [createDesign] = useMutation<
    CreateDesignPayload,
    CreateDesignVariables
  >(CREATE_DESIGN, {
    variables: {
      data: {
        name: 'Test Planner Design',
        room: {
          connect: {
            id: props.room_id,
          },
        },
      },
    },
    onCompleted: () => refetchDesigns(),
  })

  const onKeydownFunc = useCallback(
    (event: KeyboardEvent) => {
      // ESC key
      if (event.keyCode === 27 && selectedNode) {
        dispatch({
          type: 'delete_selected_node',
          floorRoomElementClass,
        })
      }
      // N key
      if (
        event.keyCode === 78 &&
        (selectedNode.isWindow() || selectedNode.isDoor()) &&
        viewMode === ViewMode.PLAN
      ) {
        const [, after] = compose.getWallsBeforeAndAfter(
          selectedNode.parent as WallBase,
          sceneRelationshipGraph,
        )
        selectedNode.setParent(after)
        const {
          nodesToAdd,
          nodesToRemove,
          newNode: newSelected,
        } = NodeFactory.copyWithParentOrChildren(selectedNode, {
          changedParameters: [{ key: 'offsetLateral', value: 700 }],
        })
        dispatch({
          type: 'change_nodes',
          floorRoomElementClass,
          moveEnabled,
          nodesToAdd,
          nodesToRemove,
          selectedNode: newSelected,
        })
      }
      // \ key
      if (event.keyCode === 220 && viewMode === ViewMode.SCENE) {
        dispatch({
          type: 'set_view_scene',
          camera: activeCamera.isUniversalCamera()
            ? defaultArcRotateCamera
            : defaultUniversalCamera,
        })
      }
      // \ key
      if (event.keyCode === 220 && viewMode === ViewMode.SECTION) {
        dispatch({
          type: 'set_view_section',
          camera: activeCamera.isUniversalCamera()
            ? defaultTargetCamera
            : defaultUniversalCamera,
        })
      }
    },
    [activeCamera, sceneState, selectedNode, viewMode],
  )

  useEffect(() => {
    document.body.style['overflow-y' as any] = 'hidden'
    return () => {
      document.body.style['overflow-y' as any] = 'auto'
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', onKeydownFunc, false)
    return () => {
      document.removeEventListener('keydown', onKeydownFunc, false)
    }
  }, [activeCamera, sceneState, selectedNode, viewMode])

  const recenter = (params?: { plan: boolean; camera: boolean }) => {
    const {
      cameraPosition,
      cameraTarget,
      viewBoxBase,
      viewBoxPanX,
      viewBoxPanY,
    } = plannerUtils.recenterScene(sceneState)
    if (cameraPosition)
      dispatch({
        type: 'set_view_parameters',
        cameraPosition,
        cameraTarget,
        recenterCameraTriggeredAt: params.camera ? new Date() : undefined,
        recenterPlanTriggeredAt: params.plan ? new Date() : undefined,
        viewBoxBase,
        viewBoxPanX,
        viewBoxPanY,
      })
  }

  useEffect(() => {
    recenter({
      camera: true,
      plan: true,
    })
  }, [roomLoaded])

  useEffect(() => {
    if (!roomLoaded) {
      dispatch({
        type: 'load_room',
        floorRoomElementClass,
        nodesToAdd: room.elements?.length
          ? RoomElementFactory.roomFromGraphQL(room)
          : [],
      })
    }
  })

  useEffect(() => {
    if (viewMode !== ViewMode.PLAN) {
      const timer1 = setTimeout(() => {
        setLoader({
          show: true,
          style: false,
        })
      }, 1600)
      const timer2 = setTimeout(() => {
        setLoader({
          show: false,
          style: false,
        })
      }, 2000)

      return () => {
        clearTimeout(timer1)
        clearTimeout(timer2)
      }
    }
  }, [viewMode])

  const nullFunc = (_: BABYLON.Scene) => {}

  const onCreateRoomElement = (roomElementClass: RoomElementClass): void => {
    const nodeToAdd = RoomElementFactory.createRoomElement({
      roomElementClass,
    })
    if (nodeToAdd.hasParentWall()) {
      const [parentWall] = sortBy(
        sceneState.filter((n) => n.isWall()),
        (w) => w.children.length,
      )
      nodeToAdd.setParent(parentWall)
    }
    dispatch({
      type: 'change_nodes',
      floorRoomElementClass,
      nodesToAdd: [nodeToAdd],
      nodesToRemove: [],
      selectedNode: nodeToAdd,
    })
  }

  const onDelete = () => {
    dispatch({
      type: 'delete_selected_node',
    })
  }

  const onOptionChange = (changedOptions: NodeOption[]): void => {
    if (selectedNode.isRoomElement()) {
      const selectedElement = selectedNode as RoomElementBase
      const { nodesToAdd, nodesToRemove, newNode } =
        NodeFactory.copyWithParentOrChildren(
          selectedElement,
          {
            changedOptions,
          },
          sceneRelationshipGraph,
        )
      dispatch({
        type: 'change_nodes',
        floorRoomElementClass,
        nodesToAdd,
        nodesToRemove,
        selectedNode: newNode,
      })
      return
    }
    console.log(
      `onOptionChange does not support nodes of type ${selectedNode.getType()}`,
    )
  }

  const onParameterChange = (changedParameter: NodeParameter): void => {
    if (changedParameter.key === 'length') {
      const changed = compose.redrawRoom(
        selectedNode as WallBase,
        changedParameter.value,
        sceneRelationshipGraph,
      )
      let newSelected: NodeBase = null
      const nodesToAdd: NodeBase[] = []
      const nodesToRemove: NodeBase[] = []
      for (const e of changed) {
        const {
          nodesToAdd: nodesToAdd_,
          nodesToRemove: nodesToRemove_,
          newNode,
        } = NodeFactory.copyWithParentOrChildren(e.wall, {
          changedParameters: e.changedParameters,
        })
        newSelected = newSelected || newNode
        nodesToAdd.push(...nodesToAdd_)
        nodesToRemove.push(...nodesToRemove_)
      }
      dispatch({
        type: 'change_nodes',
        floorRoomElementClass,
        nodesToAdd,
        nodesToRemove,
        selectedNode: newSelected,
      })
      return
    }
    const { nodesToAdd, nodesToRemove, newNode } =
      NodeFactory.copyWithParentOrChildren(selectedNode, {
        changedParameters: [changedParameter],
      })
    dispatch({
      type: 'change_nodes',
      floorRoomElementClass,
      nodesToAdd,
      nodesToRemove,
      selectedNode: newNode,
    })
  }

  const onMouseDown = (
    _: React.MouseEvent,
    n: NodeBase,
    pointR?: [number, number, number],
  ) => {
    if (!drawMode) {
      if (!n) {
        dispatch({
          type: 'deselect_node',
        })
      } else if (n.isSectionView()) {
        dispatch({
          type: 'set_active_section_view',
          activeSectionView: n as SectionViewBase,
        })
      } else {
        dispatch({
          type: 'pick_node',
          nodeName: n.getNodeName(),
        })
      }
    }
    if (drawMode) {
      const [xR, , z] = pointR
      const x = -xR
      let adjustedX = activeHotspot ? activeHotspot.dynamicParameters.x : x
      let adjustedZ = activeHotspot ? activeHotspot.dynamicParameters.z : z
      if (!activeHotspot) {
        for (const hotspot of sceneState.filter((n) => n.isHotspot())) {
          const xd = Math.abs(x - hotspot.dynamicParameters.x)
          const zd = Math.abs(z - hotspot.dynamicParameters.z)
          if (xd < 100) adjustedX = hotspot.dynamicParameters.x
          if (zd < 100) adjustedZ = hotspot.dynamicParameters.z
        }
      }
      // if there's a selectedNode, the wall is being drawn
      if (selectedNode) {
        const endingWall = selectedNode as WallBase
        const [, after] = compose.getWallsBeforeAndAfter(
          endingWall,
          sceneRelationshipGraph,
        )
        if (!after) {
          const newEndingWall = RoomElementFactory.copy(endingWall, {
            changedParameters: [
              { key: 'x2', value: adjustedX },
              { key: 'z2', value: adjustedZ },
            ],
          }) as WallBase
          const newWall = new WallBase({
            dynamicParameters: {
              depth: 150,
              height: 2800,
              orientation: 0,
              x1: adjustedX,
              z1: adjustedZ,
              x2: adjustedX + 1.0,
              z2: adjustedZ + 1.0,
            },
            roomElementClass: wallRoomElementClass,
          })
          dispatch({
            type: 'change_nodes',
            floorRoomElementClass,
            moveEnabled: false,
            nodesToAdd: [newEndingWall, newWall],
            nodesToRemove: [endingWall],
            selectedNode: newWall,
            wallBeingDrawn: newWall,
          })
        } else {
          dispatch({
            type: 'change_nodes',
            floorRoomElementClass,
            moveEnabled: false,
            nodesToAdd: [],
            nodesToRemove: [],
            selectedNode: null,
            wallBeingDrawn: null,
          })
        }
        return
      }
      // if not selected just create a new wall
      const newWall = new WallBase({
        dynamicParameters: {
          depth: 150,
          height: 2800,
          orientation: 0,
          x1: adjustedX,
          z1: adjustedZ,
          x2: adjustedX + 1.0,
          z2: adjustedZ + 1.0,
        },
        roomElementClass: wallRoomElementClass,
      })
      dispatch({
        type: 'change_nodes',
        floorRoomElementClass,
        moveEnabled: false,
        nodesToAdd: [newWall],
        nodesToRemove: [],
        selectedNode: newWall,
        wallBeingDrawn: newWall,
      })
    }
  }

  const onMouseEnter = (_: React.MouseEvent, n: NodeBase) => {
    if (n.isHotspot())
      dispatch({
        type: 'activate_hotspot',
        hotspot: n as HotspotBase,
      })
  }

  const onMouseLeave = (_: React.MouseEvent, n: NodeBase) => {
    if (n.isHotspot())
      dispatch({
        type: 'deactivate_hotspot',
        hotspot: n as HotspotBase,
      })
  }

  const onMouseMove = (
    _: React.MouseEvent,
    [xR, _1, z]: [number, number, number],
  ) => {
    const x = -xR
    if (!drawMode) {
      if (moveEnabled) {
        if (selectedNode.isWall()) {
          const wall = selectedNode as WallBase
          const [before, after] = compose.getWallsBeforeAndAfter(
            wall,
            sceneRelationshipGraph,
          )
          const { y: rot } = geometryUtils.getRotationBetweenTwoVectors(
            wall.getForward(),
            new BABYLON.Vector3(1, 0, 0),
          )
          if (geometryUtils.isHorizontal(rot)) {
            const {
              nodesToAdd: selectedNodesToAdd,
              nodesToRemove: selectedNodesToRemove,
              newNode: newSelected,
            } = NodeFactory.copyWithParentOrChildren(
              wall,
              {
                changedParameters: [
                  { key: 'z1', value: z },
                  { key: 'z2', value: z },
                ],
              },
              sceneRelationshipGraph,
            )
            const {
              nodesToAdd: beforeNodesToAdd,
              nodesToRemove: beforeNodesToRemove,
            } = before
              ? NodeFactory.copyWithParentOrChildren(
                  before,
                  {
                    changedParameters: [{ key: 'z2', value: z }],
                  },
                  sceneRelationshipGraph,
                )
              : {
                  nodesToAdd: [] as NodeBase[],
                  nodesToRemove: [] as NodeBase[],
                }

            const {
              nodesToAdd: afterNodesToAdd,
              nodesToRemove: afterNodesToRemove,
            } = after
              ? NodeFactory.copyWithParentOrChildren(after, {
                  changedParameters: [{ key: 'z1', value: z }],
                })
              : {
                  nodesToAdd: [] as NodeBase[],
                  nodesToRemove: [] as NodeBase[],
                }
            dispatch({
              type: 'change_nodes',
              floorRoomElementClass,
              moveEnabled: moveEnabled,
              nodesToAdd: [
                ...selectedNodesToAdd,
                ...beforeNodesToAdd,
                ...afterNodesToAdd,
              ],
              nodesToRemove: [
                ...selectedNodesToRemove,
                ...beforeNodesToRemove,
                ...afterNodesToRemove,
              ],
              selectedNode: newSelected,
            })
          } else {
            const {
              nodesToAdd: selectedNodesToAdd,
              nodesToRemove: selectedNodesToRemove,
              newNode: newSelected,
            } = NodeFactory.copyWithParentOrChildren(wall, {
              changedParameters: [
                { key: 'x1', value: x },
                { key: 'x2', value: x },
              ],
            })
            const {
              nodesToAdd: beforeNodesToAdd,
              nodesToRemove: beforeNodesToRemove,
            } = before
              ? NodeFactory.copyWithParentOrChildren(before, {
                  changedParameters: [{ key: 'x2', value: x }],
                })
              : {
                  nodesToAdd: [] as NodeBase[],
                  nodesToRemove: [] as NodeBase[],
                }
            const {
              nodesToAdd: afterNodesToAdd,
              nodesToRemove: afterNodesToRemove,
            } = after
              ? NodeFactory.copyWithParentOrChildren(after, {
                  changedParameters: [{ key: 'x1', value: x }],
                })
              : {
                  nodesToAdd: [] as NodeBase[],
                  nodesToRemove: [] as NodeBase[],
                }
            dispatch({
              type: 'change_nodes',
              floorRoomElementClass,
              moveEnabled: moveEnabled,
              nodesToAdd: [
                ...selectedNodesToAdd,
                ...beforeNodesToAdd,
                ...afterNodesToAdd,
              ],
              nodesToRemove: [
                ...selectedNodesToRemove,
                ...beforeNodesToRemove,
                ...afterNodesToRemove,
              ],
              selectedNode: newSelected,
            })
          }
        }
        if (selectedNode.isWindow() || selectedNode.isDoor()) {
          let [startOffset, startMouse] = moveFrom || [null, null]
          if (startOffset === null || startMouse === null) {
            startOffset = selectedNode.dynamicParameters.offsetLateral
            startMouse = [x, z]
          }
          const wall = selectedNode.parent as WallBase
          const startMousePos = new BABYLON.Vector3(
            startMouse[0],
            0,
            startMouse[1],
          )
          const mousePos = new BABYLON.Vector3(x, 0, z)
          const distanceToWall =
            BABYLON.Plane.SignedDistanceToPlaneFromPositionAndNormal(
              wall.getPointOne(),
              wall.getForward(),
              mousePos,
            )
          const mouseProjected = mousePos.add(
            wall.getForward().scale(distanceToWall),
          )
          const offsetChange = BABYLON.Vector3.Distance(
            startMousePos,
            mouseProjected,
          )
          const [, insidePointTwo, insidePointOne] = wall.getObject(
            sceneRelationshipGraph,
          ).shape
          const distanceStart = BABYLON.Vector3.Distance(
            startMousePos,
            insidePointTwo,
          )
          const distanceEnd = BABYLON.Vector3.Distance(
            mouseProjected,
            insidePointTwo,
          )
          const positive = distanceEnd > distanceStart
          let newOffset = positive
            ? startOffset + offsetChange
            : startOffset - offsetChange
          const wallLength = BABYLON.Vector3.Distance(
            insidePointTwo,
            insidePointOne,
          )
          const maxOffset =
            wallLength - (selectedNode as RoomElementBase).getWidth()
          if (newOffset < 0) newOffset = 0
          if (newOffset > maxOffset) newOffset = maxOffset
          const {
            nodesToAdd,
            nodesToRemove,
            newNode: newSelected,
          } = NodeFactory.copyWithParentOrChildren(selectedNode, {
            changedParameters: [{ key: 'offsetLateral', value: newOffset }],
          })
          dispatch({
            type: 'change_nodes',
            floorRoomElementClass,
            moveEnabled: moveEnabled,
            moveFrom: moveFrom || [startOffset, startMouse],
            nodesToAdd: nodesToAdd,
            nodesToRemove: nodesToRemove,
            selectedNode: newSelected,
          })
        }
      }
    }

    if (drawMode) {
      if (selectedNode) {
        let adjustedX = activeHotspot ? activeHotspot.dynamicParameters.x : x
        let adjustedZ = activeHotspot ? activeHotspot.dynamicParameters.z : z
        if (!activeHotspot) {
          for (const hotspot of sceneState.filter((n) => n.isHotspot())) {
            const xd = Math.abs(x - hotspot.dynamicParameters.x)
            const zd = Math.abs(z - hotspot.dynamicParameters.z)
            if (xd < 100) adjustedX = hotspot.dynamicParameters.x
            if (zd < 100) adjustedZ = hotspot.dynamicParameters.z
          }
        }
        const wall = selectedNode as WallBase
        const xd = Math.abs(x - wall.dynamicParameters.x1)
        const zd = Math.abs(z - wall.dynamicParameters.z1)
        if (!activeHotspot && zd > 100 && xd < 100)
          adjustedX = wall.dynamicParameters.x1
        if (!activeHotspot && xd > 100 && zd < 100)
          adjustedZ = wall.dynamicParameters.z1
        if (
          wall.dynamicParameters.x2 !== adjustedX ||
          wall.dynamicParameters.z2 !== adjustedZ
        ) {
          const {
            nodesToAdd,
            nodesToRemove,
            newNode: newSelected,
          } = NodeFactory.copyWithParentOrChildren(wall, {
            changedParameters: [
              { key: 'x2', value: adjustedX },
              { key: 'z2', value: adjustedZ },
            ],
          })
          dispatch({
            type: 'change_nodes',
            floorRoomElementClass,
            moveEnabled: false,
            nodesToAdd,
            nodesToRemove,
            selectedNode: newSelected,
            wallBeingDrawn: newSelected as WallBase,
          })
        }
      }
    }
  }

  const onMouseUp = (_: React.MouseEvent) => {
    if (!drawMode) {
      if (moveEnabled)
        dispatch({
          type: 'disable_move',
        })
    }
  }

  const onPointerDown = (scene: BABYLON.Scene, _: HTMLCanvasElement): void => {
    const pickResult = scene.pick(scene.pointerX, scene.pointerY, (node) => {
      const formNode = nodeUtils.findNodeByName(sceneState, node.name)
      if (!formNode) return false
      return true
    })

    if (pickResult.hit) {
      dispatch({
        type: 'pick_node',
        nodeName: pickResult.pickedMesh.name,
      })
    }

    if (!pickResult.hit) {
      dispatch({
        type: 'deselect_node',
      })
    }
  }

  const onSave = async () => {
    const res: RoomElementGraphQLCreateInput[] = []
    const oldRoomElements = room.elements
    for (const n of sceneState) {
      if (
        !nodeUtils.isSaveableRoomElement(n) ||
        (n.parent && n.parent.isWall())
      )
        continue
      let roomElement = (n as RoomElementBase).toGraphQLCreate()
        .data as RoomElementGraphQLCreateInput
      if (roomElement.childrenRoomElements?.create) {
        roomElement = {
          ...roomElement,
          childrenRoomElements: {
            create: roomElement.childrenRoomElements.create.map((c) => ({
              ...c,
              room: {
                connect: {
                  id: room.id,
                },
              },
            })),
          },
        }
      }
      res.push(roomElement)
    }
    await updateRoom({
      data: {
        elements: {
          delete: oldRoomElements.map((d) => ({ id: d.id })),
          create: res,
        },
      },
      where: {
        id: room.id,
      },
    }).then(() => {
      notifySuccess('Room saved')
    })
  }

  const renderOptionPanel = () => {
    if (!selectedNode) return <RoomCreatePanel onCreate={onCreateRoomElement} />
    const allOptions = selectedNode.getNodeOptions()
    return (
      <NodeOptionPanel
        node={selectedNode}
        nodeOptions={allOptions}
        nodeParameters={selectedNode.getNodeParameters(sceneRelationshipGraph)}
        onDelete={onDelete}
        onOptionChange={onOptionChange}
        onParameterChange={onParameterChange}
      />
    )
  }

  return (
    <PlannerLayout
      controlSetProps={{
        buttonsGroup: [
          {
            active: viewMode === ViewMode.SCENE,
            onClick: () => {
              setLoader({
                show: viewMode === ViewMode.PLAN,
                style: viewMode === ViewMode.PLAN,
              })
              dispatch({
                type: 'set_view_scene',
                camera: activeCamera.isTargetCamera()
                  ? defaultUniversalCamera
                  : activeCamera,
                renderMode: RenderMode.MATERIALS,
              })
            },
            icon: (
              <svg width="32" height="32">
                <image
                  href={
                    viewMode === ViewMode.SCENE
                      ? Icons.View3DActive
                      : Icons.View3D
                  }
                  width="32"
                  height="32"
                />
              </svg>
            ),
            key: 'Perspective 3D View',
          },
          {
            active: viewMode === ViewMode.PLAN,
            onClick: () => {
              dispatch({
                type: 'set_view_plan',
              })
            },
            icon: (
              <svg width="32" height="32">
                <image
                  href={
                    viewMode === ViewMode.PLAN
                      ? Icons.ViewTopActive
                      : Icons.ViewTop
                  }
                  width="32"
                  height="32"
                />
              </svg>
            ),
            key: 'Top View',
          },
          {
            active: viewMode === ViewMode.SECTION,
            disabled: !activeSectionView,
            onClick: () => {
              setLoader({
                show: viewMode === ViewMode.PLAN,
                style: viewMode === ViewMode.PLAN,
              })
              dispatch({
                type: 'set_view_section',
                camera: defaultTargetCamera,
                renderMode: RenderMode.MATERIALS,
              })
            },
            icon: (
              <svg width="32" height="32">
                <image
                  href={
                    viewMode === ViewMode.SECTION
                      ? Icons.ViewElevationActive
                      : Icons.ViewElevation
                  }
                  width="32"
                  height="32"
                />
              </svg>
            ),
            key: 'Elevations',
          },
        ],
        buttonsGroupTwo: [
          {
            active: false,
            onClick: () => {
              dispatch({
                type: 'toggle_measurement_unit',
                measurementUnit:
                  measurementUnit === MeasurementUnit.Millimeters
                    ? MeasurementUnit.Inches
                    : MeasurementUnit.Millimeters,
              })
            },
            icon: (
              <svg width="32" height="32">
                {/* TODO Replace icon once it is ready */}
                <image href={Icons.SectionView} width="32" height="32" />
              </svg>
            ),
            key: 'Toggle Measurement Unit',
          },
          {
            active: renderMode === RenderMode.LINES,
            onClick: () => {
              dispatch({
                type: 'toggle_materials',
              })
            },
            icon: (
              <svg width="32" height="32">
                <image
                  href={
                    renderMode === RenderMode.LINES
                      ? Icons.LineActive
                      : Icons.Line
                  }
                  width="32"
                  height="32"
                />
              </svg>
            ),
            key: 'Toggle Materials',
          },
          ...(viewMode === ViewMode.PLAN
            ? [
                {
                  active: drawMode,
                  onClick: () => {
                    dispatch({
                      type: 'toggle_draw_mode',
                    })
                  },
                  icon: (
                    <svg width="32" height="32">
                      <image
                        href={drawMode ? Icons.DrawActive : Icons.Draw}
                        width="32"
                        height="32"
                      />
                    </svg>
                  ),
                  key: 'Toggle Draw',
                },
                {
                  active: sectionViewMode,
                  onClick: () => {
                    dispatch({
                      type: 'toggle_section_view_mode',
                    })
                  },
                  icon: (
                    <svg width="32" height="32">
                      <image
                        href={
                          sectionViewMode
                            ? Icons.SectionViewActive
                            : Icons.SectionView
                        }
                        width="32"
                        height="32"
                      />
                    </svg>
                  ),
                  key: 'Toggle Section View',
                },
                {
                  active: false,
                  onClick: () => recenter({ plan: true, camera: false }),
                  icon: (
                    <svg width="32" height="32">
                      <image href={Icons.Center} width="32" height="32" />
                    </svg>
                  ),
                  key: 'Recenter',
                },
              ]
            : []),
          ...(viewMode === ViewMode.SCENE
            ? [
                {
                  active: false,
                  onClick: () => recenter({ plan: false, camera: true }),
                  icon: (
                    <svg width="32" height="32">
                      <image href={Icons.Center} width="32" height="32" />
                    </svg>
                  ),
                  key: 'Recenter',
                },
              ]
            : []),
        ],
      }}
      headerProps={{
        buttons: [
          {
            active: false,
            icon: 'plus',
            key: 'plus',
            onClick: () => createDesign,
          },
        ],
        menus: [
          {
            key: 'File',
            text: 'File',
            items: [
              {
                key: 'save',
                text: 'Save',
                onClick: onSave,
              },
            ],
          },
          {
            key: 'Designs',
            text: 'Designs',
            items: designs.map((d) => ({
              key: d.id,
              text: d.name,
              onClick: () =>
                (window.location.href = `/admin/rooms/${props.room_id}/designs/${d.id}`),
            })),
          },
        ],
      }}
      loader={loader}
      main={
        <>
          <PlannerColumn show={viewMode !== ViewMode.PLAN}>
            <PlannerScene
              activeCamera={activeCamera}
              activeSectionView={activeSectionView}
              cameraPosition={cameraPosition}
              cameraTarget={cameraTarget}
              onPointerDown={onPointerDown}
              onPointerMove={nullFunc}
              onPointerUp={nullFunc}
              recenterTriggeredAt={recenterCameraTriggeredAt}
              renderMode={renderMode}
              sceneActive={viewMode !== ViewMode.PLAN}
              sceneCache={sceneCache}
              sceneRelationshipGraph={sceneRelationshipGraph}
              sceneState={sceneState}
              selectedNode={selectedNode}
            />
          </PlannerColumn>
          <PlannerColumn show={viewMode === ViewMode.PLAN}>
            <PlannerPlan
              activeHotspot={activeHotspot}
              activeSectionView={activeSectionView}
              drawMode={drawMode}
              measurementUnit={measurementUnit}
              moveEnabled={moveEnabled}
              onMouseDown={onMouseDown}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onMouseMove={onMouseMove}
              onMouseUp={onMouseUp}
              recenterTriggeredAt={recenterPlanTriggeredAt}
              sceneRelationshipGraph={sceneRelationshipGraph}
              sceneState={sceneState}
              sectionViewMode={sectionViewMode}
              selectedNode={selectedNode}
              viewBoxBase={viewBoxBase}
              viewBoxPanX={viewBoxPanX}
              viewBoxPanY={viewBoxPanY}
            />
          </PlannerColumn>
        </>
      }
      sidebar={
        <>
          <StyledMenu attached="top" fluid widths={1}>
            <Menu.Item name="Room Elements" active={true} onClick={() => {}} />
          </StyledMenu>
          <StyledSegment>{renderOptionPanel()}</StyledSegment>
        </>
      }
    />
  )
}

export default (props: PlannerProps) => (
  <RoomProvider room_id={props.room_id}>
    <Planner {...props} />
  </RoomProvider>
)
