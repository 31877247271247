// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import { Button, Dropdown, Image, Menu } from 'semantic-ui-react'

import FormLogo from 'icons/form-logo-white.svg'
import { HeaderDropdown, StyledHeader } from 'styles/planner/main'
import { User } from 'types/user'

export interface HeaderProps {
  logout: () => void
  menus: {
    key: string
    text: string
    items: {
      key: string
      icon?: string
      text: string
      onClick: () => void
    }[]
    openOnHover?: boolean
  }[]
  buttons: {
    active: boolean
    onClick: () => void
    icon: IconName
    key: string
  }[]
  user: User
}

export default (props: HeaderProps) => {
  return (
    <StyledHeader borderless>
      <Menu.Item style={{ padding: '13px 25px' }}>
        <Link to="/admin">
          <FormLogo />
        </Link>
      </Menu.Item>
      {props.menus.map((m) => (
        <Menu.Item key={m.key}>
          <HeaderDropdown
            icon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
            item
            text={m.text}
            simple={!!m.openOnHover}
          >
            <Dropdown.Menu>
              {m.items.map((m) => (
                <Dropdown.Item key={m.key} onClick={m.onClick} text={m.text} />
              ))}
            </Dropdown.Menu>
          </HeaderDropdown>
        </Menu.Item>
      ))}
      <Menu.Menu position="right">
        <Menu.Item>
          {props.buttons.map((b) => (
            <Button
              active={b.active}
              className="blue small"
              icon={<FontAwesomeIcon icon={['fal', b.icon]} />}
              key={b.key}
              onClick={b.onClick}
              style={{ marginRight: '9px' }}
            />
          ))}
        </Menu.Item>
        <Menu.Item style={{ padding: '0' }}>
          <HeaderDropdown
            className="user"
            icon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
            item
            trigger={
              <>
                <Image
                  circular
                  avatar
                  src={props.user ? props.user.picture : null}
                />
                <span className="label-gray">
                  {props.user
                    ? `${props.user.firstName || props.user.email}`
                    : 'Unknown User'}
                </span>
              </>
            }
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={props.logout} text="Log Out" />
            </Dropdown.Menu>
          </HeaderDropdown>
        </Menu.Item>
      </Menu.Menu>
    </StyledHeader>
  )
}
