import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { sortBy } from 'lodash'
import { Comment, Divider, Transition } from 'semantic-ui-react'

import FormComment from 'components/shared/project/project-file/comment/comment'
import {
  CommentActions,
  CommentStyled,
  Replies,
  StyledCheckbox,
} from 'components/shared/project/project-file/utils'
import useAuth from 'context/auth/use'
import useDesign from 'context/design/use'
import useDesignCommentMutate from 'context/design/use-comment-mutate'
import Button from 'design-system/components/button'

import AddCommentForm from './form'

interface CommentListProps extends RouteComponentProps {
  disabled?: boolean
  isAdmin?: boolean
  isReviewer?: boolean
  onClose: () => void
  showResolvedComments?: boolean
}

const CommentList = (props: CommentListProps) => {
  const { user } = useAuth()
  const { comments, refetch } = useDesign()
  const [commentToReply, setCommentToReply] = useState<string | null>(null)
  const [closedThreads, setClosedThreads] = useState<string[]>([])
  const { deleteCommentAndRefetch, loadingDelete, updateCommentAndRefetch } =
    useDesignCommentMutate()

  const onEditComplete = async () => {
    await refetch()
  }

  return (
    <Comment.Group>
      {comments?.map((comment) => (
        <Transition
          animation="scale"
          duration={500}
          key={comment.id}
          visible={
            !(
              (!!comment.data?.doneBy ||
                !!comment.data?.internalSpecialExceptionBy) &&
              !props.showResolvedComments
            )
          }
        >
          <div>
            <CommentStyled>
              <FormComment
                comment={comment}
                commentToReply={commentToReply ?? ''}
                onEditComplete={onEditComplete}
                onDelete={async () => {
                  if (!loadingDelete) {
                    await deleteCommentAndRefetch({
                      variables: { where: { id: comment.id } },
                    })
                  }
                }}
                showActions={!comment.replies.length && !props.disabled}
              />

              {!!comment.replies.length && (
                <Replies>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: 15,
                    }}
                  >
                    <p className="no-margin small light-gray">
                      {comment.replies.length} Replies
                    </p>

                    <Button
                      kind="text"
                      text={`${
                        !closedThreads.includes(comment.id) ? 'Hide' : 'View'
                      } Thread`}
                      fontAwesomeIcon={
                        !closedThreads.includes(comment.id)
                          ? 'chevron-up'
                          : 'chevron-down'
                      }
                      color="gray"
                      style={{ fontSize: 14 }}
                      onClick={() => {
                        const isClosed = closedThreads.includes(comment.id)
                        let copy = [...closedThreads]
                        if (isClosed) {
                          copy = copy.filter((c) => c !== comment.id)
                        }

                        if (!isClosed) {
                          copy.push(comment.id)
                        }

                        setClosedThreads(copy)
                      }}
                    />
                  </div>
                  {!closedThreads.includes(comment.id)
                    ? sortBy(comment.replies, 'createdAt').map((reply, idx) => (
                        <div style={{ marginTop: 15 }} key={reply.id}>
                          <FormComment
                            comment={reply}
                            onEditComplete={onEditComplete}
                            isNested={!comment.replyOf}
                            commentToReply={commentToReply ?? ''}
                            onDelete={() => {
                              if (!loadingDelete) {
                                deleteCommentAndRefetch({
                                  variables: { where: { id: reply.id } },
                                })
                              }
                            }}
                            showActions={
                              comment.replies.length === idx + 1 &&
                              !props.disabled
                            }
                          />
                          {idx + 1 !== comment.replies.length ? (
                            <Divider />
                          ) : null}
                        </div>
                      ))
                    : null}

                  {commentToReply === comment.id && (
                    <div style={{ marginTop: 20 }}>
                      <AddCommentForm
                        onCommentCreated={() => {
                          setCommentToReply(null)
                          refetch()
                        }}
                        onCancel={() => {
                          setCommentToReply(null)
                        }}
                        comment={comment}
                        commentToReply={commentToReply}
                      />
                    </div>
                  )}
                </Replies>
              )}

              {commentToReply === comment.id && !comment.replies.length && (
                <AddCommentForm
                  onCommentCreated={() => {
                    setCommentToReply(null)
                    refetch()
                  }}
                  onCancel={() => {
                    setCommentToReply(null)
                  }}
                  comment={comment}
                  commentToReply={commentToReply}
                />
              )}
            </CommentStyled>
            {commentToReply !== comment.id && (
              <CommentActions isAdmin={!!props.isAdmin}>
                {(props.isReviewer || !!comment.data?.doneBy) && (
                  <StyledCheckbox
                    checked={!!comment.data?.doneBy}
                    disabled={!props.isReviewer}
                    label={
                      <label>
                        <p className="gray">
                          Resolved{' '}
                          {comment.data?.doneBy && ` by ${comment.data.doneBy}`}
                        </p>
                      </label>
                    }
                    onChange={async () =>
                      await updateCommentAndRefetch({
                        variables: {
                          data: {
                            data: {
                              ...comment.data,
                              doneBy: comment.data?.doneBy
                                ? null
                                : `${user.firstName} ${user.lastName}`,
                            },
                          },
                          where: { id: comment.id },
                        },
                      })
                    }
                  />
                )}
                {(props.isReviewer
                  ? !!comment.data?.internalSpecialExceptionBy
                  : !comment.designAdvisoryCode?.required) && (
                  <StyledCheckbox
                    checked={!!comment.data?.internalSpecialExceptionBy}
                    disabled={props.isReviewer}
                    label={
                      <label>
                        <p className="gray">
                          Exception acknowledged{' '}
                          {comment.data?.internalSpecialExceptionBy &&
                            ` by ${comment.data.internalSpecialExceptionBy}`}
                        </p>
                      </label>
                    }
                    onChange={async () =>
                      await updateCommentAndRefetch({
                        variables: {
                          data: {
                            data: {
                              ...comment.data,
                              internalSpecialExceptionBy: comment.data
                                ?.internalSpecialExceptionBy
                                ? null
                                : `${user.firstName} ${user.lastName}`,
                            },
                          },
                          where: { id: comment.id },
                        },
                      })
                    }
                  />
                )}
                {!props.disabled && (
                  <Button
                    color="gray"
                    fontAwesomeIcon="reply"
                    iconAnimation="left"
                    iconPosition="left"
                    kind="ghost"
                    onClick={() => setCommentToReply(comment.id)}
                    text="Reply"
                    style={{ fontSize: '14px' }}
                  />
                )}
              </CommentActions>
            )}
          </div>
        </Transition>
      ))}
    </Comment.Group>
  )
}

export default CommentList
