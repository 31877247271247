import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_GENERIC_PRODUCT_CLASS,
  FindUniqueGenericProductClassPayload,
  FindUniqueGenericProductClassVariables,
} from 'queries/generic-product-class'
import { GenericProductClass } from 'types/generic-product-class'

interface IGenericProductClassContext {
  catalog: string
  genericProductClass: GenericProductClass
  refetch: () => Promise<unknown>
}

interface GenericProductClassProps
  extends RouteComponentProps<{
    catalog: string
    generic_product_class_id: string
  }> {
  children: React.ReactNode
}

export const GenericProductClassContext =
  createContext<IGenericProductClassContext>({} as IGenericProductClassContext)

const GenericProductClassProvider = (props: GenericProductClassProps) => {
  const { data, loading, refetch } = useQuery<
    FindUniqueGenericProductClassPayload,
    FindUniqueGenericProductClassVariables
  >(FIND_UNIQUE_GENERIC_PRODUCT_CLASS, {
    variables: {
      where: { id: props.generic_product_class_id },
    },
  })

  if (loading || !data) return <Loader />

  return (
    <GenericProductClassContext.Provider
      value={{
        catalog: props.catalog ?? '',
        genericProductClass: data.genericProductClass,
        refetch,
      }}
    >
      {props.children}
    </GenericProductClassContext.Provider>
  )
}

export default GenericProductClassProvider
