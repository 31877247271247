// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import * as BABYLON from 'babylonjs'
import Measure from 'react-measure'
import styled from 'styled-components'

import * as objectUtils from '../utils/object-utils'

const StyledCanvas = styled.canvas`
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
`

interface PlannerAssetProps {
  onPointerDown?: (scene: BABYLON.Scene, canvas?: HTMLCanvasElement) => void
  onPointerMove?: (scene: BABYLON.Scene, canvas?: HTMLCanvasElement) => void
  onPointerUp?: (scene: BABYLON.Scene, canvas?: HTMLCanvasElement) => void
  sceneActive: boolean
  url?: string
}

interface PlannerAssetState {
  dimensions: {
    width: number
    height: number
  }
}

class PlannerAsset extends React.Component<
  PlannerAssetProps,
  PlannerAssetState
> {
  public canvas: HTMLCanvasElement
  public engine: BABYLON.Engine
  public scene: BABYLON.Scene

  constructor(props: PlannerAssetProps) {
    super(props)
    this.state = {
      dimensions: {
        // arbitrary initial values (resized by measure component)
        width: 1200,
        height: 800,
      },
    }
  }

  async componentDidMount() {
    // Create engine
    this.engine = new BABYLON.Engine(
      this.canvas,
      true, // antialias
      {
        preserveDrawingBuffer: true,
      }, // BABYLON.EngineOptions
      false, // adaptToDeviceRatio
    )

    // Create scene
    this.scene = new BABYLON.Scene(this.engine)
    this.scene.clearColor = new BABYLON.Color4(1, 1, 1, 1)

    window.addEventListener('resize', this.onResizeWindow)

    // Adding a light
    const light = new BABYLON.PointLight(
      '',
      new BABYLON.Vector3(20, 20, 100),
      this.scene,
    )

    // HDRI for global illumination
    const text = BABYLON.CubeTexture.CreateFromPrefilteredData(
      `${process.env.GATSBY_CDN_ROOT}/assets3d/environment/HDR_StuttgartHillside.env`,
      this.scene,
    )
    this.scene.environmentTexture = text

    // Color curves
    const curve = new BABYLON.ColorCurves()
    curve.globalHue = 600
    curve.globalDensity = 80
    curve.globalSaturation = 100
    curve.highlightsHue = 80
    curve.highlightsDensity = 80
    curve.highlightsSaturation = -80
    curve.shadowsHue = 2
    curve.shadowsDensity = 80
    curve.shadowsSaturation = 40

    // Default pipeline
    const defaultPipeline = new BABYLON.DefaultRenderingPipeline(
      'default',
      true,
      this.scene,
    )
    defaultPipeline.depthOfField.focalLength = 150
    defaultPipeline.imageProcessing.colorCurves = curve
    defaultPipeline.imageProcessing.exposure = 0.6
    defaultPipeline.imageProcessing.contrast = 0.9
    defaultPipeline.samples = 4

    const meshImported = await objectUtils.importMesh(
      this.scene,
      this.props.url,
    )

    const size = meshImported.getBoundingInfo().boundingBox.extendSizeWorld
    const maxSize = size.x + size.y + size.z
    const meshCenter = meshImported.getBoundingInfo().boundingBox.centerWorld

    // Create camera
    const camera = new BABYLON.ArcRotateCamera(
      'ArcRotateCamera',
      0,
      0,
      0,
      BABYLON.Vector3.Zero(),
      this.scene,
    )
    camera.wheelPrecision = 5
    camera.inertia = 0.7
    camera.attachControl(this.canvas, true)
    camera.setTarget(meshCenter)

    const ratio = this.engine.getAspectRatio(camera)
    const h = maxSize / (Math.cos(camera.fov) * ratio)

    // Adjust camera
    camera.setPosition(
      new BABYLON.Vector3(
        meshCenter.x,
        meshCenter.y + h,
        meshCenter.z + maxSize * 2,
      ),
    )

    // Move the light with the camera
    this.scene.registerBeforeRender(function () {
      light.position = camera.position
    })

    // Hide babylon loading
    this.engine.hideLoadingUI()

    // Run render loop
    this.engine.runRenderLoop(() => {
      this.scene.render()
    })
  }

  createCanvasRef(c: HTMLCanvasElement) {
    if (c) this.canvas = c
  }

  onResizeWindow = () => {
    if (this.engine) this.engine.resize()
  }

  render() {
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          if (this.state.dimensions.width !== contentRect.bounds.width) {
            this.setState({
              dimensions: {
                width: contentRect.bounds.width,
                height: contentRect.bounds.height,
              },
            })
          }
        }}
      >
        {({ measureRef }) => {
          return (
            <div style={{ height: '100%' }} ref={measureRef}>
              <StyledCanvas
                width={this.state.dimensions.width}
                height={this.state.dimensions.height}
                ref={this.createCanvasRef.bind(this)}
              />
            </div>
          )
        }}
      </Measure>
    )
  }
}

export default PlannerAsset
