import React, { useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'

import IssuesTable from 'components/admin/project/issue/table'
import AddressFormat from 'components/shared/address-format'
import { Chip } from 'components/shared/chip'
import { UnderlineSubtitle } from 'components/shared/utils'
import useDesignMutateNoContext from 'context/design/use-mutate-no-context'
import { isAddOnDesign } from 'context/design/utils'
import { parseOwners } from 'context/project/utils'
import {
  getRoomFormattedType,
  getRoomTypeWithDescription,
} from 'context/room/utils'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox } from 'styles/admin/main'
import { Design } from 'types/design'
import { Issue } from 'types/issue'
import { Project } from 'types/project'

import SupplierOrderForm from './supplier-order-form'

export type SupplierOrderObject = {
  applianceSpecs?: string[]
  nobiliaOrderRef: string
  notesForNobilia: string
  supplierOrderFiles?: string[]
  issues?: Issue[]
}

const SendToNobiliaModal = (props: {
  design: Design
  onClose: () => void
  open: boolean
  project?: Project
  refetch: () => void
}) => {
  const { design, onClose, open, refetch } = props
  const { loadingUpdate: loadingUpateDesign, updateDesign } =
    useDesignMutateNoContext()

  const room = design?.room
  const project = props.project || room?.project
  const designIssues = design?.issuesResolved
  const { primaryOwnersNames, primaryOwnerName } = parseOwners(project) || {}

  const [supplierOrders, setSupplierOrders] = useState<SupplierOrderObject[]>([
    {
      applianceSpecs: [],
      nobiliaOrderRef: design
        ? isAddOnDesign(design)
          ? `${primaryOwnerName} | `
          : `${primaryOwnerName} | ${getRoomFormattedType(room)} 1`
        : '',
      notesForNobilia: '',
      supplierOrderFiles: [],
    },
  ])

  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    setSupplierOrders([
      {
        applianceSpecs: [],
        nobiliaOrderRef: design
          ? isAddOnDesign(design)
            ? `${primaryOwnerName} | `
            : `${primaryOwnerName} | ${getRoomFormattedType(room)} 1`
          : '',
        notesForNobilia: '',
        supplierOrderFiles: [],
      },
    ])
    setReady(false)
  }, [design, open])

  const onSave = async () => {
    await updateDesign({
      variables: {
        data: {
          metadata: {
            ...design.metadata,
            sentToNobiliaAt: new Date(),
          },
          supplierOrders: {
            create: supplierOrders.map((so) => ({
              nobiliaOrderRef: so.nobiliaOrderRef,
              data: {
                notesForNobilia: so.notesForNobilia,
              },
              files: {
                connect: [
                  ...(so.applianceSpecs?.map((f) => ({ id: f })) ?? []),
                  ...(so.supplierOrderFiles?.map((f) => ({ id: f })) ?? []),
                ],
              },
              ...(designIssues?.length && {
                issuesResolved: {
                  connect: designIssues.map((i) => ({ id: i.id })),
                },
              }),
            })),
          },
        },
        where: {
          id: design.id,
        },
      },
    })

    refetch()
    onClose()
  }

  const onChange = (index: number, name: string, value: string | string[]) =>
    setSupplierOrders((prev) =>
      prev.map((so, i) => (i === index ? { ...so, [name]: value } : so)),
    )

  const onRemove = (idx: number) =>
    setSupplierOrders(supplierOrders.filter((_, index) => index !== idx))

  const { applianceSpecSelected, supplierOrderFileSelected } =
    supplierOrders.reduce<{
      applianceSpecSelected: string[]
      supplierOrderFileSelected: string[]
    }>(
      (acc, s) => {
        acc.applianceSpecSelected.push(...(s.applianceSpecs ?? []))
        acc.supplierOrderFileSelected.push(...(s.supplierOrderFiles ?? []))
        return acc
      },
      { applianceSpecSelected: [], supplierOrderFileSelected: [] },
    )

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="large"
      title="Send to Nobilia and Create Supplier Order"
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row columns="equal" verticalAlign="middle">
            <Grid.Column width={5}>
              <FormCheckbox
                checked={ready}
                disabled={
                  !supplierOrders.every(
                    (so) =>
                      !!so.nobiliaOrderRef && !!so.supplierOrderFiles?.length,
                  )
                }
                label="Ready To Send"
                onChange={() => setReady(!ready)}
              />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                color="blue"
                kind="solid"
                onClick={onClose}
                style={{ marginRight: '16px' }}
                text="Close"
              />
              <Button
                kind="solid"
                disabled={!ready}
                loading={loadingUpateDesign}
                onClick={onSave}
                text="Send to Nobilia and Create Supplier Order"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    >
      {design && (
        <Grid>
          {!!designIssues?.length && (
            <Grid.Row>
              <Grid.Column>
                <h5>Add-On Order</h5>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={3}>
            <Grid.Column>
              <p className="caption no-margin">Room / Design:</p>
              <div className="flex" style={{ gap: '12px' }}>
                <Chip
                  name={getRoomTypeWithDescription(room)}
                  color={room.data.tagColor}
                  opacity={0.3}
                />
                <p>{design?.name}</p>
              </div>
            </Grid.Column>
            <Grid.Column>
              <p className="caption no-margin">Customers:</p>
              <p>{primaryOwnersNames}</p>
            </Grid.Column>
            <Grid.Column>
              <p className="caption no-margin">Project Address:</p>
              <p>
                <AddressFormat address={project.projectAddress} />
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button
                fontAwesomeIcon="arrow-up-right-from-square"
                color="blue"
                kind="solid"
                onClick={() =>
                  window.open(
                    `/admin/projects/${project.id}/rooms/${room.id}/production-files`,
                  )
                }
                text="Upload Production Files"
              />
            </Grid.Column>
          </Grid.Row>
          <SupplierOrderForm
            index={0}
            applianceSpecSelected={applianceSpecSelected}
            onChange={onChange}
            room={room}
            supplierOrder={supplierOrders[0]}
            supplierOrderFileSelected={supplierOrderFileSelected}
          />
          {supplierOrders.slice(1).map((so, idx) => (
            <SupplierOrderForm
              key={idx}
              applianceSpecSelected={applianceSpecSelected}
              index={idx + 1}
              onChange={onChange}
              onRemove={(id: number) => onRemove(id)}
              room={room}
              supplierOrder={so}
              supplierOrderFileSelected={supplierOrderFileSelected}
            />
          ))}
          {designIssues?.length ? (
            <>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <UnderlineSubtitle>Issues Resolved</UnderlineSubtitle>
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                  <Button
                    fontAwesomeIcon="arrow-up-right-from-square"
                    color="blue"
                    kind="solid"
                    onClick={() =>
                      window.open(
                        `/admin/projects/${project.id}/rooms/${room.id}/designs/${design.id}`,
                      )
                    }
                    text="Go to Design"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <IssuesTable issues={designIssues} mode="readOnly" />
                </Grid.Column>
              </Grid.Row>
            </>
          ) : (
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button
                  fontAwesomeIcon="plus"
                  kind="ghost"
                  onClick={() =>
                    setSupplierOrders([
                      ...supplierOrders,
                      {
                        applianceSpecs: [],
                        nobiliaOrderRef: design
                          ? `${primaryOwnerName} | ${getRoomFormattedType(
                              room,
                            )} ${supplierOrders.length + 1}`
                          : '',
                        notesForNobilia: '',
                        supplierOrderFiles: [],
                      },
                    ])
                  }
                  text="Split Order"
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
    </Modal>
  )
}

export default SendToNobiliaModal
