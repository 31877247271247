// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Grid, Image } from 'semantic-ui-react'

import useDesign from 'context/planner/design-use'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import { DeleteButton, FormSelect } from 'styles/planner/main'
import { Colors } from 'styles/planner/system'

import NobiliaAdditionalItemsMenu from './option-menus/nobilia-additional-items'
import NodeOptionsMenu from './option-menus/node-options'
import NodeParametersMenu from './option-menus/node-parameters'
import ProductSummaryMenu from './option-menus/product-summary'
import { NodeRelationshipGraph } from '../../compose'
import { NodeOption, NodeParameter } from '../../objects/node'
import { ProductBase } from '../../objects/product'
import NobiliaProductBase from '../../objects/products/nobilia/base'
import { matchPreset, Preset } from '../../utils/preset-utils'

interface ProductOptionPanelProps {
  onAdditionalItemChange: (changedAdditionalItem: ProductBase) => void
  onDelete: () => void
  onOptionChange: (changedNodeOption: NodeOption[]) => void
  onParameterChange: (changedNodeParameter: NodeParameter) => void
  product: ProductBase
  sceneRelationshipGraph: NodeRelationshipGraph
}

const ProductOptionPanel = (props: ProductOptionPanelProps) => {
  const { catalog, presets, presetsCount } = useDesign()
  const { globalRestrictionsIndex } = useNobiliaOptions()
  const [preset, setPreset] = useState<Preset>()
  const [nodeOptions, setNodeOptions] = useState<NodeOption[]>([])

  useEffect(() => {
    const newNodeOptions = isNobilia
      ? (props.product as NobiliaProductBase).getNodeOptions(
          globalRestrictionsIndex,
        )
      : props.product.getNodeOptions()
    const newPreset = isNobilia
      ? matchPreset(presets, (props.product as NobiliaProductBase).options)
      : null
    setPreset(newPreset)
    setNodeOptions(newNodeOptions)
  }, [props.product])

  if (!props.product) return null

  const name = props.product.getName()
  const description = props.product.getDescription()
  const imageUrl = props.product.getImageURL()
  const nodeParameters = props.product.getNodeParameters(
    props.sceneRelationshipGraph,
  )
  const isNobilia = props.product.isNobiliaProduct()
  const isGeneric = props.product.isGenericProduct()
  const nobiliaProduct = isNobilia
    ? (props.product as NobiliaProductBase)
    : null

  return (
    <>
      <Grid style={{ background: Colors.gray }}>
        <Grid.Row columns={2}>
          <Grid.Column>
            <h2>{name}</h2>
            <p style={{ color: Colors.gray600 }}>
              {description} (
              <a
                style={{ color: 'inherit', textDecoration: 'underline' }}
                href={
                  isNobilia
                    ? `/admin/catalog/${catalog}/nobilia-product-classes/${props.product.productClass.id}`
                    : `/admin/catalog/${catalog}/generic-product-classes/${props.product.productClass.id}`
                }
                target="_blank"
              >
                See more
              </a>
              )
            </p>
            {presetsCount && isNobilia && (
              <FormSelect
                fluid
                icon={
                  <i>
                    <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                  </i>
                }
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  { value }: { value: string },
                ) => {
                  setPreset(presets.find((p) => p.identifier === value))
                  const newOptions = nodeOptions
                    .filter(
                      (o) => !['HINGE_SPEC', 'ORIENTATION'].includes(o.id),
                    )
                    .map((o) => ({
                      ...o,
                      value:
                        presets
                          .find((p) => p.identifier === value)
                          .options.find((p) => p.featureNo === o.id)
                          ?.optionKey || '',
                    }))
                  props.onOptionChange(newOptions)
                }}
                options={presets.map((p) => ({
                  key: p.identifier,
                  text: p.name,
                  value: p.identifier,
                }))}
                style={{ border: `1px solid ${Colors.gray300}` }}
                value={preset?.identifier}
              />
            )}
          </Grid.Column>
          <Grid.Column>
            {imageUrl && <Image src={imageUrl} size="small" />}
          </Grid.Column>
          <DeleteButton
            className="orange-hover small"
            icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
            onClick={props.onDelete}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ProductSummaryMenu product={props.product} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
      {nodeParameters.length ? (
        <NodeParametersMenu
          ps={nodeParameters}
          onParameterChange={props.onParameterChange}
        />
      ) : null}
      {!isGeneric && (
        <Divider className="full-width" style={{ margin: '11px 0' }} />
      )}
      {nodeOptions.length ? (
        <NodeOptionsMenu
          nodeOptions={nodeOptions}
          onOptionChange={props.onOptionChange}
        />
      ) : null}
      {!isGeneric && (
        <Divider className="full-width" style={{ margin: '11px 0' }} />
      )}
      {nobiliaProduct ? (
        <NobiliaAdditionalItemsMenu
          onAdditionalItemChange={props.onAdditionalItemChange}
          product={nobiliaProduct}
        />
      ) : null}
    </>
  )
}

export default ProductOptionPanel
