import moment from 'moment'

import useProject from 'context/project/use'
import { parseFiles } from 'context/project-file/utils-many'
import { OverviewChecklist, OverviewAlert, StatusMode } from 'types/utils'

import * as utils from './utils'

const useConstructInitialDesign = (): {
  checklists: OverviewChecklist[]
  alerts: OverviewAlert[]
} => {
  const {
    meetingPresentationOccurred,
    meetingPresentationScheduled,
    paymentProjectDepositPaid,
    project,
    roomPrimary: room,
  } = useProject()
  const { measurementAids } = parseFiles(room?.files)

  const {
    initialDesign,
    initialDesignPlanInternalReviewMode,
    initialDesignRendersReleased,
    initialDesignRendersUnreleased,
  } = utils.parseDesigns(room)

  return {
    checklists: [
      {
        checklistItems: [
          {
            key: 'measurements',
            url: `/admin/projects/${project.id}/rooms/${room?.id}/measurements`,
            name: 'Measurements',
            statusMode: measurementAids.length
              ? StatusMode.Confirmed
              : StatusMode.OpenForEdit,
            statusText: measurementAids.length
              ? 'Measurement Aid Uploaded'
              : 'Pending',
          },
          {
            key: 'initialDesign',
            url: initialDesign
              ? `/admin/projects/${project.id}/rooms/${room?.id}/designs/${initialDesign.id}`
              : `/admin/projects/${project.id}/rooms/${room?.id}/designs`,
            name: 'Initial Design',
            statusMode: (() => {
              if (initialDesign) {
                if (initialDesign.metadata?.publishedAt)
                  return StatusMode.Confirmed
                if (initialDesignRendersReleased.length)
                  return StatusMode.Confirmation
                if (initialDesignRendersUnreleased.length)
                  return StatusMode.Confirmation
                if (initialDesign.sentToArchAt)
                  return StatusMode.OpenForRevision
                if (initialDesign.sentToRenderingAt)
                  return StatusMode.OpenForRevision
                if (initialDesignPlanInternalReviewMode) {
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Confirmed
                  ) {
                    return StatusMode.OpenForRevision
                  }
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Submitted
                  ) {
                    return StatusMode.Submitted
                  }
                  if (
                    initialDesignPlanInternalReviewMode ===
                    StatusMode.OpenForRevision
                  ) {
                    return StatusMode.OpenForRevision
                  }
                }
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (initialDesign) {
                if (initialDesign.metadata?.publishedAt)
                  return `Published with ${initialDesignRendersReleased.length} renders`
                if (initialDesignRendersReleased.length)
                  return `${initialDesignRendersReleased.length} renders added, not published`
                if (initialDesignRendersUnreleased.length)
                  return `${initialDesignRendersUnreleased.length} renders received`
                if (initialDesign.sentToArchAt)
                  return `Sent to CGI ${moment(
                    initialDesign.sentToArchAt,
                  ).format('LL')}`
                if (initialDesign.sentToRenderingAt)
                  return `Sent to render ${moment(
                    initialDesign.sentToRenderingAt,
                  ).format('LL')}`
                if (initialDesignPlanInternalReviewMode) {
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Confirmed
                  ) {
                    return `Internal review signed off`
                  }
                  if (
                    initialDesignPlanInternalReviewMode === StatusMode.Submitted
                  ) {
                    return `${initialDesign.comments.length} internal comments submitted`
                  }
                  if (
                    initialDesignPlanInternalReviewMode ===
                    StatusMode.OpenForRevision
                  ) {
                    return 'Awaiting internal review'
                  }
                }
                return 'Design created'
              }
              return 'Pending'
            })(),
          },
          {
            key: 'designPresentation',
            url: `/admin/projects/${project.id}/meetings`,
            name: 'Design Presentation',
            statusMode: (() => {
              if (meetingPresentationOccurred) {
                return StatusMode.Confirmed
              }
              if (meetingPresentationScheduled) {
                return StatusMode.OpenForRevision
              }
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (meetingPresentationOccurred) {
                return `Completed ${moment(
                  meetingPresentationOccurred.startTime,
                ).format('LL')}`
              }
              if (meetingPresentationScheduled) {
                return `Scheduled ${moment(
                  meetingPresentationScheduled.startTime,
                ).format('LL')}`
              }
              return 'Pending'
            })(),
          },
          {
            key: 'projectDeposit',
            url: `/admin/projects/${project.id}/payments`,
            name: 'Project Deposit Paid',
            statusMode: paymentProjectDepositPaid
              ? StatusMode.Confirmed
              : StatusMode.OpenForEdit,
            statusText: paymentProjectDepositPaid ? 'Paid' : 'Pending',
          },
        ],
      },
    ],
    alerts: [],
  }
}

export default useConstructInitialDesign
