import React from 'react'

import { RouteComponentProps } from '@reach/router'

import AppliancePackProvider from 'context/appliance-pack/provider'
import GenericProductClassManyProvider from 'context/generic-product-class/provider-many'

interface AppliancePackIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    appliance_pack_id: string
  }> {
  children: React.ReactNode
}

const AppliancePackIndex = (props: AppliancePackIndexProps) => {
  return (
    <AppliancePackProvider appliance_pack_id={props.appliance_pack_id}>
      <GenericProductClassManyProvider
        defaultFilters={{
          identifierNotIn: ['backsplash', 'countertop', 'plinth'],
          sortBy: 'identifier',
          sortDirection: 'ascending',
          take: undefined,
        }}
      >
        {props.children}
      </GenericProductClassManyProvider>
    </AppliancePackProvider>
  )
}

export default AppliancePackIndex
