import React from 'react'

import { RouteComponentProps } from '@reach/router'

import FrontsBrowser from 'components/shared/catalog/nobilia-feature/fronts-browser'
import HandlesBrowser from 'components/shared/catalog/nobilia-feature/handles-browser'
import * as NO from 'planner/objects/products/nobilia/options/definitions'

const NobiliaFeatureBrowser = (
  props: RouteComponentProps<{
    catalog_id: string
    feature_no: string
  }>,
) => {
  if (props.feature_no === NO.FRONT_COMBO)
    return <FrontsBrowser catalog={props.catalog_id} hideScroll={true} />
  else if (props.feature_no === NO.HANDLE_COMBINATION)
    return <HandlesBrowser catalog={props.catalog_id} hideScroll={true} />
  else return null
}

export default NobiliaFeatureBrowser
