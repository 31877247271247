import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import Tooltip from 'components/admin/tooltip'
import useAuth from 'context/auth/use'
import useGenericOptionMutate from 'context/generic-option/use-mutate'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import { GenericOption, GenericOptionData } from 'types/generic-option'

interface GenericOptionModalProps {
  genericOption?: GenericOption
  refetch: () => Promise<unknown>
}

const GenericOptionModal = ({
  genericOption: genericOption,
  refetch,
}: GenericOptionModalProps) => {
  const { isAdmin } = useAuth()
  const [showModal, toggleModal] = useState<boolean>(false)
  const initialState = {
    displayName: genericOption?.data?.displayName || genericOption?.description,
    isDiscontinued: genericOption?.data?.isDiscontinued || false,
    showOnDesignCall: genericOption?.data?.showOnDesignCall || false,
  }
  const [state, setState] = useState<GenericOptionData>(initialState)
  useEffect(() => {
    setState(initialState)
  }, [genericOption])

  const onCloseModal = () => {
    setState(initialState)
    toggleModal(false)
  }

  const { updateGenericOption, loadingUpdate } = useGenericOptionMutate()

  const onSave = async () => {
    await updateGenericOption({
      variables: {
        data: {
          data: {
            ...state,
          },
        },
        where: { id: genericOption?.id ?? '' },
      },
      onCompleted: refetch,
    })
    toggleModal(false)
  }

  return (
    <Modal
      title="Metadata"
      onClose={() => onCloseModal()}
      onOpen={() => (!isAdmin ? null : toggleModal(true))}
      trigger={
        <Button
          disabled={!isAdmin}
          color="gray"
          fontAwesomeIcon="pencil"
          kind="ghost"
          text="Edit"
        />
      }
      open={showModal}
      size="small"
      saveButton={{
        loading: loadingUpdate,
        onClick: onSave,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <p className="caption">Product Status</p>
        <FormCheckbox
          checked={state.isDiscontinued}
          label={
            <label>
              Product Discontinued
              <Tooltip type="Product Discontinued" />
            </label>
          }
          onChange={() =>
            setState({
              ...state,
              isDiscontinued: !state.isDiscontinued,
            })
          }
        />
        <Form.Input
          label="Display Name"
          onChange={(_: unknown, { value }: { value: string }) => {
            setState({
              ...state,
              displayName: value,
            })
          }}
          value={state.displayName}
        />
        <Form.Checkbox
          checked={state.showOnDesignCall}
          label="Show on Initial Call"
          onChange={() =>
            setState({
              ...state,
              showOnDesignCall: !state.showOnDesignCall,
            })
          }
          toggle
        />
      </StyledForm>
    </Modal>
  )
}

export default GenericOptionModal
