import React, { useState } from 'react'

import { Table } from 'semantic-ui-react'

import { Badge } from 'components/shared/chip'
import useOrderCabinetryMany from 'context/order/use-many-cabinetry'
import * as orderUtils from 'context/order/utils'
import Button from 'design-system/components/button'
import { Order } from 'types/order'
import { ProjectStaffRole } from 'types/project-staff'

import OrderModal from './create-update-modal'
import OrderCellContentCommission from './table-cell-content-commission'
import OrderCellContentFulfillment from './table-cell-content-fulfillment'
import OrderCellContentPlacement from './table-cell-content-placement'
import { ProjectOwnersCellContents } from '../project/project-owners/table-cell-content'
import { ProjectStaffCellContents } from '../project/project-staff/table-cell-content'

const OrderCabinetryTable = () => {
  const { orders, refetch, queryFilters, setQueryFilters } =
    useOrderCabinetryMany()
  const { sortBy, sortDirection } = queryFilters

  const [orderToEdit, setOrderToEdit] = useState<Order>()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Project</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'placedAt' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'placedAt',
                sortDirection:
                  sortBy === 'placedAt' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Order status
          </Table.HeaderCell>
          <Table.HeaderCell>GP</Table.HeaderCell>
          <Table.HeaderCell>Delivery</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {orders.map((o: Order) => {
          const project = o.project
          const orderTypeBadge = orderUtils.getOrderTypeBadge(o)

          if (!project) {
            console.warn('No project found, skipping', o)
            return null
          }
          const { revenueFormatted } = orderUtils.calculateGrossProfit(o)
          return (
            <Table.Row key={o.id}>
              <Table.Cell width={3} textAlign="left">
                <ProjectOwnersCellContents project={o.project} linkToProject />
              </Table.Cell>
              <Table.Cell width={2}>
                <ProjectStaffCellContents
                  project={o.project}
                  roleStaff={ProjectStaffRole.SALES_DESIGNER}
                />
              </Table.Cell>
              <Table.Cell width={2}>
                <Badge
                  color={orderTypeBadge.color}
                  name={orderTypeBadge.name}
                />
                <p>
                  {orderUtils.getOrderFormattedAdditional(o) ||
                    o.metadata?.name}
                </p>
                <p className="gray">{revenueFormatted}</p>
              </Table.Cell>
              <Table.Cell width={2}>
                <OrderCellContentPlacement order={o} />
              </Table.Cell>
              <Table.Cell width={2}>
                <OrderCellContentCommission order={o} />
              </Table.Cell>
              <Table.Cell width={3}>
                <OrderCellContentFulfillment order={o} />
              </Table.Cell>
              <Table.Cell width={1}>
                <div
                  style={{
                    display: 'grid',
                    justifyContent: 'center',
                    rowGap: '8px',
                  }}
                >
                  <Button
                    color="dark"
                    kind="solid"
                    fontAwesomeIcon="edit"
                    onClick={() => setOrderToEdit(o)}
                  />
                  <Button
                    color="blue"
                    kind="solid"
                    fontAwesomeIcon="eye"
                    onClick={() =>
                      window.open(
                        `/admin/projects/${o.project?.id}/orders/${o.id}`,
                      )
                    }
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
        <OrderModal
          onClose={() => setOrderToEdit(undefined)}
          open={!!orderToEdit}
          orderId={orderToEdit?.id ?? ''}
          refetch={async () => {
            refetch()
          }}
        />
      </Table.Body>
    </Table>
  )
}

export default OrderCabinetryTable
