import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Grid, Label, SemanticWIDTHS } from 'semantic-ui-react'

import NobiliaOptionSelectionCard from 'components/shared/catalog/nobilia-option/card'
import {
  SelectionCardDeleteProps,
  SelectionCardProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { SelectionAccordion } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { NobiliaOption } from 'types/nobilia-option'
import { catalogToQuery } from 'views/utils'

import NobiliaOptionsModal from './selection-modal'

interface NobiliaOptionAccordionProps {
  catalog: string
  featureNo?: string
  gridColumns?: SemanticWIDTHS
  nobiliaOptions: Partial<NobiliaOption>[]
  onSave: (nobiliaOptions: Partial<NobiliaOption>[]) => void
  selectionCardProps?: SelectionCardProps
  title: string
}

const NobiliaOptionAccordion = ({
  catalog,
  featureNo,
  gridColumns,
  nobiliaOptions,
  onSave,
  selectionCardProps,
  title,
}: NobiliaOptionAccordionProps) => {
  const [showCards, toggleCards] = useState<boolean>(true)

  return (
    <SelectionAccordion fluid>
      <Accordion.Title className="accordion-title">
        <span className="name" onClick={() => toggleCards(!showCards)}>
          <p className="subtitle no-margin">{title}</p>
          <FontAwesomeIcon
            icon={['fal', showCards ? 'chevron-up' : 'chevron-down']}
            style={{ color: Colors.orange500 }}
          />
        </span>
        <NobiliaOptionsModal
          {...(featureNo &&
            ({
              defaultFilters: {
                catalog: catalogToQuery(catalog),
                feature: {
                  featureNo,
                },
                sortBy: 'optionKey',
                sortDirection: 'ascending',
                take: undefined,
              },
            } as any))}
          onSave={onSave}
          selectedNobiliaOptions={nobiliaOptions}
          selectionCardProps={selectionCardProps}
          title={title}
        />
      </Accordion.Title>
      <Accordion.Content active className="accordion-content">
        {showCards ? (
          <Grid>
            <Grid.Row columns={gridColumns || 3}>
              {nobiliaOptions?.map((no) => (
                <Grid.Column key={no.id}>
                  <NobiliaOptionSelectionCard
                    nobiliaOption={no}
                    selectionCardProps={
                      {
                        ...selectionCardProps,
                        onDelete: () =>
                          onSave(nobiliaOptions.filter((n) => n.id !== no.id)),
                      } as Partial<SelectionCardDeleteProps>
                    }
                    selectionCardVariation={SelectionCardVariation.DELETE}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        ) : (
          <div className="labels">
            {nobiliaOptions?.map((no) => (
              <Label key={no.id}>
                {no.optionKey} - {no.description}
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  onClick={() =>
                    onSave(nobiliaOptions.filter((n) => n.id !== no.id))
                  }
                />
              </Label>
            ))}
          </div>
        )}
      </Accordion.Content>
    </SelectionAccordion>
  )
}

export default NobiliaOptionAccordion
