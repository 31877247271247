import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NobiliaOrderProcessorProvider from 'context/dashboard/nobilia-order-processor/provider'

const NobiliaOrderProcessorIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  return (
    <NobiliaOrderProcessorProvider user_id={props.user_id ?? ''}>
      {props.children}
    </NobiliaOrderProcessorProvider>
  )
}

export default NobiliaOrderProcessorIndex
