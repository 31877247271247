import React from 'react'

import { Grid } from 'semantic-ui-react'

import useRoomElementOptionFiltersSearch from 'components/admin/catalog/room-element-option/use-filters-search'
import FiltersAndSearch from 'components/admin/filters-search'
import Loader from 'components/shared/loader'
import useRoomElementOptionMany from 'context/room-element-option/use-many'
import {
  SelectionCardExpandSelectProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { RoomElementOption } from 'types/room-element-option'

import RoomElementOptionSelectionCard from './card'

const RoomElementOptionsSelection = ({
  selectedRoomElementOptions,
  setSelectedRoomElementOptions,
}: {
  selectedRoomElementOptions: Partial<RoomElementOption>[]
  setSelectedRoomElementOptions: (
    roomElementOption: Partial<RoomElementOption>[],
  ) => void
}) => {
  const { roomElementOptions, loading } = useRoomElementOptionMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useRoomElementOptionFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
        style={{ marginTop: '10px' }}
      />
      {loading ? (
        <Grid.Row style={{ minHeight: '100vh' }}>
          <Grid.Column>
            <Loader flex fullScreen={false} />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row columns={4}>
          {roomElementOptions.map((item) => {
            return (
              <Grid.Column key={item.id} style={{ marginBottom: '20px' }}>
                <RoomElementOptionSelectionCard
                  roomElementOption={item}
                  selectionCardProps={
                    {
                      selectOnly: true,
                      onSelect: () => {
                        if (
                          selectedRoomElementOptions.find(
                            (s) => s.id === item.id,
                          )
                        )
                          setSelectedRoomElementOptions(
                            selectedRoomElementOptions.filter(
                              (s) => s.id !== item.id,
                            ),
                          )
                        else
                          setSelectedRoomElementOptions([
                            ...selectedRoomElementOptions,
                            item,
                          ])
                      },
                      selected: selectedRoomElementOptions.some(
                        (s) => s.id === item.id,
                      ),
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      )}
    </Grid>
  )
}

export default RoomElementOptionsSelection
