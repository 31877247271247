import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { startCase } from 'lodash'
import { Form, Grid, Input, Label, Message } from 'semantic-ui-react'
import styled from 'styled-components'

import AdminLayout from 'components/admin/layout'
import SaveModal from 'components/admin/save-modal'
import SectionTitle from 'components/admin/section-title'
import EstimateDelivery from 'components/shared/timeline/estimate-delivery'
import LeadTimeProvider, {
  ZipCodeLeadTime,
  useLeadTime,
} from 'context/lead-times/provider'
import { notifySuccess } from 'context/notifications/trigger'
import useSettings from 'context/settings/use'
import useSettingsMutate from 'context/settings/use-mutate'
import { Colors } from 'styles/app/system'
import { Settings } from 'types/settings'

const leadTimePreviewKeys: Partial<keyof ZipCodeLeadTime>[] = [
  'expectedYear',
  'totalWeeks',
  'weeksHigh',
  'weeksLow',
]

const LeadTimes = () => {
  const { leadTime } = useLeadTime()
  const { refetch, manufacturingLeadTime, orderConfirmationLeadTime } =
    useSettings()
  const { updateSettings } = useSettingsMutate()
  const [orderConfirmationState, setOrderConfirmationState] =
    useState<Settings>(orderConfirmationLeadTime)
  const [orderConfirmationError, setOrderConfirmationError] =
    useState<boolean>(false)

  const [manufacturingState, setManufacturingState] = useState<Settings>(
    manufacturingLeadTime,
  )
  const [manufacturingError, setManufacturingError] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const onChangeOrderConfirmationInput = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(value)
    if (val <= 0) setOrderConfirmationError(true)
    else {
      setOrderConfirmationError(false)
      setOrderConfirmationState({
        ...orderConfirmationState,
        value,
      })
    }
  }

  const onChangeManufacturingInput = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(value)
    if (val <= 0) setManufacturingError(true)
    else {
      setManufacturingError(false)
      setManufacturingState({
        ...manufacturingState,
        value,
      })
    }
  }

  return (
    <Grid>
      <SectionTitle title="Expected Delivery Date Settings" />
      <Grid.Row>
        <Grid.Column>
          <ErrorMessage>
            <p className="caption">
              <FontAwesomeIcon icon={['fal', 'exclamation-circle']} /> IMPORTANT
            </p>
            <p>
              Changing these dates will update the Expected Delivery Date in{' '}
              <b>all projects</b>. Please check the data is correct before
              saving.
            </p>
          </ErrorMessage>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={9}>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  <p>Order confirmation lead time</p>
                </label>
                <Input
                  label={{ content: 'weeks' }}
                  labelPosition="right"
                  name={orderConfirmationState?.key}
                  min={1}
                  onChange={onChangeOrderConfirmationInput}
                  type="number"
                  value={orderConfirmationState?.value || ''}
                />
                {orderConfirmationError && (
                  <Label pointing prompt>
                    Invalid value
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>
                  <p>Manufacturing lead time</p>
                </label>
                <Input
                  label={{ content: 'weeks' }}
                  labelPosition="right"
                  name={manufacturingState?.key}
                  min={1}
                  onChange={onChangeManufacturingInput}
                  type="number"
                  value={manufacturingState?.value || ''}
                />
                {manufacturingError && (
                  <Label pointing prompt>
                    Invalid value
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} centered textAlign="left">
        <Grid.Column width={10}>
          <EstimateDelivery initialZipCode="11201" />
        </Grid.Column>
        <Grid.Column width={6}>
          <ul>
            {leadTimePreviewKeys.map((k) => (
              <li key={k}>
                <p>
                  <strong>{startCase(k)}: </strong>
                  <span>{leadTime?.[k]}</span>
                </p>
              </li>
            ))}
          </ul>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <SaveModal
            currentState={{
              orderConfirmation: orderConfirmationState,
              manufacturing: manufacturingState,
            }}
            disableSave={orderConfirmationError || manufacturingError}
            initialState={{
              orderConfirmation: orderConfirmationLeadTime,
              manufacturing: manufacturingLeadTime,
            }}
            loadingSave={loading}
            onDiscard={() => {
              setOrderConfirmationState(orderConfirmationLeadTime)
              setManufacturingState(manufacturingLeadTime)
            }}
            onSave={async () => {
              setLoading(true)
              if (
                orderConfirmationLeadTime?.value !==
                orderConfirmationState?.value
              )
                await updateSettings({
                  variables: {
                    data: {
                      value: orderConfirmationState.value,
                    },
                    where: {
                      id: orderConfirmationLeadTime.id,
                    },
                  },
                })
              if (manufacturingLeadTime?.value !== manufacturingState?.value)
                await updateSettings({
                  variables: {
                    data: {
                      value: manufacturingState.value,
                    },
                    where: {
                      id: manufacturingLeadTime.id,
                    },
                  },
                })

              setLoading(false)
              notifySuccess('Lead times Updated')
              refetch()
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <LeadTimeProvider>
        <LeadTimes />
      </LeadTimeProvider>
    </AdminLayout>
  )
}

const ErrorMessage = styled(Message)`
  &&& {
    background: ${Colors.red50};
    border: 1px solid ${Colors.red800};
    box-shadow: none;
    width: fit-content;

    p,
    svg {
      color: ${Colors.red800};
      font-size: 14px;
    }
  }
`
