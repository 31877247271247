import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Header, Image, Table } from 'semantic-ui-react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import useAppliancePack from 'context/appliance-pack/use'
import useAppliancePackGenericProductMutate from 'context/appliance-pack/use-generic-product-mutate'
import useAuth from 'context/auth/use'
import { getReferenceImage } from 'context/media/utils-many'
import { GenericProductGraphQL } from 'types/generic-product'

import CreateUpdateApplianceModal from './create-update-modal'

const GenericProductsTable = () => {
  const { isAdmin } = useAuth()
  const { appliancePack } = useAppliancePack()
  const { deleteGenericProductAndRefetch } =
    useAppliancePackGenericProductMutate()
  const [applianceToDelete, setApplianceToDelete] =
    useState<GenericProductGraphQL>()
  const [applianceToEdit, setApplianceToEdit] =
    useState<GenericProductGraphQL>()

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Appliance</Table.HeaderCell>
          <Table.HeaderCell>Make / Model</Table.HeaderCell>
          <Table.HeaderCell>Link</Table.HeaderCell>
          <Table.HeaderCell>Link to Spec</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {appliancePack.appliances.map((gp) => {
          const { productClass, data } = gp
          return (
            <Table.Row key={gp.id}>
              <Table.Cell width={3}>
                <Header as="h4" image>
                  <Image
                    src={
                      productClass.media
                        ? getReferenceImage(productClass.media)
                        : ''
                    }
                    rounded
                    size="mini"
                    style={{ marginBottom: 0 }}
                  />
                  <Header.Content>
                    {productClass.metadata?.name}
                    <Header.Subheader>{data?.size}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell width={3}>{data?.productDetails}</Table.Cell>
              <Table.Cell style={{ lineBreak: 'anywhere' }} width={2}>
                {data?.linkToProduct ? (
                  <a target="_blank" href={data?.linkToProduct}>
                    {data?.linkToProduct}
                  </a>
                ) : null}
              </Table.Cell>
              <Table.Cell style={{ lineBreak: 'anywhere' }} width={2}>
                {data?.linkToProductSpec ? (
                  <a target="_blank" href={data.linkToProductSpec}>
                    {data.linkToProductSpec}
                  </a>
                ) : null}
              </Table.Cell>
              <Table.Cell width={1} textAlign="center">
                <Button
                  disabled={!isAdmin}
                  icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
                  primary
                  style={{ margin: '5px' }}
                  onClick={() => setApplianceToEdit(gp)}
                />
                <Button
                  disabled={!isAdmin}
                  icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
                  style={{ margin: '5px' }}
                  onClick={() => setApplianceToDelete(gp)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <CreateUpdateApplianceModal
        appliance={applianceToEdit}
        open={!!applianceToEdit}
        onClose={() => setApplianceToEdit(undefined)}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to delete this product?"
        onCancel={() => setApplianceToDelete(undefined)}
        onConfirm={async () => {
          await deleteGenericProductAndRefetch({
            variables: {
              where: {
                id: applianceToDelete?.id ?? '',
              },
            },
          })
          setApplianceToDelete(undefined)
        }}
        open={!!applianceToDelete}
      />
    </Table>
  )
}

export default GenericProductsTable
