import React, { createContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import useProject from 'context/project/use'
import {
  FIND_MANY_ISSUE,
  FindManyIssuePayload,
  FindManyIssueVariables,
} from 'queries/issue'
import { Issue } from 'types/issue'

import { QueryFilters, getQueryVariables } from './utils-many'

interface IProjectIssueManyContext {
  issues: Issue[]
  loading: boolean
  queryFilters: QueryFilters
  refetch: () => Promise<unknown>
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

interface ProjectIssueManyProps
  extends RouteComponentProps<{ project_id: string }> {
  children: React.ReactNode
}

export const ProjectIssueManyContext = createContext<IProjectIssueManyContext>(
  {} as IProjectIssueManyContext,
)

const ProjectIssueManyProvider = (props: ProjectIssueManyProps) => {
  const { project } = useProject()
  const [queryFilters, setQueryFilters] = useState<QueryFilters>({
    projectId: project.id,
    sortBy: 'derivedStatus',
    sortDirection: 'ascending',
  })
  const [queryVariables, setQueryVariables] = useState<FindManyIssueVariables>(
    getQueryVariables(queryFilters),
  )

  const { data, loading, refetch } = useQuery<
    FindManyIssuePayload,
    FindManyIssueVariables
  >(FIND_MANY_ISSUE, {
    variables: queryVariables,
  })

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  if (loading) return <Loader />

  return (
    <ProjectIssueManyContext.Provider
      value={{
        issues: data?.issues || [],
        loading,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {props.children}
    </ProjectIssueManyContext.Provider>
  )
}

export default ProjectIssueManyProvider
