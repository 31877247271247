import { useContext } from 'react'

import { RenderPropManyContext } from './provider-many'

const useRenderPropMany = () => {
  const {
    catalog,
    renderProps,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    queryFilters,
    refetch,
    setQueryFilters,
  } = useContext(RenderPropManyContext)

  return {
    catalog,
    renderProps,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    queryFilters,
    refetch,
    setQueryFilters,
  }
}

export default useRenderPropMany
