import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  IFormDropdownValue,
} from 'components/admin/filters-search'
import useDisconnectedOptionMany from 'context/disconnected-option/use-many'

export type DisconnectedOptionFilterValues = {
  featureIdentifier: IFormDropdownValue
}

const useDisconnectedOptionFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useDisconnectedOptionMany()

  const filters: DynamicFilterGroup<DisconnectedOptionFilterValues>[] =
    useMemo(() => {
      return []
    }, [])

  const defaultFilters = useMemo(() => {
    const disconnectedOptionFilters: Partial<DisconnectedOptionFilterValues> =
      {}
    return disconnectedOptionFilters
  }, [queryFilters])

  const handleApplyFilters = (_: DisconnectedOptionFilterValues) => {
    setQueryFilters({
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useDisconnectedOptionFiltersSearch
