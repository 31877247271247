import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NotesDashboard from 'components/admin/project-notes/notes-dashboard'
import useAuth from 'context/auth/use'
import CommentNoteManyProvider from 'context/project/project-notes/provider-many'

import AccountExecutiveLayout from './layout'

type AccountExecutiveNotesProps = React.PropsWithChildren<RouteComponentProps>

const AccountExecutiveNotes = (_: AccountExecutiveNotesProps) => {
  const { formUserId } = useAuth()

  return (
    <CommentNoteManyProvider
      formUserId={formUserId}
      defaultFilters={{
        newFor: {
          id: formUserId,
        },
      }}
    >
      <AccountExecutiveLayout>
        <NotesDashboard />
      </AccountExecutiveLayout>
    </CommentNoteManyProvider>
  )
}

export default AccountExecutiveNotes
