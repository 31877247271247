import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { DiffModal } from 'components/shared/diff-modal/diff-modal'
import { newForMe } from 'context/new-for'
import { formatCreationTime, parseOwners } from 'context/project/utils'
import useProjectEventMutate from 'context/project-event/use-mutate'
import Button, { ButtonTooltip } from 'design-system/components/button'
import { TooltipProps } from 'design-system/components/tooltip'
import { Colors } from 'styles/app/system'
import { ProjectEvent } from 'types/project-event'
import { getNameForUser, getShortNameForUser } from 'views/utils'

import {
  getEventSubtitle,
  getEventTitle,
  getEventTypeIcon,
  getPublisherTag,
  getUrlAdmin,
} from './utils'

const COMMON_TOOLTIP_PROPS: Omit<TooltipProps, 'children' | 'label'> = {
  color: Colors.blue700,
}

type EventCardProps = {
  event: ProjectEvent
  formUserId: string
  refetch: () => Promise<unknown>
  showProjectData?: boolean
}

export const EventCard = ({
  event,
  formUserId,
  refetch,
  showProjectData,
}: EventCardProps) => {
  const [viewDiff, setViewDiff] = useState(false)
  const { publisher } = event

  const { loadingUpdateEvent, updateProjectEvent } = useProjectEventMutate({
    refetch,
  })

  const icon = getEventTypeIcon(event.type)
  const eventSubtitle = getEventSubtitle(event)
  const isAcknowledged = !newForMe(event, formUserId)
  const publisherTag = getPublisherTag(event.publisher?.type)
  const urlAdmin = getUrlAdmin(event)

  const toggleAcknowledge = async () => {
    await updateProjectEvent({
      variables: {
        data: {
          newFor: isAcknowledged
            ? { connect: { id: formUserId } } //* Acknowledged. Set as unacknowledged
            : { disconnect: { id: formUserId } }, //* Not acknowledged. Set as acknowledged
        },
        where: {
          id: event.id,
        },
      },
    })
  }

  const { primaryOwner } = parseOwners(event.project)

  return (
    <MainContainer>
      <IconAcknowledged acknowledged={isAcknowledged}>
        <FontAwesomeIcon icon={['fal', icon]} />
      </IconAcknowledged>
      <div className="event-info">
        <p className="caption">{getEventTitle(event)}</p>
        {eventSubtitle && <p className="small">{eventSubtitle}</p>}
        <p className="x-small">
          {formatCreationTime(event.data.lastUpdatedAt || event.createdAt)}
        </p>
      </div>
      <div className="publisher">
        <img
          src={publisher?.picture}
          alt={publisher?.firstName}
          style={{ gridRow: publisherTag ? '1 / span 2' : '1' }}
        />
        <p className="no-margin">{getShortNameForUser(publisher)}</p>
        {!!publisherTag && <p className="x-small tag">{publisherTag}</p>}
      </div>
      <div className="actions">
        {event.data.diffs ? (
          <ButtonTooltip
            kind={isAcknowledged ? 'ghost' : 'solid'}
            fontAwesomeIcon="eye"
            onClick={() => setViewDiff(true)}
            loading={loadingUpdateEvent}
            tooltipProps={{
              label: 'View Changes',
              ...COMMON_TOOLTIP_PROPS,
            }}
          />
        ) : null}
        <ButtonTooltip
          kind={isAcknowledged ? 'ghost' : 'solid'}
          fontAwesomeIcon={isAcknowledged ? 'bell-plus' : 'square-check'}
          onClick={toggleAcknowledge}
          loading={loadingUpdateEvent}
          tooltipProps={{
            label: isAcknowledged ? 'Unacknowledge' : 'Acknowledge',
            ...COMMON_TOOLTIP_PROPS,
          }}
        />
        <ButtonTooltip
          kind="ghost"
          color="blue"
          fontAwesomeIcon="arrow-up-right-from-square"
          disabled={!urlAdmin}
          onClick={() => window.open(urlAdmin)}
          tooltipProps={{
            label: 'Go to',
            ...COMMON_TOOLTIP_PROPS,
          }}
        />
      </div>
      {showProjectData && primaryOwner && event.project.id && (
        <Button
          kind="text"
          color="blue"
          text={`Open ${getNameForUser(primaryOwner)}'s project`}
          fontAwesomeIcon="arrow-up-right-from-square"
          onClick={() =>
            window.open(
              `${process.env.GATSBY_HOME_URL}/admin/projects/${event.project.id}/overview`,
            )
          }
          className="project-data"
        />
      )}

      {event.data.diffs ? (
        <DiffModal
          diffs={event.data.diffs}
          open={viewDiff}
          onClose={() => setViewDiff(false)}
          title={getEventTitle(event)}
        />
      ) : null}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid ${Colors.blue100};
  column-gap: 40px;
  display: grid;
  grid-template-columns: max-content 30% auto max-content;
  padding: 16px;
  row-gap: 16px;

  &:hover,
  &:focus {
    background-color: ${Colors.white};
  }

  & > button.project-data {
    grid-column: 1 / span 4;
    font-size: 14px;
  }

  & > div.event-info {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    & > p {
      margin: 0;
    }
  }

  & > div.publisher {
    align-items: center;
    column-gap: 8px;
    row-gap: 4px;
    display: grid;
    grid-template-columns: max-content max-content;

    & > img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    & > p.tag {
      width: max-content;
      background-color: ${Colors.blue100};
      border-radius: 2px;
      padding: 0 6px;
      grid-column: 2;
    }
  }

  & > div.actions {
    display: flex;
    column-gap: 8px;
    justify-content: flex-end;
    position: relative; // Needed as it is the tooltip's parent
  }
`

const IconAcknowledged = styled.div<{ acknowledged?: boolean }>`
  position: relative;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.blue100};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-left: 24px;
  width: 40px;

  &::before {
    content: '';
    background-color: ${Colors.orange500};
    border-radius: 50%;
    height: 8px;
    position: absolute;
    left: -24px;
    visibility: ${(props) => (props.acknowledged ? 'hidden' : 'visible')};
    width: 8px;
  }

  & > svg {
    font-size: 16px;
  }
`
