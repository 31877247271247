import { gql } from '@apollo/client'

import { RenderProp } from 'types/render-prop'

export const FIND_MANY_RENDER_PROP = gql`
  query findManyRenderProp(
    $take: Int
    $orderBy: [RenderPropOrderByWithRelationInput!]
    $skip: Int
    $where: RenderPropWhereInput
  ) {
    count: findManyRenderPropCount(where: $where)
    renderProps: findManyRenderProp(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      identifier
      data
      description
      groupIdentifier
      imageUrl
    }
  }
`

export interface FindManyRenderPropPayload {
  count?: number
  renderProps: RenderProp[]
}

export interface FindManyRenderPropVariables {
  orderBy?:
    | {
        identifier?: string
      }
    | {
        groupIdentifier: string
        identifier: string
      }[]
  take?: number
  skip?: number
  where?: {
    description?: {
      contains: string
      mode?: 'insensitive'
    }
    groupIdentifier?: {
      equals?: string
      in?: string[]
    }
  }
}

export const FIND_UNIQUE_RENDER_PROP = gql`
  query findUniqueRenderProp($where: RenderPropWhereUniqueInput!) {
    renderProp: findUniqueRenderProp(where: $where) {
      id
      data
      description
      groupIdentifier
      identifier
    }
  }
`

export interface FindUniqueRenderPropPayload {
  renderProp: RenderProp
}

export interface FindUniqueRenderPropVariables {
  where?: {
    id?: string
    identifier?: string
  }
}

export const CREATE_RENDER_PROP = gql`
  mutation createRenderProp($data: RenderPropCreateInput!) {
    createOneRenderProp(data: $data) {
      id
      data
      description
      groupIdentifier
      identifier
      imageUrl
    }
  }
`

export interface CreateRenderPropPayload {
  createOneRenderProp: RenderProp
}

export interface CreateRenderPropVariables {
  data: Partial<RenderProp>
}

export const UPDATE_RENDER_PROP = gql`
  mutation updateRenderProp(
    $data: RenderPropUpdateInput!
    $where: RenderPropWhereUniqueInput!
  ) {
    updateOneRenderProp(data: $data, where: $where) {
      id
      data
      description
      groupIdentifier
      identifier
      imageUrl
    }
  }
`

export interface UpdateRenderPropPayload {
  updateOneRenderProp: {
    id: string
  }
}

export interface UpdateRenderPropVariables {
  data: Partial<RenderProp>
  where: {
    id: string
  }
}
