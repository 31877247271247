import React, { useEffect, useMemo } from 'react'

import { useLazyQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import Loader from 'components/shared/loader'
import { MessageList } from 'components/shared/message'
import { groupMessagesByDate } from 'context/project/message/utils-many'
import {
  FIND_MANY_MESSAGE,
  FindManyMessagePayload,
  FindManyMessageVariables,
} from 'queries/message'
import { MessageSource } from 'types/message'

type ConversationMessagesProps = {
  conversationId: string
  setLoading?: (loading: boolean) => void
  messagesFilter?: 'all' | MessageSource.SMS | MessageSource.CALL_TRANSCRIPT
}

export const ConversationMessages = ({
  conversationId,
  setLoading,
  messagesFilter,
}: ConversationMessagesProps) => {
  const [fetchMessages, { data, loading }] = useLazyQuery<
    FindManyMessagePayload,
    FindManyMessageVariables
  >(FIND_MANY_MESSAGE)

  const { messagesGroupedByDate } = useMemo(() => {
    const messagesSorted = sortBy(data?.messages || [], 'createdAt')
    return {
      messages: messagesSorted,
      messagesGroupedByDate: groupMessagesByDate(messagesSorted),
    }
  }, [data?.messages])

  useEffect(() => {
    setLoading?.(loading)
  }, [loading])

  useEffect(() => {
    if (!conversationId) return

    const variables: FindManyMessageVariables = {
      where: {
        conversation: {
          id: { equals: conversationId },
        },
      },
    }

    if (messagesFilter === 'all') {
      variables.where.OR = [
        { data: { path: ['source'], equals: MessageSource.SMS } },
        { data: { path: ['source'], equals: MessageSource.CALL_TRANSCRIPT } },
      ]
    } else if (messagesFilter) {
      variables.where.data = { path: ['source'], equals: messagesFilter }
    }

    fetchMessages({
      variables,
      fetchPolicy: 'cache-and-network',
    })
  }, [conversationId, messagesFilter])

  return loading ? (
    <Loader flex fullScreen={false} />
  ) : (
    <MessageList messages={messagesGroupedByDate} admin disableUpdateMessage />
  )
}
