import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Grid, Label, SemanticWIDTHS } from 'semantic-ui-react'

import CustomOptionCreateUpdateModal from 'components/admin/catalog/custom-option/create-update-modal'
import GenericOptionSelectionCard from 'components/shared/catalog/generic-option/card'
import {
  SelectionCardDeleteProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import {
  FIND_UNIQUE_GENERIC_OPTION,
  FindUniqueGenericOptionPayload,
  FindUniqueGenericOptionVariables,
} from 'queries/generic-option'
import { SelectionAccordion } from 'styles/admin/main'
import { GenericOption } from 'types/generic-option'
import { ProjectFile } from 'types/project-file'
import { GenericOptionRoom } from 'types/room'

import GenericOptionsModal from './selection-modal'

interface GenericOptionAccordionProps {
  conceptImages?: ProjectFile[]
  customIdentifier?: string
  featureIdentifier?: string
  genericOptions: Partial<GenericOption>[]
  gridColumns?: SemanticWIDTHS
  onDelete?: (go: Partial<GenericOption>) => void
  onSave: (go: Partial<GenericOption>[]) => void
  onSaveCustom?: (go: Partial<GenericOptionRoom>) => void
  title: string
}

const GenericOptionAccordion = ({
  conceptImages,
  customIdentifier,
  featureIdentifier,
  genericOptions,
  gridColumns,
  onDelete: onDeleteGenericOption,
  onSave,
  onSaveCustom,
  title,
}: GenericOptionAccordionProps) => {
  const [showCards, toggleCards] = useState<boolean>(true)

  const { data: { genericOption } = { genericOption: null } } = useQuery<
    FindUniqueGenericOptionPayload,
    FindUniqueGenericOptionVariables
  >(FIND_UNIQUE_GENERIC_OPTION, {
    skip: !customIdentifier,
    variables: {
      where: { identifier: customIdentifier },
    },
  })

  const formOptions = genericOptions.filter(
    (o) => o.optionIdentifier !== 'custom',
  )

  return (
    <SelectionAccordion fluid>
      <Accordion.Title className="accordion-title">
        <span className="name" onClick={() => toggleCards(!showCards)}>
          <p className="subtitle no-margin">{title}</p>
          <FontAwesomeIcon
            icon={['fal', showCards ? 'chevron-up' : 'chevron-down']}
          />
        </span>
        <div style={{ display: 'flex', gap: '14px' }}>
          {conceptImages?.length ? (
            <CustomOptionCreateUpdateModal
              conceptImages={conceptImages}
              elementType={title}
              onSave={(n, f, link) =>
                onSaveCustom?.({
                  genericOption: {
                    ...(genericOption as GenericOption),
                    data: {
                      ...genericOption?.data,
                      customImageUrl: f.key,
                      customName: n,
                      fileId: f.id,
                      linkToProductSpec: link,
                    },
                  },
                  data: {
                    name: n,
                    linkToProductSpec: link,
                  },
                  projectFile: f,
                })
              }
              showLinkToSpec
            />
          ) : null}
          <GenericOptionsModal
            {...(featureIdentifier &&
              ({
                defaultFilters: {
                  feature: {
                    identifier: featureIdentifier,
                  },
                  sortBy: 'description',
                  sortDirection: 'ascending',
                  take: undefined,
                },
              } as any))}
            onSave={onSave}
            selectedGenericOptions={formOptions}
            title={title}
          />
        </div>
      </Accordion.Title>
      <Accordion.Content active className="accordion-content">
        {showCards ? (
          <Grid>
            <Grid.Row columns={gridColumns || 3}>
              {genericOptions?.map((option) => (
                <Grid.Column key={option.id}>
                  <GenericOptionSelectionCard
                    genericOption={option}
                    selectionCardProps={
                      {
                        imageRatio: '1/1',
                        linkToSpec: option.data?.linkToProductSpec,
                        onDelete: () => onDeleteGenericOption?.(option),
                      } as Partial<SelectionCardDeleteProps>
                    }
                    selectionCardVariation={SelectionCardVariation.DELETE}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        ) : (
          <div className="labels">
            {genericOptions?.map((option) => (
              <Label key={option.id}>
                {option.description}
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  onClick={() => onDeleteGenericOption?.(option)}
                />
              </Label>
            ))}
          </div>
        )}
      </Accordion.Content>
    </SelectionAccordion>
  )
}

export default GenericOptionAccordion
