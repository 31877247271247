import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Image, Table } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useCatalogFeatureMany from 'context/catalog-feature/use-many'
import * as mUtils from 'context/media/utils-many'
import Button from 'design-system/components/button/button'
import { CatalogFeature } from 'types/catalog-feature'
import { camelCaseToTitleCase } from 'views/utils'

import CatalogFeatureCreateUpdateModal from './create-update-modal'

const CatalogFeatureTable = () => {
  const { catalog, catalogFeatures, refetch, queryFilters, setQueryFilters } =
    useCatalogFeatureMany()
  const { sortBy, sortDirection } = queryFilters
  const [catalogFeatureToUpdate, setCatalogFeatureToUpdate] =
    useState<CatalogFeature>()
  const { isSuperAdmin } = useAuth()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'groupIdentifier' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'groupIdentifier',
                sortDirection:
                  sortBy === 'groupIdentifier' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Group
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'identifier' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'identifier',
                sortDirection:
                  sortBy === 'identifier' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Identifier
          </Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {catalogFeatures.map((n: CatalogFeature, idx) => {
          const display = mUtils.parseMedia(n.media).reference.length
            ? mUtils.parseMedia(n.media).reference[0].key
            : 'images/app/catalog_missing_file.png'
          return (
            <Table.Row key={`${n.id}_${idx}`}>
              <Table.Cell width={3}>
                <Image
                  centered
                  size="small"
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    display,
                  )}`}
                />
              </Table.Cell>
              <Table.Cell width={4}>{n.description}</Table.Cell>
              <Table.Cell width={4}>
                {camelCaseToTitleCase(n.groupIdentifier)}
              </Table.Cell>
              <Table.Cell width={3}>{n.identifier}</Table.Cell>
              <Table.Cell width={2}>
                {n.data?.showOnWebsiteCatalog && 'Showing on catalog'}
              </Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                <Button
                  kind="solid"
                  color="blue"
                  fontAwesomeIcon="arrow-up-right-from-square"
                  onClick={() =>
                    window.open(
                      `/admin/catalog/${catalog}/catalog-features/${n.id}`,
                    )
                  }
                />
                <Button
                  kind="solid"
                  disabled={!isSuperAdmin}
                  fontAwesomeIcon="pencil"
                  onClick={() => setCatalogFeatureToUpdate(n)}
                  style={{
                    marginTop: 8,
                  }}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <CatalogFeatureCreateUpdateModal
        catalogFeature={catalogFeatureToUpdate}
        onClose={() => setCatalogFeatureToUpdate(undefined)}
        onSave={(id: string) => {
          setCatalogFeatureToUpdate(undefined)
          refetch()
          navigate(`/admin/catalog/${catalog}/catalog-features/${id}`)
        }}
        openModal={!!catalogFeatureToUpdate}
      />
    </Table>
  )
}

export default CatalogFeatureTable
