import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'
import {
  INoteTextEditorPluginsContext,
  NoteTextEditorPluginsProvider,
} from 'context/project/project-notes/note-editor-plugins-provider'
import { useCommentNoteMany } from 'context/project/project-notes/provider-many'
import useFORMEmployees from 'context/user/use-form-employees'
import Button, { ButtonTooltip } from 'design-system/components/button'
import { Hexagon } from 'styles/app/components/icon'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { getNameForUser } from 'views/utils'

import { CreateNotesModal } from './create-modal'
import { NotesDrawer } from './notes-drawer'
import { useNoteFilters } from './use-note-filters'
import FiltersAndSearch from '../filters-search/filters-search'

type NotesDasboardProps = {
  showProjectData?: boolean
  isProjectNote?: boolean
} & INoteTextEditorPluginsContext

const NotesDashboard = ({
  showProjectData,
  isProjectNote,
  deepLinks,
  mentions: mentionsProps,
}: NotesDasboardProps) => {
  const { formUserId, isSuperAdmin } = useAuth()
  const { users: staff } = useFORMEmployees()
  const mentions = useMemo(
    () => staff.map((s) => ({ id: s.id, label: getNameForUser(s) })),
    [staff],
  )

  const [openCreateModal, setOpenCreateModal] = useState(false)
  const {
    commentNotesGroupedByDate,
    refetchAll,
    fetchMore,
    fetchedCount,
    count,
    loadingMore,
    loadingFirst,
    loading,
  } = useCommentNoteMany()
  const {
    defaultFilters,
    filters,
    handleApplyFilters,
    handleApplySearch,
    searchValue,
  } = useNoteFilters(formUserId, staff)

  if (loadingFirst)
    return <Loader flex style={{ height: 400 }} fullScreen={false} />

  return (
    <NoteTextEditorPluginsProvider
      deepLinks={deepLinks}
      mentions={mentionsProps ?? mentions}
    >
      <MainContainer>
        <div className="filters">
          <div>
            <ButtonTooltip
              kind="solid"
              fontAwesomeIcon="refresh"
              color="blue"
              loading={loading}
              onClick={refetchAll}
              style={{ marginRight: 16 }}
              tooltipProps={{ label: 'Refresh' }}
            />
            {isProjectNote && (
              <CreateNotesModal
                open={openCreateModal}
                onClose={() => setOpenCreateModal(false)}
                onOpen={() => setOpenCreateModal(true)}
              />
            )}
          </div>
          <FiltersAndSearch
            filters={filters}
            defaultAppliedFilters={defaultFilters}
            onApply={handleApplyFilters}
            handleSearchChange={handleApplySearch}
            searchValue={searchValue}
          />
        </div>

        {commentNotesGroupedByDate.length ? (
          <NotesContainer isProjectNote={isProjectNote}>
            <div className="note-header">
              <p className="no-margin">
                Notes
                <span className="x-small">
                  {` (Showing ${
                    fetchedCount < count ? `${fetchedCount}+` : fetchedCount
                  })`}
                </span>
              </p>
              <p className="no-margin">Published by</p>
              {!isProjectNote && (
                <p className="no-margin">Project / Prospect</p>
              )}
              <p className="no-margin">Actions</p>
            </div>
            <div className="drawers" id="note-drawers">
              {commentNotesGroupedByDate.map(([date, groupedNotes], idx) => (
                <NotesDrawer
                  key={date}
                  defaultOpen={idx < 2}
                  formUserId={formUserId}
                  isSuperAdmin={isSuperAdmin}
                  title={date}
                  notes={groupedNotes}
                  refetch={refetchAll}
                  showProjectData={showProjectData}
                  isProjectNote={isProjectNote}
                />
              ))}
              {fetchedCount < count && (
                <Button
                  kind="solid"
                  text="Load More"
                  loading={loadingMore}
                  onClick={fetchMore}
                  style={{ display: 'block', margin: '20px auto 0 auto' }}
                />
              )}
            </div>
          </NotesContainer>
        ) : (
          <EmptyState>
            <Hexagon
              styled={{
                background: Colors.blue100,
                color: Colors.blue900,
                fontSize: '24px',
                size: '80px',
              }}
            >
              <FontAwesomeIcon icon={['fal', 'question']} />
            </Hexagon>
            <h3 className="no-margin">There are no notes to show</h3>
            <Button
              kind="solid"
              text="Refresh"
              fontAwesomeIcon="refresh"
              iconPosition="left"
              loading={loading}
              onClick={refetchAll}
            />
          </EmptyState>
        )}
      </MainContainer>
    </NoteTextEditorPluginsProvider>
  )
}

const MainContainer = styled.div`
  & > div.filters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
  }
`

const NotesContainer = styled.div<{ isProjectNote?: boolean }>`
  border: 1px solid ${Colors.gray200};
  border-radius: 4px;
  margin-top: 32px;
  width: 100%;

  & > div.note-header {
    background-color: ${Colors.gray100};
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid ${Colors.gray200};
    display: grid;
    grid-template-columns: ${(props) =>
      props.isProjectNote
        ? 'calc(24px + 64px + 40px + 28.6% + 40px) auto 200px'
        : 'calc(24px + 64px + 40px + 28.6% + 40px) 17.5% auto 170px'};
    padding: 8px 24px;

    span.x-small {
      font-size: 12px;
    }
  }

  & > div.drawers {
    max-height: 800px;
    overflow: auto;
    padding: 16px;
    scroll-behavior: smooth;
    ${ScrollBarStyle}
  }
`

const EmptyState = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
  padding-top: 80px;
  width: 100%;
`

export default NotesDashboard
