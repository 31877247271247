import React, { useState } from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import FiltersAndSearch from 'components/admin/filters-search'
import { BackToTop } from 'components/shared/load-buttons'
import useAppliancePackMany from 'context/appliance-pack/use-many'
import useAuth from 'context/auth/use'
import Button from 'design-system/components/button'

import AppliancePackCreateUpdateModal from './create-update-modal'
import AppliancePacksTable from './table'
import useAppliancePackFiltersSearch from './use-filters-search'

const AppliancePacks = ({ table }: { table?: React.ReactNode }) => {
  const [enableCreateAppliancePack, setEnableCreateAppliancePack] =
    useState(false)
  const { isAdmin } = useAuth()
  const { count, appliancePacks, fetchMore, loading, loadingMore } =
    useAppliancePackMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useAppliancePackFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${appliancePacks?.length} of ${count}`}{' '}
            Appliance Packs
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            color="gray"
            disabled={!isAdmin}
            kind="outlined"
            onClick={() => setEnableCreateAppliancePack(true)}
            text="Create New"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table || <AppliancePacksTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && appliancePacks?.length > 0 && <BackToTop />}
      <AppliancePackCreateUpdateModal
        open={enableCreateAppliancePack}
        onClose={() => setEnableCreateAppliancePack(false)}
      />
    </Grid>
  )
}

export default AppliancePacks
