import { useContext } from 'react'

import { NobiliaProductClassManyContext } from './provider-many'

const useNobiliaProductClassMany = () => {
  const {
    catalog,
    nobiliaProductClasses,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    queryFilters,
    refetch,
    setQueryFilters,
  } = useContext(NobiliaProductClassManyContext)

  return {
    catalog,
    nobiliaProductClasses,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    queryFilters,
    refetch,
    setQueryFilters,
  }
}

export default useNobiliaProductClassMany
