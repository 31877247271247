export type ChecklistItemData =
  | {
      identifier: string
      text: string
      readonly?: boolean
      indent?: boolean
    }
  | { pro_tip: string }

export type ChecklistItems = {
  [key: string]:
    | {
        title: string
        items: ChecklistItemData[]
        groups?: never
      }
    | {
        title: string
        items?: never
        groups: {
          title: string
          items: ChecklistItemData[]
        }[]
      }
}

/**
 * IMPORTANT NOTE
 * DO NOT modify the `identifier` property, as it is the value we use to search for checked items in the DB
 * Also, do not change the items order
 * Identifier structure = <tab_name>.<drawer>.<checklist_name>
 */
export const designChecklistItems: ChecklistItems = {
  preparation: {
    title: 'Preparation',
    items: [
      {
        identifier: 'design.preparation.prospect_dashboard',
        text: 'Review prospect dashboard',
      },
      { identifier: 'design.preparation.estimator', text: 'Review estimator' },
      {
        identifier: 'design.preparation.prospect_call',
        text: 'Watch prospect call',
      },
      {
        identifier: 'design.preparation.floor_plan',
        text: 'Review floor plan',
      },
      {
        identifier: 'design.preparation.photos',
        text: 'Review photos and inspiration',
      },
      {
        identifier: 'design.preparation.appliances',
        text: 'Review appliances',
      },
    ],
  },
  designCall: {
    title: 'Initial Call',
    groups: [
      {
        title: 'Prepare',
        items: [
          {
            identifier: 'design.design_call.prepare_guide',
            text: 'Prepare Initial Call interactive guide',
            readonly: true,
          },
          {
            identifier: 'design.design_call.pdf',
            text: 'Open PDF/plan in Adobe (optional)',
            readonly: true,
          },
          {
            identifier: 'design.design_call.features',
            text: 'Open a tab with Features (optional)',
            readonly: true,
          },
          {
            pro_tip:
              'If the client did NOT SHOW, checkmark in Meetings to avoid releasing the guide!',
          },
        ],
      },
      {
        title: 'Follow up',
        items: [
          {
            identifier: 'design.design_call.design_in_progress_msg',
            text: 'Send follow-up message 02-M1 Post Initial Call, "Your initial design is now in progress"',
          },
          {
            identifier: 'design.design_call.more_info_needed_msg',
            text: 'or Send follow-up message 02-M2 Post Initial Call, "Before I can get started on your initial design" (more info needed)',
          },
          {
            identifier: 'design.design_call.more_info_received_msg',
            text: 'Send follow-up message 02-M5 Post Initial Call, "Your initial design is now in progress" (missing info received)',
            indent: true,
          },
          {
            identifier: 'design.design_call.scheduled_presentation',
            text: 'Presentation Scheduled?',
          },
        ],
      },
    ],
  },
  initialDesign: {
    title: 'Initial Design',
    items: [
      {
        identifier: 'design.initial_design.detailed_quote_upload',
        text: 'Upload Initial Design Detailed Quote',
      },
      {
        identifier: 'design.initial_design.internal_review',
        text: 'Send Initial Design to Internal Review (optional)',
      },
      {
        identifier: 'design.initial_design.budget',
        text: 'Within budget? If far off, reach out to client as a courtesy',
      },
      {
        identifier: 'design.initial_design.measurments',
        text: 'Add floor plan and ceiling/wall dimensions to "Measurements"',
      },
      {
        identifier: 'design.initial_design.submit_render',
        text: 'Submit to render',
      },
      {
        identifier: 'design.initial_design.upload_renders',
        text: 'Upload renders to "Visual Quote"',
      },
      {
        identifier: 'design.initial_design.design_brief',
        text: 'Add any features used to "Design Brief" (optional)',
      },
      {
        identifier: 'design.initial_design.publish',
        text: 'Publish Design!',
      },
      {
        identifier: 'design.initial_design.check_samples',
        text: 'Check if samples were delivered (track Fedex)',
      },
      {
        identifier: 'design.initial_design.pre-presentation-reminder-msg',
        text: 'Send reminder message 03-M1 Pre Presentation, "How to prepare for your Design Presentation"',
      },
    ],
  },
  presentationCall: {
    title: 'Presentation Call',
    groups: [
      {
        title: 'Prepare',
        items: [
          {
            identifier: 'design.presentation_call.preload_guide',
            text: 'Preload Presentation Call interactive guide',
            readonly: true,
          },
        ],
      },
      {
        title: 'During',
        items: [
          {
            pro_tip:
              'If the client did NOT SHOW, checkmark in Meetings to avoid releasing the guide!',
          },
          {
            identifier: 'design.presentation_call.extra_rooms',
            text: 'Interested in extra rooms?',
          },
          {
            identifier: 'design.presentation_call.schedule_check_in',
            text: 'Schedule first Design Review Check-in Call',
          },
        ],
      },
      {
        title: 'Follow up',
        items: [
          {
            identifier: 'design.presentation_call.next_steps_msg',
            text: 'Send follow-up message 04-M1 Post Presentation, "Next steps after the Design Presentation"',
          },
          {
            identifier: 'design.presentation_call.post_presentation_faq_msg',
            text: 'Send follow-up message 04-M2 Post Presentation, FAQ (24 hour policy)',
          },
          {
            identifier:
              'design.presentation_call.post_presentation_checking_in_msg',
            text: 'Send follow-up message 04-M4 Post Presentation, "Checking in" (client hasn\'t paid project deposit)',
          },
          {
            identifier: 'design.presentation_call.confirm_project_deposit',
            text: 'Confirm Project Deposit Paid!',
          },
          {
            identifier: 'design.presentation_call.post_project_deposit_msg',
            text: 'Send confirmation message 05-M1 Project Deposit Paid, "Next steps after completing your Project Deposit"',
          },
          {
            identifier: 'design.presentation_call.schedule_design_review',
            text: 'If needed, send follow-up message 05-M2 Project Deposit Paid, "Let\'s schedule our first Design Review call"',
          },
          {
            identifier: 'design.presentation_call.first_review_check_in_call',
            text: 'First Design Review Check-In Call Scheduled?',
          },
        ],
      },
    ],
  },
}

/**
 * IMPORTANT NOTE
 * DO NOT modify the `identifier` property, as it is the value we use to search for checked items in the DB
 * Also, do not change the items order
 * Identifier structure = <tab_name>.<drawer>.<checklist_name>
 */
export const deliveryChecklistItems: ChecklistItems = {
  handoverCall: {
    title: 'Handover Call',
    groups: [
      {
        title: 'Prepare',
        items: [
          {
            identifier: 'deliver.handover_call.project_status',
            text: 'Check the latest project status',
            readonly: true,
          },
          {
            identifier: 'deliver.handover_call.preload_guide',
            text: 'Preload Handover Call interactive guide',
            readonly: true,
          },
        ],
      },
    ],
  },
  orderConfirmation: {
    title: 'Order Confirmation',
    groups: [
      {
        title: 'Order Confirmation',
        items: [
          {
            identifier: 'delivery.order_confirmation.compile_specs',
            text: 'Compile and upload Appliances Specs',
          },
          {
            identifier: 'delivery.order_confirmation.submit_and_track',
            text: 'Submit order and track - Final Design',
          },
          {
            identifier: 'delivery.order_confirmation.ack_approved',
            text: 'Ack approved!',
          },
          {
            identifier:
              'delivery.order_confirmation.enter_price_volumes_to_app',
            text: 'Enter confirmed ack price and volumes into app',
          },
          {
            identifier: 'delivery.order_confirmation.guaranteed_date_task',
            text: 'Create task for Nick "Send guaranteed date" due in 1 day',
          },
        ],
      },
      {
        title: 'Final Renders',
        items: [
          {
            identifier: 'delivery.oreder_confirmation.render_notes',
            text: 'Check render notes and send to final render due in 7 days',
          },
          {
            identifier: 'delivery.oreder_confirmation.visuals_available_msg',
            text: 'Send Message 10-M1 "Your updated visuals are now available"',
          },
        ],
      },
      {
        title: 'Installation Pack',
        items: [
          {
            identifier: 'delivery.oreder_confirmation.create_installation_pack',
            text: 'Create and upload Installation Pack in 14 Days',
          },
          {
            identifier: 'delivery.oreder_confirmation.ip_available_msg',
            text: 'Send Message 10-M2 "Your Installation Pack is now available"',
          },
        ],
      },
    ],
  },
}

/**
 * IMPORTANT NOTE
 * DO NOT modify the `identifier` property, as it is the value we use to search for checked items in the DB
 * Also, do not change the items order
 * Identifier structure = <tab_name>.<drawer>.<checklist_name>
 */
export const refineChecklistItems: ChecklistItems = {
  revision_1: {
    title: 'Revision 1',
    groups: [
      {
        title: 'Prepare',
        items: [
          {
            identifier: 'refine.revision_1.measurments_change',
            text: 'Review any measurements changes',
          },
          {
            identifier: 'refine.revision_1.appliances_change',
            text: 'Review any appliances changes',
          },
          {
            identifier: 'refine.revision_1.design_plan_comments',
            text: 'Review comments in Design Plan',
          },
        ],
      },
      {
        title: 'During',
        items: [
          {
            identifier: 'refine.revision_1.preload_revision_guide',
            readonly: true,
            text: 'Preload Revision Call interactive guide',
          },
          {
            identifier: 'refine.revision_1.preload_extra_rooms_guide',
            readonly: true,
            text: 'Preload Extra Rooms interactive guide',
          },
          {
            identifier: 'refine.revision_1.submit_comments',
            text: 'Submit comments',
          },
          {
            identifier: 'refine.revision_1.confirm_measurments',
            text: 'Measurments confirmation',
          },
          {
            identifier: 'refine.revision_1.confirm_appliance',
            text: 'Appliance confirmation',
          },
          {
            identifier: 'refine.revision_1.schedule_next_revision',
            text: 'Schedule next Revision Check-in Call',
          },
        ],
      },
      {
        title: 'Follow up',
        items: [
          {
            identifier: 'refine.revision_1.in_progress_msg',
            text: 'Send follow-up message 06-M1 Design Review, "Your revised design is now in progress"',
          },
          {
            identifier: 'refine.revision_1.upload_detailed_quote',
            text: 'Upload revised Design Detailed Quote',
          },
          {
            identifier: 'refine.revision_1.to_internal_review',
            text: 'Send revised design to Internal Review (only 1st Revision)',
          },
          {
            identifier: 'refine.revision_1.upload_winner_3d',
            text: 'Send revised design to Internal Review (only 1st Revision)',
          },
          {
            identifier: 'refine.revision_1.confirm_finishes',
            text: 'Confirm finishes & any details on Visual Quote are still accurate',
          },
          {
            identifier: 'refine.revision_1.publish',
            text: 'Publish Design!',
          },
          {
            identifier: 'refine.revision_1.design_available_msg',
            text: 'Send Message 06-M2 Design Review, "Your revised design is available to review"',
          },
          {
            identifier: 'refine.revision_1.next_review_msg',
            text: 'If needed, send follow-up message 06-M3 Design Review, "Let\'s schedule our next Design Review call"',
          },
          {
            identifier: 'refine.revision_1.schedule_next_review',
            text: 'Next Design Review Check-In Call Scheduled?',
          },
        ],
      },
    ],
  },
  revision_2: {
    title: 'Revision 2',
    groups: [
      {
        title: 'Prepare',
        items: [
          {
            identifier: 'refine.revision_2.measurments_change',
            text: 'Review any measurements changes',
          },
          {
            identifier: 'refine.revision_2.appliances_change',
            text: 'Review any appliances changes',
          },
          {
            identifier: 'refine.revision_2.design_plan_comments',
            text: 'Review comments in Design Plan',
          },
        ],
      },
      {
        title: 'During',
        items: [
          {
            identifier: 'refine.revision_2.preload_revision_guide',
            readonly: true,
            text: 'Preload Revision Call interactive guide',
          },
          {
            identifier: 'refine.revision_2.preload_extra_rooms_guide',
            readonly: true,
            text: 'Preload Extra Rooms interactive guide',
          },
          {
            identifier: 'refine.revision_2.submit_comments',
            text: 'Submit comments',
          },
          {
            identifier: 'refine.revision_2.confirm_measurments',
            text: 'Measurments confirmation',
          },
          {
            identifier: 'refine.revision_2.confirm_appliance',
            text: 'Appliance confirmation',
          },
          {
            identifier: 'refine.revision_2.schedule_next_revision',
            text: 'Schedule next Revision Check-in Call',
          },
        ],
      },
      {
        title: 'Follow up',
        items: [
          {
            identifier: 'refine.revision_2.in_progress_msg',
            text: 'Send follow-up message 06-M1 Design Review, "Your revised design is now in progress"',
          },
          {
            identifier: 'refine.revision_2.upload_detailed_quote',
            text: 'Upload revised Design Detailed Quote',
          },
          {
            identifier: 'refine.revision_2.to_internal_review',
            text: 'Send revised design to Internal Review (only 2nd Revision)',
          },
          {
            identifier: 'refine.revision_2.upload_winner_3d',
            text: 'Send revised design to Internal Review (only 2nd Revision)',
          },
          {
            identifier: 'refine.revision_2.confirm_finishes',
            text: 'Confirm finishes & any details on Visual Quote are still accurate',
          },
          {
            identifier: 'refine.revision_2.publish',
            text: 'Publish Design!',
          },
          {
            identifier: 'refine.revision_2.design_available_msg',
            text: 'Send Message 06-M2 Design Review, "Your revised design is available to review"',
          },
          {
            identifier: 'refine.revision_2.next_review_msg',
            text: 'If needed, send follow-up message 06-M3 Design Review, "Let\'s schedule our next Design Review call"',
          },
          {
            identifier: 'refine.revision_2.schedule_next_review',
            text: 'Next Design Review Check-In Call Scheduled?',
          },
        ],
      },
    ],
  },
  revision_3: {
    title: 'Revision 3',
    groups: [
      {
        title: 'Prepare',
        items: [
          {
            identifier: 'refine.revision_3.measurments_change',
            text: 'Review any measurements changes',
          },
          {
            identifier: 'refine.revision_3.appliances_change',
            text: 'Review any appliances changes',
          },
          {
            identifier: 'refine.revision_3.design_plan_comments',
            text: 'Review comments in Design Plan',
          },
        ],
      },
      {
        title: 'During',
        items: [
          {
            identifier: 'refine.revision_3.preload_revision_guide',
            readonly: true,
            text: 'Preload Revision Call interactive guide',
          },
          {
            identifier: 'refine.revision_3.preload_extra_rooms_guide',
            readonly: true,
            text: 'Preload Extra Rooms interactive guide',
          },
          {
            identifier: 'refine.revision_3.submit_comments',
            text: 'Submit comments',
          },
          {
            identifier: 'refine.revision_3.confirm_measurments',
            text: 'Measurments confirmation',
          },
          {
            identifier: 'refine.revision_3.confirm_appliance',
            text: 'Appliance confirmation',
          },
          {
            identifier: 'refine.revision_3.schedule_next_revision',
            text: 'Schedule next Revision Check-in Call',
          },
        ],
      },
      {
        title: 'Follow up',
        items: [
          {
            identifier: 'refine.revision_3.in_progress_msg',
            text: 'Send follow-up message 06-M1 Design Review, "Your revised design is now in progress"',
          },
          {
            identifier: 'refine.revision_3.upload_detailed_quote',
            text: 'Upload revised Design Detailed Quote',
          },
          {
            identifier: 'refine.revision_3.to_internal_review',
            text: 'Send revised design to Internal Review (only 3rd Revision)',
          },
          {
            identifier: 'refine.revision_3.upload_winner_3d',
            text: 'Send revised design to Internal Review (only 3rd Revision)',
          },
          {
            identifier: 'refine.revision_3.confirm_finishes',
            text: 'Confirm finishes & any details on Visual Quote are still accurate',
          },
          {
            identifier: 'refine.revision_3.publish',
            text: 'Publish Design!',
          },
          {
            identifier: 'refine.revision_3.design_available_msg',
            text: 'Send Message 06-M2 Design Review, "Your revised design is available to review"',
          },
          {
            identifier: 'refine.revision_3.next_review_msg',
            text: 'If needed, send follow-up message 06-M3 Design Review, "Let\'s schedule our next Design Review call"',
          },
          {
            identifier: 'refine.revision_3.schedule_next_review',
            text: 'Next Design Review Check-In Call Scheduled?',
          },
        ],
      },
    ],
  },
  extra_room: {
    title: 'Extra Room',
    items: [
      {
        identifier: 'refine.extra_room.in_dashboard_msg',
        text: 'Send message 06-ME1 Extra Room, "Extra Room is available on your dashboard" (setup + payment if needed)',
      },
      {
        identifier: 'refine.extra_room.review_measurments',
        text: 'Review any measurements',
      },
      {
        identifier: 'refine.extra_room.review_appliances',
        text: 'Review any appliances',
      },
      {
        identifier: 'refine.extra_room.plan_dimensions',
        text: 'Add floor plan and ceiling/wall dimensions to "Measurements"',
      },
      {
        identifier: 'refine.extra_room.before_start_msg',
        text: 'Send message 06-ME2 Extra Room, "Before I can get started on your extra room"',
      },
      {
        identifier: 'refine.extra_room.in_progress_msg',
        text: 'Send message 06-ME3 Extra Room, "Your extra room design is now in progress"',
      },
      {
        identifier: 'refine.extra_room.upload_detailed_quote',
        text: 'Upload Extra Room Design Detailed Quote',
      },
      {
        identifier: 'refine.extra_room.to_internal_review',
        text: 'Send design to Internal Review (optional)',
      },
      {
        identifier: 'refine.extra_room.upload_winner_3d',
        text: 'Upload Winner 3D perspectives to "Visual Quote"',
      },
      {
        identifier: 'refine.extra_room.confirm_finishes',
        text: 'Confirm finishes & any details on Visual Quote are accurate',
      },
      {
        identifier: 'refine.extra_room.publish',
        text: 'Publish Design!',
      },
      {
        identifier: 'refine.extra_room.design_available_msg',
        text: 'Send message 06-ME4 Extra Room, "Your extra room design is available to review"',
      },
      {
        identifier: 'refine.extra_room.approved',
        text: 'Extra Room Design Approved',
      },
      {
        identifier: 'refine.extra_room.create_form_order',
        text: 'Create FORM order',
      },
      {
        identifier: 'refine.extra_room.publish_form_order',
        text: 'Publish FORM order',
      },
      {
        identifier: 'refine.extra_room.export_design',
        text: 'Export and save only the Approved Design project file to dropbox',
      },
      {
        identifier: 'refine.extra_room.final_notes',
        text: 'Add final project and render notes',
      },
      {
        identifier: 'refine.extra_room.confirm_payment',
        text: 'Confirm Extra Room Balance paid!',
      },
    ],
  },
  approval: {
    title: 'Approval',
    groups: [
      {
        title: 'Prepare',
        items: [
          {
            identifier: 'refine.approval.design_approved',
            text: 'Design Approved',
          },
          {
            identifier: 'refine.approval.create_form_order',
            text: 'Create FORM order',
          },
          {
            identifier: 'refine.approval.publish_form_order',
            text: 'Publish FORM order',
          },
          {
            identifier: 'refine.approval.next_steps_msg',
            text: 'Send message 07-M1 Design Approved, "Next steps after approving your design"',
          },
          {
            identifier: 'refine.approval.export_design',
            text: 'Export and save only the Approved Design project file to dropbox',
          },
          {
            identifier: 'refine.approval.final_notes',
            text: 'Add final project and render notes',
          },
          {
            identifier: 'refine.approval.upload_extra_room_visual_quote',
            text: 'Upload Extra Room Design Detailed Quote',
          },
        ],
      },
      {
        title: 'Follow up',
        items: [
          {
            identifier: 'refine.approval.confirm_payment',
            text: 'Confirm Balance paid!',
          },
          {
            identifier: 'refine.approval.payment_received_msg',
            text: 'Send message 08-M1 Balance paid, "Your balance payment has been received"',
          },
          {
            identifier: 'refine.approval.notify_td',
            text: 'Send notification to TD (temporary)',
          },
          {
            identifier: 'refine.approval.schedule_handover_call',
            text: 'Handover Call Scheduled 4 weeks out',
          },
          {
            identifier: 'refine.approval.confirmed_ack',
            text: 'ACK confirmed',
          },
          {
            identifier: 'refine.approval.td_visual_upload',
            text: 'TD uploaded Final Visuals (optional)',
          },
          {
            identifier: 'refine.approval.td_install_pack_upload',
            text: 'TD uploaded Install Pack (optional)',
          },
        ],
      },
    ],
  },
}
