import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import moment from 'moment'
import { Grid, Table } from 'semantic-ui-react'

import OrderCellContentPlacement from 'components/admin/order-form/table-cell-content-placement'
import { RoomPageTitle } from 'components/admin/page-title'
import RoomLayout from 'components/admin/project/room/layout'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { parseRenders, parseLatestPlan } from 'context/design/utils'
import { formatDollars } from 'context/dollar-utils'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomDesignMutate from 'context/room/use-design-mutate'
import { parseSupplierOrdersByType } from 'context/supplier-order/utils'
import Button from 'design-system/components/button'
import StatusLabel from 'design-system/components/status-label/status-label'
import { Design } from 'types/design'
import { StatusMode } from 'types/utils'

const Designs = (
  props: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  const { project } = useProject()
  const { designs, room } = useRoom()
  const { deleteDesignAndRefetch } = useRoomDesignMutate()
  const [designToDelete, setDesignToDelete] = useState<Design>()

  return (
    <RoomLayout>
      <RoomPageTitle room={room} viewName="Designs" />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button
              kind="solid"
              text="Create Design"
              fontAwesomeIcon="plus"
              onClick={() => {
                navigate(
                  `/admin/projects/${project.id}/rooms/${room?.id}/designs/create`,
                )
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ paddingBottom: '20px' }}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Publish Status</Table.HeaderCell>
                  <Table.HeaderCell>Review Status</Table.HeaderCell>
                  <Table.HeaderCell>Render Status</Table.HeaderCell>
                  <Table.HeaderCell>External Status</Table.HeaderCell>
                  <Table.HeaderCell>Order and Production</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {designs.map((d, i) => {
                  const {
                    latestPlanInternalReviewMode,
                    latestPlanInternalReviewText,
                    latestPlanExternalReviewMode,
                    latestPlanExternalReviewText,
                  } = parseLatestPlan(d)
                  const {
                    rendersReleased,
                    rendersUnreleased,
                    rendersStatusMode,
                    rendersStatusText,
                  } = parseRenders(d)

                  const {
                    supplierOrdersRegular,
                    supplierOrdersRemedial,
                    supplierOrdersAddOn,
                  } = parseSupplierOrdersByType(
                    d.supplierOrders.map((so) => ({ ...so, design: d })),
                  )

                  return (
                    <Table.Row key={i}>
                      <Table.Cell width={2}>
                        <p className="no-margin">{d.name}</p>
                        <span className="small gray">
                          {moment(d.createdAt).format('MM/DD/YYYY')}
                        </span>
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {formatDollars(d.metadata?.price || 0)}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <StatusLabel
                          type={
                            d.metadata.publishedAt
                              ? StatusMode.Confirmed
                              : StatusMode.OpenForEdit
                          }
                          text={
                            d.metadata.publishedAt ? 'Published' : 'Unpublished'
                          }
                        />
                        <span className="small gray">
                          {rendersReleased.length} visuals
                        </span>
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {latestPlanInternalReviewMode ? (
                          <StatusLabel
                            type={latestPlanInternalReviewMode}
                            text={latestPlanInternalReviewText.summary}
                            description={latestPlanInternalReviewText.text}
                          />
                        ) : null}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <StatusLabel
                          type={rendersStatusMode}
                          text={rendersStatusText?.summary}
                          description={rendersStatusText?.text}
                        />
                        <p className="small gray">
                          {rendersUnreleased.length} visuals pending
                        </p>
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {latestPlanExternalReviewMode && (
                          <StatusLabel
                            type={latestPlanExternalReviewMode}
                            text={latestPlanExternalReviewText.summary}
                            description={latestPlanExternalReviewText.text}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <OrderCellContentPlacement order={d.order} />
                        {!!supplierOrdersRegular.length && (
                          <p className="small gray no-margin">
                            {supplierOrdersRegular.length} regular supplier
                            order(s)
                          </p>
                        )}
                        {!!supplierOrdersRemedial.length && (
                          <p className="small gray no-margin">
                            {supplierOrdersRemedial.length} remedial supplier
                            order(s)
                          </p>
                        )}
                        {!!supplierOrdersAddOn.length && (
                          <p className="small gray no-margin">
                            {supplierOrdersAddOn.length} add-on supplier
                            order(s)
                          </p>
                        )}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '6px',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            kind="solid"
                            fontAwesomeIcon="edit"
                            onClick={() =>
                              navigate(
                                `/admin/projects/${props.project_id}/rooms/${room?.id}/designs/${d.id}`,
                              )
                            }
                          />
                          <Button
                            kind="solid"
                            color="gray"
                            fontAwesomeIcon="eye"
                            onClick={() =>
                              navigate(
                                `/app/projects/${props.project_id}/rooms/${room?.id}/designs/${d.id}/quote`,
                                { state: { isAdmin: true } },
                              )
                            }
                          />
                          <Button
                            kind="solid"
                            color="gray"
                            fontAwesomeIcon="trash-alt"
                            onClick={() => setDesignToDelete(d)}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
              <ConfirmationModal
                firstTitle="Are you sure you want to delete this design?"
                onCancel={() => setDesignToDelete(undefined)}
                onConfirm={async () => {
                  await deleteDesignAndRefetch({
                    variables: {
                      where: {
                        id: designToDelete?.id ?? '',
                      },
                    },
                  })
                  setDesignToDelete(undefined)
                }}
                open={!!designToDelete}
              />
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </RoomLayout>
  )
}

export default Designs
