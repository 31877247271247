import React from 'react'

import { Form } from 'semantic-ui-react'

import CurrencyInput from 'components/shared/currency-input'
import Button from 'design-system/components/button'
import { StyledForm } from 'styles/admin/main'
import { PaymentItem } from 'types/payment'

const PaymentItemsForm = ({
  items,
  setItems,
}: {
  items: PaymentItem[]
  setItems: (s: PaymentItem[]) => void
}) => {
  const handleAdd = () => {
    setItems(items.concat([{ name: '', price: 0, quantity: 1 }]))
  }

  const handleDelete = (deleteId: number) => {
    setItems(items.filter((_, id) => id !== deleteId))
  }

  const onChangeInput = ({
    target: { value, id, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(id)
    items[index] = { ...items[index], [name]: value }
    setItems(items)
  }

  const onChangePrice = ({
    target: { value, id },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(id)
    const newItems = [...items]
    newItems[index] = {
      ...items[index],
      price: Math.round(Number(value.replace(/[, $]+/g, '')) * 100),
    }
    setItems(newItems)
  }

  return (
    <StyledForm>
      <Form.Group widths="equal">
        <Form.Field>
          <Button
            kind="ghost"
            color="gray"
            text="Add Discount"
            fontAwesomeIcon="plus"
            onClick={handleAdd}
            style={{ float: 'right' }}
          />
        </Form.Field>
      </Form.Group>
      {items.map((item, id) => {
        const { name, price, quantity } = item
        return (
          <Form.Group key={id} widths="equal">
            <Form.Input
              fluid
              id={id}
              label="Name"
              name="name"
              onChange={onChangeInput}
              required
              value={name}
            />
            <Form.Input
              fluid
              id={id}
              label="Quantity"
              name="quantity"
              onChange={onChangeInput}
              required
              value={quantity}
            />
            <Form.Field required error={price >= 0}>
              <label>Price</label>
              <CurrencyInput
                id={`${id}`}
                inputMode="numeric"
                maskOptions={{}}
                name="price"
                onChange={onChangePrice}
                placeholder="-$30"
                value={`${price / 100}`}
              />
            </Form.Field>
            <Form.Field
              width={2}
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <Button
                key={id}
                kind="ghost"
                color="gray"
                fontAwesomeIcon="times"
                onClick={() => handleDelete(id)}
              />
            </Form.Field>
          </Form.Group>
        )
      })}
    </StyledForm>
  )
}

export default PaymentItemsForm
