import React from 'react'

import { RouteComponentProps } from '@reach/router'

import TasksDashboard from 'components/admin/task/dashboard'
import useTechDesigner from 'context/dashboard/tech-designer/use'

import TechDesignerLayout from './layout'

const TechDesignerTasks = (_: RouteComponentProps) => {
  const { tasksToDo, tasksUrgent, tasksCompleted, refetchTasks } =
    useTechDesigner()
  return (
    <TechDesignerLayout>
      <TasksDashboard
        refetch={refetchTasks}
        tasksToDo={tasksToDo}
        tasksUrgent={tasksUrgent}
        tasksCompleted={tasksCompleted}
      />
    </TechDesignerLayout>
  )
}

export default TechDesignerTasks
