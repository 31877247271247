import React from 'react'

import { RouteComponentProps } from '@reach/router'

import useAuth from 'context/auth/use'
import ProjectMessageManyProvider from 'context/project/message/provider-many'
import CommentNoteManyProvider from 'context/project/project-notes/provider-many'
import ProjectProvider from 'context/project/provider'
import ProjectEventsManyProvider from 'context/project-event/provider-many'

const ProjectIndex = ({
  children,
  ...rest
}: RouteComponentProps<{ project_id: string }> & {
  children?: React.ReactNode
}) => {
  const { formUserId } = useAuth()
  return (
    <ProjectProvider {...rest}>
      <ProjectMessageManyProvider>
        <ProjectEventsManyProvider
          defaultFilters={{
            projectId: rest.project_id,
            take: 40,
            sortBy: 'createdAt',
            sortDirection: 'descending',
            newFor: {
              id: formUserId,
            },
          }}
          formUserId={formUserId}
        >
          <CommentNoteManyProvider
            defaultFilters={{
              projectId: rest.project_id,
              take: 20,
              forMe: {
                id: formUserId,
              },
            }}
            formUserId={formUserId}
          >
            {children}
          </CommentNoteManyProvider>
        </ProjectEventsManyProvider>
      </ProjectMessageManyProvider>
    </ProjectProvider>
  )
}

export default ProjectIndex
