import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import { DropdownProps, Form } from 'semantic-ui-react'

import {
  getCalendarTypeOptions,
  getDefaultMeeting,
  getDurationOptions,
  MeetingTypeOptionsProject,
} from 'context/meeting/utils'
import useProject from 'context/project/use'
import useProjectMeetingMutate from 'context/project/use-meeting-mutate'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import { Meeting, MeetingTypeProject } from 'types/meeting'
import { User } from 'types/user'

const now = moment().format('MM-DD-YYYY hh:mm a')

interface MeetingCreateUpdateModalProps {
  meeting?: Meeting
  open: boolean
  onClose: () => void
}

const MeetingCreateUpdateModal = ({
  meeting,
  open,
  onClose,
}: MeetingCreateUpdateModalProps) => {
  const { primaryDesigner, project } = useProject()
  const {
    createMeetingAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateMeetingAndRefetch,
  } = useProjectMeetingMutate()
  const [state, setState] = useState<Meeting>(
    meeting ||
      (getDefaultMeeting(
        MeetingTypeProject.CHECK_IN,
        primaryDesigner as User,
      ) as Meeting),
  )

  useEffect(() => {
    setState(
      meeting ||
        (getDefaultMeeting(
          MeetingTypeProject.CHECK_IN,
          primaryDesigner as User,
        ) as Meeting),
    )
  }, [meeting])

  const onChangeDuration = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    const duration = parseFloat(value as string)
    const defaultMeeting = getDefaultMeeting(
      state.metadata.type,
      primaryDesigner as User,
      duration,
      state.metadata.calendar,
    ) as Meeting

    setState({
      ...state,
      calendlyUrl: defaultMeeting?.calendlyUrl as string,
      duration: defaultMeeting?.duration as number,
      metadata: {
        ...state.metadata,
        ...defaultMeeting?.metadata,
      },
    })
  }

  const onChangeStartTime = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      startTime: moment(value, 'MM-DD-YYYY hh:mm a').isValid()
        ? moment(value, 'MM-DD-YYYY hh:mm a').toDate()
        : null,
    })
  }

  const onChangeCustomerNoShow = () => {
    setState({
      ...state,
      metadata: {
        ...state.metadata,
        customerNoShow: !state.metadata.customerNoShow,
      },
    })
  }

  const onChangeCalendlyUrl = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      calendlyUrl: value,
    })
  }

  const onChangeRecording = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      metadata: {
        ...state.metadata,
        recording: value,
      },
    })
  }

  const onChangeDropdown = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    const v = value as MeetingTypeProject
    const defaultMeeting = getDefaultMeeting(
      v,
      primaryDesigner as User,
      state.duration,
      state.metadata.calendar,
    )

    setState({
      ...state,
      calendlyUrl: defaultMeeting?.calendlyUrl as string,
      duration: defaultMeeting?.duration,
      metadata: {
        ...state.metadata,
        ...defaultMeeting?.metadata,
      },
    })
  }

  const onCalendarChange = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    const defaultMeeting = getDefaultMeeting(
      state.metadata.type,
      primaryDesigner as User,
      state.duration,
      value as 'normal' | 'urgent',
    )

    setState({
      ...state,
      calendlyUrl: defaultMeeting?.calendlyUrl as string,
      metadata: {
        ...state.metadata,
        calendar: value as 'normal' | 'urgent',
      },
    })
  }

  const onSave = async () => {
    if (meeting)
      await updateMeetingAndRefetch({
        variables: {
          data: {
            calendlyUrl: state.calendlyUrl,
            duration: state.duration,
            metadata: state.metadata,
            startTime: state.startTime || null,
          },
          where: {
            id: meeting.id as string,
          },
        },
      })
    else
      await createMeetingAndRefetch({
        variables: {
          data: {
            calendlyUrl: state.calendlyUrl,
            duration: state.duration,
            metadata: state.metadata,
            project: { connect: { id: project.id } },
            staffMember: {
              connect: {
                id: primaryDesigner?.id as string,
              },
            },
            startTime: state.startTime || null,
          },
        },
      })
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title={meeting ? 'Edit Meeting' : 'Create Meeting'}
      saveButton={{
        disabled: !state.metadata.type || !state.duration || !state.calendlyUrl,
        onClick: onSave,
        loading: loadingCreate || loadingUpdate,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Field>
          <label>Type</label>
          <Form.Dropdown
            name="type"
            onChange={onChangeDropdown}
            options={MeetingTypeOptionsProject}
            placeholder="Meeting Type"
            selection
            value={state.metadata.type || ''}
          />
        </Form.Field>
        <Form.Field>
          <label>Calendar</label>
          <Form.Dropdown
            name="calendar"
            onChange={onCalendarChange}
            options={getCalendarTypeOptions(state.metadata.type)}
            placeholder="Calendar"
            selection
            value={state.metadata.calendar || 'normal'}
          />
        </Form.Field>
        <Form.Field>
          <label>Duration</label>
          <Form.Dropdown
            disabled={!state.metadata.type}
            placeholder="Meeting Duration"
            selection
            options={getDurationOptions(state.metadata.type)}
            onChange={onChangeDuration}
            value={state.duration?.toString() || ''}
          />
        </Form.Field>
        <Form.Field>
          <label>Calendly URL</label>
          <Form.Input
            name="calendlyUrl"
            onChange={onChangeCalendlyUrl}
            placeholder="Calendly URL"
            value={state.calendlyUrl || ''}
          />
        </Form.Field>
        <Form.Field>
          <label>Start Time OR Leave Blank For Customer to Schedule</label>
          <DateTimeInput
            closable
            autoComplete="off"
            className="example-calendar-input"
            dateFormat="MM-DD-YYYY hh:mm a"
            timeFormat="ampm"
            duration={0}
            hideMobileKeyboard
            iconPosition="left"
            initialDate={now}
            name="time"
            onChange={onChangeStartTime}
            placeholder="Time"
            value={
              state.startTime
                ? moment(state.startTime).format('MM-DD-YYYY hh:mm a')
                : ''
            }
          />
        </Form.Field>
        <Form.Field>
          <label>Link to Recording</label>
          <Form.Input
            name="recording"
            onChange={onChangeRecording}
            placeholder="Link to Recording"
            value={state.metadata.recording || ''}
          />
        </Form.Field>
        <FormCheckbox
          checked={state.metadata?.customerNoShow}
          label="Customer No Show"
          onChange={onChangeCustomerNoShow}
          style={{ fontWeight: 'bold', marginTop: '10px' }}
        />
      </StyledForm>
    </Modal>
  )
}

export default MeetingCreateUpdateModal
