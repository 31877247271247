import { isDesignApproved } from 'context/design/utils'
import { Issue, ResolutionType } from 'types/issue'

export enum IssueStatusMode {
  Completed = 'Completed',
  Missing = 'Missing',
  Pending = 'Pending',
}

export const parseIssueStatus = (
  issue: Issue,
): {
  status: IssueStatusMode
  text: string
}[] => {
  if (issue.resolutionType === ResolutionType.REMEDIAL) {
    return [
      ...(!issue.delivery
        ? [
            {
              status: IssueStatusMode.Missing,
              text: 'Missing Delivery',
            },
          ]
        : [
            {
              status: IssueStatusMode.Completed,
              text: 'Delivery Attached',
            },
          ]),
      ...(!issue.data?.orderNumber
        ? [
            {
              status: IssueStatusMode.Missing,
              text: 'Missing ACK Number',
            },
          ]
        : [
            {
              status: IssueStatusMode.Completed,
              text: 'ACK Attached',
            },
          ]),
    ]
  }

  //design status
  let designStatus
  if (!issue.designResolving)
    designStatus = { status: IssueStatusMode.Missing, text: 'Missing Design' }
  else if (isDesignApproved(issue.designResolving))
    designStatus = {
      status: IssueStatusMode.Completed,
      text: 'Design Approved',
    }
  else
    designStatus = { status: IssueStatusMode.Pending, text: 'Design Attached' }

  //order status
  let orderStatus
  const order = issue.designResolving?.order
  if (!order)
    orderStatus = {
      status: IssueStatusMode.Missing,
      text: 'Missing FORM Order',
    }
  else if (order.placedAt)
    orderStatus = {
      status: IssueStatusMode.Completed,
      text: 'FORM Order Placed',
    }
  else
    orderStatus = {
      status: IssueStatusMode.Pending,
      text: 'Awaiting FORM Order Placement',
    }

  return [designStatus, orderStatus]
}
