import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Deliveries from 'components/admin/delivery/index-many'
import useCustomerSupport from 'context/dashboard/customer-support/use'
import DeliveryManyProvider from 'context/delivery/provider-many'

import CustomerSupportLayout from './layout'

const CustomerSupportDeliveries = (_: RouteComponentProps) => {
  const { userId, userName } = useCustomerSupport()
  return (
    <CustomerSupportLayout>
      <DeliveryManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          take: 30,
        }}
        skipLoader
      >
        <Deliveries />
      </DeliveryManyProvider>
    </CustomerSupportLayout>
  )
}

export default CustomerSupportDeliveries
