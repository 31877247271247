import { useQuery } from '@apollo/client'

import {
  GET_ARCHIVIZER_PRODUCTS,
  GetArchivizerProductsPayload,
  GetArchivizerProductsVariables,
} from 'queries/archivizer'

const useArchiverProductsMany = (opts?: {
  variables: GetArchivizerProductsVariables
  skip: boolean
}) => {
  const { data: { renderProps } = { renderProps: [] }, loading } = useQuery<
    GetArchivizerProductsPayload,
    GetArchivizerProductsVariables
  >(GET_ARCHIVIZER_PRODUCTS, {
    skip: opts?.skip,
    variables: opts?.variables,
  })

  return {
    loading,
    renderProps,
  }
}

export default useArchiverProductsMany
