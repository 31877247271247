import { gql } from '@apollo/client'

export type GetOrderLogsForbulkImportPayload = {
  orderLogs: RawOrderLog[]
}

export const GET_ORDER_LOGS_FOR_BULK_IMPORT = gql`
  query getOrderLogsForBulkImport {
    orderLogs: getOrderLogsForBulkImport {
      zohoId
      ackNumber
      firstAttemptCallToEndPoint
      firstAttemptCallOutcome
      dateFinalMileContactCommittedToDelivery
      dateAgreedForDelivery
      storageStartDate
      monthsInStorage
      schedulingNotes
      finalMileDeliverdToFinalDestination
    }
  }
`

export type BulkImportOrderLogsVariables = {
  data: { orderLogs: Omit<NobiliaOrderLog, 'Ack_No'>[] }
}

export const BULK_IMPORT_ORDER_LOGS = gql`
  mutation bulkImportOrderLogs($data: OrderLogBulkImportUpdateInput) {
    bulkImportOrderLogs(data: $data)
  }
`

export type RawOrderLog = {
  [Key in keyof typeof RawOrderLogColumnHeader]: string
}

export type NobiliaOrderLog = {
  id: string
  Ack_No?: string
  st_Attempt_for_Delivery_Appointment?: string
  st_Attempt_Outcome?: string
  Final_Mile_Delivery_Date_Scheduled?: string
  Scheduled_Delivery_Date?: string
  Storage_Start_Date?: string
  Months_in_Storage?: string
  Scheduling_Notes?: string
  Final_Delivery_Date_Actual?: string
}

export enum RawOrderLogColumnHeader {
  zohoId = 'Order Log ID',
  ackNumber = 'Factory Ackn.No.',
  firstAttemptCallToEndPoint = '1st Attempt call to end-point',
  firstAttemptCallOutcome = '1st Attempt-Outcome',
  dateFinalMileContactCommittedToDelivery = 'Date the final mile contact committed to delivery',
  dateAgreedForDelivery = 'Date agreed for delivery',
  storageStartDate = 'Storage Start Date',
  monthsInStorage = 'Months in Storage',
  schedulingNotes = 'Scheduling Notes',
  finalMileDeliverdToFinalDestination = 'Final mile delivered to final destination',
}
