import React, { useState } from 'react'

import { Divider, Form, Grid } from 'semantic-ui-react'

import AdditionalItemCard from 'components/shared/catalog/nobilia-product-class/additional-item-card'
import useAuth from 'context/auth/use'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { NodeOption } from 'planner/objects/node'
import { ProductBase } from 'planner/objects/product'
import NobiliaProductBase from 'planner/objects/products/nobilia/base'
import { StyledForm } from 'styles/admin/main'
import { NobiliaProductClass } from 'types/nobilia-product-class'

const OptionsModal = ({
  buttonText,
  nodeAdditionalItems,
  nodeOptions,
  onChange,
  product,
}: {
  buttonText: string
  nodeAdditionalItems: ProductBase[]
  nodeOptions: NodeOption[]
  onChange: (option: NodeOption[], additionalItems: ProductBase[]) => void
  product: NobiliaProductBase
}) => {
  const { isAdmin } = useAuth()
  const newProduct = product
  const { globalRestrictionsIndex } = useNobiliaOptions()

  const [additionalItems, setAdditionalItems] =
    useState<ProductBase[]>(nodeAdditionalItems)
  const [options, setOptions] = useState<NodeOption[]>(nodeOptions)
  const [showModal, toggleModal] = useState<boolean>(false)

  const additionalItemOptions =
    product.productClass.additionalItemGroups.reduce<
      {
        productClass: NobiliaProductClass | undefined
        maxQuantity: number
      }[]
    >((acc, g) => {
      if (g.additionalItems)
        g.additionalItems.map((i, idx) =>
          acc.push({
            productClass: product.productClass?.additionalItems?.find(
              (a) => a.orderId === i,
            ),
            maxQuantity: g.quantities[idx],
          }),
        )
      return acc
    }, [])

  return (
    <Modal
      title="Configure cabinet"
      onClose={() => toggleModal(false)}
      onOpen={() => (!isAdmin ? null : toggleModal(true))}
      open={showModal}
      trigger={<Button kind="solid" disabled={!isAdmin} text={buttonText} />}
      size="large"
      saveButton={{
        text: 'Add',
        onClick: async () => {
          onChange(
            options,
            additionalItems.filter((a) => a.quantity > 0),
          )
          toggleModal(false)
        },
      }}
      cancelButton={{
        onClick: () => {
          setAdditionalItems(nodeAdditionalItems)
          setOptions(nodeOptions)
          toggleModal(false)
        },
      }}
    >
      <h3>{product.productClass.orderId}</h3>
      <p className="subtitle">Options</p>
      <Divider />
      <StyledForm>
        <Form.Group widths={3} style={{ flexWrap: 'wrap' }}>
          {options.map((o) => (
            <Form.Field key={o.id}>
              <label>{o.description}</label>
              <Form.Dropdown
                fluid
                clearable={o.id !== '1'}
                options={o.options}
                onChange={(_, { value }) => {
                  newProduct.applyNodeOptions(
                    options.map((oldO) =>
                      oldO.id === o.id
                        ? { ...o, value: value as string }
                        : oldO,
                    ),
                  )
                  setOptions(newProduct.getNodeOptions(globalRestrictionsIndex))
                }}
                selection
                value={o.value}
              />
            </Form.Field>
          ))}
        </Form.Group>
      </StyledForm>
      {additionalItemOptions.length ? (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p className="subtitle">Additional Items</p>
              <Divider />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} stretched>
            {additionalItemOptions.map((a) => (
              <Grid.Column key={a.productClass?.id}>
                <AdditionalItemCard
                  additionalItemProductClass={
                    a.productClass as NobiliaProductClass
                  }
                  maxQuantity={a.maxQuantity}
                  onAdditionalItemChange={(additionalItem) => {
                    setAdditionalItems([
                      ...additionalItems.filter(
                        (a) =>
                          a.productClass.id !== additionalItem.productClass?.id,
                      ),
                      additionalItem as ProductBase,
                    ])
                  }}
                  quantity={
                    additionalItems.find(
                      (_a) => _a.productClass.id === a.productClass?.id,
                    )?.quantity || 0
                  }
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      ) : null}
    </Modal>
  )
}

export default OptionsModal
