import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormDropdownValue,
} from 'components/admin/filters-search'
import useNobiliaProductClassMany from 'context/nobilia-product-class/use-many'

export type NobiliaProductClassFilterValues = {
  section: IFormDropdownValue
}

const useNobiliaProductClassFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useNobiliaProductClassMany()

  const filters: DynamicFilterGroup<NobiliaProductClassFilterValues>[] =
    useMemo(() => {
      return [
        {
          filters: [
            {
              filterId: 'section',
              type: DynamicFilterTypes.DROPDOWN,
              label: 'Section',
              options: sectionOptions,
            },
          ],
        },
      ]
    }, [])

  const defaultFilters = useMemo(() => {
    const nobiliaProductClassFilters: Partial<NobiliaProductClassFilterValues> =
      {}

    if (queryFilters.section)
      nobiliaProductClassFilters.section = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.section,
          text: queryFilters.section,
        },
      }
    return nobiliaProductClassFilters
  }, [queryFilters])

  const handleApplyFilters = (data: NobiliaProductClassFilterValues) => {
    setQueryFilters({
      catalog: queryFilters.catalog,
      section: data.section?.selection?.value ?? '',
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useNobiliaProductClassFiltersSearch

const sectionOptions = [
  {
    key: '3.20',
    text: '3.20 Base',
    value: '3.20',
  },
  {
    key: '3.21',
    text: '3.21 Base Corners',
    value: '3.21',
  },
  {
    key: '3.22',
    text: '3.22 Base Half Corners',
    value: '3.22',
  },
  {
    key: '3.23',
    text: '3.23 Base Shelves',
    value: '3.23',
  },
  {
    key: '3.24',
    text: '3.24 Base Angle Fillers',
    value: '3.24',
  },
  {
    key: '3.25',
    text: '3.25 Base Fillers',
    value: '3.25',
  },
  {
    key: '3.30',
    text: '3.30 Base Cooker Housing',
    value: '3.30',
  },
  {
    key: '3.31',
    text: '3.31 Base Hot Plate',
    value: '3.31',
  },
  {
    key: '3.32',
    text: '3.32 Base Sink',
    value: '3.32',
  },
  {
    key: '3.33',
    text: '3.33 Base Sink Half Corner',
    value: '3.33',
  },
  {
    key: '3.34',
    text: '3.34 Base Appliance Front',
    value: '3.34',
  },
  {
    key: '3.40',
    text: '3.40 Base XL',
    value: '3.40',
  },
  {
    key: '3.41',
    text: '3.41 Base XL Corners',
    value: '3.41',
  },
  {
    key: '3.42',
    text: '3.42 Base XL Half Corners',
    value: '3.42',
  },
  {
    key: '3.44',
    text: '3.44 Base XL Angle Fillers',
    value: '3.44',
  },
  {
    key: '3.45',
    text: '3.45 Base XL Fillers',
    value: '3.45',
  },
  {
    key: '3.50',
    text: '3.50 Base XL Cooker Housing',
    value: '3.50',
  },
  {
    key: '3.51',
    text: '3.51 Base XL Hot Plate',
    value: '3.51',
  },
  {
    key: '3.52',
    text: '3.52 Base XL Sink',
    value: '3.52',
  },
  {
    key: '3.53',
    text: '3.53 Base XL Sink Half Corner',
    value: '3.53',
  },
  {
    key: '3.54',
    text: '3.54 Base XL Appliance Front',
    value: '3.54',
  },
  {
    key: '3.74',
    text: '3.74 Base Special Angle Filler',
    value: '3.74',
  },
  {
    key: '4.10',
    text: '4.10 Carcase Material',
    value: '4.10',
  },
  {
    key: '5.10',
    text: '5.10 Panel Upright Base',
    value: '5.10',
  },
  {
    key: '5.20',
    text: '5.20 Panel Upright Base XL',
    value: '5.20',
  },
  {
    key: '5.30',
    text: '5.30 Panel Upright Tall',
    value: '5.30',
  },
  {
    key: '5.31',
    text: '5.31 Panel Upright Tall XL',
    value: '5.31',
  },
  {
    key: '5.32',
    text: '5.32 Panel Upright Tall 1',
    value: '5.32',
  },
  {
    key: '5.33',
    text: '5.33 Panel Upright Tall 1X',
    value: '5.33',
  },
  {
    key: '5.34',
    text: '5.34 Panel Upright Tall 2',
    value: '5.34',
  },
  {
    key: '5.35',
    text: '5.35 Panel Upright Tall 2X',
    value: '5.35',
  },
  {
    key: '5.36',
    text: '5.36 Panel Upright Tall 3',
    value: '5.36',
  },
  {
    key: '5.37',
    text: '5.37 Panel Upright Tall 3X',
    value: '5.37',
  },
  {
    key: '5.38',
    text: '5.38 Panel Upright Tall Dresser',
    value: '5.38',
  },
  {
    key: '5.40',
    text: '5.40 Panel Upright Wall',
    value: '5.40',
  },
  {
    key: '5.60',
    text: '5.60 Shelf Wall',
    value: '5.60',
  },
  {
    key: '6.00',
    text: '6.00 Tall Equipment',
    value: '6.00',
  },
  {
    key: '6.20',
    text: '6.20 Tall',
    value: '6.20',
  },
  {
    key: '6.22',
    text: '6.22 Tall Fillers',
    value: '6.22',
  },
  {
    key: '6.30',
    text: '6.30 Tall XL',
    value: '6.30',
  },
  {
    key: '6.32',
    text: '6.32 Tall XL Fillers',
    value: '6.32',
  },
  {
    key: '6.40',
    text: '6.40 Tall Special',
    value: '6.40',
  },
  {
    key: '6.50',
    text: '6.50 Tall 1',
    value: '6.50',
  },
  {
    key: '6.51',
    text: '6.51 Tall 1 Housings',
    value: '6.51',
  },
  {
    key: '6.52',
    text: '6.52 Tall 1 Fillers',
    value: '6.52',
  },
  {
    key: '6.60',
    text: '6.60 Tall 1X',
    value: '6.60',
  },
  {
    key: '6.61',
    text: '6.61 Tall 1X Housings',
    value: '6.61',
  },
  {
    key: '6.62',
    text: '6.62 Tall 1X Fillers',
    value: '6.62',
  },
  {
    key: '6.70',
    text: '6.70 Tall 2',
    value: '6.70',
  },
  {
    key: '6.71',
    text: '6.71 Tall 2 Housings',
    value: '6.71',
  },
  {
    key: '6.72',
    text: '6.72 Tall 2 Fillers',
    value: '6.72',
  },
  {
    key: '6.80',
    text: '6.80 Tall 2X',
    value: '6.80',
  },
  {
    key: '6.81',
    text: '6.81 Tall 2X Housings',
    value: '6.81',
  },
  {
    key: '6.82',
    text: '6.82 Tall 2X Fillers',
    value: '6.82',
  },
  {
    key: '6.90',
    text: '6.90 Tall 3',
    value: '6.90',
  },
  {
    key: '6.91',
    text: '6.91 Tall 3 Housings',
    value: '6.91',
  },
  {
    key: '6.92',
    text: '6.92 Tall 3 Fillers',
    value: '6.92',
  },
  {
    key: '7.10',
    text: '7.10 Wall 360',
    value: '7.10',
  },
  {
    key: '7.20',
    text: '7.20 Wall 1',
    value: '7.20',
  },
  {
    key: '7.21',
    text: '7.21 Wall 1 Corner',
    value: '7.21',
  },
  {
    key: '7.23',
    text: '7.23 Wall 1 Filler',
    value: '7.23',
  },
  {
    key: '7.30',
    text: '7.30 Wall 2',
    value: '7.30',
  },
  {
    key: '7.31',
    text: '7.31 Wall 2 Corner',
    value: '7.31',
  },
  {
    key: '7.32',
    text: '7.32 Wall 2 Half Corner',
    value: '7.32',
  },
  {
    key: '7.33',
    text: '7.33 Wall 2 Filler',
    value: '7.33',
  },
  {
    key: '7.40',
    text: '7.40 Wall 3',
    value: '7.40',
  },
  {
    key: '7.41',
    text: '7.41 Wall 3 Corner',
    value: '7.41',
  },
  {
    key: '7.42',
    text: '7.42 Wall 3 Half Corner',
    value: '7.42',
  },
  {
    key: '7.43',
    text: '7.43 Wall 3 Filler',
    value: '7.43',
  },
  {
    key: '7.60',
    text: '7.60 Wall Dresser',
    value: '7.60',
  },
  {
    key: '7.70',
    text: '7.70 Wall Cooker Hood',
    value: '7.70',
  },
  {
    key: '8.31',
    text: '8.31 Line N Base',
    value: '8.31',
  },
  {
    key: '8.32',
    text: '8.32 Line N Base Corner',
    value: '8.32',
  },
  {
    key: '8.33',
    text: '8.33 Line N Base Half Corner',
    value: '8.33',
  },
  {
    key: '8.34',
    text: '8.34 Line N Base Hot Plate',
    value: '8.34',
  },
  {
    key: '8.35',
    text: '8.35 Line N Base Sink',
    value: '8.35',
  },
  {
    key: '8.36',
    text: '8.36 Line N Base Sink Half Corner',
    value: '8.36',
  },
  {
    key: '8.37',
    text: '8.37 Line N Base Appliance Front',
    value: '8.37',
  },
  {
    key: '8.38',
    text: '8.38 Line N Base Angle Filler',
    value: '8.38',
  },
  {
    key: '8.39',
    text: '8.39 Line N Base Filler',
    value: '8.39',
  },
  {
    key: '8.41',
    text: '8.41 Line N Base XL',
    value: '8.41',
  },
  {
    key: '8.42',
    text: '8.42 Line N Base XL Corner',
    value: '8.42',
  },
  {
    key: '8.43',
    text: '8.43 Line N Base XL Half Corner',
    value: '8.43',
  },
  {
    key: '8.44',
    text: '8.44 Line N Base XL Hot Plate',
    value: '8.44',
  },
  {
    key: '8.45',
    text: '8.45 Line N Base XL Sink',
    value: '8.45',
  },
  {
    key: '8.46',
    text: '8.46 Line N Base XL Sink Half Corner',
    value: '8.46',
  },
  {
    key: '8.47',
    text: '8.47 Line N Base XL Appliance Front',
    value: '8.47',
  },
  {
    key: '8.48',
    text: '8.48 Line N Base XL Angle Filler',
    value: '8.48',
  },
  {
    key: '8.49',
    text: '8.49 Line N Base XL Filler',
    value: '8.49',
  },
  {
    key: '8.50',
    text: '8.50 Line N Tall Equipment',
    value: '8.50',
  },
  {
    key: '8.51',
    text: '8.51 Line N Tall',
    value: '8.51',
  },
  {
    key: '8.52',
    text: '8.52 Line N Tall XL',
    value: '8.52',
  },
  {
    key: '8.53',
    text: '8.53 Line N Tall 1',
    value: '8.53',
  },
  {
    key: '8.54',
    text: '8.54 Line N Tall 1X',
    value: '8.54',
  },
  {
    key: '8.55',
    text: '8.55 Line N Tall 2',
    value: '8.55',
  },
  {
    key: '8.56',
    text: '8.56 Line N Tall 2X',
    value: '8.56',
  },
  {
    key: '8.57',
    text: '8.57 Line N Tall 3',
    value: '8.57',
  },
  {
    key: '8.58',
    text: '8.58 Line N Tall 3X',
    value: '8.58',
  },
  {
    key: '8.60',
    text: '8.60 Line N Tall Vertical Equipment',
    value: '8.60',
  },
  {
    key: '8.64',
    text: '8.64 Line N Tall Vertical 2',
    value: '8.64',
  },
  {
    key: '8.65',
    text: '8.65 Line N Tall Vertical 2X',
    value: '8.65',
  },
  {
    key: '8.66',
    text: '8.66 Line N Tall Vertical 3',
    value: '8.66',
  },
  {
    key: '8.71',
    text: '8.71 Line N Wall 360',
    value: '8.71',
  },
  {
    key: '8.72',
    text: '8.72 Line N Wall 1',
    value: '8.72',
  },
  {
    key: '8.73',
    text: '8.73 Line N Wall 2',
    value: '8.73',
  },
  {
    key: '8.74',
    text: '8.74 Line N Wall 3',
    value: '8.74',
  },
  {
    key: '8.75',
    text: '8.75 Line N Wall Cooker Hood',
    value: '8.75',
  },
  {
    key: 'A.100',
    text: 'A.100 Trays and Organizers',
    value: 'A.100',
  },
]
