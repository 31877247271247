import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomElementClassProvider from 'context/room-element-class/provider'

interface RoomElementClassIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    room_element_class_id: string
  }> {
  children: React.ReactNode
}

const RoomElementClassIndex = (props: RoomElementClassIndexProps) => {
  return (
    <RoomElementClassProvider
      catalog={props.catalog_id}
      room_element_class_id={props.room_element_class_id}
    >
      {props.children}
    </RoomElementClassProvider>
  )
}

export default RoomElementClassIndex
