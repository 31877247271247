// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const Immutable = require('immutable')

export function isMap(obj: any) {
  return Immutable.Iterable.isKeyed(obj)
}
export function isIndexed(obj: any) {
  return Immutable.Iterable.isIndexed(obj)
}

export function op(operation: any, path: any, value?: any) {
  if (operation === 'remove') {
    return { op: operation, path: path }
  }

  return { op: operation, path: path, value: value }
}
