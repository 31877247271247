import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { useProjectEventsMany } from 'context/project-event/provider-many'
import Button from 'design-system/components/button'
import { Hexagon } from 'styles/app/components/icon'
import { Colors } from 'styles/app/system'
import { ScrollBarStyle } from 'styles/app/system'

import { EventDrawer } from './event-drawer'
import { EventFilters } from './event-filters'

type ActivityInboxProps = {
  disableFilters?: boolean
  formUserId: string
}

const ActivityInbox = ({ disableFilters, formUserId }: ActivityInboxProps) => {
  const {
    count,
    fetchedCount,
    fetchMore,
    projectEventsGroupedByDate,
    loadingMore,
    queryFilters,
    refetchAll,
  } = useProjectEventsMany()

  return (
    <div>
      {!disableFilters && <EventFilters formUserId={formUserId} />}
      {projectEventsGroupedByDate.length ? (
        <ActivityContainer>
          <div className="activity-header">
            <p className="no-margin">Activity</p>
            <p className="no-margin">User</p>
            <p className="no-margin">Actions</p>
          </div>
          <div className="drawers" id="event-drawers">
            {projectEventsGroupedByDate.map(([date, groupedEvents], idx) => (
              <EventDrawer
                key={date}
                defaultOpen={idx < 2}
                formUserId={formUserId}
                title={date}
                events={groupedEvents}
                refetch={refetchAll}
                showProjectData={!queryFilters.projectId}
              />
            ))}
            {fetchedCount < count && (
              <Button
                kind="solid"
                text="Load More"
                loading={loadingMore}
                onClick={fetchMore}
                style={{ display: 'block', margin: '20px auto 0 auto' }}
              />
            )}
          </div>
        </ActivityContainer>
      ) : (
        <EmptyState>
          <Hexagon
            styled={{
              background: Colors.blue100,
              color: Colors.blue900,
              fontSize: '24px',
              size: '80px',
            }}
          >
            <FontAwesomeIcon icon={['fal', 'check']} />
          </Hexagon>
          <h3 className="no-margin">You're up to date</h3>
          <Button
            kind="solid"
            text="Refresh"
            fontAwesomeIcon="refresh"
            iconPosition="left"
            onClick={() => refetchAll()}
          />
        </EmptyState>
      )}
    </div>
  )
}

export default ActivityInbox

const ActivityContainer = styled.div`
  border: 1px solid ${Colors.gray200};
  border-radius: 4px;
  width: 100%;

  & > div.activity-header {
    background-color: ${Colors.gray100};
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid ${Colors.gray200};
    display: grid;
    grid-template-columns: calc(16px + 64px + 40px + 28.9% + 40px) auto 104px;
    padding: 8px 24px;
  }

  & > div.drawers {
    max-height: 800px;
    overflow: auto;
    padding: 16px;
    ${ScrollBarStyle}
  }
`

const EmptyState = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
  padding-top: 80px;
  width: 100%;
`
