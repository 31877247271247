import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { sortBy } from 'lodash'
import { Grid, Tab, Table } from 'semantic-ui-react'

import DesignAdvisoryCodeGroupModal from 'components/admin/design-advisory-code-group/create-update-modal'
import DesignAdvisoryCodeModal from 'components/admin/design-advisory-code-group/design-advisory-code/create-update-modal'
import AdminLayout from 'components/admin/layout'
import Loader from 'components/shared/loader'
import Button from 'design-system/components/button'
import {
  FIND_MANY_DESIGN_ADVISORY_CODE_GROUPS,
  FindManyDesignAdvisoryCodeGroupPayload,
} from 'queries/design-advisory-code-group'
import { StyledTab } from 'styles/admin/main'
import {
  DesignAdvisoryCode,
  DesignAdvisoryCodeGroup,
} from 'types/design-advisory-code'

import * as viewUtils from '../utils'

const md = require('markdown-it')({
  breaks: true,
})

const DesignAdvisoryCodeGroups = () => {
  const [showCreateGroup, toggleCreateGroup] = useState<boolean>(false)

  const {
    data: dataGroups,
    loading: loadingGroups,
    refetch,
  } = useQuery<FindManyDesignAdvisoryCodeGroupPayload>(
    FIND_MANY_DESIGN_ADVISORY_CODE_GROUPS,
    {
      fetchPolicy: 'no-cache',
    },
  )

  if (loadingGroups) return <Loader />
  const designAdvisoryCodeGroups = sortBy(
    dataGroups?.designAdvisoryCodeGroups || [],
    (g) => g.index,
  )

  const DesignAdvisoryCodeRow = ({
    designAdvisoryCode,
    refetch,
  }: {
    designAdvisoryCode: DesignAdvisoryCode
    refetch?: () => void
  }) => {
    const [showUpdateModal, toggleUpdateModal] = useState<boolean>(false)

    return (
      <Table.Row>
        <Table.Cell width={4}>{designAdvisoryCode.index}</Table.Cell>
        <Table.Cell width={8}>
          <div
            dangerouslySetInnerHTML={{
              __html: md.render(designAdvisoryCode.description),
            }}
          />
        </Table.Cell>
        <Table.Cell width={2}>
          {designAdvisoryCode.required ? 'Yes' : ''}
        </Table.Cell>
        <Table.Cell collapsing>
          <Button
            kind="solid"
            color="blue"
            fontAwesomeIcon="pencil"
            onClick={() => toggleUpdateModal(true)}
          />
          <DesignAdvisoryCodeModal
            designAdvisoryCode={designAdvisoryCode}
            onCloseModal={() => toggleUpdateModal(false)}
            openModal={showUpdateModal}
            refetch={refetch}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  const DesignAdvisoryCodeGroupTab = ({
    designAdvisoryCodeGroup,
  }: {
    designAdvisoryCodeGroup: DesignAdvisoryCodeGroup
  }) => {
    const [showCodeModal, toggleCodeModal] = useState<boolean>(false)
    const [updateModal, toggleUpdateModal] = useState<boolean>(false)

    return (
      <Tab.Pane as="div">
        <Grid style={{ marginTop: '10px' }}>
          <Grid.Row>
            <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
              <h3>{designAdvisoryCodeGroup.index}</h3>
              <Button
                kind="solid"
                color="blue"
                fontAwesomeIcon="pencil"
                style={{ marginLeft: '20px' }}
                onClick={() => toggleUpdateModal(true)}
              />
              <DesignAdvisoryCodeGroupModal
                designAdvisoryCodeGroup={designAdvisoryCodeGroup}
                onCloseModal={() => toggleUpdateModal(false)}
                openModal={updateModal}
                refetch={refetch}
              />
            </Grid.Column>
          </Grid.Row>
          {designAdvisoryCodeGroup.description && (
            <Grid.Row>
              <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
                <p>{designAdvisoryCodeGroup.description}</p>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Index</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Required</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {designAdvisoryCodeGroup.designAdvisoryCodes.map((d, k) => {
                    return (
                      <DesignAdvisoryCodeRow
                        designAdvisoryCode={d}
                        key={k}
                        refetch={refetch}
                      />
                    )
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button
                kind="solid"
                text="Add Design Advisory Code"
                fontAwesomeIcon="plus"
                onClick={() => toggleCodeModal(true)}
              />
              <DesignAdvisoryCodeModal
                designAdvisoryCodeGroupId={designAdvisoryCodeGroup.id}
                onCloseModal={() => toggleCodeModal(false)}
                openModal={showCodeModal}
                refetch={refetch}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    )
  }

  return (
    <>
      <Button
        kind="solid"
        color="blue"
        text="New Design Advisory Code Group"
        fontAwesomeIcon="plus"
        onClick={() => toggleCreateGroup(true)}
        style={{ float: 'right' }}
      />
      <DesignAdvisoryCodeGroupModal
        onCloseModal={() => toggleCreateGroup(false)}
        openModal={showCreateGroup}
        refetch={refetch}
      />
      <StyledTab
        menu={{ secondary: true, pointing: true }}
        panes={designAdvisoryCodeGroups.map((d) => ({
          key: d.id,
          menuItem: viewUtils.responsiveText(d.index, 10),
          render: () => (
            <DesignAdvisoryCodeGroupTab designAdvisoryCodeGroup={d} />
          ),
        }))}
      />
    </>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <DesignAdvisoryCodeGroups />
    </AdminLayout>
  )
}
