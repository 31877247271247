// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as BABYLON from 'babylonjs'

import * as compose from 'planner/compose'
import { HotspotBase } from 'planner/objects/annotations/hotspot'
import { SectionViewBase } from 'planner/objects/annotations/section-view'
import { CameraBase } from 'planner/objects/camera'
import { NodeBase } from 'planner/objects/node'
import { WallBase } from 'planner/objects/room-elements/wall'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import * as nodeUtils from 'planner/utils/node-utils'
import { RoomElementClass } from 'types/room-element-class'

import { RenderMode, ViewMode } from './types'

export interface RoomPlannerReducerState {
  activeCamera: CameraBase
  activeHotspot: HotspotBase
  activeSectionView: SectionViewBase
  cameraPosition: BABYLON.Vector3
  cameraTarget: BABYLON.Vector3
  drawMode: boolean
  measurementUnit: MeasurementUnit
  moveEnabled: boolean
  moveFrom: [number, number[]]
  recenterCameraTriggeredAt: Date
  recenterPlanTriggeredAt: Date
  renderMode: RenderMode
  roomLoaded: boolean
  sceneRelationshipGraph: compose.NodeRelationshipGraph
  sceneState: NodeBase[]
  sectionViewMode: boolean
  selectedNode: NodeBase
  viewBoxBase: number
  viewBoxPanX: number
  viewBoxPanY: number
  viewMode: ViewMode
}

interface ActivateHotspot {
  type: 'activate_hotspot'
  hotspot: HotspotBase
}

interface ChangeNodes {
  type: 'change_nodes'
  floorRoomElementClass?: RoomElementClass
  moveEnabled?: boolean
  moveFrom?: [number, number[]]
  nodesToAdd: NodeBase[]
  nodesToRemove: NodeBase[]
  selectedNode: NodeBase
  wallBeingDrawn?: WallBase
}

interface DeleteSelectedNode {
  type: 'delete_selected_node'
  floorRoomElementClass?: RoomElementClass
}

interface DeactivateHotspot {
  type: 'deactivate_hotspot'
  hotspot: HotspotBase
}

interface DeselectNode {
  type: 'deselect_node'
}

interface DisableMove {
  type: 'disable_move'
}

interface LoadRoom {
  type: 'load_room'
  nodesToAdd: NodeBase[]
  floorRoomElementClass?: RoomElementClass
}

interface PickNode {
  type: 'pick_node'
  nodeName: string
}

interface SetActiveSectionView {
  type: 'set_active_section_view'
  activeSectionView: SectionViewBase
}

interface SetViewParameters {
  type: 'set_view_parameters'
  cameraPosition: BABYLON.Vector3
  cameraTarget: BABYLON.Vector3
  recenterCameraTriggeredAt?: Date
  recenterPlanTriggeredAt?: Date
  viewBoxBase: number
  viewBoxPanX: number
  viewBoxPanY: number
}

interface SetViewPlan {
  type: 'set_view_plan'
}

interface SetViewScene {
  type: 'set_view_scene'
  camera: CameraBase
  renderMode?: RenderMode
}

interface SetViewSection {
  type: 'set_view_section'
  camera: CameraBase
  renderMode?: RenderMode
}

interface ToggleDrawMode {
  type: 'toggle_draw_mode'
}

interface ToggleMaterials {
  type: 'toggle_materials'
}

interface ToggleMeasurementUnit {
  type: 'toggle_measurement_unit'
  measurementUnit: MeasurementUnit
}

interface ToggleSectionViewMode {
  type: 'toggle_section_view_mode'
}

export type RoomPlannerReducerAction =
  | ActivateHotspot
  | ChangeNodes
  | DeactivateHotspot
  | DeleteSelectedNode
  | DeselectNode
  | DisableMove
  | LoadRoom
  | PickNode
  | SetActiveSectionView
  | SetViewParameters
  | SetViewPlan
  | SetViewScene
  | SetViewSection
  | ToggleDrawMode
  | ToggleMaterials
  | ToggleMeasurementUnit
  | ToggleSectionViewMode

const roomPlannerReducer = (
  state: RoomPlannerReducerState,
  action: RoomPlannerReducerAction,
): Partial<RoomPlannerReducerState> => {
  switch (action.type) {
    case 'activate_hotspot':
      return {
        ...state,
        activeHotspot: action.hotspot,
      }
    case 'change_nodes': {
      const nodesToRemove = action.nodesToRemove
      const nodesToAdd = action.nodesToAdd
      return {
        ...state,
        moveEnabled: action.moveEnabled || false,
        moveFrom: action.moveFrom || null,
        ...compose.deriveNodes(
          state.sceneState,
          nodesToAdd,
          nodesToRemove,
          state.sceneRelationshipGraph,
          {
            floorRoomElementClass: action.floorRoomElementClass,
            isRoomPlanner: true,
            wallBeingDrawn: action.wallBeingDrawn,
          },
        ),
        selectedNode: action.selectedNode,
      }
    }
    case 'deactivate_hotspot':
      return {
        ...state,
        activeHotspot: null,
      }
    case 'delete_selected_node':
      if (state.selectedNode.parent)
        state.selectedNode.parent.removeChild(state.selectedNode)
      return {
        ...state,
        ...compose.deriveNodes(
          state.sceneState,
          [],
          state.sceneState.filter(
            (n) => n === state.selectedNode || n.parent === state.selectedNode,
          ),
          state.sceneRelationshipGraph,
          {
            floorRoomElementClass: action.floorRoomElementClass,
            isRoomPlanner: true,
          },
        ),
        selectedNode: null,
      }
    case 'deselect_node':
      return {
        ...state,
        selectedNode: null,
        moveEnabled: false,
      }
    case 'disable_move':
      return {
        ...state,
        moveEnabled: false,
        moveFrom: null,
      }
    case 'load_room':
      return {
        ...state,
        roomLoaded: true,
        ...compose.deriveNodes(
          state.sceneState,
          action.nodesToAdd,
          [],
          state.sceneRelationshipGraph,
          {
            floorRoomElementClass: action.floorRoomElementClass,
            isRoomPlanner: true,
          },
        ),
      }
    case 'pick_node': {
      const node = nodeUtils.findNodeByName(state.sceneState, action.nodeName)
      if (nodeUtils.isMoveableRoom(node))
        return {
          ...state,
          moveEnabled: true,
          selectedNode: node,
        }
      return {
        ...state,
        selectedNode: node,
      }
    }
    case 'set_active_section_view':
      return {
        ...state,
        activeSectionView: action.activeSectionView,
      }
    case 'set_view_parameters':
      return {
        ...state,
        cameraPosition: action.cameraPosition,
        cameraTarget: action.cameraTarget,
        recenterCameraTriggeredAt:
          action.recenterCameraTriggeredAt || state.recenterCameraTriggeredAt,
        recenterPlanTriggeredAt:
          action.recenterPlanTriggeredAt || state.recenterPlanTriggeredAt,
        viewBoxBase: action.viewBoxBase,
        viewBoxPanX: action.viewBoxPanX,
        viewBoxPanY: action.viewBoxPanY,
      }
    case 'set_view_plan':
      return {
        ...state,
        selectedNode: null,
        viewMode: ViewMode.PLAN,
      }
    case 'set_view_scene':
      return {
        ...state,
        activeCamera: action.camera,
        renderMode: action.renderMode || state.renderMode,
        viewMode: ViewMode.SCENE,
      }
    case 'set_view_section':
      return {
        ...state,
        activeCamera: action.camera,
        renderMode: action.renderMode || state.renderMode,
        viewMode: ViewMode.SECTION,
      }
    case 'toggle_draw_mode':
      return {
        ...state,
        drawMode: !state.drawMode,
      }
    case 'toggle_materials':
      return {
        ...state,
        renderMode:
          state.renderMode === RenderMode.MATERIALS
            ? RenderMode.LINES
            : RenderMode.MATERIALS,
      }
    case 'toggle_measurement_unit':
      return { ...state, measurementUnit: action.measurementUnit }
    case 'toggle_section_view_mode':
      return {
        ...state,
        sectionViewMode: !state.sectionViewMode,
      }
    default:
      return state
  }
}

export default roomPlannerReducer
