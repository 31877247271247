import React from 'react'

import { Grid } from 'semantic-ui-react'

import HeaderAdmin from 'components/admin/header'
import useAuth from 'context/auth/use'
import { AdminMain } from 'styles/admin/main'

const AdminLayout = ({
  children,
  noHeader,
}: React.PropsWithChildren<{ noHeader?: boolean }>) => {
  const { user, logout } = useAuth()
  return (
    <>
      {!noHeader && <HeaderAdmin user={user} logout={logout} />}
      <AdminMain>
        <Grid>
          <Grid.Row>
            <Grid.Column>{children}</Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminMain>
    </>
  )
}

export default AdminLayout
