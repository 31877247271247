import React, { useEffect, useMemo, useState } from 'react'

import { DropdownProps, Form, FormInputProps, Image } from 'semantic-ui-react'

import useGenericProductClassMany from 'context/generic-product-class/use-many'
import { getReferenceImage } from 'context/media/utils-many'
import useRoom from 'context/room/use'
import useRoomGenericProductMutate from 'context/room/use-generic-product-mutate'
import SelectTextCard from 'design-system/components/cards/selection/select-text-card'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { GenericProductGraphQL } from 'types/generic-product'
import { GenericProductClass } from 'types/generic-product-class'

const emptyAppliance: Partial<GenericProductGraphQL> = {
  productClass: undefined,
  data: {
    imageUrl: '',
    productDetails: '',
    linkToProduct: '',
    linkToProductSpec: '',
    size: '',
  },
}

const CreateUpdateApplianceModal = ({
  appliance,
  open,
  onClose,
}: {
  appliance?: Partial<GenericProductGraphQL>
  open: boolean
  onClose: () => void
}) => {
  const { room } = useRoom()
  const {
    createGenericProductAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateGenericProductAndRefetch,
  } = useRoomGenericProductMutate()
  const { genericProductClasses } = useGenericProductClassMany()
  const [state, setState] = useState<Partial<GenericProductGraphQL>>(
    appliance || emptyAppliance,
  )
  useEffect(() => {
    setState(appliance || emptyAppliance)
  }, [open, appliance])

  const sizes = state.productClass?.metadata?.defaultSizes

  useEffect(() => {
    if (sizes?.length === 1)
      setState({
        ...state,
        data: {
          ...state.data,
          size: sizes[0],
        },
      })
  }, [sizes])

  const genericProductClassOptions = useMemo(
    () =>
      genericProductClasses.map((ce: GenericProductClass) => {
        return {
          key: ce.identifier,
          text: (
            <div
              style={{
                alignItems: 'center',
                display: 'inline-flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Image
                style={{
                  margin: '0 12px 0 0',
                  width: '55px',
                  height: '55px',
                }}
                src={getReferenceImage(ce.media)}
              />
              {ce.metadata?.name}
            </div>
          ),
          value: ce.identifier,
        }
      }),
    [genericProductClasses],
  )

  const onChangeProductClass = (
    _: React.ChangeEvent<HTMLInputElement>,
    { value }: { value: string },
  ) => {
    setState({
      ...state,
      productClass: value
        ? genericProductClasses.find((a) => a.identifier === value)
        : undefined,
      data: {
        ...state.data,
        size: '',
      },
    })
  }

  const onChangeInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { name, value }: { name: string; value: string },
  ) =>
    setState({
      ...state,
      data: {
        ...state.data,
        [`${name}`]: value,
      },
    })

  const onSave = async () => {
    if (appliance) {
      await updateGenericProductAndRefetch({
        variables: {
          data: {
            productClass: {
              connect: {
                id: state.productClass?.id ?? '',
              },
            },
            data: state.data,
          },
          where: {
            id: appliance.id ?? '',
          },
        },
      })
    } else {
      await createGenericProductAndRefetch({
        variables: {
          data: {
            data: state.data ?? {},
            productClass: {
              connect: {
                id: state.productClass?.id ?? '',
              },
            },
            quantity: 1,
            room: {
              connect: {
                id: room?.id ?? '',
              },
            },
          },
        },
      })
    }
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title={appliance ? 'Update appliance' : 'Create appliance'}
      saveButton={{
        disabled:
          !state.productClass ||
          !state.data?.size ||
          !state.data?.productDetails ||
          !state.data?.linkToProduct,
        onClick: onSave,
        loading: loadingCreate || loadingUpdate,
      }}
    >
      <StyledForm>
        <Form.Dropdown
          label="Appliance"
          onChange={onChangeProductClass as DropdownProps['onChange']}
          options={genericProductClassOptions}
          placeholder="Select an appliance"
          required
          selection
          value={state.productClass?.identifier}
        />
        {sizes?.length && sizes.length > 1 && (
          <Form.Field required style={{ margin: '0' }}>
            <label>Size</label>
            <Form.Group
              style={{ flexWrap: 'wrap', rowGap: '10px', width: '100%' }}
              widths={5}
            >
              {sizes?.map((s: string, idx) => (
                <Form.Field key={idx}>
                  <SelectTextCard
                    text={s}
                    onClick={() =>
                      setState({
                        ...state,
                        data: {
                          ...state.data,
                          size: s,
                        },
                      })
                    }
                    selected={state.data?.size === s}
                  />
                </Form.Field>
              ))}
            </Form.Group>
          </Form.Field>
        )}
        <Form.Input
          label="Make / Model"
          name="productDetails"
          onChange={onChangeInput as FormInputProps['onChange']}
          required
          value={state.data?.productDetails}
        />
        <Form.Input
          label="Link"
          name="linkToProduct"
          onChange={onChangeInput as FormInputProps['onChange']}
          required
          value={state.data?.linkToProduct}
        />
        <Form.Input
          label="Link to Spec"
          name="linkToProductSpec"
          onChange={onChangeInput as FormInputProps['onChange']}
          value={state.data?.linkToProductSpec}
        />
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateApplianceModal
