import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import MediaModal from 'components/admin/catalog/media/modal'
import Photography from 'components/admin/catalog/media/photography'
import Installation from 'components/admin/catalog/nobilia-product-class//installation'
import AdditionalItems from 'components/admin/catalog/nobilia-product-class/additional-items'
import Summary from 'components/admin/catalog/nobilia-product-class/summary'
import useAuth from 'context/auth/use'
import * as mUtils from 'context/media/utils-many'
import useNobiliaProductClass from 'context/nobilia-product-class/use'
import Button from 'design-system/components/button'
import { ArcRotateCameraBase } from 'planner/objects/cameras/arcrotate'
import { EnvironmentLightBase } from 'planner/objects/lights/environment'
import { HemisphericLightBase } from 'planner/objects/lights/hemispheric'
import { NodeBase } from 'planner/objects/node'
import { createProduct } from 'planner/objects/product-factory'
import NobiliaProductBase from 'planner/objects/products/nobilia/base'
import { StyledBreadcrumb } from 'styles/planner/main'

const Module = (_: RouteComponentProps) => {
  const { isAdmin } = useAuth()
  const { catalog, nobiliaProductClass, refetch } = useNobiliaProductClass()
  const [
    {
      defaultArcRotateCamera,
      defaultAmbient,
      defaultEnvironment,
      defaultLight,
    },
  ] = useState(() => {
    const defaultArcRotateCamera = new ArcRotateCameraBase({
      setActiveOnSceneIfNoneActive: true,
      alpha: (2 * Math.PI) / 3,
      beta: Math.PI / 3,
      radius: 4000,
    })
    const defaultAmbient = new HemisphericLightBase({
      color: BABYLON.Color3.White(),
    })
    const defaultLight = new HemisphericLightBase({
      parent: defaultArcRotateCamera,
    })
    const defaultEnvironment = new EnvironmentLightBase({})
    return {
      defaultArcRotateCamera,
      defaultAmbient,
      defaultEnvironment,
      defaultLight,
    }
  })

  const product = createProduct(nobiliaProductClass) as NobiliaProductBase
  const additionalItemProductClasses = product.productClass.additionalItems

  const sceneNodes: NodeBase[] = [
    defaultArcRotateCamera,
    defaultEnvironment,
    defaultLight,
    defaultAmbient,
  ]

  const sections = [
    {
      key: 'Catalog',
      content: `Catalog ${catalog}`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'ProductsClasses',
      content: 'Products',
      href: `/admin/catalog/${catalog}/nobilia-product-classes`,
    },
    {
      key: 'Products',
      content: product.productClass.orderId,
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
        <Grid.Column>
          <MediaModal
            nobiliaProductClasses={[nobiliaProductClass]}
            refetch={refetch}
            trigger={
              <Button
                kind="solid"
                color="blue"
                text="Upload Media"
                disabled={!isAdmin}
                style={{ float: 'right' }}
                fontAwesomeIcon="cloud-arrow-up"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Summary
            nobiliaProduct={product}
            sceneNodes={sceneNodes}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
      <AdditionalItems
        catalogId={catalog}
        additionalItemProductClasses={additionalItemProductClasses}
        refetch={refetch}
      />
      <Grid.Row>
        <Grid.Column>
          <Installation
            installationMedia={
              mUtils.parseMedia(product.productClass.media).installation
            }
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Photography
            media={product.productClass.media || []}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Module
