import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import FiltersAndSearch from 'components/admin/filters-search'
import { BackToTop } from 'components/shared/load-buttons'
import useAuth from 'context/auth/use'
import useCatalogFeatureMany from 'context/catalog-feature/use-many'
import Button from 'design-system/components/button'

import CatalogFeatureCreateUpdateModal from './create-update-modal'
import CatalogFeaturesTable from './table'
import useCatalogFeatureFiltersSearch from './use-filters-search'

const CatalogFeatures = ({ table }: { table?: React.ReactNode }) => {
  const [showModal, setShowModal] = useState(false)
  const { isSuperAdmin } = useAuth()
  const {
    count,
    catalog,
    catalogFeatures,
    fetchMore,
    loading,
    loadingMore,
    refetch,
  } = useCatalogFeatureMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useCatalogFeatureFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${catalogFeatures?.length} of ${count}`}{' '}
            Catalog Features
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <CatalogFeatureCreateUpdateModal
            onClose={() => setShowModal(false)}
            onSave={(id: string) => {
              setShowModal(false)
              refetch()
              navigate(`/admin/catalog/${catalog}/catalog-features/${id}`)
            }}
            openModal={showModal}
            trigger={
              <Button
                color="gray"
                disabled={!isSuperAdmin}
                kind="outlined"
                onClick={() => setShowModal(true)}
                text="Create New"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table || <CatalogFeaturesTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && catalogFeatures?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default CatalogFeatures
