import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'

import Loader from 'components/shared/loader'
import useProject from 'context/project/use'
import {
  FIND_MANY_ORDER_CABINETRY,
  FindManyOrderPayload,
  FindManyOrderVariables,
} from 'queries/order'
import { Order, OrderType } from 'types/order'

interface IProjectOrderFORMManyContext {
  orders: Order[]
  refetch: () => Promise<unknown>
}

type ProjectOrderFORMManyProviderProps = React.PropsWithChildren

export const ProjectOrderFORMManyContext =
  createContext<IProjectOrderFORMManyContext>(
    {} as IProjectOrderFORMManyContext,
  )

const ProjectOrderFORMManyProvider = (
  props: ProjectOrderFORMManyProviderProps,
) => {
  const { project } = useProject()
  const { data, loading, refetch } = useQuery<
    FindManyOrderPayload,
    FindManyOrderVariables
  >(FIND_MANY_ORDER_CABINETRY, {
    variables: {
      where: {
        project: {
          id: {
            equals: project.id,
          },
        },
        type: {
          equals: OrderType.CABINETRY,
        },
      },
    },
  })

  if (loading) return <Loader />

  return (
    <ProjectOrderFORMManyContext.Provider
      value={{
        orders: data?.orders ?? [],
        refetch,
      }}
    >
      {props.children}
    </ProjectOrderFORMManyContext.Provider>
  )
}

export default ProjectOrderFORMManyProvider
