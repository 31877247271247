import React, { useEffect, useState } from 'react'

import CreateUpdateIssueForm from 'components/admin/project/issue/form'
import useIssueMutate from 'context/issue/use-mutate-no-context'
import ProjectProvider from 'context/project/provider'
import useProject from 'context/project/use'
import { Modal } from 'design-system/components/modal'
import { FIND_UNIQUE_PROJECT_ISSUE } from 'queries/project'
import { Issue, IssueData, IssueType, ResolutionType } from 'types/issue'

interface IssueModaldProps {
  issue?: Issue
  onClose: () => void
  open: boolean

  refetch?: () => Promise<unknown>
}

const UpdateIssueModal = ({
  open,
  onClose,
  issue,
  refetch,
}: IssueModaldProps) => {
  const { damagePhotos } = useProject()
  const { loadingUpdate, updateIssue } = useIssueMutate()

  const initialState = {
    type: issue?.type || null,
    resolutionType: issue?.resolutionType || ResolutionType.PENDING,
    data: issue?.data || {
      orderNumber: '',
      positionNumber: '',
    },
    delivery: issue?.delivery,
    files: issue?.files || [],
  }
  const [state, setState] = useState<NullableRecord<Issue>>(initialState)

  useEffect(() => {
    setState(initialState)
  }, [issue, open])

  const onSave = async () => {
    await updateIssue({
      variables: {
        data: {
          data: state.data as IssueData,
          type: state.type as IssueType,
          resolutionType: state.resolutionType as ResolutionType,
          files: {
            set: state.files?.map((d) => ({ id: d.id })) ?? [],
          },
        },
        where: {
          id: issue?.id ?? '',
        },
      },
    })
    onClose()
    refetch?.()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="large"
      title="Update Issue"
      saveButton={{
        loading: loadingUpdate,
        onClick: onSave,
      }}
      onExited={() => {
        refetch?.()
        onClose()
      }}
    >
      <CreateUpdateIssueForm
        damagePhotos={damagePhotos}
        grayInputs
        issue={state as Issue}
        onChange={setState}
        refetch={refetch}
      />
    </Modal>
  )
}

const IssueModal = (props: IssueModaldProps) => {
  if (!props.issue) return null
  return (
    <ProjectProvider
      project_id={props.issue?.project?.id || ''}
      query={FIND_UNIQUE_PROJECT_ISSUE}
      noLoader
    >
      <UpdateIssueModal {...props} />
    </ProjectProvider>
  )
}

export default IssueModal
