import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Grid, Loader } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import { useSelectionCenters } from 'context/selection-centers/provider'
import Button from 'design-system/components/button'

import SelectionCenterModal from './create-update-modal'
import SelectionCentersTable from './table'

const SelectionCentersIndexMany = () => {
  const [enableCreate, setEnableCreate] = useState(false)
  const {
    selectionCenters,
    count,
    loadingMore,
    selectionCenter,
    setSelectionCenter,
  } = useSelectionCenters()

  return (
    <Grid>
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            Showing {selectionCenters?.length} of {count} Selection Centers
          </p>
        </Grid.Column>

        <Grid.Column textAlign="right">
          <Button
            color="gray"
            kind="outlined"
            onClick={() => {
              if (selectionCenter) setSelectionCenter(null)
              setEnableCreate(true)
            }}
            text="Create New"
          />
          <Button
            style={{ marginLeft: 8 }}
            kind="solid"
            onClick={() => {
              navigate('/selection-centers')
            }}
            text="Go to app"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <SelectionCentersTable />
        </Grid.Column>
      </Grid.Row>

      {loadingMore ? (
        <Grid.Row>
          <Grid.Column>
            <Loader active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      ) : null}

      {selectionCenters.length ? <BackToTop /> : null}

      <SelectionCenterModal
        onClose={() => setEnableCreate(false)}
        open={enableCreate}
      />
    </Grid>
  )
}

export default SelectionCentersIndexMany
