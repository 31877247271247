import React from 'react'

import { RouteComponentProps } from '@reach/router'

import GenericOptionManyProvider from 'context/generic-option/provider-many'

interface GenericOptionIndexManyProps
  extends RouteComponentProps<{
    catalog_id: string
    generic_feature_id: string
  }> {
  children: React.ReactNode
}

const GenericOptionIndexMany = (props: GenericOptionIndexManyProps) => {
  return (
    <GenericOptionManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        feature: {
          id: props.generic_feature_id,
        },
        sortBy: 'description',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </GenericOptionManyProvider>
  )
}

export default GenericOptionIndexMany
