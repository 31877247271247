import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { RoomPageTitle } from 'components/admin/page-title'
import RoomLayout from 'components/admin/project/room/layout'
import CategorizationModalMultiple from 'components/admin/project-file/categorization-modal-multiple'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import CheckHighlighted from 'design-system/components/check-highlighted'
import { ProjectFileType } from 'types/project-file'

const Plans = (
  _: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  const { project } = useProject()
  const { floorPlans, room, refetch } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()
  const [openModal, setOpenModal] = useState(false)

  return (
    <RoomLayout>
      <RoomPageTitle room={room} viewName="Plans" />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                columnGap: '5px',
              }}
            >
              <Button
                kind="ghost"
                text="Choose Files To Recategorize"
                color="gray"
                onClick={() => setOpenModal(true)}
              />
              <CheckHighlighted
                checked={!!room?.data?.floorPlansCompletedAt}
                color="red"
                text="Initial Flow Completed"
                onChange={async () => {
                  await updateRoomAndRefetch({
                    variables: {
                      data: {
                        data: {
                          floorPlansCompletedAt: !room?.data
                            .floorPlansCompletedAt
                            ? new Date()
                            : null,
                        },
                      },
                      where: {
                        id: room?.id ?? '',
                      },
                    },
                  })
                }}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          includeDownloadAndCarousel
          onDownload={async () => {
            const res = await updateRoomAndRefetch({
              variables: {
                data: {
                  data: {
                    floorPlansDownloadedAt: new Date(),
                  },
                },
                where: {
                  id: room?.id ?? '',
                },
              },
            })
            window.open(res.data?.updateOneRoom.data.floorPlansZipUrl)
          }}
          openOnNewTab={true}
          projectFiles={floorPlans}
          projectFileType={ProjectFileType.FLOOR_PLAN}
          projectId={project.id}
          refetch={refetch}
          roomId={room?.id}
        />
      </Grid>
      <CategorizationModalMultiple
        open={openModal}
        onClose={() => setOpenModal(false)}
        files={floorPlans}
        refetch={refetch}
        title="Choose Files To Recategorize"
        typeFrom={ProjectFileType.FLOOR_PLAN}
        typeTo={ProjectFileType.ROOM_PHOTO}
      />
    </RoomLayout>
  )
}

export default Plans
