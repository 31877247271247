import { useMemo } from 'react'

import useProjectMany from 'context/project/use-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { ProjectType } from 'types/project'
import { ProjectStaffRole, PROJECT_STAFF_ROLES } from 'types/project-staff'
import {
  camelCaseToTitleCase,
  capsSnakeCaseToTitleCase,
  toTitleCase,
} from 'views/utils'

import {
  DynamicFilterTypes,
  IFormDropdownValue,
  IFormRadioValue,
} from '../filters-search'

export type ProjectFilterValues = {
  assignedTo: IFormDropdownValue
  assignedToRole: IFormDropdownValue
  projectType: IFormRadioValue
  projectStatus: IFormRadioValue
  progress: IFormRadioValue
  paymentStatus: IFormRadioValue
}
const useProjectFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useProjectMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()

  const filters = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'assignedTo',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Assigned to',
            options: [
              ...userOptions,
              {
                key: 'everyone',
                text: 'Everyone',
                value: 'Everyone',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'assignedToRole',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Assigned role',
            options: PROJECT_STAFF_ROLES.map((r) => {
              return {
                key: r,
                text: capsSnakeCaseToTitleCase(r),
                value: r,
              }
            }),
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'projectType',
            type: DynamicFilterTypes.RADIO,
            label: 'Project type',
            options: [
              {
                text: 'All',
                value: 'all',
              },
            ].concat(
              Object.values(ProjectType).map((pt) => {
                return {
                  text: toTitleCase(pt),
                  value: pt as string,
                }
              }),
            ),
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'projectStatus',
            type: DynamicFilterTypes.RADIO,
            label: 'Project status',
            options: [
              {
                text: 'All',
                value: 'all',
              },
              {
                text: 'Open',
                value: 'open',
              },
              {
                text: 'Closed',
                value: 'closed',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'progress',
            type: DynamicFilterTypes.RADIO,
            label: 'Progress',
            options: [
              {
                text: 'All',
                value: 'all',
              },
              {
                text: 'Design',
                value: '01',
              },
              {
                text: 'Refine',
                value: '02',
              },
              {
                text: 'Delivery',
                value: '03',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'paymentStatus',
            type: DynamicFilterTypes.RADIO,
            label: 'Payment Status',
            options: [
              {
                text: 'All',
                value: 'all',
              },
              {
                text: 'Design Deposit',
                value: '01',
              },
              {
                text: 'Project/Trade Deposit',
                value: '02',
              },
              {
                text: 'Balance Payment',
                value: '03',
              },
              {
                text: 'No Payment',
                value: '00',
              },
            ],
          },
        ],
      },
    ]
  }, [userOptions])

  const defaultFilters = useMemo(() => {
    const projectFilters: Partial<ProjectFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      projectFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }
    if (queryFilters.assignedToRole)
      projectFilters.assignedToRole = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedToRole,
          text: capsSnakeCaseToTitleCase(queryFilters.assignedToRole),
        },
      }
    if (queryFilters.projectType)
      projectFilters.projectType = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.projectType,
          text: queryFilters.projectType,
        },
      }
    if (queryFilters.status)
      projectFilters.projectStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.status,
          text: camelCaseToTitleCase(queryFilters.status),
        },
      }
    if (queryFilters.progress)
      projectFilters.progress = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.progress,
          text: queryFilters.progress,
        },
      }
    if (queryFilters.paymentStatus)
      projectFilters.paymentStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.paymentStatus,
          text: queryFilters.paymentStatus,
        },
      }

    return projectFilters
  }, [queryFilters])

  const handleApplyFilters = (data: ProjectFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      assignedToRole: data.assignedToRole?.selection.value as ProjectStaffRole,
      projectType:
        data.projectType?.selection.value === 'all'
          ? undefined
          : (data.projectType?.selection.value as ProjectType),
      status: data.projectStatus?.selection.value as 'all' | 'open' | 'closed',
      progress:
        data.progress?.selection.value === 'all'
          ? undefined
          : data.progress?.selection.value,
      paymentStatus:
        data.paymentStatus?.selection.value === 'all'
          ? undefined
          : data.paymentStatus?.selection.value,
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: null,
        assignedToRole: null,
      }),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useProjectFiltersSearch
