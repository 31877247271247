import { useMutation } from '@apollo/client'

import {
  CREATE_SELECTION_CENTER,
  CreateSelectionCenterPayload,
  CreateSelectionCenterVariables,
  DELETE_SELECTION_CENTER,
  DeleteSelectionCenterPayload,
  DeleteSelectionCenterVariables,
  UPDATE_SELECTION_CENTER,
  UpdateSelectionCenterPayload,
  UpdateSelectionCenterVariables,
} from 'queries/selection-center'

const useSelectionCenterMutate = () => {
  const [createSelectionCenter, { loading: loadingCreate }] = useMutation<
    CreateSelectionCenterPayload,
    CreateSelectionCenterVariables
  >(CREATE_SELECTION_CENTER)

  const [deleteSelectionCenter, { loading: loadingDelete }] = useMutation<
    DeleteSelectionCenterPayload,
    DeleteSelectionCenterVariables
  >(DELETE_SELECTION_CENTER)

  const [updateSelectionCenter, { loading: loadingUpdate }] = useMutation<
    UpdateSelectionCenterPayload,
    UpdateSelectionCenterVariables
  >(UPDATE_SELECTION_CENTER)

  return {
    createSelectionCenter,
    loadingCreate,
    deleteSelectionCenter,
    loadingDelete,
    updateSelectionCenter,
    loadingUpdate,
  }
}

export default useSelectionCenterMutate
