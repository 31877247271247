import React, { useMemo, useState } from 'react'

import { partition } from 'lodash'
import moment from 'moment'
import { Table } from 'semantic-ui-react'

import ArchiveProjectModal from 'components/admin/project/archive-project-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useAuth from 'context/auth/use'
import { newForMe } from 'context/new-for'
import useProjectMany from 'context/project/use-many'
import useProjectMutateNoContext from 'context/project/use-mutate-no-context'
import Button from 'design-system/components/button'
import { InfoPopup, StyledLabel } from 'styles/admin/main'
import { ArchivedState, Project } from 'types/project'

import { ProjectOwnersCellContents } from './project-owners/table-cell-content'
import { ProjectStaffCellContents } from './project-staff/table-cell-content'
import { ProjectActivityCellContent } from './table-cell-activity'
import { ProjectProgressCellContent } from './table-cell-progress'
import { ProjectTasksCellContent } from './task/table-cell-tasks'

const ProjectsTable = () => {
  const { formUserId } = useAuth()
  const { updateProject, loadingUpdate } = useProjectMutateNoContext()
  const { projects, refetch, queryFilters, setQueryFilters } = useProjectMany()
  const { sortBy, sortDirection } = queryFilters

  const [projectToArchive, setProjectToArchive] = useState<Project>()
  const [projectToUnarchive, setProjectToUnarchive] = useState<Project>()

  // Temp hack to bubble newFor items to the top
  const sortedProjects = useMemo(() => {
    if (sortBy === 'derivedActivity' && sortDirection === 'descending') {
      const [projectsWithNewFor, rest] = partition(
        projects,
        (p) =>
          p.events.some((e) => newForMe(e, formUserId)) ||
          p.messages.some((e) => newForMe(e, formUserId)),
      )
      return [...projectsWithNewFor, ...rest]
    }
    return projects
  }, [sortBy, sortDirection, projects, formUserId])

  return (
    <Table className="small" celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Owners</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedPlay' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedPlay',
                sortDirection:
                  sortBy === 'derivedPlay' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Progress
          </Table.HeaderCell>
          <Table.HeaderCell>Tasks</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedActivity' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedActivity',
                sortDirection:
                  sortBy === 'derivedActivity' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Activity
          </Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedProjects.map((project) => {
          const closedWon =
            project.data?.archivedState === ArchivedState.CLOSED_DEAL
          const isArchived = !!project.archivedAt
          const tasksUnarchived =
            project.tasks.filter((t) => !t.data.archivedAt) || []

          return (
            <Table.Row key={project.id}>
              <Table.Cell width={3}>
                {isArchived && (
                  <StyledLabel ribbon styled={{ green: closedWon }}>
                    Closed
                  </StyledLabel>
                )}
                <ProjectOwnersCellContents
                  project={project}
                  includeAddress
                  multipleOwners
                />
              </Table.Cell>
              <Table.Cell width={3}>
                <ProjectStaffCellContents project={project} />
              </Table.Cell>
              <Table.Cell width={4}>
                <ProjectProgressCellContent project={project} />
              </Table.Cell>
              <Table.Cell width={4}>
                <ProjectTasksCellContent tasks={tasksUnarchived} />
              </Table.Cell>
              <Table.Cell width={2}>
                {!isArchived && (
                  <ProjectActivityCellContent project={project} />
                )}
                {!!isArchived && (
                  <p className={`${closedWon ? 'green' : 'red'} small`}>
                    <b>Reason why: </b>
                    {project.data?.archivedState &&
                      (project.data?.archivedReason || 'Closed Deal')}
                    <br />
                    <b>Closed at: </b>
                    {moment(project.archivedAt).format('MM/DD/YYYY')}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                <div style={{ display: 'grid', rowGap: '8px' }}>
                  <InfoPopup
                    content={<p>Open project</p>}
                    trigger={
                      <Button
                        color="dark"
                        fontAwesomeIcon="arrow-up-right-from-square"
                        kind="solid"
                        onClick={() =>
                          window.open(`/admin/projects/${project.id}/overview`)
                        }
                      />
                    }
                  />
                  {!!isArchived && (
                    <Button
                      color="blue"
                      fontAwesomeIcon="box-open"
                      kind="solid"
                      onClick={() => setProjectToUnarchive(project)}
                    />
                  )}
                  {!isArchived && (
                    <InfoPopup
                      content={<p>Archive project</p>}
                      trigger={
                        <Button
                          color="blue"
                          fontAwesomeIcon="archive"
                          kind="solid"
                          onClick={() => setProjectToArchive(project)}
                        />
                      }
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <ConfirmationModal
        firstTitle="Are you sure you want to reopen this project?"
        loading={loadingUpdate}
        onCancel={() => setProjectToUnarchive(undefined)}
        onConfirm={async () => {
          await updateProject({
            variables: {
              data: {
                archivedAt: null,
                snoozedUntil: null,
                data: {
                  archivedReason: '',
                  archivedState: null,
                },
              },
              where: {
                id: projectToUnarchive?.id ?? '',
              },
            },
          })
          refetch()
          setProjectToUnarchive(undefined)
        }}
        open={!!projectToUnarchive}
      />
      {projectToArchive ? (
        <ArchiveProjectModal
          open={!!projectToArchive}
          onClose={() => setProjectToArchive(undefined)}
          project={projectToArchive}
          refetch={refetch}
        />
      ) : null}
    </Table>
  )
}

export default ProjectsTable
