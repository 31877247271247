import { gql } from '@apollo/client'

import { ProspectStaff, ProspectStaffRole } from 'types/prospect-staff'

export const CREATE_ONE_PROSPECT_STAFF = gql`
  mutation createOneProspectsStaff($data: ProspectsStaffCreateInput!) {
    createOneProspectsStaff(data: $data) {
      prospectId
      staffMemberId
    }
  }
`

export interface CreateOneProspectStaffPayload {
  createOneProspectsStaff: ProspectStaff
}

export interface CreateOneProspectStaffVariables {
  data: {
    data?: ProspectStaff['data']
    isSubscribed?: boolean
    isPrimary?: boolean
    staffMemberRole?: ProspectStaffRole
    prospect: {
      connect: {
        id: string
      }
    }
    staffMember: {
      connect: {
        id: string
      }
    }
  }
}

export const DELETE_ONE_PROSPECT_STAFF = gql`
  mutation deleteOneProspectsStaff($where: ProspectsStaffWhereUniqueInput!) {
    deleteOneProspectsStaff(where: $where) {
      prospectId
      staffMemberId
    }
  }
`

export interface DeleteOneProspectStaffPayload {
  deleteOneProspectsStaff: {
    prospectId: string
    staffMemberId: string
  }
}

export interface DeleteOneProspectStaffVariables {
  where: {
    prospectId_staffMemberId: {
      prospectId: string
      staffMemberId: string
    }
  }
}

export const UPDATE_ONE_PROSPECT_STAFF = gql`
  mutation updateOneProspectsStaff(
    $data: ProspectsStaffUpdateInput!
    $where: ProspectsStaffWhereUniqueInput!
  ) {
    updateOneProspectsStaff(data: $data, where: $where) {
      prospectId
      staffMemberId
    }
  }
`

export interface UpdateOneProspectStaffPayload {
  updateOneProspectsStaff: ProspectStaff
}

export interface UpdateOneProspectStaffVariables {
  data: {
    data?: ProspectStaff['data']
    isPrimary?: boolean
    isSubscribed?: boolean
    staffMemberRole?: ProspectStaffRole
    staffMember?: {
      connect: {
        id: string
      }
    }
  }
  where: {
    prospectId_staffMemberId: {
      prospectId: string
      staffMemberId: string
    }
  }
}

export const FIND_MANY_PROSPECTS_STAFF = gql`
  query findManyProspectsStaff(
    $orderBy: [ProspectsStaffOrderByWithRelationInput!]
    $take: Int
    $where: ProspectsStaffWhereInput
  ) {
    prospectsStaff: findManyProspectsStaff(
      orderBy: $orderBy
      take: $take
      where: $where
    ) {
      createdAt
      data
      isPrimary
      prospect {
        id
        data
        email
        firstName
        lastName
        projects {
          projectId
          project {
            id
            createdAt
          }
        }
      }
      staffMember {
        id
        data
        email
        firstName
        lastName
        picture
        type
      }
    }
  }
`

export interface FindManyProspectsStaffPayload {
  prospectsStaff: ProspectStaff[]
}

export interface FindManyProspectsStaffVariables {
  orderBy?: {
    createdAt: 'asc' | 'desc'
  }
  take?: number
  where?: {
    staffMemberRole: {
      equals: ProspectStaffRole
    }
  }
}

export const UPSERT_ONE_PROSPECTS_STAFF = gql`
  mutation upsertOneProspectsStaff(
    $where: ProspectsStaffWhereUniqueInput!
    $create: ProspectsStaffCreateInput!
    $update: ProspectsStaffUpdateInput!
  ) {
    upsertOneProspectsStaff(where: $where, create: $create, update: $update) {
      prospectId
      staffMemberId
    }
  }
`

export interface UpsertOneProspectsStaffPayload {
  upsertOneProspectsStaff: ProspectStaff
}

export interface UpsertOneProspectsStaffVariables {
  create: Partial<ProspectStaff>
  update: Partial<ProspectStaff>
  where: {
    prospectId_staffMemberId: {
      prospectId: string
      staffMemberId: string
    }
  }
}
