import { ParsedNobiliaFeatures } from 'context/catalog/utils'
import { DisconnectedFeature } from 'types/disconnected-feature'
import { GenericFeature } from 'types/generic-feature'
import { RoomElementFeature } from 'types/room-element-feature'

export const CATALOGS: {
  subtitle: string
  categories: {
    navigateUrl:
      | string
      | ((
          urlProps: {
            catalog: string
            genericFeatures: GenericFeature[]
            roomElementFeatures: RoomElementFeature[]
            disconnectedFeatures: DisconnectedFeature[]
          } & ParsedNobiliaFeatures,
        ) => string)
    imageName: string
    name: string
  }[]
}[] = [
  {
    subtitle: 'Gallery, Product Features, Render Props and Appliance Packs',
    categories: [
      {
        navigateUrl: '/admin/designs?defaultFilter=gallery',
        imageName: '01-gallery.webp',
        name: 'Gallery',
      },
      {
        navigateUrl: ({ catalog }) =>
          `/admin/catalog/${catalog}/catalog-features`,
        imageName: '02-product-features.webp',
        name: 'Product Features',
      },
      {
        navigateUrl: ({ catalog }) => `/admin/catalog/${catalog}/render-props`,
        imageName: '03-render-props.webp',
        name: 'Render Props',
      },
      {
        navigateUrl: ({ catalog }) =>
          `/admin/catalog/${catalog}/appliance-packs`,
        imageName: '04-appliance-packages.webp',
        name: 'Appliance Packs',
      },
    ],
  },
  {
    subtitle: 'Products and Room Elements',
    categories: [
      {
        navigateUrl: ({ catalog }) =>
          `/admin/catalog/${catalog}/nobilia-sample-classes`,
        imageName: '29-samples.webp',
        name: 'Nobilia Samples',
      },
      {
        navigateUrl: ({ catalog }) =>
          `/admin/catalog/${catalog}/nobilia-product-classes`,
        imageName: '05-nobilia-products.webp',
        name: 'Nobilia Products',
      },
      {
        navigateUrl: ({ catalog }) =>
          `/admin/catalog/${catalog}/generic-product-classes`,
        imageName: '06-generic-products.webp',
        name: 'Generic Products',
      },
      {
        navigateUrl: ({ catalog }) =>
          `/admin/catalog/${catalog}/room-element-classes`,
        imageName: '07-room-elements.webp',
        name: 'Room Elements',
      },
    ],
  },
  {
    subtitle: 'Nobilia Features',
    categories: [
      {
        navigateUrl: ({ catalog, frontFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${frontFeature?.id}`,
        imageName: '08-fronts.webp',
        name: 'Fronts',
      },
      {
        navigateUrl: ({ catalog, handleFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${handleFeature?.id}`,
        imageName: '09-handles.webp',
        name: 'Handles',
      },
      {
        navigateUrl: ({ catalog, glassColorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${glassColorFeature?.id}`,
        imageName: '10-glass-colors.webp',
        name: 'Glass Colors',
      },
      {
        navigateUrl: ({ catalog, glassDesignFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${glassDesignFeature?.id}`,
        imageName: '11-glass-designs.webp',
        name: 'Glass Designs',
      },
      {
        navigateUrl: ({ catalog, glassDoorFrameColorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${glassDoorFrameColorFeature?.id}`,
        imageName: '12-glass-door-frame-color.webp',
        name: 'Glass Door Frame Colors',
      },
      {
        navigateUrl: ({ catalog, glassDoorFrameDesignFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${glassDoorFrameDesignFeature?.id}`,
        imageName: '13-glass-door-frame-designs.webp',
        name: 'Glass Door Frame Designs',
      },
      {
        navigateUrl: ({ catalog, carcaseColorExteriorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${carcaseColorExteriorFeature?.id}`,
        imageName: '14-carcase-color-exterior.webp',
        name: 'Carcase Colors Exterior',
      },
      {
        navigateUrl: ({ catalog, carcaseColorInteriorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${carcaseColorInteriorFeature?.id}`,
        imageName: '15-carcase-color-interior.webp',
        name: 'Carcase Colors Interior',
      },
      {
        navigateUrl: ({ catalog, plinthColorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${plinthColorFeature?.id}`,
        imageName: '16-plinth.webp',
        name: 'Plinth Color',
      },
      {
        navigateUrl: ({ catalog, ceilingPanelColorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${ceilingPanelColorFeature?.id}`,
        imageName: '17-ceiling-panel-color.webp',
        name: 'Ceiling Panel Color',
      },
      {
        navigateUrl: ({ catalog, uprightBarColorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${uprightBarColorFeature?.id}`,
        imageName: '18-upright-bar-color.webp',
        name: 'Upright/bar Color',
      },
      {
        navigateUrl: ({ catalog, wallShelfColorFeature }) =>
          `/admin/catalog/${catalog}/nobilia-features/${wallShelfColorFeature?.id}`,
        imageName: 'catalog_missing_file.png',
        name: 'Wall Shelf Color',
      },
    ],
  },
  {
    subtitle: 'Generic Features',
    categories: [
      {
        navigateUrl: ({ catalog, genericFeatures }) =>
          `/admin/catalog/${catalog}/generic-features/${genericFeatures.find(
            (f) => f.identifier === 'countertopMaterial',
          )?.id}`,
        imageName: '19-countertop-materials.webp',
        name: 'Countertop Materials',
      },
      {
        navigateUrl: ({ catalog, genericFeatures }) =>
          `/admin/catalog/${catalog}/generic-features/${genericFeatures.find(
            (f) => f.identifier === 'backsplashMaterial',
          )?.id}`,
        imageName: '20-backsplash-materials.webp',
        name: 'Backsplash Materials',
      },
    ],
  },
  {
    subtitle: 'Room Element Features',
    categories: [
      {
        navigateUrl: ({ catalog, roomElementFeatures }) =>
          `/admin/catalog/${catalog}/room-element-features/${roomElementFeatures.find(
            (f) => f.identifier === 'floorMaterial',
          )?.id}`,
        imageName: '21-floor-materials.webp',
        name: 'Floor Materials',
      },
      {
        navigateUrl: ({ catalog, roomElementFeatures }) =>
          `/admin/catalog/${catalog}/room-element-features/${roomElementFeatures.find(
            (f) => f.identifier === 'wallMaterial',
          )?.id}`,
        imageName: '22-wall-materials.webp',
        name: 'Wall Materials',
      },
    ],
  },
  {
    subtitle: 'Disconnected Features',
    categories: [
      {
        navigateUrl: ({ catalog, disconnectedFeatures }) =>
          `/admin/catalog/${catalog}/disconnected-features/${disconnectedFeatures.find(
            (f) => f.identifier === 'inspirationGeneral',
          )?.id}`,
        imageName: '23-general-inspiration.webp',
        name: 'General Inspiration',
      },
      {
        navigateUrl: ({ catalog, disconnectedFeatures }) =>
          `/admin/catalog/${catalog}/disconnected-features/${disconnectedFeatures.find(
            (f) => f.identifier === 'inspirationElement',
          )?.id}`,
        imageName: '24-inspiration-images.webp',
        name: 'Inspiration Elements',
      },
      {
        navigateUrl: ({ catalog, disconnectedFeatures }) =>
          `/admin/catalog/${catalog}/disconnected-features/${disconnectedFeatures.find(
            (f) => f.identifier === 'nobiliaFrontDiscontinued',
          )?.id}`,
        imageName: '25-nobilia-fronts-disc.webp',
        name: 'Nobilia Fronts Discontinued',
      },
      {
        navigateUrl: ({ catalog, disconnectedFeatures }) =>
          `/admin/catalog/${catalog}/disconnected-features/${disconnectedFeatures.find(
            (f) => f.identifier === 'nobiliaHandleDiscontinued',
          )?.id}`,
        imageName: '26-nobilia-handles-disc.webp',
        name: 'Nobilia Handles Discontinued',
      },
      {
        navigateUrl: ({ catalog, disconnectedFeatures }) =>
          `/admin/catalog/${catalog}/disconnected-features/${disconnectedFeatures.find(
            (f) => f.identifier === 'nobiliaCarcaseExteriorDiscontinued',
          )?.id}`,
        imageName: '27-nobilia-carcase-exterior-disc.webp',
        name: 'Nobilia Carcase Exterior Discontinued',
      },
      {
        navigateUrl: ({ catalog, disconnectedFeatures }) =>
          `/admin/catalog/${catalog}/disconnected-features/${disconnectedFeatures.find(
            (f) => f.identifier === 'nobiliaPlinthColorDiscontinued',
          )?.id}`,
        imageName: '28-nobilia-toekicks-disc.webp',
        name: 'Nobilia Toe Kicks Discontinued',
      },
    ],
  },
]
