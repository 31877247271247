import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  DELETE_PROJECT_FILE,
  DeleteProjectFilePayload,
  DeleteProjectFileVariables,
  UPDATE_PROJECT_FILE,
  UpdateProjectFilePayload,
  UpdateProjectFileVariables,
} from 'queries/project-file'

import { DesignContext } from './provider'

const useDesignProjectFileMutate = () => {
  const { refetch } = useContext(DesignContext)

  const [updateProjectFileAndRefetch] = useMutation<
    UpdateProjectFilePayload,
    UpdateProjectFileVariables
  >(UPDATE_PROJECT_FILE, {
    onCompleted: refetch,
  })

  const [deleteProjectFileAndRefetch] = useMutation<
    DeleteProjectFilePayload,
    DeleteProjectFileVariables
  >(DELETE_PROJECT_FILE, {
    onCompleted: refetch,
  })

  return {
    updateProjectFileAndRefetch,
    deleteProjectFileAndRefetch,
  }
}

export default useDesignProjectFileMutate
