import { useContext } from 'react'

import { ProspectManyContext } from './provider-many'

const useProspectMany = () => {
  const {
    prospects,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(ProspectManyContext)

  return {
    prospects,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useProspectMany
