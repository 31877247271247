import React from 'react'

import { NobiliaProductClassDiff } from 'types/nobilia-product-class-diff'

import MediaDiffComponent from './media-diff'
import {
  getReferenceImage,
  ImageCard,
  ReferenceDiv,
  StyledLabelDiffs,
  SubGrid,
} from './utils'

const MediaDiffs = ({
  nobiliaProductClassDiff,
}: {
  nobiliaProductClassDiff: NobiliaProductClassDiff
}) => {
  const isAdd = nobiliaProductClassDiff.type === 'add'
  const isDelete = nobiliaProductClassDiff.type === 'delete'
  const showMedia =
    !isAdd && !isDelete
      ? !!nobiliaProductClassDiff.media?.length
      : nobiliaProductClassDiff.snapshot.media.length > 0
  if (!showMedia) return <></>
  if (isAdd)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {nobiliaProductClassDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'add',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  if (isDelete)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {nobiliaProductClassDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'delete',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  return (
    <>
      <p className="caption no-margin">Media</p>
      {nobiliaProductClassDiff.media?.map((mdiff) => (
        <MediaDiffComponent key={mdiff.snapshot.key} mediaDiff={mdiff} />
      ))}
    </>
  )
}

const NobiliaProductClassDiffComponent = ({
  nobiliaProductClassDiff,
}: {
  nobiliaProductClassDiff: NobiliaProductClassDiff
}) => {
  const reference = getReferenceImage(nobiliaProductClassDiff.snapshot.media)

  return (
    <>
      <ReferenceDiv>
        <ImageCard image={reference} />
        <div>
          <p className="caption">
            Catalog {nobiliaProductClassDiff.snapshot.catalog}
          </p>
          <p>{nobiliaProductClassDiff.snapshot.identifier}</p>
        </div>
        <StyledLabelDiffs type={nobiliaProductClassDiff.type} />
      </ReferenceDiv>
      <SubGrid>
        <MediaDiffs nobiliaProductClassDiff={nobiliaProductClassDiff} />
      </SubGrid>
    </>
  )
}

export default NobiliaProductClassDiffComponent
