import React from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'

import Prospects from 'components/admin/prospect/index-many'
import useSalesDesigner from 'context/dashboard/sales-designer/use'
import ProspectManyProvider from 'context/prospect/provider-many'

import SalesDesignerLayout from './layout'

const SalesDesignerProjects = (_: RouteComponentProps) => {
  const { userId, userName } = useSalesDesigner()

  return (
    <SalesDesignerLayout>
      <ProspectManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          meetingTime: {
            gte: moment().startOf('week').toISOString(),
            lte: moment().endOf('week').toISOString(),
          },
          sortBy: 'derivedMeeting',
          sortDirection: 'ascending',
          take: 30,
        }}
        skipLoader
      >
        <Prospects />
      </ProspectManyProvider>
    </SalesDesignerLayout>
  )
}

export default SalesDesignerProjects
