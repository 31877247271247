import React, { useEffect } from 'react'

import { useLocation } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import useRoom from 'context/room/use'

import RoomMenu from './menu'

const RoomLayout = (props: React.PropsWithChildren) => {
  const { pathname } = useLocation()
  const { typeWithDescription } = useRoom()

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [pathname])

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width={11}>
          <h3>{typeWithDescription}</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <RoomMenu />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{props.children || null}</Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default RoomLayout
