import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useProspect from 'context/prospect/use'
import { ScrollBarStyle } from 'styles/app/system'

const MenuTrade = () => {
  const { pathname } = useLocation()
  const { isCompanyCompleted, prospect } = useProspect()

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="overview"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/overview`,
        )}
        onClick={() => navigate(`/admin/prospects/${prospect?.id}/overview`)}
      />
      <Menu.Item
        name="company"
        active={pathname.startsWith(`/admin/prospects/${prospect?.id}/company`)}
        onClick={() => navigate(`/admin/prospects/${prospect?.id}/company`)}
      >
        Company {isCompanyCompleted ? <Label color="green">YES</Label> : null}
      </Menu.Item>
      <Menu.Item
        name="call-outcome"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/call-outcome`,
        )}
        onClick={() =>
          navigate(`/admin/prospects/${prospect?.id}/call-outcome`)
        }
      >
        Prospect Call Outcome
      </Menu.Item>
      <Menu.Item
        name="message-templates"
        active={pathname.startsWith(
          `/admin/prospects/${prospect?.id}/message-templates`,
        )}
        onClick={() =>
          navigate(`/admin/prospects/${prospect?.id}/message-templates`)
        }
      >
        Message Templates
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuTrade

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
