import React from 'react'

import { Image, Table } from 'semantic-ui-react'

import useDisconnectedOptionMany from 'context/disconnected-option/use-many'
import { DisconnectedOption } from 'types/disconnected-option'

const DisconnectedOptionTable = () => {
  const { disconnectedOptions, queryFilters, setQueryFilters } =
    useDisconnectedOptionMany()
  const { sortBy, sortDirection } = queryFilters
  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortBy === 'description' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'description',
                sortDirection:
                  sortBy === 'description' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Description
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'identifier' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'identifier',
                sortDirection:
                  sortBy === 'identifier' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Identifier
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {disconnectedOptions.map((n: DisconnectedOption) => {
          return (
            <Table.Row key={n.id}>
              <Table.Cell width={2}>
                <Image
                  centered
                  size="small"
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    n.data?.imageUrl || '',
                  )}`}
                />
              </Table.Cell>
              <Table.Cell width={7}>{n.description}</Table.Cell>
              <Table.Cell width={4}>{n.identifier}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default DisconnectedOptionTable
