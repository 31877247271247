import React from 'react'

import useGuideUrls from 'context/project/use-guide-urls'
import { GuideType } from 'types/utils'

import SecondaryCard from '../cards/secondary-card'

const GuideCard = ({
  type,
  orderId,
}: {
  type: GuideType
  orderId?: string
}) => {
  const guideUrls = useGuideUrls()
  const getGuideObject = () => {
    switch (type) {
      case GuideType.DesignCall:
        return {
          image: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-call/kickoff-guide.webp`,
          title: 'Initial Call',
          url: guideUrls.DesignCall,
        }
      case GuideType.DesignPresentation:
        return {
          image: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-presentation/design-presentation.webp`,
          title: 'Design Presentation',
          url: guideUrls.DesignPresentation,
        }
      case GuideType.DesignReview:
        return {
          image: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-review/design-review.webp`,
          title: 'Design Review',
          url: guideUrls.DesignReview,
        }
      case GuideType.ExtraRoom:
        return {
          image: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/extra-room/extra-room.webp`,
          title: 'Extra Room',
          url: guideUrls.ExtraRoom,
        }
      case GuideType.HandoverCall:
        return {
          image: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/handover/handover.webp`,
          title: 'Handover Call',
          url: guideUrls.getHandover(orderId ?? ''),
        }
      case GuideType.ProjectConsultation:
        return {
          image: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/project-consultation/trade-project-consultation.webp`,
          title: 'Project Consultation',
          url: guideUrls.ProjectConsultation,
        }
      case GuideType.ProjectProposal:
        return {
          image: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/project-proposal/trade-project-proposal.webp`,
          title: 'Project Proposal',
          url: guideUrls.ProjectProposal,
        }
    }
  }

  const guideObject = getGuideObject()

  return (
    <SecondaryCard
      disabled={!guideObject.url}
      image={guideObject.image}
      onClick={() => window.open(guideObject.url)}
      title={guideObject.title}
    />
  )
}

export default GuideCard
