import { useQuery } from '@apollo/client'

import {
  GET_TECH_DESIGNER_SUPPORT_NEW_PROJECT_QUEUE,
  UserQueuePayload,
} from 'queries/round-robin'

const useTechDesignerSupportNewProjectQueue = () => {
  const {
    data: { designers } = { designers: [] },
    loading,
    refetch,
  } = useQuery<UserQueuePayload>(GET_TECH_DESIGNER_SUPPORT_NEW_PROJECT_QUEUE)

  return { designers, loading, refetch }
}

export default useTechDesignerSupportNewProjectQueue
