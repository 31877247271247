import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Divider, Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useProject from 'context/project/use'
import { ProjectFileType } from 'types/project-file'

const InstallationPhotos = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project, refetch, installationPhotos } = useProject()

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Installation Photos" />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h5>Upload Installation Photos</h5>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p>Upload installation photos</p>
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          projectFiles={installationPhotos}
          projectFileType={ProjectFileType.INSTALLATION_PHOTO}
          projectId={project.id}
          refetch={async () => {
            refetch()
          }}
        />
      </Grid>
    </ProjectLayout>
  )
}

export default InstallationPhotos
