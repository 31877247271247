import React from 'react'

import { RouteComponentProps } from '@reach/router'

import CatalogFeatureProvider from 'context/catalog-feature/provider'

interface CatalogFeatureIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    catalog_feature_id: string
  }> {
  children: React.ReactNode
}

const CatalogFeatureIndex = (props: CatalogFeatureIndexProps) => {
  return (
    <CatalogFeatureProvider
      catalog={props.catalog_id}
      catalog_feature_id={props.catalog_feature_id}
    >
      {props.children}
    </CatalogFeatureProvider>
  )
}

export default CatalogFeatureIndex
