import React, { useState } from 'react'

import moment from 'moment'
import { Button, Card } from 'semantic-ui-react'

import ArchiveProjectModal from 'components/admin/project/archive-project-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import { StyledIcon } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { ArchivedState } from 'types/project'
import * as viewUtils from 'views/utils'

const ArchiveCard = () => {
  const { project, refetch } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()
  const [openArchive, setOpenArchive] = useState(false)
  const [openUnarchive, setOpenUnarchive] = useState(false)

  return (
    <Card fluid>
      <Card.Content>
        <h6>
          Archive Project{' '}
          {!!project.archivedAt &&
            project.data.archivedState === ArchivedState.CLOSED_DEAL && (
              <StyledIcon
                icon={['fal', 'check']}
                style={{ width: '10px', height: '10px' }}
              />
            )}
          {!!project.archivedAt &&
            project.data.archivedState === ArchivedState.LOST_DEAL && (
              <StyledIcon
                icon={['fal', 'window-close']}
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: Colors.redBright,
                }}
              />
            )}
        </h6>
        {(() => {
          if (project.archivedAt) {
            return (
              <>
                <Card.Content style={{ marginBottom: '15px' }}>
                  <p style={{ marginBottom: '2px' }}>
                    <b>Archive State: </b>
                    {viewUtils.capsSnakeCaseToTitleCase(
                      project.data.archivedState,
                    )}
                  </p>
                  {project.data?.archivedReason && (
                    <p style={{ marginBottom: '2px' }}>
                      <b>Reason Why: </b>
                      {project.data.archivedReason}
                    </p>
                  )}
                  <p>
                    <b>Date Closed: </b>
                    {!!project.archivedAt &&
                      moment(project.archivedAt).format('LL')}
                  </p>
                </Card.Content>
                <Card.Content>
                  <Button
                    primary
                    content="Reopen Project"
                    floated="right"
                    onClick={() => setOpenUnarchive(true)}
                  />
                  <Button
                    content="Edit Closing Info"
                    floated="right"
                    onClick={() => setOpenArchive(true)}
                  />
                </Card.Content>
              </>
            )
          }
          return (
            <Card.Content>
              <Button
                content="Archive Project"
                floated="right"
                onClick={() => setOpenArchive(true)}
              />
            </Card.Content>
          )
        })()}
      </Card.Content>
      <ArchiveProjectModal
        open={openArchive}
        onClose={() => setOpenArchive(false)}
        project={project}
        refetch={refetch}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to reopen this project?"
        onCancel={() => setOpenUnarchive(false)}
        onConfirm={async () => {
          await updateProjectAndRefetch({
            variables: {
              data: {
                archivedAt: null,
                data: {
                  archivedReason: '',
                  archivedState: null,
                },
              },
              where: {
                id: project.id,
              },
            },
          })
          setOpenUnarchive(false)
        }}
        open={openUnarchive}
      />
    </Card>
  )
}

export default ArchiveCard
