import React from 'react'

import { isEqual } from 'lodash'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

import { GenericProductClassDiff } from 'types/generic-product-class-diff'

import MediaDiffComponent from './media-diff'
import {
  getReferenceImage,
  ImageCard,
  ReferenceDiv,
  StrikeThrough,
  StyledLabelDiffs,
  SubGrid,
} from './utils'

const parseDataFields = (genericProductClass: GenericProductClassDiff) => {
  const isUpdate = genericProductClass.type === 'update'
  const snapshotFrom = isUpdate
    ? genericProductClass.data?.from
    : genericProductClass.snapshot
  const snapshotTo = isUpdate
    ? genericProductClass.data?.to
    : genericProductClass.snapshot
  const snapshotFromData = snapshotFrom?.metadata
  const snapshotToData = snapshotTo?.metadata
  const out = [
    {
      fieldName: 'Name',
      fieldValue: snapshotToData?.name,
      fieldValueChanged: snapshotFromData?.name,
    },
    {
      fieldName: 'Default Depth',
      fieldValue: snapshotToData?.defaultDepth,
      fieldValueChanged: snapshotFromData?.defaultDepth,
    },
    {
      fieldName: 'Default Height',
      fieldValue: snapshotToData?.defaultHeight,
      fieldValueChanged: snapshotFromData?.defaultHeight,
    },
    {
      fieldName: 'Default Image Aspect Ratio',
      fieldValue: snapshotToData?.defaultImageAspectRatio,
      fieldValueChanged: snapshotFromData?.defaultImageAspectRatio,
    },
    {
      fieldName: 'Default Image Url',
      fieldValue: snapshotToData?.defaultImageUrl,
      fieldValueChanged: snapshotFromData?.defaultImageUrl,
    },
    {
      fieldName: 'Default Mesh Url',
      fieldValue: snapshotToData?.defaultMeshUrl,
      fieldValueChanged: snapshotFromData?.defaultMeshUrl,
    },
    {
      fieldName: 'Default Sizes',
      fieldValue: snapshotToData?.defaultSizes,
      fieldValueChanged: snapshotFromData?.defaultSizes,
    },
    {
      fieldName: 'Default Width',
      fieldValue: snapshotToData?.defaultWidth,
      fieldValueChanged: snapshotFromData?.defaultWidth,
    },
  ]
  return out.filter((o) => {
    if (!isUpdate) return true
    return !isEqual(o.fieldValue, o.fieldValueChanged)
  })
}

const MediaDiffs = ({
  genericProductClassDiff,
}: {
  genericProductClassDiff: GenericProductClassDiff
}) => {
  const isAdd = genericProductClassDiff.type === 'add'
  const isDelete = genericProductClassDiff.type === 'delete'
  const showMedia =
    !isDelete && !isAdd
      ? genericProductClassDiff.media?.length
      : genericProductClassDiff.snapshot.media.length
  if (!showMedia) return <></>
  if (isAdd)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {genericProductClassDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'add',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  if (isDelete)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {genericProductClassDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'delete',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  return (
    <>
      <p className="caption no-margin">Media</p>
      {genericProductClassDiff.media?.map((mdiff) => (
        <MediaDiffComponent key={mdiff.snapshot.key} mediaDiff={mdiff} />
      ))}
    </>
  )
}

const DataDiffs = ({
  genericProductClassDiff,
}: {
  genericProductClassDiff: GenericProductClassDiff
}) => {
  const fields = parseDataFields(genericProductClassDiff)
  const isUpdate = genericProductClassDiff.type === 'update'
  if (fields.length === 0) return <></>
  return (
    <>
      <p className="caption no-margin">Data</p>
      {fields.map((f) => (
        <Grid.Row key={f.fieldName}>
          <Grid.Column width={4}>
            <p className="caption">{f.fieldName}</p>
          </Grid.Column>
          <Grid.Column width={12}>
            {isUpdate ? (
              <StrikeThrough>{`${f.fieldValueChanged}`}</StrikeThrough>
            ) : null}
            <p>{`${f.fieldValue}`}</p>
          </Grid.Column>
        </Grid.Row>
      ))}
    </>
  )
}

const GenericProductClassDiffComponent = ({
  genericProductClassDiff,
}: {
  genericProductClassDiff: GenericProductClassDiff
}) => {
  const reference = getReferenceImage(genericProductClassDiff.snapshot.media)
  return (
    <>
      <ReferenceDiv>
        <ImageCard image={reference} />
        <div>
          <p className="caption">
            {genericProductClassDiff.snapshot.metadata?.name}
          </p>
          <p>{genericProductClassDiff.snapshot.identifier}</p>
        </div>
        <StyledLabelDiffs type={genericProductClassDiff.type} />
      </ReferenceDiv>
      <SubGrid>
        <MediaDiffs genericProductClassDiff={genericProductClassDiff} />
        <DataDiffs genericProductClassDiff={genericProductClassDiff} />
      </SubGrid>
    </>
  )
}

export default GenericProductClassDiffComponent
