import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import MeetingsUpcoming from 'components/admin/meeting/dashboard-upcoming'
import useAccountExecutive from 'context/dashboard/account-executive/use'

import AccountExecutiveLayout from './layout'

const AccountExecutiveOverview = (_: RouteComponentProps) => {
  const { meetings } = useAccountExecutive()
  return (
    <AccountExecutiveLayout>
      <Grid stackable doubling padded>
        <MeetingsUpcoming meetings={meetings} />
      </Grid>
    </AccountExecutiveLayout>
  )
}

export default AccountExecutiveOverview
