import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Image } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import { getMediaDisplay } from 'context/media/utils-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import PlannerAsset from 'planner/ui/planner-asset'
import { CatalogLabel, VideoCard } from 'styles/admin/main'
import { Media } from 'types/media'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import AssociationLabels from './association-labels'
import MediaModal from './modal'

const PreviewModal = ({
  media,
  refetch,
}: {
  media: Partial<Media>
  refetch?: () => Promise<unknown>
}) => {
  const [showModal, toggleModal] = useState<boolean>(false)
  const { isAdmin } = useAuth()

  const { display, isVideo, isOBJ, isGLB } = getMediaDisplay(media?.key ?? '')

  return (
    <Modal
      title="Gallery"
      onClose={() => toggleModal(false)}
      onOpen={() => toggleModal(true)}
      trigger={
        <Button
          color="blue"
          fontAwesomeIcon="face-viewfinder"
          kind="solid"
          onClick={() => toggleModal(!showModal)}
          text="Preview"
        />
      }
      open={showModal}
      customActions={
        <>
          <MediaModal
            media={media}
            onClose={() => toggleModal(false)}
            refetch={refetch}
            trigger={
              <Button
                disabled={!isAdmin}
                kind="solid"
                style={{ marginRight: '14px' }}
                text="Edit"
              />
            }
          />
          <Button
            color="gray"
            kind="outlined"
            text="Download"
            fontAwesomeIcon="arrow-down-to-line"
            onClick={() => {
              window.open(
                `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                  media?.key ?? '',
                )}`,
                '_blank',
              )
            }}
            size="large"
          />
        </>
      }
      size="large"
    >
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column width={8}>
            <h3 style={{ wordBreak: 'break-word' }}>{media.name}</h3>
            <p className="caption">{media?.key?.replace('media/', '')}</p>
            {isOBJ || isGLB ? (
              <div style={{ height: '300px' }}>
                <PlannerAsset sceneActive={false} url={display} />
              </div>
            ) : isVideo ? (
              <VideoCard>
                <video controls playsInline src={display} />
              </VideoCard>
            ) : (
              <Image
                centered
                src={display}
                style={{ maxHeight: '560px', width: 'auto' }}
              />
            )}
          </Grid.Column>
          <Grid.Column>
            <p className="caption" style={{ paddingTop: '15px' }}>
              Type
            </p>
            <CatalogLabel
              content={<p>{capsSnakeCaseToTitleCase(media?.category ?? '')}</p>}
              icon={<FontAwesomeIcon icon={['fal', 'image']} />}
              styled={{ color: 'yellow' }}
            />
            <p className="caption" style={{ paddingTop: '15px' }}>
              Associations
            </p>
            <AssociationLabels media={media} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default PreviewModal
