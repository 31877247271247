import { useMutation } from '@apollo/client'

import { notifySuccess } from 'context/notifications/trigger'
import {
  DIFF_CATALOG,
  DIFF_CATALOG_RESTORE,
  DiffCatalogPayload,
  DiffCatalogRestorePayload,
  RESTORE_CATALOG,
  RestoreCatalogPayload,
  SAVE_CATALOG,
  SaveCatalogPayload,
} from 'queries/catalog-version'

const useCatalogSnapshot = () => {
  const [
    diffCatalog,
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: { diffCatalog: diffCatalogData } = {
        diffCatalog: {
          catalogFeatures: [],
          genericOptions: [],
          genericProductClasses: [],
          media: [],
          nobiliaOptions: [],
          nobiliaProductClasses: [],
          roomElementOptions: [],
          roomElementClasses: [],
          _metadata: {
            author: null,
            email: null,
            timestamp: null,
          },
        },
      },
      loading: loadingDiffCatalog,
    },
  ] = useMutation<DiffCatalogPayload>(DIFF_CATALOG)

  const [
    diffCatalogRestore,
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: { diffCatalogRestore: diffCatalogRestoreData } = {
        diffCatalogRestore: {
          catalogFeatures: [],
          genericOptions: [],
          genericProductClasses: [],
          media: [],
          nobiliaOptions: [],
          nobiliaProductClasses: [],
          roomElementOptions: [],
          roomElementClasses: [],
          _metadata: {
            author: null,
            email: null,
            timestamp: null,
          },
        },
      },
      loading: loadingDiffCatalogRestore,
    },
  ] = useMutation<DiffCatalogRestorePayload>(DIFF_CATALOG_RESTORE)

  const [restoreCatalog, { loading: loadingRestore }] =
    useMutation<RestoreCatalogPayload>(RESTORE_CATALOG, {
      onCompleted: () => {
        notifySuccess('Restored from Snapshot done')
      },
    })

  const [saveCatalog, { loading: loadingSave }] =
    useMutation<SaveCatalogPayload>(SAVE_CATALOG, {
      onCompleted: () => {
        notifySuccess('Published Changes done')
      },
    })

  const getDiffs = async () =>
    Promise.all([diffCatalog(), diffCatalogRestore()])

  const totalUpdated =
    diffCatalogData?.catalogFeatures?.length +
    diffCatalogData?.genericOptions?.length +
    diffCatalogData?.genericProductClasses?.length +
    diffCatalogData?.nobiliaOptions?.length +
    diffCatalogData?.nobiliaProductClasses?.length +
    diffCatalogData?.roomElementOptions?.length +
    diffCatalogData?.roomElementClasses?.length

  const totalRestoreUpdated =
    diffCatalogRestoreData?.catalogFeatures?.length +
    diffCatalogRestoreData?.genericOptions?.length +
    diffCatalogRestoreData?.genericProductClasses?.length +
    diffCatalogRestoreData?.nobiliaOptions?.length +
    diffCatalogRestoreData?.nobiliaProductClasses?.length +
    diffCatalogRestoreData?.roomElementOptions?.length +
    diffCatalogRestoreData?.roomElementClasses?.length

  return {
    diffCatalogData,
    diffCatalogRestoreData,
    getDiffs,
    loadingDiffCatalog,
    loadingDiffCatalogRestore,
    loadingRestore,
    loadingSave,
    restoreCatalog,
    saveCatalog,
    totalRestoreUpdated,
    totalUpdated,
  }
}
export default useCatalogSnapshot
