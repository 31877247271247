import React, { createContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { VisibilityCalculations } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import { apolloClient } from 'context/authorized-apollo/provider'
import {
  FindManyCompanyPayload,
  FindManyCompanyVariables,
  FIND_MANY_COMPANY,
} from 'queries/company'
import { Company } from 'types/company'

import { QueryFilters, getQueryVariables } from './utils-many'

interface ICompanyManyContext {
  companies: Company[]
  count: number
  fetchMore: (v: VisibilityCalculations) => Promise<void>
  loading: boolean
  loadingMore: boolean
  queryFilters: QueryFilters
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
  refetch: () => Promise<unknown>
}

type CompanyManyProviderProps = React.PropsWithChildren<{
  defaultFilters: QueryFilters
  skipLoader?: boolean
}>

export const CompanyManyContext = createContext<ICompanyManyContext>(
  {} as ICompanyManyContext,
)

const CompanyManyProvider = ({
  children,
  defaultFilters,
  skipLoader,
}: CompanyManyProviderProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(defaultFilters)
  const [queryVariables, setQueryVariables] =
    useState<FindManyCompanyVariables>(getQueryVariables(queryFilters))

  const {
    data: { companies, count } = { companies: [], count: 0 },
    loading,
    fetchMore,
  } = useQuery<FindManyCompanyPayload, FindManyCompanyVariables>(
    FIND_MANY_COMPANY,
    {
      variables: queryVariables,
    },
  )

  useEffect(() => {
    // this effect triggers on refetch, something to do with the cache eviction
    // triggering a higher level re-render.
    // To avoid losing filters on refetch we add the refetching check here
    if (!refetching) {
      setQueryFilters(defaultFilters)
    }
    setRefetching(false)
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  const fetchMore_ = async ({ bottomVisible }: VisibilityCalculations) => {
    if (loading || loadingMore || !bottomVisible || companies.length === count)
      return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: companies.length,
      },
    })
    setLoadingMore(false)
  }

  const refetch = async () => {
    setRefetching(true)
    // evict and refetch first batch
    apolloClient?.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'findManyCompany',
    })
  }

  if (loading && !skipLoader) return <Loader />

  return (
    <CompanyManyContext.Provider
      value={{
        companies,
        count: count ?? 0,
        loading,
        loadingMore,
        fetchMore: fetchMore_,
        queryFilters,
        setQueryFilters,
        refetch,
      }}
    >
      {children}
    </CompanyManyContext.Provider>
  )
}

export default CompanyManyProvider
