import { useMutation } from '@apollo/client'

import {
  CREATE_SUPPLIER_ORDER_ACTION,
  CreateSupplierOrderActionPayload,
  CreateSupplierOrderActionVariables,
  DELETE_SUPPLIER_ORDER_ACTION,
  DeleteSupplierOrderActionPayload,
  DeleteSupplierOrderActionVariables,
  UPDATE_SUPPLIER_ORDER_ACTION,
  UpdateSupplierOrderActionPayload,
  UpdateSupplierOrderActionVariables,
} from 'queries/supplier-order-action'

const useSupplierOrderActionMutate = () => {
  const [createSupplierOrderAction, { loading: loadingCreate }] = useMutation<
    CreateSupplierOrderActionPayload,
    CreateSupplierOrderActionVariables
  >(CREATE_SUPPLIER_ORDER_ACTION)

  const [deleteSupplierOrderAction, { loading: loadingDelete }] = useMutation<
    DeleteSupplierOrderActionPayload,
    DeleteSupplierOrderActionVariables
  >(DELETE_SUPPLIER_ORDER_ACTION)

  const [updateSupplierOrderAction, { loading: loadingUpdate }] = useMutation<
    UpdateSupplierOrderActionPayload,
    UpdateSupplierOrderActionVariables
  >(UPDATE_SUPPLIER_ORDER_ACTION)

  return {
    createSupplierOrderAction,
    deleteSupplierOrderAction,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateSupplierOrderAction,
  }
}

export default useSupplierOrderActionMutate
