import React from 'react'

import { RouteComponentProps } from '@reach/router'

const SelectionCenterManagerIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  return <>{props.children}</>
}

export default SelectionCenterManagerIndex
