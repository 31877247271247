import { IFormDateValue } from './types'

export const dynamicDateValidation = (dateVal: IFormDateValue) => {
  if (!dateVal || !dateVal.value) return true //* Required validation handles this scenario
  const [startDateStr, endDateStr] = dateVal.value.split('-')
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)

  const isValid =
    !Number.isNaN(startDate.getTime()) && !Number.isNaN(endDate.getTime())
  return isValid || 'Invalid date range!'
}
