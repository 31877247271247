import React, { useEffect, useState } from 'react'

import moment from 'moment'
import {
  Controller,
  ControllerProps,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { DateInput } from 'semantic-ui-calendar-react'
import { Form, RadioProps, SemanticWIDTHS } from 'semantic-ui-react'
import styled from 'styled-components'

import { useNobiliaSampleClassMany } from 'context/nobilia-sample-class/provider-many'
import useNobiliaSampleClassMutation from 'context/nobilia-sample-class/use-mutation'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import Button from 'design-system/components/button/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { FormCheckbox } from 'styles/admin/main'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'

type NobiliaSampleForm = {
  identifier: string
  description: string
  imageUrl: string
  expectedRestockDate: string
  deprecated: boolean
  outOfStock: boolean
}

const dateFormat = 'MM/DD/YYYY'
const defaultValues: NobiliaSampleForm = {
  identifier: '',
  description: '',
  imageUrl: '',
  deprecated: false,
  outOfStock: false,
  expectedRestockDate: '',
}

type CreateUpdateNobiliaSampleClassModalProps = {
  nobiliaSampleClass: NobiliaSampleClass | null
  setNobiliaSampleClass: (nobiliaSampleClass: NobiliaSampleClass | null) => void
}

const CreateUpdateNobiliaSampleClassModal = ({
  nobiliaSampleClass,
  setNobiliaSampleClass,
}: CreateUpdateNobiliaSampleClassModalProps) => {
  const { refetch } = useNobiliaSampleClassMany()
  const { loading, upsertNobiliaSampleClass } = useNobiliaSampleClassMutation()

  const [open, setOpen] = useState(false)
  const {
    setValue,
    reset,
    control,
    formState: { isValid },
    watch,
    handleSubmit,
  } = useForm<NobiliaSampleForm>({
    mode: 'onTouched',
    defaultValues,
  })

  const isDeprecated = watch('deprecated')
  const isOutOfStock = watch('outOfStock')

  useEffect(() => {
    if (nobiliaSampleClass) {
      setOpen(true)
      setValue('identifier', nobiliaSampleClass.identifier)
      setValue('description', nobiliaSampleClass.description)
      setValue('imageUrl', nobiliaSampleClass.imageUrl ?? '')
      setValue(
        'expectedRestockDate',
        nobiliaSampleClass.meta.expectedRestockDate
          ? moment(nobiliaSampleClass.meta.expectedRestockDate).format(
              dateFormat,
            )
          : '',
      )
      setValue('deprecated', !!nobiliaSampleClass.meta.deprecated)
      setValue('outOfStock', !!nobiliaSampleClass.meta.outOfStock)
    }
  }, [nobiliaSampleClass])

  const handleExited = () => {
    reset(defaultValues)
    setNobiliaSampleClass(null)
  }

  const onSubmit: SubmitHandler<NobiliaSampleForm> = async (data) => {
    const newNobiliaSampleClass = {
      identifier: data.identifier,
      description: data.description,
      imageUrl: data.imageUrl.replace(`${process.env.GATSBY_CDN_ROOT}/`, ''),
      meta: {
        expectedRestockDate: data.expectedRestockDate
          ? moment(data.expectedRestockDate).toISOString()
          : null,
        deprecated: data.deprecated,
        outOfStock: data.outOfStock,
      },
    }

    await upsertNobiliaSampleClass({
      variables: {
        where: {
          id: nobiliaSampleClass?.id ?? '',
        },
        update: newNobiliaSampleClass,
        create: newNobiliaSampleClass,
      },
      onCompleted: () => {
        refetch()
        setOpen(false)
        notifySuccess(
          `Successfully ${nobiliaSampleClass ? 'updated' : 'created'}`,
        )
      },
      onError: () => {
        notifyError(
          `Could not ${
            nobiliaSampleClass ? 'update' : 'create'
          } nobilia sample class`,
        )
      },
    })
  }

  useEffect(() => {
    if (isDeprecated) {
      setValue('outOfStock', false)
    }
  }, [isDeprecated])

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onExited={handleExited}
      trigger={
        <Button
          kind="outlined"
          color="blue"
          text="Create New"
          fontAwesomeIcon="plus"
        />
      }
      title={
        nobiliaSampleClass
          ? 'Update Nobilia Sample'
          : 'Create New Nobilia Sample'
      }
      saveButton={{
        text: nobiliaSampleClass ? 'Update' : 'Create',
        disabled: !isValid,
        onClick: handleSubmit(onSubmit),
        loading,
      }}
      size="medium"
    >
      <StyledForm>
        <StyledFormGroup>
          <ControlledInput
            control={control}
            label="Identifier"
            name="identifier"
            rules={{ required: 'This field is required' }}
            width={8}
          />
          {isOutOfStock && (
            <Controller
              control={control}
              name="expectedRestockDate"
              shouldUnregister
              rules={{
                validate: (value) => {
                  if (value && !moment(value, dateFormat, true).isValid()) {
                    return 'Invalid date!'
                  }
                  return true
                },
              }}
              render={({
                field: { name, value, onBlur, onChange },
                fieldState: { error },
              }) => {
                return (
                  <DateInput
                    width={8}
                    clearable
                    name={name}
                    dateFormat={dateFormat}
                    placeholder={dateFormat}
                    value={value ?? ''}
                    minDate={moment()}
                    onBlur={onBlur}
                    label="Expected Restock Date"
                    onChange={(_, { value }) => onChange(value)}
                    error={!!error?.message}
                  />
                )
              }}
            />
          )}
        </StyledFormGroup>
        <ControlledInput
          control={control}
          label="Description"
          name="description"
          rules={{ required: 'This field is required' }}
          width={16}
        />
        <ControlledInput
          control={control}
          label="Image URL"
          name="imageUrl"
          rules={{ required: 'This field is required' }}
          width={16}
        />

        <Form.Group widths="equal">
          <ControlledRadio
            control={control}
            label="Deprecated"
            name="deprecated"
            width={8}
          />
          <ControlledRadio
            control={control}
            label="Out of Stock"
            name="outOfStock"
            width={8}
            disabled={isDeprecated}
          />
        </Form.Group>
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateNobiliaSampleClassModal

const ControlledInput = ({
  label,
  placeholder,
  width,
  disabled,
  ...props
}: Omit<ControllerProps<NobiliaSampleForm>, 'render'> & {
  placeholder?: string
  label?: string
  width?: SemanticWIDTHS
  disabled?: boolean
}) => (
  <Controller<NobiliaSampleForm>
    {...props}
    render={({
      field: { name, value, onBlur, onChange },
      fieldState: { error },
    }) => (
      <Form.Input
        width={width}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        placeholder={placeholder}
        error={
          !!error?.message && {
            content: <p className="red small">{error.message}</p>,
          }
        }
        disabled={disabled}
        required={!!props.rules?.required}
      />
    )}
  />
)

const ControlledRadio = ({
  label,
  disabled,
  width,
  ...props
}: Omit<ControllerProps<NobiliaSampleForm>, 'render'> & {
  label?: string
  disabled?: boolean
  width?: SemanticWIDTHS
}) => (
  <Controller<NobiliaSampleForm>
    {...props}
    render={({ field: { name, value, onBlur, onChange } }) => {
      const handleChange: RadioProps['onChange'] = (_, { checked }) => {
        onChange(checked)
      }

      return (
        <FormCheckbox
          name={name}
          checked={!!value}
          onChange={handleChange}
          onBlur={onBlur}
          label={label}
          disabled={disabled}
          width={width}
        />
      )
    }}
  />
)

const StyledFormGroup = styled(Form.Group)`
  align-items: flex-start !important;
  justify-content: space-between !important;

  & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`
