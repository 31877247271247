import React from 'react'

import { RouteComponentProps } from '@reach/router'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'

const PaymentsIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ project_id: string }>>,
) => {
  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Payments" />
      {props.children}
    </ProjectLayout>
  )
}

export default PaymentsIndex
