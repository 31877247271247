import React from 'react'

import { RouteComponentProps } from '@reach/router'

import OrderProvider from 'context/order/provider'
import { FIND_UNIQUE_ORDER_SAMPLES_CONTEXT } from 'queries/order'

interface OrderProps
  extends RouteComponentProps<{
    project_id: string
    order_id: string
  }> {
  children?: React.ReactNode
}

export default (props: OrderProps) => (
  <OrderProvider
    query={FIND_UNIQUE_ORDER_SAMPLES_CONTEXT}
    order_id={props.order_id}
  >
    {props.children}
  </OrderProvider>
)
