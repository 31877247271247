import React, { useEffect } from 'react'

import { useLocation } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import HeaderAdmin from 'components/admin/header'
import Tracking from 'components/shared/tracking'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'

import ProjectMenu from './menu'
import ProjectOverviewFeed from './overview-feed'

const ProjectLayout = (props: React.PropsWithChildren) => {
  const { pathname } = useLocation()
  const { user, logout } = useAuth()
  const { project } = useProject()

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [pathname])

  return (
    <>
      <HeaderAdmin project={project} user={user} logout={logout} />
      <Tracking />
      <div style={{ padding: '0 1rem' }}>
        <Grid stackable style={{ marginBottom: '0' }}>
          <ProjectOverviewFeed />
          <Grid.Row>
            <Grid.Column width={3}>
              <ProjectMenu />
            </Grid.Column>
            <Grid.Column width={13}>{props.children}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  )
}

export default ProjectLayout
