import styled, { css } from 'styled-components'

import {
  Breakpoint,
  Colors,
  ScrollBarStyle,
  StyledSystem,
} from 'styles/app/system'

export type PanelSize = 'sm' | 'md' | 'lg'

export type PanelPosition = 'right' | 'left'

export const Overlay = styled.div<{ open: boolean; position: PanelPosition }>`
  ${StyledSystem}

  position: fixed;
  inset: 0;
  background: rgba(99, 108, 128, 0.4);
  transition: background 0.3s ease-in-out;
  z-index: 2000;
  isolation: isolate;

  ${(props) =>
    props.open
      ? css`
          background: rgba(99, 108, 128, 0.4);
          pointer-events: visible;
        `
      : css`
          background: rgba(99, 108, 128, 0);

          pointer-events: none;
        `}

  &.enter {
    background: rgba(99, 108, 128, 0);

    .panel {
      ${({ position }) => css`
        transform: translateX(${position === 'right' ? '100%' : '-100%'});
      `}
    }
  }
`

type PanelProps = { open: boolean; size: PanelSize; position: PanelPosition }

export const Panel = styled.div<PanelProps>`
  ${ScrollBarStyle}

  background-color: ${Colors.white};
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 1.5rem;
  padding-top: 36px;
  position: absolute;
  top: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;

  ${(props) =>
    props.position === 'right' &&
    css`
      right: 0;
    `};

  ${(props) =>
    props.position === 'left' &&
    css`
      left: 0;
    `};

  ${({ open, position }) =>
    open
      ? css`
          pointer-events: visible;
          transform: translateX(0%);
        `
      : css`
          pointer-events: none;
          transform: translateX(${position === 'right' ? '100%' : '-100%'});
        `}

  ${({ size }) =>
    size === 'sm' &&
    css`
      max-width: 460px;
    `};

  ${({ size }) =>
    size === 'md' &&
    css`
      max-width: 760px;
    `};

  ${({ size }) =>
    size === 'md' &&
    css`
      max-width: 760px;
    `};

  ${({ size }) =>
    size === 'lg' &&
    css`
      max-width: 960px;
    `};

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media ${Breakpoint.onlyMobile} {
    max-width: 100% !important;
  }
`
