import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import SupplierOrderTab from 'components/admin/project/supplier-order/tab'
import { Chip } from 'components/shared/chip'
import useProjectSupplierOrderMany from 'context/project/supplier-order/use-many'
import useProject from 'context/project/use'
import { getRoomTypeWithDescription } from 'context/room/utils'
import useSupplierOrder from 'context/supplier-order/use'

const SupplierOrderCreateUpdate = (
  _: RouteComponentProps<{ project_id: string; delivery_id?: string }>,
) => {
  const { project, refetch: refetchProject } = useProject()
  const { supplierOrder } = useSupplierOrder()
  const { refetch } = useProjectSupplierOrderMany()
  const room = supplierOrder?.design?.room

  return (
    <Grid>
      <ProjectPageTitle viewName="Supplier Order" />
      <Grid.Row>
        <Grid.Column>
          <div className="flex" style={{ gap: '20px' }}>
            <h5 className="no-margin">
              {supplierOrder ? 'Edit Supplier Order' : 'Create Supplier Order'}
            </h5>
            {supplierOrder && room ? (
              <Chip
                noMargin
                name={getRoomTypeWithDescription(room)}
                color={room.data.tagColor}
                opacity={0.3}
              />
            ) : null}
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <SupplierOrderTab
            onComplete={(createdId?: string) => {
              refetch()
              refetchProject()
              if (createdId)
                navigate(
                  `/admin/projects/${project.id}/supplier-orders/${createdId}`,
                )
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default SupplierOrderCreateUpdate
