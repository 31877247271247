import React from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectLayout from 'components/admin/project/layout'
import ProjectOrderFORMManyProvider from 'context/project/order-form/provider-many'

interface ProjectOrderFORMIndexManyProps
  extends RouteComponentProps<{
    project_id: string
  }> {
  children?: React.ReactNode
}

export default (props: ProjectOrderFORMIndexManyProps) => (
  <ProjectLayout>
    <ProjectOrderFORMManyProvider>
      {props.children}
    </ProjectOrderFORMManyProvider>
  </ProjectLayout>
)
