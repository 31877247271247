import React, { createContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { VisibilityCalculations } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import {
  FIND_MANY_USER,
  FindManyUserPayload,
  FindManyUserVariables,
} from 'queries/user'
import { User } from 'types/user'

import { QueryFilters, getQueryVariables } from './utils-many'

interface IUserManyContext {
  users: User[]
  count: number
  loading: boolean
  loadingMore: boolean
  fetchMore: (v: VisibilityCalculations) => Promise<void>
  queryFilters: QueryFilters
  refetch: () => Promise<unknown>
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

type UserManyProviderProps = React.PropsWithChildren<{
  defaultFilters: QueryFilters
  skipLoader?: boolean
}>

export const UserManyContext = createContext<IUserManyContext>(
  {} as IUserManyContext,
)

const UserManyProvider = ({
  children,
  defaultFilters,
  skipLoader,
}: UserManyProviderProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(defaultFilters)
  const [queryVariables, setQueryVariables] = useState<FindManyUserVariables>(
    getQueryVariables(queryFilters),
  )

  const {
    data: { users, count } = { users: [], count: 0 },
    loading,
    fetchMore,
    refetch,
  } = useQuery<FindManyUserPayload, FindManyUserVariables>(FIND_MANY_USER, {
    variables: queryVariables,
  })

  useEffect(() => {
    setQueryFilters(defaultFilters)
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  const fetchMore_ = async ({ bottomVisible }: VisibilityCalculations) => {
    if (loading || loadingMore || !bottomVisible || users.length === count)
      return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: users.length,
      },
    })
    setLoadingMore(false)
  }

  if (loading && !skipLoader) return <Loader />

  return (
    <UserManyContext.Provider
      value={{
        users,
        count: count ?? 0,
        loading,
        loadingMore,
        fetchMore: fetchMore_,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {children}
    </UserManyContext.Provider>
  )
}

export default UserManyProvider
