import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import useProjectMutateNoContext from 'context/project/use-mutate-no-context'
import { parseOwners } from 'context/project/utils'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import { Project, ArchivedState } from 'types/project'
import { getNameForUser } from 'views/utils'

interface ArchiveProjectModalProps {
  onClose: () => void
  open: boolean
  project: Project
  refetch: () => Promise<unknown>
}

const archivedReasonOptions = [
  {
    key: '0',
    text: 'On Pause - Check back later',
    value: 'On Pause - Check back later',
  },
  {
    key: '1',
    text: 'Price - Too expensive',
    value: 'Price - Too expensive',
  },
  {
    key: '2',
    text: 'Client didn’t like the design proposal',
    value: 'Client didn’t like the design proposal',
  },
  {
    key: '3',
    text: 'Lead time too long',
    value: 'Lead time too long',
  },
  {
    key: '4',
    text: 'Client wanted something very specific (product limitations)',
    value: 'Client wanted something very specific (product limitations)',
  },
  {
    key: '5',
    text: 'Lost to competitor',
    value: 'Lost to competitor',
  },
  {
    key: '6',
    text: 'Client stopped replying',
    value: 'Client stopped replying',
  },
  {
    key: '7',
    text: 'Other',
    value: 'Other',
  },
]

export const ArchiveProjectModal = ({
  onClose,
  open,
  project,
  refetch,
}: ArchiveProjectModalProps) => {
  const { updateProject } = useProjectMutateNoContext()

  const archivedState = project?.data?.archivedState
  const initialState = (() => {
    if (archivedState === ArchivedState.CLOSED_DEAL)
      return {
        archivedState,
        archivedReason: '',
        other: '',
      }
    if (archivedState === ArchivedState.LOST_DEAL) {
      const lookup = archivedReasonOptions.find(
        (o) => o.value === project.data?.archivedReason,
      )
      if (lookup) {
        return {
          archivedState,
          archivedReason: project.data?.archivedReason,
          other: '',
        }
      }
      return {
        archivedState,
        archivedReason: 'Other',
        other: project.data?.archivedReason,
      }
    }
    return {
      archivedState,
      archivedReason: '',
      other: '',
    }
  })()

  const [state, setState] = useState<{
    archivedReason?: string
    archivedState?: string
    other?: string
  }>(initialState)

  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    setState(initialState)
    setChecked(false)
  }, [project, open])

  const onSave = async () => {
    await updateProject({
      variables: {
        data: {
          archivedAt: project.archivedAt || new Date(),
          data: {
            archivedReason:
              state.archivedReason === 'Other'
                ? state.other
                : state.archivedReason,
            archivedState: state.archivedState as ArchivedState,
          },
        },
        where: {
          id: project.id,
        },
      },
    })
    refetch?.()
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      containerStyle={{ minHeight: '600px' }}
      size="small"
      title={`Close / Pause ${getNameForUser(
        parseOwners(project)?.primaryOwner,
      )} Project`}
      customActions={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <FormCheckbox
            checked={checked}
            label="I'm sure I want to close this project"
            onChange={() => setChecked(!checked)}
          />
          <div>
            <Button
              color="blue"
              kind="solid"
              onClick={onClose}
              style={{ marginRight: '14px' }}
              text="Close"
            />
            <Button
              kind="solid"
              disabled={(() => {
                if (!checked) return true
                if (state.archivedState !== ArchivedState.CLOSED_DEAL)
                  return (
                    !state.archivedState ||
                    (state.archivedReason === 'Other' && !state.other) ||
                    !state.archivedReason
                  )
                return false
              })()}
              onClick={onSave}
              text="Save"
            />
          </div>
        </div>
      }
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Field>
          <label>Choose an action</label>
          <Button
            color="blue"
            kind={
              state.archivedState &&
              state.archivedState === ArchivedState.LOST_DEAL
                ? 'solid'
                : 'outlined'
            }
            onClick={() =>
              setState({
                ...state,
                archivedState: ArchivedState.LOST_DEAL,
              })
            }
            style={{ marginRight: '14px' }}
            text="Project Is Lost"
            size="large"
          />
          <Button
            color="blue"
            kind={
              state.archivedState === ArchivedState.CLOSED_DEAL
                ? 'solid'
                : 'outlined'
            }
            onClick={() =>
              setState({
                ...state,
                archivedState: ArchivedState.CLOSED_DEAL,
              })
            }
            text="Project Is Installed"
            size="large"
          />
        </Form.Field>
        {!!state.archivedState &&
          state.archivedState !== ArchivedState.CLOSED_DEAL && (
            <>
              <Form.Group widths="equal">
                <Form.Dropdown
                  label="Reason for archiving"
                  onChange={(_, { value }) =>
                    setState({
                      ...state,
                      archivedReason: value as string,
                      other: value === 'Other' ? state.other : '',
                    })
                  }
                  options={archivedReasonOptions}
                  selection
                  value={state.archivedReason}
                />
              </Form.Group>
              <Form.Input
                disabled={state.archivedReason !== 'Other'}
                label="Other"
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) =>
                  setState({ ...state, other: value })
                }
                placeholder="Write the reason here"
                value={state.other}
              />
            </>
          )}
      </StyledForm>
    </Modal>
  )
}

export default ArchiveProjectModal
