import React, { useState } from 'react'

import moment from 'moment'

import { Chip } from 'components/shared/chip'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import { ArchivedState } from 'types/project'

const ProjectOverviewArchived = () => {
  const [confirmUnarchive, toggleConfirmUnarchive] = useState<boolean>(false)
  const { project } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()
  if (!project.archivedAt) return null
  const isClosedDeal = project.data?.archivedState === ArchivedState.CLOSED_DEAL

  return (
    <div className="flex">
      <Chip
        name={`${isClosedDeal ? 'Closed Won' : 'Closed Lost'} on ${
          project.archivedAt ? moment(project.archivedAt).format('LL') : ''
        }`}
        color={isClosedDeal ? Colors.greenBright50 : Colors.redBright}
        opacity={0.3}
        noMargin
      />
      <Button
        color="gray"
        kind="outlined"
        onClick={() => toggleConfirmUnarchive(true)}
        text="Unarchive"
        style={{ marginLeft: '18px' }}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to unarchive this project?"
        onCancel={() => toggleConfirmUnarchive(false)}
        onConfirm={async () => {
          await updateProjectAndRefetch({
            variables: {
              data: {
                archivedAt: null,
                snoozedUntil: null,
                data: {
                  archivedReason: '',
                  archivedState: null,
                },
              },
              where: {
                id: project.id,
              },
            },
          })
          toggleConfirmUnarchive(false)
        }}
        open={confirmUnarchive}
      />
    </div>
  )
}

export default ProjectOverviewArchived
