import { useContext } from 'react'

import { CompanyManyContext } from './provider-many'

const useCompanyMany = () => {
  const {
    companies,
    count,
    fetchMore,
    loading,
    loadingMore,
    queryFilters,
    setQueryFilters,
    refetch,
  } = useContext(CompanyManyContext)

  return {
    companies,
    count,
    fetchMore,
    loading,
    loadingMore,
    queryFilters,
    setQueryFilters,
    refetch,
  }
}

export default useCompanyMany
