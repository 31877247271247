import React, { createContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { VisibilityCalculations } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import { apolloClient } from 'context/authorized-apollo/provider'
import {
  FindManyRoomPayload,
  FindManyRoomVariables,
  FIND_MANY_ROOM,
} from 'queries/room'
import { RoomGraphQL } from 'types/room'

import { QueryFilters, getQueryVariables } from './utils-many'

interface IRoomManyContext {
  rooms: RoomGraphQL[]
  count: number
  loading: boolean
  loadingMore: boolean
  fetchMore: (v: VisibilityCalculations) => Promise<void>
  refetch: () => Promise<unknown>
  queryFilters: QueryFilters
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

type RoomManyProviderProps = React.PropsWithChildren<{
  defaultFilters?: QueryFilters
  skipLoader?: boolean
}>

export const RoomManyContext = createContext<IRoomManyContext>(
  {} as IRoomManyContext,
)

const RoomManyProvider = ({
  children,
  defaultFilters,
  skipLoader,
}: RoomManyProviderProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(
    defaultFilters as QueryFilters,
  )
  const [queryVariables, setQueryVariables] = useState<FindManyRoomVariables>(
    getQueryVariables(queryFilters),
  )

  const {
    data: { rooms, count } = { rooms: [], count: 0 },
    loading,
    fetchMore,
  } = useQuery<FindManyRoomPayload, FindManyRoomVariables>(FIND_MANY_ROOM, {
    variables: queryVariables,
  })

  useEffect(() => {
    // this effect triggers on refetch, something to do with the cache eviction
    // triggering a higher level re-render.
    // To avoid losing filters on refetch we add the refetching check here
    if (!refetching) {
      setQueryFilters(defaultFilters as QueryFilters)
    }
    setRefetching(false)
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  const fetchMore_ = async ({ bottomVisible }: VisibilityCalculations) => {
    if (loading || loadingMore || !bottomVisible || rooms?.length === count)
      return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: rooms?.length,
      },
    })
    setLoadingMore(false)
  }

  const refetch = async () => {
    setRefetching(true)
    // evict and refetch first batch
    apolloClient?.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'findManyRoom',
    })
  }

  if (loading && !skipLoader) return <Loader />

  return (
    <RoomManyContext.Provider
      value={{
        rooms: rooms ?? [],
        count: count ?? 0,
        loading,
        loadingMore,
        fetchMore: fetchMore_,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {children}
    </RoomManyContext.Provider>
  )
}

export default RoomManyProvider
