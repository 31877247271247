import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Projects from 'components/admin/project/index-many'
import useTechDesignerSupport from 'context/dashboard/tech-designer-support/use'
import ProjectManyProvider from 'context/project/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import TechDesignerSupportLayout from './layout'

const TechDesignerSupportProjects = (_: RouteComponentProps) => {
  const { userId, userName } = useTechDesignerSupport()

  return (
    <TechDesignerSupportLayout>
      <ProjectManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          assignedToRole: ProjectStaffRole.TECH_DESIGNER_SUPPORT,
          sortBy: 'derivedActivity',
          sortDirection: 'descending',
          status: 'open',
          take: 30,
        }}
        skipLoader
      >
        <Projects />
      </ProjectManyProvider>
    </TechDesignerSupportLayout>
  )
}

export default TechDesignerSupportProjects
