import { useMemo } from 'react'

import useShipmentManyNoContext from 'context/shipment/use-many-no-context'
import { WarehouseOptions } from 'context/shipment/utils'
import useSupplierOrderMany from 'context/supplier-order/use-many'
import {
  FORMStatus,
  NobiliaStatus,
  SupplierOrderType,
} from 'context/supplier-order/utils-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { getDropdownOptionsFromEnum } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormCheckboxValue,
  IFormDropdownValue,
} from '../filters-search'

export type SupplierOrderFilterValues = {
  assignedTo: IFormDropdownValue
  includeThirdParty: IFormCheckboxValue
  type: IFormCheckboxValue
  shipment: IFormDropdownValue
  warehouse: IFormDropdownValue
  statusesFORM: IFormCheckboxValue
  statusesNobilia: IFormCheckboxValue
}
const useSupplierOrderFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useSupplierOrderMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()
  const { shipmentOptions } = useShipmentManyNoContext({
    variables: {
      where: {
        archivedAt: null,
      },
    },
  })

  const filters: DynamicFilterGroup<SupplierOrderFilterValues>[] =
    useMemo(() => {
      return [
        {
          filters: [
            {
              filterId: 'assignedTo',
              type: DynamicFilterTypes.DROPDOWN,
              label: 'Assigned to',
              options: [
                ...userOptions,
                {
                  key: 'everyone',
                  text: 'Everyone',
                  value: 'Everyone',
                },
              ],
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'includeThirdParty',
              type: DynamicFilterTypes.CHECKBOX,
              label: '',
              options: [
                {
                  text: 'Include Third Party',
                  value: 'thirdParty',
                },
              ],
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'type',
              type: DynamicFilterTypes.CHECKBOX,
              label: 'Type',
              options: getDropdownOptionsFromEnum(SupplierOrderType, true),
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'shipment',
              type: DynamicFilterTypes.DROPDOWN,
              label: 'Shipment',
              options: shipmentOptions,
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'warehouse',
              type: DynamicFilterTypes.DROPDOWN,
              label: 'Warehouse',
              options: WarehouseOptions,
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'statusesFORM',
              type: DynamicFilterTypes.CHECKBOX,
              label: 'FORM status',
              options: getDropdownOptionsFromEnum(FORMStatus, true),
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'statusesNobilia',
              type: DynamicFilterTypes.CHECKBOX,
              label: 'Nobilia status',
              options: getDropdownOptionsFromEnum(NobiliaStatus, true),
            },
          ],
        },
      ]
    }, [shipmentOptions, userOptions])

  const defaultFilters = useMemo(() => {
    const supplierOrderFilters: Partial<SupplierOrderFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      supplierOrderFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }
    if (queryFilters.includeThirdParty)
      supplierOrderFilters.includeThirdParty = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: [
          {
            value: 'thirdParty',
            text: 'Include Third Party',
          },
        ],
      }
    if (queryFilters.type?.length)
      supplierOrderFilters.type = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.type.map((s) => {
          return {
            value: s,
            text: s,
          }
        }),
      }
    if (queryFilters.shipment)
      supplierOrderFilters.shipment = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.shipment,
          text:
            shipmentOptions.find((so) => so.value === queryFilters.shipment)
              ?.text ?? '',
        },
      }
    if (queryFilters.warehouse)
      supplierOrderFilters.warehouse = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.warehouse,
          text:
            WarehouseOptions.find((w) => w.value === queryFilters.warehouse)
              ?.text ?? '',
        },
      }
    if (queryFilters.statusesFORM?.length)
      supplierOrderFilters.statusesFORM = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.statusesFORM.map((s) => {
          return {
            value: s,
            text: s,
          }
        }),
      }
    if (queryFilters.statusesNobilia?.length)
      supplierOrderFilters.statusesNobilia = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.statusesNobilia.map((s) => {
          return {
            value: s,
            text: s,
          }
        }),
      }

    return supplierOrderFilters
  }, [queryFilters])

  const handleApplyFilters = (data: SupplierOrderFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      includeThirdParty:
        data.includeThirdParty?.selection[0].value === 'thirdParty',
      type: data.type?.selection.map((f) => {
        return f.value as SupplierOrderType
      }),
      shipment: data.shipment?.selection.value ?? undefined,
      warehouse: data.warehouse?.selection.value ?? undefined,
      statusesFORM: data.statusesFORM?.selection.map((f) => {
        return f.value as FORMStatus
      }),
      statusesNobilia: data.statusesNobilia?.selection.map((f) => {
        return f.value as NobiliaStatus
      }),
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: undefined,
        assignedToRole: null,
      }),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useSupplierOrderFiltersSearch
