import React, { useState } from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import useShipmentMany from 'context/shipment/use-many'
import Button from 'design-system/components/button'

import ShipmentModal from './create-update-modal'
import ShipmentsTable from './table'
import useShipmentFiltersSearch from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const Shipments = () => {
  const [enableShipmentCreate, setEnableShipmentCreate] = useState(false)
  const { count, shipments, fetchMore, loading, loadingMore, refetch } =
    useShipmentMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useShipmentFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${shipments?.length} of ${count}`}{' '}
            Shipments
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            color="gray"
            kind="outlined"
            onClick={() => setEnableShipmentCreate(true)}
            text="Create New"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            <ShipmentsTable />
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && shipments?.length > 0 && <BackToTop />}
      <ShipmentModal
        open={enableShipmentCreate}
        onClose={() => {
          setEnableShipmentCreate(false)
        }}
        refetch={refetch}
        shipmentId="create"
      />
    </Grid>
  )
}

export default Shipments
