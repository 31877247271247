import { FindManyNobiliaProductClassVariables } from 'queries/nobilia-product-class'

export interface QueryFilters {
  catalog: string

  section?: string

  // search by description
  search?: string

  // how many to take
  take: number

  // sort values
  sortBy: 'derivedSort' | 'identifier'
  sortDirection: 'ascending' | 'descending'
}

export const getQueryVariables = ({
  catalog,
  section,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyNobiliaProductClassVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'identifier': {
        return {
          identifier: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      case 'derivedSort': {
        return {
          derivedSort: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyNobiliaProductClassVariables = {
    orderBy,
    take,
  }
  if (catalog) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        catalog: {
          equals: catalog,
        },
      },
    }
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        orderId: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (section) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSort: {
          contains: section,
          mode: 'insensitive',
        },
      },
    }
  }
  return variables
}
