import { useMemo } from 'react'

import useDesignMany from 'context/design/use-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { ProjectStaffRole, PROJECT_STAFF_ROLES } from 'types/project-staff'
import { capsSnakeCaseToTitleCase, camelCaseToTitleCase } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormDropdownValue,
  IFormRadioValue,
} from '../filters-search'

export type DesignFilterValues = {
  assignedTo: IFormDropdownValue
  assignedToRole: IFormDropdownValue
  galleryStatus: IFormRadioValue
  designStage: IFormRadioValue
  orderStatus: IFormRadioValue
  renderStatus: IFormRadioValue
  type?: IFormRadioValue
}

export enum FiltersVersion {
  APPROVED = 'APPROVED',
  RENDERING = 'RENDERING',
  REVIEW = 'REVIEW',
  GALLERY = 'GALLERY',
}

const useDesignFiltersSearch = (filtersVersion?: FiltersVersion) => {
  const { queryFilters, setQueryFilters } = useDesignMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()

  const filters: DynamicFilterGroup<DesignFilterValues>[] = useMemo(() => {
    switch (filtersVersion) {
      case FiltersVersion.APPROVED: {
        return [
          {
            filters: [
              {
                filterId: 'type',
                type: DynamicFilterTypes.RADIO,
                label: 'Type',
                options: [
                  {
                    text: 'All',
                    value: 'all',
                  },
                  {
                    text: 'Regular',
                    value: 'regular',
                  },
                  {
                    text: 'Add-On',
                    value: 'addOn',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'assignedTo',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned to',
                options: [
                  ...userOptions,
                  {
                    key: 'everyone',
                    text: 'Everyone',
                    value: 'Everyone',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'assignedToRole',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned role',
                options: PROJECT_STAFF_ROLES.map((r) => {
                  return {
                    key: r,
                    text: capsSnakeCaseToTitleCase(r),
                    value: r,
                  }
                }),
              },
            ],
          },
        ]
      }
      case FiltersVersion.RENDERING: {
        return [
          {
            filters: [
              {
                filterId: 'assignedTo',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned to',
                options: [
                  ...userOptions,
                  {
                    key: 'everyone',
                    text: 'Everyone',
                    value: 'Everyone',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'assignedToRole',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned role',
                options: PROJECT_STAFF_ROLES.map((r) => {
                  return {
                    key: r,
                    text: capsSnakeCaseToTitleCase(r),
                    value: r,
                  }
                }),
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'renderStatus',
                type: DynamicFilterTypes.RADIO,
                label: 'Render status',
                options: [
                  {
                    text: 'All',
                    value: 'all',
                  },
                  {
                    text: 'Not sent to ArchCGI',
                    value: 'notSentToArchCGI',
                  },
                  {
                    text: 'Sent to ArchCGI',
                    value: 'sentToArchCGI',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'designStage',
                type: DynamicFilterTypes.RADIO,
                label: 'Design stage',
                options: [
                  {
                    text: 'All',
                    value: 'all',
                  },
                  {
                    text: 'Initial',
                    value: 'initial',
                  },
                  {
                    text: 'Revision',
                    value: 'revision',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'orderStatus',
                type: DynamicFilterTypes.RADIO,
                label: 'Order status',
                options: [
                  {
                    text: 'All',
                    value: 'all',
                  },
                  {
                    text: 'Not created',
                    value: 'notCreated',
                  },
                  {
                    text: 'Created',
                    value: 'created',
                  },
                  {
                    text: 'Placed',
                    value: 'placed',
                  },
                ],
              },
            ],
          },
        ]
      }
      case FiltersVersion.REVIEW: {
        return [
          {
            filters: [
              {
                filterId: 'assignedTo',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned to',
                options: [
                  ...userOptions,
                  {
                    key: 'everyone',
                    text: 'Everyone',
                    value: 'Everyone',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'assignedToRole',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned role',
                options: PROJECT_STAFF_ROLES.map((r) => {
                  return {
                    key: r,
                    text: capsSnakeCaseToTitleCase(r),
                    value: r,
                  }
                }),
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'designStage',
                type: DynamicFilterTypes.RADIO,
                label: 'Design stage',
                options: [
                  {
                    text: 'All',
                    value: 'all',
                  },
                  {
                    text: 'Initial',
                    value: 'initial',
                  },
                  {
                    text: 'Revision',
                    value: 'revision',
                  },
                ],
              },
            ],
          },
        ]
      }
      case FiltersVersion.GALLERY: {
        return [
          {
            filters: [
              {
                filterId: 'assignedTo',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned to',
                options: [
                  ...userOptions,
                  {
                    key: 'everyone',
                    text: 'Everyone',
                    value: 'Everyone',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'assignedToRole',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned role',
                options: PROJECT_STAFF_ROLES.map((r) => {
                  return {
                    key: r,
                    text: capsSnakeCaseToTitleCase(r),
                    value: r,
                  }
                }),
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'galleryStatus',
                type: DynamicFilterTypes.RADIO,
                label: 'Gallery status',
                options: [
                  {
                    text: 'All',
                    value: 'all',
                  },
                  {
                    text: 'Showing In Gallery',
                    value: 'showingInGallery',
                  },
                  {
                    text: 'Not Showing In Gallery',
                    value: 'notShowingInGallery',
                  },
                ],
              },
            ],
          },
        ]
      }
      default: {
        return [
          {
            filters: [
              {
                filterId: 'assignedTo',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned to',
                options: [
                  ...userOptions,
                  {
                    key: 'everyone',
                    text: 'Everyone',
                    value: 'Everyone',
                  },
                ],
              },
            ],
          },
          {
            filters: [
              {
                filterId: 'assignedToRole',
                type: DynamicFilterTypes.DROPDOWN,
                label: 'Assigned role',
                options: PROJECT_STAFF_ROLES.map((r) => {
                  return {
                    key: r,
                    text: capsSnakeCaseToTitleCase(r),
                    value: r,
                  }
                }),
              },
            ],
          },
        ]
      }
    }
  }, [filtersVersion, userOptions])

  const defaultFilters = useMemo(() => {
    const designFilters: Partial<DesignFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      designFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }
    if (queryFilters.assignedToRole)
      designFilters.assignedToRole = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedToRole,
          text: capsSnakeCaseToTitleCase(queryFilters.assignedToRole),
        },
      }
    if (queryFilters.renderStatus)
      designFilters.renderStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.renderStatus,
          text: camelCaseToTitleCase(queryFilters.renderStatus),
        },
      }
    if (queryFilters.designStage)
      designFilters.designStage = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.designStage,
          text: camelCaseToTitleCase(queryFilters.designStage),
        },
      }
    if (queryFilters.orderStatus)
      designFilters.orderStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.orderStatus,
          text: camelCaseToTitleCase(queryFilters.orderStatus),
        },
      }
    if (queryFilters.galleryStatus)
      designFilters.galleryStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.galleryStatus,
          text: camelCaseToTitleCase(queryFilters.galleryStatus),
        },
      }
    if (queryFilters.type)
      designFilters.type = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.type,
          text: camelCaseToTitleCase(queryFilters.type),
        },
      }
    return designFilters
  }, [queryFilters])

  const handleApplyFilters = (data: DesignFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      assignedToRole: data.assignedToRole?.selection.value as ProjectStaffRole,
      designStage:
        data.designStage?.selection.value === 'all'
          ? undefined
          : (data.designStage?.selection.value as 'initial' | 'revision'),
      galleryStatus: queryFilters.galleryStatus,
      orderStatus:
        data.orderStatus?.selection.value === 'all'
          ? undefined
          : (data.orderStatus?.selection.value as
              | 'placed'
              | 'created'
              | 'notCreated'),
      productionStatus: queryFilters.productionStatus,
      renderStatus: data.renderStatus?.selection.value as
        | 'all'
        | 'notSentToArchCGI'
        | 'sentToArchCGI',
      reviewStatus: queryFilters.reviewStatus,
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: null,
        assignedToRole: null,
      }),
      type:
        data.type?.selection.value === 'all'
          ? undefined
          : (data.type?.selection.value as 'regular' | 'addOn'),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useDesignFiltersSearch
