import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Feed, Grid, Image } from 'semantic-ui-react'

import ProjectChecklistPanel from 'components/admin/project/checklist-panel'
import { getAddressString } from 'context/address/utils'
import ProjectChecklistProvider from 'context/project/checklist/provider'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import { StyledLabel } from 'styles/admin/main'
import * as viewUtils from 'views/utils'

import ProjectOverviewArchived from './overview-archived'

const ProjectOverviewFeed = () => {
  const { allStaff, project, primaryOwner, primaryOwnersNames } = useProject()
  const [checklistOpen, setChecklistOpen] = useState(false)

  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column width={13}>
          <ProjectOverviewArchived />
          <Feed>
            <Feed.Event>
              <Feed.Label style={{ width: '43px' }}>
                <Image
                  src={primaryOwner.picture}
                  circular
                  style={{ height: '43px', width: '43px' }}
                />
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary className="flex" style={{ gap: '16px' }}>
                  <h3 className="no-margin">
                    {viewUtils.responsiveText(primaryOwnersNames, 100)}{' '}
                    {project.data?.projectLabel || ''}
                  </h3>
                  {project.data?.projectType && (
                    <StyledLabel
                      styled={viewUtils.getLabelStyleForProjectType(
                        project.data?.projectType,
                      )}
                    >
                      {viewUtils.capsSnakeCaseToTitleCase(
                        project.data?.projectType,
                      )}
                    </StyledLabel>
                  )}
                  <Button
                    fontAwesomeIcon="cog"
                    text="Settings"
                    kind="solid"
                    color="blue"
                    onClick={() =>
                      navigate(
                        `/admin/projects/${project.id}/customer-settings`,
                      )
                    }
                  />
                </Feed.Summary>
                <Feed.Extra className="no-margin">
                  <p className="gray no-margin">
                    {allStaff.map(viewUtils.getNameForUser).join(', ')}
                  </p>
                  <p className="gray no-margin">
                    {getAddressString(project.projectAddress) ||
                      'No Location Yet'}
                  </p>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Grid.Column>
        <Grid.Column textAlign="right" width={3}>
          <Button
            text="To Do"
            kind="ghost"
            color="dark"
            fontAwesomeIcon="clipboard-list-check"
            onClick={() => setChecklistOpen(true)}
          />
        </Grid.Column>
      </Grid.Row>

      <ProjectChecklistProvider>
        <ProjectChecklistPanel
          open={checklistOpen}
          onClose={() => setChecklistOpen(false)}
        />
      </ProjectChecklistProvider>
    </>
  )
}

export default ProjectOverviewFeed
