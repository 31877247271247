// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { difference, sortBy } from 'lodash'
import {
  Button,
  Divider,
  DropdownItemProps,
  Form,
  Grid,
  Portal,
} from 'semantic-ui-react'
import styled from 'styled-components'

import useDesign from 'context/planner/design-use'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import {
  FormSelect,
  StyledForm,
  StyledPortal,
  StyledSearch,
} from 'styles/planner/main'
import { StyledContent } from 'styles/planner/system'
import { NobiliaFeature } from 'types/nobilia-feature'
import { NobiliaOption } from 'types/nobilia-option'

import { NodeOption } from '../../../objects/node'
import { ProductBase } from '../../../objects/product'
import NobiliaProductBase from '../../../objects/products/nobilia/base'
import { nobiliaFeatureToNodeOption } from '../../../objects/products/nobilia/options'
import * as plannerUtils from '../../../utils/planner-utils'
import { matchPreset } from '../../../utils/preset-utils'
import * as presetUtils from '../../../utils/preset-utils'

interface GlobalOptionsProps {
  locked?: boolean
  options: NobiliaOption[]
  onSave?: () => void
  presetIdentifier?: string
}

const GlobalOptionsMenu = ({
  locked,
  options,
  onSave = () => {},
  presetIdentifier,
}: GlobalOptionsProps) => {
  const { design, presets, sceneState, updateDesign } = useDesign()
  const {
    globalRestrictionsIndex,
    nobiliaFeatures,
    essential,
    secondary,
    regularlyUsed,
    rarelyUsed,
    neverUsed,
  } = useNobiliaOptions()
  const [selectedOptions, setSelectedOptions] =
    useState<Partial<NobiliaOption>[]>(options)
  const [loading, setLoading] = useState<boolean>(false)
  const nonEssential = [
    ...secondary,
    ...regularlyUsed,
    ...rarelyUsed,
    ...neverUsed,
  ].filter((f) => selectedOptions.some((o) => o.featureNo === f.featureNo))

  const onSaveOpts = async () => {
    setLoading(true)

    // create options object
    const newOptions = presetUtils.getOptionsExcluded(selectedOptions, options)
    const deletedOptions = presetUtils.getOptionsExcluded(
      options,
      selectedOptions,
    )
    if (!newOptions.length && !deletedOptions.length) {
      setLoading(false)
      return
    }

    const createNobiliaOptions = newOptions.length
      ? newOptions.map((o) => ({
          identifier: presetIdentifier,
          nobiliaOption: {
            connect: {
              id: presetUtils.getOptionIdFromOption(nobiliaFeatures, o),
            },
          },
        }))
      : undefined

    const deleteNobiliaOptions = deletedOptions.length
      ? deletedOptions.map((o) => ({
          nobiliaOptionId_designId_identifier: {
            nobiliaOptionId: presetUtils.getOptionIdFromOption(
              nobiliaFeatures,
              o,
            ),
            designId: design.id,
            identifier: presetIdentifier,
          },
        }))
      : undefined

    // update products based on presets
    for (const n of sceneState) {
      if (!n.isProduct()) continue
      const p = n as ProductBase
      if (!p.isNobiliaProduct()) continue
      const np = p as NobiliaProductBase
      const preset = matchPreset(presets, np.options)
      if (preset?.identifier === presetIdentifier) {
        np.applyNodeOptions(
          nobiliaFeatures
            .filter((f) =>
              selectedOptions.some((no) => no.featureNo === f.featureNo),
            )
            .map((f) =>
              nobiliaFeatureToNodeOption(
                f,
                selectedOptions.find((p) => p.featureNo === f.featureNo),
                [],
                {},
              ),
            ),
        )
      }
    }

    await updateDesign({
      data: {
        ...plannerUtils.sceneToGraphQL(design, sceneState),
        nobiliaOptions: {
          create: createNobiliaOptions,
          delete: deleteNobiliaOptions,
        },
      },
      where: {
        id: design.id,
      },
    })
    onSave()
    setLoading(false)
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            {presetIdentifier === 'default' && <p>Create Default Preset</p>}
          </Grid.Column>
          {!locked && (
            <Grid.Column width={1} floated="right">
              <Button
                className="orange"
                content="Save"
                disabled={
                  !difference(
                    selectedOptions.map((o) => o.featureNo + o.optionKey),
                    options.map((o) => o.featureNo + o.optionKey),
                  ).length
                }
                floated="right"
                loading={loading}
                onClick={onSaveOpts}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <StyledForm style={{ marginTop: '12px', width: '100%' }}>
        {essential.map((f) => {
          const no = nobiliaFeatureToNodeOption(
            f,
            selectedOptions.find((o) => o.featureNo === f.featureNo),
            selectedOptions,
            globalRestrictionsIndex,
          )
          return (
            <FeatureOption
              key={no.id}
              search={true}
              disabled={locked}
              feature={no}
              featureOptions={no.options}
              onChangeOption={(opt: NodeOption[]) => {
                const featuresAffected = opt.map((o) => o.id)
                const featuresDeleted = opt
                  .filter((o) => !o.value)
                  .map((o) => o.id)
                const optsSet = opt.filter((o) => !!o.value)
                setSelectedOptions([
                  ...selectedOptions.filter(
                    (o) =>
                      !featuresAffected.includes(o.featureNo) &&
                      !featuresDeleted.includes(o.featureNo),
                  ),
                  ...optsSet.map((o) => ({
                    featureNo: o.id,
                    optionKey: o.value,
                  })),
                ])
              }}
            />
          )
        })}
        {nonEssential.length ? (
          <>
            <Divider className="full-width" style={{ margin: '30px 0 20px' }} />
            {nonEssential.map((f) => {
              const no = nobiliaFeatureToNodeOption(
                f,
                selectedOptions.find((o) => o.featureNo === f.featureNo),
                selectedOptions,
                globalRestrictionsIndex,
              )
              return (
                <FeatureOption
                  key={no.id}
                  disabled={locked}
                  feature={no}
                  featureOptions={no.options}
                  onChangeOption={(opt: NodeOption[]) => {
                    const featuresAffected = opt.map((o) => o.id)
                    const featuresDeleted = opt
                      .filter((o) => !o.value)
                      .map((o) => o.id)
                    const optsSet = opt.filter((o) => !!o.value)
                    setSelectedOptions([
                      ...selectedOptions.filter(
                        (o) =>
                          !featuresAffected.includes(o.featureNo) &&
                          featuresDeleted.includes(o.featureNo),
                      ),
                      ...optsSet.map((o) => ({
                        featureNo: o.id,
                        optionKey: o.value,
                      })),
                    ])
                  }}
                />
              )
            })}
          </>
        ) : null}
        <GlobalOptionsModal
          options={selectedOptions}
          presetIdentifier={presetIdentifier}
          onSave={(opts: Partial<NobiliaOption>[]) => {
            setSelectedOptions(opts)
          }}
        />
      </StyledForm>
    </>
  )
}

export default GlobalOptionsMenu

const FeatureOption = ({
  disabled,
  feature,
  featureOptions,
  onChangeOption,
  search,
}: {
  disabled?: boolean
  feature: NodeOption
  featureOptions: DropdownItemProps[]
  onChangeOption: (option: NodeOption[]) => void
  search?: boolean
}) => (
  <FormSelect
    key={feature.id}
    clearable
    search={search}
    disabled={disabled || (featureOptions.length === 0 && !feature.value)}
    fluid
    label={feature.description}
    icon={
      <i style={{ zIndex: 10 }}>
        <FontAwesomeIcon
          icon={['fal', feature.value ? 'times' : 'chevron-down']}
        />
      </i>
    }
    onChange={(
      _: React.ChangeEvent<HTMLInputElement>,
      { value }: { value: string },
    ) => onChangeOption([{ ...feature, value: value }])}
    options={featureOptions}
    value={feature.value}
  />
)

const GlobalOptionsModal = ({
  options,
  onSave,
  presetIdentifier,
}: {
  options: Partial<NobiliaOption>[]
  onSave: (options: Partial<NobiliaOption>[]) => void
  presetIdentifier: string
}) => {
  const {
    nobiliaFeatures,
    essential,
    secondary,
    regularlyUsed,
    rarelyUsed,
    neverUsed,
  } = useNobiliaOptions()
  const [selectedOptions, setSelectedOptions] =
    useState<Partial<NobiliaOption>[]>(options)
  const [showOptionsModal, toggleOptionsModal] = useState<boolean>(false)
  const [search, setSearch] = useState<{
    results: { key: string; title: string }[]
    value: string
  }>({
    results: null,
    value: '',
  })

  useEffect(() => {
    setSelectedOptions(options)
  }, [options])

  const searchElements = nobiliaFeatures.map((f) => ({
    key: f.featureNo,
    title: f.description,
  }))

  const handleResultSelect = (
    _e: React.MouseEvent,
    { result }: { result: { key: string; title: string } },
  ) => setSearch({ results: [result], value: result.title })

  const handleSearchChange = (
    _e: React.MouseEvent,
    { value }: { value: string },
  ) =>
    setSearch({
      results: searchElements.filter((e) =>
        e.title.toLowerCase().includes(value.toLowerCase()),
      ),
      value: value,
    })

  const FormOptions = ({
    noMarginTop,
    features,
    title,
  }: {
    noMarginTop?: boolean
    features: NobiliaFeature[]
    title?: string
  }) => {
    const { globalRestrictionsIndex } = useNobiliaOptions()
    return (
      <>
        {title ? (
          <TitleField nomargin={noMarginTop ? 'true' : null} width={16}>
            <p className="label-gray">{title}</p>
            <Divider />
          </TitleField>
        ) : null}
        {sortBy(features, 'featureNo').map((f) => {
          const no = nobiliaFeatureToNodeOption(
            f,
            selectedOptions.find((o) => o.featureNo === f.featureNo),
            selectedOptions,
            globalRestrictionsIndex,
          )
          return (
            <Form.Field
              key={f.featureNo}
              style={{ flexBasis: '33.3%', padding: '0 14px' }}
            >
              <FeatureOption
                feature={no}
                featureOptions={no.options}
                onChangeOption={(opt: NodeOption[]) => {
                  const featuresAffected = opt.map((o) => o.id)
                  const featuresDeleted = opt
                    .filter((o) => !o.value)
                    .map((o) => o.id)
                  const optsSet = opt.filter((o) => !!o.value)
                  setSelectedOptions([
                    ...selectedOptions.filter(
                      (o) =>
                        !featuresAffected.includes(o.featureNo) &&
                        !featuresDeleted.includes(o.featureNo),
                    ),
                    ...optsSet.map((o) => ({
                      featureNo: o.id,
                      optionKey: o.value,
                    })),
                  ])
                }}
              />
            </Form.Field>
          )
        })}
      </>
    )
  }

  return (
    <Portal
      closeOnTriggerClick
      openOnTriggerClick
      onClose={() => toggleOptionsModal(false)}
      onOpen={() => toggleOptionsModal(true)}
      trigger={
        <div className="flex" style={{ justifyContent: 'center' }}>
          <Button className="white small" content="Show More" />
        </div>
      }
      open={showOptionsModal}
    >
      <StyledContent>
        <StyledPortal
          style={{ height: '-webkit-fill-available', minWidth: '65vw' }}
        >
          <div className="header">
            <p>All options</p>
          </div>
          <div className="content">
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <h1>Choosing for: {presetIdentifier}</h1>
                </Grid.Column>
                <Grid.Column width={5}>
                  <StyledSearch
                    onResultSelect={handleResultSelect}
                    onSearchChange={handleSearchChange}
                    placeholder="Search feature by name"
                    results={search.results}
                    value={search.value}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider
              style={{
                margin: '14px 0px 14px -30px',
                width: 'calc(100% + 60px)',
              }}
            />
            <StyledForm>
              <Form.Group
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {search.results && !!search.value ? (
                  <FormOptions
                    features={nobiliaFeatures.filter((f) =>
                      search.results.map((r) => r.key).includes(f.featureNo),
                    )}
                  />
                ) : (
                  <>
                    <FormOptions
                      noMarginTop={true}
                      features={essential}
                      title="Main Features"
                    />
                    <FormOptions
                      features={secondary}
                      title="Secondary Features"
                    />
                    <FormOptions
                      features={regularlyUsed}
                      title="Tertiary Features"
                    />
                    <FormOptions features={rarelyUsed} title="Extra Features" />
                    <FormOptions
                      features={neverUsed}
                      title="Rarely Used Features"
                    />
                  </>
                )}
              </Form.Group>
            </StyledForm>
          </div>
          <div className="actions">
            <Button
              className="white"
              content="Cancel"
              onClick={() => {
                setSelectedOptions(options)
                toggleOptionsModal(false)
              }}
              style={{ marginRight: '14px' }}
            />
            <Button
              className="blue"
              content="Add"
              onClick={async () => {
                onSave(selectedOptions)
                toggleOptionsModal(false)
              }}
            />
          </div>
        </StyledPortal>
      </StyledContent>
    </Portal>
  )
}

const TitleField = styled(Form.Field)<{ nomargin?: string }>`
  &&& {
    align-items: center;
    display: flex;
    margin: ${(props) =>
      props?.nomargin ? '0 0 20px' : '25px 0 20px'} !important;

    p {
      min-width: fit-content;
      margin: 0 16px 0 0;
      line-height: 0;
    }

    div.divider {
      margin: 10px 0 8px;
      width: -webkit-fill-available;
    }
  }
`
