import { useQuery } from '@apollo/client'

import {
  FIND_UNIQUE_ORDER_LOOKUP,
  FindUniqueOrderPayload,
  FindUniqueOrderVariables,
} from 'queries/order'

// to get ids for contexts
const useOrderLookup = (orderId: string) => {
  const { loading, data } = useQuery<
    FindUniqueOrderPayload,
    FindUniqueOrderVariables
  >(FIND_UNIQUE_ORDER_LOOKUP, {
    skip: !orderId,
    variables: {
      where: { id: orderId },
    },
  })

  return {
    loading,
    order: data?.order,
  }
}

export default useOrderLookup
