import React, { useEffect, useReducer, useState } from 'react'

import { sortBy } from 'lodash'
import moment from 'moment'
import { DateInput } from 'semantic-ui-calendar-react'
import { Divider, Form, Grid, Input, Menu } from 'semantic-ui-react'

import OrderCard from 'components/admin/project/order/card'
import PaymentItemsForm from 'components/admin/project/order/payment-items-form'
import SaveModal from 'components/admin/save-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import HelpMessage from 'components/shared/help-message'
import useAuth from 'context/auth/use'
import { isDesignApproved } from 'context/design/utils'
import { formatDollars } from 'context/dollar-utils'
import { notifyAppError } from 'context/notifications/trigger'
import orderReducer, { OrderReducerState } from 'context/order/reducer'
import useOrder from 'context/order/use'
import useOrderMutate from 'context/order/use-mutate'
import useOrderMutateNoContext from 'context/order/use-mutate-no-context'
import {
  getOrderFormattedAdditional,
  getOrderFormattedName,
  isAddOnOrder,
} from 'context/order/utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import useProject from 'context/project/use'
import {
  getRoomFormattedType,
  getRoomTypeWithDescription,
} from 'context/room/utils'
import Button from 'design-system/components/button'
import CheckHighlighted from 'design-system/components/check-highlighted'
import StatusLabel from 'design-system/components/status-label'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import { Design } from 'types/design'
import { Order, OrderData, OrderType } from 'types/order'
import { PaymentItem, PaymentMethodType, PaymentType } from 'types/payment'
import { Address, StatusMode } from 'types/utils'
import * as viewUtils from 'views/utils'

const stateToDiff = (state: Partial<OrderReducerState>) => {
  return {
    ...state.order,
    designs: sortBy(state.order?.designs?.map((d) => d.id)),
    payments: sortBy(state.order?.payments?.map((d) => d.id)),
  }
}

const OrderTab = ({
  onComplete,
}: {
  onComplete: (createdId?: string) => void
}) => {
  const [menuSelected, setMenuSelected] = useState<'order_info' | 'placement'>(
    'order_info',
  )
  const { isSuperAdmin } = useAuth()
  const {
    project,
    projectDiscounts,
    designsApproved,
    designsAddOn,
    ordersCabinetryPublished,
    paymentsEligibleForOrder,
  } = useProject()
  const { designs, order, payments } = useOrder()
  const { updateOrderAndRefetch, loadingUpdate } = useOrderMutate()
  const { createOrder, loadingCreate } = useOrderMutateNoContext()

  const initialState: OrderReducerState = {
    order: {
      id: order?.id,
      designs: designs || [],
      discounts:
        order?.discounts ||
        (ordersCabinetryPublished.length ? [] : projectDiscounts),
      metadata: {
        ...order?.metadata,
        name: order?.metadata?.name || 'Project Order',
        descriptionAdditional: order?.metadata?.descriptionAdditional || '',
      },
      nobiliaSamples: order?.nobiliaSamples || [],
      placedAt: order?.placedAt || null,
      payments: payments || [],
      shippingAddress: order?.shippingAddress || project.projectAddress,
      taxRate: order?.taxRate || 0,
      type: OrderType.CABINETRY,
    },
  }
  const [state, dispatch] = useReducer(orderReducer, initialState)
  const [confirmInvoice, toggleConfirmInvoice] = useState<boolean>(false)
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false)
  const [designOptions, setDesignOptions] = useState<Design[]>(designsApproved)

  // update state when payments created on backend
  useEffect(() => {
    if (
      order &&
      (order.payments?.length ?? 0) > (state.order.payments?.length ?? 0)
    ) {
      dispatch({
        type: 'set_payments',
        payments: order.payments ?? [],
      })
    }
  }, [order?.payments])

  useEffect(() => {
    dispatch({
      type: 'reset_state',
      state: initialState,
    })
  }, [order])

  useEffect(() => {
    setDesignOptions(isAddOnOrder(state.order) ? designsAddOn : designsApproved)
  }, [isAddOnOrder(state.order)])

  const onSave = async () => {
    if (!project.projectAddress) {
      notifyAppError(
        'Project address is missing. Please request from the customer and enter, or release the "Set Up Project" card.',
      )
      return
    }
    let createdId = ''
    if (order) {
      await updateOrderAndRefetch({
        variables: {
          data: {
            designs: {
              set: state.order.designs?.map((d) => ({
                id: d.id,
              })),
            },
            discounts: state.order.discounts as PaymentItem[],
            metadata: state.order.metadata as OrderData,
            placedAt: state.order.placedAt,
            payments: {
              set: viewUtils.mapOrEmptyArray(
                state.order.payments ?? [],
                (p) => ({
                  id: p.id,
                }),
              ),
            },
            shippingAddress: state.order.shippingAddress as Address,
            taxRate: state.order.taxRate as number,
          },
          where: { id: order.id as string },
        },
      })
    } else {
      const res = await createOrder({
        variables: {
          data: {
            discounts: state.order.discounts as PaymentItem[],
            designs: {
              connect: viewUtils.mapOrEmptyArray(
                state.order.designs ?? [],
                (d) => ({
                  id: d.id,
                }),
              ),
            },
            metadata: state.order.metadata as OrderData,
            payments: {
              connect: viewUtils.mapOrEmptyArray(
                state.order.payments ?? [],
                (p) => ({
                  id: p.id as string,
                }),
              ) as { id: string }[],
            },
            placedAt: state.order.placedAt as string,
            project: { connect: { id: project.id } },
            shippingAddress: state.order.shippingAddress as Address,
            taxRate: state.order.taxRate as number,
            type: state.order.type as OrderType,
          },
        },
      })
      createdId = res.data?.createOneOrder.id ?? ''
    }
    onComplete(createdId)
  }

  const amountDue = checkoutUtils.calculateAmountDue(
    state.order as Partial<Order>,
  )

  return (
    <>
      <Menu pointing secondary>
        <Menu.Item
          name="Order Information"
          active={menuSelected === 'order_info'}
          onClick={() => setMenuSelected('order_info')}
        />
        <Menu.Item
          name="Placement"
          active={menuSelected === 'placement'}
          onClick={() => setMenuSelected('placement')}
        />
      </Menu>
      {menuSelected === 'order_info' ? (
        <Grid padded>
          <Grid.Row columns="equal" verticalAlign="middle">
            <Grid.Column width={5}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '20px',
                }}
              >
                {order && (
                  <Button
                    color={order.metadata?.publishedAt ? 'blue' : 'dark'}
                    kind="solid"
                    text={
                      order.metadata?.publishedAt
                        ? 'Unpublish'
                        : isAddOnOrder(order)
                        ? 'Publish Order and Designs'
                        : 'Publish Order'
                    }
                    onClick={async () => {
                      await updateOrderAndRefetch({
                        variables: {
                          where: { id: order.id as string },
                          data: {
                            metadata: {
                              ...order.metadata,
                              publishedAt: order.metadata?.publishedAt
                                ? null
                                : new Date(),
                            },
                          },
                        },
                      })
                    }}
                  />
                )}
                {order?.metadata?.publishedAt ? (
                  <StatusLabel
                    type={StatusMode.Confirmed}
                    text={`Published on ${moment(
                      order.metadata.publishedAt,
                    ).format('MMM DD YYYY')}`}
                  />
                ) : null}
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  float: 'right',
                  gap: '25px',
                  justifyContent: 'space-between',
                }}
              >
                {!!state.order.metadata?.isAddOn && (
                  <HelpMessage message="Add-On orders will be published with the designs chosen below" />
                )}
                <CheckHighlighted
                  checked={!!state.order.metadata?.isAddOn}
                  color="red"
                  text="This is an add on"
                  onChange={() =>
                    dispatch({
                      type: 'set_is_add_on',
                      isAddOn: !state.order.metadata?.isAddOn,
                    })
                  }
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <p className="subtitle underlined">Order Information</p>
              <StyledForm>
                <Form.Group widths="equal">
                  <Form.Input
                    disabled
                    name="name"
                    type="text"
                    label="Name"
                    value={getOrderFormattedName(state.order as Order)}
                  />
                  <Form.Input
                    name="additional"
                    type="text"
                    label="Additional description"
                    placeholder="Additional description"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: 'set_description_additional',
                        descriptionAdditional: e.target.value,
                      })
                    }}
                    value={getOrderFormattedAdditional(state.order as Order)}
                  />
                </Form.Group>
                <Form.Dropdown
                  label={
                    isAddOnOrder(state.order)
                      ? 'Designs (Choose from Add-On Designs)'
                      : 'Designs (Choose from approved design revisions)'
                  }
                  fluid
                  multiple
                  options={designOptions
                    .filter((d) => {
                      if (!d.order?.id || d.order?.id === order?.id) return true
                      return false
                    })
                    .map((d) => ({
                      key: d.id,
                      text: `${getRoomFormattedType(d.room)} | ${d.name}`,
                      value: d.id,
                    }))}
                  onChange={(_, { value }) =>
                    dispatch({
                      type: 'set_designs',
                      designs: designOptions.filter(
                        (d) => (value as string[])?.includes(d.id),
                      ),
                    })
                  }
                  placeholder="Designs"
                  search
                  selection
                  value={state.order.designs?.map((d) => d.id)}
                />
                <Form.Dropdown
                  fluid
                  multiple
                  label="Deposits (Apply all the deposits)"
                  options={paymentsEligibleForOrder
                    .filter((p) => {
                      if (!p.order?.id || p.order?.id === order?.id) return true
                      return false
                    })
                    .map((p) => ({
                      key: p.id,
                      text: `${viewUtils.capsSnakeCaseToTitleCase(
                        p.metadata.type,
                      )} - ${formatDollars(p.amount)}`,
                      description: p.room
                        ? getRoomTypeWithDescription(p.room)
                        : null,
                      value: p.id,
                    }))}
                  onChange={(_, { value }) =>
                    dispatch({
                      type: 'set_payments',
                      payments: paymentsEligibleForOrder.filter((p) =>
                        (value as string[]).includes(p.id as string),
                      ),
                    })
                  }
                  placeholder="Deposits"
                  search
                  selection
                  value={state.order.payments?.map((d) => d.id as string)}
                />
              </StyledForm>
              <Divider hidden />
              <p className="subtitle underlined">Taxes</p>
              <StyledForm>
                <Form.Field>
                  Tax Rate (Calculated automatically on save)
                  <Input
                    disabled
                    label={{ basic: true, content: '%' }}
                    labelPosition="right"
                    name="taxRate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: 'set_tax_rate',
                        taxRate: parseFloat(e.target.value),
                      })
                    }}
                    type="number"
                    value={state.order.taxRate}
                  />
                </Form.Field>
                <FormCheckbox
                  checked={!!state.order.metadata?.taxExempt}
                  disabled={!isSuperAdmin}
                  label="Tax Exempt (must be set by admin on creation)"
                  onChange={() => {
                    dispatch({
                      type: 'set_tax_exempt',
                      isTaxExempt: !state.order.metadata?.taxExempt,
                    })
                  }}
                />
              </StyledForm>
              <Divider hidden />
              <p className="subtitle underlined">Discounts</p>
              <PaymentItemsForm
                items={state.order.discounts ?? []}
                setItems={(ds: PaymentItem[]) =>
                  dispatch({
                    type: 'set_discounts',
                    discounts: ds,
                  })
                }
              />
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <p className="subtitle">Checkout</p>
              <OrderCard order={state.order} />
              <Grid padded>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    {isAddOnOrder(state.order) && (
                      <FormCheckbox
                        checked={!!state.order.placedAt}
                        disabled={
                          !!order?.placedAt ||
                          amountDue !== 0 ||
                          !designs?.every((d) => isDesignApproved(d))
                        }
                        label="Place Order"
                        onChange={() => {
                          dispatch({
                            type: 'set_placed_at',
                            placedAt: !state.order.placedAt
                              ? moment().toDate()
                              : null,
                          })
                        }}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <Button
                      color="blue"
                      disabled={!order || !amountDue}
                      fontAwesomeIcon="file-invoice-dollar"
                      kind="text"
                      loading={loadingInvoice}
                      onClick={() => toggleConfirmInvoice(true)}
                      text="Create Invoice"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <ConfirmationModal
                firstTitle="Are you sure you want to create this invoice?"
                onCancel={() => toggleConfirmInvoice(false)}
                onConfirm={async () => {
                  toggleConfirmInvoice(false)
                  setLoadingInvoice(true)
                  await updateOrderAndRefetch({
                    variables: {
                      data: {
                        payments: {
                          create: [
                            {
                              amount: amountDue,
                              description: '',
                              metadata: {
                                type: PaymentType.ORDER_PAYMENT,
                                name: 'Balance Payment',
                                method: PaymentMethodType.INVOICE,
                              },
                              project: { connect: { id: project.id } },
                              status: 'pending',
                            },
                          ],
                        },
                      },
                      where: { id: order.id as string },
                    },
                  })
                  setLoadingInvoice(false)
                }}
                open={confirmInvoice}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : null}
      {menuSelected === 'placement' ? (
        <Grid padded>
          <Grid.Row>
            <Grid.Column width={7}>
              <p className="subtitle underlined">
                Placement and Refund Information
              </p>
              <Form>
                <Form.Field>
                  Placed At
                  <DateInput
                    closable
                    dateFormat={'MM/DD/YYYY'}
                    duration={0}
                    initialDate={moment().format('MM/DD/YYYY')}
                    minDate={moment().subtract(2, 'years').format('MM/DD/YYYY')}
                    name="date"
                    onChange={(
                      _: React.SyntheticEvent<HTMLElement, Event>,
                      { value }: { value: string },
                    ) => {
                      dispatch({
                        type: 'set_placed_at',
                        placedAt: moment(value, 'MM/DD/YYYY').isValid()
                          ? moment(value, 'MM/DD/YYYY').toDate()
                          : '',
                      })
                    }}
                    value={
                      state.order.placedAt
                        ? moment(state.order.placedAt).format('MM/DD/YYYY')
                        : ''
                    }
                  />
                </Form.Field>
              </Form>
              <Divider hidden />
              <HelpMessage message="Do not touch unless customer pays outside the system" />
              <Divider hidden />
              <p className="subtitle underlined">Refund</p>
              <FormCheckbox
                disabled={!state.order.payments?.some((p) => p.refundAmount)}
                checked={!!state.order?.metadata?.avataxRefundOn}
                label="Send Refund To Avatax"
                onChange={() => {
                  dispatch({
                    type: 'toggle_refund',
                  })
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : null}
      <SaveModal
        currentState={stateToDiff(state)}
        initialState={stateToDiff(initialState)}
        loadingSave={loadingUpdate || loadingCreate}
        onDiscard={onComplete}
        onSave={onSave}
      />
    </>
  )
}

export default OrderTab
