import React, { useState } from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Accordion, Divider } from 'semantic-ui-react'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox } from 'styles/app/components/checkbox'
import { Colors } from 'styles/app/system'
import { CatalogDiff } from 'types/catalog-diff'

import CatalogFeatureDiffComponent from './catalog-feature-diff'
import GenericOptionDiffComponent from './generic-option-diff'
import GenericProductClassDiffComponent from './generic-product-class-diff'
import NobiliaOptionDiffComponent from './nobilia-option-diff'
import NobiliaProductClassDiffComponent from './nobilia-product-class-diff'
import RoomElementClassDiffComponent from './room-element-class-diff'
import RoomElementOptionDiffComponent from './room-element-option-diff'
import { parseDiffs, StyledLabelDiffs } from './utils'

export const PublishModal = ({
  catalogDiff,
  onCommit,
  onCommitLoading,
  icon,
  title,
  trigger,
}: {
  catalogDiff: CatalogDiff
  onCommit: () => Promise<void>
  onCommitLoading: boolean
  icon: string
  title: string
  trigger: JSX.Element
}) => {
  const [showCatalogFeatures, toggleCatalogFeatures] = useState<boolean>(false)
  const [isSure, setSure] = useState<boolean>(false)
  const [showGenericOptions, toggleGenericOptions] = useState<boolean>(false)
  const [showGenericProductClasses, toggleGenericProductClasses] =
    useState<boolean>(false)
  const [showNobiliaOptions, toggleNobiliaOptions] = useState<boolean>(false)
  const [showNobiliaProductClasses, toggleNobiliaProductClasses] =
    useState<boolean>(false)
  const [showRoomElementOptions, toggleRoomElementOptions] =
    useState<boolean>(false)
  const [showRoomElementClasses, toggleRoomElementClasses] =
    useState<boolean>(false)
  const [showModal, toggleModal] = useState<boolean>(false)
  const onClose = () => {
    toggleModal(false)
  }
  const diffsParsed = parseDiffs(catalogDiff)

  const totalUpdated =
    catalogDiff?.catalogFeatures?.length +
    catalogDiff?.genericOptions?.length +
    catalogDiff?.genericProductClasses?.length +
    catalogDiff?.nobiliaOptions?.length +
    catalogDiff?.nobiliaProductClasses?.length +
    catalogDiff?.roomElementOptions?.length +
    catalogDiff?.roomElementClasses?.length

  return (
    <Modal
      title={title}
      onClose={onClose}
      onOpen={() => toggleModal(true)}
      trigger={trigger}
      open={showModal}
      size="large"
      customActions={
        <CustomActions>
          <h6 className="no-margin">Viewing {totalUpdated} changes</h6>
          <FormCheckbox
            checked={isSure}
            label={`I'm sure I want to ${title}`}
            onChange={() => setSure(!isSure)}
          />
          <div>
            <Button
              kind="outlined"
              color="blue"
              text="Cancel"
              onClick={onClose}
            />
            <Button
              kind="solid"
              text={title}
              disabled={!isSure}
              fontAwesomeIcon={icon as IconName}
              loading={onCommitLoading}
              onClick={onCommit}
            />
          </div>
        </CustomActions>
      }
    >
      <h4>
        Last Update:{' '}
        {moment(catalogDiff?._metadata?.timestamp).format('MMM Do YYYY')} by{' '}
        {catalogDiff?._metadata?.author}
      </h4>
      <PublishAccordion fluid>
        <Accordion.Title
          onClick={() => toggleCatalogFeatures(!showCatalogFeatures)}
        >
          <h6>Catalog Features</h6>
          <StyledLabelDiffs
            updated={diffsParsed.catalogFeaturesUpdates.length}
            added={diffsParsed.catalogFeaturesAdds.length}
            deleted={diffsParsed.catalogFeaturesDeletes.length}
          />
          <FontAwesomeIcon
            icon={['fal', showCatalogFeatures ? 'chevron-up' : 'chevron-down']}
          />
        </Accordion.Title>
        <Accordion.Content active className="accordion-content">
          {showCatalogFeatures
            ? catalogDiff.catalogFeatures.map((cf) => (
                <CatalogFeatureDiffComponent catalogFeatureDiff={cf} />
              ))
            : null}
        </Accordion.Content>
        <Divider />
        <Accordion.Title
          onClick={() =>
            toggleNobiliaProductClasses(!showNobiliaProductClasses)
          }
        >
          <h6>Nobilia Product Classes</h6>
          <StyledLabelDiffs
            updated={diffsParsed.nobiliaProductClassesUpdates.length}
            added={diffsParsed.nobiliaProductClassesAdds.length}
            deleted={diffsParsed.nobiliaProductClassesDeletes.length}
          />
          <FontAwesomeIcon
            icon={[
              'fal',
              showNobiliaProductClasses ? 'chevron-up' : 'chevron-down',
            ]}
          />
        </Accordion.Title>
        <Accordion.Content active className="accordion-content">
          {showNobiliaProductClasses
            ? catalogDiff.nobiliaProductClasses.map((cf) => (
                <NobiliaProductClassDiffComponent
                  nobiliaProductClassDiff={cf}
                />
              ))
            : null}
        </Accordion.Content>
        <Divider />
        <Accordion.Title
          onClick={() => toggleNobiliaOptions(!showNobiliaOptions)}
        >
          <h6>Nobilia Options</h6>
          <StyledLabelDiffs
            updated={diffsParsed.nobiliaOptionsUpdates.length}
            added={diffsParsed.nobiliaOptionsAdds.length}
            deleted={diffsParsed.nobiliaOptionsDeletes.length}
          />
          <FontAwesomeIcon
            icon={['fal', showNobiliaOptions ? 'chevron-up' : 'chevron-down']}
          />
        </Accordion.Title>
        <Accordion.Content active className="accordion-content">
          {showNobiliaOptions
            ? catalogDiff.nobiliaOptions.map((cf) => (
                <NobiliaOptionDiffComponent nobiliaOptionDiff={cf} />
              ))
            : null}
        </Accordion.Content>
        <Divider />
        <Accordion.Title
          onClick={() =>
            toggleGenericProductClasses(!showGenericProductClasses)
          }
        >
          <h6>Generic Product Class</h6>
          <StyledLabelDiffs
            updated={diffsParsed.genericProductClassesUpdates.length}
            added={diffsParsed.genericProductClassesAdds.length}
            deleted={diffsParsed.genericProductClassesDeletes.length}
          />
          <FontAwesomeIcon
            icon={[
              'fal',
              showGenericProductClasses ? 'chevron-up' : 'chevron-down',
            ]}
          />
        </Accordion.Title>
        <Accordion.Content active className="accordion-content">
          {showGenericProductClasses
            ? catalogDiff.genericProductClasses.map((cf) => (
                <GenericProductClassDiffComponent
                  genericProductClassDiff={cf}
                />
              ))
            : null}
        </Accordion.Content>
        <Divider />
        <Accordion.Title
          onClick={() => toggleGenericOptions(!showGenericOptions)}
        >
          <h6>Generic Options</h6>
          <StyledLabelDiffs
            updated={diffsParsed.genericOptionsUpdates.length}
            added={diffsParsed.genericOptionsAdds.length}
            deleted={diffsParsed.genericOptionsDeletes.length}
          />
          <FontAwesomeIcon
            icon={['fal', showGenericOptions ? 'chevron-up' : 'chevron-down']}
          />
        </Accordion.Title>
        <Accordion.Content active className="accordion-content">
          {showGenericOptions
            ? catalogDiff.genericOptions.map((go) => (
                <GenericOptionDiffComponent genericOptionDiff={go} />
              ))
            : null}
        </Accordion.Content>
        <Divider />
        <Accordion.Title
          onClick={() => toggleRoomElementClasses(!showRoomElementClasses)}
        >
          <h6>Room Element Classes</h6>
          <StyledLabelDiffs
            updated={diffsParsed.roomElementClassesUpdates.length}
            added={diffsParsed.roomElementClassesAdds.length}
            deleted={diffsParsed.roomElementClassesDeletes.length}
          />
          <FontAwesomeIcon
            icon={[
              'fal',
              showRoomElementClasses ? 'chevron-up' : 'chevron-down',
            ]}
          />
        </Accordion.Title>
        <Accordion.Content active className="accordion-content">
          {showRoomElementClasses
            ? catalogDiff.roomElementClasses.map((rec) => (
                <RoomElementClassDiffComponent roomElementClassDiff={rec} />
              ))
            : null}
        </Accordion.Content>
        <Divider />
        <Accordion.Title
          onClick={() => toggleRoomElementOptions(!showRoomElementOptions)}
        >
          <h6>Room Element Options</h6>
          <StyledLabelDiffs
            updated={diffsParsed.roomElementOptionsUpdates.length}
            added={diffsParsed.roomElementOptionsAdds.length}
            deleted={diffsParsed.roomElementOptionsDeletes.length}
          />
          <FontAwesomeIcon
            icon={[
              'fal',
              showRoomElementOptions ? 'chevron-up' : 'chevron-down',
            ]}
          />
        </Accordion.Title>
        <Accordion.Content active className="accordion-content">
          {showRoomElementOptions
            ? catalogDiff.roomElementOptions.map((rec) => (
                <RoomElementOptionDiffComponent roomElementOptionDiff={rec} />
              ))
            : null}
        </Accordion.Content>
      </PublishAccordion>
    </Modal>
  )
}

export default PublishModal

const PublishAccordion = styled(Accordion)`
  &&&&& {
    .title {
      align-items: center;
      display: flex !important;
      justify-content: space-between;

      svg {
        color: ${Colors.orange500};
        font-size: 11px;
        margin-right: 9px;
      }

      div.tags {
        display: inline-flex;
        gap: 8px;
        margin-left: auto;
        margin-right: 20px;
      }
    }

    .content {
      padding: 0 !important;
    }
  }
`

const CustomActions = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  div {
    display: flex;
    gap: 16px;
  }
`
