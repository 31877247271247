import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useTechDesigner from 'context/dashboard/tech-designer/use'
import useCommentNoteCount from 'context/project/project-notes/use-count'
import { ScrollBarStyle } from 'styles/app/system'

const MenuTechDesigner = () => {
  const { pathname } = useLocation()
  const {
    designsAwaitingFeedbackCount,
    designsApprovedUnsentCount,
    roomsAwaitingPackCount,
    sosEDINotSentCount,
    sosAwaitingFORMCount,
    sosAwaitingNobiliaCount,
    projects,
    tasksToDo,
    tasksUrgent,
    userId,
  } = useTechDesigner()

  const { count: newForNotesCount } = useCommentNoteCount({
    newFor: { id: userId },
  })

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="overview"
        active={pathname === `/admin/dashboard/${userId}/tech-designer/`}
        onClick={() => navigate(`/admin/dashboard/${userId}/tech-designer`)}
      />
      <Menu.Item
        name="projects"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/projects`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer/projects`)
        }
      >
        Projects
        <Label className="count">{projects.length}</Label>
      </Menu.Item>
      <Menu.Item
        name="notes"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/notes`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer/notes`)
        }
      >
        Notes
        {!!newForNotesCount && (
          <Label className="count">{newForNotesCount}</Label>
        )}
      </Menu.Item>
      <Menu.Item
        name="tasks"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/tasks`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer/tasks`)
        }
      >
        Tasks
        {tasksToDo.length || tasksUrgent.length ? (
          <Label className="count">
            {tasksToDo.length + tasksUrgent.length}
          </Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="designsAwaitingFeedback"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/designs-awaiting-feedback`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer/designs-awaiting-feedback`,
          )
        }
      >
        Design Review
        {designsAwaitingFeedbackCount ? (
          <Label className="count">{designsAwaitingFeedbackCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="roomsAwaitingPack"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/rooms-awaiting-pack`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer/rooms-awaiting-pack`,
          )
        }
      >
        Packs
        {roomsAwaitingPackCount ? (
          <Label className="count">{roomsAwaitingPackCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="designsApprovedUnsent"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/designs-approved-unsent`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer/designs-approved-unsent`,
          )
        }
      >
        Designs Approved
        {designsApprovedUnsentCount ? (
          <Label className="count">{designsApprovedUnsentCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="supplierOrdersEDINotSent"
        active={
          pathname ===
          `/admin/dashboard/${userId}/tech-designer/supplier-orders-edi-not-sent/`
        }
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer/supplier-orders-edi-not-sent`,
          )
        }
      >
        S.O. EDI Not Sent
        {sosEDINotSentCount ? (
          <Label className="count">{sosEDINotSentCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="supplierOrdersNobilia"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/supplier-orders-awaiting-nobilia`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer/supplier-orders-awaiting-nobilia`,
          )
        }
      >
        S.O. Nobilia
        {sosAwaitingNobiliaCount ? (
          <Label className="count">{sosAwaitingNobiliaCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="supplierOrdersFORM"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer/supplier-orders-awaiting-form`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer/supplier-orders-awaiting-form`,
          )
        }
      >
        S.O. FORM
        {sosAwaitingFORMCount ? (
          <Label className="count">{sosAwaitingFORMCount}</Label>
        ) : null}
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuTechDesigner

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
