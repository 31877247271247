import React, { useCallback, useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { debounce, isEqual } from 'lodash'
import moment from 'moment'
import { DateInput } from 'semantic-ui-calendar-react'
import {
  DropdownProps,
  Form,
  Grid,
  Image,
  Input,
  InputOnChangeData,
  Label,
  List,
  Tab,
} from 'semantic-ui-react'
import styled from 'styled-components'

import AdminLayout from 'components/admin/layout'
import Projects from 'components/admin/project/index-many'
import SectionTitle from 'components/admin/section-title'
import { UploadCompanyFileCard } from 'components/shared/company/upload-file-card'
import CardFile from 'components/shared/project/project-file/card'
import { UnderlineSubtitle } from 'components/shared/utils'
import CompanyProvider from 'context/company/provider'
import useCompany from 'context/company/use'
import useCompanyMutate from 'context/company/use-mutate'
import ProjectManyProvider from 'context/project/provider-many'
import useProjectFileMutateNoContext from 'context/project-file/use-mutate-no-context'
import { parseFiles } from 'context/project-file/utils-many'
import { getNameForProspect } from 'context/prospect/utils'
import useUserMany from 'context/user/use-many-lazy'
import Button from 'design-system/components/button'
import SaveToast from 'design-system/components/save-toast'
import { StyledForm, StyledRadio } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import {
  Company,
  ExemptionDocumentationType,
  IndustryType,
  ProfessionalDesignAssociationTypes,
} from 'types/company'
import { ProjectFileType } from 'types/project-file'
import { TRADE_CLIENTS } from 'types/user'
import { Address } from 'types/utils'
import * as viewUtils from 'views/utils'

const Module = () => {
  const { company, refetch } = useCompany()
  const {
    createCompanyAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateCompanyAndRefetch,
  } = useCompanyMutate()
  const { deleteProjectFile, loadingDelete } = useProjectFileMutateNoContext()

  const initialState: Partial<Company> = company || {
    data: {
      exemptionExpirationDate: '',
      professionalDesignAssociation: '' as ProfessionalDesignAssociationTypes,
      membershipNumber: '',
      exemptionDocumentationType: '' as ExemptionDocumentationType,
      exemptionTaxId: '',
    },
    domain: '',
    name: '',
  }
  const initialAddress: Address = company?.address || {
    addressCountry: '',
    addressLocality: '',
    addressRegion: '',
    apartmentNumber: '',
    postalCode: '',
    postOfficeBoxNumber: '',
    streetAddress: '',
  }

  const { businessLicenses, taxExemptionCertificates } = parseFiles(
    company?.files ?? [],
  )

  const [inputError, setInputError] = useState<boolean>(false)
  const [state, setState] = useState<Partial<Company>>(initialState)
  const [address, setAddress] = useState<Address>(initialAddress)

  const [search, setSearch] = useState('')
  const [newUser, setNewUser] = useState<string>('')
  const { fetchUsers, userOptions, users, resetUsers } = useUserMany()

  useEffect(() => {
    if (search)
      fetchUsers({
        variables: {
          where: {
            type: { in: TRADE_CLIENTS },
            derivedSearch: {
              contains: search,
              mode: 'insensitive',
            },
            companyId: {
              equals: undefined,
            },
          },
        },
        fetchPolicy: 'no-cache',
      })
  }, [search])

  const onSearchChange = async (_: unknown, data: { searchQuery: string }) => {
    setSearch(data?.searchQuery)
  }

  const debouncedOnSearchChange = useCallback(debounce(onSearchChange, 300), [])

  useEffect(() => {
    setState(initialState)
  }, [company])

  const onChangeStateData = <T extends string>(name: string, value: T) =>
    setState({
      ...state,
      data: {
        ...state?.data,
        [name]: value,
      },
    })

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: value })

  const onChangeData = (
    _: unknown,
    { value, name }: InputOnChangeData | DropdownProps,
  ) => {
    if (name === 'EINNumber') {
      if (value && !viewUtils.validateEINNumber(value as string))
        setInputError(true)
      else {
        setInputError(false)
      }
    }
    onChangeStateData(name, value as string)
  }

  const onSave = async () => {
    const identifier = state?.domain || state?.name
    if (company?.id) {
      await updateCompanyAndRefetch({
        variables: {
          data: {
            address,
            data: {
              ...state?.data,
            },
            identifier,
            name: state?.name ?? '',
            domain: state?.domain ?? '',
            users: {
              set: state?.users?.map((u) => ({ id: u.id })),
            },
          },
          where: {
            id: company.id,
          },
        },
      })
    } else {
      await createCompanyAndRefetch({
        variables: {
          data: {
            address,
            data: {
              ...state?.data,
            },
            identifier: identifier ?? '',
            name: state?.name ?? '',
            domain: state?.domain ?? '',
          },
        },
      }).then(({ data }) => {
        navigate(`/admin/companies/${data?.createOneCompany?.id}`)
      })
    }
  }

  return (
    <AdminLayout>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Button
              fontAwesomeIcon="arrow-left"
              iconPosition="left"
              color="gray"
              kind="text"
              text="Go back to Companies"
              onClick={() => navigate('/admin/companies')}
            />
          </Grid.Column>
        </Grid.Row>
        <SectionTitle title="Company Information" />
        <Grid.Row>
          <Grid.Column>
            <StyledTab
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: 'Main Information',
                  render: () => (
                    <Tab.Pane>
                      <div style={{ maxWidth: '60%' }}>
                        <StyledForm styled={{ grayInputs: true }}>
                          <Form.Group widths="equal">
                            <Form.Input
                              label="Name"
                              name="name"
                              onChange={onChangeInput}
                              placeholder="Name"
                              required
                              value={state?.name}
                            />
                            <LabeledField>
                              <label>Domain</label>
                              <Input
                                name="domain"
                                onChange={onChangeInput}
                                label="@"
                                required
                                placeholder="Domain"
                                value={state?.domain}
                              />
                            </LabeledField>
                          </Form.Group>
                          <Form.Group
                            inline
                            style={{ alignItems: 'baseline', padding: '0 7px' }}
                          >
                            <label>
                              <p className="small">
                                Size <span className="red">*</span>
                              </p>
                            </label>
                            <StyledRadio
                              checked={state?.data?.size === '1-10'}
                              label="1-10"
                              name="size"
                              onChange={() => onChangeStateData('size', '1-10')}
                              value="1-10"
                            />
                            <StyledRadio
                              checked={state?.data?.size === '10-30'}
                              label="10-30"
                              name="size"
                              onChange={() =>
                                onChangeStateData('size', '10-30')
                              }
                              value="10-30"
                            />
                            <StyledRadio
                              checked={state?.data?.size === '30-70'}
                              label="30-70"
                              name="size"
                              onChange={() =>
                                onChangeStateData('size', '30-70')
                              }
                              value="30-70"
                            />
                            <StyledRadio
                              checked={state?.data?.size === '70+'}
                              label="70+"
                              name="size"
                              onChange={() => onChangeStateData('size', '70+')}
                              value="70+"
                            />
                          </Form.Group>
                          <Form.Group widths="equal">
                            <Form.Dropdown
                              label="Industry"
                              onChange={(_, { value }) =>
                                onChangeStateData('industry', value as string)
                              }
                              options={viewUtils.getDropdownOptionsFromEnum(
                                IndustryType,
                                true,
                              )}
                              placeholder="Industry"
                              required
                              selection
                              value={state?.data?.industry}
                            />
                            <Form.Input
                              disabled={
                                state?.data?.industry !== IndustryType.OTHER
                              }
                              label="Other"
                              placeholder="Other Industry"
                              name="otherIndustry"
                              onChange={onChangeData}
                              value={state?.data?.otherIndustry}
                            />
                          </Form.Group>
                          <LabeledField>
                            <label>Website</label>
                            <Input
                              name="website"
                              onChange={onChangeData}
                              label="https://"
                              placeholder="mysite.com"
                              value={state?.data?.website}
                            />
                          </LabeledField>
                          <LabeledField>
                            <label>Instagram handle</label>
                            <Input
                              name="instagram"
                              onChange={onChangeData}
                              label="@"
                              value={state?.data?.instagram}
                            />
                          </LabeledField>
                        </StyledForm>
                      </div>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: {
                    content: 'Company Documentation',
                    disabled: !company,
                  },
                  render: () => (
                    <Tab.Pane>
                      <div style={{ maxWidth: '60%' }}>
                        <UnderlineSubtitle>Business Details</UnderlineSubtitle>
                        <StyledForm styled={{ grayInputs: true }}>
                          <Form.Group widths={2}>
                            <LabeledField width={8}>
                              <label>EIN Number</label>
                              <Input
                                name="EINNumber"
                                onBlur={() => {
                                  if (
                                    state?.data?.EINNumber &&
                                    !viewUtils.validateEINNumber(
                                      state?.data?.EINNumber,
                                    )
                                  )
                                    setInputError(true)
                                  else {
                                    setInputError(false)
                                  }
                                }}
                                onChange={onChangeData}
                                value={state?.data?.EINNumber}
                              />
                              {inputError && (
                                <Label pointing prompt>
                                  <p className="red small">
                                    Please enter a valid EIN number, e.g.
                                    12-3456789
                                  </p>
                                </Label>
                              )}
                            </LabeledField>
                          </Form.Group>
                          <Form.Group widths="equal">
                            <Form.Dropdown
                              label="Professional Design Association"
                              name="professionalDesignAssociation"
                              onChange={onChangeData}
                              options={viewUtils.getDropdownOptionsFromEnum(
                                ProfessionalDesignAssociationTypes,
                                true,
                              )}
                              selection
                              upward
                              value={state?.data?.professionalDesignAssociation}
                            />
                            <Form.Input
                              disabled={
                                !state?.data?.professionalDesignAssociation
                              }
                              label="Membership Number"
                              name="membershipNumber"
                              onChange={onChangeData}
                              placeholder="Membership Number"
                              value={state?.data?.membershipNumber}
                            />
                          </Form.Group>
                          <p className="gray">Business License</p>
                          <Form.Field width={5}>
                            {businessLicenses?.length ? (
                              <CardFile
                                file={businessLicenses[0]}
                                onFileRemoved={async (f) => {
                                  await deleteProjectFile({
                                    variables: {
                                      where: {
                                        id: f.id as string,
                                      },
                                    },
                                  })
                                  refetch()
                                }}
                                showDeleteBtn
                              />
                            ) : (
                              <UploadCompanyFileCard
                                fileType={ProjectFileType.BUSINESS_LICENSE}
                                companyId={state?.id}
                                onFilesUploaded={() => refetch()}
                              />
                            )}
                          </Form.Field>
                        </StyledForm>
                        <UnderlineSubtitle>
                          Tax Exemption Information
                        </UnderlineSubtitle>
                        <StyledForm styled={{ grayInputs: true }}>
                          <Form.Group widths="equal">
                            <Form.Dropdown
                              label="Documentation Type"
                              name="exemptionDocumentationType"
                              onChange={onChangeData}
                              options={viewUtils.getDropdownOptionsFromEnum(
                                ExemptionDocumentationType,
                                true,
                              )}
                              selection
                              upward
                              value={state?.data?.exemptionDocumentationType}
                            />
                            <Form.Input
                              label="Tax ID #"
                              name="exemptionTaxId"
                              onChange={onChangeData}
                              placeholder="Tax ID Number"
                              value={state?.data?.exemptionTaxId}
                            />
                          </Form.Group>
                          <Form.Group widths={2}>
                            <Form.Field>
                              <label>Expiration Date</label>
                              <DateInput
                                closable
                                dateFormat={'MM/DD/YYYY'}
                                duration={0}
                                initialDate={moment().format('MM/DD/YYYY')}
                                name="exemptionExpirationDate"
                                onChange={onChangeData}
                                value={
                                  state?.data?.exemptionExpirationDate ?? ''
                                }
                              />
                            </Form.Field>
                          </Form.Group>
                          <p className="gray">Tax Exempt documentation</p>
                          <Form.Field width={5}>
                            {taxExemptionCertificates.length ? (
                              <CardFile
                                file={taxExemptionCertificates[0]}
                                onFileRemoved={async (f) => {
                                  await deleteProjectFile({
                                    variables: {
                                      where: {
                                        id: f.id as string,
                                      },
                                    },
                                  })
                                  refetch()
                                }}
                                showDeleteBtn
                              />
                            ) : (
                              <UploadCompanyFileCard
                                fileType={
                                  ProjectFileType.TAX_EXEMPTION_CERTIFICATE
                                }
                                onFilesUploaded={() => refetch()}
                                companyId={state?.id}
                              />
                            )}
                          </Form.Field>
                        </StyledForm>
                      </div>
                    </Tab.Pane>
                  ),
                },
                {
                  key: 'address',
                  menuItem: {
                    content: 'Address',
                    disabled: !company,
                  },
                  render: () => (
                    <Tab.Pane>
                      <div style={{ maxWidth: '60%' }}>
                        <StyledForm styled={{ grayInputs: true }}>
                          <Form.Input
                            label="Street Address"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setAddress({
                                ...address,
                                streetAddress: e.target.value,
                              })
                            }}
                            placeholder="Street Address"
                            required
                            value={address.streetAddress}
                          />
                          <Form.Input
                            label="Apt / Floor Number"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setAddress({
                                ...address,
                                apartmentNumber: e.target.value,
                              })
                            }}
                            placeholder="Apt / Floor Number"
                            value={address.apartmentNumber}
                          />
                          <Form.Input
                            label="City"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setAddress({
                                ...address,
                                addressLocality: e.target.value,
                              })
                            }}
                            placeholder="City"
                            required
                            value={address.addressLocality}
                          />
                          <Form.Group widths="equal">
                            <Form.Input
                              label="State"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setAddress({
                                  ...address,
                                  addressRegion: e.target.value,
                                })
                              }}
                              placeholder="State"
                              required
                              value={address.addressRegion}
                            />
                            <Form.Input
                              label="Zip Code"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setAddress({
                                  ...address,
                                  postalCode: e.target.value,
                                })
                              }}
                              placeholder="Zip Code"
                              required
                              value={address.postalCode}
                            />
                          </Form.Group>
                        </StyledForm>
                      </div>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: {
                    key: 'users',
                    content: 'Users',
                    disabled: !company,
                  },
                  render: () => (
                    <Tab.Pane>
                      <div style={{ maxWidth: '60%' }}>
                        <StyledForm styled={{ grayInputs: true }}>
                          <Form.Group
                            widths="equal"
                            style={{ alignItems: 'center' }}
                          >
                            <Form.Dropdown
                              fluid
                              clearable
                              label="User (please enter name or email)"
                              options={userOptions}
                              onChange={(_, { value }) =>
                                setNewUser(value as string)
                              }
                              onSearchChange={debouncedOnSearchChange}
                              placeholder="Enter full user email here"
                              search
                              selection
                              value={newUser}
                            />
                            <Button
                              kind="solid"
                              color="blue"
                              text="Add"
                              disabled={!newUser}
                              onClick={() => {
                                setState({
                                  ...state,
                                  users: state?.users?.concat(
                                    users.find((u) => u.id === newUser) ?? [],
                                  ),
                                })
                                setNewUser('')
                                resetUsers([])
                              }}
                            />
                          </Form.Group>
                        </StyledForm>
                        <List divided>
                          {state?.users?.map((user) => (
                            <List.Item key={user.id}>
                              <List.Content floated="right">
                                <Button
                                  fontAwesomeIcon="close"
                                  kind="ghost"
                                  color="gray"
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      users: state?.users?.filter(
                                        (u) => u.id !== user.id,
                                      ),
                                    })
                                  }
                                />
                              </List.Content>
                              <Image src={user.picture} avatar circular />
                              <List.Content>
                                <List.Header>
                                  <p className="blod">
                                    {viewUtils.getNameForUser(user)}
                                  </p>
                                </List.Header>
                                {!!user.firstName && (
                                  <List.Description>
                                    <p className="small gray">{user.email}</p>
                                  </List.Description>
                                )}
                                <List.Description>
                                  <p className="small gray">
                                    {viewUtils.capsSnakeCaseToTitleCase(
                                      user.type,
                                    )}
                                  </p>
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          ))}
                        </List>
                      </div>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: {
                    key: 'prospects',
                    content: 'Prospects',
                    disabled: !company,
                  },
                  render: () => (
                    <Tab.Pane>
                      <List divided>
                        {company?.prospects?.map((prospect) => (
                          <List.Item key={prospect.id}>
                            <List.Content>
                              <List.Header>
                                <p className="blod">
                                  {getNameForProspect(prospect)}
                                </p>
                              </List.Header>
                              <List.Description>
                                <p className="small gray">{prospect.email}</p>
                              </List.Description>
                              <List.Description>
                                <p className="small gray">
                                  {viewUtils.capsSnakeCaseToTitleCase(
                                    prospect.data?.userType || '',
                                  )}
                                </p>
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        ))}
                      </List>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: {
                    key: 'projects',
                    content: 'Projects',
                    disabled: !company,
                  },
                  render: () => (
                    <Tab.Pane>
                      <ProjectManyProvider
                        defaultFilters={{
                          companyId: company?.id,
                          sortBy: 'derivedActivity',
                          sortDirection: 'descending',
                          status: 'open',
                          take: 30,
                        }}
                        skipLoader
                      >
                        <Projects noFilters />
                      </ProjectManyProvider>
                    </Tab.Pane>
                  ),
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SaveToast
        disabled={
          !state?.name ||
          !state?.data?.industry ||
          !state?.data?.size ||
          (!!state?.data?.EINNumber && inputError)
        }
        loading={loadingCreate || loadingDelete || loadingUpdate}
        onClick={onSave}
        show={
          !isEqual(state, initialState) || !isEqual(address, initialAddress)
        }
      />
    </AdminLayout>
  )
}

export default (props: RouteComponentProps<{ company_id: string }>) => {
  return (
    <CompanyProvider companyId={props.company_id || ''}>
      <Module />
    </CompanyProvider>
  )
}

const StyledTab = styled(Tab)`
  .tab {
    border: none !important;
  }
`

const LabeledField = styled(Form.Field)`
  .labeled > .label {
    background: ${Colors.gray100};
    border: 1px solid ${Colors.gray100};
    padding: 14px !important;

    font-family: 'GT America';
    font-size: 16px !important;
    font-weight: 100;
    color: ${Colors.gray600};
  }
`
