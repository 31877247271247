import React, { useState } from 'react'

import { Form, Grid, Transition } from 'semantic-ui-react'

import CarouselModal from 'components/shared/carousel/carousel-modal'
import FileCard from 'components/shared/project/project-file/card'
import { UploadProjectFileCard } from 'components/shared/project/project-file/upload-card'
import useDesign from 'context/design/use'
import useDesignProjectFileMutate from 'context/design/use-project-file-mutate'
import { sortAndReindexRenders } from 'context/design/utils'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import StyledAccordion from 'design-system/components/accordion'
import Button from 'design-system/components/button'
import { StyledToggle } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { Design } from 'types/design'
import { ProjectFile, ProjectFileType } from 'types/project-file'

const VisualQuote = ({
  setState,
  state,
}: {
  setState: (d: Partial<Design>) => void
  state: Partial<Design>
}) => {
  const [showSorting, setShowSorting] = useState<boolean>(false)
  const { project } = useProject()
  const { room } = useRoom()
  const { design, refetch } = useDesign()
  const { deleteProjectFileAndRefetch } = useDesignProjectFileMutate()

  const onFilesUploaded = () => {
    refetch()
  }

  const renderUrls = state.renders?.map((f) => {
    return `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(f.key)}`
  })

  return (
    <StyledAccordion titleText="Visual Quote">
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column width={11}>
            <p className="caption">Renders or 3D elevations</p>
          </Grid.Column>
          <Grid.Column textAlign="right" width={5}>
            <Form>
              <Form.Group>
                <Form.Field>
                  <StyledToggle
                    checked={showSorting}
                    label={
                      <label className="caption">
                        Reorder {showSorting ? 'On' : 'Off'}
                      </label>
                    }
                    onChange={() => setShowSorting(!showSorting)}
                    toggle
                  />
                </Form.Field>
                <Form.Field>
                  {renderUrls?.length ? (
                    <CarouselModal
                      currentIdx={0}
                      images={renderUrls}
                      trigger={<Button kind="solid" text="Open Carousel" />}
                    />
                  ) : null}
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column>
            <UploadProjectFileCard
              fileType={ProjectFileType.RENDER}
              onFilesUploaded={onFilesUploaded}
              projectId={project.id}
              roomId={room?.id}
              designRenderId={design?.id}
            />
          </Grid.Column>
          {state.renders?.map((f, index) => (
            <Grid.Column key={f.id} style={{ marginBottom: '25px' }}>
              <FileCard
                file={f}
                onFileRemoved={async (f: Partial<ProjectFile>) => {
                  deleteProjectFileAndRefetch({
                    variables: {
                      where: {
                        id: f.id as string,
                      },
                    },
                  })
                }}
                navigateTo={`/admin/files/${f.id}/`}
                showActions
              />
              <Transition
                animation="slide down"
                duration={500}
                visible={showSorting}
              >
                <div>
                  <Grid
                    style={{
                      marginTop: '0',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid.Column width={7}>
                      <label className="caption">
                        Position
                        <span style={{ color: Colors.black }}>
                          {' '}
                          #{index + 1}
                        </span>
                      </label>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={7}>
                      {index !== 0 && (
                        <Button
                          color="blue"
                          fontAwesomeIcon="arrow-left"
                          kind="solid"
                          onClick={() =>
                            setState({
                              renders: sortAndReindexRenders(
                                state.renders?.map((r, idx) => {
                                  if (idx === index) {
                                    return {
                                      ...r,
                                      metadata: {
                                        ...r.metadata,
                                        index: isNaN(
                                          Number(r.metadata.index) - 1,
                                        )
                                          ? idx
                                          : (r.metadata.index as number) - 1,
                                      },
                                    }
                                  }
                                  if (idx === index - 1) {
                                    return {
                                      ...r,
                                      metadata: {
                                        ...r.metadata,
                                        index: isNaN(
                                          Number(r.metadata.index) + 1,
                                        )
                                          ? idx - 1
                                          : (r.metadata.index as number) + 1,
                                      },
                                    }
                                  }
                                  return r
                                }) ?? [],
                              ),
                            })
                          }
                        />
                      )}
                      {state.renders && index !== state.renders.length - 1 && (
                        <Button
                          color="blue"
                          fontAwesomeIcon="arrow-right"
                          kind="solid"
                          onClick={() =>
                            setState({
                              renders: sortAndReindexRenders(
                                state.renders?.map((r, idx) => {
                                  if (idx === index)
                                    return {
                                      ...r,
                                      metadata: {
                                        ...r.metadata,
                                        index: isNaN(
                                          Number(r.metadata.index) + 1,
                                        )
                                          ? idx - 1
                                          : (r.metadata.index as number) + 1,
                                      },
                                    }
                                  if (idx === index + 1)
                                    return {
                                      ...r,
                                      metadata: {
                                        ...r.metadata,
                                        index: isNaN(
                                          Number(r.metadata.index) - 1,
                                        )
                                          ? idx
                                          : (r.metadata.index as number) - 1,
                                      },
                                    }
                                  return r
                                }) ?? [],
                              ),
                            })
                          }
                          style={{ marginLeft: '5px' }}
                        />
                      )}
                    </Grid.Column>
                  </Grid>
                </div>
              </Transition>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </StyledAccordion>
  )
}

export default VisualQuote
