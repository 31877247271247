import { useMutation } from '@apollo/client'

import {
  UPDATE_DESIGN,
  UpdateDesignPayload,
  UpdateDesignVariables,
} from 'queries/design'

const useDesignMutate = () => {
  const [updateDesign, { loading: loadingUpdate }] = useMutation<
    UpdateDesignPayload,
    UpdateDesignVariables
  >(UPDATE_DESIGN)

  return {
    loadingUpdate,
    updateDesign,
  }
}

export default useDesignMutate
