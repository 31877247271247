import React, { useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import {
  useFormContext,
  Controller,
  FormProvider,
  UseFormReturn,
  Path,
  PathValue,
  Validate,
} from 'react-hook-form'
import { DatesRangeInput } from 'semantic-ui-calendar-react'
import {
  DropdownItemProps,
  Form,
  FormCheckboxProps,
  FormDropdownProps,
  FormRadioProps,
} from 'semantic-ui-react'
import styled from 'styled-components'

import { Drawer } from 'design-system/components/drawer'
import { FormCheckbox, StyledRadio } from 'styles/admin/main'
import { Colors } from 'styles/app/system'

import {
  DynamicFilter,
  DynamicFilterGroup,
  DynamicFilterTypes,
  IDynamicFields,
  IFormCheckboxValue,
  IFormDateValue,
  IFormDropdownValue,
  IFormRadioValue,
} from './types'
import { dynamicDateValidation } from './utils'

type DynamicFilterFormProps<TFields extends IDynamicFields> = {
  filterGroups: DynamicFilterGroup<TFields>[]
  savedValues?: Partial<TFields>
  hookFormMethods: UseFormReturn<TFields>
}

export function DynamicFilterForm<TFields extends IDynamicFields>({
  filterGroups,
  savedValues,
  hookFormMethods,
}: DynamicFilterFormProps<TFields>) {
  const { setValue } = hookFormMethods

  useEffect(() => {
    if (savedValues) {
      Object.entries(savedValues).forEach(([filterId, filterValue]) => {
        filterId &&
          filterValue &&
          setValue(
            filterId as Path<TFields>,
            filterValue as PathValue<TFields, Path<TFields>>,
          )
      })
    }
  }, [])

  // ! The `as any` cast is needed. Otherwise, tsc throws `type instantiation is excessively deep and possibly infinite.`
  // ! This might be an issue with the types in the FormProvider and generics, since this any "solves" the issue.
  // ! When updating the package, try to remove the any and see if it works
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <FormProvider {...(hookFormMethods as any)}>
      <Form>
        <FormContainer>
          {filterGroups.map((group, idx) => (
            <DynamicFilterGroupComponent
              key={`group#${idx}-${group.groupName ?? ''}`}
              {...group}
            />
          ))}
        </FormContainer>
      </Form>
    </FormProvider>
  )
}

function DynamicFilterGroupComponent<TFields extends IDynamicFields>({
  groupName,
  filters,
  withDrawer,
}: DynamicFilterGroup<TFields>) {
  const methods = useFormContext<TFields>()
  const { getValues } = methods
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    if (withDrawer) {
      const hasSelectedOption = filters.some(
        (filter) => !!getValues(filter.filterId as Path<TFields>),
      )
      setOpenDrawer(hasSelectedOption)
    }
  }, [withDrawer])

  const filtersComponent = (
    <>
      {filters.map((filter) => (
        <DyamicInput key={filter.filterId as string} {...filter} />
      ))}
    </>
  )

  return withDrawer ? (
    <Drawer
      open={openDrawer}
      setOpen={setOpenDrawer}
      actionsStyle={{ padding: 0 }}
      unmount={false}
      title={groupName ?? ''}
      content={
        <GroupContainer style={{ paddingTop: 8 }}>
          {filtersComponent}
        </GroupContainer>
      }
      allowOutsideClick
    />
  ) : (
    <GroupContainer>
      {groupName && <p className="overline gray no-margin">{groupName}</p>}
      {filtersComponent}
    </GroupContainer>
  )
}

function DyamicInput<TFields extends IDynamicFields>(
  props: DynamicFilter<TFields>,
) {
  const methods = useFormContext<TFields>()
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = methods

  const filterId = props.filterId as Path<TFields>
  const defaultValue = props.defaultValue as PathValue<TFields, Path<TFields>>
  const shouldDisable = props.disableIf?.(watch())

  const dropdowOptionsWithIcon = useMemo(() => {
    if (props.type === DynamicFilterTypes.DROPDOWN) {
      return props.options.map((option) => ({
        ...option,
        icon: option.icon && (
          <FontAwesomeIcon
            key={option.key}
            style={{ marginRight: 16, width: 16, height: 16 }}
            icon={['fal', option.icon]}
          />
        ),
      }))
    }
    return []
  }, [])

  useEffect(() => {
    if (shouldDisable) {
      setValue(filterId, null as any)
    }
  }, [shouldDisable])

  const renderControllerInput = () => {
    switch (props.type) {
      case DynamicFilterTypes.CHECKBOX:
        return (
          <Controller
            name={filterId}
            control={control}
            defaultValue={defaultValue}
            rules={{
              required: props.required,
            }}
            render={({ field: { onChange, value, onBlur } }) => {
              const checkboxValue = value as IFormCheckboxValue

              const handleChange: FormCheckboxProps['onChange'] = (_, data) => {
                const currentValue = checkboxValue ?? {
                  type: DynamicFilterTypes.CHECKBOX,
                  selection: [],
                }

                if (data.checked) {
                  currentValue.selection.push({
                    text: data.label as string,
                    value: data.value as string,
                  })
                } else {
                  const idx = currentValue.selection.findIndex(
                    (val) => val.value === data.value,
                  )
                  if (idx !== -1) {
                    currentValue.selection.splice(idx, 1)
                  }
                }

                onChange(currentValue.selection.length ? currentValue : null)
              }

              return (
                <RadioContainer>
                  {props.options.map((option, idx) => (
                    <FormCheckbox
                      key={`checkbox-${option.value}#${idx}`}
                      checked={
                        !!checkboxValue?.selection?.find(
                          (v) => v.value === option.value,
                        )
                      }
                      name={`${filterId}-${option.value}`}
                      label={option.text}
                      onBlur={onBlur}
                      onChange={handleChange}
                      disabled={shouldDisable}
                      value={option.value}
                    />
                  ))}
                </RadioContainer>
              )
            }}
          />
        )
      case DynamicFilterTypes.DROPDOWN:
        return (
          <Controller
            name={filterId}
            defaultValue={defaultValue}
            rules={{
              required: props.required,
            }}
            render={({ field: { value, onBlur, onChange } }) => {
              const dropdownValue = value as IFormDropdownValue

              const handleChange: FormDropdownProps['onChange'] = (_, data) => {
                if (data.value) {
                  const selection = props.options.find(
                    (opt) => opt.value === data.value,
                  )
                  if (selection) {
                    onChange({
                      type: DynamicFilterTypes.DROPDOWN,
                      selection,
                    })
                    return
                  }
                }
                onChange(null)
              }

              return (
                <Form.Dropdown
                  options={dropdowOptionsWithIcon as DropdownItemProps[]}
                  value={dropdownValue?.selection?.value ?? undefined}
                  clearable
                  fluid
                  search
                  selection
                  onChange={handleChange}
                  onBlur={onBlur}
                  disabled={shouldDisable}
                />
              )
            }}
          />
        )
      case DynamicFilterTypes.RADIO:
        return (
          <Controller
            name={filterId}
            defaultValue={defaultValue}
            rules={{
              required: props.required,
            }}
            render={({ field: { value, onChange, onBlur } }) => {
              const radioValue = value as IFormRadioValue

              const handleChange: FormRadioProps['onChange'] = (_, data) => {
                if (data.checked) {
                  onChange({
                    type: DynamicFilterTypes.RADIO,
                    selection: { text: data.label, value: data.value },
                  })
                } else {
                  onChange(null)
                }
              }

              return (
                <RadioContainer>
                  {props.options.map((option, idx) => (
                    <StyledRadio
                      key={`radio-${option.value}#${idx}`}
                      name={`${filterId}-${option.value}`}
                      label={option.text}
                      checked={radioValue?.selection?.value === option.value}
                      onChange={handleChange}
                      onBlur={onBlur}
                      disabled={shouldDisable}
                      value={option.value}
                    />
                  ))}
                </RadioContainer>
              )
            }}
          />
        )
      case DynamicFilterTypes.DATE: {
        const format = props.format ?? 'MM/DD/YYYY'
        const placeholder = props.placeholder ?? 'Choose date'
        const validate = (props.validation ??
          dynamicDateValidation) as Validate<
          PathValue<TFields, Path<TFields>>,
          TFields
        >
        const errorMessage = errors[filterId]?.message as string

        return (
          <DateContainer>
            <Controller
              name={filterId}
              defaultValue={defaultValue}
              rules={{
                validate,
                required: props.required,
              }}
              render={({ field: { onBlur, onChange, value, ref } }) => {
                const dateValue = value as IFormDateValue
                const maxDate = props.max ? moment(props.max) : undefined
                const minDate = props.min ? moment(props.min) : undefined

                return (
                  <DatesRangeInput
                    ref={ref}
                    clearable
                    dateFormat={format}
                    fluid
                    initialDate={moment().format(format)}
                    onChange={(_, data) => {
                      if (data?.value) {
                        onChange({
                          type: DynamicFilterTypes.DATE,
                          value: data.value,
                        })
                      } else {
                        onChange(null)
                      }
                    }}
                    placeholder={placeholder}
                    popupPosition="bottom center"
                    value={dateValue?.value ?? ''}
                    onBlur={onBlur}
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={shouldDisable}
                    allowSameEndDate
                    error={
                      errorMessage && {
                        content: <p className="red small">{errorMessage}</p>,
                      }
                    }
                  />
                )
              }}
            />
          </DateContainer>
        )
      }
      default:
        break
    }
  }

  if (props.label)
    return (
      <div>
        <p
          className="overline"
          style={{
            marginBottom: '8px',
            display: 'inline-block',
          }}
        >
          {props.label}
        </p>
        {renderControllerInput()}
      </div>
    )

  return renderControllerInput() ?? null
}

const DateContainer = styled.div`
  & > div {
    margin: 0 !important;
  }

  & > p {
    margin: 8px 0 0;
    color: ${Colors.red700} !important;
    font-style: italic;
  }
`
const RadioContainer = styled.div`
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  row-gap: 16px;
  width: 100%;

  & > div {
    margin-bottom: 0 !important;
  }
`

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
