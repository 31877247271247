import { useMutation } from '@apollo/client'

import {
  CREATE_RENDER_PROP,
  CreateRenderPropPayload,
  CreateRenderPropVariables,
  UPDATE_RENDER_PROP,
  UpdateRenderPropPayload,
  UpdateRenderPropVariables,
} from 'queries/render-prop'

const useRenderPropMutate = () => {
  const [createRenderProp, { loading: loadingCreate }] = useMutation<
    CreateRenderPropPayload,
    CreateRenderPropVariables
  >(CREATE_RENDER_PROP)

  const [updateRenderProp, { loading: loadingUpdate }] = useMutation<
    UpdateRenderPropPayload,
    UpdateRenderPropVariables
  >(UPDATE_RENDER_PROP)

  return {
    createRenderProp,
    loadingCreate,
    loadingUpdate,
    updateRenderProp,
  }
}

export default useRenderPropMutate
