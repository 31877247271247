import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import Assets from 'components/admin/catalog/media/assets'
import MediaModal from 'components/admin/catalog/media/modal'
import RoomElementClassSummary from 'components/admin/catalog/room-element-class/summary'
import useAuth from 'context/auth/use'
import useRoomElementClass from 'context/room-element-class/use'
import Button from 'design-system/components/button'
import { StyledBreadcrumb } from 'styles/planner/main'

const Module = (_: RouteComponentProps) => {
  const { isAdmin } = useAuth()
  const { catalog, roomElementClass, refetch } = useRoomElementClass()
  const sections = [
    {
      key: 'Catalog',
      content: `Catalog`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Room Elements',
      content: 'Room Elements',
      href: `/admin/catalog/${catalog}/room-element-classes`,
    },
    {
      key: 'Room Element',
      content: `${roomElementClass.identifier}`,
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
        <Grid.Column>
          <MediaModal
            refetch={refetch}
            roomElementClasses={[roomElementClass]}
            trigger={
              <Button
                kind="solid"
                color="blue"
                text="Upload Media"
                disabled={!isAdmin}
                style={{ float: 'right' }}
                fontAwesomeIcon="cloud-arrow-up"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{roomElementClass.identifier}</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <RoomElementClassSummary
            refetch={refetch}
            roomElementClass={roomElementClass}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Assets media={roomElementClass.media ?? []} refetch={refetch} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Module
