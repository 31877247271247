import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Form } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import { notifyError, notifySuccess } from 'context/notifications/trigger'
import { Modal } from 'design-system/components/modal'
import {
  CreateOneGenericOptionPayload,
  CreateOneGenericOptionVariables,
  CREATE_ONE_GENERIC_OPTION,
  UpdateGenericOptionPayload,
  UpdateGenericOptionVariables,
  UPDATE_ONE_GENERIC_OPTION,
} from 'queries/generic-option'
import { StyledForm } from 'styles/admin/main'
import { GenericOption } from 'types/generic-option'
import { camelCaseToTitleCase } from 'views/utils'

const DEFAULT_STATE: GenericOption = {
  data: {},
  description: '',
  featureIdentifier: '',
  id: '',
  identifier: '',
  optionIdentifier: '',
  media: [],
}

type CreateUpdateGenericOptionModalProps = {
  featureIdentifier: string
  genericOption?: GenericOption
  onClose: () => void
  onSave: (id: string, featureId: string) => void
  openModal: boolean
  trigger?: React.ReactElement
}

const CreateUpdateGenericOptionModal = ({
  featureIdentifier,
  genericOption,
  onClose,
  onSave,
  openModal,
  trigger,
}: CreateUpdateGenericOptionModalProps) => {
  //* While it is an overkill to have the full state for the current scenario, it allows
  //* to further on add more fields to update without modifying the underlying structure
  const [state, setState] = useState<GenericOption>(
    genericOption ?? DEFAULT_STATE,
  )

  const [updateOneGenericOption, { loading: loadingUpdate }] = useMutation<
    UpdateGenericOptionPayload,
    UpdateGenericOptionVariables
  >(UPDATE_ONE_GENERIC_OPTION, {
    onCompleted: ({ updateOneGenericOption: { id, featureId = '' } }) => {
      notifySuccess('Generic option updated')
      onSave(id, featureId)
      setState(DEFAULT_STATE)
    },
    onError: () => {
      notifyError('Could not update option')
    },
  })

  const [createOneGenericOption, { loading: loadingSave }] = useMutation<
    CreateOneGenericOptionPayload,
    CreateOneGenericOptionVariables
  >(CREATE_ONE_GENERIC_OPTION, {
    onCompleted: ({ createOneGenericOption: { id, featureId = '' } }) => {
      notifySuccess('Generic option created')
      onSave(id, featureId)
      setState(DEFAULT_STATE)
    },
    onError: () => {
      notifyError('Could not create option')
    },
  })

  const handleClose = () => {
    onClose()
    setState(DEFAULT_STATE)
  }

  const handleSave = async () => {
    const { optionIdentifier, identifier } =
      generateIdentifiers(featureIdentifier)

    await createOneGenericOption({
      variables: {
        data: {
          data: {},
          description: state.description,
          feature: {
            connect: {
              identifier: featureIdentifier,
            },
          },
          featureIdentifier,
          identifier,
          optionIdentifier,
        },
      },
    })
  }

  const handleUpdate = async () => {
    await updateOneGenericOption({
      variables: {
        data: {
          description: state.description,
        },
        where: {
          id: genericOption?.id ?? '',
        },
      },
    })
  }

  useEffect(() => {
    setState(genericOption || DEFAULT_STATE)
  }, [genericOption])

  return (
    <Modal
      onClose={handleClose}
      open={openModal}
      size="medium"
      trigger={trigger}
      title={
        genericOption
          ? `Update ${camelCaseToTitleCase(featureIdentifier)}`
          : `Create ${camelCaseToTitleCase(featureIdentifier)}`
      }
      saveButton={
        genericOption
          ? {
              disabled: !state.description.trim(),
              loading: loadingUpdate,
              onClick: handleUpdate,
            }
          : {
              disabled: !state.description.trim(),
              loading: loadingSave,
              onClick: handleSave,
            }
      }
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Input
          label="Name / Description"
          name="nameDescription"
          type="text"
          placeholder="Name / Description"
          value={state.description}
          onChange={(evt) =>
            setState({ ...state, description: evt.target.value })
          }
          required
        />
      </StyledForm>
    </Modal>
  )
}

const generateIdentifiers = (featureIdentifier: string) => {
  const optionIdentifier = uuidv4().substring(0, 8)

  const identifier = `${featureIdentifier}_${optionIdentifier}`
  return { identifier, optionIdentifier }
}

export default CreateUpdateGenericOptionModal
