import React, { useState } from 'react'

import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

import { useSelectionCenters } from 'context/selection-centers/provider'
import useSelectionCenterMutate from 'context/selection-centers/use-mutate'
import { ButtonTooltip } from 'design-system/components/button'
import { SelectionCenter } from 'types/selection-center'

import SelectionCenterModal from './create-update-modal'

const SelectionCentersTable = () => {
  const [enableEdit, setEnableEdit] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const { selectionCenters, setSelectionCenter, refetch } =
    useSelectionCenters()
  const { deleteSelectionCenter } = useSelectionCenterMutate()

  const handleEdit = (selectionCenter: SelectionCenter) => {
    setSelectionCenter(selectionCenter.identifier)
    setEnableEdit(true)
  }

  const handleDelete = async (id: string) => {
    setDeleteId(id)
    await deleteSelectionCenter({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        setDeleteId('')
        refetch()
      },
      onError: () => setDeleteId(''),
    })
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {selectionCenters.map((sc) => {
          return (
            <Table.Row key={sc.id}>
              <Table.Cell>{sc.name}</Table.Cell>
              <Table.Cell>{sc.data?.address}</Table.Cell>
              <Table.Cell>
                {`${sc.staff.length} staff ${
                  sc.staff.length === 1 ? 'member' : 'members'
                }`}
              </Table.Cell>
              <Table.Cell>
                <ActionsWrapper>
                  <ButtonTooltip
                    kind="solid"
                    color="blue"
                    fontAwesomeIcon="edit"
                    tooltipProps={{
                      label: 'Edit Selection Center',
                    }}
                    onClick={() => handleEdit(sc)}
                  />
                  <ButtonTooltip
                    kind="solid"
                    fontAwesomeIcon="trash-alt"
                    loading={deleteId === sc.id}
                    tooltipProps={{
                      label: 'Delete Selection Center',
                    }}
                    onClick={() => handleDelete(sc.id)}
                  />
                </ActionsWrapper>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <SelectionCenterModal
        onClose={() => setEnableEdit(false)}
        open={enableEdit}
        onExited={() => setSelectionCenter(null)}
      />
    </Table>
  )
}

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`

export default SelectionCentersTable
