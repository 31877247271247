import { gql } from '@apollo/client'

import { DesignAdvisoryCodeGroup } from 'types/design-advisory-code'

export const CREATE_ONE_DESIGN_ADVISORY_CODE_GROUP = gql`
  mutation createOneDesignAdvisoryCodeGroup(
    $data: DesignAdvisoryCodeGroupCreateInput!
  ) {
    createOneDesignAdvisoryCodeGroup(data: $data) {
      id
    }
  }
`

export interface CreateOneDesignAdvisoryCodeGroupPayload {
  createOneDesignAdvisoryCodeGroup: DesignAdvisoryCodeGroup
}

export interface CreateOneDesignAdvisoryCodeGroupVariables {
  data: {
    description: string
    index: string
  }
}

export const FIND_MANY_DESIGN_ADVISORY_CODE_GROUPS = gql`
  query findManyDesignAdvisoryCodeGroup {
    designAdvisoryCodeGroups: findManyDesignAdvisoryCodeGroup {
      id
      createdAt
      description
      index
      designAdvisoryCodes(orderBy: { index: asc }) {
        id
        createdAt
        description
        index
        required
        updatedAt
      }
      updatedAt
    }
  }
`

export interface FindManyDesignAdvisoryCodeGroupPayload {
  designAdvisoryCodeGroups: DesignAdvisoryCodeGroup[]
}

export const UPDATE_ONE_DESIGN_ADVISORY_CODE_GROUP = gql`
  mutation updateOneDesignAdvisoryCodeGroup(
    $data: DesignAdvisoryCodeGroupUpdateInput!
    $where: DesignAdvisoryCodeGroupWhereUniqueInput!
  ) {
    updateOneDesignAdvisoryCodeGroup(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateOneDesignAdvisoryCodeGroupPayload {
  updateOneDesignAdvisoryCodeGroup: {
    id: string
  }
}

export interface UpdateOneDesignAdvisoryCodeGroupVariables {
  data: {
    description?: string
    index?: string
  }
  where: {
    id: string
  }
}
