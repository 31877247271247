import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import moment from 'moment'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import { DropdownProps, Form, FormInputProps } from 'semantic-ui-react'

import { parseStaff } from 'context/prospect/utils'
import { Modal } from 'design-system/components/modal'
import {
  CREATE_MEETING,
  CreateMeetingPayload,
  CreateMeetingVariables,
  UPDATE_MEETING,
  UpdateMeetingPayload,
  UpdateMeetingVariables,
} from 'queries/meeting'
import { StyledForm } from 'styles/admin/main'
import { Meeting, MeetingMetadata, MeetingTypeProspect } from 'types/meeting'
import { Prospect } from 'types/prospect'
import { getNameForUser } from 'views/utils'

const now = moment().format('MM-DD-YYYY hh:mm a')

interface MeetingProps {
  meeting?: Meeting
  onCompleted: () => void
  prospect: Prospect
  trigger: JSX.Element
}

const ConsultationCreateUpdateModal = (props: MeetingProps) => {
  const { primaryAccountExecutive } = parseStaff(props.prospect)

  const [showModal, toggleModal] = useState(false)
  const [meeting, setMeeting] = useState<NullableRecord<Meeting>>(
    props.meeting || {
      calendlyUrl: '',
      duration: 15,
      metadata: {
        agenda: '',
        type: MeetingTypeProspect.FREE,
        recording: '',
      },
      staffMemberId: primaryAccountExecutive?.id || null,
    },
  )

  const onCloseModal = () => {
    toggleModal(false)
    setMeeting(
      props.meeting || {
        calendlyUrl: '',
        duration: 15,
        metadata: {
          agenda: '',
          type: MeetingTypeProspect.FREE,
          recording: '',
        },
        staffMemberId: primaryAccountExecutive?.id || null,
      },
    )
  }

  const [createMeeting] = useMutation<
    CreateMeetingPayload,
    CreateMeetingVariables
  >(CREATE_MEETING, {
    onCompleted: () => props.onCompleted(),
  })

  const [updateMeeting] = useMutation<
    UpdateMeetingPayload,
    UpdateMeetingVariables
  >(UPDATE_MEETING, {
    onCompleted: () => props.onCompleted(),
  })

  const onChangeStartTime = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setMeeting({
      ...meeting,
      startTime: moment(value, 'MM-DD-YYYY hh:mm a').toDate(),
    })
  }

  const onChangeInput = (
    _: React.SyntheticEvent,
    { name, value }: FormInputProps,
  ) => {
    setMeeting({
      ...meeting,
      metadata: {
        ...meeting.metadata,
        [`${name}`]: value,
      } as MeetingMetadata,
    })
  }

  const onChangeDropdown = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) =>
    setMeeting({
      ...meeting,
      duration: null,
      metadata: {
        ...meeting.metadata,
        type: value as MeetingTypeProspect,
      },
    })

  const onChangeDuration = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) =>
    setMeeting({
      ...meeting,
      duration: parseFloat(value as string),
    })

  const onSubmit = async () => {
    if (meeting.id)
      await updateMeeting({
        variables: {
          data: {
            calendlyUrl: meeting.calendlyUrl,
            duration: meeting.duration || null,
            metadata: meeting.metadata,
            staffMember: {
              connect: { id: meeting.staffMemberId as string },
            },
            startTime: meeting.startTime || null,
          },
          where: {
            id: meeting.id,
          },
        },
      })
    else
      await createMeeting({
        variables: {
          data: {
            calendlyUrl: meeting.calendlyUrl as string,
            duration: meeting.duration || undefined,
            metadata: meeting.metadata as MeetingMetadata,
            prospect: { connect: { id: props.prospect.id } },
            staffMember: {
              connect: { id: meeting.staffMemberId as string },
            },
            startTime: meeting.startTime || undefined,
          },
        },
      })
    toggleModal(false)
  }

  return (
    <Modal
      onClose={onCloseModal}
      onOpen={() => toggleModal(true)}
      open={showModal}
      size="small"
      title={meeting.id ? 'Edit Consultation' : 'Create Consultation'}
      trigger={props.trigger}
      saveButton={{
        onClick: onSubmit,
        disabled: !meeting.staffMemberId,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Dropdown
          fluid
          label="User"
          options={props.prospect.staff
            .map((s) => s.staffMember)
            .map((user) => ({
              image: {
                avatar: true,
                src: user?.picture,
              },
              key: user?.id,
              text: getNameForUser(user),
              value: user?.id,
            }))}
          onChange={(_, { value }) =>
            setMeeting({
              ...meeting,
              staffMemberId: value as string,
            })
          }
          placeholder="Staff Member"
          required
          search
          selection
          value={meeting.staffMemberId ?? undefined}
        />
        <Form.Dropdown
          name="type"
          label="Type"
          onChange={onChangeDropdown}
          options={[
            {
              key: 'f',
              text: 'Advisory Call / Free Consultation',
              value: MeetingTypeProspect.FREE,
            },
            {
              key: 'fv',
              text: 'Free Visit SF',
              value: MeetingTypeProspect.FREE_VISIT_SF,
            },
            {
              key: 'fvn',
              text: 'Free Visit NY',
              value: MeetingTypeProspect.FREE_VISIT_NY,
            },
            {
              key: 'fU',
              text: 'Follow Up Call',
              value: MeetingTypeProspect.FOLLOW_UP,
            },
            {
              key: 'sc',
              text: 'Selection Center',
              value: MeetingTypeProspect.SELECTION_CENTER,
            },
          ]}
          placeholder="Meeting Type"
          selection
          value={meeting.metadata?.type || ''}
        />
        {meeting.metadata?.type === MeetingTypeProspect.FREE && (
          <Form.Field>
            <label>Duration</label>
            <Form.Dropdown
              disabled={!meeting.metadata?.type}
              placeholder="Meeting Duration"
              selection
              options={[
                { key: '15', value: '15', text: '15 minutes / Advisory Call' },
                { key: '25', value: '25', text: '25 minutes / Free Call' },
              ]}
              onChange={onChangeDuration}
              value={meeting.duration?.toString() || ''}
            />
          </Form.Field>
        )}
        <Form.Field>
          <label>Date</label>
          <DateTimeInput
            closable
            autoComplete="off"
            className="example-calendar-input"
            dateFormat="MM-DD-YYYY hh:mm a"
            timeFormat="ampm"
            duration={0}
            hideMobileKeyboard
            iconPosition="left"
            initialDate={now}
            name="time"
            onChange={onChangeStartTime}
            placeholder="Time"
            value={
              meeting.startTime
                ? moment(meeting.startTime).format('MM-DD-YYYY hh:mm a')
                : ''
            }
          />
        </Form.Field>
        <Form.Input
          name="recording"
          label="Recording link"
          onChange={onChangeInput}
          placeholder="Link to Recording"
          value={meeting.metadata?.recording || ''}
        />
        <Form.Input
          label="Password of recording link"
          name="recordingPassword"
          onChange={onChangeInput}
          placeholder="Password"
          value={meeting.metadata?.recordingPassword || ''}
        />
      </StyledForm>
    </Modal>
  )
}

export default ConsultationCreateUpdateModal
