import React from 'react'

import { Grid, Loader as Loading } from 'semantic-ui-react'

import useRenderPropFiltersSearch from 'components/admin/catalog/render-prop/use-filters-search'
import FiltersAndSearch from 'components/admin/filters-search'
import { LoadButton } from 'components/shared/load-buttons'
import Loader from 'components/shared/loader'
import useRenderPropMany from 'context/render-prop/use-many'
import {
  SelectionCardExpandSelectProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { RenderProp } from 'types/render-prop'

import RenderPropSelectionCard from './card'

const RenderPropSelection = ({
  oneSelection,
  setSelectedRenderProp,
  selectedRenderProp = [],
}: {
  oneSelection?: boolean
  setSelectedRenderProp: (renderProp?: RenderProp[]) => void
  selectedRenderProp?: RenderProp[]
}) => {
  const { count, renderProps, fetchMoreOnDemand, loading, loadingMore } =
    useRenderPropMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useRenderPropFiltersSearch()
  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
        style={{ marginTop: '10px' }}
      />
      {loading ? (
        <Grid.Row style={{ minHeight: '100vh' }}>
          <Grid.Column>
            <Loader flex fullScreen={false} />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row columns={4}>
          {renderProps.map((item) => {
            return (
              <Grid.Column key={item.id} style={{ marginBottom: '20px' }}>
                <RenderPropSelectionCard
                  renderProp={item}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      selectOnly: true,
                      onSelect: () => {
                        if (selectedRenderProp?.find((s) => s.id === item.id))
                          setSelectedRenderProp(
                            oneSelection
                              ? []
                              : selectedRenderProp.filter(
                                  (s) => s.id !== item.id,
                                ),
                          )
                        else
                          setSelectedRenderProp(
                            oneSelection
                              ? [item]
                              : [...selectedRenderProp, item],
                          )
                      },
                      selected: selectedRenderProp?.some(
                        (s) => s.id === item.id,
                      ),
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      )}
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && renderProps?.length > 0 && renderProps.length !== count && (
        <LoadButton onClick={fetchMoreOnDemand} />
      )}
    </Grid>
  )
}

export default RenderPropSelection
