import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'semantic-ui-react'
import styled from 'styled-components'

import { getMediaDisplay } from 'context/media/utils-many'
import PlannerAsset from 'planner/ui/planner-asset'
import { CatalogLabel, VideoCard } from 'styles/admin/main'
import { FormCard } from 'styles/app/components/card'
import { Breakpoint } from 'styles/app/system'
import { Media } from 'types/media'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import AssociationLabels from './association-labels'
import PreviewModal from './preview-modal'

interface MediaCardProps {
  enableEdit?: boolean
  media: Partial<Media>
  refetch?: () => Promise<unknown>
}

const MediaCard = ({
  enableEdit = false,
  media = {
    key: 'images/app/catalog_missing_file.png',
  },
  refetch,
}: MediaCardProps) => {
  const { display, isVideo, isOBJ, isGLB } = getMediaDisplay(media?.key ?? '')

  if (isOBJ || isGLB) {
    return (
      <>
        {enableEdit && (
          <div>
            <div>
              <CatalogLabel
                content={
                  <p>
                    {capsSnakeCaseToTitleCase(media?.category ?? '') +
                      `${
                        media.category === 'special'
                          ? ' - ' + media.data?.specialTag
                          : ''
                      }`}
                  </p>
                }
                icon={<FontAwesomeIcon icon={['fal', 'image']} />}
                styled={{ color: 'yellow' }}
              />
              <AssociationLabels media={media} />
            </div>
            <PreviewModal media={media} refetch={refetch} />
          </div>
        )}
        <CardFileContainer fluid key={media.key}>
          <Card.Content
            className="file"
            textAlign="center"
            style={{ width: '90%' }}
          >
            <PlannerAsset sceneActive={false} url={display} />
          </Card.Content>
        </CardFileContainer>
      </>
    )
  }

  return (
    <>
      <CardFileContainer fluid key={media.key}>
        <Card.Content className="file" textAlign="center">
          {isVideo ? (
            <VideoCard>
              <video controls playsInline src={display} />
            </VideoCard>
          ) : (
            <ImageCard image={display} />
          )}
        </Card.Content>
        {enableEdit && (
          <div className="hover">
            <div>
              <CatalogLabel
                content={
                  <p>
                    {capsSnakeCaseToTitleCase(media.category ?? '') +
                      `${
                        media.category === 'special'
                          ? ' - ' + media.data?.specialTag
                          : ''
                      }`}
                  </p>
                }
                icon={<FontAwesomeIcon icon={['fal', 'image']} />}
                styled={{ color: 'yellow' }}
              />
              <AssociationLabels media={media} />
            </div>
            <PreviewModal media={media} refetch={refetch} />
          </div>
        )}
      </CardFileContainer>
    </>
  )
}

export default MediaCard

const CardFileContainer = styled(FormCard)`
  &&& {
    border: none;
    border-radius: 1px;
    box-shadow: none;
    min-width: 200px !important;
    padding: 0;
    height: 100%;

    div.file {
      height: 100%;
      padding: 0;
      width: 100%;
    }

    :hover {
      div.hover {
        opacity: 1;
      }
    }

    div.hover {
      background: rgba(250, 250, 250, 0.9);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      left: 0;
      opacity: 0;
      padding: 16px;
      position: absolute;
      top: 0;
      transition: all 0.25s ease-in-out;
      width: 100%;
    }

    @media ${Breakpoint.onlyMobile} {
      padding: 0 !important;
    }
  }
`

const ImageCard = styled.div<{ image: string }>`
  background: url('${(props) => props.image}') no-repeat center;
  border-radius: 1px;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  height: 100%;
  min-height: 280px;
  width: 100%;
`
