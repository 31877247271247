import { useMemo } from 'react'

import moment from 'moment'

import { useConversationMany } from 'context/project/conversation/provider-many'
import { QueryFilters } from 'context/project/conversation/utils-many'
import { camelCaseToTitleCase } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IDynamicFields,
  IFormDateValue,
  IFormRadioValue,
} from '../filters-search/types'

interface IConversationFilterForm extends IDynamicFields {
  clientUser: IFormRadioValue
  dateFrom: IFormDateValue
}

const CONVERSATION_FILTERS: DynamicFilterGroup<IConversationFilterForm>[] = [
  {
    filters: [
      {
        label: 'Client User Assignation',
        filterId: 'clientUser',
        type: DynamicFilterTypes.RADIO,
        options: [
          {
            text: 'All',
            value: 'all',
          },
          {
            text: 'Not Assigned',
            value: 'notAssigned',
          },
          {
            text: 'Assigned',
            value: 'assigned',
          },
        ],
      },
    ],
  },
  {
    filters: [
      {
        label: 'Conversation Date Range',
        filterId: 'dateFrom',
        type: DynamicFilterTypes.DATE,
        max: new Date(),
      },
    ],
  },
]

export const useConversationFilters = () => {
  const { queryFilters, setQueryFilters } = useConversationMany()

  const defaultFilters = useMemo<Partial<IConversationFilterForm>>(() => {
    const filters: Partial<IConversationFilterForm> = {}

    if (!queryFilters.clientUser || queryFilters.clientUser === 'all') {
      filters.project = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: 'all',
          text: 'All',
        },
      }
    } else {
      filters.clientUser = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.clientUser,
          text: camelCaseToTitleCase(queryFilters.clientUser),
        },
      }
    }

    if (queryFilters.lastMessageReceivedAt) {
      filters.dateFrom = {
        type: DynamicFilterTypes.DATE,
        value: `${moment(queryFilters.lastMessageReceivedAt.gte).format(
          'YYYY-MM-DD',
        )} - ${moment(queryFilters.lastMessageReceivedAt.lte).format(
          'YYYY-MM-DD',
        )}`,
      }
    }

    return filters
  }, [])

  const handleApplyFilters = (data: Partial<IConversationFilterForm>) => {
    setQueryFilters((currQueryFilters) => {
      const newQueryFilters: QueryFilters = {
        take: queryFilters.take,
        search: currQueryFilters.search,
      }

      if (data.clientUser) {
        newQueryFilters.clientUser = data.clientUser.selection.value as
          | 'all'
          | 'notAssigned'
          | 'assigned'
      }

      if (data.dateFrom) {
        const [gte, lte] = data.dateFrom.value.split('-')
        newQueryFilters.lastMessageReceivedAt = {
          gte: moment(gte.trim()).startOf('day').toISOString(),
          lte: moment(lte.trim()).endOf('day').toISOString(),
        }
      }

      return newQueryFilters
    })
  }

  const handleApplySearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search.trim(),
    }))
  }

  return {
    filters: CONVERSATION_FILTERS,
    handleApplyFilters,
    defaultFilters,
    handleApplySearch,
    searchValue: queryFilters.search,
  }
}
