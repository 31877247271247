import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Button as SuiButton, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import ConfirmationModal from 'components/shared/confirmation-modal'
import useSupplierOrder from 'context/supplier-order/use'
import useSupplierOrderActionMutate from 'context/supplier-order/use-supplier-order-action-mutate'
import Button from 'design-system/components/button/button'
import {
  SupplierOrderAction,
  SupplierOrderActionType,
} from 'types/supplier-order-action'

import SupplierOrderActionCreateUpdateModal from './create-update-modal'

const SupplierOrderActionTable = ({
  refetchMany,
}: {
  refetchMany?: () => Promise<unknown>
}) => {
  const { supplierOrderActions } = useSupplierOrder()
  const { deleteSupplierOrderActionAndRefetch, loadingDelete } =
    useSupplierOrderActionMutate()

  const [supplierOrderActionEnableCreate, setSupplierOrderActionEnableCreate] =
    useState(false)
  const [supplierOrderActionToDelete, setSupplierOrderActionToDelete] =
    useState<SupplierOrderAction>()
  const [supplierOrderActionToEdit, setSupplierOrderActionToEdit] =
    useState<SupplierOrderAction>()

  return (
    <StyledTable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.HeaderCell>ACK</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {supplierOrderActions?.map((soa) => (
          <Table.Row
            key={soa.id}
            positive={
              soa.data?.type === SupplierOrderActionType.FORMAckApproved
            }
          >
            <Table.Cell width={4}>{soa.data?.type}</Table.Cell>
            <Table.Cell width={4}>
              {soa.data?.nobiliaAckNumber && soa.ackFile ? (
                <a
                  href={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    soa.ackFile.key,
                  )}`}
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                >
                  {soa.data?.nobiliaAckNumber}
                </a>
              ) : null}
              {soa.data?.nobiliaAckNumber && !soa.ackFile
                ? soa.data?.nobiliaAckNumber
                : ''}
            </Table.Cell>
            <Table.Cell width={4}>
              {moment(soa.data?.doneAt).format('MM/DD/YYYY hh:mm a')}
            </Table.Cell>
            <Table.Cell width={4}>
              <SuiButton
                icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
                onClick={() => setSupplierOrderActionToEdit(soa)}
              />
              <SuiButton
                icon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
                onClick={() => setSupplierOrderActionToDelete(soa)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell colSpan="4">
            <Button
              kind="solid"
              onClick={() => setSupplierOrderActionEnableCreate(true)}
              size="small"
              text="Add Action"
              style={{ float: 'right' }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
      <SupplierOrderActionCreateUpdateModal
        supplierOrderAction={supplierOrderActionToEdit}
        open={supplierOrderActionEnableCreate || !!supplierOrderActionToEdit}
        onClose={() => {
          setSupplierOrderActionEnableCreate(false)
          setSupplierOrderActionToEdit(undefined)
        }}
        refetchMany={refetchMany}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to delete this action?"
        loading={loadingDelete}
        onCancel={() => setSupplierOrderActionToDelete(undefined)}
        onConfirm={async () => {
          await deleteSupplierOrderActionAndRefetch({
            variables: {
              where: {
                id: supplierOrderActionToDelete?.id ?? '',
              },
            },
          })
          setSupplierOrderActionToDelete(undefined)
          refetchMany?.()
        }}
        open={!!supplierOrderActionToDelete}
      />
    </StyledTable>
  )
}

export default SupplierOrderActionTable

const StyledTable = styled(Table)`
  &&&&&&&&& {
    th {
      &:hover {
        background-color: #f6f5f4 !important;
      }
    }
  }
`
