import React from 'react'

import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/planner/system'
import { NobiliaProductClass } from 'types/nobilia-product-class'

interface PriceGroupTableProps {
  productClass: NobiliaProductClass
}

const PriceGroupTable = ({ productClass }: PriceGroupTableProps) => {
  return productClass.prices.priceFields.length > 1 ? (
    <PriceTable borderless>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="5">
            <p className="caption green">Base price per price group</p>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row className="border">
          {['1', '2', '3', '4', '5'].map((idx) => (
            <Table.Cell collapsing key={idx}>
              <p className="caption green">{idx}</p>
            </Table.Cell>
          ))}
        </Table.Row>
        <Table.Row>
          {['1', '2', '3', '4', '5'].map((idx) => {
            const index = productClass.prices.priceFields.indexOf(idx)
            return (
              <Table.Cell collapsing key={idx}>
                <p>
                  {index > -1 ? productClass.prices.prices[index] / 100 : '--'}
                </p>
              </Table.Cell>
            )
          })}
        </Table.Row>
        <Table.Row className="border green">
          {['6', '7', '8', '9', '10'].map((idx) => (
            <Table.Cell collapsing key={idx}>
              <p className="caption green">{idx}</p>
            </Table.Cell>
          ))}
        </Table.Row>
        <Table.Row className="green">
          {['6', '7', '8', '9', '10'].map((idx) => {
            const index = productClass.prices.priceFields.indexOf(idx)
            return (
              <Table.Cell collapsing key={idx}>
                <p>
                  {index > -1 ? productClass.prices.prices[index] / 100 : '--'}
                </p>
              </Table.Cell>
            )
          })}
        </Table.Row>
      </Table.Body>
    </PriceTable>
  ) : null
}

export default PriceGroupTable

const PriceTable = styled(Table)`
  &&&& {
    border: none;

    p.green {
      color: ${Colors.green800};
    }

    thead tr th {
      background: ${Colors.green50} !important;
      border: none;
      border-radius: 0 !important;
    }

    td {
      border: none;
      padding: 12px;
    }

    tr.border td {
      border-bottom: 1px solid ${Colors.green100};
      padding: 9px 12px;
    }

    tr.green {
      background: ${Colors.green50};
    }
  }
`
