import React, { useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { sortBy } from 'lodash'
import { Grid, Tab, Table } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import MessageTemplateBookModal from 'components/admin/message-template-book/create-update-modal'
import CreateUpdateTemplateModal from 'components/admin/message-template-book/message-template/create-update-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'
import { notifySuccess } from 'context/notifications/trigger'
import Button from 'design-system/components/button'
import RichText from 'design-system/components/rich-text'
import {
  DELETE_MESSAGE_TEMPLATE,
  DeleteMessageTemplatePayload,
  DeleteMessageTemplateVariables,
} from 'queries/message-template'
import {
  FETCH_MESSAGE_TEMPLATE_BOOKS,
  FetchMessageTemplateBooksPayload,
} from 'queries/message-template-book'
import { MessageTemplate, MessageTemplateBook } from 'types/message-template'

const MessageTemplateBooks = () => {
  const { isAdmin } = useAuth()
  const [showCreateTemplateBook, toggleCreateTemplateBook] =
    useState<boolean>(false)

  const {
    data: dataTemplateBooks,
    loading: loadingTemplateBooks,
    refetch,
  } = useQuery<FetchMessageTemplateBooksPayload>(FETCH_MESSAGE_TEMPLATE_BOOKS, {
    fetchPolicy: 'no-cache',
  })

  if (loadingTemplateBooks) return <Loader />
  const messageTemplateBooks = sortBy(
    dataTemplateBooks?.messageTemplateBooks || [],
    (p) => p.index,
  )

  const TemplateRow = ({
    template,
    refetch,
  }: {
    template: MessageTemplate
    refetch?: () => void
  }) => {
    const [showUpdateModal, toggleUpdateModal] = useState<boolean>(false)
    const [showConfirm, toggleConfirm] = useState<boolean>(false)

    const [deleteMessageTemplate] = useMutation<
      DeleteMessageTemplatePayload,
      DeleteMessageTemplateVariables
    >(DELETE_MESSAGE_TEMPLATE, {
      onCompleted: () => {
        notifySuccess('Message Template deleted')
        refetch?.()
      },
    })

    return (
      <Table.Row>
        <Table.Cell width={2}>{template.index}</Table.Cell>
        <Table.Cell width={5}>{template.title}</Table.Cell>
        <Table.Cell width={8}>
          <RichText content={template.body} />
        </Table.Cell>
        <Table.Cell collapsing>
          <Button
            kind="solid"
            disabled={!isAdmin}
            fontAwesomeIcon="edit"
            onClick={() => toggleUpdateModal(true)}
          />
          <Button
            color="blue"
            kind="solid"
            disabled={!isAdmin}
            fontAwesomeIcon="trash-alt"
            onClick={() => toggleConfirm(true)}
            style={{ marginLeft: '10px' }}
          />
          <CreateUpdateTemplateModal
            onCloseModal={() => toggleUpdateModal(false)}
            openModal={showUpdateModal}
            refetch={refetch as () => void}
            template={template}
          />
          <ConfirmationModal
            firstTitle="Are you sure you want to delete the message template?"
            onCancel={() => toggleConfirm(false)}
            onConfirm={async () => {
              await deleteMessageTemplate({
                variables: {
                  where: {
                    id: template.id ?? '',
                  },
                },
              })
              toggleConfirm(false)
            }}
            open={showConfirm}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  const TemplateBookTab = ({
    templateBook,
  }: {
    templateBook: MessageTemplateBook
  }) => {
    const [showTemplateModal, toggleTemplateModal] = useState<boolean>(false)
    const [updateModal, toggleUpdateModal] = useState<boolean>(false)

    return (
      <Tab.Pane as="div">
        <Grid style={{ marginTop: '10px' }}>
          <Grid.Row columns="equal">
            <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
              <h3>{templateBook.data.name}</h3>
              <Button
                color="blue"
                disabled={!isAdmin}
                fontAwesomeIcon="pencil"
                kind="solid"
                onClick={() => toggleUpdateModal(true)}
                style={{ marginLeft: '20px' }}
              />
              <MessageTemplateBookModal
                onCloseModal={() => toggleUpdateModal(false)}
                openModal={updateModal}
                template={templateBook}
                refetch={refetch}
              />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                fontAwesomeIcon="plus"
                kind="solid"
                onClick={() => toggleTemplateModal(true)}
                text="Add Message Template"
              />
              <CreateUpdateTemplateModal
                onCloseModal={() => toggleTemplateModal(false)}
                openModal={showTemplateModal}
                templateBookId={templateBook.id}
                refetch={refetch}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Index</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Body</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {templateBook.templates.map((t, k) => {
                    return (
                      <TemplateRow template={t} key={k} refetch={refetch} />
                    )
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    )
  }

  return (
    <>
      <Button
        color="gray"
        disabled={!isAdmin}
        fontAwesomeIcon="plus"
        kind="text"
        onClick={() => toggleCreateTemplateBook(true)}
        style={{ float: 'right' }}
        text="New Message Template Book"
      />
      <MessageTemplateBookModal
        onCloseModal={() => toggleCreateTemplateBook(false)}
        openModal={showCreateTemplateBook}
        refetch={refetch}
      />
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={messageTemplateBooks.map((m) => ({
          key: m.id,
          menuItem: `${m.index} ${m.data.name}`,
          render: () => <TemplateBookTab templateBook={m} />,
        }))}
      />
    </>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <MessageTemplateBooks />
    </AdminLayout>
  )
}
