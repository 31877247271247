import React, { useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEqual } from 'lodash'
import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import { DateInput } from 'semantic-ui-calendar-react'
import {
  Button,
  Card,
  Form,
  Grid,
  Message,
  Tab,
  Table,
} from 'semantic-ui-react'

import ColorPicker from 'components/admin/color-picker'
import SaveModal from 'components/admin/save-modal'
import SupplierOrdersPlanning from 'components/admin/supplier-order/index-many-planning'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useShipment from 'context/shipment/use'
import useShipmentMutate from 'context/shipment/use-mutate'
import useShipmentSupplierOrderMutate from 'context/shipment/use-supplier-order-mutate'
import {
  parseSupplierOrders,
  productionWeekOptions,
  PortOptions,
  ShipmentTypeOptions,
  WarehouseOptions,
} from 'context/shipment/utils'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import { ProjectFileType } from 'types/project-file'
import { Shipment, ShipmentData, ShipmentType } from 'types/shipment'
import { SupplierOrder } from 'types/supplier-order'
import * as viewUtils from 'views/utils'

import ShipmentChecklist from './checklist'
import ShipmentSummaryTable from './summary'

const ShipmentTab = ({
  onComplete,
  supplierOrdersInitial,
}: {
  onComplete: () => void
  supplierOrdersInitial: SupplierOrder[]
}) => {
  const { shipment } = useShipment()
  const {
    createShipmentAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateShipmentAndRefetch,
  } = useShipmentMutate()
  const initialState: NullableRecord<Shipment> = {
    id: shipment?.id || '',
    color: shipment?.color || '',
    confirmedAt: shipment?.confirmedAt || null,
    data: shipment?.data || {},
    reference: shipment?.reference || '',
    supplierOrders: shipment?.supplierOrders || supplierOrdersInitial || [],
  }
  const [state, setState] = useState<NullableRecord<Shipment>>(initialState)

  useEffect(() => {
    setState({
      id: shipment?.id || '',
      color: shipment?.color || '',
      confirmedAt: shipment?.confirmedAt || null,
      data: shipment?.data || {},
      reference: shipment?.reference || '',
      supplierOrders: shipment?.supplierOrders || supplierOrdersInitial || [],
    })
  }, [shipment, supplierOrdersInitial])

  const onSave = async () => {
    if (shipment) {
      await updateShipmentAndRefetch({
        variables: {
          data: {
            color: state.color,
            confirmedAt: state.confirmedAt,
            data: state.data,
            reference: state.reference,
          },
          where: { id: shipment.id as string },
        },
      })
    } else {
      await createShipmentAndRefetch({
        variables: {
          data: {
            color: state.color,
            confirmedAt: state.confirmedAt,
            data: state.data as ShipmentData,
            reference: state.reference,
            supplierOrders: {
              connect:
                viewUtils.mapOrEmptyArray(state.supplierOrders, (o) => ({
                  id: o.id as string,
                })) ?? [],
            },
          },
        },
      })
    }
    onComplete()
  }

  const { supplierOrders, volumeM3, weightKg } = useMemo(
    () => parseSupplierOrders(state),
    [state.supplierOrders],
  )

  return (
    <>
      <Tab
        panes={[
          {
            menuItem: 'Information',
            render: () => (
              <Tab.Pane>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Form
                        onSubmit={(e) => e.preventDefault()}
                        style={{ margin: '0' }}
                      >
                        <Form.Group widths="equal">
                          <Form.Field>
                            <p>Load</p>
                            {supplierOrders?.length} Orders ({volumeM3} m3,{' '}
                            {weightKg} kg)
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <p>Shipment Color</p>
                            <ColorPicker
                              color={state.color ?? undefined}
                              onChangeColor={(hex: string) => {
                                setState({
                                  ...state,
                                  color: hex,
                                })
                              }}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Shipment Confirmed</label>
                            <Message>
                              <Form.Checkbox
                                label="Shipment Confirmed"
                                checked={!!state.confirmedAt}
                                onChange={() => {
                                  setState({
                                    ...state,
                                    confirmedAt: state.confirmedAt
                                      ? null
                                      : new Date(),
                                  })
                                }}
                              />
                            </Message>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Input
                            label="Reference"
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) => {
                              setState({
                                ...state,
                                reference: value,
                              })
                            }}
                            value={state.reference || ''}
                          />
                          <Form.Dropdown
                            clearable
                            name="type"
                            label="Type"
                            onChange={(_, { value }) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  type: value as ShipmentType,
                                },
                              })
                            }}
                            options={ShipmentTypeOptions}
                            placeholder="Shipment Type"
                            selection
                            value={state.data?.type || undefined}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Input
                            label="Carrier"
                            onChange={(_, { value }) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  carrier: value,
                                },
                              })
                            }}
                            value={state.data?.carrier || ''}
                          />
                          <Form.Input
                            label="Shipment Number"
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  shipmentNumber: value,
                                },
                              })
                            }}
                            value={state.data?.shipmentNumber || ''}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Dropdown
                            label="Port"
                            onChange={(_, { value }) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  port: value as string,
                                },
                              })
                            }}
                            options={PortOptions}
                            placeholder="Port"
                            selection
                            value={state.data?.port || undefined}
                          />
                          <Form.Dropdown
                            label="Receiving Warehouse"
                            onChange={(_, { value }) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  receivingWarehouse: value as string,
                                },
                              })
                            }
                            options={WarehouseOptions}
                            placeholder="Receiving Warehouse"
                            search
                            selection
                            value={state.data?.receivingWarehouse || ''}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Input
                            label={
                              state.data?.flexportShipmentShareableUrl ? (
                                <a
                                  className="red"
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                  href={
                                    state.data?.flexportShipmentShareableUrl
                                  }
                                  target="_blank"
                                >
                                  Flexport Shipment ID
                                  <FontAwesomeIcon
                                    style={{ marginLeft: 5 }}
                                    icon={['fal', 'arrow-up-right-from-square']}
                                  />
                                </a>
                              ) : (
                                'Flexport Shipment ID'
                              )
                            }
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  flexportShipmentId: value,
                                },
                              })
                            }}
                            value={state.data?.flexportShipmentId || ''}
                          />
                          <Form.Input
                            label="Container Number"
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  containerNumber: value,
                                },
                              })
                            }}
                            value={state.data?.containerNumber || ''}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Message>
                            <Form.Checkbox
                              label="Shipment Blocked"
                              checked={!!state.data?.blockedAt}
                              onChange={(_, { checked }) => {
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    blockedAt: checked
                                      ? moment().format('MM/DD/YYYY')
                                      : null,
                                  },
                                })
                              }}
                            />
                          </Message>
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form
                        onSubmit={(e) => e.preventDefault()}
                        style={{ margin: '0' }}
                      >
                        <Form.Group>
                          <Form.Dropdown
                            label="Production Week #"
                            onChange={(_, { value }) => {
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  productionWeek: value as string,
                                  productionAtEstimated: moment(
                                    moment()
                                      .isoWeek(parseInt(value as string))
                                      .endOf('week'),
                                  ).format('MM/DD/YYYY'),
                                },
                              })
                            }}
                            options={productionWeekOptions()}
                            placeholder="Production Week"
                            selection
                            value={state.data?.productionWeek}
                            width={8}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Production Estimated</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    productionAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.productionAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Production Actual</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    productionAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.productionAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Loading Estimated</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    loadingAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.loadingAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Loading Actual</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    loadingAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.loadingAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Shipping Estimated</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    shippingAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.shippingAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Shipping Actual</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    shippingAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.shippingAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Arriving Estimated</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    arrivingAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.arrivingAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Arriving Actual</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    arrivingAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.arrivingAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Receiving At Warehouse Estimated</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    receivingAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.receivingAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Receiving At Warehouse Actual</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    receivingAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.receivingAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field
                            control={TextareaAutosize}
                            label="Notes"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  notes: e.target.value,
                                },
                              })
                            }
                            placeholder="Notes"
                            value={state.data?.notes || ''}
                          />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Nobilia Information',
            render: () => (
              <Tab.Pane>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column>
                      <Table celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell width={4}>Record ID</Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaRecordId}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>Name</Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaContainerName}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>
                              Method Of Dispatch
                            </Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaMethodOfDispatch}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>
                              Container Sequence
                            </Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaContainerSequence}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>Tracking Number</Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaTrackingNumber}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>Container Number</Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaContainerNumber}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>Port</Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaPort}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>Warehouse</Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaWarehouse}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>
                              Estimated Shipping
                            </Table.Cell>
                            <Table.Cell>
                              {
                                shipment?.data
                                  ?.nobiliaContainerShippingAtEstimated
                              }
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>Actual Shipping</Table.Cell>
                            <Table.Cell>
                              {shipment?.data?.nobiliaContainerShippingAtActual}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>
                              Estimated Warehouse
                            </Table.Cell>
                            <Table.Cell>
                              {
                                shipment?.data
                                  ?.nobiliaContainerReceivingAtEstimated
                              }
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={4}>Actual Warehouse</Table.Cell>
                            <Table.Cell>
                              {
                                shipment?.data
                                  ?.nobiliaContainerReceivingAtActual
                              }
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          },
          ...(shipment
            ? [
                {
                  menuItem: 'Orders',
                  render: () => (
                    <div style={{ padding: '30px' }}>
                      <SupplierOrderManyProvider
                        defaultFilters={{
                          includeThirdParty: true,
                          shipment: shipment.id,
                          sortBy: 'derivedStatus',
                          sortDirection: 'ascending',
                          take: 100,
                        }}
                        skipLoader
                      >
                        <SupplierOrdersPlanning />
                      </SupplierOrderManyProvider>
                    </div>
                  ),
                },
              ]
            : []),
          ...(shipment
            ? [
                {
                  menuItem: 'Delivery Notes',
                  render: () => (
                    <Tab.Pane>
                      <Grid>
                        <Grid.Row columns={4}>
                          {supplierOrders
                            .filter((so) => so.project)
                            .map((so, i) => (
                              <SupplierOrderDeliveryNoteForm
                                i={i}
                                key={so.id}
                                so={so}
                              />
                            ))}
                        </Grid.Row>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
              ]
            : []),
          ...(shipment
            ? [
                {
                  menuItem: 'Last Mile',
                  render: () => (
                    <Tab.Pane>
                      <Grid padded>
                        <Grid.Row>
                          <Grid.Column>
                            <Grid>
                              <Grid.Row columns={3}>
                                {supplierOrders
                                  .filter((so) => so.project)
                                  .map((so, i) => (
                                    <SupplierOrderLastMileForm
                                      key={so.id}
                                      i={i}
                                      so={so}
                                    />
                                  ))}
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
              ]
            : []),
          ...(shipment
            ? [
                {
                  menuItem: 'Summary',
                  render: () => (
                    <Tab.Pane>
                      <Grid padded>
                        <Grid.Row centered>
                          <Grid.Column width={10}>
                            <ShipmentSummaryTable shipment={shipment} />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
              ]
            : []),
          ...(shipment
            ? [
                {
                  menuItem: 'Checklist',
                  render: () => (
                    <Tab.Pane>
                      <Grid padded>
                        <Grid.Row centered>
                          <Grid.Column width={16}>
                            <ShipmentChecklist />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
              ]
            : []),
        ]}
      />
      <SaveModal
        currentState={state}
        initialState={initialState}
        loadingSave={loadingCreate || loadingUpdate}
        onDiscard={onComplete}
        onSave={onSave}
      />
    </>
  )
}

const SupplierOrderLastMileForm = ({
  so,
  i,
}: {
  so: SupplierOrder
  i: number
}) => {
  const [state, setState] = useState(so)
  const { loadingUpdate, updateSupplierOrderAndRefetch } =
    useShipmentSupplierOrderMutate()

  const isDirty = !isEqual(
    {
      readyForDispatchAtEstimated: state.data?.readyForDispatchAtEstimated,
      readyForDispatchAtActual: state.data?.readyForDispatchAtActual,
      inStorageUntil: state.data?.inStorageUntil,
      customerContactAtEstimated: state.data?.customerContactAtEstimated,
      customerContactAtActual: state.data?.customerContactAtActual,
      deliveryAtEstimated: state.data?.deliveryAtEstimated,
      deliveryAtActual: state.data?.deliveryAtActual,
    },
    {
      readyForDispatchAtEstimated: so.data.readyForDispatchAtEstimated,
      readyForDispatchAtActual: so.data.readyForDispatchAtActual,
      inStorageUntil: so.data.inStorageUntil,
      customerContactAtEstimated: so.data.customerContactAtEstimated,
      customerContactAtActual: so.data.customerContactAtActual,
      deliveryAtEstimated: so.data.deliveryAtEstimated,
      deliveryAtActual: so.data.deliveryAtActual,
    },
  )

  return (
    <Grid.Column style={{ marginBottom: '20px' }}>
      <Card fluid>
        <Card.Content>
          <h4>
            #{i + 1} - {state.nobiliaOrderRef}, {state.nobiliaAckNumber}
          </h4>
          <Form
            onSubmit={(e) => e.preventDefault()}
            style={{ marginBottom: 0 }}
          >
            <Form.Field width={8}>
              <label>In Storage Until</label>
              <DateInput
                closable
                dateFormat={'MM/DD/YYYY'}
                duration={0}
                initialDate={moment().format('MM/DD/YYYY')}
                onChange={(
                  _: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: { value: string },
                ) =>
                  setState({
                    ...state,
                    data: {
                      ...state.data,
                      inStorageUntil: value,
                    },
                  })
                }
                value={state.data?.inStorageUntil || ''}
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Ready For Dispatch Estimated</label>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  onChange={(
                    _: React.SyntheticEvent<HTMLElement, Event>,
                    { value }: { value: string },
                  ) =>
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        readyForDispatchAtEstimated: value,
                      },
                    })
                  }
                  value={state.data?.readyForDispatchAtEstimated || ''}
                />
              </Form.Field>
              <Form.Field>
                <label>Customer Contact Actual</label>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  onChange={(
                    _: React.SyntheticEvent<HTMLElement, Event>,
                    { value }: { value: string },
                  ) =>
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        readyForDispatchAtActual: value,
                      },
                    })
                  }
                  value={state.data?.readyForDispatchAtActual || ''}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Customer Contact Estimated</label>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  onChange={(
                    _: React.SyntheticEvent<HTMLElement, Event>,
                    { value }: { value: string },
                  ) =>
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        customerContactAtEstimated: value,
                      },
                    })
                  }
                  value={state.data?.customerContactAtEstimated || ''}
                />
              </Form.Field>
              <Form.Field>
                <label>Customer Contact Actual</label>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  onChange={(
                    _: React.SyntheticEvent<HTMLElement, Event>,
                    { value }: { value: string },
                  ) =>
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        customerContactAtActual: value,
                      },
                    })
                  }
                  value={state.data?.customerContactAtActual || ''}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Delivery Date (From Delivery Card)</label>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  onChange={() => {}}
                  value={
                    state.delivery?.date
                      ? moment(state.delivery.date).format('MM/DD/YYYY')
                      : ''
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Delivery Actual</label>
                <DateInput
                  closable
                  dateFormat={'MM/DD/YYYY'}
                  duration={0}
                  initialDate={moment().format('MM/DD/YYYY')}
                  onChange={(
                    _: React.SyntheticEvent<HTMLElement, Event>,
                    { value }: { value: string },
                  ) =>
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        deliveryAtActual: value,
                      },
                    })
                  }
                  value={state.data?.deliveryAtActual || ''}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Card.Content>
        <Card.Content extra>
          <Button
            content="Save"
            disabled={!isDirty}
            floated="right"
            loading={loadingUpdate}
            onClick={() => {
              updateSupplierOrderAndRefetch({
                variables: {
                  data: {
                    data: state.data,
                  },
                  where: {
                    id: so.id as string,
                  },
                },
              })
            }}
            primary
          />
        </Card.Content>
      </Card>
    </Grid.Column>
  )
}

const SupplierOrderDeliveryNoteForm = ({
  i,
  so,
}: {
  i: number
  so: SupplierOrder
}) => {
  const [state, setState] = useState(so)
  const { refetch } = useShipment()
  const { loadingUpdate, updateSupplierOrderAndRefetch } =
    useShipmentSupplierOrderMutate()

  const onSave = async () => {
    await updateSupplierOrderAndRefetch({
      variables: {
        data: {
          data: state.data,
        },
        where: {
          id: state.id as string,
        },
      },
    })
  }

  const isDirty = !isEqual(
    {
      numPalletes: state.data?.numPalletes,
      numPackages: state.data?.numPackages,
    },
    {
      numPalletes: so.data.numPalletes,
      numPackages: so.data.numPackages,
    },
  )

  return (
    <Grid.Column key={i} style={{ marginBottom: '20px' }}>
      <Card fluid>
        <Card.Content>
          <h4>
            #{i + 1} - {state.nobiliaOrderRef}, {state.nobiliaAckNumber}
          </h4>
          <Grid>
            <ProjectFileUploads
              columnsProps={{
                computer: 16,
                widescreen: 16,
              }}
              onlyOneFile
              projectFiles={so?.deliveryNote ? [so.deliveryNote] : []}
              projectFileType={ProjectFileType.DELIVERY_NOTE}
              projectId={so.project.id}
              refetch={refetch}
              supplierOrderDeliveryNoteId={so.id}
            />
          </Grid>
          <Form
            onSubmit={(e) => e.preventDefault()}
            style={{ marginTop: '20px', marginBottom: '0' }}
          >
            <Form.Input
              label="# of Palletes"
              type="number"
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    numPalletes: parseFloat(value),
                  },
                })
              }
              value={state.data?.numPalletes || ''}
            />
            <Form.Input
              label="# of Boxes"
              type="number"
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    numPackages: parseFloat(value),
                  },
                })
              }
              value={state.data?.numPackages || ''}
            />
          </Form>
        </Card.Content>
        <Card.Content extra>
          <Button
            content="Save"
            disabled={!isDirty}
            loading={loadingUpdate}
            floated="right"
            onClick={onSave}
            primary
          />
        </Card.Content>
      </Card>
    </Grid.Column>
  )
}

export default ShipmentTab
