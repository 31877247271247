import React, { useState } from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import FiltersAndSearch from 'components/admin/filters-search'
import { BackToTop } from 'components/shared/load-buttons'
import useRenderPropMany from 'context/render-prop/use-many'
import Button from 'design-system/components/button'

import RenderPropCreateUpdateModal from './create-update-modal'
import RenderPropsTable from './table'
import useRenderPropFiltersSearch from './use-filters-search'

const RenderProps = ({ table }: { table?: React.ReactNode }) => {
  const [showModal, setShowModal] = useState(false)
  const { count, renderProps, fetchMore, loading, loadingMore, refetch } =
    useRenderPropMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useRenderPropFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${renderProps?.length} of ${count}`}{' '}
            Render Props
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <RenderPropCreateUpdateModal
            onClose={() => setShowModal(false)}
            onSave={() => {
              refetch()
              setShowModal(false)
            }}
            openModal={showModal}
            trigger={
              <Button
                color="gray"
                kind="outlined"
                onClick={() => setShowModal(true)}
                text="Create New"
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table || <RenderPropsTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && renderProps?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default RenderProps
