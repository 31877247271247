import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomElementOptionProvider from 'context/room-element-option/provider'

interface RoomElementOptionIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    room_element_option_id: string
  }> {
  children: React.ReactNode
}

const RoomElementOptionIndex = (props: RoomElementOptionIndexProps) => {
  return (
    <RoomElementOptionProvider
      catalog={props.catalog_id}
      room_element_option_id={props.room_element_option_id}
    >
      {props.children}
    </RoomElementOptionProvider>
  )
}

export default RoomElementOptionIndex
