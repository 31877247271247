import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  UpdateRoomPayload,
  UpdateRoomVariables,
  UPDATE_ROOM,
} from 'queries/room'

import { RoomContext } from './room-provider'

const useRoom = () => {
  const {
    defaultArcRotateCamera,
    defaultTargetCamera,
    defaultUniversalCamera,
    designs,
    dispatch,
    floorRoomElementClass,
    refetch,
    refetchDesigns,
    room,
    state,
    wallRoomElementClass,
  } = useContext(RoomContext)

  const [updateRoomRaw] = useMutation<UpdateRoomPayload, UpdateRoomVariables>(
    UPDATE_ROOM,
    {
      onCompleted: refetch,
    },
  )

  const updateRoom = async (variables: UpdateRoomVariables) => {
    await updateRoomRaw({
      variables,
    })
  }

  return {
    defaultArcRotateCamera,
    defaultTargetCamera,
    defaultUniversalCamera,
    designs,
    dispatch,
    floorRoomElementClass,
    refetch,
    refetchDesigns,
    room,
    ...state,
    updateRoom,
    wallRoomElementClass,
  }
}

export default useRoom
