import React from 'react'

import { Form, Grid } from 'semantic-ui-react'

import CurrencyInput from 'components/shared/currency-input'
import { formatDollars } from 'context/dollar-utils'
import { FORM_CABINETRY_DISCOUNT_OPTIONS } from 'context/project/utils-discounts'
import useRoom from 'context/room/use'
import StyledAccordion from 'design-system/components/accordion'
import CheckHighlighted from 'design-system/components/check-highlighted'
import { StyledForm } from 'styles/admin/main'
import { Design, DESIGN_TYPES } from 'types/design'
import * as viewUtils from 'views/utils'

import { getNameParts } from './utils'

const DesignSettings = ({
  setState,
  state,
  shouldUpdateBaseCabinetry,
  toggleShouldUpdateBaseCabinetry,
}: {
  setState: (d: Partial<Design>) => void
  state: Partial<Design>
  shouldUpdateBaseCabinetry: boolean
  toggleShouldUpdateBaseCabinetry: (d: boolean) => void
}) => {
  const { room } = useRoom()

  const onChangePrice = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      metadata: {
        ...state.metadata,
        price: Math.round(Number(value.replace(/[, $]+/g, '')) * 100),
      },
    })
  }
  const nameParts = getNameParts(
    state.name as string,
    room?.designs.length ?? 0,
  )

  return (
    <StyledAccordion titleText="Design Settings">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StyledForm onSubmit={(e: any) => e.preventDefault()}>
              <Form.Group>
                <Form.Dropdown
                  label="Type"
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      name: value as string,
                    })
                  }}
                  options={Object.entries(DESIGN_TYPES).map(([key, value]) => ({
                    key: key,
                    text: value,
                    value: value,
                    disabled:
                      value === DESIGN_TYPES.ADD_ON_DESIGN &&
                      !room?.designs.length,
                  }))}
                  required
                  selection
                  value={nameParts.type}
                  width={4}
                />
                <Form.Input
                  disabled={nameParts.type !== 'Revision'}
                  label="Number"
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      name: `Revision-${value}`,
                    })
                  }}
                  required
                  type="number"
                  value={
                    getNameParts(
                      state.name as string,
                      room?.designs.length ?? 0,
                    ).number
                  }
                  width={2}
                />
                <Form.Dropdown
                  error={!state.metadata?.catalog}
                  label="Catalog"
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      metadata: {
                        ...state.metadata,
                        catalog: value as string,
                      },
                    })
                  }}
                  options={viewUtils.DESIGN_CATALOG_OPTIONS}
                  placeholder="Catalog"
                  required
                  selection
                  value={state.metadata?.catalog}
                  width={5}
                />
                <Form.Field width={5}>
                  <label>
                    Price <span className="red">*</span>
                  </label>
                  <CurrencyInput
                    defaultValue={
                      state.metadata?.price
                        ? formatDollars(state.metadata.price)
                        : formatDollars(0)
                    }
                    inputMode="numeric"
                    maskOptions={{}}
                    onChange={onChangePrice}
                  />
                </Form.Field>
              </Form.Group>
              {nameParts.type === 'Other' && (
                <Form.Group>
                  <Form.Input
                    width={4}
                    required
                    label="Name"
                    onChange={(_, { value }) => {
                      setState({
                        ...state,
                        name: value,
                      })
                    }}
                    value={nameParts.other}
                  />
                </Form.Group>
              )}
              {nameParts.type === DESIGN_TYPES.ADD_ON_DESIGN && (
                <Form.Group>
                  <Form.Input
                    width={4}
                    label="Additional Description"
                    onChange={(_, { value }) => {
                      setState({
                        ...state,
                        name: `${DESIGN_TYPES.ADD_ON_DESIGN}-${value}`,
                      })
                    }}
                    value={nameParts.additionalDescription}
                  />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Dropdown
                  label="FORM Cabinetry Discount"
                  placeholder="FORM Cabinetry Discount"
                  selection
                  options={FORM_CABINETRY_DISCOUNT_OPTIONS}
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      metadata: {
                        ...state.metadata,
                        formDiscount: parseFloat(String(value)),
                      },
                    })
                  }}
                  value={(state.metadata?.formDiscount || 0).toString()}
                  width={6}
                />
                <Form.Field width={5}>
                  <CheckHighlighted
                    color="red"
                    checked={shouldUpdateBaseCabinetry}
                    text="Update discount banner"
                    onChange={() =>
                      toggleShouldUpdateBaseCabinetry(
                        !shouldUpdateBaseCabinetry,
                      )
                    }
                  />
                </Form.Field>
              </Form.Group>
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledAccordion>
  )
}

export default DesignSettings
