import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Designs from 'components/admin/design/index-many'
import DesignsReviewsTable from 'components/admin/design/table-reviews'
import { FiltersVersion } from 'components/admin/design/use-filters-search'
import useTradeDesigner from 'context/dashboard/trade-designer/use'
import DesignManyProvider from 'context/design/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import TradeDesignerLayout from './layout'

const TradeDesignerDesignsAwaitingSignoff = (_: RouteComponentProps) => {
  const { userId, userName } = useTradeDesigner()

  return (
    <TradeDesignerLayout>
      <DesignManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          assignedToRole: ProjectStaffRole.TECH_DESIGNER,
          reviewStatus: 'awaitingSignoff',
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          take: 30,
        }}
        skipLoader
      >
        <Designs
          filtersType={FiltersVersion.REVIEW}
          table={<DesignsReviewsTable />}
        />
      </DesignManyProvider>
    </TradeDesignerLayout>
  )
}

export default TradeDesignerDesignsAwaitingSignoff
