import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Card, Divider, Form, Grid, Table } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import AddressFormat from 'components/shared/address-format'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import ProjectProvider from 'context/project/provider'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import useProjectMutateNoContext from 'context/project/use-mutate-no-context'
import { parseFiles } from 'context/project-file/utils-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { BuildZoomStatus } from 'types/project'
import { BuildZoomData } from 'types/utils'
import * as viewUtils from 'views/utils'

const questions = (zoomAnswers: BuildZoomData) => {
  return [
    {
      question: ' 1. Is the kitchen renovation part of a larger project?',
      answer: `${zoomAnswers.largeProject === 'yes' ? 'Yes' : 'No'}`,
    },
    {
      question:
        '  2. Are you looking for help with the whole project or just the cabinetry install?',
      answer: `${
        zoomAnswers.wholeProject === 'cabinetry_install'
          ? 'Cabinetry install'
          : zoomAnswers.wholeProject === 'cabinetry_install'
          ? 'Part of the project'
          : 'Whole project'
      }`,
    },
    {
      question: '3. Are you removing or installing new walls?',
      answer: `${zoomAnswers.newLayout === 'yes' ? 'Yes' : 'No'}`,
    },
    {
      question:
        ' 4. Are you planning to move your sink(s) or any major appliance(s)?',
      answer: `${zoomAnswers.needsPlumber === 'yes' ? 'Yes' : 'No'}`,
    },
    {
      question: '5. Are you replacing the existing flooring?',
      answer: `${zoomAnswers.needsElectrician === 'yes' ? 'Yes' : 'No'}`,
    },
    {
      question: '6. Are you adding more appliances to your existing kitchen?',
      answer: `${zoomAnswers.newFlooring === 'yes' ? 'Yes' : 'No'}`,
    },
    {
      question:
        '7. Do you need help ordering your new countertops and backsplash?',
      answer: `${zoomAnswers.noSupplier === 'yes' ? 'Yes' : 'No'}`,
    },
    {
      question: 'Additional Comments:',
      answer: zoomAnswers.extraInfo,
    },
  ]
}

export const renderBuildZoomBlock = (zoomAnswers: BuildZoomData) => (
  <>
    {questions(zoomAnswers).map((q, idx) => (
      <Card.Content key={idx} style={{ padding: '12px 18px' }}>
        <p style={{ margin: '0' }}>
          <b>{q.question}</b>
        </p>
        <p style={{ paddingLeft: '16px' }}>{q.answer}</p>
      </Card.Content>
    ))}
  </>
)

const Module = () => {
  const {
    meetingBuildZoomOccurred,
    meetingBuildZoomScheduled,
    primaryOwner,
    project,
    refetch,
  } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()
  const { updateProject } = useProjectMutateNoContext()
  const { buildZoomContractorName, buildZoomData, buildZoomStatus } =
    project.data
  const [status, setStatus] = useState(buildZoomStatus)
  const [contractor, setContractor] = useState<string>(
    buildZoomContractorName || '',
  )
  const [showStatusModal, toggleStatusModal] = useState<boolean>(false)

  const meeting = meetingBuildZoomScheduled || meetingBuildZoomOccurred

  const buildZoomPhotosChosen = buildZoomData?.photos || []
  const buildZoomPhotosFound = project.files.filter((pf) =>
    buildZoomPhotosChosen.includes(pf.key),
  )
  const { buildzoomPhotos, floorPlans, roomPhotos } =
    buildZoomPhotosFound.length
      ? parseFiles(buildZoomPhotosFound)
      : parseFiles(project.files)
  const files = [...buildzoomPhotos, ...floorPlans, ...roomPhotos]

  return (
    <Grid>
      <SectionTitle title="Project for BuildZoom" />
      <Grid.Row columns={2}>
        <Grid.Column>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <p>Primary Client</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{viewUtils.getNameForUser(primaryOwner)}</p>
                  <p>
                    <FontAwesomeIcon icon={['fal', 'phone']} />{' '}
                    {primaryOwner.phone}
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>Project Address</p>
                </Table.Cell>
                <Table.Cell>
                  <AddressFormat address={project.projectAddress} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>Consultation</p>
                </Table.Cell>
                <Table.Cell>
                  {meeting?.startTime &&
                    moment(meeting.startTime).format('MM/DD/YYYY hh:mm a')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>Status</p>
                </Table.Cell>
                <Table.Cell>
                  {viewUtils.capsSnakeCaseToTitleCase(status)}
                  {status === BuildZoomStatus.CONTRACTOR_INTRODUCED &&
                    buildZoomContractorName && (
                      <p>
                        <span className="small gray">Contractor’s Name</span>
                        <br />
                        {buildZoomContractorName}
                      </p>
                    )}
                </Table.Cell>
                <Table.Cell>
                  <Modal
                    onClose={() => toggleStatusModal(false)}
                    open={showStatusModal}
                    size="mini"
                    title="Change status"
                    hideCancelButton
                    trigger={
                      <Button
                        onClick={() => toggleStatusModal(true)}
                        text="Change"
                        kind="solid"
                      />
                    }
                    contentStyle={{ overflow: 'initial' }}
                    saveButton={{
                      disabled:
                        status === BuildZoomStatus.CONTRACTOR_INTRODUCED &&
                        !contractor,
                      onClick: async () => {
                        await updateProjectAndRefetch({
                          variables: {
                            data: {
                              data: {
                                buildZoomStatus: status,
                                ...(contractor && {
                                  buildZoomContractorName: contractor,
                                }),
                              },
                            },
                            where: {
                              id: project.id,
                            },
                          },
                        })
                        toggleStatusModal(false)
                      },
                    }}
                  >
                    <StyledForm styled={{ grayInputs: true }}>
                      <Form.Dropdown
                        onChange={(_, { value }) => {
                          setStatus(value as BuildZoomStatus)
                        }}
                        options={[
                          {
                            key: '1',
                            text: 'Call Scheduled',
                            value: BuildZoomStatus.CALL_SCHEDULED,
                          },
                          {
                            key: '2',
                            text: 'Call Re-scheduled',
                            value: BuildZoomStatus.CALL_RESCHEDULED,
                          },
                          {
                            key: '3',
                            text: 'Call Canceled',
                            value: BuildZoomStatus.CALL_CANCELED,
                          },
                          {
                            key: '4',
                            text: 'Call Completed',
                            value: BuildZoomStatus.CALL_COMPLETED,
                          },
                          {
                            key: '5',
                            text: 'Contractor Introduced',
                            value: BuildZoomStatus.CONTRACTOR_INTRODUCED,
                          },
                          {
                            key: '6',
                            text: 'Not Interested',
                            value: BuildZoomStatus.NOT_INTERESTED,
                          },
                          {
                            key: '7',
                            text: 'Completed 2020 Flow',
                            value: BuildZoomStatus.COMPLETED_2020_FLOW,
                          },
                        ]}
                        scrolling
                        selection
                        value={status}
                      />
                      {status === BuildZoomStatus.CONTRACTOR_INTRODUCED && (
                        <Form.Input
                          label="Contractor’s Name"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setContractor(e.target.value)
                          }
                          placeholder="Contractor’s Name"
                          required
                          value={contractor}
                        />
                      )}
                    </StyledForm>
                  </Modal>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Card fluid>
            <Card.Content>
              <h4>Scope of work</h4>
            </Card.Content>
            {buildZoomData && renderBuildZoomBlock(buildZoomData)}{' '}
          </Card>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <ProjectFileUploads
        includeDownloadAndCarousel
        noUpload
        onDownload={async () => {
          await updateProject({
            variables: {
              data: {
                data: {
                  buildZoomPhotosDownloadedAt: new Date(),
                },
              },
              where: {
                id: project.id,
              },
            },
          }).then(({ data }) => {
            window.open(data?.updateOneProject.data.buildZoomZipUrl)
          })
        }}
        projectFiles={files}
        projectId={project.id}
        refetch={refetch}
      />
    </Grid>
  )
}

export default (props: RouteComponentProps<{ project_id: string }>) => {
  return (
    <AdminLayout>
      <ProjectProvider project_id={props.project_id}>
        <Module />
      </ProjectProvider>
    </AdminLayout>
  )
}
