import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import CompanyIndexMany from 'components/admin/company/index-many'
import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import CompanyManyProvider from 'context/company/provider-many'

const Companies = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="Companies" />
        <Grid.Row>
          <Grid.Column>
            <CompanyManyProvider
              defaultFilters={{
                sortBy: 'name',
                sortDirection: 'ascending',
                take: 40,
              }}
              skipLoader
            >
              <CompanyIndexMany />
            </CompanyManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Companies
