import React from 'react'

import { Drawer } from 'design-system/components/drawer'
import { ProjectEvent } from 'types/project-event'

import { EventCard } from './event-card'

type EventDrawerProps = {
  events: ProjectEvent[]
  formUserId: string
  title: string
  refetch: () => Promise<unknown>
  defaultOpen: boolean
  showProjectData?: boolean
}

export const EventDrawer = ({
  events,
  formUserId,
  refetch,
  title,
  defaultOpen,
  showProjectData,
}: EventDrawerProps) => {
  return (
    <Drawer
      allowOutsideClick
      defaultOpen={defaultOpen}
      title={title}
      content={
        <>
          {events.map((event) => (
            <EventCard
              key={event.id}
              formUserId={formUserId}
              event={event}
              refetch={refetch}
              showProjectData={showProjectData}
            />
          ))}
        </>
      }
    />
  )
}
