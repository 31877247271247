import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Button, Image, Table } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Tooltip from 'components/admin/tooltip'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useAuth from 'context/auth/use'
import { getReferenceImage } from 'context/media/utils-many'
import useRoom from 'context/room/use'
import useRoomGenericProductMutate from 'context/room/use-generic-product-mutate'
import { InfoPopup } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { GenericProductGraphQL } from 'types/generic-product'
import { StatusMode } from 'types/utils'

import CreateUpdateApplianceModal from './create-update-modal'

const GenericProductsTable = () => {
  const { isAdmin } = useAuth()
  const { appliancesUnarchived, appliancesArchived, appliancesMode } = useRoom()
  const { deleteGenericProductAndRefetch, updateGenericProductAndRefetch } =
    useRoomGenericProductMutate()

  const [showArchived, toggleArchived] = useState<boolean>(false)
  const [applianceToRestore, setApplianceToRestore] =
    useState<GenericProductGraphQL>()
  const [applianceToDelete, setApplianceToDelete] =
    useState<GenericProductGraphQL>()
  const [applianceToEdit, setApplianceToEdit] =
    useState<GenericProductGraphQL>()
  const [applianceToConfirm, setApplianceToConfirm] =
    useState<GenericProductGraphQL>()

  return (
    <StyledTable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Appliance</Table.HeaderCell>
          <Table.HeaderCell width={1}>Size</Table.HeaderCell>
          <Table.HeaderCell width={2}>Model</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width={1}>
            Product Link
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>Spec Link</Table.HeaderCell>
          <Table.HeaderCell width={4}>
            Customer Updates{' '}
            <span style={{ fontSize: '20px' }}>
              <Tooltip type={'Customer updates'} popupPosition="left center" />
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            Actions{' '}
            <span style={{ fontSize: '20px' }}>
              <Tooltip type={'Actions'} popupPosition="left center" />
            </span>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {appliancesUnarchived?.map((gp) => {
          const { productClass, data } = gp

          // link to product
          const linkToProduct = processLink(data?.linkToProduct ?? '')

          // link to product spec
          const linkToProductSpec = processLink(data?.linkToProductSpec ?? '')

          // deletion requested at
          const deletionRequestedByCustomerAt =
            !data?.confirmedAt && data?.deletionRequestedByCustomerAt

          // update by customer at
          const updatedByCustomerAt =
            !data?.confirmedAt &&
            !data?.deletionRequestedByCustomerAt &&
            data?.lastUpdatedByCustomerAt &&
            !data?.lastAcknowledgedAt
              ? data?.lastUpdatedByCustomerAt
              : undefined

          // confirmedAt
          const confirmedAt = data?.confirmedAt

          return (
            <Table.Row key={gp.id}>
              <Table.Cell width={2}>
                <div className="appliance-cell">
                  <Image
                    src={getReferenceImage(productClass.media ?? [])}
                    rounded
                    size="mini"
                    style={{ marginRight: '8px' }}
                  />
                  {productClass.metadata?.name}
                </div>
              </Table.Cell>
              <Table.Cell width={1}>
                {data?.size?.endsWith('"') && data?.size}
              </Table.Cell>
              <Table.Cell width={2}>{data?.productDetails}</Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                {linkToProduct && (
                  <LinkButton
                    icon={
                      <FontAwesomeIcon
                        icon={['fal', 'arrow-up-right-from-square']}
                      />
                    }
                    onClick={() => window.open(linkToProduct)}
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                {linkToProductSpec && (
                  <LinkButton
                    icon={
                      <FontAwesomeIcon
                        icon={['fal', 'arrow-up-right-from-square']}
                      />
                    }
                    onClick={() => window.open(linkToProductSpec)}
                  />
                )}
              </Table.Cell>
              <Table.Cell width={4}>
                {deletionRequestedByCustomerAt && (
                  <UpdatesDiv styled={{ delete: true }}>
                    <div>
                      <div className="text">Delete request</div>
                      <div className="date">
                        {moment(deletionRequestedByCustomerAt).format(
                          'MM/DD/yyyy',
                        )}
                      </div>
                    </div>
                    <Button
                      content="Cancel"
                      onClick={() => setApplianceToRestore(gp)}
                    />
                  </UpdatesDiv>
                )}
                {updatedByCustomerAt && (
                  <UpdatesDiv styled={{ update: true }}>
                    <div>
                      <div className="text">Updated by customer</div>
                      <div className="date">
                        {moment(updatedByCustomerAt).format('MM/DD/yyyy')}
                      </div>
                    </div>
                    <Button
                      content="Acknowledge"
                      onClick={async () =>
                        await updateGenericProductAndRefetch({
                          variables: {
                            data: {
                              data: {
                                ...gp.data,
                                lastAcknowledgedAt: new Date(),
                              },
                            },
                            where: {
                              id: gp.id ?? '',
                            },
                          },
                        })
                      }
                      primary
                    />
                  </UpdatesDiv>
                )}
                {confirmedAt && (
                  <UpdatesDiv styled={{ confirm: true }}>
                    <FontAwesomeIcon
                      icon={['fal', 'check']}
                      style={{
                        color: Colors.greenBright,
                        marginRight: '16px',
                      }}
                    />
                    <div>
                      <div className="text">Confirmed</div>
                      <div className="date">
                        {moment(confirmedAt).format('MM/DD/YYYY')}
                      </div>
                    </div>
                  </UpdatesDiv>
                )}
                {!confirmedAt &&
                  !deletionRequestedByCustomerAt &&
                  !updatedByCustomerAt &&
                  [StatusMode.Confirmation, StatusMode.Confirmed].includes(
                    appliancesMode as StatusMode,
                  ) && (
                    <UpdatesDiv styled={{ update: true }}>
                      <div>
                        <div className="text">Awaiting confirmation</div>
                      </div>
                      <Button
                        content="Confirm"
                        onClick={() => setApplianceToConfirm(gp)}
                      />
                    </UpdatesDiv>
                  )}
              </Table.Cell>
              <Table.Cell width={2} textAlign="center">
                <InfoPopup
                  content={<p>Delete</p>}
                  trigger={
                    <Button
                      disabled={!isAdmin}
                      icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
                      style={{ margin: '5px' }}
                      onClick={() => setApplianceToDelete(gp)}
                    />
                  }
                />
                <InfoPopup
                  content={<p>Archive</p>}
                  trigger={
                    <Button
                      icon={<FontAwesomeIcon icon={['fal', 'archive']} />}
                      onClick={async () =>
                        await updateGenericProductAndRefetch({
                          variables: {
                            data: {
                              data: {
                                ...gp.data,
                                archivedAt: new Date(),
                              },
                            },
                            where: {
                              id: gp.id ?? '',
                            },
                          },
                        })
                      }
                      style={{ margin: '5px' }}
                    />
                  }
                />
                <InfoPopup
                  content={<p>Edit</p>}
                  trigger={
                    <Button
                      icon={<FontAwesomeIcon icon={['fal', 'pencil']} />}
                      onClick={() => setApplianceToEdit(gp)}
                      style={{ margin: '5px' }}
                    />
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <Table.Header>
        <Table.Row onClick={() => toggleArchived(!showArchived)}>
          <Table.HeaderCell colSpan={7} textAlign="left">
            Archived Appliances
            {appliancesArchived?.length
              ? ` ( ${appliancesArchived.length} )`
              : ''}
            <FontAwesomeIcon
              className={`${showArchived && 'fa-rotate-180'}`}
              icon={['fal', 'chevron-down']}
              style={{ marginLeft: '8px' }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(showArchived ? appliancesArchived : [])?.map((gp) => {
          const { productClass, data } = gp
          const linkToProduct = processLink(data?.linkToProduct ?? '')
          const linkToProductSpec = processLink(data?.linkToProductSpec ?? '')
          return (
            <Table.Row key={gp.id}>
              <Table.Cell width={2}>
                <div className="appliance-cell">
                  <Image
                    src={getReferenceImage(productClass.media ?? [])}
                    rounded
                    size="mini"
                    style={{ marginRight: '8px' }}
                  />
                  {productClass.metadata?.name}
                </div>
              </Table.Cell>
              <Table.Cell width={1}>
                {data?.size?.endsWith('"') && data?.size}
              </Table.Cell>
              <Table.Cell width={2}>{data?.productDetails}</Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                {linkToProduct && (
                  <LinkButton
                    icon={
                      <FontAwesomeIcon
                        icon={['fal', 'arrow-up-right-from-square']}
                      />
                    }
                    onClick={() => window.open(linkToProduct)}
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                {linkToProductSpec && (
                  <LinkButton
                    icon={
                      <FontAwesomeIcon
                        icon={['fal', 'arrow-up-right-from-square']}
                      />
                    }
                    onClick={() => window.open(linkToProductSpec)}
                  />
                )}
              </Table.Cell>
              <Table.Cell width={4}>
                <ArchivedDiv>
                  <div className="text">Archived</div>
                  <div className="date">
                    {moment(data?.archivedAt).format('MM/DD/yyyy')}
                  </div>
                </ArchivedDiv>
              </Table.Cell>
              <Table.Cell width={2} textAlign="center">
                <InfoPopup
                  content={<p>Unarchive</p>}
                  trigger={
                    <Button
                      icon={<FontAwesomeIcon icon={['fal', 'box-open']} />}
                      onClick={async () =>
                        await updateGenericProductAndRefetch({
                          variables: {
                            data: {
                              data: {
                                ...gp.data,
                                archivedAt: null,
                              },
                            },
                            where: {
                              id: gp.id ?? '',
                            },
                          },
                        })
                      }
                      style={{ margin: '5px' }}
                    />
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <CreateUpdateApplianceModal
        appliance={applianceToEdit}
        open={!!applianceToEdit}
        onClose={() => setApplianceToEdit(undefined)}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to delete this product?"
        onCancel={() => setApplianceToDelete(undefined)}
        onConfirm={async () => {
          await deleteGenericProductAndRefetch({
            variables: {
              where: {
                id: applianceToDelete?.id ?? '',
              },
            },
          })
          setApplianceToDelete(undefined)
        }}
        open={!!applianceToDelete}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to cancel this deletion request?"
        onCancel={() => setApplianceToRestore(undefined)}
        onConfirm={async () => {
          await updateGenericProductAndRefetch({
            variables: {
              data: {
                data: {
                  ...applianceToRestore?.data,
                  deletionRequestedByCustomerAt: null,
                },
              },
              where: {
                id: applianceToRestore?.id ?? '',
              },
            },
          })
          setApplianceToRestore(undefined)
        }}
        open={!!applianceToRestore}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to confirm this appliance for the customer?"
        onCancel={() => setApplianceToConfirm(undefined)}
        onConfirm={async () => {
          await updateGenericProductAndRefetch({
            variables: {
              data: {
                data: {
                  ...applianceToConfirm?.data,
                  confirmedAt: new Date(),
                },
              },
              where: {
                id: applianceToConfirm?.id ?? '',
              },
            },
          })
          setApplianceToConfirm(undefined)
        }}
        open={!!applianceToConfirm}
      />
    </StyledTable>
  )
}

export default GenericProductsTable

const processLink = (link: string) => {
  if (!link) return link
  if (link.startsWith('http')) return link
  return `https://${link}`
}

const ArchivedDiv = styled.div`
  padding-left: 16px;
  .text {
    font-size: 14px;
    font-weight: 300;
    white-space: nowrap;
  }

  .date {
    font-color: ${Colors.gray500};
    font-size: 14px;
  }
`

const LinkButton = styled(Button)`
  &&& {
    background-color: white;
    color: ${Colors.orange500};

    :hover,
    :active {
      background-color: ${Colors.red100};
      color: ${Colors.orange500};
    }
  }
`

const StyledTable = styled(Table)`
  &&&&&&&&&& {
    margin: 0;
    th {
      font-size: 14px;
      line-height: 23px;
      padding-bottom: 4px;
      padding-top: 4px;
    }

    .appliance-cell {
      align-items: center;
      display: inline-flex;
      font-weight: 300;
      vertical-align: middle;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
`

const UpdatesDiv = styled.div<{
  styled: {
    delete?: boolean
    update?: boolean
    confirm?: boolean
  }
}>`
  &&& {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px;
    max-width: 310px;

    button {
      height: fit-content;
      font-weight: 300;
      width: 120px;
      padding: 10px;
    }

    .text {
      font-size: 14px;
      font-weight: 300;
      white-space: nowrap;
    }

    .date {
      font-color: ${Colors.gray500};
      font-size: 14px;
    }

    ${({ styled }) =>
      styled?.delete &&
      css`
        background-color: ${Colors.red100};
        border: 1px solid ${Colors.red200};
      `}

    ${({ styled }) =>
      styled?.update &&
      css`
        background-color: ${Colors.yellow50};
        border: 1px solid ${Colors.yellow100};
      `}

    ${({ styled }) =>
      styled?.confirm &&
      css`
        background-color: #f6f7ee;
        border: 1px solid ${Colors.green200};
        padding: 11px 26px;
        width: fit-content !important;
      `}
  }
`
