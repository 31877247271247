import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import OrdersCabinetry from 'components/admin/order-form/index-many'
import SectionTitle from 'components/admin/section-title'
import OrderCabinetryManyProvider from 'context/order/provider-many-cabinetry'
import { OrderType } from 'types/order'

const Module = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="FORM Orders" />
        <Grid.Row>
          <Grid.Column>
            <OrderCabinetryManyProvider
              defaultFilters={{
                sortBy: 'placedAt',
                sortDirection: 'descending',
                take: 30,
                type: OrderType.CABINETRY,
              }}
              skipLoader
            >
              <OrdersCabinetry />
            </OrderCabinetryManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
