import { useMemo } from 'react'

import useRoomMany from 'context/room/use-many'
import useFORMEmployees from 'context/user/use-form-employees'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormDropdownValue,
  IFormRadioValue,
} from '../filters-search'

const packStatusOptions = {
  all: 'All',
  releasedPack: 'Released',
  awaitingPack: 'Awaiting',
}

export type RoomFilterValues = {
  assignedTo: IFormDropdownValue
  packStatus: IFormRadioValue
}
const useRoomsFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useRoomMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()

  const filters: DynamicFilterGroup<RoomFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'assignedTo',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Assigned to',
            options: [
              ...userOptions,
              {
                key: 'everyone',
                text: 'Everyone',
                value: 'Everyone',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'packStatus',
            type: DynamicFilterTypes.RADIO,
            label: 'Pack status',
            options: Object.keys(packStatusOptions).map((o) => {
              return {
                text: packStatusOptions[
                  o as 'all' | 'awaitingPack' | 'releasedPack'
                ],
                value: o,
              }
            }),
          },
        ],
      },
    ]
  }, [userOptions])

  const defaultFilters = useMemo(() => {
    const roomFilters: Partial<RoomFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      roomFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }
    if (queryFilters.packStatus)
      roomFilters.packStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.packStatus,
          text: packStatusOptions[queryFilters.packStatus],
        },
      }

    return roomFilters
  }, [queryFilters])

  const handleApplyFilters = (data: RoomFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      packStatus: data.packStatus?.selection.value as
        | 'all'
        | 'awaitingPack'
        | 'releasedPack',
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: null,
        assignedToRole: null,
      }),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useRoomsFiltersSearch
