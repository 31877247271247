import React, { useMemo } from 'react'

import moment from 'moment'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

import { Badge } from 'components/shared/chip'
import { UserInfo } from 'components/shared/user-info'
import { getAddressString } from 'context/address/utils'
import { useConversationMany } from 'context/project/conversation/provider-many'
import { parseOwners } from 'context/project/utils'
import { ButtonTooltip } from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import { Conversation } from 'types/conversation'
import { formatPhoneNumber, getNameForUser } from 'views/utils'

type ConversationsTableProps = {
  onSetConversation: (conversation: Conversation) => void
  onViewMessages: (conversationId: string) => void
}

export const ConversationsTable = ({
  onSetConversation,
  onViewMessages,
}: ConversationsTableProps) => {
  const { conversations } = useConversationMany()

  const ownerPerProject = useMemo(() => {
    return conversations.reduce<
      Record<string, { formattedName: string; picture: string }>
    >((acc, conversation) => {
      conversation.clientUser?.projects.forEach((projectUser) => {
        if (projectUser.project && !acc[projectUser.project.id]) {
          const { primaryOwner } = parseOwners(projectUser.project)

          acc[projectUser.project.id] = {
            formattedName: getNameForUser(primaryOwner),
            picture: primaryOwner.picture,
          }
        }
      })
      return acc
    }, {})
  }, [conversations])

  return (
    <Table celled columns={16}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}>People</Table.HeaderCell>
          <Table.HeaderCell width={2}>Sent From</Table.HeaderCell>

          <Table.HeaderCell width={4}>Messages</Table.HeaderCell>
          <Table.HeaderCell width={5}>Project</Table.HeaderCell>
          <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {conversations.map((conversation) => {
          const phoneFromMsg =
            conversation.messages.find(
              (message) => message.data?.direction === 'incoming',
            )?.data?.sourceFrom || conversation.messages?.[0]?.data?.sourceFrom

          return (
            <Table.Row key={conversation.id}>
              <Table.Cell>
                <FlexCell>
                  {conversation.clientUser ? (
                    <UserInfo
                      name={getNameForUser(conversation.clientUser)}
                      picture={conversation.clientUser.picture}
                      phone={
                        conversation.clientUser?.phone ||
                        phoneFromMsg ||
                        'No Phone'
                      }
                    />
                  ) : (
                    <UserInfo
                      name="Anonymous"
                      phone={phoneFromMsg || 'No Phone'}
                    />
                  )}
                </FlexCell>
              </Table.Cell>
              <Table.Cell>
                {formatPhoneNumber(phoneFromMsg ?? '')}
                {conversation.clientUser &&
                  phoneFromMsg !== conversation.clientUser?.phone && (
                    <p className="x-small red">
                      This phone number is different from the one assigned to
                      the user
                    </p>
                  )}
              </Table.Cell>
              <Table.Cell>
                <FlexCell>
                  <Badge
                    name={`${conversation.messages.length} Message${
                      conversation.messages.length !== 1 ? 's' : ''
                    }`}
                    color={Colors.blue100}
                  />
                  <p className="no-margin x-small">
                    {`${moment(conversation.createdAt).format(
                      'MMM DD, YYYY - h:mm a',
                    )} to ${moment(conversation.lastMessageReceivedAt).format(
                      'MMM DD, YYYY - h:mm a',
                    )}`}
                  </p>
                </FlexCell>
              </Table.Cell>
              <Table.Cell>
                <FlexCell>
                  {conversation.clientUser?.projects.map((projectUser) => (
                    <UserInfo
                      key={projectUser.project?.id}
                      name={
                        projectUser.project?.id
                          ? ownerPerProject[projectUser.project?.id]
                              ?.formattedName
                          : ''
                      }
                      picture={
                        projectUser.project?.id
                          ? ownerPerProject[projectUser.project?.id]?.picture
                          : ''
                      }
                      content={
                        projectUser?.project?.projectAddress
                          ? getAddressString(
                              projectUser?.project?.projectAddress,
                            )
                          : null
                      }
                    />
                  ))}
                </FlexCell>
              </Table.Cell>
              <Table.Cell>
                <FlexCell alignCenter>
                  <ButtonTooltip
                    kind="solid"
                    color="gray"
                    fontAwesomeIcon="comments"
                    onClick={() => onViewMessages(conversation.id)}
                    tooltipProps={{
                      label: 'View Conversation',
                      color: Colors.blue700,
                    }}
                  />
                  <ButtonTooltip
                    kind="solid"
                    fontAwesomeIcon="user-pen"
                    onClick={() => onSetConversation(conversation)}
                    tooltipProps={{
                      label: 'Reassign User',
                      color: Colors.blue700,
                    }}
                  />
                </FlexCell>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const FlexCell = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  justify-content: center;
  row-gap: 8px;
`
