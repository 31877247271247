import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_SUPPLIER_ORDER_CONTEXT,
  FindUniqueSupplierOrderPayload,
  FindUniqueSupplierOrderVariables,
} from 'queries/supplier-order'
import { SupplierOrder } from 'types/supplier-order'

interface ISupplierOrderContext {
  supplierOrder: SupplierOrder
  refetch: () => Promise<unknown>
}

interface SupplierOrderProps
  extends RouteComponentProps<{
    supplier_order_id: string
    noLoader?: boolean
  }> {
  children: React.ReactNode
}

export const SupplierOrderContext = createContext<ISupplierOrderContext>(
  {} as ISupplierOrderContext,
)

const SupplierOrderProvider = (props: SupplierOrderProps) => {
  const { loading, data, refetch } = useQuery<
    FindUniqueSupplierOrderPayload,
    FindUniqueSupplierOrderVariables
  >(FIND_UNIQUE_SUPPLIER_ORDER_CONTEXT, {
    variables: {
      where: { id: props.supplier_order_id ?? '' },
    },
  })

  if (loading || !data) return props.noLoader ? null : <Loader />
  const supplierOrder = data?.supplierOrder

  return (
    <SupplierOrderContext.Provider
      value={{
        supplierOrder,
        refetch,
      }}
    >
      {props.children}
    </SupplierOrderContext.Provider>
  )
}

export default SupplierOrderProvider
