import React from 'react'

import { RouteComponentProps } from '@reach/router'

import IssueProvider from 'context/issue/provider'

interface IssueProps
  extends RouteComponentProps<{
    project_id: string
    issue_id: string
  }> {
  children?: React.ReactNode
}

export default (props: IssueProps) => (
  <IssueProvider issue_id={props.issue_id}>{props.children}</IssueProvider>
)
