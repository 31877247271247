import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Badge } from 'components/shared/chip'
import {
  hasProject,
  isQuizCompleted,
  isContactCompleted,
  isEstimateFirst,
  isCompanyCompleted,
  isTradeProspect,
  parseFiles,
} from 'context/prospect/utils'
import { Colors } from 'styles/app/system'
import { Prospect } from 'types/prospect'

const HomeownerTasksStatus = ({ prospect }: { prospect: Prospect }) => {
  const quizCompleted = isQuizCompleted(prospect)
  const estimatorCompleted = !!prospect.data?.estimate
  const parsedFiles = parseFiles(prospect)
  const showUsYourSpaceCompleted =
    !!parsedFiles?.prospectFloorPlans?.length ||
    !!parsedFiles?.prospectRoomPhotos?.length
  const projectCreated = !!hasProject(prospect)
  const estimateFirst = isEstimateFirst(prospect)
  const projectInfoLabel = (
    <TaskLabel className="no-margin" completed={quizCompleted}>
      <FontAwesomeIcon icon={['fal', quizCompleted ? 'check' : 'clock']} />
      Project information
    </TaskLabel>
  )
  const estimatorLabel = (
    <TaskLabel className="no-margin" completed={estimatorCompleted}>
      <FontAwesomeIcon icon={['fal', estimatorCompleted ? 'check' : 'clock']} />
      Estimator
    </TaskLabel>
  )

  return (
    <div style={{ display: 'grid', rowGap: '4px' }}>
      {projectCreated && <Badge color="#D7F3DC" name="Converted" />}
      {estimateFirst ? estimatorLabel : projectInfoLabel}
      {estimateFirst ? projectInfoLabel : estimatorLabel}
      <TaskLabel className="no-margin" completed={showUsYourSpaceCompleted}>
        <FontAwesomeIcon
          icon={['fal', showUsYourSpaceCompleted ? 'check' : 'clock']}
        />
        Show us your space
      </TaskLabel>
      <TaskLabel className="no-margin" completed={projectCreated}>
        <FontAwesomeIcon icon={['fal', projectCreated ? 'check' : 'clock']} />
        Project created
      </TaskLabel>
    </div>
  )
}

const TradeTasksStatus = ({ prospect }: { prospect: Prospect }) => {
  const contactCompleted = isContactCompleted(prospect)
  const companyCompleted = isCompanyCompleted(prospect)
  const projectCreated = !!hasProject(prospect)
  return (
    <div style={{ display: 'grid', rowGap: '4px' }}>
      {projectCreated && <Badge color="#D7F3DC" name="Converted" />}
      <TaskLabel className="no-margin" completed={contactCompleted}>
        <FontAwesomeIcon icon={['fal', contactCompleted ? 'check' : 'clock']} />
        Contact information
      </TaskLabel>
      <TaskLabel className="no-margin" completed={companyCompleted}>
        <FontAwesomeIcon icon={['fal', companyCompleted ? 'check' : 'clock']} />
        Company information
      </TaskLabel>
      <TaskLabel className="no-margin" completed={projectCreated}>
        <FontAwesomeIcon icon={['fal', projectCreated ? 'check' : 'clock']} />
        Project created
      </TaskLabel>
    </div>
  )
}

const TasksStatus = ({ prospect }: { prospect: Prospect }) => {
  if (isTradeProspect(prospect)) {
    return <TradeTasksStatus prospect={prospect} />
  }
  return <HomeownerTasksStatus prospect={prospect} />
}

export default TasksStatus

const TaskLabel = styled.p<{ completed: boolean }>`
  color: ${(props) =>
    props?.completed ? Colors.greenBright : Colors.black} !important;
  svg {
    color: ${(props) =>
      props?.completed ? Colors.greenBright : Colors.yellow600} !important;
    margin-right: 8px;
  }
`
