import React from 'react'

import { BottomSectionC } from 'components/shared/project/project-file/utils'

import AddCommentForm from './comment-internal/form'
import CommentList from './comment-internal/list'
import SectionSubmit from './section-submit-internal'

interface SidebarCommentsProps {
  fileId: string
  isReviewer?: boolean
  showResolvedComments?: boolean
  toggleSideMenu: () => void
}

const SidebarComments = (props: SidebarCommentsProps) => {
  return (
    <>
      <SectionSubmit isReviewer={!!props.isReviewer} />
      <CommentList
        showResolvedComments={props.showResolvedComments}
        isAdmin
        isReviewer={props.isReviewer}
        onClose={props.toggleSideMenu}
      />
      <div style={{ padding: '140px 0' }} />
      {props.isReviewer ? (
        <BottomSectionC>
          <div className="content">
            <AddCommentForm />
          </div>
        </BottomSectionC>
      ) : null}
    </>
  )
}

export default SidebarComments
