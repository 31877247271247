import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Button, Card, Divider, Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import FileCard from 'components/shared/project/project-file/card'
import useProject from 'context/project/use'
import { parseFiles } from 'context/project-file/utils-many'

import { renderBuildZoomBlock } from '../project-buildzoom'

const BuildZoom = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project } = useProject()
  const { buildZoomData } = project.data

  const { buildzoomPhotos } = parseFiles(project.files)

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="BuildZoom" />
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <h5>BuildZoom</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              onClick={() =>
                window.open(`/admin/projects-buildzoom/${project.id}`)
              }
            >
              Go to BuildZoom project
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column width={8}>
            {buildZoomData ? (
              <div style={{ height: '100vh' }} id="divToOfferInfo">
                <Card fluid>{renderBuildZoomBlock(buildZoomData)}</Card>
              </div>
            ) : (
              'No data yet'
            )}
          </Grid.Column>
          <Grid.Column width={8}>
            {buildzoomPhotos.length ? (
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <h4>Additional Photos</h4>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                  {buildzoomPhotos.map((f) => (
                    <Grid.Column key={f.id} style={{ paddingBottom: '20px' }}>
                      <FileCard file={f} navigateTo={`/admin/files/${f.id}/`} />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProjectLayout>
  )
}

export default BuildZoom
