import { useMutation } from '@apollo/client'

import {
  UPDATE_ONE_CONVERSATION,
  UpdateOneConversationPayload,
  UpdateOneConversationVariables,
} from 'queries/conversation'

export const useConversationMutate = () => {
  const [updateConversation, { loading: loadingUpdate }] = useMutation<
    UpdateOneConversationPayload,
    UpdateOneConversationVariables
  >(UPDATE_ONE_CONVERSATION)

  return {
    updateConversation,
    loadingUpdate,
  }
}
