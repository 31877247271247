import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Image, Table } from 'semantic-ui-react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import { parseNobiliaOptions } from 'context/design-option-utils'
import useMediaMany from 'context/media/use-many'
import useMediaMutate from 'context/media/use-mutate'
import Button from 'design-system/components/button/button'
import { CatalogLabel } from 'styles/admin/main'
import { Media } from 'types/media'
import { NobiliaOption } from 'types/nobilia-option'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import MediaModal from './modal'

const MediaTable = () => {
  const [mediaToDelete, setMediaToDelete] = useState<Media>()
  const { media, refetch, queryFilters, setQueryFilters } = useMediaMany()
  const { sortBy, sortDirection } = queryFilters
  const { deleteMedia, loadingDelete } = useMediaMutate()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell
            sorted={sortBy === 'name' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'name',
                sortDirection:
                  sortBy === 'name' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'createdAt' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'createdAt',
                sortDirection:
                  sortBy === 'createdAt' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Created At
          </Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Associations</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {media.map((m: Media) => {
          const parsedNobiliaOptions = parseNobiliaOptions(
            m.nobiliaOptions as NobiliaOption[],
          )
          return (
            <Table.Row key={m.id}>
              <Table.Cell width={2}>
                {m.key && !m.key.includes('.obj') && !m.key.includes('.glb') ? (
                  <Image
                    centered
                    size="small"
                    src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                      m.key,
                    )}`}
                  />
                ) : null}
              </Table.Cell>
              <Table.Cell width={3}>{m.name}</Table.Cell>
              <Table.Cell width={2}>
                {moment(m.createdAt).format('MM/DD/YYYY')}
              </Table.Cell>
              <Table.Cell width={3}>
                <CatalogLabel
                  content={
                    <p>
                      {capsSnakeCaseToTitleCase(m.category) +
                        `${
                          m.category === 'special'
                            ? ' - ' + m.data?.specialTag
                            : ''
                        }`}
                    </p>
                  }
                  icon={<FontAwesomeIcon icon={['fal', 'image']} />}
                  styled={{ color: 'yellow' }}
                />
              </Table.Cell>
              <Table.Cell width={4}>
                {m.roomElementClasses.length ? (
                  <div>
                    Room Element Classes:{' '}
                    {m.roomElementClasses
                      .map((rec) => rec.identifier)
                      .join(', ')}
                  </div>
                ) : null}
                {m.genericProductClasses.length ? (
                  <div>
                    Generic Product Classes:{' '}
                    {m.genericProductClasses
                      .map((gpc) => gpc.identifier)
                      .join(', ')}
                  </div>
                ) : null}
                {m.nobiliaProductClasses.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Nobilia Product Classes:{' '}
                        {m.nobiliaProductClasses
                          .map((npc) => npc.orderId)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/product-class.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
                {parsedNobiliaOptions.fronts.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Fronts:{' '}
                        {parsedNobiliaOptions.fronts
                          .map((f) => f.optionKey)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/front.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
                {parsedNobiliaOptions.handles.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Handles:{' '}
                        {parsedNobiliaOptions.handles
                          .map((f) => f.optionKey)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/handle.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
                {parsedNobiliaOptions.carcaseColorsExterior.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Carcase exterior:{' '}
                        {parsedNobiliaOptions.carcaseColorsExterior
                          .map((f) => f.optionKey)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/carcase.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
                {parsedNobiliaOptions.carcaseColorsInterior.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Carcase interior:{' '}
                        {parsedNobiliaOptions.carcaseColorsInterior
                          .map((f) => f.optionKey)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/carcase.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
                {parsedNobiliaOptions.plinthColors.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Plinth:{' '}
                        {parsedNobiliaOptions.plinthColors
                          .map((f) => f.optionKey)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/carcase.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
                {parsedNobiliaOptions.ceilingPanelColors.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Ceiling panel:{' '}
                        {parsedNobiliaOptions.ceilingPanelColors
                          .map((f) => f.optionKey)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/carcase.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
                {parsedNobiliaOptions.uprightBarColors.length ? (
                  <CatalogLabel
                    content={
                      <p>
                        Upright bar:{' '}
                        {parsedNobiliaOptions.uprightBarColors
                          .map((f) => f.optionKey)
                          .join(', ')}
                      </p>
                    }
                    icon={
                      <img
                        src={`${process.env.GATSBY_CDN_ROOT}/images/catalog/carcase.png`}
                      />
                    }
                    styled={{ color: 'green' }}
                  />
                ) : null}
              </Table.Cell>
              <Table.Cell textAlign="center" width={2}>
                <MediaModal
                  media={m}
                  refetch={refetch}
                  trigger={
                    <Button
                      kind="solid"
                      fontAwesomeIcon="edit"
                      style={{ marginRight: 4 }}
                    />
                  }
                />
                <Button
                  kind="solid"
                  color="gray"
                  fontAwesomeIcon="trash-alt"
                  onClick={() => setMediaToDelete(m)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
        <ConfirmationModal
          firstTitle="Are you sure you want to delete this media?"
          onCancel={() => setMediaToDelete(undefined)}
          onConfirm={async () => {
            await deleteMedia({
              variables: {
                where: {
                  id: mediaToDelete?.id ?? '',
                },
              },
              onCompleted: refetch,
            })
            setMediaToDelete(undefined)
          }}
          loading={loadingDelete}
          open={!!mediaToDelete}
        />
      </Table.Body>
    </Table>
  )
}

export default MediaTable
