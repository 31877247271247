import React from 'react'

import moment from 'moment'
import { Image, Table } from 'semantic-ui-react'

import { useNobiliaSampleClassMany } from 'context/nobilia-sample-class/provider-many'
import Button from 'design-system/components/button/button'
import { StyledLabel } from 'styles/admin/main'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'

type NobiliaSampleClassTableProps = {
  handleEdit?: (nobiliaSample: NobiliaSampleClass) => void
}

export const NobiliaSampleClassTable = ({
  handleEdit,
}: NobiliaSampleClassTableProps) => {
  const { nobiliaSampleClasses, setQueryFilters, queryFilters } =
    useNobiliaSampleClassMany()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={
              queryFilters.sortBy === 'identifier'
                ? queryFilters.sortDirection
                : undefined
            }
            onClick={() =>
              setQueryFilters((currFilters) => {
                if (!currFilters.sortBy) {
                  return {
                    ...currFilters,
                    sortBy: 'identifier',
                    sortDirection: 'ascending',
                  }
                }
                if (currFilters.sortDirection === 'ascending') {
                  return {
                    ...currFilters,
                    sortBy: 'identifier',
                    sortDirection: 'descending',
                  }
                }
                return {
                  ...currFilters,
                  sortBy: undefined,
                  sortDirection: undefined,
                }
              })
            }
          >
            Identifier
          </Table.HeaderCell>
          <Table.HeaderCell>Sample</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          {!!handleEdit && <Table.HeaderCell />}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {nobiliaSampleClasses.map((sample, idx) => {
          const isOutOfStock = sample.meta.outOfStock
          const isDeprecated = sample.meta.deprecated
          const expectedRestockDate = sample.meta.expectedRestockDate
          return (
            <Table.Row key={`${sample.id}_${idx}`}>
              <Table.Cell width={1}>
                {(isOutOfStock || isDeprecated) && (
                  <>
                    <StyledLabel
                      ribbon
                      styled={{
                        yellow: true,
                        transform: 'translateY(-24px)',
                      }}
                    >
                      {isDeprecated ? 'Deprecated' : 'Out of Stock'}
                    </StyledLabel>
                  </>
                )}
                {sample.identifier}
              </Table.Cell>
              <Table.Cell width={3}>
                <Image
                  centered
                  size="small"
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    sample.imageUrl,
                  )}`}
                />
              </Table.Cell>
              <Table.Cell width={7}>{sample.description}</Table.Cell>
              <Table.Cell width={3}>
                {isDeprecated && 'Deprecated'}
                {!isDeprecated && isOutOfStock && (
                  <>
                    Out of Stock
                    <br />
                    <b>Restock Date: </b>
                    {sample.meta.expectedRestockDate
                      ? moment(sample.meta.expectedRestockDate).format(
                          'MMM DD, YYYY',
                        )
                      : 'No date'}
                  </>
                )}
                {!isDeprecated && !isOutOfStock && 'In Stock'}
                {isDeprecated && isOutOfStock && (
                  <p className="red">
                    An item that is deprecated should not be marked as out of
                    stock. Please fix this.
                  </p>
                )}
                {isOutOfStock &&
                  expectedRestockDate &&
                  moment(expectedRestockDate).isBefore() && (
                    <p className="red">
                      The expected restock date is due. Please check if it is
                      still out of stock and update the status or date if
                      necessary.
                    </p>
                  )}
              </Table.Cell>
              {!!handleEdit && (
                <Table.Cell textAlign="center" width={2}>
                  <Button
                    kind="solid"
                    fontAwesomeIcon="pencil"
                    onClick={() => handleEdit(sample)}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}
