import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Divider, Grid, Table } from 'semantic-ui-react'

import { Chip } from 'components/shared/chip'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { formatDollars } from 'context/dollar-utils'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import { getRoomTypeWithDescription } from 'context/room/utils'
import Button from 'design-system/components/button'
import StatusLabel from 'design-system/components/status-label/status-label'
import { Payment } from 'types/payment'
import { StatusMode } from 'types/utils'
import { capsSnakeCaseToTitleCase, copyToClipboard } from 'views/utils'

import PaymentCreateUpdateModal from './create-update-modal'

const PaymentsViewMany = (_: RouteComponentProps) => {
  const { paymentsAll } = useProject()
  const { deletePaymentAndRefetch } = useProjectPaymentMutate()
  const [enablePaymentCreate, setEnablePaymentCreate] = useState(false)
  const [paymentToDelete, setPaymentToDelete] = useState<Payment>()
  const [paymentToEdit, setPaymentToEdit] = useState<Payment>()

  const handleCopyURL = async (paymentId: string) => {
    const isCopySuccessful = await copyToClipboard(
      `${process.env.GATSBY_HOME_URL}/_fk/payments/${paymentId}`,
    )
    if (isCopySuccessful) notifySuccess('URL Copied to Clipboard')
    else notifyError('Unable to copy to clipboard. Please, try again.')
  }

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column>
          <h5>Payments</h5>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            kind="solid"
            text="Create New Payment"
            onClick={() => setEnablePaymentCreate(true)}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Refunded</Table.HeaderCell>
                <Table.HeaderCell>Method</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Stripe</Table.HeaderCell>
                <Table.HeaderCell>Public Link</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {paymentsAll.map((p) => (
                <Table.Row key={p.id}>
                  <Table.Cell width={3}>
                    <p className="no-margin">
                      {capsSnakeCaseToTitleCase(p.metadata.type)}
                    </p>
                    <span className="small gray">
                      {p.metadata?.name || p.description}
                    </span>
                    {p.room ? (
                      <Chip
                        name={getRoomTypeWithDescription(p.room)}
                        color={p.room.data.tagColor}
                        opacity={0.3}
                      />
                    ) : null}
                  </Table.Cell>
                  <Table.Cell width={2}>{formatDollars(p.amount)}</Table.Cell>
                  <Table.Cell width={2}>
                    {formatDollars(p.refundAmount)}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {p.metadata.method
                      ? capsSnakeCaseToTitleCase(p.metadata.method)
                      : ''}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {(() => {
                      if (p.status === 'succeeded') {
                        return (
                          <StatusLabel
                            type={StatusMode.Confirmed}
                            text={`Paid ${
                              p.metadata?.paidAt
                                ? moment(p.metadata?.paidAt).format('LL')
                                : ''
                            }`}
                          />
                        )
                      }
                      if (p.metadata?.pendingSince) {
                        return (
                          <StatusLabel
                            type={StatusMode.Confirmation}
                            text={`Initiated ${moment(
                              p.metadata?.pendingSince,
                            ).format('LL')}`}
                          />
                        )
                      }
                      if (p.status === 'failed') {
                        return (
                          <StatusLabel
                            type={StatusMode.Submitted}
                            text={`Failed ${
                              p.metadata?.failedAt
                                ? moment(p.metadata?.failedAt).format('LL')
                                : ''
                            }`}
                          />
                        )
                      }
                      return (
                        <StatusLabel
                          type={StatusMode.OpenForEdit}
                          text={`Pending since ${moment(p.createdAt).format(
                            'LLL',
                          )}`}
                        />
                      )
                    })()}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {p.stripeChargeId &&
                    (p.stripeChargeId.startsWith('ch') ||
                      p.stripeChargeId.startsWith('py') ||
                      p.stripeChargeId.startsWith('pi')) ? (
                      <Button
                        color="blue"
                        kind="solid"
                        fontAwesomeIcon="magnifying-glass-plus"
                        onClick={() =>
                          window.open(
                            `https://dashboard.stripe.com/payments/${p.stripeChargeId}`,
                          )
                        }
                      />
                    ) : (
                      ''
                    )}
                    {p.stripeInvoiceId?.startsWith('in') ? (
                      <Button
                        color="blue"
                        kind="solid"
                        fontAwesomeIcon="magnifying-glass-plus"
                        onClick={() =>
                          window.open(
                            `https://dashboard.stripe.com/invoices/${p.stripeInvoiceId}`,
                          )
                        }
                      />
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      color="gray"
                      fontAwesomeIcon="link"
                      kind="ghost"
                      onClick={() => handleCopyURL(p.id ?? '')}
                      text="Copy payment link"
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      fontAwesomeIcon="edit"
                      kind="solid"
                      onClick={() => {
                        setPaymentToEdit(p)
                      }}
                    />
                    <Button
                      color="blue"
                      fontAwesomeIcon="trash-alt"
                      kind="solid"
                      onClick={() => setPaymentToDelete(p)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <PaymentCreateUpdateModal
              open={!!paymentToEdit || enablePaymentCreate}
              payment={paymentToEdit}
              onClose={() => {
                setPaymentToEdit(undefined)
                setEnablePaymentCreate(false)
              }}
            />
            <ConfirmationModal
              firstTitle="Are you sure you want to delete the payment?"
              onCancel={() => setPaymentToDelete(undefined)}
              onConfirm={async () => {
                await deletePaymentAndRefetch({
                  variables: {
                    where: {
                      id: paymentToDelete?.id ?? '',
                    },
                  },
                })
                setPaymentToDelete(undefined)
              }}
              open={!!paymentToDelete}
            />
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PaymentsViewMany
