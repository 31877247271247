import React, { useState } from 'react'

import { Table } from 'semantic-ui-react'

import SendOrderModal from 'components/admin/design/supplier-order/send-order-modal'
import useDesignMany from 'context/design/use-many'
import { parseRenders, parseLatestPlan } from 'context/design/utils'
import { getRoomTypeWithDescription } from 'context/room/utils'
import StatusLabel from 'design-system/components/status-label/status-label'
import { Design } from 'types/design'

import CellContentActions from './table-cell-actions'
import { ProjectOwnersCellContents } from '../project/project-owners/table-cell-content'
import { ProjectStaffCellContents } from '../project/project-staff/table-cell-content'

const DesignsReviewsTable = () => {
  const { designs, refetch, queryFilters, setQueryFilters } = useDesignMany()
  const { sortBy, sortDirection } = queryFilters

  const [designToSend, setDesignToSend] = useState<Design>()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Project</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Room / Design</Table.HeaderCell>
          <Table.HeaderCell>Review Status</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'sentToRenderingAt' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'sentToRenderingAt',
                sortDirection:
                  sortBy === 'sentToRenderingAt' &&
                  sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Render Status
          </Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {designs.map((d: Design) => {
          const project = d.room?.project
          if (!project) return null
          const { latestPlanInternalReviewMode, latestPlanInternalReviewText } =
            parseLatestPlan(d)
          const { rendersUnreleased, rendersStatusMode, rendersStatusText } =
            parseRenders(d)
          return (
            <Table.Row key={d.id}>
              <Table.Cell width={3}>
                <ProjectOwnersCellContents project={project} linkToProject />
              </Table.Cell>
              <Table.Cell width={3}>
                <ProjectStaffCellContents project={project} />
              </Table.Cell>
              <Table.Cell width={3}>
                {getRoomTypeWithDescription(d.room)}
                <p className="light-gray x-small">{d.name}</p>
              </Table.Cell>
              <Table.Cell width={3}>
                {latestPlanInternalReviewMode ? (
                  <StatusLabel
                    type={latestPlanInternalReviewMode}
                    text={latestPlanInternalReviewText.summary}
                    description={latestPlanInternalReviewText.text}
                  />
                ) : null}
              </Table.Cell>
              <Table.Cell width={3}>
                <StatusLabel
                  type={rendersStatusMode}
                  text={rendersStatusText?.summary}
                  description={rendersStatusText?.text}
                />
                <p className="x-small light-gray" style={{ marginTop: '8px' }}>
                  {rendersUnreleased.length} visuals pending
                </p>
                {d.metadataGallery?.showOnWebsiteGallery && (
                  <p className="x-small">
                    Showing on Gallery: {d.metadataGallery?.name || ''}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                <CellContentActions design={d} />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      {designToSend ? (
        <SendOrderModal
          design={designToSend}
          onClose={() => setDesignToSend(undefined)}
          open={!!designToSend}
          refetch={refetch}
        />
      ) : null}
    </Table>
  )
}

export default DesignsReviewsTable
