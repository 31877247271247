import React, { useEffect, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import { Editor, JSONContent } from '@tiptap/react'
import { Form } from 'semantic-ui-react'

import { uploadImage } from 'components/shared/project/project-file/upload-card'
import RichTextEditor from 'components/shared/rich-text-editor'
import useDesign from 'context/design/use'
import useDesignCommentMutate from 'context/design/use-comment-mutate'
import useProject from 'context/project/use'
import useProjectFile from 'context/project-file/use'
import {
  FIND_MANY_DESIGN_ADVISORY_CODE_GROUPS,
  FindManyDesignAdvisoryCodeGroupPayload,
} from 'queries/design-advisory-code-group'
import { StyledForm } from 'styles/app/components/form'
import { Comment, CommentType } from 'types/comment'
import { ProjectFile, ProjectFileType } from 'types/project-file'
import * as viewUtils from 'views/utils'

interface AddCommentFormProps {
  commentToReply?: string
  comment?: Comment
  onCancel?: () => void
  onCommentCreated?: () => void
}

const AddCommentForm = ({
  comment,
  commentToReply,
  onCancel,
  onCommentCreated,
}: AddCommentFormProps) => {
  const [advisoryCode, setAdvisoryCode] = useState<string>('')
  const [attachments, setAttachments] = useState<File[]>([])
  const [positionNumber, setPositionNumber] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const filesRef = useRef<HTMLInputElement>(null)
  const [richTextApi, setRichTextApi] = useState<Editor | null>(null)

  const { project } = useProject()
  const { setCommentToAdd, setCreationMode } = useProjectFile()
  const { design, refetch: refetchDesign } = useDesign()
  const { createCommentAndRefetch } = useDesignCommentMutate()

  const {
    data: { designAdvisoryCodeGroups } = { designAdvisoryCodeGroups: [] },
  } = useQuery<FindManyDesignAdvisoryCodeGroupPayload>(
    FIND_MANY_DESIGN_ADVISORY_CODE_GROUPS,
  )

  const handleCancel = () => {
    setAdvisoryCode('')
    setAttachments([])
    setCommentToAdd(null)
    setCreationMode(false)
    setPositionNumber('')
    richTextApi?.commands.clearContent()
    onCancel && onCancel()
  }

  const hanldeAddComment = async () => {
    setLoading(true)
    let files: ProjectFile[] = []
    if (attachments.length > 0) {
      await Promise.all(
        attachments.map(async (a) => {
          const file = await uploadImage({
            fileType: ProjectFileType.COMMENT_ATTACHMENT,
            imgFile: a,
            projectId: project.id,
          })
          files = files.concat(file)
        }),
      )
    }

    const body = richTextApi?.getJSON()
    const bodyHtml = richTextApi?.getHTML()

    await createCommentAndRefetch({
      variables: {
        data: {
          attachments: {
            connect:
              viewUtils.mapOrEmptyArray(files, (f) => ({
                id: f.id as string,
              })) ?? [],
          },
          design: {
            connect: {
              id: design?.id ?? '',
            },
          },
          designAdvisoryCode: advisoryCode
            ? {
                connect: {
                  id: advisoryCode,
                },
              }
            : undefined,
          data: !commentToReply
            ? { internalPositionNumber: positionNumber }
            : {},
          replyOf: commentToReply
            ? { connect: { id: commentToReply } }
            : undefined,
          pageNum: comment?.pageNum,
          project: { connect: { id: project.id } },
          body: body as JSONContent,
          bodyHtml: bodyHtml as string,
          type: CommentType.FILE,
          x: comment?.x,
          y: comment?.y,
        },
      },
      onCompleted: async () => {
        await refetchDesign()
        if (!comment) {
          richTextApi?.commands.clearContent()
        }
        onCommentCreated && onCommentCreated()
        setAdvisoryCode('')
        setCommentToAdd(null)
        setPositionNumber('')
        setAttachments([])
        setLoading(false)
      },
    })
  }

  const handleAddFiles = () => {
    filesRef.current?.click()
  }

  const onChangeFileInput = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setAttachments(attachments.concat(Array.prototype.slice.call(files)))
  }

  const handleRemoveFile = (file: File | ProjectFile) => {
    setAttachments(attachments.filter((f) => f.name !== file.name))
  }

  useEffect(() => {
    if (comment) richTextApi?.commands.focus()
  }, [richTextApi, comment])

  useEffect(() => {
    setRichTextApi(null)
  }, [])

  return (
    <div style={{ padding: '0', paddingTop: 10 }}>
      {!commentToReply ? (
        <StyledForm styled={{ whiteInputs: !commentToReply }}>
          <Form.Group>
            <Form.Input
              icon="hashtag"
              label={
                <label>
                  POS <span className="red">*</span>
                </label>
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPositionNumber(e.target.value)
              }
              placeholder="POS"
              style={{ marginLeft: '-5px' }}
              width={5}
              value={positionNumber}
            />
            <Form.Dropdown
              pointing="top"
              label={
                <label>
                  Advisory Code <span className="red">*</span>
                </label>
              }
              onChange={(_, { value }) => setAdvisoryCode(value as string)}
              options={designAdvisoryCodeGroups
                .filter((g) => !g.index.startsWith('old'))
                .reduce<
                  {
                    className: string
                    disabled?: boolean
                    key: string
                    text: string
                    value?: string
                  }[]
                >((acc, g, i) => {
                  acc.push({
                    className: `small section`,
                    disabled: true,
                    key: g.index + i,
                    text: g.index,
                    value: g.id,
                  })
                  for (const code of g.designAdvisoryCodes) {
                    acc.push({
                      className: `small ${code.required ? 'red' : ''}`,
                      key: code.id as string,
                      text: code.index,
                      value: code.id,
                    })
                  }
                  return acc
                }, [])}
              placeholder="Advisory Code"
              search
              selection
              width={11}
              value={advisoryCode}
            />
          </Form.Group>
        </StyledForm>
      ) : null}
      <div style={{ margin: '10px 5px 5px' }}>
        <RichTextEditor
          getRichTextApi={setRichTextApi}
          placeholder="Additional note (optional)"
          loading={loading}
          actions="add-comment"
          handleAddFiles={handleAddFiles}
          handleAddComment={hanldeAddComment}
          handleCancel={handleCancel}
          disabled={
            commentToReply ? undefined : !advisoryCode || !positionNumber
          }
          handleRemoveFile={handleRemoveFile}
          attachments={attachments}
          whiteActions
        />
        <input
          id="commentAttachments"
          hidden
          type="file"
          multiple
          ref={filesRef}
          onChange={onChangeFileInput}
        />
      </div>
    </div>
  )
}

export default AddCommentForm
