import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import {
  FORMStatus,
  SupplierOrderType,
} from 'context/supplier-order/utils-many'
import {
  FIND_MANY_SUPPLIER_ORDER_COUNT,
  FindManySupplierOrderVariables,
  FindManySupplierOrderCountPayload,
} from 'queries/supplier-order'

import { getQueryVariables } from './utils-many'

const useAddOnRemedialCounts = (userId?: string) => {
  const variablesAddOnEDINotSent = useMemo(
    () =>
      getQueryVariables({
        type: [SupplierOrderType.AddOn],
        assignedTo: { id: userId ?? '' },
        statusesFORM: [FORMStatus.EDINotSent],
        take: undefined,
      }),
    [userId],
  )

  const variablesNobilia = useMemo(
    () =>
      getQueryVariables({
        type: [SupplierOrderType.Remedial, SupplierOrderType.AddOn],
        assignedTo: { id: userId ?? '' },
        statusesFORM: [FORMStatus.AwaitingNobilia],
        take: undefined,
      }),
    [userId],
  )

  const variablesFORM = useMemo(
    () =>
      getQueryVariables({
        type: [SupplierOrderType.Remedial, SupplierOrderType.AddOn],
        assignedTo: { id: userId ?? '' },
        statusesFORM: [FORMStatus.AwaitingFORM],
        take: undefined,
      }),
    [userId],
  )

  const {
    data: { count: sosAddOnEDINotSentCount } = { count: 0 },
    loading: loadingSosAddOnEDINotSentCount,
    refetch: refetchSosAddOnEDINotSentCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesAddOnEDINotSent,
  })

  const {
    data: { count: addOnRemedialsAwaitingNobiliaCount } = { count: 0 },
    loading: loadingAddOnRemedialsAwaitingNobiliaCount,
    refetch: refetchAddOnRemedialsANCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesNobilia,
  })

  const {
    data: { count: addOnRemedialsAwaitingFORMCount } = { count: 0 },
    loading: loadingAddOnRemedialsAwaitingFORMCount,
    refetch: refecthAddOnRemedialsAFCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesFORM,
  })

  const refetchAllCounts = async () => {
    await refetchSosAddOnEDINotSentCount()
    await refetchAddOnRemedialsANCount()
    await refecthAddOnRemedialsAFCount()
  }

  return {
    refetchAllCounts,
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
    loadingAddOnRemedialsAwaitingFORMCount,
    loadingAddOnRemedialsAwaitingNobiliaCount,
    loadingSosAddOnEDINotSentCount,
    sosAddOnEDINotSentCount,
  }
}

export default useAddOnRemedialCounts
