import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Designs from 'components/admin/design/index-many'
import DesignsReviewsTable from 'components/admin/design/table-reviews'
import { FiltersVersion } from 'components/admin/design/use-filters-search'
import useSalesDesigner from 'context/dashboard/sales-designer/use'
import DesignManyProvider from 'context/design/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import SalesDesignerLayout from './layout'

const SalesDesignerDesignsAwaitingSignoff = (_: RouteComponentProps) => {
  const { userId, userName } = useSalesDesigner()

  return (
    <SalesDesignerLayout>
      <DesignManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          assignedToRole: ProjectStaffRole.SALES_DESIGNER,
          reviewStatus: 'awaitingSignoff',
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          take: 30,
        }}
        skipLoader
      >
        <Designs
          filtersType={FiltersVersion.REVIEW}
          table={<DesignsReviewsTable />}
        />
      </DesignManyProvider>
    </SalesDesignerLayout>
  )
}

export default SalesDesignerDesignsAwaitingSignoff
