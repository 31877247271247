import { gql } from '@apollo/client'

import { Payout } from 'types/payout'

export const CREATE_PAYOUT = gql`
  mutation createPayout($data: PayoutCreateInput!) {
    createOnePayout(data: $data) {
      id
    }
  }
`

export interface CreatePayoutPayload {
  createPayout: Payout
}

export interface CreatePayoutVariables {
  data: Partial<Payout> & {
    project?: {
      connect?: {
        id: string
      }
    }
  }
}

export const DELETE_PAYOUT = gql`
  mutation deletePayout($where: PayoutWhereUniqueInput!) {
    deleteOnePayout(where: $where) {
      id
    }
  }
`

export interface DeletePayoutPayload {
  deleteOnePayout: Payout
}

export interface DeletePayoutVariables {
  where: { id: string }
}

export const UPDATE_PAYOUT = gql`
  mutation updatePayout(
    $data: PayoutUpdateInput!
    $where: PayoutWhereUniqueInput!
  ) {
    updateOnePayout(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdatePayoutPayload {
  updateOnePayout: Payout
}

export interface UpdatePayoutVariables {
  data: Partial<Payout>
  where: { id: string }
}
