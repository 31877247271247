import { useContext } from 'react'

import { ProjectDeliveryManyContext } from './provider-many'

const useProjectDeliveryMany = () => {
  const { deliveries, loading, refetch } = useContext(
    ProjectDeliveryManyContext,
  )

  return {
    deliveries,
    loading,
    refetch,
  }
}

export default useProjectDeliveryMany
