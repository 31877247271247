import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Projects from 'components/admin/project/index-many'
import useTradeDesigner from 'context/dashboard/trade-designer/use'
import ProjectManyProvider from 'context/project/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import TradeDesignerLayout from './layout'

const TradeDesignerProjects = (_: RouteComponentProps) => {
  const { userId, userName } = useTradeDesigner()

  return (
    <TradeDesignerLayout>
      <ProjectManyProvider
        defaultFilters={{
          assignedTo: {
            id: userId,
            name: userName,
          },
          assignedToRole: ProjectStaffRole.TRADE_DESIGNER,
          sortBy: 'derivedActivity',
          sortDirection: 'descending',
          status: 'open',
          take: 30,
        }}
        skipLoader
      >
        <Projects />
      </ProjectManyProvider>
    </TradeDesignerLayout>
  )
}

export default TradeDesignerProjects
