import React from 'react'

import { RouteComponentProps } from '@reach/router'

import TasksDashboard from 'components/admin/task/dashboard'
import useCustomerSupport from 'context/dashboard/customer-support/use'

import CustomerSupportLayout from './layout'

const CustomerSupportTasks = (_: RouteComponentProps) => {
  const { tasksToDo, tasksUrgent, tasksCompleted, refetchTasks } =
    useCustomerSupport()
  return (
    <CustomerSupportLayout>
      <TasksDashboard
        refetch={refetchTasks}
        tasksToDo={tasksToDo}
        tasksUrgent={tasksUrgent}
        tasksCompleted={tasksCompleted}
      />
    </CustomerSupportLayout>
  )
}

export default CustomerSupportTasks
