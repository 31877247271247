import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import NobiliaOptions from 'components/admin/catalog/nobilia-option/index-many'
import useNobiliaOptionMany from 'context/nobilia-option/use-many'
import { StyledBreadcrumb } from 'styles/planner/main'

const NobiliaOptionViewMany = (_: RouteComponentProps) => {
  const { catalog, nobiliaOptions, queryFilters } = useNobiliaOptionMany()
  const feature = nobiliaOptions.find(
    (no) => no.feature?.id === queryFilters.feature?.id,
  )?.feature
  const sections = [
    {
      key: 'Catalog',
      content: `Catalog ${catalog}`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Feature',
      content: `${feature?.featureNo} - ${feature?.description}`,
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3 className="catalog">{`${feature?.featureNo} - ${feature?.description}`}</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <NobiliaOptions />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default NobiliaOptionViewMany
