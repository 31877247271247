import { useContext } from 'react'

import { DisconnectedOptionManyContext } from './provider-many'

const useDisconnectedOptionMany = () => {
  const {
    catalog,
    count,
    loading,
    loadingMore,
    fetchMore,
    disconnectedOptions,
    queryFilters,
    refetch,
    setQueryFilters,
  } = useContext(DisconnectedOptionManyContext)

  return {
    catalog,
    count,
    loading,
    loadingMore,
    fetchMore,
    disconnectedOptions,
    queryFilters,
    refetch,
    setQueryFilters,
  }
}

export default useDisconnectedOptionMany
