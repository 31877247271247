import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import {
  FIND_MANY_CONVERSATION_COUNT,
  FindManyConversationCountPayload,
  FindManyConversationCountVariables,
} from 'queries/conversation'

import { QueryFilters, getQueryVariables } from './utils-many'

const useConversationCount = (queryFilters: QueryFilters) => {
  const queryVariables = useMemo(
    () => getQueryVariables(queryFilters),
    [queryFilters],
  )
  const {
    data: { count } = { count: 0 },
    loading,
    refetch,
  } = useQuery<
    FindManyConversationCountPayload,
    FindManyConversationCountVariables
  >(FIND_MANY_CONVERSATION_COUNT, {
    variables: queryVariables,
  })

  return {
    count,
    loading,
    refetch,
  }
}

export default useConversationCount
