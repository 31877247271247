import React, { useState } from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'

export default ({
  backgroundWhite,
  closeIcon = 'minus',
  children,
  initialState = true,
  titleExtras,
  titleText,
  openIcon = 'plus',
}: {
  backgroundWhite?: boolean
  closeIcon?: string
  children: React.ReactNode
  initialState?: boolean
  titleExtras?: React.ReactNode
  titleText: string
  openIcon?: string
}) => {
  const [active, setActive] = useState<boolean>(initialState)

  return (
    <AccordionComponent fluid>
      <Accordion.Title
        onClick={() => setActive(!active)}
        className="accordion-component-title"
      >
        <div>
          <h6 className="no-margin">{titleText}</h6>
          {titleExtras}
        </div>
        <FontAwesomeIcon
          icon={['fal', (active ? closeIcon : openIcon) as IconName]}
          style={{ color: Colors.orange500 }}
        />
      </Accordion.Title>
      <Accordion.Content
        active={active}
        style={{
          background: backgroundWhite ? Colors.white : Colors.gray100,
          padding: '30px 26px',
        }}
      >
        {children}
      </Accordion.Content>
    </AccordionComponent>
  )
}

export const AccordionComponent = styled(Accordion)`
  &&& {
    background: none;
    margin-bottom: 30px;
    margin-top: 20px;
    .accordion-component-title {
      align-items: center;
      background: ${Colors.white};
      box-shadow: 0px 2px 5px 0px rgba(120, 113, 108, 0.09);
      display: flex;
      justify-content: space-between;
      padding: 17px 20px !important;
      div {
        align-items: center;
        display: flex;
        gap: 16px;
      }
      svg {
        font-size: 15px;
      }
    }
  }
`
