import React from 'react'

import { Table } from 'semantic-ui-react'

import { Prospect } from 'types/prospect'

const CompanyInfo = ({ prospect }: { prospect: Prospect }) => {
  const { company } = prospect
  return (
    <Table compact celled>
      <Table.Body>
        <Table.Row>
          <Table.Cell collapsing>Company Name</Table.Cell>
          <Table.Cell>{company?.name}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Email Domain</Table.Cell>
          <Table.Cell>{company?.domain}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Website</Table.Cell>
          <Table.Cell>{company?.data?.website}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Industry</Table.Cell>
          <Table.Cell>{company?.data?.industry}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Instagram</Table.Cell>
          <Table.Cell>{company?.data?.instagram}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>EIN Number</Table.Cell>
          <Table.Cell>{company?.data?.EINNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Association</Table.Cell>
          <Table.Cell>
            {company?.data?.professionalDesignAssociation}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Membership #</Table.Cell>
          <Table.Cell>{company?.data?.membershipNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Company Size</Table.Cell>
          <Table.Cell>{company?.data?.size}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default CompanyInfo
