import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import ProjectFileNotFound from 'components/shared/project/project-file/file-not-found'
import ProjectFileLayout from 'components/shared/project/project-file/layout'
import ProjectFileProvider from 'context/project-file/provider'
import useProjectFile from 'context/project-file/use'
import { ProjectFileType } from 'types/project-file'

type ProjectFileProps = RouteComponentProps<{
  project_id: string
  file_id: string
}>

const ProjectFile = ({ project_id, location, file_id }: ProjectFileProps) => {
  const { projectFile } = useProjectFile()
  useEffect(() => {
    if (
      projectFile &&
      location?.pathname === `/admin/projects/${project_id}/files/${file_id}/`
    ) {
      if (projectFile.type === ProjectFileType.DETAILED_QUOTE) {
        navigate(
          `/admin/projects/${projectFile.project.id}/rooms/${projectFile.designPlan.room.id}/designs/${projectFile.designPlan.id}/plan/${projectFile.id}`,
        )
        return
      }
      if (projectFile.type === ProjectFileType.MEASUREMENT_AID) {
        navigate(
          `/admin/projects/${projectFile.project.id}/rooms/${projectFile.room.id}/measurements/${projectFile.id}`,
        )
        return
      }
    }
  }, [projectFile])

  if (!projectFile) return <ProjectFileNotFound />
  return <ProjectFileLayout />
}

export default (props: ProjectFileProps) => (
  <ProjectFileProvider file_id={props.file_id}>
    <ProjectFile {...props} />
  </ProjectFileProvider>
)
