import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { getQueryVariables } from 'context/issue/utils-many'
import {
  FIND_MANY_ISSUE_COUNT,
  FindManyIssueCountPayload,
  FindManyIssueVariables,
} from 'queries/issue'

const useUnresolvedIssuesCount = (userId?: string, userName?: string) => {
  const variables = useMemo(
    () =>
      getQueryVariables({
        status: 'unresolved',
        assignedTo: { id: userId, name: userName },
        take: undefined,
      }),
    [userId],
  )
  const { data: { count: issuesUnresolvedCount } = { count: 0 }, loading } =
    useQuery<FindManyIssueCountPayload, FindManyIssueVariables>(
      FIND_MANY_ISSUE_COUNT,
      {
        variables,
      },
    )

  return {
    loading,
    issuesUnresolvedCount,
  }
}

export default useUnresolvedIssuesCount
