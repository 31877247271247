import { gql } from '@apollo/client'

import { RoomElementFeature } from 'types/room-element-feature'

export const FIND_MANY_ROOM_ELEMENT_FEATURE = gql`
  query findManyRoomElementFeature($where: RoomElementFeatureWhereInput) {
    roomElementFeatures: findManyRoomElementFeature(where: $where) {
      id
      identifier
      description
      options(orderBy: { identifier: asc }) {
        id
        identifier
        description
        featureIdentifier
        optionIdentifier
      }
    }
  }
`

export interface FindManyRoomElementFeaturePayload {
  roomElementFeatures: RoomElementFeature[]
}

export interface FindManyRoomElementFeatureVariables {
  where?: {
    featureNo?: { in: string[] }
  }
}

export const FIND_UNIQUE_ROOM_ELEMENT_FEATURE = gql`
  query findUniqueRoomElementFeature(
    $where: RoomElementFeatureWhereUniqueInput!
  ) {
    roomElementFeature: findUniqueRoomElementFeature(where: $where) {
      id
      description
      identifier
    }
  }
`

export interface FindUniqueRoomElementFeaturePayload {
  roomElementFeature: RoomElementFeature
}

export interface FindUniqueRoomElementFeatureVariables {
  where?: {
    id: string
  }
}
