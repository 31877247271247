import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  IFormDropdownValue,
} from 'components/admin/filters-search'
import useRoomElementOptionMany from 'context/room-element-option/use-many'

export type RoomElementOptionFilterValues = {
  featureIdentifier: IFormDropdownValue
}

const useRoomElementOptionFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useRoomElementOptionMany()

  const filters: DynamicFilterGroup<RoomElementOptionFilterValues>[] =
    useMemo(() => {
      return []
    }, [])

  const defaultFilters = useMemo(() => {
    const roomElementOptionFilters: Partial<RoomElementOptionFilterValues> = {}
    return roomElementOptionFilters
  }, [queryFilters])

  const handleApplyFilters = (_: RoomElementOptionFilterValues) => {
    setQueryFilters({
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useRoomElementOptionFiltersSearch
