import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { sortBy } from 'lodash'
import moment from 'moment'
import { Button, Divider, Grid, Table } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { formatDollars } from 'context/dollar-utils'
import useProject from 'context/project/use'
import {
  DELETE_PAYOUT,
  DeletePayoutPayload,
  DeletePayoutVariables,
} from 'queries/payout'
import { toTitleCase } from 'views/utils'

import PayoutsModal from './create-update-modal'

const Payouts = (props: RouteComponentProps<{ project_id: string }>) => {
  const { project, refetch } = useProject()
  const payoutsSorted = sortBy(project.payouts, (p) => p.createdAt).reverse()

  const [deletePayout] = useMutation<
    DeletePayoutPayload,
    DeletePayoutVariables
  >(DELETE_PAYOUT, {
    onCompleted: () => {
      refetch()
    },
  })

  const DeletePayoutModal = ({ payoutId }: { payoutId: string }) => {
    const [showDeleteModal, toggleDeleteModal] = useState(false)
    return (
      <>
        <Button
          icon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
          floated="right"
          onClick={() => toggleDeleteModal(true)}
        />
        <ConfirmationModal
          firstTitle="Are you sure you want to delete the payout?"
          onCancel={() => toggleDeleteModal(false)}
          onConfirm={async () => {
            await deletePayout({
              variables: {
                where: {
                  id: payoutId,
                },
              },
            })
            toggleDeleteModal(false)
          }}
          open={showDeleteModal}
        />
      </>
    )
  }

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Payouts" />
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <h5>Payouts</h5>
          </Grid.Column>
          <Grid.Column>
            <PayoutsModal
              projectId={props.project_id}
              refetch={refetch}
              trigger={
                <Button floated="right" content="Create New Payout" primary />
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Paid At</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {payoutsSorted.map((p) => (
                  <Table.Row key={p.id}>
                    <Table.Cell width={2}>{p.description}</Table.Cell>
                    <Table.Cell width={2}>{formatDollars(p.amount)}</Table.Cell>
                    <Table.Cell width={2}>
                      {p.createdAt ? moment(p.createdAt).format('LL') : ''}
                    </Table.Cell>
                    <Table.Cell width={2}>{toTitleCase(p.type)}</Table.Cell>
                    <Table.Cell>
                      <DeletePayoutModal payoutId={p.id ?? ''} />
                      <PayoutsModal
                        payout={p}
                        projectId={props.project_id}
                        refetch={refetch}
                        trigger={
                          <Button
                            floated="right"
                            icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
                            primary
                          />
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProjectLayout>
  )
}

export default Payouts
