import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { DropdownProps, Form } from 'semantic-ui-react'

import CurrencyInput from 'components/shared/currency-input'
import { formatDollars } from 'context/dollar-utils'
import { Modal } from 'design-system/components/modal'
import {
  CREATE_PAYOUT,
  CreatePayoutPayload,
  CreatePayoutVariables,
  UPDATE_PAYOUT,
  UpdatePayoutPayload,
  UpdatePayoutVariables,
} from 'queries/payout'
import { StyledForm } from 'styles/admin/main'
import { Payout } from 'types/payout'

interface PayoutProps {
  payout?: Payout
  projectId?: string
  refetch?: () => void
  trigger: JSX.Element
}

const PayoutsModal = (props: PayoutProps) => {
  const [showModal, toggleModal] = useState(false)

  const [payout, setPayout] = useState<Payout>(
    props.payout || {
      amount: 0,
      description: 'Rendering',
      type: 'RENDERING',
    },
  )

  const closeModal = () => {
    toggleModal(false)
    setPayout(
      props.payout || {
        amount: 0,
        description: 'Rendering',
        type: 'RENDERING',
      },
    )
  }

  const [createPayout, { loading: loadingCreate }] = useMutation<
    CreatePayoutPayload,
    CreatePayoutVariables
  >(CREATE_PAYOUT, { onCompleted: props.refetch })

  const [updatePayout, { loading: loadingUpdate }] = useMutation<
    UpdatePayoutPayload,
    UpdatePayoutVariables
  >(UPDATE_PAYOUT, { onCompleted: props.refetch })

  const onChangePrice = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPayout({
      ...payout,
      [name]: Math.round(Number(value.replace(/[, $]+/g, '')) * 100),
    })
  }

  const onChangeType = (_: React.SyntheticEvent, { value }: DropdownProps) => {
    setPayout({
      ...payout,
      type: value as string,
    })
  }

  const onChangeDescription = (
    _: React.SyntheticEvent,
    { value }: { value: string },
  ) => {
    setPayout({
      ...payout,
      description: value,
    })
  }

  const handleSubmit = async () => {
    if (props.payout?.id)
      await updatePayout({
        variables: {
          where: { id: props.payout.id },
          data: {
            ...payout,
          },
        },
      })
    else
      await createPayout({
        variables: {
          data: {
            ...payout,
            project: {
              connect: { id: props.projectId ?? '' },
            },
          },
        },
      })
    closeModal()
  }

  return (
    <Modal
      onClose={closeModal}
      open={showModal}
      size="small"
      title={payout.id ? 'Edit Payout' : 'Create Payout'}
      saveButton={{
        loading: loadingCreate || loadingUpdate,
        onClick: handleSubmit,
      }}
      trigger={props.trigger}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Field>
          <label>Type</label>
          <Form.Dropdown
            name="type"
            onChange={onChangeType}
            options={[
              {
                key: 'r',
                text: 'Rendering',
                value: 'RENDERING',
              },
              {
                key: 's',
                text: 'Supplier',
                value: 'SUPPLIER',
              },
              {
                key: 'o',
                text: 'Other',
                value: 'OTHER',
              },
            ]}
            placeholder="Payout Type"
            selection
            value={payout.type}
          />
        </Form.Field>
        <Form.Field required>
          <label>Description</label>
          <Form.Input
            name="description"
            type="text"
            onChange={onChangeDescription}
            value={payout.description}
          />
        </Form.Field>
        <Form.Field>
          <label>Amount</label>
          <CurrencyInput
            defaultValue={formatDollars(payout.amount)}
            inputMode="numeric"
            maskOptions={{}}
            name="amount"
            onChange={onChangePrice}
          />
        </Form.Field>
      </StyledForm>
    </Modal>
  )
}

export default PayoutsModal
