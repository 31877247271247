import React from 'react'

import StatusLabel from 'design-system/components/status-label'
import { Delivery } from 'types/delivery'
import { StatusMode } from 'types/utils'

const CellContentsDamages = ({ delivery }: { delivery: Delivery }) => {
  let damagesStatus = {
    type: StatusMode.OpenForEdit,
    text: 'No damages selection',
    description: '',
  }
  if (
    delivery.issues?.length > 0 ||
    delivery.data?.damagesSelection === 'damaged'
  )
    damagesStatus = {
      type: StatusMode.Submitted,
      text: 'Issues resolved',
      description:
        delivery.issues?.length > 0
          ? `${delivery.issues?.length} reported | ${
              delivery.issues.filter((d) => !d.supplierOrderResolving).length
            } unresolved`
          : '',
    }
  else if (delivery.data?.damagesSelection === 'no-damage')
    damagesStatus = {
      type: StatusMode.Confirmed,
      text: 'No issues reported',
      description:
        delivery.files?.length > 0
          ? `${delivery.files?.length} delivery photos uploaded`
          : '',
    }

  return (
    <StatusLabel
      type={damagesStatus.type}
      text={damagesStatus.text}
      description={damagesStatus.description}
    />
  )
}

export default CellContentsDamages
