import React from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectLayout from 'components/admin/project/layout'
import ProjectOrderSamplesManyProvider from 'context/project/order-samples/provider-many'

interface ProjectOrderSamplesManyIndexProps
  extends RouteComponentProps<{
    project_id: string
  }> {
  children?: React.ReactNode
}

export default (props: ProjectOrderSamplesManyIndexProps) => (
  <ProjectLayout>
    <ProjectOrderSamplesManyProvider>
      {props.children}
    </ProjectOrderSamplesManyProvider>
  </ProjectLayout>
)
