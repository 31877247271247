// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useCallback, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import * as BABYLON from 'babylonjs'
import { Menu } from 'semantic-ui-react'

import { notifySuccess } from 'context/notifications/trigger'
import DesignProvider from 'context/planner/design-provider'
import useDesign from 'context/planner/design-use'
import { RenderMode, ViewMode } from 'context/planner/types'
import { SectionViewBase } from 'planner/objects/annotations/section-view'
import { CameraScene } from 'planner/objects/camera'
import { MeshScene } from 'planner/objects/mesh'
import {
  NodeBase,
  NodeOption,
  NodeParameter,
  NodeScene,
} from 'planner/objects/node'
import { ProductBase } from 'planner/objects/product'
import * as ProductFactory from 'planner/objects/product-factory'
import NobiliaProductBase from 'planner/objects/products/nobilia/base'
import * as RoomElementFactory from 'planner/objects/room-element-factory'
import { ControlSetProps } from 'planner/ui/controls/control-set'
import ProductsMenu from 'planner/ui/controls/create-menus/products'
import NodeOptionPanel from 'planner/ui/controls/node-option-panel'
import PresetCreatePanel from 'planner/ui/controls/preset-create-panel'
import ProductOptionPanel from 'planner/ui/controls/product-option-panel'
import PlannerLayout from 'planner/ui/layout'
import EstimateModal from 'planner/ui/modals/estimate-modal'
import ProductListModal from 'planner/ui/modals/product-list-modal'
import PlannerPlan from 'planner/ui/planner-plan'
import PlannerScene from 'planner/ui/planner-scene'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import * as nodeUtils from 'planner/utils/node-utils'
import * as plannerUtils from 'planner/utils/planner-utils'
import { PlannerColumn, StyledMenu, StyledSegment } from 'styles/planner/main'
import { Icons } from 'styles/planner/system'

const sceneCache: NodeScene[] = []

type PlannerProps = RouteComponentProps<{ room_id: string; design_id: string }>

const Planner = (props: PlannerProps) => {
  const [loader, setLoader] = useState<{
    show: boolean
    style: boolean
  }>({
    show: false,
    style: false,
  })
  const {
    activeCamera,
    activeMenu,
    activeSectionView,
    cameraPosition,
    cameraTarget,
    catalog,
    countertopProductClass,
    defaultArcRotateCamera,
    defaultTargetCamera,
    defaultUniversalCamera,
    defaultPosition,
    defaultPositionWall,
    defaultRotation,
    defaultRotationWall,
    design,
    designLoaded,
    dispatch,
    measurementUnit,
    moveEnabled,
    moveFrom,
    plinthProductClass,
    recenterCameraTriggeredAt,
    recenterPlanTriggeredAt,
    renderMode,
    room,
    sceneRelationshipGraph,
    sceneState,
    screenshotTriggeredAt,
    sectionViewMode,
    selectedNode,
    showEstimate,
    showProductList,
    snapToProducts,
    updateDesign,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
    viewMode,
  } = useDesign()

  const onKeydownFunc = useCallback(
    (event: KeyboardEvent) => {
      // \ key
      if (event.keyCode === 220 && viewMode === ViewMode.SCENE) {
        dispatch({
          type: 'set_view_scene',
          camera: activeCamera.isUniversalCamera()
            ? defaultArcRotateCamera
            : defaultUniversalCamera,
        })
      }
      // \ key
      if (event.keyCode === 220 && viewMode === ViewMode.SECTION) {
        dispatch({
          type: 'set_view_section',
          camera: activeCamera.isUniversalCamera()
            ? defaultTargetCamera
            : defaultUniversalCamera,
        })
      }
    },
    [viewMode, activeCamera],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeydownFunc, false)
    return () => {
      document.removeEventListener('keydown', onKeydownFunc, false)
    }
  }, [viewMode, activeCamera])

  useEffect(() => {
    if (!designLoaded) {
      dispatch({
        type: 'load_design',
        countertopProductClass,
        plinthProductClass,
        nodesToAdd: [
          ...(room.elements?.length
            ? RoomElementFactory.roomFromGraphQL(room)
            : []),
          ...plannerUtils.parseProductsToScene([
            ...design.nobiliaProducts,
            ...design.genericProducts,
          ]),
        ],
      })
    }
  }, [design])

  useEffect(() => {
    if (viewMode !== ViewMode.PLAN) {
      const timer1 = setTimeout(() => {
        setLoader({
          show: true,
          style: false,
        })
      }, 1600)
      const timer2 = setTimeout(() => {
        setLoader({
          show: false,
          style: false,
        })
      }, 2000)

      return () => {
        clearTimeout(timer1)
        clearTimeout(timer2)
      }
    }
  }, [viewMode])

  useEffect(() => {
    recenter({
      camera: true,
      plan: true,
    })
  }, [designLoaded])

  const recenter = (params?: { plan: boolean; camera: boolean }) => {
    const {
      cameraPosition,
      cameraTarget,
      viewBoxBase,
      viewBoxPanX,
      viewBoxPanY,
    } = plannerUtils.recenterScene(sceneState)
    if (cameraPosition)
      dispatch({
        type: 'set_view_parameters',
        cameraPosition,
        cameraTarget,
        recenterCameraTriggeredAt: params.camera ? new Date() : undefined,
        recenterPlanTriggeredAt: params.plan ? new Date() : undefined,
        viewBoxBase,
        viewBoxPanX,
        viewBoxPanY,
      })
  }

  const onDelete = (): void => {
    dispatch({
      type: 'delete_selected_node',
      countertopProductClass,
      plinthProductClass,
    })
  }

  const onAdditionalItemChange = async (changedAdditionalItem: ProductBase) => {
    const selectedProduct = selectedNode as NobiliaProductBase
    const newProduct = ProductFactory.copy(selectedProduct, {
      changedAdditionalItem: changedAdditionalItem,
    })

    dispatch({
      type: 'change_nodes',
      countertopProductClass,
      nodesToAdd: [newProduct],
      nodesToRemove: [selectedProduct],
      plinthProductClass,
      selectedNode: newProduct,
    })
  }

  const onOptionChange = (changedOption: NodeOption[]): void => {
    if (selectedNode.isProduct()) {
      const selectedProduct = selectedNode as ProductBase
      const newProduct = ProductFactory.copy(selectedProduct, {
        changedOptions: changedOption,
      })
      const newChildren = selectedProduct.children.map((c) => {
        const newChild = ProductFactory.copy(c as ProductBase)
        newChild.setParent(newProduct)
        return newChild
      })
      dispatch({
        type: 'change_nodes',
        countertopProductClass,
        nodesToAdd: [newProduct, ...newChildren],
        nodesToRemove: [selectedProduct, ...selectedProduct.children],
        plinthProductClass,
        selectedNode: newProduct,
      })
      return
    }
    console.log(
      `onOptionChange does not support nodes of type ${selectedNode.getType()}`,
    )
  }

  const onParameterChange = (changedParameter: NodeParameter): void => {
    if (selectedNode.isProduct()) {
      const selectedProduct = selectedNode as ProductBase
      const newProduct = ProductFactory.copy(selectedProduct, {
        changedParameters: [changedParameter],
      })
      const newChildren = selectedProduct.children.map((c) => {
        const newChild = ProductFactory.copy(c as ProductBase)
        newChild.setParent(newProduct)
        return newChild
      })
      dispatch({
        type: 'change_nodes',
        countertopProductClass,
        nodesToAdd: [newProduct, ...newChildren],
        nodesToRemove: [selectedProduct, ...selectedProduct.children],
        plinthProductClass,
        selectedNode: newProduct,
      })
      return
    }

    // don't replace camera
    if (selectedNode.isCamera()) {
      selectedNode.applyNodeParameters([changedParameter])
      return
    }

    console.log(
      `onParameterChange does not support nodes of type ${selectedNode.getType()}`,
    )
  }

  const onCreate = (products: ProductBase[]): void => {
    dispatch({
      type: 'change_nodes',
      countertopProductClass,
      plinthProductClass,
      nodesToAdd: products,
      nodesToRemove: [],
      selectedNode: null,
    })
  }

  const onMouseDown = (_: React.MouseEvent, n: NodeBase) => {
    if (!n || n.isRoomElement()) {
      if (!selectedNode) return
      dispatch({
        type: 'deselect_node',
      })
    } else if (n.isSectionView()) {
      dispatch({
        type: 'set_active_section_view',
        activeSectionView: n as SectionViewBase,
      })
    } else {
      dispatch({
        type: 'pick_node',
        nodeName: n.getNodeName(),
      })
    }
  }

  const onPointerDown = (
    scene: BABYLON.Scene,
    canvas: HTMLCanvasElement,
  ): void => {
    const pickResult = scene.pick(scene.pointerX, scene.pointerY, (node) => {
      const parentNode = nodeUtils.getParent(node)
      const formNode = nodeUtils.findNodeByName(sceneState, parentNode.name)
      if (!formNode) return false
      return !formNode.isRoomElement()
    })

    if (pickResult.hit) {
      const selected = nodeUtils.getParent(pickResult.pickedMesh)
      dispatch({
        type: 'pick_node',
        nodeName: selected.name,
      })
      const cameraScene = nodeUtils.findNodeSceneByName(
        sceneCache,
        activeCamera.getNodeName(),
      ) as CameraScene
      cameraScene.detachControl(canvas)
    }

    if (!pickResult.hit && selectedNode) {
      dispatch({
        type: 'deselect_node',
      })
    }
  }

  const onMouseMove = (
    _: React.MouseEvent,
    pointR: [number, number, number],
  ) => {
    const pointL = [-pointR[0], pointR[1], pointR[2]]
    if (selectedNode.isProduct()) {
      const selectedProduct = selectedNode as ProductBase
      let [startPos, startMouse] = moveFrom || [null, null]
      if (startPos === null || startMouse === null) {
        const pos = new BABYLON.Vector3().copyFrom(
          selectedProduct.getPosition(),
        )
        startPos = pos
        startMouse = [pointL[0], pointL[2]]
      }

      selectedProduct.setXAndZPosition(
        startPos
          .add(BABYLON.Vector3.Forward().scale(pointL[2] - startMouse[1]))
          .add(BABYLON.Vector3.Right().scale(pointL[0] - startMouse[0])),
      )
      sceneRelationshipGraph.remove([selectedProduct])
      sceneRelationshipGraph.add([selectedProduct])

      const nr = sceneRelationshipGraph.graph[selectedProduct.idLocal]
      const position = selectedProduct.getPosition()

      if (nr.snapVectorWalls.length() > 0.1) {
        selectedProduct.setPosition(position.add(nr.snapVectorWalls))
        nr.snapVectorWalls = BABYLON.Vector3.Zero()
        sceneRelationshipGraph.remove([selectedProduct])
        sceneRelationshipGraph.add([selectedProduct])
      }

      if (snapToProducts && nr.snapVectorProducts.length() > 0.1) {
        selectedProduct.setPosition(position.add(nr.snapVectorProducts))
        nr.snapVectorProducts = BABYLON.Vector3.Zero()
      }

      dispatch({
        type: 'do_move',
        moveFrom: moveFrom || [startPos, startMouse],
        productInMotion: selectedProduct,
      })
    }
  }

  const onMouseUp = (_: React.MouseEvent) => {
    if (moveEnabled) {
      dispatch({
        type: 'disable_move',
        countertopProductClass,
        plinthProductClass,
      })
    }
  }

  const onPointerMove = (
    scene: BABYLON.Scene,
    _: HTMLCanvasElement,
    dimensions: {
      width: number
      height: number
    },
  ): void => {
    if (moveEnabled && activeCamera.isTargetCamera() && activeSectionView) {
      if (selectedNode.isProduct()) {
        const selectedProduct = selectedNode as ProductBase
        const selectedMeshScene = nodeUtils.findNodeSceneByName(
          sceneCache,
          selectedProduct.getNodeName(),
        ) as MeshScene
        const cameraScene = nodeUtils.findNodeSceneByName(
          sceneCache,
          activeCamera.getNodeName(),
        ) as CameraScene
        const orthoCamera = cameraScene.cameraBabylon as BABYLON.TargetCamera
        const x =
          orthoCamera.orthoLeft +
          (orthoCamera.orthoRight - orthoCamera.orthoLeft) *
            (scene.pointerX / dimensions.width)
        const y =
          orthoCamera.orthoBottom +
          ((orthoCamera.orthoTop - orthoCamera.orthoBottom) *
            (dimensions.height - scene.pointerY)) /
            dimensions.height
        let [startPos, startMouse] = moveFrom || [null, null]
        if (startPos === null || startMouse === null) {
          const pos = new BABYLON.Vector3().copyFrom(
            selectedProduct.getPosition(),
          )
          startPos = pos
          startMouse = [x, y]
        }
        selectedProduct.setPosition(
          startPos
            .add(activeSectionView.getLeft().scale(x - startMouse[0]))
            .add(
              snapToProducts
                ? BABYLON.Vector3.Zero()
                : BABYLON.Vector3.Up().scale(y - startMouse[1]),
            ),
        )
        sceneRelationshipGraph.remove([selectedProduct])
        sceneRelationshipGraph.add([selectedProduct])
        let position = selectedProduct.getPosition()

        const nr = sceneRelationshipGraph.graph[selectedProduct.idLocal]

        if (nr.snapVectorWalls.length() > 0.1) {
          position = position.add(nr.snapVectorWalls)
          selectedProduct.setPosition(position)
          nr.snapVectorWalls = BABYLON.Vector3.Zero()
          sceneRelationshipGraph.remove([selectedProduct])
          sceneRelationshipGraph.add([selectedProduct])
        }

        if (snapToProducts && nr.snapVectorProducts.length() > 0.1) {
          position = position.add(nr.snapVectorProducts)
          selectedProduct.setPosition(position)
          nr.snapVectorProducts = BABYLON.Vector3.Zero()
        }

        selectedMeshScene.setPosition(position)

        dispatch({
          type: 'do_move',
          moveFrom: moveFrom || [startPos, startMouse],
          productInMotion: selectedProduct,
        })
      }
    }
  }

  const onPointerUp = (_: BABYLON.Scene, canvas: HTMLCanvasElement): void => {
    if (moveEnabled && selectedNode) {
      const selectedScene = nodeUtils.findNodeSceneByName(
        sceneCache,
        selectedNode.getNodeName(),
      )
      selectedScene.sync()
      dispatch({
        type: 'disable_move',
        countertopProductClass,
        plinthProductClass,
      })
    }
    const cameraScene = nodeUtils.findNodeSceneByName(
      sceneCache,
      activeCamera.getNodeName(),
    ) as CameraScene
    cameraScene.attachControlIfAllowed(canvas)
  }

  const onSave = async () => {
    await updateDesign({
      data: {
        ...plannerUtils.sceneToGraphQL(design, sceneState),
      },
      where: {
        id: design.id,
      },
    }).then(() => notifySuccess('Design saved'))
  }

  const renderOptionPanel = () => {
    if (!selectedNode)
      return (
        <>
          {activeMenu === 'presets' && <PresetCreatePanel />}
          {activeMenu === 'products' && (
            <ProductsMenu
              defaultPosition={defaultPosition}
              defaultPositionWall={defaultPositionWall}
              defaultRotation={defaultRotation}
              defaultRotationWall={defaultRotationWall}
              onCreate={onCreate}
            />
          )}
        </>
      )
    if (selectedNode.isProduct())
      return (
        <ProductOptionPanel
          onAdditionalItemChange={onAdditionalItemChange}
          onDelete={onDelete}
          onOptionChange={onOptionChange}
          onParameterChange={onParameterChange}
          product={selectedNode as ProductBase}
          sceneRelationshipGraph={sceneRelationshipGraph}
        />
      )
    return (
      <NodeOptionPanel
        node={selectedNode}
        nodeOptions={selectedNode.getNodeOptions()}
        nodeParameters={selectedNode.getNodeParameters(sceneRelationshipGraph)}
        onDelete={onDelete}
        onOptionChange={onOptionChange}
        onParameterChange={onParameterChange}
      />
    )
  }

  const controlSetPlan: ControlSetProps['buttonsGroupTwo'] = [
    {
      active: false,
      onClick: () => {
        dispatch({
          type: 'toggle_measurement_unit',
          measurementUnit:
            measurementUnit === MeasurementUnit.Millimeters
              ? MeasurementUnit.Inches
              : MeasurementUnit.Millimeters,
        })
      },
      icon: (
        <svg width="32" height="32">
          {/* TODO Replace icon once it is ready */}
          <image href={Icons.SectionView} width="32" height="32" />
        </svg>
      ),
      key: 'Toggle Measurement Unit',
    },

    {
      active: snapToProducts,
      onClick: () => {
        dispatch({
          type: 'toggle_snap_to_products',
        })
      },
      icon: (
        <svg width="32" height="32">
          <image
            href={snapToProducts ? Icons.SnapActive : Icons.Snap}
            width="32"
            height="32"
          />
        </svg>
      ),
      key: 'Toggle Snapping',
    },
    {
      active: sectionViewMode,
      onClick: () => {
        dispatch({
          type: 'toggle_section_view_mode',
        })
      },
      icon: (
        <svg width="32" height="32">
          <image
            href={sectionViewMode ? Icons.SectionViewActive : Icons.SectionView}
            width="32"
            height="32"
          />
        </svg>
      ),
      key: 'Toggle Section View',
    },
    {
      active: false,
      onClick: () => recenter({ plan: true, camera: false }),
      icon: (
        <svg width="32" height="32">
          <image href={Icons.Center} width="32" height="32" />
        </svg>
      ),
      key: 'Recenter',
    },
  ]

  const controlSetScene: ControlSetProps['buttonsGroupTwo'] = [
    {
      active: renderMode === RenderMode.LINES,
      onClick: () => {
        dispatch({
          type: 'toggle_materials',
        })
      },
      icon: (
        <svg width="32" height="32">
          <image
            href={
              renderMode === RenderMode.LINES ? Icons.LineActive : Icons.Line
            }
            width="32"
            height="32"
          />
        </svg>
      ),
      key: 'Toggle Materials',
    },
    ...(viewMode === ViewMode.SCENE
      ? [
          {
            active: false,
            onClick: () => recenter({ plan: false, camera: true }),
            icon: (
              <svg width="32" height="32">
                <image href={Icons.Center} width="32" height="32" />
              </svg>
            ),
            key: 'Recenter',
          },
        ]
      : []),
  ]

  return (
    <>
      <PlannerLayout
        controlSetProps={{
          buttonsGroup: [
            {
              active: viewMode === ViewMode.SCENE,
              onClick: () => {
                setLoader({
                  show: viewMode === ViewMode.PLAN,
                  style: viewMode === ViewMode.PLAN,
                })
                dispatch({
                  type: 'set_view_scene',
                  camera: activeCamera.isTargetCamera()
                    ? defaultUniversalCamera
                    : activeCamera,
                  renderMode: RenderMode.MATERIALS,
                })
              },
              icon: (
                <svg width="32" height="32">
                  <image
                    href={
                      viewMode === ViewMode.SCENE
                        ? Icons.View3DActive
                        : Icons.View3D
                    }
                    width="32"
                    height="32"
                  />
                </svg>
              ),
              key: 'Perspective 3D View',
            },
            {
              active: viewMode === ViewMode.PLAN,
              onClick: () => {
                dispatch({
                  type: 'set_view_plan',
                })
              },
              icon: (
                <svg width="32" height="32">
                  <image
                    href={
                      viewMode === ViewMode.PLAN
                        ? Icons.ViewTopActive
                        : Icons.ViewTop
                    }
                    width="32"
                    height="32"
                  />
                </svg>
              ),
              key: 'Top View',
            },
            {
              active: viewMode === ViewMode.SECTION,
              disabled: !activeSectionView,
              onClick: () => {
                setLoader({
                  show: viewMode === ViewMode.PLAN,
                  style: viewMode === ViewMode.PLAN,
                })
                dispatch({
                  type: 'set_view_section',
                  camera: defaultTargetCamera,
                  renderMode: RenderMode.MATERIALS,
                })
              },
              icon: (
                <svg width="32" height="32">
                  <image
                    href={
                      viewMode === ViewMode.SECTION
                        ? Icons.ViewElevationActive
                        : Icons.ViewElevation
                    }
                    width="32"
                    height="32"
                  />
                </svg>
              ),
              key: 'Elevations',
            },
          ],
          buttonsGroupTwo:
            viewMode === ViewMode.PLAN ? controlSetPlan : controlSetScene,
        }}
        headerProps={{
          buttons: [
            {
              active: false,
              icon: 'arrow-left',
              key: 'arrow left',
              onClick: () =>
                (window.location.href = `/admin/rooms/${props.room_id}`),
            },
            {
              active: false,
              icon: 'clipboard-list',
              key: 'clipboard list',
              onClick: () => dispatch({ type: 'toggle_product_list' }),
            },
            {
              active: false,
              icon: 'paste',
              key: 'paste',
              onClick: () => dispatch({ type: 'toggle_estimate' }),
            },
          ],
          menus: [
            {
              key: 'File',
              text: 'File',
              items: [
                {
                  key: 'save',
                  text: 'Save',
                  onClick: onSave,
                },
                {
                  key: 'upload',
                  text: 'Create Order',
                  onClick: async () => {
                    const { data } = await updateDesign({
                      data: {
                        metadata: {
                          ...design.metadata,
                          ediSentAt: new Date(),
                        },
                      },
                      where: {
                        id: design.id,
                      },
                    })
                    window.open(
                      `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                        data.updateOneDesign.metadata.zipUrlForEdi,
                      )}`,
                    )
                  },
                },
                {
                  key: 'erase',
                  text: 'Clear Scene',
                  onClick: () => {
                    dispatch({
                      type: 'change_nodes',
                      countertopProductClass,
                      plinthProductClass,
                      nodesToAdd: [],
                      nodesToRemove: sceneState.filter(
                        (n) => n.isProduct() || n.isVolume(),
                      ),
                      selectedNode: null,
                    })
                  },
                },
              ],
            },
          ],
        }}
        loader={loader}
        main={
          <>
            <PlannerColumn show={viewMode !== ViewMode.PLAN}>
              <PlannerScene
                activeCamera={activeCamera}
                activeSectionView={activeSectionView}
                cameraPosition={cameraPosition}
                cameraTarget={cameraTarget}
                onPointerDown={onPointerDown}
                onPointerMove={onPointerMove}
                onPointerUp={onPointerUp}
                recenterTriggeredAt={recenterCameraTriggeredAt}
                renderMode={renderMode}
                sceneActive={viewMode !== ViewMode.PLAN}
                sceneCache={sceneCache}
                sceneRelationshipGraph={sceneRelationshipGraph}
                sceneState={sceneState}
                screenshotTriggeredAt={screenshotTriggeredAt}
                selectedNode={selectedNode}
              />
            </PlannerColumn>
            <PlannerColumn show={viewMode === ViewMode.PLAN}>
              <PlannerPlan
                activeSectionView={activeSectionView}
                measurementUnit={measurementUnit}
                moveEnabled={moveEnabled}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                recenterTriggeredAt={recenterPlanTriggeredAt}
                sceneRelationshipGraph={sceneRelationshipGraph}
                sceneState={sceneState}
                sectionViewMode={sectionViewMode}
                selectedNode={selectedNode}
                viewBoxBase={viewBoxBase}
                viewBoxPanX={viewBoxPanX}
                viewBoxPanY={viewBoxPanY}
              />
            </PlannerColumn>
          </>
        }
        sidebar={
          <>
            <StyledMenu attached="top" fluid widths={2}>
              <Menu.Item
                name="Presets"
                active={activeMenu === 'presets'}
                onClick={() =>
                  dispatch({
                    type: 'set_active_menu',
                    activeMenu: 'presets',
                  })
                }
              />
              <Menu.Item
                name="Products"
                active={activeMenu === 'products'}
                onClick={() =>
                  dispatch({
                    type: 'set_active_menu',
                    activeMenu: 'products',
                  })
                }
              />
            </StyledMenu>
            <StyledSegment>{renderOptionPanel()}</StyledSegment>
          </>
        }
      />
      <ProductListModal
        onCloseModal={() => dispatch({ type: 'toggle_product_list' })}
        open={showProductList}
        products={sceneState.filter((n) => n.isProduct()) as ProductBase[]}
      />
      <EstimateModal
        catalog={catalog}
        onCloseModal={() => dispatch({ type: 'toggle_estimate' })}
        onPopulate={(nodes: NodeBase[]) => {
          dispatch({
            type: 'change_nodes',
            countertopProductClass,
            nodesToAdd: nodes,
            nodesToRemove: [],
            plinthProductClass,
            selectedNode: null,
          })
        }}
        open={showEstimate}
        position={
          activeSectionView?.data?.points?.[1]
            ? new BABYLON.Vector3(...activeSectionView.data.points[1])
            : BABYLON.Vector3.Zero()
        }
        rotation={
          activeSectionView
            ? activeSectionView.getRotation()
            : BABYLON.Vector3.Zero()
        }
      />
    </>
  )
}

export default (props: PlannerProps) => (
  <DesignProvider design_id={props.design_id} room_id={props.room_id}>
    <Planner {...props} />
  </DesignProvider>
)
