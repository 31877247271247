import { useQuery } from '@apollo/client'

import {
  FIND_MANY_MESSAGE_DASHBOARD,
  FindManyMessagePayload,
  FindManyMessageVariables,
} from 'queries/message'
import { Message } from 'types/message'
import { ProjectStaffRole } from 'types/project-staff'

const useMessageMany = (
  userId: string,
  projectStaffRole?: ProjectStaffRole,
) => {
  const {
    data: { messages } = { messages: [] as Message[] },
    loading,
    refetch,
  } = useQuery<FindManyMessagePayload, FindManyMessageVariables>(
    FIND_MANY_MESSAGE_DASHBOARD,
    {
      variables: {
        take: 300,
        orderBy: { createdAt: 'desc' },
        where: {
          project: {
            staff: {
              some: {
                staffMemberId: {
                  equals: userId,
                },
                isSubscribed: {
                  equals: true,
                },
                ...(projectStaffRole && {
                  staffMemberRole: {
                    equals: projectStaffRole,
                  },
                }),
              },
            },
          },
          newFor: {
            some: {
              id: {
                equals: userId,
              },
            },
          },
        },
      },
    },
  )

  return {
    messages,
    loading,
    refetch,
  }
}

export default useMessageMany
