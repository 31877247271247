import React from 'react'

import { RouteComponentProps } from '@reach/router'

import SupplierOrders from 'components/admin/supplier-order/index-many'
import useCustomerSupport from 'context/dashboard/customer-support/use'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import {
  FORMStatus,
  SupplierOrderType,
} from 'context/supplier-order/utils-many'

import CustomerSupportLayout from './layout'

const CustomerSupportRemedialsNobilia = (_: RouteComponentProps) => {
  const { userId, userName } = useCustomerSupport()
  return (
    <CustomerSupportLayout>
      <SupplierOrderManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          statusesFORM: [FORMStatus.AwaitingNobilia],
          type: [SupplierOrderType.Remedial, SupplierOrderType.AddOn],
          take: 50,
        }}
        skipLoader
      >
        <SupplierOrders />
      </SupplierOrderManyProvider>
    </CustomerSupportLayout>
  )
}

export default CustomerSupportRemedialsNobilia
