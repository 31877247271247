import React from 'react'

import { RouteComponentProps } from '@reach/router'

import DesignProvider from 'context/design/provider'

interface RoomsProps
  extends RouteComponentProps<{
    project_id: string
    room_id: string
    design_id: string
  }> {
  children?: React.ReactNode
}

export default (props: RoomsProps) => (
  <DesignProvider designId={props.design_id ?? ''} includeLastDesign>
    {props.children}
  </DesignProvider>
)
