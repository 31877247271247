import React from 'react'

import { Card, Divider } from 'semantic-ui-react'

import AddressFormat from 'components/shared/address-format'
import useProject from 'context/project/use'

const AddressCard = () => {
  const { project } = useProject()
  return (
    <Card fluid>
      <Card.Content>
        <h6>Address - To ship kitchen</h6>
        <Divider />
        <AddressFormat address={project.projectAddress} />
      </Card.Content>
    </Card>
  )
}

export default AddressCard
