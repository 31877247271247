import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_ONE_APPLIANCE_PACK,
  CreateOneAppliancePackPayload,
  CreateOneAppliancePackVariables,
  DELETE_ONE_APPLIANCE_PACK,
  DeleteOneAppliancePackPayload,
  DeleteOneAppliancePackVariables,
  UPDATE_ONE_APPLIANCE_PACK,
  UpdateOneAppliancePackPayload,
  UpdateOneAppliancePackVariables,
} from 'queries/appliance-pack'

import { AppliancePackManyContext } from './provider-many'

const useAppliancePackManyMutate = () => {
  const { refetch } = useContext(AppliancePackManyContext)
  const [createAppliancePackAndRefetch, { loading: loadingCreate }] =
    useMutation<CreateOneAppliancePackPayload, CreateOneAppliancePackVariables>(
      CREATE_ONE_APPLIANCE_PACK,
      {
        onCompleted: refetch,
      },
    )

  const [deleteAppliancePackAndRefetch, { loading: loadingDelete }] =
    useMutation<DeleteOneAppliancePackPayload, DeleteOneAppliancePackVariables>(
      DELETE_ONE_APPLIANCE_PACK,
      {
        onCompleted: refetch,
      },
    )

  const [updateAppliancePackAndRefetch, { loading: loadingUpdate }] =
    useMutation<UpdateOneAppliancePackPayload, UpdateOneAppliancePackVariables>(
      UPDATE_ONE_APPLIANCE_PACK,
      {
        onCompleted: refetch,
      },
    )

  return {
    createAppliancePackAndRefetch,
    deleteAppliancePackAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateAppliancePackAndRefetch,
  }
}

export default useAppliancePackManyMutate
