import React from 'react'

import moment from 'moment'
import { Table } from 'semantic-ui-react'

import useRoomMany from 'context/room/use-many'
import { getRoomTypeWithDescription, parsePackStatus } from 'context/room/utils'
import Button from 'design-system/components/button'

import { ProjectOwnersCellContents } from '../project/project-owners/table-cell-content'
import { ProjectStaffCellContents } from '../project/project-staff/table-cell-content'

const RoomsTable = () => {
  const { rooms, queryFilters, setQueryFilters } = useRoomMany()
  const { sortBy, sortDirection } = queryFilters

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Project</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Room</Table.HeaderCell>
          <Table.HeaderCell>Confirmed</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedStatus' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedStatus',
                sortDirection:
                  sortBy === 'derivedStatus' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Installation Pack
          </Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rooms.map((room) => {
          const project = room.project
          if (!project) {
            console.warn('No project found, skipping', room)
            return null
          }
          const {
            latestAckApprovedAt,
            installationPackLate,
            installationPackDueFormatted,
          } = parsePackStatus(room)

          return (
            <Table.Row key={room.id} error={installationPackLate}>
              <Table.Cell width={3}>
                <ProjectOwnersCellContents project={project} linkToProject />
              </Table.Cell>
              <Table.Cell width={3}>
                <ProjectStaffCellContents project={project} />
              </Table.Cell>
              <Table.Cell width={3}>
                <div>{getRoomTypeWithDescription(room)}</div>
              </Table.Cell>
              <Table.Cell width={3}>
                {latestAckApprovedAt
                  ? moment(latestAckApprovedAt).format('MMMM DD, YYYY')
                  : ''}
              </Table.Cell>
              <Table.Cell width={3}>{installationPackDueFormatted}</Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                <Button
                  color="dark"
                  kind="solid"
                  onClick={() =>
                    window.open(
                      `/admin/projects/${project.id}/rooms/${room.id}/packs`,
                    )
                  }
                  fontAwesomeIcon="eye"
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default RoomsTable
