import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import Routes from 'components/admin/route/index-many'
import SectionTitle from 'components/admin/section-title'
import RouteManyProvider from 'context/route/provider-many'

const Module = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid columns="equal">
        <SectionTitle title="Routes" />
        <Grid.Row>
          <Grid.Column>
            <RouteManyProvider
              defaultFilters={{
                includeArchived: false,
                includeProposed: false,
                sortBy: 'derivedStatus',
                sortDirection: 'ascending',
                take: 20,
              }}
              skipLoader
            >
              <Routes />
            </RouteManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
