import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useAccountExecutive from 'context/dashboard/account-executive/use'
import useCommentNoteCount from 'context/project/project-notes/use-count'
import { ScrollBarStyle } from 'styles/app/system'

const MenuAccountExecutive = () => {
  const { pathname } = useLocation()
  const { userId } = useAccountExecutive()
  const { count: newForNotesCount } = useCommentNoteCount({
    newFor: { id: userId },
  })

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="overview"
        active={pathname === `/admin/dashboard/${userId}/account-executive/`}
        onClick={() => navigate(`/admin/dashboard/${userId}/account-executive`)}
      />
      <Menu.Item
        name="prospects"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/account-executive/prospects`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/account-executive/prospects`)
        }
      >
        Prospects
      </Menu.Item>
      <Menu.Item
        name="notes"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/account-executive/notes`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/account-executive/notes`)
        }
      >
        Notes
        {!!newForNotesCount && (
          <Label className="count">{newForNotesCount}</Label>
        )}
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuAccountExecutive

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
