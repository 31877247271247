import React from 'react'

import { Container, Grid, Image } from 'semantic-ui-react'

import FileCard from 'components/shared/project/project-file/card'
import useDesign from 'context/design/use'
import useRoom from 'context/room/use'
import StyledAccordion from 'design-system/components/accordion'
import { ProjectFile } from 'types/project-file'

const DesignCustomerUploads = () => {
  const { floorPlans, roomPhotos } = useRoom()
  const { latestPlan } = useDesign()

  return (
    <StyledAccordion backgroundWhite titleText="Customer uploads">
      <Grid>
        <Grid.Row columns={3} className="row-gap">
          {latestPlan && (
            <Grid.Column key={latestPlan.id}>
              <p className="caption">Design Plan</p>
              <FileCard file={latestPlan} />
            </Grid.Column>
          )}
          {floorPlans.map((f: Partial<ProjectFile>) => (
            <Grid.Column key={f.id}>
              <p className="caption">User-uploaded Floor Plans</p>
              <FileCard file={f} navigateTo={`/admin/files/${f.id}/`} />
            </Grid.Column>
          ))}
        </Grid.Row>
        {roomPhotos.length > 0 && (
          <Grid.Row>
            <Grid.Column>
              <p className="caption">Room Photos</p>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={3}>
          {roomPhotos.map(
            (f: {
              id: React.Key
              key: string | number | boolean
              name:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
            }) => (
              <Grid.Column key={f.id} style={{ marginBottom: '30px' }}>
                <Container
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Image
                    src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                      f.key,
                    )}`}
                    style={{ marginBottom: '15px' }}
                  />
                  <strong>{f.name}</strong>
                </Container>
              </Grid.Column>
            ),
          )}
        </Grid.Row>
      </Grid>
    </StyledAccordion>
  )
}

export default DesignCustomerUploads
