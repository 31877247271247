import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import useIssueMutate from 'context/issue/use-mutate-no-context'
import { parseSupplierOrders } from 'context/project/utils'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { Design } from 'types/design'
import { Project } from 'types/project'
import { SupplierOrder } from 'types/supplier-order'

const AttachSupplierOrderModal = ({
  issueId,
  onClose,
  open,
  project,
  refetch,
}: {
  issueId: string
  onClose: () => void
  open: boolean
  project: Project
  refetch: () => void
}) => {
  const { supplierOrdersAddOn } = parseSupplierOrders(project ?? undefined)
  const { loadingUpdate, updateIssue } = useIssueMutate()

  const designs: Design[] = supplierOrdersAddOn.reduce<Design[]>((acc, so) => {
    const foundIdx = acc.findIndex((d: Design) => d.id === so.design.id)
    if (foundIdx >= 0) acc[foundIdx].supplierOrders.push(so)
    else
      acc.push({
        ...so.design,
        supplierOrders: [so],
      })
    return acc
  }, [])

  const [designResolving, setDesignResolving] = useState<Design | null>(null)
  const [supplierOrder, setSupplierOrder] = useState<SupplierOrder>()

  useEffect(() => {
    setDesignResolving(null)
  }, [open])

  if (!project) return null

  const onSave = async () => {
    await updateIssue({
      variables: {
        data: {
          designResolving: {
            connect: {
              id: designResolving?.id ?? '',
            },
          },
          supplierOrderResolving: {
            connect: {
              id: supplierOrder?.id ?? '',
            },
          },
        },
        where: {
          id: issueId,
        },
      },
    })
    refetch()
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title="Attach to existing Order"
      saveButton={{
        disabled: !designResolving || !supplierOrder,
        loading: loadingUpdate,
        onClick: onSave,
      }}
    >
      <div style={{ minHeight: '315px' }}>
        <StyledForm>
          <Form.Dropdown
            label="Design"
            options={designs?.map((d) => ({
              key: d.id,
              text: d.name,
              value: d.id,
            }))}
            onChange={(_, { value }) => {
              const design = designs.find((d) => d.id === value)
              setDesignResolving(design ?? null)
              if (design?.supplierOrders.length === 1)
                setSupplierOrder(design.supplierOrders[0])
            }}
            placeholder="Choose a design"
            search
            selection
            value={designResolving?.id}
          />
          {designResolving &&
            (designResolving.supplierOrders.length > 1 ? (
              <Form.Dropdown
                label="Supplier Order"
                options={designResolving.supplierOrders?.map((d) => ({
                  key: d.id,
                  text: d.nobiliaOrderRef,
                  value: d.id,
                }))}
                onChange={(_, { value }) =>
                  setSupplierOrder(
                    designResolving.supplierOrders.find((s) => s.id === value),
                  )
                }
                placeholder="Choose a supplier order"
                search
                selection
                value={supplierOrder?.id}
              />
            ) : (
              <Form.Field>
                <label>Supplier Order</label>
                <p>{designResolving.supplierOrders[0].nobiliaOrderRef}</p>
              </Form.Field>
            ))}
        </StyledForm>
      </div>
    </Modal>
  )
}

export default AttachSupplierOrderModal
