import { useMutation } from '@apollo/client'

import {
  CREATE_SHIPMENT,
  CreateShipmentPayload,
  CreateShipmentVariables,
  DELETE_SHIPMENT,
  DeleteShipmentPayload,
  DeleteShipmentVariables,
  UPDATE_SHIPMENT,
  UpdateShipmentPayload,
  UpdateShipmentVariables,
} from 'queries/shipment'

const useShipmentMutateNoContext = () => {
  const [createShipment, { loading: loadingCreate }] = useMutation<
    CreateShipmentPayload,
    CreateShipmentVariables
  >(CREATE_SHIPMENT)

  const [deleteShipment, { loading: loadingDelete }] = useMutation<
    DeleteShipmentPayload,
    DeleteShipmentVariables
  >(DELETE_SHIPMENT)

  const [updateShipment, { loading: loadingUpdate }] = useMutation<
    UpdateShipmentPayload,
    UpdateShipmentVariables
  >(UPDATE_SHIPMENT)

  return {
    createShipment,
    deleteShipment,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateShipment,
  }
}

export default useShipmentMutateNoContext
