import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  UPDATE_DESIGN,
  UpdateDesignPayload,
  UpdateDesignVariables,
} from 'queries/design'

import { DesignContext } from './provider'

const useDesignMutate = () => {
  const { refetch } = useContext(DesignContext)

  const [updateDesignAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateDesignPayload,
    UpdateDesignVariables
  >(UPDATE_DESIGN, {
    onCompleted: refetch,
  })

  return {
    loadingUpdate,
    updateDesignAndRefetch,
  }
}

export default useDesignMutate
