import React from 'react'

import { Grid, Button } from 'semantic-ui-react'

import PageTitle from './page-title'

interface SectionTitleProps {
  buttonLabel?: string
  onClickButton?: () => void
  subTitle?: string
  title: string
}

const SectionTitle = ({
  buttonLabel,
  onClickButton,
  subTitle,
  title,
}: SectionTitleProps) => {
  return (
    <>
      <PageTitle title={title} subTitle={subTitle} />
      <Grid.Row columns="equal">
        <Grid.Column>
          <h3>{title}</h3>
          {subTitle ? <p>{subTitle}</p> : null}
        </Grid.Column>
        {onClickButton && (
          <Grid.Column textAlign="right">
            <Grid.Row>
              <Button onClick={onClickButton} primary>
                {buttonLabel}
              </Button>
            </Grid.Row>
          </Grid.Column>
        )}
      </Grid.Row>
    </>
  )
}

export default SectionTitle
