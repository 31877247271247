import { useContext } from 'react'

import { RouteManyContext } from './provider-many'

const useRouteMany = () => {
  const {
    routes,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(RouteManyContext)

  return {
    routes,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useRouteMany
