import React from 'react'

import { Controller, UseFormReturn } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  ControlledCheckbox,
  ControlledDropdown,
  ControlledInput,
  ControlledPhoneInput,
} from 'design-system/components/form'
import { StyledForm } from 'styles/admin/main'
import {
  ProjectUserRole,
  ProjectUserRoleHomeowner,
  PROJECT_USER_ROLES,
} from 'types/project-user'
import {
  UserType,
  UserTypeHomeowner,
  USER_TYPES_NOT_FORM_EMPLOYEE,
} from 'types/user'
import { emailIsValid, capsSnakeCaseToTitleCase } from 'views/utils'

import { SearchProjectBar } from './search-project-bar'

const REQUIRED_MSG = 'This field is required'

const USER_TYPE_OPTIONS = USER_TYPES_NOT_FORM_EMPLOYEE.map((type) => ({
  key: type,
  text: capsSnakeCaseToTitleCase(type),
  value: type,
}))

const PROJECT_ROLE_OPTIONS = PROJECT_USER_ROLES.map((role) => ({
  key: role,
  text: capsSnakeCaseToTitleCase(role),
  value: role,
}))

export type IUserFields = {
  firstName: string
  lastName: string
  email: string
  phone: string
  project: string
  projectRole: ProjectUserRole
  primary: boolean
  subscribed: boolean
  type: UserType
}

export const DEFAULT_NEW_USER_VALUES: IUserFields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  project: '',
  primary: false,
  subscribed: true,
  type: UserTypeHomeowner.HOMEOWNER,
  projectRole: ProjectUserRoleHomeowner.HOMEOWNER,
}

type ConversationCreateUserProps = {
  formMethods: UseFormReturn<IUserFields>
}

export const ConversationCreateUser = ({
  formMethods,
}: ConversationCreateUserProps) => {
  const { control } = formMethods

  return (
    <StyledForm>
      <StyledFormGroup widths="equal">
        <ControlledInput
          control={control}
          rules={{ required: REQUIRED_MSG }}
          name="firstName"
          label="First Name"
          shouldUnregister
          placeholder="John"
        />
        <ControlledInput
          control={control}
          rules={{ required: REQUIRED_MSG }}
          name="lastName"
          label="Last Name"
          shouldUnregister
          placeholder="Wick"
        />
      </StyledFormGroup>

      <StyledFormGroup widths="equal">
        <ControlledInput
          control={control}
          name="email"
          label="Email"
          rules={{
            required: REQUIRED_MSG,
            validate: (value) =>
              emailIsValid(value as string) ||
              'Please enter a valid email address',
          }}
          shouldUnregister
          placeholder="john.wick@boogieman.com"
        />
        <ControlledPhoneInput
          control={control}
          rules={{ required: REQUIRED_MSG }}
          name="phone"
          label="Phone Number"
          shouldUnregister
        />
      </StyledFormGroup>

      <StyledFormGroup widths="equal">
        <ControlledDropdown
          control={control}
          name="type"
          label="User Type"
          options={USER_TYPE_OPTIONS}
          rules={{ required: REQUIRED_MSG }}
          search
        />
        <ControlledDropdown
          control={control}
          name="projectRole"
          label="Project Role"
          options={PROJECT_ROLE_OPTIONS}
          rules={{ required: REQUIRED_MSG }}
          search
        />
      </StyledFormGroup>

      <Controller
        control={control}
        name="project"
        rules={{ required: REQUIRED_MSG }}
        shouldUnregister
        render={({ field: { onChange, onBlur, value } }) => (
          <SearchProjectBar
            handleSelectProject={(project) => onChange(project?.id || '')}
            onBlur={onBlur}
            selectedProjectId={value}
          />
        )}
      />

      <StyledFormGroup widths="equal">
        <ControlledCheckbox name="primary" label="Primary" control={control} />
        <ControlledCheckbox
          name="subscribed"
          label="Subscribed"
          control={control}
        />
      </StyledFormGroup>
    </StyledForm>
  )
}

const StyledFormGroup = styled(Form.Group)`
  align-items: flex-start !important;

  & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`
