import { useMemo } from 'react'

import useIssueMany from 'context/issue/use-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { IssueType, ResolutionType } from 'types/issue'
import { camelCaseToTitleCase } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormCheckboxValue,
  IFormDropdownValue,
  IFormRadioValue,
} from '../filters-search'

export type IssueFilterValues = {
  assignedTo: IFormDropdownValue
  resolutionType: IFormCheckboxValue
  status: IFormRadioValue
  issueType: IFormCheckboxValue
}

const useIssuesFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useIssueMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()

  const filters: DynamicFilterGroup<IssueFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'assignedTo',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Assigned to',
            options: [
              ...userOptions,
              {
                key: 'everyone',
                text: 'Everyone',
                value: 'Everyone',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'status',
            type: DynamicFilterTypes.RADIO,
            label: 'Issue status',
            options: [
              {
                text: 'All',
                value: 'all',
              },
              {
                text: 'Resolved',
                value: 'resolved',
              },
              {
                text: 'Unresolved',
                value: 'unresolved',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'issueType',
            type: DynamicFilterTypes.CHECKBOX,
            label: 'Issue type',
            options: Object.entries(IssueType).map(([key, value]) => ({
              key: key,
              text: value,
              value: value,
            })),
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'resolutionType',
            type: DynamicFilterTypes.CHECKBOX,
            label: 'Resolution type',
            options: Object.entries(ResolutionType).map(([key, value]) => ({
              key: key,
              text: value,
              value: value,
            })),
          },
        ],
      },
    ]
  }, [userOptions])

  const defaultFilters = useMemo(() => {
    const issueFilters: Partial<IssueFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      issueFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }

    if (queryFilters.status)
      issueFilters.status = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.status,
          text: camelCaseToTitleCase(queryFilters.status),
        },
      }

    if (queryFilters.issueType?.length)
      issueFilters.issueType = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.issueType.map((s) => {
          return {
            value: s,
            text: s,
          }
        }),
      }

    if (queryFilters.resolutionType?.length)
      issueFilters.resolutionType = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.resolutionType.map((s) => {
          return {
            value: s,
            text: s,
          }
        }),
      }

    return issueFilters
  }, [queryFilters])

  const handleApplyFilters = (data: IssueFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      status: data.status?.selection.value as 'all' | 'resolved' | 'unresolved',
      issueType: data.issueType?.selection.map((f) => {
        return f.value as IssueType
      }),
      resolutionType: data.resolutionType?.selection.map((f) => {
        return f.value as ResolutionType
      }),
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: null,
        assignedToRole: null,
      }),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useIssuesFiltersSearch
