import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_SUPPLIER_ORDER,
  CreateSupplierOrderPayload,
  CreateSupplierOrderVariables,
  UPDATE_SUPPLIER_ORDER,
  UpdateSupplierOrderPayload,
  UpdateSupplierOrderVariables,
} from 'queries/supplier-order'

import { ShipmentContext } from './provider'

const useSupplierOrderMutate = () => {
  const { refetch } = useContext(ShipmentContext)

  const [createSupplierOrderAndRefetch, { loading: loadingCreate }] =
    useMutation<CreateSupplierOrderPayload, CreateSupplierOrderVariables>(
      CREATE_SUPPLIER_ORDER,
      {
        onCompleted: refetch,
      },
    )
  const [updateSupplierOrderAndRefetch, { loading: loadingUpdate }] =
    useMutation<UpdateSupplierOrderPayload, UpdateSupplierOrderVariables>(
      UPDATE_SUPPLIER_ORDER,
      {
        onCompleted: refetch,
      },
    )

  return {
    createSupplierOrderAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateSupplierOrderAndRefetch,
  }
}

export default useSupplierOrderMutate
