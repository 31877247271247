import React from 'react'

import { Modal } from 'design-system/components/modal'
import { Diff, DiffType } from 'types/diff'

import DiffsSection from './diffs-section'

export type DiffModalProps = {
  diffs: Diff[]
  onClose: () => void
  open: boolean
  title: string
}

export const DiffModal = ({ diffs, onClose, open, title }: DiffModalProps) => {
  const groupedDiffs = groupDiffs(diffs)

  return (
    <Modal title={title} open={open} onClose={onClose} size="medium">
      {Object.entries(groupedDiffs).map(([type, diffs]) => {
        if (!diffs.length) return null

        return <DiffsSection diffs={diffs} type={type as DiffType} key={type} />
      })}
    </Modal>
  )
}

type GroupedDiffs = Record<DiffType, Diff[]>

const groupDiffs = (diffs: Diff[]): GroupedDiffs => {
  const grouped: GroupedDiffs = {
    [DiffType.ADD]: [],
    [DiffType.UPDATE]: [],
    [DiffType.DELETE]: [],
  }

  for (const diff of diffs) grouped[diff.type].push(diff)

  return grouped
}
