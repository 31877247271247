import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'

import AddressCard from './address-card'
import ArchiveCard from './archive-card'
import DiscountsCard from './discounts-card'
import PeopleCard from './people-card'
import ProjectTypeCard from './project-type-card'
import RoomsCard from './rooms-card'

const CustomerSettings = (_: RouteComponentProps<{ project_id: string }>) => {
  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Settings" />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h5>Customers / Settings</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <PeopleCard />
            <ProjectTypeCard />
          </Grid.Column>
          <Grid.Column>
            <RoomsCard />
            <AddressCard />
          </Grid.Column>
          <Grid.Column>
            <ArchiveCard />
            <DiscountsCard />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProjectLayout>
  )
}

export default CustomerSettings
