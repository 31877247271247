import { useMemo } from 'react'

import useShipmentMany from 'context/shipment/use-many'
import { WarehouseOptions } from 'context/shipment/utils'
import { FORMStatus } from 'context/supplier-order/utils-many'
import { getDropdownOptionsFromEnum } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormCheckboxValue,
  IFormDropdownValue,
} from '../filters-search'

export type ShipmentFilterValues = {
  includeProposed: IFormCheckboxValue
  includeArchived: IFormCheckboxValue
  warehouse: IFormDropdownValue
  statusesFORM: IFormCheckboxValue
}
const useShipmentFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useShipmentMany()
  const filters: DynamicFilterGroup<ShipmentFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'includeProposed',
            type: DynamicFilterTypes.CHECKBOX,
            label: '',
            options: [
              {
                text: 'Include Proposed',
                value: 'proposed',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'includeArchived',
            type: DynamicFilterTypes.CHECKBOX,
            label: '',
            options: [
              {
                text: 'Include Archived',
                value: 'archived',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'warehouse',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Warehouse',
            options: WarehouseOptions,
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'statusesFORM',
            type: DynamicFilterTypes.CHECKBOX,
            label: 'FORM status',
            options: getDropdownOptionsFromEnum(FORMStatus, true).filter(
              (o) => o.value > '04' && o.value < '10',
            ),
          },
        ],
      },
    ]
  }, [])

  const defaultFilters = useMemo(() => {
    const shipmentFilters: Partial<ShipmentFilterValues> = {}

    if (queryFilters.includeProposed)
      shipmentFilters.includeProposed = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: [
          {
            value: 'proposed',
            text: 'Include Proposed',
          },
        ],
      }
    if (queryFilters.includeArchived)
      shipmentFilters.includeArchived = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: [
          {
            value: 'archived',
            text: 'Include Archived',
          },
        ],
      }
    if (queryFilters.warehouse)
      shipmentFilters.warehouse = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.warehouse,
          text:
            WarehouseOptions.find((w) => w.value === queryFilters.warehouse)
              ?.text ?? '',
        },
      }
    if (queryFilters.statusesFORM?.length)
      shipmentFilters.statusesFORM = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.statusesFORM.map((s) => {
          return {
            value: s,
            text: s,
          }
        }),
      }
    return shipmentFilters
  }, [queryFilters])

  const handleApplyFilters = (data: ShipmentFilterValues) => {
    setQueryFilters({
      includeProposed: data.includeProposed?.selection[0].value === 'proposed',
      includeArchived: data.includeArchived?.selection[0].value === 'archived',
      warehouse: data.warehouse?.selection.value ?? '',
      statusesFORM: data.statusesFORM?.selection.map((f) => {
        return f.value as FORMStatus
      }),
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useShipmentFiltersSearch
