import React from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'

import Prospects from 'components/admin/prospect/index-many'
import useTradeDesigner from 'context/dashboard/trade-designer/use'
import ProspectManyProvider from 'context/prospect/provider-many'

import TradeDesignerLayout from './layout'

const TradeDesignerProjects = (_: RouteComponentProps) => {
  const { userId, userName } = useTradeDesigner()

  return (
    <TradeDesignerLayout>
      <ProspectManyProvider
        defaultFilters={{
          assignedTo: {
            id: userId,
            name: userName,
          },
          meetingTime: {
            gte: moment().startOf('week').toISOString(),
            lte: moment().endOf('week').toISOString(),
          },
          sortBy: 'derivedMeeting',
          sortDirection: 'ascending',
          take: 30,
        }}
        skipLoader
      >
        <Prospects />
      </ProspectManyProvider>
    </TradeDesignerLayout>
  )
}

export default TradeDesignerProjects
