import React from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import FiltersAndSearch from 'components/admin/filters-search'
import { BackToTop } from 'components/shared/load-buttons'
import useGenericProductClassMany from 'context/generic-product-class/use-many'

import GenericProductClassTable from './table'
import useGenericProductClassFiltersSearch from './use-filters-search'

const GenericProductClasses = ({ table }: { table?: React.ReactNode }) => {
  const { count, genericProductClasses, fetchMore, loading, loadingMore } =
    useGenericProductClassMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useGenericProductClassFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading
              ? `???`
              : `Showing ${genericProductClasses?.length} of ${count}`}{' '}
            Generic Product Classes
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table || <GenericProductClassTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && genericProductClasses?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default GenericProductClasses
