import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import { ScrollBarStyle } from 'styles/app/system'

const RoomMenu = () => {
  const { pathname } = useLocation()
  const { project } = useProject()
  const {
    room,
    floorPlansIncluded,
    roomPhotosIncluded,
    inspirationImagesIncluded,
    appliancesIncluded,
    measurementsIncluded,
  } = useRoom()

  return (
    <StyledMenu pointing secondary>
      {roomPhotosIncluded && (
        <Menu.Item
          name="photos"
          active={pathname.startsWith(
            `/admin/projects/${project.id}/rooms/${room?.id}/photos`,
          )}
          onClick={() =>
            navigate(`/admin/projects/${project.id}/rooms/${room?.id}/photos`)
          }
        />
      )}
      {floorPlansIncluded && (
        <Menu.Item
          name="plans"
          active={pathname.startsWith(
            `/admin/projects/${project.id}/rooms/${room?.id}/plans`,
          )}
          onClick={() =>
            navigate(`/admin/projects/${project.id}/rooms/${room?.id}/plans`)
          }
        />
      )}
      {inspirationImagesIncluded && (
        <Menu.Item
          name="inspiration-images"
          active={pathname.startsWith(
            `/admin/projects/${project.id}/rooms/${room?.id}/inspiration-images`,
          )}
          onClick={() =>
            navigate(
              `/admin/projects/${project.id}/rooms/${room?.id}/inspiration-images`,
            )
          }
        />
      )}
      <Menu.Item
        name="design-brief"
        active={pathname.startsWith(
          `/admin/projects/${project.id}/rooms/${room?.id}/design-brief`,
        )}
        onClick={() =>
          navigate(
            `/admin/projects/${project.id}/rooms/${room?.id}/design-brief`,
          )
        }
      />
      {appliancesIncluded && (
        <Menu.Item
          name="appliances"
          active={pathname.startsWith(
            `/admin/projects/${project.id}/rooms/${room?.id}/appliances`,
          )}
          onClick={() =>
            navigate(
              `/admin/projects/${project.id}/rooms/${room?.id}/appliances`,
            )
          }
        />
      )}
      {measurementsIncluded && (
        <Menu.Item
          name="measurements"
          active={pathname.startsWith(
            `/admin/projects/${project.id}/rooms/${room?.id}/measurements`,
          )}
          onClick={() =>
            navigate(
              `/admin/projects/${project.id}/rooms/${room?.id}/measurements`,
            )
          }
        />
      )}
      <Menu.Item
        name="designs"
        active={pathname.startsWith(
          `/admin/projects/${project.id}/rooms/${room?.id}/designs`,
        )}
        onClick={() =>
          navigate(`/admin/projects/${project.id}/rooms/${room?.id}/designs`)
        }
      />
      <Menu.Item
        name="packs"
        active={pathname.startsWith(
          `/admin/projects/${project.id}/rooms/${room?.id}/packs`,
        )}
        onClick={() =>
          navigate(`/admin/projects/${project.id}/rooms/${room?.id}/packs`)
        }
      />
      <Menu.Item
        name="production-files"
        active={pathname.startsWith(
          `/admin/projects/${project.id}/rooms/${room?.id}/production-files`,
        )}
        onClick={() =>
          navigate(
            `/admin/projects/${project.id}/rooms/${room?.id}/production-files`,
          )
        }
      />
    </StyledMenu>
  )
}

export default RoomMenu

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
