import { useContext } from 'react'

import { RoomManyContext } from './provider-many'

const useRoomMany = () => {
  const {
    rooms,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(RoomManyContext)

  return {
    rooms,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useRoomMany
