import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { RoomPageTitle } from 'components/admin/page-title'
import RoomLayout from 'components/admin/project/room/layout'
import HelpMessage from 'components/shared/help-message'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import StatusCard from 'design-system/components/status-card'
import { ProjectFileType } from 'types/project-file'
import { StatusMode } from 'types/utils'

const MeasurementsComponent = (
  _: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  const { project, refetch: refetchProject } = useProject()
  const {
    measurementsAreConfirmed,
    measurementsMode,
    measurementsModeText,
    measurementAids,
    refetch,
    room,
  } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()

  return (
    <RoomLayout>
      <RoomPageTitle room={room} viewName="Measurements" />
      <Grid>
        {measurementsMode === StatusMode.Confirmed &&
          !measurementsAreConfirmed && (
            <Grid.Row>
              <Grid.Column>
                <HelpMessage
                  message="Measurements are confirmed at the top level, but not all individual measurements are confirmed. Please unconfirm at the top level to allow customer to confirm on their side, or confirm individual measurements on the customer's behalf to get the system in sync."
                  title="Confirmation is out of sync"
                />
              </Grid.Column>
            </Grid.Row>
          )}
        <Grid.Row>
          <Grid.Column width={6}>
            <StatusCard
              statusStep={measurementsModeText.summary}
              statusText={measurementsModeText.text}
              statusType={measurementsMode}
            />
            {measurementsMode &&
            (measurementsMode === StatusMode.OpenForRevision ||
              measurementsMode === StatusMode.Submitted) ? (
              <Button
                color="gray"
                fontAwesomeIcon={
                  measurementsMode === StatusMode.Submitted
                    ? 'lock'
                    : 'lock-open'
                }
                kind="text"
                text={`${
                  measurementsMode === StatusMode.Submitted
                    ? 'Unsubmit'
                    : 'Submit'
                } changes`}
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  width: '100%',
                }}
                onClick={async () => {
                  await updateRoomAndRefetch({
                    variables: {
                      data: {
                        data: {
                          measurementChangesSubmittedAt: room?.data
                            .measurementChangesSubmittedAt
                            ? null
                            : new Date(),
                        },
                      },
                      where: { id: room?.id ?? '' },
                    },
                  })
                  refetchProject()
                }}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center" width={6}>
            {measurementsMode &&
            (measurementsMode === StatusMode.Confirmation ||
              measurementsMode === StatusMode.Confirmed) ? (
              <Button
                color="blue"
                fontAwesomeIcon={
                  measurementsMode === StatusMode.Confirmed
                    ? 'lock'
                    : 'lock-open'
                }
                kind="solid"
                text={`${
                  measurementsMode === StatusMode.Confirmed
                    ? 'Unconfirm'
                    : 'Confirm'
                } Measurements`}
                onClick={async () => {
                  await updateRoomAndRefetch({
                    variables: {
                      data: {
                        data: {
                          measurementsConfirmedAt: room?.data
                            .measurementsConfirmedAt
                            ? null
                            : new Date(),
                        },
                      },
                      where: { id: room?.id ?? '' },
                    },
                  })
                  refetchProject()
                }}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          navigateTo={
            measurementAids.length
              ? `/admin/files/${measurementAids[0].id}/`
              : undefined
          }
          onlyOneFile
          projectFiles={measurementAids}
          projectFileType={ProjectFileType.MEASUREMENT_AID}
          projectId={project.id}
          roomId={room?.id}
          refetch={refetch}
        />
      </Grid>
    </RoomLayout>
  )
}

export default MeasurementsComponent
