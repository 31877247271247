import React, { useState } from 'react'

import { isEqual } from 'lodash'
import { Form, Grid } from 'semantic-ui-react'

import CardFile from 'components/shared/project/project-file/card'
import { UnderlineSubtitle } from 'components/shared/utils'
import useProjectFileMutate from 'context/project-file/use-mutate-no-context'
import Button from 'design-system/components/button'
import { ProjectFile, ProjectFileType } from 'types/project-file'

import { UploadTestimonialFileCard } from './upload-testimonial'

interface TestimonialFormProps {
  index: number
  onRemove: (projectFileId: string) => void
  refetch: () => Promise<unknown>
  testimonial?: Partial<ProjectFile>
  userId: string
}

const TestimonialForm = ({
  index,
  onRemove,
  refetch,
  testimonial,
  userId,
}: TestimonialFormProps) => {
  const { updateProjectFile, loadingUpdate } = useProjectFileMutate()
  const [testimonialFile, setTestimonialFile] = useState<
    Partial<ProjectFile> | undefined
  >(testimonial)

  const onSaveTestimonial = async (testimonialFile?: Partial<ProjectFile>) => {
    await updateProjectFile({
      variables: {
        data: {
          metadata: {
            ...testimonial?.metadata,
            ...(testimonialFile?.metadata?.customerQuote && {
              customerQuote: testimonialFile.metadata.customerQuote,
            }),
            ...(testimonialFile?.metadata?.customerName && {
              customerName: testimonialFile.metadata.customerName,
            }),
          },
        },
        where: {
          id: testimonial?.id ?? '',
        },
      },
      onCompleted: refetch,
    })
  }

  return (
    <>
      <Grid.Row columns="equal" verticalAlign="bottom">
        <Grid.Column>
          <UnderlineSubtitle>
            Project & Testimonial #{index + 1}
          </UnderlineSubtitle>
        </Grid.Column>
        {testimonialFile && (
          <Grid.Column width={1}>
            <Button
              kind="solid"
              color="blue"
              fontAwesomeIcon="trash-alt"
              onClick={() => onRemove(testimonialFile.id ?? '')}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column verticalAlign="middle" width={4}>
          {testimonialFile ? (
            <CardFile file={testimonialFile} />
          ) : (
            <UploadTestimonialFileCard
              fileType={ProjectFileType.TESTIMONIAL}
              onFilesUploaded={refetch}
              userId={userId}
            />
          )}
        </Grid.Column>
        <Grid.Column width={12}>
          <Form.TextArea
            disabled={!testimonialFile?.id}
            style={{ height: '60px' }}
            label="Customer Quote (100 characters)"
            name="customerQuote"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLTextAreaElement>) =>
              setTestimonialFile({
                ...testimonialFile,
                metadata: {
                  ...testimonialFile?.metadata,
                  customerQuote: value,
                },
              })
            }
            value={testimonialFile?.metadata?.customerQuote ?? ''}
          />
          <Form.Input
            disabled={!testimonialFile?.id}
            label="Customer's Name"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              setTestimonialFile({
                ...testimonialFile,
                metadata: {
                  ...testimonialFile?.metadata,
                  customerName: value,
                },
              })
            }
            value={testimonialFile?.metadata?.customerName ?? ''}
          />
          <Button
            color="dark"
            disabled={isEqual(testimonial?.metadata, testimonialFile?.metadata)}
            kind="solid"
            loading={loadingUpdate}
            onClick={() => onSaveTestimonial(testimonialFile)}
            text="Save Testimonial"
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default TestimonialForm
