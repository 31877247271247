import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Image, Table } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useGenericOptionMany from 'context/generic-option/use-many'
import * as mUtils from 'context/media/utils-many'
import Button from 'design-system/components/button/button'
import { GenericOption } from 'types/generic-option'

import GenericOptionCreateUpdateModal from './create-update-modal'

const GenericOptionTable = () => {
  const { catalog, genericOptions, refetch, queryFilters, setQueryFilters } =
    useGenericOptionMany()
  const { sortBy, sortDirection } = queryFilters
  const [genericOptionToUpdate, setGenericOptionToUpdate] =
    useState<GenericOption>()
  const { isSuperAdmin } = useAuth()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell
            sorted={sortBy === 'description' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'description',
                sortDirection:
                  sortBy === 'description' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Description
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'identifier' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'identifier',
                sortDirection:
                  sortBy === 'identifier' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Identifier
          </Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {genericOptions.map((n: GenericOption, idx) => {
          const display = mUtils.parseMedia(n.media ?? []).reference.length
            ? mUtils.parseMedia(n.media ?? []).reference[0].key
            : 'images/app/catalog_missing_file.png'
          return (
            <Table.Row key={`${n.id}_${idx}`}>
              <Table.Cell width={2}>
                <Image
                  centered
                  size="small"
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    display,
                  )}`}
                />
              </Table.Cell>
              <Table.Cell width={4}>{n.description}</Table.Cell>
              <Table.Cell width={4}>{n.identifier}</Table.Cell>
              <Table.Cell width={4}>
                {n.data?.showOnDesignCall && 'Show On Initial Call Guide'}
              </Table.Cell>
              <Table.Cell textAlign="center" width={2}>
                <Button
                  kind="solid"
                  color="blue"
                  fontAwesomeIcon="arrow-up-right-from-square"
                  onClick={() =>
                    window.open(
                      `/admin/catalog/${catalog}/generic-features/${n.feature?.id}/generic-options/${n.id}`,
                    )
                  }
                  style={{ marginRight: 4 }}
                />
                <Button
                  kind="solid"
                  fontAwesomeIcon="pencil"
                  disabled={!isSuperAdmin}
                  onClick={() => setGenericOptionToUpdate(n)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <GenericOptionCreateUpdateModal
        featureIdentifier={genericOptionToUpdate?.featureIdentifier ?? ''}
        genericOption={genericOptionToUpdate}
        onClose={() => setGenericOptionToUpdate(undefined)}
        onSave={(id: string, featureId: string) => {
          setGenericOptionToUpdate(undefined)
          refetch()
          navigate(
            `/admin/catalog/${catalog}/generic-features/${featureId}/generic-options/${id}`,
          )
        }}
        openModal={!!genericOptionToUpdate}
      />
    </Table>
  )
}

export default GenericOptionTable
