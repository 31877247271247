import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import { ScrollBarStyle } from 'styles/app/system'

const MenuStaffQueue = () => {
  const { pathname } = useLocation()

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="salesDesignerProjects"
        active={pathname === `/admin/staff-queue/sales-designer-projects/`}
        onClick={() => navigate(`/admin/staff-queue/sales-designer-projects`)}
      >
        Sales Designers
      </Menu.Item>
      <Menu.Item
        name="tradeDesignerProjects"
        active={pathname === `/admin/staff-queue/trade-designer-projects/`}
        onClick={() => navigate(`/admin/staff-queue/trade-designer-projects`)}
      >
        Trade Designers
      </Menu.Item>
      <Menu.Item
        name="techDesignerProjects"
        active={pathname === `/admin/staff-queue/tech-designer-projects/`}
        onClick={() => navigate(`/admin/staff-queue/tech-designer-projects`)}
      >
        Tech Designers
      </Menu.Item>
      <Menu.Item
        name="techDesignerSupportProjects"
        active={
          pathname === `/admin/staff-queue/tech-designer-support-projects/`
        }
        onClick={() =>
          navigate(`/admin/staff-queue/tech-designer-support-projects`)
        }
      >
        Tech Designers Support
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuStaffQueue

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
