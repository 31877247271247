import React from 'react'

import { RouteComponentProps } from '@reach/router'

import GenericOptionProvider from 'context/generic-option/provider'

interface GenericOptionIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    generic_option_id: string
  }> {
  children: React.ReactNode
}

const GenericOptionIndex = (props: GenericOptionIndexProps) => {
  return (
    <GenericOptionProvider
      catalog={props.catalog_id}
      generic_option_id={props.generic_option_id}
    >
      {props.children}
    </GenericOptionProvider>
  )
}

export default GenericOptionIndex
