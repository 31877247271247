import { useMutation } from '@apollo/client'

import {
  CREATE_ONE_CATALOG_FEATURE,
  CreateOneCatalogFeaturePayload,
  CreateOneCatalogFeatureVariables,
  UPDATE_ONE_CATALOG_FEATURE,
  UpdateCatalogFeaturePayload,
  UpdateCatalogFeatureVariables,
} from 'queries/catalog-feature'

const useCatalogFeatureMutate = () => {
  const [createCatalogFeature, { loading: loadingCreate }] = useMutation<
    CreateOneCatalogFeaturePayload,
    CreateOneCatalogFeatureVariables
  >(CREATE_ONE_CATALOG_FEATURE)

  const [updateCatalogFeature, { loading: loadingUpdate }] = useMutation<
    UpdateCatalogFeaturePayload,
    UpdateCatalogFeatureVariables
  >(UPDATE_ONE_CATALOG_FEATURE)

  return {
    createCatalogFeature,
    loadingCreate,
    loadingUpdate,
    updateCatalogFeature,
  }
}

export default useCatalogFeatureMutate
