import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  IFormDropdownValue,
} from 'components/admin/filters-search'
import useNobiliaOptionMany from 'context/nobilia-option/use-many'

export type NobiliaOptionFilterValues = {
  section: IFormDropdownValue
}

const useNobiliaOptionFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useNobiliaOptionMany()

  const filters: DynamicFilterGroup<NobiliaOptionFilterValues>[] =
    useMemo(() => {
      return []
    }, [])

  const defaultFilters = useMemo(() => {
    const nobiliaOptionFilters: Partial<NobiliaOptionFilterValues> = {}
    return nobiliaOptionFilters
  }, [queryFilters])

  const handleApplyFilters = (_: NobiliaOptionFilterValues) => {
    setQueryFilters({
      catalog: queryFilters.catalog,
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useNobiliaOptionFiltersSearch
