import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import MediaCard from 'components/admin/catalog/media/card'
import { formatDollars } from 'context/dollar-utils'
import * as mUtils from 'context/media/utils-many'
import Button from 'design-system/components/button'
import { Colors } from 'styles/planner/system'
import { NobiliaProductClass } from 'types/nobilia-product-class'

import * as productUtils from './utils'

const AdditionalItems = ({
  additionalItemProductClasses,
  catalogId,
  refetch,
}: {
  additionalItemProductClasses: NobiliaProductClass[]
  catalogId: string
  refetch: () => Promise<unknown>
}) => {
  return (
    <>
      <Divider style={{ width: '100%' }} />
      <Grid.Row>
        <Grid.Column>
          <h3>Additional Items</h3>
        </Grid.Column>
      </Grid.Row>
      {additionalItemProductClasses.length ? (
        <Grid.Row columns={4} stretched>
          {additionalItemProductClasses.map((a) => (
            <AdditionalItemColumn
              key={a.id}
              onClick={() =>
                navigate(
                  `/admin/catalog/${catalogId}/nobilia-product-classes/${a.id}`,
                )
              }
            >
              <MediaCard
                media={mUtils.parseMedia(a.media ?? []).reference[0]}
                refetch={refetch}
              />
              <div className="info">
                <p>{a.orderId}</p>
                <p>{formatDollars(productUtils.getAdditionalItemPrice(a))}</p>
              </div>
              <div className="hover">
                <p>{a.shortText}</p>
                <Button
                  color="blue"
                  fontAwesomeIcon="chevron-right"
                  kind="solid"
                  text="Learn more"
                />
              </div>
            </AdditionalItemColumn>
          ))}
        </Grid.Row>
      ) : (
        <Grid.Row>
          <Grid.Column>No additional items found</Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

export default AdditionalItems

const AdditionalItemColumn = styled(Grid.Column)`
  padding: 30px 40px !important;

  :hover {
    div.hover {
      opacity: 0.9;
    }
  }

  p {
    color: ${Colors.black};
    margin: 0;
  }

  div.info {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }

  div.hover {
    background: ${Colors.gray100};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    left: 10px;
    opacity: 0;
    padding: 40px 30px 75px;
    position: absolute;
    top: 0;
    transition: all 0.25s ease-in-out;
    width: calc(100% - 20px);
  }
`
