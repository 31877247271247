import React, { useMemo, useState } from 'react'

import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

import ConfirmationModal from 'components/shared/confirmation-modal'
import useAuth from 'context/auth/use'
import { parseStaff } from 'context/project/utils'
import useTaskMutate from 'context/task/use-mutate-no-context'
import { sortTasks } from 'context/task/utils-many'
import Button from 'design-system/components/button'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { Project } from 'types/project'
import { Task } from 'types/task'

import CreateUpdateTaskModal from './create-update-modal'
import TaskCard from './task-card'

const Tasks = ({
  completedTasks,
  project,
  projectView,
  refetch,
  tasks,
  title,
}: {
  completedTasks?: boolean
  project?: Project
  projectView?: boolean
  refetch?: () => void
  tasks?: Task[]
  title?: string
}) => {
  const { user } = useAuth()
  const { updateTask, deleteTask } = useTaskMutate()
  const personalTasks =
    user?.email && tasks?.filter((t) => t.owner.email === user.email)

  const [deleteId, setDeleteId] = useState<string | null>(null)
  const [editableTask, setEditableTask] = useState<Task | null>(null)
  const [showModal, toggleModal] = useState<boolean>(false)
  const [showAllTasks, toggleAllTasks] = useState<boolean>(true)

  const taskList = useMemo(
    () =>
      sortTasks(
        showAllTasks ? (tasks as Task[]) : (personalTasks as Task[]),
        completedTasks,
      ),
    [tasks, showAllTasks, completedTasks],
  )

  return (
    <Container>
      <div className="head">
        <p className="no-margin">{title || 'Tasks'}</p>
        {projectView && !completedTasks ? (
          <div>
            <Checkbox
              checked={!showAllTasks}
              label="Show Only Me"
              onChange={() => toggleAllTasks(!showAllTasks)}
              toggle
            />
            <Button
              kind="solid"
              onClick={() => toggleModal(true)}
              text="Create New"
              style={{ marginLeft: '16px' }}
            />
          </div>
        ) : null}
      </div>
      {taskList.length ? (
        <>
          <div className="task-header">
            <div style={{ flex: '0 1 55px' }} />
            <p className="x-small" style={{ flex: '1 0 18%', margin: 'auto' }}>
              {projectView ? 'Assigned to' : 'Project'}
            </p>
            <p className="x-small" style={{ flex: '1 0 40%', margin: 'auto' }}>
              Task
            </p>
            <p
              className="x-small"
              style={{ flex: '0 1 200px', margin: 'auto' }}
            >
              Date
            </p>
            {!completedTasks && (
              <div style={{ flex: '0 1 80px', margin: 'auto' }} />
            )}
          </div>
          <div className="content">
            {taskList.map((t: Task) => (
              <TaskCard
                key={t.id}
                noActions={completedTasks}
                onCheck={async () => {
                  await updateTask({
                    variables: {
                      data: {
                        data: {
                          ...t.data,
                          completedAt: t.data.completedAt ? null : new Date(),
                        },
                      },
                      where: {
                        id: t.id,
                      },
                    },
                  })
                  refetch?.()
                }}
                onDelete={() => setDeleteId(t.id)}
                onEdit={() => setEditableTask(t)}
                projectView={projectView}
                task={t}
              />
            ))}
          </div>
        </>
      ) : (
        <div style={{ padding: '10px 20px' }}>
          <p>No tasks</p>
        </div>
      )}
      <ConfirmationModal
        confirmText="Yes, delete"
        firstTitle="Are you sure you want to delete this task?"
        description="Once this task is deleted it cannot be recovered."
        onCancel={() => setDeleteId(null)}
        onConfirm={async () => {
          await deleteTask({
            variables: {
              where: {
                id: deleteId ?? '',
              },
            },
          })
          refetch?.()
          setDeleteId(null)
        }}
        open={!!deleteId}
      />
      <CreateUpdateTaskModal
        onClose={() => {
          setEditableTask(null)
          toggleModal(false)
        }}
        open={!!editableTask || showModal}
        projectId={project?.id}
        projectStaff={parseStaff(project)?.allStaff}
        refetch={refetch}
        task={editableTask}
      />
    </Container>
  )
}

export default Tasks

const Container = styled.div`
  border: 1px solid ${Colors.gray200};
  border-radius: 4px;
  width: 100%;

  & > div.head {
    background-color: ${Colors.gray100};
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid ${Colors.gray200};
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
  }

  & > div.content {
    max-height: 800px;
    overflow: auto;
    padding: 16px;
    ${ScrollBarStyle}
  }

  & > div.task-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0;
    gap: 16px;
  }
`
