import React from 'react'

import moment from 'moment'

import useAuth from 'context/auth/use'
import { newForMe } from 'context/new-for'
import { parseEvents } from 'context/project-event/utils-many'
import { Project } from 'types/project'

export const ProjectActivityCellContent = ({
  project,
}: {
  project: Project
}) => {
  const { formUserId } = useAuth()
  const newMessages = project.messages.filter((m) => newForMe(m, formUserId))
  const [lastMessage] = project.messages
  const lastMessageString = lastMessage
    ? `${moment(lastMessage.createdAt).fromNow()}`
    : '---'
  const newEvents = project.events.filter((e) => newForMe(e, formUserId))
  const { customer: customerEvents, internal: internalEvents } =
    parseEvents(newEvents)
  const [lastCustomerEvent] = customerEvents
  const lastCustomerEventString = lastCustomerEvent
    ? `${moment(
        lastCustomerEvent.data?.lastUpdatedAt || lastCustomerEvent.createdAt,
      ).fromNow()}`
    : '---'
  const [lastInternalEvent] = internalEvents
  const lastInternalEventString = lastInternalEvent
    ? `${moment(
        lastInternalEvent.data?.lastUpdatedAt || lastInternalEvent.createdAt,
      ).fromNow()}`
    : '---'

  return (
    <div style={{ display: 'grid', rowGap: '16px' }}>
      <div>
        <p
          className={`no-margin x-small ${
            customerEvents.length > 0 ? 'orange' : 'light-gray'
          }`}
        >
          Customer {customerEvents.length > 0 && `(${customerEvents.length})`}
        </p>
        <p className="no-margin">{lastCustomerEventString}</p>
      </div>
      <div>
        <p
          className={`no-margin x-small ${
            internalEvents.length > 0 ? 'orange' : 'light-gray'
          }`}
        >
          Internal {internalEvents.length > 0 && `(${internalEvents.length})`}
        </p>
        <p className="no-margin">{lastInternalEventString}</p>
      </div>
      <div>
        <p
          className={`no-margin x-small ${
            newMessages.length > 0 ? 'orange' : 'light-gray'
          }`}
        >
          Messages {newMessages.length > 0 ? `(${newMessages.length})` : null}
        </p>
        <p className="no-margin">{lastMessageString}</p>
      </div>
    </div>
  )
}
