import React, { useCallback, useEffect, useState } from 'react'

import { debounce } from 'lodash'
import { Divider, DropdownProps, Form } from 'semantic-ui-react'

import useArchiverProductsMany from 'context/archivizer-product/use-many'
import { notifySuccess } from 'context/notifications/trigger'
import useRenderPropMutate from 'context/render-prop/use-mutate'
import useRenderPropNoContext from 'context/render-prop/use-no-context'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { RenderProp } from 'types/render-prop'

interface CreateUpdateModalProps {
  onClose: () => void
  onSave: () => void
  openModal: boolean
  renderProp?: RenderProp
  trigger: React.ReactElement
}

const CreateUpdateModal = ({
  onClose,
  onSave,
  openModal,
  renderProp,
  trigger,
}: CreateUpdateModalProps) => {
  const [state, setState] = useState<RenderProp | null>(renderProp ?? null)
  const [productSearch, setProductSearch] = useState<string>('')

  useEffect(() => {
    resetState(renderProp as RenderProp)
  }, [renderProp])

  const resetState = (renderProp: RenderProp | null) => {
    setProductSearch('')
    setState(renderProp)
  }

  const handleSearchChange_ = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { searchQuery }: DropdownProps,
  ) => {
    setProductSearch(searchQuery as string)
  }

  const handleSearchChange = useCallback(
    debounce(handleSearchChange_, 1500),
    [],
  )

  const { renderProps, loading: loadingSearch } = useArchiverProductsMany({
    skip: !productSearch,
    variables: {
      data: {
        name: productSearch,
      },
    },
  })

  const { renderProp: renderPropFound } = useRenderPropNoContext({
    skip: !state?.identifier,
    variables: {
      where: {
        identifier: state?.identifier,
      },
    },
  })

  const { createRenderProp, loadingCreate, loadingUpdate, updateRenderProp } =
    useRenderPropMutate()

  const toggleModal = () => {
    onClose()
    resetState(null)
  }

  const handleSubmit = async () => {
    if (!renderProp) {
      await createRenderProp({
        variables: {
          data: {
            data: {
              ...state?.data,
            },
            description: state?.description,
            groupIdentifier: state?.groupIdentifier,
            identifier: state?.identifier,
            imageUrl: state?.imageUrl,
          },
        },
        onCompleted: () => {
          notifySuccess('Render prop created')
        },
      })
    } else {
      await updateRenderProp({
        variables: {
          data: {
            data: {
              ...state?.data,
            },
            description: state?.description,
            groupIdentifier: state?.groupIdentifier,
            identifier: state?.identifier,
            imageUrl: state?.imageUrl,
          },
          where: {
            id: state?.id ?? '',
          },
        },
        onCompleted: () => {
          notifySuccess('Render prop created')
        },
      })
    }
    onSave()
  }

  return (
    <Modal
      onClose={toggleModal}
      open={openModal}
      size="medium"
      trigger={trigger}
      title={renderProp ? 'Update Render Prop' : 'Create Render Prop'}
      saveButton={{
        disabled: !state || (!renderProp && !!renderPropFound),
        loading: loadingUpdate || loadingCreate || loadingSearch,
        onClick: handleSubmit,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Dropdown
          clearable
          fluid
          loading={loadingSearch}
          options={renderProps.map((r) => ({
            key: r.identifier,
            text: `${r.description}`,
            value: r.identifier,
            image: r.imageUrl,
          }))}
          onChange={(_, { value }) => {
            setState(renderProps?.find((r) => r.identifier === value) ?? null)
          }}
          onSearchChange={handleSearchChange}
          placeholder="Search products"
          search
          selection
          value={state?.identifier || ''}
          error={
            !renderProp && renderPropFound
              ? {
                  content: 'Render prop already exists',
                  pointing: 'below',
                }
              : null
          }
        />
        <Divider />
        <Form.Group widths="equal">
          <Form.Input
            disabled={!state}
            label="Name"
            value={state?.data?.name || ''}
          />
          <Form.Input
            disabled={!state}
            label="Brand"
            value={state?.data?.brand || ''}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            disabled={!state}
            label="Category"
            value={state?.data?.category || ''}
          />
          <Form.Input
            disabled={!state}
            label="Identifier"
            value={state?.identifier || ''}
          />
        </Form.Group>
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateModal
