import axios from 'axios'
import sanitize from 'sanitize-filename'

import { apolloClient } from 'context/authorized-apollo/provider'
import {
  apiResultToAWSFormData,
  GetUserProfileFileUploadPayload,
  GetUserProfileFileUploadVariables,
  GET_USER_PROFILE_FILE_UPLOAD_URL,
} from 'queries/aws'

export const uploadUserProfileImage = async (
  file: File | null,
  userId: string,
): Promise<string> => {
  const fileRemovedSpecialCharacters = file?.name.replace(
    /[^a-zA-Z0-9.\-_]/g,
    '',
  )

  const fileNameSanitized = sanitize(fileRemovedSpecialCharacters ?? '')

  let url = ''
  let dataFields: Record<string, any> = {}

  const res = await apolloClient?.mutate<
    GetUserProfileFileUploadPayload,
    GetUserProfileFileUploadVariables
  >({
    mutation: GET_USER_PROFILE_FILE_UPLOAD_URL,
    variables: { data: { fname: fileNameSanitized, userId } },
  })

  url = res?.data?.uploadUrlUserProfileFile.url ?? ''
  dataFields = apiResultToAWSFormData(res?.data?.uploadUrlUserProfileFile ?? {})
  const data = new FormData()
  Object.keys(dataFields).forEach((k) => {
    data.append(k, dataFields[k])
  })
  data.append('acl', 'public-read')
  if (file) {
    data.append('Content-Type', file.type)
    data.append('file', file)
  }

  const uploadRes = await axios.post(url, data)

  if (uploadRes.status === 204) {
    return `${res?.data?.uploadUrlUserProfileFile.url}/${res?.data?.uploadUrlUserProfileFile.key}`
  }

  throw new Error(
    `[${uploadRes.status}] Upload image failed: ${uploadRes.statusText}`,
  )
}
