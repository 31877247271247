import React from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import UserTab from 'components/admin/user/tab'
import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'
import {
  FIND_UNIQUE_USER,
  FindUniqueUserPayload,
  FindUniqueUserVariables,
} from 'queries/user'

const ProfileView = () => {
  const { user: userAuth } = useAuth()
  const { data: userDb, loading } = useQuery<
    FindUniqueUserPayload,
    FindUniqueUserVariables
  >(FIND_UNIQUE_USER, {
    variables: {
      where: {
        email: userAuth.email,
      },
    },
  })

  if (loading) return <Loader />

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <SectionTitle title="Profile" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <UserTab user={userDb?.user} profileView></UserTab>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <ProfileView />
    </AdminLayout>
  )
}
