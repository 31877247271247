import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { getQueryVariables } from 'context/design/utils-many'
import {
  FIND_MANY_DESIGN_COUNT,
  FindManyDesignCountPayload,
  FindManyDesignVariables,
} from 'queries/design'
import { ProjectStaffRole } from 'types/project-staff'

const useAwaitingRenderCount = (
  userId?: string,
  projectStaffRole?: ProjectStaffRole,
) => {
  const variables = useMemo(
    () =>
      getQueryVariables({
        renderStatus: 'all',
        assignedTo: { id: userId ?? '' },
        assignedToRole: projectStaffRole,
        take: undefined,
      }),
    [userId, projectStaffRole],
  )

  const {
    data: { count: designsAwaitingRenderCount } = { count: 0 },
    loading,
  } = useQuery<FindManyDesignCountPayload, FindManyDesignVariables>(
    FIND_MANY_DESIGN_COUNT,
    {
      variables: {
        where: {
          ...variables.where,
          // add null here so we only show counts for not sent to arch
          sentToArchAt: null,
        },
      },
    },
  )

  return {
    loading,
    designsAwaitingRenderCount,
  }
}

export default useAwaitingRenderCount
