import { gql } from '@apollo/client'

import { Conversation, ConversationSource } from 'types/conversation'
import { MessageData } from 'types/message'

export const FIND_MANY_CONVERSATION_NO_PROJECT = gql`
  query findManyConversation(
    $take: Int
    $orderBy: [ConversationOrderByWithRelationInput!]
    $skip: Int
    $where: ConversationWhereInput
  ) {
    count: findManyConversationCount(where: $where)
    conversations: findManyConversation(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      identifier
      source
      lastMessageReceivedAt
      clientUser {
        id
        firstName
        lastName
      }
    }
  }
`

export const FIND_MANY_CONVERSATION = gql`
  query findManyConversation(
    $take: Int
    $orderBy: [ConversationOrderByWithRelationInput!]
    $skip: Int
    $where: ConversationWhereInput
  ) {
    count: findManyConversationCount(where: $where)
    conversations: findManyConversation(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      identifier
      source
      createdAt
      lastMessageReceivedAt
      messages {
        id
        data
      }
      clientUser {
        id
        email
        firstName
        lastName
        picture
        phone
        projects {
          project {
            id
            projectAddress
            owners {
              isSubscribed
              isPrimary
              user {
                id
                email
                firstName
                lastName
                phone
                picture
                type
              }
              userId
              userRole
            }
          }
        }
      }
    }
  }
`

type SourceOr = {
  OR?: {
    source: { equals: string }
  }[]
}

type SearchOr = {
  OR?: (
    | {
        messages: {
          some: {
            data: {
              path: (keyof MessageData)[]
              equals?: string
              string_contains?: string
            }
          }
        }
      }
    | {
        clientUser: {
          derivedSearch?: {
            contains: string
            mode?: 'insensitive'
          }
          phone?: {
            contains: string
          }
        }
      }
  )[]
}

export type FindManyConversationWhere = {
  id?: string
  identifier?: string
  source?: {
    in: ConversationSource[]
  }
  createdAt?: {
    lte?: string
    gte?: string
  }
  lastMessageReceivedAt?: {
    lte?: string
    gte?: string
  }
  clientUser?: null | { projects: { some: { projectId: { equals: string } } } }
  NOT?: {
    clientUser?: null
  }
  AND?: (SourceOr | SearchOr)[]
}

export type FindManyConversationPayload = {
  count: number
  conversations: Conversation[]
}

export type FindManyConversationVariables = {
  take?: number
  orderBy?:
    | { createdAt: 'desc' | 'asc' }
    | { updatedAt: 'desc' | 'asc' }
    | { lastMessageReceivedAt: 'desc' | 'asc' }
  skip?: number
  where?: FindManyConversationWhere
}

export const FIND_MANY_CONVERSATION_COUNT = gql`
  query findManyConversation($where: ConversationWhereInput) {
    count: findManyConversationCount(where: $where)
  }
`

export type FindManyConversationCountPayload = {
  count: number
}

export type FindManyConversationCountVariables = {
  where?: FindManyConversationWhere
}

export const UPDATE_ONE_CONVERSATION = gql`
  mutation updateOneConversation(
    $data: ConversationUpdateInput!
    $where: ConversationWhereUniqueInput!
  ) {
    updateOneConversation(data: $data, where: $where) {
      id
    }
  }
`

export type UpdateOneConversationPayload = {
  id: string
}

export type UpdateOneConversationVariables = {
  data: {
    clientUser: {
      connect?: {
        id: string
      }
      disconnect?: boolean
    }
  }
  where:
    | {
        id: string
      }
    | {
        identifier: string
      }
}
