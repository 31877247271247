import React, { createContext, useContext, useState } from 'react'

import { Tab } from 'design-system/components/tabs'
import { MessageSource } from 'types/message'

export enum MessageActiveTabOptions {
  ALL = 'all',
  PINNED = 'pinned',
}

export type MessageTab = Tab<{ isConversation?: boolean }>

type AdminMessageLayoutContext = {
  activeTab: MessageTab
  setActiveTab: (tab: MessageTab) => void
  pinnedFilter: 'client' | 'all' | 'me'
  setPinnedFilter: (filter: 'client' | 'all' | 'me') => void
  conversationMessagesFilter:
    | 'all'
    | MessageSource.SMS
    | MessageSource.CALL_TRANSCRIPT
  setConversationMessagesFilter: (
    filter: 'all' | MessageSource.SMS | MessageSource.CALL_TRANSCRIPT,
  ) => void
}

const Context = createContext<AdminMessageLayoutContext>({
  activeTab: { label: 'All', value: MessageActiveTabOptions.ALL },
  pinnedFilter: 'all',
  setActiveTab: () => {},
  setPinnedFilter: () => {},
  conversationMessagesFilter: 'all',
  setConversationMessagesFilter: () => {},
})

const AdminMessageLayoutProvider = ({ children }: React.PropsWithChildren) => {
  const [activeTab, setActiveTab] = useState<MessageTab>({
    label: 'All',
    value: MessageActiveTabOptions.ALL,
  })
  const [pinnedFilter, setPinnedFilter] = useState<'client' | 'all' | 'me'>(
    'all',
  )

  const [conversationMessagesFilter, setConversationMessagesFilter] = useState<
    'all' | MessageSource.SMS | MessageSource.CALL_TRANSCRIPT
  >('all')

  return (
    <Context.Provider
      value={{
        activeTab,
        pinnedFilter,
        setActiveTab,
        setPinnedFilter,
        conversationMessagesFilter,
        setConversationMessagesFilter,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default AdminMessageLayoutProvider

export const useAdminMessageLayout = () => useContext(Context)
