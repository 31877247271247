import React from 'react'

import { isEqual } from 'lodash'
import { Grid } from 'semantic-ui-react'

import {
  GenericOptionDiff,
  GenericOptionUpdate,
} from 'types/generic-option-diff'

import MediaDiffComponent from './media-diff'
import {
  getReferenceImage,
  ImageCard,
  ReferenceDiv,
  StrikeThrough,
  StyledLabelDiffs,
  SubGrid,
} from './utils'

const parseDataFields = (genericOption: GenericOptionDiff) => {
  const isUpdate = genericOption.type === 'update'
  const snapshotFrom = isUpdate
    ? (genericOption as GenericOptionUpdate).data?.from
    : genericOption.snapshot
  const snapshotTo = isUpdate
    ? (genericOption as GenericOptionUpdate).data?.to
    : genericOption.snapshot
  const snapshotFromData = snapshotFrom?.data
  const snapshotToData = snapshotTo?.data
  const out = [
    {
      fieldName: 'Description',
      fieldValue: snapshotTo?.description,
      fieldValueChanged: snapshotFrom?.description,
    },
    {
      fieldName: 'Display Name',
      fieldValue: snapshotToData?.displayName,
      fieldValueChanged: snapshotFromData?.displayName,
    },
    {
      fieldName: 'Discontinued',
      fieldValue: snapshotToData?.isDiscontinued,
      fieldValueChanged: snapshotFromData?.isDiscontinued,
    },
    {
      fieldName: 'Show On Initial Call',
      fieldValue: snapshotToData?.showOnDesignCall,
      fieldValueChanged: snapshotFromData?.showOnDesignCall,
    },
  ]
  return out.filter((o) => {
    if (!isUpdate) return true
    return !isEqual(o.fieldValue, o.fieldValueChanged)
  })
}

const MediaDiffs = ({
  genericOptionDiff,
}: {
  genericOptionDiff: GenericOptionDiff
}) => {
  const isAdd = genericOptionDiff.type === 'add'
  const isDelete = genericOptionDiff.type === 'delete'
  const showMedia =
    !isAdd && !isDelete
      ? !!genericOptionDiff.media?.length
      : genericOptionDiff.snapshot.media.length > 0
  if (!showMedia) return <></>
  if (isAdd)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {genericOptionDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'add',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  if (isDelete)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {genericOptionDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'delete',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  return (
    <>
      <p className="caption no-margin">Media</p>
      {genericOptionDiff?.media?.map((mdiff) => (
        <MediaDiffComponent key={mdiff.snapshot.key} mediaDiff={mdiff} />
      ))}
    </>
  )
}

const DataDiffs = ({
  genericOptionDiff,
}: {
  genericOptionDiff: GenericOptionDiff
}) => {
  const fields = parseDataFields(genericOptionDiff)
  const isUpdate = genericOptionDiff.type === 'update'
  return (
    <>
      {!!fields.length && <p className="caption no-margin">Data</p>}
      {fields.map((f) => (
        <Grid.Row key={f.fieldName}>
          <Grid.Column width={4}>
            <p className="caption">{f.fieldName}</p>
          </Grid.Column>
          <Grid.Column width={12}>
            {isUpdate ? (
              <StrikeThrough>{`${f.fieldValueChanged}`}</StrikeThrough>
            ) : null}
            <p>{`${f.fieldValue}`}</p>
          </Grid.Column>
        </Grid.Row>
      ))}
    </>
  )
}

const GenericOptionDiffComponent = ({
  genericOptionDiff,
}: {
  genericOptionDiff: GenericOptionDiff
}) => {
  const reference = getReferenceImage(genericOptionDiff.snapshot.media)

  return (
    <>
      <ReferenceDiv>
        <ImageCard image={reference} />
        <div>
          <p className="caption">
            {genericOptionDiff.snapshot.data?.displayName ||
              genericOptionDiff.snapshot.description}
          </p>
        </div>
        <StyledLabelDiffs type={genericOptionDiff.type} />
      </ReferenceDiv>
      <SubGrid>
        <MediaDiffs genericOptionDiff={genericOptionDiff} />
        <DataDiffs genericOptionDiff={genericOptionDiff} />
      </SubGrid>
    </>
  )
}

export default GenericOptionDiffComponent
