import React from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import useProjectMany from 'context/project/use-many'

import ProjectsTable from './table'
import useProjectFiltersSearch from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const Projects = ({ noFilters }: { noFilters?: boolean }) => {
  const { count, projects, fetchMore, loading, loadingMore } = useProjectMany()

  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useProjectFiltersSearch()

  return (
    <Grid>
      {!noFilters && (
        <FiltersAndSearch
          defaultAppliedFilters={defaultFilters}
          filters={
            filters as React.ComponentProps<typeof FiltersAndSearch>['filters']
          }
          onApply={handleApplyFilters}
          handleSearchChange={handleSearch}
        />
      )}
      <Grid.Row style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${projects?.length} of ${count}`}{' '}
            Projects
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            <ProjectsTable />
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && projects?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default Projects
