import React, { createContext, useContext, useMemo } from 'react'

import { useQuery } from '@apollo/client'

import * as NO from 'planner/objects/products/nobilia/options/definitions'
import {
  FIND_MANY_DISCONNECTED_FEATURE,
  FindManyDisconnectedFeaturePayload,
  FindManyDisconnectedFeatureVariables,
} from 'queries/disconnected-feature'
import {
  FIND_MANY_GENERIC_FEATURE,
  FindManyGenericFeaturePayload,
  FindManyGenericFeatureVariables,
} from 'queries/generic-feature'
import {
  GET_NOBILIA_FEATURES_QUERY,
  GetNobiliaFeaturesPayload,
  GetNobiliaFeaturesVariables,
} from 'queries/nobilia-feature'
import {
  FIND_MANY_ROOM_ELEMENT_FEATURE,
  FindManyRoomElementFeaturePayload,
  FindManyRoomElementFeatureVariables,
} from 'queries/room-element-feature'
import { DisconnectedFeature } from 'types/disconnected-feature'
import { GenericFeature } from 'types/generic-feature'
import { RoomElementFeature } from 'types/room-element-feature'

import { ParsedNobiliaFeatures, parseNobiliaFeatures } from './utils'

interface ICatalogContext {
  catalog: string
  disconnectedFeatures: DisconnectedFeature[]
  genericFeatures: GenericFeature[]
  loading: boolean
  loadingDisconnectedFeatures: boolean
  loadingGenericFeatures: boolean
  loadingNobiliaFeatures: boolean
  loadingRoomElementFeatures: boolean
  roomElementFeatures: RoomElementFeature[]
  parsedNobiliaFeatures: ParsedNobiliaFeatures
}

const CatalogContenxt = createContext<ICatalogContext>({} as ICatalogContext)

type CatalogProviderProps = {
  children: React.ReactNode
  catalog: string
}

export const CatalogProvider = ({
  catalog,
  children,
}: CatalogProviderProps) => {
  const {
    data: { disconnectedFeatures } = { disconnectedFeatures: [] },
    loading: loadingDisconnectedFeatures,
  } = useQuery<
    FindManyDisconnectedFeaturePayload,
    FindManyDisconnectedFeatureVariables
  >(FIND_MANY_DISCONNECTED_FEATURE)

  const {
    data: { genericFeatures } = { genericFeatures: [] },
    loading: loadingGenericFeatures,
  } = useQuery<FindManyGenericFeaturePayload, FindManyGenericFeatureVariables>(
    FIND_MANY_GENERIC_FEATURE,
  )

  const {
    data: { roomElementFeatures } = { roomElementFeatures: [] },
    loading: loadingRoomElementFeatures,
  } = useQuery<
    FindManyRoomElementFeaturePayload,
    FindManyRoomElementFeatureVariables
  >(FIND_MANY_ROOM_ELEMENT_FEATURE)

  const {
    data: { nobiliaFeatures } = { nobiliaFeatures: [] },
    loading: loadingNobiliaFeatures,
  } = useQuery<GetNobiliaFeaturesPayload, GetNobiliaFeaturesVariables>(
    GET_NOBILIA_FEATURES_QUERY,
    {
      variables: {
        where: {
          catalog: { equals: catalog },
          featureNo: {
            in: [
              NO.FRONT_COMBO,
              NO.HANDLE_COMBINATION,
              NO.GLASS_COLOR,
              NO.GLASS_DESIGN,
              NO.GLASS_DOOR_FRAME_COLOR,
              NO.GLASS_DOOR_FRAME_DESIGN,
              NO.CARCASE_COLOR_EXTERIOR,
              NO.CARCASE_COLOR_INTERIOR,
              NO.PLINTH_COLOR,
              NO.CEILING_PANEL_COLOR,
              NO.UPRIGHT_BAR_COLOR,
              NO.WALL_SHELF_COLOR,
            ],
          },
        },
      },
    },
  )

  const parsedNobiliaFeatures = useMemo(
    () => parseNobiliaFeatures(nobiliaFeatures),
    [nobiliaFeatures],
  )

  return (
    <CatalogContenxt.Provider
      value={{
        catalog,
        disconnectedFeatures,
        loading:
          loadingNobiliaFeatures ||
          loadingDisconnectedFeatures ||
          loadingGenericFeatures ||
          loadingRoomElementFeatures,
        loadingDisconnectedFeatures,
        loadingGenericFeatures,
        loadingNobiliaFeatures,
        loadingRoomElementFeatures,
        genericFeatures,
        roomElementFeatures,
        parsedNobiliaFeatures,
      }}
    >
      {children}
    </CatalogContenxt.Provider>
  )
}

export const useCatalog = () => useContext(CatalogContenxt)
