import { useMemo } from 'react'

import useOrderSamplesMany from 'context/order/use-many-samples'
import useFORMEmployees from 'context/user/use-form-employees'
import { ProjectStaffRole, PROJECT_STAFF_ROLES } from 'types/project-staff'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormDropdownValue,
  IFormRadioValue,
} from '../filters-search'

export type OrdersSamplesFilterValues = {
  assignedTo: IFormDropdownValue
  assignedToRole: IFormDropdownValue
  orderStatus: IFormRadioValue
}
const useOrdersSamplesFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useOrderSamplesMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()

  const filters: DynamicFilterGroup<OrdersSamplesFilterValues>[] =
    useMemo(() => {
      return [
        {
          filters: [
            {
              filterId: 'assignedTo',
              type: DynamicFilterTypes.DROPDOWN,
              label: 'Assigned to',
              options: [
                ...userOptions,
                {
                  key: 'everyone',
                  text: 'Everyone',
                  value: 'Everyone',
                },
              ],
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'assignedToRole',
              type: DynamicFilterTypes.DROPDOWN,
              label: 'Assigned role',
              options: PROJECT_STAFF_ROLES.map((r) => {
                return {
                  key: r,
                  text: capsSnakeCaseToTitleCase(r),
                  value: r,
                }
              }),
            },
          ],
        },
        {
          filters: [
            {
              filterId: 'orderStatus',
              type: DynamicFilterTypes.RADIO,
              label: 'Order status',
              options: [
                {
                  text: 'All',
                  value: 'all',
                },
                {
                  text: 'Awaiting',
                  value: 'awaiting',
                },
                {
                  text: 'Placed',
                  value: 'placed',
                },
              ],
            },
          ],
        },
      ]
    }, [userOptions])

  const defaultFilters = useMemo(() => {
    const ordersSamplesFilters: Partial<OrdersSamplesFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      ordersSamplesFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }
    if (queryFilters.assignedToRole)
      ordersSamplesFilters.assignedToRole = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedToRole,
          text: capsSnakeCaseToTitleCase(queryFilters.assignedToRole),
        },
      }
    if (queryFilters.orderStatus)
      ordersSamplesFilters.orderStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.orderStatus,
          text: capsSnakeCaseToTitleCase(queryFilters.orderStatus),
        },
      }

    return ordersSamplesFilters
  }, [queryFilters])

  const handleApplyFilters = (data: OrdersSamplesFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      assignedToRole: data.assignedToRole?.selection.value as ProjectStaffRole,
      orderStatus:
        data.orderStatus?.selection.value === 'all'
          ? undefined
          : (data.orderStatus?.selection.value as
              | 'all'
              | 'awaiting'
              | 'placed'),
      type: queryFilters.type,
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: null,
        assignedToRole: null,
      }),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useOrdersSamplesFiltersSearch
