import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Form } from 'semantic-ui-react'

import { notifySuccess } from 'context/notifications/trigger'
import { Modal } from 'design-system/components/modal'
import {
  CREATE_ONE_DESIGN_ADVISORY_CODE_GROUP,
  CreateOneDesignAdvisoryCodeGroupPayload,
  CreateOneDesignAdvisoryCodeGroupVariables,
  UPDATE_ONE_DESIGN_ADVISORY_CODE_GROUP,
  UpdateOneDesignAdvisoryCodeGroupPayload,
  UpdateOneDesignAdvisoryCodeGroupVariables,
} from 'queries/design-advisory-code-group'
import { StyledForm } from 'styles/admin/main'
import { DesignAdvisoryCodeGroup } from 'types/design-advisory-code'

interface DesignAdvisoryCodeGroupProps {
  designAdvisoryCodeGroup?: DesignAdvisoryCodeGroup
  onCloseModal: () => void
  openModal: boolean
  refetch: () => void
}

const DesignAdvisoryCodeGroupModal = ({
  designAdvisoryCodeGroup,
  onCloseModal,
  openModal,
  refetch,
}: DesignAdvisoryCodeGroupProps) => {
  const initialState = {
    description: designAdvisoryCodeGroup?.description || '',
    index: designAdvisoryCodeGroup?.index || '',
  }
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (openModal) setState(initialState)
  }, [openModal])

  const [createDesignAdvisoryCodeGroup] = useMutation<
    CreateOneDesignAdvisoryCodeGroupPayload,
    CreateOneDesignAdvisoryCodeGroupVariables
  >(CREATE_ONE_DESIGN_ADVISORY_CODE_GROUP, {
    onCompleted: () => {
      notifySuccess('Design Advisory Code Group Created')
      refetch()
    },
  })

  const [updateDesignAdvisoryCodeGroup] = useMutation<
    UpdateOneDesignAdvisoryCodeGroupPayload,
    UpdateOneDesignAdvisoryCodeGroupVariables
  >(UPDATE_ONE_DESIGN_ADVISORY_CODE_GROUP, {
    onCompleted: () => {
      notifySuccess('Design Advisory Code Group Updated')
      refetch()
    },
  })

  const onClose = () => {
    setState(initialState)
    onCloseModal()
  }

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: value })

  const handleSubmit = async () => {
    if (designAdvisoryCodeGroup?.id) {
      await updateDesignAdvisoryCodeGroup({
        variables: {
          data: {
            description: state.description,
            index: state.index,
          },
          where: {
            id: designAdvisoryCodeGroup.id,
          },
        },
      })
    } else {
      await createDesignAdvisoryCodeGroup({
        variables: {
          data: {
            description: state.description,
            index: state.index,
          },
        },
      })
    }

    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={openModal}
      size="small"
      title={
        designAdvisoryCodeGroup
          ? 'Update Design Advisory Code Group'
          : 'Create Design Advisory Code Group'
      }
      saveButton={{
        disabled: !state.index || !state.description,
        onClick: handleSubmit,
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Form.Input
          label="Index"
          name="index"
          onChange={onChangeInput}
          required
          value={state.index}
        />
        <Form.Input
          label="Description"
          name="description"
          onChange={onChangeInput}
          required
          value={state.description}
        />
      </StyledForm>
    </Modal>
  )
}

export default DesignAdvisoryCodeGroupModal
