import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from 'semantic-ui-react'

import RoomElementOptionsSelection from 'components/shared/catalog/room-element-option/selection'
import RoomElementOptionManyProvider from 'context/room-element-option/provider-many'
import { QueryFilters } from 'context/room-element-option/utils-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { RoomElementOption } from 'types/room-element-option'

import { Labels } from '../utils'

const RoomElementOptionsModal = ({
  defaultFilters,
  onSave,
  selectedRoomElementOptions,
  title,
}: {
  defaultFilters: QueryFilters
  onSave: (roomElementOptions: Partial<RoomElementOption>[]) => void
  selectedRoomElementOptions: Partial<RoomElementOption>[]
  title?: string
}) => {
  const [roomElementOptions, setRoomElementOptions] = useState<
    Partial<RoomElementOption>[]
  >(selectedRoomElementOptions)
  const [showModal, toggleModal] = useState<boolean>(false)
  const [defaultFilters_] = useState<QueryFilters>(defaultFilters)

  useEffect(
    () => setRoomElementOptions(selectedRoomElementOptions),
    [selectedRoomElementOptions],
  )

  const onClose = () => {
    setRoomElementOptions(selectedRoomElementOptions)
    toggleModal(false)
  }

  return (
    <Modal
      onClose={onClose}
      open={showModal}
      size="large"
      title={`Choose ${title || 'options'}`}
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={13}>
              {roomElementOptions.length ? (
                <Labels>
                  {roomElementOptions.map((o) => (
                    <div key={o.id} className="selection">
                      <p>{o.data?.displayName || o.description}</p>
                      <FontAwesomeIcon
                        icon={['fal', 'times']}
                        onClick={() => {
                          if (roomElementOptions.find((s) => s.id === o.id))
                            setRoomElementOptions(
                              roomElementOptions.filter((s) => s.id !== o.id),
                            )
                          else setRoomElementOptions([...roomElementOptions, o])
                        }}
                      />
                    </div>
                  ))}
                </Labels>
              ) : null}
            </Grid.Column>
            <Grid.Column width={3}>
              <div className="flex-center" style={{ flexWrap: 'unset' }}>
                <Button
                  kind="outlined"
                  text="Close"
                  color="gray"
                  onClick={onClose}
                  style={{ marginRight: '20px' }}
                />
                <Button
                  kind="solid"
                  text="Save"
                  onClick={() => {
                    onSave(roomElementOptions)
                    toggleModal(false)
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      trigger={
        <Button
          kind="ghost"
          fontAwesomeIcon={roomElementOptions.length ? 'pencil' : 'plus'}
          text={roomElementOptions.length ? 'Change' : 'Add'}
          onClick={() => toggleModal(true)}
        />
      }
    >
      <RoomElementOptionManyProvider
        defaultFilters={defaultFilters_}
        skipLoader
      >
        <RoomElementOptionsSelection
          selectedRoomElementOptions={roomElementOptions}
          setSelectedRoomElementOptions={setRoomElementOptions}
        />
      </RoomElementOptionManyProvider>
    </Modal>
  )
}

export default RoomElementOptionsModal
