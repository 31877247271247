import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_ROUTE,
  FindUniqueRoutePayload,
  FindUniqueRouteVariables,
} from 'queries/route'
import { Route } from 'types/route'

interface IRouteContext {
  route?: Route
  refetch: () => Promise<unknown>
}

interface RouteProps
  extends RouteComponentProps<{
    route_id: string
    noLoader?: boolean
  }> {
  children: React.ReactNode
}

export const RouteContext = createContext<IRouteContext>({} as IRouteContext)

const RouteProvider = (props: RouteProps) => {
  const { loading, data, refetch } = useQuery<
    FindUniqueRoutePayload,
    FindUniqueRouteVariables
  >(FIND_UNIQUE_ROUTE, {
    skip: !props.route_id,
    variables: {
      where: { id: props.route_id },
    },
  })

  if (loading) return props.noLoader ? null : <Loader />

  return (
    <RouteContext.Provider
      value={{
        route: data?.route,
        refetch,
      }}
    >
      {props.children}
    </RouteContext.Provider>
  )
}

export default RouteProvider
