import React from 'react'

import { Grid } from 'semantic-ui-react'

import useGenericOptionFiltersSearch from 'components/admin/catalog/generic-option/use-filters-search'
import FiltersAndSearch from 'components/admin/filters-search'
import Loader from 'components/shared/loader'
import useGenericOptionMany from 'context/generic-option/use-many'
import {
  SelectionCardExpandSelectProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { GenericOption } from 'types/generic-option'

import GenericOptionSelectionCard from './card'

const GenericOptionsSelection = ({
  selectedGenericOptions,
  setSelectedGenericOptions,
}: {
  selectedGenericOptions: Partial<GenericOption>[]
  setSelectedGenericOptions: (genericOption: Partial<GenericOption>[]) => void
}) => {
  const { genericOptions, loading } = useGenericOptionMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useGenericOptionFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
        style={{ marginTop: '10px' }}
      />
      {loading ? (
        <Grid.Row style={{ minHeight: '100vh' }}>
          <Grid.Column>
            <Loader flex fullScreen={false} />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row columns={4}>
          {genericOptions.map((item) => {
            return (
              <Grid.Column key={item.id} style={{ marginBottom: '20px' }}>
                <GenericOptionSelectionCard
                  genericOption={item}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      selectOnly: true,
                      onSelect: () => {
                        if (
                          selectedGenericOptions.find((s) => s.id === item.id)
                        )
                          setSelectedGenericOptions(
                            selectedGenericOptions.filter(
                              (s) => s.id !== item.id,
                            ),
                          )
                        else
                          setSelectedGenericOptions([
                            ...selectedGenericOptions,
                            item,
                          ])
                      },
                      selected: selectedGenericOptions.some(
                        (s) => s.id === item.id,
                      ),
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      )}
    </Grid>
  )
}

export default GenericOptionsSelection
