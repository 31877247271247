// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sortBy } from 'lodash'
import { Accordion, Image, Menu, Modal, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import { formatDollars } from 'context/dollar-utils'
import { getReferenceImage } from 'context/media/utils-many'
import useDesign from 'context/planner/design-use'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import { ModalIcon } from 'design-system/components/modal'
import { PointingMenu, StyledModal, StyledTable } from 'styles/planner/main'
import { Colors } from 'styles/planner/system'
import { NobiliaFeature } from 'types/nobilia-feature'

import { ProductBase } from '../../objects/product'
import { GenericProductBase } from '../../objects/products/generic/base'
import NobiliaProductBase from '../../objects/products/nobilia/base'
import { matchPreset, Preset } from '../../utils/preset-utils'

const TAB_ITEMS = {
  Nobilia: 'Nobilia',
  Appliances: 'Appliances',
  Countertop: 'Countertop',
} as const

type TabItemType = (typeof TAB_ITEMS)[keyof typeof TAB_ITEMS]

interface ProductListModalProps {
  onCloseModal: () => void
  open: boolean
  products: ProductBase[]
}

const ProductListModal = ({
  open,
  onCloseModal,
  products,
}: ProductListModalProps) => {
  const { presets } = useDesign()
  const { nobiliaFeatures } = useNobiliaOptions()
  const [activeItem, setActiveItem] = useState<TabItemType>(TAB_ITEMS.Nobilia)

  const { totalPrice, nobiliaProducts, appliances, countertops } =
    useMemo(() => {
      const countertops: GenericProductBase[] = []
      const appliances: GenericProductBase[] = []
      const nobiliaProducts: NobiliaProductBase[] = []
      const totalPrice = products.reduce((acc, product) => {
        if (!product.isNobiliaProduct()) {
          const { identifier } = product.productClass
          const genericPoduct = product as GenericProductBase
          if (identifier === 'countertop' || identifier === 'backsplash') {
            countertops.push(genericPoduct)
          } else if (identifier !== 'plinth') {
            appliances.push(genericPoduct)
          }
          return acc
        }
        const nobiliaProduct = product as NobiliaProductBase
        nobiliaProducts.push(nobiliaProduct)

        return acc + nobiliaProduct.getPriceWithAdditionalItems()
      }, 0)

      return { totalPrice, nobiliaProducts, appliances, countertops }
    }, [products])

  return (
    <StyledModal onClose={onCloseModal} open={open} size="large">
      <ModalIcon onClick={onCloseModal}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </ModalIcon>
      <Modal.Header>
        <p className="label-black">Products</p>
      </Modal.Header>
      <Modal.Content scrolling>
        <PointingMenu pointing secondary>
          <Menu.Item
            content={<p>Nobilia</p>}
            name={TAB_ITEMS.Nobilia}
            active={activeItem === TAB_ITEMS.Nobilia}
            onClick={() => setActiveItem(TAB_ITEMS.Nobilia)}
          />
          <Menu.Item
            content={<p>Appliances</p>}
            name={TAB_ITEMS.Appliances}
            active={activeItem === TAB_ITEMS.Appliances}
            onClick={() => setActiveItem(TAB_ITEMS.Appliances)}
          />
          <Menu.Item
            content={<p>Countertop</p>}
            name={TAB_ITEMS.Countertop}
            active={activeItem === TAB_ITEMS.Countertop}
            onClick={() => setActiveItem(TAB_ITEMS.Countertop)}
          />
        </PointingMenu>
        {activeItem === TAB_ITEMS.Nobilia && (
          <ProductsTable
            nobiliaFeatures={nobiliaFeatures}
            presets={presets}
            products={sortBy(
              nobiliaProducts,
              (p) => p.dynamicParameters.positionNumber,
            )}
          />
        )}
        {activeItem === TAB_ITEMS.Appliances && (
          <GenericProductsTable products={appliances} />
        )}
        {activeItem === TAB_ITEMS.Countertop && (
          <GenericProductsTable products={countertops} />
        )}
      </Modal.Content>
      <ProductsTotalPrice>
        <p>Total Cabinetry Price:</p>
        <h2>{formatDollars(totalPrice)}</h2>
      </ProductsTotalPrice>
    </StyledModal>
  )
}

export default ProductListModal

const ProductsTable = ({
  nobiliaFeatures,
  presets,
  products,
}: {
  nobiliaFeatures: NobiliaFeature[]
  presets: Preset[]
  products: ProductBase[]
}) => {
  return (
    <StyledTable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <p className="small">POS</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Cabinet</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Options</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Additional Items</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Price</p>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {products
          .filter((p) => !p.parent)
          .map((p, i) => (
            <ProductRow
              key={p.idLocal + i}
              p={p}
              presets={presets}
              nobiliaFeatures={nobiliaFeatures}
            />
          ))}
      </Table.Body>
    </StyledTable>
  )
}

const ProductRow = ({
  p,
  nobiliaFeatures,
  presets,
}: {
  p: ProductBase
  nobiliaFeatures: NobiliaFeature[]
  presets: Preset[]
}) => {
  const [activePrice, setActivePrice] = useState<boolean>(false)
  const [activeOptions, setActiveOptions] = useState<boolean>(false)

  const additionalItems = p.isNobiliaProduct()
    ? (p as NobiliaProductBase).getNobiliaProductChildren()
    : []
  const options = p.getNodeOptions()
  const image = p.getImageURL()
  const name = p.getName()

  const fullPrice = additionalItems.reduce(
    (acc, a) => acc + a.getPrice(),
    p.getPrice(),
  )
  const preset = p.isNobiliaProduct()
    ? matchPreset(presets, (p as NobiliaProductBase).options)
    : null

  return (
    <Table.Row key={`nobiliaProducts#${p.idLocal}`} verticalAlign="top">
      <Table.Cell width={1}>{p.dynamicParameters.positionNumber}</Table.Cell>
      <Table.Cell width={3}>
        <p>{name}</p>
        {image && (
          <div style={{ position: 'relative', minHeight: '200px' }}>
            <Image
              src={image}
              style={{ position: 'absolute', width: '100%' }}
            />
          </div>
        )}
      </Table.Cell>
      <Table.Cell width={5}>
        <StyledAccordion fluid styled>
          <Accordion.Title
            active={activeOptions}
            onClick={() => setActiveOptions(!activeOptions)}
          >
            <p>{preset?.name || 'Custom'}</p>
            <FontAwesomeIcon icon={['fal', activeOptions ? 'minus' : 'plus']} />
          </Accordion.Title>
          <Accordion.Content active={activeOptions}>
            {options
              ?.filter((o) => !!o.value)
              ?.map((o) => {
                const feature = nobiliaFeatures.find(
                  (f) => f.featureNo === o.id,
                )
                const option = feature?.options.find(
                  (opt) => opt.optionKey === o.value,
                )

                return (
                  feature && (
                    <p key={o.id} style={{ marginBottom: '6px' }}>
                      <span className="gray small">{feature.description}</span>
                      <br />
                      {option?.optionKey} {option?.description}
                    </p>
                  )
                )
              })}
          </Accordion.Content>
        </StyledAccordion>
      </Table.Cell>
      <Table.Cell width={4}>
        {additionalItems.map((a) => (
          <p style={{ marginBottom: '6px' }}>
            <span className="gray small">Qty: {a.quantity}</span>
            <br />
            <b>{a.getName()}</b>
            <br />
            {a.getDescription()}
          </p>
        ))}
      </Table.Cell>
      <Table.Cell width={3}>
        <StyledAccordion fluid styled>
          <Accordion.Title
            active={activePrice}
            onClick={() => setActivePrice(!activePrice)}
          >
            <p>{formatDollars(fullPrice)}</p>
            <FontAwesomeIcon icon={['fal', activePrice ? 'minus' : 'plus']} />
          </Accordion.Title>
          <Accordion.Content active={activePrice}>
            <p style={{ marginBottom: '6px' }}>
              <span className="gray">{formatDollars(p.getPrice())}</span>
              <br />
              <span className="small">{name}</span>
            </p>
            {additionalItems?.map((a) => (
              <p key={a.idLocal} style={{ marginBottom: '6px' }}>
                <span className="gray">{formatDollars(a.getPrice())}</span>
                <br />
                <span className="small">
                  {a.productClass.orderId} ({a.quantity})
                </span>
              </p>
            ))}
          </Accordion.Content>
        </StyledAccordion>
      </Table.Cell>
    </Table.Row>
  )
}

const GenericProductsTable = ({
  products,
}: {
  products: GenericProductBase[]
}) => {
  return (
    <StyledTable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <p className="small">Appliance</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Size</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Model</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Product Link</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Spec Link</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="small">Status</p>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {products.map((product, idx) => {
          const { productClass } = product
          return (
            <Table.Row key={`appliances#${idx}`}>
              <Table.Cell>
                <ProductImageDiv>
                  <Image
                    src={getReferenceImage(productClass.media)}
                    rounded
                    size="tiny"
                  />
                  <span>
                    {productClass?.metadata?.name ?? product.getName()}
                  </span>
                </ProductImageDiv>
              </Table.Cell>
              {/* TODO Add metadata to the appliances and countertops to populate these cells */}
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </StyledTable>
  )
}

export const StyledAccordion = styled(Accordion)`
  background: ${Colors.gray50} !important;
  border-radius: 3px !important;
  box-shadow: none !important;

  .title {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
    padding: 6px 12px !important;

    p {
      color: ${Colors.black};
      margin: 0 15px 0 0;
    }

    &.active {
      border-bottom: 1px solid ${Colors.gray200};
    }

    svg {
      color: ${Colors.black};
      font-size: 13px;
      margin-right: 5px;
    }
  }

  .content {
    padding: 9px 14px 5px !important;
  }
`

const ProductsTotalPrice = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
  width: 100%;
  background-color: ${Colors.gray100};
  border: 1px solid ${Colors.gray200};
  text-align: right;

  & > p {
    margin-bottom: 0;
    margin-right: 14px;
  }
`

const ProductImageDiv = styled.div`
  display: flex;
  align-items: center;
  font-family: 'GT America';
  & > span {
    margin-left: 8px;
  }
`
