import { sortBy } from 'lodash'
import moment from 'moment'

import { parseIssues } from 'context/delivery/utils'
import { parseFiles } from 'context/project-file/utils-many'
import { getRoomFormattedType } from 'context/room/utils'
import { getSupplierOrderStatusSummary } from 'context/supplier-order/utils'
import { Order } from 'types/order'
import { Project } from 'types/project'
import { RoomGraphQL } from 'types/room'
import { OverviewChecklist, OverviewAlert, StatusMode } from 'types/utils'

import { getOrderNameWithDescription, parseSupplierOrders } from './utils'

const constructOverview = (
  o: Order,
  {
    project,
    rooms,
  }: {
    project: Project
    rooms: RoomGraphQL[]
  },
  onlyAddOnRemedialOrders?: boolean,
): {
  checklists: OverviewChecklist[]
  alerts: OverviewAlert[]
} => {
  const { deliveries, supplierOrdersAll } = parseSupplierOrders(
    o,
    onlyAddOnRemedialOrders,
  )

  const roomsSorted = sortBy(rooms, (r) => r.createdAt)

  return {
    checklists: [
      {
        checklistItems: [
          {
            key: 'balancePaid',
            url: `/admin/projects/${project.id}/orders/${o.id}`,
            name: 'Balance Paid',
            subtitle: getOrderNameWithDescription(o),
            statusMode: (() => {
              if (o.placedAt) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (o.placedAt) return 'Completed'
              return 'Pending'
            })(),
          },
          ...sortBy(o.designs, (d) => d.room.createdAt).map((d) => ({
            key: `orderSent${d.id}`,
            url: `/admin/designs?defaultFilter=unsent`,
            name: `${getRoomFormattedType(d.room)} Order Sent`,
            room: d.room,
            statusMode: (() => {
              if (d.supplierOrders.length) return StatusMode.Confirmed
              return StatusMode.OpenForEdit
            })(),
            statusText: (() => {
              if (d.supplierOrders.length)
                return `Sent ${moment(d.supplierOrders[0].createdAt).format(
                  'LL',
                )}`
              return 'Pending'
            })(),
          })),
          ...roomsSorted.map((r) => {
            const { installationPacks } = parseFiles(r.files)
            return {
              key: `pack${r.id}`,
              url: `/admin/projects/${project.id}/rooms/${r.id}/packs`,
              name: `${getRoomFormattedType(r)} Pack`,
              room: r,
              statusMode: (() => {
                if (
                  installationPacks.length ||
                  r.data?.installationPackNotIncluded
                )
                  return StatusMode.Confirmed
                return StatusMode.OpenForEdit
              })(),
              statusText: (() => {
                if (r.data?.installationPackNotIncluded) return 'Not required'
                if (installationPacks.length)
                  return `Created ${moment(
                    installationPacks[0].createdAt,
                  ).format('LL')}`
                return 'Pending'
              })(),
            }
          }),
          ...supplierOrdersAll.map((so) => {
            return {
              key: `supplierOrder${so.id}`,
              url: `/admin/projects/${project.id}/supplier-orders/${so.id}`,
              name: `${getRoomFormattedType(so.design.room)} ACK ${
                so.nobiliaAckNumber || 'Pending'
              }`,
              room: so.design.room,
              statusMode: (() => {
                if (so.data?.deliveryAtActual) return StatusMode.Confirmed
                return StatusMode.OpenForEdit
              })(),
              statusText: (() => {
                const statusSummary = getSupplierOrderStatusSummary(so)
                return `${statusSummary.currentStage} ${statusSummary.currentStageDate}`
              })(),
            }
          }),
          ...deliveries.map((d) => {
            const { damagesPresent, issues } = parseIssues(d)
            const { deliveryPalletes } = parseFiles(d.files)
            return {
              key: `delivery${d.id}`,
              url: `/admin/projects/${project.id}/deliveries/${d.id}`,
              name: d.data?.name || `Delivery`,
              statusMode: (() => {
                if (damagesPresent) return StatusMode.Submitted
                if (d.data?.damagesSelection) return StatusMode.Confirmed
                return StatusMode.OpenForEdit
              })(),
              statusText: (() => {
                if (damagesPresent) return `${issues.length} issues submitted`
                if (d.data?.damagesSelection)
                  return `${deliveryPalletes.length} photos uploaded`
                return `Pending`
              })(),
            }
          }),
        ],
      },
    ],
    alerts: [],
  }
}

export default constructOverview
