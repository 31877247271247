import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import Projects from 'components/admin/project/index-many'
import SectionTitle from 'components/admin/section-title'
import ProjectManyProvider from 'context/project/provider-many'

const ProjectsView = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title="Projects" />
        <Grid.Row>
          <Grid.Column>
            <ProjectManyProvider
              defaultFilters={{
                sortBy: 'derivedActivity',
                sortDirection: 'descending',
                status: 'open',
                take: 30,
              }}
              skipLoader
            >
              <Projects />
            </ProjectManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default ProjectsView
