import React from 'react'

import { Image, Table } from 'semantic-ui-react'

import * as mUtils from 'context/media/utils-many'
import useRoomElementClassMany from 'context/room-element-class/use-many'
import Button from 'design-system/components/button/button'
import * as viewUtils from 'views/utils'

const RoomElementClassesTable = () => {
  const { catalog, roomElementClasses, queryFilters, setQueryFilters } =
    useRoomElementClassMany()
  const { sortBy, sortDirection } = queryFilters
  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Product Type</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'identifier' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'identifier',
                sortDirection:
                  sortBy === 'identifier' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Identifier
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {roomElementClasses.map((rec) => {
          const display = mUtils.parseMedia(rec.media).reference.length
            ? mUtils.parseMedia(rec.media).reference[0].key
            : 'images/app/catalog_missing_file.png'
          return (
            <Table.Row key={rec.id}>
              <Table.Cell width={2}>
                <Image
                  centered
                  size="small"
                  src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    display,
                  )}`}
                />
              </Table.Cell>
              <Table.Cell width={6}>
                {viewUtils.capsSnakeCaseToTitleCase(rec.roomElementType)}
              </Table.Cell>
              <Table.Cell width={7}>{rec.identifier}</Table.Cell>
              <Table.Cell textAlign="center" width={1}>
                <Button
                  kind="solid"
                  color="blue"
                  fontAwesomeIcon="arrow-up-right-from-square"
                  onClick={() =>
                    window.open(
                      `/admin/catalog/${catalog}/room-element-classes/${rec.id}`,
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default RoomElementClassesTable
