import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectFileNotFound from 'components/shared/project/project-file/file-not-found'
import ProjectFileLayout from 'components/shared/project/project-file/layout'
import { ScrollContainer } from 'components/shared/project/project-file/utils'
import useAuth from 'context/auth/use'
import useDesign from 'context/design/use'
import ProjectFileProvider from 'context/project-file/provider'
import useProjectFile from 'context/project-file/use'

import HeaderInternal from './header-internal'
import SidebarComments from './sidebar-comments-internal'

type ProjectFileProps = RouteComponentProps<{
  project_id: string
  room_id: string
  design_id: string
  file_id: string
}>

const DesignPlanInternal = (_: ProjectFileProps) => {
  const { isTechDesigner } = useAuth()
  const { comments } = useDesign()
  const { projectFile } = useProjectFile()
  const [sidebarShow, setSidebarShow] = useState(false)
  const [isReviewer, setIsReviewer] = useState(isTechDesigner || false)
  const [showResolvedComments, setShowResolvedComments] = useState(false)

  const toggleShowResolvedComments = () =>
    setShowResolvedComments(!showResolvedComments)
  const toggleIsReviewer = () => setIsReviewer(!isReviewer)
  const sidebarToggle = () => setSidebarShow(!sidebarShow)

  if (!projectFile) return <ProjectFileNotFound />

  return (
    <ProjectFileLayout
      header={
        <HeaderInternal
          disableResolvedComments={!comments?.length}
          downloadName={projectFile.name}
          downloadUrl={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
            projectFile.key,
          )}`}
          isInternalReview
          isReviewer={isReviewer}
          showResolvedComments={showResolvedComments}
          toggleIsReviewer={toggleIsReviewer}
          toggleShowResolvedComments={toggleShowResolvedComments}
        />
      }
      sidebar={
        <ScrollContainer>
          <SidebarComments
            fileId={projectFile.id}
            isReviewer={isReviewer}
            showResolvedComments={showResolvedComments}
            toggleSideMenu={sidebarToggle}
          />
        </ScrollContainer>
      }
      sidebarShow
      sidebarToggle={sidebarToggle}
      sidebarTrigger={<p>Comments</p>}
    />
  )
}

export default (props: ProjectFileProps) => (
  <ProjectFileProvider file_id={props.file_id}>
    <DesignPlanInternal {...props} />
  </ProjectFileProvider>
)
