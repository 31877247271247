import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import Shipments from 'components/admin/shipment/index-many'
import ShipmentManyProvider from 'context/shipment/provider-many'

const Module = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid columns="equal">
        <SectionTitle title="Shipments" />
        <Grid.Row>
          <Grid.Column>
            <ShipmentManyProvider
              defaultFilters={{
                includeArchived: false,
                includeProposed: false,
                sortBy: 'derivedStatus',
                sortDirection: 'ascending',
                take: 20,
              }}
              skipLoader
            >
              <Shipments />
            </ShipmentManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
