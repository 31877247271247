import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_NOBILIA_PRODUCT_CLASS,
  FindUniqueNobiliaProductClassPayload,
  FindUniqueNobiliaProductClassVariables,
} from 'queries/nobilia-product-class'
import { NobiliaProductClass } from 'types/nobilia-product-class'

interface INobiliaProductClassContext {
  catalog: string
  nobiliaProductClass: NobiliaProductClass
  refetch: () => Promise<unknown>
}

interface NobiliaProductClassProps
  extends RouteComponentProps<{
    catalog: string
    nobilia_product_class_id: string
  }> {
  children: React.ReactNode
}

export const NobiliaProductClassContext =
  createContext<INobiliaProductClassContext>({} as INobiliaProductClassContext)

const NobiliaProductClassProvider = (props: NobiliaProductClassProps) => {
  const { data, loading, refetch } = useQuery<
    FindUniqueNobiliaProductClassPayload,
    FindUniqueNobiliaProductClassVariables
  >(FIND_UNIQUE_NOBILIA_PRODUCT_CLASS, {
    variables: {
      where: { id: props.nobilia_product_class_id },
    },
  })

  if (loading || !data) return <Loader />

  return (
    <NobiliaProductClassContext.Provider
      value={{
        catalog: props.catalog ?? '',
        nobiliaProductClass: data.nobiliaProductClass,
        refetch,
      }}
    >
      {props.children}
    </NobiliaProductClassContext.Provider>
  )
}

export default NobiliaProductClassProvider
