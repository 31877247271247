import React, { useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'

import { notifyError, notifySuccess } from 'context/notifications/trigger'
import { useConversationMany } from 'context/project/conversation/provider-many'
import { useConversationMutate } from 'context/project/conversation/use-mutate'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import { Modal } from 'design-system/components/modal'
import { Colors } from 'styles/app/system'
import { Conversation } from 'types/conversation'
import { User } from 'types/user'

import { ConversatonModalConfirmation } from './confirmation'
import {
  ConversationCreateUser,
  DEFAULT_NEW_USER_VALUES,
  IUserFields,
} from './create-user'
import { ConversationUserModalActions } from './modal-actions'
import { ConversationSelectUser } from './select-user'

type ConversationUserModalProps = {
  conversation: Conversation | null
  handleExited: () => void
  handleClose: () => void
  openUserModal: boolean
}

export const ConversationUserModal = ({
  conversation,
  handleClose,
  handleExited,
  openUserModal,
}: ConversationUserModalProps) => {
  //* QUERY/MUTATION HOOKS
  const { updateConversation, loadingUpdate } = useConversationMutate()
  const { refetch } = useConversationMany()
  const { createUser, loadingCreate } = useUserMutateNoContext()

  //* FORM
  const formMethods = useForm<IUserFields>({
    defaultValues: DEFAULT_NEW_USER_VALUES,
    mode: 'onTouched',
  })
  const {
    handleSubmit,
    setValue,
    formState: { isValid: createUserIsValid },
  } = formMethods

  //* STATE
  const [showCreateNewUser, setShowCreateNewUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [showConfirmation, setShowConfirmation] = useState(false)

  //* HELPERS
  const clientUserId = conversation?.clientUser?.id

  const disableSaveButton = showCreateNewUser
    ? !createUserIsValid
    : selectedUser?.id === clientUserId || (!clientUserId && !selectedUser)

  //* HANDLERS
  const handleSave: SubmitHandler<IUserFields> = async (newUser) => {
    let userToConnect = selectedUser

    if (showCreateNewUser) {
      try {
        const response = await createUser({
          variables: {
            data: {
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              email: newUser.email,
              phone: newUser.phone,
              type: newUser.type,
              projects: {
                create: [
                  {
                    project: {
                      connect: {
                        id: newUser.project,
                      },
                    },
                    isPrimary: newUser.primary,
                    userRole: newUser.projectRole,
                    isSubscribed: newUser.subscribed,
                  },
                ],
              },
            },
          },
        })
        userToConnect = response.data?.createOneUser ?? null
      } catch (error) {
        notifyError('Could not create user')
        return
      }
    }

    await updateConversation({
      variables: {
        where: {
          id: conversation?.id ?? '',
        },
        data: {
          clientUser: userToConnect
            ? { connect: { id: userToConnect.id } }
            : { disconnect: true },
        },
      },
      onCompleted: () => {
        notifySuccess(
          showCreateNewUser
            ? 'User created and connected successfully'
            : `User ${
                userToConnect ? 'connected' : 'disconnected'
              } successfully`,
        )
      },
      onError: () =>
        notifyError(
          `Could not ${
            userToConnect ? 'connect' : 'disconnect'
          } user to conversation`,
        ),
    })
    refetch()
    handleClose()
  }

  //* EFFECTS
  useEffect(() => {
    if (conversation?.clientUser) {
      setSelectedUser(conversation.clientUser)
    }
  }, [conversation?.clientUser])

  useEffect(() => {
    if (conversation?.messages && showCreateNewUser) {
      const clientPhone =
        conversation.messages.find(
          (message) => message.data?.direction === 'incoming',
        )?.data?.sourceFrom ||
        conversation.messages[0]?.data?.sourceFrom ||
        ''

      setValue('phone', clientPhone)
    }
  }, [conversation?.messages, showCreateNewUser])

  return (
    <>
      <Modal
        open={openUserModal && !!conversation}
        onClose={handleClose}
        onExited={() => {
          setShowCreateNewUser(false)
          setSelectedUser(null)
          setShowConfirmation(false)
          handleExited()
        }}
        unmountOnExit
        title="Link Client User"
        size="medium"
        contentStyle={{
          padding: 32,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 16,
          backgroundColor: Colors.gray50,
        }}
        actionsStyle={{
          zIndex: 3,
        }}
        headerStyle={{
          zIndex: 3,
        }}
        customActions={
          <ConversationUserModalActions
            disableSave={disableSaveButton}
            onCancel={handleClose}
            onSave={handleSubmit(handleSave)}
            setShowCreateNewUser={setShowCreateNewUser}
            showCreateNewUser={showCreateNewUser}
            showConfirmation={showConfirmation}
            setShowConfirmation={setShowConfirmation}
            disableCreateNewUser={!showCreateNewUser && !!selectedUser}
            loading={loadingUpdate || loadingCreate}
          />
        }
      >
        {showCreateNewUser ? (
          <ConversationCreateUser formMethods={formMethods} />
        ) : (
          <ConversationSelectUser
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        )}
        <ConversatonModalConfirmation
          showConfirmation={showConfirmation}
          isCreatingUser={showCreateNewUser}
          hasUserSelected={!!selectedUser}
        />
      </Modal>
    </>
  )
}
