import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { Form, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import Tooltip from 'components/admin/tooltip'
import { useCatalogFrontColor } from 'content-queries/cx/catalog-front-color'
import { useCatalogHandleColor } from 'content-queries/cx/catalog-handle-color'
import { useCategoryHandle } from 'content-queries/cx/category-handle'
import { useDrillPatterns } from 'content-queries/cx/drill-patterns'
import { useFrontMaterial } from 'content-queries/cx/front-material'
import { useHandleType } from 'content-queries/cx/handle-type'
import useAuth from 'context/auth/use'
import useNobiliaOptionManyNoContext from 'context/nobilia-option/use-many-no-context'
import useNobiliaOptionMutate from 'context/nobilia-option/use-mutate'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import * as NO from 'planner/objects/products/nobilia/options/definitions'
import { StyledForm } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { NobiliaOption, NobiliaOptionData } from 'types/nobilia-option'
import { getPriceRangeFromPriceGroup } from 'views/utils'

interface NobiliaOptionMetadataModalProps {
  nobiliaOption?: NobiliaOption
  priceGroup?: string
  refetch?: () => Promise<unknown>
}

const NobiliaOptionMetadataModal = ({
  nobiliaOption,
  priceGroup,
  refetch,
}: NobiliaOptionMetadataModalProps) => {
  const isFront = nobiliaOption?.featureNo === NO.FRONT_COMBO
  const [showModal, toggleModal] = useState<boolean>(false)
  const [nobiliaOptionSearch, setNobiliaOptionSearch] = useState<string>('')
  const initialState = {
    category: nobiliaOption?.data?.category || '',
    color: nobiliaOption?.data?.color || '',
    displayName: nobiliaOption?.data?.displayName || nobiliaOption?.description,
    drillDistance: nobiliaOption?.data?.drillDistance || undefined,
    drillPatterns: nobiliaOption?.data?.drillPatterns || undefined,
    edging: nobiliaOption?.data?.edging || '',
    finish: nobiliaOption?.data?.finish || '',
    handleTypes: nobiliaOption?.data?.handleTypes || undefined,
    height: nobiliaOption?.data?.height || undefined,
    isDiscontinued: nobiliaOption?.data?.isDiscontinued || false,
    material: nobiliaOption?.data?.material || '',
    priceRange:
      (isFront
        ? getPriceRangeFromPriceGroup(priceGroup)
        : nobiliaOption?.data?.priceRange) || '',
    relatedFronts: nobiliaOption?.data?.relatedFronts || [],
    relatedHandles: nobiliaOption?.data?.relatedHandles || [],
    showOnWebsiteCatalog: nobiliaOption?.data?.showOnWebsiteCatalog || false,
    texture: nobiliaOption?.data?.texture || '',
  }
  const [state, setState] =
    useState<NullableRecord<NobiliaOptionData>>(initialState)
  const { isAdmin } = useAuth()

  useEffect(() => {
    setState(initialState)
  }, [nobiliaOption])

  useEffect(() => {
    if (state.isDiscontinued)
      setState({
        ...state,
        contentfulEntryId: null,
        showOnWebsiteCatalog: false,
      })
    if (!state.showOnWebsiteCatalog)
      setState({
        ...state,
        contentfulEntryId: null,
      })
  }, [state.isDiscontinued, state.showOnWebsiteCatalog])

  const { updateNobiliaOption, loadingUpdate } = useNobiliaOptionMutate()

  const { fronts, handles } = useNobiliaOptionManyNoContext({
    variables: {
      orderBy: {
        optionKey: 'asc',
      },
      where: {
        catalog: { equals: nobiliaOption?.catalog ?? '' },
        featureNo: {
          in: [NO.FRONT_COMBO, NO.HANDLE_COMBINATION],
        },
        optionKey: {
          contains: nobiliaOptionSearch,
        },
      },
    },
    skip: !nobiliaOptionSearch,
  })

  const onCloseModal = () => {
    setState(initialState)
    toggleModal(false)
  }

  const onSave = async () => {
    await updateNobiliaOption({
      variables: {
        data: {
          data: {
            ...state,
          },
        },
        where: { id: nobiliaOption?.id ?? '' },
      },
      onCompleted: refetch,
    })
    toggleModal(false)
  }

  const { data: handleCategories } = useCategoryHandle()
  const { data: handleColors } = useCatalogHandleColor()
  const { data: colors } = useCatalogFrontColor()

  const { data: drillPatterns } = useDrillPatterns()
  const handleDrillPatterns = drillPatterns.filter(
    (dp: { title: string }) => dp.title !== 'NA',
  )
  const finishes = [
    'Ultra High Gloss',
    'High Gloss',
    'Matt',
    'Super Matt',
    'Ultra Matt',
    'Honed Matt',
    'Natural Material Reproduction',
  ]
  const { data: handleTypes } = useHandleType()
  const { data: materials } = useFrontMaterial()
  const textures = ['No Texture', 'Stone', 'Wood', 'Steel']
  const priceRanges = ['$', '$$', '$$$']
  const drillDistances = [
    'NA',
    '12',
    '16',
    '32',
    '64',
    '96',
    '128',
    '160',
    '184',
    '192',
    '320',
  ]
  const handleHeights = [
    'NA',
    '8',
    '16',
    '17',
    '19',
    '20',
    '21',
    '22',
    '25',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '43',
  ]

  if (nobiliaOption?.featureNo === NO.FRONT_COMBO)
    return (
      <Modal
        title="Tags"
        onClose={() => onCloseModal()}
        onOpen={() => (!isAdmin ? null : toggleModal(true))}
        trigger={
          <Button
            color="gray"
            disabled={!isAdmin}
            fontAwesomeIcon="pencil"
            kind="ghost"
            text="Edit"
          />
        }
        open={showModal}
        size="fullscreen"
        saveButton={{
          loading: loadingUpdate,
          onClick: onSave,
          disabled:
            !state.handleTypes?.length ||
            !state.color ||
            !state.finish ||
            !state.texture ||
            !state.material ||
            !state.drillPatterns,
        }}
      >
        <StyledForm styled={{ grayInputs: true }}>
          <h3>Metadata - {nobiliaOption.optionKey}</h3>
          <div
            style={{
              backgroundColor: Colors.gray100,
              margin: '24px 0 28px',
              padding: '16px 0 0 16px',
              width: '100%',
            }}
          >
            <p className="subtitle">Product Status</p>
            <Form.Group inline>
              <Form.Checkbox
                checked={state.isDiscontinued ?? undefined}
                label={
                  <label>
                    <span style={{ paddingRight: '5px' }}>
                      Product Discontinued
                    </span>
                    <Tooltip type={'Product Discontinued'} />
                  </label>
                }
                onChange={() =>
                  setState({
                    ...state,
                    isDiscontinued: !state.isDiscontinued,
                  })
                }
                style={{ paddingRight: '40px' }}
              />
              <Form.Checkbox
                checked={state.showOnWebsiteCatalog ?? undefined}
                disabled={state.isDiscontinued ?? undefined}
                label={
                  <label>
                    <span style={{ color: Colors.green800 }}>
                      Show on website catalog
                    </span>
                  </label>
                }
                onChange={() =>
                  setState({
                    ...state,
                    showOnWebsiteCatalog: !state.showOnWebsiteCatalog,
                  })
                }
                toggle
              />
            </Form.Group>
          </div>
          <Form.Group widths={2}>
            <Form.Input
              label="Display Name"
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  displayName: value as string,
                })
              }}
              value={state.displayName}
            />
          </Form.Group>
          <p className="subtitle">For Catalog Filters</p>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Handle Type"
              multiple
              options={handleTypes.map(
                (ht: { title: string; contentful_id: string }) => ({
                  key: ht.title,
                  text: ht.title,
                  value: ht.contentful_id,
                }),
              )}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  handleTypes: value as string[],
                })
              }}
              placeholder="Handle Type"
              required
              selection
              value={state.handleTypes ?? []}
            />
            <Form.Dropdown
              fluid
              label="Color"
              options={colors.map(
                (c: {
                  contentful_id: string
                  image: { file: { url: string } }
                  title: string
                }) => ({
                  key: c.contentful_id,
                  text: (
                    <ColorText>
                      <Image src={c.image?.file.url} />
                      {c.title}
                    </ColorText>
                  ),
                  value: c.contentful_id,
                }),
              )}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  color: value as string,
                })
              }}
              placeholder="Color"
              required
              selection
              value={state.color ?? ''}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Finish"
              options={finishes.map((f) => ({
                key: f,
                text: f,
                value: f,
              }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  finish: value as string,
                })
              }}
              placeholder="Finish"
              required
              selection
              value={state.finish as string}
            />
            <Form.Dropdown
              fluid
              label="Texture"
              options={textures.map((t) => ({
                key: t,
                text: t,
                value: t,
              }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  texture: value as string,
                })
              }}
              placeholder="Texture"
              required
              selection
              value={state.texture as string}
            />
          </Form.Group>
          <p className="subtitle">For Product Page Only</p>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Material"
              options={materials.map(
                (m: { title: string; contentful_id: string }) => ({
                  key: m.title,
                  text: m.title,
                  value: m.contentful_id,
                }),
              )}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  material: value as string,
                })
              }}
              placeholder="Material"
              required
              selection
              value={state.material as string}
            />
            <Form.Dropdown
              fluid
              label="Drill Patterns"
              multiple
              options={drillPatterns.map(
                (dp: { contentful_id: string; title: string }) => ({
                  key: dp.title,
                  text: dp.title,
                  value: dp.contentful_id,
                }),
              )}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  drillPatterns: value as string[],
                })
              }}
              placeholder="Drill Patterns"
              required
              selection
              value={state.drillPatterns as string[]}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Related Handles"
              multiple
              options={_.uniqBy(
                [...(state?.relatedHandles ?? []), ...handles],
                (no) => no.identifier,
              ).map((no) => ({
                key: no.id,
                text: `${no.optionKey} - ${no.description}`,
                value: no.id,
              }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  relatedHandles: (value as string[]).map((v) => {
                    {
                      const found =
                        handles.find((no) => no.id === v) ||
                        state.relatedHandles?.find((no) => no.id === v)
                      return {
                        id: v,
                        identifier: found?.identifier as string,
                        description: found?.description as string,
                        featureNo: found?.featureNo as string,
                        optionKey: found?.optionKey as string,
                      }
                    }
                  }),
                })
              }}
              onSearchChange={(
                _: unknown,
                data: { searchQuery: React.SetStateAction<string> },
              ) => setNobiliaOptionSearch(data.searchQuery)}
              placeholder="Related Handles"
              search
              selection
              value={state.relatedHandles?.map((d) => d.id)}
            />
          </Form.Group>
          <p className="subtitle">For Additional Products Section</p>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Related Fronts"
              multiple
              options={_.uniqBy(
                [...(state.relatedFronts ?? []), ...fronts],
                (no) => no.identifier,
              )
                .filter((o) => o.id !== nobiliaOption.id)
                .map((no) => ({
                  key: no.id,
                  text: `${no.optionKey} - ${no.description}`,
                  value: no.id,
                }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  relatedFronts: (value as string[]).map((v) => {
                    {
                      const found =
                        fronts.find((no) => no.id === v) ||
                        state.relatedFronts?.find((no) => no.id === v)
                      return {
                        id: v,
                        identifier: found?.identifier as string,
                        description: found?.description as string,
                        featureNo: found?.featureNo as string,
                        optionKey: found?.optionKey as string,
                      }
                    }
                  }),
                })
              }}
              onSearchChange={(
                _: unknown,
                data: { searchQuery: React.SetStateAction<string> },
              ) => setNobiliaOptionSearch(data.searchQuery)}
              placeholder="Related Fronts"
              search
              selection
              value={state.relatedFronts?.map((d) => d.id)}
            />
          </Form.Group>
        </StyledForm>
      </Modal>
    )
  else if (nobiliaOption?.featureNo === NO.HANDLE_COMBINATION)
    return (
      <Modal
        title="Tags"
        onClose={() => onCloseModal()}
        onOpen={() => (!isAdmin ? null : toggleModal(true))}
        trigger={
          <Button
            color="gray"
            disabled={!isAdmin}
            fontAwesomeIcon="pencil"
            kind="ghost"
            text="Edit"
          />
        }
        open={showModal}
        size="fullscreen"
        saveButton={{
          loading: loadingUpdate,
          onClick: onSave,
          disabled:
            !state.category ||
            !state.color ||
            !state.priceRange ||
            !state.drillPatterns?.length ||
            !state.drillDistance ||
            !state.height,
        }}
      >
        <StyledForm styled={{ grayInputs: true }}>
          <h3>Metadata - {nobiliaOption?.optionKey}</h3>
          <div
            style={{
              backgroundColor: Colors.gray100,
              margin: '24px 0 28px',
              padding: '16px 0 0 16px',
              width: '100%',
            }}
          >
            <p className="subtitle">Product Status</p>
            <Form.Group inline>
              <Form.Checkbox
                checked={state.isDiscontinued ?? undefined}
                label={
                  <label>
                    Product Discontinued
                    <Tooltip type={'Product Discontinued'} />
                  </label>
                }
                onChange={() =>
                  setState({
                    ...state,
                    isDiscontinued: !state.isDiscontinued,
                  })
                }
                style={{ paddingRight: '40px' }}
              />
              <Form.Checkbox
                checked={state.showOnWebsiteCatalog ?? undefined}
                disabled={state.isDiscontinued ?? undefined}
                label={
                  <label>
                    <span style={{ color: Colors.green800 }}>
                      Show on website catalog
                    </span>
                  </label>
                }
                onChange={() =>
                  setState({
                    ...state,
                    showOnWebsiteCatalog: !state.showOnWebsiteCatalog,
                  })
                }
                toggle
              />
            </Form.Group>
          </div>
          <Form.Group widths={2}>
            <Form.Input
              label="Display Name"
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  displayName: value as string,
                })
              }}
              value={state.displayName}
            />
          </Form.Group>
          <p className="subtitle">For Catalog Filters</p>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Category"
              options={handleCategories.map(
                (hc: { title: string; contentful_id: string }) => ({
                  key: hc.title,
                  text: hc.title,
                  value: hc.contentful_id,
                }),
              )}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  category: value as string,
                })
              }}
              placeholder="Category"
              required
              selection
              value={state.category ?? undefined}
            />
            <Form.Dropdown
              fluid
              label="Color"
              options={handleColors.map(
                (c: {
                  contentful_id: string
                  image: { file: { url: string } }
                  title: string
                }) => ({
                  key: c.contentful_id,
                  text: (
                    <ColorText>
                      <Image src={c.image?.file.url} />
                      {c.title}
                    </ColorText>
                  ),
                  value: c.contentful_id,
                }),
              )}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  color: value as string,
                })
              }}
              placeholder="Color"
              required
              selection
              value={state.color ?? undefined}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Price Range"
              options={priceRanges.map((pr) => ({
                key: pr,
                text: pr,
                value: pr,
              }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  priceRange: value as string,
                })
              }}
              placeholder="Price Range"
              required
              selection
              value={state.priceRange ?? undefined}
            />
          </Form.Group>
          <p className="subtitle">For Product Page Only</p>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Drill Patterns"
              multiple
              options={handleDrillPatterns.map(
                (dp: { contentful_id: string; title: string }) => ({
                  key: dp.title,
                  text: dp.title,
                  value: dp.contentful_id,
                }),
              )}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  drillPatterns: value as string[],
                })
              }}
              placeholder="Drill Patterns"
              required
              selection
              value={state.drillPatterns ?? undefined}
            />
            <Form.Dropdown
              fluid
              label="Drill Distance"
              multiple
              options={drillDistances.map((dd) => ({
                key: dd,
                text: dd,
                value: dd,
              }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  drillDistance: value as string[],
                })
              }}
              placeholder="Drill Distance"
              required
              selection
              value={state.drillDistance ?? []}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Height"
              multiple
              options={handleHeights.map((h) => ({
                key: h,
                text: h,
                value: h,
              }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  height: value as string[],
                })
              }}
              placeholder="Height"
              required
              selection
              value={state.height ?? []}
            />
          </Form.Group>
          <p className="subtitle">For Additional Products Section</p>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              label="Related Handles"
              multiple
              options={_.uniqBy(
                [...(state.relatedHandles ?? []), ...handles],
                (no) => no.identifier,
              )
                .filter((o) => o.id !== nobiliaOption?.id)
                .map((no) => ({
                  key: no.id,
                  text: `${no.optionKey} - ${no.description}`,
                  value: no.id,
                }))}
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  relatedHandles: (value as string[]).map((v) => {
                    {
                      const found =
                        handles.find((no) => no.id === v) ||
                        state.relatedHandles?.find((no) => no.id === v)
                      return {
                        id: v,
                        identifier: found?.identifier as string,
                        description: found?.description as string,
                        featureNo: found?.featureNo as string,
                        optionKey: found?.optionKey as string,
                      }
                    }
                  }),
                })
              }}
              onSearchChange={(
                _: unknown,
                data: { searchQuery: React.SetStateAction<string> },
              ) => setNobiliaOptionSearch(data.searchQuery)}
              placeholder="Related Handles"
              search
              selection
              value={state.relatedHandles?.map((d) => d.id)}
            />
          </Form.Group>
        </StyledForm>
      </Modal>
    )
  else
    return (
      <Modal
        title="Tags"
        onClose={() => onCloseModal()}
        onOpen={() => (!isAdmin ? null : toggleModal(true))}
        trigger={
          <Button
            color="gray"
            disabled={!isAdmin}
            fontAwesomeIcon="pencil"
            kind="ghost"
            text="Edit"
          />
        }
        open={showModal}
        size="large"
        saveButton={{
          loading: loadingUpdate,
          onClick: onSave,
        }}
      >
        <StyledForm styled={{ grayInputs: true }}>
          <h3>Metadata</h3>
          <div
            style={{
              backgroundColor: Colors.gray100,
              margin: '24px 0 28px',
              padding: '16px 0 0 16px',
              width: '100%',
            }}
          >
            <p className="subtitle">Product Status</p>
            <Form.Group inline>
              <Form.Checkbox
                checked={state.isDiscontinued ?? undefined}
                label={
                  <label>
                    Product Discontinued
                    <Tooltip type={'Product Discontinued'} />
                  </label>
                }
                onChange={() =>
                  setState({
                    ...state,
                    isDiscontinued: !state.isDiscontinued,
                  })
                }
                style={{ paddingRight: '40px' }}
              />
              <Form.Checkbox
                checked={state.showOnWebsiteCatalog ?? undefined}
                disabled={state.isDiscontinued ?? undefined}
                label={
                  <label>
                    <span style={{ color: Colors.green800 }}>
                      Show on website catalog
                    </span>
                  </label>
                }
                onChange={() =>
                  setState({
                    ...state,
                    showOnWebsiteCatalog: !state.showOnWebsiteCatalog,
                  })
                }
                toggle
              />
            </Form.Group>
          </div>
          <Form.Group widths={2}>
            <Form.Input
              label="Display Name"
              onChange={(_: unknown, { value }) => {
                setState({
                  ...state,
                  displayName: value as string,
                })
              }}
              value={state.displayName}
            />
          </Form.Group>
        </StyledForm>
      </Modal>
    )
}

export default NobiliaOptionMetadataModal

const ColorText = styled.p`
  display: inline-flex;
  align-items: center;

  img.image {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: 2px;
  }
`
