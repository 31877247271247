import React from 'react'

import { useMatch } from '@reach/router'
import axios from 'axios'
import { navigate } from 'gatsby'
import { Checkbox, Menu } from 'semantic-ui-react'

import Button from 'design-system/components/button'
import HeaderContainer from 'design-system/containers/header/header'
import { StyledToggle } from 'styles/admin/main'

interface HeaderProps {
  disableResolvedComments?: boolean
  downloadName?: string
  downloadUrl?: string
  isInternalReview?: boolean
  isReviewer?: boolean
  showResolvedComments?: boolean
  toggleIsReviewer?: () => void
  toggleShowResolvedComments?: () => void
}

export default ({
  disableResolvedComments,
  downloadName,
  downloadUrl,
  isInternalReview,
  isReviewer,
  showResolvedComments,
  toggleShowResolvedComments,
  toggleIsReviewer,
}: HeaderProps) => {
  const match = useMatch(
    '/admin/projects/:project_id/rooms/:room_id/designs/:design_id/*',
  )

  return (
    <HeaderContainer
      leftItems={
        <Button
          onClick={() => {
            if (match)
              navigate(
                `/admin/projects/${match.project_id}/rooms/${match.room_id}/designs/${match.design_id}`,
              )
            else navigate(-1)
          }}
          color="gray"
          fontAwesomeIcon="arrow-left"
          iconAnimation="left"
          iconPosition="left"
          kind="text"
          text="Back"
        />
      }
      rightItems={
        <>
          {downloadUrl && (
            <Menu.Item className="only-computer">
              <Button
                color="gray"
                fontAwesomeIcon="arrow-to-bottom"
                iconAnimation="down"
                kind="ghost"
                text="Download"
                onClick={async () => {
                  // no-cache deal with Chromium cache CORS
                  const getContent = await axios.get(
                    `${downloadUrl}?no-cache`,
                    {
                      responseType: 'blob',
                    },
                  )
                  const file = window.URL.createObjectURL(
                    new Blob([getContent.data]),
                  )
                  const link = document.createElement('a')
                  link.download =
                    downloadName ||
                    (decodeURIComponent(downloadUrl).split('/').pop() as string)
                  link.href = file
                  const clickEvt = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                  })
                  link.dispatchEvent(clickEvt)
                  link.remove()
                }}
              />
            </Menu.Item>
          )}
          {isInternalReview && (
            <Checkbox
              checked={isReviewer}
              className="black"
              label="Reviewer Mode"
              onChange={() => toggleIsReviewer?.()}
              style={{ marginRight: '30px' }}
              toggle
            />
          )}
          {isInternalReview && (
            <StyledToggle
              checked={showResolvedComments}
              className="black"
              disabled={disableResolvedComments}
              label={<label>Show Resolved Issues</label>}
              onChange={() => toggleShowResolvedComments?.()}
              style={{ marginRight: '30px', minWidth: '250px' }}
              toggle
            />
          )}
        </>
      }
    />
  )
}
