import React from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import useSupplierOrderMany from 'context/supplier-order/use-many'

import SupplierOrdersTable from './table'
import useSupplierOrderFiltersSearch from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const SupplierOrders = ({ table }: { table?: React.ReactNode }) => {
  const { count, supplierOrders, fetchMore, loading, loadingMore } =
    useSupplierOrderMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useSupplierOrderFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${supplierOrders?.length} of ${count}`}{' '}
            Supplier Orders
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            {table ? table : <SupplierOrdersTable />}
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && supplierOrders?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default SupplierOrders
