import React from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'

import Prospects from 'components/admin/prospect/index-many'
import useAccountExecutive from 'context/dashboard/account-executive/use'
import ProspectManyProvider from 'context/prospect/provider-many'

import AccountExecutiveLayout from './layout'

const AccountExecutiveOverview = (_: RouteComponentProps) => {
  const { userId, userName } = useAccountExecutive()
  return (
    <AccountExecutiveLayout>
      <ProspectManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          meetingTime: {
            gte: moment().startOf('week').toISOString(),
            lte: moment().endOf('week').toISOString(),
          },
          sortBy: 'derivedMeeting',
          sortDirection: 'ascending',
          take: 30,
        }}
        skipLoader
      >
        <Prospects />
      </ProspectManyProvider>
    </AccountExecutiveLayout>
  )
}

export default AccountExecutiveOverview
