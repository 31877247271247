import { sortPayments, sortDesigns } from 'context/order/utils'
import { Design } from 'types/design'
import { NobiliaSampleGraphQL } from 'types/nobilia-sample'
import { Order } from 'types/order'
import { Payment, PaymentItem } from 'types/payment'
import { ProjectFile } from 'types/project-file'
import { Address } from 'types/utils'

export interface OrderReducerState {
  order: Partial<NullableRecord<Order>>
}

interface AddNobiliaSampleAction {
  type: 'add_nobilia_sample'
  nobiliaSample: NobiliaSampleGraphQL
}

interface RemoveNobiliaSampleAction {
  type: 'remove_nobilia_sample'
  nobiliaSample: NobiliaSampleGraphQL
}

interface ResetState {
  type: 'reset_state'
  state: OrderReducerState
}

interface SetDescriptionAdditional {
  type: 'set_description_additional'
  descriptionAdditional: string
}

interface SetDeliveredAt {
  type: 'set_delivered_at'
  deliveredAt: string
}

interface SetDesigns {
  type: 'set_designs'
  designs: Design[]
}

interface SetDiscounts {
  type: 'set_discounts'
  discounts: PaymentItem[]
}

interface SetFiles {
  type: 'set_files'
  files: ProjectFile[]
}

interface SetIsAddOn {
  type: 'set_is_add_on'
  isAddOn: boolean
}

interface SetName {
  type: 'set_name'
  name: string
}

interface SetNotes {
  type: 'set_notes'
  notes: string
}

interface SetNobiliaSamples {
  type: 'set_nobilia_samples'
  nobiliaSamples: NobiliaSampleGraphQL[]
}

interface SetPayments {
  type: 'set_payments'
  payments: Payment[]
}

interface SetPlacedAt {
  type: 'set_placed_at'
  placedAt: Date | string | null
}

interface SetPublishedAt {
  type: 'set_published_at'
  publishedAt: Date | string
}

interface SetReferenceAdditional {
  type: 'set_reference_additional'
  referenceAdditional: string
}

interface SetReferenceNumber {
  type: 'set_reference_number'
  referenceNumber: string
}

interface SetShippedAt {
  type: 'set_shipped_at'
  shippedAt: string | null
}

interface SetShippedBy {
  type: 'set_shipped_by'
  shippedBy: string
}

interface SetShippingAddress {
  type: 'set_shipping_address'
  shippingAddress: Address
}

interface SetTargetDate {
  type: 'set_target_date'
  targetDate: Date | string
}

interface SetTaxExempt {
  type: 'set_tax_exempt'
  isTaxExempt: boolean
}

interface SetTaxRate {
  type: 'set_tax_rate'
  taxRate: number
}

interface ToggleRefund {
  type: 'toggle_refund'
}

interface UpdateShippingAddressAction {
  type: 'update_shipping_address'
  address: Address
}

export type OrderReducerAction =
  | AddNobiliaSampleAction
  | RemoveNobiliaSampleAction
  | ResetState
  | SetDeliveredAt
  | SetDescriptionAdditional
  | SetDesigns
  | SetDiscounts
  | SetFiles
  | SetIsAddOn
  | SetName
  | SetNotes
  | SetNobiliaSamples
  | SetPayments
  | SetPlacedAt
  | SetPublishedAt
  | SetReferenceAdditional
  | SetReferenceNumber
  | SetShippedAt
  | SetShippedBy
  | SetShippingAddress
  | SetTargetDate
  | SetTaxExempt
  | SetTaxRate
  | ToggleRefund
  | UpdateShippingAddressAction

const orderReducer = (
  state: OrderReducerState,
  action: OrderReducerAction,
): OrderReducerState => {
  switch (action.type) {
    case 'add_nobilia_sample':
      return {
        ...state,
        order: {
          ...state.order,
          nobiliaSamples: state?.order?.nobiliaSamples?.concat([
            action.nobiliaSample,
          ]),
        },
      }
    case 'remove_nobilia_sample':
      return {
        ...state,
        order: {
          ...state.order,
          nobiliaSamples: state.order.nobiliaSamples?.filter(
            (p) => p !== action.nobiliaSample,
          ),
        },
      }
    case 'reset_state':
      return action.state
    case 'set_description_additional':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            descriptionAdditional: action.descriptionAdditional,
          },
        },
      }
    case 'set_delivered_at':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            deliveredAt: action.deliveredAt,
          },
        },
      }
    case 'set_designs':
      return {
        ...state,
        order: {
          ...state.order,
          designs: sortDesigns(action.designs),
        },
      }
    case 'set_name':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            name: action.name,
          },
        },
      }
    case 'set_discounts':
      return {
        ...state,
        order: {
          ...state.order,
          discounts: action.discounts,
        },
      }
    case 'set_files':
      return {
        ...state,
        order: {
          ...state.order,
          files: action.files,
        },
      }
    case 'set_is_add_on':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            isAddOn: action.isAddOn,
            name: action.isAddOn ? 'Add-On Order' : 'Project Order',
          },
        },
      }
    case 'set_published_at':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            publishedAt: action.publishedAt,
          },
        },
      }
    case 'set_notes':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            notes: action.notes,
          },
        },
      }
    case 'set_payments':
      return {
        ...state,
        order: {
          ...state.order,
          payments: sortPayments(action.payments),
        },
      }
    case 'set_placed_at':
      return {
        ...state,
        order: {
          ...state.order,
          placedAt: action.placedAt,
        },
      }
    case 'set_nobilia_samples':
      return {
        ...state,
        order: {
          ...state.order,
          nobiliaSamples: action.nobiliaSamples,
        },
      }
    case 'set_reference_additional':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            referenceAdditional: action.referenceAdditional,
          },
        },
      }
    case 'set_reference_number':
      return {
        ...state,
        order: {
          ...state.order,
          referenceNumber: action.referenceNumber,
        },
      }
    case 'set_shipped_at':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            shippedAt: action.shippedAt,
          },
        },
      }
    case 'set_shipped_by':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            shippedBy: action.shippedBy,
          },
        },
      }
    case 'set_shipping_address':
      return {
        ...state,
        order: {
          ...state.order,
          shippingAddress: action.shippingAddress,
        },
      }
    case 'set_target_date':
      return {
        ...state,
        order: {
          ...state.order,
          targetDate: action.targetDate,
        },
      }
    case 'set_tax_exempt':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            taxExempt: action.isTaxExempt,
          },
        },
      }
    case 'set_tax_rate':
      return {
        ...state,
        order: {
          ...state.order,
          taxRate: action.taxRate,
        },
      }
    case 'toggle_refund':
      return {
        ...state,
        order: {
          ...state.order,
          metadata: {
            ...state.order.metadata,
            avataxRefundOn: !state.order.metadata?.avataxRefundOn,
          },
        },
      }
    case 'update_shipping_address':
      return {
        ...state,
        order: {
          ...state.order,
          shippingAddress: action.address,
        },
      }
    default:
      return state
  }
}

export default orderReducer
