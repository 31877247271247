// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createContext, Dispatch, useReducer, useState } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import * as BABYLON from 'babylonjs'

import Loader from 'components/shared/loader'
import { NodeRelationshipGraph } from 'planner/compose'
import { ArcRotateCameraBase } from 'planner/objects/cameras/arcrotate'
import { TargetCameraBase } from 'planner/objects/cameras/target'
import { UniversalCameraBase } from 'planner/objects/cameras/universal'
import { EnvironmentLightBase } from 'planner/objects/lights/environment'
import { HemisphericLightBase } from 'planner/objects/lights/hemispheric'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import {
  FindUniqueDesignPayload,
  FindUniqueDesignVariables,
  FIND_UNIQUE_DESIGN_PLANNER,
} from 'queries/design'
import {
  FindManyGenericProductClassPayload,
  FindManyGenericProductClassVariables,
  FIND_MANY_GENERIC_PRODUCT_CLASS,
} from 'queries/generic-product-class'
import {
  FIND_UNIQUE_ROOM_PLANNER,
  FindUniqueRoomPayload,
  FindUniqueRoomVariables,
} from 'queries/room'
import { Design } from 'types/design'
import { GenericProductClass } from 'types/generic-product-class'
import { RoomGraphQL } from 'types/room'

import designPlannerReducer, {
  DesignPlannerReducerState,
  DesignPlannerReducerAction,
} from './design-reducer'
import NobiliaOptionsProvider from './nobilia-options-provider'
import { RenderMode, ViewMode } from './types'
import * as presetUtils from '../../planner/utils/preset-utils'

interface IDesignContext {
  catalog: string
  countertopProductClass: GenericProductClass
  defaultArcRotateCamera: ArcRotateCameraBase
  defaultTargetCamera: TargetCameraBase
  defaultUniversalCamera: UniversalCameraBase
  design: Design
  dispatch: Dispatch<DesignPlannerReducerAction>
  plinthProductClass: GenericProductClass
  presets: presetUtils.Preset[]
  refetch: () => void
  room: RoomGraphQL
  state: Partial<DesignPlannerReducerState>
}

interface DesignProps
  extends RouteComponentProps<{ design_id: string; room_id: string }> {
  children: any
}

export const DesignContext = createContext<IDesignContext>({} as IDesignContext)

const DesignProvider = (props: DesignProps) => {
  const [
    {
      defaultAmbient,
      defaultEnvironment,
      defaultLight,
      defaultArcRotateCamera,
      defaultTargetCamera,
      defaultUniversalCamera,
    },
  ] = useState(() => {
    const uc = new UniversalCameraBase({})
    const arc = new ArcRotateCameraBase({
      setActiveOnSceneIfNoneActive: true,
    })
    const tc = new TargetCameraBase({
      mode: BABYLON.Camera.ORTHOGRAPHIC_CAMERA,
    })
    const al = new HemisphericLightBase({
      color: BABYLON.Color3.White(),
    })
    const dl = new HemisphericLightBase({
      parent: arc,
    })
    const de = new EnvironmentLightBase({})
    return {
      defaultAmbient: al,
      defaultEnvironment: de,
      defaultLight: dl,
      defaultArcRotateCamera: arc,
      defaultTargetCamera: tc,
      defaultUniversalCamera: uc,
    }
  })

  const { loading, data, refetch, error } = useQuery<
    FindUniqueDesignPayload,
    FindUniqueDesignVariables
  >(FIND_UNIQUE_DESIGN_PLANNER, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: props.design_id ?? '',
      },
    },
  })

  const { loading: loadingRoom, data: dataRoom } = useQuery<
    FindUniqueRoomPayload,
    FindUniqueRoomVariables
  >(FIND_UNIQUE_ROOM_PLANNER, {
    variables: { where: { id: props.room_id ?? '' } },
  })

  const { loading: loadingGenericClasses, data: dataGenericClasses } = useQuery<
    FindManyGenericProductClassPayload,
    FindManyGenericProductClassVariables
  >(FIND_MANY_GENERIC_PRODUCT_CLASS, {
    variables: {
      where: {
        identifier: {
          in: ['countertop', 'plinth'],
        },
      },
    },
  })

  const [state, dispatch] = useReducer(designPlannerReducer, {
    activeMenu: 'presets',
    activeCamera: defaultUniversalCamera,
    activeSectionView: null,
    cameraPosition: null,
    cameraTarget: null,
    defaultPosition: BABYLON.Vector3.Zero(),
    defaultPositionWall: BABYLON.Vector3.Zero(),
    defaultRotation: BABYLON.Vector3.Zero(),
    defaultRotationWall: BABYLON.Vector3.Zero(),
    designLoaded: false,
    measurementUnit: MeasurementUnit.MillimetersWithSuffix,
    moveEnabled: false,
    moveFrom: null,
    recenterCameraTriggeredAt: null,
    recenterPlanTriggeredAt: null,
    renderMode: RenderMode.MATERIALS,
    sceneRelationshipGraph: new NodeRelationshipGraph([]),
    sceneState: [
      defaultAmbient,
      defaultEnvironment,
      defaultLight,
      defaultArcRotateCamera,
      defaultTargetCamera,
      defaultUniversalCamera,
    ],
    screenshotTriggeredAt: null,
    sectionViewMode: false,
    selectedNode: null,
    showEstimate: false,
    showProductList: false,
    snapToProducts: true,
    viewMode: ViewMode.PLAN,
  })

  if (
    loading ||
    error ||
    !data ||
    loadingRoom ||
    !dataRoom ||
    loadingGenericClasses ||
    !dataGenericClasses
  )
    return <Loader />

  const design = data.design
  const room = dataRoom.room
  const { countertopProductClass, plinthProductClass } =
    dataGenericClasses.genericProductClasses.reduce<{
      countertopProductClass: GenericProductClass
      plinthProductClass: GenericProductClass
    }>(
      (acc, gpc) => {
        if (gpc.identifier.includes('countertop'))
          acc.countertopProductClass = gpc
        if (gpc.identifier.includes('plinth')) acc.plinthProductClass = gpc
        return acc
      },
      {
        countertopProductClass: {} as GenericProductClass,
        plinthProductClass: {} as GenericProductClass,
      },
    )

  const presets = presetUtils.deserializePresets(design.nobiliaOptions)
  const catalog = design.metadata?.catalog || process.env.GATSBY_DEFAULT_CATALOG

  return (
    <DesignContext.Provider
      value={{
        catalog: catalog ?? '',
        countertopProductClass,
        defaultArcRotateCamera,
        defaultTargetCamera,
        defaultUniversalCamera,
        design,
        dispatch,
        plinthProductClass,
        presets,
        refetch,
        room,
        state,
      }}
    >
      <NobiliaOptionsProvider catalog={catalog ?? ''}>
        {props.children}
      </NobiliaOptionsProvider>
    </DesignContext.Provider>
  )
}

export default DesignProvider
