import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from 'semantic-ui-react'

import { RenderMode } from 'context/planner/types'
import { deriveNodes, NodeRelationshipGraph } from 'planner/compose'
import { CameraBase } from 'planner/objects/camera'
import { NodeBase } from 'planner/objects/node'
import { GenericProductBase } from 'planner/objects/products/generic/base'
import PlannerPlan from 'planner/ui/planner-plan'
import PlannerScene from 'planner/ui/planner-scene'
import {
  FIND_MANY_GENERIC_PRODUCT_CLASS,
  FindManyGenericProductClassPayload,
  FindManyGenericProductClassVariables,
} from 'queries/generic-product-class'
import { StyledButton, StyledPopup } from 'styles/planner/main'
import { Icons } from 'styles/planner/system'
import { GenericProductClass } from 'types/generic-product-class'

const Module = ({
  countertopProductClass,
  plinthProductClass,
  product,
  sceneNodes,
}: {
  countertopProductClass?: GenericProductClass
  plinthProductClass?: GenericProductClass
  product: GenericProductBase
  sceneNodes: NodeBase[]
}) => {
  const [renderMode, setRenderMode] = useState<'MATERIALS' | 'LINES' | 'PLAN'>(
    'MATERIALS',
  )

  const { sceneState, sceneRelationshipGraph } = deriveNodes(
    sceneNodes,
    [product],
    [],
    new NodeRelationshipGraph([]),
    {
      countertopProductClass: countertopProductClass,
      plinthProductClass: plinthProductClass,
    },
  )

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column
          style={{
            background: '#FFFFFF',
            borderRadius: '1px',
            height: '400px',
          }}
          textAlign="center"
        >
          {renderMode === 'MATERIALS' ? (
            <PlannerScene
              activeCamera={sceneNodes[0] as CameraBase}
              renderMode={RenderMode.MATERIALS}
              sceneActive={false}
              sceneCache={[]}
              sceneRelationshipGraph={sceneRelationshipGraph}
              sceneState={sceneState}
            />
          ) : null}
          {renderMode === 'LINES' ? (
            <PlannerScene
              activeCamera={sceneNodes[0] as CameraBase}
              renderMode={RenderMode.LINES}
              sceneActive={false}
              sceneCache={[]}
              sceneRelationshipGraph={sceneRelationshipGraph}
              sceneState={sceneState}
            />
          ) : null}
          {renderMode === 'PLAN' ? (
            <PlannerPlan
              moveEnabled={false}
              sceneRelationshipGraph={sceneRelationshipGraph}
              sceneState={sceneState}
              viewBoxBase={2000}
              viewBoxGridSquareSize={100}
            />
          ) : null}
        </Grid.Column>
        <Grid.Column width={1}>
          <StyledPopup
            content={<p>Texture 3D View</p>}
            on="hover"
            position="right center"
            styled={{ position: 'right' }}
            trigger={
              <span>
                <StyledButton
                  active={renderMode === 'MATERIALS'}
                  icon={
                    <svg width="32" height="32">
                      <image
                        href={
                          renderMode === 'MATERIALS'
                            ? Icons.View3DActive
                            : Icons.View3D
                        }
                        width="32"
                        height="32"
                      />
                    </svg>
                  }
                  onClick={() => setRenderMode('MATERIALS')}
                  style={{ margin: '0 0 14px' }}
                />
              </span>
            }
          />
          <StyledPopup
            content={<p>Outline 3D View</p>}
            on="hover"
            position="right center"
            styled={{ position: 'right' }}
            trigger={
              <span>
                <StyledButton
                  active={renderMode === 'LINES'}
                  icon={
                    <svg width="32" height="32">
                      <image
                        href={
                          renderMode === 'LINES' ? Icons.LineActive : Icons.Line
                        }
                        width="32"
                        height="32"
                      />
                    </svg>
                  }
                  onClick={() => setRenderMode('LINES')}
                  style={{ margin: '0 0 14px' }}
                />
              </span>
            }
          />
          <StyledPopup
            content={<p>Top View</p>}
            on="hover"
            position="right center"
            styled={{ position: 'right' }}
            trigger={
              <span>
                <StyledButton
                  active={renderMode === 'PLAN'}
                  icon={
                    <svg width="32" height="32">
                      <image
                        href={
                          renderMode === 'PLAN'
                            ? Icons.ViewTopActive
                            : Icons.ViewTop
                        }
                        width="32"
                        height="32"
                      />
                    </svg>
                  }
                  onClick={() => setRenderMode('PLAN')}
                  style={{ margin: '0 0 14px' }}
                />
              </span>
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ({
  product,
  sceneNodes,
}: {
  product: GenericProductBase
  sceneNodes: NodeBase[]
}) => {
  const { loading, data } = useQuery<
    FindManyGenericProductClassPayload,
    FindManyGenericProductClassVariables
  >(FIND_MANY_GENERIC_PRODUCT_CLASS, {
    variables: {
      where: {
        identifier: {
          in: ['countertop', 'plinth'],
        },
      },
    },
  })
  if (loading) return null

  const genericClasses = data?.genericProductClasses
  const countertopProductClass = genericClasses?.find(
    (gpc) => gpc.identifier === 'countertop',
  )
  const plinthProductClass = genericClasses?.find(
    (gpc) => gpc.identifier === 'plinth',
  )

  return (
    <Module
      product={product}
      countertopProductClass={countertopProductClass}
      plinthProductClass={plinthProductClass}
      sceneNodes={sceneNodes}
    />
  )
}
