import React from 'react'

import _ from 'lodash'
import { Image, List } from 'semantic-ui-react'

import { parseOwners } from 'context/project/utils'
import { TableUserListItem } from 'styles/admin/main'
import { SupplierOrder } from 'types/supplier-order'

export const SupplierOrdersCellContents = ({
  onSelectSupplierOrder,
  supplierOrders,
}: {
  onSelectSupplierOrder: (id: string) => void
  supplierOrders: SupplierOrder[]
}) => {
  return (
    <List relaxed>
      {_.sortBy(
        supplierOrders,
        (so) => so.nobiliaOrderRef || so.data?.nobiliaOrderReference,
      ).map((so) => {
        const owner = so.project ? parseOwners(so.project)?.primaryOwner : null
        const orderName =
          so.nobiliaOrderRef || so.data?.nobiliaOrderReference || ''
        return (
          <TableUserListItem key={so.id}>
            <Image src={owner?.picture} avatar circular />
            <List.Content className="small">
              <List.Header>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    so.project
                      ? window.open(`/admin/projects/${so.project.id}/overview`)
                      : null
                  }
                >
                  {orderName}
                </a>
              </List.Header>
              <List.Description>
                <span
                  className={
                    so.data?.nobiliaStatus?.startsWith('8') ? 'green' : 'red'
                  }
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => onSelectSupplierOrder(so.id ?? '')}
                >
                  Order:{' '}
                  {so.nobiliaAckNumber || so.data?.nobiliaFactoryAckNumber}
                </span>
              </List.Description>
            </List.Content>
          </TableUserListItem>
        )
      })}
    </List>
  )
}
