import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Validate } from 'react-hook-form'

type ValidationFunction<TFields> = (fields: Partial<TFields>) => boolean

export enum DynamicFilterTypes {
  DROPDOWN = 'DROPDOWN',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  DATE = 'DATE',
}

type GenericFieldValue = { text: string; value: string | null }

interface CommonFilterProps<TFields> {
  filterId: keyof TFields
  disableIf?: ValidationFunction<TFields>
  required?: boolean
  label?: string
}

export interface DynamicDropdownFilter {
  type: DynamicFilterTypes.DROPDOWN
  options: Array<
    GenericFieldValue & {
      key: string
      image?: {
        src: string
        avatar?: boolean
      }
      icon?: IconName
    }
  >
  defaultValue?: IFormDropdownValue
}

export interface DynamicCheckboxFilter {
  type: DynamicFilterTypes.CHECKBOX
  options: GenericFieldValue[]
  defaultValue?: IFormCheckboxValue
}

export interface DynamicRadioFilter {
  type: DynamicFilterTypes.RADIO
  options: GenericFieldValue[]
  defaultValue?: IFormRadioValue
}

export interface DynamicDateFilter<TFields extends IDynamicFields> {
  type: DynamicFilterTypes.DATE
  placeholder?: string
  max?: string | Date
  min?: string | Date
  format?: string
  validation?: Validate<IFormDateValue, TFields>
  defaultValue?: IFormDateValue
}

export type DynamicFilter<TFields extends IDynamicFields> =
  CommonFilterProps<TFields> &
    (
      | DynamicDropdownFilter
      | DynamicCheckboxFilter
      | DynamicRadioFilter
      | DynamicDateFilter<TFields>
    )

export type DynamicFilterGroup<TFields extends IDynamicFields> = {
  groupName?: string
  filters: DynamicFilter<TFields>[]
  withDrawer?: boolean
}

export interface IFormDropdownValue {
  type: DynamicFilterTypes.DROPDOWN
  selection: GenericFieldValue
}

export interface IFormCheckboxValue {
  type: DynamicFilterTypes.CHECKBOX
  selection: GenericFieldValue[]
}

export interface IFormRadioValue {
  type: DynamicFilterTypes.RADIO
  selection: GenericFieldValue
}

export interface IFormDateValue {
  type: DynamicFilterTypes.DATE
  value: string
}

export type IFormFilterValue =
  | IFormDropdownValue
  | IFormCheckboxValue
  | IFormRadioValue
  | IFormDateValue

export type IDynamicFields = Record<string, IFormFilterValue>
