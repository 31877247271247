import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import { orderBy } from 'lodash'
import { Divider, Grid, Label, List, Table } from 'semantic-ui-react'

import OrderCellContentCommission from 'components/admin/order-form/table-cell-content-commission'
import OrderCellContentFulfillment from 'components/admin/order-form/table-cell-content-fulfillment'
import OrderCellContentPlacement from 'components/admin/order-form/table-cell-content-placement'
import OrderCellContentPublished from 'components/admin/order-form/table-cell-content-published'
import { ProjectPageTitle } from 'components/admin/page-title'
import ConfirmationModal from 'components/shared/confirmation-modal'
import * as orderUtils from 'context/order/utils'
import useProjectOrderFORMMany from 'context/project/order-form/use-many'
import useProjectOrderFORMManyMutate from 'context/project/order-form/use-many-mutate'
import useProject from 'context/project/use'
import { getRoomTypeWithDescription } from 'context/room/utils'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { FormCheckbox } from 'styles/admin/main'
import { Order } from 'types/order'

const Orders = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project } = useProject()
  const { orders } = useProjectOrderFORMMany()
  const { deleteOrderAndRefetch } = useProjectOrderFORMManyMutate()

  const [orderToDelete, setOrderToDelete] = useState<Order>()
  const [orderToConfirm, setOrderToConfirm] = useState<Order>()

  return (
    <>
      <ProjectPageTitle viewName="Orders" />
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <h5>Orders</h5>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              kind="solid"
              onClick={() =>
                navigate(`/admin/projects/${project.id}/orders/create`)
              }
              text="Create New Order"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Designs</Table.HeaderCell>
                  <Table.HeaderCell>Publish Status</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell>Payment</Table.HeaderCell>
                  <Table.HeaderCell>GP</Table.HeaderCell>
                  <Table.HeaderCell>Supplier Orders</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {orders.map((o) => {
                  const { commissionApproved, gp, revenueFormatted } =
                    orderUtils.calculateGrossProfit(o)
                  const isAddOn = orderUtils.isAddOnOrder(o)
                  return (
                    <Table.Row key={o.id}>
                      <Table.Cell width={2}>
                        {isAddOn && (
                          <Label color="red" content="Add-On" ribbon />
                        )}
                        <p>{orderUtils.getOrderNameWithDescription(o)}</p>
                      </Table.Cell>
                      <Table.Cell width={3} textAlign="left">
                        <List>
                          {orderBy(o.designs, ['metadata.price'], ['desc']).map(
                            (d) => (
                              <List.Item key={d.id}>
                                <List.Content>
                                  <List.Header>
                                    <Link
                                      to={`/admin/projects/${o.project?.id}/rooms/${d.room.id}/designs/${d.id}`}
                                    >
                                      {getRoomTypeWithDescription(d.room)} -{' '}
                                      {d.name}
                                    </Link>
                                  </List.Header>
                                </List.Content>
                              </List.Item>
                            ),
                          )}
                        </List>
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <OrderCellContentPublished order={o} />
                      </Table.Cell>
                      <Table.Cell width={2}>{revenueFormatted}</Table.Cell>
                      <Table.Cell width={2}>
                        <OrderCellContentPlacement order={o} />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <OrderCellContentCommission order={o} />
                        <div style={{ textAlign: 'center' }}>
                          {!commissionApproved && gp ? (
                            <Button
                              color="blue"
                              fontAwesomeIcon="money-check"
                              kind="solid"
                              onClick={() => setOrderToConfirm(o)}
                            />
                          ) : null}
                        </div>
                      </Table.Cell>
                      <Table.Cell width={3}>
                        <OrderCellContentFulfillment order={o} />
                      </Table.Cell>
                      <Table.Cell width={1} textAlign="center">
                        <Button
                          fontAwesomeIcon="edit"
                          kind="solid"
                          onClick={() =>
                            navigate(
                              `/admin/projects/${project.id}/orders/${o.id}`,
                            )
                          }
                        />
                        <Button
                          color="blue"
                          fontAwesomeIcon="trash-alt"
                          kind="solid"
                          onClick={() => setOrderToDelete(o)}
                          style={{ marginTop: '5px', marginBottom: '5px' }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmCommissionModal
        onClose={() => setOrderToConfirm(undefined)}
        order={orderToConfirm}
      />
      <ConfirmationModal
        firstTitle="Are you sure you want to delete the order?"
        onCancel={() => setOrderToDelete(undefined)}
        onConfirm={async () => {
          await deleteOrderAndRefetch({
            variables: {
              where: {
                id: orderToDelete?.id ?? '',
              },
            },
          })
          setOrderToDelete(undefined)
        }}
        open={!!orderToDelete}
      />
    </>
  )
}

const ConfirmCommissionModal = ({
  order,
  onClose,
}: {
  order?: Order
  onClose: () => void
}) => {
  const [confirmComission, setConfirmComission] = useState<boolean>(false)
  const { updateOrderAndRefetch } = useProjectOrderFORMManyMutate()
  if (!order) return null
  const { revenueFormatted, cogsFormatted, gpFormatted, gp } =
    orderUtils.calculateGrossProfit(order)
  return (
    <Modal
      onClose={onClose}
      open={!!order}
      size="medium"
      title="Confirm Commission"
      saveButton={{
        disabled: !confirmComission,
        text: 'Submit Commission',
        onClick: async () => {
          await updateOrderAndRefetch({
            variables: {
              data: {
                metadata: {
                  ...order.metadata,
                  commissionApprovedAt: new Date(),
                  commissionGPApproved: gp,
                },
              },
              where: {
                id: order.id ?? '',
              },
            },
          })
          onClose()
        },
      }}
    >
      <p className="no-margin">Sales Price: {revenueFormatted}</p>
      <p className="no-margin">Cost of ACKs: {cogsFormatted}</p>
      <p className="no-margin">---------------------------------</p>
      <p>Gross Profit: {gpFormatted}</p>
      <br />
      <FormCheckbox
        checked={confirmComission}
        label={
          'I certify to the best of my knowledge that (1) the numbers shown above are correct and (2) there are no outstanding ACKs'
        }
        onChange={() => {
          setConfirmComission(!confirmComission)
        }}
      />
    </Modal>
  )
}

export default Orders
