import React from 'react'

import { Icon, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

const MarkdownTooltip = () => {
  return (
    <Popup
      header="Markdown syntax"
      content={
        <Markdown>
          <li>
            <p>
              To create a line break put the backslash character (\) on empty
              lines
            </p>
            <p>
              This is the first line.
              <br />
              <code>\</code>
              <br />
              And this is the second line.
            </p>
          </li>
          <li>
            <p>
              To create a heading, add number signs (#) in front of a phrase.
            </p>
            <code>### Example Heading level 3</code>
          </li>
          <li>
            <p>
              To <b>bold</b> text, add <code>**two asterisks**</code> or{' '}
              <code>__underscores__</code>
            </p>
          </li>
          <li>
            <p>
              To <i>italicize</i> text, add <code>*one asterisk*</code> or{' '}
              <code>_underscores_</code>
            </p>
          </li>
          <li>
            <p>
              To create a link, enclose the link text in brackets and then
              follow it with the URL in parentheses:
            </p>
            <p>
              <code>Go to [formkitchens](https://formkitchens.com)</code>
            </p>
          </li>
          <li>
            <p>
              To create a list, add dashes (-), asterisks (*), or plus signs (+)
              in front of line items:
            </p>
            <code>- First item</code>
            <br />
            <code>* Second item</code>
            <br />
            <code>+ Third item</code>
          </li>
        </Markdown>
      }
      on="click"
      position="right center"
      pinned
      trigger={
        <Icon color="blue" name="help circle" style={{ cursor: 'pointer' }} />
      }
    />
  )
}

export default MarkdownTooltip

const Markdown = styled.ul`
  padding-left: 16px;
  min-width: 430px;

  li {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 8px;
  }

  code {
    background: #f6f5f4;
  }
`
