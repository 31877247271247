import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { getQueryVariables } from 'context/design/utils-many'
import {
  FIND_MANY_DESIGN_COUNT,
  FindManyDesignCountPayload,
  FindManyDesignVariables,
} from 'queries/design'
import { ProjectStaffRole } from 'types/project-staff'

const useAwaitingSignoffCount = (
  userId?: string,
  projectStaffRole?: ProjectStaffRole,
) => {
  const variables = useMemo(
    () =>
      getQueryVariables({
        reviewStatus: 'awaitingSignoff',
        assignedTo: { id: userId ?? '' },
        assignedToRole: projectStaffRole,
        take: undefined,
      }),
    [userId, projectStaffRole],
  )
  const {
    data: { count: designsAwaitingSignoffCount } = { count: 0 },
    loading,
  } = useQuery<FindManyDesignCountPayload, FindManyDesignVariables>(
    FIND_MANY_DESIGN_COUNT,
    {
      variables,
    },
  )

  return {
    loading,
    designsAwaitingSignoffCount,
  }
}

export default useAwaitingSignoffCount
