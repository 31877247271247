import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ApolloQueryResult, useQuery } from '@apollo/client'

import {
  FIND_MANY_PROJECT_CHECKLIST_ITEM,
  FindManyProjectChecklistItemPayload,
  FindManyProjectChecklistItemVariables,
} from 'queries/project-checklist-items'

import useProject from '../use'

type ProjectChecklistContext = {
  activeDrawers: ChecklistTab[]
  checklistItems: { id: string; identifier: string; projectId: string }[]
  loading: boolean
  refetch: (
    vars?: Partial<FindManyProjectChecklistItemVariables>,
  ) => Promise<ApolloQueryResult<FindManyProjectChecklistItemPayload>>
  initialTab: ChecklistTab
  setActiveDrawers: React.Dispatch<React.SetStateAction<ChecklistTab[]>>
  setScrollPosition: React.Dispatch<React.SetStateAction<number>>
  scrollPosition: number
}

const ProjectChecklistContext = createContext<ProjectChecklistContext>(
  {} as ProjectChecklistContext,
)

type ProviderProps = {
  children: React.ReactNode
}

export type ChecklistTab = 'design' | 'refine' | 'delivery'

const ProjectChecklistProvider = ({ children }: ProviderProps) => {
  const { project } = useProject()
  const initialTabRef = useRef('')
  const [activeDrawers, setActiveDrawers] = useState<ChecklistTab[]>([])
  const [scrollPosition, setScrollPosition] = useState(0)
  const { data, loading, refetch } = useQuery<
    FindManyProjectChecklistItemPayload,
    FindManyProjectChecklistItemVariables
  >(FIND_MANY_PROJECT_CHECKLIST_ITEM, {
    variables: {
      where: {
        project: {
          id: { equals: project.id },
        },
      },
    },
  })

  const initialTab = useMemo<ChecklistTab>(() => {
    if (initialTabRef.current) return initialTabRef.current as ChecklistTab

    if (Array.isArray(data?.checklistItems) && !loading) {
      const identifiers = data?.checklistItems
        .map((c) => c.identifier)
        .join(',')
      let tab: ChecklistTab = 'design'

      if (identifiers?.includes('design.')) tab = 'design'
      if (identifiers?.includes('refine.')) tab = 'refine'
      if (identifiers?.includes('delivery.')) tab = 'delivery'

      initialTabRef.current = tab

      return tab
    }

    return 'design'
  }, [data?.checklistItems, loading])

  return (
    <ProjectChecklistContext.Provider
      value={{
        activeDrawers,
        checklistItems: data?.checklistItems || [],
        initialTab,
        loading,
        refetch,
        scrollPosition,
        setActiveDrawers,
        setScrollPosition,
      }}
    >
      {children}
    </ProjectChecklistContext.Provider>
  )
}

export default ProjectChecklistProvider

export const useProjectChecklist = () => useContext(ProjectChecklistContext)
