import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Designs from 'components/admin/design/index-many'
import DesignsApprovedTable from 'components/admin/design/table-approved'
import { FiltersVersion } from 'components/admin/design/use-filters-search'
import useTechDesigner from 'context/dashboard/tech-designer/use'
import DesignManyProvider from 'context/design/provider-many'

import TechDesignerLayout from './layout'

const TechDesignerDesignsApprovedUnsent = (_: RouteComponentProps) => {
  const { userId, userName } = useTechDesigner()

  return (
    <TechDesignerLayout>
      <DesignManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          productionStatus: 'approvedUnsent',
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          take: 40,
        }}
        skipLoader
      >
        <Designs
          filtersType={FiltersVersion.APPROVED}
          table={<DesignsApprovedTable />}
        />
      </DesignManyProvider>
    </TechDesignerLayout>
  )
}

export default TechDesignerDesignsApprovedUnsent
