import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import { useProjectMessageMany } from 'context/project/message/provider-many'
import { useCommentNoteMany } from 'context/project/project-notes/provider-many'
import useProject from 'context/project/use'
import { formatCount } from 'context/project/utils'
import { useProjectEventsMany } from 'context/project-event/provider-many'
import { getNameForProspect } from 'context/prospect/utils'
import { getRoomTypeWithDescription } from 'context/room/utils'
import { Colors, ScrollBarStyle } from 'styles/app/system'

const ProjectMenu = () => {
  const { pathname } = useLocation()
  const { project, prospects, roomsUnarchived, isTradeProject } = useProject()
  const { newForMeCount: newForMeCountMessages } = useProjectMessageMany()
  const {
    newForMeCount: newForMeCountEvents,
    queryFilters: queryFiltersProjectEvents,
  } = useProjectEventsMany()
  const { newForMeCount: newForMeCountNotes, queryFilters: queryFiltersNotes } =
    useCommentNoteMany()

  return (
    <StyledMenu vertical>
      <Menu.Item
        className="main"
        name="overview"
        active={pathname.startsWith(`/admin/projects/${project.id}/overview`)}
        onClick={() => navigate(`/admin/projects/${project.id}/overview`)}
      >
        <FontAwesomeIcon icon={['fal', 'columns']} fixedWidth />
        Overview
      </Menu.Item>
      <Menu.Item>
        <p className="caption">
          <FontAwesomeIcon icon={['fal', 'poll-h']} fixedWidth />
          Activity
        </p>
        <Menu.Menu>
          <Menu.Item
            name="messages"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/messages`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/messages`)}
          >
            Messages
            {newForMeCountMessages ? (
              <Label>{newForMeCountMessages}</Label>
            ) : null}
          </Menu.Item>
          <Menu.Item
            name="activity"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/activity`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/activity`)}
          >
            Activity
            {newForMeCountEvents ? (
              <Label>
                {formatCount(
                  queryFiltersProjectEvents.take,
                  newForMeCountEvents,
                )}
              </Label>
            ) : null}
          </Menu.Item>
          <Menu.Item
            name="tasks"
            active={pathname.startsWith(`/admin/projects/${project.id}/tasks`)}
            onClick={() => navigate(`/admin/projects/${project.id}/tasks`)}
          >
            Tasks
          </Menu.Item>
          <Menu.Item
            name="meetings"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/meetings`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/meetings`)}
          >
            Meetings
          </Menu.Item>
          <Menu.Item
            name="notes"
            active={pathname.startsWith(`/admin/projects/${project.id}/notes`)}
            onClick={() => navigate(`/admin/projects/${project.id}/notes`)}
          >
            Notes
            {newForMeCountNotes ? (
              <Label>
                {formatCount(queryFiltersNotes?.take ?? 0, newForMeCountNotes)}
              </Label>
            ) : null}
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <p className="caption">
          <FontAwesomeIcon icon={['fal', 'house']} fixedWidth />
          Rooms
        </p>
        <Menu.Menu>
          {roomsUnarchived.map((r) => (
            <Menu.Item
              key={r.id}
              name={`rooms/${r.id}`}
              active={pathname.startsWith(
                `/admin/projects/${project.id}/rooms/${r.id}`,
              )}
              onClick={() =>
                navigate(`/admin/projects/${project.id}/rooms/${r.id}/photos`)
              }
            >
              {getRoomTypeWithDescription(r)}
            </Menu.Item>
          ))}
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item
        active={pathname.startsWith(`/admin/projects/${project.id}/guides`)}
        onClick={() => navigate(`/admin/projects/${project.id}/guides`)}
      >
        <p className="caption">
          <FontAwesomeIcon icon={['fal', 'poll-h']} fixedWidth />
          Interactive Guides
        </p>
      </Menu.Item>
      {isTradeProject ? (
        <Menu.Item>
          <p className="caption">
            <FontAwesomeIcon icon={['fal', 'file-invoice']} fixedWidth />
            Trade Proposals
          </p>
          <Menu.Menu>
            <Menu.Item
              name="initial-costing"
              active={pathname.startsWith(
                `/admin/projects/${project.id}/initial-costing`,
              )}
              onClick={() =>
                navigate(`/admin/projects/${project.id}/initial-costing`)
              }
            >
              Initial Costing
            </Menu.Item>
            <Menu.Item
              name="project-proposal"
              active={pathname.startsWith(
                `/admin/projects/${project.id}/project-proposal`,
              )}
              onClick={() =>
                navigate(`/admin/projects/${project.id}/project-proposal`)
              }
            >
              Project Proposal
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      ) : null}
      <Menu.Item>
        <p className="caption">
          <FontAwesomeIcon icon={['fal', 'money-check-alt']} fixedWidth />
          Payments
        </p>
        <Menu.Menu>
          <Menu.Item
            name="payments"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/payments`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/payments`)}
          >
            Payments
          </Menu.Item>
          <Menu.Item
            name="payouts"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/payouts`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/payouts`)}
          >
            Payouts
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <p className="caption">
          <FontAwesomeIcon icon={['fal', 'file-invoice']} fixedWidth />
          Orders
        </p>
        <Menu.Menu>
          <Menu.Item
            name="sample-orders"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/sample-orders`,
            )}
            onClick={() =>
              navigate(`/admin/projects/${project.id}/sample-orders`)
            }
          >
            Sample Orders
          </Menu.Item>
          <Menu.Item
            name="supplier-orders"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/supplier-orders`,
            )}
            onClick={() =>
              navigate(`/admin/projects/${project.id}/supplier-orders`)
            }
          >
            Supplier Orders
          </Menu.Item>
          <Menu.Item
            name="orders"
            active={pathname.startsWith(`/admin/projects/${project.id}/orders`)}
            onClick={() => navigate(`/admin/projects/${project.id}/orders`)}
          >
            FORM Orders
          </Menu.Item>
          <Menu.Item
            name="deliveries"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/deliveries`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/deliveries`)}
          >
            Deliveries
          </Menu.Item>
          <Menu.Item
            name="issues"
            active={pathname.startsWith(`/admin/projects/${project.id}/issues`)}
            onClick={() => navigate(`/admin/projects/${project.id}/issues`)}
          >
            Issues
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <p className="caption">
          <FontAwesomeIcon icon={['fal', 'cog']} fixedWidth />
          Other
        </p>
        <Menu.Menu>
          <Menu.Item
            name="addresses"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/addresses`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/addresses`)}
          >
            Addresses
          </Menu.Item>
          <Menu.Item
            name="custom-images"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/custom-images`,
            )}
            onClick={() =>
              navigate(`/admin/projects/${project.id}/custom-images`)
            }
          >
            Custom Images
          </Menu.Item>
          <Menu.Item
            name="buildzoom"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/buildzoom`,
            )}
            onClick={() => navigate(`/admin/projects/${project.id}/buildzoom`)}
          >
            BuildZoom
          </Menu.Item>
          <Menu.Item
            name="installation-photos"
            active={pathname.startsWith(
              `/admin/projects/${project.id}/installation-photos`,
            )}
            onClick={() =>
              navigate(`/admin/projects/${project.id}/installation-photos`)
            }
          >
            Installation Photos
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <p className="caption">
          <FontAwesomeIcon icon={['fal', 'user']} fixedWidth />
          Prospects
        </p>
        <Menu.Menu>
          {prospects.map((p) => (
            <Menu.Item
              key={p?.id}
              name={p?.id}
              active={false}
              onClick={() => window.open(`/admin/prospects/${p?.id}`)}
            >
              {getNameForProspect(p)}
            </Menu.Item>
          ))}
        </Menu.Menu>
      </Menu.Item>
    </StyledMenu>
  )
}

export default ProjectMenu

const StyledMenu = styled(Menu)`
  &&&& {
    ${ScrollBarStyle}

    border-radius: 0;
    border: none;
    box-shadow: none;
    height: 100%;
    max-height: fit-content;
    overflow: hidden;
    overflow-y: auto !important;
    width: 100%;

    .main {
      background: ${Colors.gray150} !important;
      border-radius: 0 !important;
      border-bottom: 1px solid ${Colors.gray400};

      :hover {
        background: ${Colors.gray200} !important;
      }
    }

    .item {
      padding: 14px;

      p {
        color: ${Colors.black};
        font-size: 12px;
        margin: 0;
      }

      svg {
        margin-right: 10px;
      }

      :before {
        content: none;
      }
    }

    .menu {
      margin-top: 10px !important;

      .item {
        border-left: 1px solid ${Colors.gray300};
        color: ${Colors.gray600};
        font-size: 14px;
        margin-left: 22px;
        margin-right: 10px;
        padding: 10px 16px;

        .label {
          align-items: center;
          background: ${Colors.orange500} !important;
          border-radius: 50%;
          color: white !important;
          display: flex;
          font-size: 10px;
          height: 20px;
          justify-content: center;
          width: 20px;
        }

        &.active {
          background: ${Colors.gray100} !important;
          border-left: 2px solid ${Colors.green800};
          color: ${Colors.black};
        }

        :hover {
          background: ${Colors.gray100} !important;
        }
      }
    }
  }
`
