import React from 'react'

import moment from 'moment'

import { Badge } from 'components/shared/chip'
import { isMeetingCanceled } from 'context/meeting/utils'
import { prospectMeetingType } from 'context/prospect/utils'
import { StyledRating } from 'styles/admin/main'
import { Meeting } from 'types/meeting'

import { meetingOutcomeToStarCount } from './utils'

export const MeetingCellContents = ({ meeting }: { meeting: Meeting }) => {
  if (!meeting) return null
  return (
    <div>
      <p className="no-margin">{prospectMeetingType(meeting)}</p>
      <p className="light-gray x-small">
        {moment(meeting.startTime).format('MM/DD/YYYY hh:mm a')}
      </p>
      {meeting.metadata.outcome && (
        <>
          <p className="no-margin x-small">{meeting.metadata.outcome}</p>
          <StyledRating
            className="small"
            disabled
            maxRating={meetingOutcomeToStarCount(meeting.metadata.outcome)}
            style={{ marginBottom: '20px' }}
          />
        </>
      )}
      {isMeetingCanceled(meeting) && <Badge color="#FFD7D7" name="Canceled" />}
    </div>
  )
}
