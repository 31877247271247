import * as NO from 'planner/objects/products/nobilia/options/definitions'
import { NobiliaFeature } from 'types/nobilia-feature'

export type ParsedNobiliaFeatures = Partial<{
  frontFeature: NobiliaFeature
  handleFeature: NobiliaFeature
  glassColorFeature: NobiliaFeature
  glassDesignFeature: NobiliaFeature
  glassDoorFrameColorFeature: NobiliaFeature
  glassDoorFrameDesignFeature: NobiliaFeature
  carcaseColorExteriorFeature: NobiliaFeature
  carcaseColorInteriorFeature: NobiliaFeature
  plinthColorFeature: NobiliaFeature
  ceilingPanelColorFeature: NobiliaFeature
  uprightBarColorFeature: NobiliaFeature
  wallShelfColorFeature: NobiliaFeature
}>
export const parseNobiliaFeatures = (
  nobiliaFeatures: NobiliaFeature[],
): ParsedNobiliaFeatures =>
  nobiliaFeatures.reduce<ParsedNobiliaFeatures>((acc, f) => {
    if (f.featureNo === NO.FRONT_COMBO)
      return {
        ...acc,
        frontFeature: f,
      }
    if (f.featureNo === NO.HANDLE_COMBINATION)
      return {
        ...acc,
        handleFeature: f,
      }
    if (f.featureNo === NO.GLASS_COLOR)
      return {
        ...acc,
        glassColorFeature: f,
      }
    if (f.featureNo === NO.GLASS_DESIGN)
      return {
        ...acc,
        glassDesignFeature: f,
      }
    if (f.featureNo === NO.GLASS_DOOR_FRAME_COLOR)
      return {
        ...acc,
        glassDoorFrameColorFeature: f,
      }
    if (f.featureNo === NO.GLASS_DOOR_FRAME_DESIGN)
      return {
        ...acc,
        glassDoorFrameDesignFeature: f,
      }
    if (f.featureNo === NO.CARCASE_COLOR_EXTERIOR)
      return {
        ...acc,
        carcaseColorExteriorFeature: f,
      }
    if (f.featureNo === NO.CARCASE_COLOR_INTERIOR)
      return {
        ...acc,
        carcaseColorInteriorFeature: f,
      }
    if (f.featureNo === NO.PLINTH_COLOR)
      return {
        ...acc,
        plinthColorFeature: f,
      }
    if (f.featureNo === NO.CEILING_PANEL_COLOR)
      return {
        ...acc,
        ceilingPanelColorFeature: f,
      }
    if (f.featureNo === NO.UPRIGHT_BAR_COLOR)
      return {
        ...acc,
        uprightBarColorFeature: f,
      }
    if (f.featureNo === NO.WALL_SHELF_COLOR)
      return {
        ...acc,
        wallShelfColorFeature: f,
      }
    return acc
  }, {})
