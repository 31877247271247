import React, { useCallback, useEffect, useState } from 'react'

import { debounce } from 'lodash'
import { Form } from 'semantic-ui-react'

import useProject from 'context/project/use'
import useProjectProspectMutate from 'context/project/use-project-prospect-mutate'
import useProspectUnique from 'context/prospect/use-unique'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { ProjectProspect } from 'types/project-prospect'
import { Prospect } from 'types/prospect'

interface ProjectProspectModalProps {
  open: boolean
  onClose: () => void
  projectProspect: ProjectProspect
}

const ProjectProspectCreateUpdateModal = ({
  open,
  onClose,
  projectProspect,
}: ProjectProspectModalProps) => {
  const { project, prospects } = useProject()
  const {
    createProjectProspectAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateProjectProspectAndRefetch,
  } = useProjectProspectMutate()
  const [search, setSearch] = useState('')
  const { prospectOptions } = useProspectUnique(search, prospects as Prospect[])
  const [state, setState] = useState<ProjectProspect>(
    projectProspect || {
      isPrimary: false,
      prospectId: '',
      projectId: project.id,
    },
  )

  useEffect(() => {
    setState(
      projectProspect || {
        isPrimary: false,
        prospectId: '',
        projectId: project.id,
      },
    )
  }, [projectProspect])

  const onSearchChange = (_: unknown, data: { searchQuery: string }) =>
    setSearch(data.searchQuery)
  const debouncedOnSearchChange = useCallback(debounce(onSearchChange, 300), [])

  const onSave = async () => {
    if (projectProspect) {
      await updateProjectProspectAndRefetch({
        variables: {
          data: {
            prospect: {
              connect: {
                id: state.prospectId,
              },
            },
            isPrimary: state.isPrimary,
          },
          where: {
            projectId_prospectId: {
              projectId: project.id,
              prospectId: state.prospectId,
            },
          },
        },
      })
    } else {
      await createProjectProspectAndRefetch({
        variables: {
          data: {
            project: {
              connect: {
                id: project.id,
              },
            },
            prospect: {
              connect: {
                id: state.prospectId,
              },
            },
            isPrimary: state.isPrimary,
          },
        },
      })
    }
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="small"
      title={projectProspect ? 'Edit Prospect Relationship' : 'Attach Prospect'}
      contentStyle={{ overflow: 'initial' }}
      saveButton={{
        disabled: !state.prospectId,
        loading: loadingCreate || loadingUpdate,
        onClick: onSave,
      }}
    >
      <StyledForm>
        <Form.Dropdown
          fluid
          label="Prospect (please enter full email)"
          options={prospectOptions}
          onChange={(_, { value }) =>
            setState({
              ...state,
              prospectId: value as string,
            })
          }
          onSearchChange={debouncedOnSearchChange}
          placeholder="Enter full prospect email here"
          search
          selection
          value={state.prospectId}
        />
      </StyledForm>
    </Modal>
  )
}

export default ProjectProspectCreateUpdateModal
