/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'

import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'
import { CatalogDiff } from 'types/catalog-diff'
import { CatalogFeatureDiff } from 'types/catalog-feature-diff'
import { GenericOptionDiff } from 'types/generic-option-diff'
import { GenericProductClassDiff } from 'types/generic-product-class-diff'
import { MediaSnapshot } from 'types/media-diff'
import { NobiliaOptionDiff } from 'types/nobilia-option-diff'
import { NobiliaProductClassDiff } from 'types/nobilia-product-class-diff'
import { RoomElementClassDiff } from 'types/room-element-class-diff'
import { RoomElementOptionDiff } from 'types/room-element-option-diff'

export const StyledLabelDiffs = ({
  updated,
  added,
  deleted,
  type,
}: {
  updated?: number
  added?: number
  deleted?: number
  type?: string
}) => {
  return (
    <div className="tags">
      {((updated && updated > 0) || type === 'update') && (
        <StyledTag color={'#FFF0D9'} textColor={'#6C4000'}>
          <p>{updated} Updated</p>
        </StyledTag>
      )}
      {((added && added > 0) || type === 'add') && (
        <StyledTag color={'#E5EDD3'} textColor={'#3D5903'}>
          <p>{added} Added</p>
        </StyledTag>
      )}
      {((deleted && deleted > 0) || type === 'delete') && (
        <StyledTag color={'#FFD7D7'} textColor={'#830000'}>
          <p>{deleted} Deleted</p>
        </StyledTag>
      )}
    </div>
  )
}

export const getReferenceImage = (media: MediaSnapshot[]) => {
  const ref = media.find((m) => m.category === 'reference')
  const display = ref?.key || 'images/app/catalog_missing_file.png'
  return `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(display)}`
}

export const parseDiffs = (catalogDiffs: CatalogDiff) => {
  const {
    catalogFeaturesAdds,
    catalogFeaturesUpdates,
    catalogFeaturesDeletes,
  } = catalogDiffs?.catalogFeatures?.reduce(
    (acc, cf) => {
      if (cf.type === 'add')
        return {
          ...acc,
          catalogFeaturesAdds: [...acc.catalogFeaturesAdds, cf],
        }
      if (cf.type === 'update')
        return {
          ...acc,
          catalogFeaturesUpdates: [...acc.catalogFeaturesUpdates, cf],
        }
      if (cf.type === 'delete')
        return {
          ...acc,
          catalogFeaturesDeletes: [...acc.catalogFeaturesDeletes, cf],
        }
      return acc
    },
    {
      catalogFeaturesAdds: [] as CatalogFeatureDiff[],
      catalogFeaturesDeletes: [] as CatalogFeatureDiff[],
      catalogFeaturesUpdates: [] as CatalogFeatureDiff[],
    },
  )

  const { genericOptionsAdds, genericOptionsUpdates, genericOptionsDeletes } =
    catalogDiffs?.genericOptions?.reduce(
      (acc, cd) => {
        if (cd.type === 'add')
          return {
            ...acc,
            genericOptionsAdds: [...acc.genericOptionsAdds, cd],
          }
        if (cd.type === 'update')
          return {
            ...acc,
            genericOptionsUpdates: [...acc.genericOptionsUpdates, cd],
          }
        if (cd.type === 'delete')
          return {
            ...acc,
            genericOptionsDeletes: [...acc.genericOptionsDeletes, cd],
          }
        return acc
      },
      {
        genericOptionsAdds: [] as GenericOptionDiff[],
        genericOptionsDeletes: [] as GenericOptionDiff[],
        genericOptionsUpdates: [] as GenericOptionDiff[],
      },
    )

  const {
    genericProductClassesAdds,
    genericProductClassesUpdates,
    genericProductClassesDeletes,
  } = catalogDiffs?.genericProductClasses?.reduce(
    (acc, cd) => {
      if (cd.type === 'add')
        return {
          ...acc,
          genericProductClassesAdds: [...acc.genericProductClassesAdds, cd],
        }
      if (cd.type === 'update')
        return {
          ...acc,
          genericProductClassesUpdates: [
            ...acc.genericProductClassesUpdates,
            cd,
          ],
        }
      if (cd.type === 'delete')
        return {
          ...acc,
          genericProductClassesDeletes: [
            ...acc.genericProductClassesDeletes,
            cd,
          ],
        }
      return acc
    },
    {
      genericProductClassesAdds: [] as GenericProductClassDiff[],
      genericProductClassesDeletes: [] as GenericProductClassDiff[],
      genericProductClassesUpdates: [] as GenericProductClassDiff[],
    },
  )

  const { nobiliaOptionsAdds, nobiliaOptionsUpdates, nobiliaOptionsDeletes } =
    catalogDiffs?.nobiliaOptions?.reduce(
      (acc, cd) => {
        if (cd.type === 'add')
          return {
            ...acc,
            nobiliaOptionsAdds: [...acc.nobiliaOptionsAdds, cd],
          }
        if (cd.type === 'update')
          return {
            ...acc,
            nobiliaOptionsUpdates: [...acc.nobiliaOptionsUpdates, cd],
          }
        if (cd.type === 'delete')
          return {
            ...acc,
            nobiliaOptionsDeletes: [...acc.nobiliaOptionsDeletes, cd],
          }
        return acc
      },
      {
        nobiliaOptionsAdds: [] as NobiliaOptionDiff[],
        nobiliaOptionsDeletes: [] as NobiliaOptionDiff[],
        nobiliaOptionsUpdates: [] as NobiliaOptionDiff[],
      },
    )

  const {
    nobiliaProductClassesAdds,
    nobiliaProductClassesUpdates,
    nobiliaProductClassesDeletes,
  } = catalogDiffs?.nobiliaProductClasses?.reduce(
    (acc, cd) => {
      if (cd.type === 'add')
        return {
          ...acc,
          nobiliaProductClassesAdds: [...acc.nobiliaProductClassesAdds, cd],
        }
      if (cd.type === 'update')
        return {
          ...acc,
          nobiliaProductClassesUpdates: [
            ...acc.nobiliaProductClassesUpdates,
            cd,
          ],
        }
      if (cd.type === 'delete')
        return {
          ...acc,
          nobiliaProductClassesDeletes: [
            ...acc.nobiliaProductClassesDeletes,
            cd,
          ],
        }
      return acc
    },
    {
      nobiliaProductClassesAdds: [] as NobiliaProductClassDiff[],
      nobiliaProductClassesDeletes: [] as NobiliaProductClassDiff[],
      nobiliaProductClassesUpdates: [] as NobiliaProductClassDiff[],
    },
  )

  const {
    roomElementClassesAdds,
    roomElementClassesUpdates,
    roomElementClassesDeletes,
  } = catalogDiffs?.roomElementClasses?.reduce(
    (acc, cd) => {
      if (cd.type === 'add')
        return {
          ...acc,
          roomElementClassesAdds: [...acc.roomElementClassesAdds, cd],
        }
      if (cd.type === 'update')
        return {
          ...acc,
          roomElementClassesUpdates: [...acc.roomElementClassesUpdates, cd],
        }
      if (cd.type === 'delete')
        return {
          ...acc,
          roomElementClassesDeletes: [...acc.roomElementClassesDeletes, cd],
        }
      return acc
    },
    {
      roomElementClassesAdds: [] as RoomElementClassDiff[],
      roomElementClassesDeletes: [] as RoomElementClassDiff[],
      roomElementClassesUpdates: [] as RoomElementClassDiff[],
    },
  )

  const {
    roomElementOptionsAdds,
    roomElementOptionsDeletes,
    roomElementOptionsUpdates,
  } = catalogDiffs?.roomElementOptions?.reduce(
    (acc, cd) => {
      if (cd.type === 'add')
        return {
          ...acc,
          roomElementOptionsAdds: [...acc.roomElementOptionsAdds, cd],
        }
      if (cd.type === 'update')
        return {
          ...acc,
          roomElementOptionsUpdates: [...acc.roomElementOptionsUpdates, cd],
        }
      if (cd.type === 'delete')
        return {
          ...acc,
          roomElementOptionsDeletes: [...acc.roomElementOptionsDeletes, cd],
        }
      return acc
    },
    {
      roomElementOptionsAdds: [] as RoomElementOptionDiff[],
      roomElementOptionsDeletes: [] as RoomElementOptionDiff[],
      roomElementOptionsUpdates: [] as RoomElementOptionDiff[],
    },
  )

  return {
    catalogFeaturesAdds,
    catalogFeaturesDeletes,
    catalogFeaturesUpdates,
    genericOptionsAdds,
    genericOptionsDeletes,
    genericOptionsUpdates,
    genericProductClassesAdds,
    genericProductClassesDeletes,
    genericProductClassesUpdates,
    nobiliaOptionsAdds,
    nobiliaOptionsDeletes,
    nobiliaOptionsUpdates,
    nobiliaProductClassesAdds,
    nobiliaProductClassesDeletes,
    nobiliaProductClassesUpdates,
    roomElementClassesAdds,
    roomElementClassesDeletes,
    roomElementClassesUpdates,
    roomElementOptionsAdds,
    roomElementOptionsDeletes,
    roomElementOptionsUpdates,
  }
}

export const ImageCard = styled.div<{ image: string }>`
  background-size: contain;
  background: url('${(props) => props.image}') no-repeat center;
  border-radius: 1px;
  height: 100px;
  margin-right: 16px;
  width: 100px;
  -moz-background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;
`

export const ReferenceDiv = styled.div`
  align-items: center;
  display: flex;
  padding-left: 20px;

  p {
    margin: 0;
  }

  .tags {
    margin-left: auto;
    padding-right: 60px;
  }
`

export const SubGrid = styled(Grid)`
  margin: 20px 70px !important;
`

export const StrikeThrough = styled.p`
  color: ${Colors.gray500} !important;
  text-decoration-line: line-through;
  margin: 0;
`

const StyledTag = styled.div<{
  color: string
  textColor: string
}>`
  background: ${(props) => props.color};
  border-radius: 24px;
  display: flex;
  padding: 6px 16px;
  width: fit-content;

  p {
    color: ${(props) => props.textColor} !important;
    line-height: 100%;
  }
`
