import { gql } from '@apollo/client'

import { DesignAdvisoryCode } from 'types/design-advisory-code'

export const CREATE_ONE_DESIGN_ADVISORY_CODE = gql`
  mutation createOneDesignAdvisoryCode($data: DesignAdvisoryCodeCreateInput!) {
    createOneDesignAdvisoryCode(data: $data) {
      id
    }
  }
`

export interface CreateOneDesignAdvisoryCodePayload {
  createOneDesignAdvisoryCode: DesignAdvisoryCode
}

export interface CreateOneDesignAdvisoryCodeVariables {
  data: {
    description: string
    index: string
    required?: boolean
    designAdvisoryCodeGroup?: {
      connect: {
        id: string
      }
    }
  }
}

export const DELETE_ONE_DESIGN_ADVISORY_CODE = gql`
  mutation deleteOneDesignAdvisoryCode(
    $where: DesignAdvisoryCodeWhereUniqueInput!
  ) {
    deleteOneDesignAdvisoryCode(where: $where) {
      id
    }
  }
`

export interface DeleteOneDesignAdvisoryCodePayload {
  deleteOneDesignAdvisoryCode: {
    id: string
  }
}

export interface DeleteOneDesignAdvisoryCodeVariables {
  where: {
    id: string
  }
}

export const FIND_MANY_DESIGN_ADVISORY_CODE = gql`
  query {
    designAdvisoryCodes: findManyDesignAdvisoryCode {
      id
      createdAt
      description
      index
      designAdvisoryCodeGroup {
        id
        createdAt
        description
        index
      }
    }
    required
  }
`

export interface FindManyDesignAdvisoryCodePayload {
  designAdvisoryCodes: DesignAdvisoryCode[]
}

export const UPDATE_ONE_DESIGN_ADVISORY_CODE = gql`
  mutation updateOneDesignAdvisoryCode(
    $data: DesignAdvisoryCodeUpdateInput!
    $where: DesignAdvisoryCodeWhereUniqueInput!
  ) {
    updateOneDesignAdvisoryCode(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateOneDesignAdvisoryCodePayload {
  updateOneDesignAdvisoryCode: {
    id: string
  }
}

export interface UpdateOneDesignAdvisoryCodeVariables {
  data: {
    description?: string
    index?: string
    required?: boolean
    designAdvisoryCodeGroup?: {
      connect: {
        id: string
      }
    }
  }
  where: {
    id: string
  }
}
