import React from 'react'

import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'

interface CheckHighlightedProps {
  checked?: boolean
  color: 'red' | 'gray'
  disabled?: boolean
  onChange?: (val: React.FormEvent<HTMLInputElement>) => void
  text: string
}

const CheckHighlighted = (props: CheckHighlightedProps) => {
  return (
    <CheckContainer color={props.color}>
      <Checkbox
        disabled={props.disabled}
        checked={props.checked}
        label={
          <label>
            <p>{props.text}</p>
          </label>
        }
        onChange={props.onChange}
      />
    </CheckContainer>
  )
}

export default CheckHighlighted

const mapColorProp: Record<string, { light: string; dark: string }> = {
  red: {
    light: Colors.red50,
    dark: Colors.red700,
  },
  gray: {
    light: Colors.gray50,
    dark: Colors.gray600,
  },
}

const CheckContainer = styled.div<{
  color: string
}>`
  &&& {
    background: ${(props) => mapColorProp[props.color].light};
    border: 1px solid ${(props) => mapColorProp[props.color].dark};
    border-radius: 1px;
    margin-top: 0;
    padding: 10px 16px;
    width: fit-content !important;

    .checkbox {
      label {
        align-items: center;
        display: flex;
        height: auto;
        min-height: 24px;
        padding-left: 40px;
        text-align: left;

        p {
          color: ${(props) => mapColorProp[props.color].dark};
        }

        :after,
        :before {
          border-radius: 2px;
          height: 25px !important;
          width: 25px !important;
        }

        :before {
          background: ${Colors.gray50};
          border: 1px solid ${Colors.gray400} !important;
          border-radius: 1px;
        }
      }

      /* Checkmark */
      input:checked ~ label:after {
        align-items: center;
        content: '\f00c';
        display: flex;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        font-size: 12px;
        justify-content: center;
      }

      /* Checked */
      input:checked ~ .box:after,
      input:checked ~ label:after {
        background: ${(props) => mapColorProp[props.color].dark} !important;
        border: none !important;
        color: ${Colors.white} !important;
      }
    }
  }
`
