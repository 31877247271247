import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import SupplierOrdersPlanning from 'components/admin/supplier-order/index-many-planning'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import { NobiliaStatus } from 'context/supplier-order/utils-many'

const SOPlanning = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <SupplierOrderManyProvider
              defaultFilters={{
                includeThirdParty: true,
                sortBy: 'derivedStatus',
                sortDirection: 'ascending',
                statusesNobilia: [NobiliaStatus.ACKApproved],
                take: 100,
              }}
              skipLoader
            >
              <SupplierOrdersPlanning includeFilters includeJobButtons />
            </SupplierOrderManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default SOPlanning
