import React, { useEffect, useReducer, useState } from 'react'

import _ from 'lodash'
import moment from 'moment'
import { DateInput } from 'semantic-ui-calendar-react'
import {
  Divider,
  Form,
  Grid,
  Input,
  Label,
  Menu,
  Message,
} from 'semantic-ui-react'
import styled from 'styled-components'

import SamplesModal from 'components/admin/catalog/nobilia-sample-class/selection-modal'
import OrderCard from 'components/admin/project/order/card'
import PaymentItemsForm from 'components/admin/project/order/payment-items-form'
import SaveModal from 'components/admin/save-modal'
import { isAddressComplete } from 'components/shared/address/form/parser'
import HelpMessage from 'components/shared/help-message'
import useAuth from 'context/auth/use'
import orderReducer, { OrderReducerState } from 'context/order/reducer'
import useOrder from 'context/order/use'
import useOrderMutate from 'context/order/use-mutate'
import useOrderMutateNoContext from 'context/order/use-mutate-no-context'
import { NOBILIA_SAMPLE_PRICE } from 'context/order/utils-checkout'
import ProjectOrderSamplesManyProvider from 'context/project/order-samples/provider-many'
import useProjectOrderSamplesMany from 'context/project/order-samples/use-many'
import ProjectProvider from 'context/project/provider'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import { parseRooms } from 'context/project/utils'
import RoomProvider from 'context/room/provider'
import useRoom from 'context/room/use'
import { FormCheckbox, StyledForm } from 'styles/admin/main'
import { NobiliaSampleGraphQL } from 'types/nobilia-sample'
import { OrderData, OrderType } from 'types/order'
import { PaymentItem } from 'types/payment'
import { ProductClassType } from 'types/product-class'
import { Project } from 'types/project'
import { Prospect } from 'types/prospect'
import { Address } from 'types/utils'

const StandardDiscount = [
  {
    name: '3 Samples Included',
    quantity: 3,
    price: -NOBILIA_SAMPLE_PRICE,
  },
]

interface SamplesOrderProps {
  inRoomProvider?: boolean
  onComplete: () => void
  project?: Project
  prospect?: Prospect
}

const SamplesOrderTab = ({
  inRoomProvider,
  onComplete,
  project,
  prospect,
}: SamplesOrderProps) => {
  const { isSuperAdmin } = useAuth()
  const { primaryOwner } = useProject()
  const { order } = useOrder()
  const { updateOrderAndRefetch, loadingUpdate } = useOrderMutate()
  const { createOrder, loadingCreate } = useOrderMutateNoContext()
  const { nobiliaSamples } = useProjectOrderSamplesMany()
  const { updateProjectAndRefetch } = useProjectMutate()

  const [showAlert, toggleAlert] = useState<boolean>(false)
  const [menuSelected, setMenuSelected] = useState<
    'order_info' | 'tracking' | 'shipping'
  >('order_info')
  const { fronts } = useRoom()

  //pre-populate samples
  let initialSamples: NobiliaSampleGraphQL[] = []
  if (inRoomProvider) {
    const frontsOptKey = fronts?.map((f) => f.optionKey)
    if (frontsOptKey) {
      const samplesFromDesignBrief = nobiliaSamples.filter((s) =>
        frontsOptKey.includes(s.identifier),
      )

      initialSamples = samplesFromDesignBrief.map((s) => ({
        quantity: 1,
        productClass: s,
        productClassType: ProductClassType.NOBILIA_SAMPLE,
      }))
    }
  }

  const initialState: OrderReducerState = {
    order: {
      discounts:
        order?.discounts || (order || prospect ? [] : StandardDiscount),
      metadata: order?.metadata || {},
      nobiliaSamples: order?.nobiliaSamples || [],
      placedAt: order?.placedAt || null,
      payments: order?.payments || [],
      referenceNumber: order?.referenceNumber || '',
      shippingAddress: order?.shippingAddress || project?.shippingAddress,
      taxRate: order?.taxRate || 0,
      type: order?.type || OrderType.SAMPLES,
    },
  }
  const [state, dispatch] = useReducer(orderReducer, initialState)

  useEffect(() => {
    if (!state.order.nobiliaSamples?.length && initialSamples.length)
      dispatch({
        type: 'set_nobilia_samples',
        nobiliaSamples: initialSamples,
      })
  }, [inRoomProvider])

  const onSave = async () => {
    const nobiliaSamples = state.order.nobiliaSamples?.map((ns) => ({
      where: {
        id: ns.id || 'new',
      },
      update: {
        quantity: ns.quantity,
        productClassType: ns.productClassType,
        productClassId: ns.productClass.id,
      },
      create: {
        quantity: ns.quantity,
        productClassType: ns.productClassType,
        productClassId: ns.productClass.id,
      },
    }))
    if (order) {
      await updateOrderAndRefetch({
        variables: {
          data: {
            discounts: state.order.discounts ?? [],
            metadata: state.order.metadata as OrderData,
            nobiliaSamples: {
              upsert: nobiliaSamples,
              delete: order.nobiliaSamples
                ?.filter(
                  (ns) =>
                    !state.order.nobiliaSamples
                      ?.map((ns) => ns.id)
                      .includes(ns.id),
                )
                .map((ns) => ({ id: ns.id as string })),
            },
            placedAt: state.order.placedAt,
            referenceNumber: state.order.referenceNumber ?? undefined,
            shippingAddress: state.order.shippingAddress || undefined,
            taxRate: state.order.taxRate ?? undefined,
          },
          where: { id: order.id ?? '' },
        },
      })
    } else {
      await createOrder({
        variables: {
          data: {
            discounts: state.order.discounts ?? [],
            metadata: state.order.metadata as OrderData,
            nobiliaSamples: {
              create: state.order.nobiliaSamples?.map((ns) => ({
                quantity: ns.quantity,
                productClassType: ns.productClassType,
                productClass: {
                  connect: {
                    id: ns.productClass.id,
                  },
                },
              })),
            },
            ...(project && { project: { connect: { id: project.id } } }),
            ...(prospect && { prospect: { connect: { id: prospect.id } } }),
            shippingAddress: state.order.shippingAddress || undefined,
            taxRate: state.order.taxRate ?? undefined,
            type: state.order.type ?? undefined,
          },
        },
      })
    }
    updateProjectAndRefetch({
      variables: {
        where: {
          id: project?.id ?? '',
        },
        data: {
          shippingAddress: state.order.shippingAddress || undefined,
        },
      },
    })
    onComplete()
  }

  const shippingAddress = state.order.shippingAddress

  return (
    <>
      <Menu pointing secondary>
        <Menu.Item
          name="Order Information"
          active={menuSelected === 'order_info'}
          onClick={() => setMenuSelected('order_info')}
        />
        <Menu.Item
          name="Confirmation and Tracking"
          active={menuSelected === 'tracking'}
          onClick={() => setMenuSelected('tracking')}
        />
        <Menu.Item
          name="Shipping Address"
          active={menuSelected === 'shipping'}
          onClick={() => setMenuSelected('shipping')}
        />
      </Menu>
      {menuSelected === 'order_info' ? (
        <Grid padded>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <p className="subtitle underlined">Order Information</p>
              <p>
                Please select 3 samples per order whenever possible. More than 3
                requires multiple orders.
              </p>
              <StyledForm
                error={
                  !!state.order.nobiliaSamples?.length &&
                  !state.order.placedAt &&
                  nobiliaSamples
                    .filter(
                      (s) =>
                        state.order.nobiliaSamples
                          ?.map((p) => p.productClass.id)
                          .includes(s.id),
                    )
                    .some((s) => s.meta.outOfStock)
                }
              >
                <Form.Field>
                  <span>
                    Samples (Choose 3 samples)
                    <span className="red">*</span>
                  </span>
                  <div style={{ marginBottom: '12px' }}>
                    {state.order.nobiliaSamples?.length
                      ? nobiliaSamples
                          .filter(
                            (s) =>
                              state.order.nobiliaSamples
                                ?.map((p) => p.productClass.id)
                                .includes(s.id),
                          )
                          .map((s) => (
                            <Label
                              key={s.id}
                              content={s.description}
                              size="large"
                              style={{ marginBottom: '8px' }}
                              color={s.meta.outOfStock ? 'red' : undefined}
                            />
                          ))
                      : null}
                  </div>
                  <Message
                    error
                    content={`Warning! This order includes an out-of-stock sample. We will be unable to ship it until at least ${
                      state.order.nobiliaSamples?.length &&
                      moment(
                        _.maxBy(
                          nobiliaSamples.filter(
                            (s) =>
                              state.order.nobiliaSamples
                                ?.map((p) => p.productClass.id)
                                .includes(s.id),
                          ),
                          (s) => s.meta.expectedRestockDate,
                        )?.meta?.expectedRestockDate,
                      ).format('MMMM Do, YYYY')
                    }. We recommend you do not release the samples card until the sample is confirmed in stock. If you need to release the card, please double-check with logistics@formkitchens.com.`}
                  />
                  <SamplesModal
                    samples={nobiliaSamples.filter(
                      (nsc) => !nsc.meta || (nsc.meta && !nsc.meta.deprecated),
                    )}
                    selectedSamples={
                      state.order.nobiliaSamples?.map((p) => p.productClass) ??
                      []
                    }
                    setSamples={(samples: string[]) =>
                      dispatch({
                        type: 'set_nobilia_samples',
                        nobiliaSamples: nobiliaSamples
                          .filter((sc) => samples.includes(sc.id))
                          .map((sc) => ({
                            quantity: 1,
                            productClassType: ProductClassType.NOBILIA_SAMPLE,
                            productClass: sc,
                          })),
                      })
                    }
                  />
                </Form.Field>
              </StyledForm>
              <Divider hidden />
              <p className="subtitle underlined">Taxes</p>
              <StyledForm>
                <Form.Field>
                  Tax Rate (Calculated automatically on save)
                  <Input
                    label={{ basic: true, content: '%' }}
                    labelPosition="right"
                    name="taxRate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: 'set_tax_rate',
                        taxRate: parseFloat(e.target.value),
                      })
                    }}
                    type="number"
                    value={state.order.taxRate}
                  />
                </Form.Field>
                <FormCheckbox
                  checked={!!state.order.metadata?.taxExempt}
                  disabled={!isSuperAdmin}
                  label="Tax Exempt (must be set by admin on creation)"
                  onChange={() =>
                    dispatch({
                      type: 'set_tax_exempt',
                      isTaxExempt: !state.order.metadata?.taxExempt,
                    })
                  }
                />
              </StyledForm>
              <Divider hidden />
              <p className="subtitle underlined">Discounts</p>
              <PaymentItemsForm
                items={state.order.discounts ?? []}
                setItems={(ds: PaymentItem[]) =>
                  dispatch({
                    type: 'set_discounts',
                    discounts: ds,
                  })
                }
              />
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <p className="subtitle">Checkout</p>
              <OrderCard order={state.order} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : null}
      {menuSelected === 'tracking' ? (
        <Grid padded>
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="subtitle underlined">Customer Confirms</p>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Field disabled={!order}>
                  Placed At
                  <DateInput
                    closable
                    dateFormat={'MM/DD/YYYY'}
                    duration={0}
                    initialDate={moment().format('MM/DD/YYYY')}
                    onChange={async (
                      _: React.SyntheticEvent<HTMLElement, Event>,
                      { value }: { value: string },
                    ) => {
                      if (!primaryOwner.firstName) toggleAlert(true)
                      else
                        dispatch({
                          type: 'set_placed_at',
                          placedAt: moment(value, 'MM/DD/YYYY').isValid()
                            ? moment(value, 'MM/DD/YYYY').toDate()
                            : null,
                        })
                    }}
                    value={
                      state.order.placedAt
                        ? moment(state.order.placedAt).format('MM/DD/YYYY')
                        : ''
                    }
                  />
                </Form.Field>
                {showAlert && (
                  <HelpMessage message="Please complete customer’s name in the users table before ordering samples." />
                )}
                <Divider hidden />
                <HelpMessage message="Do not touch unless customer pays outside the system" />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p className="subtitle underlined">Delivery</p>
              <StyledForm>
                <Form.Group widths="equal">
                  <Form.Field>
                    <span>Shipped By</span>
                    <Form.Dropdown
                      name="shippedBy"
                      onChange={(_, { value }) => {
                        dispatch({
                          type: 'set_shipped_by',
                          shippedBy: value as string,
                        })
                      }}
                      options={[
                        {
                          key: 'NULL',
                          text: '',
                          value: '',
                        },
                        {
                          key: 'FEDEX_2DAY',
                          text: 'FedEx 2 Day (Standard)',
                          value: 'FedEx 2 Day (Standard)',
                        },
                        {
                          key: 'FEDEX_EXPEDITED',
                          text: 'FedEx Expedited',
                          value: 'FedEx Expedited',
                        },
                        {
                          key: 'DHL',
                          text: 'DHL',
                          value: 'DHL',
                        },
                        {
                          key: 'PICKUP',
                          text: 'Pick Up',
                          value: 'Pick Up',
                        },
                        {
                          key: 'FEDEX',
                          text: 'FedEx (legacy)',
                          value: 'FEDEX',
                        },
                        {
                          key: 'UPS',
                          text: 'UPS (legacy)',
                          value: 'UPS',
                        },
                      ]}
                      placeholder="Shipped By"
                      search
                      selection
                      value={state.order?.metadata?.shippedBy || ''}
                    />
                  </Form.Field>
                  <Form.Input
                    label="Tracking Number"
                    name="referenceNumber"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: 'set_reference_number',
                        referenceNumber: e.target.value,
                      })
                    }}
                    value={state.order.referenceNumber}
                  />
                  <Form.Field>
                    <span>Shipped At</span>
                    <StyledDateInputContainer>
                      <DateInput
                        closable
                        dateFormat={'MM/DD/YYYY'}
                        duration={0}
                        name="date"
                        onChange={(
                          _: React.SyntheticEvent<HTMLElement, Event>,
                          { value }: { value: string },
                        ) =>
                          dispatch({
                            type: 'set_shipped_at',
                            shippedAt: value.length ? value : null,
                          })
                        }
                        value={
                          state.order.metadata?.shippedAt
                            ? moment(state.order.metadata.shippedAt).format(
                                'MM/DD/YYYY',
                              )
                            : ''
                        }
                      />
                    </StyledDateInputContainer>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={3}>
                  <Form.Input
                    label="Tracking Number Second"
                    name="referenceAdditional"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: 'set_reference_additional',
                        referenceAdditional: e.target.value,
                      })
                    }}
                    value={state.order.metadata?.referenceAdditional || ''}
                  />
                </Form.Group>
              </StyledForm>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : null}
      {menuSelected === 'shipping' ? (
        <Grid padded>
          <Grid.Row>
            <Grid.Column width={7}>
              <p className="subtitle underlined">Shipping Address</p>
              {!!prospect && (
                <HelpMessage message="To update the shipping address, please go to the <b>General Shipment Address</b> form below the Samples Order table." />
              )}
              <StyledForm>
                <Form.Input
                  disabled={!!prospect}
                  label="Name of Recipient"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: 'set_shipping_address',
                      shippingAddress: {
                        ...(shippingAddress as Address),
                        recipient: e.target.value,
                      },
                    })
                  }}
                  placeholder="Name of Recipient"
                  value={shippingAddress?.recipient}
                />
                <Form.Input
                  disabled={!!prospect}
                  label="Street Address"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: 'set_shipping_address',
                      shippingAddress: {
                        ...(shippingAddress as Address),
                        streetAddress: e.target.value,
                      },
                    })
                  }}
                  placeholder="Street Address"
                  required
                  value={shippingAddress?.streetAddress}
                />
                <Form.Input
                  disabled={!!prospect}
                  label="Apt / Floor Number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: 'set_shipping_address',
                      shippingAddress: {
                        ...(shippingAddress as Address),
                        apartmentNumber: e.target.value,
                      },
                    })
                  }}
                  placeholder="Apt / Floor Number"
                  value={shippingAddress?.apartmentNumber}
                />
                <Form.Input
                  disabled={!!prospect}
                  label="City"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: 'set_shipping_address',
                      shippingAddress: {
                        ...(shippingAddress as Address),
                        addressLocality: e.target.value,
                      },
                    })
                  }}
                  placeholder="City"
                  required
                  value={shippingAddress?.addressLocality}
                />
                <StyledFormGroup widths="equal">
                  <Form.Input
                    disabled={!!prospect}
                    label="State"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: 'set_shipping_address',
                        shippingAddress: {
                          ...(shippingAddress as Address),
                          addressRegion: e.target.value,
                        },
                      })
                    }}
                    placeholder="State"
                    required
                    value={shippingAddress?.addressRegion}
                  />
                  <Form.Input
                    disabled={!!prospect}
                    label="Zip Code"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: 'set_shipping_address',
                        shippingAddress: {
                          ...(shippingAddress as Address),
                          postalCode: e.target.value,
                        },
                      })
                    }}
                    placeholder="Zip Code"
                    required
                    value={shippingAddress?.postalCode}
                  />
                </StyledFormGroup>
              </StyledForm>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : null}
      <SaveModal
        currentState={state}
        loadingSave={loadingCreate || loadingUpdate}
        disableSave={
          (!isSuperAdmin && Number(state.order.nobiliaSamples?.length) < 3) ||
          (shippingAddress &&
            !isAddressComplete(shippingAddress) &&
            !Object.values(shippingAddress)?.every((v) => !v)) ||
          ((state.order.placedAt && !shippingAddress) as boolean)
        }
        initialState={initialState}
        onDiscard={onComplete}
        onSave={onSave}
      />
    </>
  )
}

export default (props: SamplesOrderProps) => {
  if (!props.project || !!props.project?.orders?.length)
    return <SamplesOrderTab {...props} />

  const { roomPrimary } = parseRooms(props.project)

  return (
    <ProjectProvider project_id={props.project.id}>
      <ProjectOrderSamplesManyProvider>
        <RoomProvider room_id={roomPrimary.id}>
          <SamplesOrderTab {...props} inRoomProvider />
        </RoomProvider>
      </ProjectOrderSamplesManyProvider>
    </ProjectProvider>
  )
}

const StyledDateInputContainer = styled.div`
  &&& {
    thead {
      text-align: center !important;
    }
  }
`

const StyledFormGroup = styled(Form.Group)`
  align-items: flex-start !important;

  & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`
