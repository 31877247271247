import React from 'react'

import moment from 'moment'
import { Image, List, Table } from 'semantic-ui-react'

import { parseOwners } from 'context/project/utils'
import { TableUserListItem } from 'styles/admin/main'
import { ProjectStaff } from 'types/project-staff'
import * as viewUtils from 'views/utils'

const ProjectsStaffTable = ({
  projectsStaff,
}: {
  projectsStaff: ProjectStaff[]
}) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Designer</Table.HeaderCell>
          <Table.HeaderCell>Project</Table.HeaderCell>
          <Table.HeaderCell>Assigned</Table.HeaderCell>
          <Table.HeaderCell>Assignment Type</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {projectsStaff.map((ps) => {
          const { primaryOwner, primaryOwnersNames } = parseOwners(ps.project)
          const designer = ps.staffMember

          return (
            <Table.Row key={`${ps.project?.id}${ps.staffMember?.id}`}>
              <Table.Cell textAlign="left" width={4}>
                <List relaxed verticalAlign="middle">
                  <TableUserListItem>
                    <Image
                      src={
                        designer?.picture ||
                        'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon&f=y'
                      }
                      avatar
                      circular
                    />
                    <List.Content verticalAlign="middle">
                      <List.Header>
                        {designer
                          ? viewUtils.responsiveText(
                              viewUtils.getNameForUser(designer),
                              50,
                            )
                          : 'No designer connected'}
                      </List.Header>
                    </List.Content>
                  </TableUserListItem>
                </List>
              </Table.Cell>
              <Table.Cell textAlign="left" width={4}>
                <List relaxed verticalAlign="middle">
                  <TableUserListItem>
                    <Image
                      src={
                        primaryOwner?.picture ||
                        'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon&f=y'
                      }
                      avatar
                      circular
                    />
                    <List.Content verticalAlign="middle">
                      <List.Header>
                        <a
                          onClick={(e) => {
                            e.preventDefault()
                            window.open(
                              `/admin/projects/${ps.project?.id}/overview`,
                            )
                          }}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          {primaryOwnersNames
                            ? viewUtils.responsiveText(primaryOwnersNames, 50)
                            : 'No homeowner connected'}
                        </a>
                      </List.Header>
                    </List.Content>
                  </TableUserListItem>
                </List>
              </Table.Cell>
              <Table.Cell textAlign="left">
                {moment(ps.createdAt).format('LL')}
              </Table.Cell>
              <Table.Cell>
                {viewUtils.capsSnakeCaseToTitleCase(
                  ps.data?.staffMemberAssignmentType || '',
                )}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default ProjectsStaffTable
