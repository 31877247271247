import React from 'react'

import { Divider, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { useCatalogFrontColor } from 'content-queries/cx/catalog-front-color'
import { useCatalogHandleColor } from 'content-queries/cx/catalog-handle-color'
import { useCategoryHandle } from 'content-queries/cx/category-handle'
import { useDrillPatterns } from 'content-queries/cx/drill-patterns'
import { useFrontMaterial } from 'content-queries/cx/front-material'
import { useHandleType } from 'content-queries/cx/handle-type'
import * as NO from 'planner/objects/products/nobilia/options/definitions'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { NobiliaOption } from 'types/nobilia-option'

import NobiliaOptionMetadataModal from './metadata-modal'

const NobiliaOptionMetadata = ({
  option,
  priceGroup,
  refetch,
}: {
  option: NobiliaOption
  priceGroup?: string
  refetch?: () => Promise<unknown>
}) => {
  const { data: allDrillPatterns } = useDrillPatterns()

  const drillPatternsTitle = option.data?.drillPatterns?.map((dp) => {
    return allDrillPatterns.find(
      (adp: { contentful_id: string }) => dp === adp.contentful_id,
    )?.title
  })

  const optionStatus = option.data?.isDiscontinued
    ? 'Discontinued, not showing website catalog'
    : option.data?.showOnWebsiteCatalog
    ? 'Showing on website catalog'
    : 'Not showing on website catalog'

  if (option.featureNo === NO.FRONT_COMBO) {
    return (
      <FrontCombo
        option={option}
        priceGroup={priceGroup}
        optionStatus={optionStatus}
        drillPatternsTitle={drillPatternsTitle ?? []}
      />
    )
  } else if (option.featureNo === NO.HANDLE_COMBINATION) {
    return (
      <HandleCombination
        option={option}
        refetch={refetch}
        optionStatus={optionStatus}
        drillPatternsTitle={drillPatternsTitle ?? []}
      />
    )
  } else
    return (
      <Metadata>
        <Grid.Row>
          <Grid.Column width={6}>
            <h6>Metadata</h6>
          </Grid.Column>
          <Grid.Column width={6} floated="right" style={{ textAlign: 'right' }}>
            <NobiliaOptionMetadataModal
              nobiliaOption={option}
              refetch={refetch}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <div className="content">
          <Grid>
            <Grid.Row>
              <Grid.Column
                floated="left"
                style={{ color: Colors.gray600 }}
                width={7}
              >
                Status
              </Grid.Column>
              <Grid.Column width={9}>{optionStatus}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                floated="left"
                style={{ color: Colors.gray600 }}
                width={7}
              >
                Display Name
              </Grid.Column>
              <Grid.Column width={9}>
                {option.data?.displayName || option.description}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Metadata>
    )
}

export default NobiliaOptionMetadata

const FrontCombo = ({
  drillPatternsTitle,
  option,
  optionStatus,
  priceGroup,
  refetch,
}: {
  drillPatternsTitle: string[]
  option: NobiliaOption
  optionStatus: string
  priceGroup?: string
  refetch?: () => Promise<unknown>
}) => {
  const { data: allFrontColors } = useCatalogFrontColor()
  const { data: allHandleTypes } = useHandleType()
  const { data: allFrontMaterials } = useFrontMaterial()

  const handleTypesTitle = option.data?.handleTypes?.map((ht) => {
    return allHandleTypes.find(
      (aht: { contentful_id: string }) => ht === aht.contentful_id,
    )?.title
  })

  const materialsTitle = allFrontMaterials.find(
    (afm: { contentful_id: string }) =>
      option.data?.material === afm.contentful_id,
  )?.title

  const frontColor = allFrontColors.find(
    (afc: { contentful_id: string }) =>
      afc.contentful_id === option.data?.color,
  )

  return (
    <Metadata>
      <Grid.Row>
        <Grid.Column width={6}>
          <h6 className="no-margin">Metadata</h6>
        </Grid.Column>
        <Grid.Column width={8} textAlign="right">
          <NobiliaOptionMetadataModal
            nobiliaOption={option}
            priceGroup={priceGroup}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <div className="content">
        <Grid>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Status
            </Grid.Column>
            <Grid.Column width={9}>{optionStatus}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Display Name
            </Grid.Column>
            <Grid.Column width={9}>
              {option.data?.displayName || option.description}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Finish
            </Grid.Column>
            <Grid.Column width={9}>{option.data?.finish}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Material
            </Grid.Column>
            <Grid.Column width={9}>{materialsTitle}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Texture
            </Grid.Column>
            <Grid.Column width={9}>{option.data?.texture}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Drill Patterns
            </Grid.Column>
            <Grid.Column width={9}>
              {drillPatternsTitle?.join(', ')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Related Fronts
            </Grid.Column>
            <Grid.Column width={9}>
              {option.data?.relatedFronts
                ?.map((d) => d.optionKey + ' - ' + d.description)
                ?.join(', ')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Color
            </Grid.Column>
            <Grid.Column width={9}>
              <p style={{ display: 'inline-flex' }}>
                <Image
                  style={{ width: '25px', marginRight: '10px' }}
                  src={frontColor?.image?.file.url}
                />
                {frontColor?.title}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Handle Type
            </Grid.Column>
            <Grid.Column width={9}>{handleTypesTitle?.join(', ')}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Related Handles
            </Grid.Column>
            <Grid.Column width={9}>
              {option.data?.relatedHandles
                ?.map((d) => d.optionKey + ' - ' + d.description)
                ?.join(', ')}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Metadata>
  )
}

const HandleCombination = ({
  drillPatternsTitle,
  option,
  optionStatus,
  refetch,
}: {
  drillPatternsTitle: string[]
  option: NobiliaOption
  optionStatus: string
  refetch?: () => Promise<unknown>
}) => {
  const { data: allCategoryHandle } = useCategoryHandle()

  const categoryHandleTitle = allCategoryHandle.find(
    (ach: { contentful_id: string }) =>
      option.data?.category === ach.contentful_id,
  )?.title

  const { data: allHandleColors } = useCatalogHandleColor()
  const handleColor = allHandleColors.find(
    (afc: { contentful_id: string }) =>
      afc.contentful_id === option.data?.color,
  )
  return (
    <Metadata>
      <Grid.Row>
        <Grid.Column width={6}>
          <h6>Metadata</h6>
        </Grid.Column>
        <Grid.Column width={6} floated="right" style={{ textAlign: 'right' }}>
          <NobiliaOptionMetadataModal
            nobiliaOption={option}
            refetch={refetch}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <div className="content">
        <Grid>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Status
            </Grid.Column>
            <Grid.Column width={9}>{optionStatus}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Display Name
            </Grid.Column>
            <Grid.Column width={9}>
              {option.data?.displayName || option.description}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Drill Patterns
            </Grid.Column>
            <Grid.Column width={9}>
              {drillPatternsTitle?.join(', ')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Drill Distance
            </Grid.Column>
            <Grid.Column width={9}>
              {option.data?.drillDistance?.join(' mm, ')}{' '}
              {option.data?.drillDistance ? 'mm' : ''}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Height
            </Grid.Column>
            <Grid.Column width={9}>
              {option.data?.height?.join(', ')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Category
            </Grid.Column>
            <Grid.Column width={9}>{categoryHandleTitle}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Color
            </Grid.Column>
            <Grid.Column width={9}>
              <p style={{ display: 'inline-flex' }}>
                <Image
                  style={{ width: '25px', marginRight: '10px' }}
                  src={handleColor?.image?.file.url}
                />
                {handleColor?.title}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Related Handles
            </Grid.Column>
            <Grid.Column width={9}>
              {option.data?.relatedHandles
                ?.map((d) => d.optionKey + ' - ' + d.description)
                ?.join(', ')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              floated="left"
              style={{ color: Colors.gray600 }}
              width={7}
            >
              Price Range
            </Grid.Column>
            <Grid.Column width={9}>{option.data?.priceRange}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Metadata>
  )
}

const Metadata = styled(Grid)`
  background-color: ${Colors.gray50};
  padding: 10px !important;

  div.divider {
    margin: 3px 14px 14px !important;
    padding: 0px !important;
  }

  div.row {
    padding: 10px 0 !important;
  }

  div.content {
    max-height: 350px;
    overflow-y: scroll;
    padding-left: 5px;
    position: relative;
    right: -10px;

    ${ScrollBarStyle}
  }
`
