import { useMemo, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { sortBy, uniqBy } from 'lodash'

import {
  FIND_MANY_USER,
  FindManyUserPayload,
  FindManyUserVariables,
} from 'queries/user'
import { User } from 'types/user'
import { getNameForUser } from 'views/utils'

const useUserMany = () => {
  const [users, resetUsers] = useState<User[]>([])
  const [findManyUsers, { loading }] = useLazyQuery<
    FindManyUserPayload,
    FindManyUserVariables
  >(FIND_MANY_USER)

  const fetchUsers = (args: Parameters<typeof findManyUsers>[0]) =>
    new Promise<FindManyUserPayload>((resolve, reject) => {
      findManyUsers({
        ...args,
        onCompleted: (data) => {
          resetUsers(data.users || [])
          args?.onCompleted?.(data)
          resolve(data)
        },
        onError: (error) => {
          args?.onError?.(error)
          reject(error)
        },
      })
    })

  const userOptions = useMemo(() => {
    return sortBy(
      uniqBy([...(users.length ? users : [])], (p) => p.email).map((p) => {
        return {
          key: p.id,
          text: getNameForUser(p),
          value: p.id,
          ...(!!p.firstName && { description: p.email }),
        }
      }),
      'text',
    )
  }, [users])

  return {
    fetchUsers,
    loading,
    resetUsers,
    userOptions,
    users,
  }
}

export default useUserMany
