import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Deliveries from 'components/admin/delivery/index-many'
import useTechDesignerSupport from 'context/dashboard/tech-designer-support/use'
import DeliveryManyProvider from 'context/delivery/provider-many'

import TechDesignerSupportLayout from './layout'

const TechDesignerSupportDeliveries = (_: RouteComponentProps) => {
  const { userId, userName } = useTechDesignerSupport()
  return (
    <TechDesignerSupportLayout>
      <DeliveryManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          take: 30,
        }}
        skipLoader
      >
        <Deliveries />
      </DeliveryManyProvider>
    </TechDesignerSupportLayout>
  )
}

export default TechDesignerSupportDeliveries
