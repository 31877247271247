import React, { useState } from 'react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import Button from 'design-system/components/button'

const SignOffModal = ({
  disabled,
  loading,
  onSave,
}: {
  disabled: boolean
  loading: boolean
  onSave: () => void
}) => {
  const [showModal, toggleModal] = useState<boolean>(false)
  const onClose = () => {
    toggleModal(false)
  }
  return (
    <ConfirmationModal
      firstTitle="Confirm Design Sign-Off"
      cancelText="No, let me check again"
      confirmText="Yes, sign off"
      extraContent={
        <p>
          After the design sign-off, you will have the ability to publish your
          design.
        </p>
      }
      onCancel={onClose}
      onConfirm={() => {
        onSave()
        onClose()
      }}
      open={showModal}
      trigger={
        <Button
          disabled={disabled}
          kind="solid"
          loading={loading}
          onClick={() => toggleModal(true)}
          size="fluid"
          text="Sign Off"
        />
      }
    />
  )
}

export default SignOffModal
