import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import ProspectLayout from 'components/admin/prospect/layout'
import useProspect from 'context/prospect/use'

import CompanyInfo from './info/company'

const Company = (_: RouteComponentProps) => {
  const { prospect } = useProspect()
  return (
    <ProspectLayout>
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            {!!prospect && <CompanyInfo prospect={prospect} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProspectLayout>
  )
}

export default Company
