import {
  FindManyConversationVariables,
  FindManyConversationWhere,
} from 'queries/conversation'

export interface QueryFilters {
  lastMessageReceivedAt?: {
    gte: string
    lte: string
  }
  search?: string
  clientUser?: 'all' | 'assigned' | 'notAssigned'
  orderBy?: 'asc' | 'desc'

  // how many to take
  take?: number
}

export const getQueryVariables = ({
  clientUser,
  take,
  orderBy = 'desc',
  search,
  lastMessageReceivedAt,
}: QueryFilters): FindManyConversationVariables => {
  const variables = {
    orderBy: { lastMessageReceivedAt: orderBy },
    where: {
      AND: [],
    } as WithRequiredProperty<FindManyConversationWhere, 'AND'>,
    take,
  } satisfies FindManyConversationVariables

  if (clientUser === 'assigned') {
    variables.where.NOT = {
      clientUser: null,
    }
  } else if (clientUser === 'notAssigned') {
    variables.where.clientUser = null
  }

  if (search) {
    const phoneSearch = search.replace(/\W/g, '')

    variables.where.AND.push({
      OR: [
        {
          messages: {
            some: {
              data: {
                path: ['sourceFrom'],
                string_contains: phoneSearch,
              },
            },
          },
        },
        {
          messages: {
            some: {
              data: {
                path: ['sourceTo'],
                string_contains: phoneSearch,
              },
            },
          },
        },
        {
          clientUser: {
            derivedSearch: {
              contains: search,
              mode: 'insensitive',
            },
          },
        },
        {
          clientUser: {
            phone: {
              contains: phoneSearch,
            },
          },
        },
      ],
    })
  }

  if (lastMessageReceivedAt) {
    variables.where.createdAt = {
      gte: lastMessageReceivedAt.gte,
    }
    variables.where.lastMessageReceivedAt = {
      lte: lastMessageReceivedAt.lte,
    }
  }

  return variables
}
