import React, { useState } from 'react'

import moment from 'moment'
import { Form, Grid } from 'semantic-ui-react'

import CarouselModal from 'components/shared/carousel/carousel-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import FileCard from 'components/shared/project/project-file/card'
import useAuth from 'context/auth/use'
import useDesign from 'context/design/use'
import useDesignMutate from 'context/design/use-mutate'
import useDesignProjectFileMutate from 'context/design/use-project-file-mutate'
import StyledAccordion from 'design-system/components/accordion'
import Button from 'design-system/components/button'
import { StyledToggle } from 'styles/admin/main'

const DesignRenders = () => {
  const { isAdmin } = useAuth()
  const { design, rendersReleased, rendersUnreleased } = useDesign()
  const { updateDesignAndRefetch, loadingUpdate } = useDesignMutate()
  const { updateProjectFileAndRefetch } = useDesignProjectFileMutate()

  const [markDone, setMarkDone] = useState<boolean>(false)
  const [removeId, setRemoveId] = useState<string | null>(null)
  const [sendUnreleased, setSendUnreleased] = useState<boolean>(false)

  return (
    <StyledAccordion backgroundWhite titleText="Renders">
      <Grid>
        {isAdmin && (
          <>
            <Grid.Row>
              <Grid.Column>
                <CarouselModal
                  currentIdx={0}
                  images={rendersUnreleased.map(
                    (f) =>
                      `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                        f.key,
                      )}`,
                  )}
                  trigger={
                    <Button
                      color="blue"
                      fontAwesomeIcon="eye"
                      kind="solid"
                      text="View Pending"
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              {[...rendersReleased, ...rendersUnreleased].map((f) => (
                <Grid.Column key={f.id} style={{ marginBottom: '25px' }}>
                  <FileCard
                    file={f}
                    navigateTo={`${
                      process.env.GATSBY_CDN_ROOT
                    }/${encodeURIComponent(f.key)}`}
                  />
                  <br />
                  {f.metadata?.renderOnHoldAt ? (
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <StyledToggle
                            checked={!!f.metadata?.renderReleasedAt}
                            label={
                              f.metadata?.renderReleasedAt
                                ? 'Released'
                                : 'Pending'
                            }
                            onChange={() =>
                              updateProjectFileAndRefetch({
                                variables: {
                                  data: {
                                    metadata: {
                                      ...f.metadata,
                                      renderReleasedAt: f.metadata
                                        ?.renderReleasedAt
                                        ? null
                                        : new Date(),
                                    },
                                  },
                                  where: {
                                    id: f.id,
                                  },
                                },
                              })
                            }
                            toggle
                          />
                        </Form.Field>
                        <Form.Field>
                          {!f.metadata?.renderReleasedAt ? (
                            <Button
                              kind="ghost"
                              color="gray"
                              fontAwesomeIcon="trash"
                              onClick={() => setRemoveId(f.id)}
                              style={{ float: 'right' }}
                            />
                          ) : null}
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  ) : (
                    <div>Uploaded by designer</div>
                  )}
                </Grid.Column>
              ))}
              <ConfirmationModal
                firstTitle="Are you sure you want to remove this file?"
                onCancel={() => setRemoveId(null)}
                onConfirm={async () => {
                  await updateProjectFileAndRefetch({
                    variables: {
                      data: {
                        metadata: {
                          ...design?.renders.find((r) => r.id === removeId)
                            ?.metadata,
                          archivedAt: new Date(),
                        },
                        designRender: { disconnect: true },
                      },
                      where: {
                        id: removeId ?? '',
                      },
                    },
                  })
                  setSendUnreleased(false)
                }}
                open={!!removeId}
              />
            </Grid.Row>
            <Grid.Row columns="4">
              <Grid.Column>
                <ConfirmationModal
                  firstTitle={`Are you sure you want to send ${rendersUnreleased.length} unreleased renders to designer?`}
                  onCancel={() => setSendUnreleased(false)}
                  onConfirm={async () => {
                    updateDesignAndRefetch({
                      variables: {
                        data: {
                          metadata: {
                            ...design?.metadata,
                            rendersSendUnreleasedAt: new Date(),
                          },
                        },
                        where: {
                          id: design?.id ?? '',
                        },
                      },
                    })
                    setSendUnreleased(false)
                  }}
                  open={!!sendUnreleased}
                />
                <Button
                  fontAwesomeIcon="envelope"
                  kind="solid"
                  color="blue"
                  loading={loadingUpdate}
                  onClick={() => setSendUnreleased(true)}
                  text={
                    design?.metadata?.rendersSendUnreleasedAt
                      ? `Sent Pending ${moment(
                          design?.metadata?.rendersSendUnreleasedAt,
                        ).format('LL')}`
                      : 'Send Pending'
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <ConfirmationModal
                  firstTitle="Are you sure you want to mark these renders as done?"
                  onCancel={() => setMarkDone(false)}
                  onConfirm={async () => {
                    updateDesignAndRefetch({
                      variables: {
                        data: {
                          metadata: {
                            ...design?.metadata,
                            rendersDoneAt: new Date(),
                          },
                        },
                        where: {
                          id: design?.id ?? '',
                        },
                      },
                    })
                    setMarkDone(false)
                  }}
                  open={!!markDone}
                />
                <Button
                  kind="solid"
                  fontAwesomeIcon="check"
                  loading={loadingUpdate}
                  onClick={() => setMarkDone(true)}
                  text={
                    design?.metadata?.rendersDoneAt
                      ? `Done ${moment(design?.metadata?.rendersDoneAt).format(
                          'LL',
                        )}`
                      : 'Mark Done'
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </StyledAccordion>
  )
}

export default DesignRenders
