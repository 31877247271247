import React from 'react'

import { RouteComponentProps } from '@reach/router'

import SupplierOrders from 'components/admin/supplier-order/index-many'
import useTechDesigner from 'context/dashboard/tech-designer/use'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import {
  FORMStatus,
  SupplierOrderType,
} from 'context/supplier-order/utils-many'

import TechDesignerLayout from './layout'

const TechDesignerSupplierOrders = (_: RouteComponentProps) => {
  const { refetchCounts, userId, userName } = useTechDesigner()

  return (
    <TechDesignerLayout>
      <SupplierOrderManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          sortBy: 'derivedStatus',
          sortDirection: 'ascending',
          statusesFORM: [FORMStatus.AwaitingFORM],
          take: 50,
          type: [SupplierOrderType.Regular],
        }}
        refetchMore={refetchCounts}
        skipLoader
      >
        <SupplierOrders />
      </SupplierOrderManyProvider>
    </TechDesignerLayout>
  )
}

export default TechDesignerSupplierOrders
