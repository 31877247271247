import { useContext, useMemo } from 'react'

import { Route } from 'types/route'

import { RouteContext } from './provider'
import * as utils from './utils'

const useRoute = () => {
  const { route, refetch } = useContext(RouteContext)

  const parsedSupplierOrders = useMemo(
    () => utils.parseSupplierOrders(route as Route),
    [route?.supplierOrders],
  )

  return {
    ...parsedSupplierOrders,
    route,
    refetch,
  }
}

export default useRoute
