import moment from 'moment'

import { FindManyDeliveryVariables } from 'queries/delivery'
import { ProjectStaffRole } from 'types/project-staff'

export interface QueryFilters {
  // staff assigned
  assignedTo?: {
    id: string
    name?: string
  } | null
  assignedToRole?: ProjectStaffRole | null
  damagesStatus?: 'all' | 'damages' | 'noDamages'
  deliveryStatus?: 'all' | 'upcoming' | 'delivered'
  show?: 'all' | 'unresolvedDamages'
  // owner search
  search?: string

  // how many to take
  take: number

  // sort values
  sortBy?: 'date'
  sortDirection?: 'descending' | 'ascending'
}

export const getQueryVariables = ({
  assignedTo,
  assignedToRole,
  damagesStatus,
  deliveryStatus,
  show,
  search,
  sortBy,
  sortDirection,
  take,
}: QueryFilters): FindManyDeliveryVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'date': {
        return {
          date: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyDeliveryVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (assignedTo?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        project: {
          staff: {
            some: {
              staffMemberId: {
                equals: assignedTo.id,
              },
              isSubscribed: {
                equals: true,
              },
              ...(assignedToRole && {
                staffMemberRole: {
                  equals: assignedToRole,
                },
              }),
            },
          },
        },
      },
    }
  }
  switch (deliveryStatus) {
    case 'upcoming': {
      variables = {
        ...variables,
        where: {
          ...variables.where,
          date: {
            gt: moment().toISOString(),
          },
        },
      }
      break
    }
    case 'delivered': {
      variables = {
        ...variables,
        where: {
          ...variables.where,
          date: {
            lte: moment().toISOString(),
          },
        },
      }
      break
    }
    default: {
      break
    }
  }
  switch (damagesStatus) {
    case 'damages': {
      variables = {
        ...variables,
        where: {
          ...variables.where,
          OR: [
            {
              issues: {
                some: {
                  id: {
                    not: undefined,
                  },
                },
              },
            },
            {
              data: {
                path: 'damagesSelection',
                equals: 'damaged',
              },
            },
          ],
        },
      } as any
      break
    }
    case 'noDamages': {
      variables = {
        ...variables,
        where: {
          ...variables.where,
          AND: [
            {
              issues: { none: {} },
            },
            {
              data: {
                path: 'damagesSelection',
                equals: 'no-damage',
              },
            },
          ],
        },
      }
      break
    }
    default: {
      break
    }
  }
  if (show === 'unresolvedDamages') {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        issues: {
          some: {
            supplierOrderResolving: null,
          },
        },
      },
    }
  }

  return variables
}
