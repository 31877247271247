import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from 'semantic-ui-react'

import { Colors } from 'styles/app/system'
import { Prospect } from 'types/prospect'

const ProspectInfo = ({ prospect }: { prospect: Prospect | null }) => {
  if (!prospect) return null
  return (
    <Table compact>
      <Table.Body>
        <Table.Row>
          <Table.Cell collapsing>
            <FontAwesomeIcon icon={['fal', 'user']} />
          </Table.Cell>
          <Table.Cell>
            {prospect.firstName} {prospect.lastName}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <FontAwesomeIcon icon={['fal', 'envelope']} />
          </Table.Cell>
          <Table.Cell>{prospect.email}</Table.Cell>
        </Table.Row>
        {prospect.data?.emailAlternate ? (
          <Table.Row>
            <Table.Cell>
              <FontAwesomeIcon icon={['fal', 'envelope']} />
            </Table.Cell>
            <Table.Cell>
              Check alternate email: {prospect.data?.emailAlternate}
            </Table.Cell>
          </Table.Row>
        ) : null}
        <Table.Row>
          <Table.Cell>
            <FontAwesomeIcon icon={['fal', 'phone']} />
          </Table.Cell>
          <Table.Cell>{prospect.phone}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
          </Table.Cell>
          <Table.Cell>
            {`${prospect.zipCode}${
              prospect.data?.city
                ? ` ${prospect.data?.city}, ${prospect.data?.state}`
                : ''
            }`}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <FontAwesomeIcon icon={['fal', 'people-arrows']} />
          </Table.Cell>
          <Table.Cell>
            Type:{' '}
            <span style={{ color: Colors.gray500 }}>
              {prospect.data?.location || 'Online'}
            </span>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <FontAwesomeIcon icon={['fal', 'arrow-alt-circle-right']} />
          </Table.Cell>
          <Table.Cell>
            Source:{' '}
            <span style={{ color: Colors.gray500 }}>
              {prospect.dataTracking?.utmSource
                ? [
                    prospect.dataTracking.utmSource,
                    prospect.dataTracking.utmMedium,
                    prospect.dataTracking.utmTerm,
                  ]
                    .filter((a) => a)
                    .join(' | ')
                : '---'}
            </span>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <FontAwesomeIcon icon={['fal', 'badge-percent']} />
          </Table.Cell>
          <Table.Cell>
            Affiliate:{' '}
            <span style={{ color: Colors.gray500 }}>
              {prospect?.data?.affiliateCode || '---'}
            </span>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default ProspectInfo
