import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import moment from 'moment'
import styled from 'styled-components'

import { getSupplierOrders } from 'context/order/utils'
import { getSupplierOrderStatusSummary } from 'context/supplier-order/utils'
import { Order } from 'types/order'

const CellContentsFulfillment = ({ order }: { order: Order }) => {
  const supplierOrdersAll = getSupplierOrders(order)
  return (
    <div style={{ display: 'grid', rowGap: '8px' }}>
      {supplierOrdersAll.map((so) => {
        const soStatus = getSupplierOrderStatusSummary(so)
        return (
          <FullfilmentDiv key={so.id}>
            <div>
              <FontAwesomeIcon icon={['fal', 'dolly']} />
            </div>
            <div>
              <p
                className="no-margin reference"
                onClick={() => {
                  navigate(
                    `/admin/projects/${order.project?.id}/supplier-orders/${so.id}`,
                  )
                }}
              >
                {so.nobiliaOrderRef}
              </p>
              <p className="light-gray no-margin x-small">
                {soStatus.currentStage}
              </p>
              <p className="light-gray no-margin x-small">
                {soStatus.currentStageDate
                  ? moment(soStatus.currentStageDate).format('MMMM DD, YYYY')
                  : null}
              </p>
            </div>
          </FullfilmentDiv>
        )
      })}
    </div>
  )
}

export default CellContentsFulfillment

const FullfilmentDiv = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  p.reference {
    cursor: pointer;
  }
`
