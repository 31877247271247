import React from 'react'

import { Grid } from 'semantic-ui-react'

import Button from 'design-system/components/button'

export const AppLoadButton = ({ onClick }: { onClick: () => void }) => (
  <Grid.Row centered>
    <Grid>
      <Grid.Column>
        <Button
          kind="outlined"
          color="gray"
          size="large"
          text="View more"
          onClick={onClick}
        />
      </Grid.Column>
    </Grid>
  </Grid.Row>
)

export const LoadButton = ({ onClick }: { onClick: () => void }) => (
  <Grid.Row>
    <Grid.Column textAlign="right">
      <Button
        color="blue"
        fontAwesomeIcon="arrow-to-bottom"
        kind="solid"
        onClick={onClick}
        text="Load More"
      />
    </Grid.Column>
  </Grid.Row>
)

export const BackToTop = ({ onClick }: { onClick?: () => void }) => (
  <Grid.Row>
    <Grid.Column textAlign="right">
      <Button
        fontAwesomeIcon="arrow-to-top"
        text="Back To Top"
        kind="solid"
        color="blue"
        onClick={
          onClick
            ? onClick
            : () => {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0
              }
        }
      />
    </Grid.Column>
  </Grid.Row>
)
