import React, { useState } from 'react'

import moment from 'moment'
import { Card, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import ConfirmationModal from 'components/shared/confirmation-modal'
import useMeetingMutateNoContext from 'context/meeting/use-mutate-no-context'
import { notifySuccess } from 'context/notifications/trigger'
import { prospectMeetingType } from 'context/prospect/utils'
import Button from 'design-system/components/button'
import FormLogo from 'icons/short-logo.svg'
import { Colors } from 'styles/app/system'
import { Meeting } from 'types/meeting'
import { Prospect } from 'types/prospect'

import ConsultationModal from './create-update-consultation'

const ConsultationCard = ({
  meeting,
  prospect,
  refetch,
}: {
  meeting: Meeting
  prospect: Prospect
  refetch: () => void
}) => {
  const [confirmModal, toggleConfirmModal] = useState<boolean>(false)
  const { deleteMeeting, loadingDelete } = useMeetingMutateNoContext()

  const projectDescription =
    meeting.calendlyResponse?.payload?.questions_and_answers?.[1]?.answer
  const location = meeting.calendlyResponse?.payload?.event?.location
  const link = meeting.metadata?.recording
  const linkPwd = meeting.metadata?.recordingPassword

  return (
    <StyledCard fluid>
      <Card.Content>
        <div className="flex" style={{ alignItems: 'center' }}>
          {meeting.staffMember?.picture ? (
            <Image avatar circular src={meeting.staffMember?.picture} />
          ) : (
            <FormLogo style={{ width: '28px', marginRight: '12px' }} />
          )}
          <p className="gray">
            {prospectMeetingType(meeting)}{' '}
            {meeting.calendlyResponse?.payload?.old_invitee?.is_reschedule
              ? 're'
              : ''}
            scheduled for
          </p>
          <div className="buttons">
            <ConsultationModal
              meeting={meeting}
              onCompleted={() => {
                refetch()
                notifySuccess('Consultation updated')
              }}
              prospect={prospect}
              trigger={<Button kind="solid" fontAwesomeIcon="pencil" />}
            />
            <Button
              kind="solid"
              color="blue"
              fontAwesomeIcon="trash"
              loading={loadingDelete}
              onClick={() => toggleConfirmModal(true)}
            />
          </div>
        </div>
        {meeting.startTime ? (
          <h2>{moment(meeting.startTime).format('MM/DD/YYYY hh:mm a')}</h2>
        ) : (
          'Waiting on Customer to Schedule'
        )}
        {location && (
          <p className="small gray">
            <b>Location: </b>
            {location}
          </p>
        )}
        {projectDescription && (
          <div className="description">
            <p className="caption" style={{ margin: '0' }}>
              Project description
            </p>
            <p className="gray">{projectDescription}</p>
          </div>
        )}
        {link && (
          <div className="flex" style={{ marginTop: '13px', gap: '14px' }}>
            <Button
              kind="solid"
              color="blue"
              text="Watch recording"
              onClick={() => window.open(link)}
            />
            {linkPwd && (
              <p className="small">
                Password: <span className="gray"> {linkPwd}</span>
              </p>
            )}
          </div>
        )}
      </Card.Content>
      <ConfirmationModal
        firstTitle="Are you sure you want to delete the consultation?"
        onCancel={() => toggleConfirmModal(false)}
        onConfirm={async () => {
          deleteMeeting({
            variables: {
              where: {
                id: meeting.id ?? '',
              },
            },
            onCompleted: refetch,
          })
          toggleConfirmModal(false)
        }}
        open={confirmModal}
      />
    </StyledCard>
  )
}

export default ConsultationCard

const StyledCard = styled(Card)`
  &&&& {
    background: ${Colors.white};
    border: none;
    border-radius: 1px;
    box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.07);
    padding: 0;

    img {
      height: 28px;
      margin-right: 12px;
      width: 28px;
    }

    div.buttons {
      display: flex;
      gap: 8px;
      position: absolute;
      right: 16px;
    }

    div.description {
      margin-top: 10px;
      background: ${Colors.gray50};
      text-align: left;
      padding: 16px;
    }
  }
`
