import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { List } from 'semantic-ui-react'

import { Badge } from 'components/shared/chip'
import { parseRooms } from 'context/project/utils'
import { Colors } from 'styles/app/system'
import { Project } from 'types/project'
import { getPaymentString, getProgressBadge } from 'views/utils'

export const ProjectProgressCellContent = ({
  project,
}: {
  project: Project
}) => {
  const paymentString = getPaymentString(project)
  const progressBadge = getProgressBadge(project)
  const { roomsUnarchived } = parseRooms(project)
  const date = project.derivedPlay?.substring(2, project.derivedPlay.length)

  return (
    <List style={{ display: 'grid', rowGap: '16px' }}>
      <List.Item
        icon={
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'credit-card']}
            style={{ color: Colors.gray400, marginRight: '4px' }}
          />
        }
        content={paymentString}
        style={{ display: 'flex', alignItems: 'center' }}
      />
      <List.Item
        content={
          progressBadge ? (
            <div
              style={{
                alignItems: 'center',
                columnGap: '6px',
                display: 'inline-flex',
              }}
            >
              <Badge color={progressBadge.color} name={progressBadge.name} />{' '}
              since {moment(date).format('MM/DD/YY')}
            </div>
          ) : null
        }
        icon={
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'clock']}
            style={{ color: Colors.gray400, marginRight: '4px' }}
          />
        }
        style={{ display: 'flex', alignItems: 'center' }}
      />
      <List.Item
        icon={
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'house-chimney-window']}
            style={{ color: Colors.gray400, marginRight: '4px' }}
          />
        }
        content={`${roomsUnarchived.length} room(s)`}
        style={{ display: 'flex', alignItems: 'center' }}
      />
    </List>
  )
}
