import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uniqBy } from 'lodash'
import moment from 'moment'
import { Button, Checkbox, Table } from 'semantic-ui-react'

import MultiMessageModal from 'components/admin/project/message/create-update-message-many-modal'
import SupplierOrderModal from 'components/admin/supplier-order/create-update-modal'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useAuth from 'context/auth/use'
import useShipmentMany from 'context/shipment/use-many'
import useShipmentMutateNoContext from 'context/shipment/use-mutate-no-context'
import * as shipmentUtils from 'context/shipment/utils'
import useSupplierOrderMutateMany from 'context/supplier-order/use-mutate-many'
import * as supplierOrderUtils from 'context/supplier-order/utils'
import { StyledIcon, StyledLabelAnyColor } from 'styles/admin/main'
import { Shipment } from 'types/shipment'
import { SupplierOrder } from 'types/supplier-order'

import ShipmentModal from './create-update-modal'
import { SupplierOrdersCellContents } from '../supplier-order/table-cell-content-many'

const ShipmentsTable = () => {
  const { isNobiliaOrderProcessor } = useAuth()
  const { shipments, refetch, queryFilters, setQueryFilters } =
    useShipmentMany()
  const { sortBy, sortDirection } = queryFilters
  const { deleteShipment, loadingDelete, loadingUpdate, updateShipment } =
    useShipmentMutateNoContext()
  const { loadingUpdateMany, updateManySupplierOrder } =
    useSupplierOrderMutateMany()

  const [shipmentToSyncOrderLogs, setShipmentToSyncOrderLogs] =
    useState<Shipment>()
  const [shipmentToArchive, setShipmentToArchive] = useState<Shipment>()
  const [shipmentToDelete, setShipmentToDelete] = useState<Shipment>()
  const [shipmentToEdit, setShipmentToEdit] = useState<Shipment>()
  const [shipmentToMessage, setShipmentToMessage] = useState<Shipment>()
  const [supplierOrderToEdit, setSupplierOrderToEdit] =
    useState<SupplierOrder>()
  const [supplierOrdersShow, setSupplierOrdersShow] = useState(true)

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortBy === 'reference' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'reference',
                sortDirection:
                  sortBy === 'reference' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Sequence
          </Table.HeaderCell>
          <Table.HeaderCell>Number</Table.HeaderCell>
          <Table.HeaderCell>Volume / Weight</Table.HeaderCell>
          <Table.HeaderCell>
            Orders
            <Checkbox
              checked={supplierOrdersShow}
              onChange={() => setSupplierOrdersShow(!supplierOrdersShow)}
              style={{ float: 'right' }}
              toggle
            />
          </Table.HeaderCell>
          <Table.HeaderCell>Port / Warehouse</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedStatus' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedStatus',
                sortDirection:
                  sortBy === 'derivedStatus' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Status
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {shipments.map((s: Shipment) => {
          const shipmentStatusSummary =
            shipmentUtils.getShipmentStatusSummary(s)
          return (
            <Table.Row key={s.id} warning={!s.confirmedAt}>
              <Table.Cell width={2}>
                <div>
                  <div>
                    <StyledLabelAnyColor background={s.color} />
                    <span>{s.reference || ''}</span>
                  </div>
                  <div>
                    {s.data?.flexportShipmentShareableUrl && (
                      <a
                        className="red"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        href={s.data?.flexportShipmentShareableUrl}
                        target="_blank"
                      >
                        {s.data?.flexportShipmentId || 'No Flexport ID'}
                        <FontAwesomeIcon
                          style={{ marginLeft: 5 }}
                          icon={['fal', 'arrow-up-right-from-square']}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell width={2}>{s.data?.shipmentNumber}</Table.Cell>
              <Table.Cell width={2}>
                {supplierOrderUtils.sumSupplierOrderVolumes(s.supplierOrders)}{' '}
                m3 /{' '}
                {supplierOrderUtils.sumSupplierOrderWeights(s.supplierOrders)}{' '}
                kg
              </Table.Cell>
              <Table.Cell width={3}>
                {supplierOrdersShow ? (
                  <SupplierOrdersCellContents
                    onSelectSupplierOrder={(id: string) =>
                      setSupplierOrderToEdit(
                        s.supplierOrders.find((so) => so.id === id),
                      )
                    }
                    supplierOrders={s.supplierOrders}
                  />
                ) : (
                  `${s.supplierOrders.length} Total Order(s), ${
                    s.supplierOrders.filter((so) => so.project).length
                  } FORM Order(s)`
                )}
              </Table.Cell>
              <Table.Cell width={2}>
                {s.data?.port || s.data?.receivingWarehouse
                  ? `${s.data?.port} / ${s.data?.receivingWarehouse}`
                  : ''}
              </Table.Cell>
              <Table.Cell width={2}>
                <div>{shipmentStatusSummary.currentStage}</div>
                <div style={{ marginBottom: '5px' }}>
                  {shipmentStatusSummary.currentStageDate}
                  <StyledIcon
                    style={{ marginLeft: '5px', height: '10px', width: '10px' }}
                    icon={[
                      'fal',
                      shipmentStatusSummary.currentStageConfirmedAt
                        ? 'check'
                        : 'question',
                    ]}
                    styled={{
                      inactive: !shipmentStatusSummary.currentStageConfirmedAt,
                    }}
                  />
                </div>
              </Table.Cell>
              <Table.Cell width={3}>
                <Button
                  floated="right"
                  icon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
                  onClick={() => setShipmentToDelete(s)}
                />
                {!s.archivedAt ? (
                  <Button
                    floated="right"
                    icon={<FontAwesomeIcon icon={['fal', 'archive']} />}
                    onClick={() => setShipmentToArchive(s)}
                    loading={loadingUpdate || loadingUpdateMany}
                  />
                ) : null}
                {queryFilters.includeProposed ? (
                  <Button
                    floated="right"
                    icon={<FontAwesomeIcon icon={['fal', 'ship']} />}
                    onClick={() => setShipmentToSyncOrderLogs(s)}
                    loading={loadingUpdate}
                  />
                ) : s.supplierOrders.length > 0 && !isNobiliaOrderProcessor ? (
                  <Button
                    floated="right"
                    icon={<FontAwesomeIcon icon={['fal', 'envelope']} />}
                    onClick={() => setShipmentToMessage(s)}
                  />
                ) : null}
                <Button
                  floated="right"
                  icon={<FontAwesomeIcon icon={['fal', 'eye']} />}
                  onClick={() => setShipmentToEdit(s)}
                  primary
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
        {isNobiliaOrderProcessor ? null : (
          <MultiMessageModal
            open={!!shipmentToMessage}
            onClose={() => setShipmentToMessage(undefined)}
            projects={uniqBy(
              shipmentToMessage?.supplierOrders
                .filter((so) => so.project)
                .map((so) => so.project) || [],
              'id',
            )}
            title={`Shipment ${shipmentToMessage?.reference}`}
          />
        )}

        <ShipmentModal
          open={!!shipmentToEdit}
          onClose={() => setShipmentToEdit(undefined)}
          refetch={refetch}
          shipmentId={shipmentToEdit?.id ?? ''}
        />
        <SupplierOrderModal
          onClose={() => setSupplierOrderToEdit(undefined)}
          open={!!supplierOrderToEdit}
          supplierOrderId={supplierOrderToEdit?.id ?? ''}
        />
        <ConfirmationModal
          firstTitle="Are you sure you want to delete the shipment?"
          loading={loadingDelete}
          onCancel={() => setShipmentToDelete(undefined)}
          onConfirm={async () => {
            await deleteShipment({
              variables: {
                where: {
                  id: shipmentToDelete?.id ?? '',
                },
              },
            })
            setShipmentToDelete(undefined)
          }}
          open={!!shipmentToDelete}
        />
        <ConfirmationModal
          firstTitle="Are you sure you want to sync order logs for this shipment?"
          loading={loadingUpdate}
          onCancel={() => setShipmentToSyncOrderLogs(undefined)}
          onConfirm={async () => {
            await updateShipment({
              variables: {
                where: { id: shipmentToSyncOrderLogs?.id ?? '' },
                data: {
                  data: {
                    ...shipmentToSyncOrderLogs?.data,
                    orderLogsAssignedAt: moment().format(),
                  },
                },
              },
            })
            setShipmentToSyncOrderLogs(undefined)
          }}
          open={!!shipmentToSyncOrderLogs}
        />
        <ConfirmationModal
          firstTitle="Are you sure you want to archive the shipment *and* mark all orders as delivered?"
          loading={loadingUpdate || loadingUpdateMany}
          onCancel={() => setShipmentToArchive(undefined)}
          onConfirm={async () => {
            if (shipmentToArchive) {
              // this is a special mutation that only updates deliveredAt dates
              await updateManySupplierOrder({
                variables: {
                  data: {
                    data: {
                      deliveryAtEstimated: moment().format('MM/DD/YYYY'),
                      deliveryAtActual: moment().format('MM/DD/YYYY'),
                    },
                  },
                  where: {
                    id: {
                      in: shipmentToArchive.supplierOrders.map(
                        (so) => so.id as string,
                      ),
                    },
                  },
                },
              })
              await updateShipment({
                variables: {
                  data: {
                    archivedAt: new Date(),
                  },
                  where: {
                    id: shipmentToArchive.id ?? '',
                  },
                },
              })
            }
            setShipmentToArchive(undefined)
          }}
          open={!!shipmentToArchive}
        />
      </Table.Body>
    </Table>
  )
}

export default ShipmentsTable
