import React, { useState } from 'react'

import { sortBy } from 'lodash'
import moment from 'moment'
import { DateInput } from 'semantic-ui-calendar-react'
import {
  Divider,
  Form,
  Grid,
  Label,
  Menu,
  Message,
  Tab,
} from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import CreateUpdateIssueModal from 'components/admin/project/issue/create-update-modal'
import IssuesTable from 'components/admin/project/issue/table'
import SaveModal from 'components/admin/save-modal'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useDelivery from 'context/delivery/use'
import useDeliveryMutate from 'context/delivery/use-mutate'
import useDeliveryMutateNoContext from 'context/delivery/use-mutate-no-context'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import { StyledForm } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { Delivery, DeliveryData } from 'types/delivery'
import { ProjectFileType } from 'types/project-file'
import * as viewUtils from 'views/utils'

const DeliveryTab = ({
  onComplete,
}: {
  onComplete: (createdId?: string) => void
}) => {
  const { project } = useProject()
  const {
    damagePhotos,
    damagesPresent,
    deliveryPalletes,
    delivery,
    refetch: refetchDelivery,
  } = useDelivery()
  const { createDelivery, loadingCreate } = useDeliveryMutateNoContext()

  const { loadingUpdate, updateDeliveryAndRefetch } = useDeliveryMutate()
  const initialState: Partial<NullableRecord<Delivery>> = {
    data: delivery?.data || {
      name: 'Delivery',
    },
    date: delivery?.date || null,
    supplierOrders: delivery?.supplierOrders || [],
  }
  const [state, setState] =
    useState<Partial<NullableRecord<Delivery>>>(initialState)
  const [openCreateIssueModal, setOpenCreateIssueModal] = useState(false)

  const onSave = async () => {
    let createdId = ''
    if (delivery) {
      await updateDeliveryAndRefetch({
        variables: {
          data: {
            data: state.data ?? {},
            date: state.date,
            supplierOrders: {
              set: state.supplierOrders?.map((so) => ({
                id: so.id ?? '',
              })),
            },
          },
          where: { id: delivery.id ?? '' },
        },
      })
    } else {
      const res = await createDelivery({
        variables: {
          data: {
            data: state.data as DeliveryData,
            date: state.date as string | Date,
            supplierOrders: {
              connect: viewUtils.mapOrEmptyArray(
                state.supplierOrders,
                (so) => ({
                  id: so.id ?? '',
                }),
              ),
            },
            project: { connect: { id: project.id } },
          },
        },
      })
      createdId = res.data?.createOneDelivery.id ?? ''
    }
    onComplete(createdId)
  }

  return (
    <>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={[
          {
            menuItem: 'Delivery Information',
            render: () => (
              <Grid padded>
                <Grid.Row>
                  <Grid.Column computer={6} tablet={16}>
                    <StyledForm>
                      <Form.Input
                        name="name"
                        label="Name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setState({
                            ...state,
                            data: {
                              ...state.data,
                              name: e.target.value,
                            },
                          })
                        }}
                        value={state.data?.name || ''}
                      />
                      <Form.Field>
                        <span>Supplier Orders</span>
                        <Form.Dropdown
                          fluid
                          multiple
                          options={project.supplierOrders
                            .filter(
                              (so) =>
                                !so.delivery ||
                                (delivery && so.delivery?.id === delivery?.id),
                            )
                            .map((so) => ({
                              key: so.id,
                              text: `${so.nobiliaOrderRef} (ACK${
                                so.nobiliaAckNumber || ''
                              })`,
                              value: so.id,
                            }))}
                          onChange={(_, { value }) =>
                            setState({
                              ...state,
                              supplierOrders: project.supplierOrders.filter(
                                (so) =>
                                  so.id && (value as string[])?.includes(so.id),
                              ),
                            })
                          }
                          placeholder="Supplier Orders"
                          selection
                          value={state.supplierOrders?.map((so) => so.id ?? '')}
                        />
                      </Form.Field>
                      <Form.Field>
                        <span>Delivery Date</span>
                        <StyledDateInputContainer>
                          <DateInput
                            closable
                            dateFormat={'MM/DD/YYYY'}
                            duration={0}
                            initialDate={moment().format('MM/DD/YYYY')}
                            minDate={moment()
                              .subtract(1, 'years')
                              .format('MM/DD/YYYY')}
                            name="date"
                            onChange={(
                              _: React.SyntheticEvent<HTMLElement, Event>,
                              { value }: { value: string },
                            ) => {
                              setState({
                                ...state,
                                date: moment(value, 'MM/DD/YYYY').isValid()
                                  ? moment(value, 'MM/DD/YYYY').toDate()
                                  : null,
                              })
                            }}
                            value={
                              state.date
                                ? moment(state.date).format('MM/DD/YYYY')
                                : ''
                            }
                          />
                        </StyledDateInputContainer>
                      </Form.Field>
                    </StyledForm>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ),
          },
          ...(delivery
            ? [
                {
                  menuItem: (
                    <Menu.Item key="uploads">
                      Uploads
                      {deliveryPalletes.length || damagePhotos.length ? (
                        <StyledLabel styled={{ red: !!damagePhotos.length }}>
                          Yes
                        </StyledLabel>
                      ) : null}
                    </Menu.Item>
                  ),
                  render: () => (
                    <Grid padded>
                      <Grid.Row>
                        <Grid.Column>
                          <h4>Issue Photos</h4>
                        </Grid.Column>
                      </Grid.Row>
                      <ProjectFileUploads
                        deliveryId={delivery.id}
                        includeDownloadAndCarousel
                        onDownload={async () =>
                          await updateDeliveryAndRefetch({
                            variables: {
                              data: {
                                data: {
                                  ...delivery.data,
                                  damagePhotosDownloadedAt: new Date(),
                                },
                              },
                              where: {
                                id: delivery.id ?? '',
                              },
                            },
                          }).then(({ data }) => {
                            window.open(
                              data?.updateOneDelivery?.data?.damagePhotosZipUrl,
                            )
                          })
                        }
                        projectFiles={damagePhotos}
                        projectFileType={ProjectFileType.DAMAGE_PHOTO}
                        projectId={project.id}
                        refetch={refetchDelivery}
                      />
                      <Divider />
                      <Grid.Row>
                        <Grid.Column>
                          <h4>Delivery Uploads</h4>
                        </Grid.Column>
                      </Grid.Row>
                      <ProjectFileUploads
                        deliveryId={delivery.id}
                        includeDownloadAndCarousel
                        onDownload={async () =>
                          await updateDeliveryAndRefetch({
                            variables: {
                              data: {
                                data: {
                                  ...delivery.data,
                                  palletesDownloadedAt: new Date(),
                                },
                              },
                              where: {
                                id: delivery.id ?? '',
                              },
                            },
                          }).then(({ data }) => {
                            window.open(
                              data?.updateOneDelivery?.data?.palletesZipUrl,
                            )
                          })
                        }
                        projectFiles={deliveryPalletes}
                        projectFileType={ProjectFileType.DELIVERY_PALLETE}
                        projectId={project.id}
                        refetch={refetchDelivery}
                      />
                    </Grid>
                  ),
                },
              ]
            : []),
          ...(delivery
            ? [
                {
                  menuItem: (
                    <Menu.Item key="issues">
                      Issues
                      {damagesPresent ? (
                        <StyledLabel styled={{ red: true }}>Yes</StyledLabel>
                      ) : null}
                    </Menu.Item>
                  ),
                  render: () => (
                    <Grid padded>
                      <Grid.Row>
                        <Grid.Column width={6}>
                          <Message compact>
                            <Form.Checkbox
                              label="Issues Exist"
                              checked={
                                delivery.data?.damagesSelection === 'damaged'
                              }
                              onChange={() => {
                                setState({
                                  ...delivery,
                                  data: {
                                    ...state?.data,
                                    damagesSelection:
                                      state.data?.damagesSelection === 'damaged'
                                        ? 'no-damage'
                                        : 'damaged',
                                  },
                                })
                              }}
                            />
                          </Message>
                        </Grid.Column>
                      </Grid.Row>
                      <Divider />
                      <Grid.Row>
                        <Grid.Column textAlign="right">
                          <Button
                            kind="solid"
                            fontAwesomeIcon="plus"
                            text="Create Issue"
                            onClick={() => setOpenCreateIssueModal(true)}
                          />
                          <CreateUpdateIssueModal
                            open={openCreateIssueModal}
                            onClose={() => setOpenCreateIssueModal(false)}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <IssuesTable
                            issues={delivery.issues}
                            mode="enableEdit"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ),
                },
              ]
            : []),
        ]}
      />
      <SaveModal
        currentState={{
          ...state,
          supplierOrders: sortBy(state.supplierOrders?.map((so) => so.id)),
        }}
        initialState={{
          ...initialState,
          supplierOrders: sortBy(
            initialState.supplierOrders?.map((so) => so.id),
          ),
        }}
        loadingSave={loadingCreate || loadingUpdate}
        onDiscard={onComplete}
        onSave={onSave}
      />
    </>
  )
}

export default DeliveryTab

const StyledDateInputContainer = styled.div`
  &&& {
    thead {
      text-align: center !important;
    }
  }
`

const StyledLabel = styled(Label)<{
  styled?: { red?: boolean }
}>`
  &&&&&&& {
    background: ${Colors.green900} !important;
    color: #ffffff !important;
    padding: 0.4em 0.6em !important;

    ${({ styled }) =>
      styled?.red &&
      css`
        background: ${Colors.red700} !important;
      `}
  }
`
