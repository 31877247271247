import { FindManyProjectVariables } from 'queries/project'
import { ProjectType } from 'types/project'
import { ProjectStaffRole } from 'types/project-staff'

export interface QueryFilters {
  // staff assigned
  assignedTo?: {
    id: string
    name?: string
  } | null
  assignedToRole?: ProjectStaffRole | null

  // project type
  projectType?: ProjectType | null
  paymentStatus?: string | null
  progress?: string | null

  companyId?: string | null
  ownerEmail?: string | null

  // owner search
  search?: string

  status?: 'open' | 'closed' | 'all'

  // how many to take
  take: number

  // sort values
  sortBy?: 'derivedActivity' | 'derivedPlay'
  sortDirection?: 'descending' | 'ascending'
}

export const getQueryVariables = ({
  assignedTo,
  assignedToRole,
  companyId,
  paymentStatus,
  progress,
  projectType,
  search,
  sortBy,
  sortDirection,
  status,
  take,
  ownerEmail,
}: QueryFilters): FindManyProjectVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'derivedActivity': {
        return {
          derivedActivity: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      case 'derivedPlay': {
        return {
          derivedPlay: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManyProjectVariables = {
    orderBy,
    take,
  }
  if (search) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (assignedTo?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        staff: {
          some: {
            staffMemberId: {
              equals: assignedTo.id,
            },
            isSubscribed: {
              equals: true,
            },
            ...(assignedToRole && {
              staffMemberRole: {
                equals: assignedToRole,
              },
            }),
          },
        },
      },
    }
  }
  if (companyId) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        companyId: {
          equals: companyId,
        },
      },
    }
  }
  if (ownerEmail) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        owners: {
          some: {
            user: {
              email: {
                contains: ownerEmail,
              },
            },
          },
        },
      },
    }
  }
  if (status === 'open') {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        archivedAt: null,
      },
    }
  }
  if (status === 'closed') {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        archivedAt: { not: null },
      },
    }
  }
  if (paymentStatus) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedPayment: {
          in: [paymentStatus],
        },
      },
    }
  }
  if (progress) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedPlay: {
          startsWith: progress,
        },
      },
    }
  }
  if (projectType) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        data: {
          path: ['projectType'],
          equals: projectType,
        },
      },
    }
  }
  return variables
}
