import { SelectionCenter } from 'types/selection-center'
import { User, UserTypeStaff } from 'types/user'

export type SelectionCenterStaff = {
  createdAt?: Date | string
  updatedAt?: Date | string
  staffMemberRole?: SelectionCenterStaffRole
  staffMember?: User
  staffMemberId?: string
  selectionCenter?: SelectionCenter
  selectionCenterId?: string
  isActive?: Date | string | null
}

export enum SelectionCenterStaffRole {
  MANAGER = UserTypeStaff.SELECTION_CENTER_MANAGER,
  ADMIN = UserTypeStaff.ADMIN,
  SALES_DESIGNER = UserTypeStaff.SALES_DESIGNER,
}
