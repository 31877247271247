import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import {
  FIND_MANY_COMMENT_NOTE,
  FindManyCommentNotePayload,
  FindManyCommentNoteVariables,
} from 'queries/comment-notes'

import { QueryFilters, getQueryVariables } from './utils-many'

const useCommentNoteCount = (queryFilters: QueryFilters) => {
  const queryVariables = useMemo(
    () => getQueryVariables(queryFilters),
    [queryFilters],
  )
  const {
    data: { count } = { count: 0 },
    loading,
    refetch,
  } = useQuery<FindManyCommentNotePayload, FindManyCommentNoteVariables>(
    FIND_MANY_COMMENT_NOTE,
    {
      variables: queryVariables,
    },
  )

  return {
    count,
    loading,
    refetch,
  }
}

export default useCommentNoteCount
