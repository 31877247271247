import React, { useState } from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import FiltersAndSearch from 'components/admin/filters-search'
import { BackToTop } from 'components/shared/load-buttons'
import useAuth from 'context/auth/use'
import { useNobiliaSampleClassMany } from 'context/nobilia-sample-class/provider-many'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'

import CreateUpdateNobiliaSampleClassModal from './create-update-modal'
import { NobiliaSampleClassTable } from './table'
import useNobiliaSampleClassesFiltersSearch from './use-filters-search'

const NobiliaSampleClasses = () => {
  const { isAdmin } = useAuth()
  const [nobiliaSampleToEdit, setNobiliaSampleToEdit] =
    useState<NobiliaSampleClass | null>(null)
  const {
    count,
    fetchedCount,
    fetchMore,
    loading,
    loadingMore,
    nobiliaSampleClasses,
  } = useNobiliaSampleClassMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useNobiliaSampleClassesFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />

      <Grid.Row
        columns="equal"
        style={{ padding: '10px 0 0 0' }}
        verticalAlign="middle"
      >
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${fetchedCount} of ${count}`} Nobilia
            Samples
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          {isAdmin && (
            <CreateUpdateNobiliaSampleClassModal
              nobiliaSampleClass={nobiliaSampleToEdit}
              setNobiliaSampleClass={setNobiliaSampleToEdit}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => {
              calculations.bottomVisible &&
                fetchedCount < count &&
                !loadingMore &&
                !loading &&
                fetchMore()
            }}
          >
            <NobiliaSampleClassTable
              handleEdit={isAdmin ? setNobiliaSampleToEdit : undefined}
            />
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && nobiliaSampleClasses?.length > 0 && <BackToTop />}
    </Grid>
  )
}

export default NobiliaSampleClasses
