import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import useAuth from 'context/auth/use'
import Button from 'design-system/components/button'

const DisplayNameEditable = ({
  category,
  description,
  name,
  onSave,
}: {
  category?: string
  description?: string
  name: string
  onSave: (newDisplayName: string) => void
}) => {
  const [isEditable, setEditable] = useState<boolean>(false)
  const [displayName, setNewDisplayName] = useState<string>(name)
  const { isAdmin } = useAuth()
  useEffect(() => {
    setNewDisplayName(name)
  }, [name])

  return (
    <StyledDisplayName>
      {category && <p>{category}</p>}
      {isEditable ? (
        <div>
          <h3>
            <Form.Input
              value={displayName}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                setNewDisplayName(value)
              }
            />
          </h3>
          <Button
            kind="solid"
            fontAwesomeIcon="save"
            onClick={() => {
              onSave(displayName)
              setEditable(false)
            }}
            text="Save"
          />
          <Button
            kind="solid"
            fontAwesomeIcon="times"
            color="blue"
            onClick={() => {
              setNewDisplayName(name)
              setEditable(false)
            }}
            text="Cancel"
          />
        </div>
      ) : (
        <div>
          <h3>{name}</h3>
          <Button
            kind="ghost"
            fontAwesomeIcon="pencil"
            disabled={!isAdmin}
            onClick={() => {
              setEditable(true)
            }}
          />
        </div>
      )}
      {description && <p>{description}</p>}
    </StyledDisplayName>
  )
}

export default DisplayNameEditable

const StyledDisplayName = styled.div`
  div {
    align-items: center;
    display: flex;
    gap: 12px;
  }

  h3 {
    margin: 0;
  }

  input {
    font: inherit !important;
    font-weight: 100 !important;
    min-height: 55px;
  }
`
