import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import {
  FORMStatus,
  SupplierOrderType,
} from 'context/supplier-order/utils-many'
import {
  FIND_MANY_SUPPLIER_ORDER_COUNT,
  FindManySupplierOrderVariables,
  FindManySupplierOrderCountPayload,
} from 'queries/supplier-order'

import { getQueryVariables } from './utils-many'

const useRegularCounts = (userId?: string) => {
  const variablesEDINotSent = useMemo(
    () =>
      getQueryVariables({
        type: [SupplierOrderType.Regular],
        assignedTo: { id: userId ?? '' },
        statusesFORM: [FORMStatus.EDINotSent],
        take: undefined,
      }),
    [userId],
  )

  const variablesNobilia = useMemo(
    () =>
      getQueryVariables({
        type: [SupplierOrderType.Regular],
        assignedTo: { id: userId ?? '' },
        statusesFORM: [FORMStatus.AwaitingNobilia],
        take: undefined,
      }),
    [userId],
  )

  const variablesFORM = useMemo(
    () =>
      getQueryVariables({
        type: [SupplierOrderType.Regular],
        assignedTo: { id: userId ?? '' },
        statusesFORM: [FORMStatus.AwaitingFORM],
        take: undefined,
      }),
    [userId],
  )

  const {
    data: { count: sosEDINotSentCount } = { count: 0 },
    loading: loadingSosEDINotSentCount,
    refetch: refetchSosEDINotSentCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesEDINotSent,
  })

  const {
    data: { count: sosAwaitingNobiliaCount } = { count: 0 },
    loading: loadingSosAwaitingNobiliaCount,
    refetch: refetchSosAwaitingNobiliaCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesNobilia,
  })

  const {
    data: { count: sosAwaitingFORMCount } = { count: 0 },
    loading: loadingSosAwaitingFORMCount,
    refetch: refetchSosAwaitingFORMCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesFORM,
  })

  const refetchAllCounts = async () => {
    await refetchSosEDINotSentCount()
    await refetchSosAwaitingFORMCount()
    await refetchSosAwaitingNobiliaCount()
  }

  return {
    loadingSosEDINotSentCount,
    loadingSosAwaitingNobiliaCount,
    loadingSosAwaitingFORMCount,
    refetchAllCounts,
    sosEDINotSentCount,
    sosAwaitingNobiliaCount,
    sosAwaitingFORMCount,
  }
}

export default useRegularCounts
