import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Table } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import Loader from 'components/shared/loader'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import useZohoBulkImport from 'context/zoho-bulk-import/use'
import Button, { ButtonTooltip } from 'design-system/components/button'

import NobiliaOrderProcessorLayout from './layout'

const NobiliaZohoBulkImport = (_: RouteComponentProps) => {
  return (
    <NobiliaOrderProcessorLayout>
      <OrderLogsTable />
    </NobiliaOrderProcessorLayout>
  )
}

const OrderLogsTable = () => {
  const {
    bulkImportOrderLogs,
    importingOrderLogs,
    fetchOrderLogs,
    fetchingOrderLogs,
    rawOrderLogs,
    parseRawOrderLogToZohoApiStructure,
  } = useZohoBulkImport()

  const handleBulkImport = async () => {
    const { data } = await bulkImportOrderLogs({
      variables: {
        data: {
          orderLogs: rawOrderLogs.map(parseRawOrderLogToZohoApiStructure),
        },
      },
    })

    if (!data?.bulkImportOrderLogs) {
      return notifyError(
        'There was an error. Try again. If the error persists, please report it.',
      )
    }

    notifySuccess(
      'Done! Eligible order logs have been updated. You can verify this directly on Zoho.',
    )
  }

  useEffect(() => {
    fetchOrderLogs()
  }, [fetchOrderLogs])

  return (
    <>
      {!fetchingOrderLogs ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {rawOrderLogs.length ? (
            <div style={{ maxWidth: 'fit-content' }}>
              <HelpMessage content="IMPORTANT: You are only seeing eligible order logs for bulk import." />
            </div>
          ) : null}
          <ButtonTooltip
            tooltipProps={{ label: 'Sync Order Logs', placement: 'left' }}
            kind="ghost"
            fontAwesomeIcon="sync"
            onClick={() => fetchOrderLogs()}
          />
        </div>
      ) : null}

      <Table celled collapsing className="small" style={{ width: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Order Log ID</Table.HeaderCell>
            <Table.HeaderCell>Factory Ackn.No.</Table.HeaderCell>
            <Table.HeaderCell>1st Attempt call to end-point</Table.HeaderCell>
            <Table.HeaderCell>1st Attempt-Outcome</Table.HeaderCell>
            <Table.HeaderCell>
              Date the final mile contact committed to delivery
            </Table.HeaderCell>
            <Table.HeaderCell>Date agreed for delivery</Table.HeaderCell>
            <Table.HeaderCell>Storage Start Date</Table.HeaderCell>
            <Table.HeaderCell>Months in Storage</Table.HeaderCell>
            <Table.HeaderCell>Scheduling Notes</Table.HeaderCell>
            <Table.HeaderCell>
              Final mile delivered to final destination
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!fetchingOrderLogs ? (
            rawOrderLogs.length ? (
              rawOrderLogs.map((o) => {
                return (
                  <Table.Row key={o.zohoId}>
                    <Table.Cell>{o.zohoId ?? null}</Table.Cell>
                    <Table.Cell>{o.ackNumber ?? null}</Table.Cell>
                    <Table.Cell>
                      {o.firstAttemptCallToEndPoint ?? null}
                    </Table.Cell>
                    <Table.Cell>{o.firstAttemptCallOutcome ?? null}</Table.Cell>
                    <Table.Cell>
                      {o.dateFinalMileContactCommittedToDelivery ?? null}
                    </Table.Cell>
                    <Table.Cell>{o.dateAgreedForDelivery ?? null}</Table.Cell>
                    <Table.Cell>{o.storageStartDate ?? null}</Table.Cell>
                    <Table.Cell>{o.monthsInStorage ?? null}</Table.Cell>
                    <Table.Cell>{o.schedulingNotes ?? null}</Table.Cell>
                    <Table.Cell>
                      {o.finalMileDeliverdToFinalDestination ?? null}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            ) : (
              <Table.Row>
                <Table.Cell
                  colSpan={10}
                  collapsing
                  style={{ textAlign: 'center' }}
                >
                  There are no valid order logs for import.
                </Table.Cell>
              </Table.Row>
            )
          ) : (
            <Table.Row>
              <Table.Cell colSpan={10} collapsing>
                <Loader flex fullScreen={false} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

        {!fetchingOrderLogs && rawOrderLogs.length ? (
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.Cell colSpan={10} style={{ textAlign: 'right' }}>
                <Button
                  text="Import Order Logs"
                  loading={importingOrderLogs}
                  kind="solid"
                  onClick={handleBulkImport}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        ) : null}
      </Table>
    </>
  )
}

export default NobiliaZohoBulkImport
