import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Container, Divider, Grid, Label, Placeholder } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import { ProjectOwnersCellContents } from 'components/admin/project/project-owners/table-cell-content'
import { ProjectStaffCellContents } from 'components/admin/project/project-staff/table-cell-content'
import Loader from 'components/shared/loader'
import { getAddressString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import DesignProvider from 'context/design/provider'
import useDesign from 'context/design/use'
import useDesignLookup from 'context/design/use-lookup'
import useDesignMutate from 'context/design/use-mutate'
import ProjectProvider from 'context/project/provider'
import useProject from 'context/project/use'
import RoomProvider from 'context/room/provider'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import {
  GET_CURRENT_ARCHIVIZER_PROJECT,
  GetCurrentArchivizerProjectPayload,
} from 'queries/archivizer'

import DesignAssets from './design/assets'
import DesignCustomerUploads from './design/customer-uploads'
import DesignGallery from './design/gallery'
import DesignRenders from './design/renders'

const DesignForArtist = (_: RouteComponentProps<{ design_id: string }>) => {
  const { isAdmin } = useAuth()

  const { design } = useDesign()
  const { formattedType, room } = useRoom()
  const { primaryDesigner, project } = useProject()

  const zipFileUrl = design?.metadata?.zipUrlForArtist

  return (
    <Container>
      <Divider hidden />
      <h4>{formattedType}</h4>
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <ProjectOwnersCellContents project={project} />
            {project.projectAddress && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FontAwesomeIcon
                  icon={['fal', 'location-dot']}
                  style={{ padding: '9px' }}
                />
                {getAddressString(project.projectAddress)}
              </div>
            )}
          </Grid.Column>
          <Grid.Column>
            {primaryDesigner && <ProjectStaffCellContents project={project} />}
            {isAdmin && (
              <Button
                color="blue"
                kind="solid"
                onClick={() =>
                  window.open(
                    `/admin/projects/${project.id}/rooms/${room?.id}/designs/${design?.id}`,
                  )
                }
                text="View design"
              />
            )}
          </Grid.Column>
          <Grid.Column>
            <div style={{ backgroundColor: 'white', padding: '20px' }}>
              <p>Status</p>
              <RenderStatus isAdmin={isAdmin} />
            </div>
          </Grid.Column>
        </Grid.Row>
        {zipFileUrl && (
          <Grid.Row>
            <Grid.Column>
              <Button
                color="blue"
                kind="solid"
                onClick={() =>
                  window.open(
                    `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                      zipFileUrl,
                    )}`,
                  )
                }
                text="Download zip with all files"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <DesignCustomerUploads />
        <DesignAssets />
        <DesignRenders />
        <DesignGallery />
      </Grid>
    </Container>
  )
}

const ArchivizerTaskTarget = () => {
  const { loading, data } = useQuery<GetCurrentArchivizerProjectPayload>(
    GET_CURRENT_ARCHIVIZER_PROJECT,
  )

  if (loading)
    return (
      <Placeholder>
        <Placeholder.Line length="short" />
      </Placeholder>
    )

  if (!data?.currentProject) {
    return (
      <Label
        content="Something went wrong! Couldn't find project."
        size="large"
        style={{ marginBottom: '8px' }}
        color="red"
      />
    )
  }

  return (
    <a
      href={`https://archivizer.com/projects/${data.currentProject.id}`}
      target="_blank"
    >
      {data.currentProject.name}
    </a>
  )
}

const RenderStatus = ({ isAdmin }: { isAdmin: boolean }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { design } = useDesign()
  const { updateDesignAndRefetch, loadingUpdate } = useDesignMutate()

  if (design?.sentToArchAt) {
    return (
      <p className="caption">
        <p>Sent {moment(design.sentToArchAt).format('LLL')}</p>
        <p>
          {design.dataArchivizer?.archTaskId ? (
            <a
              href={`https://archivizer.com/tasks/${design.dataArchivizer?.archTaskId}`}
              style={{ textDecoration: 'underline' }}
              target="_blank"
            >
              Archivizer Task
            </a>
          ) : (
            <b>Processing request</b>
          )}
        </p>
      </p>
    )
  }
  if (!isAdmin) {
    return <p className="caption">Awaiting admin approval</p>
  }
  return (
    <Modal
      onClose={() => setModalOpen(false)}
      open={modalOpen}
      size="small"
      title="Confirm send to Archivizer (Admins Only)"
      saveButton={{
        text: 'Send to Archivizer',
        onClick: async () => {
          if (!design) return
          await updateDesignAndRefetch({
            variables: {
              data: {
                sentToArchAt: new Date(),
              },
              where: {
                id: design.id,
              },
            },
          })
          setModalOpen(false)
        },
        loading: loadingUpdate,
      }}
      trigger={
        <Button
          kind="solid"
          text="Send to Archivizer"
          onClick={() => setModalOpen(true)}
        />
      }
    >
      <p>This will do the following in their system:</p>
      <div>
        <ul>
          <li>Create a task</li>
          <li>Upload this design information</li>
          <li>Upload the render props (including appliance props)</li>
          <li>Upload initial render notes</li>
          <li>Upload any visual notes</li>
        </ul>
      </div>
      <p>The task will be created in the following project:</p>
      <div style={{ marginBottom: '1em' }}>
        <ArchivizerTaskTarget />
      </div>
      <p>
        Ready to go? Buckle up, this might take a bit. We will notify you when
        the process is finished.
      </p>
    </Modal>
  )
}

export default (props: RouteComponentProps<{ design_id: string }>) => {
  const { design, loading } = useDesignLookup(props.design_id)

  return (
    <AdminLayout>
      {loading ? (
        <Loader />
      ) : (
        <ProjectProvider project_id={design?.room.project.id}>
          <RoomProvider room_id={design?.room.id}>
            <DesignProvider designId={design?.id ?? ''}>
              <DesignForArtist {...props} />
            </DesignProvider>
          </RoomProvider>
        </ProjectProvider>
      )}
    </AdminLayout>
  )
}
