import React, { createContext } from 'react'

import Loader from 'components/shared/loader'
import useMeetingMany from 'context/dashboard/use-meeting-many'
import useMessageMany from 'context/dashboard/use-message-many'
import useProjectMany from 'context/dashboard/use-project-many'
import useTaskMany from 'context/dashboard/use-task-many'
import { Meeting } from 'types/meeting'
import { Message } from 'types/message'
import { Project } from 'types/project'
import { Task } from 'types/task'

interface ICustomerSupportContext {
  meetings: Meeting[]
  messages: Message[]
  projects: Project[]
  refetchMessages: () => Promise<unknown>
  refetchProjects: () => Promise<unknown>
  refetchTasks: () => Promise<unknown>
  tasks: Task[]
  userId: string
}

type CustomerSupportProps = React.PropsWithChildren<{ user_id: string }>

export const CustomerSupportContext = createContext<ICustomerSupportContext>(
  {} as ICustomerSupportContext,
)

const CustomerSupportProvider = (props: CustomerSupportProps) => {
  const { loading: loadingMeetings, meetings } = useMeetingMany(props.user_id)
  const {
    loading: loadingMessages,
    messages,
    refetch: refetchMessages,
  } = useMessageMany(props.user_id)
  const {
    projects,
    loading: loadingProjects,
    refetch: refetchProjects,
  } = useProjectMany(props.user_id)

  const {
    tasks,
    loading: loadingTasks,
    refetch: refetchTasks,
  } = useTaskMany(props.user_id)

  if (loadingProjects || loadingTasks || loadingMeetings || loadingMessages)
    return <Loader />

  return (
    <CustomerSupportContext.Provider
      value={{
        meetings,
        messages,
        projects: projects ?? [],
        refetchMessages,
        refetchProjects,
        refetchTasks,
        tasks,
        userId: props.user_id,
      }}
    >
      {props.children}
    </CustomerSupportContext.Provider>
  )
}

export default CustomerSupportProvider
