import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import { DateInput } from 'semantic-ui-calendar-react'
import { Form, Grid, Message, Tab } from 'semantic-ui-react'

import ColorPicker from 'components/admin/color-picker'
import SaveModal from 'components/admin/save-modal'
import SupplierOrdersPlanning from 'components/admin/supplier-order/index-many-planning'
import useRoute from 'context/route/use'
import useRouteMutate from 'context/route/use-mutate'
import { parseSupplierOrders, RouteTypeOptions } from 'context/route/utils'
import { WarehouseOptions } from 'context/shipment/utils'
import SupplierOrderManyProvider from 'context/supplier-order/provider-many'
import { Route, RouteData, RouteType } from 'types/route'
import { SupplierOrder } from 'types/supplier-order'
import * as viewUtils from 'views/utils'

const RouteTab = ({
  onComplete,
  supplierOrdersInitial,
}: {
  onComplete: () => void
  supplierOrdersInitial: SupplierOrder[]
}) => {
  const { route } = useRoute()
  const {
    createRouteAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateRouteAndRefetch,
  } = useRouteMutate()
  const initialState: NullableRecord<Route> = {
    id: route?.id || '',
    color: route?.color || '',
    confirmedAt: route?.confirmedAt || null,
    data: route?.data || {},
    reference: route?.reference || '',
    supplierOrders: route?.supplierOrders || supplierOrdersInitial || [],
  }
  const [state, setState] = useState<NullableRecord<Route>>(initialState)

  useEffect(() => {
    setState({
      id: route?.id || '',
      color: route?.color || '',
      confirmedAt: route?.confirmedAt || null,
      data: route?.data || {},
      reference: route?.reference || '',
      supplierOrders: route?.supplierOrders || supplierOrdersInitial || [],
    })
  }, [route, supplierOrdersInitial])

  const onSave = async () => {
    if (route?.id) {
      await updateRouteAndRefetch({
        variables: {
          data: {
            color: state.color as string,
            confirmedAt: state.confirmedAt as Date,
            data: state.data as RouteData,
            reference: state.reference as string,
          },
          where: { id: route.id },
        },
      })
    } else {
      await createRouteAndRefetch({
        variables: {
          data: {
            color: state.color ?? undefined,
            confirmedAt: state.confirmedAt ?? undefined,
            data: state.data ?? {},
            reference: state.reference ?? undefined,
            supplierOrders: {
              connect:
                viewUtils.mapOrEmptyArray(state.supplierOrders, (o) => ({
                  id: o.id as string,
                })) ?? [],
            },
          },
        },
      })
    }
    onComplete()
  }

  const { supplierOrders, volumeM3, weightKg } = useMemo(
    () => parseSupplierOrders(state),
    [state.supplierOrders],
  )

  return (
    <>
      <Tab
        panes={[
          {
            menuItem: 'Information',
            render: () => (
              <Tab.Pane>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Form
                        onSubmit={(e) => e.preventDefault()}
                        style={{ margin: '0' }}
                      >
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Load</label>
                            <div style={{ height: '10px' }} />
                            {supplierOrders?.length} Orders ({volumeM3} m3,{' '}
                            {weightKg} kg)
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Route Color</label>
                            <div style={{ height: '10px' }} />
                            <ColorPicker
                              color={state.color ?? undefined}
                              onChangeColor={(hex: string) => {
                                setState({
                                  ...state,
                                  color: hex,
                                })
                              }}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Route Confirmed</label>
                            <Message>
                              <Form.Checkbox
                                label={
                                  <label>
                                    <b>Confirmed</b>
                                  </label>
                                }
                                checked={!!state.confirmedAt}
                                onChange={() => {
                                  setState({
                                    ...state,
                                    confirmedAt: state.confirmedAt
                                      ? null
                                      : new Date(),
                                  })
                                }}
                              />
                            </Message>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Reference</label>
                            <Form.Input
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) => {
                                setState({
                                  ...state,
                                  reference: value,
                                })
                              }}
                              value={state.reference || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Type</label>
                            <Form.Dropdown
                              clearable
                              name="type"
                              onChange={(_, { value }) => {
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    type: value as RouteType,
                                  },
                                })
                              }}
                              options={RouteTypeOptions}
                              placeholder="Route Type"
                              selection
                              value={state.data?.type || undefined}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Carrier</label>
                            <Form.Input
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) => {
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    carrier: value,
                                  },
                                })
                              }}
                              value={state.data?.carrier || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Receiving Warehouse</label>
                            <Form.Dropdown
                              onChange={(_, { value }) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    receivingWarehouse: value as string,
                                  },
                                })
                              }
                              options={WarehouseOptions}
                              placeholder="Receiving Warehouse"
                              search
                              selection
                              value={state.data?.receivingWarehouse || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form
                        onSubmit={(e) => e.preventDefault()}
                        style={{ margin: '0' }}
                      >
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Dispatch Date Estimated</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    dispatchedAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.dispatchedAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Dispatch Date Actual</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    dispatchedAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.dispatchedAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Completion Date Estimated</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    completedAtEstimated: value,
                                  },
                                })
                              }
                              value={state.data?.completedAtEstimated || ''}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Completion Date Actual</label>
                            <DateInput
                              closable
                              dateFormat={'MM/DD/YYYY'}
                              duration={0}
                              initialDate={moment().format('MM/DD/YYYY')}
                              onChange={(
                                _: React.SyntheticEvent<HTMLElement, Event>,
                                { value }: { value: string },
                              ) =>
                                setState({
                                  ...state,
                                  data: {
                                    ...state.data,
                                    completedAtActual: value,
                                  },
                                })
                              }
                              value={state.data?.completedAtActual || ''}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field
                            control={TextareaAutosize}
                            label={<label>Notes</label>}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              setState({
                                ...state,
                                data: {
                                  ...state.data,
                                  notes: e.target.value,
                                },
                              })
                            }
                            placeholder="Notes"
                            value={state.data?.notes || ''}
                          />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          },
          ...(route
            ? [
                {
                  menuItem: 'Orders',
                  render: () => (
                    <div style={{ padding: '30px' }}>
                      <SupplierOrderManyProvider
                        defaultFilters={{
                          includeThirdParty: true,
                          routes: [route.id as string],
                          sortBy: 'derivedStatus',
                          sortDirection: 'ascending',
                          take: 100,
                        }}
                        skipLoader
                      >
                        <SupplierOrdersPlanning />
                      </SupplierOrderManyProvider>
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
      <SaveModal
        currentState={state}
        initialState={initialState}
        loadingSave={loadingCreate || loadingUpdate}
        onDiscard={onComplete}
        onSave={onSave}
      />
    </>
  )
}

export default RouteTab
