import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Divider, Grid, Image, List, Table } from 'semantic-ui-react'

import { ProjectPageTitle } from 'components/admin/page-title'
import AddressFormat from 'components/shared/address-format'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { formatDollars } from 'context/dollar-utils'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import * as orderUtils from 'context/order/utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import useProjectOrderSamplesMany from 'context/project/order-samples/use-many'
import useProjectOrderSamplesManyMutate from 'context/project/order-samples/use-many-mutate'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import { Order } from 'types/order'
import * as viewUtils from 'views/utils'
import { TableCellWithCheckProps } from 'views/utils'

const Orders = (_: RouteComponentProps<{ project_id: string }>) => {
  const { project, refetch } = useProject()
  const { orders } = useProjectOrderSamplesMany()
  const { deleteOrderAndRefetch } = useProjectOrderSamplesManyMutate()
  const [orderToDelete, setOrderToDelete] = useState<Order>()

  const handleCopyURL = async (orderId = '') => {
    try {
      await viewUtils.copyToClipboard(
        `${process.env.GATSBY_HOME_URL}/_fk/sample-orders/${orderId}`,
      )
      notifySuccess('URL Copied to Clipboard')
    } catch (error) {
      notifyError('Unable to copy to clipboard. Please, try again.')
    }
  }

  return (
    <>
      <ProjectPageTitle viewName="Samples Orders" />
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <h5>Samples Orders</h5>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              kind="solid"
              text="Create New Samples Order"
              onClick={() =>
                navigate(`/admin/projects/${project.id}/sample-orders/create`)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Samples</Table.HeaderCell>
                  <Table.HeaderCell>Shipping Address</Table.HeaderCell>
                  <Table.HeaderCell>Total Before Tax</Table.HeaderCell>
                  <Table.HeaderCell>Ordered</Table.HeaderCell>
                  <Table.HeaderCell>Shipped</Table.HeaderCell>
                  <Table.HeaderCell>Public Link</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {orders.map((o) => {
                  const orderStatus = orderUtils.getSamplesOrderStatus(o)
                  return (
                    <Table.Row key={o.id}>
                      <Table.Cell width={4} textAlign="left">
                        <List>
                          {o.nobiliaSamples?.map((p, k) => {
                            const product =
                              checkoutUtils.nobiliaSampleToPaymentItem(p)
                            return (
                              <List.Item key={k}>
                                <List.Icon>
                                  <Image
                                    src={product.imageUrl}
                                    style={{ margin: '0', maxWidth: '20px' }}
                                  />
                                </List.Icon>
                                <List.Content verticalAlign="middle">
                                  <p>{product.name}</p>
                                </List.Content>
                              </List.Item>
                            )
                          })}
                        </List>
                      </Table.Cell>
                      <Table.Cell width={3}>
                        <AddressFormat address={o.shippingAddress} />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {formatDollars(
                          checkoutUtils.calculateTotalBeforeTaxes(o),
                        )}
                      </Table.Cell>
                      <viewUtils.TableCellWithCheck
                        width={2}
                        {...(orderStatus.find(
                          (s) => s.id === 'placed',
                        ) as TableCellWithCheckProps)}
                      />
                      <viewUtils.TableCellWithCheck
                        width={2}
                        {...(orderStatus.find(
                          (s) => s.id === 'shipped',
                        ) as TableCellWithCheckProps)}
                      />
                      <Table.Cell width={2}>
                        {o.placedAt ? (
                          <Button
                            color="gray"
                            fontAwesomeIcon="link"
                            kind="ghost"
                            onClick={() => handleCopyURL(o.id)}
                            text="Copy tracking link"
                          />
                        ) : null}
                      </Table.Cell>
                      <Table.Cell width={1}>
                        <Button
                          fontAwesomeIcon="edit"
                          kind="solid"
                          onClick={() =>
                            navigate(
                              `/admin/projects/${project.id}/sample-orders/${o.id}`,
                            )
                          }
                        />
                        <Button
                          color="blue"
                          fontAwesomeIcon="trash-alt"
                          kind="solid"
                          onClick={() => setOrderToDelete(o)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmationModal
        firstTitle="Are you sure you want to delete the order?"
        onCancel={() => setOrderToDelete(undefined)}
        onConfirm={async () => {
          await deleteOrderAndRefetch({
            variables: {
              where: {
                id: orderToDelete?.id ?? '',
              },
            },
          })
          refetch()
          setOrderToDelete(undefined)
        }}
        open={!!orderToDelete}
      />
    </>
  )
}

export default Orders
