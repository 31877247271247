import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import Tooltip from 'components/admin/tooltip'
import useAuth from 'context/auth/use'
import useRoomElementOptionMutate from 'context/room-element-option/use-mutate'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import {
  RoomElementOption,
  RoomElementOptionData,
} from 'types/room-element-option'

interface RoomElementOptionModalProps {
  roomElementOption?: RoomElementOption
  refetch?: () => Promise<unknown>
}

const RoomElementOptionModal = ({
  roomElementOption,
  refetch,
}: RoomElementOptionModalProps) => {
  const { isAdmin } = useAuth()
  const [showModal, toggleModal] = useState<boolean>(false)
  const initialState = {
    displayName:
      roomElementOption?.data?.displayName || roomElementOption?.description,
    isDiscontinued: roomElementOption?.data?.isDiscontinued || false,
  }
  const [state, setState] = useState<RoomElementOptionData>(initialState)
  useEffect(() => {
    setState(initialState)
  }, [roomElementOption])

  const onCloseModal = () => {
    setState(initialState)
    toggleModal(false)
  }

  const { updateRoomElementOption, loadingUpdate } =
    useRoomElementOptionMutate()

  const onSave = async () => {
    await updateRoomElementOption({
      variables: {
        data: {
          data: {
            ...state,
          },
        },
        where: { id: roomElementOption?.id ?? '' },
      },
      onCompleted: () => {
        refetch?.()
      },
    })
    toggleModal(false)
  }

  return (
    <Modal
      title="Tags"
      onClose={() => onCloseModal()}
      onOpen={() => (!isAdmin ? null : toggleModal(true))}
      trigger={
        <Button
          color="gray"
          fontAwesomeIcon="pencil"
          kind="ghost"
          text="Edit"
        />
      }
      open={showModal}
      size="large"
      saveButton={{
        loading: loadingUpdate,
        onClick: onSave,
      }}
    >
      <h3>Metadata</h3>
      <StyledForm styled={{ grayInputs: true }}>
        <div
          style={{
            backgroundColor: Colors.gray100,
            margin: '24px 0 28px',
            padding: '16px 0 0 16px',
            width: '100%',
          }}
        >
          <p className="subtitle">Product Status</p>
          <Form.Checkbox
            checked={state.isDiscontinued}
            label={
              <label>
                <span style={{ paddingRight: '5px' }}>
                  Product Discontinued
                </span>
                <Tooltip type="Product Discontinued" />
              </label>
            }
            onChange={() =>
              setState({
                ...state,
                isDiscontinued: !state.isDiscontinued,
              })
            }
            style={{ paddingRight: '40px' }}
          />
        </div>
        <Form.Group widths={2}>
          <Form.Input
            label="Display Name"
            onChange={(_: unknown, { value }: { value: string }) => {
              setState({
                ...state,
                displayName: value,
              })
            }}
            value={state.displayName}
          />
        </Form.Group>
      </StyledForm>
    </Modal>
  )
}

export default RoomElementOptionModal
