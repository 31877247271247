import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AdminLayout from 'components/admin/layout'
import PageTitle from 'components/admin/page-title'

import Menu from './menu'

const StaffQueue = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  return (
    <AdminLayout>
      <Grid>
        <PageTitle title="Staff Queue" />
        <Grid.Row>
          <Grid.Column>
            <Menu />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{props.children}</Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default StaffQueue
