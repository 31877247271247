import React, { useEffect, useMemo, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { isEqual } from 'lodash'
import moment from 'moment'

import { RoomPageTitle } from 'components/admin/page-title'
import RoomLayout from 'components/admin/project/room/layout'
import HelpMessage from 'components/shared/help-message'
import useDesign from 'context/design/use'
import useDesignMutate from 'context/design/use-mutate'
import useDesignMutateNoContext from 'context/design/use-mutate-no-context'
import { isAddOnDesign } from 'context/design/utils'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import useProjectFileMutateNoContext from 'context/project-file/use-mutate-no-context'
import useRoom from 'context/room/use'
import useRoomDesignMutate, {
  calculateInitialDesignOptions,
} from 'context/room/use-design-mutate'
import Button from 'design-system/components/button'
import SaveToast from 'design-system/components/save-toast'
import StatusLabel from 'design-system/components/status-label'
import { Design } from 'types/design'
import { StatusMode } from 'types/utils'

import CountertopEstimate from './countertop-estimate'
import DesignPlan from './design-plan'
import DesignSettings from './design-settings'
import Finishes from './finishes'
import Issues from './issues'
import Rendering from './rendering'
import VisualQuote from './visual-quote'
import WinnerFile from './winner-file'

const DesignCreateUpdate = () => {
  // pull context data
  const { project } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()
  const { latestPlanInternalReviewMode } = useDesign()
  const { room, refetch: refetchRoom } = useRoom()
  const { createDesignAndRefetch: createDesignAndRefetchRoom } =
    useRoomDesignMutate()

  const { design, lastDesign, refetch, rendersReleased } = useDesign()
  const { updateDesign } = useDesignMutateNoContext()
  const { updateDesignAndRefetch } = useDesignMutate()
  // don't update cache on update project file, as it makes reordering glitch
  const { updateProjectFileReturnIdOnly } = useProjectFileMutateNoContext()

  // helper state
  const [shouldUpdateBaseCabinetry, toggleShouldUpdateBaseCabinetry] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  // set global form state
  const getInitialState = () => {
    const isInitialDesign = room?.designs.length === 0
    return {
      id: design?.id || null,
      metadata: {
        catalog:
          design?.metadata?.catalog ||
          (isInitialDesign
            ? process.env.GATSBY_DEFAULT_CATALOG
            : room?.designs[room?.designs.length - 1]?.metadata?.catalog),
        formDiscount:
          design?.metadata?.formDiscount ||
          project.data?.baseCabinetryDiscount ||
          0,
        price: design?.metadata?.price || 0,
        initialRenderNotes: design?.metadata?.initialRenderNotes ?? '',
      },
      name:
        design?.name ||
        `${
          isInitialDesign
            ? 'Initial Design'
            : `Revision-${room?.designs.length}`
        }`,
      sentToRenderingAt: design?.sentToRenderingAt,
      issuesResolved: design?.issuesResolved || [],
    }
  }

  // set initial state and state
  const [initialState, setInitialState] = useState<
    Partial<NullableRecord<Design, undefined>>
  >({
    ...getInitialState(),
    renders: rendersReleased,
  })
  const [state, setState] =
    useState<Partial<NullableRecord<Design, undefined>>>(initialState)

  // update initial state on form saves
  useEffect(() => {
    setInitialState({
      ...initialState,
      ...getInitialState(),
    })
    setState({
      ...state,
      ...getInitialState(),
    })
  }, [
    design?.name,
    design?.metadata,
    design?.sentToRenderingAt,
    design?.issuesResolved,
  ])

  // update state and initial state on renders saves, to prevent toast flashing
  useEffect(() => {
    setInitialState({
      ...initialState,
      renders: rendersReleased,
    })
    setState({
      ...state,
      renders: rendersReleased,
    })
  }, [rendersReleased])

  // calculate disable save
  const disableSave = useMemo(() => {
    return (
      !state.name ||
      !state.metadata?.price ||
      state.metadata?.price <= 0 ||
      !state.metadata?.catalog
    )
  }, [state.name, state.metadata])

  // on save function
  const onSave = async () => {
    setLoading(true)
    let createdId: string = ''
    if (!design?.id) {
      // create design and refetch room
      const res = await createDesignAndRefetchRoom({
        variables: {
          data: {
            ...(calculateInitialDesignOptions(room, lastDesign) as any),
            room: {
              connect: { id: room?.id },
            },
            metadata: {
              catalog: state.metadata?.catalog,
              formDiscount: state.metadata?.formDiscount,
              price: state.metadata?.price,
            },
            name: state.name ?? undefined,
            sentToRenderingAt: state.sentToRenderingAt
              ? new Date(state.sentToRenderingAt)
              : undefined,
          },
        },
      })
      res.data?.createOneDesign.id
        ? (createdId = res.data?.createOneDesign.id)
        : undefined
    } else {
      // update design
      await updateDesign({
        variables: {
          data: {
            metadata: {
              ...design.metadata,
              catalog: state.metadata?.catalog,
              formDiscount: state.metadata?.formDiscount,
              initialRenderNotes: state.metadata?.initialRenderNotes,
              price: state.metadata?.price,
            },
            name: state.name ?? undefined,
            sentToRenderingAt: state.sentToRenderingAt
              ? new Date(state.sentToRenderingAt)
              : undefined,
            issuesResolved: {
              set: state.issuesResolved?.length
                ? state.issuesResolved.map((i) => ({ id: i.id ?? '' }))
                : [],
            },
          },
          where: {
            id: design.id,
          },
        },
      })

      const renders = state?.renders ?? []
      // update render indexes
      for (const render of renders) {
        const initialRender = design.renders.find((r) => r.id === render.id)
        if (initialRender?.metadata?.index === render.metadata?.index) {
          continue
        }
        await updateProjectFileReturnIdOnly({
          variables: {
            where: { id: render.id },
            data: {
              metadata: {
                ...render.metadata,
                index: render.metadata.index,
              },
            },
          },
        })
      }

      // refetch design on update
      await refetch()
      // refetch room on update
      await refetchRoom()
    }
    // update project discount and refetch project
    if (
      shouldUpdateBaseCabinetry &&
      initialState.metadata?.formDiscount !== state.metadata?.formDiscount
    ) {
      await updateProjectAndRefetch({
        variables: {
          data: {
            data: {
              baseCabinetryDiscount: state.metadata?.formDiscount,
              hiddenDiscount: false,
            },
          },
          where: {
            id: project.id,
          },
        },
      })
    }
    setLoading(false)
    if (createdId)
      navigate(
        `/admin/projects/${project.id}/rooms/${room?.id}/designs/${createdId}`,
      )
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '34px' }}>
        {design &&
          (isAddOnDesign(design) ? (
            <HelpMessage message="This Add-On design will be published alongside the FORM order." />
          ) : (
            <Button
              color={design?.metadata?.publishedAt ? 'blue' : 'dark'}
              disabled={
                !design?.metadata?.publishedAt &&
                (latestPlanInternalReviewMode === StatusMode.OpenForRevision ||
                  latestPlanInternalReviewMode === StatusMode.Submitted)
              }
              kind="solid"
              text={
                design?.metadata?.publishedAt ? 'Unpublish' : 'Publish Design'
              }
              onClick={async () => {
                await updateDesignAndRefetch({
                  variables: {
                    where: { id: design.id },
                    data: {
                      metadata: {
                        ...design.metadata,
                        publishedAt: design.metadata?.publishedAt
                          ? null
                          : new Date(),
                      },
                    },
                  },
                })
              }}
            />
          ))}
        {design?.metadata?.publishedAt ? (
          <StatusLabel
            type={StatusMode.Confirmed}
            text={`Published on ${moment(design.metadata?.publishedAt).format(
              'MMM DD YYYY',
            )}`}
          />
        ) : null}
      </div>
      <DesignSettings
        setState={(s: Partial<Design>) => setState(s)}
        state={state as Design}
        shouldUpdateBaseCabinetry={shouldUpdateBaseCabinetry}
        toggleShouldUpdateBaseCabinetry={(s: boolean) =>
          toggleShouldUpdateBaseCabinetry(s)
        }
      />
      {design?.id ? <DesignPlan /> : null}
      {design?.id && isAddOnDesign(design) ? (
        <Issues
          setState={(s: Partial<Design>) => setState(s)}
          state={state as Design}
          readOnly={!!design?.supplierOrders?.length}
        />
      ) : null}
      {design?.id ? (
        <Finishes initialCatalog={initialState.metadata?.catalog} />
      ) : null}
      {design?.id ? (
        <Rendering
          setState={(s: Partial<Design>) =>
            setState((prev) => {
              return {
                ...prev,
                ...s,
              }
            })
          }
          state={state as Design}
        />
      ) : null}
      {design?.id ? <CountertopEstimate /> : null}
      {design?.id ? (
        <VisualQuote
          setState={(s: Partial<Design>) =>
            setState((prev) => {
              return {
                ...prev,
                ...s,
              }
            })
          }
          state={state as Design}
        />
      ) : null}
      {design?.id ? <WinnerFile /> : null}
      <div style={{ margin: '20px 0' }}>
        <SaveToast
          disabled={disableSave}
          extraOptions={
            <>
              or
              <Button
                color="dark"
                kind="outlined"
                text="Discard"
                onClick={async () => {
                  navigate(
                    `/admin/projects/${project.id}/rooms/${room?.id}/designs`,
                  )
                }}
              />
            </>
          }
          loading={loading}
          mainButtonText="Save"
          message="You have unsaved changes"
          onClick={onSave}
          show={!isEqual(state, initialState)}
        />
      </div>
    </>
  )
}

export default (
  _: RouteComponentProps<{
    project_id: string
    room_id: string
    design_id: string
  }>,
) => {
  const { room } = useRoom()
  const { design } = useDesign()

  return (
    <RoomLayout>
      <RoomPageTitle room={room} viewName={design?.name || 'Create'} />
      <DesignCreateUpdate />
    </RoomLayout>
  )
}
