import { useMutation } from '@apollo/client'

import {
  CREATE_SUPPLIER_ORDER,
  CreateSupplierOrderPayload,
  CreateSupplierOrderVariables,
  UPDATE_SUPPLIER_ORDER,
  UpdateSupplierOrderPayload,
  UpdateSupplierOrderVariables,
} from 'queries/supplier-order'

const useSupplierOrderMutateNoContext = () => {
  const [createSupplierOrder, { loading: loadingCreate }] = useMutation<
    CreateSupplierOrderPayload,
    CreateSupplierOrderVariables
  >(CREATE_SUPPLIER_ORDER)

  const [updateSupplierOrder, { loading: loadingUpdate }] = useMutation<
    UpdateSupplierOrderPayload,
    UpdateSupplierOrderVariables
  >(UPDATE_SUPPLIER_ORDER)

  return {
    createSupplierOrder,
    loadingCreate,
    loadingUpdate,
    updateSupplierOrder,
  }
}

export default useSupplierOrderMutateNoContext
