import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Divider, Form, Grid, Table } from 'semantic-ui-react'

import CatalogFeatureAccordion from 'components/admin/catalog/catalog-feature/accordion'
import DisconnectedOptionAccordion from 'components/admin/catalog/disconnected-option/accordion'
import GenericOptionAccordion from 'components/admin/catalog/generic-option/accordion'
import NobiliaOptionAccordion from 'components/admin/catalog/nobilia-option/accordion'
import RoomElementOptionAccordion from 'components/admin/catalog/room-element-option/accordion'
import { RoomPageTitle } from 'components/admin/page-title'
import RoomLayout from 'components/admin/project/room/layout'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomDesignOptionsMutate from 'context/room/use-design-options-mutate'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import CheckHighlighted from 'design-system/components/check-highlighted'
import * as NO from 'planner/objects/products/nobilia/options/definitions'

const DesignBrief = (
  props: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  const { conceptImages, inspirationImages, project } = useProject()
  const {
    backsplashMaterials,
    carcaseColorsExterior,
    carcaseColorsInterior,
    catalogFeatures,
    countertopMaterials,
    floorMaterials,
    fronts,
    glassColors,
    glassDesigns,
    glassFrameColors,
    glassFrameDesigns,
    handles,
    inspirationElements,
    inspirationGenerals,
    nobiliaCarcaseExteriorsDiscontinued,
    nobiliaFrontsDiscontinued,
    nobiliaHandlesDiscontinued,
    nobiliaPlinthColorsDiscontinued,
    plinthColors,
    room,
    refetch,
  } = useRoom()
  const { loadingUpdate, updateRoomAndRefetch } = useRoomMutate()
  const {
    onDeleteDisconnectedOption,
    onDeleteGenericOption,
    onDeleteRoomElementOption,
    onSaveCustomDisconnectedOption,
    onSaveCustomGenericOption,
    onSaveCustomRoomElementOption,
    onSaveCatalogFeatures,
    onSaveDisconnectedOptions,
    onSaveGenericOptions,
    onSaveNobiliaOptions,
    onSaveRoomElementOptions,
  } = useRoomDesignOptionsMutate()

  const approved = room?.data?.designBriefApprovedAt
  const whatWeHeard = room?.data?.designBriefWhatWeHeard || []
  const [newItem, setNewItem] = useState<string>('')
  const [idxToDelete, setIdxToDelete] = useState<number>()
  const [showGlassOptions, toggleGlassOptions] = useState<boolean>(
    !!glassColors?.length ||
      !!glassDesigns?.length ||
      !!glassFrameColors?.length ||
      !!glassFrameDesigns?.length,
  )

  const handleSubmit = async () => {
    await updateRoomAndRefetch({
      variables: {
        data: {
          data: {
            designBriefWhatWeHeard: whatWeHeard.concat(newItem),
          },
        },
        where: {
          id: room?.id ?? '',
        },
      },
      onCompleted: () => {
        refetch()
        setNewItem('')
      },
    })
  }

  return (
    <RoomLayout>
      <RoomPageTitle room={room} viewName="Design Brief" />
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <div className="flex" style={{ gap: '22px' }}>
              {process.env.GATSBY_ACTIVE_ENV !== 'production' && (
                <Button
                  kind="solid"
                  color="gray"
                  text="View Initial Call Guide"
                  onClick={() =>
                    window.open(
                      `${process.env.GATSBY_HOME_URL}/app/projects/${props.project_id}/rooms/${room?.id}/design-call`,
                      '_blank',
                    )
                  }
                />
              )}
              <Button
                kind="solid"
                text="View Brief"
                onClick={() =>
                  navigate(
                    `/app/projects/${props.project_id}/rooms/${room?.id}/design-brief`,
                    { state: { isAdmin: true } },
                  )
                }
              />
            </div>
          </Grid.Column>
          <Grid.Column style={{ display: 'flex', justifyContent: 'right' }}>
            <CheckHighlighted
              checked={!!approved}
              color="red"
              text="Design Brief Approved"
              onChange={async () => {
                await updateRoomAndRefetch({
                  variables: {
                    data: {
                      data: {
                        designBriefApprovedAt: !approved ? new Date() : null,
                      },
                    },
                    where: {
                      id: room?.id ?? '',
                    },
                  },
                })
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <h6>What we heard</h6>
            <Form onSubmit={handleSubmit}>
              <Form.TextArea
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setNewItem(e.target.value)
                }}
                placeholder="Write here"
                value={newItem}
              />
              <Button
                kind="solid"
                color="blue"
                text="Add"
                fontAwesomeIcon="plus"
                disabled={!newItem}
                loading={loadingUpdate}
              />
            </Form>
          </Grid.Column>
          {whatWeHeard.length ? (
            <Grid.Column>
              {whatWeHeard.map((item: string, idx: number) => (
                <Table celled key={idx} style={{ border: 'none' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{item}</Table.Cell>
                      <Table.Cell collapsing>
                        <Button
                          kind="ghost"
                          fontAwesomeIcon="trash-alt"
                          onClick={() => setIdxToDelete(idx)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ))}
              <ConfirmationModal
                firstTitle="Are you sure you want to delete it?"
                onCancel={() => setIdxToDelete(undefined)}
                onConfirm={async () => {
                  const whatWeHeardToSave = whatWeHeard.filter(
                    (_, idx) => idx !== idxToDelete,
                  )
                  await updateRoomAndRefetch({
                    variables: {
                      data: {
                        data: {
                          designBriefWhatWeHeard: whatWeHeardToSave,
                        },
                      },
                      where: {
                        id: room?.id ?? '',
                      },
                    },
                  })
                  setIdxToDelete(undefined)
                }}
                open={idxToDelete !== undefined}
              />
            </Grid.Column>
          ) : null}
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <h5>Our finishes</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={room?.data?.catalog ?? ''}
              featureNo={NO.FRONT_COMBO}
              nobiliaOptions={fronts ?? []}
              onSave={(no) => onSaveNobiliaOptions(no, fronts ?? [])}
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Fronts"
            />
          </Grid.Column>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={room?.data?.catalog ?? ''}
              featureNo={NO.HANDLE_COMBINATION}
              nobiliaOptions={handles ?? []}
              onSave={(no) => onSaveNobiliaOptions(no, handles ?? [])}
              selectionCardProps={{ imageRatio: '3/2' }}
              title="Handles"
            />
          </Grid.Column>
        </Grid.Row>
        {nobiliaFrontsDiscontinued?.length ||
        nobiliaHandlesDiscontinued?.length ? (
          <Grid.Row columns={2}>
            <Grid.Column>
              <DisconnectedOptionAccordion
                featureIdentifier="nobiliaFrontDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) => {
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaFrontsDiscontinued ?? [],
                  )
                }}
                disconnectedOptions={nobiliaFrontsDiscontinued ?? []}
                selectionCardProps={{ imageRatio: '2/3' }}
                title="Fronts (discontinued)"
              />
            </Grid.Column>
            <Grid.Column>
              <DisconnectedOptionAccordion
                featureIdentifier="nobiliaHandleDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) => {
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaFrontsDiscontinued ?? [],
                  )
                }}
                disconnectedOptions={nobiliaHandlesDiscontinued ?? []}
                selectionCardProps={{ imageRatio: '3/2' }}
                title="Handles (discontinued)"
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row columns={2}>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={room?.data?.catalog ?? ''}
              featureNo={NO.CARCASE_COLOR_EXTERIOR}
              nobiliaOptions={carcaseColorsExterior ?? []}
              onSave={(no) =>
                onSaveNobiliaOptions(no, carcaseColorsExterior ?? [])
              }
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Carcase Exterior"
            />
          </Grid.Column>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={room?.data?.catalog ?? ''}
              featureNo={NO.CARCASE_COLOR_INTERIOR}
              nobiliaOptions={carcaseColorsInterior ?? []}
              onSave={(no) =>
                onSaveNobiliaOptions(no, carcaseColorsInterior ?? [])
              }
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Carcase Interior"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <NobiliaOptionAccordion
              catalog={room?.data?.catalog ?? ''}
              featureNo={NO.PLINTH_COLOR}
              nobiliaOptions={plinthColors ?? []}
              onSave={(no) => onSaveNobiliaOptions(no, plinthColors ?? [])}
              selectionCardProps={{ imageRatio: '2/3' }}
              title="Toe kicks"
            />
          </Grid.Column>
        </Grid.Row>
        {nobiliaCarcaseExteriorsDiscontinued?.length ||
        nobiliaPlinthColorsDiscontinued?.length ? (
          <Grid.Row columns={2}>
            <Grid.Column>
              <DisconnectedOptionAccordion
                disconnectedOptions={nobiliaCarcaseExteriorsDiscontinued ?? []}
                featureIdentifier="nobiliaCarcaseExteriorDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) =>
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaFrontsDiscontinued ?? [],
                  )
                }
                selectionCardProps={{ imageRatio: '2/3' }}
                title="Carcase exterior (discontinued)"
              />
              <DisconnectedOptionAccordion
                disconnectedOptions={nobiliaPlinthColorsDiscontinued ?? []}
                featureIdentifier="nobiliaPlinthColorDiscontinued"
                onDelete={onDeleteDisconnectedOption}
                onSave={async (re) =>
                  await onSaveDisconnectedOptions(
                    re,
                    nobiliaFrontsDiscontinued ?? [],
                  )
                }
                selectionCardProps={{ imageRatio: '2/3' }}
                title="Toe kick (discontinued)"
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            <CatalogFeatureAccordion
              catalogFeatures={catalogFeatures ?? []}
              onSave={(cf) => onSaveCatalogFeatures(cf, catalogFeatures ?? [])}
              title="Features"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h6
              style={{ cursor: 'pointer' }}
              onClick={() => toggleGlassOptions(!showGlassOptions)}
            >
              {showGlassOptions ? 'Hide' : 'Show'} glass door options{' '}
              <FontAwesomeIcon
                icon={['fal', showGlassOptions ? 'chevron-up' : 'chevron-down']}
                style={{ marginLeft: '10px' }}
              />
            </h6>
          </Grid.Column>
        </Grid.Row>
        {showGlassOptions ? (
          <>
            <Grid.Row columns={3}>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={room?.data?.catalog ?? ''}
                  featureNo={NO.GLASS_DOOR_FRAME_DESIGN}
                  gridColumns={2}
                  nobiliaOptions={glassFrameDesigns ?? []}
                  onSave={(no) =>
                    onSaveNobiliaOptions(no, glassFrameDesigns ?? [])
                  }
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Frame design"
                />
              </Grid.Column>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={room?.data?.catalog ?? ''}
                  featureNo={NO.GLASS_DOOR_FRAME_COLOR}
                  gridColumns={2}
                  nobiliaOptions={glassFrameColors ?? []}
                  onSave={(no) =>
                    onSaveNobiliaOptions(no, glassFrameColors ?? [])
                  }
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Frame color"
                />
              </Grid.Column>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={room?.data?.catalog ?? ''}
                  featureNo={NO.GLASS_COLOR}
                  gridColumns={2}
                  nobiliaOptions={glassColors ?? []}
                  onSave={(no) => onSaveNobiliaOptions(no, glassColors ?? [])}
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Glass color"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <NobiliaOptionAccordion
                  catalog={room?.data?.catalog ?? ''}
                  featureNo={NO.GLASS_DESIGN}
                  gridColumns={2}
                  nobiliaOptions={glassDesigns ?? []}
                  onSave={(no) => onSaveNobiliaOptions(no, glassDesigns ?? [])}
                  selectionCardProps={{ imageRatio: '1/1' }}
                  title="Glass design"
                />
              </Grid.Column>
            </Grid.Row>
          </>
        ) : null}
        <Divider />
        <Grid.Row columns={2}>
          <Grid.Column>
            <h5>Additional finishes</h5>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              kind="solid"
              text="Upload custom image"
              fontAwesomeIcon="up-right-from-square"
              onClick={() =>
                navigate(`/admin/projects/${project.id}/custom-images`)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <GenericOptionAccordion
              conceptImages={conceptImages}
              customIdentifier="backsplashMaterialcustom"
              featureIdentifier="backsplashMaterial"
              genericOptions={backsplashMaterials ?? []}
              onDelete={onDeleteGenericOption}
              onSave={(go) =>
                onSaveGenericOptions(go, backsplashMaterials ?? [])
              }
              onSaveCustom={onSaveCustomGenericOption}
              title="Backsplash"
            />
          </Grid.Column>
          <Grid.Column>
            <GenericOptionAccordion
              conceptImages={conceptImages}
              customIdentifier="countertopMaterialcustom"
              featureIdentifier="countertopMaterial"
              genericOptions={countertopMaterials ?? []}
              onDelete={onDeleteGenericOption}
              onSave={(go) =>
                onSaveGenericOptions(go, countertopMaterials ?? [])
              }
              onSaveCustom={onSaveCustomGenericOption}
              title="Countertop"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <RoomElementOptionAccordion
              conceptImages={conceptImages}
              customIdentifier="floorMaterialcustom"
              featureIdentifier="floorMaterial"
              onDelete={onDeleteRoomElementOption}
              onSave={(re) =>
                onSaveRoomElementOptions(re, floorMaterials ?? [])
              }
              onSaveCustom={onSaveCustomRoomElementOption}
              roomElementOptions={floorMaterials ?? []}
              title="Floor"
            />
          </Grid.Column>
          <Grid.Column>
            <DisconnectedOptionAccordion
              conceptImages={[...conceptImages, ...inspirationImages]}
              customIdentifier="inspirationGeneralcustom"
              disconnectedOptions={inspirationGenerals ?? []}
              featureIdentifier="inspirationGeneral"
              onDelete={onDeleteDisconnectedOption}
              onSave={async (re) =>
                await onSaveDisconnectedOptions(re, inspirationGenerals ?? [])
              }
              onSaveCustom={onSaveCustomDisconnectedOption}
              selectionCardProps={{ imageRatio: '3/2' }}
              title="General Inspiration"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <DisconnectedOptionAccordion
              conceptImages={[...conceptImages, ...inspirationImages]}
              customIdentifier="inspirationElementcustom"
              disconnectedOptions={inspirationElements ?? []}
              featureIdentifier="inspirationElement"
              onDelete={onDeleteDisconnectedOption}
              onSave={async (re) =>
                await onSaveDisconnectedOptions(re, inspirationElements ?? [])
              }
              onSaveCustom={onSaveCustomDisconnectedOption}
              selectionCardProps={{ imageRatio: '1/1' }}
              title="Inspiration Elements"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
      </Grid>
    </RoomLayout>
  )
}

export default DesignBrief
