import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  CREATE_ONE_PROJECT_USER,
  CreateOneProjectUserPayload,
  CreateOneProjectUserVariables,
  DELETE_ONE_PROJECT_USER,
  DeleteOneProjectUserPayload,
  DeleteOneProjectUserVariables,
  UPDATE_ONE_PROJECT_USER,
  UpdateOneProjectUserPayload,
  UpdateOneProjectUserVariables,
} from 'queries/project-user'

import { ProjectContext } from './provider'

const useProjectUserMutate = () => {
  const { refetch } = useContext(ProjectContext)

  const [createProjectUserAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateOneProjectUserPayload,
    CreateOneProjectUserVariables
  >(CREATE_ONE_PROJECT_USER, {
    onCompleted: () => {
      refetch()
    },
  })

  const [deleteProjectUserAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteOneProjectUserPayload,
    DeleteOneProjectUserVariables
  >(DELETE_ONE_PROJECT_USER, {
    onCompleted: () => {
      refetch()
    },
  })

  const [updateProjectUserAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateOneProjectUserPayload,
    UpdateOneProjectUserVariables
  >(UPDATE_ONE_PROJECT_USER, {
    onCompleted: () => {
      refetch()
    },
  })

  return {
    createProjectUserAndRefetch,
    deleteProjectUserAndRefetch,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateProjectUserAndRefetch,
  }
}

export default useProjectUserMutate
