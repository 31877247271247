import { useQuery } from '@apollo/client'

import {
  FIND_UNIQUE_DELIVERY_LOOKUP,
  FindUniqueDeliveryPayload,
  FindUniqueDeliveryVariables,
} from 'queries/delivery'

// to get ids for contexts
const useDeliveryLookup = (deliveryId: string) => {
  const { loading, data } = useQuery<
    FindUniqueDeliveryPayload,
    FindUniqueDeliveryVariables
  >(FIND_UNIQUE_DELIVERY_LOOKUP, {
    skip: !deliveryId,
    variables: {
      where: { id: deliveryId },
    },
  })

  return {
    loading,
    delivery: data?.delivery,
  }
}

export default useDeliveryLookup
