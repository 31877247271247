import { sortBy } from 'lodash'
import moment from 'moment'

import * as supplierOrderUtils from 'context/supplier-order/utils'
import { Route, RouteType } from 'types/route'
import { StatusStage } from 'types/utils'

export const getRouteStatusSummary = (route: Route) => {
  const confirmedAt = route?.confirmedAt
  const dispatchedAtEstimated = route?.data?.dispatchedAtEstimated
  const dispatchedAtActual = route?.data?.dispatchedAtActual
  const completedAtEstimated = route?.data?.completedAtEstimated
  const completedAtActual = route?.data?.completedAtActual

  if (completedAtActual)
    return {
      currentStage: `Completed`,
      currentStageDate: moment(completedAtActual, 'MM/DD/YYYY').format(
        'MM/DD/YYYY',
      ),
      currentStageConfirmedAt: completedAtActual,
      nextStage: `Done`,
      nextStageDate: ':)',
      sortKey: `15${moment(completedAtActual, 'MM/DD/YYYY').format(
        'YYYYMMDD',
      )}`,
      stageNo: 15,
    }

  if (dispatchedAtActual)
    return {
      currentStage: `Dispatched`,
      currentStageDate: moment(dispatchedAtActual, 'MM/DD/YYYY').format(
        'MM/DD/YYYY',
      ),
      currentStageConfirmedAt: dispatchedAtActual,
      nextStage: `Completed`,
      nextStageDate: completedAtEstimated
        ? moment(completedAtEstimated, 'MM/DD/YYYY').format('MM/DD/YYYY')
        : '?',
      sortKey: `13${moment(dispatchedAtActual, 'MM/DD/YYYY').format(
        'YYYYMMDD',
      )}`,
      stageNo: 13,
    }

  if (confirmedAt)
    return {
      currentStage: `Route Scheduled`,
      currentStageDate: moment(confirmedAt).format('MM/DD/YYYY'),
      currentStageConfirmedAt: confirmedAt,
      nextStage: `Dispatch`,
      nextStageDate: dispatchedAtEstimated
        ? moment(dispatchedAtEstimated, 'MM/DD/YYYY').format('MM/DD/YYYY')
        : '?',
      sortKey: `12${
        dispatchedAtEstimated
          ? moment(dispatchedAtEstimated, 'MM/DD/YYYY').format('YYYYMMDD')
          : '20000101'
      }`,
      stageNo: 12,
    }

  return {
    currentStage: 'Proposed',
    currentStageDate: '?',
    currentStageConfirmedAt: false,
    nextStage: 'Scheduled',
    nextStageDate: '?',
    sortKey: '00',
    stageNo: 0,
  }
}

export const getRouteStatus = (route: Route): StatusStage[] => {
  const dispatchedAtEstimated = route?.data?.dispatchedAtEstimated
  const dispatchedAtActual = route?.data?.dispatchedAtActual
  const completedAtEstimated = route?.data?.completedAtEstimated
  const completedAtActual = route?.data?.completedAtActual

  return [
    {
      date: dispatchedAtActual || dispatchedAtEstimated || null,
      data: {
        type: 'plain',
        value: route.reference ? route.reference : null,
      },
      id: 'dispatch',
      publishToCustomer: !!dispatchedAtActual,
      title: 'Date of Dispatch',
      titleCustomer: dispatchedAtActual
        ? 'Last mile delivery dispatched'
        : 'Estimated date of dispatch',
    },
    {
      date: completedAtActual || completedAtEstimated || null,
      data: {
        type: 'plain',
        value: route.data?.carrier,
      },
      publishToCustomer: !!completedAtActual,
      id: 'delivery',
      title: 'Date of Completion',
      titleCustomer: completedAtActual
        ? 'Estimated date of completion'
        : 'Last mile delivery complete',
    },
  ]
}

export const RouteTypeOptions = [
  {
    key: 'NULL',
    text: '',
    value: '',
  },
  {
    key: 'BT',
    text: 'Box Truck',
    value: RouteType.BOX_TRUCK,
  },
  {
    key: 'LH',
    text: 'Line Haul',
    value: RouteType.LINE_HAUL,
  },
  {
    key: 'LTL',
    text: 'LTL',
    value: RouteType.LTL,
  },
]

export const parseSupplierOrders = (route: Route | NullableRecord<Route>) => {
  const sorted = sortBy(route?.supplierOrders || [], (so) => so.nobiliaOrderRef)
  return {
    supplierOrders: sorted,
    volumeM3: supplierOrderUtils.sumSupplierOrderVolumes(sorted),
    weightKg: supplierOrderUtils.sumSupplierOrderWeights(sorted),
  }
}
