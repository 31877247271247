import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Projects from 'components/admin/project/index-many'
import useTechDesigner from 'context/dashboard/tech-designer/use'
import ProjectManyProvider from 'context/project/provider-many'
import { ProjectStaffRole } from 'types/project-staff'

import TechDesignerLayout from './layout'

const TechDesignerProjects = (_: RouteComponentProps) => {
  const { userId, userName } = useTechDesigner()

  return (
    <TechDesignerLayout>
      <ProjectManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          assignedToRole: ProjectStaffRole.TECH_DESIGNER,
          sortBy: 'derivedActivity',
          sortDirection: 'descending',
          status: 'open',
          take: 30,
        }}
        skipLoader
      >
        <Projects />
      </ProjectManyProvider>
    </TechDesignerLayout>
  )
}

export default TechDesignerProjects
