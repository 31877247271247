import { useMemo } from 'react'

import moment from 'moment'

import useProspectMany from 'context/prospect/use-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { MeetingOutcome, MeetingTypeProspect } from 'types/meeting'
import { ProspectType } from 'types/prospect'
import { capsSnakeCaseToTitleCase, toTitleCase } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormDateValue,
  IFormDropdownValue,
  IFormRadioValue,
} from '../filters-search'

export type ProspectFilterValues = {
  assignedTo: IFormDropdownValue
  prospectType: IFormRadioValue
  meetingType: IFormDropdownValue
  meetingOutcome: IFormDropdownValue
  meetingTime: IFormDateValue
  suggestedFollowUpDate: IFormDateValue
}
const useProspectFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useProspectMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()

  const filters: DynamicFilterGroup<ProspectFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'assignedTo',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Assigned to',
            options: [
              ...userOptions,
              {
                key: 'everyone',
                text: 'Everyone',
                value: 'Everyone',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'prospectType',
            type: DynamicFilterTypes.RADIO,
            label: 'Prospect type',
            options: [
              {
                text: 'All',
                value: 'all',
              },
            ].concat(
              Object.values(ProspectType).map((pt) => {
                return {
                  text: toTitleCase(pt),
                  value: pt as string,
                }
              }),
            ),
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'meetingType',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Meeting type',
            options: Object.values(MeetingTypeProspect).map((m) => {
              return {
                key: m,
                text: capsSnakeCaseToTitleCase(m),
                value: m,
              }
            }),
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'meetingOutcome',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Meeting outcome',
            options: Object.values(MeetingOutcome).map((m) => {
              return {
                key: m,
                text: capsSnakeCaseToTitleCase(m),
                value: m,
              }
            }),
          },
        ],
      },
      {
        filters: [
          {
            type: DynamicFilterTypes.DATE,
            filterId: 'meetingTime',
            label: 'Meeting Date Range',
          },
        ],
      },
      {
        filters: [
          {
            type: DynamicFilterTypes.DATE,
            filterId: 'suggestedFollowUpDate',
            label: 'Suggested Follow Up Date Range',
          },
        ],
      },
    ]
  }, [userOptions])

  const defaultFilters = useMemo(() => {
    const prospectFilters: Partial<ProspectFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      prospectFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }
    if (queryFilters.prospectType)
      prospectFilters.prospectType = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.prospectType,
          text: queryFilters.prospectType,
        },
      }
    if (queryFilters.meetingType)
      prospectFilters.meetingType = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.meetingType,
          text: capsSnakeCaseToTitleCase(queryFilters.meetingType),
        },
      }
    if (queryFilters.meetingOutcome)
      prospectFilters.meetingOutcome = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.meetingOutcome,
          text: capsSnakeCaseToTitleCase(queryFilters.meetingOutcome),
        },
      }
    if (queryFilters.meetingTime?.gte)
      prospectFilters.meetingTime = {
        type: DynamicFilterTypes.DATE,
        value: `${moment(queryFilters.meetingTime.gte).format(
          'MM/DD/YYYY',
        )} - ${moment(queryFilters.meetingTime.lte).format('MM/DD/YYYY')}`,
      }
    if (queryFilters.suggestedFollowUpDate?.gte)
      prospectFilters.suggestedFollowUpDate = {
        type: DynamicFilterTypes.DATE,
        value: `${moment(queryFilters.suggestedFollowUpDate.gte).format(
          'MM/DD/YYYY',
        )} - ${moment(queryFilters.suggestedFollowUpDate.lte).format(
          'MM/DD/YYYY',
        )}`,
      }

    return prospectFilters
  }, [queryFilters])

  const handleApplyFilters = (data: ProspectFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      prospectType:
        data.prospectType?.selection.value === 'all'
          ? undefined
          : (data.prospectType?.selection.value as ProspectType),
      meetingType: data.meetingType?.selection.value as MeetingTypeProspect,
      meetingOutcome: data.meetingOutcome?.selection.value as MeetingOutcome,
      meetingTime: !data.meetingTime
        ? undefined
        : {
            gte: moment(data.meetingTime?.value.split('-')[0].trim())
              .startOf('day')
              .toISOString(),
            lte: moment(data.meetingTime?.value.split('-')[1].trim())
              .endOf('day')
              .toISOString(),
          },
      suggestedFollowUpDate: !data.suggestedFollowUpDate
        ? undefined
        : {
            gte: moment(data.suggestedFollowUpDate?.value.split('-')[0].trim())
              .startOf('day')
              .toISOString(),
            lte: moment(data.suggestedFollowUpDate?.value.split('-')[1].trim())
              .endOf('day')
              .toISOString(),
          },
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: null,
      }),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useProspectFiltersSearch
