import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import {
  UpdateDesignPayload,
  UpdateDesignVariables,
  UPDATE_DESIGN,
} from 'queries/design'

import { DesignContext } from './design-provider'

const useDesign = () => {
  const {
    catalog,
    countertopProductClass,
    defaultArcRotateCamera,
    defaultTargetCamera,
    defaultUniversalCamera,
    design,
    dispatch,
    plinthProductClass,
    presets,
    refetch,
    state,
    room,
  } = useContext(DesignContext)

  const [updateDesignRaw] = useMutation<
    UpdateDesignPayload,
    UpdateDesignVariables
  >(UPDATE_DESIGN, {
    onCompleted: refetch,
  })

  const updateDesign = async (variables: UpdateDesignVariables) => {
    return await updateDesignRaw({
      variables,
    })
  }

  return {
    catalog,
    countertopProductClass,
    defaultArcRotateCamera,
    defaultTargetCamera,
    defaultUniversalCamera,
    design,
    dispatch,
    presetDefault: presets.find((p) => p.identifier === 'default'),
    presets,
    presetsCount: presets.length,
    plinthProductClass,
    refetch,
    room,
    ...state,
    updateDesign,
  }
}

export default useDesign
