import { useMutation } from '@apollo/client'

import {
  UPDATE_ONE_ROOM_ELEMENT_OPTION,
  UpdateRoomElementOptionPayload,
  UpdateRoomElementOptionVariables,
} from 'queries/room-element-option'

const useRoomElementOptionMutate = () => {
  const [updateRoomElementOption, { loading: loadingUpdate }] = useMutation<
    UpdateRoomElementOptionPayload,
    UpdateRoomElementOptionVariables
  >(UPDATE_ONE_ROOM_ELEMENT_OPTION)

  return {
    loadingUpdate,
    updateRoomElementOption,
  }
}

export default useRoomElementOptionMutate
