import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NobiliaOptionManyProvider from 'context/nobilia-option/provider-many'

interface NobiliaOptionIndexManyProps
  extends RouteComponentProps<{
    catalog_id: string
    nobilia_feature_id: string
  }> {
  children: React.ReactNode
}

const NobiliaOptionIndexMany = (props: NobiliaOptionIndexManyProps) => {
  return (
    <NobiliaOptionManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        catalog: props.catalog_id,
        feature: {
          id: props.nobilia_feature_id,
        },
        sortBy: 'optionKey',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </NobiliaOptionManyProvider>
  )
}

export default NobiliaOptionIndexMany
