import { FindManySupplierOrderVariables } from 'queries/supplier-order'
import { DESIGN_TYPES } from 'types/design'

export interface QueryFilters {
  // staff assigned
  assignedTo?: {
    id: string
    name?: string
  }

  createdAt?: {
    gte?: string
    lte?: string
  }

  // search string
  search?: string

  // sort values
  sortBy?: 'createdAt' | 'derivedStatus' | 'derivedStatusPlanning'
  sortDirection?: 'descending' | 'ascending'

  // query values
  includeThirdParty?: boolean
  routes?: string[]
  shipment?: string
  statusesFORM?: FORMStatus[]
  statusesNobilia?: NobiliaStatus[]
  type?: SupplierOrderType[]
  warehouse?: string

  // how many to take
  take?: number
}

export const getQueryVariables = ({
  assignedTo,
  includeThirdParty,
  createdAt,
  routes,
  search,
  shipment,
  sortBy,
  sortDirection,
  statusesFORM,
  statusesNobilia,
  take,
  type,
  warehouse,
}: QueryFilters): FindManySupplierOrderVariables => {
  const orderBy = (() => {
    switch (sortBy) {
      case 'createdAt': {
        return {
          createdAt: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      case 'derivedStatus': {
        return {
          derivedStatus: sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      case 'derivedStatusPlanning': {
        return {
          derivedStatusPlanning:
            sortDirection === 'descending' ? 'desc' : 'asc',
        }
      }
      default: {
        return undefined
      }
    }
  })()
  let variables: FindManySupplierOrderVariables = {
    orderBy,
    take,
  }
  // if search, just do search
  if (search) {
    return {
      ...variables,
      where: {
        ...variables.where,
        derivedSearch: {
          contains: search,
          mode: 'insensitive',
        },
      },
    }
  }
  if (!includeThirdParty) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        projectId: {
          not: null,
        },
      },
    }
  }
  if (assignedTo?.id) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        project: {
          staff: {
            some: {
              staffMemberId: {
                equals: assignedTo.id,
              },
              isSubscribed: {
                equals: true,
              },
            },
          },
        },
      },
    }
  }
  if (createdAt?.gte) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        createdAt: {
          gte: createdAt.gte,
        },
      },
    }
  }
  if (createdAt?.lte) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        createdAt: {
          lte: createdAt.lte,
        },
      },
    }
  }
  if (warehouse) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        derivedStatusPlanning: {
          contains: warehouse,
        },
      },
    }
  }
  if (routes?.length) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        route: {
          id: {
            in: routes,
          },
        },
      },
    }
  }
  if (shipment) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        shipment: {
          id: {
            equals: shipment,
          },
        },
      },
    }
  }
  if (statusesNobilia?.length) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        AND: [
          ...(variables.where?.AND || []),
          {
            OR: statusesNobilia.map((s) => ({
              data: {
                path: ['nobiliaStatus'],
                equals: s,
              },
            })),
          },
        ],
      },
    }
  }
  if (statusesFORM?.length) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        AND: [
          ...(variables.where?.AND || []),
          {
            OR: statusesFORM.map((s) => ({
              derivedStatus: {
                startsWith: s.slice(0, 2),
              },
            })),
          },
        ],
      },
    }
  }
  if (type?.length) {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        AND: [
          ...(variables.where?.AND || []),
          {
            OR: [
              {
                data: {
                  path: ['nobiliaOrderType'],
                  array_contains: type,
                },
              },
              ...(type.includes(SupplierOrderType.Regular)
                ? [
                    {
                      design: {
                        name: {
                          not: {
                            startsWith: DESIGN_TYPES.ADD_ON_DESIGN,
                          },
                        },
                      },
                      issuesResolved: {
                        none: {},
                      },
                    },
                  ]
                : []),
              ...(type.includes(SupplierOrderType.Remedial)
                ? [
                    {
                      data: {
                        path: ['isRemedial'],
                        equals: true,
                      },
                    },
                  ]
                : []),
              ...(type.includes(SupplierOrderType.AddOn)
                ? [
                    {
                      design: {
                        name: {
                          startsWith: DESIGN_TYPES.ADD_ON_DESIGN,
                        },
                      },
                    },
                  ]
                : []),
            ],
          },
        ],
      },
    }
  }
  return variables
}

export enum NobiliaStatus {
  OrderReceived = '0-Order Received',
  ACKReceived = '1-ACK received',
  ChangedRequested = '2-Change requested to Germany',
  RevisedACKReceived = '3-Revised ACK received',
  ACKApproved = '4-ACK approved',
  ContainerScheduled = '5-Container scheduled',
  ShippedLeftFactory = '6-Shipped-Left factory',
  OrderReadyForPickupAtWarehouse = '7-Order ready for Pickup at Warehouse',
  Delivered = '8-Delivered',
  Canceled = '9-Canceled',
}

export enum FORMStatus {
  EDINotSent = '00-EDI Not Sent',
  AwaitingNobilia = '01-Awaiting Nobilia',
  AwaitingFORM = '02-Awaiting FORM',
  ACKApproved = '03-ACK Approved',
  ProductionScheduled = '04-Production Scheduled',
  Produced = '05-Produced',
  Loaded = '06-Loaded',
  Shipped = '07-Shipped',
  ArrivedInUS = '08-Arrived in US',
  ReceivedAtWarehouse = '09-Received at Warehouse',
  InStorage = '10-In Storage',
  ReadyForDispatch = '11-Ready For Dispatch',
  LastMileScheduled = '12-Last Mile Scheduled',
  LastMileDispatched = '13-Last Mile Dispatched',
  Delivered = '14-Delivered',
}

export enum SupplierOrderType {
  Regular = 'Regular',
  Remedial = 'Remedial',
  AddOn = 'Add-On',
}
