import React, { useState } from 'react'

import { Table } from 'semantic-ui-react'

import CreateRemedialOrderModal from 'components/admin/delivery/create-remedial-order-modal'
import DeliveryModal from 'components/admin/delivery/create-update-modal'
import SendOrderModal from 'components/admin/design/supplier-order/send-order-modal'
import useIssueMany from 'context/issue/use-many'
import { ButtonTooltip } from 'design-system/components/button'
import { TooltipProps } from 'design-system/components/tooltip'
import { Colors } from 'styles/app/system'
import { Delivery } from 'types/delivery'
import { Issue, ResolutionType } from 'types/issue'

import AttachSupplierOrderModal from './attach-supplier-order'
import IssueStatusCell from './issue-status-cell'
import IssueModal from './update-modal'
import {
  IssueCreatedAtCell,
  IssueDescriptionCell,
  IssueRelatedImgsCell,
  IssueResolutionCell,
  IssueTypeSubtypeCell,
  ProjectIssueCell,
} from './utils-table'

const IssuesTable = () => {
  const { issues, refetch, queryFilters, setQueryFilters } = useIssueMany()
  const [issueToEdit, setIssueToEdit] = useState<Issue>()
  const [deliveryToEdit, setDeliveryToEdit] = useState<Delivery>()
  const [issueToAttach, setIssueToAttach] = useState<Issue>()
  const [issueToSendOrder, setIssueToSendOrder] = useState<Issue>()
  const [issueToSendRemedial, setIssueToSendRemedial] = useState<Issue>()

  const { sortBy, sortDirection } = queryFilters

  const COMMON_TOOLTIP_PROPS: Omit<TooltipProps, 'children' | 'label'> = {
    color: Colors.blue700,
  }

  return (
    <Table celled sortable fixed className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Project</Table.HeaderCell>
          <Table.HeaderCell width={2}>Issue Type / Sub-type</Table.HeaderCell>
          <Table.HeaderCell width={2}>Resolution</Table.HeaderCell>
          <Table.HeaderCell width={3}>Description</Table.HeaderCell>
          <Table.HeaderCell width={3}>Related image</Table.HeaderCell>
          <Table.HeaderCell
            width={3}
            sorted={sortBy === 'status' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'status',
                sortDirection:
                  sortBy === 'status' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Status / Order
          </Table.HeaderCell>
          <Table.HeaderCell
            width={3}
            sorted={sortBy === 'createdAt' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'createdAt',
                sortDirection:
                  sortBy === 'createdAt' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Created At
          </Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {issues.map((i: Issue) => {
          return (
            <Table.Row key={i.id}>
              <ProjectIssueCell issue={i} />
              <IssueTypeSubtypeCell issue={i} />
              <IssueResolutionCell issue={i} />
              <IssueDescriptionCell issue={i} />
              <IssueRelatedImgsCell issue={i} />
              <IssueStatusCell
                issue={i}
                toggleOrderModal={() =>
                  i.resolutionType === ResolutionType.REMEDIAL
                    ? setIssueToSendRemedial(i)
                    : setIssueToSendOrder(i)
                }
                onAttachOrder={() => setIssueToAttach(i)}
              />
              <IssueCreatedAtCell issue={i} />
              <Table.Cell>
                <div
                  style={{
                    display: 'grid',
                    rowGap: '12px',
                    justifyContent: 'center',
                  }}
                >
                  <ButtonTooltip
                    kind="solid"
                    fontAwesomeIcon="edit"
                    onClick={() => setIssueToEdit(i)}
                    tooltipProps={{
                      label: 'Edit issue',
                      ...COMMON_TOOLTIP_PROPS,
                    }}
                  />
                  {!!i.delivery?.id && (
                    <ButtonTooltip
                      color="blue"
                      fontAwesomeIcon="truck-ramp-box"
                      kind="solid"
                      onClick={() => setDeliveryToEdit(i.delivery)}
                      tooltipProps={{
                        label: 'Open delivery',
                        ...COMMON_TOOLTIP_PROPS,
                      }}
                    />
                  )}
                  <ButtonTooltip
                    color="blue"
                    kind="solid"
                    fontAwesomeIcon="arrow-up-right-from-square"
                    onClick={() =>
                      window.open(`/admin/projects/${i.project?.id}/issues`)
                    }
                    tooltipProps={{
                      label: 'Go to project',
                      ...COMMON_TOOLTIP_PROPS,
                    }}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <IssueModal
        onClose={() => setIssueToEdit(undefined)}
        open={!!issueToEdit}
        refetch={refetch}
        issue={issueToEdit}
      />
      <DeliveryModal
        onClose={() => setDeliveryToEdit(undefined)}
        open={!!deliveryToEdit}
        refetch={refetch}
        deliveryId={deliveryToEdit?.id ?? ''}
      />
      {issueToSendOrder?.designResolving ? (
        <SendOrderModal
          design={issueToSendOrder?.designResolving}
          onClose={() => setIssueToSendOrder(undefined)}
          open={!!issueToSendOrder}
          project={issueToSendOrder?.project}
          refetch={refetch}
        />
      ) : null}
      {issueToSendRemedial?.delivery ? (
        <CreateRemedialOrderModal
          delivery={issueToSendRemedial?.delivery}
          issueSelected={issueToSendRemedial}
          open={!!issueToSendRemedial}
          onClose={() => setIssueToSendRemedial(undefined)}
          refetch={refetch}
        />
      ) : null}
      {issueToAttach?.project && issueToAttach?.id ? (
        <AttachSupplierOrderModal
          project={issueToAttach?.project}
          issueId={issueToAttach?.id}
          open={!!issueToAttach}
          onClose={() => setIssueToAttach(undefined)}
          refetch={refetch}
        />
      ) : null}
    </Table>
  )
}

export default IssuesTable
