import React, { useState } from 'react'

import moment from 'moment'
import { Table } from 'semantic-ui-react'

import DeliveryModal from 'components/admin/delivery/create-update-modal'
import ShipmentModal from 'components/admin/shipment/create-update-modal'
import SupplierOrderModal from 'components/admin/supplier-order/create-update-modal'
import AddressFormat from 'components/shared/address-format'
import useAuth from 'context/auth/use'
import useDeliveryMany from 'context/delivery/use-many'
import { parseOwners } from 'context/project/utils'
import { ButtonTooltip } from 'design-system/components/button'
import { TooltipProps } from 'design-system/components/tooltip'
import { Colors } from 'styles/app/system'
import { Delivery } from 'types/delivery'
import { Shipment } from 'types/shipment'
import { SupplierOrder } from 'types/supplier-order'

import CellContentsDamages from './table-cell-damages'
import CellContentsSupplierOrders from './table-cell-supplier-orders'
import { ProjectOwnersCellContents } from '../project/project-owners/table-cell-content'
import { ProjectStaffCellContents } from '../project/project-staff/table-cell-content'

const DeliveriesTable = () => {
  const { isArtist, isNobiliaOrderProcessor } = useAuth()
  const isThirdParty = isArtist || isNobiliaOrderProcessor

  const { deliveries, refetch, queryFilters, setQueryFilters } =
    useDeliveryMany()
  const { sortBy, sortDirection } = queryFilters

  const [deliveryToEdit, setDeliveryToEdit] = useState<Delivery>()
  const [shipmentToEdit, setShipmentToEdit] = useState<Shipment>()
  const [supplierOrderToEdit, setSupplierOrderToEdit] =
    useState<SupplierOrder>()

  const COMMON_TOOLTIP_PROPS: Omit<TooltipProps, 'children' | 'label'> = {
    color: Colors.blue700,
  }

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Project</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'date' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'date',
                sortDirection:
                  sortBy === 'date' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Description
          </Table.HeaderCell>
          <Table.HeaderCell>Shipping Address</Table.HeaderCell>
          <Table.HeaderCell>Supplier Orders</Table.HeaderCell>
          <Table.HeaderCell>Delivery Status</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {deliveries.map((d: Delivery) => {
          const { primaryOwner } = parseOwners(d?.project) || {}
          const address = d.supplierOrders?.[0]?.design?.order?.shippingAddress
          if (!primaryOwner) {
            console.warn('No primary owner found:', d)
            return null
          }
          return (
            <Table.Row key={d.id}>
              <Table.Cell width={3} textAlign="left">
                <ProjectOwnersCellContents project={d.project} />
              </Table.Cell>
              <Table.Cell width={2}>
                <ProjectStaffCellContents project={d.project} />
              </Table.Cell>
              <Table.Cell width={2}>
                <p className="no-margin">{d.data?.name}</p>
                <p className="light-gray no-margin x-small">
                  {d.date ? moment(d.date).format('MMMM DD, YYYY') : null}
                </p>
              </Table.Cell>
              <Table.Cell width={2}>
                <AddressFormat address={address} />
              </Table.Cell>
              <Table.Cell width={3} textAlign="left">
                <CellContentsSupplierOrders
                  delivery={d}
                  setShipmentToEdit={setShipmentToEdit}
                  setSupplierOrderToEdit={setSupplierOrderToEdit}
                />
              </Table.Cell>
              <Table.Cell width={3}>
                <CellContentsDamages delivery={d} />
              </Table.Cell>
              <Table.Cell width={1}>
                <div
                  style={{
                    display: 'grid',
                    justifyContent: 'center',
                    rowGap: '8px',
                  }}
                >
                  <ButtonTooltip
                    color="dark"
                    fontAwesomeIcon="edit"
                    kind="solid"
                    onClick={() => setDeliveryToEdit(d)}
                    style={{ marginBottom: '8px' }}
                    tooltipProps={{
                      label: 'Edit delivery',
                      ...COMMON_TOOLTIP_PROPS,
                    }}
                  />
                  {isThirdParty ? null : (
                    <ButtonTooltip
                      color="blue"
                      fontAwesomeIcon="eye"
                      kind="solid"
                      onClick={() =>
                        window.open(
                          `/admin/projects/${d.project?.id}/deliveries/${d.id}`,
                        )
                      }
                      style={{ marginBottom: '8px' }}
                      tooltipProps={{
                        label: 'Open in project',
                        ...COMMON_TOOLTIP_PROPS,
                      }}
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
        <DeliveryModal
          onClose={() => setDeliveryToEdit(undefined)}
          open={!!deliveryToEdit}
          refetch={refetch}
          deliveryId={deliveryToEdit?.id ?? ''}
        />
        <ShipmentModal
          onClose={() => setShipmentToEdit(undefined)}
          open={!!shipmentToEdit}
          refetch={refetch}
          shipmentId={shipmentToEdit?.id ?? ''}
        />
        <SupplierOrderModal
          open={!!supplierOrderToEdit}
          onClose={() => setSupplierOrderToEdit(undefined)}
          refetch={refetch}
          supplierOrderId={supplierOrderToEdit?.id ?? ''}
        />
      </Table.Body>
    </Table>
  )
}

export default DeliveriesTable
