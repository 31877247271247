import React, { useEffect, useMemo } from 'react'

import { useForm, Controller, Control, ControllerProps } from 'react-hook-form'
import { DropdownItemProps, Form, FormDropdownProps } from 'semantic-ui-react'
import styled from 'styled-components'

import { useCommentNoteMany } from 'context/project/project-notes/provider-many'
import useCommentNoteMutate from 'context/project/project-notes/use-mutate'
import useProject from 'context/project/use'
import Button from 'design-system/components/button/button'
import { Modal, ModalBasicProps } from 'design-system/components/modal'
import { CreateCommentNoteVariables } from 'queries/comment-notes'
import { StyledForm } from 'styles/admin/main'
import { CommentType } from 'types/comment'
import { NoteTopic } from 'types/comment-note'

import { NoteTextEditor, NoteTextEditorProps } from './note-text-editor'
import { useFormattedDropdowns } from './use-formatted-dropdowns'
import {
  INoteForm,
  TOPICS,
  getDisabledEditorMessage,
  getNoteTopicVariablesFromData,
} from './utils'

type CreateNotesModalProps = Pick<
  ModalBasicProps,
  'onOpen' | 'onClose' | 'open' | 'onExited'
>

export const CreateNotesModal = ({
  onClose,
  onExited,
  ...modalProps
}: CreateNotesModalProps) => {
  //* STATES, CONST AND HOOKS
  const {
    project: { id: projectId },
  } = useProject()
  const { refetch } = useCommentNoteMany()
  const { createOneCommentNote } = useCommentNoteMutate({ refetch })

  const {
    control,
    watch,
    reset,
    formState: { isValid },
    setValue,
    getValues,
    unregister,
  } = useForm<INoteForm>({
    mode: 'onTouched',
    defaultValues: {
      title: '',
    },
  })
  const selectedTopic = watch('topic')
  const selectedRoom = watch('roomId')

  const {
    formattedDeliveries,
    formattedDesigns,
    formattedIssues,
    formattedMeetings,
    formattedOwners,
    formattedPayments,
    formattedProspects,
    formattedRooms,
    formattedSupplierOrders,
  } = useFormattedDropdowns({ selectedRoom })

  const disableEditor = useMemo(() => {
    switch (selectedTopic) {
      case NoteTopic.CUSTOMER:
        return !formattedOwners.length
      case NoteTopic.DELIVERY:
        return !formattedDeliveries.length
      case NoteTopic.DESIGN:
        if (!formattedRooms.length) return true
        if (!formattedDesigns) return false
        return !formattedDesigns.length
      case NoteTopic.ISSUE:
        return !formattedIssues.length
      case NoteTopic.MEETING:
        return !formattedMeetings.length
      case NoteTopic.PAYMENT:
        return !formattedPayments.length
      case NoteTopic.PROSPECT:
        return !formattedProspects.length
      case NoteTopic.SUPPLIER_ORDER:
        return !formattedSupplierOrders.length
      default:
        return false
    }
  }, [selectedTopic, formattedDesigns])

  //* CALLBACKS
  const handleCreateNote: NoteTextEditorProps['onAddComment'] = async (
    richTextData,
  ): Promise<boolean> => {
    const data = getValues()
    const variablesData: CreateCommentNoteVariables['data'] = {
      body: richTextData.body,
      bodyHtml: richTextData.bodyHtml,
      text: richTextData.text,
      type: CommentType.NOTES,
      title: data.title,
      project: { connect: { id: projectId } },
      ...getNoteTopicVariablesFromData(data),
    }

    if (richTextData.files?.length) {
      variablesData.attachments = {
        connect: richTextData.files.map(({ id }) => ({ id })),
      }
    }

    const successful = await createOneCommentNote({
      variables: { data: variablesData },
    })

    if (!successful) return false

    onClose?.()
    return true
  }

  const resetModal = (node: HTMLElement) => {
    reset({
      topic: null,
      title: '',
    })
    onExited?.(node)
  }

  //* EFFECTS
  useEffect(() => {
    if (!formattedDesigns) {
      unregister('designId')
      return
    }
    setValue(
      'designId',
      formattedDesigns.length === 1 ? formattedDesigns[0].value : null,
    )
  }, [formattedDesigns])

  //* RENDER FUNCTIONS
  const renderExtraFields = () => {
    switch (selectedTopic) {
      case NoteTopic.CUSTOMER:
        return (
          <ControllerDropdown
            control={control}
            name="customerId"
            label="Customer"
            inputPlaceholder="The project has no customer"
            options={formattedOwners}
            noOptionsMessage="customers"
          />
        )
      case NoteTopic.DELIVERY:
        return (
          <ControllerDropdown
            control={control}
            name="deliveryId"
            label="Delivery"
            inputPlaceholder="No deliveries have been created"
            options={formattedDeliveries}
            noOptionsMessage="deliveries"
          />
        )
      case NoteTopic.DESIGN:
        return (
          <StyledFormGroup widths="equal">
            <ControllerDropdown
              control={control}
              name="roomId"
              label="Room"
              inputPlaceholder="No room has been created"
              options={formattedRooms}
              noOptionsMessage="rooms"
            />
            <ControllerDropdown
              control={control}
              name="designId"
              label="Design"
              inputPlaceholder="No design has been created for this room"
              options={formattedDesigns ?? []}
              disableDefaultValue
              noOptionsMessage="designs"
            />
          </StyledFormGroup>
        )

      case NoteTopic.ISSUE:
        return (
          <ControllerDropdown
            control={control}
            name="issueId"
            label="Issue"
            inputPlaceholder="No issues have been created"
            options={formattedIssues}
            noOptionsMessage="issues"
          />
        )

      case NoteTopic.MEETING:
        return (
          <ControllerDropdown
            control={control}
            name="meetingId"
            label="Meeting type"
            inputPlaceholder="No meetings have been created"
            options={formattedMeetings}
            noOptionsMessage="meetings"
          />
        )

      case NoteTopic.PAYMENT:
        return (
          <ControllerDropdown
            control={control}
            name="paymentId"
            label="Payment type"
            inputPlaceholder="No payments have been created"
            options={formattedPayments}
            noOptionsMessage="payments"
          />
        )

      case NoteTopic.PROSPECT:
        return (
          <ControllerDropdown
            control={control}
            name="prospectId"
            label="Prospect"
            inputPlaceholder="No prospect was found"
            options={formattedProspects}
            noOptionsMessage="prospects"
          />
        )
      case NoteTopic.SUPPLIER_ORDER:
        return (
          <ControllerDropdown
            control={control}
            name="supplierOrderId"
            label="Supplier Order"
            inputPlaceholder="No supplier orders have been created"
            options={formattedSupplierOrders}
            noOptionsMessage="supplier orders"
          />
        )
      default:
        return null
    }
  }

  return (
    <Modal
      size="medium"
      trigger={
        <Button
          kind="solid"
          fontAwesomeIcon="plus"
          text="Add note"
          iconPosition="left"
        />
      }
      title="Create Project Note"
      onClose={onClose}
      disableBackdropClick
      onExited={resetModal}
      {...modalProps}
    >
      <StyledForm>
        <Controller
          name="title"
          control={control}
          rules={{
            required: 'Title is required!',
            validate: (value) => {
              const trimmed = value.trim()
              if (trimmed.length < 4)
                return 'Title must be at least 4 characters long!'
              if (hasSpecialChar(trimmed))
                return 'Title cannot contain special characters!'
              return true
            },
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <Form.Input
              label="Title"
              placeholder="Title of the note"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              required
              error={
                error?.message && {
                  content: <p className="red small">{error.message}</p>,
                }
              }
            />
          )}
        />
        <Controller
          control={control}
          name="topic"
          rules={{
            required: true,
          }}
          render={({ field: { onBlur, onChange, value } }) => {
            const handleChange: FormDropdownProps['onChange'] = (_, data) => {
              onChange(data.value)
            }
            return (
              <Form.Dropdown
                fluid
                selection
                search
                required
                label="Topic"
                options={TOPICS}
                onBlur={onBlur}
                onChange={handleChange}
                value={value ?? undefined}
              />
            )
          }}
        />

        {renderExtraFields()}
      </StyledForm>

      <NoteTextEditor
        onAddComment={handleCreateNote}
        projectId={projectId}
        disabled={!isValid}
        onCancel={onClose}
        disableEditor={disableEditor}
        expanded
      />
    </Modal>
  )
}

const hasSpecialChar = (str: string) => /[^\w\s\-:?!]/.test(str)

const StyledFormGroup = styled(Form.Group)`
  align-items: flex-start !important;

  & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

const ControllerDropdown = ({
  control,
  name,
  multiple,
  rules,
  options,
  label,
  inputPlaceholder,
  disableDefaultValue,
  noOptionsMessage,
}: {
  control: Control<INoteForm>
  name: keyof INoteForm
  multiple?: boolean
  rules?: ControllerProps<INoteForm>['rules']
  options: DropdownItemProps[]
  label: string
  inputPlaceholder: string
  disableDefaultValue?: boolean
  noOptionsMessage?: string
}) => {
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      defaultValue={
        !disableDefaultValue && options.length === 1
          ? (options[0].value as string)
          : null
      }
      rules={{
        required: true,
        ...rules,
      }}
      render={({ field: { onBlur, onChange, value } }) => {
        const handleChange: FormDropdownProps['onChange'] = (_, data) => {
          onChange(data.value)
        }

        if (options?.length <= 1) {
          return (
            <>
              <Form.Input
                label={label}
                required
                fluid
                readOnly
                value={options[0]?.text ?? ''}
                placeholder={inputPlaceholder}
                error={
                  options?.length === 0 && noOptionsMessage
                    ? {
                        content: (
                          <p className="red small">
                            {getDisabledEditorMessage(noOptionsMessage)}
                          </p>
                        ),
                      }
                    : undefined
                }
              />
            </>
          )
        }

        return (
          <Form.Dropdown
            fluid
            selection
            search
            required
            multiple={multiple}
            label={label}
            options={options}
            onBlur={onBlur}
            onChange={handleChange}
            value={value ?? undefined}
            disabled={!options}
          />
        )
      }}
    />
  )
}
