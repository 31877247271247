import React from 'react'

import { Divider, Image, List } from 'semantic-ui-react'

import AddressFormat from 'components/shared/address-format'
import { parseOwners } from 'context/project/utils'
import { getSupplierOrderAddress } from 'context/supplier-order/utils'
import Button from 'design-system/components/button'
import { TableUserListItem } from 'styles/admin/main'
import { SupplierOrder } from 'types/supplier-order'

export const SupplierOrderCellContentsLess = ({
  supplierOrder,
  includeAddress,
}: {
  supplierOrder: SupplierOrder
  includeAddress?: boolean
}) => {
  const owner = supplierOrder.project
    ? parseOwners(supplierOrder.project)?.primaryOwner
    : null
  const orderName =
    supplierOrder.nobiliaOrderRef ||
    supplierOrder.data?.nobiliaOrderReference ||
    ''
  const orderDescription =
    supplierOrder.nobiliaAckNumber ||
    supplierOrder.data?.nobiliaFactoryAckNumber

  return (
    <List relaxed>
      <TableUserListItem key={supplierOrder.id}>
        <Image src={owner?.picture} avatar circular />
        <List.Content>
          <p className="no-margin small">{orderName}</p>
          <p className="no-margin x-small light-gray">
            {orderDescription ? `ACK ${orderDescription}` : null}
          </p>
        </List.Content>
      </TableUserListItem>
      {includeAddress ? (
        <div className="light-gray x-small" style={{ padding: '0' }}>
          <AddressFormat address={getSupplierOrderAddress(supplierOrder)} />
        </div>
      ) : null}
      {supplierOrder.project ? (
        <>
          <Divider style={{ margin: '8px 0 0 0' }} />
          <Button
            color="dark"
            fontAwesomeIcon="arrow-up-right-from-square"
            kind="text"
            text="Go to project"
            onClick={() =>
              window.open(
                `/admin/projects/${supplierOrder.project?.id}/overview`,
              )
            }
            style={{ padding: '0' }}
          />
        </>
      ) : null}
    </List>
  )
}
