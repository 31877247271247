import { useContext } from 'react'

import { AppliancePackContext } from './provider'

const useAppliancePack = () => {
  const { appliancePack, refetch } = useContext(AppliancePackContext)

  return {
    appliancePack,
    refetch,
  }
}

export default useAppliancePack
