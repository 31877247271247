import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Breadcrumb, Grid } from 'semantic-ui-react'

import AppliancePacks from 'components/admin/catalog/appliance-pack/index-many'
import useAppliancePackMany from 'context/appliance-pack/use-many'
import { StyledBreadcrumb } from 'styles/planner/main'

const AppliancePacksViewMany = (_: RouteComponentProps) => {
  const { catalog } = useAppliancePackMany()
  const sections = [
    {
      key: 'Catalog',
      content: `Catalog`,
      href: `/admin/catalog/${catalog}`,
    },
    {
      key: 'Appliance Packs',
      content: 'Appliance Packs',
      active: true,
    },
  ]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <StyledBreadcrumb>
            <Breadcrumb
              className="caption"
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'greater-than']}
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    padding: '0 10px',
                  }}
                />
              }
              sections={sections}
            />
          </StyledBreadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3 className="catalog">Appliance Packs</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AppliancePacks />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default AppliancePacksViewMany
