import React from 'react'

import { Grid } from 'semantic-ui-react'

import PageTitle from 'components/admin/page-title'

import Menu from './menu'

const SalesDesignerLayout = (props: React.PropsWithChildren) => {
  return (
    <Grid>
      <PageTitle title="Sales Designer Dashboard" />
      <Grid.Row>
        <Grid.Column>
          <Menu />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{props.children}</Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default SalesDesignerLayout
