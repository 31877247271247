import React from 'react'

import { Table } from 'semantic-ui-react'

import { parseMeetings } from 'context/project/utils'
import { parseFiles } from 'context/project-file/utils-many'
import useShipment from 'context/shipment/use'
import { StyledIcon } from 'styles/admin/main'

const ShipmentChecklist = () => {
  const { supplierOrders } = useShipment()
  return (
    <Table definition celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Guarantee Date Set</Table.HeaderCell>
          <Table.HeaderCell>Installation Pack Released</Table.HeaderCell>
          <Table.HeaderCell>Installation Call Created</Table.HeaderCell>
          <Table.HeaderCell>Installation Call Scheduled</Table.HeaderCell>
          <Table.HeaderCell>Delivery Scheduled</Table.HeaderCell>
          <Table.HeaderCell>Delivery Completed</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {supplierOrders
          .filter((so) => so.project)
          .map((so) => {
            const room = so.design.room
            const project = so.project
            const delivery = so.delivery
            const { installationPacks } = parseFiles(room.files || [])
            const [installationPack] = installationPacks
            const installationPackReleased =
              !!room.data?.installationPackNotIncluded || !!installationPack
            const { meetingPreinstallScheduled, meetingPreinstallOccurred } =
              parseMeetings(project)
            const installCallScheduled =
              meetingPreinstallScheduled || meetingPreinstallOccurred
            return (
              <Table.Row textAlign="center">
                <Table.Cell width={3}>{so.nobiliaOrderRef}</Table.Cell>
                <Table.Cell width={3}>
                  <a
                    target="_blank"
                    href={`/admin/projects/${project.id}/supplier-orders/${so.id}`}
                  >
                    {so.data?.guaranteedOnOrBefore ? (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        icon={['fal', 'check']}
                      />
                    ) : (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        styled={{ inactive: true }}
                        icon={['fal', 'clock']}
                      />
                    )}
                  </a>
                </Table.Cell>
                <Table.Cell width={2}>
                  <a
                    target="_blank"
                    href={`/admin/projects/${project.id}/rooms/${room.id}/packs`}
                  >
                    {installationPackReleased ? (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        icon={['fal', 'check']}
                      />
                    ) : (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        styled={{ inactive: true }}
                        icon={['fal', 'clock']}
                      />
                    )}
                  </a>
                </Table.Cell>
                <Table.Cell width={2}>
                  <a
                    target="_blank"
                    href={`/admin/projects/${project.id}/consultations`}
                  >
                    {installCallScheduled ? (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        icon={['fal', 'check']}
                      />
                    ) : (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        styled={{ inactive: true }}
                        icon={['fal', 'clock']}
                      />
                    )}
                  </a>
                </Table.Cell>
                <Table.Cell width={2}>
                  <a
                    target="_blank"
                    href={`/admin/projects/${project.id}/consultations`}
                  >
                    {installCallScheduled ? (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        icon={['fal', 'check']}
                      />
                    ) : (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        styled={{ inactive: true }}
                        icon={['fal', 'clock']}
                      />
                    )}
                  </a>
                </Table.Cell>
                <Table.Cell width={2}>
                  <a
                    target="_blank"
                    href={`/admin/deliveries${
                      delivery?.id ? `/${delivery?.id}` : ''
                    }`}
                  >
                    {delivery?.date ? (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        icon={['fal', 'check']}
                      />
                    ) : (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        styled={{ inactive: true }}
                        icon={['fal', 'clock']}
                      />
                    )}
                  </a>
                </Table.Cell>
                <Table.Cell width={2}>
                  <a
                    target="_blank"
                    href={`/admin/projects/${project.id}/supplier-orders/${so.id}`}
                  >
                    {so.data?.deliveryAtActual ? (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        icon={['fal', 'check']}
                      />
                    ) : (
                      <StyledIcon
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                        styled={{ inactive: true }}
                        icon={['fal', 'clock']}
                      />
                    )}
                  </a>
                </Table.Cell>
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}

export default ShipmentChecklist
