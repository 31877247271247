import React, { useState } from 'react'

import { Grid } from 'semantic-ui-react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import FileCard from 'components/shared/project/project-file/card'
import { UploadProjectFileCard } from 'components/shared/project/project-file/upload-card'
import useDesign from 'context/design/use'
import useDesignProjectFileMutate from 'context/design/use-project-file-mutate'
import useProject from 'context/project/use'
import { isPDF } from 'context/project-file/utils'
import useRoom from 'context/room/use'
import StyledAccordion from 'design-system/components/accordion'
import Button from 'design-system/components/button'
import { UnderlineSubtitle } from 'design-system/components/guide/utils'
import StatusCard from 'design-system/components/status-card'
import { ProjectFile, ProjectFileType } from 'types/project-file'
import { StatusMode } from 'types/utils'

const DesignPlan = () => {
  const { project, refetch: refetchProject } = useProject()
  const { room, refetch: refetchRoom } = useRoom()
  const {
    design,
    latestPlan,
    latestPlanExternalReviewMode,
    latestPlanExternalReviewText,
    latestPlanInternalReviewMode,
    latestPlanInternalReviewText,
    refetch,
  } = useDesign()
  const { deleteProjectFileAndRefetch, updateProjectFileAndRefetch } =
    useDesignProjectFileMutate()
  const [updatePlan, setUpdatePlan] = useState<boolean>(false)
  const [showConfirmation, toggleConfirmation] = useState<boolean>(false)

  const onFilesUploaded = () => {
    refetch()
    if (updatePlan) setUpdatePlan(false)
  }

  return (
    <StyledAccordion titleText="Design Plan">
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            {latestPlan?.id ? (
              <Grid>
                <Grid.Row centered>
                  {updatePlan ? (
                    <Grid.Column>
                      <UploadProjectFileCard
                        fileType={ProjectFileType.DETAILED_QUOTE}
                        onFilesUploaded={onFilesUploaded}
                        projectId={project.id}
                        roomId={room?.id}
                        designPlanId={design?.id}
                      />
                    </Grid.Column>
                  ) : (
                    <Grid.Column>
                      <FileCard
                        disabled={
                          isPDF(latestPlan) && latestPlan.numPages === null
                        }
                        file={latestPlan}
                        onFileRemoved={async (f: Partial<ProjectFile>) => {
                          deleteProjectFileAndRefetch({
                            variables: {
                              where: {
                                id: f.id ?? '',
                              },
                            },
                          })
                        }}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Button
                      color="gray"
                      disabled={!!updatePlan}
                      fontAwesomeIcon="exchange"
                      onClick={() => toggleConfirmation(true)}
                      kind="outlined"
                      text="Replace Plan"
                    />
                    <ConfirmationModal
                      confirmText="Replace plan"
                      firstTitle="Are you sure you want to update this plan?"
                      description="IMPORTANT! Updating this plan will create a new review and a Tech Designer will be notified there’s a new plan available to be reviewed."
                      onCancel={() => toggleConfirmation(false)}
                      onConfirm={() => {
                        setUpdatePlan(!updatePlan)
                        toggleConfirmation(false)
                      }}
                      open={showConfirmation}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : (
              <UploadProjectFileCard
                fileType={ProjectFileType.DETAILED_QUOTE}
                onFilesUploaded={onFilesUploaded}
                projectId={project.id}
                roomId={room?.id}
                designPlanId={design?.id}
              />
            )}
          </Grid.Column>
          <Grid.Column textAlign="center">
            <UnderlineSubtitle>Internal Review</UnderlineSubtitle>
            <Button
              disabled={!latestPlan}
              color="blue"
              kind="solid"
              size="fluid"
              text="Review Internal Comments"
              style={{ marginBottom: '20px' }}
              onClick={() =>
                window.open(
                  `/admin/projects/${project.id}/rooms/${room?.id}/designs/${design?.id}/plan-internal/${latestPlan?.id}`,
                )
              }
            />
            {latestPlanInternalReviewMode ? (
              <StatusCard
                statusStep={latestPlanInternalReviewText?.summary}
                statusText={latestPlanInternalReviewText?.text}
                statusType={latestPlanInternalReviewMode}
              />
            ) : null}
            {latestPlanInternalReviewMode ? (
              <Button
                color="gray"
                disabled={true}
                fontAwesomeIcon={
                  latestPlanInternalReviewMode !== StatusMode.OpenForEdit
                    ? 'lock'
                    : 'lock-open'
                }
                kind="outlined"
                text={`${
                  latestPlanInternalReviewMode !== StatusMode.OpenForEdit
                    ? 'Review Requested'
                    : 'Request Review'
                }`}
                style={{ marginTop: '20px' }}
                onClick={async () => {
                  if (latestPlanInternalReviewMode === StatusMode.OpenForEdit) {
                    await updateProjectFileAndRefetch({
                      variables: {
                        data: {
                          metadata: {
                            ...latestPlan.metadata,
                            internalReviewRequestedAt: latestPlan.metadata
                              ?.internalReviewRequestedAt
                              ? null
                              : new Date(),
                          },
                        },
                        where: { id: latestPlan.id },
                      },
                    })
                    refetchRoom()
                    refetchProject()
                  }
                }}
              />
            ) : null}
          </Grid.Column>
          <Grid.Column textAlign="center">
            <UnderlineSubtitle>External Review</UnderlineSubtitle>
            <Button
              color="dark"
              disabled={!latestPlan}
              kind="solid"
              size="fluid"
              style={{ marginBottom: '20px' }}
              text="Review External Comments"
              onClick={() =>
                window.open(
                  `/admin/projects/${project.id}/rooms/${room?.id}/designs/${design?.id}/plan-external/${latestPlan?.id}`,
                )
              }
            />
            {latestPlanExternalReviewMode ? (
              <StatusCard
                statusStep={latestPlanExternalReviewText.summary}
                statusText={latestPlanExternalReviewText.text}
                statusType={latestPlanExternalReviewMode}
              />
            ) : null}
            {latestPlanExternalReviewMode &&
            (latestPlanExternalReviewMode === StatusMode.OpenForRevision ||
              latestPlanExternalReviewMode === StatusMode.Submitted) ? (
              <Button
                color="gray"
                fontAwesomeIcon={
                  latestPlanExternalReviewMode === StatusMode.Submitted
                    ? 'lock'
                    : 'lock-open'
                }
                kind="outlined"
                text={`${
                  latestPlanExternalReviewMode === StatusMode.Submitted
                    ? 'Unsubmit'
                    : 'Submit'
                } Comments`}
                style={{ marginTop: '20px' }}
                onClick={async () => {
                  await updateProjectFileAndRefetch({
                    variables: {
                      data: {
                        submittedAt: latestPlan.submittedAt ? null : new Date(),
                      },
                      where: { id: latestPlan.id },
                    },
                  })
                  refetchRoom()
                  refetchProject()
                }}
              />
            ) : null}
            {latestPlanExternalReviewMode &&
            (latestPlanExternalReviewMode === StatusMode.Confirmation ||
              latestPlanExternalReviewMode === StatusMode.Confirmed) ? (
              <Button
                color="gray"
                fontAwesomeIcon={
                  latestPlanExternalReviewMode === StatusMode.Confirmed
                    ? 'lock'
                    : 'lock-open'
                }
                kind="text"
                text={`${
                  latestPlanExternalReviewMode === StatusMode.Confirmed
                    ? 'Unapprove'
                    : 'Approve'
                } Plan`}
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  width: '100%',
                }}
                onClick={async () => {
                  await updateProjectFileAndRefetch({
                    variables: {
                      data: {
                        approvedAt: latestPlan.approvedAt ? null : new Date(),
                      },
                      where: { id: latestPlan.id },
                    },
                  })
                  refetchRoom()
                  refetchProject()
                }}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledAccordion>
  )
}

export default DesignPlan
