import React, { createContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { VisibilityCalculations } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import { apolloClient } from 'context/authorized-apollo/provider'
import {
  FindManyRenderPropPayload,
  FindManyRenderPropVariables,
  FIND_MANY_RENDER_PROP,
} from 'queries/render-prop'
import { RenderProp } from 'types/render-prop'

import { QueryFilters, getQueryVariables } from './utils-many'

interface IRenderPropManyContext {
  catalog: string
  renderProps: RenderProp[]
  count: number
  loading: boolean
  loadingMore: boolean
  fetchMore: (v: VisibilityCalculations) => Promise<void>
  fetchMoreOnDemand: () => Promise<void>
  queryFilters: QueryFilters
  refetch: () => Promise<unknown>
  setQueryFilters: React.Dispatch<React.SetStateAction<QueryFilters>>
}

type RenderPropManyProviderProps = React.PropsWithChildren<{
  catalog?: string
  defaultFilters: QueryFilters
  skipLoader?: boolean
}>

export const RenderPropManyContext = createContext<IRenderPropManyContext>(
  {} as IRenderPropManyContext,
)

const RenderPropManyProvider = ({
  catalog,
  children,
  defaultFilters,
  skipLoader,
}: RenderPropManyProviderProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [queryFilters, setQueryFilters] = useState<QueryFilters>(defaultFilters)
  const [queryVariables, setQueryVariables] =
    useState<FindManyRenderPropVariables>(getQueryVariables(queryFilters))
  const {
    data: { renderProps, count } = { renderProps: [], count: 0 },
    loading,
    fetchMore,
  } = useQuery<FindManyRenderPropPayload, FindManyRenderPropVariables>(
    FIND_MANY_RENDER_PROP,
    {
      variables: queryVariables,
    },
  )

  useEffect(() => {
    // this effect triggers on refetch, something to do with the cache eviction
    // triggering a higher level re-render.
    // To avoid losing filters on refetch we add the refetching check here
    if (!refetching) {
      setQueryFilters(defaultFilters)
    }
  }, [defaultFilters])

  useEffect(() => {
    setQueryVariables(getQueryVariables(queryFilters))
  }, [queryFilters])

  const fetchMore_ = async ({ bottomVisible }: VisibilityCalculations) => {
    if (
      loading ||
      loadingMore ||
      !bottomVisible ||
      renderProps.length === count
    )
      return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: renderProps.length,
      },
    })
    setLoadingMore(false)
  }

  const fetchMoreOnDemand = async () => {
    if (loading || loadingMore || renderProps.length === count) return

    setLoadingMore(true)
    await fetchMore({
      variables: {
        skip: renderProps.length,
      },
    })
    setLoadingMore(false)
  }

  const refetch = async () => {
    setRefetching(true)
    // evict and refetch first batch
    apolloClient?.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'findManyRenderProp',
    })
  }

  if (loading && !skipLoader) return <Loader />

  return (
    <RenderPropManyContext.Provider
      value={{
        catalog: catalog ?? '',
        renderProps: renderProps,
        count: count ?? 0,
        loading,
        loadingMore,
        fetchMore: fetchMore_,
        fetchMoreOnDemand,
        queryFilters,
        refetch,
        setQueryFilters,
      }}
    >
      {children}
    </RenderPropManyContext.Provider>
  )
}

export default RenderPropManyProvider
