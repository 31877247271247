import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sortBy } from 'lodash'
import moment from 'moment'
import { Header, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import { prospectMeetingType } from 'context/prospect/utils'
import Button from 'design-system/components/button'
import { Meeting, MeetingOutcome, MeetingTypeProspect } from 'types/meeting'
import { Prospect } from 'types/prospect'

export const meetingOutcomeToStarCount = (outcome: MeetingOutcome) => {
  switch (outcome) {
    case MeetingOutcome.FIVE_STARS:
      return 5
    case MeetingOutcome.FOUR_STARS:
      return 4
    case MeetingOutcome.THREE_STARS:
      return 3
    case MeetingOutcome.TWO_STARS:
      return 2
    case MeetingOutcome.ONE_STAR:
      return 1
    case MeetingOutcome.NO_SHOW:
      return 0
    default:
      return 0
  }
}

export const prospectMeetingIcon = (meeting: Meeting) => {
  if (meeting.metadata.type === MeetingTypeProspect.FREE) return 'video'
  if (
    meeting.metadata.type === MeetingTypeProspect.FREE_VISIT_SF ||
    meeting.metadata.type === MeetingTypeProspect.FREE_VISIT_NY
  )
    return 'house-return'
  return 'video'
}

export const ProspectStatus = ({ prospect }: { prospect: Prospect }) => {
  if (prospect.data.closedAt) {
    return (
      <StatusWithIcon color="#9D5B45">
        <div>
          <FontAwesomeIcon icon={['fal', 'times-square']} />
        </div>
        <p className="small">Closed Prospect</p>
      </StatusWithIcon>
    )
  }

  const [project] = sortBy(prospect.projects, (p) => p.project?.createdAt)
  if (project) {
    return (
      <Button
        kind="text"
        onClick={() =>
          window.open(`/admin/projects/${project.project?.id}/overview`)
        }
        text={`Project Created ${moment(project.project?.createdAt).format(
          'LL',
        )}`}
        fontAwesomeIcon="check-circle"
        iconPosition="left"
      />
    )
  }

  const nextMeeting = sortBy(prospect?.meetings, 'startTime').find(
    (m) =>
      m.startTime && !m.canceledAt && moment(m.startTime).isAfter(moment()),
  )
  if (nextMeeting) {
    return (
      <Header as="h4" image style={{ marginTop: 0 }}>
        <Header.Content>
          <Header.Subheader>
            <p className="gray small">
              {prospectMeetingType(nextMeeting)}{' '}
              {nextMeeting.calendlyResponse?.payload?.old_invitee?.is_reschedule
                ? 're'
                : ''}
              scheduled for
            </p>
          </Header.Subheader>
          {moment(nextMeeting.startTime).format('LL')}
        </Header.Content>
      </Header>
    )
  }

  return (
    <div>
      <p>Open</p>
      {prospect.data.followUpCallDate ? (
        <Header as="h4" image style={{ marginTop: 0 }}>
          <Header.Content>
            <Header.Subheader>
              <p className="gray small">Suggested Follow Up Date</p>
            </Header.Subheader>
            {moment(prospect.data.followUpCallDate).format('MM/DD/YYYY')}
          </Header.Content>
        </Header>
      ) : (
        ''
      )}
    </div>
  )
}

export const ProspectStatusTable = ({
  prospect,
}: {
  prospect: Prospect | null
}) => {
  if (!prospect) return null
  return (
    <Table definition compact>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={6}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <FontAwesomeIcon icon={['fal', 'clipboard-check']} />
              <p style={{ marginLeft: '12px' }}>Status:</p>
            </div>
          </Table.Cell>
          <Table.Cell>
            <ProspectStatus prospect={prospect} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

const StatusWithIcon = styled.div<{ color: string }>`
  display: flex;
  align-items: center;

  div {
    align-items: center;
    background: ${(props) => props.color};
    border-radius: 50%;
    color: white;
    display: flex;
    font-size: 11px;
    height: 20px;
    justify-content: center;
    margin-right: 11px;
    min-width: 20px;
  }
`
