import { useMutation } from '@apollo/client'

import {
  CREATE_ONE_MEDIA,
  CreateMediaPayload,
  CreateMediaVariables,
  DELETE_ONE_MEDIA,
  DeleteMediaPayload,
  DeleteMediaVariables,
  UPDATE_ONE_MEDIA,
  UpdateMediaPayload,
  UpdateMediaVariables,
} from 'queries/media'

const useMediaMutate = () => {
  const [createMedia, { loading: loadingCreate }] = useMutation<
    CreateMediaPayload,
    CreateMediaVariables
  >(CREATE_ONE_MEDIA)

  const [deleteMedia, { loading: loadingDelete }] = useMutation<
    DeleteMediaPayload,
    DeleteMediaVariables
  >(DELETE_ONE_MEDIA)

  const [updateMedia, { loading: loadingUpdate }] = useMutation<
    UpdateMediaPayload,
    UpdateMediaVariables
  >(UPDATE_ONE_MEDIA)

  return {
    createMedia,
    deleteMedia,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateMedia,
  }
}

export default useMediaMutate
