import { useMemo } from 'react'

import useDeliveryMany from 'context/delivery/use-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { ProjectStaffRole, PROJECT_STAFF_ROLES } from 'types/project-staff'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormDropdownValue,
  IFormRadioValue,
} from '../filters-search'

export type DeliveryFilterValues = {
  assignedTo: IFormDropdownValue
  assignedToRole: IFormDropdownValue
  deliveryStatus: IFormRadioValue
  damagesStatus: IFormRadioValue
}
const useDeliveriesFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useDeliveryMany()
  const { userOptions, FORMEmployeeIDToName } = useFORMEmployees()

  const filters: DynamicFilterGroup<DeliveryFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'assignedTo',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Assigned to',
            options: [
              ...userOptions,
              {
                key: 'everyone',
                text: 'Everyone',
                value: 'Everyone',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'assignedToRole',
            type: DynamicFilterTypes.DROPDOWN,
            label: 'Assigned role',
            options: PROJECT_STAFF_ROLES.map((r) => {
              return {
                key: r,
                text: capsSnakeCaseToTitleCase(r),
                value: r,
              }
            }),
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'deliveryStatus',
            type: DynamicFilterTypes.RADIO,
            label: 'Delivery status',
            options: [
              {
                text: 'All',
                value: 'all',
              },
              {
                text: 'Upcoming',
                value: 'upcoming',
              },
              {
                text: 'Delivered',
                value: 'delivered',
              },
            ],
          },
        ],
      },
      {
        filters: [
          {
            filterId: 'damagesStatus',
            type: DynamicFilterTypes.RADIO,
            label: 'Damages status',
            options: [
              {
                text: 'All',
                value: 'all',
              },
              {
                text: 'Damages',
                value: 'damages',
              },
              {
                text: 'No damages',
                value: 'noDamages',
              },
            ],
          },
        ],
      },
    ]
  }, [userOptions])

  const defaultFilters = useMemo(() => {
    const deliveryFilters: Partial<DeliveryFilterValues> = {}

    if (queryFilters.assignedTo?.id)
      deliveryFilters.assignedTo = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedTo.id,
          text: FORMEmployeeIDToName[queryFilters.assignedTo.id],
        },
      }
    if (queryFilters.assignedToRole)
      deliveryFilters.assignedToRole = {
        type: DynamicFilterTypes.DROPDOWN,
        selection: {
          value: queryFilters.assignedToRole,
          text: capsSnakeCaseToTitleCase(queryFilters.assignedToRole),
        },
      }
    if (queryFilters.deliveryStatus)
      deliveryFilters.deliveryStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.deliveryStatus,
          text: capsSnakeCaseToTitleCase(queryFilters.deliveryStatus),
        },
      }
    if (queryFilters.damagesStatus)
      deliveryFilters.damagesStatus = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.damagesStatus,
          text: capsSnakeCaseToTitleCase(queryFilters.damagesStatus),
        },
      }

    return deliveryFilters
  }, [queryFilters])

  const handleApplyFilters = (data: DeliveryFilterValues) => {
    setQueryFilters({
      assignedTo: { id: data.assignedTo?.selection.value ?? '' },
      assignedToRole: data.assignedToRole?.selection.value as ProjectStaffRole,
      deliveryStatus:
        data.deliveryStatus?.selection.value === 'all'
          ? undefined
          : (data.deliveryStatus?.selection.value as
              | 'all'
              | 'upcoming'
              | 'delivered'),
      damagesStatus:
        data.damagesStatus?.selection.value === 'all'
          ? undefined
          : (data.damagesStatus?.selection.value as
              | 'all'
              | 'damages'
              | 'noDamages'),
      show: queryFilters.show,
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
      ...(data.assignedTo?.selection.value === 'Everyone' && {
        assignedTo: null,
        assignedToRole: null,
      }),
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useDeliveriesFiltersSearch
