import React from 'react'

import { Divider } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'
import { StatusMode } from 'types/utils'

import StatusLabel from '../status-label'

interface StatusCardProps {
  statusText: string
  statusType?: StatusMode | null
  statusStep: string
}

const StatusCard = (props: StatusCardProps) => {
  return (
    <StatusDiv>
      <p className="overline gray">Status</p>
      <Divider />
      <div className="status-content">
        <p className="light-gray no-margin step">{props.statusStep}</p>
        <StatusLabel type={props.statusType} text={props.statusText} />
      </div>
    </StatusDiv>
  )
}

export default StatusCard

const StatusDiv = styled.div`
  background: ${Colors.white};
  border-radius: 4px;
  padding: 16px;
  text-align: left;

  .status-content {
    display: flex;
    flex-direction: row;
    p.step {
      width: 50%;
    }
  }
`
