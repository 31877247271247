import React, { useEffect, useState } from 'react'

import lodash, { sortBy } from 'lodash'
import moment from 'moment'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import { Form } from 'semantic-ui-react'

import useTaskMutate from 'context/task/use-mutate-no-context'
import useFORMEmployees from 'context/user/use-form-employees'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { Task } from 'types/task'
import { User } from 'types/user'
import * as viewUtils from 'views/utils'

interface CreateUpdateTaskModalProps {
  projectId?: string
  projectStaff?: User[]
  refetch?: () => void
  onClose?: () => void
  open: boolean
  task?: Task | null
}

const CreateUpdateTaskModal = ({
  onClose,
  open,
  projectId,
  projectStaff,
  refetch,
  task,
}: CreateUpdateTaskModalProps) => {
  const { users, userOptions } = useFORMEmployees()
  const { createTask, updateTask } = useTaskMutate()
  const [state, setState] = useState({
    description: task ? task.data.description : '',
    dueAt: task?.data.dueAt
      ? moment(task.data.dueAt).format('MM/DD/YYYY hh:mm a')
      : null,
    ownerId: task ? task.owner.id : null,
  })

  useEffect(() => {
    if (task)
      setState({
        description: task.data.description,
        dueAt: task?.data.dueAt
          ? moment(task.data.dueAt).format('MM/DD/YYYY hh:mm a')
          : null,
        ownerId: task.owner.id,
      })
  }, [task])

  const onCloseModal = () => {
    setState({
      description: task ? task.data.description : '',
      dueAt: task?.data.dueAt
        ? moment(task.data.dueAt).format('MM/DD/YYYY hh:mm a')
        : null,
      ownerId: task ? task.owner.id : null,
    })
    onClose?.()
  }

  const usersOptionsBuild = (users: User[], assigned: string) => {
    if (!users || users.length === 0) return []

    return [
      {
        disabled: true,
        key: assigned,
        text: assigned,
        value: assigned,
      },
      ...sortBy(
        users.map((user) => ({
          disabled: false,
          image: {
            avatar: true,
            src: user?.picture,
          },
          key: user?.id,
          text: viewUtils.getNameForUser(user),
          value: user?.id,
        })),
        'text',
      ),
    ]
  }

  const designerOptions = () => {
    const usersGrouped = lodash.groupBy(users, (user) =>
      projectStaff?.find((u) => u.email === user.email)
        ? 'Assigned'
        : 'Unassigned',
    )
    return [
      ...usersOptionsBuild(usersGrouped['Assigned'], 'Assigned'),
      ...usersOptionsBuild(usersGrouped['Unassigned'], 'Unassigned'),
    ]
  }

  const onSubmit = async () => {
    if (task) {
      await updateTask({
        variables: {
          data: {
            data: {
              ...task.data,
              description: state.description,
              dueAt: moment(state.dueAt, 'MM/DD/YYYY hh:mm a').toDate(),
            },
            owner: {
              connect: {
                id: state.ownerId ?? '',
              },
            },
          },
          where: {
            id: task.id,
          },
        },
      })
    } else
      await createTask({
        variables: {
          data: {
            data: {
              description: state.description,
              dueAt: moment(state.dueAt, 'MM/DD/YYYY hh:mm a').toDate(),
            },
            project: {
              connect: {
                id: projectId ?? '',
              },
            },
            owner: {
              connect: {
                id: state.ownerId ?? '',
              },
            },
          },
        },
      })
    refetch?.()
  }

  return (
    <Modal
      onClose={onCloseModal}
      open={open}
      size="small"
      title={task ? 'Edit Task' : 'Add a Task'}
      saveButton={{
        disabled: !state.description || !state.ownerId,
        onClick: async () => {
          await onSubmit()
          onCloseModal()
        },
      }}
    >
      <StyledForm
        onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) =>
          e.preventDefault()
        }
        styled={{ grayInputs: true }}
      >
        <Form.Dropdown
          label="Designer"
          onChange={(_, { value }) =>
            setState({
              ...state,
              ownerId: value as string,
            })
          }
          options={projectStaff ? designerOptions() : userOptions}
          placeholder="Select Designer"
          search
          selection
          value={state.ownerId ?? ''}
        />
        <Form.Input
          label="Task"
          onChange={(
            _: React.ChangeEvent<HTMLInputElement>,
            { value }: { value: string },
          ) =>
            setState({
              ...state,
              description: value,
            })
          }
          placeholder="Enter Task"
          value={state.description}
        />
        <Form.Field>
          <label>Due On</label>
          <DateTimeInput
            closable
            dateFormat={'MM/DD/YYYY'}
            timeFormat={'ampm'}
            duration={0}
            initialDate={moment().format('MM/DD/YYYY hh:mm a')}
            name="dueOn"
            onChange={(
              _: React.SyntheticEvent<HTMLElement, Event>,
              { value }: { value: string },
            ) => {
              setState({
                ...state,
                dueAt: value,
              })
            }}
            value={state.dueAt || ''}
            disableMinute={true}
          />
        </Form.Field>
      </StyledForm>
    </Modal>
  )
}

export default CreateUpdateTaskModal
