import React from 'react'

import { RouteComponentProps } from '@reach/router'

import CustomerSupportProvider from 'context/dashboard/customer-support/provider'

const CustomerSupportIndex = (
  props: React.PropsWithChildren<RouteComponentProps<{ user_id: string }>>,
) => {
  return (
    <CustomerSupportProvider user_id={props.user_id ?? ''}>
      {props.children}
    </CustomerSupportProvider>
  )
}

export default CustomerSupportIndex
