import React from 'react'

import { useSelectionCenters } from 'context/selection-centers/provider'
import { Modal } from 'design-system/components/modal'

import SelectionCenterTab from './tab'

type SelectionCenterModalProps = {
  onClose: () => void
  open: boolean
  onExited?: () => void
}

const SelectionCenterModal = ({
  onClose,
  open,
  onExited,
}: SelectionCenterModalProps) => {
  const { selectionCenter, refetch } = useSelectionCenters()

  const onComplete = () => {
    refetch()
    onClose()
  }

  return (
    <Modal
      title={
        selectionCenter ? 'Update Selection Center' : 'Create Selection Center'
      }
      onClose={onClose}
      open={open}
      onExited={onExited}
      size="large"
    >
      <SelectionCenterTab
        selectionCenter={selectionCenter}
        onComplete={onComplete}
      />
    </Modal>
  )
}

export default SelectionCenterModal
