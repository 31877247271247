import { useContext, useMemo } from 'react'

import useDesignsAwaitingSignoffCount from 'context/design/use-awaiting-signoff-count'
import { parseTasks } from 'context/task/utils-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { ProjectStaffRole } from 'types/project-staff'

import { TradeDesignerContext } from './provider'

const useTradeDesigner = () => {
  const {
    meetings,
    messages,
    projects,
    refetchMessages,
    refetchTasks,
    refetchProjects,
    tasks,
    userId,
  } = useContext(TradeDesignerContext)

  const { designsAwaitingSignoffCount } = useDesignsAwaitingSignoffCount(
    userId,
    ProjectStaffRole.TRADE_DESIGNER,
  )

  const { FORMEmployeeIDToName } = useFORMEmployees()
  const userName = FORMEmployeeIDToName[userId]

  const parsedTasks = useMemo(() => parseTasks(tasks), [tasks])

  return {
    designsAwaitingSignoffCount,
    meetings,
    messages,
    projects,
    refetchMessages,
    refetchProjects,
    refetchTasks,
    userId,
    userName,
    ...parsedTasks,
  }
}

export default useTradeDesigner
