import { IconName } from '@fortawesome/fontawesome-svg-core'

import { meetingTypeName } from 'context/meeting/mappers'
import { getPaymentNameFromType } from 'context/payment/utils'
import { isFORMStaff } from 'context/user/utils'
import { MeetingTypeProspect } from 'types/meeting'
import { OrderType } from 'types/order'
import { EventSubtype, EventType, ProjectEvent } from 'types/project-event'
import { ProjectFileType } from 'types/project-file'
import {
  UserType,
  UserTypeHomeowner,
  UserTypePartner,
  UserTypeTrade,
} from 'types/user'

export const getEventSubtype = (type: EventType): EventSubtype =>
  mapEventTypeToSubtype[type]

export const getEventTypeIcon = (type: EventType): IconName => {
  const icon = mapEventSubtypeToIcon[getEventSubtype(type)]

  return icon ?? 'hexagon'
}

export const getPublisherTag = (userType: UserType) => {
  if (userType === UserTypeHomeowner.HOMEOWNER) {
    return 'Homeowner'
  }
  if (userType === UserTypeHomeowner.ARCHITECT) {
    return 'Architect'
  }
  if (userType === UserTypeHomeowner.INSTALLER) {
    return 'Installer'
  }
  if (userType === UserTypePartner.ARTIST) {
    return 'ArchiCGI'
  }
  if (userType === UserTypeTrade.BUILDER) {
    return 'Builder'
  }
  if (userType === UserTypeTrade.DEVELOPER) {
    return 'Developer'
  }
  if (userType === UserTypeHomeowner.INTERIOR_DESIGNER) {
    return 'Interior Designer'
  }
  if (userType === UserTypeTrade.MARKETING_PARTNER) {
    return 'Marketing Partner'
  }
  if (userType === UserTypeTrade.REALTOR) {
    return 'Realtor'
  }
  return ''
}

export const getEventTitle = ({
  publisher,
  type,
  data: { count },
}: ProjectEvent) => {
  let title = mapEventTypeToTitle[type]
  if (!title) {
    return type
  }
  if (count > 1) {
    title = `${title} (${count}x)`
  }

  if (type === EventType.MESSAGE_CREATED) {
    title = `${title} ${isFORMStaff(publisher?.type) ? 'Sent' : 'Received'}`
  }
  return title
}

export const getEventSubtitle = (event: ProjectEvent) => {
  const subtype = getEventSubtype(event.type)
  if (subtype === EventSubtype.MEETING) {
    return meetingTypeName[event.data.meetingType]
  }
  if (subtype === EventSubtype.PAYMENT) {
    return getPaymentNameFromType(event.data.paymentType)
  }
  if (subtype === EventSubtype.SUPPLIER_ORDER) {
    return event.data.supplierOrderName || event.data.supplierOrderType
  }
  if (subtype === EventSubtype.ORDER) {
    return `${mapOrderTypeToSubtitle[event.data.orderType]}`
  }
  if (subtype === EventSubtype.COMMENT) {
    return `${event.data.roomName || ''} - ${event.data.designName || ''}`
  }
  if (subtype === EventSubtype.PROJECT_FILE) {
    return mapProjectFileTypeToSubtitle[event.data.projectFileType]
  }
  if (event.data.roomName) {
    if (event.data.designName) {
      return `${event.data.roomName || ''} - ${event.data.designName}`
    }
    return event.data.roomName || ''
  }
  return ''
}

const mapEventTypeToSubtype: Record<EventType, EventSubtype> = {
  [EventType.COMMENT_CREATED]: EventSubtype.COMMENT,
  [EventType.COMMENT_CREATED_INTERNAL]: EventSubtype.COMMENT,
  [EventType.DELIVERY_DAMAGE_REPORTED]: EventSubtype.DELIVERY,
  [EventType.DELIVERY_UPDATED]: EventSubtype.DELIVERY,
  [EventType.DESIGN_CREATED]: EventSubtype.DESIGN,
  [EventType.DESIGN_PLAN_APPROVED]: EventSubtype.DESIGN,
  [EventType.DESIGN_PLAN_APPROVED_INTERNALLY]: EventSubtype.DESIGN,
  [EventType.DESIGN_PLAN_INTERNAL_REVIEW_REQUESTED]: EventSubtype.DESIGN,
  [EventType.DESIGN_PLAN_SUBMITTED]: EventSubtype.DESIGN,
  [EventType.DESIGN_PLAN_SUBMITTED_INTERNALLY]: EventSubtype.DESIGN,
  [EventType.DESIGN_READY_FOR_RENDER]: EventSubtype.RENDER,
  [EventType.DESIGN_RENDERS_UPLOADED]: EventSubtype.RENDER,
  [EventType.DESIGN_UPDATED]: EventSubtype.DESIGN,
  [EventType.ISSUE_ADDED]: EventSubtype.ISSUE,
  [EventType.MEETING_CANCELED]: EventSubtype.MEETING,
  [EventType.MEETING_CREATED]: EventSubtype.MEETING,
  [EventType.MEETING_SCHEDULED]: EventSubtype.MEETING,
  [EventType.MESSAGE_CREATED]: EventSubtype.MESSAGE,
  [EventType.ORDER_PLACED]: EventSubtype.ORDER,
  [EventType.ORDER_UPDATED]: EventSubtype.ORDER,
  [EventType.PAYMENT_FAILED]: EventSubtype.PAYMENT,
  [EventType.PAYMENT_INVOICE_CREATED]: EventSubtype.PAYMENT,
  [EventType.PAYMENT_PENDING]: EventSubtype.PAYMENT,
  [EventType.PAYMENT_SUCCEEDED]: EventSubtype.PAYMENT,
  [EventType.PROJECT_ACCESSED]: EventSubtype.SIGN_IN,
  [EventType.PROJECT_ADDRESS_UPDATED]: EventSubtype.PROJECT,
  [EventType.PROJECT_ARCHIVED]: EventSubtype.PROJECT,
  [EventType.PROJECT_CREATED]: EventSubtype.PROJECT,
  [EventType.PROJECT_OWNERS_ADDED]: EventSubtype.PROJECT,
  [EventType.PROJECT_UNARCHIVED]: EventSubtype.PROJECT,
  [EventType.PROJECT_UPDATED]: EventSubtype.PROJECT,
  [EventType.PROJECT_FILE_DELETED]: EventSubtype.PROJECT_FILE,
  [EventType.PROJECT_FILE_UPLOADED]: EventSubtype.PROJECT_FILE,
  [EventType.ROOM_APPLIANCES_CONFIRMED]: EventSubtype.ROOM,
  [EventType.ROOM_APPLIANCE_CHANGES_SUBMITTED]: EventSubtype.ROOM,
  [EventType.ROOM_APPLIANCES_UPDATED]: EventSubtype.ROOM,
  [EventType.ROOM_DESIGN_BRIEF_APPROVED]: EventSubtype.ROOM,
  [EventType.ROOM_MEASUREMENTS_CONFIRMED]: EventSubtype.ROOM,
  [EventType.ROOM_MEASUREMENT_CHANGES_SUBMITTED]: EventSubtype.ROOM,
  [EventType.ROOM_MEASUREMENTS_UPDATED]: EventSubtype.ROOM,
  [EventType.ROOM_PACK_DISABLED]: EventSubtype.ROOM,
  [EventType.ROOM_UPDATED]: EventSubtype.ROOM,
  [EventType.SUPPLIER_ORDER_APPROVED]: EventSubtype.SUPPLIER_ORDER,
  [EventType.SUPPLIER_ORDER_CREATED]: EventSubtype.SUPPLIER_ORDER,
  [EventType.USER_CREATED]: EventSubtype.USER,
  [EventType.USER_UPDATED]: EventSubtype.USER,
}

const mapEventSubtypeToIcon: Record<EventSubtype, IconName> = {
  [EventSubtype.COMMENT]: 'comment-alt',
  [EventSubtype.DELIVERY]: 'truck',
  [EventSubtype.DESIGN]: 'book',
  [EventSubtype.ISSUE]: 'bone-break',
  [EventSubtype.MEETING]: 'video',
  [EventSubtype.MESSAGE]: 'envelope',
  [EventSubtype.ORDER]: 'box',
  [EventSubtype.PAYMENT]: 'dollar-sign',
  [EventSubtype.PROJECT]: 'cog',
  [EventSubtype.PROJECT_FILE]: 'file',
  [EventSubtype.RENDER]: 'images',
  [EventSubtype.ROOM]: 'home-heart',
  [EventSubtype.SIGN_IN]: 'arrow-right-to-bracket',
  [EventSubtype.SUPPLIER_ORDER]: 'clipboard-list',
  [EventSubtype.USER]: 'user',
}

const mapEventTypeToTitle: Record<EventType, string> = {
  [EventType.COMMENT_CREATED]: 'Commment Created',
  [EventType.COMMENT_CREATED_INTERNAL]: 'Internal Comment Created',
  [EventType.DELIVERY_DAMAGE_REPORTED]: 'Delivery Damage Reported',
  [EventType.DELIVERY_UPDATED]: 'Delivery Updated',
  [EventType.DESIGN_CREATED]: 'Design Created',
  [EventType.DESIGN_PLAN_APPROVED]: 'Design Plan Approved',
  [EventType.DESIGN_PLAN_APPROVED_INTERNALLY]:
    'Design Plan Approved Internally',
  [EventType.DESIGN_PLAN_INTERNAL_REVIEW_REQUESTED]:
    'Design Plan Review Requested',
  [EventType.DESIGN_PLAN_SUBMITTED]: 'Design Plan Submitted',
  [EventType.DESIGN_PLAN_SUBMITTED_INTERNALLY]: 'Design Plan Review Submitted',
  [EventType.DESIGN_READY_FOR_RENDER]: 'Design Ready for Render',
  [EventType.DESIGN_RENDERS_UPLOADED]: 'Render Photos Ready',
  [EventType.DESIGN_UPDATED]: 'Design Updated',
  [EventType.ISSUE_ADDED]: 'Issue Added',
  [EventType.MEETING_CANCELED]: 'Meeting Canceled',
  [EventType.MEETING_CREATED]: 'Meeting Created',
  [EventType.MEETING_SCHEDULED]: 'Meeting Scheduled',
  [EventType.MESSAGE_CREATED]: 'Message',
  [EventType.ORDER_PLACED]: 'Order Placed',
  [EventType.ORDER_UPDATED]: 'Order Updated',
  [EventType.PAYMENT_FAILED]: 'Payment Failed',
  [EventType.PAYMENT_INVOICE_CREATED]: 'Invoice Payment Created',
  [EventType.PAYMENT_PENDING]: 'Payment Pending',
  [EventType.PAYMENT_SUCCEEDED]: 'Payment Succeeded',
  [EventType.PROJECT_ACCESSED]: 'Sign In',
  [EventType.PROJECT_ADDRESS_UPDATED]: 'Address Updated',
  [EventType.PROJECT_ARCHIVED]: 'Project Archived',
  [EventType.PROJECT_CREATED]: 'Project Created',
  [EventType.PROJECT_OWNERS_ADDED]: 'Collaborators Added',
  [EventType.PROJECT_UNARCHIVED]: 'Project Unarchived',
  [EventType.PROJECT_UPDATED]: 'Project Updated',
  [EventType.PROJECT_FILE_DELETED]: 'File Deleted',
  [EventType.PROJECT_FILE_UPLOADED]: 'File Uploaded',
  [EventType.ROOM_APPLIANCES_CONFIRMED]: 'Appliances Confirmed',
  [EventType.ROOM_APPLIANCE_CHANGES_SUBMITTED]: 'Appliance Changes Submitted',
  [EventType.ROOM_APPLIANCES_UPDATED]: 'Appliances Updated',
  [EventType.ROOM_DESIGN_BRIEF_APPROVED]: 'Design Brief Approved',
  [EventType.ROOM_MEASUREMENTS_CONFIRMED]: 'Measurements Confirmed',
  [EventType.ROOM_MEASUREMENT_CHANGES_SUBMITTED]:
    'Measurements Changes Submitted',
  [EventType.ROOM_MEASUREMENTS_UPDATED]: 'Measurements Updated',
  [EventType.ROOM_PACK_DISABLED]: 'Pack Disabled',
  [EventType.ROOM_UPDATED]: 'Room Updated',
  [EventType.SUPPLIER_ORDER_APPROVED]: 'Supplier Order Approved',
  [EventType.SUPPLIER_ORDER_CREATED]: 'Supplier Order Created',
  [EventType.USER_CREATED]: 'User Created',
  [EventType.USER_UPDATED]: 'User Updated',
}

const mapOrderTypeToSubtitle: Record<OrderType, string> = {
  [OrderType.CABINETRY]: 'Cabinetry',
  [OrderType.SAMPLES]: 'Samples',
}

const mapProjectFileTypeToSubtitle: Record<ProjectFileType, string> = {
  [ProjectFileType.ACK_FILE]: 'Ack File',
  [ProjectFileType.APPLIANCE_SPEC]: 'Appliance Spec',
  [ProjectFileType.BUILDZOOM_PHOTO]: 'Buildzoom Photo',
  [ProjectFileType.BUSINESS_LICENSE]: 'Business License',
  [ProjectFileType.COMMENT_ATTACHMENT]: 'Comment Attachment',
  [ProjectFileType.CONCEPT_IMAGE]: 'Concept Image',
  [ProjectFileType.CONSTRUCTION_PACK]: 'Construction Pack',
  [ProjectFileType.COUNTERTOP_ESTIMATE]: 'Countertop Estimate',
  [ProjectFileType.DAMAGE_PHOTO]: 'Damage Photo',
  [ProjectFileType.DELIVERY_NOTE]: 'Delivery Note',
  [ProjectFileType.DELIVERY_PALLETE]: 'Delivery Pallete',
  [ProjectFileType.DESIGN_BRIEF]: 'Design Brief',
  [ProjectFileType.DESIGN_BRIEF_ATTACHMENT]: 'Design Brief Attachment',
  [ProjectFileType.DETAILED_QUOTE]: 'Detailed Quote',
  [ProjectFileType.FLOOR_PLAN]: 'Floor Plan',
  [ProjectFileType.INITIAL_COSTING]: 'Initial Costing',
  [ProjectFileType.INSPIRATION_IMAGE]: 'Inspiration Image',
  [ProjectFileType.INSTALLATION_PACK]: 'Installation Pack',
  [ProjectFileType.INSTALLATION_PHOTO]: 'Installation Photo',
  [ProjectFileType.MEASUREMENT_AID]: 'Measurement Aid',
  [ProjectFileType.MESSAGE_ATTACHMENT]: 'Message Attachment',
  [ProjectFileType.PACKING_LIST]: 'Packing List',
  [ProjectFileType.PROJECT_PROPOSAL]: 'Project Proposal',
  [ProjectFileType.PROSPECT_COVER_PHOTO]: 'Prospect Cover Photo',
  [ProjectFileType.PROSPECT_PHOTO]: 'Prospect Photo',
  [ProjectFileType.RENDER]: 'Render',
  [ProjectFileType.RENDER_FEEDBACK]: 'Render Feedback',
  [ProjectFileType.ROOM_PHOTO]: 'Room Photo',
  [ProjectFileType.SUPPLIER_ORDER_FILE]: 'Supplier Order File',
  [ProjectFileType.TAX_EXEMPTION_CERTIFICATE]: 'Tax Exemption Certificate',
  [ProjectFileType.TESTIMONIAL]: 'Testimonial',
  [ProjectFileType.UTILITY_POINTS_AID]: 'Utility Points Aid',
  [ProjectFileType.WINNER_FILE]: 'Winner File',
}

export const getUrlAdmin = (e: ProjectEvent): string => {
  if (!e.project || !e.project.data) {
    return ''
  }

  switch (e.type) {
    case EventType.COMMENT_CREATED_INTERNAL: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.COMMENT_CREATED: {
      return `/admin/files/${e.data?.fileId}`
    }
    case EventType.DELIVERY_DAMAGE_REPORTED: {
      return `/admin/projects/${e.project?.id}/deliveries/${e.data?.deliveryId}`
    }
    case EventType.ISSUE_ADDED: {
      return `/admin/projects/${e.project?.id}/issues`
    }
    case EventType.DELIVERY_UPDATED: {
      return `/admin/projects/${e.project?.id}/deliveries/${e.data?.deliveryId}`
    }
    case EventType.DESIGN_CREATED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_PLAN_APPROVED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_PLAN_APPROVED_INTERNALLY: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_PLAN_INTERNAL_REVIEW_REQUESTED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_PLAN_SUBMITTED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_PLAN_SUBMITTED_INTERNALLY: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_READY_FOR_RENDER: {
      return `/admin/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_RENDERS_UPLOADED: {
      return `/admin/designs/${e.data?.designId}`
    }
    case EventType.DESIGN_UPDATED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/designs/${e.data?.designId}`
    }
    case EventType.MEETING_CANCELED: {
      switch (e.data?.meetingType) {
        case MeetingTypeProspect.FREE:
          return `/admin/prospects`
        case MeetingTypeProspect.FREE_VISIT_NY:
          return `/admin/prospects`
        case MeetingTypeProspect.FREE_VISIT_SF:
          return `/admin/prospects`
        case MeetingTypeProspect.TRADE:
          return `/admin/prospects`
        case MeetingTypeProspect.WEBINAR:
          return `/admin/prospects`
        default: {
          return `/admin/projects/${e.project?.id}/meetings`
        }
      }
    }
    case EventType.MEETING_SCHEDULED: {
      switch (e.data?.meetingType) {
        case MeetingTypeProspect.FREE:
          return `/admin/prospects`
        case MeetingTypeProspect.FREE_VISIT_NY:
          return `/admin/prospects`
        case MeetingTypeProspect.FREE_VISIT_SF:
          return `/admin/prospects`
        case MeetingTypeProspect.TRADE:
          return `/admin/prospects`
        case MeetingTypeProspect.WEBINAR:
          return `/admin/prospects`
        default: {
          return `/admin/projects/${e.project?.id}/meetings`
        }
      }
    }
    case EventType.MESSAGE_CREATED: {
      return `/admin/projects/${e.project?.id}/messages`
    }
    case EventType.ORDER_PLACED: {
      switch (e.data?.orderType) {
        case OrderType.CABINETRY:
          return `/admin/projects/${e.project?.id}/orders/${e.data?.orderId}`
        default:
          return `/admin/projects/${e.project?.id}/sample-orders/${e.data?.orderId}`
      }
    }
    case EventType.ORDER_UPDATED: {
      switch (e.data?.orderType) {
        case OrderType.CABINETRY:
          return `/admin/projects/${e.project?.id}/orders/${e.data?.orderId}`
        default:
          return `/admin/projects/${e.project?.id}/sample-orders/${e.data?.orderId}`
      }
    }
    case EventType.PAYMENT_FAILED: {
      return `/admin/projects/${e.project?.id}/payments`
    }
    case EventType.PAYMENT_INVOICE_CREATED: {
      return `/admin/projects/${e.project?.id}/payments`
    }
    case EventType.PAYMENT_PENDING: {
      return `/admin/projects/${e.project?.id}/payments`
    }
    case EventType.PAYMENT_SUCCEEDED: {
      return `/admin/projects/${e.project?.id}/payments`
    }
    case EventType.PROJECT_ACCESSED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_ADDRESS_UPDATED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_ARCHIVED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_CREATED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_OWNERS_ADDED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_UNARCHIVED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_UPDATED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_FILE_DELETED: {
      return `/admin/projects/${e.project?.id}/overview`
    }
    case EventType.PROJECT_FILE_UPLOADED: {
      return `/admin/files/${e.data?.projectFileId}`
    }
    case EventType.ROOM_APPLIANCES_CONFIRMED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/appliances`
    }
    case EventType.ROOM_APPLIANCE_CHANGES_SUBMITTED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/appliances`
    }
    case EventType.ROOM_APPLIANCES_UPDATED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/appliances`
    }
    case EventType.ROOM_DESIGN_BRIEF_APPROVED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/design-brief`
    }
    case EventType.ROOM_MEASUREMENTS_CONFIRMED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/measurements`
    }
    case EventType.ROOM_MEASUREMENT_CHANGES_SUBMITTED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/measurements`
    }
    case EventType.ROOM_MEASUREMENTS_UPDATED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/measurements`
    }
    case EventType.ROOM_PACK_DISABLED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}/packs`
    }
    case EventType.ROOM_UPDATED: {
      return `/admin/projects/${e.project?.id}/rooms/${e.data?.roomId}`
    }
    case EventType.SUPPLIER_ORDER_APPROVED: {
      return `/admin/projects/${e.project?.id}/supplier-orders/${e.data?.supplierOrderId}`
    }
    case EventType.SUPPLIER_ORDER_CREATED: {
      return `/admin/projects/${e.project?.id}/supplier-orders/${e.data?.supplierOrderId}`
    }
    default: {
      return `/admin/projects/${e.project?.id}/overview`
    }
  }
}
