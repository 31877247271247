import { FindManyNobiliaSampleClassVariables } from 'queries/nobilia-sample-class'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'
import { ProductClassType } from 'types/product-class'

export interface QueryFilters {
  take?: number
  search?: string
  stock?: 'all' | 'inStock' | 'outOfStock' | 'deprecated'
  sortBy?: Extract<keyof NobiliaSampleClass, 'identifier'>
  sortDirection?: 'ascending' | 'descending'
}

export const getQueryVariables = ({
  take,
  search,
  stock,
  sortBy,
  sortDirection,
}: QueryFilters): FindManyNobiliaSampleClassVariables => {
  const queryVariables: WithRequiredProperty<
    FindManyNobiliaSampleClassVariables,
    'where'
  > = {
    take,
    where: {
      productClassType: {
        equals: ProductClassType.NOBILIA_SAMPLE,
      },
    },
  }

  if (search) {
    queryVariables.where.OR = [
      { description: { contains: search, mode: 'insensitive' } },
      { identifier: { contains: search, mode: 'insensitive' } },
    ]
  }

  if (sortBy) {
    queryVariables.orderBy = {
      [sortBy]: sortDirection === 'ascending' ? 'asc' : 'desc',
    }
  }

  if (stock && stock !== 'all') {
    if (stock === 'deprecated') {
      queryVariables.where.AND = [
        {
          meta: {
            path: ['deprecated'],
            equals: true,
          },
        },
      ]
    } else {
      queryVariables.where.AND = [
        {
          meta: {
            path: ['outOfStock'],
            equals: stock === 'outOfStock',
          },
        },
        { meta: { path: ['deprecated'], equals: false } },
      ]
    }
  }

  return queryVariables
}
