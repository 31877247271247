import React, { useState } from 'react'

import { Grid, Loader as Loading, Visibility } from 'semantic-ui-react'

import { BackToTop } from 'components/shared/load-buttons'
import useUserMany from 'context/user/use-many'
import Button from 'design-system/components/button'

import UserCreateUpdateModal from './create-update-modal'
import UsersTable from './table'
import useUserFiltersSearch from './use-filters-search'
import FiltersAndSearch from '../filters-search'

const UserIndexMany = () => {
  const [enableCreateUser, setEnableCreateUser] = useState(false)
  const { count, fetchMore, loading, loadingMore, users } = useUserMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useUserFiltersSearch()

  return (
    <Grid>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
      />
      <Grid.Row columns="equal" style={{ padding: '10px 0 0 0' }}>
        <Grid.Column>
          <p className="gray small">
            {loading ? `???` : `Showing ${users?.length} of ${count}`} Users
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            color="gray"
            kind="outlined"
            onClick={() => setEnableCreateUser(true)}
            text="Create New"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility
            onUpdate={(_, { calculations }) => fetchMore(calculations)}
          >
            <UsersTable />
          </Visibility>
        </Grid.Column>
      </Grid.Row>
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading && users?.length > 0 && <BackToTop />}
      <UserCreateUpdateModal
        onClose={() => setEnableCreateUser(false)}
        open={enableCreateUser}
      />
    </Grid>
  )
}

export default UserIndexMany
