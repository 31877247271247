import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NobiliaProductClassProvider from 'context/nobilia-product-class/provider'

interface NobiliaProductClassIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    nobilia_product_class_id: string
  }> {
  children: React.ReactNode
}

const NobiliaProductClassIndex = (props: NobiliaProductClassIndexProps) => {
  return (
    <NobiliaProductClassProvider
      catalog={props.catalog_id}
      nobilia_product_class_id={props.nobilia_product_class_id}
    >
      {props.children}
    </NobiliaProductClassProvider>
  )
}

export default NobiliaProductClassIndex
