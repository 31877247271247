import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Grid, Image, Modal } from 'semantic-ui-react'

import { Colors } from 'styles/app/system'

const MessageAttachments = ({
  attachments,
  handleDelete,
  handleOnChange,
}: {
  attachments: File[]
  handleDelete: (key: number) => void
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <Form.Group inline>
      <label htmlFor="attachments">
        <div style={{ cursor: 'pointer' }}>
          <Button
            content="Add Attachments"
            icon="paperclip"
            labelPosition="right"
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </label>
      <input
        hidden
        id="attachments"
        multiple
        onChange={handleOnChange}
        type="file"
      />
      {attachments.length ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {attachments.map((a, key) => (
            <Button.Group
              key={key}
              style={{ marginBottom: '8px', marginRight: '8px' }}
            >
              <Modal
                closeOnDimmerClick={false}
                closeOnDocumentClick={false}
                closeIcon
                trigger={
                  <Button
                    style={{
                      backgroundColor: `${Colors.blue200}`,
                    }}
                    content={a.name}
                    key={`preview-${key}`}
                  />
                }
                header={`Preview: ${attachments[key].name}`}
                content={
                  <Grid
                    centered
                    style={{
                      padding: '40px',
                    }}
                  >
                    {attachments[key].type === 'application/pdf' ? (
                      <FontAwesomeIcon icon={['fal', 'file-pdf']} size="8x" />
                    ) : attachments[key].type.match('video.*') ? (
                      <FontAwesomeIcon icon={['fal', 'file-video']} size="8x" />
                    ) : (
                      <Image
                        size="medium"
                        src={URL.createObjectURL(attachments[key])}
                      />
                    )}
                  </Grid>
                }
                actions={[{ key: 'ok', content: 'OK', primary: true }]}
              />
              <Button
                basic
                icon="times"
                key={`delete-${key}`}
                onClick={() => handleDelete(key)}
                style={{
                  paddingLeft: '5px',
                }}
              />
            </Button.Group>
          ))}
        </div>
      ) : null}
    </Form.Group>
  )
}

export default MessageAttachments
