// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion } from 'semantic-ui-react'

import { FormSelect, StyledAccordion, StyledForm } from 'styles/planner/main'

import { NodeOption } from '../../../objects/node'

interface NodeOptionsProps {
  nodeOptions: NodeOption[]
  onOptionChange: (changedNodeOption: NodeOption[]) => void
}

const NodeOptions = (props: NodeOptionsProps) => {
  const [optionsActive, setOptionsActive] = useState<boolean>(false)

  return (
    <StyledAccordion fluid transparent="true">
      <Accordion.Title
        active={optionsActive}
        onClick={() => setOptionsActive(!optionsActive)}
      >
        <FontAwesomeIcon
          icon={['fal', optionsActive ? 'chevron-down' : 'chevron-right']}
        />
        <p>Options</p>
      </Accordion.Title>
      <Accordion.Content active={optionsActive}>
        <StyledForm>
          {props.nodeOptions.map((o) => {
            return (
              <FormSelect
                key={o.id}
                search
                clearable={o.id !== '1'}
                icon={
                  <i style={{ zIndex: 10 }}>
                    <FontAwesomeIcon
                      icon={[
                        'fal',
                        !!o.value && !(o.id === '1') ? 'times' : 'chevron-down',
                      ]}
                    />
                  </i>
                }
                label={o.description}
                options={o.options}
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  { value }: { value: string },
                ) =>
                  props.onOptionChange([
                    {
                      ...o,
                      value: value,
                    },
                  ])
                }
                value={o.value}
              />
            )
          })}
        </StyledForm>
      </Accordion.Content>
    </StyledAccordion>
  )
}

export default NodeOptions
