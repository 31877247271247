import { gql } from '@apollo/client'
import { JSONContent } from '@tiptap/react'

import { MessageTemplate, MessageTemplateData } from 'types/message-template'

export const CREATE_MESSAGE_TEMPLATE = gql`
  mutation createMessageTemplate($data: MessageTemplateCreateInput!) {
    createOneMessageTemplate(data: $data) {
      id
    }
  }
`

export interface CreateMessageTemplatePayload {
  createOneMessageTemplate: MessageTemplate
}

export interface CreateMessageTemplateVariables {
  data: {
    body: JSONContent
    bodyHtml: string
    title: string
    data?: Partial<MessageTemplateData>
    identifier: string
    index: string
    messageTemplateBook?: {
      connect: {
        id: string
      }
    }
  }
}

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteMessageTemplate($where: MessageTemplateWhereUniqueInput!) {
    deleteOneMessageTemplate(where: $where) {
      id
    }
  }
`

export interface DeleteMessageTemplatePayload {
  deleteOneMessageTemplate: {
    id: string
  }
}

export interface DeleteMessageTemplateVariables {
  where: {
    id: string
  }
}

export const FETCH_MESSAGE_TEMPLATES = gql`
  query {
    messageTemplates: findManyMessageTemplate {
      id
      identifier
      index
      createdAt
      data
      title
      body
    }
  }
`

export interface FetchMessageTemplatesPayload {
  messageTemplates: MessageTemplate[]
}

export const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation updateMessageTemplate(
    $data: MessageTemplateUpdateInput!
    $where: MessageTemplateWhereUniqueInput!
  ) {
    updateOneMessageTemplate(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateMessageTemplatePayload {
  updateOneMessageTemplate: {
    id: string
  }
}

export interface UpdateMessageTemplateVariables {
  data: {
    data?: Partial<MessageTemplateData>
    identifier?: string
    index?: string
    body?: JSONContent
    bodyHtml?: string
    title?: string
  }
  where: {
    id: string
  }
}
