import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import {
  FIND_MANY_PROJECT_EVENT_COUNT,
  FindManyProjectEventCountPayload,
  FindManyProjectEventVariables,
} from 'queries/project-event'

import { getQueryVariables, QueryFilters } from './utils-many'

const useProjectEventCount = (queryFilters: QueryFilters) => {
  const queryVariables = useMemo(
    () => getQueryVariables(queryFilters),
    [queryFilters],
  )
  const {
    data: { count } = { count: 0 },
    loading,
    refetch,
  } = useQuery<FindManyProjectEventCountPayload, FindManyProjectEventVariables>(
    FIND_MANY_PROJECT_EVENT_COUNT,
    {
      variables: queryVariables,
    },
  )

  return {
    count,
    loading,
    refetch,
  }
}

export default useProjectEventCount
