import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomElementOptionManyProvider from 'context/room-element-option/provider-many'

interface RoomElementOptionIndexManyProps
  extends RouteComponentProps<{
    catalog_id: string
    room_element_feature_id: string
  }> {
  children: React.ReactNode
}

const RoomElementOptionIndexMany = (props: RoomElementOptionIndexManyProps) => {
  return (
    <RoomElementOptionManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        feature: {
          id: props.room_element_feature_id,
        },
        sortBy: 'description',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </RoomElementOptionManyProvider>
  )
}

export default RoomElementOptionIndexMany
