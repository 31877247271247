import styled from 'styled-components'

const UrgentBadge = styled.span`
  align-items: center;
  background-color: #e5edd3;
  border-radius: 2px;
  font-size: 12px;
  justify-content: center;
  margin-left: 10px;
  padding: 2px 5px;
  width: min-content;
`

export default UrgentBadge
