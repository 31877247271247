import React from 'react'

import { isEqual } from 'lodash'
import { Grid } from 'semantic-ui-react'

import { RoomElementOptionDiff } from 'types/room-element-option-diff'

import MediaDiffComponent from './media-diff'
import {
  getReferenceImage,
  ImageCard,
  ReferenceDiv,
  StrikeThrough,
  StyledLabelDiffs,
  SubGrid,
} from './utils'

const parseDataFields = (roomElementOption: RoomElementOptionDiff) => {
  const isUpdate = roomElementOption.type === 'update'
  const snapshotFrom = isUpdate
    ? roomElementOption.data?.from
    : roomElementOption.snapshot
  const snapshotTo = isUpdate
    ? roomElementOption.data?.to
    : roomElementOption.snapshot
  const snapshotFromData = snapshotFrom?.data
  const snapshotToData = snapshotTo?.data
  const out = [
    {
      fieldName: 'Description',
      fieldValue: snapshotTo?.description,
      fieldValueChanged: snapshotFrom?.description,
    },
    {
      fieldName: 'Display Name',
      fieldValue: snapshotToData?.displayName,
      fieldValueChanged: snapshotFromData?.displayName,
    },
    {
      fieldName: 'Discontinued',
      fieldValue: snapshotToData?.isDiscontinued,
      fieldValueChanged: snapshotFromData?.isDiscontinued,
    },
  ]
  return out.filter((o) => {
    if (!isUpdate) return true
    return !isEqual(o.fieldValue, o.fieldValueChanged)
  })
}

const MediaDiffs = ({
  roomElementOptionDiff,
}: {
  roomElementOptionDiff: RoomElementOptionDiff
}) => {
  const isAdd = roomElementOptionDiff.type === 'add'
  const isDelete = roomElementOptionDiff.type === 'delete'
  const showMedia =
    !isAdd && !isDelete
      ? !!roomElementOptionDiff.media?.length
      : roomElementOptionDiff.snapshot.media.length > 0
  if (!showMedia) return <></>
  if (isAdd)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {roomElementOptionDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'add',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  if (isDelete)
    return (
      <>
        <p className="caption no-margin">Media</p>
        {roomElementOptionDiff.snapshot.media.map((msnap) => (
          <MediaDiffComponent
            key={msnap.key}
            mediaDiff={{
              type: 'delete',
              snapshot: msnap,
            }}
          />
        ))}
      </>
    )
  return (
    <>
      <p className="caption no-margin">Media</p>
      {roomElementOptionDiff.media?.map((mdiff) => (
        <MediaDiffComponent key={mdiff.snapshot.key} mediaDiff={mdiff} />
      ))}
    </>
  )
}

const DataDiffs = ({
  roomElementOptionDiff,
}: {
  roomElementOptionDiff: RoomElementOptionDiff
}) => {
  const fields = parseDataFields(roomElementOptionDiff)
  const isUpdate = roomElementOptionDiff.type === 'update'
  return (
    <>
      {!!fields.length && <p className="caption no-margin">Data</p>}
      {fields.map((f) => (
        <Grid.Row key={f.fieldName}>
          <Grid.Column width={4}>
            <p className="caption">{f.fieldName}</p>
          </Grid.Column>
          <Grid.Column width={12}>
            {isUpdate ? (
              <StrikeThrough>{`${f.fieldValueChanged}`}</StrikeThrough>
            ) : null}
            <p>{`${f.fieldValue}`}</p>
          </Grid.Column>
        </Grid.Row>
      ))}
    </>
  )
}

const RoomElementOptionDiffComponent = ({
  roomElementOptionDiff,
}: {
  roomElementOptionDiff: RoomElementOptionDiff
}) => {
  const reference = getReferenceImage(roomElementOptionDiff.snapshot.media)

  return (
    <>
      <ReferenceDiv>
        <ImageCard image={reference} />
        <div>
          <p className="caption">
            {roomElementOptionDiff.snapshot.data?.displayName ||
              roomElementOptionDiff.snapshot.description}
          </p>
        </div>
        <StyledLabelDiffs type={roomElementOptionDiff.type} />
      </ReferenceDiv>
      <SubGrid>
        <MediaDiffs roomElementOptionDiff={roomElementOptionDiff} />
        <DataDiffs roomElementOptionDiff={roomElementOptionDiff} />
      </SubGrid>
    </>
  )
}

export default RoomElementOptionDiffComponent
