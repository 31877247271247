// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BABYLON from 'babylonjs'
import { Breadcrumb, Card, Grid, Icon, Image, Input } from 'semantic-ui-react'

import useDesign from 'context/planner/design-use'
import useNobiliaOptions from 'context/planner/nobilia-options-use'
import {
  FIND_MANY_GENERIC_PRODUCT_CLASS,
  FindManyGenericProductClassPayload,
  FindManyGenericProductClassVariables,
} from 'queries/generic-product-class'
import {
  FIND_UNIQUE_NOBILIA_PRODUCT_CLASS,
  FindUniqueNobiliaProductClassPayload,
  FindUniqueNobiliaProductClassVariables,
  FIND_MANY_NOBILIA_PRODUCT_CLASS_PARTIAL,
  FindManyNobiliaProductClassPayload,
  FindManyNobiliaProductClassVariables,
} from 'queries/nobilia-product-class'
import { StyledBreadcrumb, StyledCard } from 'styles/planner/main'
import { ProductClassType } from 'types/product-class'
import { ProductType } from 'types/product-type'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import { ProductBase } from '../../../objects/product'
import * as ProductFactory from '../../../objects/product-factory'
import NobiliaProductBase from '../../../objects/products/nobilia/base'
import { nobiliaFeatureToNodeOption } from '../../../objects/products/nobilia/options'
import * as plannerUtils from '../../../utils/planner-utils'
import { OptionStyle, ProductCategories } from '../utils'

interface ProductsProps {
  defaultPosition: BABYLON.Vector3
  defaultPositionWall: BABYLON.Vector3
  defaultRotation: BABYLON.Vector3
  defaultRotationWall: BABYLON.Vector3
  onCreate: (products: ProductBase[]) => void
}

const formatProductType = (pt: ProductType) =>
  capsSnakeCaseToTitleCase(pt).replace('Cabinet ', '')

const ProductsMenu = ({
  defaultPosition,
  defaultPositionWall,
  defaultRotation,
  defaultRotationWall,
  onCreate,
}: ProductsProps) => {
  const { design, presetDefault } = useDesign()
  const { nobiliaFeatures } = useNobiliaOptions()
  const [productFilter, setProductFilter] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedProductType, setSelectedProductType] = useState(null)
  const { data: dataNobilia, loading: loadingNobilia } = useQuery<
    FindManyNobiliaProductClassPayload,
    FindManyNobiliaProductClassVariables
  >(FIND_MANY_NOBILIA_PRODUCT_CLASS_PARTIAL, {
    variables: {
      orderBy: {
        derivedSort: 'asc',
      },
      where: {
        catalog: {
          equals:
            design.metadata?.catalog || process.env.GATSBY_DEFAULT_CATALOG,
        },
        productType: { in: [selectedProductType] },
      },
    },
    skip: !selectedProductType,
  })
  const { data: dataGeneric, loading: loadingGeneric } = useQuery<
    FindManyGenericProductClassPayload,
    FindManyGenericProductClassVariables
  >(FIND_MANY_GENERIC_PRODUCT_CLASS, {
    variables: {
      where: {
        identifier: {
          in: [
            // supported for creation
            'cooktopBasic',
            'dishwasherBasic',
            'extractorBasic',
            'ovenSingleBasic',
            'ovenDoubleBasic',
            'rangeBasic',
            'refrigeratorBasic',
          ],
        },
      },
    },
  })
  const apolloClient = useApolloClient()

  if (loadingNobilia || loadingGeneric) return null

  const products = dataNobilia?.nobiliaProductClasses
    .map((pc) => ProductFactory.createProduct(pc))
    .concat(
      dataGeneric.genericProductClasses.map((gpc) =>
        ProductFactory.createProduct(gpc),
      ),
    )

  const renderProductCategories = () => (
    <Grid>
      <Grid.Row stretched>
        {Object.keys(ProductCategories).map((c, i) => (
          <Grid.Column key={i} width={8} style={OptionStyle}>
            <StyledCard onClick={() => setSelectedCategory(c)}>
              <Image
                src={`${process.env.GATSBY_CDN_ROOT}/images/planner/${c
                  .split(',')[0]
                  .toLocaleLowerCase()}.svg`}
                wrapped
                ui={false}
              />
              <Card.Content>
                <p>{c}</p>
              </Card.Content>
            </StyledCard>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  )

  const renderProductTypes = () => (
    <Grid>
      <Grid.Row columns={3} stretched>
        {ProductCategories[selectedCategory].map((pt, i) => (
          <Grid.Column key={i} style={OptionStyle}>
            <StyledCard onClick={() => setSelectedProductType(pt)}>
              <Image
                onError={(i: { target: { src: string } }) =>
                  (i.target.src = 'https://via.placeholder.com/100')
                }
                src={`${process.env.GATSBY_CDN_ROOT}/images/planner/${pt
                  .replace(new RegExp('_', 'g'), '-')
                  .toLowerCase()}.svg`}
                wrapped
                ui={false}
              />
              <Card.Content>
                <p>{formatProductType(pt)}</p>
              </Card.Content>
            </StyledCard>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  )

  const renderProduct = (p: ProductBase, i: number) => (
    <Grid.Column key={i} style={OptionStyle}>
      <StyledCard
        onClick={async () => {
          let toScene: ProductBase = null
          if (
            p.productClass.productClassType === ProductClassType.NOBILIA_PRODUCT
          ) {
            const np = p as NobiliaProductBase
            const { data: dataDetail } = await apolloClient.query<
              FindUniqueNobiliaProductClassPayload,
              FindUniqueNobiliaProductClassVariables
            >({
              query: FIND_UNIQUE_NOBILIA_PRODUCT_CLASS,
              variables: { where: { id: np.productClass.id } },
            })

            toScene = ProductFactory.createProduct(
              dataDetail.nobiliaProductClass,
              defaultPosition,
              defaultRotation,
            )
            if (presetDefault)
              toScene.applyNodeOptions(
                nobiliaFeatures
                  .filter((f) =>
                    presetDefault.options.some(
                      (no) => no.featureNo === f.featureNo,
                    ),
                  )
                  .map((f) =>
                    nobiliaFeatureToNodeOption(
                      f,
                      presetDefault.options.find(
                        (p) => p.featureNo === f.featureNo,
                      ),
                      [],
                      {},
                    ),
                  ),
              )
            if (toScene.onWall()) {
              toScene.setPosition(
                new BABYLON.Vector3(
                  defaultPositionWall.x,
                  toScene.getYDefault(),
                  defaultPositionWall.z,
                ),
              )
              toScene.setRotation(defaultRotationWall)
            }
            const children = await plannerUtils.createChildProducts(toScene)
            return onCreate([toScene, ...children])
          }
          toScene = ProductFactory.createProduct(
            p.productClass,
            defaultPosition,
            defaultRotation,
          )
          if (toScene.onWall()) {
            toScene.setPosition(
              new BABYLON.Vector3(
                defaultPositionWall.x,
                toScene.getYDefault(),
                defaultPositionWall.z,
              ),
            )
            toScene.setRotation(defaultRotationWall)
          }
          const children = await plannerUtils.createChildProducts(toScene)
          onCreate([toScene, ...children])
        }}
      >
        {p.getImageURL() && (
          <Card.Content className="product-image">
            <Image src={p.getImageURL()} />
          </Card.Content>
        )}
        <Card.Content>
          <p>{p.getName()}</p>
        </Card.Content>
      </StyledCard>
    </Grid.Column>
  )

  const renderProducts = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Input
            icon
            fluid
            placeholder="Search"
            onChange={(_, { value }) => setProductFilter(value as string)}
          >
            <input value={productFilter} />
            <Icon name="search" />
          </Input>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        {products
          .filter(
            (p) =>
              p.productClass.productType === selectedProductType &&
              p.getName().toLowerCase().includes(productFilter.toLowerCase()),
          )
          .map(renderProduct)}
      </Grid.Row>
    </Grid>
  )

  const renderCards = () => {
    if (!selectedCategory) return renderProductCategories()
    if (!selectedProductType) return renderProductTypes()
    return renderProducts()
  }

  return (
    <>
      <StyledBreadcrumb>
        <Breadcrumb.Section
          link
          onClick={() => {
            setSelectedCategory(null)
            setSelectedProductType(null)
          }}
        >
          All
        </Breadcrumb.Section>
        {selectedCategory && (
          <>
            <FontAwesomeIcon icon={['fal', 'chevron-right']} />
            <Breadcrumb.Section
              link
              onClick={() => {
                setSelectedProductType(null)
              }}
            >
              {selectedCategory}
            </Breadcrumb.Section>
          </>
        )}
        {selectedProductType && (
          <>
            <FontAwesomeIcon icon={['fal', 'chevron-right']} />
            <Breadcrumb.Section link>
              {formatProductType(selectedProductType)}
            </Breadcrumb.Section>
          </>
        )}
      </StyledBreadcrumb>
      {renderCards()}
    </>
  )
}

export default ProductsMenu
