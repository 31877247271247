import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import Tooltip from 'components/admin/tooltip'
import { useCategoryFeature } from 'content-queries/cx/category-feature'
import useAuth from 'context/auth/use'
import useCatalogFeature from 'context/catalog-feature/use'
import useCatalogFeatureMutate from 'context/catalog-feature/use-mutate'
import { notifySuccess } from 'context/notifications/trigger'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { CatalogFeatureData } from 'types/catalog-feature'
import { getProductFeatureCategoryFromGroupId } from 'views/utils'

const CatalogFeatureMetadataModal = () => {
  const { isAdmin } = useAuth()
  const { catalogFeature, refetch } = useCatalogFeature()
  const { data: allCategoryFeature } = useCategoryFeature()
  const { updateCatalogFeature, loadingUpdate } = useCatalogFeatureMutate()
  const categoryContentId = allCategoryFeature.find(
    (acf: { title: string }) =>
      acf.title ===
      getProductFeatureCategoryFromGroupId(catalogFeature?.groupIdentifier),
  )?.contentful_id
  const [showModal, toggleModal] = useState<boolean>(false)
  const initialState: CatalogFeatureData = {
    displayName:
      catalogFeature?.data?.displayName || catalogFeature.description,
    category: catalogFeature?.data?.category || categoryContentId || '',
    isDiscontinued: catalogFeature?.data?.isDiscontinued || false,
    priceRange: catalogFeature?.data?.priceRange || '',
    showOnWebsiteCatalog: catalogFeature?.data?.showOnWebsiteCatalog || false,
    usage: catalogFeature?.data?.usage || '',
  }
  const [state, setState] = useState<CatalogFeatureData>(initialState)
  useEffect(() => {
    setState(initialState)
  }, [catalogFeature])

  useEffect(() => {
    if (state.isDiscontinued)
      setState({
        ...state,
        contentfulEntryId: null,
        showOnWebsiteCatalog: false,
      })
    if (!state.showOnWebsiteCatalog)
      setState({
        ...state,
        contentfulEntryId: null,
      })
  }, [state.isDiscontinued, state.showOnWebsiteCatalog])

  const onCloseModal = () => {
    setState(initialState)
    toggleModal(false)
  }

  const onSave = async () => {
    await updateCatalogFeature({
      variables: {
        data: {
          data: {
            ...catalogFeature.data,
            ...state,
          },
        },
        where: { id: catalogFeature.id },
      },
      onCompleted: () => {
        refetch()
        notifySuccess('Data Updated')
      },
    })
    toggleModal(false)
  }

  const usages = [
    'Accessory',
    'Inserts',
    'Lighting',
    'Storage',
    'Trash',
    'Pull-Outs',
  ]
  const priceRanges = ['$', '$$', '$$$']

  return (
    <Modal
      title="Tags"
      onClose={() => onCloseModal()}
      onOpen={() => (!isAdmin ? null : toggleModal(true))}
      open={showModal}
      trigger={
        <Button
          color="gray"
          fontAwesomeIcon="pencil"
          kind="ghost"
          text="Edit"
        />
      }
      size="large"
      saveButton={{
        loading: loadingUpdate,
        onClick: onSave,
      }}
    >
      <h3>Metadata</h3>
      <StyledForm styled={{ grayInputs: true }}>
        <div
          style={{
            backgroundColor: Colors.gray100,
            margin: '24px 0 28px',
            padding: '16px 0 0 16px',
            width: '100%',
          }}
        >
          <p className="subtitle">Product Status</p>
          <Form.Group inline>
            <Form.Checkbox
              checked={state.isDiscontinued}
              label={
                <label>
                  <span style={{ paddingRight: '5px' }}>
                    Product Discontinued
                  </span>
                  <Tooltip type={'Product Discontinued'} />
                </label>
              }
              onChange={() =>
                setState({
                  ...state,
                  isDiscontinued: !state.isDiscontinued,
                })
              }
              style={{ paddingRight: '40px' }}
            />
            <Form.Checkbox
              checked={state.showOnWebsiteCatalog}
              disabled={state.isDiscontinued}
              label={
                <label>
                  <span style={{ color: Colors.green800 }}>
                    Show on website catalog
                  </span>
                </label>
              }
              onChange={() =>
                setState({
                  ...state,
                  showOnWebsiteCatalog: !state.showOnWebsiteCatalog,
                })
              }
              toggle
            />
          </Form.Group>
        </div>
        <Form.Group widths={2}>
          <Form.Input
            label="Display Name"
            onChange={(_: unknown, { value }: { value: string }) => {
              setState({
                ...state,
                displayName: value,
              })
            }}
            value={state.displayName}
          />
        </Form.Group>
        <p className="subtitle">For Catalog Filters</p>
        <Form.Group widths={2}>
          <Form.Dropdown
            fluid
            label="Usage"
            options={usages.map((u) => ({
              key: u,
              text: u,
              value: u,
            }))}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                usage: value as string,
              })
            }}
            placeholder="Usage"
            selection
            value={state.usage}
          />
          <Form.Dropdown
            fluid
            label="Price Range"
            options={priceRanges.map((pr) => ({
              key: pr,
              text: pr,
              value: pr,
            }))}
            onChange={(_: unknown, { value }) => {
              setState({
                ...state,
                priceRange: value as string,
              })
            }}
            placeholder="Price Range"
            selection
            value={state.priceRange}
          />
        </Form.Group>
      </StyledForm>
    </Modal>
  )
}

export default CatalogFeatureMetadataModal
