import React, { useState } from 'react'

import styled from 'styled-components'

import Button from 'design-system/components/button'
import Tooltip from 'design-system/components/tooltip'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { Message } from 'types/message'

import InboxMessage from './inbox-message'

type NewMessagesProps = {
  messages: Message[]
  refetch: () => Promise<unknown>
}

const NewMessages = ({ messages, refetch }: NewMessagesProps) => {
  const [loading, setLoading] = useState(false)

  const hanldeRefecth = async () => {
    setLoading(true)
    await refetch()
    setLoading(false)
  }

  return (
    <Container>
      <div className="head">
        <p className="no-margin">Unread Messages</p>
        <div className="flex-center">
          <Tooltip label="Refresh" placement="top">
            <Button
              kind="ghost"
              fontAwesomeIcon="arrows-rotate"
              onClick={hanldeRefecth}
              loading={loading}
            />
          </Tooltip>
          <p className="no-margin">{messages.length} messages</p>
        </div>
      </div>
      <div className="content">
        {messages.map((m) => (
          <InboxMessage message={m} refetch={refetch} key={m.id} />
        ))}
      </div>
    </Container>
  )
}

export default NewMessages

const Container = styled.div`
  border: 1px solid ${Colors.gray200};
  border-radius: 4px;
  width: 100%;

  & > div.head {
    background-color: ${Colors.gray100};
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid ${Colors.gray200};
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
  }

  & > div.content {
    max-height: 800px;
    overflow: auto;
    padding: 16px;
    ${ScrollBarStyle}
  }
`
