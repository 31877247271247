import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import moment from 'moment'
import { Button, Divider, Grid, Label, Table } from 'semantic-ui-react'

import DeliveryModal from 'components/admin/delivery/create-update-modal'
import OrderCellContentPlacement from 'components/admin/order-form/table-cell-content-placement'
import { ProjectPageTitle } from 'components/admin/page-title'
import RouteModal from 'components/admin/route/create-update-modal'
import ShipmentModal from 'components/admin/shipment/create-update-modal'
import SupplierOrderCellContentFulfillmentStatus from 'components/admin/supplier-order/table-cell-content-fulfillment-status'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useAuth from 'context/auth/use'
import { isAddOnDesign } from 'context/design/utils'
import useProjectSupplierOrderMany from 'context/project/supplier-order/use-many'
import useProjectSupplierOrderManyMutate from 'context/project/supplier-order/use-many-mutate'
import useProject from 'context/project/use'
import { getRoomFormattedType } from 'context/room/utils'
import * as supplierOrderUtils from 'context/supplier-order/utils'
import { InfoPopup } from 'styles/admin/main'
import { Delivery } from 'types/delivery'
import { Route } from 'types/route'
import { Shipment } from 'types/shipment'
import { SupplierOrder } from 'types/supplier-order'

const SupplierOrders = (_: RouteComponentProps<{ project_id: string }>) => {
  const { isSuperAdmin } = useAuth()
  const { project, refetch: refetchProject } = useProject()
  const { supplierOrders, refetch: refetchSupplierOrders } =
    useProjectSupplierOrderMany()
  const { createSupplierOrderAndRefetch, deleteSupplierOrderAndRefetch } =
    useProjectSupplierOrderManyMutate()

  const [deliveryToEdit, setDeliveryToEdit] = useState<Delivery>()
  const [routeToEdit, setRouteToEdit] = useState<Route>()
  const [shipmentToEdit, setShipmentToEdit] = useState<Shipment>()
  const [supplierOrderToDelete, setSupplierOrderToDelete] =
    useState<SupplierOrder>()
  const [supplierOrderToSplit, setSupplierOrderToSplit] =
    useState<SupplierOrder>()

  const refetch = async () => {
    await refetchProject()
    await refetchSupplierOrders()
  }

  return (
    <>
      <ProjectPageTitle viewName="Supplier Orders" />
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <h5>Supplier Orders</h5>
          </Grid.Column>
          <Grid.Column>
            <Button
              primary
              floated="right"
              onClick={() =>
                navigate(`/admin/projects/${project.id}/supplier-orders/create`)
              }
            >
              Create New Supplier Order
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Room / Design</Table.HeaderCell>
                  <Table.HeaderCell>Reference</Table.HeaderCell>
                  <Table.HeaderCell>ACK</Table.HeaderCell>
                  <Table.HeaderCell>Order Status</Table.HeaderCell>
                  <Table.HeaderCell>Fulfillment Status</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {supplierOrders.map((so) => {
                  const guaranteed =
                    supplierOrderUtils.getGuaranteedOnOrBefore(so)
                  const isAddOn = isAddOnDesign(so.design)
                  const isRemedial =
                    supplierOrderUtils.isRemedialSupplierOrder(so)
                  return (
                    <Table.Row
                      key={so.id}
                      {...(isRemedial && { error: true })}
                      {...(isAddOn && { warning: true })}
                    >
                      <Table.Cell width={3}>
                        {isRemedial ? (
                          <Label color="red" content="Remedial" ribbon />
                        ) : null}
                        {isAddOn ? (
                          <Label color="yellow" content="Add-On" ribbon />
                        ) : null}
                        <p className="no-margin">
                          {so.design?.room &&
                            getRoomFormattedType(so.design?.room)}
                        </p>
                        <span className="small gray">{so.design?.name}</span>
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {so.nobiliaOrderRef || ''}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {so.nobiliaAckNumber || ''}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <OrderCellContentPlacement order={so.design?.order} />
                        <span className="small gray">
                          {guaranteed
                            ? `Guaranteed ${moment(guaranteed).format('LL')}`
                            : null}
                        </span>
                      </Table.Cell>
                      <Table.Cell width={3}>
                        <SupplierOrderCellContentFulfillmentStatus
                          supplierOrder={so}
                          onDeliveryClick={() => setDeliveryToEdit(so.delivery)}
                          onRouteClick={() => setRouteToEdit(so.route)}
                          onShipmentClick={() => setShipmentToEdit(so.shipment)}
                        />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <InfoPopup
                          content={<p>Edit S.O</p>}
                          trigger={
                            <Button
                              icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
                              primary
                              onClick={() =>
                                navigate(
                                  `/admin/projects/${project.id}/supplier-orders/${so.id}`,
                                )
                              }
                            />
                          }
                        />
                        <InfoPopup
                          content={<p>Split S.O</p>}
                          trigger={
                            <Button
                              icon={
                                <FontAwesomeIcon icon={['fal', 'divide']} />
                              }
                              onClick={() => setSupplierOrderToSplit(so)}
                            />
                          }
                        />
                        <InfoPopup
                          content={<p>Delete S.O.</p>}
                          trigger={
                            <Button
                              disabled={!isSuperAdmin}
                              icon={
                                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                              }
                              onClick={() => setSupplierOrderToDelete(so)}
                            />
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
                <DeliveryModal
                  deliveryId={deliveryToEdit?.id as string}
                  open={!!deliveryToEdit}
                  refetch={refetch}
                  onClose={() => setDeliveryToEdit(undefined)}
                />
                <RouteModal
                  open={!!routeToEdit?.id}
                  onClose={() => setRouteToEdit(undefined)}
                  refetch={refetch}
                  routeId={routeToEdit?.id as string}
                />
                <ShipmentModal
                  open={!!shipmentToEdit}
                  onClose={() => setShipmentToEdit(undefined)}
                  refetch={refetch}
                  shipmentId={shipmentToEdit?.id as string}
                />
                <ConfirmationModal
                  firstTitle="Are you sure you want to delete the order?"
                  onCancel={() => setSupplierOrderToDelete(undefined)}
                  onConfirm={async () => {
                    await deleteSupplierOrderAndRefetch({
                      variables: {
                        where: {
                          id: supplierOrderToDelete?.id as string,
                        },
                      },
                    })
                    setSupplierOrderToDelete(undefined)
                  }}
                  open={!!supplierOrderToDelete}
                />
                <ConfirmationModal
                  secondTitle="Are you sure you want to split this supplier order?"
                  firstTitle="Split Supplier Order"
                  onCancel={() => setSupplierOrderToSplit(undefined)}
                  onConfirm={async () => {
                    const so = supplierOrderToSplit
                    await createSupplierOrderAndRefetch({
                      variables: {
                        data: {
                          actions: {
                            create:
                              so?.actions.map((soa) => ({
                                data: {
                                  ...soa.data,
                                },
                              })) ?? [],
                          },
                          ...(so?.design
                            ? {
                                design: {
                                  connect: { id: so.design.id },
                                },
                              }
                            : null),
                          data: so?.data,
                          nobiliaAckNumber: so?.nobiliaAckNumber,
                          nobiliaOrderRef: !isNaN(
                            parseFloat(so?.nobiliaOrderRef.slice(-1) ?? ''),
                          )
                            ? `${so?.nobiliaOrderRef.slice(0, -1)}${
                                parseFloat(
                                  so?.nobiliaOrderRef.slice(-1) ?? '',
                                ) + 1
                              }`
                            : `${so?.nobiliaOrderRef} 2`,
                          project: {
                            connect: { id: so?.project.id as string },
                          },
                        },
                      },
                    })
                    setSupplierOrderToSplit(undefined)
                  }}
                  open={!!supplierOrderToSplit}
                />
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default SupplierOrders
