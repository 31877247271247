import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NobiliaProductClassManyProvider from 'context/nobilia-product-class/provider-many'

interface NobiliaProductClassIndexManyProps
  extends RouteComponentProps<{ catalog_id: string }> {
  children: React.ReactNode
}

const NobiliaProductClassIndexMany = (
  props: NobiliaProductClassIndexManyProps,
) => {
  return (
    <NobiliaProductClassManyProvider
      catalog={props.catalog_id}
      defaultFilters={{
        catalog: props.catalog_id ?? '',
        sortBy: 'derivedSort',
        sortDirection: 'ascending',
        take: 50,
      }}
      skipLoader
    >
      {props.children}
    </NobiliaProductClassManyProvider>
  )
}

export default NobiliaProductClassIndexMany
