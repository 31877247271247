import { gql } from '@apollo/client'

import { ProjectUser, ProjectUserRole } from 'types/project-user'

export const CREATE_ONE_PROJECT_USER = gql`
  mutation createOneProjectsUsers($data: ProjectsUsersCreateInput!) {
    createOneProjectsUsers(data: $data) {
      projectId
      userId
    }
  }
`

export interface CreateOneProjectUserPayload {
  createOneProjectUser: ProjectUser
}

export interface CreateOneProjectUserVariables {
  data: {
    isSubscribed?: boolean
    isPrimary?: boolean
    userRole?: ProjectUserRole
    project: {
      connect: {
        id: string
      }
    }
    user: {
      connect: {
        id: string
      }
    }
  }
}

export const DELETE_ONE_PROJECT_USER = gql`
  mutation deleteOneProjectsUsers($where: ProjectsUsersWhereUniqueInput!) {
    deleteOneProjectsUsers(where: $where) {
      projectId
      userId
    }
  }
`

export interface DeleteOneProjectUserPayload {
  deleteOneProjectsUsers: {
    projectId: string
    userId: string
  }
}

export interface DeleteOneProjectUserVariables {
  where: {
    projectId_userId: {
      projectId: string
      userId: string
    }
  }
}

export const UPDATE_ONE_PROJECT_USER = gql`
  mutation updateOneProjectsUsers(
    $data: ProjectsUsersUpdateInput!
    $where: ProjectsUsersWhereUniqueInput!
  ) {
    updateOneProjectsUsers(data: $data, where: $where) {
      projectId
      userId
    }
  }
`

export interface UpdateOneProjectUserPayload {
  updateOneProjectsUsers: ProjectUser
}

export interface UpdateOneProjectUserVariables {
  data: {
    isSubscribed?: boolean
    isPrimary?: boolean
    userRole?: ProjectUserRole
    user: {
      connect: {
        id: string
      }
    }
  }
  where: {
    projectId_userId: {
      projectId: string
      userId: string
    }
  }
}
