import { useMemo } from 'react'

import useCompanyMany from 'context/company/use-many'
import { IndustryType } from 'types/company'
import { getDropdownOptionsFromEnum } from 'views/utils'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormRadioValue,
} from '../filters-search'

export type CompanyFilterValues = {
  companyType: IFormRadioValue
}

const useCompanyFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useCompanyMany()
  const filters: DynamicFilterGroup<CompanyFilterValues>[] = useMemo(() => {
    return [
      {
        filters: [
          {
            filterId: 'companyType',
            type: DynamicFilterTypes.RADIO,
            label: 'Company Types',
            options: getDropdownOptionsFromEnum(IndustryType),
          },
        ],
      },
    ]
  }, [])

  const defaultFilters = useMemo(() => {
    const userFilters: Partial<CompanyFilterValues> = {}

    if (queryFilters.companyType)
      userFilters.companyType = {
        type: DynamicFilterTypes.RADIO,
        selection: {
          value: queryFilters.companyType,
          text: queryFilters.companyType,
        },
      }
    return userFilters
  }, [queryFilters])

  const handleApplyFilters = (data: CompanyFilterValues) => {
    setQueryFilters({
      companyType: data.companyType?.selection.value as IndustryType,
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useCompanyFiltersSearch
