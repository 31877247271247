import React from 'react'

import moment from 'moment/moment'
import { Image, Table } from 'semantic-ui-react'

import { ProjectOwnersCellContents } from 'components/admin/project/project-owners/table-cell-content'
import { getFileImages } from 'context/project-file/utils'
import Button from 'design-system/components/button'
import { Issue } from 'types/issue'
import * as viewUtils from 'views/utils'

export const ProjectIssueCell = ({ issue }: { issue: Issue }) =>
  issue.project ? (
    <Table.Cell>
      <ProjectOwnersCellContents
        project={issue.project}
        includeAddress
        multipleOwners
      />
      <Button
        kind="text"
        fontAwesomeIcon="arrow-up-right-from-square"
        onClick={() =>
          window.open(`/admin/projects/${issue?.project?.id}/issues`)
        }
        style={{ marginTop: '8px' }}
        text="See all issues"
      />
    </Table.Cell>
  ) : null

export const IssueTypeSubtypeCell = ({ issue }: { issue: Issue }) => (
  <Table.Cell>
    <ul style={{ paddingLeft: '16px' }}>
      {!issue.type ? (
        <p className="red">Awaiting classification</p>
      ) : (
        <li>
          <p>
            {issue.type}
            {!!issue.data?.typeOther && `: ${issue.data?.typeOther}`}
          </p>
        </li>
      )}
      <ul style={{ paddingLeft: '16px' }}>
        {issue.data?.subtype?.map((s) => (
          <li key={s}>
            <p>{s === 'Other' ? `Other: ${issue.data?.subtypeOther}` : s}</p>
          </li>
        ))}
      </ul>
    </ul>
  </Table.Cell>
)

export const IssueDescriptionCell = ({ issue }: { issue: Issue }) => (
  <Table.Cell>
    {!!issue.data?.customerDescription && (
      <p>
        <b>Client:</b> {issue.data?.customerDescription}
      </p>
    )}
    {!!issue.data?.internalDescription && (
      <p>
        <b>Internal:</b> {issue.data?.internalDescription}
      </p>
    )}
  </Table.Cell>
)

export const IssueRelatedImgsCell = ({ issue }: { issue: Issue }) => (
  <Table.Cell>
    {issue.files?.map((file) => (
      <div
        key={file.id}
        className="flex"
        style={{ marginBottom: '10px', gap: '8px', cursor: 'pointer' }}
        onClick={() =>
          window.open(
            `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
              getFileImages(file.key, file.numPages)[0],
            )}`,
          )
        }
      >
        <Image
          src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
            getFileImages(file.key, file.numPages)[0],
          )}`}
          style={{
            width: '50px',
            height: '50px',
            objectFit: 'cover',
          }}
        />
        <p>{viewUtils.responsiveText(file.name, 18)}</p>
      </div>
    ))}
  </Table.Cell>
)

export const IssueResolutionCell = ({ issue }: { issue: Issue }) => (
  <Table.Cell>
    {issue.resolutionType ? (
      <p>
        {issue.resolutionType}
        {!!issue.data?.isAddOnComplementary && ' - Complementary'}
      </p>
    ) : (
      <p className="red">Awaiting Resolution</p>
    )}
    {!!issue.data?.orderNumber && (
      <p className="light-gray no-margin">
        Order No.: {issue.data?.orderNumber}
      </p>
    )}
    {!!issue.data?.positionNumber && (
      <p className="light-gray no-margin">POS: {issue.data?.positionNumber}</p>
    )}
  </Table.Cell>
)

export const IssueCreatedAtCell = ({ issue }: { issue: Issue }) => (
  <Table.Cell>
    <p>{moment(issue.createdAt).format('MM/DD/YYYY')}</p>
  </Table.Cell>
)
