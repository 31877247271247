import React, { useEffect, useState } from 'react'

import {
  useForm,
  SubmitHandler,
  Path,
  PathValue,
  DeepPartial,
} from 'react-hook-form'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { FiltersPopup } from 'styles/admin/main'
import { Colors, StyledSystem } from 'styles/app/system'

import { DynamicFilterForm } from './dynamic-filter-form'
import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IDynamicFields,
  IFormCheckboxValue,
} from './types'
import SearchField from '../../shared/search'

type FiltersAndSearchProps<TFields extends IDynamicFields> = {
  defaultAppliedFilters?: Partial<TFields>
  filters: DynamicFilterGroup<TFields>[]
  handleRefetch?: () => void
  handleSearchChange?: (search: string) => void
  onApply: SubmitHandler<TFields>
  overrideWithDefaultFilters?: boolean
  searchValue?: string
  style?: React.CSSProperties
}

const FiltersAndSearch = <TFields extends IDynamicFields>({
  defaultAppliedFilters,
  filters,
  handleRefetch,
  handleSearchChange,
  onApply,
  overrideWithDefaultFilters,
  searchValue,
  style,
}: FiltersAndSearchProps<TFields>) => {
  const [showPopup, setShowPopup] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState<Partial<TFields> | null>(
    defaultAppliedFilters ?? null,
  )

  const methods = useForm<TFields>({
    mode: 'onBlur',
    defaultValues: defaultAppliedFilters as DeepPartial<TFields>,
  })
  const {
    reset,
    handleSubmit: handleSubmitHookForm,
    formState: { isValid, isDirty, isSubmitted },
    setValue,
    getValues,
  } = methods

  const handleClose = () => setShowPopup(false)

  const handleToggle = () => setShowPopup(!showPopup)

  const handleApply: SubmitHandler<TFields> = (data) => {
    setAppliedFilters(data)
    onApply(data)
    handleClose()
  }

  const handleSubmit = handleSubmitHookForm(handleApply)

  const handleClear = () => {
    const resetFields = Object.entries(getValues()).reduce<Partial<TFields>>(
      (acc, [filterId]) => ({ ...acc, [filterId]: null }),
      {},
    )
    reset(resetFields as DeepPartial<TFields>)
    handleSubmit()
  }

  useEffect(() => {
    if ((!isDirty && !isSubmitted) || overrideWithDefaultFilters) {
      overrideWithDefaultFilters &&
        Object.entries(getValues()).forEach(([fieldId]) =>
          setValue(
            fieldId as Path<TFields>,
            null as PathValue<TFields, Path<TFields>>,
          ),
        )
      setAppliedFilters(defaultAppliedFilters ?? null)
      if (defaultAppliedFilters)
        Object.entries(defaultAppliedFilters).forEach(([fieldId, value]) =>
          setValue(
            fieldId as Path<TFields>,
            value as PathValue<TFields, Path<TFields>>,
          ),
        )
    }
  }, [defaultAppliedFilters])

  return (
    <FilterSearchRow style={style}>
      <FiltersPopup
        basic
        content={
          <FiltersPopupContainer>
            <div className="close">
              <Button
                kind="ghost"
                color="gray"
                fontAwesomeIcon="times"
                onClick={handleClose}
              />
            </div>
            <DynamicFilterForm
              hookFormMethods={methods}
              filterGroups={filters}
              savedValues={appliedFilters ?? undefined}
            />
            <div className="actions">
              <Button
                kind="ghost"
                text="Clear all"
                color="gray"
                onClick={handleClear}
              />
              <Button
                kind="solid"
                text="Apply"
                color="blue"
                disabled={!isValid}
                onClick={handleSubmit}
              />
            </div>
          </FiltersPopupContainer>
        }
        flowing
        on="click"
        open={showPopup}
        position="bottom left"
        trigger={
          <Button
            disabled={!filters.length}
            kind="solid"
            text={showPopup ? 'Close' : 'Add filter'}
            color="blue"
            fontAwesomeIcon={showPopup ? 'times' : 'plus'}
            onClick={handleToggle}
          />
        }
      />
      <AppliedFiltersContainer>
        {appliedFilters &&
          (Object.entries(appliedFilters) as [string, TFields[string]][]).map(
            ([filterId, filter], idx) => {
              if (!filter || !filterId) return null
              if (filter.type === DynamicFilterTypes.CHECKBOX) {
                return filter.selection.map((opt, checkboxIdx) => (
                  <Button
                    key={`${filter.type}#${idx}-${checkboxIdx}`}
                    kind="outlined"
                    fontAwesomeIcon="times"
                    color="gray"
                    text={opt.text}
                    onClick={() => {
                      if (filter) {
                        const temp = [...filter.selection]
                        const optionIndex = temp.findIndex(
                          (val) => val.value === opt.value,
                        )
                        optionIndex !== -1 && temp.splice(optionIndex, 1)
                        if (temp.length) {
                          const newValue: IFormCheckboxValue = {
                            type: DynamicFilterTypes.CHECKBOX,
                            selection: temp,
                          }
                          setValue(
                            filterId as Path<TFields>,
                            newValue as PathValue<TFields, Path<TFields>>,
                          )
                        } else {
                          setValue(filterId as Path<TFields>, null as any)
                        }
                        handleSubmit()
                      }
                    }}
                  />
                ))
              }
              return (
                <Button
                  key={`${filter.type}#${idx}`}
                  kind="outlined"
                  fontAwesomeIcon="times"
                  color="gray"
                  text={
                    filter.type === DynamicFilterTypes.DATE
                      ? filter.value
                      : filter.selection?.text
                  }
                  onClick={() => {
                    setValue(filterId as Path<TFields>, null as any, {
                      shouldDirty: true,
                    })
                    handleSubmit()
                  }}
                />
              )
            },
          )}
      </AppliedFiltersContainer>
      {handleRefetch && (
        <Button
          kind="solid"
          color="blue"
          fontAwesomeIcon="refresh"
          onClick={handleRefetch}
        />
      )}
      {handleSearchChange && (
        <div className="search">
          <SearchField onChange={handleSearchChange} value={searchValue} />
        </div>
      )}
    </FilterSearchRow>
  )
}

const FilterSearchRow = styled.div`
  column-gap: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div.search {
    min-width: 300px;
  }
`

const AppliedFiltersContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;

  & > button {
    min-width: max-content;
    max-height: 36px;
  }
`

const FiltersPopupContainer = styled.div`
  ${StyledSystem}
  position: relative;
  background-color: ${Colors.white};
  min-height: unset;
  padding: 0 8px;
  max-width: 400px;

  & > div.close {
    display: flex;
    justify-content: flex-end;
  }

  & > div.actions {
    background: ${Colors.white};
    border-top: 1px solid ${Colors.gray200};
    bottom: 0;
    column-gap: 24px;
    display: flex;
    justify-content: flex-end;
    left: 0;
    margin-top: 30px;
    padding: 12px;
    position: sticky;
    width: 100%;
  }
`

export default FiltersAndSearch
