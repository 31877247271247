import { SupplierOrder } from './supplier-order'

export interface RouteData {
  carrier?: string
  completedAtEstimated?: string // MM/DD/YYYY
  completedAtActual?: string // MM/DD/YYYY
  dispatchedAtEstimated?: string // MM/DD/YYYY
  dispatchedAtActual?: string // MM/DD/YYYY
  notes?: string
  receivingWarehouse?: string
  type?: RouteType
}

export interface Route {
  id?: string
  archivedAt?: Date
  color?: string
  confirmedAt?: Date
  data: RouteData
  derivedSearch?: string
  derivedStatus?: string
  reference?: string
  supplierOrders: SupplierOrder[]
}

export enum RouteType {
  BOX_TRUCK = 'BOX TRUCK',
  LINE_HAUL = 'LINE_HAUL',
  LTL = 'LTL',
}
