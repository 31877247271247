import React, { useState } from 'react'

import { Table } from 'semantic-ui-react'

import {
  IssueDescriptionCell,
  IssueRelatedImgsCell,
  IssueResolutionCell,
  IssueTypeSubtypeCell,
} from 'components/admin/issue/utils-table'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useDeliveryIssueMutate from 'context/delivery/use-issue-mutate'
import Button from 'design-system/components/button'
import { FormCheckbox } from 'styles/admin/main'
import { Issue } from 'types/issue'

import CreateUpdateIssueModal from './create-update-modal'

const IssuesTable = ({
  issues,
  mode = 'readOnly',
  selected,
  onSelectToggle,
}: {
  issues: Issue[]
  mode: 'readOnly' | 'enableSelect' | 'enableEdit'
  onSelectToggle?: (issue: Issue) => void
  selected?: Issue[]
}) => {
  const [issueToEdit, setIssueToEdit] = useState<Issue>()
  const [issueToDelete, setIssueToDelete] = useState<Issue>()

  return (
    <Table celled fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}>Type / Sub-type</Table.HeaderCell>
          <Table.HeaderCell width={3}>Resolution</Table.HeaderCell>
          <Table.HeaderCell width={4}>Description</Table.HeaderCell>
          <Table.HeaderCell width={4}>Related image</Table.HeaderCell>
          {mode !== 'readOnly' ? <Table.HeaderCell></Table.HeaderCell> : null}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {issues?.map((i: Issue) => (
          <Table.Row key={i.id}>
            <IssueTypeSubtypeCell issue={i} />
            <IssueResolutionCell issue={i} />
            <IssueDescriptionCell issue={i} />
            <IssueRelatedImgsCell issue={i} />
            {mode === 'enableEdit' ? (
              <Table.Cell textAlign="center" width={2}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '6px',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    kind="solid"
                    fontAwesomeIcon="edit"
                    onClick={() => {
                      setIssueToEdit(i)
                    }}
                  />
                  <Button
                    kind="solid"
                    color="gray"
                    fontAwesomeIcon="trash-alt"
                    onClick={() => {
                      setIssueToDelete(i)
                    }}
                  />
                </div>
              </Table.Cell>
            ) : null}
            {mode === 'enableSelect' ? (
              <Table.Cell width={1}>
                <div className="flex-center">
                  <FormCheckbox
                    checked={!!selected?.find((s) => s.id === i.id)}
                    onChange={() => onSelectToggle?.(i)}
                  />
                </div>
              </Table.Cell>
            ) : null}
          </Table.Row>
        ))}
      </Table.Body>
      {mode === 'enableEdit' && (
        <CreateUpdateIssueModal
          issue={issueToEdit}
          open={!!issueToEdit}
          onClose={() => setIssueToEdit(undefined)}
        />
      )}
      {mode === 'enableEdit' && (
        <DeleteIssueConfirm
          issue={issueToDelete}
          open={!!issueToDelete}
          onCancel={() => setIssueToDelete(undefined)}
        />
      )}
    </Table>
  )
}

export default IssuesTable

const DeleteIssueConfirm = ({
  issue,
  open,
  onCancel,
}: {
  issue: Issue | undefined
  open: boolean
  onCancel: () => void
}) => {
  const { deleteIssueAndRefetch } = useDeliveryIssueMutate()
  return (
    <ConfirmationModal
      firstTitle="Are you sure you want to delete the issue?"
      onCancel={onCancel}
      onConfirm={async () => {
        await deleteIssueAndRefetch({
          variables: {
            where: {
              id: issue?.id ?? '',
            },
          },
        })
        onCancel()
      }}
      open={open}
    />
  )
}
