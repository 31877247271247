import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import MediaCard from 'components/admin/catalog/media/card'
import useCatalogFeature from 'context/catalog-feature/use'
import * as mUtils from 'context/media/utils-many'
import { Colors } from 'styles/app/system'

import CatalogFeatureMetadataModal from './metadata-modal'

const CatalogFeatureSummary = () => {
  const { catalogFeature, refetch } = useCatalogFeature()
  const referenceImage = mUtils.parseMedia(catalogFeature.media).reference[0]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column
          width={5}
          textAlign="center"
          style={{ display: 'inline-table' }}
        >
          <MediaCard
            enableEdit={!!referenceImage}
            media={referenceImage}
            refetch={refetch}
          />
          <p className="caption" style={{ marginTop: '14px' }}>
            Reference Image
          </p>
        </Grid.Column>
        <Grid.Column width={6}>
          <Metadata>
            <Grid.Row>
              <Grid.Column width={8}>
                <h6 className="no-margin">Metadata</h6>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <CatalogFeatureMetadataModal />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column className="title" floated="left" width={7}>
                Display Name
              </Grid.Column>
              <Grid.Column width={9}>
                {catalogFeature.data?.displayName || catalogFeature.description}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="title" floated="left" width={7}>
                Status
              </Grid.Column>
              <Grid.Column width={9}>
                {catalogFeature.data?.isDiscontinued
                  ? 'Discontinued, not showing website catalog'
                  : catalogFeature.data?.showOnWebsiteCatalog
                  ? 'Showing on website catalog'
                  : 'Not showing on website catalog'}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="title" floated="left" width={7}>
                Usage
              </Grid.Column>
              <Grid.Column width={9}>{catalogFeature.data?.usage}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="title" floated="left" width={7}>
                Price Range
              </Grid.Column>
              <Grid.Column width={9}>
                {catalogFeature.data?.priceRange}
              </Grid.Column>
            </Grid.Row>
          </Metadata>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default CatalogFeatureSummary

const Metadata = styled(Grid)`
  background-color: ${Colors.gray50};
  padding: 10px !important;
  div.divider {
    margin: 3px 14px 14px !important;
    padding: 0px !important;
  }
  div.row {
    padding: 10px 0 !important;
  }

  div.column.title {
    color: ${Colors.gray600};
  }
`
