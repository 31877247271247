import { useContext } from 'react'

import useFORMEmployees from 'context/user/use-form-employees'

import { AccountExecutiveContext } from './provider'

const useAccountExecutive = () => {
  const { meetings, userId } = useContext(AccountExecutiveContext)

  const { FORMEmployeeIDToName } = useFORMEmployees()
  const userName = FORMEmployeeIDToName[userId]

  return {
    meetings,
    userId,
    userName,
  }
}

export default useAccountExecutive
