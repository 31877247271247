import React from 'react'

import { RouteComponentProps } from '@reach/router'

import NobiliaOptionProvider from 'context/nobilia-option/provider'

interface NobiliaOptionIndexProps
  extends RouteComponentProps<{
    catalog_id: string
    nobilia_option_id: string
  }> {
  children: React.ReactNode
}

const NobiliaOptionIndex = (props: NobiliaOptionIndexProps) => {
  return (
    <NobiliaOptionProvider
      catalog={props.catalog_id}
      nobilia_option_id={props.nobilia_option_id}
    >
      {props.children}
    </NobiliaOptionProvider>
  )
}

export default NobiliaOptionIndex
