import React from 'react'

import { Grid, Loader as Loading } from 'semantic-ui-react'

import useCatalogFeatureFiltersSearch from 'components/admin/catalog/catalog-feature/use-filters-search'
import FiltersAndSearch from 'components/admin/filters-search'
import { LoadButton } from 'components/shared/load-buttons'
import Loader from 'components/shared/loader'
import useCatalogFeatureMany from 'context/catalog-feature/use-many'
import {
  SelectionCardVariation,
  SelectionCardExpandSelectProps,
} from 'design-system/components/selection-card'
import { CatalogFeature } from 'types/catalog-feature'

import CatalogFeatureSelectionCard from './card'

const CatalogFeaturesSelection = ({
  selectedCatalogFeatures,
  setSelectedCatalogFeatures,
  stackable,
}: {
  selectedCatalogFeatures: Partial<CatalogFeature>[]
  setSelectedCatalogFeatures: (
    catalogFeature: Partial<CatalogFeature>[],
  ) => void
  stackable?: boolean
}) => {
  const { count, catalogFeatures, fetchMoreOnDemand, loading, loadingMore } =
    useCatalogFeatureMany()
  const { defaultFilters, filters, handleApplyFilters, handleSearch } =
    useCatalogFeatureFiltersSearch()

  return (
    <Grid stackable={stackable}>
      <FiltersAndSearch
        defaultAppliedFilters={defaultFilters}
        filters={filters}
        onApply={handleApplyFilters}
        handleSearchChange={handleSearch}
        style={{ marginTop: '10px' }}
      />
      {loading ? (
        <Grid.Row style={{ minHeight: '100vh' }}>
          <Grid.Column>
            <Loader flex fullScreen={false} />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row columns={4} stretched>
          {catalogFeatures.map((item) => {
            return (
              <Grid.Column key={item.id} style={{ marginBottom: '30px' }}>
                <CatalogFeatureSelectionCard
                  catalogFeature={item}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      selectOnly: true,
                      onSelect: () => {
                        if (
                          selectedCatalogFeatures.find((s) => s.id === item.id)
                        )
                          setSelectedCatalogFeatures(
                            selectedCatalogFeatures.filter(
                              (s) => s.id !== item.id,
                            ),
                          )
                        else
                          setSelectedCatalogFeatures([
                            ...selectedCatalogFeatures,
                            item,
                          ])
                      },
                      selected: selectedCatalogFeatures.some(
                        (s) => s.id === item.id,
                      ),
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      )}
      {loadingMore && (
        <Grid.Row>
          <Grid.Column>
            <Loading active inline="centered" size="large" />
          </Grid.Column>
        </Grid.Row>
      )}
      {!loading &&
        catalogFeatures?.length > 0 &&
        catalogFeatures.length !== count && (
          <LoadButton onClick={fetchMoreOnDemand} />
        )}
    </Grid>
  )
}

export default CatalogFeaturesSelection
