import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Issues from 'components/admin/issue/index-many'
import useTechDesignerSupport from 'context/dashboard/tech-designer-support/use'
import IssueManyProvider from 'context/issue/provider-many'

import TechDesignerSupportLayout from './layout'

const TechDesignerSupportIssues = (_: RouteComponentProps) => {
  const { userId, userName } = useTechDesignerSupport()
  return (
    <TechDesignerSupportLayout>
      <IssueManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          status: 'unresolved',
          take: 30,
          sortBy: 'status',
        }}
        skipLoader
      >
        <Issues />
      </IssueManyProvider>
    </TechDesignerSupportLayout>
  )
}

export default TechDesignerSupportIssues
