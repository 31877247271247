import { useContext } from 'react'

import { ProjectManyContext } from './provider-many'

const useProjectMany = () => {
  const {
    projects,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(ProjectManyContext)

  return {
    projects,
    count,
    loading,
    loadingMore,
    fetchMore,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useProjectMany
