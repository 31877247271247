export type Diff<S extends Snapshot = Snapshot> =
  | {
      type: DiffType.ADD | DiffType.DELETE
      snapshot: S
      changes?: never
    }
  | {
      type: DiffType.UPDATE
      snapshot: S
      changes: {
        from: Partial<S>
        to: Partial<S>
      }
    }

export enum DiffType {
  ADD = 'add',
  DELETE = 'delete',
  UPDATE = 'update',
}

export type Snapshot<
  T extends Record<string, string> = Record<string, string>,
> = { id: string; idReadable: string } & T
