import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import MediaCard from 'components/admin/catalog/media/card'
import * as mUtils from 'context/media/utils-many'
import { Colors } from 'styles/app/system'
import { GenericOption } from 'types/generic-option'
import { Media } from 'types/media'

import GenericOptionModal from './metadata-modal'

const GenericOptionSummary = ({
  option,
  refetch,
}: {
  option: GenericOption
  refetch: () => Promise<unknown>
}) => {
  const referenceImage = mUtils.parseMedia(option.media as Media[]).reference[0]

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column
          width={5}
          textAlign="center"
          style={{ display: 'inline-table' }}
        >
          <MediaCard
            enableEdit={!!referenceImage}
            media={referenceImage}
            refetch={refetch}
          />
          <p className="caption" style={{ marginTop: '14px' }}>
            Reference Image
          </p>
        </Grid.Column>
        <Grid.Column width={5}>
          <Metadata>
            <Grid.Row>
              <Grid.Column width={8}>
                <h6 className="no-margin">Metadata</h6>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <GenericOptionModal genericOption={option} refetch={refetch} />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={7}>
                <p className="overline">Status</p>
              </Grid.Column>
              <Grid.Column width={9}>
                <p>
                  {option.data?.isDiscontinued ? 'Discontinued' : 'Available'}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <p className="overline">Display Name</p>
              </Grid.Column>
              <Grid.Column width={9}>
                <p>{option.data?.displayName || option.description}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <p className="overline">Show on Initial Call</p>
              </Grid.Column>
              <Grid.Column width={9}>
                <p>{option.data?.showOnDesignCall ? 'Yes' : 'No'}</p>
              </Grid.Column>
            </Grid.Row>
          </Metadata>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default GenericOptionSummary

const Metadata = styled(Grid)`
  background-color: ${Colors.gray50};
  padding: 10px !important;

  div.divider {
    margin: 3px 14px 14px !important;
    padding: 0px !important;
  }

  div.row {
    padding: 10px 0 !important;
  }
`
