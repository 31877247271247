import React, { useEffect, useRef, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'

import { ProjectPageTitle } from 'components/admin/page-title'
import Loader from 'components/shared/loader'
import { MessageBox, MessageList } from 'components/shared/message'
import { MessagesGroup } from 'components/shared/message/message-list'
import useAuth from 'context/auth/use'
import { newForMe } from 'context/new-for'
import { useProjectMessageMany } from 'context/project/message/provider-many'
import useProjectMessageManyMutate from 'context/project/message/use-many-mutate'
import Button from 'design-system/components/button'
import { TabContent } from 'design-system/components/tabs'
import { Colors, ScrollBarStyle } from 'styles/app/system'
import { MessageData } from 'types/message'
import {
  MessageActiveTabOptions,
  useAdminMessageLayout,
} from 'views/admin/project/messages/layout/context'

import { ConversationMessages } from './conversation-messages'

const scrollContainerId = 'messagesContainer'

const Messages = (_: RouteComponentProps<{ project_id: string }>) => {
  const { user, formUserId } = useAuth()
  const {
    count,
    fetchedCount,
    fetchMore,
    loading,
    loadingMore,
    messagesGroupedByDate,
    messages,
    refetch: refetchMessages,
    scrollToLastMessage,
    messagesFetched,
  } = useProjectMessageMany()
  const { toggleMessagePin, acknowledgeMessage } = useProjectMessageManyMutate()
  const { activeTab, pinnedFilter, conversationMessagesFilter } =
    useAdminMessageLayout()
  const [showLoader, setShowLoader] = useState(true)
  const scrolledToBottom = useRef(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const filteredPinnedMessages = messages.filter((m) => {
    const source = m.from?.email ?? m.data?.sourceFrom ?? ''
    if (pinnedFilter === 'client') return source !== user.email
    if (pinnedFilter === 'me') return source === user.email
    return true
  })

  const handlePinToggleMessage = async (
    id: string,
    pinned: boolean,
    messageData: Partial<MessageData>,
  ) => {
    await toggleMessagePin({
      id,
      pinned,
      prevMessageData: messageData as MessageData,
    })
  }

  const handleFetchMore = async () => {
    containerRef.current?.scrollTo({
      top: 1,
      behavior: 'auto',
    })
    await fetchMore()
  }

  useEffect(() => {
    if (!scrolledToBottom.current && messagesFetched) {
      const onScrolled = () => setShowLoader(false)
      scrollToLastMessage({ scrollContainerId, callback: onScrolled })

      scrolledToBottom.current = true
    }
  }, [messagesFetched])

  useEffect(() => {
    scrolledToBottom.current = false
  }, [activeTab])

  useEffect(() => {
    return () => {
      refetchMessages()
    }
  }, [])

  return (
    <>
      <ProjectPageTitle viewName="Messages" />
      <Container>
        <TabContent active={activeTab.value === MessageActiveTabOptions.ALL}>
          <MessagesContainer id={scrollContainerId} ref={containerRef}>
            {fetchedCount < count ? (
              <Button
                onClick={handleFetchMore}
                kind="solid"
                text="Load More"
                loading={loading || loadingMore}
                style={{ display: 'block', margin: '20px auto 0 auto' }}
              />
            ) : null}
            <MessageList
              messages={messagesGroupedByDate}
              admin
              togglePinned={handlePinToggleMessage}
              enableCategorization
              refetch={refetchMessages}
              acknowledgeMessage={acknowledgeMessage}
            />
          </MessagesContainer>
        </TabContent>

        <TabContent active={activeTab.value === MessageActiveTabOptions.PINNED}>
          <MessagesGroup>
            {filteredPinnedMessages.map((m) => {
              const fromMe = m.from?.email === user.email
              const _newForMe = newForMe(m, formUserId) && !fromMe

              return (
                <MessageBox
                  key={m.id}
                  message={m}
                  admin
                  togglePinned={(id, pinned) =>
                    handlePinToggleMessage(id, pinned, m.data as MessageData)
                  }
                  fullDate
                  fromMe={fromMe}
                  newForMe={_newForMe}
                  pinned={!!m.data?.pinned}
                  refetch={refetchMessages}
                  acknowledgeMessage={acknowledgeMessage}
                />
              )
            })}
          </MessagesGroup>
        </TabContent>

        <TabContent
          active={
            activeTab.value !== MessageActiveTabOptions.ALL &&
            activeTab.value !== MessageActiveTabOptions.PINNED &&
            activeTab.isConversation
          }
        >
          <ConversationMessages
            conversationId={activeTab.value ?? ''}
            messagesFilter={conversationMessagesFilter}
          />
        </TabContent>

        {showLoader && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
      </Container>
    </>
  )
}

export default Messages

const MessagesContainer = styled.div`
  ${ScrollBarStyle}

  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const LoaderContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 3;
  background-color: ${Colors.gray50};
`
