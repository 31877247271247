import React from 'react'

import HelpMessage from 'components/shared/help-message'
import {
  ChecklistItemData,
  ChecklistItems,
  deliveryChecklistItems,
  designChecklistItems,
  refineChecklistItems,
} from 'context/project/checklist/items'
import {
  ChecklistTab,
  useProjectChecklist,
} from 'context/project/checklist/provider'
import { Drawer } from 'design-system/components/drawer'
import { Colors } from 'styles/app/system'

import CheckListItem from './check-list-item'

const renderItems = (items: ChecklistItemData[]) => {
  return items.map((item, i) => {
    if ('pro_tip' in item) {
      return (
        <div style={{ marginBlock: 20 }} key={`proTip-${i}`}>
          <HelpMessage title="PRO TIP" message={item.pro_tip} />
        </div>
      )
    }

    return <CheckListItem key={item.identifier} {...item} />
  })
}

const renderDrawers = (
  checklistItems: ChecklistItems,
  activeDrawers: ChecklistTab[],
  setActiveDrawers: React.Dispatch<React.SetStateAction<ChecklistTab[]>>,
) => {
  return Object.keys(checklistItems).map((key) => {
    let content: React.ReactNode

    if ('items' in checklistItems[key]) {
      content = renderItems(checklistItems[key].items ?? [])
    }

    if ('groups' in checklistItems[key]) {
      content = checklistItems[key]?.groups?.map((group, i) => {
        return (
          <React.Fragment key={`${group.title}-${i}`}>
            <p
              className="x-small"
              style={{ color: Colors.blue700, marginBlock: 20 }}
            >
              {group.title}
            </p>
            {renderItems(group.items)}
          </React.Fragment>
        )
      })
    }

    const onDrawerChange = (opened: boolean) => {
      setActiveDrawers((prev) => {
        if (opened) prev.push(key as ChecklistTab)

        if (!opened) {
          const idx = prev.indexOf(key as ChecklistTab)
          prev.splice(idx, 1)
        }
        return prev
      })
    }

    return (
      <Drawer
        key={key}
        title={checklistItems[key].title}
        content={content}
        actionsStyle={{ padding: 0, paddingBlock: 20 }}
        allowOutsideClick
        onChange={onDrawerChange}
        defaultOpen={activeDrawers.includes(key as ChecklistTab)}
      />
    )
  })
}

export const DesignTab = () => {
  const { activeDrawers, setActiveDrawers } = useProjectChecklist()

  return (
    <>{renderDrawers(designChecklistItems, activeDrawers, setActiveDrawers)}</>
  )
}

export const RefineTab = () => {
  const { activeDrawers, setActiveDrawers } = useProjectChecklist()

  return (
    <>{renderDrawers(refineChecklistItems, activeDrawers, setActiveDrawers)}</>
  )
}

export const DelivaryTab = () => {
  const { activeDrawers, setActiveDrawers } = useProjectChecklist()

  return (
    <>
      {renderDrawers(deliveryChecklistItems, activeDrawers, setActiveDrawers)}
    </>
  )
}
