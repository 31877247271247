import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import ProjectFileNotFound from 'components/shared/project/project-file/file-not-found'
import ProjectFileLayout from 'components/shared/project/project-file/layout'
import ProjectFileProvider from 'context/project-file/provider'
import useProjectFile from 'context/project-file/use'
import { StatusMode } from 'types/utils'

type ProjectFileProps = RouteComponentProps<{
  project_id: string
  room_id: string
  design_id: string
  file_id: string
}>

const DesignPlan = ({
  project_id,
  location,
  file_id,
  room_id,
  design_id,
}: ProjectFileProps) => {
  const { projectFile, internalReviewMode } = useProjectFile()

  useEffect(() => {
    if (
      projectFile &&
      location?.pathname ===
        `/admin/projects/${project_id}/rooms/${room_id}/designs/${design_id}/plan/${file_id}/`
    ) {
      const isInternalReview = [
        StatusMode.Submitted,
        StatusMode.OpenForRevision,
      ].includes(internalReviewMode as StatusMode)
      if (isInternalReview) {
        navigate(
          `/admin/projects/${projectFile.project.id}/rooms/${projectFile.designPlan.room.id}/designs/${projectFile.designPlan.id}/plan-internal/${file_id}`,
          { replace: true },
        )
        return
      }
      navigate(
        `/admin/projects/${project_id}/rooms/${room_id}/designs/${design_id}/plan-external/${file_id}`,
        { replace: true },
      )
      return
    }
  }, [projectFile])

  if (!projectFile) return <ProjectFileNotFound />
  return <ProjectFileLayout />
}

export default (props: ProjectFileProps) => (
  <ProjectFileProvider file_id={props.file_id}>
    <DesignPlan {...props} />
  </ProjectFileProvider>
)
