import React, { createContext } from 'react'

interface INobiliaOrderProcessorContext {
  userId: string
}

type NobiliaOrderProcessorProps = React.PropsWithChildren<{ user_id: string }>

export const NobiliaOrderProcessorContext =
  createContext<INobiliaOrderProcessorContext>(
    {} as INobiliaOrderProcessorContext,
  )

const NobiliaOrderProcessorProvider = (props: NobiliaOrderProcessorProps) => {
  return (
    <NobiliaOrderProcessorContext.Provider
      value={{
        userId: props.user_id,
      }}
    >
      {props.children}
    </NobiliaOrderProcessorContext.Provider>
  )
}

export default NobiliaOrderProcessorProvider
