import { useMutation } from '@apollo/client'

import {
  UPDATE_ONE_NOBILIA_OPTION,
  UpdateNobiliaOptionPayload,
  UpdateNobiliaOptionVariables,
} from 'queries/nobilia-option'

const useNobiliaOptionMutate = () => {
  const [updateNobiliaOption, { loading: loadingUpdate }] = useMutation<
    UpdateNobiliaOptionPayload,
    UpdateNobiliaOptionVariables
  >(UPDATE_ONE_NOBILIA_OPTION)

  return {
    loadingUpdate,
    updateNobiliaOption,
  }
}

export default useNobiliaOptionMutate
