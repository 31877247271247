import React from 'react'

import * as supplierOrderUtils from 'context/supplier-order/utils'
import { Colors } from 'styles/app/system'
import { SupplierOrder } from 'types/supplier-order'

const SupplierOrderCellContentNobiliaStatus = ({
  supplierOrder,
}: {
  supplierOrder: SupplierOrder
}) => {
  const { orderConfirmationStatus, daysSinceCreation, daysToApproval } =
    supplierOrderUtils.getOrderConfirmationStatus(supplierOrder)

  switch (orderConfirmationStatus) {
    case supplierOrderUtils.OrderConfirmationStatus.APPROVED:
      return (
        <>
          <p className="caption" style={{ marginBottom: '4px' }}>
            Approved
          </p>
          <p className="x-small">
            {daysToApproval} days from order to approval
          </p>
        </>
      )
    case supplierOrderUtils.OrderConfirmationStatus.LATE:
      return (
        <>
          <p
            className="caption"
            style={{ color: Colors.orange100, marginBottom: '4px' }}
          >
            Late
          </p>
          <p className="x-small">
            {daysSinceCreation} days since order creation
          </p>
        </>
      )
    case supplierOrderUtils.OrderConfirmationStatus.ON_TRACK:
      return (
        <>
          <p
            className="caption"
            style={{ color: Colors.greenBright, marginBottom: '4px' }}
          >
            On track
          </p>
          <p className="x-small">
            {daysSinceCreation} days since order creation
          </p>
        </>
      )
    default:
      return null
  }
}

export default SupplierOrderCellContentNobiliaStatus
