import React from 'react'

import { RouteComponentProps } from '@reach/router'

import OrdersCabinetry from 'components/admin/order-form/index-many'
import useSalesDesigner from 'context/dashboard/sales-designer/use'
import OrderCabinetryManyProvider from 'context/order/provider-many-cabinetry'
import { OrderType } from 'types/order'
import { ProjectStaffRole } from 'types/project-staff'

import SalesDesignerLayout from './layout'

const SalesDesignerDesignsAwaitingSignoff = (_: RouteComponentProps) => {
  const { userId, userName } = useSalesDesigner()

  return (
    <SalesDesignerLayout>
      <OrderCabinetryManyProvider
        defaultFilters={{
          assignedTo: { id: userId, name: userName },
          assignedToRole: ProjectStaffRole.SALES_DESIGNER,
          sortBy: 'placedAt',
          sortDirection: 'descending',
          take: 30,
          type: OrderType.CABINETRY,
        }}
        skipLoader
      >
        <OrdersCabinetry />
      </OrderCabinetryManyProvider>
    </SalesDesignerLayout>
  )
}

export default SalesDesignerDesignsAwaitingSignoff
