import React from 'react'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import useTechDesignerSupport from 'context/dashboard/tech-designer-support/use'
import useCommentNoteCount from 'context/project/project-notes/use-count'
import { ScrollBarStyle } from 'styles/app/system'

const MenuTechDesignerSupport = () => {
  const { pathname } = useLocation()
  const {
    issuesUnresolvedCount,
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
    sosAddOnEDINotSentCount,
    projects,
    tasksToDo,
    tasksUrgent,
    userId,
  } = useTechDesignerSupport()

  const { count: newForNotesCount } = useCommentNoteCount({
    newFor: { id: userId },
  })

  return (
    <StyledMenu pointing secondary>
      <Menu.Item
        name="overview"
        active={
          pathname === `/admin/dashboard/${userId}/tech-designer-support/`
        }
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer-support`)
        }
      />
      <Menu.Item
        name="projects"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer-support/projects`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer-support/projects`)
        }
      >
        Projects
        <Label className="count">{projects.length}</Label>
      </Menu.Item>
      <Menu.Item
        name="notes"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer-support/notes`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer-support/notes`)
        }
      >
        Notes
        {!!newForNotesCount && (
          <Label className="count">{newForNotesCount}</Label>
        )}
      </Menu.Item>
      <Menu.Item
        name="tasks"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer-support/tasks`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer-support/tasks`)
        }
      >
        Tasks
        {tasksToDo.length || tasksUrgent.length ? (
          <Label className="count">
            {tasksToDo.length + tasksUrgent.length}
          </Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="issues"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer-support/issues`,
        )}
        onClick={() =>
          navigate(`/admin/dashboard/${userId}/tech-designer-support/issues`)
        }
      >
        Issues
        {issuesUnresolvedCount ? (
          <Label className="count">{issuesUnresolvedCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="supplierOrders"
        active={
          pathname ===
          `/admin/dashboard/${userId}/tech-designer-support/supplier-orders/`
        }
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer-support/supplier-orders`,
          )
        }
      >
        Supplier Orders
      </Menu.Item>
      <Menu.Item
        name="supplierOrdersEDINotSent"
        active={
          pathname ===
          `/admin/dashboard/${userId}/tech-designer-support/supplier-orders-edi-not-sent/`
        }
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer-support/supplier-orders-edi-not-sent`,
          )
        }
      >
        S.O. EDI Not Sent
        {sosAddOnEDINotSentCount ? (
          <Label className="count">{sosAddOnEDINotSentCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="supplierOrdersNobilia"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer-support/supplier-orders-awaiting-nobilia`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer-support/supplier-orders-awaiting-nobilia`,
          )
        }
      >
        S.O. Awaiting Nobilia
        {addOnRemedialsAwaitingNobiliaCount ? (
          <Label className="count">{addOnRemedialsAwaitingNobiliaCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="supplierOrdersFORM"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer-support/supplier-orders-awaiting-form`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer-support/supplier-orders-awaiting-form`,
          )
        }
      >
        S.O. Awaiting FORM
        {addOnRemedialsAwaitingFORMCount ? (
          <Label className="count">{addOnRemedialsAwaitingFORMCount}</Label>
        ) : null}
      </Menu.Item>
      <Menu.Item
        name="deliveries"
        active={pathname.startsWith(
          `/admin/dashboard/${userId}/tech-designer-support/delivieries`,
        )}
        onClick={() =>
          navigate(
            `/admin/dashboard/${userId}/tech-designer-support/deliveries`,
          )
        }
      >
        Deliveries
      </Menu.Item>
    </StyledMenu>
  )
}

export default MenuTechDesignerSupport

export const StyledMenu = styled(Menu)`
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  ${ScrollBarStyle}

  a.item {
    border-bottom-width: 3px !important;
  }
`
