import React, { useState } from 'react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import Button from 'design-system/components/button'

const FeedbackModal = ({
  disabled,
  isReadyToSignOff,
  loading,
  onSave,
}: {
  disabled: boolean
  isReadyToSignOff: boolean
  loading: boolean
  onSave: () => void
}) => {
  const [showModal, toggleModal] = useState<boolean>(false)

  const onClose = () => {
    toggleModal(false)
  }

  const firstTitle = 'Are you sure you want to submit your feedback?'
  let extraContent = (
    <div style={{ textAlign: 'initial' }}>
      <p>Once submitted:</p>
      <ul>
        <li>
          <p>The Sales Designer will be notified of your feedback.</p>
        </li>
        <li>
          <p>
            The Sales Designer will be able to reply to your comments and / or
            update the plan, please stay tuned.
          </p>
        </li>
      </ul>
    </div>
  )

  if (isReadyToSignOff) {
    extraContent = (
      <div style={{ textAlign: 'initial' }}>
        <p>Once submitted:</p>
        <p>
          This Design will be ready for Sign Off since all advisories were
          resolved.
        </p>
      </div>
    )
  }

  return (
    <ConfirmationModal
      firstTitle={firstTitle}
      cancelText="No, let me check again"
      confirmText="Yes, submit feedback"
      extraContent={extraContent}
      onCancel={onClose}
      onConfirm={() => {
        onSave()
        onClose()
      }}
      open={showModal}
      trigger={
        <Button
          disabled={disabled}
          kind="solid"
          loading={loading}
          onClick={() => toggleModal(true)}
          size="fluid"
          text="Submit Feedback"
        />
      }
    />
  )
}

export default FeedbackModal
