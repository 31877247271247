import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { RoomPageTitle } from 'components/admin/page-title'
import RoomLayout from 'components/admin/project/room/layout'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import CheckHighlighted from 'design-system/components/check-highlighted'
import { ProjectFileType } from 'types/project-file'

const InspirationImages = (
  _: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  const { project } = useProject()
  const { room, inspirationImages, refetch } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()

  return (
    <RoomLayout>
      <RoomPageTitle room={room} viewName="Inspiration Images" />
      <Grid>
        <Grid.Row>
          <Grid.Column style={{ display: 'flex', justifyContent: 'right' }}>
            <CheckHighlighted
              checked={!!room?.data?.inspirationImagesCompletedAt}
              color="red"
              text="Initial Flow Completed"
              onChange={async () => {
                await updateRoomAndRefetch({
                  variables: {
                    data: {
                      data: {
                        inspirationImagesCompletedAt: !room?.data
                          .inspirationImagesCompletedAt
                          ? new Date()
                          : null,
                      },
                    },
                    where: {
                      id: room?.id ?? '',
                    },
                  },
                })
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          includeDownloadAndCarousel
          onDownload={async () => {
            const res = await updateRoomAndRefetch({
              variables: {
                data: {
                  data: {
                    inspirationImagesDownloadedAt: new Date(),
                  },
                },
                where: {
                  id: room?.id ?? '',
                },
              },
            })
            res.data?.updateOneRoom.data.inspirationImagesZipUrl &&
              window.open(res.data?.updateOneRoom.data.inspirationImagesZipUrl)
          }}
          openOnNewTab={true}
          projectFiles={inspirationImages}
          projectFileType={ProjectFileType.INSPIRATION_IMAGE}
          projectId={project.id}
          roomId={room?.id}
          refetch={refetch}
        />
      </Grid>
    </RoomLayout>
  )
}

export default InspirationImages
