import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Grid, Label, SemanticWIDTHS } from 'semantic-ui-react'

import CustomOptionCreateUpdateModal from 'components/admin/catalog/custom-option/create-update-modal'
import RoomElementOptionSelectionCard from 'components/shared/catalog/room-element-option/card'
import {
  SelectionCardDeleteProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import {
  FIND_UNIQUE_ROOM_ELEMENT_OPTION,
  FindUniqueRoomElementOptionPayload,
  FindUniqueRoomElementOptionVariables,
} from 'queries/room-element-option'
import { SelectionAccordion } from 'styles/admin/main'
import { ProjectFile } from 'types/project-file'
import { RoomElementOptionRoom } from 'types/room'
import { RoomElementOption } from 'types/room-element-option'

import RoomElementOptionsModal from './selection-modal'

interface RoomElementOptionAccordionProps {
  conceptImages?: ProjectFile[]
  customIdentifier?: string
  featureIdentifier?: string
  gridColumns?: SemanticWIDTHS
  onDelete?: (re: Partial<RoomElementOption>) => void
  onSave: (re: Partial<RoomElementOption>[]) => void
  onSaveCustom?: (re: Partial<RoomElementOptionRoom>) => void
  roomElementOptions: Partial<RoomElementOption>[]
  title: string
}

const RoomElementOptionAccordion = ({
  conceptImages,
  customIdentifier,
  featureIdentifier,
  gridColumns,
  onDelete: onDeleteRoomElement,
  onSave,
  onSaveCustom,
  roomElementOptions,
  title,
}: RoomElementOptionAccordionProps) => {
  const [showCards, toggleCards] = useState<boolean>(true)

  const { data: { roomElementOption } = { roomElementOption: null } } =
    useQuery<
      FindUniqueRoomElementOptionPayload,
      FindUniqueRoomElementOptionVariables
    >(FIND_UNIQUE_ROOM_ELEMENT_OPTION, {
      skip: !customIdentifier,
      variables: {
        where: { identifier: customIdentifier },
      },
    })

  const formOptions = roomElementOptions.filter(
    (o) => o.optionIdentifier !== 'custom',
  )

  return (
    <SelectionAccordion fluid>
      <Accordion.Title className="accordion-title">
        <span className="name" onClick={() => toggleCards(!showCards)}>
          <p className="subtitle no-margin">{title}</p>
          <FontAwesomeIcon
            icon={['fal', showCards ? 'chevron-up' : 'chevron-down']}
          />
        </span>
        <div style={{ display: 'flex', gap: '14px' }}>
          {conceptImages?.length ? (
            <CustomOptionCreateUpdateModal
              conceptImages={conceptImages}
              elementType={title}
              onSave={(n, f, link) =>
                onSaveCustom?.({
                  roomElementOption: {
                    ...roomElementOption,
                    data: {
                      ...roomElementOption?.data,
                      customImageUrl: f.key,
                      customName: n,
                      fileId: f.id,
                      linkToProductSpec: link,
                    },
                  } as RoomElementOption,
                  data: {
                    name: n,
                    linkToProductSpec: link,
                  },
                  projectFile: f,
                })
              }
              showLinkToSpec
            />
          ) : null}
          <RoomElementOptionsModal
            {...(featureIdentifier &&
              ({
                defaultFilters: {
                  feature: {
                    identifier: featureIdentifier,
                  },
                  sortBy: 'description',
                  sortDirection: 'ascending',
                  take: undefined,
                },
              } as any))}
            onSave={onSave}
            selectedRoomElementOptions={formOptions}
            title={title}
          />
        </div>
      </Accordion.Title>
      <Accordion.Content active className="accordion-content">
        {showCards ? (
          <Grid>
            <Grid.Row columns={gridColumns || 3}>
              {roomElementOptions?.map((option) => (
                <Grid.Column key={option.id}>
                  <RoomElementOptionSelectionCard
                    roomElementOption={option}
                    selectionCardProps={
                      {
                        linkToSpec: option.data?.linkToProductSpec,
                        onDelete: () => onDeleteRoomElement?.(option),
                      } as Partial<SelectionCardDeleteProps>
                    }
                    selectionCardVariation={SelectionCardVariation.DELETE}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        ) : (
          <div className="labels">
            {roomElementOptions?.map((option) => (
              <Label key={option.id}>
                {option.description}
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  onClick={() => onDeleteRoomElement?.(option)}
                />
              </Label>
            ))}
          </div>
        )}
      </Accordion.Content>
    </SelectionAccordion>
  )
}

export default RoomElementOptionAccordion
