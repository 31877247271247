import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from 'semantic-ui-react'

import DisconnectedOptionManyProvider from 'context/disconnected-option/provider-many'
import { QueryFilters } from 'context/disconnected-option/utils-many'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { DisconnectedOption } from 'types/disconnected-option'

import DisconnectedOptionsSelection from './selection'
import { Labels } from '../utils'

const DisconnectedOptionsModal = ({
  defaultFilters,
  onSave,
  selectedDisconnectedOptions,
  title,
}: {
  defaultFilters: QueryFilters
  onSave: (disconnectedOptions: Partial<DisconnectedOption>[]) => void
  selectedDisconnectedOptions: Partial<DisconnectedOption>[]
  title?: string
}) => {
  const [disconnectedOptions, setDisconnectedOptions] = useState<
    Partial<DisconnectedOption>[]
  >(selectedDisconnectedOptions)
  const [showModal, toggleModal] = useState<boolean>(false)
  const [defaultFilters_] = useState<QueryFilters>(defaultFilters)

  useEffect(
    () => setDisconnectedOptions(selectedDisconnectedOptions),
    [selectedDisconnectedOptions],
  )

  const onClose = () => {
    setDisconnectedOptions(selectedDisconnectedOptions)
    toggleModal(false)
  }

  return (
    <Modal
      onClose={onClose}
      open={showModal}
      size="large"
      title={`Choose ${title || 'options'}`}
      customActions={
        <Grid style={{ width: '100%' }}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={13}>
              {disconnectedOptions.length ? (
                <Labels>
                  {disconnectedOptions.map((o) => (
                    <div key={o.id} className="selection">
                      <p>{o.description}</p>
                      <FontAwesomeIcon
                        icon={['fal', 'times']}
                        onClick={() => {
                          if (disconnectedOptions.find((s) => s.id === o.id))
                            setDisconnectedOptions(
                              disconnectedOptions.filter((s) => s.id !== o.id),
                            )
                          else
                            setDisconnectedOptions([...disconnectedOptions, o])
                        }}
                      />
                    </div>
                  ))}
                </Labels>
              ) : null}
            </Grid.Column>
            <Grid.Column width={3}>
              <div className="flex-center" style={{ flexWrap: 'unset' }}>
                <Button
                  kind="outlined"
                  text="Close"
                  color="gray"
                  onClick={onClose}
                  style={{ marginRight: '20px' }}
                />
                <Button
                  kind="solid"
                  text="Save"
                  onClick={() => {
                    onSave(disconnectedOptions)
                    toggleModal(false)
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      trigger={
        <Button
          kind="ghost"
          fontAwesomeIcon={disconnectedOptions.length ? 'pencil' : 'plus'}
          text={disconnectedOptions.length ? 'Change' : 'Add'}
          onClick={() => toggleModal(true)}
        />
      }
    >
      <DisconnectedOptionManyProvider
        defaultFilters={defaultFilters_}
        skipLoader
      >
        <DisconnectedOptionsSelection
          selectedDisconnectedOptions={disconnectedOptions}
          setSelectedDisconnectedOptions={setDisconnectedOptions}
        />
      </DisconnectedOptionManyProvider>
    </Modal>
  )
}

export default DisconnectedOptionsModal
