import { useContext, useMemo } from 'react'

import useDesignsAwaitingRenderCount from 'context/design/use-awaiting-render-count'
import useDesignsAwaitingSignoffCount from 'context/design/use-awaiting-signoff-count'
import { parseTasks } from 'context/task/utils-many'
import useFORMEmployees from 'context/user/use-form-employees'
import { ProjectStaffRole } from 'types/project-staff'

import { SalesDesignerContext } from './provider'

const useSalesDesigner = () => {
  const {
    meetings,
    messages,
    projects,
    refetchMessages,
    refetchTasks,
    refetchProjects,
    tasks,
    userId,
  } = useContext(SalesDesignerContext)

  const { designsAwaitingSignoffCount } = useDesignsAwaitingSignoffCount(
    userId,
    ProjectStaffRole.SALES_DESIGNER,
  )
  const { designsAwaitingRenderCount } = useDesignsAwaitingRenderCount(
    userId,
    ProjectStaffRole.SALES_DESIGNER,
  )

  const { FORMEmployeeIDToName } = useFORMEmployees()
  const userName = FORMEmployeeIDToName[userId]

  const parsedTasks = useMemo(() => parseTasks(tasks), [tasks])

  return {
    designsAwaitingSignoffCount,
    designsAwaitingRenderCount,
    meetings,
    messages,
    projects,
    refetchMessages,
    refetchProjects,
    refetchTasks,
    userId,
    userName,
    ...parsedTasks,
  }
}

export default useSalesDesigner
