import { useContext } from 'react'

import { UserManyContext } from './provider-many'

const useUserMany = () => {
  const {
    users,
    count,
    loading,
    loadingMore,
    fetchMore,
    queryFilters,
    refetch,
    setQueryFilters,
  } = useContext(UserManyContext)

  return {
    users,
    count,
    loading,
    loadingMore,
    fetchMore,
    queryFilters,
    refetch,
    setQueryFilters,
  }
}

export default useUserMany
