import React from 'react'

import { RouteComponentProps } from '@reach/router'

import { ProjectPageTitle } from 'components/admin/page-title'
import ProjectLayout from 'components/admin/project/layout'
import ActivityFeed from 'components/admin/project-event'
import useAuth from 'context/auth/use'

const Activity = (_: RouteComponentProps<{ project_id: string }>) => {
  const { formUserId } = useAuth()

  return (
    <ProjectLayout>
      <ProjectPageTitle viewName="Activity" />
      <h5>Activity</h5>
      <ActivityFeed formUserId={formUserId} />
    </ProjectLayout>
  )
}

export default Activity
