// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import { ProductType } from 'types/product-type'
import { RoomElementType } from 'types/room-element-class'

export const AccordionMenu = styled(Menu)`
  ::-webkit-scrollbar {
    display: none;
  }
`

export const AccordionStyle = {
  width: '100%',
  maxHeight: '500px',
  overflowY: 'scroll',
}

export const CardStyle = { padding: '5px 0 0 5px' }

export const OptionStyle = { padding: '0 8px' }

export const ProductCategories: Record<string, ProductType[]> = {
  Appliances: [
    ProductType.APPLIANCE_FRONT,
    ProductType.COOKTOP,
    ProductType.DISHWASHER,
    ProductType.EXTRACTOR,
    ProductType.MICROWAVE,
    ProductType.OVEN_SINGLE,
    ProductType.RANGE,
    ProductType.REFRIGERATOR,
  ],
  Cabinets: [
    ProductType.CABINET_BASE,
    ProductType.CABINET_CORNER,
    ProductType.CABINET_CORNER_HALF,
    ProductType.CABINET_CORNER_HALF_SINK,
    ProductType.CABINET_DRESSER,
    ProductType.FILLER,
    ProductType.FILLER_CORNER,
    ProductType.CABINET_HOT_PLATE,
    ProductType.CABINET_SINK,
    ProductType.CABINET_TALL,
    ProductType.CABINET_WALL,
    ProductType.CABINET_WALL_CORNER,
    ProductType.CABINET_WALL_CORNER_HALF,
  ],
  'Panels, etc.': [
    ProductType.CARCASE_MATERIAL,
    ProductType.HANDLE_VERTICAL,
    ProductType.PANEL_UPRIGHT,
    ProductType.SHELF,
  ],
}

export const RoomElementTypesToCreate: RoomElementType[] = [
  RoomElementType.WINDOW,
  RoomElementType.DOOR,
]
