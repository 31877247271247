import React from 'react'

import { Image, List } from 'semantic-ui-react'

import { groupTasksByOwner, parseTasks } from 'context/task/utils-many'
import { TableUserListItemDivided } from 'styles/admin/main'
import { Task } from 'types/task'

export const ProjectTasksCellContent = ({ tasks }: { tasks: Task[] }) => {
  const tasksByOwner = groupTasksByOwner(
    tasks.filter((t) => !t.data?.completedAt),
  )
  return (
    <>
      {tasksByOwner.map((tbo, idx) => {
        const { tasksUrgent, tasksToDo } = parseTasks(tbo.tasks)
        return (
          <List key={idx}>
            <TableUserListItemDivided style={{ hyphens: 'auto' }}>
              <Image
                src={`${
                  tbo?.owner?.picture ??
                  'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon&f=y'
                }`}
                avatar
                circular
                size="mini"
                style={{ margin: '0 0 4px', maxWidth: '28px' }}
              />
              <List.Content>
                <p className="gray no-margin x-small">Task(s):</p>
                <ul style={{ margin: '0' }}>
                  {tasksUrgent.map((ot) => (
                    <li key={ot.id}>
                      <p>{ot.data.description}</p>
                    </li>
                  ))}
                  {tasksToDo.map((ot) => (
                    <li key={ot.id}>
                      <p>{ot.data.description}</p>
                    </li>
                  ))}
                </ul>
              </List.Content>
            </TableUserListItemDivided>
          </List>
        )
      })}
      {!tasksByOwner.length && <p>---</p>}
    </>
  )
}
