import React from 'react'

import { Drawer } from 'design-system/components/drawer'
import { CommentNote } from 'types/comment-note'

import { NoteCard } from './note-card'

type NotesDrawerProps = {
  notes: CommentNote[]
  formUserId: string
  isSuperAdmin: boolean
  title: string
  refetch: () => Promise<unknown>
  defaultOpen: boolean
  showProjectData?: boolean
  isProjectNote?: boolean
}

export const NotesDrawer = ({
  notes,
  formUserId,
  isSuperAdmin,
  refetch,
  title,
  defaultOpen,
  showProjectData,
  isProjectNote,
}: NotesDrawerProps) => {
  return (
    <Drawer
      unmount={false}
      allowOutsideClick
      defaultOpen={defaultOpen}
      title={title}
      content={() => (
        <>
          {notes.map((note) => (
            <NoteCard
              key={note.id}
              formUserId={formUserId}
              isSuperAdmin={isSuperAdmin}
              note={note}
              refetch={refetch}
              showProjectData={showProjectData}
              isProjectNote={isProjectNote}
            />
          ))}
        </>
      )}
    />
  )
}
