import React from 'react'

import { Grid } from 'semantic-ui-react'

import HeaderAdmin from 'components/admin/header'
import useAuth from 'context/auth/use'
import { AdminMain } from 'styles/admin/main'

const AdminCatalogLayout = ({ children }: React.PropsWithChildren) => {
  const { user, logout } = useAuth()
  return (
    <section style={{ background: 'white', minHeight: '100vh' }}>
      <HeaderAdmin user={user} logout={logout} />
      <AdminMain>
        <Grid>
          <Grid.Row>
            <Grid.Column>{children}</Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminMain>
    </section>
  )
}

export default AdminCatalogLayout
