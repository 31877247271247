// TODO: move to global messages component
import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { JSONContent } from '@tiptap/react'
import TextareaAutosize from 'react-textarea-autosize'
import { Grid, Form, Table, DropdownProps } from 'semantic-ui-react'

import MarkdownTooltip from 'components/admin/markdown-tooltip'
import PersonListItem from 'components/admin/person-list-item'
import AddressFormat from 'components/shared/address-format'
import { uploadImage } from 'components/shared/project/project-file/upload-card'
import useAuth from 'context/auth/use'
import { notifySuccess } from 'context/notifications/trigger'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import useMdEditor from 'design-system/components/rich-text/use-md-editor'
import {
  CREATE_MESSAGE,
  CreateMessagePayload,
  CreateMessageVariables,
} from 'queries/message'
import {
  FETCH_MESSAGE_TEMPLATES,
  FetchMessageTemplatesPayload,
} from 'queries/message-template'
import {
  FIND_UNIQUE_USER,
  FindUniqueUserPayload,
  FindUniqueUserVariables,
} from 'queries/user'
import { StyledForm } from 'styles/admin/main'
import { FormCheckbox } from 'styles/app/components/checkbox'
import { Colors } from 'styles/app/system'
import { MessageSource } from 'types/message'
import { Project } from 'types/project'
import { ProjectFile, ProjectFileType } from 'types/project-file'
import { User } from 'types/user'
import * as viewUtils from 'views/utils'

import MessageAttachments from './attachments'

const md = require('markdown-it')({
  breaks: true,
})

interface MultiMessageModalProps {
  messageTemplateIdentifiers?: string[]
  open?: boolean
  onClose?: () => void
  projects: Project[]
  title?: string
}

type State = {
  from: User | null
  to: User[] | null
  body: string | JSONContent
  data?: { subject?: string }
}

const MultiMessageModal = ({
  messageTemplateIdentifiers,
  open,
  onClose,
  projects,
  title,
}: MultiMessageModalProps) => {
  const { user } = useAuth()
  const [attachments, setAttachments] = useState<File[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [ready, setReady] = useState<boolean>(false)
  const [projectsToSend, setProjectsToSend] = useState<Project[]>(
    projects ?? [],
  )
  // TODO - add MessageEditor instead of parsing like this
  const editor = useMdEditor()

  const [state, setState] = useState<State>({
    from: user,
    to: null,
    body: '',
  })

  useEffect(() => setProjectsToSend(projects), [projects])

  const [createMessage] = useMutation<
    CreateMessagePayload,
    CreateMessageVariables
  >(CREATE_MESSAGE)
  const { data, loading: loadingMessageTemplates } =
    useQuery<FetchMessageTemplatesPayload>(FETCH_MESSAGE_TEMPLATES)
  const { data: dataUser, loading: loadingUser } = useQuery<
    FindUniqueUserPayload,
    FindUniqueUserVariables
  >(FIND_UNIQUE_USER, {
    variables: {
      where: {
        email: 'team@formkitchens.com',
      },
    },
  })

  if (!projects) return null

  if (loadingMessageTemplates || loadingUser) return null
  const systemUser = dataUser?.user || null

  const messageTemplates = messageTemplateIdentifiers?.length
    ? data?.messageTemplates.filter((t) =>
        messageTemplateIdentifiers.includes(t.identifier),
      )
    : data?.messageTemplates

  const sortedTemplates = [...(messageTemplates ?? [])]?.sort((a, b) => {
    if (a.title > b.title) return 1
    if (a.title < b.title) return -1

    return 0
  })

  const templateOptions = sortedTemplates.map((t) => ({
    id: t.id,
    key: t.id,
    value: t.id,
    text: t.title,
  }))

  const onChangeDropdown = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    { value }: { value: string },
  ) => {
    const selectedTemplate = messageTemplates?.filter(
      (t) => t.id === value,
    )?.[0]

    editor?.commands.setContent(selectedTemplate?.body || '')

    setState({
      ...state,
      data: {
        subject: selectedTemplate?.title,
      },
      body: editor?.getMarkdown(),
    })
  }

  const onChangeFileInput = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setAttachments(attachments.concat(Array.prototype.slice.call(target.files)))
    target.value = null as unknown as string
  }

  const onDeleteFileInput = (key: number) => {
    setAttachments(attachments.filter((_, id) => id !== key))
  }

  const createProjectMessage = async (project: Project) => {
    let files: ProjectFile[] = []
    if (attachments.length > 0) {
      await Promise.all(
        attachments.map(async (a) => {
          const newFile = await uploadImage({
            fileType: ProjectFileType.MESSAGE_ATTACHMENT,
            imgFile: a,
            projectId: project.id,
          })
          files = files.concat(newFile)
        }),
      )
    }

    editor.commands.setContent(state.body)
    await createMessage({
      variables: {
        data: {
          attachments: {
            connect:
              viewUtils.mapOrEmptyArray(files, (f) => ({ id: f.id })) ?? [],
          },
          body: editor.getJSON(),
          bodyHtml: editor.getHTML(),
          from: {
            connect: { email: state.from?.email },
          },
          project: {
            connect: { id: project.id },
          },
          newFor: {
            connect:
              viewUtils.mapOrEmptyArray(project.owners, (u) => ({
                id: u.user?.id ?? '',
              })) ?? [],
          },
          to: {
            connect:
              viewUtils.mapOrEmptyArray(project.owners, (u) => ({
                id: u.user?.id ?? '',
              })) ?? [],
          },
          data: {
            source: MessageSource.APP,
          },
        },
      },
    })
  }

  const onUpdate = async () => {
    for (const project of projectsToSend) {
      if (project.owners.length === 0) continue
      await createProjectMessage(project)
    }
    notifySuccess(`${projectsToSend.length} messages sent`)
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size="fullscreen"
      title={`Compose Message (${title})`}
      customActions={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <FormCheckbox
            checked={ready}
            label="Ready To Send"
            onChange={() => setReady(!ready)}
          />
          <div>
            <Button
              color="blue"
              kind="solid"
              onClick={onClose}
              style={{ marginRight: '14px' }}
              text="Close"
            />
            <Button
              kind="solid"
              text="Send Message"
              disabled={!ready || loading}
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await onUpdate()
                setLoading(false)
                setAttachments([])
                setState({
                  data: {
                    subject: '',
                  },
                  body: '',
                  from: user,
                  to: null,
                })
                setLoading(false)
                onClose?.()
              }}
            />
          </div>
        </div>
      }
    >
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={10}>
            <StyledForm>
              <Form.Field width={10}>
                <Form.Dropdown
                  label="Select Template"
                  onChange={onChangeDropdown as DropdownProps['onChange']}
                  options={templateOptions}
                  placeholder="Choose a Template"
                  search
                  selection
                />
              </Form.Field>
              <Form.Field width={10}>
                <Form.Dropdown
                  label="From"
                  onChange={(_, { value }) => {
                    setState({
                      ...state,
                      from: value === user.email ? user : systemUser,
                    })
                  }}
                  options={[
                    {
                      id: user.id,
                      key: user.id,
                      text: viewUtils.getNameForUser(user),
                      value: user.email,
                    },
                    ...(systemUser
                      ? [
                          {
                            id: systemUser.id,
                            key: systemUser.id,
                            text: viewUtils.getNameForUser(systemUser),
                            value: systemUser.email,
                          },
                        ]
                      : []),
                  ]}
                  placeholder="User To Send As"
                  search
                  selection
                  value={state.from?.email}
                />
              </Form.Field>
              <Form.Field width={10}>
                <Form.Input
                  fluid
                  label="Subject"
                  onChange={(_, { value }) =>
                    setState({
                      ...state,
                      data: { ...state.data, subject: value },
                    })
                  }
                  value={state.data?.subject}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field
                  control={TextareaAutosize}
                  label={
                    <label>
                      Body <MarkdownTooltip />
                    </label>
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      body: e.target.value,
                    })
                  }
                  placeholder="Body"
                  value={state.body}
                />
                <Form.Field>
                  <label>Preview</label>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: md.render(state.body),
                    }}
                    style={{
                      border: `1px solid ${Colors.gray300}`,
                      maxHeight: '25em',
                      minHeight: '8em',
                      overflowY: 'scroll',
                      padding: '10px',
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <MessageAttachments
                attachments={attachments}
                handleDelete={onDeleteFileInput}
                handleOnChange={onChangeFileInput}
              />
            </StyledForm>
          </Grid.Column>
          <Grid.Column style={{ background: '#FAFAFA' }} width={6}>
            <h6>Recipients</h6>
            <p>
              We will send this message to all customers in the following list:
            </p>
            <Table celled>
              <Table.Body>
                {projectsToSend.map((project) =>
                  project.owners.map((owner) => (
                    <Table.Row key={`${project.id}-${owner.user?.id}`}>
                      <Table.Cell>
                        <PersonListItem user={owner.user as User} />
                        <AddressFormat address={project.projectAddress} />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Button
                          kind="solid"
                          fontAwesomeIcon="trash-alt"
                          color="gray"
                          disabled={loading}
                          loading={loading}
                          onClick={() => {
                            setLoading(true)
                            setProjectsToSend(
                              projectsToSend.map((p) => {
                                if (project.id !== p.id) return p
                                return {
                                  ...p,
                                  owners: p.owners.filter(
                                    (u) => u.user?.id !== owner.user?.id,
                                  ),
                                }
                              }),
                            )
                            setLoading(false)
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )),
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default MultiMessageModal
