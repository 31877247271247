import { useMemo } from 'react'

import { DocumentNode, useQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import {
  FIND_MANY_ROUTE,
  FindManyRouteVariables,
  FindManyRoutePayload,
} from 'queries/route'

const useRouteManyNoContext = (opts?: {
  variables?: FindManyRouteVariables
  query?: DocumentNode
}) => {
  const {
    data: { routes } = { routes: [] },
    loading,
    refetch,
  } = useQuery<FindManyRoutePayload, FindManyRouteVariables>(
    opts?.query || FIND_MANY_ROUTE,
    {
      variables: opts?.variables,
    },
  )

  const routeOptions = useMemo(() => {
    return sortBy(
      routes.map((r) => {
        return {
          key: r?.id,
          text: r.reference,
          value: r.reference,
        }
      }),
      'text',
    )
  }, [routes])

  return {
    loading,
    refetch,
    routeOptions,
    routes,
  }
}

export default useRouteManyNoContext
