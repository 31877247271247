import { useMutation } from '@apollo/client'

import { notifyError, notifySuccess } from 'context/notifications/trigger'
import {
  UpdateManyProjectEventsPayload,
  UpdateManyProjectEventsVariables,
  UpdateProjectEventPayload,
  UpdateProjectEventVariables,
  UPDATE_MANY_PROJECT_EVENTS,
  UPDATE_PROJECT_EVENT,
} from 'queries/project-event'

type UseProjectEventMutateProps = {
  successMessage?: {
    updateMany?: string
    updateOne?: string
  }
  errorMessage?: {
    updateMany?: string
    updateOne?: string
  }
  disableNotifications?: boolean
  refetch?: () => unknown
}

const useProjectEventMutate = ({
  successMessage = { updateMany: 'Events updated', updateOne: 'Event updated' },
  errorMessage = {
    updateMany: "Couldn't update events",
    updateOne: "Couldn't update event",
  },
  disableNotifications = false,
  refetch = () => {},
}: UseProjectEventMutateProps = {}) => {
  const [updateProjectEvent, { loading: loadingUpdateEvent }] = useMutation<
    UpdateProjectEventPayload,
    UpdateProjectEventVariables
  >(UPDATE_PROJECT_EVENT, {
    onCompleted: () => {
      !disableNotifications && notifySuccess(successMessage.updateOne ?? '')
      refetch?.()
    },
    onError: () => {
      !disableNotifications && notifyError(errorMessage.updateOne ?? '')
    },
  })

  const [updateManyProjectEvents, { loading: loadingUpdateManyEvents }] =
    useMutation<
      UpdateManyProjectEventsPayload,
      UpdateManyProjectEventsVariables
    >(UPDATE_MANY_PROJECT_EVENTS, {
      onCompleted: () => {
        !disableNotifications && notifySuccess(successMessage.updateMany ?? '')
        refetch?.()
      },
      onError: () => {
        !disableNotifications && notifyError(errorMessage.updateMany ?? '')
      },
    })

  return {
    updateProjectEvent,
    updateManyProjectEvents,
    loadingUpdateManyEvents,
    loadingUpdateEvent,
    loading: loadingUpdateEvent || loadingUpdateManyEvents,
  }
}

export default useProjectEventMutate
