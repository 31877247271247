import { useContext } from 'react'

import { IssueContext } from './provider'

const useIssue = () => {
  const { issue, refetch } = useContext(IssueContext)

  return {
    issue,
    refetch,
  }
}

export default useIssue
