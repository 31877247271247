import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Button, Grid } from 'semantic-ui-react'

import ProspectLayout from 'components/admin/prospect/layout'
import useProspect from 'context/prospect/use'

const EstimateResults = (_: RouteComponentProps) => {
  const { estimate, prospect } = useProspect()
  const hasEstimate = !!estimate

  return (
    <ProspectLayout>
      <Grid padded>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <Button
              onClick={() =>
                window.open(
                  `/welcome/${prospect?.id}/estimate/${
                    hasEstimate ? 'results' : 'layout'
                  }`,
                )
              }
              secondary
              style={{ marginRight: '20px' }}
            >
              {hasEstimate ? 'View' : 'Create'} Estimate
              <FontAwesomeIcon
                icon={['fal', 'arrow-up-right-from-square']}
                style={{ marginLeft: '10px' }}
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ProspectLayout>
  )
}

export default EstimateResults
