import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { startCase } from 'lodash'
import styled, { css } from 'styled-components'

import { Badge } from 'components/shared/chip'
import { Colors } from 'styles/app/system'
import { Diff, DiffType } from 'types/diff'

type DiffsSectionProps = {
  diffs: Diff[]
  type: DiffType
}

const badgeColor = {
  [DiffType.ADD]: '#D7F3DC',
  [DiffType.DELETE]: '#FFD7D7',
  [DiffType.UPDATE]: '#FFF0D9',
}

const DiffsSection = ({ diffs, type }: DiffsSectionProps) => {
  return (
    <Wrapper>
      <Badge
        color={badgeColor[type]}
        name={`${type} ${diffs.length > 1 ? `(${diffs.length})` : ''}`}
      />

      <Content>
        {diffs.map(({ snapshot, changes }, i) => {
          return (
            <DiffWrapper key={snapshot.id + i}>
              <DiffText isTitle>ID: {snapshot.idReadable}</DiffText>

              <DiffTable>
                <tbody>
                  {Object.keys(snapshot).map((key) => {
                    if (['id', 'idReadable'].includes(key)) return null

                    return type !== DiffType.UPDATE ? (
                      <tr key={key}>
                        <td style={{ marginRight: 8, paddingLeft: 0 }}>
                          <DiffText className="no-margin light-gray">
                            {startCase(key)}
                          </DiffText>
                        </td>

                        <td className="fixed-size">
                          <DiffText
                            className="no-margin"
                            striked={type === DiffType.DELETE}
                            title={snapshot[key]}
                          >
                            {snapshot[key] || '--'}
                          </DiffText>
                        </td>
                      </tr>
                    ) : (
                      <tr key={key}>
                        <td style={{ marginRight: 8, paddingLeft: 0 }}>
                          <DiffText className="no-margin light-gray">
                            {startCase(key)}
                          </DiffText>
                        </td>

                        <td className="fixed-size">
                          {changes?.from[key] !== undefined ? (
                            <DiffText
                              className="no-margin"
                              title={changes?.from[key] || undefined}
                              striked
                            >
                              {changes?.from[key] || '--'}
                            </DiffText>
                          ) : (
                            <DiffText
                              className="no-margin"
                              title={snapshot[key] || undefined}
                            >
                              {snapshot[key] || '--'}
                            </DiffText>
                          )}
                        </td>

                        <td>
                          {changes?.to[key] || !!changes?.from[key] ? (
                            <FontAwesomeIcon
                              icon={['fal', 'long-arrow-right']}
                              color={Colors.blue200}
                            />
                          ) : null}
                        </td>

                        <td className="fixed-size">
                          {changes?.to[key] !== undefined ? (
                            <DiffText
                              className="no-margin"
                              title={changes?.to[key] || undefined}
                            >
                              {changes?.to[key] || '--'}
                            </DiffText>
                          ) : null}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </DiffTable>
            </DiffWrapper>
          )
        })}
      </Content>
    </Wrapper>
  )
}

export default DiffsSection

const Wrapper = styled.div`
  padding-block: 8px;
  margin-inline: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const DiffWrapper = styled.div`
  border-bottom: 1px solid ${Colors.gray100};
  margin-bottom: 16px;
  padding-bottom: 16px;
`

type DiffTextProps = {
  isTitle?: boolean
  striked?: boolean
}

const DiffText = styled.p<DiffTextProps>`
  margin: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  ${(props) =>
    props.striked
      ? css`
          color: ${Colors.gray400} !important;
          text-decoration: line-through;
        `
      : undefined}

  ${(props) =>
    props.isTitle
      ? css`
          color: ${Colors.gray300} !important;
          width: max-content;
        `
      : undefined}
`

const DiffTable = styled.table`
  td {
    padding-left: 16px;
    overflow: hidden;
    white-space: nowrap;
  }

  td.fixed-size {
    white-space: normal;
    max-width: 140px;
    width: 140px;
  }
`
