import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { Button, Form, Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { notifySuccess } from 'context/notifications/trigger'
import {
  UPSERT_PROSPECT,
  UpsertProspectPayload,
  UpsertProspectVariables,
} from 'queries/prospect'
import { StyledSystem } from 'styles/admin/system'
import { FormContactSource } from 'types/crm'
import { Prospect, ProspectData, ProspectType } from 'types/prospect'
import { UserTypeTrade } from 'types/user'
import {
  capsSnakeCaseToTitleCase,
  emailIsValid,
  getDropdownOptionsFromEnum,
} from 'views/utils'

interface ProspectModalProps {
  onCloseModal: () => void
  onSave?: () => void
  openModal: boolean
}

const CreateProspectModal = ({
  onCloseModal,
  onSave,
  openModal,
}: ProspectModalProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<Partial<NullableRecord<Prospect>>>({
    data: {
      form_contact_source: FormContactSource.TRADE,
      prospectType: ProspectType.TRADE,
      userType: UserTypeTrade.ARCHITECT,
    },
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    zipCode: '',
  })

  const resetState = () => {
    setState({
      data: {
        form_contact_source: FormContactSource.TRADE,
        prospectType: ProspectType.TRADE,
        userType: UserTypeTrade.ARCHITECT,
      },
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      zipCode: '',
    })
  }

  const [upsertProspect] = useMutation<
    UpsertProspectPayload,
    UpsertProspectVariables
  >(UPSERT_PROSPECT, {
    onCompleted: () => {
      setLoading(false)
      notifySuccess('Prospect Created')
      resetState()
      onSave?.()
    },
  })

  const toggleModal = () => {
    onCloseModal()
    resetState()
  }

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [name]: value })

  const handleSubmit = async () => {
    setLoading(true)
    await upsertProspect({
      variables: {
        data: {
          data: {
            form_contact_source: state.data?.form_contact_source,
            location: '',
            locationBy: '',
            prospectType: state.data?.prospectType,
            quizAnswers: [],
            userType: state.data?.userType,
          },
          email: state.email ?? '',
          firstName: state.firstName ?? '',
          lastName: state.lastName ?? '',
          phone: state.phone ?? '',
          zipCode: state.zipCode ?? '',
        },
      },
    })
  }

  return (
    <StyledModal
      closeIcon
      open={openModal}
      onClose={toggleModal}
      size="large"
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Header>
        <h4>Create Prospect</h4>
      </Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Prospect Type</label>
              <Form.Dropdown
                clearable
                name="prospectType"
                onChange={(_, { value }) => {
                  const isTrade = value === ProspectType.TRADE
                  const isHomeowner = value === ProspectType.HOMEOWNER
                  const isNull = !value
                  const contactSource = isTrade
                    ? {
                        form_contact_source: FormContactSource.TRADE,
                        prospectType: ProspectType.TRADE,
                        userType: UserTypeTrade.ARCHITECT,
                      }
                    : isHomeowner
                    ? {
                        form_contact_source: FormContactSource.SCHEDULE,
                        prospectType: ProspectType.HOMEOWNER,
                        userType: null,
                      }
                    : {
                        form_contact_source: null,
                        prospectType: null,
                        userType: null,
                      }
                  setState({
                    ...state,
                    ...((isTrade || isNull) && {
                      phone: null,
                    }),
                    data: {
                      ...state.data,
                      ...contactSource,
                      prospectType: value as ProspectType,
                    } as ProspectData,
                  })
                }}
                options={[
                  ...Object.values(ProspectType).map((v) => ({
                    key: v,
                    text: capsSnakeCaseToTitleCase(v),
                    value: v,
                  })),
                ]}
                selection
                value={state.data?.prospectType || ''}
              />
            </Form.Field>
            <Form.Field>
              <label>Contact Source</label>
              <Form.Dropdown
                clearable
                name="form_contact_source"
                onChange={(_, { value }) => {
                  setState({
                    ...state,
                    data: {
                      ...state.data,
                      form_contact_source: value as FormContactSource,
                    },
                  })
                }}
                options={[
                  {
                    key: 'Schedule',
                    text: 'Schedule',
                    value: FormContactSource.SCHEDULE,
                  },
                  {
                    key: 'Trade',
                    text: 'Trade',
                    value: FormContactSource.TRADE,
                  },
                ]}
                selection
                value={state.data?.form_contact_source}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Email"
              name="email"
              onChange={onChangeInput}
              required
              value={state.email}
            />
            <Form.Input
              label="Zip Code"
              name="zipCode"
              onChange={onChangeInput}
              value={state.zipCode}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="First Name"
              name="firstName"
              onChange={onChangeInput}
              placeholder="First Name"
              value={state.firstName}
            />
            <Form.Input
              label="Last Name"
              name="lastName"
              onChange={onChangeInput}
              placeholder="Last Name"
              value={state.lastName}
            />
          </Form.Group>
          {state.data?.prospectType === ProspectType.TRADE && (
            <Form.Group widths="equal">
              <Form.Dropdown
                label="User Type"
                onChange={(_, { value }) =>
                  setState({
                    ...state,
                    data: {
                      ...state.data,
                      userType: value as UserTypeTrade,
                    },
                  })
                }
                options={getDropdownOptionsFromEnum(UserTypeTrade)}
                placeholder="User Type"
                selection
                value={state.data?.userType}
              />
              <Form.Field />
            </Form.Group>
          )}
          {state.data?.prospectType === ProspectType.HOMEOWNER && (
            <Form.Group widths="equal">
              <Form.Input
                label="Phone"
                name="phone"
                onChange={onChangeInput}
                placeholder="Phone"
                value={state.phone}
              />
              <Form.Field />
            </Form.Group>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Save"
          disabled={!emailIsValid(state.email ?? '')}
          loading={loading}
          onClick={handleSubmit}
          primary
        />
      </Modal.Actions>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${StyledSystem}
  cursor: auto;
  p {
    margin: 0;
  }
  min-height: auto;
  h2 {
    margin-bottom: 1rem;
  }
`

export default CreateProspectModal
