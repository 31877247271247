import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import lodash from 'lodash'
import { Dropdown, Image, Label, Menu } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import useAuth from 'context/auth/use'
import useDesignsApprovedUnsentCount from 'context/design/use-approved-unsent-count'
import useDesignsAwaitingFeedbackCount from 'context/design/use-awaiting-feedback-count'
import useDesignsAwaitingRenderCount from 'context/design/use-awaiting-render-count'
import useRoomsAwaitingPackCount from 'context/room/use-awaiting-pack-count'
import useAddOnRemedialCounts from 'context/supplier-order/use-addon-remedial-counts'
import useSORegularCounts from 'context/supplier-order/use-regular-counts'
import FormLogo from 'icons/form-logo-white.svg'
import { Colors } from 'styles/app/system'
import { Project } from 'types/project'
import { FORM_EMPLOYEE_ROLES, User, UserRole } from 'types/user'

interface HeaderProps {
  defaultValue?: string
  project: Project
  label?: string
  logout: () => void
  user: User
  fixed?: boolean
}

export default (props: RouteComponentProps<HeaderProps>) => {
  const { formUserId } = useAuth()

  const { designsApprovedUnsentCount } = useDesignsApprovedUnsentCount()
  const { designsAwaitingFeedbackCount } = useDesignsAwaitingFeedbackCount()
  const { designsAwaitingRenderCount } = useDesignsAwaitingRenderCount()
  const { roomsAwaitingPackCount } = useRoomsAwaitingPackCount()
  const { sosEDINotSentCount, sosAwaitingFORMCount, sosAwaitingNobiliaCount } =
    useSORegularCounts()
  const {
    addOnRemedialsAwaitingFORMCount,
    addOnRemedialsAwaitingNobiliaCount,
  } = useAddOnRemedialCounts()

  // random prospect id for arbitrary estimator usage
  const randomProspectId = useMemo(() => {
    // thomasgsherman@gmail.com prospects in prod
    const ids = [
      'ckar2val0ymmq07530vbgkjcs',
      'ckp03xxea873941g9j7tfac9zk',
      'ckp4r95641153051g7ivh8aupxw',
      'cktp98149646711gcnxzrhyafm',
      'ckufua67l723551g9ait7wfr85',
    ]
    const randomIndex = Math.floor(Math.random() * ids.length)
    return ids[randomIndex]
  }, [])

  const menuItems: (
    | SingleMenuItemData
    | SingleNotifMenuItemData
    | DropdownMenuItemData
  )[] = [
    {
      type: 'single',
      text: 'ACK Planning',
      link: '/admin/supplier-orders-planning',
      roles: [UserRole.NOBILIA_ORDER_PROCESSOR],
    },
    {
      type: 'single',
      text: 'Shipments',
      link: '/admin/shipments',
      roles: [UserRole.NOBILIA_ORDER_PROCESSOR],
    },
    {
      type: 'single',
      text: 'Routes',
      link: '/admin/routes',
      roles: [UserRole.NOBILIA_ORDER_PROCESSOR],
    },
    {
      type: 'single',
      text: 'Prospects',
      link: '/admin/prospects',
      roles: [
        UserRole.ACCOUNT_EXECUTIVE,
        UserRole.ADMIN,
        UserRole.SALES_DESIGNER,
        UserRole.TRADE_DESIGNER,
      ],
    },
    {
      type: 'single',
      text: 'Projects',
      link: '/admin/projects',
      roles: FORM_EMPLOYEE_ROLES,
    },
    {
      type: 'single-notif',
      text: 'Renders',
      link: '/admin/designs?defaultFilter=rendering',
      notifValue: designsAwaitingRenderCount,
      roles: [UserRole.ARTIST, UserRole.RENDER_MANAGER, UserRole.ADMIN],
    },
    {
      type: 'dropdown',
      text: 'Design Ops',
      items: [
        {
          text: 'Awaiting Review',
          link: '/admin/designs?defaultFilter=awaitingFeedback',
          label: designsAwaitingFeedbackCount,
        },
        {
          label: designsApprovedUnsentCount,
          link: `/admin/designs?defaultFilter=unsent`,
          text: 'Approved Unsent',
        },
        {
          text: 'Awaiting Pack',
          link: '/admin/packs?defaultFilter=awaitingPack',
          label: roomsAwaitingPackCount,
        },
        {
          text: 'Packs Released',
          link: '/admin/packs?defaultFilter=releasedPack',
        },
      ],
      roles: [
        UserRole.TECH_DESIGNER,
        UserRole.TECH_DESIGNER_SUPPORT,
        UserRole.TRADE_DESIGNER,
        UserRole.SALES_DESIGNER,
        UserRole.CUSTOMER_SUPPORT,
        UserRole.ADMIN,
      ],
    },
    {
      type: 'dropdown',
      text: 'Logistics',
      items: [
        {
          text: 'Deliveries',
          link: '/admin/deliveries',
        },
        {
          text: 'Orders: FORM',
          link: '/admin/orders-cabinetry',
        },
        {
          text: 'Orders: Samples',
          link: '/admin/orders-samples',
        },
        {
          text: 'Issues Supplier Orders',
          link: '/admin/supplier-orders?type=issues',
        },
        {
          label: addOnRemedialsAwaitingNobiliaCount,
          text: 'Issues: Awaiting Nobilia',
          link: '/admin/supplier-orders?type=issues&defaultFilter=awaitingNobilia',
        },
        {
          label: addOnRemedialsAwaitingFORMCount,
          text: 'Issues: Awaiting FORM',
          link: '/admin/supplier-orders?type=issues&defaultFilter=awaitingForm',
        },
        {
          text: 'Routes',
          link: '/admin/routes',
        },
        {
          text: 'Shipments',
          link: '/admin/shipments',
        },
        {
          text: 'Supplier Orders',
          link: `/admin/supplier-orders`,
        },
        {
          label: sosEDINotSentCount,
          text: 'Supplier Orders: EDI Not Sent',
          link: `/admin/supplier-orders?defaultFilter=EDINotSent`,
        },
        {
          label: sosAwaitingNobiliaCount,
          text: 'Supplier Orders: Awaiting Nobilia',
          link: `/admin/supplier-orders?defaultFilter=awaitingNobilia`,
        },
        {
          label: sosAwaitingFORMCount,
          text: 'Supplier Orders: Awaiting FORM',
          link: `/admin/supplier-orders?defaultFilter=awaitingForm`,
        },
        {
          text: 'Supplier Orders: Order Processing',
          link: `/admin/dashboard/${formUserId}/nobilia-order-processor`,
        },
        {
          text: 'Supplier Orders: Planning',
          link: `/admin/supplier-orders-planning`,
        },
      ],
      style: {
        minWidth: '119px',
      },
      roles: [
        UserRole.CUSTOMER_SUPPORT,
        UserRole.TECH_DESIGNER,
        UserRole.TECH_DESIGNER_SUPPORT,
        UserRole.TRADE_DESIGNER,
        UserRole.ADMIN,
      ],
    },
    {
      type: 'dropdown',
      text: 'Product Tools',
      items: [
        {
          text: 'Catalog',
          link: `/admin/catalog/${process.env.GATSBY_DEFAULT_CATALOG}`,
          roles: [
            UserRole.ADMIN,
            UserRole.SALES_DESIGNER,
            UserRole.TECH_DESIGNER,
            UserRole.TECH_DESIGNER_SUPPORT,
            UserRole.TRADE_DESIGNER,
            UserRole.CUSTOMER_SUPPORT,
          ],
        },
        {
          text: 'Browse Fronts',
          link: `/admin/catalog/${process.env.GATSBY_DEFAULT_CATALOG}/nobilia-features/105/browse`,
          roles: [
            UserRole.ADMIN,
            UserRole.SALES_DESIGNER,
            UserRole.TECH_DESIGNER,
            UserRole.TECH_DESIGNER_SUPPORT,
            UserRole.TRADE_DESIGNER,
            UserRole.CUSTOMER_SUPPORT,
          ],
        },
        {
          text: 'Browse Handles',
          link: `/admin/catalog/${process.env.GATSBY_DEFAULT_CATALOG}/nobilia-features/303/browse`,
          roles: [
            UserRole.ADMIN,
            UserRole.SALES_DESIGNER,
            UserRole.TECH_DESIGNER,
            UserRole.TECH_DESIGNER_SUPPORT,
            UserRole.TRADE_DESIGNER,
            UserRole.CUSTOMER_SUPPORT,
          ],
        },
        {
          text: 'Test Estimator',
          link: `/welcome/${randomProspectId}/estimate/results`,
          roles:
            process.env.GATSBY_ACTIVE_ENV === 'production'
              ? [UserRole.SALES_DESIGNER, UserRole.ADMIN]
              : [],
        },
      ],
    },
    {
      type: 'dropdown',
      text: 'Configuration',
      items: [
        {
          text: 'BuildZoom',
          link: '/admin/projects-buildzoom',
          roles: [UserRole.BUILDZOOM, UserRole.ADMIN],
        },
        {
          text: 'Companies',
          link: '/admin/companies',
          roles: [UserRole.ADMIN, UserRole.TRADE_DESIGNER],
        },
        {
          text: 'Design Advisory Codes',
          link: '/admin/design-advisory-codes',
          roles: [
            UserRole.ADMIN,
            UserRole.TECH_DESIGNER,
            UserRole.TECH_DESIGNER_SUPPORT,
            UserRole.TRADE_DESIGNER,
          ],
        },
        {
          text: 'Designer Queue',
          link: '/admin/staff-queue/sales-designer-projects',
          roles: [
            UserRole.SALES_DESIGNER,
            UserRole.TECH_DESIGNER,
            UserRole.TECH_DESIGNER_SUPPORT,
            UserRole.TRADE_DESIGNER,
            UserRole.ADMIN,
          ],
        },
        {
          text: 'Lead Times',
          link: '/admin/lead-times',
          roles: FORM_EMPLOYEE_ROLES,
        },
        {
          text: 'Message Templates',
          link: '/admin/message-template-books',
          roles: [
            UserRole.SALES_DESIGNER,
            UserRole.TECH_DESIGNER,
            UserRole.TECH_DESIGNER_SUPPORT,
            UserRole.TRADE_DESIGNER,
            UserRole.ADMIN,
          ],
        },
        {
          text: 'Planner',
          link: '/admin/rooms-planner-test',
          roles:
            process.env.GATSBY_ACTIVE_ENV === 'production'
              ? []
              : [
                  UserRole.SALES_DESIGNER,
                  UserRole.TECH_DESIGNER,
                  UserRole.TECH_DESIGNER_SUPPORT,
                  UserRole.TRADE_DESIGNER,
                  UserRole.ADMIN,
                ],
        },
        {
          text: 'Selection Centers',
          link: '/admin/selection-centers',
          roles: [UserRole.ADMIN],
        },
        {
          text: 'Users',
          link: '/admin/users',
          roles: [
            UserRole.ADMIN,
            UserRole.TRADE_DESIGNER,
            UserRole.CUSTOMER_SUPPORT,
          ],
        },
      ],
      style: {
        minWidth: '158px',
      },
    },
  ]

  return (
    <StyledMenu borderless $fixed={props.fixed}>
      <Menu.Item className="logo" key="logo">
        <Link to="/admin" style={{ verticalAlign: 'middle' }}>
          <FormLogo />
        </Link>
      </Menu.Item>

      {menuItems.map((item) => {
        if (item.type === 'dropdown') {
          return (
            <DropdownMenuItem
              item={item as DropdownMenuItemData}
              key={item.text}
            />
          )
        } else if (item.type === 'single-notif') {
          return (
            <SingleNotifMenuItem
              item={item as SingleNotifMenuItemData}
              key={item.text}
            />
          )
        } else {
          return (
            <SingleMenuItem item={item as SingleMenuItemData} key={item.text} />
          )
        }
      })}

      <Menu.Item position="right" style={{ padding: 0 }} key="User Menu">
        <UserDropdown
          item
          icon={
            <FontAwesomeIcon
              icon={['fal', 'angle-down']}
              style={{ marginLeft: '12px' }}
            />
          }
          trigger={
            <>
              <Image
                circular
                avatar
                src={props.user ? props.user.picture : null}
              />
              <span className="caption">
                {props.user
                  ? `${props.user.firstName || props.user.email}`
                  : 'Unknown User'}
              </span>
            </>
          }
        >
          <Dropdown.Menu>
            <Menu.Item onClick={() => navigate('/admin/profile')}>
              Profile
            </Menu.Item>
            <Menu.Item onClick={props.logout}>Log Out</Menu.Item>
          </Dropdown.Menu>
        </UserDropdown>
      </Menu.Item>
    </StyledMenu>
  )
}

interface MenuLinkData {
  label?: number
  link: string
  roles?: UserRole[]
  text: string
}

interface SingleMenuItemData extends MenuLinkData {
  type: 'single'
}

interface SingleNotifMenuItemData extends MenuLinkData {
  type: 'single-notif'
  notifValue: string | number
}

interface DropdownMenuItemData {
  type: 'dropdown'
  text: string
  items: MenuLinkData[]
  style?: unknown
  roles?: UserRole[]
}

const canViewItem = (item: { roles?: UserRole[] }, userRoles: UserRole[]) => {
  return !item.roles || lodash.intersection(item.roles, userRoles).length
}

const SingleMenuItem = ({ item }: { item: SingleMenuItemData }) => {
  const { roles: userRoles } = useAuth()
  if (!canViewItem(item, userRoles)) {
    return null
  }
  return (
    <Menu.Item active={window.location.pathname === `${item.link}/`}>
      <Link to={item.link}>{item.text}</Link>
    </Menu.Item>
  )
}

const SingleNotifMenuItem = ({ item }: { item: SingleNotifMenuItemData }) => {
  const { roles: userRoles } = useAuth()
  if (!canViewItem(item, userRoles)) {
    return null
  }
  return (
    <Menu.Item active={window.location.href.endsWith(item.link)}>
      <Link to={item.link}>{item.text}</Link>
      {item.notifValue ? (
        <Label className="orange-count">{item.notifValue}</Label>
      ) : null}
    </Menu.Item>
  )
}

const DropdownMenuItem = ({ item }: { item: DropdownMenuItemData }) => {
  const { roles: userRoles } = useAuth()
  if (
    !canViewItem(item, userRoles) ||
    !item.items.some((child) => canViewItem(child, userRoles))
  ) {
    return null
  }
  return (
    <StyledDropdown
      icon={
        <FontAwesomeIcon
          icon={['fal', 'angle-down']}
          style={{ marginLeft: '12px' }}
        />
      }
      item
      key={item.text}
      simple
      style={item.style}
      text={item.text}
    >
      <Dropdown.Menu>
        {item.items.map((child) => {
          if (!canViewItem(child, userRoles)) {
            return null
          }
          return (
            <Menu.Item
              active={window.location.href.endsWith(child.link)}
              key={child.text}
            >
              <Link to={child.link}>{child.text}</Link>
              {child.label ? (
                <Label className="count">{child.label}</Label>
              ) : null}
            </Menu.Item>
          )
        })}
      </Dropdown.Menu>
    </StyledDropdown>
  )
}

const StyledMenu = styled(Menu)`
  &&&&& {
    background: ${Colors.blue700};
    border: none !important;
    border-radius: 0;
    box-shadow: none !important;
    min-height: 57px;
    ${(props) =>
      !props.$fixed &&
      css`
        margin-bottom: 35px;
      `}

    > .item {
      color: white;
      font-size: 15px;
      font-weight: 300;
      justify-content: center;
      padding: 18px 25px;
    }

    > .item.active,
    .menu > .item.active {
      background-color: inherit !important;
      font-weight: 300 !important;
    }

    .item.logo {
      padding-left: 25px;
    }

    > .item:hover:not(.logo),
    .item.active {
      background-color: ${Colors.blue800} !important;
    }

    a {
      line-height: 1;
    }

    .label.orange-count {
      align-items: center;
      background: ${Colors.orange500} !important;
      border-radius: 100%;
      color: white !important;
      display: flex;
      font-size: 10px;
      font-weight: 300;
      height: 21px;
      justify-content: center;
      padding: 0;
      width: 21px;
    }
  }
`

const StyledDropdown = styled(Dropdown)`
  &&&&& {
    border: none;
    box-shadow: none !important;
    z-index: 3;

    .menu {
      border: 1px solid ${Colors.gray200} !important;
      border-radius: 0 !important;
      box-shadow: none;
      min-width: 100%;

      > .item {
        color: ${Colors.gray500} !important;
        display: flex !important;
        font-weight: 300 !important;
        font-size: 13px !important;
        padding: 0 !important;
      }

      > .item a {
        padding: 14px 16px;
        width: 100%;
      }

      > .item:hover,
      .item.active:hover {
        color: ${Colors.black} !important;
        background-color: ${Colors.gray50} !important;
      }

      div.count {
        align-items: center;
        background: #f5edd7 !important;
        border-radius: 100%;
        color: #7c6b36 !important;
        display: flex;
        font-size: 10px;
        font-weight: 300;
        height: 20px;
        justify-content: center;
        min-width: 20px;
        padding: 0;
        width: 20px;
      }
    }
  }
`

const UserDropdown = styled(StyledDropdown)`
  &&&&& {
    align-items: center;
    background-color: ${Colors.blue800} !important;
    display: flex;
    height: 100%;

    > span,
    svg {
      color: ${Colors.white};
    }

    .avatar {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }

    .menu {
      > .item {
        padding: 14px 16px !important;
        width: 100%;
      }
    }
  }
`
