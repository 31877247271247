import { useContext } from 'react'

import { NobiliaProductClassContext } from './provider'

const useNobiliaProductClass = () => {
  const { catalog, nobiliaProductClass, refetch } = useContext(
    NobiliaProductClassContext,
  )

  return {
    catalog,
    nobiliaProductClass,
    refetch,
  }
}

export default useNobiliaProductClass
