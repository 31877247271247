import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import moment from 'moment'

import { FORMStatus } from 'context/supplier-order/utils-many'
import {
  FIND_MANY_SUPPLIER_ORDER_COUNT,
  FindManySupplierOrderVariables,
  FindManySupplierOrderCountPayload,
} from 'queries/supplier-order'

import { getQueryVariables } from './utils-many'

const useNobiliaOrderProcessorCounts = (userId?: string) => {
  const variablesLateOrders = useMemo(
    () =>
      getQueryVariables({
        createdAt: {
          lte: moment().subtract(7, 'days').toISOString(),
        },
        statusesFORM: [
          FORMStatus.AwaitingFORM,
          FORMStatus.AwaitingNobilia,
          FORMStatus.EDINotSent,
        ],
        take: undefined,
      }),
    [userId],
  )

  const variablesOnTrackOrders = useMemo(
    () =>
      getQueryVariables({
        createdAt: {
          gte: moment().subtract(7, 'days').toISOString(),
        },
        statusesFORM: [
          FORMStatus.AwaitingFORM,
          FORMStatus.AwaitingNobilia,
          FORMStatus.EDINotSent,
        ],
        take: undefined,
      }),
    [userId],
  )

  const {
    data: { count: lateCount } = { count: 0 },
    loading: loadingLateCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesLateOrders,
  })

  const {
    data: { count: onTrackCount } = { count: 0 },
    loading: loadingOnTrackCount,
  } = useQuery<
    FindManySupplierOrderCountPayload,
    FindManySupplierOrderVariables
  >(FIND_MANY_SUPPLIER_ORDER_COUNT, {
    variables: variablesOnTrackOrders,
  })

  return {
    loadingLateCount,
    loadingOnTrackCount,
    lateCount,
    onTrackCount,
  }
}

export default useNobiliaOrderProcessorCounts
