// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as BABYLON from 'babylonjs'

import * as compose from 'planner/compose'
import { SectionViewBase } from 'planner/objects/annotations/section-view'
import { CameraBase } from 'planner/objects/camera'
import { NodeBase } from 'planner/objects/node'
import { ProductBase } from 'planner/objects/product'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import * as nodeUtils from 'planner/utils/node-utils'
import { GenericProductClass } from 'types/generic-product-class'

import { RenderMode, ViewMode } from './types'

export interface DesignPlannerReducerState {
  activeMenu: string
  activeCamera: CameraBase
  activeSectionView: SectionViewBase
  cameraPosition: BABYLON.Vector3
  cameraTarget: BABYLON.Vector3
  defaultPosition: BABYLON.Vector3
  defaultPositionWall: BABYLON.Vector3
  defaultRotation: BABYLON.Vector3
  defaultRotationWall: BABYLON.Vector3
  designLoaded: boolean
  measurementUnit: MeasurementUnit
  moveEnabled: boolean
  moveFrom: [BABYLON.Vector3, number[]]
  recenterCameraTriggeredAt: Date
  recenterPlanTriggeredAt: Date
  renderMode: RenderMode
  sceneRelationshipGraph: compose.NodeRelationshipGraph
  sceneState: NodeBase[]
  screenshotTriggeredAt: Date
  sectionViewMode: boolean
  selectedNode: NodeBase
  showEstimate: boolean
  showProductList: boolean
  snapToProducts: boolean
  viewBoxBase: number
  viewBoxPanX: number
  viewBoxPanY: number
  viewMode: ViewMode
}

interface ChangeNodes {
  type: 'change_nodes'
  countertopProductClass?: GenericProductClass
  nodesToAdd: NodeBase[]
  nodesToRemove: NodeBase[]
  plinthProductClass?: GenericProductClass
  selectedNode: NodeBase
}

interface DeleteSelectedNode {
  type: 'delete_selected_node'
  countertopProductClass?: GenericProductClass
  plinthProductClass?: GenericProductClass
}

interface DeselectNode {
  type: 'deselect_node'
}

interface DisableMove {
  type: 'disable_move'
  countertopProductClass?: GenericProductClass
  plinthProductClass?: GenericProductClass
}

interface DoMove {
  type: 'do_move'
  moveFrom: [BABYLON.Vector3, number[]]
  productInMotion: ProductBase
}

interface LoadDesign {
  type: 'load_design'
  countertopProductClass?: GenericProductClass
  plinthProductClass?: GenericProductClass
  nodesToAdd: NodeBase[]
}

interface PickNode {
  type: 'pick_node'
  nodeName: string
}

interface SetActiveMenu {
  type: 'set_active_menu'
  activeMenu: string
}

interface SetActiveSectionView {
  type: 'set_active_section_view'
  activeSectionView: SectionViewBase
}

interface SetSelectedNode {
  type: 'set_selected_node'
  node: NodeBase
}

interface SetViewParameters {
  type: 'set_view_parameters'
  cameraPosition: BABYLON.Vector3
  cameraTarget: BABYLON.Vector3
  recenterCameraTriggeredAt?: Date
  recenterPlanTriggeredAt?: Date
  viewBoxBase: number
  viewBoxPanX: number
  viewBoxPanY: number
}

interface SetViewPlan {
  type: 'set_view_plan'
}

interface SetViewScene {
  type: 'set_view_scene'
  camera: CameraBase
  renderMode?: RenderMode
}

interface SetViewSection {
  type: 'set_view_section'
  camera: CameraBase
  renderMode?: RenderMode
}

interface ToggleEstimate {
  type: 'toggle_estimate'
}

interface ToggleMaterials {
  type: 'toggle_materials'
}

interface ToggleMeasurementUnit {
  type: 'toggle_measurement_unit'
  measurementUnit: MeasurementUnit
}

interface ToggleProductList {
  type: 'toggle_product_list'
}

interface ToggleSectionViewMode {
  type: 'toggle_section_view_mode'
}

interface ToggleSnapToProducts {
  type: 'toggle_snap_to_products'
}

interface TriggerScreenshot {
  type: 'trigger_screenshot'
}

export type DesignPlannerReducerAction =
  | ChangeNodes
  | DeleteSelectedNode
  | DeselectNode
  | DisableMove
  | DoMove
  | LoadDesign
  | PickNode
  | SetActiveMenu
  | SetActiveSectionView
  | SetSelectedNode
  | SetViewPlan
  | SetViewScene
  | SetViewSection
  | ToggleEstimate
  | ToggleMaterials
  | ToggleMeasurementUnit
  | ToggleProductList
  | ToggleSectionViewMode
  | ToggleSnapToProducts
  | TriggerScreenshot
  | SetViewParameters

const designPlannerReducer = (
  state: DesignPlannerReducerState,
  action: DesignPlannerReducerAction,
): Partial<DesignPlannerReducerState> => {
  switch (action.type) {
    case 'change_nodes': {
      const nodesToRemove = action.nodesToRemove
      const nodesToAdd = action.nodesToAdd
      return {
        ...state,
        moveEnabled: false,
        ...compose.deriveNodes(
          state.sceneState,
          nodesToAdd,
          nodesToRemove,
          state.sceneRelationshipGraph,
          {
            countertopProductClass: action.countertopProductClass,
            plinthProductClass: action.plinthProductClass,
          },
        ),
        selectedNode: action.selectedNode,
      }
    }
    case 'delete_selected_node':
      if (state.selectedNode.parent)
        state.selectedNode.parent.removeChild(state.selectedNode)
      return {
        ...state,
        ...compose.deriveNodes(
          state.sceneState,
          [],
          state.sceneState.filter(
            (n) => n === state.selectedNode || n.parent === state.selectedNode,
          ),
          state.sceneRelationshipGraph,
          {
            countertopProductClass: action.countertopProductClass,
            plinthProductClass: action.plinthProductClass,
          },
        ),
        selectedNode: null,
      }
    case 'deselect_node':
      return {
        ...state,
        selectedNode: null,
      }
    case 'disable_move':
      return {
        ...state,
        ...compose.deriveNodes(
          state.sceneState,
          [],
          [],
          state.sceneRelationshipGraph,
          {
            countertopProductClass: action.countertopProductClass,
            plinthProductClass: action.plinthProductClass,
          },
        ),
        moveEnabled: false,
        moveFrom: null,
      }
    case 'do_move':
      return {
        ...state,
        ...compose.deriveNodes(
          state.sceneState,
          [],
          [],
          state.sceneRelationshipGraph,
          {
            productInMotion: action.productInMotion,
          },
        ),
        moveFrom: action.moveFrom || null,
      }
    case 'load_design':
      return {
        ...state,
        designLoaded: true,
        ...compose.deriveNodes(
          state.sceneState,
          action.nodesToAdd,
          [],
          state.sceneRelationshipGraph,
          {
            countertopProductClass: action.countertopProductClass,
            plinthProductClass: action.plinthProductClass,
          },
        ),
      }
    case 'pick_node': {
      const node = nodeUtils.findNodeByName(state.sceneState, action.nodeName)
      if (nodeUtils.isMoveableDesign(node))
        return {
          ...state,
          moveEnabled: true,
          selectedNode: node,
          activeMenu: 'products',
        }
      return {
        ...state,
        selectedNode: node,
      }
    }
    case 'set_active_menu':
      return {
        ...state,
        activeMenu: action.activeMenu,
        selectedNode: null,
      }
    case 'set_active_section_view':
      return {
        ...state,
        activeSectionView: action.activeSectionView,
      }
    case 'set_selected_node':
      return {
        ...state,
        selectedNode: action.node,
      }
    case 'set_view_parameters':
      return {
        ...state,
        cameraPosition: action.cameraPosition,
        cameraTarget: action.cameraTarget,
        recenterCameraTriggeredAt:
          action.recenterCameraTriggeredAt || state.recenterCameraTriggeredAt,
        recenterPlanTriggeredAt:
          action.recenterPlanTriggeredAt || state.recenterPlanTriggeredAt,
        viewBoxBase: action.viewBoxBase,
        viewBoxPanX: action.viewBoxPanX,
        viewBoxPanY: action.viewBoxPanY,
      }
    case 'set_view_plan':
      return {
        ...state,
        viewMode: ViewMode.PLAN,
      }
    case 'set_view_scene':
      return {
        ...state,
        activeCamera: action.camera || state.activeCamera,
        renderMode: action.renderMode || state.renderMode,
        viewMode: ViewMode.SCENE,
      }
    case 'set_view_section':
      return {
        ...state,
        activeCamera: action.camera || state.activeCamera,
        renderMode: action.renderMode || state.renderMode,
        viewMode: ViewMode.SECTION,
      }
    case 'toggle_estimate':
      return {
        ...state,
        showEstimate: !state.showEstimate,
      }
    case 'toggle_materials':
      return {
        ...state,
        renderMode:
          state.renderMode === RenderMode.MATERIALS
            ? RenderMode.LINES
            : RenderMode.MATERIALS,
      }
    case 'toggle_measurement_unit':
      return { ...state, measurementUnit: action.measurementUnit }
    case 'toggle_product_list':
      return {
        ...state,
        showProductList: !state.showProductList,
      }
    case 'toggle_section_view_mode':
      return {
        ...state,
        sectionViewMode: !state.sectionViewMode,
      }
    case 'toggle_snap_to_products':
      return {
        ...state,
        snapToProducts: !state.snapToProducts,
      }
    case 'trigger_screenshot':
      return {
        ...state,
        screenshotTriggeredAt: new Date(),
      }
    default:
      return state
  }
}

export default designPlannerReducer
