import React from 'react'

import { Icon, Popup } from 'semantic-ui-react'
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'

import { Colors } from 'styles/app/system'

interface Instruction {
  text: string
  type: string
}

const instructionTemplates: Instruction[] = [
  {
    text: `
            <ul>
              <li><b>Delete:</b><br/>This will delete an appliance from the list and from the customer's dashboard.</li>
              <li><b>Archive:</b><br/>You can archive an appliance instead of deleting if you're not 100% sure about doing so. You'll be able to unarchive later on if needed. Keep in mind unarchiving will make an appliance show on the customer's dashboard as well.</li>
              <li><b>Edit:</b><br/>Edit the details of any appliance & add product or spec links that will help with the rendering process.</li>
            </ul>
            `,
    type: 'Actions',
  },
  {
    text: `
            <ul>
              <li><b>Updated by customer:</b><br/>When a customer changes appliance information you'll see an "Updated by customer" label. Click on "Acknowledge" button to confirm you have processed this udpate.</li>
              <li><b>Delete request:</b><br/>Customers can request to delete appliances. In order to delete, click on the "delete" button. This will take appliances off your appliance list and the customer's dashboard. <br/>In case a customer deleted an appliance by accident you can cancel the request by clicking on the "Cancel" button.</li>
              <li><b>Confirmed appliances:</b><br/>A green label will show when your customer confirms their appliances.</li>
            </ul>
            `,
    type: 'Customer updates',
  },
  {
    text: `<p><br/>
            Use in case you want a customers to schedule <br/>
            only one Design review for multiple rooms. <br/>
            This means you'll need to <br/>
            <b>activate this in one of the rooms</b> <br/>
            or create a <b>Design Review consultation card</b> <br/>
            and release it.
            </p>
            `,
    type: 'For reviewing multiple rooms in one call',
  },
  {
    text: `<p><b>To create Initial Design</b></p>
            <ul>
              <li><b>Name</b>:Name the design accordingly (automatically sugested name)</li>
              <li><b>Price:</b>Add price according to initial design</li>
              <li>Confirm or change features</li>
              <li> Upload Plan</li>
              <li> Save </li>
              <p><b>To send design to render</b></p>
              <li> Click Edit button for design</li>
              <li> Upload metric plan</li>
              <li> Once uploaded, check "Send to rendering"</li>
              <li> Save </li>
              <p><b>To complete Initial Design</b></p>
              <li> Click Edit button for design</li>
              <li> Upload renders</li>
              <li> Save</li>
            </ul>
            `,
    type: 'Create Initial Design',
  },
  {
    text: `<p><b>To prepare for rendering</b></p>
            <ul>
              <li><b>Upload finishes</b>: Make sure countertops, flooring, and backsplashes are up-to-date</li>
              <li><b>Rendering props</b>: The available options are a subset of available props. Reach out to the team if you need something that isn't here!</li>
              <li><b>Passing along notes</b>: Notes about the render and prop locations can be added via text or an image overlay</li>
              <li><b>Camera angles</b>: Preferred camera angles can be uploaded via the visual notes</li>
              <li>When done, check "Design is ready for render review" and save!</li>
              <li>The team will then review the submission to make sure it is all ready to go and will then submit to Archi CGI.</li>
            </ul>
            `,
    type: 'Prepare for rendering',
  },
  {
    text: `<p><b>Discontinued products will no longer be displayed on</b></p>
            <ul>
              <li>Kickoff guide</li>
              <li>Upgrade pack</li>
              <li>Webstie catalog</li>
            </ul>
            <p><b>Will show as discontinued on</b></p>
            <ul>
              <li>Design Brief</li>
              <li>Design</li>
            </ul>
            `,
    type: 'Product Discontinued',
  },
]

export type PopupPosition =
  | 'top center'
  | 'top left'
  | 'top right'
  | 'bottom center'
  | 'bottom left'
  | 'bottom right'
  | 'right center'
  | 'left center'

const Tooltip = ({
  type,
  iconPosition,
  iconSize,
  popupPosition,
}: {
  type: string
  iconPosition?: string
  iconSize?: IconSizeProp
  popupPosition?: PopupPosition
}) => {
  const template = instructionTemplates.find((e) => e.type === type)
  iconPosition = iconPosition ? iconPosition : 'middle'
  iconSize = iconSize ? iconSize : 'small'
  popupPosition = popupPosition ? popupPosition : 'right center'
  return (
    <Popup
      header={template?.type}
      content={
        <div
          dangerouslySetInnerHTML={{
            __html: template?.text ?? '',
          }}
        />
      }
      on="hover"
      position={popupPosition}
      pinned
      wide="very"
      trigger={
        <Icon
          name="help circle"
          size={iconSize}
          style={{
            color: Colors.blue800,
            cursor: 'pointer',
            verticalAlign: iconPosition,
          }}
        />
      }
    />
  )
}

export default Tooltip
