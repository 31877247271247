import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import Deliveries from 'components/admin/delivery/index-many'
import AdminLayout from 'components/admin/layout'
import SectionTitle from 'components/admin/section-title'
import DeliveryManyProvider from 'context/delivery/provider-many'

const Module = (_: RouteComponentProps) => {
  return (
    <AdminLayout>
      <Grid>
        <SectionTitle title={`Deliveries`} />
        <Grid.Row>
          <Grid.Column>
            <DeliveryManyProvider
              defaultFilters={{
                sortBy: 'date',
                sortDirection: 'ascending',
                take: 30,
              }}
              skipLoader
            >
              <Deliveries />
            </DeliveryManyProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminLayout>
  )
}

export default Module
