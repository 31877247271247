import React from 'react'

import moment from 'moment'

import StatusLabel from 'design-system/components/status-label/status-label'
import { Order } from 'types/order'
import { StatusMode } from 'types/utils'

const CellContentsPlacement = ({ order }: { order: Order }) => {
  let orderStatus = {
    type: StatusMode.OpenForEdit,
    text: 'No order activity',
    description: '',
  }
  if (order?.placedAt)
    orderStatus = {
      type: StatusMode.Confirmed,
      text: 'Placed (paid)',
      description: moment(order.placedAt).format('LL'),
    }
  else if (order)
    orderStatus = {
      type: StatusMode.Confirmation,
      text: 'Awaiting payment',
      description: '',
    }
  return (
    <>
      <StatusLabel
        type={orderStatus.type}
        text={orderStatus.text}
        description={orderStatus.description}
      />
    </>
  )
}

export default CellContentsPlacement
