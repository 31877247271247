import { gql } from '@apollo/client'

import { ProjectProspect } from 'types/project-prospect'

export const CREATE_ONE_PROJECT_PROSPECT = gql`
  mutation createOneProjectsProspects($data: ProjectsProspectsCreateInput!) {
    createOneProjectsProspects(data: $data) {
      projectId
      prospectId
    }
  }
`

export interface CreateOneProjectProspectPayload {
  createOneProjectProspects: ProjectProspect
}

export interface CreateOneProjectProspectVariables {
  data: {
    isPrimary?: boolean
    project: {
      connect: {
        id: string
      }
    }
    prospect: {
      connect: {
        id: string
      }
    }
  }
}

export const DELETE_ONE_PROJECT_PROSPECT = gql`
  mutation deleteOneProjectProspects(
    $where: ProjectsProspectsWhereUniqueInput!
  ) {
    deleteOneProjectsProspects(where: $where) {
      projectId
      prospectId
    }
  }
`

export interface DeleteOneProjectProspectPayload {
  deleteOneProjectsProspects: {
    projectId: string
    prospectId: string
  }
}

export interface DeleteOneProjectProspectVariables {
  where: {
    projectId_prospectId: {
      projectId: string
      prospectId: string
    }
  }
}

export const UPDATE_ONE_PROJECT_PROSPECT = gql`
  mutation updateOneProjectsProspects(
    $data: ProjectsProspectsUpdateInput!
    $where: ProjectsProspectsWhereUniqueInput!
  ) {
    updateOneProjectsProspects(data: $data, where: $where) {
      projectId
      prospectId
    }
  }
`

export interface UpdateOneProjectProspectPayload {
  updateOneProjectsProspects: ProjectProspect
}

export interface UpdateOneProjectProspectVariables {
  data: {
    isPrimary?: boolean
    prospect: {
      connect: {
        id: string
      }
    }
  }
  where: {
    projectId_prospectId: {
      projectId: string
      prospectId: string
    }
  }
}
