import { gql } from '@apollo/client'

import {
  SupplierOrderAction,
  SupplierOrderActionData,
} from 'types/supplier-order-action'

export const CREATE_SUPPLIER_ORDER_ACTION = gql`
  mutation createSupplierOrderAction($data: SupplierOrderActionCreateInput!) {
    createOneSupplierOrderAction(data: $data) {
      id
    }
  }
`

export interface CreateSupplierOrderActionPayload {
  createOneSupplierOrderAction: { id: string }
}

export interface CreateSupplierOrderActionVariables {
  data: {
    ackFile?: {
      connect: { id: string }
    }
    data: SupplierOrderActionData
    supplierOrder: {
      connect: { id: string }
    }
  }
}
export const DELETE_SUPPLIER_ORDER_ACTION = gql`
  mutation deleteSupplierOrderAction(
    $where: SupplierOrderActionWhereUniqueInput!
  ) {
    deleteOneSupplierOrderAction(where: $where) {
      id
    }
  }
`

export interface DeleteSupplierOrderActionPayload {
  deleteOneSupplierOrderAction: SupplierOrderAction
}

export interface DeleteSupplierOrderActionVariables {
  where: { id: string }
}

export const FETCH_SUPPLIER_ORDER_ACTIONS = gql`
  query supplierOrderActions($where: SupplierOrderActionWhereInput!) {
    supplierOrderActions: findManySupplierOrderAction(where: $where) {
      id
      ackFile {
        id
        key
        name
        numPages
        type
      }
      data
    }
  }
`

export interface FetchSupplierOrderActionsPayload {
  supplierOrderActions: SupplierOrderAction[]
}

export interface FetchSupplierOrderActionsVariables {
  where: {
    supplierOrderId: {
      equals: string
    }
  }
}

export const UPDATE_SUPPLIER_ORDER_ACTION = gql`
  mutation updateSupplierOrderAction(
    $data: SupplierOrderActionUpdateInput!
    $where: SupplierOrderActionWhereUniqueInput!
  ) {
    updateOneSupplierOrderAction(data: $data, where: $where) {
      id
      ackFile {
        id
        key
        name
        numPages
        type
      }
      data
    }
  }
`

export interface UpdateSupplierOrderActionPayload {
  updateOneSupplierOrderAction: { id: string }
}

export interface UpdateSupplierOrderActionVariables {
  where: { id: string }
  data: {
    ackFile?: {
      connect?: { id: string }
      disconnect?: boolean
    }
    data?: SupplierOrderActionData
    supplierOrder?: {
      connect: { id: string }
    }
  }
}
