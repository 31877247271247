import React, { useEffect } from 'react'

import { Grid } from 'semantic-ui-react'

import IssuesTable from 'components/admin/project/issue/table'
import { parseIssuesByResolution } from 'context/issue/utils-many'
import ProjectIssueManyProvider from 'context/project/issue/provider-many'
import useProjectIssueMany from 'context/project/issue/use-many'
import StyledAccordion from 'design-system/components/accordion'
import { Design } from 'types/design'
import { Issue } from 'types/issue'

interface IssueProps {
  readOnly?: boolean
  setState: (d: Partial<Design>) => void
  state: Partial<Design>
}

const Issues = ({ readOnly, setState, state }: IssueProps) => {
  const { issues, refetch } = useProjectIssueMany()
  const { addOnIssues } = parseIssuesByResolution(
    issues.filter(
      (i) => !i.designResolving || i.designResolving?.id === state?.id,
    ),
  )

  useEffect(() => {
    return () => {
      refetch()
    }
  }, [])

  return (
    <StyledAccordion titleText="Issues Resolved by this Add-On">
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <p className="subtitle">Select the issues solved by this design</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {readOnly ? (
              <IssuesTable
                issues={addOnIssues.filter(
                  (i) => state.issuesResolved?.map((r) => r.id).includes(i.id),
                )}
                mode="readOnly"
              />
            ) : (
              <IssuesTable
                issues={addOnIssues}
                mode="enableSelect"
                selected={state.issuesResolved}
                onSelectToggle={(i: Issue) =>
                  setState({
                    ...state,
                    issuesResolved: state.issuesResolved?.find(
                      (p) => p.id === i.id,
                    )
                      ? state.issuesResolved.filter((p) => p.id !== i.id)
                      : [...(state.issuesResolved as Issue[]), i],
                  })
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledAccordion>
  )
}

export default (props: IssueProps) => {
  return (
    <ProjectIssueManyProvider>
      <Issues {...props} />
    </ProjectIssueManyProvider>
  )
}
