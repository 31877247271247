import React, { useState } from 'react'

import moment from 'moment'
import { Label, Table } from 'semantic-ui-react'

import DeliveryModal from 'components/admin/delivery/create-update-modal'
import OrderCellContentPlacement from 'components/admin/order-form/table-cell-content-placement'
import RouteModal from 'components/admin/route/create-update-modal'
import ShipmentModal from 'components/admin/shipment/create-update-modal'
import SupplierOrderCellContentFulfillmentStatus from 'components/admin/supplier-order/table-cell-content-fulfillment-status'
import AddressFormat from 'components/shared/address-format'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { isAddOnDesign } from 'context/design/utils'
import useSupplierOrderMany from 'context/supplier-order/use-many'
import useSupplierOrderMutateNoContext from 'context/supplier-order/use-mutate-no-context'
import * as supplierOrderUtils from 'context/supplier-order/utils'
import Button from 'design-system/components/button'
import { InfoPopup } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { Delivery } from 'types/delivery'
import { Route } from 'types/route'
import { Shipment } from 'types/shipment'
import { SupplierOrder } from 'types/supplier-order'

import SupplierOrderModal from './create-update-modal'
import { SupplierOrderCellContentsLess } from './table-cell-content'
import { ProjectStaffCellContents } from '../project/project-staff/table-cell-content'

const SupplierOrdersTable = () => {
  const { createSupplierOrder } = useSupplierOrderMutateNoContext()
  const { supplierOrders, refetch, queryFilters, setQueryFilters } =
    useSupplierOrderMany()
  const { sortBy, sortDirection } = queryFilters

  const [deliveryToEdit, setDeliveryToEdit] = useState<Delivery>()
  const [routeToEdit, setRouteToEdit] = useState<Route>()
  const [shipmentToEdit, setShipmentToEdit] = useState<Shipment>()
  const [supplierOrderToEdit, setSupplierOrderToEdit] =
    useState<SupplierOrder>()
  const [supplierOrderToSplit, setSupplierOrderToSplit] =
    useState<SupplierOrder>()

  return (
    <Table celled sortable className="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nobilia Order Reference</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Shipping Address</Table.HeaderCell>
          <Table.HeaderCell>ACK</Table.HeaderCell>
          <Table.HeaderCell>Order Status</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortBy === 'derivedStatus' ? sortDirection : undefined}
            onClick={() =>
              setQueryFilters({
                ...queryFilters,
                sortBy: 'derivedStatus',
                sortDirection:
                  sortBy === 'derivedStatus' && sortDirection === 'descending'
                    ? 'ascending'
                    : 'descending',
              })
            }
          >
            Fulfillment Status
          </Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {supplierOrders.map((so: SupplierOrder) => {
          const guaranteed = supplierOrderUtils.getGuaranteedOnOrBefore(so)
          const isRemedial = supplierOrderUtils.isRemedialSupplierOrder(so)
          const isAddOn = isAddOnDesign(so.design)

          return (
            <Table.Row key={so.id}>
              <Table.Cell width={3}>
                {isRemedial ? (
                  <Label color="red" content="Remedial" ribbon />
                ) : null}
                {isAddOn ? (
                  <Label color="yellow" content="Add-On" ribbon />
                ) : null}
                <SupplierOrderCellContentsLess supplierOrder={so} />
              </Table.Cell>
              <Table.Cell width={2}>
                {so.project ? (
                  <ProjectStaffCellContents project={so.project} />
                ) : (
                  'Third Party Order'
                )}
              </Table.Cell>
              <Table.Cell width={2}>
                <AddressFormat
                  address={supplierOrderUtils.getSupplierOrderAddress(so)}
                />
              </Table.Cell>
              <Table.Cell textAlign="center" width={2}>
                <p style={{ color: Colors.orange700 }}>
                  {so.nobiliaAckNumber ||
                    so.data?.nobiliaFactoryAckNumber ||
                    ''}
                </p>
              </Table.Cell>
              <Table.Cell width={2}>
                <OrderCellContentPlacement order={so.design?.order} />
                <p className="x-small light-gray">
                  {guaranteed
                    ? `Guaranteed ${moment(guaranteed).format('LL')}`
                    : null}
                </p>
              </Table.Cell>
              <Table.Cell width={2}>
                <SupplierOrderCellContentFulfillmentStatus
                  supplierOrder={so}
                  onDeliveryClick={() => setDeliveryToEdit(so.delivery)}
                  onRouteClick={() => setRouteToEdit(so.route)}
                  onShipmentClick={() => setShipmentToEdit(so.shipment)}
                />
              </Table.Cell>
              <Table.Cell width={1}>
                <div
                  style={{
                    display: 'grid',
                    justifyContent: 'center',
                    rowGap: '8px',
                  }}
                >
                  <InfoPopup
                    content={<p>Edit Supplier Order</p>}
                    trigger={
                      <Button
                        color="dark"
                        kind="solid"
                        fontAwesomeIcon="edit"
                        onClick={() => setSupplierOrderToEdit(so)}
                      />
                    }
                  />
                  {so.project ? (
                    <>
                      <InfoPopup
                        content={<p>Split Supplier Order</p>}
                        trigger={
                          <Button
                            color="blue"
                            kind="solid"
                            fontAwesomeIcon="divide"
                            onClick={() => setSupplierOrderToSplit(so)}
                          />
                        }
                      />
                      <InfoPopup
                        content={<p>Open in project</p>}
                        trigger={
                          <Button
                            color="blue"
                            kind="solid"
                            fontAwesomeIcon="arrow-up-right-from-square"
                            onClick={() =>
                              window.open(
                                `/admin/projects/${so.project.id}/supplier-orders/${so.id}`,
                              )
                            }
                          />
                        }
                      />
                    </>
                  ) : null}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
        <DeliveryModal
          deliveryId={deliveryToEdit?.id ?? ''}
          open={!!deliveryToEdit}
          refetch={refetch}
          onClose={() => setDeliveryToEdit(undefined)}
        />
        <RouteModal
          open={!!routeToEdit?.id}
          onClose={() => setRouteToEdit(undefined)}
          refetch={refetch}
          routeId={routeToEdit?.id ?? ''}
        />
        <ShipmentModal
          open={!!shipmentToEdit}
          onClose={() => setShipmentToEdit(undefined)}
          refetch={refetch}
          shipmentId={shipmentToEdit?.id ?? ''}
        />
        <SupplierOrderModal
          open={!!supplierOrderToEdit}
          onClose={() => setSupplierOrderToEdit(undefined)}
          refetch={refetch}
          supplierOrderId={supplierOrderToEdit?.id ?? ''}
        />
        <ConfirmationModal
          secondTitle="Are you sure you want to split this supplier order?"
          firstTitle="Split Supplier Order"
          onCancel={() => setSupplierOrderToSplit(undefined)}
          onConfirm={async () => {
            const so = supplierOrderToSplit
            await createSupplierOrder({
              variables: {
                data: {
                  actions: {
                    create:
                      so?.actions.map((soa) => ({
                        data: {
                          ...soa.data,
                        },
                      })) ?? [],
                  },
                  ...(so?.design
                    ? {
                        design: {
                          connect: { id: so.design.id },
                        },
                      }
                    : null),
                  data: so?.data,
                  nobiliaAckNumber: so?.nobiliaAckNumber,
                  nobiliaOrderRef: !isNaN(
                    parseFloat(String(so?.nobiliaOrderRef.slice(-1))),
                  )
                    ? `${so?.nobiliaOrderRef.slice(0, -1)}${
                        parseFloat(String(so?.nobiliaOrderRef.slice(-1))) + 1
                      }`
                    : `${so?.nobiliaOrderRef} 2`,
                  project: {
                    connect: { id: so?.project.id ?? '' },
                  },
                },
              },
            })
            refetch()
            setSupplierOrderToSplit(undefined)
          }}
          open={!!supplierOrderToSplit}
        />
      </Table.Body>
    </Table>
  )
}

export default SupplierOrdersTable
