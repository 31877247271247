import { useContext } from 'react'

import { SupplierOrderManyContext } from './provider-many'

const useSupplierOrderMany = () => {
  const {
    supplierOrders,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    refetch,
    queryFilters,
    setQueryFilters,
  } = useContext(SupplierOrderManyContext)

  return {
    supplierOrders,
    count,
    loading,
    loadingMore,
    fetchMore,
    fetchMoreOnDemand,
    refetch,
    queryFilters,
    setQueryFilters,
  }
}

export default useSupplierOrderMany
