import React from 'react'

import styled from 'styled-components'

import { ChipToggle } from 'components/shared/chip'
import { MessageSourceTag } from 'components/shared/message/source-tag'
import Search from 'components/shared/search'
import { notifySuccess } from 'context/notifications/trigger'
import { useProjectMessageMany } from 'context/project/message/provider-many'
import { useConversationMessages } from 'context/project/message/use-conversation-messages'
import useProjectMessageManyMutate from 'context/project/message/use-many-mutate'
import Button from 'design-system/components/button'
import Tabs from 'design-system/components/tabs'
import { Colors } from 'styles/app/system'
import { MessageSource } from 'types/message'

import {
  MessageActiveTabOptions,
  MessageTab,
  useAdminMessageLayout,
} from './context'

const LayoutTabs = () => {
  const {
    activeTab,
    setActiveTab,
    pinnedFilter,
    setPinnedFilter,
    conversationMessagesFilter,
    setConversationMessagesFilter,
  } = useAdminMessageLayout()
  const { pinnedCount, newForMeCount, queryFilters, setQueryFilters } =
    useProjectMessageMany()
  const { acknowledgeAll, loadingManyUpdate, loadingCreate } =
    useProjectMessageManyMutate()

  const { usersPerConversation } = useConversationMessages()

  const handleAckAll = async () => {
    await acknowledgeAll()
    notifySuccess('Messages acknowledged!')
  }

  const fixedTabs: MessageTab[] = [
    {
      label: <>All {newForMeCount ? <Badge>{newForMeCount}</Badge> : null}</>,
      value: MessageActiveTabOptions.ALL,
    },
    {
      label: `Pinned ${pinnedCount ? `(${pinnedCount})` : ''}`,
      value: MessageActiveTabOptions.PINNED,
    },
  ]

  const conversationTabs = usersPerConversation.map<MessageTab>(
    ({ userName, conversationId }) => ({
      label: (
        <>
          {userName}
          <MessageSourceTag>SMS</MessageSourceTag>
        </>
      ),
      value: conversationId,
      isConversation: true,
    }),
  )

  const tabs = [...fixedTabs, ...conversationTabs]

  const handleTabChange = (_: number, tab: MessageTab) => {
    setActiveTab(tab)
    setQueryFilters({
      ...queryFilters,
      pinned: tab.value === 'pinned' || undefined,
    })
  }

  const handleSearchChange = (search: string) => {
    setQueryFilters({
      ...queryFilters,
      search: search || undefined,
    })
  }

  return (
    <TabsWrapper>
      <Tabs
        tabs={tabs}
        currentTab={tabs.findIndex((t) => t.value === activeTab.value)}
        variant="plain"
        handleClick={handleTabChange}
      />
      <Row style={{ paddingBottom: 8, gap: 16 }}>
        {activeTab.value === MessageActiveTabOptions.ALL && (
          <Button
            kind="solid"
            text="Acknowledge All"
            fontAwesomeIcon="square-check"
            loading={loadingManyUpdate || loadingCreate}
            disabled={!newForMeCount}
            onClick={handleAckAll}
            size="small"
          />
        )}
        {activeTab.value === MessageActiveTabOptions.PINNED && (
          <Row>
            <p className="no-margin light-gray">Showing:</p>

            <Row>
              <ChipToggle
                active={pinnedFilter === 'all'}
                onClick={() => setPinnedFilter('all')}
                noMargin
                name="All"
              />
              <ChipToggle
                active={pinnedFilter === 'client'}
                onClick={() => setPinnedFilter('client')}
                noMargin
                name="Client"
              />
              <ChipToggle
                active={pinnedFilter === 'me'}
                onClick={() => setPinnedFilter('me')}
                noMargin
                name="Sent by me"
              />
            </Row>
          </Row>
        )}
        {activeTab.isConversation && (
          <Row>
            <p className="no-margin light-gray">Showing:</p>

            <Row>
              <ChipToggle
                active={conversationMessagesFilter === 'all'}
                onClick={() => setConversationMessagesFilter('all')}
                noMargin
                name="All"
              />
              <ChipToggle
                active={conversationMessagesFilter === MessageSource.SMS}
                onClick={() => setConversationMessagesFilter(MessageSource.SMS)}
                noMargin
                name="SMS"
              />
              <ChipToggle
                active={
                  conversationMessagesFilter === MessageSource.CALL_TRANSCRIPT
                }
                onClick={() =>
                  setConversationMessagesFilter(MessageSource.CALL_TRANSCRIPT)
                }
                noMargin
                name="Transcripts"
              />
            </Row>
          </Row>
        )}

        <Search onChange={handleSearchChange} fluid={false} />
      </Row>
    </TabsWrapper>
  )
}

export default LayoutTabs

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Badge = styled.span`
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.orange500};
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const TabsWrapper = styled.div`
  padding: 0 80px;
  display: grid;
  grid-template-columns: calc(100% - 600px) auto;
  align-items: center;
  justify-content: space-between;
`
