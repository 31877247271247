import React from 'react'

import { RouteComponentProps } from '@reach/router'

import AdminLayout from 'components/admin/layout'

const DashboardIndex = (
  props: React.PropsWithChildren<RouteComponentProps>,
) => {
  return <AdminLayout>{props.children}</AdminLayout>
}

export default DashboardIndex
