import React from 'react'

import { Grid } from 'semantic-ui-react'

import FileCard from 'components/shared/project/project-file/card'
import { UploadProjectFileCard } from 'components/shared/project/project-file/upload-card'
import useDesign from 'context/design/use'
import useDesignProjectFileMutate from 'context/design/use-project-file-mutate'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import StyledAccordion from 'design-system/components/accordion'
import { ProjectFile, ProjectFileType } from 'types/project-file'

const WinnerFile = () => {
  const { project } = useProject()
  const { room } = useRoom()
  const { design, refetch } = useDesign()
  const { deleteProjectFileAndRefetch } = useDesignProjectFileMutate()

  const onFilesUploaded = () => {
    refetch()
  }

  return (
    <StyledAccordion titleText="Winner File">
      <Grid>
        <Grid.Row columns={4}>
          <Grid.Column>
            <UploadProjectFileCard
              fileType={ProjectFileType.WINNER_FILE}
              onFilesUploaded={onFilesUploaded}
              projectId={project.id}
              roomId={room?.id}
              designWinnerFileId={design?.id}
            />
          </Grid.Column>
          {design?.winnerFiles?.map((f) => (
            <Grid.Column key={f.id} style={{ marginBottom: '25px' }}>
              <FileCard
                file={f}
                onFileRemoved={async (f: Partial<ProjectFile>) => {
                  deleteProjectFileAndRefetch({
                    variables: {
                      where: {
                        id: f.id ?? '',
                      },
                    },
                  })
                }}
                navigateTo={`/admin/files/${f.id}/`}
                showActions
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </StyledAccordion>
  )
}

export default WinnerFile
