import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  IFormCheckboxValue,
} from 'components/admin/filters-search'
import useGenericProductClassMany from 'context/generic-product-class/use-many'

export type GenericProductClassFilterValues = {
  productTypes: IFormCheckboxValue
}

const useGenericProductClassFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useGenericProductClassMany()

  const filters: DynamicFilterGroup<GenericProductClassFilterValues>[] =
    useMemo(() => {
      return []
    }, [])

  const defaultFilters = useMemo(() => {
    const genericProductClassFilters: Partial<GenericProductClassFilterValues> =
      {}
    return genericProductClassFilters
  }, [queryFilters])

  const handleApplyFilters = (_: GenericProductClassFilterValues) => {
    setQueryFilters({
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useGenericProductClassFiltersSearch
