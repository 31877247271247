import { useMemo } from 'react'

import { useCommentNoteMany } from 'context/project/project-notes/provider-many'
import { QueryFilters } from 'context/project/project-notes/utils-many'
import { NoteTopic } from 'types/comment-note'
import { User } from 'types/user'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import { IFormCheckboxValue } from '../filters-search'
import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IDynamicFields,
  IFormDateValue,
  IFormDropdownValue,
} from '../filters-search/types'

interface INoteFilterForm extends IDynamicFields {
  topics: IFormCheckboxValue
  subscription: IFormCheckboxValue
  forMe: IFormCheckboxValue
  createdAt: IFormDateValue
  publishedBy: IFormDropdownValue
}

const NOTE_FILTERS: DynamicFilterGroup<INoteFilterForm>[] = [
  {
    groupName: 'Show',
    filters: [
      {
        filterId: 'subscription',
        type: DynamicFilterTypes.CHECKBOX,
        options: [
          {
            text: 'New for me',
            value: 'new',
          },
          {
            text: 'Mentions',
            value: 'mentioned',
          },
        ],
        disableIf({ forMe }) {
          return !!forMe?.selection?.[0]?.value
        },
      },
      {
        filterId: 'forMe',
        type: DynamicFilterTypes.CHECKBOX,
        options: [
          {
            text: 'For me',
            value: 'forMe',
          },
        ],
      },
      {
        filterId: 'createdAt',
        type: DynamicFilterTypes.DATE,
        label: 'Created between',
        max: new Date(),
      },
    ],
  },
  {
    groupName: 'Topic',
    withDrawer: true,
    filters: [
      {
        filterId: 'topics',
        type: DynamicFilterTypes.CHECKBOX,
        options: Object.values(NoteTopic).map((topic) => ({
          text: capsSnakeCaseToTitleCase(topic),
          value: topic,
        })),
      },
    ],
  },
]

export const useNoteFilters = (formUserId: string, staff?: User[]) => {
  const { queryFilters, setQueryFilters } = useCommentNoteMany()

  const filters = useMemo(() => {
    if (staff?.length) {
      // ! the shallow clone [...NOTE_FILTERS] does not work in a multidimensional array
      const filtersWithStaff = NOTE_FILTERS.map((nf) => ({
        ...nf,
        filters: [...nf.filters],
      }))
      filtersWithStaff[0].filters.push({
        filterId: 'publishedBy',
        type: DynamicFilterTypes.DROPDOWN,
        label: 'Published by',
        options: staff.map((s) => ({
          value: s.id,
          key: s.id,
          text: `${s.firstName} ${s.lastName}`,
          image: {
            src: s.picture,
            avatar: true,
          },
        })),
      })
      return filtersWithStaff
    }
    return NOTE_FILTERS
  }, [staff])

  const defaultFilters = useMemo<Partial<INoteFilterForm>>(() => {
    const filters: Partial<INoteFilterForm> = {}

    if (queryFilters.forMe?.id) {
      filters.forMe = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: [
          {
            value: 'forMe',
            text: 'For me',
          },
        ],
      }
    } else if (queryFilters.newFor?.id || queryFilters.mentioned?.id) {
      const selection = []
      if (queryFilters.newFor?.id) {
        selection.push({
          value: 'new',
          text: 'New for me',
        })
      }
      if (queryFilters.mentioned?.id) {
        selection.push({
          text: 'Mentions',
          value: 'mentioned',
        })
      }
      filters.subscription = {
        type: DynamicFilterTypes.CHECKBOX,
        selection,
      }
    }

    if (queryFilters.publishedBy && staff) {
      const staffInfo = staff.find((s) => s.id === queryFilters.publishedBy)
      if (staffInfo) {
        filters.publishedBy = {
          type: DynamicFilterTypes.DROPDOWN,
          selection: {
            text: `${staffInfo.firstName} ${staffInfo.lastName}`,
            value: queryFilters.publishedBy,
          },
        }
      }
    }

    if (queryFilters?.topics?.length) {
      filters.topics = {
        type: DynamicFilterTypes.CHECKBOX,
        selection: queryFilters.topics.map((topic) => ({
          value: topic,
          text: topic,
        })),
      }
    }
    return filters
  }, [])

  const handleApplyFilters = (data: Partial<INoteFilterForm>) => {
    setQueryFilters((currQueryFilters) => {
      const newQueryFilters: QueryFilters = {
        take: queryFilters.take,
        projectId: currQueryFilters.projectId,
        search: currQueryFilters.search,
      }

      if (data.subscription?.selection?.find(({ value }) => value === 'new')) {
        newQueryFilters.newFor = { id: formUserId }
      }

      if (
        data.subscription?.selection?.find(({ value }) => value === 'mentioned')
      ) {
        newQueryFilters.mentioned = { id: formUserId }
      }

      if (data.forMe?.selection?.[0]?.value) {
        newQueryFilters.forMe = { id: formUserId }
      }

      if (data.topics?.selection?.length) {
        newQueryFilters.topics = data.topics.selection.map<NoteTopic>(
          ({ value }) => value as NoteTopic,
        )
      }
      if (data.createdAt?.value) {
        const [startTimeStr, endTimeStr] = data.createdAt.value.split(' - ')

        const startTime = new Date(startTimeStr)
        const endTime = new Date(endTimeStr)
        startTime.setHours(0, 0, 0, 0)
        endTime.setHours(23, 59, 59, 999)

        newQueryFilters.createdAt = {
          gte: startTime.toISOString(),
          lte: endTime.toISOString(),
        }
      }
      if (data.publishedBy?.selection?.value) {
        newQueryFilters.publishedBy = data.publishedBy?.selection?.value
      }

      return newQueryFilters
    })
  }

  const handleApplySearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search.trim(),
    }))
  }

  return {
    filters,
    handleApplyFilters,
    defaultFilters,
    handleApplySearch,
    searchValue: queryFilters.search,
  }
}
