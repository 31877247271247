import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Form, Grid } from 'semantic-ui-react'

import { RoomPageTitle } from 'components/admin/page-title'
import RoomLayout from 'components/admin/project/room/layout'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import { notifySuccess } from 'context/notifications/trigger'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutateNoContext from 'context/room/use-mutate-no-context'
import { ProjectFileType } from 'types/project-file'

const ProductionFiles = (
  _: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  const { project, refetch } = useProject()
  const {
    room,
    applianceSpecs,
    supplierOrderFiles,
    refetch: refetchRoom,
  } = useRoom()
  const { updateRoom, loadingUpdate } = useRoomMutateNoContext()

  return (
    <RoomLayout>
      <RoomPageTitle room={room} viewName="Packs" />
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <h5>Appliance Specs</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Checkbox
              checked={!!room?.data?.appliancesSpecsNotIncluded}
              disabled={loadingUpdate}
              label="This room has no appliances"
              onChange={async () =>
                await updateRoom({
                  variables: {
                    data: {
                      data: {
                        appliancesSpecsNotIncluded:
                          !room?.data?.appliancesSpecsNotIncluded,
                      },
                    },
                    where: {
                      id: room?.id ?? '',
                    },
                  },
                  onCompleted: () => {
                    notifySuccess('Room Updated')
                    refetch()
                  },
                })
              }
            />
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          projectFiles={applianceSpecs}
          projectFileType={ProjectFileType.APPLIANCE_SPEC}
          projectId={project.id}
          roomId={room?.id}
          refetch={refetchRoom}
        />
        <Grid.Row>
          <Grid.Column>
            <h5>Supplier Order Files</h5>
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          projectFiles={supplierOrderFiles}
          projectFileType={ProjectFileType.SUPPLIER_ORDER_FILE}
          projectId={project.id}
          roomId={room?.id}
          refetch={refetchRoom}
        />
      </Grid>
    </RoomLayout>
  )
}

export default ProductionFiles
