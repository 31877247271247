import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  DynamicFilterTypes,
  IFormRadioValue,
} from 'components/admin/filters-search'
import { useNobiliaSampleClassMany } from 'context/nobilia-sample-class/provider-many'
import { camelCaseToTitleCase } from 'views/utils'

export type NobiliaSampleClassesFilterValues = {
  stock: IFormRadioValue
}

enum StockValues {
  ALL = 'all',
  IN_STOCK = 'inStock',
  OUT_OF_STOCK = 'outOfStock',
  DEPRECATED = 'deprecated',
}

const FILTERS: DynamicFilterGroup<NobiliaSampleClassesFilterValues>[] = [
  {
    filters: [
      {
        filterId: 'stock',
        type: DynamicFilterTypes.RADIO,
        label: 'Stock',
        options: [
          {
            text: 'All',
            value: StockValues.ALL,
          },
          {
            text: 'In Stock',
            value: StockValues.IN_STOCK,
          },
          {
            text: 'Out of Stock',
            value: StockValues.OUT_OF_STOCK,
          },
          {
            text: 'Deprecated',
            value: StockValues.DEPRECATED,
          },
        ],
      },
    ],
  },
]

const useNobiliaSampleClassesFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useNobiliaSampleClassMany()

  const handleApplyFilters = (filters: NobiliaSampleClassesFilterValues) => {
    setQueryFilters((currFilters) => ({
      ...currFilters,
      stock: filters.stock?.selection?.value as StockValues,
    }))
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search,
    }))
  }

  const defaultFilters = useMemo(() => {
    const nobiliaSampleClassFilters: Partial<NobiliaSampleClassesFilterValues> =
      {}

    if (queryFilters.stock) {
      nobiliaSampleClassFilters.stock = {
        selection: {
          text: camelCaseToTitleCase(queryFilters.stock),
          value: queryFilters.stock,
        },
        type: DynamicFilterTypes.RADIO,
      }
    }
    return nobiliaSampleClassFilters
  }, [])

  return { defaultFilters, filters: FILTERS, handleApplyFilters, handleSearch }
}

export default useNobiliaSampleClassesFiltersSearch
