import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Image, List, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import ConfirmationModal from 'components/shared/confirmation-modal'
import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useCompanyMany from 'context/company/use-many'
import useCompanyManyMutate from 'context/company/use-many-mutate'
import { ButtonTooltip } from 'design-system/components/button'
import { TableUserListItem } from 'styles/admin/main'
import { Colors } from 'styles/app/system'
import { Company } from 'types/company'
import { capsSnakeCaseToTitleCase, getNameForUser } from 'views/utils'

const CompaniesTable = () => {
  const [companyToDelete, setCompanyToDelete] = useState<Company>()
  const { deleteCompanyAndRefetch, loadingDelete } = useCompanyManyMutate()
  const { companies } = useCompanyMany()

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Industry</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Links</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {companies.map((company) => (
          <Table.Row key={company.id}>
            <Table.Cell width={2}>
              <p className="no-margin">{company.name}</p>
              {!!company.data?.size && (
                <p className="small gray">Size: {company.data.size}</p>
              )}
            </Table.Cell>
            <Table.Cell width={2}>
              <p>{company?.data?.industry}</p>
              {!!company?.data?.otherIndustry && (
                <p>{company?.data.otherIndustry}</p>
              )}
            </Table.Cell>
            <Table.Cell width={2}>
              <p>{getAddressStreetAndLocalityString(company.address)}</p>
            </Table.Cell>
            <Table.Cell width={3}>
              <StyledList relaxed>
                {!!company.data?.website && (
                  <List.Item>
                    <List.Icon name="world" />
                    <List.Content>
                      <a
                        target="_blank"
                        href={`https://${company.data?.website}`}
                      >
                        {company.data?.website}
                      </a>
                    </List.Content>
                  </List.Item>
                )}
                {!!company.data?.instagram && (
                  <List.Item>
                    <List.Icon className="brand" name="instagram" />
                    <List.Content>
                      <a
                        target="_blank"
                        href={`https://www.instagram.com/${company.data?.instagram}`}
                      >
                        {company.data?.instagram}
                      </a>
                    </List.Content>
                  </List.Item>
                )}
              </StyledList>
            </Table.Cell>
            <Table.Cell width={3}>
              <List relaxed>
                {company.users?.map((user) => (
                  <TableUserListItem key={user.id}>
                    <Image src={user.picture} avatar circular />
                    <List.Content>
                      <List.Header style={{ lineBreak: 'anywhere' }}>
                        <p className="no-margin small">
                          {getNameForUser(user)}
                        </p>
                        <p className="no-margin x-small light-gray">
                          {capsSnakeCaseToTitleCase(user.type)}
                        </p>
                      </List.Header>
                    </List.Content>
                  </TableUserListItem>
                ))}
              </List>
            </Table.Cell>
            <Table.Cell width={1}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  rowGap: '8px',
                }}
              >
                <ButtonTooltip
                  kind="solid"
                  color="dark"
                  fontAwesomeIcon="edit"
                  onClick={() => navigate(`/admin/companies/${company.id}`)}
                  tooltipProps={{
                    label: 'Edit company',
                    color: Colors.blue700,
                  }}
                />
                <ButtonTooltip
                  kind="solid"
                  color="gray"
                  fontAwesomeIcon="trash-alt"
                  onClick={() => setCompanyToDelete(company)}
                  tooltipProps={{
                    label: 'Delete company',
                    color: Colors.blue700,
                  }}
                />
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <ConfirmationModal
        firstTitle="Are you sure you want to delete this company?"
        onCancel={() => setCompanyToDelete(undefined)}
        onConfirm={async () => {
          await deleteCompanyAndRefetch({
            variables: {
              where: {
                id: companyToDelete?.id ?? '',
              },
            },
          })
          setCompanyToDelete(undefined)
        }}
        loading={loadingDelete}
        open={!!companyToDelete}
      />
    </Table>
  )
}

export default CompaniesTable

const StyledList = styled(List)`
  .icon.brand {
    font-family: 'brand-icons' !important;
  }
`
