import { useMutation } from '@apollo/client'

import {
  CREATE_DELIVERY,
  CreateDeliveryPayload,
  CreateDeliveryVariables,
  DELETE_DELIVERY,
  DeleteDeliveryPayload,
  DeleteDeliveryVariables,
  UPDATE_DELIVERY,
  UpdateDeliveryPayload,
  UpdateDeliveryVariables,
} from 'queries/delivery'

const useDeliveryMutate = () => {
  const [createDelivery, { loading: loadingCreate }] = useMutation<
    CreateDeliveryPayload,
    CreateDeliveryVariables
  >(CREATE_DELIVERY)

  const [deleteDelivery, { loading: loadingDelete }] = useMutation<
    DeleteDeliveryPayload,
    DeleteDeliveryVariables
  >(DELETE_DELIVERY)

  const [updateDelivery, { loading: loadingUpdate }] = useMutation<
    UpdateDeliveryPayload,
    UpdateDeliveryVariables
  >(UPDATE_DELIVERY)

  return {
    createDelivery,
    deleteDelivery,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updateDelivery,
  }
}

export default useDeliveryMutate
