import React, { useState } from 'react'

import { Editor } from '@tiptap/react'
import moment from 'moment'
import { Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { Attachments } from 'components/shared/message'
import RichTextEditor from 'components/shared/rich-text-editor'
import useAuth from 'context/auth/use'
import useMessageMutate from 'context/dashboard/use-message-mutate'
import { notifySuccess } from 'context/notifications/trigger'
import { parseOwners } from 'context/project/utils'
import Button from 'design-system/components/button'
import RichText from 'design-system/components/rich-text'
import Tooltip from 'design-system/components/tooltip'
import { Breakpoint, Colors } from 'styles/app/system'
import { Message, MessageSource } from 'types/message'
import { getNameForUser } from 'views/utils'

type InboxMessageProps = {
  message: Message
  refetch: () => Promise<unknown>
}

const InboxMessage = ({ message, refetch }: InboxMessageProps) => {
  const { formUserId } = useAuth()
  const { acknowledgeMessage, sendMessage, loadingCreate, loadingUpdate } =
    useMessageMutate(formUserId)
  const [showReplyForm, setShowReplyForm] = useState(false)
  const [richTextApi, setRichTextApi] = useState<Editor | null>(null)

  const onAckComplete = async () => {
    await refetch()
    notifySuccess('Message acknowledged!')
  }

  const onCreateCompleted = async () => {
    await acknowledgeMessage(message)
    notifySuccess('Message sent!')
    await refetch()
  }

  const primaryOwner =
    message.project?.data?.projectLabel ||
    getNameForUser(parseOwners(message.project).primaryOwner)

  const hanldeSendMessage = async () => {
    if (!message.from) return
    await sendMessage({
      body: richTextApi?.getJSON() ?? {},
      bodyHtml: richTextApi?.getHTML() ?? '',
      onCompleted: onCreateCompleted,
      project: message.project,
      from: message.from,
    })
  }

  return (
    <Container>
      <Row $fluid>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <Image className="avatar" src={message.from?.picture} />
          <p className="caption" style={{ marginTop: 3 }}>
            {message.from
              ? getNameForUser(message.from)
              : message.data?.sourceFrom}
          </p>
        </div>

        <p className="x-small gray no-margin">
          {moment(message.createdAt).format('MM/DD/YYYY HH:mm A')}
          {', '}
          {moment(message.createdAt).fromNow()}
        </p>
      </Row>
      <MessageContent>
        <RichText
          content={message.body || message.bodyHtml}
          deepLinks
          mentions
          maxHeight={
            message.data?.source === MessageSource.CALL_TRANSCRIPT
              ? '300px'
              : undefined
          }
        />

        {message.attachments?.length ? (
          <Attachments projectFiles={message.attachments} readOnly />
        ) : null}

        {showReplyForm ? (
          <Footer>
            <RichTextEditor
              actions="quick-reply"
              getRichTextApi={setRichTextApi}
              handleSendMessage={hanldeSendMessage}
              onCancel={() => setShowReplyForm(false)}
              loading={loadingCreate}
            />
          </Footer>
        ) : (
          <Footer>
            <Row $noPadding $fluid>
              <Button
                kind="text"
                text={`Open ${primaryOwner}'s project`}
                onClick={() =>
                  window.open(`/admin/projects/${message.project?.id}/overview`)
                }
                fontAwesomeIcon="external-link"
              />
              <QuickActions>
                <Tooltip label="Acknowledge" placement="bottom-start">
                  <Button
                    kind="ghost"
                    fontAwesomeIcon="square-check"
                    onClick={() => acknowledgeMessage(message, onAckComplete)}
                    loading={loadingUpdate}
                  />
                </Tooltip>
                <Tooltip label="Quick Reply" placement="bottom-start">
                  <Button
                    kind="ghost"
                    fontAwesomeIcon="reply"
                    onClick={() => setShowReplyForm(true)}
                  />
                </Tooltip>
              </QuickActions>
            </Row>
          </Footer>
        )}
      </MessageContent>
    </Container>
  )
}

export default InboxMessage

const Row = styled.div<{ $noPadding?: boolean; $fluid?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.$fluid &&
    css`
      width: 100%;
    `}

  @media ${Breakpoint.onlyMobile} {
    ${(props) =>
      !props.$noPadding &&
      css`
        margin-bottom: 10px;
      `}
  }
`

const QuickActions = styled.div`
  display: flex;
  gap: 5px;
`

const Container = styled.div`
  align-items: flex-start;
  background-color: ${Colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  position: relative;
`

const Footer = styled.footer`
  align-items: center;
  border-top: 1px solid ${Colors.gray100};
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
`

const MessageContent = styled.div`
  flex: 1;
  width: 100%;
`
