import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'

const DashboardRedirectUser = (
  props: RouteComponentProps<{ user_id: string }>,
) => {
  const {
    formUserId,
    isAuthenticated,
    isAccountExecutive,
    isCustomerSupport,
    isSelectionCenterManager,
    isTechDesigner,
    isTechDesignerSupport,
    isTradeDesigner,
    isNobiliaOrderProcessor,
  } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) return

    if (isAccountExecutive) {
      props.navigate?.(`/admin/dashboard/${formUserId}/account-executive`)
      return
    }

    if (isTechDesigner) {
      props.navigate?.(`/admin/dashboard/${formUserId}/tech-designer`)
      return
    }

    if (isTechDesignerSupport) {
      props.navigate?.(`/admin/dashboard/${formUserId}/tech-designer-support`)
      return
    }

    if (isCustomerSupport) {
      props.navigate?.(`/admin/dashboard/${formUserId}/customer-support`)
      return
    }

    if (isSelectionCenterManager) {
      props.navigate?.(
        `/admin/dashboard/${formUserId}/selection-center-manager`,
      )
      return
    }

    if (isTradeDesigner) {
      props.navigate?.(`/admin/dashboard/${formUserId}/trade-designer`)
      return
    }

    if (isNobiliaOrderProcessor) {
      props.navigate?.(`/admin/dashboard/${formUserId}/nobilia-order-processor`)
      return
    }
    props.navigate?.(`/admin/dashboard/${formUserId}/sales-designer`)
  }, [isAuthenticated])

  return <Loader />
}

export default DashboardRedirectUser
