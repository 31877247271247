import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { useProjectChecklist } from 'context/project/checklist/provider'
import useProjectChecklistItemMutate from 'context/project/checklist/use-mutate'
import { FormCheckbox } from 'styles/admin/main'
import { Colors } from 'styles/app/system'

type CheckListItemProps = {
  identifier: string
  indent?: boolean
  readonly?: boolean
  text: string
}

const CheckListItem = ({
  identifier,
  text,
  indent,
  readonly,
}: CheckListItemProps) => {
  const { checklistItems } = useProjectChecklist()
  const {
    createChecklistItem,
    deleteChecklistItem,
    loadingCreate,
    loadingDelete,
  } = useProjectChecklistItemMutate()

  const selector = (item: (typeof checklistItems)[number]) =>
    item.identifier === identifier

  const checked = checklistItems.some(selector)

  const handleClick = async () => {
    if (checked) {
      const item = checklistItems.find(selector)
      if (item) {
        return await deleteChecklistItem(item.id)
      }
    }

    return await createChecklistItem({ identifier, data: undefined })
  }

  return (
    <ChecklistItemContainer indent={indent} $readonly={readonly}>
      {readonly ? (
        <FontAwesomeIcon
          icon={['fas', 'circle']}
          color={Colors.gray300}
          fontSize="8px"
        />
      ) : (
        <FormCheckbox
          onClick={handleClick}
          checked={checked}
          disabled={loadingCreate || loadingDelete}
        />
      )}
      <p className="small no-margin gray">{text}</p>
    </ChecklistItemContainer>
  )
}

export default CheckListItem

const ChecklistItemContainer = styled.div<{
  indent?: boolean
  $readonly?: boolean
}>`
  display: flex;
  align-items: ${(props) => (props.$readonly ? 'center' : 'start')};
  margin-block: 10px;
  gap: 10px;

  .field {
    margin-top: 2px;
  }

  ${(props) =>
    props.indent &&
    css`
      margin-left: 20px;
    `}
`
