import { useMemo } from 'react'

import {
  DynamicFilterGroup,
  IFormDropdownValue,
} from 'components/admin/filters-search'
import useMediaMany from 'context/media/use-many'

export type MediaFilterValues = {
  category: IFormDropdownValue
}

const useMediaFiltersSearch = () => {
  const { queryFilters, setQueryFilters } = useMediaMany()

  const filters: DynamicFilterGroup<MediaFilterValues>[] = useMemo(() => {
    return []
  }, [])

  const defaultFilters = useMemo(() => {
    const mediaFilters: Partial<MediaFilterValues> = {}
    return mediaFilters
  }, [queryFilters])

  const handleApplyFilters = (_: MediaFilterValues) => {
    setQueryFilters({
      sortBy: queryFilters.sortBy,
      sortDirection: queryFilters.sortDirection,
      take: queryFilters.take,
    })
  }

  const handleSearch = (search: string) => {
    setQueryFilters((currQueryFilters) => ({
      ...currQueryFilters,
      search: search,
    }))
  }

  return { defaultFilters, filters, handleApplyFilters, handleSearch }
}

export default useMediaFiltersSearch
