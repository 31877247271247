import { useContext, useMemo } from 'react'

import useDesignsApprovedUnsentCount from 'context/design/use-approved-unsent-count'
import useDesignsAwaitingFeedbackCount from 'context/design/use-awaiting-feedback-count'
import useRoomsAwaitingPackCount from 'context/room/use-awaiting-pack-count'
import useSORegularCounts from 'context/supplier-order/use-regular-counts'
import { parseTasks } from 'context/task/utils-many'
import useFORMEmployees from 'context/user/use-form-employees'

import { TechDesignerContext } from './provider'

const useTechDesigner = () => {
  const { projects, refetchTasks, refetchProjects, tasks, userId } =
    useContext(TechDesignerContext)

  const { designsAwaitingFeedbackCount } =
    useDesignsAwaitingFeedbackCount(userId)
  const { designsApprovedUnsentCount } = useDesignsApprovedUnsentCount(userId)
  const { roomsAwaitingPackCount } = useRoomsAwaitingPackCount(userId)
  const {
    refetchAllCounts: refetchCounts,
    sosEDINotSentCount,
    sosAwaitingNobiliaCount,
    sosAwaitingFORMCount,
  } = useSORegularCounts(userId)

  const { FORMEmployeeIDToName } = useFORMEmployees()
  const userName = FORMEmployeeIDToName[userId]

  const parsedTasks = useMemo(() => parseTasks(tasks), [tasks])

  return {
    designsAwaitingFeedbackCount,
    designsApprovedUnsentCount,
    projects,
    refetchCounts,
    refetchProjects,
    refetchTasks,
    roomsAwaitingPackCount,
    sosEDINotSentCount,
    sosAwaitingFORMCount,
    sosAwaitingNobiliaCount,
    userId,
    userName,
    ...parsedTasks,
  }
}

export default useTechDesigner
