import { useMutation } from '@apollo/client'

import {
  UPSERT_NOBILIA_SAMPLE_CLASS,
  UpsertNobiliaSampleClassPayload,
  UpsertNobiliaSampleClassVariables,
} from 'queries/nobilia-sample-class'

const useNobiliaSampleClassMutation = () => {
  const [upsertNobiliaSampleClass, { loading }] = useMutation<
    UpsertNobiliaSampleClassPayload,
    UpsertNobiliaSampleClassVariables
  >(UPSERT_NOBILIA_SAMPLE_CLASS)

  return { upsertNobiliaSampleClass, loading }
}
export default useNobiliaSampleClassMutation
