import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import useProspect from 'context/prospect/use'
import Button from 'design-system/components/button'
import { StyledLabel } from 'styles/admin/main'
import * as viewUtils from 'views/utils'

import HomeownerMenu from './menu-homeowner'
import TradeMenu from './menu-trade'

const ProspectLayout = (props: React.PropsWithChildren) => {
  const { pathname } = useLocation()
  const { isTradeProspect, prospect } = useProspect()

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [pathname])

  if (!prospect)
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column width={11}>
          <h3>Prospect Not Found!</h3>
        </Grid.Column>
      </Grid.Row>
    </Grid>

  return (
    <Grid>
      {isTradeProspect ? <TopRowTrade /> : <TopRowHomeowner />}
      <Grid.Row>
        <Grid.Column>
          {isTradeProspect ? <TradeMenu /> : <HomeownerMenu />}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{props.children || null}</Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const TopRowHomeowner = () => {
  const { prospect, prospectName } = useProspect()
  return (
    <Grid.Row columns="2">
      <Grid.Column
        style={{
          alignItems: 'center',
          display: 'flex',
          gap: '16px',
        }}
      >
        <h3>{prospectName}</h3>
        {prospect?.data?.prospectType && (
          <StyledLabel
            styled={viewUtils.getLabelStyleForProspectType(
              prospect?.data?.prospectType,
            )}
          >
            {viewUtils.capsSnakeCaseToTitleCase(prospect?.data?.prospectType)}
          </StyledLabel>
        )}
        <Button
          onClick={() =>
            window.open(`/welcome/${prospect?.id}/free-consultation`)
          }
          icon={
            <FontAwesomeIcon
              icon={['fal', 'arrow-up-right-from-square']}
              style={{ marginLeft: '10px' }}
            />
          }
          kind="solid"
          text="Open Free Consultation Guide"
        />
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button
          color="gray"
          disabled={!prospect?.hubspotId}
          kind="ghost"
          onClick={() =>
            window.open(
              `${process.env.GATSBY_HUBSPOT_DOMAIN}/contacts/${prospect?.hubspotId}`,
            )
          }
          text="View Hubspot Contact"
        />
        <Button
          color="gray"
          kind="ghost"
          onClick={() => window.open(`/welcome/${prospect?.id}`)}
          icon={
            <FontAwesomeIcon
              icon={['fal', 'arrow-up-right-from-square']}
              style={{ marginLeft: '10px' }}
            />
          }
          style={{ marginLeft: '20px' }}
          text="Go To Prospect Dashboard"
        />
      </Grid.Column>
    </Grid.Row>
  )
}

const TopRowTrade = () => {
  const { prospect, prospectName } = useProspect()
  return (
    <Grid.Row columns="2">
      <Grid.Column
        style={{
          alignItems: 'center',
          display: 'flex',
          gap: '16px',
        }}
      >
        <h3>{prospectName}</h3>
        {prospect?.data?.prospectType && (
          <StyledLabel
            styled={viewUtils.getLabelStyleForProspectType(
              prospect?.data?.prospectType,
            )}
          >
            {viewUtils.capsSnakeCaseToTitleCase(prospect?.data?.prospectType)}
          </StyledLabel>
        )}
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button
          color="gray"
          disabled={!prospect?.hubspotId}
          kind="ghost"
          onClick={() =>
            window.open(
              `${process.env.GATSBY_HUBSPOT_DOMAIN}/contacts/${prospect?.hubspotId}`,
            )
          }
          text="View Hubspot Contact"
        />
        <Button
          color="gray"
          kind="ghost"
          onClick={() => window.open(`/welcome/${prospect?.id}/trade`)}
          icon={
            <FontAwesomeIcon
              icon={['fal', 'arrow-up-right-from-square']}
              style={{ marginLeft: '10px' }}
            />
          }
          style={{ marginLeft: '20px' }}
          text="Go To Prospect Dashboard"
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default ProspectLayout
